import React, { useRef } from 'react';
import { Scrollbars } from "react-custom-scrollbars";
import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';

const MonthViewCreateJob = ({ timeSlotsList, eveTimeSlotsList, employeeList, setTimeSlot, timeSlot, employeeId,
    setEmployeeId, handleTechnicianSlot, handleBayJobMessage, type }) => {


    timeSlot = moment(timeSlot, 'hh:mm A').format('HH:mm:ss');
    const accordionRef = useRef(null);
    function toggleAccordion() {
        accordionRef.current.click();
    }

    return (
        <div>
            <Accordion className="small-accordion" defaultActiveKey="0" >
                <Accordion.Item eventKey="0">
                    <Accordion.Header >
                        <span className="col-md-2 text-center">Technician Details</span>
                        <span className="col-md-6 text-center">Morning</span>
                        <span className="col-md-4 text-center" >
                            <span>Afternoon</span>
                            <i className="la la-angle-down" ref={accordionRef} style={{ float: "right" }} ></i>
                        </span>

                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                        <div className="tab-wrapper">
                            <div className="schedular-blocks d-flex" style={{ border: "0px!important" }}>
                                <ul className="scheduler-person-list activity-list">
                                    <Scrollbars
                                        autoHeight
                                        autoHeightMax={160}
                                        className="custom-scrollbar"
                                        style={{ width: "100%" }}
                                        renderThumbVertical={(props) => (
                                            <div className="thumb-vertical grey-thumb" />
                                        )}
                                        renderTrackHorizontal={(props) => (
                                            <div style={{ display: "none" }} />
                                        )}
                                    >
                                        {
                                            employeeList.length !== 0 ?
                                                employeeList.map((emp, index) => {
                                                    return (
                                                        <li key={type + "_" + emp.id} >
                                                            <Form.Check
                                                                className="radio-btn mb-2"
                                                                type="radio"
                                                                id={type + "_" + emp.id}
                                                            >
                                                                <Form.Check.Input
                                                                    // checked={emp.id == employeeId ? true : false}
                                                                    name="tech"
                                                                    type="radio"
                                                                    data-id={emp.data_type}
                                                                    isValid
                                                                    value={emp.id}
                                                                    onChange={
                                                                        (e) => { setEmployeeId(emp.id); handleTechnicianSlot(e.target, emp) }
                                                                    }
                                                                    disabled={(emp.working_days === 0) ? true : false}
                                                                />
                                                                <Form.Check.Label>
                                                                    {emp.name}
                                                                    <span className="check"></span>
                                                                </Form.Check.Label>
                                                            </Form.Check>
                                                        </li>
                                                    )
                                                }) : ""
                                        }
                                    </Scrollbars>
                                </ul>
                                <ul className="schedular-list d-flex left">
                                    {
                                        timeSlotsList.length !== 0 ?
                                            timeSlotsList.map((time, index) => {
                                                return (
                                                    <li key={type + "_" + time.start_time}>
                                                        <Form.Check
                                                            className="radio-btn mb-2"
                                                            type="radio"
                                                            id={type + "_" + time.slots}
                                                        >
                                                            {/* <Form.Check.Input
                                                                checked={time.start_time === timeSlot ? true : false}
                                                                name="slots"
                                                                type="radio"
                                                                data-time={time.start_time}
                                                                isValid
                                                                value={time.slots}
                                                                data-available={time.available}
                                                                disabled={(time.available === 0) ? true : false}
                                                                onChange={
                                                                    (e) => {
                                                                        setTimeSlot(time.start_time);
                                                                    }
                                                                }
                                                            /> */}
                                                            <Form.Check.Input
                                                                // checked={time.start_time === timeSlot ? true : false}
                                                                name="slots"
                                                                type="radio"
                                                                data-time={time.start_time}
                                                                isValid
                                                                value={time.slots}
                                                                data-available={time.available}
                                                                disabled={(time.available === 0) ? true : false}
                                                                onChange={
                                                                    (e) => {
                                                                        toggleAccordion()
                                                                        setTimeSlot(time.start_time);
                                                                        handleBayJobMessage(time)
                                                                    }
                                                                }
                                                            />
                                                            <Form.Check.Label>
                                                                {time.slots}
                                                                <span className="check"></span>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </li>
                                                )
                                            })
                                            : <div style={{ textAlign: "center" }}> No Slots available . </div>
                                    }
                                </ul>
                                <ul className="schedular-list d-flex right">
                                    {
                                        eveTimeSlotsList.length !== 0 ?
                                            eveTimeSlotsList.map((time, index) => {
                                                return (
                                                    <li key={type + "_" + time.start_time}>
                                                        <Form.Check
                                                            className="radio-btn mb-2"
                                                            type="radio"
                                                            id={type + "_" + time.slots}
                                                        >
                                                            <Form.Check.Input
                                                                // checked={time.start_time === timeSlot ? true : false}
                                                                name="slots"
                                                                type="radio"
                                                                data-time={time.start_time}
                                                                isValid
                                                                value={time.slots}
                                                                data-available={time.available}
                                                                disabled={(time.available === 0) ? true : false}
                                                                onChange={
                                                                    (e) => {
                                                                        toggleAccordion()
                                                                        setTimeSlot(time.start_time);
                                                                        handleBayJobMessage(time)
                                                                    }
                                                                }
                                                            />
                                                            <Form.Check.Label>
                                                                {time.slots}
                                                                <span className="check"></span>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </li>
                                                )
                                            })
                                            : <div style={{ textAlign: "center" }}> No Slots available . </div>
                                    }
                                </ul>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
};

export default MonthViewCreateJob;