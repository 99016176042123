import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const JobList = Loadable({
  loader: () => import("./components/jobList"),
  loading: Loading,
});

const UnassignedJob = Loadable({
  loader: () => import("./components/unassignedjobs/unassignedjob"),
  loading: Loading,
});

const JobDetails = Loadable({
  loader: () => import("./components/jobDetails/jobDetails"),
  loading: Loading,
});

const JobView = Loadable({
  loader: () => import("./components/jobView/jobDetails"),
  loading: Loading,
});

// const InspectionEmailVerification = Loadable({
//   loader: () => import("./components/jobDetails/InspectionTab/InspectionEmailVerification"),
//   loading: Loading,
// });

export const routes = [
  // {
  //   path: "/inspection-email-verification/:id",
  //   exact: true,
  //   name: "InspectionEmailVerification",
  //   element: InspectionEmailVerification,
  // },
  {
    path: "/job-list",
    exact: true,
    name: "JobList",
    element: JobList,
  },
  {
    path: "/unassigned-job",
    exact: true,
    name: "UnassignedJob",
    element: UnassignedJob,
  },
  {
    path: "/job_detail/:id",
    exact: true,
    name: "JobDetails",
    element: JobDetails,
  },
  {
    path: "/view_job/:id",
    exact: true,
    name: "JobView",
    element: JobView,
  },
];

export * from "./components";
