import React, { useState, useEffect } from 'react';
import moment from "moment-timezone";
import Select from "react-select";
import Loader from "views/common/UI/Loader";
import DayValues from "./DayValues";
import { useDefaultForm } from "./useDefaultForm";

const DefaultForm = () => {
  const { formLoading, formik } = useDefaultForm();

  const dateOptions = [
    {
      value: "DD/MM/YYYY",
      label: `DD/MM/YYYY - ${moment().format("DD/MM/YYYY")}`,
    },
    {
      value: "MM/DD/YYYY",
      label: `MM/DD/YYYY - ${moment().format("MM/DD/YYYY")}`,
    },
    {
      value: "YYYY/MM/DD",
      label: `YYYY/MM/DD - ${moment().format("YYYY/MM/DD")}`,
    },
    { value: "DD/MM/YY", label: `DD/MM/YY - ${moment().format("DD/MM/YY")}` },
    { value: "MM/DD/YY", label: `MM/DD/YY - ${moment().format("MM/DD/YY")}` },
    { value: "YY/MM/DD", label: `YY/MM/DD - ${moment().format("YY/MM/DD")}` },
    {
      value: "DD/MMM/YYYY",
      label: `DD/Mon/YYYY - ${moment().format("DD/MMM/YYYY")}`,
    },
    {
      value: "MMM/DD/YYYY",
      label: `Mon/DD/YYYY - ${moment().format("MMM/DD/YYYY")}`,
    },
    {
      value: "DD/MMM/YY",
      label: `DD/Mon/YY - ${moment().format("DD/MMM/YY")}`,
    },
    {
      value: "MMM/DD/YY",
      label: `Mon/DD/YY - ${moment().format("MMM/DD/YY")}`,
    },
  ];

  const timeOptions = [
    { value: "hh:mm:a", label: "hh:mm (12 Hours)" },
    { value: "HH:mm", label: "HH:MM (24 Hours)" },
  ];

  const technicianTimeOptions = [
    { value: "30", label: "30 Min" },
    // { value: "60", label: "1 Hour" },
    // { value: "90", label: "1:30 Hour" },
    // { value: "120", label: "2 Hour" },
  ];

  const customerTimeOptions = [
    { value: "60", label: "1 Hour" },
    { value: "120", label: "2 Hour" },
  ];

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  const [dateFormat, setDateFormat] = useState(formik.values.mobileDateFormat)
  useEffect(() => {
    setDateFormat(formik.values.mobileDateFormat);
  }, [formik.values.mobileDateFormat])

  return (
    <>
      <div>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h6 className="mb-0 text-uppercase">DEFAULT</h6>
          <div className="cta">
            <button
              type="button"
              className="btn btn-success px-3 btn-rounded"
              onClick={formik.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="row m-0">
            <div className="col-md-12 col-xl-6 border-end p-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Date Format
                    </label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      options={dateOptions}
                      value={dateOptions.find(
                        (date) => date.value == dateFormat
                      )}
                      // tabSelectsValue={
                      //   formik.values.dateFormat
                      //     ? dateOptions.filter(
                      //         (date) => date.value === formik.values.dateFormat
                      //       )
                      //     : ""
                      // }
                      onChange={(e) => {
                        formik.setFieldValue("dateFormat", e.value);
                        setDateFormat(e.value);
                      }
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Time Format
                    </label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      options={timeOptions}
                      value={timeOptions.find(
                        (date) => date.value == formik.values.timeFormat
                      )}
                      onChange={(e) =>
                        formik.setFieldValue("timeFormat", e.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="font-size-13 text-uppercase border-bottom py-3 mb-3">
                Numbering
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Quote
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formik.values.quoteNumber}
                      onChange={(e) =>
                        formik.setFieldValue("quoteNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      P/O
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formik.values.poNumber}
                      onChange={(e) =>
                        formik.setFieldValue("poNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Job
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formik.values.jobNumber}
                      onChange={(e) =>
                        formik.setFieldValue("jobNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Invoice
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formik.values.invoiceNumber}
                      onChange={(e) =>
                        formik.setFieldValue("invoiceNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="font-size-13 text-uppercase border-bottom py-3 mb-3">
                SCHEDULE - THIS TIME SLOTS WILL DISPLAY IN SCHEDULE VIEW
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Technician Time Slot
                    </label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      options={technicianTimeOptions}
                      value={
                        formik.values.technicianTimeSlot
                          ? technicianTimeOptions.filter(
                            (date) =>
                              date.value === formik.values.technicianTimeSlot
                          )
                          : ""
                      }
                      onChange={(e) =>
                        formik.setFieldValue("technicianTimeSlot", e.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Customer Time Slot
                    </label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      options={customerTimeOptions}
                      value={customerTimeOptions.filter(
                        (date) => date.value === formik.values.customerTimeSlot
                      )}
                      onChange={(e) =>
                        formik.setFieldValue("customerTimeSlot", e.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xl-6 p-4">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Day
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Open Time
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Close Time
                    </label>
                  </div>
                </div>
              </div>

              {formik.values.scheduleData.map((data, index) => {
                return (
                  <DayValues
                    data={data}
                    index={index}
                    days={days}
                    setFieldValue={formik.setFieldValue}
                    handleChange={formik.handleChange}
                  />
                );
              })}
              {/* return ( */}

              {/* ) */}
            </div>
          </div>
        </div>
      </div>
      {formLoading && <Loader />}
    </>
  );
};

export default DefaultForm;
