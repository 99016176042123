import { call, put, takeLatest } from "redux-saga/effects";
import
  {
    getGopayPaymentIntegration,
    getStripePaymentIntegration, saveGopayPaymentIntegration, saveStripePaymentIntegration
  } from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";
import
  {
    getGopayPaymentIntegrationFail,
    getGopayPaymentIntegrationSuccess,
    getStripePaymentIntegrationFail, getStripePaymentIntegrationSuccess, saveGopayPaymentIntegrationFail, saveGopayPaymentIntegrationSuccess, saveStripePaymentIntegrationFail,
    saveStripePaymentIntegrationSuccess
  } from "./actions";
import
  {
    GET_STRIPE_PAYMENT_INTEGRATION, SAVE_STRIPE_PAYMENT_INTEGRATION,
    GET_GOPAY_PAYMENT_INTEGRATION, SAVE_GOPAY_PAYMENT_INTEGRATION
  } from "./actionTypes";


function* onGetStripePaymentIntegration(data) {
  try {
    const response = yield call(getStripePaymentIntegration, data);
    if (response) {
      if (response.code === 200) {
        yield put(getStripePaymentIntegrationSuccess(response.data));
      } else {
        showError(response.message);
        yield put(getStripePaymentIntegrationFail(response.message));
      }
    } else {
      yield put(getStripePaymentIntegrationFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getStripePaymentIntegrationFail(error.response));
  }
}

function* onSaveStripePaymentIntegration(data) {
  try {
    const response = yield call(saveStripePaymentIntegration, data);
    if (response) {
      if (response.code === 200) {
        yield put(saveStripePaymentIntegrationSuccess(response));
        showSuccess(response.message);
      } else {
        yield put(saveStripePaymentIntegrationFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(saveStripePaymentIntegrationFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(saveStripePaymentIntegrationFail(error.response));
  }
}


function* onGetGopayPaymentIntegration(data) {
  try {
    const response = yield call(getGopayPaymentIntegration, data);
    if (response) {
      if (response.code === 200) {
        yield put(getGopayPaymentIntegrationSuccess(response.data));
      } else {
        showError(response.message);
        yield put(getGopayPaymentIntegrationFail(response.message));
      }
    } else {
      yield put(getGopayPaymentIntegrationFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getGopayPaymentIntegrationFail(error.response));
  }
}

function* onSaveGopayPaymentIntegration(data) {
  try {
    const response = yield call(saveGopayPaymentIntegration, data);
    if (response) {
      if (response.code === 200) {
        yield put(saveGopayPaymentIntegrationSuccess(response));
        showSuccess(response.message);
      } else {
        yield put(saveGopayPaymentIntegrationFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(saveGopayPaymentIntegrationFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(saveGopayPaymentIntegrationFail(error.response));
  }
}

function* PaymentIntegrationSaga() {
  yield takeLatest(GET_STRIPE_PAYMENT_INTEGRATION, onGetStripePaymentIntegration);
  yield takeLatest(SAVE_STRIPE_PAYMENT_INTEGRATION, onSaveStripePaymentIntegration);
  yield takeLatest(GET_GOPAY_PAYMENT_INTEGRATION, onGetGopayPaymentIntegration);
  yield takeLatest(SAVE_GOPAY_PAYMENT_INTEGRATION, onSaveGopayPaymentIntegration);
}

export default PaymentIntegrationSaga;
