import { apiCall } from "helpers/apiHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import { groupBy } from "lodash";
import { date_format, getCurrency } from "utils/getCurrency";
import moment from "moment";
import { getInventoryStockData, getInventoryStockTakeData, getMaterialPurchaseOrderData, materialPurchaseOrderChartData } from "helpers/backendHelper";
import { addComma } from "utils/numberFunction";

/** Single call utilities */

// purchase order START 
async function getMaterialPurchaseOrderTableData({ start_date, end_date }) {

  const request = {
    'get':'all',
    'conditions': {
        'PurchaseOrders.workshop_id': getWorkshopId(),
        'PurchaseOrders.created_on >=': start_date,
        'PurchaseOrders.created_on <=': end_date,
        'PurchaseOrders.is_deleted': '0'
    },
    'contain':["Suppliers","StorageDevices","PurchaseOrdersReciepts"]
  };
  
  const tableResponse = await getMaterialPurchaseOrderData({ payload: request });

  const poTable =
    tableResponse?.data?.PurchaseOrders?.map((po) => {
      
      return {
        id: po?.id,
        order_date: moment(po?.created_on).format(date_format),
        job_id: po?.job_id,
        warehouse: po?.storage_device?.name,
        po_status: po?.po_status,
        supplier: po?.supplier?.name,
        po_id: po?.id,
        total: (+po?.total || 0)
      };
    }) || [];

  poTable.sort((a, b) => a.id - b.id)

  return poTable;

}

async function getMaterialPurchaseOrderChartData({ start_date, end_date }) {

  const request = {
    workshop_id: getWorkshopId(),
    start_date: start_date,
    end_date: end_date
  }

  const chartResponse = await materialPurchaseOrderChartData({ payload: request });

  const obj = {
    poDates: [],
    totalPO: [],
    openPO: [],
    completedPO: [],
    total: [],
    totalPaid: [],
    totalOwed: [],
    pendingPo: [],
  };

  chartResponse?.data?.PurchaseOrders?.forEach((po) => {

    let owed_value = 0;
      if(po?.total_paid){
        owed_value = +po?.total - +po?.total_paid;
      }

    obj.poDates.push(moment(po.order_date).format(date_format));
    obj.totalPO.push(+po?.total_po || 0);
    obj.openPO.push(+po?.open_po || 0);
    obj.completedPO.push(+po?.completed_po || 0);
    obj.total.push(+po?.total || 0);
    obj.totalPaid.push(+po?.total_paid || 0);
    obj.totalOwed.push(+owed_value || 0);
    obj.pendingPo.push(+po?.pending_po || 0);

  });

  return obj;

}



// purchase order END 

// stock value START
async function getMaterialStockValueTableData({ start_date, end_date }) {

  const request = {
    'get':'all',
    'conditions': {
        'InventoryStock.workshop_id': getWorkshopId(),
        'InventoryStock.created_on >=': start_date,
        'InventoryStock.created_on <=': end_date,
    },
    'contain':["StorageDevices","Products"]
  };
  
  const tableResponse = await getInventoryStockData({ payload: request });

  const tableData =
    tableResponse?.data?.InventoryStock?.map((inventory) => {

      let total_value = +inventory?.stocks * +inventory?.product?.cost_price;
      
      return {
        id: inventory?.id,
        storage_device: inventory?.storage_device?.name,
        part_number: inventory?.product?.product_number,
        product_name: inventory?.product?.name,
        stock_qty: inventory?.stocks,
        cost_price: addComma(+inventory?.product?.cost_price || 0.00),
        total: (+total_value || 0)
      };
    }) || [];

  // stockTable.sort((a, b) => a.id - b.id)
  return tableData;

}

// stock value END


// stock take START
async function getMaterialStockTakeTableData({ start_date, end_date }) {
  
  const request = {
    'get':'all',
    'conditions': {
        'InventoryStockTake.workshop_id': getWorkshopId(),
        'InventoryStockTake.is_deleted': 0
    },
    'contain':["StorageDevices","Users"]
  };
  
  const tableResponse = await getInventoryStockTakeData({ payload: request });

  const tableData =
    tableResponse?.data?.InventoryStockTake?.map((inventory) => {
      return {
        id: inventory?.id,
        storage_device: inventory?.storage_device?.name,
        stock_take_datetime: moment(inventory?.stock_take_datetime).format(date_format),
        created_by: inventory?.user?.firstname+" "+inventory?.user?.lastname,
        stock_take: inventory?.stock_take,
      };
    }) || [];

  return tableData;

}

// stock take END

/** Single call utilities End */


// purchase order
export async function getMaterialPurchaseOrder({ start_date, end_date }) {
  
  const POTable = await getMaterialPurchaseOrderTableData({ start_date, end_date });
  const POChart = await getMaterialPurchaseOrderChartData({ start_date, end_date });
 
  return { POTable, POChart };
}

// stock value
export async function getMaterialStockValue({ start_date, end_date }) {
  const stockTable = await getMaterialStockValueTableData({ start_date, end_date });
  return { stockTable };
}

// stock take
export async function getMaterialStockTake({ start_date, end_date }) {
  const takeTable = await getMaterialStockTakeTableData({ start_date, end_date });
  return { takeTable };
}
