import React from "react";
import Container from "react-bootstrap/Container";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "../../../layouts/sidebar";
import { InitialData } from "views/common/InitialDataContext/InitialDataContext";
import { getWorkshopId } from "utils/getWorkshopId";

const Wrapper = (props) => {
  return (
    <InitialData
      workshop_id={getWorkshopId()}
      sessionData={JSON.parse(sessionStorage.getItem("stored_data"))}
    >
      <div className="main-wrapper">
        <Header />
        <Container fluid>
          <div className="page-wrapper">
            <Sidebar />
            {props.children}
          </div>
        </Container>
        <Footer />
      </div>
    </InitialData>
  );
};

export default Wrapper;
