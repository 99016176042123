import {
  GET_INSPECTIONS_DIGITAL_INSPECTION,
  GET_INSPECTIONS_DIGITAL_INSPECTION_SUCCESS,
  GET_INSPECTIONS_DIGITAL_INSPECTION_FAIL,
  GET_INSPECTION_GROUP_DIGITAL_INSPECTION,
  GET_INSPECTION_GROUP_DIGITAL_INSPECTION_FAIL,
  GET_INSPECTION_GROUP_DIGITAL_INSPECTION_SUCCESS,
} from "./actionTypes";

/*░░░░░░░░░░░░ START INSPECTION ░░░░░░░░░░░░*/

// Inspections
export const getInspectionsDigitalInspection = () => {
  return {
    type: GET_INSPECTIONS_DIGITAL_INSPECTION,
  };
};

export const getInspectionsDigitalInspectionSuccess = (data) => {
  return {
    type: GET_INSPECTIONS_DIGITAL_INSPECTION_SUCCESS,
    payload: data,
  };
};

export const getInspectionsDigitalInspectionFail = (error) => {
  return {
    type: GET_INSPECTIONS_DIGITAL_INSPECTION_FAIL,
    payload: error,
  };
};

/*░░░░░░░░░░░░  END INSPECTION  ░░░░░░░░░░░░

  ░░░░░░░░░░░░  START INSPECTION GROUPS  ░░░░░░░░░░░░*/

export const getInspectionDigitalInspectionGroup = (data) => {
  return {
    type: GET_INSPECTION_GROUP_DIGITAL_INSPECTION,
    payload: data,
  };
};

export const getInspectionGroupDigitalInspectionSuccess = (data) => {
  return {
    type: GET_INSPECTION_GROUP_DIGITAL_INSPECTION_SUCCESS,
    payload: data,
  };
};

export const getInspectionGroupDigitalInspectionFail = (error) => {
  return {
    type: GET_INSPECTION_GROUP_DIGITAL_INSPECTION_FAIL,
    payload: error,
  };
};

/*░░░░░░░░░░░░ END INSPECTION GROUPS ░░░░░░░░░░░░

  ░░░░░░░░░░░░  ░░░░░░░░░░░░*/

  