import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import materialsBlue from "../../../assets/images/materials-blue.svg";
import PartList from "./PartList";
import KitList from "./KitList";
import "./styles.scss";
import { Link, useLocation } from "react-router-dom";

const Catalogue = () => {

  const [activeTab, setActiveTab] = useState("assigned-job");
  const location = useLocation();

  useEffect(() => {
    document.title = "Catalogue | 5iQ";

    const hash = location.hash;
    // setActiveTab(hash.split("#")[1]);
    selectActiveTab(hash.split("#")[1]);
  }, [location.hash]);
  
  function selectActiveTab(k){
    setActiveTab(k);
    var url = window.location.href;
    url = url.split('#');
    const nextURL = url[0];
    const nextTitle = "Catalogue | 5iQ";
    const nextState = {};
    window.history.replaceState(nextState, nextTitle, nextURL);
  }

  return (
    <>
      <div className="right-section-wrapper parts-and-kit">
        <div className="table-header">
          <div className="table-header-tabs">
            <Tab.Container 
              defaultActiveKey="part"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <div className="row top-block">
                <div className="col-sm-2">
                  <div className="table-title">
                    <img src={materialsBlue} alt="materialblue"></img>
                    <span className="mx-2 font-size-18">MATERIALS</span>
                  </div>
                </div>
                <div className="col-sm-8">
                  <Nav variant="tabs d-flex">
                    <Nav.Item>
                      <Nav.Link eventKey="part">Part</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="kit">kit</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-sm-2">
                  <div className="video-link-outer">
                    <div className="video-tutorial">
                      <i className="fa fa-icon"></i>
                      {/* <span className="help-text">Help</span> */}
                      <Link to="/trainee-video#materials" ><span className="tutorial-title">Parts & Kits Videos</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="part">
                  <PartList />
                </Tab.Pane>
                <Tab.Pane eventKey="kit">
                  <KitList />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Catalogue;
