import { useCallback } from 'react';
import { useSelector } from "react-redux";
import { useSecurityProvider } from "routes/SecurityProvider";

export function useRoleLink() {
  const currentSecurity = useSecurityProvider();
  const { is_inspection_business } = useSelector((state) => state.LoginReducer);

  const filterCondition = useCallback((item) => {
    let multiMods = false;
    if (item.mods) {
      const keys = Object.keys(currentSecurity);
      const mods = item.mods.map((c) => `'${c}'`);
      multiMods = mods.some((c) => keys.includes(c));
    }
    // if (item.mod === "workshop") {
    //   return +is_inspection_business === 1;
    // }
    if (+currentSecurity?.role_id === 2) {
      return true;
    }
    if (item.mod === "global") {
      return true;
    }
    return !!currentSecurity[`'${item.mod}'`] || multiMods;
  }, [currentSecurity, is_inspection_business])

  return { filterCondition };
}
