import * as actions from "../actionType";

const materialReportState = {
  purchaseOrderReport: {
    POChart: null,
    POTable: [],
    error: false,
    loading: false,
  },
  stockValueReport: {
    stockTable: [],
    error: false,
    loading: false,
  },
  stockTakeReport: {
    takeTable: [],
    error: false,
    loading: false,
  },
  
  
};

export const MaterialReportsReducer = (state = materialReportState, action) => {

  switch (action.type) {
    case actions.MATERIAL_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrderReport: {
          ...state.purchaseOrderReport,
          loading: true,
          error: false,
        },
      };
    case actions.MATERIAL_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        purchaseOrderReport: {
          ...state.purchaseOrderReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.MATERIAL_PURCHASE_ORDER_FAIL:
      return {
        ...state,
        purchaseOrderReport: {
          ...state.purchaseOrderReport,
          loading: false,
          error: true,
        },
      };

    case actions.MATERIAL_STOCK_VALUE:
      return {
        ...state,
        stockValueReport: {
          ...state.stockValueReport,
          loading: true,
          error: false,
        },
      };
    case actions.MATERIAL_STOCK_VALUE_SUCCESS:
      return {
        ...state,
        stockValueReport: {
          ...state.stockValueReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.MATERIAL_STOCK_VALUE_FAIL:
        return {
          ...state,
          stockValueReport: {
            ...state.stockValueReport,
            loading: false,
            error: true,
          },
        };
  
    case actions.MATERIAL_STOCK_TAKE:
      return {
        ...state,
        stockTakeReport: {
          ...state.stockTakeReport,
          loading: true,
          error: false,
        },
      };
    case actions.MATERIAL_STOCK_TAKE_SUCCESS:
      return {
        ...state,
        stockTakeReport: {
          ...state.stockTakeReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.MATERIAL_STOCK_TAKE_FAIL:
      return {
        ...state,
        stockTakeReport: {
          ...state.stockTakeReport,
          loading: false,
          error: true,
        },
      };      

    default:
      return state;
  }
};
