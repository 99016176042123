import {
  DELETE_WORKSHOP_MARKUP,
  DELETE_WORKSHOP_MARKUP_FAIL,
  DELETE_WORKSHOP_MARKUP_SUCCESS,
  GET_TIMEZONE,
  GET_TIMEZONE_FAIL,
  GET_TIMEZONE_SUCCESS,
  GET_WORKSHOP_DETAIL,
  GET_WORKSHOP_DETAIL_FAIL,
  GET_WORKSHOP_DETAIL_SUCCESS,
  GET_WORKSHOP_MARKUP_DETAIL,
  GET_WORKSHOP_MARKUP_DETAIL_FAIL,
  GET_WORKSHOP_MARKUP_DETAIL_SUCCESS,
  GET_WORKSHOP_OTHER_DETAIL,
  GET_WORKSHOP_OTHER_DETAIL_FAIL,
  GET_WORKSHOP_OTHER_DETAIL_SUCCESS,
  GET_WORKSHOP_SMS,
  GET_WORKSHOP_SMS_FAIL,
  GET_WORKSHOP_SMS_SUCCESS,
  UPDATE_COMPANY_DETAIL,
  UPDATE_COMPANY_DETAIL_FAIL,
  UPDATE_COMPANY_DETAIL_SUCCESS,
  UPDATE_COMPANY_OTHER_DETAIL,
  UPDATE_COMPANY_OTHER_DETAIL_FAIL,
  UPDATE_COMPANY_OTHER_DETAIL_SUCCESS,
  UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL,
  UPDATE_WORKSHOP_MARKUP_DETAIL,
  UPDATE_WORKSHOP_MARKUP_DETAIL_FAIL,
  UPDATE_WORKSHOP_MARKUP_DETAIL_SUCCESS,
  UPDATE_WORKSHOP_SMS,
  UPDATE_WORKSHOP_SMS_FAIL,
  UPDATE_WORKSHOP_SMS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  formLoading: false,
  error: {
    message: "",
  },
  success: {
    message: "",
  },
  workshopLevelData: null,
  levelsCount: 0,
  workShopDetails: null,
  workShopOtherDetails: null,
  workShopSMS: null,
  timezones: null,
};

const CompanyDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TIMEZONE:
      state = {
        ...state,
        loading: true,
        timezones: action.timezones,
      };
      break;
    case GET_TIMEZONE_SUCCESS:
      state = {
        ...state,
        loading: false,
        timezones: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case GET_TIMEZONE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case GET_WORKSHOP_DETAIL:
      state = {
        ...state,
        loading: true,
        workShopDetails: action.workShopDetails,
      };
      break;
    case GET_WORKSHOP_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        workShopDetails: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case GET_WORKSHOP_DETAIL_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case GET_WORKSHOP_OTHER_DETAIL:
      state = {
        ...state,
        loading: true,
        workShopOtherDetails: action.workShopOtherDetails,
      };
      break;
    case GET_WORKSHOP_OTHER_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        workShopOtherDetails: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case GET_WORKSHOP_OTHER_DETAIL_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case UPDATE_COMPANY_DETAIL:
      state = {
        ...state,
        formLoading: true,
        workShopDetails: action.workShopDetails,
      };
      break;
    case UPDATE_COMPANY_DETAIL_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        workShopDetails: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case UPDATE_COMPANY_DETAIL_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;
    case UPDATE_COMPANY_OTHER_DETAIL:
      state = {
        ...state,
        formLoading: true,
        // workShopOtherDetails: action.workShopOtherDetails,
      };
      break;
    case UPDATE_COMPANY_OTHER_DETAIL_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        workShopOtherDetails: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case UPDATE_COMPANY_OTHER_DETAIL_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    // start workshop markup
    case GET_WORKSHOP_MARKUP_DETAIL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_WORKSHOP_MARKUP_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        formLoading: false,
        levelsData: action.payload,
        levelsCount: action.payload.length,
        success: {
          message: action.message,
        },
      };
      break;
    case GET_WORKSHOP_MARKUP_DETAIL_FAIL:
      state = {
        ...state,
        formLoading: false,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case UPDATE_WORKSHOP_MARKUP_DETAIL:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case UPDATE_WORKSHOP_MARKUP_DETAIL_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
        levelsData: [...state.levelsData, action.payload],
        levelsCount: state.levelsCount + 1,
      };
      break;
    case UPDATE_WORKSHOP_MARKUP_DETAIL_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    case DELETE_WORKSHOP_MARKUP:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case DELETE_WORKSHOP_MARKUP_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
        levelsCount: state.levelsCount - 1,
      };
      break;
    case DELETE_WORKSHOP_MARKUP_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    // end workshop markup
    case GET_WORKSHOP_SMS:
      state = {
        ...state,
        loading: true,
        workShopSMS: action.workShopSMS,
      };
      break;
    case GET_WORKSHOP_SMS_SUCCESS:
      state = {
        ...state,
        loading: false,
        workShopSMS: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case GET_WORKSHOP_SMS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case UPDATE_WORKSHOP_SMS:
      state = {
        ...state,
        loading: true,
        workShopSMS: action.workShopSMS,
      };
      break;
    case UPDATE_WORKSHOP_SMS_SUCCESS:
      state = {
        ...state,
        loading: false,
        workShopSMS: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case UPDATE_WORKSHOP_SMS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CompanyDetailReducer;
