export const GET_EMPLOYEE_CLOCKING_HISTORY = "GET_EMPLOYEE_CLOCKING_HISTORY";
export const GET_EMPLOYEE_CLOCKING_HISTORY_SUCCESS = "GET_EMPLOYEE_CLOCKING_HISTORY_SUCCESS";
export const GET_EMPLOYEE_CLOCKING_HISTORY_FAIL = "GET_EMPLOYEE_CLOCKING_HISTORY_FAIL"; 

export const GET_EMPLOYEE_WISE_CLOCKING_HISTORY = "GET_EMPLOYEE_WISE_CLOCKING_HISTORY";
export const GET_EMPLOYEE_WISE_CLOCKING_HISTORY_SUCCESS = "GET_EMPLOYEE_WISE_CLOCKING_HISTORY_SUCCESS";
export const GET_EMPLOYEE_WISE_CLOCKING_HISTORY_FAIL = "GET_EMPLOYEE_WISE_CLOCKING_HISTORY_FAIL"; 

// export const ADD_EMPLOYEE_CLOCKING_HISTORY = "ADD_EMPLOYEE_CLOCKING_HISTORY";
// export const ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS = "ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS";
// export const ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL = "ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL";

// export const UPDATE_EMPLOYEE_CLOCKING_HISTORY = "UPDATE_EMPLOYEE_CLOCKING_HISTORY";
// export const UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS = "UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS";
// export const UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL = "UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL";