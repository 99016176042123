import { isNull } from "underscore";
import {
  SAVE_JOB_LABOUR,
  SAVE_JOB_LABOUR_SUCCESS,
  SAVE_JOB_LABOUR_FAIL,
  GET_JOB_LABOUR_LIST,
  GET_JOB_LABOUR_LIST_SUCCESS,
  GET_JOB_LABOUR_LIST_FAIL,
  DELETE_JOB_LABOUR,
  DELETE_JOB_LABOUR_SUCCESS,
  DELETE_JOB_LABOUR_FAIL,
  UPDATE_JOB_LABOUR,
  UPDATE_JOB_LABOUR_SUCCESS,
  UPDATE_JOB_LABOUR_FAIL,
  INLINE_UPDATE_JOB_LABOUR,
  INLINE_UPDATE_JOB_LABOUR_SUCCESS,
  INLINE_UPDATE_JOB_LABOUR_FAIL,
  EDIT_JOB_LABOUR_MANY,
  EDIT_JOB_LABOUR_MANY_SUCCESS,
  EDIT_JOB_LABOUR_MANY_FAIL,
} from "./actionTypes";

const initialState = {
  data: [],
  labourData: null,
  error: {
    message: "",
  },
  jobLabourList: null,
  isDataLoad: 0,
  deleteLabour: null,
  updateLabour: null,
  labourManySuccess: false,
};

const JobLabourReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_JOB_LABOUR:
      state = { 
        ...state, 
        labourData: action.payload,
      };
    break;
    case SAVE_JOB_LABOUR_SUCCESS:
      state = { 
        ...state, 
        labourData: action.payload,
        jobLabourList: [...state.jobLabourList,action.payload],  
      };
    break;
    case SAVE_JOB_LABOUR_FAIL:
      state = {
        ...state,
        labourData: null,
        error: {
          message: action.payload,
        },
      };
    break;
    case GET_JOB_LABOUR_LIST:
      state = { 
        ...state, 
        isDataLoad: 0
      };
    break;
    case GET_JOB_LABOUR_LIST_SUCCESS:
      state = { 
        ...state, 
        jobLabourList: action.payload,
        isDataLoad: 1
      };
    break;
    case GET_JOB_LABOUR_LIST_FAIL:
      state = {
        ...state,
        jobLabourList: null,
        error: {
          message: action.payload,
        },
        isDataLoad: 1
      };
    break;
    case DELETE_JOB_LABOUR:
      state = { 
        ...state, 
        deleteLabour: action.deleteLabour,
      };
    break;
    case DELETE_JOB_LABOUR_SUCCESS:
      state = { 
        ...state, 
        deleteLabour: action.payload,
        jobLabourList:  [
          ...state.jobLabourList.filter(item => (item.id !== action.payload.id)), 
        ]
      };
    break;
    case DELETE_JOB_LABOUR_FAIL:
      state = {
        ...state,
        deleteLabour: null,
        error: {
          message: action.payload,
        },
      };
    break;

    case UPDATE_JOB_LABOUR:
      state = { 
        ...state, 
        updateLabour: action.payload,
      };
    break;
    case UPDATE_JOB_LABOUR_SUCCESS:

      const labours = [...state.jobLabourList];
      const indexLabour = labours.findIndex( c => c.id === action.payload.id);  
      if(indexLabour !== -1){
        labours[indexLabour] = {
              ...labours[indexLabour],
              ...action.payload
          }
          // parts[indexPart] = {
          //   retail_price:action.payload.retail
          // }

      }
    
      state = { 
        ...state, 
        updateLabour: action.payload,
        jobLabourList : labours
      };

    break;
    case UPDATE_JOB_LABOUR_FAIL:
      state = {
        ...state,
        updateLabour: null,
        error: {
          message: action.payload,
        },
      };
    break;


    case INLINE_UPDATE_JOB_LABOUR:
      state = { 
        ...state, 
        updateLabour: action.payload,
      };
    break;
    case INLINE_UPDATE_JOB_LABOUR_SUCCESS:

      const labours1 = [...state.jobLabourList];
      const indexLabour1 = labours1.findIndex( c => c.id === action.payload.id);  
      if(indexLabour1 !== -1){
        labours1[indexLabour1] = {
              ...labours1[indexLabour1],
              ...action.payload
          }
      }
    
      state = { 
        ...state, 
        updateLabour: action.payload,
        jobLabourList : labours1
      };

    break;
    case INLINE_UPDATE_JOB_LABOUR_FAIL:
      state = {
        ...state,
        updateLabour: null,
        error: {
          message: action.payload,
        },
      };
    break;

    case EDIT_JOB_LABOUR_MANY:
      state = { 
        ...state, 
        labourManySuccess: false,
      };
    break;
    case EDIT_JOB_LABOUR_MANY_SUCCESS:
      state = { 
        ...state, 
        labourManySuccess: true,
      };
    break;
    case EDIT_JOB_LABOUR_MANY_FAIL:
      state = {
        ...state,
        labourManySuccess: false,
        error: {
          message: action.payload,
        },
      };
    break;

    default:
      state = { ...state };
    break;
  }
  return state;
};

export default JobLabourReducer;
