import {
    GET_VEHICLE_COUNT,
    GET_VEHICLE_COUNT_SUCCESS,
    GET_VEHICLE_COUNT_FAIL,
    GET_SERVICE_DUE_COUNT,
    GET_SERVICE_DUE_COUNT_SUCCESS,
    GET_SERVICE_DUE_COUNT_FAIL,
    GET_CUSTOMER_AND_APP_COUNT_SUCCESS,
    GET_CUSTOMER_AND_APP_COUNT_FAIL,
    GET_CUSTOMER_AND_APP_COUNT,
    GET_BOOK_IN_SOURCE_SUCCESS,
    GET_BOOK_IN_SOURCE_FAIL,
    GET_BOOK_IN_SOURCE,
    GET_CUSTOMER_SURVEY_SUCCESS,
    GET_CUSTOMER_SURVEY_FAIL,
    GET_CUSTOMER_SURVEY,
    GET_NEWS_FEED_SUCCESS,
    GET_NEWS_FEED_FAIL,
    GET_NEWS_FEED,
    GET_DASHBOARD_JOB_COUNT,
    GET_DASHBOARD_JOB_COUNT_SUCCESS,
    GET_DASHBOARD_JOB_COUNT_FAIL,
    GET_DASHBOARD_WEEK_JOB_COUNT,
    GET_DASHBOARD_WEEK_JOB_COUNT_SUCCESS,
    GET_DASHBOARD_WEEK_JOB_COUNT_FAIL,
    GET_DASHBOARD_INVOICE_COUNT,
    GET_DASHBOARD_INVOICE_COUNT_SUCCESS,
    GET_DASHBOARD_INVOICE_COUNT_FAIL,
    GET_DASHBOARD_WEEK_INVOICE_COUNT,
    GET_DASHBOARD_WEEK_INVOICE_COUNT_SUCCESS,
    GET_DASHBOARD_WEEK_INVOICE_COUNT_FAIL,
    GET_DASHBOARD_PAYMENT_COUNT,
    GET_DASHBOARD_PAYMENT_COUNT_SUCCESS,
    GET_DASHBOARD_PAYMENT_COUNT_FAIL,
    GET_DASHBOARD_WEEK_PAYMENT_COUNT,
    GET_DASHBOARD_WEEK_PAYMENT_COUNT_SUCCESS,
    GET_DASHBOARD_WEEK_PAYMENT_COUNT_FAIL,
    UPDATE_CHECK_IN_TIME,
    UPDATE_CHECK_IN_TIME_SUCCESS,
    UPDATE_CHECK_IN_TIME_FAIL,
} from "./actionTypes";

export const getVehicleCount = () => {
    return {
        type: GET_VEHICLE_COUNT,
    };
};

export const getVehicleCountSuccess = (data) => {
    return {
        type: GET_VEHICLE_COUNT_SUCCESS,
        payload: data,
    };
};

export const getVehicleCountFail = (error) => {
    return {
        type: GET_VEHICLE_COUNT_FAIL,
        payload: error,
    };
};

export const getServiceDueCount = () => {
    return {
        type: GET_SERVICE_DUE_COUNT,
    };
};

export const getServiceDueCountSuccess = (data) => {
    return {
        type: GET_SERVICE_DUE_COUNT_SUCCESS,
        payload: data,
    };
};

export const getServiceDueCountFail = (error) => {
    return {
        type: GET_SERVICE_DUE_COUNT_FAIL,
        payload: error,
    };
};

export const getCustomerAndAppCount = () => {
    return {
        type: GET_CUSTOMER_AND_APP_COUNT,
    };
};

export const getCustomerAndAppCountSuccess = (data) => {
    return {
        type: GET_CUSTOMER_AND_APP_COUNT_SUCCESS,
        payload: data,
    };
};

export const getCustomerAndAppCountFail = (error) => {
    return {
        type: GET_CUSTOMER_AND_APP_COUNT_FAIL,
        payload: error,
    };
};

export const getBookInSource = () => {
    return {
        type: GET_BOOK_IN_SOURCE,
    };
};

export const getBookInSourceSuccess = (data) => {
    return {
        type: GET_BOOK_IN_SOURCE_SUCCESS,
        payload: data,
    };
};

export const getBookInSourceFail = (error) => {
    return {
        type: GET_BOOK_IN_SOURCE_FAIL,
        payload: error,
    };
};

export const getCustomerSurvey = () => {
    return {
        type: GET_CUSTOMER_SURVEY,
    };
};

export const getCustomerSurveySuccess = (data) => {
    return {
        type: GET_CUSTOMER_SURVEY_SUCCESS,
        payload: data,
    };
};

export const getCustomerSurveyFail = (error) => {
    return {
        type: GET_CUSTOMER_SURVEY_FAIL,
        payload: error,
    };
};

export const getNewsFeed = () => {
    return {
        type: GET_NEWS_FEED,
    };
};

export const getNewsFeedSuccess = (data) => {
    return {
        type: GET_NEWS_FEED_SUCCESS,
        payload: data,
    };
};

export const getNewsFeedFail = (error) => {
    return {
        type: GET_NEWS_FEED_FAIL,
        payload: error,
    };
};

export const getDashboardJobCount = () => {
    return {
        type: GET_DASHBOARD_JOB_COUNT,
    };
};

export const getDashboardJobCountSuccess = (data) => {
    return {
        type: GET_DASHBOARD_JOB_COUNT_SUCCESS,
        payload: data,
    };
};

export const getDashboardJobCountFail = (error) => {
    return {
        type: GET_DASHBOARD_JOB_COUNT_FAIL,
        payload: error,
    };
};

export const getDashboardWeekJobCount = () => {
    return {
        type: GET_DASHBOARD_WEEK_JOB_COUNT,
    };
};

export const getDashboardWeekJobCountSuccess = (data) => {
    return {
        type: GET_DASHBOARD_WEEK_JOB_COUNT_SUCCESS,
        payload: data,
    };
};

export const getDashboardWeekJobCountFail = (error) => {
    return {
        type: GET_DASHBOARD_WEEK_JOB_COUNT_FAIL,
        payload: error,
    };
};

export const getDashboardInvoiceCount = () => {
    return {
        type: GET_DASHBOARD_INVOICE_COUNT,
    };
};

export const getDashboardInvoiceCountSuccess = (data) => {
    return {
        type: GET_DASHBOARD_INVOICE_COUNT_SUCCESS,
        payload: data,
    };
};

export const getDashboardInvoiceCountFail = (error) => {
    return {
        type: GET_DASHBOARD_INVOICE_COUNT_FAIL,
        payload: error,
    };
};

export const getDashboardWeekInvoiceCount = () => {
    return {
        type: GET_DASHBOARD_WEEK_INVOICE_COUNT,
    };
};

export const getDashboardWeekInvoiceCountSuccess = (data) => {
    return {
        type: GET_DASHBOARD_WEEK_INVOICE_COUNT_SUCCESS,
        payload: data,
    };
};

export const getDashboardWeekInvoiceCountFail = (error) => {
    return {
        type: GET_DASHBOARD_WEEK_INVOICE_COUNT_FAIL,
        payload: error,
    };
};

export const getDashboardPaymentCount = () => {
    return {
        type: GET_DASHBOARD_PAYMENT_COUNT,
    };
};

export const getDashboardPaymentCountSuccess = (data) => {
    return {
        type: GET_DASHBOARD_PAYMENT_COUNT_SUCCESS,
        payload: data,
    };
};

export const getDashboardPaymentCountFail = (error) => {
    return {
        type: GET_DASHBOARD_PAYMENT_COUNT_FAIL,
        payload: error,
    };
};

export const getDashboardWeekPaymentCount = () => {
    return {
        type: GET_DASHBOARD_WEEK_PAYMENT_COUNT,
    };
};

export const getDashboardWeekPaymentCountSuccess = (data) => {
    return {
        type: GET_DASHBOARD_WEEK_PAYMENT_COUNT_SUCCESS,
        payload: data,
    };
};

export const getDashboardWeekPaymentCountFail = (error) => {
    return {
        type: GET_DASHBOARD_WEEK_PAYMENT_COUNT_FAIL,
        payload: error,
    };
};

export const updateCheckInTime = () => {
    return {
        type: UPDATE_CHECK_IN_TIME,
    };
};

export const updateCheckInTimeSuccess = (data) => {
    return {
        type: UPDATE_CHECK_IN_TIME_SUCCESS,
        payload: data,
    };
};

export const updateCheckInTimeFail = (error) => {
    return {
        type: UPDATE_CHECK_IN_TIME_FAIL,
        payload: error,
    };
};
