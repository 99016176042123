import {
  SAVE_PART,
  SAVE_PART_SUCCESS,
  SAVE_PART_FAIL,
  EDIT_PART,
  EDIT_PART_SUCCESS,
  EDIT_PART_FAIL,
  SAVE_PART_REACT,
  SAVE_PART_REACT_SUCCESS,
  SAVE_PART_REACT_FAIL,
  PART_DETAIL,
  PART_DETAIL_SUCCESS,
  PART_DETAIL_FAIL,
  EDIT_PART_REACT,
  EDIT_PART_REACT_SUCCESS,
  EDIT_PART_REACT_FAIL,
  PART_VIEW,
  PART_VIEW_SUCCESS,
  PART_VIEW_FAIL,
  GET_PART_LIST,
  GET_PART_LIST_SUCCESS,
  GET_PART_LIST_FAIL,
  GET_PART_LIST_COUNT,
  GET_PART_LIST_COUNT_SUCCESS,
  GET_PART_LIST_COUNT_FAIL,
  SEARCH_PART_LIST,
  SEARCH_PART_LIST_SUCCESS,
  SEARCH_PART_LIST_FAIL,
  GET_PART_CATEGORY_LIST,
  GET_PART_CATEGORY_LIST_SUCCESS,
  GET_PART_CATEGORY_LIST_FAIL,
  GET_KIT_PART_LIST,
  GET_KIT_PART_LIST_SUCCESS,
  GET_KIT_PART_LIST_FAIL,
  GET_KIT_PART_LIST_COUNT,
  GET_KIT_PART_LIST_COUNT_SUCCESS,
  GET_KIT_PART_LIST_COUNT_FAIL,
  SEARCH_KIT_PART_LIST,
  SEARCH_KIT_PART_LIST_SUCCESS,
  SEARCH_KIT_PART_LIST_FAIL,
  DELETE_PART,
  DELETE_PART_SUCCESS,
  DELETE_PART_FAIL,
  DELETE_KIT_PART,
  DELETE_KIT_PART_SUCCESS,
  DELETE_KIT_PART_FAIL,
  ADD_PART_TO_LIST,
  REMOVE_PART_FROM_LIST,
  CLEAR_PART_LIST,
  UPDATE_PART,
  GET_PART_LIST_NEW,
  ADD_LABOUR,
  UPDATE_LABOUR,
  DELETE_LABOUR_FROM_LIST,
  UPDATE_SUMMARY,
  ADD_INITIAL_LABOURS,
  ADD_INITIAL_PARTS,
  REMOVE_PART_FROM_KIT_LIST,
  REMOVE_PO_PART_FROM_LIST
} from "./actionTypes";

// Save part START
export const savePart = () => {
  return {
    type: SAVE_PART,
  };
};

export const savePartSuccess = (data) => {
  return {
    type: SAVE_PART_SUCCESS,
    payload: data,
  };
};

export const savePartFail = (error) => {
  return {
    type: SAVE_PART_FAIL,
    payload: error,
  };
};
// Save part END


// Edit part START
export const editPart = () => {
  return {
    type: EDIT_PART,
  };
};

export const editPartSuccess = (data) => {
  return {
    type: EDIT_PART_SUCCESS,
    payload: data,
  };
};

export const editPartFail = (error) => {
  return {
    type: EDIT_PART_FAIL,
    payload: error,
  };
};
// Edit part END


// Save part START
export const savePartReact = () => {
  return {
    type: SAVE_PART_REACT,
  };
};

export const savePartReactSuccess = (data) => {
  return {
    type: SAVE_PART_REACT_SUCCESS,
    payload: data,
  };
};

export const savePartReactFail = (error) => {
  return {
    type: SAVE_PART_REACT_FAIL,
    payload: error,
  };
};
// Save part END

// Edit part START
export const editPartReact = () => {
  return {
    type: EDIT_PART_REACT,
  };
};

export const editPartReactSuccess = (data) => {
  return {
    type: EDIT_PART_REACT_SUCCESS,
    payload: data,
  };
};

export const editPartReactFail = (error) => {
  return {
    type: EDIT_PART_REACT_FAIL,
    payload: error,
  };
};
// Edit part END



// part view START
export const partView = () => {
  return {
    type: PART_VIEW,
  };
};

export const partViewSuccess = (data) => {
  return {
    type: PART_VIEW_SUCCESS,
    payload: data,
  };
};

export const partViewFail = (error) => {
  return {
    type: PART_VIEW_FAIL,
    payload: error,
  };
};
// part view END


// get part list, search part, check part exist or not START
export const getPartList = (payload, query) => {
  return {
    type: GET_PART_LIST,
    payload,
    query,
  };
};

export const getPartListNew = (payload, query) => {
  return {
    type: GET_PART_LIST_NEW,
    payload,
    query,
  };
};

export const getPartListSuccess = (data) => {
  return {
    type: GET_PART_LIST_SUCCESS,
    payload: data,
  };
};

export const clearPartList = () => {
  return {
    type: CLEAR_PART_LIST,
  };
};

export const getPartListFail = (error) => {
  return {
    type: GET_PART_LIST_FAIL,
    payload: error,
  };
};


export const partDetail = (payload, query) => {
  return {
    type: PART_DETAIL,
  };
};

export const partDetailSuccess = (data) => {
  return {
    type: PART_DETAIL_SUCCESS,
    payload: data,
  };
};

export const partDetailFail = (error) => {
  return {
    type: PART_DETAIL_FAIL,
    payload: error,
  };
};


export const getPartListCount = (payload, query) => {
  return {
    type: GET_PART_LIST_COUNT,
    payload,
    query,
  };
};

export const getPartListCountSuccess = (data) => {
  return {
    type: GET_PART_LIST_COUNT_SUCCESS,
    payload: data,
  };
};

export const getPartListCountFail = (error) => {
  return {
    type: GET_PART_LIST_COUNT_FAIL,
    payload: error,
  };
};



export const getSearchPartList = (payload, query) => {
  return {
    type: SEARCH_PART_LIST,
    payload,
    query,
  };
};

export const getSearchPartListSuccess = (data) => {
  return {
    type: SEARCH_PART_LIST_SUCCESS,
    payload: data,
  };
};

export const getSearchPartListFail = (error) => {
  return {
    type: SEARCH_PART_LIST_FAIL,
    payload: error,
  };
};



export const getPartCategoryList = (payload, query) => {
  return {
    type: GET_PART_CATEGORY_LIST,
    payload,
    query,
  };
};

export const getPartCategoryListSuccess = (data) => {
  return {
    type: GET_PART_CATEGORY_LIST_SUCCESS,
    payload: data,
  };
};

export const getPartCategoryListFail = (error) => {
  return {
    type: GET_PART_CATEGORY_LIST_FAIL,
    payload: error,
  };
};

// kit part start

export const getKitPartList = (payload, query) => {
  return {
    type: GET_KIT_PART_LIST,
    payload,
    query,
  };
};

export const getKitPartListSuccess = (data) => {
  return {
    type: GET_KIT_PART_LIST_SUCCESS,
    payload: data,
  };
};


export const getKitPartListFail = (error) => {
  return {
    type: GET_KIT_PART_LIST_FAIL,
    payload: error,
  };
};

export const getKitPartListCount = (payload, query) => {
  return {
    type: GET_KIT_PART_LIST_COUNT,
    payload,
    query,
  };
};

export const getKitPartListCountSuccess = (data) => {
  return {
    type: GET_KIT_PART_LIST_COUNT_SUCCESS,
    payload: data,
  };
};

export const getKitPartListCountFail = (error) => {
  return {
    type: GET_KIT_PART_LIST_COUNT_FAIL,
    payload: error,
  };
};

export const getKitSearchPartList = (payload, query) => {
  return {
    type: SEARCH_KIT_PART_LIST,
    payload,
    query,
  };
};

export const getKitSearchPartListSuccess = (data) => {
  return {
    type: SEARCH_KIT_PART_LIST_SUCCESS,
    payload: data,
  };
};

export const getKitSearchPartListFail = (error) => {
  return {
    type: SEARCH_KIT_PART_LIST_FAIL,
    payload: error,
  };
};

// kit part end

// get part list, search part, check part exist or not START

// Delete part START
export const deletePart = () => {
  return {
    type: DELETE_PART,
  };
};

export const deletePartSuccess = (data) => {
  return {
    type: DELETE_PART_SUCCESS,
    payload: data,
  };
};

export const deletePartFail = (error) => {
  return {
    type: DELETE_PART_FAIL,
    payload: error,
  };
};
// Delete part END

// Delete kit part START
export const deleteKitPart = () => {
  return {
    type: DELETE_KIT_PART,
  };
};

export const deleteKitPartSuccess = (data) => {
  return {
    type: DELETE_KIT_PART_SUCCESS,
    payload: data,
  };
};

export const deleteKitPartFail = (error) => {
  return {
    type: DELETE_KIT_PART_FAIL,
    payload: error,
  };
};
// Delete kit part END



export const addPartToList = (payload) => ({
  type: ADD_PART_TO_LIST,
  payload,
});

export const updatePartToList = (payload) => ({
  type: UPDATE_PART,
  payload,
});

export const removePartFromList = (id) => ({
  type: REMOVE_PART_FROM_LIST,
  id,
});

export const removePOPartFromList = (id) => ({
  type: REMOVE_PO_PART_FROM_LIST,
  id,
});

export const removePartFromKitList = (id) => ({
  type: REMOVE_PART_FROM_KIT_LIST,
  id,
});

export const addLabourToList = (payload) => ({
  type: ADD_LABOUR,
  payload,
});

export const updateLabourToList = (payload) => ({
  type: UPDATE_LABOUR,
  payload,
});

export const removeLabourFromList = (id) => ({
  type: DELETE_LABOUR_FROM_LIST,
  id,
});

export const updateSummary = (payload) => ({
  type: UPDATE_SUMMARY,
  payload,
});

export const addInitialParts = (payload) => ({
  type: ADD_INITIAL_PARTS,
  payload
})
export const addInitialLabours = (payload) => ({
  type: ADD_INITIAL_LABOURS,
  payload
})