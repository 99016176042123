import {
  GET_SUBSCRIPTION_HISTORY_LIST,
  GET_SUBSCRIPTION_HISTORY_LIST_SUCCESS,
  GET_SUBSCRIPTION_HISTORY_LIST_FAIL,
  GET_SUBSCRIPTION_HISTORY_DETAIL_LIST,
  GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_SUCCESS,
  GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_FAIL,
  GET_SUBSCRIPTION_LIST,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_FAIL,
  GET_SUBSCRIPTION_DETAILS_LIST,
  GET_SUBSCRIPTION_DETAILS_LIST_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_LIST_FAIL,
  GET_SUBSCRIPTION_MONTHLY_HISTORY,
  GET_SUBSCRIPTION_MONTHLY_HISTORY_SUCCESS,
  GET_SUBSCRIPTION_MONTHLY_HISTORY_FAIL,
} from "./actionTypes";

export const getSubscriptionList = () => {
  return {
    type: GET_SUBSCRIPTION_LIST,
  };
};

export const getSubscriptionListSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_LIST_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionListFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_LIST_FAIL,
    payload: error,
  };
};

export const getSubscriptionDetailsList = () => {
  return {
    type: GET_SUBSCRIPTION_DETAILS_LIST,
  };
};

export const getSubscriptionDetailsListSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_DETAILS_LIST_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionDetailsListFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_DETAILS_LIST_FAIL,
    payload: error,
  };
};

export const getSubscriptionHistoryList = () => {
  return {
    type: GET_SUBSCRIPTION_HISTORY_LIST,
  };
};

export const getSubscriptionHistoryListSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_HISTORY_LIST_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionHistoryListFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_HISTORY_LIST_FAIL,
    payload: error,
  };
};

export const getSubscriptionHistoryDetailsList = () => {
  return {
    type: GET_SUBSCRIPTION_HISTORY_DETAIL_LIST,
  };
};

export const getSubscriptionHistoryDetailsListSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionHistoryDetailsListFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_FAIL,
    payload: error,
  };
};


export const getMonthlySubsciptionDetail = () => {
  return {
    type: GET_SUBSCRIPTION_MONTHLY_HISTORY,
  };
};

export const getMonthlySubsciptionDetailSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_MONTHLY_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getMonthlySubsciptionDetailFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_MONTHLY_HISTORY_FAIL,
    payload: error,
  };
};