import moment from "moment-timezone/builds/moment-timezone-with-data";

export function timeZoneToUTC(fromTime, fromTimezone, fromTimeformat = "YYYY-MM-DD HH:mm:ss", convertformat = "HH:mm:ss") {
  // fromTime = moment(fromTime).format("YYYY-MM-DD HH:mm:ss");
  // console.log(moment.tz("2022-07-14 07:00:00","YYYY-MM-DD HH:mm:ss","Australia/Sydney").utc().format(convertformat));
  // construct a moment object
  var m = moment.tz(fromTime, fromTimeformat, fromTimezone);
  // convert it to utc
  m.utc();
  // format it for output
  var s = m.format(convertformat)
  return s;
}
export function convertDurationToAbsolute(duration = "00:00") {
  const [hr, mn] = duration.split(":");
  return parseFloat(hr) * 60 + parseFloat(mn);
}

export function convertDurationToDecimal(duration = "00:00") {
  const [hr = 0, mn = 0] = duration.split(":")
  return (parseFloat(hr) + parseFloat(mn) * (1 / 60)).toFixed(2)
}

export function convertTimeToDuration(duration = 0) {
  const hr = parseInt(duration / 60);
  const mn = Math.abs(duration % 60);

  return `${duration < 0 ? "-" : ""}${hr.toString().padStart(2, 0)}:${mn
    .toString()
    .padStart(2, 0)}`;
}



export function utcToTimezone(
  fromTime,
  toTimezone,
  fromTimeformat,
  convertformat = "HH:mm:ss"
) {
  const m = moment
    .utc(fromTime, fromTimeformat)
    .tz(toTimezone)
    .format(convertformat);
  return m;
}

export function utcToDateTimezone(
  fromTime,
  toTimezone,
  fromTimeformat,
  convertformat = "YYYY-MM-DD HH:mm:ss"
) {
  const m = moment
    .utc(fromTime, fromTimeformat)
    .tz(toTimezone)
    .format(convertformat);
  return m;
}

export function convertToMinute(time) {
  return moment.duration(time).asMinutes();
}

export function convertToHours(time) {
  var hours = time / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

  if (rminutes === 60) {
    rhours += 1;
    rminutes = 0;
  }

  if (rhours < 10 && rhours >= 0) {
    rhours = "0" + rhours;
  }
  if (rminutes < 10) {
    rminutes = "0" + rminutes;
  }
  return rhours + ":" + rminutes;
  // return moment.utc(moment.duration(time, "minutes").asMilliseconds()).format("HH:mm");
}

export function convertToHoursMinSec(time) {
  var hours = time / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.floor(minutes);
  var second = (minutes - rminutes) * 60;
  var rsecond = Math.round(second);

  if (rhours < 10 && rhours >= 0) {
    rhours = "0" + rhours;
  }
  if (rminutes < 10) {
    rminutes = "0" + rminutes;
  }
  if (rsecond < 10) {
    rsecond = "0" + rsecond;
  }
  return rhours + ":" + rminutes + ":" + rsecond;
  // return moment.utc(moment.duration(time, "minutes").asMilliseconds()).format("HH:mm");
}

export function timeDiffMinute(startTime, endTime) {
  var start = moment(startTime, "HH:mm:ss");
  var end = moment(endTime, "HH:mm:ss");

  var duration = moment.duration(end.diff(start));
  var minute = duration.asMinutes();

  return minute;
}

export function dateDiff(startDate, endDate) {
  let time = "";
  return time;
}

export function getHourMinSecondByTwoDates(startDate, endDate, type = "") {
  var date1, date2;

  date1 = new Date(startDate);
  date2 = new Date(endDate);

  var res = Math.abs(date1 - date2) / 1000;

  // get total days between two dates
  var days = Math.floor(res / 86400);

  // get hours        
  var hours = Math.floor(res / 3600) % 24;
  if (days > 0) {
    hours = hours * days;
  }
  hours = ('0' + hours).slice(-2)

  // get minutes
  var minutes = Math.floor(res / 60) % 60;
  minutes = ('0' + minutes).slice(-2)

  // get seconds
  var seconds = res % 60;
  seconds = ('0' + seconds).slice(-2)
  if (type === 'HHMM') {
    return hours + ":" + minutes;
  } else {
    return hours + ":" + minutes + ":" + seconds;
  }
}

// for labour calculation 
export function convertMinutesToHoursMinutes(minutesCount) {
  const time = moment.utc(
    moment.duration(minutesCount, "minutes")
      .asMilliseconds()
  )
  const minutes = time.minutes();
  const hours = time.hours();
  const hourFormatStr = hours === 1 ? 'Hr' : 'Hrs';
  const minuteFormatStr = minutes === 1 ? 'Minute' : 'Minutes';
  if (!time.minutes()) {
    return time.format(`h [${hourFormatStr}]`);
  }
  return time.format(`h [${hourFormatStr}] : mm [${minuteFormatStr}]`);
}


export function getMillisecond(startTime, endTime) {
  var time_start = new Date();
  var time_end = new Date();
  var value_start = startTime.split(':');
  var value_end = endTime.split(':');

  time_start.setHours(value_start[0], value_start[1], value_start[2], 0)
  time_end.setHours(value_end[0], value_end[1], value_end[2], 0)

  return +time_end - +time_start; // millisecond 
}

export function convertMsToHM(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}


export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}


export function convertToHoursMins(num) {
  var hours = Math.floor(num / 60);
  var minutes = num % 60;
  minutes = minutes > 9 ? "" + minutes : "0" + minutes;
  return hours + ":" + minutes;
}

export function explodeTime(time) {
  var arr = time.split(':');
  return arr[0] + "." + arr[1];
}

export function getCurrentTime(time, timezone) {
  return moment(time, "HH:mm:ss").tz(timezone).format("HH:mm:ss");
}

export function secondToHours(sec) {

  var date = new Date(null);
  date.setSeconds(sec);
  var hhmmssFormat = date.toISOString().substr(11, 8);
  return hhmmssFormat;
}

/**
 * Convert seconds into hh:mm:ss format.
 * 
 * Ex. `9000` in seconds is equal to `02:30:00`
 * @param {*} time Time in seconds.
 * @returns String - Time in "hh:mm:ss" format
 */
export function convertSecondsToHHMMSS(time) {
  var sec_num = parseInt(time, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

// get days between two dates
export function getDaysBetweenTwoDates(startDate, endDate) {
  var date1, date2;

  date1 = new Date(startDate);
  date2 = new Date(endDate);

  var res = Math.abs(date1 - date2) / 1000;

  // get total days between two dates
  return Math.floor(res / 86400);

}
export function convertH2M(timeInHour) {
  var timeParts = timeInHour.split(":");
  return Number(timeParts[0]) * 60 + Number(timeParts[1]);
}

export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  if (h < 10 && h >= 0) {
    h = "0" + h;
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (s < 10) {
    s = "0" + s;
  }

  var hDisplay = h > 0 ? h + ":" : "00:";
  var mDisplay = m > 0 ? m + ":" : "00:";
  var sDisplay = s > 0 ? s : "00";
  return hDisplay + mDisplay + sDisplay;
}

const dateFunctions = {
  timeZoneToUTC,
  utcToTimezone,
  utcToDateTimezone,
  convertToMinute,
  convertToHours,
  timeDiffMinute,
  dateDiff,
  convertMinutesToHoursMinutes,
  getMillisecond,
  convertMsToHM,
  padTo2Digits,
  convertToHoursMins,
  explodeTime,
  getCurrentTime,
  secondToHours,
  getDaysBetweenTwoDates,
  convertH2M,
  secondsToHms
};

export default dateFunctions;
