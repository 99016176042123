import { getWorkshopId } from "utils/getWorkshopId";
import {
  GET_UNASSIGNED_JOBS,
  GET_UNASSIGNED_JOBS_SUCCESS,
  GET_UNASSIGNED_JOBS_FAIL,
  GET_CUSTOMERS_LIST,
  GET_CUSTOMERS_LIST_SUCCESS,
  GET_CUSTOMERS_LIST_FAIL,
  GET_CUSTOMERANDVEHICLE_DETAILS,
  GET_CUSTOMERANDVEHICLE_DETAILS_SUCCESS,
  GET_CUSTOMERANDVEHICLE_DETAILS_FAIL,
  CREATE_JOB,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAIL,
  REGO_EXIST,
  REGO_EXIST_SUCCESS,
  REGO_EXIST_FAIL,
  GET_UNASSIGNED_JOBS_COUNT,
  GET_UNASSIGNED_JOBS_COUNT_SUCCESS,
  GET_UNASSIGNED_JOBS_COUNT_FAIL,
} from "./actionTypes";

export const getUnassignedJobs = () => {
  return {
    type: GET_UNASSIGNED_JOBS,
  };
};

export const getUnassignedJobsSuccess = (data) => {
  return {
    type: GET_UNASSIGNED_JOBS_SUCCESS,
    payload: data,
  };
};

export const getUnassignedJobsFail = (error) => {
  return {
    type: GET_UNASSIGNED_JOBS_FAIL,
    payload: error,
  };
};

export const getCustomerList = () => {
  return {
    type: GET_CUSTOMERS_LIST,
  };
};

export const getCustomerListSuccess = (data) => {
  return {
    type: GET_CUSTOMERS_LIST_SUCCESS,
    payload: data,
  };
};

export const getCustomerListFail = (error) => {
  return {
    type: GET_CUSTOMERS_LIST_FAIL,
    payload: error,
  };
};

export const getCustomerAndVehicleDetails = () => {
  return {
    type: GET_CUSTOMERANDVEHICLE_DETAILS,
  };
};

export const getCustomerAndVehicleDetailsSuccess = (data) => {
  return {
    type: GET_CUSTOMERANDVEHICLE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getCustomerAndVehicleDetailsFail = (error) => {
  return {
    type: GET_CUSTOMERANDVEHICLE_DETAILS_FAIL,
    payload: error,
  };
};

export const createJob = () => {
  return {
    type: CREATE_JOB,
  };
};

export const createJobSuccess = (data) => {
  return {
    type: CREATE_JOB_SUCCESS,
    payload: data,
  };
};

export const createJobFail = (error) => {
  return {
    type: CREATE_JOB_FAIL,
    payload: error,
  };
};

export const regoExist = () => {
  return {
    type: REGO_EXIST,
  };
};

export const regoExistSuccess = (data) => {
  return {
    type: REGO_EXIST_SUCCESS,
    payload: data,
  };
};

export const regoExistFail = (error) => {
  return {
    type: REGO_EXIST_FAIL,
    payload: error,
  };
};

export const getUnassignedJobsCount = () => {
  return {
    type: GET_UNASSIGNED_JOBS_COUNT,
  };
};

export const getUnassignedJobsCountSuccess = (data) => {
  return {
    type: GET_UNASSIGNED_JOBS_COUNT_SUCCESS,
    payload: data,
  };
};

export const getUnassignedJobsCountFail = (error) => {
  return {
    type: GET_UNASSIGNED_JOBS_COUNT_FAIL,
    payload: error,
  };
};
