import moment from 'moment-timezone/builds/moment-timezone-with-data';

export function curentWeekDateRange() {
    const weekDaysArr = [];
    for (let week=1; week <= 7; week++ ){
        weekDaysArr.push(moment().day(week).format('YYYY-MM-DD'))
    }
    return weekDaysArr;
}

export function curentMonthWeekRange() {
    let weekArr = [];
    var now = moment().day("Monday").format('YYYY-MM-DD');
    var nextnow = moment(now,'YYYY-MM-DD').day(7).format('YYYY-MM-DD');
    var day = {
        'Monday' : now,
        'Sunday':nextnow
    }
    weekArr.push(day);
    
    for (let week=1; week < 4; week++ ){
        now = moment(now,'YYYY-MM-DD').day(-6).format('YYYY-MM-DD');
        nextnow = moment(nextnow,'YYYY-MM-DD').day(-7).format('YYYY-MM-DD');
        // eslint-disable-next-line no-redeclare
        var day = {
            'Monday' : now,
            'Sunday':nextnow
        }
        weekArr.push(day);
    }
    return weekArr;
}

export function curentMonthRange() {
    let monthArr = [];
    const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment().clone().endOf('month').format('YYYY-MM-DD');
    var day = {
        'Monday' : startOfMonth,
        'Sunday' : endOfMonth
    }
    monthArr.push(day);

    const prevMonthOne = moment(startOfMonth).subtract(1, 'M').format('YYYY-MM-DD');
    const prevMonthOneEnd = moment(endOfMonth).subtract(1, 'M').format('YYYY-MM-DD');

    var day = {
        'Monday' : prevMonthOne,
        'Sunday' : prevMonthOneEnd
    }
    monthArr.push(day);

    const prevMonthTwo = moment(startOfMonth).subtract(2, 'M').format('YYYY-MM-DD');
    const prevMonthTwoEnd = moment(endOfMonth).subtract(2, 'M').format('YYYY-MM-DD');
    
    var day = {
        'Monday' : prevMonthTwo,
        'Sunday' : prevMonthTwoEnd
    }
    monthArr.push(day);

    return monthArr;
}

const dateRange = {
    curentWeekDateRange,
    curentMonthWeekRange,
    curentMonthRange,
};

export default dateRange;
