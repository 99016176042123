import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function SaveWorkAlert({ positiveAction }) {
  const handleClose = () => {
    positiveAction?.();
  };

  return (
    <Modal
      show={true}
      className="border-0"
      style={{ marginTop: 120 }}
      onHide={handleClose}
    >
      <Modal.Body className="p-4">
        <div className="d-flex justify-content-center flex-column text-center">
          <p className="h6 mt-2">
            You'll be automatically logged off at 11:59 PM!
          </p>
          <p className="mt-2">Please save your work now if needed!</p>
          <div className="mt-4 text-center">
            <Button
              variant="primary"
              style={{ minWidth: 100 }}
              onClick={handleClose}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
