import { useEffect, useMemo, useState } from "react";
import DailyClocking from "./dailyClocking/dailyClocking";
import JobClocking from "./jobClocking/jobClocking";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

let TimeClockingTab = () => {

  document.title = "5iQ | Time Log History";

  const [jobClockingTab, setJobClockingTab] = useState(false);
  const [dailyClockingTab, setDailyClockingTab] = useState(true);
  const { role_id } = useSelector((state) => state.LoginReducer);
  const { securities } = useSelector((state) => state.SecurityGroupReducer);
  
  const currentSecurity = useMemo(() => {
    let sec;
    if (securities && Object.keys(securities).length !== 0) {
      sec = securities[role_id];
    }
    sec = sec || {};
    sec.role_id = role_id;
    return sec;
  }, [securities, role_id]);

  return (
    <>

      <div className="right-section-wrapper job-detail-tab time-clocking-tab">

        <div className="">

          <div className="bottom-block col-md-12 align-end d-flex">

            <div className="col-md-3 px-0 table-header">
              <div className="table-title">
                <i style={{ color: "#61DDF2" }} className="fa fa-clock-o text-center text-primary font-size-30 m-r-10" aria-hidden="true"></i>
                <span>Employee Time Clocking</span>
              </div>
            </div>

            <div className="col-md-6 px-0" style={{ boxShadow: "0 0 22px 0 #d8d8d8" }}>
              <ul className="bg-white font-bold-600 font-size-13 stepper-list">
                <li
                  className={`${dailyClockingTab ? "active" : "inactive"
                    } ${(currentSecurity["'dailyclocking'"] || role_id == 2) ? "" : "disable-option"}`}
                  onClick={() => {
                    setDailyClockingTab(true);
                    setJobClockingTab(false);
                  }}
                >
                  <span>Daily Clocking</span>
                </li>
                <li
                  className={`${jobClockingTab ? "active" : "inactive"
                    } ${(currentSecurity["'jobclocking'"] || role_id == 2) ? "" : "disable-option"}`}
                  onClick={() => {
                    setJobClockingTab(true);
                    setDailyClockingTab(false);
                  }}
                >
                  <span>Job Clocking</span>
                </li>
              </ul>
            </div>

            <div className="col-md-3 px-0">
              <div className="video-link-outer">
                <div className="video-tutorial">
                  <i className="fa fa-icon"></i>
                  <Link to="/trainee-video#TimeLogHistory" ><span className="tutorial-title">Daily Clocking Videos</span></Link>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="stepper-content">

          {dailyClockingTab &&
            <div>
              <DailyClocking />
            </div>
          }

          {jobClockingTab &&
            <div>
              <JobClocking />
            </div>
          }

        </div>

      </div>


    </>
  );
};

export default TimeClockingTab;
