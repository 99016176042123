import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const TraineeVideo = Loadable({
  loader: () => import("./components/TraineeVideo"),
  loading: Loading,
});


export const routes = [
  {
    path: "/trainee-video",
    exact: true,
    name: "TraineeVideo",
    element: TraineeVideo,
  },
];

export * from "./components";
