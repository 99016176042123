import React, { useEffect } from "react";
import { showError, showSuccess } from "./alertMessage";
import Swal from "sweetalert2";

const DeleteModal = ({
  setShowDeleteModal,
  showDeleteModal,
  refreshData,
  msg,
  api,
  selectedRow,
  message,
  payloadData,
  icon,
  handleOutSide,
}) => {
  const handleClose = () => setShowDeleteModal(false);
  const deleteRecords = async () => {
    return api();
  };

  useEffect(() => {
    if (showDeleteModal) {
      Swal.fire({
        title: msg,
        text: "",
        icon: icon || "error",
        position: 'top',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: "#65cc88",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes",
        customClass: {
          container: 'clock-out-dialog',
        },
        preConfirm: () => {
          if (showDeleteModal) {
            deleteRecords();
            handleClose();
            return false;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) deleteRecords();
        else handleClose();
      });
    }

    return () => {
      Swal.close();
    };
  }, [showDeleteModal]);

  return "";
};
export default DeleteModal;
