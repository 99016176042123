import { apiCall } from "helpers/apiHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import { groupBy } from "lodash";
import { date_format, getCurrency } from "utils/getCurrency";
import moment from "moment";
import { customerRevenueReport, getJobBookedDetails, jobTypeCustomerReport, makeTypeCustomerReport } from "helpers/backendHelper";
import { addComma } from "utils/numberFunction";

/** Single call utilities */

// revunue functions START 
async function getCustomerAverageRevenueChartData({ start_date, end_date }) {

  const request = {
    'get': 'all',
      'conditions': {
          'workshop_id': getWorkshopId(),
          'job_status': 'Invoiced',
          'from_date >=': start_date,
          'from_date <=': end_date,
          'is_break_time': 0
        },
        'fields': {
            "parts": "sum(if(`parts_cost` != 0,`parts_cost`, 0))",
            "labours": "sum(if(`labour_cost` != 0,`labour_cost`, 0))"
        }
  };
  
  const chartResponse = await getJobBookedDetails({ payload: request });

  const revenueChart = chartResponse?.data?.JobSchedules[0];
  
  const data = [];

  let partsColor = "#"+Math.floor(Math.random()*16777215).toString(16);
  let labourColor = "#"+Math.floor(Math.random()*16777215).toString(16);
  
  const revenueSeriesData = [
    {
      "name": "Parts", 'y' : parseInt(revenueChart?.parts || 0), 'color' : partsColor
    },
    {
      "name": "Labours", 'y' : parseInt(revenueChart?.labours || 0), 'color' : labourColor
    } 
  ];

  data['revenueSeriesData'] = revenueSeriesData;

  
  let revenueTotal = +revenueChart?.parts || 0 + +revenueChart?.labours || 0;
  data['revenueTotal'] = `${getCurrency}${addComma(revenueTotal.toFixed(2))}`;

  return data;

}

async function getCustomerVistorChartData({ start_date, end_date }) {

  const request = {
    'get': 'all',
      'conditions': {
          'workshop_id': getWorkshopId(),
          'job_status': 'Invoiced',
          'is_deleted': '0',
          'from_date >=': start_date,
          'from_date <=': end_date,
        },
        'fields': {
            "visitors": "count(id)"
        }
  };
  
  
  const chartResponse = await getJobBookedDetails({ payload: request });

  const visitorChart = chartResponse?.data?.JobSchedules[0];
  
  const data = [];

  let visitorColor = "#"+Math.floor(Math.random()*16777215).toString(16);
  
  const visitorSeriesData = [
    {
      "name": "No of Visitors", 'y' : parseInt(visitorChart?.visitors || 0), 'color' : visitorColor
    }
  ];

  data['visitorSeriesData'] = visitorSeriesData;
  data['visitorTotal'] = `#${visitorChart?.visitors || 0}`;

  return data;

}


async function getCustomerVehicleChartData({ start_date, end_date }) {

  const request = {
      'workshop_id': getWorkshopId(),
      'start_date': start_date,
      'end_date': end_date,
  };
  
  
  const chartResponse = await makeTypeCustomerReport({ payload: request });

  
  const data = [];
  const seriesData = [];

  let vehicleRevenue = 0;
  chartResponse?.data?.JobSchedules?.forEach((customers) => {

    let makeColor = "#"+Math.floor(Math.random()*16777215).toString(16);

    vehicleRevenue = parseFloat(vehicleRevenue) + +customers?.total_revenue;
    const a = {};
    a['name'] = customers?.make;
    a['y'] = parseFloat(customers?.total_revenue);
    a['color'] = makeColor

    seriesData.push(a);

  });

  data['vehicleSeriesData'] = seriesData;
  data['vehicleRevenue'] = `${getCurrency}${addComma(vehicleRevenue.toFixed(2))}`;
  
  return data;

}

// revunue functions END 


// revenue by job START
async function getJobRevenueChartData({ start_date, end_date }) {

  const request = {
      'workshop_id': getWorkshopId(),
      'start_date': start_date,
      'end_date': end_date,
  };


  const chartResponse = await jobTypeCustomerReport({ payload: request });

  const job_revenue= [];
  const no_of_jobs= [];
  const categories= [];

  const data = [];
  
  chartResponse?.data?.JobSchedules?.forEach((jobs) => {

    job_revenue.push(+jobs?.avg_total || 0);
    no_of_jobs.push(+jobs?.no_of_invoice || 0);
    categories.push(jobs?.name);

  });

  const seriesData = [
    {
      "name": "Revenue", 'type' : "column", "yAxis": 1, "data": job_revenue, 
      'tooltip': {
        'valuePrefix': getCurrency,
        'valueDecimals': 2
      }
    },
    {
      "name": "#JOB", "type": "spline", "data": no_of_jobs
    }  
  ];

 
  data['categories'] = categories;
  data['seriesData'] = seriesData;
 
  return data;

}

async function getJobRevenueTableData({ start_date, end_date }) {

  const request = {
    'get':'all',
    'conditions': {
        'JobSchedules.workshop_id': getWorkshopId(),
        'JobSchedules.from_date >=': start_date,
        'JobSchedules.to_date <=': end_date,
        'JobSchedules.job_status':'Invoiced',
        'JobSchedules.is_deleted': '0'
    },
    'contain':{
        'WorkshopDepartmentsAccountsDetails': {
          'conditions': {
            'WorkshopDepartmentsAccountsDetails.workshop_id': getWorkshopId()
          },
        },
        'Customers.CustomersDetails':{
            'conditions': {
                'CustomersDetails.workshop_id': getWorkshopId()
            },
            'fields': [
                'CustomersDetails.user_id',
                'CustomersDetails.type',
                'CustomersDetails.company_name',
                'CustomersDetails.firstname',
                'CustomersDetails.lastname'
            ]
        },
    }
  };
  
  const tableResponse = await getJobBookedDetails({ payload: request });

  const revenueTable =
    tableResponse?.data?.JobSchedules?.map((job) => {

      let customer_name = job?.customer?.customers_details[0]?.type === '1' ? job?.customer?.customers_details[0]?.company_name : job?.customer?.customers_details[0]?.firstname+' '+job?.customer?.customers_details[0]?.lastname;
      
      return {
        id: job?.id,
        date: moment(job?.from_date).format(date_format),
        customer: customer_name,
        job_type: job?.workshop_departments_accounts_detail?.name,
        job_number: job?.job_number,
        total: +job?.total || 0.00
      };
    }) || [];

  return revenueTable;

  
}


// revenue by job END


// customer based revenue START

async function getCustomerRevenueChartData({ start_date, end_date, limit }) {

  const request = {
      'workshop_id': getWorkshopId(),
      'start_date': start_date,
      'end_date': end_date,
      'limit': limit,
  };


  const chartResponse = await customerRevenueReport({ payload: request });

  const data = [];

  const obj = {
    totalRevenue: [],
    totalJobs: [],
    customerData: [],
  }

  chartResponse?.data?.JobSchedules?.forEach((jobs) => {
    obj.totalRevenue.push(+jobs?.total_revenue || 0);
    obj.totalJobs.push(+jobs?.total_jobs || 0);

    let customer_name = jobs?.type === '1' ? jobs?.company_name : jobs?.customer;

    obj.customerData.push(customer_name || "");
  });


  const revenueTable =
    chartResponse?.data?.JobSchedules?.map((job) => {
      
      let customer_name = job?.type === '1' ? job?.company_name : job?.customer;

      return {
        id: job?.id,
        customer_id: job?.customer_id,
        customer: customer_name,
        mobile_number: job?.mobile_number,
        total_jobs: +job?.total_jobs || 0,
        total_revenue: +job?.total_revenue || 0
      };
    }) || [];


  data['chart'] = obj;
  data['table'] = revenueTable;

  return data;

}

// customer based revenue END

/** Single call utilities End */


// revenu per customer
export async function getCustomerAverageRevenue({ start_date, end_date }) {
  
  const revenueChart = await getCustomerAverageRevenueChartData({ start_date, end_date });
  const visitorChart = await getCustomerVistorChartData({ start_date, end_date });
  const vehicleChart = await getCustomerVehicleChartData({ start_date, end_date });

  return { revenueChart, visitorChart, vehicleChart };
}

// revenue by job

export async function getJobAverageRevenue({ start_date, end_date }) {
  
  
  const jobChart = await getJobRevenueChartData({ start_date, end_date });
  const jobTable = await getJobRevenueTableData({ start_date, end_date });
  
 
  return { jobChart, jobTable };
}

// customer based revenue

export async function getCustomerBasedRevenue({ start_date, end_date, limit }) {
  
  
  const cutomerRevenue = await getCustomerRevenueChartData({ start_date, end_date, limit });

  const customerChart = cutomerRevenue?.chart || [];
  const customerTable = cutomerRevenue?.table || [];

  return { customerChart, customerTable };
}
