import React from 'react'
import { payOption, activeCard } from './styles.module.scss';
const PayOptions = ({ cards, activeSelection, onClick }) => {
    return (
        <div className={payOption}>
            {cards.map((card,index) => (
                <div onClick={() => {
                    if (onClick) {
                        onClick(card.value, index, card.dbValue,card.processing_fees)
                    }
                }} className={activeSelection === card.value ? activeCard : ''} key={index+"_"+card?.value}>
                    <img src={card.img} alt={card.value} />
                </div>
            ))}
        </div>
    )
}

export default PayOptions