import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteStatementInvoice, updateInvoiceStatements } from "store/invoices/actions";
import { getInvoiceStatementAPI } from "store/invoices/api";
import Loader from "views/common/UI/Loader";
import CommonSecond from "./CommonSecond";
import { useInvoiceColumns } from "./useInvoiceColumns";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useCallback } from "react";
import { getCustomerLists } from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import { GET_INVOICES_STATEMENTS } from "store/invoices/actionTypes";
import moment from "moment";
import { apiCall } from "helpers/apiHelper";
import { UPDATE_INVOICE_STATEMENTS } from "helpers/urlHelper";

const InvoiceStatement = () => {
  const invoice = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  const [deletePayload, setDeletePayload] = useState(null);

  const navigate = useNavigate();

  const { invoiceStatementsColumns } = useInvoiceColumns({
    onView: ({ row }) => {
      navigate(`/edit-statement-invoice/${row.id}`);
    },
    onMail: ({ row }) => {
      navigate(`/statement_email/${row.id}`);
    },
    onPrint: ({ row }) => {
      updatePrintStatus(row.id);
      navigate(`/statement_print/${row.id}?print=true`);
    },
    onDelete: ({ row }) => {
      //Delete
      setDeletePayload([row]);
    },
  });

  async function updatePrintStatus(id) {
    // const payloadNew = {
    //   statement_status: "5",
    //   id: id,
    // };
    await apiCall(UPDATE_INVOICE_STATEMENTS, {
      payload: {
        statement_status: "5",
        id: id,
      }
    }, "POST", true);
    // dispatch(updateInvoiceStatements(payloadNew));
  }

  const deleteEvent = (cb) => {
    const payload = {
      action_type: "delete",
      ids: deletePayload.map((c) => c.id),
    };
    dispatch(deleteStatementInvoice(payload));
    cb();
  };
  const [activeTab, setActiveTab] = useState("statement");
  const [customersListState, setCustomersListState] = useState({
    isLoading: false,
    data: [],
    isError: false,
    error: undefined,
    selected: undefined,
    selected_user_id: undefined
  });


  const [state, setState] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setState({ start, end });
  };
  const label = start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY");
  const range = {
    "Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
    "Last 60 Days": [moment().subtract(59, "days").toDate(), moment().toDate()],
    "Last 90 Days": [moment().subtract(89, "days").toDate(), moment().toDate()],
    Annual: [moment().subtract(1, "year").toDate(), moment().toDate()],
  };

  useEffect(() => {
    const listRequest = {
      get: "all",
      conditions: {
        "InvoiceStatement.workshop_id": getWorkshopId(),
        // "InvoiceStatement.user_id" :customersListState.selected_user_id
        "InvoiceStatement.created_on >=": moment(start).format('YYYY-MM-DD'),
        "InvoiceStatement.created_on <": moment(end).add(1, 'days').format('YYYY-MM-DD')
      },
      contain: {
        'Users.CustomersDetails': {}
      },
      order: {
        "InvoiceStatement.id": "DESC"
      }
    };

    dispatch({ type: GET_INVOICES_STATEMENTS, payload: { listRequest } });
    // },[customersListState?.selected_user_id])
  }, [start, end])

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshopId = window.atob(storedData.workshop_id);

  // const searchCustomer = useCallback(
  //   async (input) => {
  //     if (!(input.length > 3)) return;
  //     const strs = input.split(" ");
  //     if (!Array.isArray(strs) || !(strs.length > 0)) return;

  //     // Prepare or conditions
  //     const orPayload = {
  //       "CustomersDetails.firstname LIKE ": `%${strs[0]}%`,
  //       "Users.email LIKE ": `%${strs[0]}%`,
  //       "CustomersDetails.mobile_number LIKE ": `%${strs[0]}%`,
  //       // "CustomersDetails.company_name LIKE ": `%${strs[0]}%`,
  //     };

  //     // If strs length is greater than 1 that means everything after 0th index will be considered as last name.
  //     if (strs.length > 1) {
  //       orPayload["CustomersDetails.lastname LIKE "] = strs.join(" ");
  //     }

  //     const payload = {
  //       get: "all",
  //       fields: [
  //         "CustomersDetails.id",
  //         "CustomersDetails.user_id",
  //         "CustomersDetails.type",
  //         "CustomersDetails.firstname",
  //         "CustomersDetails.lastname",
  //         "CustomersDetails.company_name",
  //         "CustomersDetails.mobile_number",
  //         "CustomersDetails.mobile_code",
  //         "CustomersDetails.phone_number",
  //         "CustomersDetails.customer_app",
  //         "CustomersDetails.postcode",
  //         "CustomersDetails.suburb",
  //         "CustomersDetails.street_address",
  //         "CustomersDetails.abn",
  //         "CustomersDetails.position",
  //         "CustomersDetails.website",
  //       ],
  //       order: {
  //         "CustomersDetails.firstname": "asc",
  //       },
  //       conditions: {
  //         OR: orPayload,
  //         "CustomersDetails.workshop_id": workshopId,
  //         role_id: 4,
  //         "CustomersDetails.is_deleted": 0,
  //         "CustomersDetails.status": 1,
  //       },
  //       contain: {
  //         Users: {
  //           fields: ["id", "workshop_id", "email"],
  //         },
  //         "Users.States": {
  //           fields: ["id", "name"],
  //         },
  //         "Users.Countries": {
  //           fields: ["id", "country_name"],
  //         },
  //         "Users.Vehicles": {
  //           fields: [
  //             "Vehicles.id",
  //             "Vehicles.user_id",
  //             "Vehicles.rego",
  //             "Vehicles.make",
  //             "Vehicles.model",
  //             "Vehicles.year",
  //             "Vehicles.fleet_number",
  //           ],
  //           conditions: {
  //             "Vehicles.workshop_id": workshopId,
  //             "Vehicles.is_deleted": 0,
  //           },
  //         },
  //       },
  //     };

  //     try {
  //       setCustomersListState((prev) => ({
  //         ...prev,
  //         isLoading: true,
  //         error: undefined,
  //         isError: false,
  //       }));
  //       const response = await getCustomerLists({
  //         payload,
  //       });
  //       // console.log(response);
  //       setCustomersListState((prev) => ({
  //         ...prev,
  //         isLoading: false,
  //         data: response.data.CustomersDetails,
  //       }));
  //     } catch (error) {
  //       console.error(error);
  //       setCustomersListState((prev) => ({
  //         ...prev,
  //         isLoading: false,
  //         error: error,
  //         isError: true,
  //       }));
  //     }
  //   },
  //   [workshopId]
  // );
  const [invoiceSum, setInvoiceSum] = useState(0);
  useEffect(() => {
    if (invoice?.invoiceStatements?.list) {
      let invoiceTotal = 0;
      (invoice?.invoiceStatements?.list).map((statementTotal) => {
        invoiceTotal = parseFloat(statementTotal.statement_total) + parseFloat(invoiceTotal);
      })
      setInvoiceSum(invoiceTotal)
    }
  }, [invoice?.invoiceStatements])

  return (
    <>
      {invoice?.invoiceStatements?.invoiceDeleteStatus === "started" && <Loader />}
      <Tab.Container
        defaultActiveKey={"statement"}
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
      >
        {/* <div className="d-flex justify-center row top-block">
            <div className="col-md-8">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="statement">
                    Statement
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vehicle-report">
                    Vehicle Report
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div> */}
        {/* <div className="d-flex justify-content-end align-items-center flex-nowrap gap-3">
            <div className="">
              <AsyncTypeahead
                id="customer-selection"
                isLoading={customersListState.isLoading}
                labelKey={(option) =>
                  option.company_name
                    ? `${option.company_name} (${option.user.email})`
                    : `${option.firstname} ${option.lastname} (${option.user.email})`
                }
                onChange={(selected) => {
                  if (selected.length > 0) {
                    // setCustomerVehicles(selected[0].user?.vehicles ?? []);
                    setCustomersListState((prev) => ({
                      ...prev,
                      selected: selected[0].id,
                      selected_user_id: selected[0].user_id,
                    }));
                  } else {
                    setCustomersListState((prev) => ({
                      ...prev,
                      selected: undefined,
                      selected_user_id: undefined,
                    }));
                    // setCustomerVehicles([]);
                  }
                }}
                onSearch={(query) => searchCustomer(query)}
                options={customersListState.data}
                useCache={true}
                placeholder="Search customer..."
              />
            </div>
          </div> */}
        <Tab.Content>
          <Tab.Pane eventKey="statement">
            <CommonSecond
              deletePayload={deletePayload}
              setDeletePayload={setDeletePayload}
              api={getInvoiceStatementAPI}
              columns={invoiceStatementsColumns}
              main={invoice?.invoiceStatements}
              // onCreateCashSale={() => { }}
              onStatementDate="statement"
              onRowClick={(row) => {
                navigate(`/edit-statement-invoice/${row.id}`);
              }}
              onSearch={(e) => {}}
              completed={true}
              deleteInvoices={deleteEvent}
              startdate={start}
              enddate={end}
              label={label}
              handleCallback={handleCallback}
              range={range}
              statementTotal={invoiceSum}
              statementDeleteMsg={"Delete Statement Records?"}
              // invoiceListType={"5"}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="vehicle-report">

          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default InvoiceStatement;
