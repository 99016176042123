import { takeLatest, put, call, delay, throttle } from "redux-saga/effects";
import {
  SAVE_PART,
  GET_PART_LIST,
  DELETE_PART,
  TEST_PART,
  TEST_PART_SUCCESS,
  GET_PART_LIST_NEW,
  GET_PART_LIST_COUNT,
  SEARCH_PART_LIST,
  GET_PART_CATEGORY_LIST,
  GET_KIT_PART_LIST,
  GET_KIT_PART_LIST_COUNT,
  SEARCH_KIT_PART_LIST,
  EDIT_PART,
  SAVE_PART_REACT,
  PART_VIEW,
  EDIT_PART_REACT,
  PART_DETAIL,
  ADD_INITIAL_PARTS,
  ADD_INITIAL_LABOURS,
  DELETE_KIT_PART,
} from "./actionTypes";
import {
  savePartSuccess,
  savePartFail,
  getPartListSuccess,
  getPartListFail,
  deletePartSuccess,
  deletePartFail,
  getPartListCountSuccess,
  getPartListCountFail,
  getSearchPartListSuccess,
  getSearchPartListFail,
  getPartCategoryListSuccess,
  getPartCategoryListFail,
  getKitPartListCountSuccess,
  getKitPartListSuccess,
  getKitPartListFail,
  getKitPartListCountFail,
  getKitSearchPartListSuccess,
  getKitSearchPartListFail,
  editPartSuccess,
  editPartFail,
  savePartReactSuccess,
  savePartReactFail,
  partViewSuccess,
  partViewFail,
  editPartReactSuccess,
  editPartReactFail,
  partDetailSuccess,
  partDetailFail,
  deleteKitPartSuccess,
  deleteKitPartFail,
} from "./actions";

import {
  savePart,
  getPartList,
  deletePart,
  getSearchPartList,
  partCategoryList,
  getSearchKitPartList,
  editPart,
  savePartReact,
  viewPart,
  editPartReact,
  editDeleteKitPart,
} from "../../helpers/backendHelper";
import { showError } from "../../utils/alertMessage";
import { getWorkshopId } from "utils/getWorkshopId";

// save part
function* onGetSavePart(data) {
  try {
    const response = yield call(savePart, data);
    if (response) {
      if (response.code === 200) {
        yield put(savePartSuccess(response.data.Products));
      } else {
        yield put(savePartFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(savePartFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(savePartFail(error.response));
  }
}

// save part
function* onGetEditPart(data) {
  try {
    const response = yield call(editPart, data);
    if (response) {
      if (response.code === 200) {
        yield put(editPartSuccess(response));
      } else {
        yield put(editPartFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(editPartFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editPartFail(error.response));
  }
}

// save part
function* onGetSavePartReact(data) {
  try {
    const response = yield call(savePartReact, data);
    if (response) {
      if (response.code === 200) {
        yield put(savePartReactSuccess(response.data.Products));
      } else {
        yield put(savePartReactFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(savePartReactFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(savePartReactFail(error.response));
  }
}
// save part

// edit part
function* onGetEditPartReact(data) {
  try {
    const response = yield call(editPartReact, data);
    if (response) {
      if (response.code === 200) {
        yield put(editPartReactSuccess(response.data.Products));
      } else {
        yield put(editPartReactFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(editPartReactFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editPartReactFail(error.response));
  }
}
// edit part

function* onGetViewReact(data) {
  try {
    const response = yield call(viewPart, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(partViewSuccess(response));
      } else {
        yield put(partViewFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(partViewFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(partViewFail(error.response));
  }
}

// get part list or single part
function* onGetPartList(data) {
  try {
    const response = yield call(getPartList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getPartListSuccess(response.data.Products));
      } else {
        yield put(getPartListFail(response.message));
      }
    } else {
      yield put(getPartListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getPartListFail(error.response));
  }
}

// get part list or single part
function* onGetPartListCount(data) {
  try {
    const response = yield call(getPartList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getPartListCountSuccess(response.data.Products[0].COUNT));
      } else {
        yield put(getPartListCountFail(response.message));
      }
    } else {
      yield put(getPartListCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getPartListCountFail(error.response));
  }
}

// get part list or single part
function* onSearchPartList(data) {
  try {
    const response = yield call(getSearchPartList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSearchPartListSuccess(response.data.Products));
      } else {
        yield put(getSearchPartListFail(response.message));
      }
    } else {
      yield put(getSearchPartListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSearchPartListFail(error.response));
  }
}

//GET PART LIST NEW
function* onGetPartListNew(data) {
  if (data.payload.q) {
    try {
      const response = yield call(getPartList, data);
      if (response) {
        if (response.code === 200) {
          yield put(getPartListSuccess(response.data.Products));
        } else {
          yield put(getPartListFail(response.message));
        }
      } else {
        yield put(getPartListFail("Didn't get api response"));
      }
    } catch (error) {
      yield put(getPartListFail(error.response));
    }
  } else {
    yield put(getPartListSuccess(null));
  }
}

// delete part
function* onGetDeletePart(data) {
  try {
    const response = yield call(deletePart, data);
    if (response) {
      if (response.code === 200) {
        yield put(deletePartSuccess(response.data.Products));
      } else {
        yield put(deletePartFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(deletePartFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(deletePartFail(error.response));
  }
}

// delete kit part
function* onGetDeleteKitPart(data) {
  try {
    const response = yield call(editDeleteKitPart, data);
    if (response) {
      if (response.code === 200) {
        yield put(deleteKitPartSuccess(response.data.Products));
      } else {
        yield put(deleteKitPartFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(deleteKitPartFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(deleteKitPartFail(error.response));
  }
}


function* onGetPartCategoryList(data) {
  try {
    const response = yield call(partCategoryList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getPartCategoryListSuccess(response));
      } else {
        yield put(getPartCategoryListFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getPartCategoryListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getPartCategoryListFail(error.response));
  }
}

// get part list or single part
function* onGetKitPartList(data) {
  try {
    const response = yield call(getPartList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getKitPartListSuccess(response.data.Products));
      } else {
        yield put(getKitPartListFail(response.message));
      }
    } else {
      yield put(getKitPartListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getKitPartListFail(error.response));
  }
}

// get part list or single part
function* onGetKitPartListCount(data) {
  try {
    const response = yield call(getPartList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getKitPartListCountSuccess(response.data.Products[0].COUNT));
      } else {
        yield put(getKitPartListCountFail(response.message));
      }
    } else {
      yield put(getKitPartListCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getKitPartListCountFail(error.response));
  }
}

// get part list or single part
function* onKitSearchPartList(data) {
  try {
    const response = yield call(getSearchKitPartList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getKitSearchPartListSuccess(response.data.Products));
      } else {
        yield put(getKitSearchPartListFail(response.message));
      }
    } else {
      yield put(getKitSearchPartListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getKitSearchPartListFail(error.response));
  }
}

// get part list or single part
function* onPartDetail(data) {
  try {
    const response = yield call(getPartList, data);
    if (response) {
      if (response.code === 200) {
        const [kit] = response.data.Products;
        let part_kit_data_list = kit.kit_parts_details_a.filter((c) => c.is_deleted !== "1");
        const savePartsPayload =
          part_kit_data_list?.map((part, i) => ({
            cost_price: part.cost,
            main_id: part.id,
            gst_percentage: "",
            gst_price: part.gst_price,
            id: part?.product_id || undefined,
            kit_id: part?.id,
            gst_total:part.gst_total,
            is_gst: part.gst_price > 0 ? 1 : 0,
            is_kit: 0,
            is_misc_part: 0,
            label: part?.product?.name,
            margin: part.margin,
            margin_markup: 0,
            more: "",
            part_name: part?.product?.name,
            part_number: part?.product?.product_number,
            qty: part?.qty,
            retail_price: part?.retail,
            sell_price: part.sell,
            sell_total: part.sell_total,
            product_id: part.product_id,
            total: parseFloat(part?.retail) * parseInt(part?.qty),
            price: parseFloat(part?.retail) * parseInt(part?.qty),
            workshop_id: getWorkshopId(),
          })) || [];

        yield put({
          type: ADD_INITIAL_PARTS,
          payload: savePartsPayload,
        });

        const saveKitsPayload =
          kit.kit_labours_details?.map((part, i) => ({
            id: part?.id || undefined,
            labour_charges_id: part?.labour_charge_id,
            workshop_id: getWorkshopId(),
            labour_description: part?.description,
            cost_price: part?.cost_price,
            retail_price: part?.retail_price,
            sell_price: part?.sell_price,
            profit: part?.profit,
            total_price: part?.total_price,
            labour_ph: part?.qty_hours.replace(":", "."),
            labour_time: part?.qty_hours,
            // priority: 0,
            // is_inspection: "0",
            // is_carried_over_labour: "0",
            // labour_charge: {
            //   is_default: "0",
            //   modified: "2022-05-27T06:51:04",
            //   id: parseInt(Math.random() * 1000),
            // },
            modified: part?.modified,
          })) || [];

        yield put({
          type: ADD_INITIAL_LABOURS,
          payload: saveKitsPayload,
        });

        yield put(partDetailSuccess(response.data.Products));
      } else {
        yield put(partDetailFail(response.message));
      }
    } else {
      yield put(partDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(partDetailFail(error.response));
  }
}

function* onTestPart(action) {
  yield delay(5000);
  yield put({ type: TEST_PART_SUCCESS, payload: "Hello World" });
}

function* PartSaga() {
  yield takeLatest(SAVE_PART, onGetSavePart);
  yield takeLatest(EDIT_PART, onGetEditPart);
  yield takeLatest(SAVE_PART_REACT, onGetSavePartReact);
  yield takeLatest(EDIT_PART_REACT, onGetEditPartReact);
  yield takeLatest(PART_VIEW, onGetViewReact);
  // yield throttle(1750, GET_PART_LIST_NEW, onGetPartListNew);
  yield takeLatest(GET_PART_LIST_NEW, onGetPartListNew);
  yield takeLatest(GET_PART_LIST, onGetPartList);
  yield takeLatest(GET_PART_LIST_COUNT, onGetPartListCount);
  yield throttle(1500, SEARCH_PART_LIST, onSearchPartList);
  yield takeLatest(GET_PART_CATEGORY_LIST, onGetPartCategoryList);

  yield takeLatest(GET_KIT_PART_LIST, onGetKitPartList);
  yield takeLatest(GET_KIT_PART_LIST_COUNT, onGetKitPartListCount);
  yield throttle(1500, SEARCH_KIT_PART_LIST, onKitSearchPartList);

  yield takeLatest(DELETE_PART, onGetDeletePart);
  yield takeLatest(DELETE_KIT_PART, onGetDeleteKitPart);
  yield takeLatest(TEST_PART, onTestPart);

  yield takeLatest(PART_DETAIL, onPartDetail);
}

export default PartSaga;
