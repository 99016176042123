
export const GET_STRIPE_PAYMENT_INTEGRATION = "GET_STRIPE_PAYMENT_INTEGRATION";
export const GET_STRIPE_PAYMENT_INTEGRATION_SUCCESS = "GET_STRIPE_PAYMENT_INTEGRATION_SUCCESS";
export const GET_STRIPE_PAYMENT_INTEGRATION_FAIL = "GET_STRIPE_PAYMENT_INTEGRATION_FAIL";

export const SAVE_STRIPE_PAYMENT_INTEGRATION = "SAVE_STRIPE_PAYMENT_INTEGRATION";
export const SAVE_STRIPE_PAYMENT_INTEGRATION_SUCCESS = "SAVE_STRIPE_PAYMENT_INTEGRATION_SUCCESS";
export const SAVE_STRIPE_PAYMENT_INTEGRATION_FAIL = "SAVE_STRIPE_PAYMENT_INTEGRATION_FAIL";


export const GET_GOPAY_PAYMENT_INTEGRATION = "GET_GOPAY_PAYMENT_INTEGRATION";
export const GET_GOPAY_PAYMENT_INTEGRATION_SUCCESS = "GET_GOPAY_PAYMENT_INTEGRATION_SUCCESS";
export const GET_GOPAY_PAYMENT_INTEGRATION_FAIL = "GET_GOPAY_PAYMENT_INTEGRATION_FAIL";

export const SAVE_GOPAY_PAYMENT_INTEGRATION = "SAVE_GOPAY_PAYMENT_INTEGRATION";
export const SAVE_GOPAY_PAYMENT_INTEGRATION_SUCCESS = "SAVE_GOPAY_PAYMENT_INTEGRATION_SUCCESS";
export const SAVE_GOPAY_PAYMENT_INTEGRATION_FAIL = "SAVE_GOPAY_PAYMENT_INTEGRATION_FAIL";