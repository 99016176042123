import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_ASSIGNED_JOBS,
  GET_ASSIGNED_JOBS_COUNT,
  GET_JOB_CARD,
} from "./actionTypes";
import {
  getassignedJobsSuccess,
  getassignedJobsFail,
  getassignedJobsCountSuccess,
  getassignedJobsCountFail,
  getJobCardSuccess,
  getJobCardFail,
} from "./actions";

import { getassignedJobs, printJobCardPdf } from "../../helpers/backendHelper";

function* onGetassignedJobs(data) {
  try {
    const response = yield call(getassignedJobs, data);
    if (response) {
      if (response.code === 200) {
        yield put(getassignedJobsSuccess(response.data.JobSchedules));
      } else {
        yield put(getassignedJobsFail(response.message));
      }
    } else {
      yield put(getassignedJobsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getassignedJobsFail(error.response));
  }
}

function* onGetassignedJobsCount(data) {
  try {
    const response = yield call(getassignedJobs, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getassignedJobsCountSuccess(response.data.JobSchedules[0].COUNT)
        );
      } else {
        yield put(getassignedJobsCountFail(response.message));
      }
    } else {
      yield put(getassignedJobsCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getassignedJobsCountFail(error.response));
  }
}

function* onGetJobCard(data) {
  try {
    const response = yield call(printJobCardPdf, data);
    if (response) {
      if (response.code === 200) {
        yield put(getJobCardSuccess(response.data.JobSchedules));
      } else {
        yield put(getJobCardFail(response.message));
      }
    } else {
      yield put(getJobCardFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobCardFail(error.response));
  }
}

function* assignedJobsSaga() {
  yield takeLatest(GET_ASSIGNED_JOBS, onGetassignedJobs);
  yield takeLatest(GET_ASSIGNED_JOBS_COUNT, onGetassignedJobsCount);
  yield takeLatest(GET_JOB_CARD, onGetJobCard);
}

export default assignedJobsSaga;
