import { getHQWorkshopList, updateUserProfile } from "helpers/backendHelper";
import { call, debounce, put, takeLatest } from "redux-saga/effects";
import { GET_HQ_WORKSHOP_LIST, GET_HQ_WORKSHOP_LIST_COUNT, SET_HQ_WORKSHOP_SIGN_IN_TOKEN } from "./actionTypes";
import { getHQWorkshopListCountFail, getHQWorkshopListCountSuccess, getHQWorkshopListFail, getHQWorkshopListSuccess, setHQWorkshopSigninTokenFail, setHQWorkshopSigninTokenSuccess } from "./actions";

/**
 * Triggers on GET_HQ_WORKSHOP_LIST event.
 * @param {*} data Request payload
 */
function* onGetWorkshopList(data) {
    try {
        const response = yield call(getHQWorkshopList, data);
        if (response) {
            if (response.code === 200) {
                yield put(getHQWorkshopListSuccess(response?.data?.Workshops ?? []));
            } else {
                yield put(getHQWorkshopListFail(response.message));
            }
        } else {
            yield put(getHQWorkshopListFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getHQWorkshopListFail(error.response));
    }
}

/**
 * Triggers on GET_HQ_WORKSHOP_LIST_COUNT event
 * @param {*} data Request payload
 */
function* onGetWorkshopCount(data) {
    try {
        const response = yield call(getHQWorkshopList, data);
        if (response) {
            if (response.code === 200) {
                const workshops = response?.data?.Workshops
                if (workshops && Array.isArray(workshops)) {
                    yield put(getHQWorkshopListCountSuccess(workshops.shift()?.COUNT ?? 0));
                } else {
                    yield put(getHQWorkshopListCountFail("Received invalid response from server"));
                }
            } else {
                yield put(getHQWorkshopListCountFail(response.message));
            }
        } else {
            yield put(getHQWorkshopListCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getHQWorkshopListCountFail(error.response));
    }
}

function* onSetWorkshopSigninWithToken(data) {
    try {
        const response = yield call(updateUserProfile, data);
        if (response) {
            if (response.code === 200) {
                yield put(setHQWorkshopSigninTokenSuccess(response));
            } else {
                yield put(setHQWorkshopSigninTokenFail(response.message));
            }
        } else {
            yield put(setHQWorkshopSigninTokenFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(setHQWorkshopSigninTokenFail(error.response));
    }
}



export default function* HQWorkshopListSaga() {
    yield debounce(1000, GET_HQ_WORKSHOP_LIST, onGetWorkshopList)
    yield debounce(1000, GET_HQ_WORKSHOP_LIST_COUNT, onGetWorkshopCount)
    yield takeLatest(SET_HQ_WORKSHOP_SIGN_IN_TOKEN, onSetWorkshopSigninWithToken)
}