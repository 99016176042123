import { getWorkshopId } from "utils/getWorkshopId";
import moment from "moment";
import {
  GET_SCHEDULED_MARKETING_LIST,
  GET_LIVE_MARKETING_LIST,
  GET_COMPLETED_MARKETING_LIST,
  DELETE_SCHEDULED_MARKETING_LIST,
  DELETE_LIVE_MARKETING_LIST,
  DELETE_COMPLETED_MARKETING_LIST
} from "./actionTypes";
import { timeZoneToUTC } from "utils/timeZoneConvert";

// SCHEDULED MARKETING

export const getScheduledMarketingListPayload = (fetchWithHQId = false) => {

  const todayDate = moment();


  //fetch session storage data
  const storedData = JSON.parse(sessionStorage.getItem('stored_data'));

  //workshop id
  let workshop_id = window.atob(typeof fetchWithHQId === 'boolean' && fetchWithHQId === true ? storedData.hq_id : storedData.workshop_id);

  const dateFormat = storedData.date_format;
  let timezoneLable = window.atob(storedData.timezone_lable);

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);
    return responseDate;
  }

  let start_date = timeZoneToUTC(
    moment().format("YYYY-MM-DD"),
    timezoneLable,
    "",
    "YYYY-MM-DD"
  );

  let time_start = timeZoneToUTC(
    moment().format("YYYY-MM-DD HH:mm:ss"),
    timezoneLable,
    "",
    "HH:mm:ss"
  );

  const listRequest = {
    get: "all",
    conditions: {
      [typeof fetchWithHQId === 'boolean' && fetchWithHQId === true ? "head_quarters_id" : "workshop_id"]: workshop_id,
      "compaign_type !=": "Referral",
      status: 1,
      is_deleted: "0",
      "date_start >=": start_date,
      // "time >=": time_start,
      "notification_status": "new",
      "is_immediate": "0"
    },
    order: {
      id: "desc",
    },
    fields: [
      "id",
      "workshop_id",
      "job_department_id",
      "compaign_type",
      "name",
      "date_start",
      "date_end",
      "offer_type",
      "status",
      "offer",
      "inbox_message",
      "message_text",
      "time",
      "text_messages",
      "email"
    ],
  };

  return {
    type: GET_SCHEDULED_MARKETING_LIST,
    payload: { marketingListRequest: listRequest },
    // payload: listRequest,
  };
};

// get sheduled list
export const getScheduledMarketingListAPI =
  (state) => (dispatch) => (workshop_id) => {
    dispatch(getScheduledMarketingListPayload(workshop_id));
  };
// SCHEDULED MARKETING

//DELETE SCHEDULED MARKETING
export const deleteArchiveScheduledMarketingListAPI = (dispatch) => (payload) => {
  dispatch({
    type: DELETE_SCHEDULED_MARKETING_LIST,
    payload: payload,
  });
};

//DELETE SCHEDULED MARKETING



// LIVE MARKETING

export const getLiveMarketingListPayload = (fetchWithHQId) => {
  const useHqId = typeof fetchWithHQId === 'boolean' && fetchWithHQId === true
  //fetch session storage data
  const storedData = JSON.parse(sessionStorage.getItem('stored_data'));

  //workshop id
  let workshop_id = window.atob(useHqId ? storedData.hq_id : storedData.workshop_id);

  const listRequest = {
    get: "all",
    conditions: {
      [useHqId ? 'head_quarters_id' : 'workshop_id']: workshop_id,
      "compaign_type NOT IN": ["Referral", "Broadcast"],
      status: "1",
      is_deleted: "0",
      AND: {
        "date_start <=": moment().format("YYYY-MM-DD 00:00:00"),
        "date_end >=": moment().format("YYYY-MM-DD 00:00:00"),
      },
    },
    contain: {
      CronJobData: {
        conditions: {
          OR: {
            msg_status: "send",
            email_status: "send",
            notification_status: "send",
            OR: {
              notification_status: "not_required",
              msg_status: "not_required",
              email_status: "not_required",
            },
          },
        },
        fields: ["id", "marketing_id", "job_id"],
      },
      "CronJobData.JobSchedules": {
        conditions: {
          [useHqId ? "JobSchedules.head_quarters_id" : "JobSchedules.workshop_id"]: workshop_id,
        },
        fields: {
          0: "JobSchedules.id",
          2: "JobSchedules.discount_code",
          3: "JobSchedules.discount_code_used",
        },
      },
    },
    order: {
      id: "desc",
    },
    fields: [
      "id",
      "workshop_id",
      "compaign_type",
      "name",
      "code",
      "offer_type",
      "date_start",
      "date_end",
      "offer",
      "status",
      "job_department_id",
      "inbox_message",
      "message_text",
      "email",
      "text_messages"
    ],
  };

  return {
    type: GET_LIVE_MARKETING_LIST,
    payload: { liveMarketingListRequest: listRequest },
    // payload: listRequest,
  };
};

// get LIVE list
export const getLiveMarketingListAPI =
  (state) => (dispatch) => (workshop_id) => {
    dispatch(getLiveMarketingListPayload(workshop_id));
  };

// LIVE MARKETING

//DELETE LIVE MARKETING
export const deleteArchiveLiveMarketingListAPI = (dispatch) => (payload) => {
  dispatch({
    type: DELETE_LIVE_MARKETING_LIST,
    payload: payload,
  });
};
//DELETE LIVE MARKETING

// COMPLETED MARKETING

export const getCompletedMarketingListPayload = (fetchWithHQId) => {
  const useHqId = typeof fetchWithHQId === 'boolean' && fetchWithHQId === true
  //fetch session storage data
  const storedData = JSON.parse(sessionStorage.getItem('stored_data'));

  //workshop id
  let workshop_id = window.atob(useHqId ? storedData.hq_id : storedData.workshop_id);

  const listRequest = {
    get: "all",
    conditions: {
      // status: 1,
      [useHqId ? 'head_quarters_id' : 'workshop_id']: workshop_id,
      "compaign_type !=": "Referral",
      // is_deleted: "0",
      "notification_status": "send",
      OR: {
        "date_end <=": moment().format("YYYY-MM-DD"),
        status: '0',
        is_deleted: '1',
      },
    },
    contain: {
      CronJobData: {
        conditions: {
          OR: {
            msg_status: "send",
            email_status: "send",
            notification_status: "send",
            OR: {
              notification_status: "not_required",
              msg_status: "not_required",
              email_status: "not_required",
            },
          },
        },
        fields: ["id", "marketing_id"],
      },
      "CronJobData.JobSchedules": {
        conditions: {
          [useHqId ? "JobSchedules.head_quarters_id" : "JobSchedules.workshop_id"]: workshop_id,
        },
        fields: {
          0: "JobSchedules.id",
          2: "JobSchedules.discount_code",
          3: "JobSchedules.discount_code_used",
        },
      },
    },
    order: {
      id: "desc",
    },
    fields: [
      "id",
      "workshop_id",
      "date_start",
      "date_end",
      "offer",
      "offer_type",
      "compaign_type",
      "name",
      "job_department_id",
      "status",
      "text_messages",
      "email"
    ],
  };

  return {
    type: GET_COMPLETED_MARKETING_LIST,
    payload: { completedMarketingListRequest: listRequest },
    // payload: listRequest,completedMarketingListRequest
  };
};

// get LIVE list
export const getCompletedMarketingListAPI =
  (state) => (dispatch) => (workshop_id) => {
    dispatch(getCompletedMarketingListPayload(workshop_id));
  };
// COMPLETED MARKETING

//DELETE LIVE MARKETING
export const deleteArchiveCompletedMarketingListAPI = (dispatch) => (payload) => {
  dispatch({
    type: DELETE_COMPLETED_MARKETING_LIST,
    payload: payload,
  });
};
  //DELETE LIVE MARKETING
