import { takeLatest, put, call, throttle, debounce } from "redux-saga/effects";
import {
  GET_UPCOMING_LIST,
  GET_OVERDUE_LIST,
  GET_SERVICEREMINDER_HISTORY,
  GET_SENTVIEW_LIST,
  GET_INSPECTION_UPCOMING_LIST,
  GET_INSPECTION_OVERDUE_LIST,
  GET_INSPECTIONREMINDER_HISTORY,
  GET_VEHICLE_UPCOMING_LIST,
  GET_VEHICLE_OVERDUE_LIST,
  GET_VEHICLEREMINDER_HISTORY,
  GET_VEHICLE_LIST,
  GET_VEHICLES_COUNT,
  GET_CARAVAN_LIST,
  GET_MARINE_LIST,
  GET_TRUCK_LIST,
  GET_TRAILER_LIST,
  GET_MOTORBIKE_LIST,
  GET_MACHINE_LIST,
  GET_CUSTOMER_LIST,
  REGOEXIST,
  HINEXIST,
  GET_VEHICLE_DETAIL,
  GET_JOB_LIST,
  GET_ACTIVE_ASSETS,
  SEARCH_VEHICLE,
  GET_VEHICLE_LEGACY_HISTORY,
} from "./actionTypes";
import {
  getUpcomingListSuccess,
  getUpcomingListFail,
  getOverDueListSuccess,
  getOverDueListFail,
  getServiceReminderHistorySuccess,
  getServiceReminderHistoryFail,
  getSentViewListSuccess,
  getSentViewListFail,
  getInspectionUpcomingListSuccess,
  getInspectionUpcomingListFail,
  getInspectionOverDueListSuccess,
  getInspectionOverDueListFail,
  getInspectionReminderHistorySuccess,
  getInspectionReminderHistoryFail,
  getVehicleUpcomingListSuccess,
  getVehicleUpcomingListFail,
  getVehicleOverDueListSuccess,
  getVehicleOverDueListFail,
  getVehicleReminderHistorySuccess,
  getVehicleReminderHistoryFail,
  getVehicleListSuccess,
  getVehicleListFail,
  getVehicleCountSuccess,
  getVehicleCountFail,
  getCaravanListSuccess,
  getCaravanListFail,
  getMarineListSuccess,
  getMarineListFail,
  getTruckListSuccess,
  getTruckListFail,
  getTrailerListSuccess,
  getTrailerListFail,
  getMotorBikeListSuccess,
  getMotorBikeListFail,
  getMachineListSuccess,
  getMachineListFail,
  getCustomerListSuccess,
  getCustomerListFail,
  regoExistSuccess,
  regoExistFail,
  hinExistSuccess,
  hinExistFail,
  getVehicleDetailSuccess,
  getVehicleDetailFail,
  getJobListSuccess,
  getJobListFail,
  getActiveAssetsSuccess,
  getActiveAssetsFail,
  searchVehicleSuccess,
  searchVehicleFail,
  getVehicleLegacyHistorySuccess,
  getVehicleLegacyHistoryFail,
} from "./actions";

import {
  getServiceReminderList,
  getServiceReminderHistory,
  getJobs,
  getCustomerAndVehicleDetails,
  getCustomerLists,
  getassignedJobs,
  getVehicleDetail,
  getWorkShopDetail,
  searchVehicle,
  getVehicleLegacyHistory
} from "../../helpers/backendHelper";

function* onGetUpcomingList(data) {
  try {
    const response = yield call(getServiceReminderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getUpcomingListSuccess(response.data.VehicleServiceReminder)
        );
      } else {
        yield put(getUpcomingListFail(response.message));
      }
    } else {
      yield put(getUpcomingListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getUpcomingListFail(error.response));
  }
}

function* onGetOverDueList(data) {
  try {
    const response = yield call(getServiceReminderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getOverDueListSuccess(response.data.VehicleServiceReminder)
        );
      } else {
        yield put(getOverDueListFail(response.message));
      }
    } else {
      yield put(getOverDueListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getOverDueListFail(error.response));
  }
}

function* onGetServiceReminderHistory(data) {
  try {
    const response = yield call(getServiceReminderHistory, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getServiceReminderHistorySuccess(response.data.ServiceReminderHistory)
        );
      } else {
        yield put(getServiceReminderHistoryFail(response.message));
      }
    } else {
      yield put(getServiceReminderHistoryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getServiceReminderHistoryFail(error.response));
  }
}

function* onGetSentViewList(data) {
  try {
    const response = yield call(getServiceReminderHistory, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSentViewListSuccess(response.data.ServiceReminderHistory));
      } else {
        yield put(getSentViewListFail(response.message));
      }
    } else {
      yield put(getSentViewListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSentViewListFail(error.response));
  }
}

function* onGetInspectionUpcomingList(data) {
  try {
    const response = yield call(getServiceReminderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getInspectionUpcomingListSuccess(response.data.VehicleServiceReminder));
      } else {
        yield put(getInspectionUpcomingListFail(response.message));
      }
    } else {
      yield put(getInspectionUpcomingListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionUpcomingListFail(error.response));
  }
}

function* onGetInspectionOverDueList(data) {
  try {
    const response = yield call(getServiceReminderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getInspectionOverDueListSuccess(response.data.VehicleServiceReminder));
      } else {
        yield put(getInspectionOverDueListFail(response.message));
      }
    } else {
      yield put(getInspectionOverDueListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionOverDueListFail(error.response));
  }
}

function* onGetInspectionReminderHistory(data) {
  try {
    const response = yield call(getServiceReminderHistory, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getInspectionReminderHistorySuccess(
            response.data.ServiceReminderHistory
          )
        );
      } else {
        yield put(getInspectionReminderHistoryFail(response.message));
      }
    } else {
      yield put(getInspectionReminderHistoryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionReminderHistoryFail(error.response));
  }
}

function* onGetVehicleUpcomingList(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getVehicleUpcomingListSuccess(response.data.Vehicles));
      } else {
        yield put(getVehicleUpcomingListFail(response.message));
      }
    } else {
      yield put(getVehicleUpcomingListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getVehicleUpcomingListFail(error.response));
  }
}

function* onGetVenicleOverDueList(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getVehicleOverDueListSuccess(response.data.Vehicles));
      } else {
        yield put(getVehicleOverDueListFail(response.message));
      }
    } else {
      yield put(getVehicleOverDueListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getVehicleOverDueListFail(error.response));
  }
}

function* onGetVehicleReminderHistory(data) {
  try {
    const response = yield call(getServiceReminderHistory, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getVehicleReminderHistorySuccess(response.data.ServiceReminderHistory)
        );
      } else {
        yield put(getVehicleReminderHistoryFail(response.message));
      }
    } else {
      yield put(getVehicleReminderHistoryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getVehicleReminderHistoryFail(error.response));
  }
}

function* onGetVehicleList(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getVehicleListSuccess(response.data.Vehicles));
      } else {
        yield put(getVehicleListFail(response.message));
      }
    } else {
      yield put(getVehicleListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getVehicleListFail(error.response));
  }
}

function* onGetVehicleCount(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getVehicleCountSuccess(response.data.Vehicles[0].COUNT));
      } else {
        yield put(getVehicleCountFail(response.message));
      }
    } else {
      yield put(getVehicleCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getVehicleCountFail(error.response));
  }
}

function* onGetCaravanList(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCaravanListSuccess(response.data.Vehicles));
      } else {
        yield put(getCaravanListFail(response.message));
      }
    } else {
      yield put(getCaravanListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCaravanListFail(error.response));
  }
}

function* onGetMarineList(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getMarineListSuccess(response.data.Vehicles));
      } else {
        yield put(getMarineListFail(response.message));
      }
    } else {
      yield put(getMarineListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getMarineListFail(error.response));
  }
}

function* onGetTruckList(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTruckListSuccess(response.data.Vehicles));
      } else {
        yield put(getTruckListFail(response.message));
      }
    } else {
      yield put(getTruckListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTruckListFail(error.response));
  }
}

function* onGetTrailerList(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTrailerListSuccess(response.data.Vehicles));
      } else {
        yield put(getTrailerListFail(response.message));
      }
    } else {
      yield put(getTrailerListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTrailerListFail(error.response));
  }
}

function* onGetMotorBikeList(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getMotorBikeListSuccess(response.data.Vehicles));
      } else {
        yield put(getMotorBikeListFail(response.message));
      }
    } else {
      yield put(getMotorBikeListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getMotorBikeListFail(error.response));
  }
}


function* onGetMachineList(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getMachineListSuccess(response.data.Vehicles));
      } else {
        yield put(getMachineListFail(response.message));
      }
    } else {
      yield put(getMachineListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getMachineListFail(error.response));
  }
}

function* onGetCustomerList(data) {
  try {
    const response = yield call(getCustomerLists, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCustomerListSuccess(response.data.CustomersDetails));
      } else {
        yield put(getCustomerListFail(response.message));
      }
    } else {
      yield put(getCustomerListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerListFail(error.response));
  }
}

function* onReogExist(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);

    if (response) {
      if (response.code === 200 || response.code === 400) {
        yield put(
          regoExistSuccess(
            response.message == "No records found..!!" ? false : true
          )
        );
      } else {
        yield put(regoExistFail(response.message));
      }
    } else {
      yield put(regoExistFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(regoExistFail(error.response));
  }
}

function* onHinExist(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);

    if (response) {
      if (response.code === 200 || response.code === 400) {
        yield put(
          hinExistSuccess(
            response.message == "No records found..!!" ? false : true
          )
        );
      } else {
        yield put(hinExistFail(response.message));
      }
    } else {
      yield put(hinExistFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(hinExistFail(error.response));
  }
}

function* onGetVehicleDetail(data) {
  try {
    const response = yield call(getVehicleDetail, data.payload);

    if (response) {
      if (response.code === 200) {
        yield put(getVehicleDetailSuccess(response.data.Vehicles));
      } else {
        yield put(getVehicleDetailFail(response.message));
      }
    } else {
      yield put(getVehicleDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getVehicleDetailFail(error.response));
  }
}

function* onGetVehicleLegacyHistory(data) {
  try {
    const response = yield call(getVehicleLegacyHistory, data);

    if (response) {
      if (response.code === 200) {
        yield put(getVehicleLegacyHistorySuccess(response.data.LegacyHistories));
      } else {
        yield put(getVehicleLegacyHistoryFail(response.message));
      }
    } else {
      yield put(getVehicleLegacyHistoryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getVehicleLegacyHistoryFail(error.response));
  }
}

function* onGetJobList(data) {
  try {
    const response = yield call(getassignedJobs, data);

    if (response) {
      if (response.code === 200) {
        yield put(getJobListSuccess(response.data.JobSchedules));
      } else {
        yield put(getJobListFail(response.message));
      }
    } else {
      yield put(getJobListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobListFail(error.response));
  }
}

function* onGetActiveAssets(data) {
  try {
    const response = yield call(getWorkShopDetail, data);

    if (response) {
      if (response.code === 200) {
        yield put(
          getActiveAssetsSuccess(response.data.Workshops.vehicle_types)
        );
      } else {
        yield put(getActiveAssetsFail(response.message));
      }
    } else {
      yield put(getActiveAssetsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getActiveAssetsFail(error.response));
  }
}

function* onSearchVehicle(data) {
  try {
    const response = yield call(searchVehicle, data);

    if (response) {
      if (response.code === 200) {
        yield put(searchVehicleSuccess(response.data.Vehicles));
      } else {
        yield put(searchVehicleFail(response.message));
      }
    } else {
      yield put(searchVehicleFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(searchVehicleFail(error.response));
  }
}

function* ListSaga() {
  yield takeLatest(GET_UPCOMING_LIST, onGetUpcomingList);
  yield takeLatest(GET_OVERDUE_LIST, onGetOverDueList);
  yield takeLatest(GET_SERVICEREMINDER_HISTORY, onGetServiceReminderHistory);
  yield takeLatest(GET_SENTVIEW_LIST, onGetSentViewList);
  yield takeLatest(GET_INSPECTION_UPCOMING_LIST, onGetInspectionUpcomingList);
  yield takeLatest(GET_INSPECTION_OVERDUE_LIST, onGetInspectionOverDueList);
  yield takeLatest(
    GET_INSPECTIONREMINDER_HISTORY,
    onGetInspectionReminderHistory
  );
  yield takeLatest(GET_VEHICLE_UPCOMING_LIST, onGetVehicleUpcomingList);
  yield takeLatest(GET_VEHICLE_OVERDUE_LIST, onGetVenicleOverDueList);
  yield takeLatest(GET_VEHICLEREMINDER_HISTORY, onGetVehicleReminderHistory);
  yield takeLatest(GET_VEHICLE_LIST, onGetVehicleList);
  yield takeLatest(GET_VEHICLES_COUNT, onGetVehicleCount);
  yield takeLatest(GET_CARAVAN_LIST, onGetCaravanList);
  yield takeLatest(GET_MARINE_LIST, onGetMarineList);
  yield takeLatest(GET_TRUCK_LIST, onGetTruckList);
  yield takeLatest(GET_TRAILER_LIST, onGetTrailerList);
  yield takeLatest(GET_MOTORBIKE_LIST, onGetMotorBikeList);
  yield takeLatest(GET_MACHINE_LIST, onGetMachineList);
  yield debounce(1000, GET_CUSTOMER_LIST, onGetCustomerList);
  yield takeLatest(REGOEXIST, onReogExist);
  yield takeLatest(HINEXIST, onHinExist);
  yield takeLatest(GET_VEHICLE_DETAIL, onGetVehicleDetail);
  yield takeLatest(GET_VEHICLE_LEGACY_HISTORY, onGetVehicleLegacyHistory);
  yield takeLatest(GET_JOB_LIST, onGetJobList);
  yield takeLatest(GET_ACTIVE_ASSETS, onGetActiveAssets);
  yield debounce(2000, SEARCH_VEHICLE, onSearchVehicle);
}

export default ListSaga;
