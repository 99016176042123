import { GET_HQ_WORKSHOP_LIST, GET_HQ_WORKSHOP_LIST_COUNT, GET_HQ_WORKSHOP_LIST_COUNT_FAIL, GET_HQ_WORKSHOP_LIST_COUNT_SUCCESS, GET_HQ_WORKSHOP_LIST_FAIL, GET_HQ_WORKSHOP_LIST_SUCCESS, SET_HQ_WORKSHOP_SIGN_IN_TOKEN, SET_HQ_WORKSHOP_SIGN_IN_TOKEN_FAIL, SET_HQ_WORKSHOP_SIGN_IN_TOKEN_SUCCESS } from "./actionTypes"

export const getHQWorkshopList = (data) => {
    return {
        type: GET_HQ_WORKSHOP_LIST,
        payload: data
    }
}

export const getHQWorkshopListSuccess = (data) => {
    return {
        type: GET_HQ_WORKSHOP_LIST_SUCCESS,
        payload: data
    }
}

export const getHQWorkshopListFail = (data) => {
    return {
        type: GET_HQ_WORKSHOP_LIST_FAIL,
        payload: data
    }
}



export const getHQWorkshopListCount = ({ head_quarters_id, ...data }) => {
    return {
        type: GET_HQ_WORKSHOP_LIST_COUNT,
        payload: {
            "get": "all",
            "conditions": {
                "Workshops.head_quarters_id": head_quarters_id,
                "Workshops.status": 1,
                ...data
            },
            contain: {
                // WorkshopSubscription: {
                //   fields: ["id", "plan_name", "plan_expiry_date"],
                //   order: [
                //     "DESC"
                //   ],
                //   limit: 1
                // },
                Countries: {
                    fields: ["id", "country_name"],
                },
                States: {
                    fields: ["id", "name"],
                },
                AccountingPackages: {
                    fields: ["id", "name"],
                },
            },
            "fields": {
                "COUNT": "COUNT(Workshops.id)"
            }
        }
    }
}

export const getHQWorkshopListCountSuccess = (data) => {
    return {
        type: GET_HQ_WORKSHOP_LIST_COUNT_SUCCESS,
        payload: data
    }
}

export const getHQWorkshopListCountFail = (data) => {
    return {
        type: GET_HQ_WORKSHOP_LIST_COUNT_FAIL,
        payload: data
    }
}

export const setHQWorkshopSigninToken = (data) => {
    return {
        type: SET_HQ_WORKSHOP_SIGN_IN_TOKEN,
        payload: data
    }
}

export const setHQWorkshopSigninTokenSuccess = (data) => {
    return {
        type: SET_HQ_WORKSHOP_SIGN_IN_TOKEN_SUCCESS,
        payload: data
    }
}

export const setHQWorkshopSigninTokenFail = (data) => {
    return {
        type: SET_HQ_WORKSHOP_SIGN_IN_TOKEN_FAIL,
        payload: data
    }
}