import { takeLatest, put, call } from "redux-saga/effects";
import { ADD_SUPPLIER, GET_SUPPLIER_LIST, CHECK_SUPPLIER_EXIST, GET_SUPPLIER_DETAIL } from "./actionTypes";
import {
  addSupplierSuccess,
  addSupplierFail,
  getSupplierListSuccess,
  getSupplierListFail,
  checkSupplierExistSuccess,
  checkSupplierExistFail,
  getSupplierDetailSuccess,
  getSupplierDetailFail
} from "./actions";

import { addSupplier, getSupplierList } from "../../helpers/backendHelper";
import { showError } from "../../utils/alertMessage";

// save part
function* onGetAddSupplier(data) {
  try {
    const response = yield call(addSupplier, data);
    if(response){
      if(response.code===200){
        yield put(addSupplierSuccess(response.data.Suppliers));
        
      }else{
        yield put(addSupplierFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(addSupplierFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(addSupplierFail(error.response));
  }
}

// get supplier list or single supplier
function* onGetSupplierList(data) {
  try {
    const response = yield call(getSupplierList, data);
    if(response){
      if(response.code===200){
        yield put(getSupplierListSuccess(response.data.Suppliers));
        
      }else{
        yield put(getSupplierListFail(response.message));
      }
    }else{
      yield put(getSupplierListFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getSupplierListFail(error.response));
  }
}

// check supplier part is exist or not
function* onGetCheckSupplier(data) {
  try {
    const response = yield call(getSupplierList, data);
    if(response){
      if(response.code===200){
        yield put(checkSupplierExistSuccess(response.data.Suppliers));
      }else{
        yield put(checkSupplierExistFail(response.message));
      }
    }else{
      yield put(checkSupplierExistFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(checkSupplierExistFail(error.response));
  }
}

// check supplier part is exist or not
function* onGetSupplierDetail(data) {
  try {
    const response = yield call(getSupplierList, data);
    if(response){
      if(response.code===200){
        yield put(getSupplierDetailSuccess(response.data.Suppliers));
      }else{
        yield put(getSupplierDetailFail(response.message));
      }
    }else{
      yield put(getSupplierDetailFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getSupplierDetailFail(error.response));
  }
}



function* SupplierSaga() {
  yield takeLatest(ADD_SUPPLIER, onGetAddSupplier);
  yield takeLatest(GET_SUPPLIER_LIST, onGetSupplierList);
  yield takeLatest(CHECK_SUPPLIER_EXIST, onGetCheckSupplier);
  yield takeLatest(GET_SUPPLIER_DETAIL, onGetSupplierDetail);
}

export default SupplierSaga;
