import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import DeleteConfirm from "../../../../../utils/deleteConfirm";
import LoaderImage from "../../../../../../src/assets/images/page-loader.svg";
import { GET_LABOUR_CHARGES_LIST } from "../../../../../store/commonApiStore/actionTypes";
import {
  DELETE_LABOUR_CHARGE_DETAILS,
  EDIT_LABOUR_CHARGES_DETAILS,
  UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL,
  UPDATE_LABOUR_CHARGE_DETAILS,
} from "../../../../../store/defaultSettings/actionTypes";

const LabourChargesList = () => {
  const dispatch = useDispatch(); // Dispatch variable
  const { formLoading } = useSelector((state) => state.defaultSettingsReducer); // GET Form loader from Company Detail Reducer data
  const { labourChargesList, loadingLogins } = useSelector(
    (state) => state.CommonApiStoreReducer
  );
  const { SearchBar } = Search;
  const [charges, setCharges] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [deleteLabourId, setDeleteLabourId] = useState();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const [emptyMessage, setEmptyMessage] = useState("No data available in table");

  const handleDeleteClose = () => setShowDeleteModal(false);

  // GET labour charge details for edit
  const editLabourChargeDetails = (charge) => {
    dispatch({
      type: EDIT_LABOUR_CHARGES_DETAILS,
      payload: {
        get: "all",
        conditions: {
          id: charge.id,
        },
      },
    });
  };
  // UPDATE labour charge
  const updateLabourChargeDetails = (charge, status) => {
    const editFormData = {
      is_enable: status,
      id: charge.id,
    };
    dispatch({
      type: UPDATE_LABOUR_CHARGE_DETAILS,
      payload: editFormData,
    });
  };

  // UPDATE default value of all charges and set default as selected labour charge
  const updateDefaultLabourChargeDetails = (charge) => {
    const workshop_id = window.atob(storedData.workshop_id);
    const editFormData = {
      is_default: "0",
      workshop_id,
      id: charge.id,
    };
    dispatch({
      type: UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL,
      payload: editFormData,
    });
  };

  //delete job labour confirmation
  async function deleteLabourConfirm(row_id) {
    setDeleteLabourId(row_id);
    setShowDeleteModal(true);
  }

  // delete labour charge
  const deleteRecord = async () => {
    dispatch({
      type: DELETE_LABOUR_CHARGE_DETAILS,
      payload: {
        id: deleteLabourId,
      },
    });

    setShowDeleteModal(false);
  };

  const sorting = (order, column) => {
    if (!order)
      return (
        <span className="ps-2">
          <i className="fa fa-sort" aria-hidden="true"></i>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ps-2">
          <i className="fa fa-sort-asc" aria-hidden="true"></i>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="ps-2">
          <i className="fa fa-sort-desc" aria-hidden="true"></i>
        </span>
      );
    return null;
  };

  const columns = [
    {
      dataField: "labour_code",
      text: "Labour Code",
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "description",
      text: "Description",
      // formatter: (cell, row) => {
      //   return <div>{`${row.percentage}%`}</div>;
      // },
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "cost_price",
      text: "Cost",
      formatter: (cell, row) => {
        return <div>{`${storedData.currency}${row.cost_price}`}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "sell_price",
      text: "Sell",
      formatter: (cell, row) => {
        return <div>{`${storedData.currency}${row.sell_price}`}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "retail_price",
      text: "Retail",
      formatter: (cell, row) => {
        return <div>{`${storedData.currency}${row.retail_price}`}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "is_enable",
      text: "Enable/Disable",
      formatter: (cell, row) => {
        return (
          <div className="enable-disable">
            <Form.Check
              type="switch"
              id={`custom-switch-${row.id}`}
              checked={row.is_enable}
              disabled={row.is_default === "1" || row.is_default === 1}
              onChange={(e) => updateLabourChargeDetails(row, e.target.checked)}
              disabled={row.is_default === "1" || row.is_default === 1}
            />
          </div>
        );
      },
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "is_default",
      text: "Default",
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
      formatter: (cell, row) => {
        return (
          <div>
            <Form.Check className="custom-check mb-2">
              <Form.Check.Input
                type="checkbox"
                name="favorite"
                id={`default-${row.id}`}
                className="custom-check secondary-check mb-2"
                checked={row.is_default === "1"}
                onChange={(e) => {
                  if (e.target.checked) {
                    updateDefaultLabourChargeDetails(row);
                  }
                }}
              />
              <Form.Check.Label htmlFor={`default-${row.id}`}>
                <span className="check"></span>
              </Form.Check.Label>
            </Form.Check>
          </div>
        );
      },
    },

    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <button
                  onClick={() => {
                    editLabourChargeDetails(row);
                  }}
                  type="button"
                  className="w-100"
                >
                  <span>
                    <i className="fa fa-pencil text-info"></i>
                  </span>
                  <span>Edit</span>
                </button>
              </li>
              <li className={row.is_default === "1" ? "d-none" : ""} >
                <button
                  onClick={() => deleteLabourConfirm(row.id)}
                  type="button"
                  variant="danger"
                  className="delete-link w-100"
                >
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </button>
              </li>
            </div>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    // setLoading(true);
    dispatch({
      type: GET_LABOUR_CHARGES_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: window.atob(storedData.workshop_id),
        },
        // limit: sizePerPage,
      },
    });
  }, []);
  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
    loadData(page, sizePerPage);
  };
  const loadData = (page = 1, pageLimit = 10) => {
    dispatch({
      type: GET_LABOUR_CHARGES_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: window.atob(storedData.workshop_id),
        },
        // limit: sizePerPage,
      },
    });
  };
  useEffect(() => {
    if (labourChargesList) {
      setCharges(labourChargesList);
      // setLoading(false);
    }
  }, [labourChargesList]);

  const emptyDataMessage = () => {
    return (
      <div className={(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  const MySearch = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
      setEmptyMessage("No Results Found")
    };
    return (
      <div>
        <input
          className="form-control"
          ref={n => input = n}
          type="text"
          id="search"
          placeholder="Search..."
          onChange={handleClick}
        />
      </div>
    );
  };

  return (
    <>
      <div>
        <ToolkitProvider keyField="id" data={charges} columns={columns} search>
          {(props) => (
            <div>
              <div className="table-search-wrapper ">
                <MySearch {...props.searchProps} />
              </div>
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage,
                  totalSize: charges.length, //levelsCount,
                  nextPageText: "Next",
                  prePageText: "Previous",
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      keyField="id"
                      data={charges}
                      columns={columns}
                      onTableChange={onTableChange}
                      noDataIndication={emptyDataMessage}
                      {...paginationTableProps}
                      {...props.baseProps}
                    />
                    <div>
                      <div className="table-pagination">
                        <div>
                          <span>Show</span>
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          <span>entries</span>
                        </div>
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            </div>
          )}
        </ToolkitProvider>
      </div>
      {showDeleteModal && (
        <DeleteConfirm
          handleDeleteClose={handleDeleteClose}
          deleteRecord={deleteRecord}
          msg={"Delete this record?"}

        // showDeleteModal={setShowDeleteModal}
        // refreshData={loadData}
        // api={deleteWorkshopMarkup}
        // selectedRow={selectedRow}
        // setLoader={setLoading}
        />
      )}
    </>
  );
};

export default LabourChargesList;
