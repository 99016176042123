import Loadable from "react-loadable";
// import InspectionEmailVerification from "";

import { Loading } from "../common/navigation";

const Logout = Loadable({
  loader: () => import("views/login/components/logout"),
  loading: Loading,
});


const DigitalSupplierStatement = Loadable({
  loader: () => import("views/Reports/components/Statements/DigitalSupplierStatements"),
  loading: Loading,
});

const DigitalSupplierListStatement = Loadable({
  loader: () => import("views/Reports/components/Statements/DigitalSupplierListStatements"),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import("views/login/components/login"),
  loading: Loading,
});

const InspectionEmailVerification = Loadable({
  loader: () =>
    import(
      "views/job/components/jobDetails/InspectionTab/InspectionEmailVerification"
    ),
  loading: Loading,
});

const DigitalQuotePdf = Loadable({
  loader: () =>
    import(
      "views/quotes/components/EmailQuote/DigitalQuotePdf"
    ),
  loading: Loading,
});

const DigitalInvoicePdf = Loadable({
  loader: () =>
    import(
      "views/invoices/components/InvoiceDetailComponents/InvoicePreview/DigitalInvoicePdf"
    ),
  loading: Loading,
});

const DigitalReceiptPdf = Loadable({
  loader: () =>
    import(
      "views/invoices/components/InvoiceDetailComponents/InvoicePreview/DigitalReceiptPdf"
    ),
  loading: Loading,
});

const DigitalStatementPdf = Loadable({
  loader: () =>
    import(
      "views/invoices/components/StatementInvoiceEdit/DigitalStatementPdf"
    ),
  loading: Loading,
});

const DigitalReportStatement = Loadable({
  loader: () =>
    import(
      "views/Reports/components/Invoices/DigitalStatementReport"
    ),
  loading: Loading,
});

const DigitalVehicleReport = Loadable({
  loader: () =>
    import(
      "views/Reports/components/Invoices/DigitalVehicleReport"
    ),
  loading: Loading,
});

const DigitalPurchaseOrderPdf = Loadable({
  loader: () =>
    import(
      "views/purchaseOrder/components/DigitalPurchaseOrder/DigitalPOPreview"
    ),
  loading: Loading,
});

const DigitalPOReceiptPdf = Loadable({
  loader: () =>
    import(
      "views/purchaseOrder/components/DigitalPurchaseOrder/DigitalReceiptPreview"
    ),
  loading: Loading,
});

const DigitalPOCreditPdf = Loadable({
  loader: () =>
    import(
      "views/purchaseOrder/components/DigitalPurchaseOrder/DigitalCreditPreview"
    ),
  loading: Loading,
});

const Iframe = Loadable({
  loader: () => import("views/iframe/components/Iframe"),
  loading: Loading,
});
const LoginWithToken = Loadable({
  loader: () => import("views/login/components/login-with-token"),
  loading: Loading,
});

const EODPreviewData = Loadable({
  loader: () => import("views/Reports/components/Invoices/EndOfDay/EODPreviewData"),
  loading: Loading,
});

const InvoiceValuePreviewData = Loadable({
  loader: () => import("views/Reports/components/Invoices/InvoiceValue/InvoiceValuePreviewData"),
  loading: Loading,
});

const InvoicePaymentPreviewData = Loadable({
  loader: () => import("views/Reports/components/Invoices/InvoicePayment/InvoicePaymentPreviewData"),
  loading: Loading,
});

const JobsRevenueDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Jobs/JobsRevenueDigitalReport"),
  loading: Loading,
});

const JobsForeCastDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Jobs/JobsForeCastDigitalReport"),
  loading: Loading,
});

const JobStateDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Jobs/JobStateDigitalReport"),
  loading: Loading,
});

const QuotesRevenueDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Quotes/QuotesRevenueDigitalReport"),
  loading: Loading,
});

const QuotesForeCastDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Quotes/QuotesForeCastDigitalReport"),
  loading: Loading,
});


const QuotesStateDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Quotes/QuotesStateDigitalReport"),
  loading: Loading,
});

const RevenuePerCustomerDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Customer/RevenuePerCustomerDigitalReport"),
  loading: Loading,
});

const RevenueByJobTypeDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Customer/RevenueByJobTypeDigitalReport"),
  loading: Loading,
});

const TopCustomerOnRevenueDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Customer/TopCustomerOnRevenueDigitalReport"),
  loading: Loading,
});

const LabourHoursDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Labour/LabourHoursDigitalReport"),
  loading: Loading,
});

const LabourRateDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Labour/LabourRateDigitalReport"),
  loading: Loading,
});

const PurchaseOrderDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Materials/PurchaseOrderDigitalReport"),
  loading: Loading,
});

const StockValueDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Materials/StockValueDigitalReport"),
  loading: Loading,
});

const StockTakeDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Materials/StockTakeDigitalReport"),
  loading: Loading,
});

const DailyClockingDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Clocking/DailyClockingDigitalReport"),
  loading: Loading,
});

const JobClockingDigitalReport = Loadable({
  loader: () => import("views/Reports/components/Clocking/JobClockingDigitalReport"),
  loading: Loading,
});

export const routes = [
  {
    path: "/inspection-email-verification/:id",
    exact: true,
    name: "InspectionEmailVerification",
    element: InspectionEmailVerification,
  },
  {
    path: "/digital-quote-pdf/:id",
    exact: true,
    name: "DigitalQuotePdf",
    element: DigitalQuotePdf,
  },
  {
    path: "/digital-invoice-pdf/:id",
    exact: true,
    name: "DigitalInvoicePdf",
    element: DigitalInvoicePdf,
  },
  {
    path: "/digital-receipt-pdf/:id",
    exact: true,
    name: "DigitalReceiptPdf",
    element: DigitalReceiptPdf,
  },
  {
    path: "/digital-statement-pdf/:id",
    exact: true,
    name: "DigitalStatementPdf",
    element: DigitalStatementPdf,
  },
  {
    path: "/digital-supplier-statement/:id",
    exact: true,
    name: "DigitalSupplierStatement",
    element: DigitalSupplierStatement,
  },
  {
    path: "/digital-supplier-list-statement/:id",
    exact: true,
    name: "DigitalSupplierListStatement",
    element: DigitalSupplierListStatement,
  },
  {
    path: "/digital-report-statement/:id",
    exact: true,
    name: "DigitalReportStatement",
    element: DigitalReportStatement,
  },
  {
    path: "/digital-report-statement",
    exact: true,
    name: "DigitalReportStatement",
    element: DigitalReportStatement,
  },
  {
    path: "/digital-vehicle-report",
    exact: true,
    name: "DigitalVehicleReport",
    element: DigitalVehicleReport,
  },
  {
    path: "/digital-po-pdf/:id",
    exact: true,
    name: "DigitalPurchaseOrderPdf",
    element: DigitalPurchaseOrderPdf,
  },
  {
    path: "/digital-po-receipt-pdf/:id",
    exact: true,
    name: "DigitalPOReceiptPdf",
    element: DigitalPOReceiptPdf,
  },
  {
    path: "/digital-po-credit-pdf/:id",
    exact: true,
    name: "DigitalPOCreditPdf",
    element: DigitalPOCreditPdf,
  },
  {
    path: "/reports/end-of-day-preview",
    exact: true,
    name: "EODPreviewData",
    element: EODPreviewData,
  },
  {
    path: "/reports/invoice-value-preview",
    exact: true,
    name: "InvoiceValuePreviewData",
    element: InvoiceValuePreviewData,
  },
  {
    path: "/reports/invoice-payment-preview",
    exact: true,
    name: "InvoicePaymentPreviewData",
    element: InvoicePaymentPreviewData,
  },
  {
    path: "/reports/job-revenue-preview",
    exact: true,
    name: "JobsRevenueDigitalReport",
    element: JobsRevenueDigitalReport,
  },
  {
    path: "/reports/job-forcast-preview",
    exact: true,
    name: "JobsForeCastDigitalReport",
    element: JobsForeCastDigitalReport,
  },
  {
    path: "/reports/job-state-preview",
    exact: true,
    name: "JobStateDigitalReport",
    element: JobStateDigitalReport,
  },
  {
    path: "/reports/quote-revenue-preview",
    exact: true,
    name: "QuotesRevenueDigitalReport",
    element: QuotesRevenueDigitalReport,
  },
  {
    path: "/reports/quote-forecast-preview",
    exact: true,
    name: "QuotesForeCastDigitalReport",
    element: QuotesForeCastDigitalReport,
  },
  {
    path: "/reports/quote-state-preview",
    exact: true,
    name: "QuotesStateDigitalReport",
    element: QuotesStateDigitalReport,
  },
  {
    path: "/reports/revenue-per-customer-preview",
    exact: true,
    name: "RevenuePerCustomerDigitalReport",
    element: RevenuePerCustomerDigitalReport,
  },
  {
    path: "/reports/revenue-by-jobtype-preview",
    exact: true,
    name: "RevenueByJobTypeDigitalReport",
    element: RevenueByJobTypeDigitalReport,
  },
  {
    path: "/reports/top-customer-preview",
    exact: true,
    name: "TopCustomerOnRevenueDigitalReport",
    element: TopCustomerOnRevenueDigitalReport,
  },
  {
    path: "/reports/labour-hours-preview",
    exact: true,
    name: "LabourHoursDigitalReport",
    element: LabourHoursDigitalReport,
  },
  {
    path: "/reports/labour-rate-preview",
    exact: true,
    name: "LabourRateDigitalReport",
    element: LabourRateDigitalReport,
  },
  {
    path: "/reports/material-po-preview",
    exact: true,
    name: "PurchaseOrderDigitalReport",
    element: PurchaseOrderDigitalReport,
  },
  {
    path: "/reports/material-stock-value-preview",
    exact: true,
    name: "StockValueDigitalReport",
    element: StockValueDigitalReport,
  },
  {
    path: "/reports/material-stock-take-preview",
    exact: true,
    name: "StockTakeDigitalReport",
    element: StockTakeDigitalReport,
  },
  {
    path: "/reports/daily-clocking-report-preview",
    exact: true,
    name: "DailyClockingDigitalReport",
    element: DailyClockingDigitalReport,
  },
  {
    path: "/reports/job-clocking-report-preview",
    exact: true,
    name: "JobClockingDigitalReport",
    element: JobClockingDigitalReport,
  },
  {
    path: "/logout",
    exact: true,
    name: "logout",
    element: Logout,
  },
  {
    path: "/login",
    exact: true,
    name: "login",
    element: Login,
  },
  {
    path: "/bookJob",
    exact: true,
    name: "Book Job Online",
    element: Iframe,
  },
  {
    path: "/login-with-token/:token",
    exact: true,
    name: "login-with-token",
    element: LoginWithToken,
  },
];
