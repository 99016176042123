import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import customerBlue from "../../../../../assets/images/customer-blue.svg";
import LoaderImage from "../../../../../assets/images/page-loader.svg";
import {
  CUSTOMER_SEARCH,
  GET_CUSTOMER_COUNT,
  GET_CUSTOMER_LIST,
} from "../../../../../store/Contact/actionTypes";
import { customerSearchSuccess } from "../../../../../store/Contact/actions";
import WorkshopFilterDropdown from "../../common/components/WorkshopFilterDropdown";
import { toast } from "react-toastify";

const CustomersList = () => {
  document.title = "5iQ | Customers";
  const headquartersId = 1;
  const [selectedWorkshop, setSelectedWorkshop] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [customerData, setCustomerData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const workshop_id = window.atob(storedData.workshop_id);
  const deleteMsg = "Customer deleted successfully";
  const deletePayloadData = {
    payload: {
      action_type: "delete",
      ids: selectedRow,
    },
  };
  const ArchiveMsg = "Customer Archived successfully";
  const ArchivePayloadData = {
    payload: {
      action_type: "archive",
      ids: selectedRow,
    },
  };

  const { loading, customerList, customerCount, customerSearch } = useSelector(
    (state) => state.Contact
  );
  
  useEffect(() => {
    return () => {
      dispatch(customerSearchSuccess({}));
    };
  }, []);

  useEffect(() => {
    //Reset search string
    setSearch("");

    loadData();

    let loadDataPayload = {
      get: "all",
      conditions: {
        "CustomersDetails.head_quarters_id" :headquartersId,
        role_id: 4,
        "CustomersDetails.is_deleted": 0,
        "CustomersDetails.status": 1,
      },
      contain: {
        Users: {
          fields: ["Users.email", "Users.id"],
        },
        "Users.Vehicles": {
          fields: ["Vehicles.user_id"],
          conditions: {
            "Vehicles.is_deleted": 0,
            "Vehicles.head_quarters_id":headquartersId
          },
        },
        CustomerContact: {
          conditions: {
            "CustomerContact.is_deleted": 0,
            "CustomerContact.status": 1,
          },
        },
      },

      order: {
        "CustomersDetails.id": "desc",
      },
      fields: {
        COUNT: "COUNT(CustomersDetails.id)",
      },
    }

    if(selectedWorkshop != 0){
      loadDataPayload["conditions"]["CustomersDetails.workshop_id"] = selectedWorkshop;
      loadDataPayload["contain"]["Users.Vehicles"]["conditions"]["Vehicles.workshop_id"] = selectedWorkshop;
    }
    
    dispatch({  
      type: GET_CUSTOMER_COUNT,
      payload: loadDataPayload,
    });
  }, [selectedWorkshop]);

  const loadData = (page = 1, pageLimit = 10) => {

    let loadDataPayload = {
      get: "all",
      conditions: {
        role_id: 4,
        "CustomersDetails.is_deleted": 0,
        "CustomersDetails.status": 1,
        "CustomersDetails.head_quarters_id": headquartersId,
      },
      contain: {
        Users: {
          fields: ["Users.email", "Users.id"],
        },
        "Users.Vehicles": {
          fields: ["Vehicles.user_id"],
          conditions: {
            "Vehicles.is_deleted": 0,
            "Vehicles.head_quarters_id": headquartersId,
          },
        },
        CustomerContact: {
          conditions: {
            "CustomerContact.is_deleted": 0,
            "CustomerContact.status": 1,
          },
        },
        Workshops: {
          fields: ["Workshops.id","Workshops.name"],
        },
      },
      limit: pageLimit,
      page: page,
      // order: {
      //   "CustomersDetails.firstname": "asc",
      // },
      fields: [
        "CustomersDetails.id",
        "CustomersDetails.type",
        "CustomersDetails.firstname",
        "CustomersDetails.lastname",
        "CustomersDetails.company_name",
        "CustomersDetails.phone_number",
        "CustomersDetails.mobile_number",
        "CustomersDetails.workshop_id",
        "CustomersDetails.suburb",
        "CustomersDetails.street_address",
        "CustomersDetails.user_id",
      ],
    }

    if(selectedWorkshop != 0){
      loadDataPayload["conditions"]["CustomersDetails.workshop_id"] = selectedWorkshop;
      loadDataPayload["contain"]["Users.Vehicles"]["conditions"]["Vehicles.workshop_id"] = selectedWorkshop;
    }

    dispatch({
      type: GET_CUSTOMER_LIST,
      payload: loadDataPayload,
    });
  };

  const columns = [
    {
      dataField: "firstname",
      text: "Name",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
      formatter: (cell, row) => {
        return row.company_name
          ? row.company_name
          : row.firstname + " " + row.lastname;
      },
    },

    {
      dataField: "mobile_number",
      text: "Contact",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
    },
    {
      dataField: "user.email",
      text: "Email",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
    },

    {
      dataField: "street_address",
      text: "Address",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
    },
    {
      dataField: "suburb",
      text: "Suburb",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
    },
    {
      dataField: "user.vehicles",
      text: "Vehicles",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
      formatter: (cell, row) => {
        return row.user.vehicles.length;
      },
    },
    {
      dataField: "workshop.name",
      text: "Workshop Name",
    },

    // {
    //   dataField: "df1",
    //   isDummyField: true,
    //   text: "Action",
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="btn-group toggle-hover">
    //         <button className="btn " type="button">
    //           <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
    //         </button>
    //         <div className="dropdown-list">
    //           <li>
    //             <Link to={`/edit_customer/${row.id}`} className="" title="EDIT">
    //               <i className="fa fa-pencil text-info"></i>
    //               <span>Edit</span>
    //             </Link>
    //           </li>
    //           <li
    //             onClick={() => {
    //               setSelectedRow(row.id);
    //               setShowDeleteModal(true);
    //             }}
    //           >
    //             <Link to="#" className="delete-link" title="DELETE">
    //               <span className="red-bg">
    //                 <i className="fa fa-minus"></i>
    //               </span>
    //               <span>Delete</span>
    //             </Link>
    //           </li>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  // const checkBox = {
  //   mode: "checkbox",
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     if (isSelect) {
  //       setSelectedRow([...selectedRow, row.id]);
  //     } else {
  //       let rowSelected = [...selectedRow];

  //       const index = rowSelected.indexOf(row.id);
  //       if (index > -1) {
  //         rowSelected.splice(index, 1);
  //       }
  //       setSelectedRow(rowSelected);
  //     }
  //   },
  //   onSelectAll: (isSelected) => {
  //     if (isSelected) {
  //       let ids = [];
  //       for (var key = 0; key < sizePerPage; key++) {
  //         if (customerData[key] !== undefined) ids.push(customerData[key].id);
  //       }
  //       setSelectedRow(ids);
  //     } else {
  //       setSelectedRow([]);
  //     }
  //   },
  //   selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         ref={(input) => {
  //           if (input) input.indeterminate = indeterminate;
  //         }}
  //         checked={rest.checked}
  //         name={rest.name}
  //         id={rest.id}
  //         type="checkbox"
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  //   selectionRenderer: ({ mode, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         id={rest.id}
  //         checked={rest.checked}
  //         type={mode}
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  // };

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
    loadData(page, sizePerPage);
  };

  const emptyDataMessage = () => {
    return (
      <div style={{ textAlign: "center" }}>No data available in table</div>
    );
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (e.target.value !== "") {
      dispatch({
        type: CUSTOMER_SEARCH,
        payload: {
          [selectedWorkshop !== 0 ? "workshop_id" : "head_quarters_id"]:
            selectedWorkshop !== 0 ? selectedWorkshop : headquartersId,
          // workshop_id: workshop_id,
          keyword: e.target.value,
        },
      });
    } else {
      dispatch(customerSearchSuccess({}));
      setCustomerData(customerList);
      setTotalRecords(customerCount);
    }
  };

  useEffect(() => {
    setTotalRecords(customerCount);
  }, [customerCount]);

  useEffect(() => {
    if (Object.keys(customerSearch).length !== 0) {
      var text = [];

      let totalSearchCount = 0;
      for (var key = 0; key < 10; key++) {
        if (customerSearch[key] !== undefined) {
          // totalSearchCount = customerSearch.total_count;
          text.push(customerSearch[key]);
        }
      }

      setTotalRecords(customerSearch.length);
      setCustomerData(text);
    } else {
      if (customerList) {
        setCustomerData(customerList);
      }
    }
  }, [customerList, customerSearch]);

  useEffect(() => {
    if (
      search.length > 0 &&
      loading === false &&
      Object.keys(customerSearch).length === 0
    ) {
      toast("No records found..!!", { type: "error" });
    }
  }, [customerSearch, search, loading]);

  return (
    <div className="right-section-wrapper">
      {loading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
      <div className="table-header">
        <div className="row top-block">
          <div className="col-md-2">
            <div className="table-title">
              <img src={customerBlue} alt=""></img>
              <span>CUSTOMERS</span>
            </div>
          </div>
          <div className="col-md-2">
            <div className="video-link-outer">
              <div className="video-tutorial">
                <i className="fa fa-icon"></i>
                {/* <span className="help-text">Help</span> */}
                <Link to="/trainee-video#contact">
                  <span className="tutorial-title">Customers Videos</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div className="table-search-wrapper">
            <input
              type="text"
              className="form-control"
              id="search"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
              value={search}
            />

            <WorkshopFilterDropdown
              current={selectedWorkshop}
              onChange={(newWorkshop) => {
                setSelectedWorkshop(newWorkshop);
              }}
            />
          </div>

          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: currentPage,
              sizePerPage: sizePerPage,
              totalSize: parseInt(totalRecords),
              nextPageText: "Next",
              prePageText: "Previous",
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable
                  remote
                  keyField="id"
                  data={customerData}
                  columns={columns}
                  onTableChange={onTableChange}
                  // selectRow={checkBox}
                  noDataIndication={emptyDataMessage}
                  {...paginationTableProps}
                />
                <div>
                  <div className="table-pagination">
                    <div>
                      <span>Show</span>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <span>entries</span>
                    </div>
                    <PaginationTotalStandalone {...paginationProps} />
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </div>
            )}
          </PaginationProvider>
        </div>
      </div>
    </div>
  );
};

export default CustomersList;
