import { getInvoicesCredits, getInvoicesPayments } from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";

export async function getInvoiceCreditDetail(id) {
  let invoice,
    customer,
    job_detail,
    invoices_payments_credits,
    credits,
    credit_number;

  const request1 = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCredits.id": id,
    },
    contain: {
      0: "Invoices.JobSchedules.Vehicles.Users.Countries",
      1: "Invoices.JobSchedules.Vehicles.Users.States",
      2: "Invoices.JobSchedules.JobsParts.Products",
      3: "Invoices.JobSchedules.CreatedByUser",
      4: "InvoicesPaymentsCreditsOtherDetails",
      "Invoices.JobSchedules.Vehicles.Users.CustomersDetails": {
        conditions: {
          workshop_id: getWorkshopId(),
        },
      },
      "Invoices.JobSchedules.Customers.CustDetails": {
        conditions: {
          "CustDetails.workshop_id": getWorkshopId(),
        },
      },
      "Invoices.JobSchedules.Customers.CustDetails.Countries":{
        "fields" : ["id","country_name"]
      },
      "Invoices.JobSchedules.Customers.CustDetails.States":{
          "fields" : ["id","name"]
      },
      "Invoices.JobSchedules.InsuranceCompany":{
        "fields" : ["id","company_name","contact_name","phone_number","email","website","street_address","country","state","postcode","suburb","abn","acn"]
      },
      "Invoices.JobSchedules.InsuranceCompany.States":{
        "fields" : ["id","name"]
      },
      "Invoices.JobSchedules.InsuranceCompany.Countries":{
          "fields" : ["id","country_name"]
      },
      "Invoices.JobSchedules.InvoiceCCustomer": {
        "fields" : ["id","firstname","lastname","mobile_number","mobile_code","type","company_name","user_id","country","postcode","state","street_address","suburb"]
      },
      "Invoices.JobSchedules.InvoiceCCustomer.Users": {
        "fields" : ["id","email"]
      },      
      "Invoices.JobSchedules.InvoiceCCustomer.Countries":{
        "fields" : ["id","country_name"]
      },
      "Invoices.JobSchedules.InvoiceCCustomer.States":{
          "fields" : ["id","name"]
      },
    },
  };

  const get_invoices = await getInvoicesPayments({ payload: request1 });

  if (get_invoices?.code === 200) {
    invoices_payments_credits = get_invoices?.data?.InvoicesPaymentsCredits[0];
    credits =
      invoices_payments_credits?.invoices_payments_credits_other_details;
    invoice = invoices_payments_credits?.invoice;
    job_detail = invoice?.job_schedule;
    // customer = job_detail?.vehicle?.user;
    customer = job_detail?.customer;

    const request2 = {
      get: "all",
      conditions: {
        "InvoicesPaymentsCreditsOtherDetails.workshop_id": getWorkshopId(),
      },
    };

    const get_all_credits = await getInvoicesCredits({ payload: request2 });

    if (get_all_credits?.code === 200) {
      const credit =
        get_all_credits?.data?.InvoicesPaymentsCreditsOtherDetails?.pop();
      credit_number = credit?.credit_no;
    }
  }

  return {
    invoice,
    customer,
    job_detail,
    invoices_payments_credits,
    credits,
    credit_number,
  };
}
