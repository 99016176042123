import Loadable from "react-loadable"; 

import { Loading } from "../common/navigation";

const welcome = Loadable({
  loader: () => import("./components/Welcome"),
  loading: Loading,
});

export const routes = [
  {
    path: "/welcome",
    exact: true,
    name: "Welcome",
    element: welcome,
  },
];

// export * from "./components";
