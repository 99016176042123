import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_CUSTOMER_NOTIFICATION,
  GET_CUSTOMER_NOTIFICATION_SUCCESS,
  GET_CUSTOMER_NOTIFICATION_FAIL,
  GET_MARKETING_NOTIFICATION,
  GET_MARKETING_NOTIFICATION_SUCCESS,
  GET_MARKETING_NOTIFICATION_FAIL,
} from "./actionTypes";

const initialState = {
  loadingLogins: false,
  message: "",
  error: "",
  notificationData: null,
  customerNotificationData: null,
  marketingNotificationData: null,
  isFetching: false,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION:
      state = { 
        ...state, 
        loadingLogins: true,
        isFetching: false,
      };
    break;
    case GET_NOTIFICATION_SUCCESS:
        state = { 
          ...state, 
          loadingLogins: false,
          notificationData: action.payload,
          isFetching: true,
        };
    break;
    case GET_NOTIFICATION_FAIL:
        state = {
          ...state,
          loadingLogins: false,
          error: {
            message: action.payload,
          },
          isFetching: true,
        };
    break;

    case GET_CUSTOMER_NOTIFICATION:
      state = { 
        ...state, 
        loadingLogins: true,
        isFetching: false,
      };
    break;
    case GET_CUSTOMER_NOTIFICATION_SUCCESS:
        state = { 
          ...state, 
          loadingLogins: false,
          customerNotificationData: action.payload,
          isFetching: true,
        };
    break;
    case GET_CUSTOMER_NOTIFICATION_FAIL:
        state = {
          ...state,
          loadingLogins: false,
          customerNotificationData: null,
          error: {
            message: action.payload,
          },
          isFetching: true,
        };
    break;

    case GET_MARKETING_NOTIFICATION:
      state = { 
        ...state, 
        loadingLogins: true,
        isFetching: false,
      };
    break;
    case GET_MARKETING_NOTIFICATION_SUCCESS:
        state = { 
          ...state, 
          loadingLogins: false,
          marketingNotificationData: action.payload,
          isFetching: true,
        };
    break;
    case GET_MARKETING_NOTIFICATION_FAIL:
        state = {
          ...state,
          loadingLogins: false,
          marketingNotificationData: null,
          error: {
            message: action.payload,
          },
          isFetching: true,
        };
    break;

    default:
      state = { ...state };
    break;
  }
  return state;
};

export default NotificationReducer;
