import { getJobDepartmentList, getJobEmailTemplateList } from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import { getMessageTemplatePayload } from "../api";

export async function getCashSaleInvoiceDetail() {
  let message_template;
  const workshop_id = getWorkshopId();

  const request1 = {
    get: "all",
    conditions: {
      workshop_id: getWorkshopId(),
    },
    fields: ["id", "job_type_parent_id", "name", "workshop_id"],
  };
  const account_detail = await getJobDepartmentList({ payload: request1 });
  const account_data = account_detail?.data?.WorkshopDepartmentsAccountsDetails;
  
  const get_message_template = await getJobEmailTemplateList({
    payload: getMessageTemplatePayload(workshop_id),
  });
  
  if (get_message_template?.code === 200) {
    message_template = get_message_template?.data?.CustomNotifications;
  }

  return {
    account_data,
    message_template
  };
}
