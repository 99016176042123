import { all } from "redux-saga/effects";

import LoginSaga from "./login/saga";
import RegisterSaga from "./register/saga";
import ForgotPasswordSaga from "./forgotPassword/saga";
import ForgotEmailSaga from "./forgotEmail/saga";
import NotificationSaga from "./notification/saga";
import NotificationListSaga from "./notificationList/saga";
import UserProfileSaga from "./userProfile/saga";
import ChangePasswordSaga from "./changePassword/saga";
import SubscriptionSaga from "./subscription/saga";
import SubscriptionAddonSaga from "./subscriptionAddon/saga";
import GlobalSearchSaga from "./globalSearch/saga";
import SchedulerSaga from "./scheduler/saga";
import OpenQuotesSaga from "./openQuotes/saga";
import WonQuotesSaga from "./wonQuotes/saga";
import LostQuotesSaga from "./lostQuotes/saga";
import UnassignedJobsSaga from "./unassignedJobs/saga";
import assignedJobsSaga from "./assignedJobs/saga";
import inProgressJobsSaga from "./inProcressJobs/saga";
import completedJobsSaga from "./completedJobs/saga";
import CommonApiStoreSaga from "./commonApiStore/saga";
import JobPartSaga from "./jobParts/saga";
import PartSaga from "./parts/saga";
import JobDetails from "./jobDetails/saga";
import SupplierSaga from "./supplier/saga";
import CompanyDetailSaga from "./companyDetails/saga";
import defaultSettingsSaga from "./defaultSettings/saga";
import JobLabourSaga from "./jobLabour/saga";
import ContactsSaga from "./contacts/saga";
import AssetsSaga from "./assets/saga";
import invoiceSaga from "./invoices/saga";
import invoiceDetailSaga from "./invoiceDetail/saga";
import SecurityGroupSaga from "./securityGroup/saga";
import QuoteDetails from "./QuoteDetails/saga";
import QuotePartSaga from "./QuoteParts/saga";
import invoicePaymentSaga from "./invoicePayment/saga";
import StatusSaga from "./statuses/saga";
import QuoteLabourSaga from "./quoteLabour/saga";
import workshopBaysSaga from "./workshopBays/saga";
import cashSaleSaga from "./invoiceDetail/cashSaleSaga";
import DigitalInspectionSaga from "./digitalInspection/saga";
import PurchaseOrderSaga from "./purchaseOrder/saga";
import StorageDevicesSaga from "./storageDevices/saga";
import DashboardSaga from "./dashboard/saga";
import InspectionSaga from "./inspection/saga";
import VehicleSaga from "./vehicles/saga";
import SyncAccountSaga from './chartOfAccount/saga';
import ListSaga from "./list/saga";
import bookJobOnlineSaga from "./bookJobOnline/saga";
import watchLeadsSaga from "./leads/saga";
import PaymentIntegrationSaga from "./PaymentIntegration/saga";
import SupplierIntegrationSaga from "./SupplierIntegration/saga";
import CannedResponsesSaga from "./cannedResponses/saga";
import MessageTemplateSaga from "./notificationMessageTemplate/saga";
import ThirdPartyCompanySaga from "./thirdPartyCompany/saga";
import ContactSaga from "./Contact/saga";
import AccountSyncSaga from "./accountSync/saga";
import StockSaga from "./Stock/saga";
import InspectionDetailsSaga from "./InspectionDetails/saga";
import marketingSaga from './marketing/saga'
import reportsSaga from "./reports/saga";
import clockTimerSaga from "./jobDetails/clockSaga";
import SubscriptionTransactionSaga from "./subscriptionTransaction/saga";
import EmployeeClockingHistorySaga from "./employeeClockingHistory/saga";

// HQ Sagas start
import HQWorkshopListSaga from "views/hq/store/workshop/saga";
// HQ Sagas end


export default function* rootSaga() {
  yield all([
    LoginSaga(),
    RegisterSaga(),
    ForgotPasswordSaga(),
    ForgotEmailSaga(),
    NotificationSaga(),
    NotificationListSaga(),
    UserProfileSaga(),
    ChangePasswordSaga(),
    GlobalSearchSaga(),
    SchedulerSaga(),
    OpenQuotesSaga(),
    WonQuotesSaga(),
    LostQuotesSaga(),
    UnassignedJobsSaga(),
    assignedJobsSaga(),
    inProgressJobsSaga(),
    completedJobsSaga(),
    CommonApiStoreSaga(),
    SubscriptionSaga(),
    SubscriptionAddonSaga(),
    JobPartSaga(),
    PartSaga(),
    JobDetails(),
    SupplierSaga(),
    CompanyDetailSaga(),
    ContactsSaga(),
    defaultSettingsSaga(),
    SecurityGroupSaga(),
    AssetsSaga(),
    QuoteDetails(),
    QuotePartSaga(),
    invoiceSaga(),
    invoicePaymentSaga(),
    StatusSaga(),
    QuoteLabourSaga(),
    JobLabourSaga(),
    invoiceDetailSaga(),
    workshopBaysSaga(),
    cashSaleSaga(),
    DigitalInspectionSaga(),
    PurchaseOrderSaga(),
    StorageDevicesSaga(),
    DashboardSaga(),
    InspectionSaga(),
    VehicleSaga(),
    SyncAccountSaga(),
    ListSaga(),
    bookJobOnlineSaga(),
    watchLeadsSaga(),
    marketingSaga(),
    PaymentIntegrationSaga(),
    SupplierIntegrationSaga(),
    CannedResponsesSaga(),
    MessageTemplateSaga(),
    ThirdPartyCompanySaga(),
    ContactSaga(),
    AccountSyncSaga(),
    StockSaga(),
    InspectionDetailsSaga(),
    reportsSaga(),
    clockTimerSaga(),
    SubscriptionTransactionSaga(),

    //HQ Sagas start
    HQWorkshopListSaga(),
    //HQ Sagas end
    EmployeeClockingHistorySaga()
  ]);
}
