import React, { useEffect, useState, useMemo } from "react";
import { Form } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import {
    GET_VEHICLEMODEL,
    GET_VEHICLESERIES,
    GET_VEHICLES,
} from "store/commonApiStore/actionTypes";
import { useDispatch, useSelector } from "react-redux";

const CaravanFields = ({
    vehiclesFormData,
    setVehiclesFormData,
    handleVehiclesInputChange,
    transmissionData,
    checkVinData,
    checkVinExist,
    checkEngineData,
    checkEngineExist,
    checkFleetExist,
    checkFleetData,
    fieldShow
}) => {

    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    const dispatch = useDispatch();
    const currentYear = parseInt(new Date().getFullYear());
    const [vehicleMakeData, setVehicleMakedata] = useState([]);
    const [vehicleModelData, setVehicleModeldata] = useState([]);
    const [VehicleSeriesData, setVehicleSeriesdata] = useState([]);
    const [makeList, setMakeList] = useState(false);
    const [modelList, setModelList] = useState(false);
    const [seriesList, setSeriesList] = useState(false);

    const { vehicles, vehicleModel, vehicleSeries } = useSelector(
        (state) => state.CommonApiStoreReducer
    );

    const seatOption = [];
    seatOption.push(<option value="">Select Seat Capacity</option>);
    for (let i = 1; i <= 22; i++) {
        seatOption.push(<option value={i}>{i}</option>);
    }

    const cylinderOption = [];
    cylinderOption.push(<option value="">Select Cylinder</option>);
    for (let i = 1; i <= 12; i++) {
        cylinderOption.push(<option value={i}>{i}</option>);
    }

    const sleepsOption = [];
    sleepsOption.push(<option value="">Select People</option>);
    for (let i = 1; i <= 10; i++) {
        sleepsOption.push(<option value={i}>{i}</option>);
    }

    useEffect(() => {
        dispatch({
            type: GET_VEHICLES,
            payload: {
                get: "all",
                conditions: {
                    "vehicle_type": "4",
                },
                group: "assets_make",
                order: {
                    "assets_make": "ASC"
                }
            },
        });
    }, [])

    useEffect(() => {

        if (vehiclesFormData.make !== "") {
            dispatch({
                type: GET_VEHICLEMODEL,
                payload: {
                    get: "all",
                    conditions: {
                        assets_make: vehiclesFormData.make,
                    },
                },
            });
        }

        if (vehicles.length != 0) {
            let filteredData = [];
            vehicles.filter((el) => {
                if (el.assets_make.toLowerCase().includes(vehiclesFormData.make.toLowerCase()))
                    filteredData.push(el);
            });

            setVehicleMakedata(filteredData);
        }

    }, [vehiclesFormData.make])


    useEffect(() => {
        if (vehiclesFormData.model_id > 0 || vehiclesFormData.asset_master_id > 0) {
            dispatch({
                type: GET_VEHICLESERIES,
                payload: {
                    get: "all",
                    conditions: {
                        asset_master_id: vehiclesFormData.model_id || vehiclesFormData.asset_master_id,
                    },
                    group: ["series"],
                    fields: ["id", "series", "asset_master_id"],
                },
            });
        }

        if (vehicleModel.length != 0) {
            let filteredData = [];
            vehicleModel.filter((el) => {
                if (el.assets_model.toLowerCase().includes(vehiclesFormData.model.toLowerCase()))
                    filteredData.push(el);
            });
            setVehicleModeldata(filteredData);
        }
    }, [vehiclesFormData.model]);


    useEffect(() => {
        if (vehicleSeries.length !== 0) {
            let filteredSeriesData = [];
            vehicleSeries.filter((el) => {
                if (el.series.toLowerCase().includes(vehiclesFormData.model_series.toLowerCase()))
                    filteredSeriesData.push(el);
            });
            setVehicleSeriesdata(filteredSeriesData);
        }
    }, [vehiclesFormData.model_series]);


    return (
        <>
            {/* First Section START */}
            <div className="form-outer vin-engine-section" >
                {/* Make */}
                <Form.Group className="form-group width-33 mb-3 auto-complete-wrapper">
                    <label>Make</label>
                    <Form.Control
                        type="text"
                        name="make"
                        placeholder="Enter make"
                        onChange={(e) => {
                            handleVehiclesInputChange(e)
                            setMakeList(true);
                        }}
                        value={vehiclesFormData.make}
                    />
                    {vehicleMakeData.length !== 0 && vehiclesFormData.make !== "" && makeList && (
                        <ul className="auto-complete-list">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={200}
                                className="custom-scrollbar"
                                style={{ width: "100%" }}
                                renderThumbVertical={(props) => (
                                    <div
                                        {...props}
                                        className="thumb-vertical grey-thumb"
                                    />
                                )}
                            >
                                {vehicleMakeData.map(({ assets_make, id }, index) => (
                                    <li
                                        key={index}
                                        name="make"
                                        onClick={() => {
                                            setVehiclesFormData({
                                                ...vehiclesFormData,
                                                make: assets_make,
                                                make_id: id,
                                            })
                                            setMakeList(false);
                                        }
                                        }
                                    >
                                        {assets_make}
                                    </li>
                                ))}
                            </Scrollbars>
                        </ul>
                    )}
                </Form.Group>

                {/* Modal */}
                <Form.Group className="form-group auto-complete-wrapper width-33 mb-3">
                    <label>Model</label>
                    <Form.Control
                        type="text"
                        name="model"
                        placeholder="Enter model"
                        onChange={(e) => {
                            handleVehiclesInputChange(e)
                            setModelList(true);
                        }}
                        value={vehiclesFormData.model}
                    />
                    {vehiclesFormData.model !== "" && vehicleModelData.length !== 0 && modelList && (
                        <ul className="auto-complete-list">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={200}
                                className="custom-scrollbar"
                                style={{ width: "100%" }}
                                renderThumbVertical={(props) => (
                                    <div
                                        {...props}
                                        className="thumb-vertical grey-thumb"
                                    />
                                )}
                            >
                                {vehicleModelData.map(({ assets_model, id }, index) => (
                                    <li
                                        key={index}
                                        name="model"
                                        onClick={() => {
                                            setVehiclesFormData({
                                                ...vehiclesFormData,
                                                model: assets_model,
                                                model_id: id,
                                            })
                                            setModelList(false);
                                        }
                                        }
                                    >
                                        {assets_model}
                                    </li>
                                ))}
                            </Scrollbars>
                        </ul>
                    )}
                </Form.Group>

                {/* Series */}
                <Form.Group className="form-group auto-complete-wrapper width-33 mb-3">
                    <label>Series</label>
                    <Form.Control
                        type="text"
                        name="model_series"
                        onChange={(e) => {
                            handleVehiclesInputChange(e)
                            setSeriesList(true);
                        }}
                        value={vehiclesFormData.model_series}
                        placeholder="Enter series"
                    />
                    {vehiclesFormData.model_series !== "" && VehicleSeriesData.length !== 0 && seriesList && (
                        <ul className="auto-complete-list">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={200}
                                className="custom-scrollbar"
                                style={{ width: "100%" }}
                                renderThumbVertical={(props) => (
                                    <div
                                        {...props}
                                        className="thumb-vertical grey-thumb"
                                    />
                                )}
                            >
                                {VehicleSeriesData.map(({ series, id }, index) => (
                                    <li
                                        key={index}
                                        name="model_series"
                                        onClick={(e) => {
                                            setVehiclesFormData({
                                                ...vehiclesFormData,
                                                model_series: series,
                                                series_id: id,
                                            })
                                            setSeriesList(false);
                                        }}
                                    >
                                        {series}
                                    </li>
                                ))}
                            </Scrollbars>
                        </ul>
                    )}
                </Form.Group>

                {/* Fuel Type */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Fuel Type</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="fuel_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.fuel_type}
                    >
                        <option value="">Select Fuel Type</option>
                        <option value="PETROL">PETROL</option>
                        <option value="DIESEL">DIESEL</option>
                    </Form.Select>
                </Form.Group>

                {/* Body Type */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Body Type</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="body_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.body_type}
                    >
                        <option value="">Select Body Type</option>
                        <option value="Caravan">Caravan</option>
                        <option value="Off-Road Caravans">Off-Road Caravans</option>
                        <option value="Pop Top Caravans">Pop Top Caravans</option>
                        <option value="Pop-Out Caravan">Pop-Out Caravan</option>
                        <option value="Wind Up Caravan">Wind Up Caravan</option>
                        <option value="Teardrop Camper">Teardrop Camper</option>
                        <option value="Camper Trailers">Camper Trailers</option>
                        <option value="Capmervans">Capmervans</option>
                        <option value="Toy Haulers">Toy Haulers</option>
                        <option value="Expanda">Expanda</option>
                        <option value="PopTops">PopTops</option>
                        <option value="J-Pods">J-Pods</option>
                        <option value="Fifth Wheelers">Fifth Wheelers</option>
                    </Form.Select>
                </Form.Group>


                <div className="form-group width-33 mb-3 d-flex gap-2">
                    {/* Month */}
                    <div className="p-0 m-0" style={{ maxWidth: 50 }}>
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Month</label>
                            <Form.Control
                                type="text"
                                name="month"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                onBlur={(e) => handleVehiclesInputChange(e, true)}
                                value={vehiclesFormData.month}
                                autoComplete="off"
                            />
                        </Form.Group>
                    </div>
                    <div className="flex-1 p-0">
                        {/* Year */}
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Year</label>
                            <Form.Select
                                aria-label="Default select example"
                                name="year"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                value={vehiclesFormData.year}
                            >
                                <option value="">Select Year</option>
                                {[...Array(currentYear - 1949)].map(
                                    (data, index) => (
                                        <option
                                            value={currentYear - index}
                                            key={index}
                                        >
                                            {currentYear - index}
                                        </option>
                                    )
                                )}
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                {/* Transmission */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Transmission</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="transmission"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.transmission}
                    >
                        <option value="">Select transmission</option>
                        {
                            transmissionData.length !== 0 &&
                            transmissionData.map((trans, index) => (
                                <option key={index} value={trans.id}>{trans.name}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>

                {/* Drivetrain */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Drivetrain</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="category"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.category}
                    >
                        <option value="">Select Drivetrain</option>
                        <option value="Front Wheel Drive(FWD)">
                            Front Wheel Drive(FWD)
                        </option>
                        <option value="Rear Wheel Drive(RWD)">
                            Rear Wheel Drive(RWD)
                        </option>
                        <option value="4 Wheel Drive(4WD)">
                            4 Wheel Drive(4WD)
                        </option>
                        <option value="All Wheel Drive(AWD)">
                            All Wheel Drive(AWD)
                        </option>
                    </Form.Select>
                </Form.Group>

                {/* Colour */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Colour</label>
                    <Form.Control
                        type="text"
                        name="colour"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.colour}
                    />
                </Form.Group>

                {/* Seat Capacity */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Seat Capacity</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="seat_capacity"
                        value={vehiclesFormData.seat_capacity}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {seatOption}
                    </Form.Select>
                </Form.Group>

                {/* Tyre Size - Front */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Tyre Size - Front</label>
                    <Form.Control
                        type="text"
                        name="tyre_size_front"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.tyre_size_front}
                    />
                </Form.Group>

                {/* Tyre Size - Rear */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Tyre Size - Rear</label>
                    <Form.Control
                        type="text"
                        name="tyre_size_rear"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.tyre_size_rear}
                    />
                </Form.Group>

                {/* Cylinder */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Cylinder</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="cyliners"
                        value={vehiclesFormData.cyliners}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {cylinderOption}
                    </Form.Select>
                </Form.Group>

                {/* Engine Type */}
                <Form.Group className=" form-group width-33 mb-3">
                    <label>Engine Type</label>
                    <Form.Control
                        type="text"
                        name="engine_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.engine_type}
                    />
                </Form.Group>

                {/* Engine Size */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Engine Size</label>
                    <Form.Control
                        type="text"
                        name="engine_size"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.engine_size}
                    />
                </Form.Group>

                {/* Travel Height(mm) */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Travel Height (mm)</Form.Label>
                    <Form.Control
                        type="text"
                        name="travel_height"
                        value={vehiclesFormData.travel_height}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Travel Length(mm) */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Travel Length (mm)</Form.Label>
                    <Form.Control
                        type="text"
                        name="travel_length"
                        value={vehiclesFormData.travel_length}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Tare Weight */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Tare Weight (kg)</Form.Label>
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="tare_weight"
                        value={vehiclesFormData.tare_weight}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Aggregate Trailer Mass(ATM) */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Aggregate Trailer Mass (ATM)</Form.Label>
                    <Form.Control
                        type="text"
                        name="aggregate_trailer_mass"
                        value={vehiclesFormData.aggregate_trailer_mass}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Tow Ball Weight(kg) */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Tow Ball Weight (kg)</Form.Label>
                    <Form.Control
                        type="text"
                        name="tow_ball_weight"
                        value={vehiclesFormData.tow_ball_weight}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Sleeps up to */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Sleeps up to</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="sleeps_up_to"
                        value={vehiclesFormData.sleeps_up_to}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {sleepsOption}
                    </Form.Select>
                </Form.Group>

                {/* Configuration */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Configuration</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="configuration"
                        value={vehiclesFormData.configuration}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        <option value="">Select Configuration</option>
                        <option value="Caravan">Caravan</option>
                        <option value="Motorhome">Motorhome</option>
                        <option value="Campervan">Campervan</option>
                        <option value="RV">RV</option>
                    </Form.Select>
                </Form.Group>

                {/* Number of Axles */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Number of Axles</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="no_of_axles"
                        value={vehiclesFormData.no_of_axles}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        <option value="">Select Axles</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </Form.Select>
                </Form.Group>

                {/* date_of_manufacture */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Date Of Manufacture</Form.Label>
                    <Form.Control
                        type="text"
                        name="date_of_manufacture"
                        value={vehiclesFormData.date_of_manufacture}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>
            </div>

            {/* Second Section Vin / Engine Section START */}
            <div className={`form-outer mb-2 vin-engine-section mt-2  hidden-form ${fieldShow ? "active" : ""}`}>
                <Form.Group className=" form-group width-50 mb-3">
                    <label>VIN Number</label>
                    <Form.Control
                        type="text"
                        name="vin_number"
                        className={
                            checkVinData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        onBlur={(e) => checkVinExist(e)}
                        value={vehiclesFormData.vin_number}
                    />
                    {checkVinData &&
                        <div className="invalid-feedback">This Vin Number is already registered.</div>
                    }
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <Form.Label>Serial Number</Form.Label>
                    <Form.Control
                        type="text"
                        name="serial_no"
                        value={vehiclesFormData.serial_no}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>
                <Form.Group
                    className={`form-group width-50 mb-3`}
                >
                    <label>Engine Number</label>
                    <Form.Control
                        type="text"
                        name="engine_number"
                        className={
                            checkEngineData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        onBlur={(e) => checkEngineExist(e)}
                        value={vehiclesFormData.engine_number}
                    />
                    {checkEngineData &&
                        <div className="invalid-feedback">
                            This Engine Number is already registered.
                        </div>
                    }
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <label>Fleet Number</label>
                    <Form.Control
                        type="text"
                        name="fleet_number"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.fleet_number}
                        onBlur={(e) => checkFleetExist(e)}
                        className={
                            checkFleetData && "form-control is-invalid"
                        }
                    />
                    {checkFleetData &&
                        <div className="invalid-feedback">
                            This Fleet Number is already registered.
                        </div>
                    }
                </Form.Group>
            </div>
            {/* Second Section Vin / Engine Section END */}
        </>
    )
}

export default CaravanFields;
