import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearReceiptDetail,
  getReceiptDetail,
} from "store/invoicePayment/actions";
import {
  deleteArchiveRaisedInvoiceAPI,
  getRaisedInvoiceAPI,
} from "store/invoices/api";
import Loader from "views/common/UI/Loader";
import PaymentComplete from "../../PaymentComplete/PaymentComplete";
import PaymentInfoProvider from "../../PaymentModal/PaymentInfoProvider";
// import Common from "./Common";
import CommonSecond from "./CommonSecond";
import { useInvoiceColumns } from "./useInvoiceColumns";
import { usePayData } from "./usePayData";
import SendSMSToUser from "views/modals/SendSMSToUser";
import JobSmsBalance from "views/modals/JobSmsBalance";
import { getSubscriptionAddon } from "helpers/backendHelper";
import SendSmsToUserModal from "../../../../modals/SendSmsToUserModal";

const RaisedInvoice = () => {

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);

  const invoice = useSelector((state) => state.invoice);
  const [deletePayload, setDeletePayload] = useState(null);
  const navigate = useNavigate();
  const payment = useSelector((state) => state.invoicePaymentReducer);
  const [openedModal, setOpenedModal] = useState("");
  const [jobStage, setJobStage] = useState();
  const [payComplete, setPayComplete] = useState(false);

  //Inspection attachment 
  const [inspStatus, setInspStatus] = useState();
  const [approvalToken, setApprovalToken] = useState();
  const [inspWorkType, setInspWorkType] = useState();
  const [inspectionId, setInspectionId] = useState();
  const [previousPaidInvoice, setPreviousPaidInvoice] = useState(false);
  const [isInvoiceB, setIsInvoiceB] = useState(false);
  const [currentPageLimit, setCurrentPageLimit] = useState(10);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentSize, setCurrentSize] = useState({ invoicePerPage: 0, saleInvoicePerPage: 0 });
  
  const handleSMSModalClose = () => setShowAddMobileModal(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { raisedInvoiceColumns } = useInvoiceColumns({
    onEdit: ({ row }) => {
      if (row.type === "sale") {
        navigate(`/edit_cash_sale_invoice/${row.id}`);
        return;
      }
      navigate(`/edit-invoice/${row.id}`);
    },
    onPayment: ({row}) => {
      if (row.type === "sale") {
        setMobileNumber(row?.data?.user?.customers_details?.[0]?.mobile_number);
        setMobileCode(row?.data?.user?.customers_details?.[0]?.mobile_code);
        setCustomerId(row?.data?.user?.customers_details?.[0]?.user_id);
        setCustomerEmail(row?.data?.user?.email);
        setCustId(row?.data?.user?.customers_details?.[0]?.id)
        setVehicleRego(row?.data?.vehicle?.rego);
        setInvoiceType("cashsale");
        setInvoiceId(row?.id);
        var name = "";
        if(row?.data?.user?.customers_details?.[0]?.type == 2){
          name = row?.data?.user?.customers_details?.[0]?.firstname;
        }else{
          name = row?.data?.user?.customers_details?.[0]?.company_name;
        }
        setCustomerName(name)
        setInvoiceNumber(row?.invoice_number)
        dispatch(
          getReceiptDetail({
            id: row.id,
            invoiceType: row.type === "sale" ? "cash_sale" : "invoice",
          })
        );
        setOpenedModal("payment");

      }else{
        let invoice = row?.data ?? {}; 
        let jobData = row?.data?.job_schedule ?? {}; 
        let customerDetail = row?.data?.job_schedule?.customer?.customers_details?.[0] ?? {};
        let attachment = row?.data?.job_schedule?.job_attachment_list ?? {};
        
        setVehicleRego(row?.data?.job_schedule?.vehicle?.rego);
        setInvoiceType("invoice");
        setInvoiceId(row?.id);
        setIsInvoiceB(false);

        let tmpCustomerName = "";
        tmpCustomerName = customerDetail?.type == 2
          ? customerDetail?.firstname + " " + customerDetail?.lastname
          : customerDetail?.company_name

        if ((jobData?.show_allocation_column == "1" && invoice?.invoice_allocation_type == "2") 
            || (jobData?.show_allocation_column == "0" && invoice?.insurance_preview_invoice == "1")) {
            
            setMobileNumber(invoice?.insurance_company?.phone_number); 
            setCustomerId(invoice?.insurance_company?.id);
            setCustomerEmail(invoice?.insurance_company?.email);
            setIsInvoiceB(true);
            tmpCustomerName = invoice?.insurance_company?.company_name;
        } else if (jobData?.show_allocation_column == "1" && invoice?.invoice_allocation_type == "3") {
            
            setMobileNumber(jobData?.invoice_c_customer?.mobile_number);
            setMobileCode(jobData?.invoice_c_customer?.mobile_code);
            setCustomerId(jobData?.invoice_c_customer?.user_id);
            setCustId(jobData?.invoice_c_customer?.id);
            setCustomerEmail(jobData?.invoice_c_customer?.user?.email);
            tmpCustomerName = jobData?.invoice_c_customer?.type == 2
              ? jobData?.invoice_c_customer?.firstname + " " + jobData?.invoice_c_customer?.lastname
              : jobData?.invoice_c_customer?.company_name
        }else {
            setMobileNumber(customerDetail?.mobile_number);
            setMobileCode(customerDetail?.mobile_code);
            setCustomerId(customerDetail?.user_id);
            setCustomerEmail(row?.data?.job_schedule?.customer?.email);
            setCustId(customerDetail?.id);
        }

        if(attachment){
          setInspStatus(attachment?.Insp_status || "");
          setApprovalToken(attachment?.approval_token || "");
          setInspWorkType(attachment?.insp_work_type || "");
          // setInspectionId(row?.data?.job_schedule?.job_attachment_list?.inspections_id || "");
        }
        setCustomerName(tmpCustomerName);
        setInvoiceNumber(row?.invoice_number);
        dispatch(
          getReceiptDetail({
            id: row.id,
            invoiceType: row.type === "sale" ? "cash_sale" : "invoice",
          })
        );
        setOpenedModal("payment");
        setJobStage(row?.data?.job_schedule?.job_stage);
      }
    },
    onMail: ({ row }) => {
      if (row.type === "sale") {
        navigate(`/email_cash_sale/${row.id}`);
        return;
      }
      navigate(`/invoice_email/${row.id}`);
    },
    onSms: ({ row }) => {
      if (row.type === "sale") {
        setMobileNumber(row?.data?.user?.customers_details?.[0]?.mobile_number);
        setMobileCode(row?.data?.user?.customers_details?.[0]?.mobile_code);
        setCustomerId(row?.data?.user?.customers_details?.[0]?.user_id);
        setCustomerEmail(row?.data?.user?.email);
        setCustId(row?.data?.user?.customers_details?.[0]?.id);
        setVehicleRego(row?.data?.vehicle?.rego);
        setInvoiceType("cashsale");
        setInvoiceId(row?.id);
        var name = "";
        if(row?.data?.user?.customers_details?.[0]?.type == 2){
          name = row?.data?.user?.customers_details?.[0]?.firstname;
        }else{
          name = row?.data?.user?.customers_details?.[0]?.company_name;
        }
        setCustomerName(name)
        setInvoiceNumber(row?.invoice_number)
      }else{                   
        setMobileNumber(row?.data?.job_schedule?.customer?.customers_details?.[0]?.mobile_number);
        setMobileCode(row?.data?.job_schedule?.customer?.customers_details?.[0]?.mobile_code);
        setCustomerId(row?.data?.job_schedule?.customer?.customers_details?.[0]?.user_id);
        setCustomerEmail(row?.data?.job_schedule?.customer?.email);
        setCustId(row?.data?.job_schedule?.customer?.customers_details?.[0]?.id);
        setVehicleRego(row?.data?.job_schedule?.vehicle?.rego);
        setInvoiceType("invoice");
        setInvoiceId(row?.id);
        setIsInvoiceB(false);

        let jobData = row?.data?.job_schedule;
        let customerData = row?.data?.job_schedule?.customer?.customers_details?.[0];
        let tmpCustomerName = "";
        tmpCustomerName = customerData?.type == 2
          ? customerData?.firstname + " " + customerData?.lastname
          : customerData?.company_name
        if((jobData?.show_allocation_column == "1" && row?.data?.invoice_allocation_type == "2") || (jobData?.show_allocation_column == "0" && row?.data?.insurance_preview_invoice == "1")){           
          setMobileNumber(row?.data?.insurance_company?.phone_number); 
          setCustomerId(row?.data?.insurance_company?.id);
          setIsInvoiceB(true); 
          tmpCustomerName = row?.data?.insurance_company?.company_name;
        }else if(jobData?.show_allocation_column == "1" && row?.data?.invoice_allocation_type == "3"){
          setMobileNumber(jobData?.invoice_c_customer?.mobile_number);
          setMobileCode(jobData?.invoice_c_customer?.mobile_code);
          setCustomerId(jobData?.invoice_c_customer?.user_id);
          setCustId(jobData?.invoice_c_customer?.id);
          tmpCustomerName = jobData?.invoice_c_customer?.type == 2
          ? jobData?.invoice_c_customer?.firstname + " " + jobData?.invoice_c_customer?.lastname
          : jobData?.invoice_c_customer?.company_name      
        }

        if(row?.data?.job_schedule?.job_attachment_list){
          setInspStatus(row?.data?.job_schedule?.job_attachment_list?.Insp_status || "");
          setApprovalToken(row?.data?.job_schedule?.job_attachment_list?.approval_token || "");
          setInspWorkType(row?.data?.job_schedule?.job_attachment_list?.insp_work_type || "");
          setInspectionId(row?.data?.job_schedule?.inspections_id || "");
        }
        setCustomerName(tmpCustomerName);
        setInvoiceNumber(row?.invoice_number)
      }
      
      getSMSBalanceData();
      // navigate(`/invoice_email/${row.id}`);
    },
    onPrint: ({ row }) => {
      if (row.type === "sale") {
        // return;
        navigate(`/print_cash_sale/${row.id}`);
        return;
      }
      navigate(`/invoice_print/${row.id}`);
    },
    // onGoPay: () => {},
    onDelete: ({ row }) => {
      setDeletePayload([row]);
    },
    // onCreditClick:()=>{},
    // onView:()=>{},
  });

  const payData = usePayData(payment?.recieptDetail?.data);

  function hideModal() {
    setOpenedModal("");
    dispatch(clearReceiptDetail());
  }
  function onChangeClick() {
    setOpenedModal("payment");
  }

  useEffect(() => {
    let areAllInvoicesStatusTwo = payment?.recieptDetail?.data?.data?.job_schedule?.invoices?.filter((invoice) => invoice.id !== payment?.recieptDetail?.data?.data?.id)?.every((invoice) => invoice.invoice_status === "2"); 
    if(areAllInvoicesStatusTwo){
      setPreviousPaidInvoice(areAllInvoicesStatusTwo);
    }else{
      setPreviousPaidInvoice(false);
    }
  }, [payment?.recieptDetail?.data]);

  useEffect(() => {
    if (payment?.createdReceipt) {
      setPayComplete(true);
      setOpenedModal("");
    }
  }, [payment?.creating, payment?.createdReceipt]);

  const deleteEvent = (cb) => {
    const saleIds = deletePayload
      .filter((c) => c.type === "sale")
      .map((c) => c.id);
    const invoiceIds = deletePayload
      .filter((c) => c.type !== "sale")
      .map((c) => c.id);

    const saleInvoice = {},
      invoice = {};
    saleInvoice.action_type = "delete";
    invoice.action_type = "delete";
    saleInvoice.ids = saleIds;
    invoice.ids = invoiceIds;

    deleteArchiveRaisedInvoiceAPI(dispatch)({ saleInvoice, invoice });
    cb();
  };

  const [showAddMobileModal, setShowAddMobileModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const [mobileCode, setMobileCode] = useState();
  const [customerId, setCustomerId] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [cust_id, setCustId] = useState();
  const [vehicleRego, setVehicleRego] = useState();
  const [invoiceType, setInvoiceType] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [customerName, setCustomerName] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState();  
  const [updateMobileNumber,setUpdateMobileNumber] = useState(false);

  // const [isSmsSubscribe, setIsSmsSubscribe] = useState(0);
  const [showSmsBalanceModal, setShowSmsBalanceModal] = useState(false);
  const handleShowSmsBalanceModal = () => setShowSmsBalanceModal(true);
  const handleCloseSmsBalanceModal = () => setShowSmsBalanceModal(false);

  async function getSMSBalanceData(){
    let data = {
      payload: {
        get: "all",
        conditions: {
          "WorkshopSubscriptionDetails.plan_type": "sms",
          "WorkshopSubscriptionDetails.current_sms_limit >": 0,
          "WorkshopSubscriptionDetails.workshop_id": workshop_id,
        },
        fields: [
          "id",
          "workshop_subscription_id",
          "subscription_plan_id",
          "sms_limit",
          "current_sms_limit",
        ],
        order: { "WorkshopSubscriptionDetails.id": "DESC" },
      },
    };
    let response = await getSubscriptionAddon(data);

    if (response.code === 200) {
      setShowAddMobileModal(true);
    } else {
      handleShowSmsBalanceModal();
    }
  }

  return (
    <>
      {isLoading === true && (<Loader />)}
      {(payment?.recieptDetail?.loading || payment?.creating) && <Loader />}
      {invoice?.raisedInvoices?.invoiceDeleteStatus === "started" && <Loader />}
      <CommonSecond
        deletePayload={deletePayload}
        setDeletePayload={setDeletePayload}
        api={getRaisedInvoiceAPI}
        columns={raisedInvoiceColumns}
        // countApi={getRaisedInvoicesCountAPI}
        main={invoice?.raisedInvoices}
        onCreateCashSale={() => {}}
        onRowClick={(row) => {
          if (row.type === "sale") {
            navigate(`/edit_cash_sale_invoice/${row.id}`);
            return;
          }
          navigate(`/edit-invoice/${row.id}`);
        }}
        onSearch={() => {}}
        deleteInvoices={deleteEvent}
        invoiceListType={"1"}
        setCurrentPageLimit={setCurrentPageLimit}
        setCurrentPageNumber={setCurrentPageNumber}
        setCurrentSize={setCurrentSize}
      />
      <PaymentInfoProvider
        hideModal={hideModal}
        payment={payment}
        onChangeClick={onChangeClick}
        openedModal={openedModal}
        setOpenedModal={setOpenedModal}
        summary={payData}
        jobStage={jobStage}
        previousPaidInvoice={previousPaidInvoice}
      />
      <PaymentComplete
        show={payComplete}
        onHide={() => {
          setPayComplete(false);
        }}
        amountPaid={payment?.createdReceipt?.amount_paid}
        paymentType={
          payment?.createdReceipt?.payment_method === "1"
            ? "Cash"
            : payment?.createdReceipt?.payment_method === "4"
            ? "BNPL"
            : payment?.createdReceipt?.payment_method === "5"
            ? "Cheque"
            : "Card"
        }
        cust_id={cust_id}
        mobile_number={mobileNumber}
        mobileCode={mobileCode}
        customerId={customerId}
        vehicleRego={vehicleRego}
        customerName={customerName}
        number={invoiceNumber}
        customerEmail={customerEmail}
        isInvoiceB={isInvoiceB}
      />

        {/* {showAddMobileModal && (
          <SendSMSToUser
              cust_id={cust_id}
              workshop_id = {workshop_id}
              type = {invoiceType}
              id = {invoiceId}
              customer_id = {customerId}
              mobileCode = {mobileCode}
              rego = {vehicleRego}
              setShowAddMobileModal = {setShowAddMobileModal}
              updateMobileNumber={updateMobileNumber}
              setUpdateMobileNumber={setUpdateMobileNumber}
              mobileNumberForCustomer={mobileNumber}
              setMobileNumberForCustomer={setMobileNumber}
              customerName={customerName}
              inspStatus={inspStatus}
              approvalToken={approvalToken}
              inspWorkType={inspWorkType}
              number={invoiceNumber}
              inspectionId={inspectionId}
          />)
        } */}

        {showAddMobileModal && (          
          <SendSmsToUserModal
            rego={vehicleRego}
            workshop_id={workshop_id}
            type = {invoiceType}
            id = {invoiceId}
            cust_id={cust_id}
            customer_id={customerId}
            mobileCode={mobileCode}
            SMSModalShow={showAddMobileModal}
            handleSMSModalClose={handleSMSModalClose}
            customerName={customerName}
            number={invoiceNumber}           
            setIsLoading={setIsLoading}
            mobileNumberForCustomer={mobileNumber}
            setMobileNumberForCustomer={setMobileNumber}
            isInvoiceB={isInvoiceB} 
            pageType={"raised_invoice"}
            currentPageLimit={currentPageLimit}
            currentPageNumber={currentPageNumber}
            currentSize={currentSize}
          />)          
        }

        {showSmsBalanceModal && (
            <JobSmsBalance
                showSmsBalanceModal={showSmsBalanceModal}
                handleShowSmsBalanceModal={handleShowSmsBalanceModal}
                handleCloseSmsBalanceModal={handleCloseSmsBalanceModal}
            />
        )}
    </>
  );
};

export default RaisedInvoice;
