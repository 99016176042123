import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const ContactHelp = Loadable({
  loader: () => import("./components/ContactHelp"),
  loading: Loading,
});

export const routes = [
  {
    path: "/contact-help",
    exact: true,
    name: "contactHelp",
    element: ContactHelp,
  },
];

export * from "./components";
