export const CONNECT_QUICKBOOK = "CONNECT_QUICKBOOK";
export const CONNECT_QUICKBOOK_SUCCESS = "CONNECT_QUICKBOOK_SUCCESS";
export const CONNECT_QUICKBOOK_FAIL = "CONNECT_QUICKBOOK_FAIL";

export const DISCONNECT_QUICKBOOK = "DISCONNECT_QUICKBOOK";
export const DISCONNECT_QUICKBOOK_SUCCESS = "DISCONNECT_QUICKBOOK_SUCCESS";
export const DISCONNECT_QUICKBOOK_FAIL = "DISCONNECT_QUICKBOOK_FAIL";

export const DISCONNECT_MYOB = "DISCONNECT_MYOB";
export const DISCONNECT_MYOB_SUCCESS = "DISCONNECT_MYOB_SUCCESS";
export const DISCONNECT_MYOB_FAIL = "DISCONNECT_MYOB_FAIL";

export const UPDATE_WORKSHOP_DETAIL = "UPDATE_WORKSHOP_DETAIL";
export const UPDATE_WORKSHOP_DETAIL_SUCCESS = "UPDATE_WORKSHOP_DETAIL_SUCCESS";
export const UPDATE_WORKSHOP_DETAIL_FAIL = "UPDATE_WORKSHOP_DETAIL_FAIL";

export const GET_SYNC_ACCOUNT_DATA = "GET_SYNC_ACCOUNT_DATA";
export const GET_SYNC_ACCOUNT_DATA_SUCCESS = "GET_SYNC_ACCOUNT_DATA_SUCCESS";
export const GET_SYNC_ACCOUNT_DATA_FAIL = "GET_SYNC_ACCOUNT_DATA_FAIL";

export const GET_SYNC_TAX_DATA = "GET_SYNC_TAX_DATA";
export const GET_SYNC_TAX_DATA_SUCCESS = "GET_SYNC_TAX_DATA_SUCCESS";
export const GET_SYNC_TAX_DATA_FAIL = "GET_SYNC_TAX_DATA_FAIL";

export const UPDATE_SYNC_ACCOUNT_DATA = "UPDATE_SYNC_ACCOUNT_DATA";
export const UPDATE_SYNC_ACCOUNT_DATA_SUCCESS = "UPDATE_SYNC_ACCOUNT_DATA_SUCCESS";
export const UPDATE_SYNC_ACCOUNT_DATA_FAIL = "UPDATE_SYNC_ACCOUNT_DATA_FAIL";

export const UPDATE_SYNC_TAX_DATA = "UPDATE_SYNC_TAX_DATA";
export const UPDATE_SYNC_TAX_DATA_SUCCESS = "UPDATE_SYNC_TAX_DATA_SUCCESS";
export const UPDATE_SYNC_TAX_DATA_FAIL = "UPDATE_SYNC_TAX_DATA_FAIL";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const GET_USERS_TECHNICIAN = "GET_USERS_TECHNICIAN";
export const GET_USERS_TECHNICIAN_SUCCESS = "GET_USERS_TECHNICIAN_SUCCESS";
export const GET_USERS_TECHNICIAN_FAIL = "GET_USERS_TECHNICIAN_FAIL";

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL";

export const GET_INVOICE_PAYMENTS_DATA = "GET_INVOICE_PAYMENTS_DATA";
export const GET_INVOICE_PAYMENTS_DATA_SUCCESS = "GET_INVOICE_PAYMENTS_DATA_SUCCESS";
export const GET_INVOICE_PAYMENTS_DATA_FAIL = "GET_INVOICE_PAYMENTS_DATA_FAIL";

export const GET_INVOICE_PAYMENTS_CREDIT = "GET_INVOICE_PAYMENTS_CREDIT";
export const GET_INVOICE_PAYMENTS_CREDIT_SUCCESS = "GET_INVOICE_PAYMENTS_CREDIT_SUCCESS";
export const GET_INVOICE_PAYMENTS_CREDIT_FAIL = "GET_INVOICE_PAYMENTS_CREDIT_FAIL";

export const SYNC_TRANSFER = "SYNC_TRANSFER";
export const SYNC_TRANSFER_SUCCESS = "SYNC_TRANSFER_SUCCESS";
export const SYNC_TRANSFER_FAIL = "SYNC_TRANSFER_FAIL";

export const GET_SYNC_DATA = "GET_SYNC_DATA";
export const GET_SYNC_DATA_SUCCESS = "GET_SYNC_DATA_SUCCESS";
export const GET_SYNC_DATA_FAIL = "GET_SYNC_DATA_FAIL";

export const UPDATE_MANY_WORKSHOP_TAX_DETAILS = "UPDATE_MANY_WORKSHOP_TAX_DETAILS";
export const UPDATE_MANY_WORKSHOP_TAX_DETAILS_SUCCESS = "UPDATE_MANY_WORKSHOP_TAX_DETAILS_SUCCESS";
export const UPDATE_MANY_WORKSHOP_TAX_DETAILS_FAIL = "UPDATE_MANY_WORKSHOP_TAX_DETAILS_FAIL";

export const UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS = "UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS";
export const UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_SUCCESS = "UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_SUCCESS";
export const UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_FAIL = "UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_FAIL";

export const DELETE_SYNC_ACCOUNTS = "DELETE_SYNC_ACCOUNTS";
export const DELETE_SYNC_ACCOUNTS_SUCCESS = "DELETE_SYNC_ACCOUNTS_SUCCESS";
export const DELETE_SYNC_ACCOUNTS_FAIL = "DELETE_SYNC_ACCOUNTS_FAIL";

export const DELETE_SYNC_TAX = "DELETE_SYNC_TAX";
export const DELETE_SYNC_TAX_SUCCESS = "DELETE_SYNC_TAX_SUCCESS";
export const DELETE_SYNC_TAX_FAIL = "DELETE_SYNC_TAX_FAIL";

export const CONNECT_XERO = "CONNECT_XERO";
export const CONNECT_XERO_SUCCESS = "CONNECT_XERO_SUCCESS";
export const CONNECT_XERO_FAIL = "CONNECT_XERO_FAIL";

export const DISCONNECT_XERO = "DISCONNECT_XERO";
export const DISCONNECT_XERO_SUCCESS = "DISCONNECT_XERO_SUCCESS";
export const DISCONNECT_XERO_FAIL = "DISCONNECT_XERO_FAIL";

export const IMPORT_MYOB = "IMPORT_MYOB";
export const IMPORT_MYOB_SUCCESS = "IMPORT_MYOB_SUCCESS";
export const IMPORT_MYOB_FAIL = "IMPORT_MYOB_FAIL";

export const GET_CASH_SALE_INVOICE = "GET_CASH_SALE_INVOICE";
export const GET_CASH_SALE_INVOICE_SUCCESS = "GET_CASH_SALE_INVOICE_SUCCESS";
export const GET_CASH_SALE_INVOICE_FAIL = "GET_CASH_SALE_INVOICE_FAIL";

export const GET_CASH_SALE_INVOICE_PAYMENT = "GET_CASH_SALE_INVOICE_PAYMENT";
export const GET_CASH_SALE_INVOICE_PAYMENT_SUCCESS = "GET_CASH_SALE_INVOICE_PAYMENT_SUCCESS";
export const GET_CASH_SALE_INVOICE_PAYMENT_FAIL = "GET_CASH_SALE_INVOICE_PAYMENT_FAIL";

export const GET_CASH_SALE_INVOICE_PAYMENT_CREDIT = "GET_CASH_SALE_INVOICE_PAYMENT_CREDIT";
export const GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_SUCCESS = "GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_SUCCESS";
export const GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_FAIL = "GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_FAIL";

export const ADD_EMPLOYEE_CLOCKING_HISTORY = "ADD_EMPLOYEE_CLOCKING_HISTORY";
export const ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS = "ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS";
export const ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL = "ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL";

export const UPDATE_EMPLOYEE_CLOCKING_HISTORY = "UPDATE_EMPLOYEE_CLOCKING_HISTORY";
export const UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS = "UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS";
export const UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL = "UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL";

export const CRON_SYNC_TRANSFER = "CRON_SYNC_TRANSFER";
export const CRON_SYNC_TRANSFER_SUCCESS = "CRON_SYNC_TRANSFER_SUCCESS";
export const CRON_SYNC_TRANSFER_FAIL = "CRON_SYNC_TRANSFER_FAIL";