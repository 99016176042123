export const GET_INVOICE_EOD_CHART = "GET_INVOICE_EOD_CHART";
export const GET_INVOICE_EOD_CHART_SUCCESS = "GET_INVOICE_EOD_CHART_SUCCESS";
export const GET_INVOICE_EOD_CHART_FAIL = "GET_INVOICE_EOD_CHART_FAIL";

export const GET_INVOICE_VALUE_CHART = "GET_INVOICE_VALUE_CHART";
export const GET_INVOICE_VALUE_CHART_SUCCESS =
  "GET_INVOICE_VALUE_CHART_SUCCESS";
export const GET_INVOICE_VALUE_CHART_FAIL = "GET_INVOICE_VALUE_CHART_FAIL";

export const GET_INVOICE_PAYMENT_CHART = "GET_INVOICE_PAYMENT_CHART";
export const GET_INVOICE_PAYMENT_CHART_SUCCESS =
  "GET_INVOICE_PAYMENT_CHART_SUCCESS";
export const GET_INVOICE_PAYMENT_CHART_FAIL = "GET_INVOICE_PAYMENT_CHART_FAIL";

export const SEND_STATEMENT_REPORT = "SEND_STATEMENT_REPORT";
export const SEND_STATEMENT_REPORT_SUCCESS = "SEND_STATEMENT_REPORT_SUCCESS";
export const SEND_STATEMENT_REPORT_FAIL = "SEND_STATEMENT_REPORT_FAIL";

export const GET_INVOICE_STATEMENTS = "GET_INVOICE_STATEMENTS";
export const GET_INVOICE_STATEMENTS_SUCCESS = "GET_INVOICE_STATEMENTS_SUCCESS";
export const GET_INVOICE_STATEMENTS_FAIL = "GET_INVOICE_STATEMENTS_FAIL";

export const GET_SUPPLIER_STATEMENT = "GET_SUPPLIER_STATEMENT"
export const GET_SUPPLIER_STATEMENT_SUCCESS = "GET_SUPPLIER_STATEMENT_SUCCESS"
export const GET_SUPPLIER_STATEMENT_FAILURE = "GET_SUPPLIER_STATEMENT_FAILURE"

export const GET_SUPPLIER_LIST_STATEMENT = "GET_SUPPLIER_LIST_STATEMENT"
export const GET_SUPPLIER_LIST_STATEMENT_SUCCESS = "GET_SUPPLIER_LIST_STATEMENT_SUCCESS"
export const GET_SUPPLIER_LIST_STATEMENT_FAILURE = "GET_SUPPLIER_LIST_STATEMENT_FAILURE"

export const GET_SUPPLIER_STATEMENT_COUNT = "GET_SUPPLIER_STATEMENT_COUNT"
export const GET_SUPPLIER_STATEMENT_COUNT_SUCCESS = "GET_SUPPLIER_STATEMENT_COUNT_SUCCESS"
export const GET_SUPPLIER_STATEMENT_COUNT_FAILURE = "GET_SUPPLIER_STATEMENT_COUNT_FAILURE"

export const GET_SUPPLIER_LIST_STATEMENT_COUNT = "GET_SUPPLIER_LIST_STATEMENT_COUNT"
export const GET_SUPPLIER_LIST_STATEMENT_COUNT_SUCCESS = "GET_SUPPLIER_LIST_STATEMENT_COUNT_SUCCESS"
export const GET_SUPPLIER_LIST_STATEMENT_COUNT_FAILURE = "GET_SUPPLIER_LIST_STATEMENT_COUNT_FAILURE"

export const GET_INVOICE_STATEMENTS_COUNT = "GET_INVOICE_STATEMENTS_COUNT";
export const GET_INVOICE_STATEMENTS_COUNT_SUCCESS = "GET_INVOICE_STATEMENTS_COUNT_SUCCESS";
export const GET_INVOICE_STATEMENTS_COUNT_FAIL = "GET_INVOICE_STATEMENTS_COUNT_FAIL";

export const JOB_REPORT_TABLE = "JOB_REPORT_TABLE";
export const JOB_REPORT_TABLE_SUCCESS = "JOB_REPORT_TABLE_SUCCESS";
export const JOB_REPORT_TABLE_FAIL = "JOB_REPORT_TABLE_FAIL";

export const JOB_REPORT_FORECAST_TABLE = "JOB_REPORT_FORECAST_TABLE";
export const JOB_REPORT_FORECAST_TABLE_SUCCESS = "JOB_REPORT_FORECAST_TABLE_SUCCESS";
export const JOB_REPORT_FORECAST_TABLE_FAIL = "JOB_REPORT_FORECAST_TABLE_FAIL";

export const JOB_REPORT_STATS = "JOB_REPORT_STATS";
export const JOB_REPORT_STATS_SUCCESS = "JOB_REPORT_STATS_SUCCESS";
export const JOB_REPORT_STATS_FAIL = "JOB_REPORT_STATS_FAIL";

export const GET_QUOTE_REPORT = "GET_QUOTE_REPORT";
export const GET_QUOTE_REPORT_SUCCESS = "GET_QUOTE_REPORT_SUCCESS";
export const GET_QUOTE_REPORT_FAIL = "GET_QUOTE_REPORT_FAIL";

export const QUOTE_REPORT_FORECAST_TABLE = "QUOTE_REPORT_FORECAST_TABLE";
export const QUOTE_REPORT_FORECAST_TABLE_SUCCESS = "QUOTE_REPORT_FORECAST_TABLE_SUCCESS";
export const QUOTE_REPORT_FORECAST_TABLE_FAIL = "QUOTE_REPORT_FORECAST_TABLE_FAIL";

export const QUOTE_REPORT_STATS = "QUOTE_REPORT_STATS";
export const QUOTE_REPORT_STATS_SUCCESS = "QUOTE_REPORT_STATS_SUCCESS";
export const QUOTE_REPORT_STATS_FAIL = "QUOTE_REPORT_STATS_FAIL";

export const AVERAGE_REVENUE_CUSTOMER = "AVERAGE_REVENUE_CUSTOMER";
export const AVERAGE_REVENUE_CUSTOMER_SUCCESS = "AVERAGE_REVENUE_CUSTOMER_SUCCESS";
export const AVERAGE_REVENUE_CUSTOMER_FAIL = "AVERAGE_REVENUE_CUSTOMER_FAIL";

export const AVERAGE_REVENUE_JOBTYPE = "AVERAGE_REVENUE_JOBTYPE";
export const AVERAGE_REVENUE_JOBTYPE_SUCCESS = "AVERAGE_REVENUE_JOBTYPE_SUCCESS";
export const AVERAGE_REVENUE_JOBTYPE_FAIL = "AVERAGE_REVENUE_JOBTYPE_FAIL";

export const CUSTOMER_BASED_REVENUE = "CUSTOMER_BASED_REVENUE";
export const CUSTOMER_BASED_REVENUE_SUCCESS = "CUSTOMER_BASED_REVENUE_SUCCESS";
export const CUSTOMER_BASED_REVENUE_FAIL = "CUSTOMER_BASED_REVENUE_FAIL";

export const LABOUR_HOURS = "LABOUR_HOURS";
export const LABOUR_HOURS_SUCCESS = "LABOUR_HOURS_SUCCESS";
export const LABOUR_HOURS_FAIL = "LABOUR_HOURS_FAIL";

export const EFFECTIVE_LABOUR_RATE = "EFFECTIVE_LABOUR_RATE";
export const EFFECTIVE_LABOUR_RATE_SUCCESS = "EFFECTIVE_LABOUR_RATE_SUCCESS";
export const EFFECTIVE_LABOUR_RATE_FAIL = "EFFECTIVE_LABOUR_RATE_FAIL";

export const MATERIAL_PURCHASE_ORDER = "MATERIAL_PURCHASE_ORDER";
export const MATERIAL_PURCHASE_ORDER_SUCCESS = "MATERIAL_PURCHASE_ORDER_SUCCESS";
export const MATERIAL_PURCHASE_ORDER_FAIL = "MATERIAL_PURCHASE_ORDER_FAIL";

export const MATERIAL_STOCK_VALUE = "MATERIAL_STOCK_VALUE";
export const MATERIAL_STOCK_VALUE_SUCCESS = "MATERIAL_STOCK_VALUE_SUCCESS";
export const MATERIAL_STOCK_VALUE_FAIL = "MATERIAL_STOCK_VALUE_FAIL";

export const MATERIAL_STOCK_TAKE = "MATERIAL_STOCK_TAKE";
export const MATERIAL_STOCK_TAKE_SUCCESS = "MATERIAL_STOCK_TAKE_SUCCESS";
export const MATERIAL_STOCK_TAKE_FAIL = "MATERIAL_STOCK_TAKE_FAIL";

export const SET_STATEMENT_PRINT_DATA = "SET_STATEMENT_PRINT_DATA"