import React from 'react';

const HQFooter = () => {
    
    return (
        <div className="footer-wrapper">
            <p>Copyright {new Date().getFullYear() }  FiiViQ Pty Ltd</p>
        </div>
    )
}

export default HQFooter;
