import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import Form from "react-bootstrap/Form";
import { addComma } from "utils/numberFunction";

const TableWithPagination = ({
  page,
  sizePerPage,
  totalSize,
  rowEvents,
  data,
  columns,
  onTableChange,
  onSelectedRowChange,
  descriptionKey,
  checkBoxDisable,
  expandRowDisable,
  baseProps = {},
  statementTotal = "0",
  emptyMessage,
  invoiceListType,
  onStatementDate
}) => {

  const [selectedRow, setSelectedRow] = useState([]);

  const emptyDataMessage = () => {
    if (onStatementDate == "statement") {
      return (
        <div className={(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >
          Note - Please ensure you have set up your customer profile as an account customer
          <br />
          Go to - Customer profile > Select customer default > Turn on Account setup
        </div>
      );
    } else {
      return (
        <div className={(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
      );
    }

  };

  const checkBox = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.findIndex((r) => r.id === row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedRow(rows);
      } else {
        setSelectedRow([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          checked={rest.checked}
          name={rest.name}
          id={rest.id}
          type="checkbox"
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          id={rest.id}
          checked={rest.checked}
          type={mode}
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
  };

  const expandRow = {
    showExpandColumn: true,

    renderer: (row) => (
      <div>
        <span dangerouslySetInnerHTML={{ __html: row[descriptionKey] }}></span>
      </div>
    ),
  };

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      onSelectedRowChange?.(selectedRow);
    }
    mounted.current = true;
  }, [selectedRow]);

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const currencySymbol = storedData.currency;
  
  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
        nextPageText: "Next",
        prePageText: "Previous",
        sizePerPageList: [10, 25, 50, 100],
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div>
          <BootstrapTable
            {...baseProps}
            remote
            keyField="id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            selectRow={!checkBoxDisable ? checkBox : undefined}
            rowEvents={rowEvents}
            // expandRow={!expandRowDisable ? expandRow : undefined}
            noDataIndication={emptyDataMessage}
            // {...baseProps}
            {...paginationTableProps}
          />
          {/* {
            statementTotal && 
            (<div className="table-footer d-flex justify-flex-end mb-4">
              <div className="statement-total">
                <div className="align-items-center d-flex justify-space-between">
                  <div className="font-bold mr-100-invoice width-370">Statement Total:</div>
                  <div className="font-bold m-l-30 mr-100-invoice width-100">{currencySymbol} {addComma(statementTotal)}</div>
                </div>
              </div>
            </div>)
          } */}
          <div>
            <div className="table-pagination">
              <div>
                <span>Show</span>
                <SizePerPageDropdownStandalone {...paginationProps} />
                <span>entries</span>
              </div>
              <PaginationTotalStandalone {...paginationProps} />
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        </div>
      )}
    </PaginationProvider>
  );
};

export default TableWithPagination;
