import { takeLatest, put, call, throttle, debounce } from "redux-saga/effects";
import {
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_COUNT,
  GET_SUPPILERS_LIST,
  GET_SUPPILERS_COUNT,
  GET_EMPLOYEE_LIST,
  GET_SUPPILERS_BRANCH,
  GET_ONSITE_STATE,
  GET_CUSTOMER_DETAIL,
  GET_CUSTOMER_VEHICLE,
  GET_CUSTOMER_NOTES,
  GET_CUSTOMER_ATTACHMENT,
  CHECK_SUPPILER_EMAIL,
  CHECK_SUPPILER_MOBILE,
  GET_SUPPILERS,
  CHECK_EMPLOYEE_EMAIL,
  CHECK_EMPLOYEE_MOBILE,
  GET_EMPLOYEE,
  GET_EMPLOYEE_NOTES,
  GET_CUSTOMER_HISTORY,
  GET_POS_HISTORY,
  CHECK_BRANCH_EMAIL,
  CHECK_BRANCH_MOBILE,
  VIEW_SUPPILERS_BRANCH,
  CUSTOMER_SEARCH,
  SUPPLIER_SEARCH,
  CLEAN_CUSTOMER_DATA,
  CLEAN_EMPLOYEE_DATA,
  HQ_GET_SUPPILERS_LIST,
  HQ_GET_SUPPILERS_COUNT,
  HQ_GET_SUPPLIER,
  GET_SECONDARY_CUSTOMER_LIST
} from "./actionTypes";
import {
  getCustomerListSuccess,
  getCustomerListFail,
  getCustomerCountSuccess,
  getCustomerCountFail,
  getSuppliersListSuccess,
  getSuppliersListFail,
  getSuppliersCountSuccess,
  getSuppliersCountFail,
  getEmployeeListSuccess,
  getEmployeeListFail,
  getSuppliersBranchSuccess,
  getSuppliersBranchFail,
  getOnSiteStateSuccess,
  getOnSiteStateFail,
  getCustomerDetailSuccess,
  getCustomerDetailFail,
  getCustomerVehicleSuccess,
  getCustomerVehicleFail,
  getCustomerNotesSuccess,
  getCustomerNotesFail,
  getCustomerAttachmentSuccess,
  getCustomerAttachmentFail,
  checkSuppilerEmailSuccess,
  checkSuppilerEmailFail,
  checkSuppilerMobileSuccess,
  checkSuppilerMobileFail,
  getSuppliersSuccess,
  getSuppliersFail,
  checkEmployeeEmailSuccess,
  checkEmployeeEmailFail,
  checkEmployeeMobileSuccess,
  checkEmployeeMobileFail,
  getEmpmployeeSuccess,
  getEmpmployeeFail,
  getEmployeeNotesSuccess,
  getEmployeeNotesFail,
  getCustomerHistorySuccess,
  getCustomerHistoryFail,
  getPosHistorySuccess,
  getPosHistoryFail,
  checkBranchEmailSuccess,
  checkBranchEmailFail,
  checkBranchMobileSuccess,
  checkBranchMobileFail,
  viewSuppliersBranchSuccess,
  viewSuppliersBranchFail,
  customerSearchSuccess,
  customerSearchFail,
  supplierSearchSuccess,
  supplierSearchFail,
  getHQSuppliersList,
  getHQSuppliersListSuccess,
  getHQSuppliersListFail,
  getHQSuppliersCount,
  getHQSuppliersCountSuccess,
  getHQSuppliersCountFail,
  getHQSingleSupplierSuccess,
  getHQSingleSuppliersFail,
  getSecondaryCustomerListSuccess,
  getSecondaryCustomerListFail,
} from "./actions";

import { checkEmialExistSuccess } from "../userProfile/actions";
import { checkMobileExistSuccess } from "../contacts/actions";

import {
  getCustomerLists,
  getSupplierList,
  getUserProfile,
  getSuppilerBranch,
  getState,
  getCustomerDetails,
  getCustomerAndVehicleDetails,
  getCustomerNotes,
  getCustomerAttachment,
  getSupplier,
  getEmployeeNotes,
  getWorkshopTechnician,
  viewSupplierBranch,
  customerSearch,
  supplierSearch,
  getHQWorkshopList,
  getHQSupplierList,
  getHQSingleSupplier,
} from "../../helpers/backendHelper";

function* onGetCustomerList(data) {
  try {
    const response = yield call(getCustomerLists, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCustomerListSuccess(response.data.CustomersDetails));
      } else {
        yield put(getCustomerListFail(response.message));
      }
    } else {
      yield put(getCustomerListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerListFail(error.response));
  }
}

function* onGetSecondaryCustomerList(data) {
  try {
    const response = yield call(getCustomerLists, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSecondaryCustomerListSuccess(response.data.CustomersDetails));
      } else {
        yield put(getSecondaryCustomerListFail(response.message));
      }
    } else {
      yield put(getSecondaryCustomerListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSecondaryCustomerListFail(error.response));
  }
}

function* onGetCustomerCount(data) {
  try {
    const response = yield call(getCustomerLists, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getCustomerCountSuccess(response.data.CustomersDetails[0].COUNT)
        );
      } else {
        yield put(getCustomerCountFail(response.message));
      }
    } else {
      yield put(getCustomerCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerCountFail(error.response));
  }
}

function* onGetSuppliersList(data) {
  try {
    const response = yield call(getSupplierList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSuppliersListSuccess(response.data.Suppliers));
      } else {
        yield put(getSuppliersListFail(response.message));
      }
    } else {
      yield put(getSuppliersListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSuppliersListFail(error.response));
  }
}

function* onGetHQSuppliersList(data) {
  try {
    const response = yield call(getHQSupplierList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getHQSuppliersListSuccess(response.data.HeadQuartersSuppliers));
      } else {
        yield put(getHQSuppliersListFail(response.message));
      }
    } else {
      yield put(getHQSuppliersListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getHQSuppliersListFail(error.response));
  }
}

function* onGetSuppliersCount(data) {
  try {
    const response = yield call(getSupplierList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSuppliersCountSuccess(response.data.Suppliers[0].COUNT));
      } else {
        yield put(getSuppliersCountFail(response.message));
      }
    } else {
      yield put(getSuppliersCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSuppliersCountFail(error.response));
  }
}


function* onGetHQSuppliersCount(data) {
  try {
    const response = yield call(getHQSupplierList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getHQSuppliersCountSuccess(response.data.HeadQuartersSuppliers[0].COUNT));
      } else {
        yield put(getHQSuppliersCountFail(response.message));
      }
    } else {
      yield put(getHQSuppliersCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getHQSuppliersCountFail(error.response));
  }
}

function* onGetEmployeeList(data) {
  try {
    const response = yield call(getUserProfile, data);
    if (response) {
      if (response.code === 200) {
        yield put(getEmployeeListSuccess(response.data.Users));
      } else {
        yield put(getEmployeeListFail(response.message));
      }
    } else {
      yield put(getEmployeeListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getEmployeeListFail(error.response));
  }
}

function* onGetSuppliersBranch(data) {
  try {
    const response = yield call(getSuppilerBranch, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSuppliersBranchSuccess(response.data.SuppliersBranches));
      } else {
        yield put(getSuppliersBranchFail(response.message));
      }
    } else {
      yield put(getSuppliersBranchFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSuppliersBranchFail(error.response));
  }
}

function* onGetOnSiteState(data) {
  try {
    const response = yield call(getState, data);
    if (response) {
      if (response.code === 200) {
        yield put(getOnSiteStateSuccess(response.data.States));
      } else {
        yield put(getOnSiteStateFail(response.message));
      }
    } else {
      yield put(getOnSiteStateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getOnSiteStateFail(error.response));
  }
}

function* onGetCustomerDetail(data) {
  try {
    const response = yield call(getCustomerDetails, data.payload);
    if (response) {
      if (response.code === 200) {
        yield put(getCustomerDetailSuccess(response.data.CustomersDetails));
      } else {
        yield put(getCustomerDetailFail(response.message));
      }
    } else {
      yield put(getCustomerDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerDetailFail(error.response));
  }
}

function* onGetCustomerVehicle(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCustomerVehicleSuccess(response.data.Vehicles));
      } else {
        yield put(getCustomerVehicleFail(response.message));
      }
    } else {
      yield put(getCustomerVehicleFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerVehicleFail(error.response));
  }
}

function* onGetCustomerNotes(data) {
  try {
    const response = yield call(getCustomerNotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCustomerNotesSuccess(response.data.CustomerNotes));
      } else {
        yield put(getCustomerNotesFail(response.message));
      }
    } else {
      yield put(getCustomerNotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerNotesFail(error.response));
  }
}

function* onGetCustomerAttachment(data) {
  try {
    const response = yield call(getCustomerAttachment, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getCustomerAttachmentSuccess(response.data.CustomerAttachment)
        );
      } else {
        yield put(getCustomerAttachmentFail(response.message));
      }
    } else {
      yield put(getCustomerAttachmentFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerAttachmentFail(error.response));
  }
}

function* onCheckSuppilerEmail(data) {
  try {
    const response = yield call(getSupplierList, data);
    if (response) {
      if (response.code === 200 || response.code === 400) {
        if (response.data.Suppliers.length == 0) {
          yield put(checkSuppilerEmailSuccess(false));
        } else {
          yield put(checkSuppilerEmailSuccess(true));
        }
      } else {
        yield put(checkSuppilerEmailFail(response.message));
      }
    } else {
      yield put(checkSuppilerEmailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkSuppilerEmailFail(error.response));
  }
}

function* onCheckSuppilerMobile(data) {
  try {
    const response = yield call(getSupplierList, data);
    if (response) {
      if (response.code === 200 || response.code === 400) {
        if (response.data.Suppliers.length == 0) {
          yield put(checkSuppilerMobileSuccess(false));
        } else {
          yield put(checkSuppilerMobileSuccess(true));
        }
      } else {
        yield put(checkSuppilerMobileFail(response.message));
      }
    } else {
      yield put(checkSuppilerMobileFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkSuppilerMobileFail(error.response));
  }
}

function* onGetSuppliers(data) {
  try {
    const response = yield call(getSupplier, data.payload);
    if (response) {
      if (response.code === 200) {
        yield put(getSuppliersSuccess(response.data.Suppliers));
      } else {
        yield put(getSuppliersFail(response.message));
      }
    } else {
      yield put(getSuppliersFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSuppliersFail(error.response));
  }
}

function* onGetHQSingleSuppliers(data) {
  try {
    const response = yield call(getHQSingleSupplier, data.payload);
    if (response) {
      if (response.code === 200) {
        yield put(getHQSingleSupplierSuccess(response.data.HeadQuartersSuppliers));
      } else {
        yield put(getHQSingleSuppliersFail(response.message));
      }
    } else {
      yield put(getHQSingleSuppliersFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getHQSingleSuppliersFail(error.response));
  }
}

function* onCheckEmployeeEmail(data) {
  try {
    const response = yield call(getUserProfile, data);
    if (response) {
      if (response.code === 200 || response.code === 400) {
        if (response.data.Users.length == 0) {
          yield put(checkEmployeeEmailSuccess(false));
        } else {
          yield put(checkEmployeeEmailSuccess(true));
        }
      } else {
        yield put(checkEmployeeEmailFail(response.message));
      }
    } else {
      yield put(checkEmployeeEmailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkEmployeeEmailFail(error.response));
  }
}

function* onCheckEmployeeMobile(data) {
  try {
    const response = yield call(getUserProfile, data);
    if (response) {
      if (response.code === 200 || response.code === 400) {
        if (response.data.Users.length == 0) {
          yield put(checkEmployeeMobileSuccess(false));
        } else {
          yield put(checkEmployeeMobileSuccess(true));
        }
      } else {
        yield put(checkEmployeeMobileFail(response.message));
      }
    } else {
      yield put(checkEmployeeMobileFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkEmployeeMobileFail(error.response));
  }
}

function* onGetEmployee(data) {
  try {
    const response = yield call(getUserProfile, data);
    if (response) {
      if (response.code === 200) {
        yield put(getEmpmployeeSuccess(response.data.Users[0]));
      } else {
        yield put(getEmpmployeeFail(response.message));
      }
    } else {
      yield put(getEmpmployeeFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getEmpmployeeFail(error.response));
  }
}

function* onGetEmployeeNotes(data) {
  try {
    const response = yield call(getEmployeeNotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(getEmployeeNotesSuccess(response.data.EmployeeNotes));
      } else {
        yield put(getEmployeeNotesFail(response.message));
      }
    } else {
      yield put(getEmployeeNotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getEmployeeNotesFail(error.response));
  }
}

function* onGetCustomerHistory(data) {
  try {
    const response = yield call(getWorkshopTechnician, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCustomerHistorySuccess(response.data.Workshops[0]));
      } else {
        yield put(getCustomerHistoryFail(response.message));
      }
    } else {
      yield put(getCustomerHistoryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerHistoryFail(error.response));
  }
}

function* onGetPosHistory(data) {
  try {
    const response = yield call(getWorkshopTechnician, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getPosHistorySuccess(response.data.Workshops[0].pos_histories)
        );
      } else {
        yield put(getPosHistoryFail(response.message));
      }
    } else {
      yield put(getPosHistoryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getPosHistoryFail(error.response));
  }
}

function* onCheckBranchEmail(data) {
  try {
    const response = yield call(getSuppilerBranch, data);
    if (response) {
      if (response.code === 200 || response.code === 400) {
        if (response.data.SuppliersBranches.length == 0) {
          yield put(checkBranchEmailSuccess(false));
        } else {
          yield put(checkBranchEmailSuccess(true));
        }
      } else {
        yield put(checkBranchEmailFail(response.message));
      }
    } else {
      yield put(checkBranchEmailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkBranchEmailFail(error.response));
  }
}

function* onCheckBranchMobile(data) {
  try {
    const response = yield call(getSuppilerBranch, data);
    if (response) {
      if (response.code === 200 || response.code === 400) {
        if (response.data.SuppliersBranches.length == 0) {
          yield put(checkBranchMobileSuccess(false));
        } else {
          yield put(checkBranchMobileSuccess(true));
        }
      } else {
        yield put(checkBranchMobileFail(response.message));
      }
    } else {
      yield put(checkBranchMobileFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkBranchMobileFail(error.response));
  }
}

function* onViewSuppliersBranch(data) {
  try {
    const response = yield call(viewSupplierBranch, data.payload);
    if (response) {
      if (response.code === 200) {
        yield put(viewSuppliersBranchSuccess(response.data.SuppliersBranches));
      } else {
        yield put(viewSuppliersBranchFail(response.message));
      }
    } else {
      yield put(viewSuppliersBranchFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(viewSuppliersBranchFail(error.response));
  }
}

function* onCustomerSearch(data) {
  try {
    const response = yield call(customerSearch, data);
    if (response) {
      if (response.code === 200) {
        yield put(customerSearchSuccess(response.data.CustomersDetails));
      } else {
        yield put(customerSearchFail(response.message));
      }
    } else {
      yield put(customerSearchFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(customerSearchFail(error.response));
  }
}

function* onSupplierSearch(data) {
  try {
    const response = yield call(supplierSearch, data);
    if (response) {
      if (response.code === 200) {
        yield put(supplierSearchSuccess(response.data.Suppliers));
      } else {
        yield put(supplierSearchFail(response.message));
      }
    } else {
      yield put(supplierSearchFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(supplierSearchFail(error.response));
  }
}

function* onCleanCustomerData() {
  yield put(getCustomerDetailSuccess([]));
  yield put(checkEmialExistSuccess(null));
  yield put(checkMobileExistSuccess(false));
}

function* onCleanEmployeeData() {
  yield put(checkEmployeeMobileSuccess(false));
  yield put(checkEmployeeEmailSuccess(false));
}

function* ContactSaga() {
  yield takeLatest(GET_CUSTOMER_LIST, onGetCustomerList);
  yield takeLatest(GET_SECONDARY_CUSTOMER_LIST, onGetSecondaryCustomerList);
  yield takeLatest(GET_CUSTOMER_COUNT, onGetCustomerCount);
  yield debounce(1000, GET_SUPPILERS_LIST, onGetSuppliersList);
  yield debounce(1000, HQ_GET_SUPPILERS_LIST, onGetHQSuppliersList);
  yield debounce(1000, HQ_GET_SUPPILERS_COUNT, onGetHQSuppliersCount);
  yield debounce(1000, GET_SUPPILERS_COUNT, onGetSuppliersCount);
  yield takeLatest(GET_EMPLOYEE_LIST, onGetEmployeeList);
  yield takeLatest(GET_SUPPILERS_BRANCH, onGetSuppliersBranch);
  yield takeLatest(GET_ONSITE_STATE, onGetOnSiteState);
  yield takeLatest(GET_CUSTOMER_DETAIL, onGetCustomerDetail);
  yield takeLatest(GET_CUSTOMER_VEHICLE, onGetCustomerVehicle);
  yield takeLatest(GET_CUSTOMER_NOTES, onGetCustomerNotes);
  yield takeLatest(GET_CUSTOMER_ATTACHMENT, onGetCustomerAttachment);
  yield takeLatest(CHECK_SUPPILER_EMAIL, onCheckSuppilerEmail);
  yield takeLatest(CHECK_SUPPILER_MOBILE, onCheckSuppilerMobile);
  yield takeLatest(GET_SUPPILERS, onGetSuppliers);
  yield takeLatest(HQ_GET_SUPPLIER, onGetHQSingleSuppliers);
  yield takeLatest(CHECK_EMPLOYEE_EMAIL, onCheckEmployeeEmail);
  yield takeLatest(CHECK_EMPLOYEE_MOBILE, onCheckEmployeeMobile);
  yield takeLatest(GET_EMPLOYEE, onGetEmployee);
  yield takeLatest(GET_EMPLOYEE_NOTES, onGetEmployeeNotes);
  yield takeLatest(GET_CUSTOMER_HISTORY, onGetCustomerHistory);
  yield takeLatest(GET_POS_HISTORY, onGetPosHistory);
  yield takeLatest(CHECK_BRANCH_EMAIL, onCheckBranchEmail);
  yield takeLatest(CHECK_BRANCH_MOBILE, onCheckBranchMobile);
  yield takeLatest(VIEW_SUPPILERS_BRANCH, onViewSuppliersBranch);
  yield debounce(2000, CUSTOMER_SEARCH, onCustomerSearch);
  yield throttle(2000, SUPPLIER_SEARCH, onSupplierSearch);
  yield takeLatest(CLEAN_CUSTOMER_DATA, onCleanCustomerData);
  yield takeLatest(CLEAN_EMPLOYEE_DATA, onCleanEmployeeData);
}

export default ContactSaga;
