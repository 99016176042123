import { takeLatest, put, call } from "redux-saga/effects";
import { GET_CUSTOMER_NOTIFICATION, GET_MARKETING_NOTIFICATION, GET_NOTIFICATION } from "./actionTypes";
import {
  getNotificationSuccess,
  getNotificationFail,
  getCustomerNotificationSuccess,
  getCustomerNotificationFail,
  getMarketingNotificationSuccess,
  getMarketingNotificationFail
} from "./actions";

import { getNotification,getMarketingNotification } from "../../helpers/backendHelper";

function* onGetNotification(data) {
  try {
    const response = yield call(getNotification, data);
    if(response){
      if(response.code === 200){
        yield put(getNotificationSuccess(response.data.NotificationsData));
      }else{
        yield put(getNotificationFail(response.message));
      }
    }else{
      yield put(getNotificationFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getNotificationFail(error.response));
  }
}


function* onGetCustomerNotification(data) {
  try {
    const response = yield call(getNotification, data);
    if(response){
      if(response.code === 200){
        yield put(getCustomerNotificationSuccess(response.data.NotificationsData));
      }else{
        yield put(getCustomerNotificationFail(response.message));
      }
    }else{
      yield put(getCustomerNotificationFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getCustomerNotificationFail(error.response));
  }
}


function* onGetMarketingNotification(data) {
  try {
    const response = yield call(getMarketingNotification, data);
    if(response){
      if(response.code === 200){
        yield put(getMarketingNotificationSuccess(response.data.CronjobData));
      }else{
        yield put(getMarketingNotificationFail(response.message));
      }
    }else{
      yield put(getMarketingNotificationFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getMarketingNotificationFail(error.response));
  }
}


function* NotificationSaga() {
  yield takeLatest(GET_NOTIFICATION, onGetNotification);
  yield takeLatest(GET_CUSTOMER_NOTIFICATION, onGetCustomerNotification);
  yield takeLatest(GET_MARKETING_NOTIFICATION, onGetMarketingNotification);
}

export default NotificationSaga;
