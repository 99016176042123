import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLANS,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST,
  DO_SUBSCRIPTION_CHECKOUT,
  EDIT_SUBSCRIPTION_PLANS,
  CAPRICORN_SUBSCRIPTION_PLANS,
} from "./actionTypes";
import {
  getSubscriptionSuccess,
  getSubscriptionFail,
  getWorkShopSmsSubscriptionDetailsListSuccess,
  getWorkShopSmsSubscriptionDetailsListFail,
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFail,
  doSubscriptionCheckoutSuccess,
  doSubscriptionCheckoutFail,
  editSubscriptionPlansSuccess,
  editSubscriptionPlansFail,
  capricornSubscriptionPlansSuccess,
  capricornSubscriptionPlansFail,

} from "./actions";

import { getSubscription, getSubscriptionPlans, getWorkshopSubscriptionDetailsList, doSubscriptionCheckout, editSubscription, capricornSubscription } from "../../helpers/backendHelper";
import { SET_EXPIRED } from "store/login/actionTypes";
import { showError } from "utils/alertMessage";

function* onGetSubscription(data) {
  try {
    const response = yield call(getSubscription, data);
    if (response) {
      if (response.code === 200) {
        const lastarray = parseInt(response.data.WorkshopSubscription.length) - 1;
        yield put(getSubscriptionSuccess(response.data.WorkshopSubscription[lastarray]));
      } else if (response.code === 400) {
        yield put(getSubscriptionSuccess({}));
      } else {
        yield put(getSubscriptionFail(response.message));
      }
    } else {
      yield put(getSubscriptionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSubscriptionFail(error.response));
  }
}

function* onGetSubscriptionPlans(data) {
  try {
    const response = yield call(getSubscriptionPlans, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSubscriptionPlansSuccess(response.data.SubscriptionPlan));
      } else {
        yield put(getSubscriptionFail(response.message));
      }
    } else {
      yield put(getSubscriptionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSubscriptionPlansFail(error.response));
  }
}

function* onGetWorkShopSmsSubscription(data) {
  try {
    const response = yield call(getWorkshopSubscriptionDetailsList, data);
    if (response) {
      if (response.code === 200) {
        let manualData = {};
        manualData.isLookSubscription = 0;
        if (response.data.WorkshopSubscriptionDetails.length > 0) {
          manualData.id = response.data.WorkshopSubscriptionDetails ? response.data.WorkshopSubscriptionDetails[0].id : "";
          manualData.workshop_subscription_id = response.data.WorkshopSubscriptionDetails ? response.data.WorkshopSubscriptionDetails[0].workshop_subscription_id : "";
          manualData.subscription_plan_id = response.data.WorkshopSubscriptionDetails ? response.data.WorkshopSubscriptionDetails[0].subscription_plan_id : "";
          manualData.current_vin_request = response.data.WorkshopSubscriptionDetails ? response.data.WorkshopSubscriptionDetails[0].current_vin_request : "";
          manualData.isLookSubscription = 1;
        }
        yield put(getWorkShopSmsSubscriptionDetailsListSuccess(manualData));
      } else {
        yield put(getWorkShopSmsSubscriptionDetailsListFail(response.message));
      }
    } else {
      yield put(getWorkShopSmsSubscriptionDetailsListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWorkShopSmsSubscriptionDetailsListFail(error.response));
  }
}

function* onDoSubscriptionCheckout(data) {
  try {
    const response = yield call(doSubscriptionCheckout, data);
    if (response) {
      if (response.code === 200) {
        yield put(doSubscriptionCheckoutSuccess(response.message));
        localStorage.setItem("isPlanExpire", false);
        yield put({
          type: SET_EXPIRED,
          payload: false,
        })
      } else if (response.code === 400) {
        yield put(doSubscriptionCheckoutSuccess({}));
      } else {
        yield put(doSubscriptionCheckoutFail(response.message));
      }
    } else {
      yield put(doSubscriptionCheckoutFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(doSubscriptionCheckoutFail(error.response));
  }
}

function* onEditSubscriptionPlans(data) {
  try {
    const response = yield call(editSubscription, data);
    if (response) {
      if (response.code === 200) {
        yield put(editSubscriptionPlansSuccess(response));
      } else {
        yield put(editSubscriptionPlansFail(response.message));
      }
    } else {
      yield put(editSubscriptionPlansFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editSubscriptionPlansFail(error.response));
  }
}

// function* onCapricornSubscriptionPlans(data) {
//   try {
//     const response = yield call(capricornSubscription, data);
//     if (response) {
//       if (response.code === 200) {
//         yield put(capricornSubscriptionPlansSuccess(response));
//       } else {
//         yield put(capricornSubscriptionPlansFail(response.message));
//       }
//     } else {
//       yield put(capricornSubscriptionPlansFail("Didn't get api response"));
//     }
//   } catch (error) {
//     yield put(capricornSubscriptionPlansFail(error.response));
//   }
// }

function* onCapricornSubscriptionPlans(data) {
  try {
    const response = yield call(capricornSubscription, data);
    if (response) {
      if (response.code === 200) {
        yield put(capricornSubscriptionPlansSuccess(response.message));
        localStorage.setItem("isPlanExpire", false);
        yield put({
          type: SET_EXPIRED,
          payload: false,
        })
      } else if (response.code === 400) {
        yield put(capricornSubscriptionPlansSuccess({}));
        showError(response.message);
      } else {
        yield put(capricornSubscriptionPlansFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(capricornSubscriptionPlansFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(capricornSubscriptionPlansFail(error.response));
  }
}

function* SubscriptionSaga() {
  yield takeLatest(GET_SUBSCRIPTION, onGetSubscription);
  yield takeLatest(GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST, onGetWorkShopSmsSubscription);
  yield takeLatest(GET_SUBSCRIPTION_PLANS, onGetSubscriptionPlans);
  yield takeLatest(DO_SUBSCRIPTION_CHECKOUT, onDoSubscriptionCheckout);
  yield takeLatest(EDIT_SUBSCRIPTION_PLANS, onEditSubscriptionPlans);
  yield takeLatest(CAPRICORN_SUBSCRIPTION_PLANS, onCapricornSubscriptionPlans);

}

export default SubscriptionSaga;
