import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteArchivePaymentInvoice } from "store/invoices/actions";
import { getInvoicePaymentsAPI } from "store/invoices/api";
import Loader from "views/common/UI/Loader";
import CommonSecond from "./CommonSecond";
import { useInvoiceColumns } from "./useInvoiceColumns";
import PaymentComplete from "../../PaymentComplete/PaymentComplete";

const InvoicePayments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePayload, setDeletePayload] = useState(null);
  const [selectedData, setSelectedData] = useState("");
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);

  const getCustomerData = (data, invoicee) => {
    const invoice = data?.invoice ?? {};
    const jobData = invoice?.job_schedule ?? {};
  
    const customerDetail = data?.invoice?.job_schedule?.customer?.customers_details?.[0] ?? {};
    let customrData = {
      cust_id: "",
      mobile_number: "",
      mobileCode: "",
      customerId: "",
      customerName: "",
      customerEmail: "",
      isInvoiceB: false
    };
  
    if (data?.job_number === 'Cash Sale') {
      const cashSaleUser = data?.cash_sale_invoice?.user ?? {};
      const cashSaleCustomerDetails = cashSaleUser?.customers_details?.[0] ?? {};
      customrData = {
        cust_id: cashSaleCustomerDetails?.id ?? "",
        mobile_number: cashSaleCustomerDetails?.mobile_number ?? "",
        mobileCode: cashSaleCustomerDetails?.mobile_code ?? "",
        customerId: cashSaleUser?.id ?? "",
        customerName: cashSaleCustomerDetails?.type === 1
          ? cashSaleCustomerDetails?.company_name ?? ""
          : `${cashSaleCustomerDetails?.firstname ?? ""} ${cashSaleCustomerDetails?.lastname ?? ""}`.trim(),
        customerEmail: cashSaleUser?.email ?? "",
        isInvoiceB: false
      };
    } else if ((jobData?.show_allocation_column == "1" && invoice?.invoice_allocation_type == "2") ||
               (jobData?.show_allocation_column == "0" && invoice?.insurance_preview_invoice == "1")) {
      const insuranceComp = invoice?.insurance_company ?? {};
      customrData = {
        cust_id: insuranceComp?.id ?? "",
        mobile_number: insuranceComp?.phone_number ?? "",
        mobileCode: insuranceComp?.mobile_code ?? "",
        customerId: insuranceComp?.id ?? "",
        customerName: insuranceComp?.company_name,
        customerEmail: insuranceComp?.email ?? "",
        isInvoiceB: true
      };
    } else if (jobData?.show_allocation_column == "1" && invoice?.invoice_allocation_type == "3") {
      const cCustomer = jobData?.invoice_c_customer ?? {};
      customrData = {
        cust_id: cCustomer?.id ?? "",
        mobile_number: cCustomer?.mobile_number ?? "",
        mobileCode: cCustomer?.mobile_code ?? "",
        customerId: cCustomer?.user_id ?? "",
        customerName: cCustomer?.type == 2 ? cCustomer?.firstname + " " + cCustomer?.lastname : cCustomer?.company_name,
        customerEmail: cCustomer?.user?.email ?? "",
        isInvoiceB: false
      };
    } else {
      customrData = {
        cust_id: customerDetail?.id ?? "",
        mobile_number: customerDetail?.mobile_number ?? "",
        mobileCode: customerDetail?.mobile_code ?? "",
        customerId: customerDetail?.user_id ?? "",
        customerName: customerDetail?.type == 2 ? customerDetail?.firstname + " " + customerDetail?.lastname : customerDetail?.company_name,
        customerEmail: jobData?.customer?.email ?? "",
        isInvoiceB: false
      };
    }

    return customrData;
  };
  

  
  const { invoicePaymentsColumns } = useInvoiceColumns({
    onView: ({ row }) => {
      if (row.job_number === 'Cash Sale') {
        navigate(`/view-cashsale-receipt/${row.id}`);
        return
      }
      navigate(`/view-invoice-receipt/${row.id}`);
    },
    // onMail: ({ row }) => {
    //   if (row.job_number === 'Cash Sale') {
    //     navigate(`/cashsale-receipt-email/${row.id}`);
    //     return
    //   }
    //   navigate(`/invoice-receipt-email/${row.id}`);
    // },
    onSend: ({ row }) => {
      // if (row.job_number === 'Cash Sale') {
      //   navigate(`/cashsale-receipt-email/${row.id}`);
      //   return
      // }
      // navigate(`/invoice-receipt-email/${row.id}`);
      setShowInvoicePopup(true);
      setSelectedData(row);
    },
    onPrint: ({ row }) => {
      if (row.job_number === 'Cash Sale') {
        navigate(`/view-cashsale-receipt/${row.id}?print=true`);
        return
      }
      // navigate(`/invoice-receipt-email/${row.id}?print=true`);
      navigate(`/view-invoice-receipt/${row.id}?print=true`);
    },
    onCreditClick: ({ row }) => {
      if (row.job_number === 'Cash Sale') {
        navigate(`/cash_sale_payment_credit/${row.id}`);
        return
      }
      navigate(`/payment_credit/${row.id}`);
    },
    onDelete: ({ row }) => {
      //Delete
      setDeletePayload([row]);
    },
  });
  const invoice = useSelector((state) => state.invoice);

  const deleteEvent = async (cb) => {
    const payload = {
      action_type: "delete",
      ids: deletePayload.map((c) => c.id),
    };
    dispatch(deleteArchivePaymentInvoice(payload));
    cb()
  };

  return (
    <>
      {/* InvoicePayments */}
      {invoice?.invoicePayments?.invoiceDeleteStatus === "started" && (
        <Loader />
      )}
      <CommonSecond
        deletePayload={deletePayload}
        setDeletePayload={setDeletePayload}
        api={getInvoicePaymentsAPI}
        columns={invoicePaymentsColumns}
        main={invoice?.invoicePayments}
        // onCreateCashSale={() => { }}
        onRowClick={(row) => {
          if (row.job_number === 'Cash Sale') {
            navigate(`/view-cashsale-receipt/${row.id}`);
            return
          }
          navigate(`/view-invoice-receipt/${row.id}`);
        }}
        onSearch={(e) => { }}
        completed={true}
        deleteInvoices={deleteEvent}
        invoiceListType={"3"}
      />

      {showInvoicePopup &&
        <PaymentComplete
          show={showInvoicePopup}
          onHide={() => {
            setShowInvoicePopup(false);
            // navigate("/invoice");
          }}
          amountPaid={selectedData?.amount_paid}
          paymentType={
            selectedData?.payment_method === "1"
              ? "Cash"
              : selectedData?.payment_method === "4"
                ? "BNPL"
                : selectedData?.payment_method === "5"
                  ? "Cheque"
                  : "Card"
          }
          {...getCustomerData(selectedData, invoice)}
          vehicleRego={
            selectedData?.job_number === 'Cash Sale' ?
              selectedData?.cash_sale_invoice?.vehicle?.rego
              :
              selectedData?.invoice?.job_schedule?.rego
          }
          number={selectedData?.invoice_number}
          paymentReceipt={true}
          receiptData={selectedData}
        />
      }
    </>
  );
};

export default InvoicePayments;
