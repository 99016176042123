import React, { useEffect, useState, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HINEXIST } from "store/list/actionTypes";

const MarineFields = ({
    vehiclesFormData,
    handleVehiclesInputChange,
    transmissionData,
    fieldShow,
    checkEngineData,
    checkEngineExist
}) => {

    const dispatch = useDispatch();
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let workshop_id = window.atob(storedData.workshop_id);
    const currentYear = parseInt(new Date().getFullYear());

    const seatOption = [];
    seatOption.push(<option value="">Select Seat</option>);
    for (let i = 2; i <= 22; i++) {
        seatOption.push(<option value={i}>{i}</option>);
    }

    const cylinderOption = [];
    cylinderOption.push(<option value="">Select Cylinder</option>);
    for (let i = 1; i <= 12; i++) {
        cylinderOption.push(<option value={i}>{i}</option>);
    }

    const checkHin = (value) => {
        if (value !== "") {
          value = value.trim().split(" ").join("");
          dispatch({
            type: HINEXIST,
            payload: {
              get: "all",
              conditions: {
                "Vehicles.workshop_id": workshop_id,
                "Vehicles.hin_number": value,
              },
            },
          });
        }
    };

    const { hinExist } = useSelector(
        (state) => state.List
    );

    return (
        <>
            {/* First Section START */}
            <div className="form-outer vin-engine-section" >
                {/* Make */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Make</label>
                    <Form.Control
                        type="text"
                        name="make"
                        placeholder="Enter make"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.make}
                    />
                </Form.Group>

                {/* Modal */}
                <Form.Group className="form-group  width-33 mb-3">
                    <label>Model</label>
                    <Form.Control
                        type="text"
                        name="model"
                        placeholder="Enter model"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.model}
                    />
                </Form.Group>

                {/* Series */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Series</label>
                    <Form.Control
                        type="text"
                        name="model_series"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.model_series}
                        placeholder="Enter series"
                    />
                </Form.Group>

                {/* Fuel Type */}
                <Form.Group className="form-group auto-complete-wrapper width-33 mb-3">
                    <label>Fuel Type</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="fuel_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.fuel_type}
                    >
                        <option value="">Select Fuel Type</option>
                        <option value="PETROL">PETROL</option>
                        <option value="DIESEL">DIESEL</option>
                    </Form.Select>
                </Form.Group>

                {/* Boat Construction */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Boat Construction</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="boat_construction"
                        value={vehiclesFormData.boat_construction}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        <option>Select Construction</option>
                        <option value="Aluminum">Aluminum</option>
                        <option value="Fiberglass">Fiberglass</option>
                        <option value="Wooden">Wooden</option>
                    </Form.Select>
                </Form.Group>

                <div className="form-group width-33 mb-3 d-flex gap-2">
                    {/* Month */}
                    <div className="p-0 m-0" style={{ maxWidth: 50 }}>
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Month</label>
                            <Form.Control
                                type="text"
                                name="month"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                onBlur={(e) => handleVehiclesInputChange(e, true)}
                                value={vehiclesFormData.month}
                                autoComplete="off"
                            />
                        </Form.Group>
                    </div>
                    <div className="flex-1 p-0">
                        {/* Year */}
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Year</label>
                            <Form.Select
                                aria-label="Default select example"
                                name="year"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                value={vehiclesFormData.year}
                            >
                                <option value="">Select Year</option>
                                {[...Array(currentYear - 1949)].map(
                                    (data, index) => (
                                        <option
                                            value={currentYear - index}
                                            key={index}
                                        >
                                            {currentYear - index}
                                        </option>
                                    )
                                )}
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                {/* Transmission */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Transmission</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="transmission"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.transmission}
                    >
                        <option value="">Select transmission</option>
                        {
                            transmissionData.length !== 0 &&
                            transmissionData.map((trans, index) => (
                                <option key={index} value={trans.id}>{trans.name}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>

                {/* Drivetrain */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Drivetrain</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="category"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.category}
                    >
                        <option value="">Select Drivetrain</option>
                        <option value="Propeller">
                            Propeller
                        </option>
                        <option value="Jet">
                            Jet
                        </option>
                    </Form.Select>
                </Form.Group>

                {/* Colour */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Colour</label>
                    <Form.Control
                        type="text"
                        name="colour"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.colour}
                    />
                </Form.Group>

                {/* Seat Capacity */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Seat Capacity</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="seat_capacity"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.seat_capacity}
                    >
                        {seatOption}
                    </Form.Select>
                </Form.Group>

                {/* Engine Location */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Engine Location</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="engine_location"
                        value={vehiclesFormData.engine_location}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        <option value="">Select Engine Location</option>
                        <option value="Inboard">Inboard</option>
                        <option value="Outboard">Outboard</option>
                    </Form.Select>
                </Form.Group>

                {/* Number Of Engines */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Number Of Engines</Form.Label>
                    <Form.Control
                        type="text"
                        name="number_of_engines"
                        value={vehiclesFormData.number_of_engines}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Cylinder */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Cylinder</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="cyliners"
                        value={vehiclesFormData.cyliners}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {cylinderOption}
                    </Form.Select>
                </Form.Group>

                {/* Engine Type */}
                <Form.Group className=" form-group width-33 mb-3">
                    <label>Engine Type</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="engine_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.engine_type}
                    >
                        <option value="">Select Engine Type</option>
                        <option value="Single">Single</option>
                        <option value="Inline">Inline</option>
                        <option value="V">V</option>
                    </Form.Select>
                </Form.Group>

                {/* Engine Size */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Engine Size (CC)</label>
                    <Form.Control
                        type="text"
                        name="engine_size"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.engine_size}
                    />
                </Form.Group>
            </div>

            {/* Second Section Vin / Engine Section START */}
            <div className={`form-outer mb-2 vin-engine-section mt-2  hidden-form ${fieldShow ? "active" : ""}`}>
                
                <Form.Group className="form-group width-100 mb-3">
                    <Form.Label>
                        HIN *
                        <span className="font-size-13"> 
                            (Hull Identification Number)
                        </span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        required
                        name="hin_number"
                        value={vehiclesFormData.hin_number}
                        onChange={(e) => handleVehiclesInputChange(e)}
                        onBlur={(e) => checkHin(e.target.value)}
                        className={hinExist && "form-control is-invalid"}
                    />
                    {hinExist && (
                      <div style={{ color: "red" }}>
                        This Hin Number already exits in this workshop.
                      </div>
                    )}
                    {hinExist === false ? (
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <Form.Label>Boat Type</Form.Label>
                    <Form.Control
                        type="text"
                        name="boat_type"
                        value={vehiclesFormData.boat_type}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3" >
                    <Form.Label>Boat Length (FT)</Form.Label>
                    <Form.Control
                        type="text"
                        name="boat_length"
                        value={vehiclesFormData.boat_length}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                <Form.Group
                    className={`form-group width-50 mb-3`}
                >
                    <label>Engine Number</label>
                    <Form.Control
                        type="text"
                        name="engine_number"
                        className={
                            checkEngineData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        // onBlur={(e) => checkEngineExist(e)}
                        value={vehiclesFormData.engine_number}
                    />
                    {checkEngineData &&
                        <div className="invalid-feedback">
                            This Engine Number is already registered.
                        </div>
                    }
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3" >
                    <Form.Label>Engine Output (HP)</Form.Label>
                    <Form.Control
                        type="text"
                        name="engine_output"
                        value={vehiclesFormData.engine_output}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>
            </div>
            {/* Second Section Vin / Engine Section END */}
        </>
    )
}

export default MarineFields;
