import React, { useEffect, useState, useMemo } from "react";
import { Form } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import {
    GET_VEHICLEMODEL,
    GET_VEHICLESERIES,
    GET_VEHICLES,
} from "store/commonApiStore/actionTypes";
import { useDispatch, useSelector } from "react-redux";

const TruckFields = ({
    vehiclesFormData,
    setVehiclesFormData,
    handleVehiclesInputChange,
    transmissionData,
    checkVinData,
    checkVinExist,
    checkChasisData,
    checkChasisExist,
    checkEngineData,
    checkEngineExist,
    checkFleetExist,
    checkFleetData,
    fieldShow
}) => {

    const dispatch = useDispatch();
    const currentYear = parseInt(new Date().getFullYear());
    const [vehicleMakeData, setVehicleMakedata] = useState([]);
    const [vehicleModelData, setVehicleModeldata] = useState([]);
    const [VehicleSeriesData, setVehicleSeriesdata] = useState([]);
    const [makeList, setMakeList] = useState(false);
    const [modelList, setModelList] = useState(false);
    const [seriesList, setSeriesList] = useState(false);

    const { vehicles, vehicleModel, vehicleSeries } = useSelector(
        (state) => state.CommonApiStoreReducer
    );

    const gearsOptions = [];
    gearsOptions.push(<option value="">Select Gears</option>);
    for (let i = 1; i <= 30; i++) {
        gearsOptions.push(<option value={i}>{i}</option>);
    }

    const axlesOptions = [];
    axlesOptions.push(<option value="">Select Axles</option>);
    for (let i = 1; i <= 6; i++) {
        axlesOptions.push(<option value={i}>{i}</option>);
    }

    const wheelsOptions = [];
    wheelsOptions.push(<option value="">Select Wheels</option>);
    for (let i = 1; i <= 16; i++) {
        wheelsOptions.push(<option value={i}>{i}</option>);
    }

    const cylinderOption = [];
    cylinderOption.push(<option value="">Select Cylinder</option>);
    for (let i = 4; i <= 12; i++) {
        cylinderOption.push(<option value={i}>{i}</option>);
    }

    useEffect(() => {
        dispatch({
            type: GET_VEHICLES,
            payload: {
                get: "all",
                conditions: {
                    "vehicle_type": "6",
                },
                group: "assets_make",
                order: {
                    "assets_make": "ASC"
                }
            },
        });
    }, [])

    useEffect(() => {

        if (vehiclesFormData.make !== "") {
            dispatch({
                type: GET_VEHICLEMODEL,
                payload: {
                    get: "all",
                    conditions: {
                        assets_make: vehiclesFormData.make,
                    },
                },
            });
        }

        if (vehicles.length != 0) {
            let filteredData = [];
            vehicles.filter((el) => {
                if (el.assets_make.toLowerCase().includes(vehiclesFormData.make.toLowerCase()))
                    filteredData.push(el);
            });

            setVehicleMakedata(filteredData);
        }

    }, [vehiclesFormData.make])


    useEffect(() => {
        if (vehiclesFormData.model_id > 0 || vehiclesFormData.asset_master_id > 0) {

            dispatch({
                type: GET_VEHICLESERIES,
                payload: {
                    get: "all",
                    conditions: {
                        asset_master_id: vehiclesFormData.model_id || vehiclesFormData.asset_master_id,
                    },
                    group: ["series"],
                    fields: ["id", "series", "asset_master_id"],
                },
            });
        }

        if (vehicleModel.length != 0) {
            let filteredData = [];
            vehicleModel.filter((el) => {
                if (el.assets_model.toLowerCase().includes(vehiclesFormData.model.toLowerCase()))
                    filteredData.push(el);
            });
            setVehicleModeldata(filteredData);
        }
    }, [vehiclesFormData.model]);


    useEffect(() => {
        if (vehicleSeries.length !== 0) {
            let filteredSeriesData = [];
            vehicleSeries.filter((el) => {
                if (el.series.toLowerCase().includes(vehiclesFormData.model_series.toLowerCase()))
                    filteredSeriesData.push(el);
            });
            setVehicleSeriesdata(filteredSeriesData);
        }
    }, [vehiclesFormData.model_series]);


    return (
        <>
            {/* First Section START */}
            <div className="form-outer vin-engine-section" >
                {/* Make */}
                <Form.Group className="form-group width-33 mb-3 auto-complete-wrapper">
                    <label>Make</label>
                    <Form.Control
                        type="text"
                        name="make"
                        autoComplete="off"
                        placeholder="Enter make"
                        onChange={(e) => {
                            handleVehiclesInputChange(e)
                            setMakeList(true);
                        }}
                        value={vehiclesFormData.make}
                    />
                    {vehicleMakeData.length !== 0 && vehiclesFormData.make !== "" && makeList && (
                        <ul className="auto-complete-list">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={200}
                                className="custom-scrollbar"
                                style={{ width: "100%" }}
                                renderThumbVertical={(props) => (
                                    <div
                                        {...props}
                                        className="thumb-vertical grey-thumb"
                                    />
                                )}
                            >
                                {vehicleMakeData.map(({ assets_make, id }, index) => (
                                    <li
                                        key={index}
                                        name="make"
                                        onClick={() => {
                                            setVehiclesFormData({
                                                ...vehiclesFormData,
                                                make: assets_make,
                                                make_id: id,
                                            })
                                            setMakeList(false);
                                        }
                                        }
                                    >
                                        {assets_make}
                                    </li>
                                ))}
                            </Scrollbars>
                        </ul>
                    )}
                </Form.Group>

                {/* Modal */}
                <Form.Group className="form-group width-33 mb-3 auto-complete-wrapper">
                    <label>Model</label>
                    <Form.Control
                        type="text"
                        name="model"
                        autoComplete="off"
                        placeholder="Enter model"
                        onChange={(e) => {
                            handleVehiclesInputChange(e)
                            setModelList(true);
                        }}
                        value={vehiclesFormData.model}
                    />
                    {vehiclesFormData.model !== "" && vehicleModelData.length !== 0 && modelList && (
                        <ul className="auto-complete-list">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={200}
                                className="custom-scrollbar"
                                style={{ width: "100%" }}
                                renderThumbVertical={(props) => (
                                    <div
                                        {...props}
                                        className="thumb-vertical grey-thumb"
                                    />
                                )}
                            >
                                {vehicleModelData.map(({ assets_model, id }, index) => (
                                    <li
                                        key={index}
                                        name="model"
                                        onClick={() => {
                                            setVehiclesFormData({
                                                ...vehiclesFormData,
                                                model: assets_model,
                                                model_id: id,
                                            })
                                            setModelList(false);
                                        }
                                        }
                                    >
                                        {assets_model}
                                    </li>
                                ))}
                            </Scrollbars>
                        </ul>
                    )}
                </Form.Group>

                {/* Series */}
                <Form.Group className="form-group width-33 mb-3 auto-complete-wrapper">
                    <label>Series</label>
                    <Form.Control
                        type="text"
                        name="model_series"
                        autoComplete="off"
                        onChange={(e) => {
                            handleVehiclesInputChange(e)
                            setSeriesList(true);
                        }}
                        value={vehiclesFormData.model_series}
                        placeholder="Enter series"
                    />
                    {vehiclesFormData.model_series !== "" && VehicleSeriesData.length !== 0 && seriesList && (
                        <ul className="auto-complete-list">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={200}
                                className="custom-scrollbar"
                                style={{ width: "100%" }}
                                renderThumbVertical={(props) => (
                                    <div
                                        {...props}
                                        className="thumb-vertical grey-thumb"
                                    />
                                )}
                            >
                                {VehicleSeriesData.map(({ series, id }, index) => (
                                    <li
                                        key={index}
                                        name="model_series"
                                        onClick={(e) => {
                                            setVehiclesFormData({
                                                ...vehiclesFormData,
                                                model_series: series,
                                                series_id: id,
                                            })
                                            setSeriesList(false);
                                        }}
                                    >
                                        {series}
                                    </li>
                                ))}
                            </Scrollbars>
                        </ul>
                    )}
                </Form.Group>

                {/* Fuel Type */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Fuel Type</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="fuel_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.fuel_type}
                    >
                        <option value="">Select Fuel Type</option>
                        <option value="PETROL">PETROL</option>
                        <option value="DIESEL">DIESEL</option>
                        <option value="HYBRID">HYBRID</option>
                        <option value="LPG">LPG</option>
                        <option value="ELECTRIC">ELECTRIC</option>
                        <option value="ELEC/DIESEL">ELEC/DIESEL</option>
                        <option value="ELEC/PETROL">ELEC/PETROL</option>
                        <option value="PETROL/LPG">PETROL/LPG</option>
                    </Form.Select>
                </Form.Group>

                {/* Body Type */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Body Type</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="body_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.body_type}
                    >
                        <option value="">Select Body Type</option>
                        <option value="Flat Bed">Flat Bed</option>
                        <option value="Tipper">Tipper</option>
                        <option value="Curtain Sider">Curtain Sider</option>
                        <option value="Box Body">Box Body</option>
                        <option value="Temperature - Controller Body">Temperature - Controller Body</option>
                        <option value="Luton Peak Body">Luton Peak Body</option>
                        <option value="Road Tanker">Road Tanker</option>
                    </Form.Select>
                </Form.Group>


                <div className="form-group width-33 mb-3 d-flex gap-2">
                    {/* Month */}
                    <div className="p-0 m-0" style={{ maxWidth: 50 }}>
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Month</label>
                            <Form.Control
                                type="text"
                                name="month"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                onBlur={(e) => handleVehiclesInputChange(e, true)}
                                value={vehiclesFormData.month}
                                autoComplete="off"
                            />
                        </Form.Group>
                    </div>
                    <div className="flex-1 p-0">
                        {/* Year */}
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Year</label>
                            <Form.Select
                                aria-label="Default select example"
                                name="year"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                value={vehiclesFormData.year}
                            >
                                <option value="">Select Year</option>
                                {[...Array(currentYear - 1949)].map(
                                    (data, index) => (
                                        <option
                                            value={currentYear - index}
                                            key={index}
                                        >
                                            {currentYear - index}
                                        </option>
                                    )
                                )}
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                {/* Transmission */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Transmission</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="transmission"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.transmission}
                    >
                        <option value="">Select transmission</option>
                        {
                            transmissionData.length !== 0 &&
                            transmissionData.map((trans, index) => (
                                <option key={index} value={trans.id}>{trans.name}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>

                {/* Number of gears */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Number Of Gears</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="number_of_gears"
                        value={vehiclesFormData.number_of_gears}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {gearsOptions}
                    </Form.Select>
                </Form.Group>

                {/* Drivetrain */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Drivetrain</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="category"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.category}
                    >
                        <option value="">Select Drivetrain</option>
                        <option value="Rear Wheel Drive(RWD)">
                            Rear Wheel Drive(RWD)
                        </option>
                        <option value="4 Wheel Drive(4WD)">
                            4 Wheel Drive(4WD)
                        </option>
                    </Form.Select>
                </Form.Group>

                {/* Number of axles */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Number Of Axles</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="no_of_axles"
                        value={vehiclesFormData.no_of_axles}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {axlesOptions}
                    </Form.Select>
                </Form.Group>

                {/* Number of wheels */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Number Of Wheels</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="number_of_wheels"
                        value={vehiclesFormData.number_of_wheels}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {wheelsOptions}
                    </Form.Select>
                </Form.Group>

                {/* Tyre Size - Front */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Tyre Size - Front</label>
                    <Form.Control
                        type="text"
                        name="tyre_size_front"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.tyre_size_front}
                    />
                </Form.Group>

                {/* Tyre Size - Rear */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Tyre Size - Rear</label>
                    <Form.Control
                        type="text"
                        name="tyre_size_rear"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.tyre_size_rear}
                    />
                </Form.Group>

                {/* Gross Weight */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Gross Weight</Form.Label>
                    <Form.Control
                        type="text"
                        name="gross_weight"
                        value={vehiclesFormData.gross_weight}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Tare Weight */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Tare Weight</Form.Label>
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="tare_weight"
                        value={vehiclesFormData.tare_weight}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Cylinder</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="cyliners"
                        value={vehiclesFormData.cyliners}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {cylinderOption}
                    </Form.Select>
                </Form.Group>

                <Form.Group className=" form-group width-33 mb-3">
                    <label>Engine Type</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="engine_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.engine_type}
                    >
                        <option value="">Select Engine Type</option>
                        <option value="Inline">Inline</option>
                        <option value="Vee">Vee</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="form-group width-33 mb-3">
                    <label>Engine Size</label>
                    <Form.Control
                        type="text"
                        name="engine_size"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.engine_size}
                    />
                </Form.Group>

                {/* Colour */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Colour</label>
                    <Form.Control
                        type="text"
                        name="colour"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.colour}
                    />
                </Form.Group>

                {/* Seat Capacity */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Seat Capacity</label>
                    <Form.Control
                        type="text"
                        name="seat_capacity"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.seat_capacity}
                    />
                </Form.Group>

                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Driving Position</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="driving_position"
                        value={vehiclesFormData.driving_position}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        <option value="">Select Driving Position</option>
                        <option value="RHD">RHD</option>
                        <option value="LHD">LHD</option>
                        <option value="Center">Center</option>
                    </Form.Select>
                </Form.Group>
            </div>

            {/* Second Section Vin / Engine Section START */}
            <div className={`form-outer mb-2 vin-engine-section mt-2  hidden-form ${fieldShow ? "active" : ""}`}>
                <Form.Group className=" form-group width-50 mb-3">
                    <label>VIN Number</label>
                    <Form.Control
                        type="text"
                        name="vin_number"
                        className={
                            checkVinData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        onBlur={(e) => checkVinExist(e)}
                        value={vehiclesFormData.vin_number}
                    />
                    {checkVinData &&
                        <div className="invalid-feedback">This Vin Number is already registered.</div>
                    }
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <label>Chassis Number</label>
                    <Form.Control
                        type="text"
                        name="chasis_number"
                        className={
                            checkChasisData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        onBlur={(e) => checkChasisExist(e)}
                        value={vehiclesFormData.chasis_number}
                    />
                    {checkChasisData &&
                        <div className="invalid-feedback">
                            This Chassis Number is already registered.
                        </div>
                    }
                </Form.Group>
                <Form.Group
                    className={`form-group width-50 mb-3`}
                >
                    <label>Engine Number</label>
                    <Form.Control
                        type="text"
                        name="engine_number"
                        className={
                            checkEngineData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        // onBlur={(e) => checkEngineExist(e)}
                        value={vehiclesFormData.engine_number}
                    />
                    {checkEngineData &&
                        <div className="invalid-feedback">
                            This Engine Number is already registered.
                        </div>
                    }
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <label>Fleet Number</label>
                    <Form.Control
                        type="text"
                        name="fleet_number"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.fleet_number}
                        onBlur={(e) => checkFleetExist(e)}
                        className={
                            checkFleetData && "form-control is-invalid"
                        }
                    />
                    {checkFleetData &&
                        <div className="invalid-feedback">
                            This Fleet Number is already registered.
                        </div>
                    }
                </Form.Group>
            </div>
            {/* Second Section Vin / Engine Section END */}
        </>
    )
}

export default TruckFields;
