import { isNull } from "underscore";
import {
  SAVE_QUOTE_LABOUR,
  SAVE_QUOTE_LABOUR_SUCCESS,
  SAVE_QUOTE_LABOUR_FAIL,
  GET_QUOTE_LABOUR_LIST,
  GET_QUOTE_LABOUR_LIST_SUCCESS,
  GET_QUOTE_LABOUR_LIST_FAIL,
  DELETE_QUOTE_LABOUR,
  DELETE_QUOTE_LABOUR_SUCCESS,
  DELETE_QUOTE_LABOUR_FAIL,
  UPDATE_QUOTE_LABOUR,
  UPDATE_QUOTE_LABOUR_SUCCESS,
  UPDATE_QUOTE_LABOUR_FAIL,
  EDIT_QUOTE_LABOUR_MANY,
  EDIT_QUOTE_LABOUR_MANY_SUCCESS,
  EDIT_QUOTE_LABOUR_MANY_FAIL,
  INLINE_UPDATE_LABOUR,
  INLINE_UPDATE_LABOUR_SUCCESS,
  INLINE_UPDATE_LABOUR_FAIL 
} from "./actionTypes";

const initialState = {
  data: [],
  labourData: null,
  error: {
    message: "",
  },
  quoteLabourList: null,
  isDataLoad: 0,
  deleteLabour: null,
  updateLabour: null,
};

const QuoteLabourReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_QUOTE_LABOUR:
      state = { 
        ...state, 
        labourData: action.payload,
      };
    break;
    case SAVE_QUOTE_LABOUR_SUCCESS:
      state = { 
        ...state, 
        labourData: action.payload,
        quoteLabourList: [...state.quoteLabourList,action.payload],  
      };
    break;
    case SAVE_QUOTE_LABOUR_FAIL:
      state = {
        ...state,
        labourData: null,
        error: {
          message: action.payload,
        },
      };
    break;
    case GET_QUOTE_LABOUR_LIST:
      state = { 
        ...state, 
        isDataLoad: 0
      };
    break;
    case GET_QUOTE_LABOUR_LIST_SUCCESS:
      state = { 
        ...state, 
        quoteLabourList: action.payload,
        isDataLoad: 1
      };
    break;
    case GET_QUOTE_LABOUR_LIST_FAIL:
      state = {
        ...state,
        quoteLabourList: null,
        error: {
          message: action.payload,
        },
        isDataLoad: 1
      };
    break;
    case DELETE_QUOTE_LABOUR:
      state = { 
        ...state, 
        deleteLabour: action.deleteLabour,
      };
    break;
    case DELETE_QUOTE_LABOUR_SUCCESS:
      state = { 
        ...state, 
        deleteLabour: action.payload,
        quoteLabourList:  [
          ...state.quoteLabourList.filter(item => (item.id !== action.payload.id)), 
        ]
      };
    break;
    case DELETE_QUOTE_LABOUR_FAIL:
      state = {
        ...state,
        deleteLabour: null,
        error: {
          message: action.payload,
        },
      };
    break;

    case UPDATE_QUOTE_LABOUR:
      state = { 
        ...state, 
        updateLabour: action.payload,
      };
    break;
    case UPDATE_QUOTE_LABOUR_SUCCESS:

      const labours = [...state.quoteLabourList];
      const indexLabour = labours.findIndex( c => c.id === action.payload.id);  
      if(indexLabour !== -1){
        labours[indexLabour] = {
              ...labours[indexLabour],
              ...action.payload
          }
          // parts[indexPart] = {
          //   retail_price:action.payload.retail
          // }

      }
    
      state = { 
        ...state, 
        updateLabour: action.payload,
        quoteLabourList : labours
      };

    break;
    case UPDATE_QUOTE_LABOUR_FAIL:
      state = {
        ...state,
        updateLabour: null,
        error: {
          message: action.payload,
        },
      };
    break;


    case INLINE_UPDATE_LABOUR:
      state = { 
        ...state, 
        updateLabour: action.payload,
      };
    break;
    case INLINE_UPDATE_LABOUR_SUCCESS:

      const labours1 = [...state.quoteLabourList];
      const indexLabour1 = labours1.findIndex( c => c.id === action.payload.id);  
      if(indexLabour1 !== -1){
        labours1[indexLabour1] = {
              ...labours1[indexLabour1],
              ...action.payload
          }
      }
    
      state = { 
        ...state, 
        updateLabour: action.payload,
        quoteLabourList : labours1
      };

    break;
    case INLINE_UPDATE_LABOUR_FAIL:
      state = {
        ...state,
        updateLabour: null,
        error: {
          message: action.payload,
        },
      };
    break;

    case EDIT_QUOTE_LABOUR_MANY:
      state = { 
        ...state, 
        labourManySuccess: false,
      };
    break;
    case EDIT_QUOTE_LABOUR_MANY_SUCCESS:
      state = { 
        ...state, 
        labourManySuccess: true,
      };
    break;
    case EDIT_QUOTE_LABOUR_MANY_FAIL:
      state = {
        ...state,
        labourManySuccess: false,
        error: {
          message: action.payload,
        },
      };
    break;

    
    default:
      state = { ...state };
    break;
  }
  return state;
};

export default QuoteLabourReducer;
