import "cropperjs/dist/cropper.css";
import { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Cropper } from "react-cropper";
import "../../style.scss";

let ImageCropModal = ({ showImageCropModal, file, setFile }) => {
  const [cropImage, setCropImage] = useState("");
  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCropImage(cropper.getCroppedCanvas().toDataURL());
  };

  const handleCropImage = async () => {
    showImageCropModal(false);
    setFile(cropImage);
  };

  return (
    <>
      <div>
        <Modal
          className="preview-dialog"
          size="lg"
          show={true}
          onHide={() => showImageCropModal(false)}
        >
          <Modal.Header>
            <Modal.Title className="d-flex justify-space-between">
              <span>Upload & Crop Image</span>
              <Button
                type="button"
                className="close-btn"
                onClick={() => showImageCropModal(false)}
              >
                <i className="la la-times"></i>{" "}
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-around align-center ">
              <Cropper
                src={file}
                style={{ height: 500, width: "550px" }}
                // Cropper.js options
                crop={onCrop}
                ref={cropperRef}
                cropBoxMovable={true}
                cropBoxResizable={false}
                viewMode={1}
                toggleDragModeOnDblclick={false}
                dragMode="move"
              />
              <Button
                className="btn btn-success btn-rounded mb-3"
                onClick={handleCropImage}
              >
                Upload
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ImageCropModal;
