import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { SAVE_JOBSMSTEMPLATE, GET_JOBSMSTEMPLATE_LIST } from "../../store/commonApiStore/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { saveJobSmsTemplateSuccess } from "../../store/commonApiStore/actions";
import { looselyMobileRegex } from "utils/validationRegex";
import { useFormik } from "formik";
import * as yup from "yup";
import smsImage from "../../assets/images/sms-vector.png";
import axios from "axios";
import { SENT_BOOKINGJOB_SMS } from "../../store/jobDetails/actionTypes";
import { CHECK_MOBILE_EXIST } from "../../store/contacts/actionTypes";
import { showSuccess } from "utils/alertMessage";
import { getInvoceEditDetail } from "store/invoiceDetail/action";
import { getRaisedInvoiceAPI, getCompletedInvoiceAPI } from "store/invoices/api";
import { sentBookingJobSmsSuccess } from "../../store/jobDetails/actions";
import { checkMobileExist } from "helpers/backendHelper";

const sendSmsValidationSchema = yup.object().shape({
  template: yup.string().trim().required("Please select a template"),
  send_to: yup.string().trim().matches(looselyMobileRegex, { message: "Please provide a valid phone number" }).required("Please provide a phone number"),
  message_body: yup.string().trim().required("Please write a message")
})

const sendDigitalInvoiceValidationSchema = yup.object().shape({
  phone_number: yup.string().trim().matches(looselyMobileRegex, { message: "Please provide a valid phone number" }).required("Please provide a phone number"),
})

const SendSmsToUserModal = ({
  rego,
  workshop_id,
  type,
  id,
  cust_id,
  customer_id,
  mobileCode,
  SMSModalShow,
  handleSMSModalClose,
  customerName,
  number,
  setIsLoading,
  setMobileNumberForCustomer,
  mobileNumberForCustomer,
  pageType,
  isInvoiceB=false,
  currentPageLimit = 10,
  currentPageNumber = 1,
  currentSize = ""
}) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("send_sms");
  const [udpateJobBookingConfirmation, setUdpateJobBookingConfirmation] = useState(false);
  const [isUpdateMobile, setIsUpdateMobile] = useState(false);
  const [isMobileExist, setIsMobileExist] = useState(false);

  const invoice = useSelector((state) => state.invoice);

  let MobileExist = useSelector(
      (state) => state.ContactsReducer.checkMobileData
  );

  const checkMobileExistFun = (e) => {
    if(isUpdateMobile){
      if (e.length >= 9 && e.length <= 11) {
        setIsMobileExist(MobileExist);
        dispatch({
          type: CHECK_MOBILE_EXIST,
          payload: {
              get: "all",
              conditions: {
                  mobile_number: e,
                  is_deleted: 0,
                  workshop_id: workshop_id,
                  "id !=": cust_id
              },
          },
        });
      }else{
        setIsMobileExist(false);
      }
    }else{
      setIsMobileExist(false);
    }
  };

  const [tinyUrl, setTinyUrl] = useState();
  const converToTinyUrl = async (values) => {
    await axios
      .get("https://tinyurl.com/api-create.php?url=" + values)
      .then((response) => {
          setTinyUrl(response.data)
      })
      .catch((error) => {

      });
  }  

  if(type == "invoice"){    
    converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(id + "WS" + workshop_id + "WSIN"));    
  }else if(type == "cashsale") {      
    converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(id + "WS" + workshop_id + "WSCS"));
  } else if (type == "payment_invoice") {
    converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(id + "WS" + workshop_id + "WSINWSP"));
  } else if (type == "payment_cashsale") {
    converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(id + "WS" + workshop_id + "WSCSWSP"));
  }

  useEffect(() => {
    dispatch({
      type: GET_JOBSMSTEMPLATE_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: workshop_id,
          type: "script",
          template_type: "1",
          is_sms: 1,
          is_deleted: 0,
        },
      },
    });
  },[])

  const {jobSmsSaveData,loadingLogins,jobSmsData} = useSelector(
    (state) => state.CommonApiStoreReducer
  );

  const formikSendDigitalInvoice = useFormik({
    initialValues: {
      phone_number: mobileNumberForCustomer || "" 
    },
    onSubmit: handleSendDigitalInvoiceSave,
    validationSchema: sendDigitalInvoiceValidationSchema
  })

  const formikSendSms = useFormik({
    initialValues: {
      template: "",
      message_body: "",
      send_method: "sms",
      send_to: mobileNumberForCustomer || "",
    },
    onSubmit: handleSendSmsSave,
    validationSchema: sendSmsValidationSchema
  })

  async function handleSendDigitalInvoiceSave(values) { 
    if (isUpdateMobile && !isInvoiceB) {
      const requestMobile = {
          get: "all",
          conditions: {
              mobile_number: values.phone_number,
              is_deleted: 0,
              workshop_id: workshop_id,
              "id !=": cust_id      
          },
      };
      const mobileDataResponse = await checkMobileExist({ payload: requestMobile });      
      if (mobileDataResponse?.code === 200) {
          setIsMobileExist(true);
          return;
      }
    }

    setIsMobileExist(false); 
    setIsLoading(true);
    var messageVar = "Invoice";

    var table = "";
    if (type == "invoice" || type == "payment_invoice") {
        table = "chkInvoice";
    } else if (type == "cashsale" || type == "payment_cashsale") {
        table = "chkCashSale";
    }

    let SendDigitalInvoiceReq = {};

    SendDigitalInvoiceReq.type = type;
    SendDigitalInvoiceReq.workshop_id = workshop_id;
    SendDigitalInvoiceReq.job_id = id;
    SendDigitalInvoiceReq.customer_id = customer_id;
    SendDigitalInvoiceReq.send_method = 'sms';
    SendDigitalInvoiceReq.table = table;
    SendDigitalInvoiceReq.send_to = values.phone_number;
    SendDigitalInvoiceReq.to = values.phone_number;

    SendDigitalInvoiceReq.message = "Hi " + customerName + " click the link to view your digital " + messageVar + " for Rego #" + rego + " " + tinyUrl;
    if (type == "payment_invoice" || type == "payment_cashsale") {
      SendDigitalInvoiceReq.message = "Hi " + customerName + " click the link to view your paid digital " + messageVar + " for Rego #" + rego + " " + tinyUrl;
    }

    SendDigitalInvoiceReq.number = number;
    SendDigitalInvoiceReq.phone_code = mobileCode;
    SendDigitalInvoiceReq.subject = "SMS";

    if (isUpdateMobile) {
        SendDigitalInvoiceReq.update_mobile = "1";
        if (type == "invoice") {
          setMobileNumberForCustomer(values.phone_number)
        }
        if(isInvoiceB){
          SendDigitalInvoiceReq.update_mobile_type = "insurance_company";
        }
    }

    dispatch({
        type: SENT_BOOKINGJOB_SMS,
        payload: SendDigitalInvoiceReq,
    });

    setUdpateJobBookingConfirmation(true);       
  };

  function handleSendSmsSave(values) {    
    setIsLoading(true);

    var table = "";
    if (type == "invoice" || type == "payment_invoice") {
        table = "chkInvoice";
    } else if (type == "cashsale" || type == "payment_cashsale") {
        table = "chkCashSale";
    }

    let sendSmsReq = {};
    sendSmsReq.workshop_id = workshop_id;
    sendSmsReq.job_id = [id];
    sendSmsReq.template_id = values.template;
    sendSmsReq.send_method = 'sms';
    sendSmsReq.table = table;
    sendSmsReq.send_to = [values.send_to];
    sendSmsReq.to = [values.send_to];
    sendSmsReq.message = values.message_body;  
    sendSmsReq.phone_code = mobileCode;

    dispatch({
      type: SAVE_JOBSMSTEMPLATE,
      payload: sendSmsReq,
    });    
  }

  const getSmsTemplate = async (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let smsTemplateID = e.target.value;
    let message_body = optionElement.getAttribute("data-val")
      ? optionElement.getAttribute("data-val")
      : "";
    await formikSendSms.setFieldValue("message_body", message_body ?? "");
    formikSendSms.setFieldValue("template", smsTemplateID ?? "");
  };  

  useEffect(() => {
    if (isUpdateMobile) {
      if(!isInvoiceB){
        checkMobileExistFun(formikSendDigitalInvoice.values?.phone_number);
      }
      // setIsMobileExist(MobileExist); 
    } else {
      setIsMobileExist(false);    
    }
    
  }, [isUpdateMobile])

  useEffect(() => {
    if (isUpdateMobile) {
      setIsMobileExist(MobileExist);
    }else{
      setIsMobileExist(false);
    }
  }, [MobileExist])  

  const jobBookingConfirmation = useSelector((state) => state?.JobDetails?.jobBookSmsSent || false);

  useEffect(() => {
      if (jobBookingConfirmation && udpateJobBookingConfirmation) {  
          if(pageType == "edit_invoice"){
            dispatch(getInvoceEditDetail({ id }));
          }else if(pageType == "raised_invoice"){     
            getRaisedInvoiceAPI(invoice?.raisedInvoices)(dispatch, "invoice", currentPageLimit, currentPageNumber)(workshop_id, 1, currentSize, true);
          }else if(pageType == "completed_invoice"){
            getCompletedInvoiceAPI(invoice?.completedInvoices)(dispatch, "invoice", currentPageLimit, currentPageNumber)(workshop_id, 1, currentSize, true);
          }
          showSuccess('notifications sent successfully.');
          setUdpateJobBookingConfirmation(false);
          setIsLoading(false);
          setIsMobileExist(false);
          handleSMSModalClose();
          dispatch(sentBookingJobSmsSuccess(null));
      }
  }, [jobBookingConfirmation, udpateJobBookingConfirmation]);

  useEffect(() => {
    let saveDataResponse = jobSmsSaveData ? jobSmsSaveData : "";
    if (saveDataResponse !== "" || saveDataResponse) {
      handleSMSModalClose();
      dispatch(saveJobSmsTemplateSuccess(null));
    } 
    setIsLoading(loadingLogins);
  }, [jobSmsSaveData,loadingLogins]);

  return (
    <Modal
        className="add-customer-vehicle preview-dialog"
        size="lg"
        show={SMSModalShow}
        onHide={handleSMSModalClose}
        centered
    >
        <Modal.Body>
            <div className="custom-tabs p-3">
                <Tabs
                    defaultActiveKey="send_sms"
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                >
                    <Tab eventKey="send_sms" title="SEND SMS">                        
                          <Form noValidate validated={Object.keys(formikSendSms.touched).length} id="smsTemplateForm" onSubmit={formikSendSms.handleSubmit}>
                        <div className="column-wrapper d-flex p-0">    
                            <div className="left-column">                               
                              <div className="custom-normal-select mb-3">
                                    <Form.Label>Template *</Form.Label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      required
                                      name="template"
                                      id="template"          
                                      value={formikSendSms.values?.template ?? ""}   
                                      isInvalid={Boolean(formikSendSms.errors.template)}
                                      onBlur={formikSendSms.handleBlur}
                                      onChange={(e) => {
                                        getSmsTemplate(e)
                                      }}                     
                                    >
                                      <option disabled key={0} value="" data-val="">
                                        Select Template
                                      </option>
                                      {jobSmsData && jobSmsData.map((val) => {
                                        return (
                                          <option
                                            key={val.id}
                                            value={val.id}
                                            data-val={val.msg_txt ? val.msg_txt : ""}
                                          >
                                            {val.name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>   
                                    {
                                      formikSendSms.errors?.template && (<Form.Control.Feedback type="invalid">
                                        {
                                          formikSendSms.errors?.template
                                        }
                                      </Form.Control.Feedback>)
                                    }                              
                              </div>

                              <Form.Group className="form-group mb-3">
                                <Form.Label>Mobile NO *</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="send_to"
                                  id="send_to"
                                  onBlur={formikSendSms.handleBlur}                                  
                                  value={formikSendSms.values?.send_to}
                                  onChange={formikSendSms.handleChange}
                                  isInvalid={Boolean(formikSendSms.errors.send_to)}
                                  isValid={!Boolean(formikSendSms.errors.send_to)}
                                  placeholder="Enter Mobile Number"
                                  required
                                />      
                                {
                                  formikSendSms.errors?.send_to && (<Form.Control.Feedback type="invalid">
                                    {formikSendSms.errors?.send_to}
                                  </Form.Control.Feedback>)
                                }                        
                              </Form.Group>   

                              <Form.Group className="form-group mb-3">
                                <Form.Label>Message *</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  name="message_body"
                                  id="text_message_body"                                  
                                  rows={8}
                                  value={formikSendSms.values?.message_body ?? ""}
                                  onBlur={formikSendSms.handleBlur}
                                  onChange={formikSendSms.handleChange}
                                  isInvalid={Boolean(formikSendSms.errors.message_body)}
                                  placeholder="Enter Message"
                                  required
                                />          
                                {
                                  formikSendSms.errors?.message_body && (<Form.Control.Feedback type="invalid">
                                    {formikSendSms.errors.message_body}
                                  </Form.Control.Feedback>)
                                }                   
                              </Form.Group>                                          
                            </div>

                            <div className="right-column">
                              <span className="panel-heading">PREVIEW</span>
                              <div className="preview-outer">
                                <div className="preview-wrapper">
                                  <span className="panel-heading">MESSAGES</span>
                                  <div className="panel-body">
                                    <div className="msg-content" style={{ wordBreak: "break-all" }}>                                     
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: formikSendSms.values?.message_body
                                            ??
                                            "",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                          </Form>
                    </Tab>

                    <Tab eventKey="send_digital_invoice" title="SEND DIGITAL INVOICE">
                        <Form noValidate validated={Object.keys(formikSendDigitalInvoice.touched).length} id="smsTemplateForm" onSubmit={formikSendDigitalInvoice.handleSubmit}>
                            <div className="content-wrapper">
                                <div className="form-outer form-align-start flex-nowrap">    
                                  <div className="">
                                    <img src={smsImage} alt="SMS" style={{ maxWidth: "80px", width: "80px" }}></img>
                                  </div>                                
                                    <Form.Group className="auto-complete-wrapper mb-3 ml-1" style={{width: "38%"}}>
                                        <label>Mobile Number *</label>
                                        <Form.Control
                                            type="text"
                                            name="phone_number"
                                            value={formikSendDigitalInvoice.values?.phone_number ?? ""}
                                            // onBlur={(e) => {
                                            //   checkMobileExist(e.target.value);
                                            // }}
                                            // onChange={(e) => {
                                            //   formikSendDigitalInvoice.handleChange(e);
                                            // }}
                                            onChange={(e) => {
                                              formikSendDigitalInvoice.handleChange(e);
                                              if(!isInvoiceB){
                                                checkMobileExistFun(e.target.value);
                                              }
                                            }}
                                            isInvalid={Boolean(formikSendDigitalInvoice.errors.phone_number)}
                                            isValid={!Boolean(formikSendDigitalInvoice.errors.phone_number)}                                            
                                            autoComplete="off"
                                            placeholder="Enter Mobile Number"
                                            required
                                        />          
                                        {
                                          formikSendDigitalInvoice.errors?.phone_number && (<Form.Control.Feedback type="invalid">
                                            {formikSendDigitalInvoice.errors?.phone_number}
                                          </Form.Control.Feedback>)
                                        }                                                                     
                                        {isMobileExist && (
                                          <div className="err-msg">
                                              This Mobile Number is already in use.
                                          </div>
                                        )}                                        
                                    </Form.Group>                                                                                                     
                                </div> 
                                <div className="col-md-12">
                                  <div className="align-items-center d-flex form-group mb-2" style={{ marginLeft: "5rem" }}>
                                      <Form.Check className="custom-check form-check table-checkbox">
                                          <Form.Check.Input
                                              id="update_message"
                                              type="checkbox"
                                              className="custom-check booking-confirmation"
                                              onChange={() => {
                                                  setIsUpdateMobile(!isUpdateMobile)
                                              }}
                                          />
                                          <Form.Check.Label>
                                              <span className="check"></span>
                                          </Form.Check.Label>
                                      </Form.Check>
                                      <div style={{ marginLeft: "10px" }} className="font-size-13">Update Customer Mobile #</div>
                                  </div>
                              </div>                              
                            </div>
                        </Form>
                    </Tab>
                </Tabs>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="outline-secondary"
                className="btn-outline rounded-btn"
                onClick={handleSMSModalClose}
            >
                Close
            </Button>            

            <Button
              className="rounded-btn btn-success"
              variant="success"
              disabled={isMobileExist && activeTab !== "send_sms" ? true : false}
              onClick={() => activeTab === "send_sms" ? formikSendSms.handleSubmit() : formikSendDigitalInvoice.handleSubmit()}
            >
              Save
          </Button>
        </Modal.Footer>
    </Modal>
  );
};
export default SendSmsToUserModal;
