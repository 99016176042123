import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_UNASSIGNED_JOBS,
  GET_UNASSIGNED_JOBS_COUNT,
} from "../../../../store/unassignedJobs/actionTypes";
import {
  GET_STATUS_COLOR,
  GET_JOBSMSTEMPLATE_LIST,
  GET_JOBEMAILTEMPLATE_LIST,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE,
  DELETE_JOBS,
  SEARCH_JOBS,
  GET_WORKSHOP_TECHNICIAN,
} from "../../../../store/commonApiStore/actionTypes";
import { GET_WORKSHOP_BAYS } from "../../../../store/scheduler/actionTypes";
import Reschedule from "../../../scheduler/components/Reschedule";
import { manageStatusChange } from "../../../../store/Jobs/actions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { searchJobsSuccess } from "../../../../store/commonApiStore/actions";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Form from "react-bootstrap/Form";
import DeleteRecords from "../../../../utils/deleteRecords";
import { deleteJobs, updateJob } from "../../../../helpers/backendHelper";
import LoaderImage from "../../../../assets/images/page-loader.svg";
import { showError, showSuccess } from "../../../../utils/alertMessage";
import { submitJobEmailTemplateSuccess } from "../../../../store/commonApiStore/actions";
import { addComma } from "utils/numberFunction";
import PoWarning from "views/modals/PoWarning";
import NoShowWarning2 from "views/modals/NoShowWarning2";
import NoCarIcon from "assets/images/no-car-icon-2_20.png";
import SMSPreview from "views/modals/SMSPreview";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RescheduleOnly from "views/scheduler/components/RescheduleOnly";

const UnassignedJobList = ({ activeTab }) => {
  const [jobData, setJobData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [emptyMessage, setEmptyMessage] = useState("No data available in table");
  const [stage, setStage] = useState("");
  const [status, setStatus] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [sendNotificationTo, setSendNotificationTo] = useState("");
  const [notificationTemplate, setNotificationTemplate] = useState("");
  const [showAssignJobPopup, setShowAssignJobPopup] = useState(false);
  const [updateCalendar, setUpdateCalendar] = useState(false);
  const [job, setJob] = useState({});
  const [showPOWarningModal, setShowPOWarningModal] = useState(false);
  const [showNoShowModal, setShowNoShowModal] = useState(false);
  const [selectedRowPhone, setSelectedRowPhone] = useState({});
  const [selectedRowPhoneCode, setSelectedRowPhoneCode] = useState({});
  const [loading, setLoading] = useState(false);

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateFormat = storedData.date_format;
  const currencySymbol = storedData.currency;
  const deleteMsg = "Job Deleted Sucessfully";
  const deletePayloadData = {
    payload: {
      action_type: "delete",
      ids: selectedRow,
    },
  };
  const {
    statusColor,
    loadingLogins,
    jobSmsData,
    jobEmailData,
    smsEmailTemplate,
    searchJobs,
  } = useSelector((state) => state.CommonApiStoreReducer);
  const { jobCount } = useSelector((state) => state.UnassignedJobsReducer);

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);

    return responseDate;
  };

  const [showSmsPreviewModal, setShowSmsPreviewModal] = useState(false);

  useEffect(() => {
    if (jobCount != 0) {
      setTotalRecords(jobCount);
    }
  }, [jobCount]);

  useEffect(() => {
    if (updateCalendar) {
      setUpdateCalendar(false);
      loadData();
    }
  }, [updateCalendar]);

  useEffect(() => {
    return () => {
      dispatch(searchJobsSuccess({}));
    };
  }, []);

  useEffect(() => {
    setSearch("");
  }, [activeTab]);

  useEffect(() => {
    dispatch({
      type: GET_WORKSHOP_TECHNICIAN,
      payload: {
        get: "all",
        conditions: { "Workshops.id": workshop_id },
        contain: {
          Users: {
            conditions: {
              "Users.workshop_id": workshop_id,
              "Users.role_id IN": [3, 7, 8, 9],
              "Users.is_deleted": 0,
            },
            fields: ["id", "workshop_id", "firstname", "lastname"],
          },
          "Users.EmployeesDetails": {
            fields: [
              "EmployeesDetails.id",
              "EmployeesDetails.user_id",
              "EmployeesDetails.shift_working_day",
              "EmployeesDetails.workshop_id",
            ],
          },
          WorkshopOtherDetails: {
            fields: [
              "shift_start_time",
              "shift_finish_time",
              "shift_start_day",
              "shift_finish_day",
              "id",
              "workshop_id",
              "schedule_details",
              "technician_timeslot",
            ],
          },
        },
        fields: ["id"],
      },
    });
  }, [dispatch, workshop_id]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({
        type: GET_WORKSHOP_BAYS,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            status: 1,
            display_in_schedule: 1,
          },
          fields: ["id", "workshop_id", "name", "location"],
        },
      });
    };
    fetchData().catch(console.error);
  }, [dispatch, workshop_id]);

  const checkStatusColor = (cell, row) => {
    if (statusColor !== null) {
      return (
        <div
          style={{
            background: statusColor[cell],
          }}
          className="jobStatus"
        >
          {cell}
        </div>
      );
    }
  };

  const columns = [
    {
      dataField: "created_on",
      text: "Date",
      formatter: dateFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "job_number",
      text: "Job No.",
      formatter: (cell, row) => {
        return (
          <div>
            <span className="text-blue mr-half">{row.job_number}</span>
            {row.show_allocation_column && row.show_allocation_column == 1 &&
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Split Job</span>
                  </Tooltip>

                }
              >
                <span className="pj-tag">SP</span>
              </OverlayTrigger>
            }
            {row.customer_quotes_id > 0 &&
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Converted Quote</span>
                  </Tooltip>

                }
              >
                <span className="co-tag px-2">Q</span>
              </OverlayTrigger>
            }
            {(row.is_copy_job === "" || row.is_copy_job === 0) && row.is_copid_job === 1 &&
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Carryover</span>
                  </Tooltip>

                }
              >
                <span className="pj-tag">CO</span>
              </OverlayTrigger>
            }
            {row.is_copy_job > 0 &&
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Carryover</span>
                  </Tooltip>

                }
              >
                <span className="co-tag">CO{row.is_copy_job}</span>
              </OverlayTrigger>
            }
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "customer.customers_details[0]",
      text: "Contact Name",
      formatter: (data) => {
        if (data) {
          if (data?.type === '1') {
            return data
              ? `${data.company_name || ""}`
              : "";
          } else {
            return data
              ? `${data.firstname || ""} ${data.lastname || ""}`
              : "";
          }
        } else {
          return "";
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "customer.customers_details[0].mobile_number",
      text: "Contact Number",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "rego",
      text: "Rego",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "job_booking_type.name",
      text: "RO Type",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "f_date",
      text: "Scheduled",
      formatter: dateFormatter,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "total",
      text: "Est Total",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
      formatter: (data) => {
        if (data == "NaN") return `${currencySymbol} 0.00`;
        else {
          return data
            ? `${currencySymbol}  ${addComma(data)}`
            : `${currencySymbol} 0.00`;
        }
      },
    },
    {
      dataField: "job_status",
      text: "Status",
      formatter: checkStatusColor,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <Link to={`/job_detail/${row.id}`} className="" title="EDIT">
                  <i className="fa fa-pencil text-info"></i>
                  <span>Edit</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className=" "
                  title="ASSIGN"
                  onClick={() => handleAssignJob(row)}
                >
                  <i className="fa fa-tasks text-info"></i>
                  <span>Assign Job</span>
                </Link>
              </li>
              <li>
                <Link to="/scheduler" className=" " title="SCHEDULE">
                  <i className="fa fa-calendar text-info"></i>
                  <span>Schedule</span>
                </Link>
              </li>
              <li>
                <Link
                  to={`/invoice-create/${row.id}`}
                  className=" "
                  title="Deposit"
                >
                  <i className="fa fa-dollar text-info"></i>
                  <span>Deposit</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setSelectedRow("");
                  setShowPOWarningModal(false);
                  setShowNoShowModal(false);
                  setSelectedRowPhone({});
                  setSelectedRowPhoneCode({});
                  setSelectedRowPhone({
                    phone:
                      row?.customer?.customers_details?.[0]?.mobile_code +
                      row?.customer?.customers_details?.[0]?.mobile_number,
                    customer_id: row?.customer_id,
                    customer_detail_id: row.customer?.cust_detail?.id,
                    from_date: row?.from_date,
                    rego: row?.vehicle?.rego,
                    customer_name: row.customer?.cust_detail?.type === "1" ? row.customer?.cust_detail?.company_name : row.customer?.cust_detail?.firstname
                  });
                  setSelectedRowPhoneCode({
                    phoneCode:
                      row?.customer?.customers_details?.[0]?.mobile_code
                    // row.customer.cust_detail.mobile_number,
                  });
                  setSelectedRow(row?.id);

                  if (row?.jobs_parts?.length > 0) {
                    setShowPOWarningModal(!showPOWarningModal);
                  } else {
                    setShowNoShowModal(!showNoShowModal);
                  }
                }}
              >
                <Link to="#" >
                  <img src={NoCarIcon} alt="Loading" height={20} width={20} ></img>
                  <span>No Show</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className="delete-link" title="DELETE">
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </Link>
              </li>
            </div>
          </div>
        );
      },
    },
  ];
  const handleAssignJob = (data) => {
    setJob(data);
    setShowAssignJobPopup(true);
  };

  const handleAssignPopupClose = () => {
    setShowAssignJobPopup(false);
  };
  useEffect(() => {
    if (statusColor === null) {
      dispatch({
        type: GET_STATUS_COLOR,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            status: 1,
          },
          fields: ["name", "color"],
        },
      });
    }

    loadData();
    dispatch({
      type: GET_UNASSIGNED_JOBS_COUNT,
      payload: {
        get: "all",
        conditions: {
          "JobSchedules.workshop_id": workshop_id,
          "JobSchedules.job_stage": "Unassigned",
          "JobSchedules.activity_schedule_id": 0,
          "JobSchedules.is_break_time": 0,
          "JobSchedules.status": 1,
          "JobSchedules.user_id": "0",
          "JobSchedules.is_copid_job": 0,
          "JobSchedules.is_no_show": 0,
        },
        contain: {
          Users: {
            fields: ["id", "workshop_id"],
          },
          "Vehicles.Users": {
            fields: ["Users.id"],
          },
          "Customers.CustomersDetails": {
            conditions: {
              workshop_id: workshop_id,
            },
            fields: ["user_id", "id"],
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
          JobBookingTypes: {
            fields: ["name", "parent_id"],
          },
          Invoices: {
            fields: ["id"],
          },
          WorkshopBaysDetails: {
            fields: ["id"],
          },
        },

        fields: {
          COUNT: "COUNT(JobSchedules.id)",
        },
      },
    });
    if (jobSmsData === null || jobEmailData === null) {
      dispatch({
        type: GET_JOBEMAILTEMPLATE_LIST,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            type: "script",
            template_type: "1",
            is_email: 1,
            is_deleted: 0,
          },
        },
      });

      dispatch({
        type: GET_JOBSMSTEMPLATE_LIST,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            type: "script",
            template_type: "1",
            is_sms: 1,
            is_deleted: 0,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (smsEmailTemplate && smsEmailTemplate.code == 200) {
      showSuccess("Bulk notification sent successfully");

      dispatch(submitJobEmailTemplateSuccess(null));
    }
  }, [smsEmailTemplate]);

  const loadData = (page = 1, pageLimit = 10) => {
    dispatch({
      type: GET_UNASSIGNED_JOBS,
      payload: {
        get: "all",
        conditions: {
          "JobSchedules.workshop_id": workshop_id,
          "JobSchedules.job_stage": "Unassigned",
          "JobSchedules.activity_schedule_id": 0,
          "JobSchedules.is_break_time": 0,
          "JobSchedules.status": 1,
          "JobSchedules.user_id": "0",
          "JobSchedules.is_copid_job": 0,
          "JobSchedules.is_no_show": 0,
        },
        contain: {
          Users: {
            fields: ["id", "workshop_id"],
          },
          "Vehicles.Users": {
            fields: ["Users.id"],
          },
          "Vehicles.Users.CustomersDetails": {
            conditions: {
              workshop_id: workshop_id,
            },
            fields: [
              "CustomersDetails.id",
              "CustomersDetails.user_id",
              "CustomersDetails.firstname",
              "CustomersDetails.lastname",
              "CustomersDetails.mobile_number",
              "CustomersDetails.customer_app",
              "CustomersDetails.type",
              "CustomersDetails.street_address",
              "CustomersDetails.suburb",
              "CustomersDetails.postcode",
              "CustomersDetails.company_name",
              "CustomersDetails.mobile_code"
            ],
          },
          // "Customers.CustDetails": {
          //   conditions: {
          //     "CustDetails.workshop_id": workshop_id,
          //   },
          //   fields: [
          //     "user_id",
          //     "id",
          //     "firstname",
          //     "lastname",
          //     "mobile_number",
          //     "mobile_code",
          //     "phone_number",
          //     "company_name",
          //     "type",
          //   ],
          // },
          "Customers":{
            fields:["id"]
          },
          "Customers.CustomersDetails":{
            conditions: {
              workshop_id: workshop_id,
            },
            fields:["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code","customer_app","street_address","suburb","postcode","state","country"]
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
          JobBookingTypes: {
            fields: ["name", "parent_id"],
          },
          Invoices: {
            fields: [
              "id",
              "job_id",
              "created_on",
              "invoice_number",
              "total",
              "remain_balance",
            ],
          },
          JobsParts: {
            conditions: {
              "JobsParts.workshop_id": workshop_id,
              "JobsParts.is_deleted": 0,
            },
            fields: [
              "id",
              "job_schedule_id"
            ],
          },
          WorkshopBaysDetails: {
            fields: ["id", "name"],
          },
        },
        limit: pageLimit,
        page: page,
        order: {
          "JobSchedules.id": "desc",
        },
        fields: [
          "JobSchedules.id",
          "JobSchedules.workshop_id",
          "JobSchedules.from_date",
          "JobSchedules.customer_quotes_id",
          "JobSchedules.description",
          "JobSchedules.created_on",
          "JobSchedules.total",
          "JobSchedules.claim_number",
          "JobSchedules.from_time",
          "JobSchedules.job_number",
          "JobSchedules.job_status",
          "JobSchedules.job_stage",
          "JobSchedules.customer_id",
          "JobSchedules.vehicle_id",
          "Vehicles.rego",
          "Vehicles.user_id",
          "Vehicles.service_due_date",
          "Vehicles.rego_due_date",
          "Vehicles.make",
          "Vehicles.model",
          "Users.id",
          "Users.firstname",
          "Users.lastname",
          "Customers.id",
          "Customers.email",
          "JobSchedules.is_copid_job",
          "JobSchedules.is_copy_job",
          "JobSchedules.rego",
          "JobSchedules.show_allocation_column"
        ],
      },
    });

    dispatch({
      type: GET_UNASSIGNED_JOBS_COUNT,
      payload: {
        get: "all",
        conditions: {
          "JobSchedules.workshop_id": workshop_id,
          "JobSchedules.job_stage": "Unassigned",
          "JobSchedules.activity_schedule_id": 0,
          "JobSchedules.is_break_time": 0,
          "JobSchedules.status": 1,
          "JobSchedules.user_id": "0",
          "JobSchedules.is_copid_job": 0,
          "JobSchedules.is_no_show": 0,
        },
        contain: {
          Users: {
            fields: ["id", "workshop_id"],
          },
          "Vehicles.Users": {
            fields: ["Users.id"],
          },
          "Customers.CustDetails": {
            conditions: {
              "CustDetails.workshop_id": workshop_id,
            },
            fields: ["user_id", "id"],
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
          JobBookingTypes: {
            fields: ["name", "parent_id"],
          },
          Invoices: {
            fields: ["id"],
          },
          WorkshopBaysDetails: {
            fields: ["id"],
          },
        },

        fields: {
          COUNT: "COUNT(JobSchedules.id)",
        },
      },
    });
  };

  const getJobData = useSelector((state) => state.UnassignedJobsReducer);

  useEffect(() => {
    if (Object.keys(searchJobs).length !== 0) {
      var text = [];

      let totalSearchCount = 0;
      for (var key = 0; key < 10; key++) {
        if (searchJobs[key] !== undefined) {
          totalSearchCount = searchJobs.total_count;
          text.push(searchJobs[key]);
        }
      }
      setTotalRecords(totalSearchCount);
      setJobData(text);
    } else {
      if (getJobData.jobData) {
        setJobData(getJobData.jobData);
        setTotalRecords(jobCount);
      } else if (getJobData.jobData === null) {
        setJobData([]);
        setTotalRecords(0);
      }
    }
  }, [getJobData]);

  const expandRow = {
    showExpandColumn: true,

    renderer: (row) => (
      <div>
        <span dangerouslySetInnerHTML={{ __html: row.description }}></span>
      </div>
    ),
  };

  const checkBox = {
    mode: "checkbox",

    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row.id]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.indexOf(row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
    onSelectAll: (isSelected) => {
      if (isSelected) {
        let ids = [];
        for (var key = 0; key < sizePerPage; key++) {
          if (jobData[key] !== undefined) ids.push(jobData[key].id);
        }
        setSelectedRow(ids);
      } else {
        setSelectedRow([]);
      }
    },

    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          checked={rest.checked}
          name={rest.name}
          id={rest.id}
          type="checkbox"
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          id={rest.id}
          checked={rest.checked}
          type={mode}
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);

    if (search === "") {
      loadData(page, sizePerPage);
    } else {
      let text = [];
      for (
        var key = (page - 1) * sizePerPage;
        key < sizePerPage * page;
        key++
      ) {
        if (searchJobs[key] !== undefined) text.push(searchJobs[key]);
      }
      setJobData(text);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setEmptyMessage("No Results Found")

    if (e.target.value !== "") {
      dispatch({
        type: SEARCH_JOBS,
        payload: {
          workshop_id: workshop_id,
          type: "Unassigned",
          keyword: e.target.value,
        },
      });
    } else {
      dispatch(searchJobsSuccess({}));
      setJobData(getJobData.jobData || []);
      setTotalRecords(jobCount);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => { },
  };

  const emptyDataMessage = () => {
    return (
      <div className={(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  const statusOption = (value) => {
    switch (value) {
      case "Unassigned":
        return <option value="Unassigned">Unassigned</option>;
      case "Bookin":
        return (
          <option value="Pending" selected>
            Booked
          </option>
        );
      case "Inprogress":
        return (
          <>
            <option value="InProgress" selected>
              In Progress
            </option>
            <option value="OnHold">On Hold</option>
            <option value="WaitingForParts">Waiting for Parts</option>
          </>
        );
      case "Archive":
        return (
          <option value="Cancle" selected>
            Cancle
          </option>
        );

      default:
        return <option value="">Select</option>;
    }
  };

  const handleStageChange = (e) => {
    setStage(e.target.value);
    switch (e.target.value) {
      case "Unassigned":
        setStatus("Unassigned");
        break;
      case "Bookin":
        setStatus("Pending");
        break;
      case "Inprogress":
        setStatus("InProgress");
        break;

      case "Archive":
        setStatus("Cancle");
        break;
    }
  };
  const changeStatus = async () => {
    if (selectedRow.length !== 0) {
      if (selectedRow.length < 2) {
        let data = {
          payload: {
            id: selectedRow[0],
            job_status: status,
            job_stage: stage,
          },
        };

        const response = await updateJob(data);
        if (response.code === 200) {
          showSuccess("Status changed successfully");
          loadData();

          if (stage != "Unassigned") {
            dispatch(manageStatusChange(stage));
          }
        } else {
          showError(response.message);
        }
      } else {
        selectedRow.forEach(async (id) => {
          let data = {
            payload: {
              id: id,
              job_status: status,
              job_stage: stage,
            },
          };

          const response = await updateJob(data);
          if (response.code === 200) {
            showSuccess("Status changed successfully");
            if (stage != "Unassigned") {
              dispatch(manageStatusChange(stage));
            }
          } else {
            showError(response.message);
          }
        });
        loadData();
      }
    } else {
      showError("Please select the record");
    }
  };

  const archiveRecord = async () => {
    if (selectedRow.length !== 0) {
      let data = {
        payload: {
          action_type: "archive",
          ids: selectedRow,
        },
      };
      let response = await deleteJobs(data);
      if (response.code === 200) {
        showSuccess("Job Archive Sucessfully");
        loadData();
      } else {
        showError(response.message);
      }
    } else {
      showError("Please select the Jobs");
    }
  };

  const sendNotification = () => {
    if (selectedRow.length !== 0) {
      if (sendNotificationTo) {
        if (notificationTemplate) {
          dispatch({
            type: SUBMIT_JOB_SMSEMAIL_TEMPLATE,
            payload: {
              workshop_id: workshop_id,
              job_id: selectedRow,
              template_id: notificationTemplate,
              send_method: sendNotificationTo,
              table: "chkBookin",
              send_type: "bulk",
            },
          });
        } else {
          showError("Please select the Template");
        }
      } else {
        showError("Please select send method SMS / Email");
      }
    } else {
      showError("Please select the Jobs");
    }
  };

  return (
    <>
      {/* {(loadingLogins || getJobData.loadingLogins || loading) && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )} */}
      {showAssignJobPopup ? (
        <RescheduleOnly
          rescheduleShow={showAssignJobPopup}
          handleRescheduleClose={handleAssignPopupClose}
          jobIdData={job.id}
          startEventTime={job.from_time}
          technicianName={
            job?.customer?.customers_details?.[0]?.firstname +
            " " +
            job?.customer?.customers_details?.[0]?.lastname
          }
          jobNumber={job.job_number}
          jobType={job.job_status}
          setUpdateCalendar={setUpdateCalendar}
          updateCalendar={updateCalendar}
          job_details={job}
          activePage={"jobDetail"}
        />
      ) : (
        ""
      )}
      <div>
        <div className="table-search-wrapper ">
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Search..."
            onChange={(e) => handleSearch(e)}
            value={search}
          />
          <Button
            className="btn-primary rounded-btn"
            onClick={() => navigate("/unassigned-job")}
          >
            <i className="fa fa-plus"></i> Create Job
          </Button>
        </div>

        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: currentPage,
            sizePerPage: sizePerPage,
            totalSize: parseInt(totalRecords),
            nextPageText: "Next",
            prePageText: "Previous",
            sizePerPageList: [10, 25, 50, 100],
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                remote
                keyField="id"
                data={jobData}
                columns={columns}
                onTableChange={onTableChange}
                selectRow={checkBox}
                rowEvents={rowEvents}
                expandRow={expandRow}
                noDataIndication={emptyDataMessage}
                {...paginationTableProps}
              />
              <div>
                <div className="table-pagination">
                  <div>
                    <span>Show</span>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <span>entries</span>
                  </div>
                  <PaginationTotalStandalone {...paginationProps} />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            </div>
          )}
        </PaginationProvider>
        <div className="d-flex custom-table-footer align-end">
          <Button
            variant="warning"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => archiveRecord()}
          >
            <i className="la la-archive font-size-18"></i> Archive
          </Button>
          <Button
            onClick={() => setShowDeleteModal(!showDeleteModal)}
            variant="danger"
            className="rounded-btn d-flex align-center mr-10"
          >
            <i className="la la-times-circle font-size-18"></i> Delete
          </Button>
          {showDeleteModal && (
            <DeleteRecords
              showDeleteModal={setShowDeleteModal}
              refreshData={loadData}
              api={deleteJobs}
              selectedRow={selectedRow}
              message={deleteMsg}
              payloadData={deletePayloadData}
            />
          )}
          <div className="custom-normal-select ">
            <label>Change Stage</label>
            <select
              id="change_stage_unassigned"
              name="change_stage_unassigned"
              onChange={(e) => handleStageChange(e)}
            >
              <option value="">Select</option>
              <option value="Unassigned">Unassigned</option>
              <option value="Bookin">Booked</option>
              <option value="Inprogress">In Progress</option>
              <option value="Archive">Archive</option>
            </select>
          </div>
          <div className="custom-normal-select ">
            <label>Change Status</label>
            <select
              id="change_status_unassigned"
              name="change_status_unassigned"
              onChange={(e) => setStatus(e.target.value)}
            >
              {statusOption(stage)}
            </select>
          </div>
          <Button
            className="btn-primary rounded-btn mr-10"
            onClick={() => changeStatus()}
          >
            Change
          </Button>
          <div className="custom-normal-select mr-10">
            <label>Send Bulk Notification</label>
            <select
              id="template_bulk_chkUnassigned"
              name="template"
              onChange={(e) => setNotificationTemplate(e.target.value)}
            >
              <option value="">Select Template</option>

              {jobSmsData &&
                jobEmailData &&
                [...jobSmsData, ...jobEmailData].map(({ id, name }, index) => (
                  <option value={id} key={index}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div className="radio-wrapper mr-10">
            <Form.Check
              className="custom-check radio-btn secondary-radio mb-2"
              type="radio"
              id="1"
            >
              <Form.Check.Input
                name="size"
                type="radio"
                isValid
                value="sms"
                onChange={(e) => setSendNotificationTo(e.target.value)}
              />
              <Form.Check.Label>
                SMS
                <span className="check"></span>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check
              className="custom-check radio-btn secondary-radio mb-2"
              type="radio"
              id="2"
            >
              <Form.Check.Input
                name="size"
                type="radio"
                isValid
                value="email"
                onChange={(e) => setSendNotificationTo(e.target.value)}
              />
              <Form.Check.Label>
                Email
                <span className="check"></span>
              </Form.Check.Label>
            </Form.Check>
          </div>
          <Button
            className="btn-primary rounded-btn"
            onClick={() => sendNotification()}
          >
            Send
          </Button>
        </div>
      </div>

      {showPOWarningModal &&
        <PoWarning
          showPOWarningModal={showPOWarningModal}
          setShowPOWarningModal={setShowPOWarningModal}
          setShowNoShowModal={setShowNoShowModal}
        />
      }

      {showNoShowModal &&
        <NoShowWarning2
          showNoShowModal={showNoShowModal}
          setShowNoShowModal={setShowNoShowModal}
          selectedRow={selectedRow}
          refreshData={loadData}
          getPhoneForPhoneModal={selectedRowPhone}
          getPhoneCodeForPhoneModal={selectedRowPhoneCode}
          setLoading1={setLoading}
          setShowSmsPreviewModal={setShowSmsPreviewModal}
        />
      }

      {showSmsPreviewModal &&
        <SMSPreview
          showSmsPreviewModal={showSmsPreviewModal}
          setShowSmsPreviewModal={setShowSmsPreviewModal}
          getPhoneForPhoneModal={selectedRowPhone}
          getPhoneCodeForPhoneModal={selectedRowPhoneCode}
          workshop_id={workshop_id}
          selectedRow={selectedRow}
          setLoading1={setLoading}
          refreshData={loadData}
        />
      }
    </>
  );
};

export default UnassignedJobList;
