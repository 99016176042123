import {
  INVENTORY_STOCK_LIST,
  INVENTORY_STOCK_LIST_FAIL,
  INVENTORY_STOCK_LIST_SUCCESS,
  INVENTORY_STOCK_TAKE_HISTORY_LIST_SUCCESS,
  INVENTORY_STOCK_TAKE_LIST,
  INVENTORY_STOCK_TAKE_LIST_FAIL,
  INVENTORY_STOCK_TAKE_LIST_SUCCESS,
  SAVE_STOCK,
  SAVE_STOCK_FAIL,
  SAVE_STOCK_SUCCESS,
  UPDATE_PART_DATA,
  UPDATE_PART_DATA_FAIL,
  UPDATE_PART_DATA_SUCCESS,
  EDIT_STOCK_TAKE_DETAILS,
  EDIT_STOCK_TAKE_DETAILS_FAIL,
  EDIT_STOCK_TAKE_DETAILS_SUCCESS,
  SAVE_STOCK_TAKE,
  SAVE_STOCK_TAKE_SUCCESS,
  SAVE_STOCK_TAKE_FAIL,
  UPDATE_PARTS_QTY,
  UPDATE_PARTS_QTY_SUCCESS,
  UPDATE_PARTS_QTY_FAIL,
} from "./actionTypes";

export const stockList = () => {
  return {
    type: INVENTORY_STOCK_LIST,
  };
};

export const stockListSuccess = (data) => {
  return {
    type: INVENTORY_STOCK_LIST_SUCCESS,
    payload: data,
  };
};

export const stockListFail = (error) => {
  return {
    type: INVENTORY_STOCK_LIST_FAIL,
    payload: error,
  };
};

export const saveStock = () => {
  return {
    type: SAVE_STOCK,
  };
};

export const saveStockSuccess = (data) => {
  return {
    type: SAVE_STOCK_SUCCESS,
    payload: data,
  };
};

export const saveStockFail = (error) => {
  return {
    type: SAVE_STOCK_FAIL,
    payload: error,
  };
};

export const updatePartData = () => {
  return {
    type: UPDATE_PART_DATA,
  };
};

export const updatePartDataSuccess = (data) => {
  return {
    type: UPDATE_PART_DATA_SUCCESS,
    payload: data,
  };
};

export const updatePartDataFail = (error) => {
  return {
    type: UPDATE_PART_DATA_FAIL,
    payload: error,
  };
};

export const stockTakeList = () => {
  return {
    type: INVENTORY_STOCK_TAKE_LIST,
  };
};

export const stockTakeListSuccess = (data) => {
  return {
    type: INVENTORY_STOCK_TAKE_LIST_SUCCESS,
    payload: data,
  };
};

export const stockTakeHistoryListSuccess = (data) => {
  return {
    type: INVENTORY_STOCK_TAKE_HISTORY_LIST_SUCCESS,
    payload: data,
  };
};

export const stockTakeListFail = (error) => {
  return {
    type: INVENTORY_STOCK_TAKE_LIST_FAIL,
    payload: error,
  };
};

export const editStockTakeDetails = () => {
  return {
    type: EDIT_STOCK_TAKE_DETAILS,
  };
};

export const editStockTakeDetailsSuccess = (data) => {
  return {
    type: EDIT_STOCK_TAKE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const editStockTakeDetailsFail = (error) => {
  return {
    type: EDIT_STOCK_TAKE_DETAILS_FAIL,
    payload: error,
  };
};

export const saveStockTake = () => {
  return {
    type: SAVE_STOCK_TAKE,
  };
};

export const saveStockTakeSuccess = (data) => {
  return {
    type: SAVE_STOCK_TAKE_SUCCESS,
    payload: data,
  };
};

export const saveStockTakeFail = (error) => {
  return {
    type: SAVE_STOCK_TAKE_FAIL,
    payload: error,
  };
};

export const updatePartsQty = () => {
  return {
    type: UPDATE_PARTS_QTY,
  };
};

export const updatePartsQtySuccess = (data) => {
  return {
    type: UPDATE_PARTS_QTY_SUCCESS,
    payload: data,
  };
};

export const updatePartsQtyFail = (error) => {
  return {
    type: UPDATE_PARTS_QTY_FAIL,
    payload: error,
  };
};