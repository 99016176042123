import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import ThirdPartyList from "views/settings/components/ThirdPartyCompany/ThirdPartyList";
import customerBlue from "../../../assets/images/customer-blue.svg";
import CustomerList from "./Customer/CustomerList";
import EmployeesList from "./Employees/EmployeesList";
import SuppliersList from "./suppliers/SuppliersList";

import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const Contacts = () => {
  const [activeTab, setActiveTab] = useState("Customers");

  const location = useLocation();

  useEffect(() => {
    document.title = "Contact List | 5iQ";
    const hash = location.hash;
    setActiveTab(hash.split("#")[1]);
  }, [location.hash]);

  return (
    <div className="right-section-wrapper">
      <div className="table-header">
        <div className="table-header-tabs">
          <Tab.Container
            defaultActiveKey="Customers"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
          >
            <div className="row top-block">
              <div className="col-md-2">
                <div className="table-title">
                  <img src={customerBlue} alt=""></img>
                  <span>CONTACTS</span>
                </div>
              </div>
              <div className="col-md-8">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="Customers">Customers</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ThirdParty">3rd Party</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Suppliers">Suppliers</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Employees">Employees</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-2">
                <div className="video-link-outer">
                  <div className="video-tutorial">
                    <i className="fa fa-icon"></i>
                    {/* <span className="help-text">Help</span> */}
                    <Link to="/trainee-video#contact" ><span className="tutorial-title">Contacts Videos</span></Link>
                  </div>
                </div>
              </div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="Customers">
                <CustomerList />
              </Tab.Pane>
              <Tab.Pane eventKey="ThirdParty">
                <ThirdPartyList />
              </Tab.Pane>
              <Tab.Pane eventKey="Suppliers">
                <SuppliersList />
              </Tab.Pane>
              <Tab.Pane eventKey="Employees">
                <EmployeesList />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
