import HighchartsReact from "highcharts-react-official";
import React, { useMemo, useState } from "react";
import "../../../style.scss";
import "../../style.scss";
import Highcharts from 'highcharts';

const DashboardPieChart = ({type,data,setOnHoverType,setOnHoverTypeColour}) => {

    let pieChartForBookinSource = {
        colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
            return {
              radialGradient: {
                cx: 0.5,
                cy: 0.3,
                r: 0.7
              },
              stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
              ]
            };
        }),
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow:false,
          margin: [0, 0, 0, 0],
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          type: 'pie',
          height:'75%'
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            size:'70%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            //   format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            //   connectorColor: 'silver'
            }
          }
        },
        series: [{
            name: '',
            point: {
                events: {
                    mouseOver: function () {
                        if(type === "BookingSource" || type === "MakeAndModel" || type === "JobByTypes"){
                          var chartName = this.name;
                          var borderColour = this.color.stops[0][1];
                          setOnHoverType(chartName)
                          setOnHoverTypeColour(borderColour)
                        }
                        // alert('Category: ' + this.category + ', value: ' + this.y);
                    },
                    mouseOut: function () {
                      if(type === "BookingSource" || type === "MakeAndModel" || type === "JobByTypes"){
                        setOnHoverType("")
                        setOnHoverTypeColour("")
                      }
                    }
                }
            },
            data: data
        }]
    }

    return (
        <>
            <div className="text-center chart-inner-wrap">
                <HighchartsReact
                    // containerProps={{ style: { height: "400px", width: '400px' } }}
                    highcharts={Highcharts}
                    options={pieChartForBookinSource}
                    allowChartUpdate = {true}
                />
            </div>
        </>
    );
};

export default DashboardPieChart;
