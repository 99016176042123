import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Calendar from './Calendar';
import { Scrollbars } from 'react-custom-scrollbars';
import schedularImage from '../../../assets/images/schedule-blue.svg';
import DetailsTable from './DetailsTable';
import { GET_WORKSHOP_TECHNICIAN } from "../../../store/commonApiStore/actionTypes";
import { useDispatch, useSelector } from "react-redux";
// import { GET_UNASSIGNED_JOBS } from 'store/unassignedJobs/actionTypes';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import CarMakeLogo from 'views/common/UI/CarMakeLogo';
import { utcToTimezone } from 'utils/timeZoneConvert';

const Scheduler = () => {

    const dispatch = useDispatch();
    const localStorageData = JSON.parse(localStorage.getItem('data'));
    const timezoneLable = localStorageData?.workshop?.workshop_other_details[0]?.timezone_label || 'Asia/Kolkata';

    const [UnassignedJobs, setUnassignedJobs] = useState([]);
    const [CarryOverJobs, setCarryOverJobs] = useState([]);
    // const [jobCreated, setJobCreated] = useState(false)
    // const [title, setTitle] = useState("5iQ | Scheduler");
    const [clickDate, setClickDate] = useState(null);
    const [monthView, setMonthView] = useState("day");
    const [displayMonth, setDisplayMonth] = useState(true);
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);

    useEffect(() => {
        setDisplayMonth(false);
    }, [clickDate])

    useEffect(() => {
        setDisplayMonth(true);
    }, [monthView])

    const title = "5iQ | Scheduler";

    useEffect(() => {
        document.title = "5iQ | Scheduler";
    }, [title]);

    useEffect(() => {
        dispatch({
            type: GET_WORKSHOP_TECHNICIAN,
            payload: {
                get: "all",
                conditions: { "Workshops.id": workshop_id },
                contain: {
                    Users: {
                        conditions: {
                            "Users.workshop_id": workshop_id,
                            "Users.role_id IN": [3, 7, 8, 9],
                            "Users.is_deleted": 0
                        },
                        fields: ["id", "workshop_id", "firstname", "lastname"]
                    },
                    "Users.EmployeesDetails": {
                        fields: [
                            "EmployeesDetails.id",
                            "EmployeesDetails.user_id",
                            "EmployeesDetails.shift_working_day",
                            "EmployeesDetails.workshop_id"
                        ]
                    },
                    WorkshopOtherDetails: {
                        fields: [
                            "shift_start_time",
                            "shift_finish_time",
                            "shift_start_day",
                            "shift_finish_day",
                            "id",
                            "workshop_id",
                            "schedule_details",
                            "technician_timeslot"
                        ]
                    }
                },
                fields: ["id"]
            }
        })
    }, [dispatch, workshop_id])

    const { statusColor } = useSelector((state) => state.CommonApiStoreReducer);

    // Get unassigned and carry over job listing
    const UnassignedJobsReducer = useSelector((state) => state.UnassignedJobsReducer);

    const TotalJobs = UnassignedJobsReducer.jobData;
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        let UnassignedJob = TotalJobs && TotalJobs.filter(value => {
            return value.is_copy_job == 0
        });
        let CarryOverJob = TotalJobs && TotalJobs.filter(value => {
            return value.is_copy_job > 0
        });
        setUnassignedJobs(UnassignedJob);
        setCarryOverJobs(CarryOverJob);
    }, [TotalJobs])

    //Draggable Events
    useEffect(() => {
        let draggableEl = document.getElementById("unassigned-list");

        new Draggable(draggableEl, {
            itemSelector: ".job-tiles",
            eventData: function (eventEl) {
                let data = JSON.parse(eventEl.getAttribute("data-value"));

                let id = eventEl.getAttribute("data");
                return {
                    _id: id,
                    title: data?.workshop_departments_accounts_detail?.name,
                    rego: data?.rego,
                    make: data?.vehicle?.make,
                    model: data?.vehicle?.model,
                    year: data?.vehicle?.year,
                    customer_name: data?.customer?.cust_detail?.firstname + " " + data?.customer?.cust_detail?.lastname,
                    customer_mobile: data?.customer?.cust_detail?.mobile_number,
                    data: data
                };
            }
        });

        let draggableElCarryOver = document.getElementById("carry-over-jobs");
        new Draggable(draggableElCarryOver, {
            itemSelector: ".job-tiles",
            eventData: function (eventEl) {
                let data = JSON.parse(eventEl.getAttribute("data-value"));
                let id = eventEl.getAttribute("data");
                return {
                    _id: id,
                    title: data?.workshop_departments_accounts_detail?.name,
                    rego: data?.rego,
                    make: data?.vehicle?.make,
                    model: data?.vehicle?.model,
                    year: data?.vehicle?.year,
                    customer_name: data?.customer?.cust_detail?.firstname + " " + data?.customer?.cust_detail?.lastname,
                    customer_mobile: data?.customer?.cust_detail?.mobile_number,
                    data: data
                };
            }
        });
    }, [])

    const [updateMonthView, setUpdateMonthView] = useState(false);

    return (
        <>
            <Container fluid className="p-r-0">
                <div className="right-section-wrapper schedular-header card m-b-10">
                    <div className="d-flex align-center">
                        <img className="image-block" src={schedularImage} alt=""></img>
                        <h5 className="schedular-title">Scheduler</h5>
                    </div>
                    <div className="video-link-outer">
                        <div className="video-tutorial">
                            <i className="fa fa-icon"></i>
                            {/* <span className="help-text">Help</span> */}
                            <Link to="/trainee-video#schedule" ><span className="tutorial-title">Scheduler Videos</span></Link>
                        </div>
                    </div>
                </div>
                <div className="schedular-outer m-b-10">
                    <div className="right-section-wrapper card">
                        <Row >
                            <Col className="col-block" md={12} lg={12}>
                                <Calendar
                                    // handleUnassignedJobData={handleUnassignedJobData}
                                    // jobCreated={jobCreated}
                                    clickDateMonth={clickDate}
                                    clickDate={setClickDate}
                                    monthView={setMonthView}
                                    key={window.location.pathname}
                                    updateMonthView={updateMonthView}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div id="right-col" className="right-col">
                        <div id="unassigned-list" className="unassigned-list">
                            <div className="block-title d-flex align-center justify-center">
                                <span>Unassigned Job</span>
                                <span className="count d-flex align-center justify-center">{UnassignedJobs ? UnassignedJobs.length : 0}</span>
                            </div>
                            <div className="job-tiles-wrapper">
                                <Scrollbars
                                    className="custom-scrollbar" style={{ width: '100%', height: 240 }}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical black-thumb" />}
                                    renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}>
                                    {UnassignedJobs && UnassignedJobs.length > 0 ? UnassignedJobs.map((value, index) => (
                                        <OverlayTrigger
                                            key="left"
                                            placement="left"
                                            overlay={
                                                <Tooltip id="tooltip-top" className="calender-tooltip" >

                                                    <div
                                                        className="tooltip-header text-center"
                                                        style={{ background: "black" }}
                                                    >
                                                        <span>Drag To Schedule </span>
                                                    </div>
                                                    <div className="tooltip-content">
                                                        {/* <ul key={value.make}>
                                                             <li>{value.rego} |</li>
                                                            <li>{value.make} {value.model} {value.year}</li>
                                                            <li>{value.title} | {value.tags.length ? value.tags[0] : ""}</li> 
                                                            <li>{value.customer_name ? value.customer_name : ""} | {value.customer_mobile ? value.customer_mobile : ""}</li>
                                                        </ul> */}
                                                        <div className="popup-border">
                                                            <p className="popup-border-top" key={value.make} >
                                                                {+value.customer?.cust_detail?.type === 1 ?
                                                                    <>
                                                                        {value.customer?.cust_detail?.company_name ? value.customer?.cust_detail?.company_name : ""} | {value.customer?.cust_detail?.mobile_number ? value.customer?.cust_detail?.mobile_number : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {value.customer?.cust_detail?.firstname ? value.customer?.cust_detail?.firstname : ""} {value.customer?.cust_detail?.lastname ? value.customer?.cust_detail?.lastname : ""} | {value.customer?.cust_detail?.mobile_number ? value.customer?.cust_detail?.mobile_number : ""}
                                                                    </>
                                                                }
                                                            </p>
                                                            {value?.description ?
                                                                <div className="popup-border-bottom"
                                                                    dangerouslySetInnerHTML={{ __html: value?.description }}
                                                                ></div>
                                                                :
                                                                <div className="popup-border-bottom">
                                                                    No description.
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div className="job-tiles" style={{ borderColor: '#ffd726' }} data={value.id} key={index} data-value={JSON.stringify(value)}>
                                                <span className="d-flex align-items-center">
                                                    <CarMakeLogo
                                                        make={value?.vehicle?.make && value?.vehicle?.make || ""}
                                                        size='small'
                                                    />
                                                    <span className="time-block rego-border">{value?.rego || ''}</span>
                                                </span>
                                                <span>
                                                    <span className="time-block">{value?.vehicle?.make || ''} {value?.vehicle?.model || ''} {value?.vehicle?.year || ''}</span><br />
                                                    <span>{value?.job_booking_type?.name || ''}</span>
                                                </span>
                                            </div>

                                        </OverlayTrigger>
                                    )) : ""}
                                </Scrollbars>
                            </div>
                        </div>
                        <div id="carry-over-jobs" className="unassigned-list">
                            <div className="block-title black-bg d-flex align-center justify-center">
                                <span>Carry Over Job</span>
                                <span className="count d-flex align-center justify-center">{CarryOverJobs ? CarryOverJobs.length : 0}</span>
                            </div>
                            <div className="job-tiles-wrapper">
                                <Scrollbars
                                    className="custom-scrollbar" style={{ width: '100%', height: 240 }}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical black-thumb" />}
                                    renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}>
                                    {CarryOverJobs && CarryOverJobs.length > 0 ? CarryOverJobs.map((value, index) => (
                                        <div className="job-tiles" style={{ borderColor: '#ffd726' }} data={value.id} key={index} data-value={JSON.stringify(value)}>
                                            <span className="d-flex align-items-center">
                                                <CarMakeLogo
                                                    make={value?.vehicle?.make && value?.vehicle?.make || ""}
                                                    size='small'
                                                />
                                                <span className="time-block rego-border">{value?.rego || ''}</span>
                                            </span>
                                            <span>
                                                <span className="time-block">{value?.vehicle?.make || ''} {value?.vehicle?.model || ''} {value?.vehicle?.year || ''}</span><br />
                                                <span>{value?.job_booking_type?.name || ''}</span>
                                            </span>
                                        </div>
                                    )) : ""}
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
                {monthView === "month" && displayMonth === false ? <DetailsTable clickDate={clickDate} setUpdateMonthView={setUpdateMonthView} updateMonthView={updateMonthView} /> : ''}
            </Container>
        </>
    )
}

export default Scheduler;