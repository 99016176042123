import {
    CONNECT_QUICKBOOK,
    CONNECT_QUICKBOOK_SUCCESS,
    CONNECT_QUICKBOOK_FAIL,
    DISCONNECT_QUICKBOOK,
    DISCONNECT_QUICKBOOK_SUCCESS,
    DISCONNECT_QUICKBOOK_FAIL,
    DISCONNECT_MYOB,
    DISCONNECT_MYOB_SUCCESS,
    DISCONNECT_MYOB_FAIL,
    UPDATE_WORKSHOP_DETAIL,
    UPDATE_WORKSHOP_DETAIL_SUCCESS,
    UPDATE_WORKSHOP_DETAIL_FAIL,
    GET_SYNC_ACCOUNT_DATA,
    GET_SYNC_ACCOUNT_DATA_SUCCESS,
    GET_SYNC_ACCOUNT_DATA_FAIL,
    GET_SYNC_TAX_DATA,
    GET_SYNC_TAX_DATA_SUCCESS,
    GET_SYNC_TAX_DATA_FAIL,
    UPDATE_SYNC_ACCOUNT_DATA,
    UPDATE_SYNC_ACCOUNT_DATA_SUCCESS,
    UPDATE_SYNC_ACCOUNT_DATA_FAIL,
    UPDATE_SYNC_TAX_DATA,
    UPDATE_SYNC_TAX_DATA_SUCCESS,
    UPDATE_SYNC_TAX_DATA_FAIL,
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    GET_USERS_TECHNICIAN,
    GET_USERS_TECHNICIAN_SUCCESS,
    GET_USERS_TECHNICIAN_FAIL,
    GET_INVOICE,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_FAIL,
    GET_INVOICE_PAYMENTS_CREDIT,
    GET_INVOICE_PAYMENTS_CREDIT_SUCCESS,
    GET_INVOICE_PAYMENTS_CREDIT_FAIL,
    GET_INVOICE_PAYMENTS_DATA,
    GET_INVOICE_PAYMENTS_DATA_SUCCESS,
    GET_INVOICE_PAYMENTS_DATA_FAIL,
    SYNC_TRANSFER,
    SYNC_TRANSFER_SUCCESS,
    SYNC_TRANSFER_FAIL,
    GET_SYNC_DATA,
    GET_SYNC_DATA_SUCCESS,
    GET_SYNC_DATA_FAIL,
    UPDATE_MANY_WORKSHOP_TAX_DETAILS,
    UPDATE_MANY_WORKSHOP_TAX_DETAILS_SUCCESS,
    UPDATE_MANY_WORKSHOP_TAX_DETAILS_FAIL,
    UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS,
    UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_SUCCESS,
    UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_FAIL,
    DELETE_SYNC_ACCOUNTS,
    DELETE_SYNC_ACCOUNTS_SUCCESS,
    DELETE_SYNC_ACCOUNTS_FAIL,
    DELETE_SYNC_TAX,
    DELETE_SYNC_TAX_SUCCESS,
    DELETE_SYNC_TAX_FAIL,
    CONNECT_XERO,
    CONNECT_XERO_SUCCESS,
    CONNECT_XERO_FAIL,
    DISCONNECT_XERO,
    DISCONNECT_XERO_SUCCESS,
    DISCONNECT_XERO_FAIL,
    IMPORT_MYOB,
    IMPORT_MYOB_SUCCESS,
    IMPORT_MYOB_FAIL,
    GET_CASH_SALE_INVOICE,
    GET_CASH_SALE_INVOICE_SUCCESS,
    GET_CASH_SALE_INVOICE_FAIL,
    GET_CASH_SALE_INVOICE_PAYMENT,
    GET_CASH_SALE_INVOICE_PAYMENT_SUCCESS,
    GET_CASH_SALE_INVOICE_PAYMENT_FAIL,
    GET_CASH_SALE_INVOICE_PAYMENT_CREDIT,
    GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_SUCCESS,
    GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_FAIL,
    ADD_EMPLOYEE_CLOCKING_HISTORY,
    ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
    ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL,
    UPDATE_EMPLOYEE_CLOCKING_HISTORY,
    UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
    UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL,
    CRON_SYNC_TRANSFER,
    CRON_SYNC_TRANSFER_SUCCESS,
    CRON_SYNC_TRANSFER_FAIL,
} from "./actionTypes";

const initialState = {
    loadingLogins: false,
    message: "",
    error: "",
    connectQuickbook: null,
    disconnectQuickbook: null,
    disconnectMyob: null,
    updateWorkshopDetails: null,
    syncAccountData: null,
    syncTaxData: null,
    updateSyncAccountData: null,
    updateSyncTaxData: null,
    usersDetails: null,
    technicianDetails: null,
    invoiceData: null,
    invoicePaymentsData: null,
    invoicePaymentsCredit: null,
    syncTransferData: null,
    syncData: null,
    updateWorkshopTax: null,
    updateWorkshopDepartment: null,
    deleteSyncAccount: null,
    deleteSyncTax: null,
    connectXero: null,
    disconnectXero: null,
    importMyob: null,
    syncloadingLogins: false,
    resyncloadingLogins: false,
    cronSyncLoading: false,
    getCashSaleInvoice: null,
    cashSaleInvoiceReceipt: null,
    cashSaleInvoiceCredit: null,
    cronSyncTransferData: null
};

const AccountSyncReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONNECT_QUICKBOOK:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case CONNECT_QUICKBOOK_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                connectQuickbook: action.payload,
            };
            break;
        case CONNECT_QUICKBOOK_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                connectQuickbook: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case DISCONNECT_QUICKBOOK:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case DISCONNECT_QUICKBOOK_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                disconnectQuickbook: action.payload,
            };
            break;
        case DISCONNECT_QUICKBOOK_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                disconnectQuickbook: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case DISCONNECT_MYOB:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case DISCONNECT_MYOB_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                disconnectMyob: action.payload,
            };
            break;
        case DISCONNECT_MYOB_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                disconnectMyob: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case UPDATE_WORKSHOP_DETAIL:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case UPDATE_WORKSHOP_DETAIL_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                updateWorkshopDetails: action.payload,
            };
            break;
        case UPDATE_WORKSHOP_DETAIL_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                updateWorkshopDetails: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_SYNC_ACCOUNT_DATA:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_SYNC_ACCOUNT_DATA_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                syncAccountData: action.payload,
            };
            break;
        case GET_SYNC_ACCOUNT_DATA_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                syncAccountData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_SYNC_TAX_DATA:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_SYNC_TAX_DATA_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                syncTaxData: action.payload,
            };
            break;
        case GET_SYNC_TAX_DATA_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                syncTaxData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case UPDATE_SYNC_ACCOUNT_DATA:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case UPDATE_SYNC_ACCOUNT_DATA_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                updateSyncAccountData: action.payload,
            };
            break;
        case UPDATE_SYNC_ACCOUNT_DATA_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                updateSyncAccountData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case UPDATE_SYNC_TAX_DATA:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case UPDATE_SYNC_TAX_DATA_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                updateSyncTaxData: action.payload,
            };
            break;
        case UPDATE_SYNC_TAX_DATA_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                updateSyncTaxData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_USERS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_USERS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                usersDetails: action.payload,
            };
            break;
        case GET_USERS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                usersDetails: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case GET_USERS_TECHNICIAN:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_USERS_TECHNICIAN_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                technicianDetails: action.payload,
            };
            break;
        case GET_USERS_TECHNICIAN_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                technicianDetails: null,
                error: {
                    message: action.payload,
                },
            };
            break;


        case GET_INVOICE:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_INVOICE_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                invoiceData: action.payload,
            };
            break;
        case GET_INVOICE_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                invoiceData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_INVOICE_PAYMENTS_DATA:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_INVOICE_PAYMENTS_DATA_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                invoicePaymentsData: action.payload,
            };
            break;
        case GET_INVOICE_PAYMENTS_DATA_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                invoicePaymentsData: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case GET_INVOICE_PAYMENTS_CREDIT:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_INVOICE_PAYMENTS_CREDIT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                invoicePaymentsCredit: action.payload,
            };
            break;
        case GET_INVOICE_PAYMENTS_CREDIT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                invoicePaymentsCredit: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case SYNC_TRANSFER:
            state = {
                ...state,
                resyncloadingLogins: true,
            };
            break;
        case SYNC_TRANSFER_SUCCESS:
            state = {
                ...state,
                resyncloadingLogins: false,
                syncTransferData: action.payload,
            };
            break;
        case SYNC_TRANSFER_FAIL:
            state = {
                ...state,
                resyncloadingLogins: false,
                syncTransferData: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case GET_SYNC_DATA:
            state = {
                ...state,
                syncloadingLogins: true,
            };
            break;
        case GET_SYNC_DATA_SUCCESS:
            state = {
                ...state,
                syncloadingLogins: false,
                syncData: action.payload,
            };
            break;
        case GET_SYNC_DATA_FAIL:
            state = {
                ...state,
                syncloadingLogins: false,
                syncData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case UPDATE_MANY_WORKSHOP_TAX_DETAILS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case UPDATE_MANY_WORKSHOP_TAX_DETAILS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                updateWorkshopTax: action.payload,
            };
            break;
        case UPDATE_MANY_WORKSHOP_TAX_DETAILS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                updateWorkshopTax: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                updateWorkshopDepartment: action.payload,
            };
            break;
        case UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                updateWorkshopDepartment: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case DELETE_SYNC_ACCOUNTS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case DELETE_SYNC_ACCOUNTS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                deleteSyncAccount: action.payload,
            };
            break;
        case DELETE_SYNC_ACCOUNTS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                deleteSyncAccount: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case DELETE_SYNC_TAX:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case DELETE_SYNC_TAX_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                deleteSyncTax: action.payload,
            };
            break;
        case DELETE_SYNC_TAX_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                deleteSyncTax: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case CONNECT_XERO:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case CONNECT_XERO_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                connectXero: action.payload,
            };
            break;
        case CONNECT_XERO_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                connectXero: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case DISCONNECT_XERO:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case DISCONNECT_XERO_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                disconnectXero: action.payload,
            };
            break;
        case DISCONNECT_XERO_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                disconnectXero: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case IMPORT_MYOB:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case IMPORT_MYOB_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                importMyob: action.payload,
            };
            break;
        case IMPORT_MYOB_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                importMyob: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_CASH_SALE_INVOICE:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_CASH_SALE_INVOICE_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getCashSaleInvoice: action.payload,
            };
            break;
        case GET_CASH_SALE_INVOICE_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getCashSaleInvoice: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case GET_CASH_SALE_INVOICE_PAYMENT:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_CASH_SALE_INVOICE_PAYMENT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                cashSaleInvoiceReceipt: action.payload,
            };
            break;
        case GET_CASH_SALE_INVOICE_PAYMENT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                cashSaleInvoiceReceipt: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case GET_CASH_SALE_INVOICE_PAYMENT_CREDIT:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                cashSaleInvoiceCredit: action.payload,
            };
            break;
        case GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                cashSaleInvoiceCredit: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_EMPLOYEE_CLOCKING_HISTORY:
            state = {
                ...state,
                // loadingLogins: true,
            };
            break;
        case ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                technicianDetails: action.payload,
            };
            break;
        case ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case UPDATE_EMPLOYEE_CLOCKING_HISTORY:
            state = {
                ...state,
                // loadingLogins: true,
            };
            break;
        case UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS:

            // const histories = [...state.usersDetails];
            // const indexPart = histories.findIndex((c) => c.id === action.payload.id);
            // if (indexPart !== -1) {
            //     histories[indexPart] = {
            //     ...histories[indexPart],
            //     ...action.payload,
            //     };
            // }

            state = {
                ...state,
                loadingLogins: false,
                technicianDetails: action.payload,
            };
            break;
        case UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;

        case CRON_SYNC_TRANSFER:
            state = {
                ...state,
                cronSyncLoading: true,
            };
            break;
        case CRON_SYNC_TRANSFER_SUCCESS:
            state = {
                ...state,
                cronSyncLoading: false,
                cronSyncTransferData: action.payload,
            };
            break;
        case CRON_SYNC_TRANSFER_FAIL:
            state = {
                ...state,
                cronSyncLoading: false,
                cronSyncTransferData: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default AccountSyncReducer;
