import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_JOBDEPARTMENT_LIST,
  GET_JOBTYPE_LIST,
  GET_JOBCANNED_LIST,
  GET_JOBSMSTEMPLATE_LIST,
  GET_JOBEMAILTEMPLATE_LIST,
  SAVE_JOBEMAILTEMPLATE,
  SAVE_JOBSMSTEMPLATE,
  UPDATE_WORKSHOP_OTHER_DETAIL,
  GET_LABOUR_CHARGES_LIST,
  GET_COUNTRY,
  GET_STATE,
  GET_VEHICLES,
  GET_VEHICLEMODEL,
  GET_VEHICLESERIES,
  GET_WORKSHOP_MARKUP_DETAILS,
  GET_WORKSHOP_TAX_DETAILS,
  GET_STATUS_COLOR,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE,
  GET_ASSETS,
  SEARCH_JOBS,
  SEARCH_QUOTE_JOBS,
  APPLY_CODE,
  GET_WORKSHOP_TECHNICIAN,
  GET_SUPPLIER_INTEGRATION,
  GET_EMPLOYEE_ROLES,
  GET_STORAGE_DEVICE,
} from "./actionTypes";
import {
  getJobDepartmentListSuccess,
  getJobDepartmentListFail,
  getTypeListSuccess,
  getJobTypeListFail,
  getJobCannedListSuccess,
  getJobCannedListFail,
  getJobSmsTemplateListSuccess,
  getJobSmsTemplateListFail,
  getJobEmailTemplateListSuccess,
  getJobEmailTemplateListFail,
  saveJobEmailTemplateSuccess,
  saveJobEmailTemplateFail,
  saveJobSmsTemplateSuccess,
  saveJobSmsTemplateFail,
  updateWorkshopOtherDetailSuccess,
  updateWorkshopOtherDetailFail,
  getLabourChargesListSuccess,
  getLabourChargesListFail,
  getCountryFail,
  getCountrySuccess,
  getStateSuccess,
  getStateFail,
  getvehiclesSuccess,
  getvehiclesFail,
  getvehicleModelSuccess,
  getvehicleModelFail,
  getvehicleSeriesSuccess,
  getvehicleSeriesFail,
  getWorkshopMarkupDetailSuccess,
  getWorkshopMarkupDetailFail,
  getWorkshopTaxDetailSuccess,
  getWorkshopTaxDetailFail,
  getStatusColorSuccess,
  getStatusColorFail,
  submitJobEmailTemplateSuccess,
  submitJobEmailTemplateFail,
  getAssetsSuccess,
  getAssetsFail,
  searchJobsSuccess,
  searchJobsFail,
  searchQuoteJobsSuccess,
  searchQuoteJobsFail,
  applyCodeSuccess,
  applyCodeFail,
  getWorkshopTechnicianSuccess,
  getWorkshopTechnicianFail,
  getsupplierIntegrationSuccess,
  getsupplierIntegrationFail,
  getEmployeeRolesSucess,
  getEmployeeRolesFail,
  getStorageDevicesSuccess,
  getStorageDevicesFail,
} from "./actions";

import {
  getJobDepartmentList,
  getJobTypeList,
  getJobCannedList,
  getJobSmsTemplateList,
  getJobEmailTemplateList,
  submitJobEmailTemplate,
  submitJobSmsTemplate,
  updateWorkshopOtherDetail,
  getLabourChargesList,
  getCountry,
  getState,
  getVehicles,
  getVehicleModel,
  getVehicleSeries,
  getWorkshopMarkupDetail,
  getWorkshopTaxDetail,
  getStatusColor,
  submitJobSmsEmailTemplate,
  getAssets,
  jobSchedulesSearch,
  quoteSearch,
  applyCode,
  getWorkshopTechnician,
  getSupplierIntegration,
  getEmployeeRoles,
  getStorageDevices,
} from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

function* onGetJobDepartmentLists(data) {
  try {
    const response = yield call(getJobDepartmentList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getJobDepartmentListSuccess(
            response.data.WorkshopDepartmentsAccountsDetails
          )
        );
      } else {
        yield put(getJobDepartmentListFail(response.message));
      }
    } else {
      yield put(getJobDepartmentListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobDepartmentListFail(error.response));
  }
}

function* onGetJobTypeLists(data) {
  try {
    const response = yield call(getJobTypeList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTypeListSuccess(response.data.JobBookingTypes));
      } else {
        yield put(getJobTypeListFail(response.message));
      }
    } else {
      yield put(getJobTypeListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobTypeListFail(error.response));
  }
}

function* onGetJobCannedLists(data) {
  try {
    const response = yield call(getJobCannedList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getJobCannedListSuccess(response.data.WorkshopScriptsDetails)
        );
      } else {
        yield put(getJobCannedListFail(response.message));
      }
    } else {
      yield put(getJobCannedListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobCannedListFail(error.response));
  }
}

function* onGetJobSmsTemplateLists(data) {
  try {
    const response = yield call(getJobSmsTemplateList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getJobSmsTemplateListSuccess(response.data.CustomNotifications)
        );
      } else {
        yield put(getJobSmsTemplateListFail(response.message));
      }
    } else {
      yield put(getJobSmsTemplateListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobSmsTemplateListFail(error.response));
  }
}

function* onGetJobEmailTemplateLists(data) {
  try {
    const response = yield call(getJobEmailTemplateList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getJobEmailTemplateListSuccess(response.data.CustomNotifications)
        );
      } else {
        yield put(getJobEmailTemplateListFail(response.message));
      }
    } else {
      yield put(getJobEmailTemplateListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobEmailTemplateListFail(error.response));
  }
}

function* onSaveJobEmailTemplate(data) {
  try {
    const response = yield call(submitJobEmailTemplate, data);
    if (response) {
      if (response.code === 200) {
        yield put(saveJobEmailTemplateSuccess(response.data.JobSchedules));
        showSuccess(response.message);
      } else {
        yield put(saveJobEmailTemplateFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(saveJobEmailTemplateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(saveJobEmailTemplateFail(error.response));
  }
}

function* onSaveJobSmsTemplate(data) {
  try {
    const response = yield call(submitJobSmsTemplate, data);
    if (response) {
      if (response.code === 200) {
        yield put(saveJobSmsTemplateSuccess(response.data.JobSchedules));
        showSuccess(response.message);
      } else {
        yield put(saveJobSmsTemplateFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(saveJobSmsTemplateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(saveJobSmsTemplateFail(error.response));
  }
}

function* onGetcountry(data) {
  try {
    const response = yield call(getCountry, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCountrySuccess(response.data.Countries));
      } else {
        yield put(getCountryFail(response.message));
      }
    } else {
      yield put(getCountryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCountryFail(error.response));
  }
}

function* onGetState(data) {
  try {
    const response = yield call(getState, data);
    if (response) {
      if (response.code === 200) {
        yield put(getStateSuccess(response.data.States));
      } else {
        yield put(getStateFail(response.message));
      }
    } else {
      yield put(getStateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getStateFail(error.response));
  }
}

function* onGetVehicles(data) {
  try {
    const response = yield call(getVehicles, data);
    if (response) {
      if (response.code === 200) {
        yield put(getvehiclesSuccess(response.data.AssetsMaster));
      } else {
        yield put(getvehiclesFail(response.message));
      }
    } else {
      yield put(getvehiclesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getvehiclesFail(error.response));
  }
}

function* onGetVehicleModel(data) {
  try {
    const response = yield call(getVehicleModel, data);
    if (response) {
      if (response.code === 200) {
        yield put(getvehicleModelSuccess(response.data.AssetsMaster));
      } else {
        yield put(getvehicleModelFail(response.message));
      }
    } else {
      yield put(getvehicleModelFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getvehicleModelFail(error.response));
  }
}

function* onGetVehicleSeries(data) {
  try {
    const response = yield call(getVehicleSeries, data);
    if (response) {
      if (response.code === 200) {
        yield put(getvehicleSeriesSuccess(response.data.AssetsDetails));
      } else {
        yield put(getvehicleSeriesFail(response.message));
      }
    } else {
      yield put(getvehicleSeriesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getvehicleSeriesFail(error.response));
  }
}

function* onUpdateWorkshopOtherDetail(data) {
  try {
    const response = yield call(updateWorkshopOtherDetail, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateWorkshopOtherDetailSuccess(response));
      } else {
        yield put(updateWorkshopOtherDetailFail(response.message));
      }
    } else {
      yield put(updateWorkshopOtherDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateWorkshopOtherDetailFail(error.response));
  }
}

function* onGetLabourChargeList(data) {
  try {
    const response = yield call(getLabourChargesList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getLabourChargesListSuccess(response.data.LabourCharges));
      } else {
        yield put(getLabourChargesListFail(response.message));
      }
    } else {
      yield put(getLabourChargesListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getLabourChargesListFail(error.response));
  }
}

/** workshop markup detail START */
function* onGetWorkshopMarkupetail(data) {
  try {
    const response = yield call(getWorkshopMarkupDetail, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getWorkshopMarkupDetailSuccess(response.data.WorkshopMarkupDetails)
        );
      } else {
        yield put(getWorkshopMarkupDetailFail(response.message));
      }
    } else {
      yield put(getWorkshopMarkupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWorkshopMarkupDetailFail(error.response));
  }
}
/** workshop markup detail END */

/** workshop markup tax / rates START */
function* onGetWorkshopTaxDetail(data) {
  try {
    const response = yield call(getWorkshopTaxDetail, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getWorkshopTaxDetailSuccess(response.data.WorkshopTaxRatesDetails)
        );
      } else {
        yield put(getWorkshopTaxDetailFail(response.message));
      }
    } else {
      yield put(getWorkshopTaxDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWorkshopTaxDetailFail(error.response));
  }
}
/** workshop markup tax / rates END */

function* onStatusChange(data) {
  try {
    const response = yield call(getStatusColor, data);
    if (response) {
      if (response.code === 200) {
        yield put(getStatusColorSuccess(response.data.Statuses));
      } else {
        yield put(getStatusColorFail(response.message));
      }
    } else {
      yield put(getStatusColorFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getStatusColorFail(error.response));
  }
}

function* onSubmitJobSmsEmailTemplate(data) {
  try {
    const response = yield call(submitJobSmsEmailTemplate, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          submitJobEmailTemplateSuccess({
            code: response.code,
            message: response.message,
          })
        );
      } else {
        yield put(submitJobEmailTemplateFail(response.message));
      }
    } else {
      yield put(submitJobEmailTemplateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(submitJobEmailTemplateFail(error.response));
  }
}

function* onGetAssets(data) {
  try {
    const response = yield call(getAssets, data);
    if (response) {
      if (response.code === 200) {
        yield put(getAssetsSuccess(response.data.Assets));
      } else {
        yield put(getAssetsFail(response.message));
      }
    } else {
      yield put(getAssetsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getAssetsFail(error.response));
  }
}
function* onSearchJobs(data) {
  try {
    const response = yield call(jobSchedulesSearch, data);
    if (response) {
      if (response.code === 200) {
        yield put(searchJobsSuccess(response.data.JobSchedules));
      } else {
        yield put(searchJobsFail(response.message));
      }
    } else {
      yield put(searchJobsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(searchJobsFail(error.response));
  }
}

function* onSearchQuoteJobs(data) {
  try {
    const response = yield call(quoteSearch, data);
    if (response) {
      if (response.code === 200) {
        yield put(searchQuoteJobsSuccess(response.data.CustomerQuotes));
      } else {
        yield put(searchQuoteJobsFail(response.message));
      }
    } else {
      yield put(searchQuoteJobsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(searchQuoteJobsFail(error.response));
  }
}

function* onGetApplyCode(data) {
  try {
    const response = yield call(applyCode, data);
    if (response) {
      if (response.code === 200) {
        yield put(applyCodeSuccess(response.data.JobSchedules));
      } else {
        showError(response.message);
        yield put(applyCodeFail(response.message));
      }
    } else {
      yield put(applyCodeFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(applyCodeFail(error.response));
  }
}

function* onGetWorkshopTechnician(data) {
  try {
    const response = yield call(getWorkshopTechnician, data);
    if (response) {
      if (response.code === 200) {
        yield put(getWorkshopTechnicianSuccess(response.data.Workshops[0]));
      } else {
        showError(response.message);
        yield put(getWorkshopTechnicianFail(response.message));
      }
    } else {
      yield put(getWorkshopTechnicianFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWorkshopTechnicianFail(error.response));
  }
}

function* onGetSupplierIntegration(data) {
  try {
    const response = yield call(getSupplierIntegration, data);
    if (response) {
      if (response.code === 200) {
        yield put(getsupplierIntegrationSuccess(response.data.SupplierIntegration));
      } else {
        // showError(response.message);
        yield put(getsupplierIntegrationFail(response.message));
      }
    } else {
      yield put(getsupplierIntegrationFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getsupplierIntegrationFail(error.response));
  }
}


function* onGetEmployeeRoles(data) {
  try {
    const response = yield call(getEmployeeRoles, data);
    if (response) {
      if (response.code === 200) {
        yield put(getEmployeeRolesSucess(response.data.Roles));
      } else {
        showError(response.message);
        yield put(getEmployeeRolesFail(response.message));
      }
    } else {
      yield put(getEmployeeRolesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getEmployeeRolesFail(error.response));
  }
}

function* onGetStorageDevices(data) {
  try {
    const response = yield call(getStorageDevices, data);
    if (response) {
      if (response.code === 200) {
        yield put(getStorageDevicesSuccess(response.data.StorageDevices));
      } else {
        yield put(getStorageDevicesFail(response.message));
      }
    } else {
      yield put(getStorageDevicesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getStorageDevicesFail(error.response));
  }
}

function* CommonApiStoreSaga() {
  yield takeLatest(GET_JOBDEPARTMENT_LIST, onGetJobDepartmentLists);
  yield takeLatest(GET_JOBTYPE_LIST, onGetJobTypeLists);
  yield takeLatest(GET_JOBCANNED_LIST, onGetJobCannedLists);
  yield takeLatest(GET_JOBSMSTEMPLATE_LIST, onGetJobSmsTemplateLists);
  yield takeLatest(GET_JOBEMAILTEMPLATE_LIST, onGetJobEmailTemplateLists);
  yield takeLatest(SAVE_JOBEMAILTEMPLATE, onSaveJobEmailTemplate);
  yield takeLatest(SAVE_JOBSMSTEMPLATE, onSaveJobSmsTemplate);
  yield takeLatest(UPDATE_WORKSHOP_OTHER_DETAIL, onUpdateWorkshopOtherDetail);
  yield takeLatest(GET_LABOUR_CHARGES_LIST, onGetLabourChargeList);
  yield takeLatest(GET_COUNTRY, onGetcountry);
  yield takeLatest(GET_STATE, onGetState);
  yield takeLatest(GET_VEHICLES, onGetVehicles);
  yield takeLatest(GET_VEHICLEMODEL, onGetVehicleModel);
  yield takeLatest(GET_VEHICLESERIES, onGetVehicleSeries);
  yield takeLatest(GET_WORKSHOP_MARKUP_DETAILS, onGetWorkshopMarkupetail);
  yield takeLatest(GET_WORKSHOP_TAX_DETAILS, onGetWorkshopTaxDetail);
  yield takeLatest(GET_STATUS_COLOR, onStatusChange);
  yield takeLatest(SUBMIT_JOB_SMSEMAIL_TEMPLATE, onSubmitJobSmsEmailTemplate);
  yield takeLatest(GET_ASSETS, onGetAssets);
  yield takeLatest(SEARCH_JOBS, onSearchJobs);
  yield takeLatest(SEARCH_QUOTE_JOBS, onSearchQuoteJobs);
  yield takeLatest(APPLY_CODE, onGetApplyCode);
  yield takeLatest(GET_WORKSHOP_TECHNICIAN, onGetWorkshopTechnician);
  yield takeLatest(GET_SUPPLIER_INTEGRATION, onGetSupplierIntegration);
  yield takeLatest(GET_EMPLOYEE_ROLES, onGetEmployeeRoles);
  yield takeLatest(GET_STORAGE_DEVICE, onGetStorageDevices);
}

export default CommonApiStoreSaga;
