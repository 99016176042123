import { apiCall } from "helpers/apiHelper";
import {
  INVOICE_EOD_CHART,
  INVOICE_EOD_PAYMENT_CHART,
  INVOICE_PAYMENT_CHART,
} from "helpers/urlHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import { groupBy } from "lodash";
import { date_format } from "utils/getCurrency";
import moment from "moment";
import { getInvoicesList } from "helpers/backendHelper";

/** Single call utilities */
async function eodPaymentChart(request) {
  const invoicePaymentChartResponse = await apiCall(
    INVOICE_EOD_PAYMENT_CHART,
    { payload: request },
    "POST",
    true
  );

  const paymentChartData = {
    cash: 0,
    card: 0,
    eft: 0,
    assist: 0,
    cheque: 0,
  };

  const payment_methods = Object.keys(paymentChartData);

  const keys = groupBy(
    invoicePaymentChartResponse?.data?.Invoices,
    "payment_method"
  );

  [1, 2, 3, 4, 5].forEach((c, i) => {
    if (keys[c]) {
      paymentChartData[payment_methods[i]] = keys[c][0].Paid;
    }
  });
  return paymentChartData;
}

function getCustomerType(data) {
  
  let jobData = data?.job_schedule;
  let customerData = data?.type === "sale" 
    ? data?.user?.customers_details?.[0]
    : jobData?.customer?.customers_details?.[0];
  
  let customerName = "";
  if (
    (jobData?.show_allocation_column === "1" && data?.invoice_allocation_type === "2") || 
    (jobData?.show_allocation_column === "0" && data?.insurance_preview_invoice === "1")
  ) {           
    customerName = data?.insurance_company?.company_name;            
  } else if (jobData?.show_allocation_column === "1" && data?.invoice_allocation_type === "3") {
    const invoiceCustomer = jobData?.invoice_c_customer;
    customerName = invoiceCustomer?.type === 2
      ? `${invoiceCustomer?.firstname} ${invoiceCustomer?.lastname}`
      : invoiceCustomer?.company_name;                     
  } else {
    customerName = customerData?.type === 2
      ? `${customerData?.firstname} ${customerData?.lastname}`
      : customerData?.company_name;    
  }
  
  const invoiceTypeMap = {
    1: ' - A',
    2: ' - B',
    3: ' - C'
  };
  
  let invoiceNumber = data?.invoice_number;
  if (data?.customer_preview_invoice === 1) {
    invoiceNumber += invoiceTypeMap[1];
  } else if (data?.insurance_preview_invoice === 1) {
    invoiceNumber += invoiceTypeMap[2];
  } else if (data?.customer_invoice_allocation_preview === 1) {
    invoiceNumber += invoiceTypeMap[3];
  }
  
  return {
    customer: customerName,
    invoice_number: invoiceNumber
  };
  
}

async function eodChartdata(request) {
  const eodChartResponse = await apiCall(
    INVOICE_EOD_CHART,
    { payload: request },
    "POST",
    true
  );
  const eodChartData = {
    invoiceDates: [],
    totalInvoices: [],
    totalPaid: [],
    totalPartsCost: [],
    totalLabourCost: [],
    invoiceAvg: [],
  };
  eodChartResponse?.data?.Invoices?.forEach((c) => {
    eodChartData.invoiceDates.push(moment(c?.InvoiceDate).format(date_format));
    eodChartData.totalInvoices.push(+c?.InvoiceTotal || 0);
    eodChartData.totalPaid.push(+c?.Paid || 0);
    eodChartData.totalPartsCost.push(+c?.PartsCost || 0);
    eodChartData.totalLabourCost.push(+c?.LabourCost || 0);
    eodChartData.invoiceAvg.push(+c?.InvoiceAvg || 0);
  });

  return eodChartData;
}

async function getPaymentChartData(request) {
  const data = await apiCall(
    INVOICE_PAYMENT_CHART,
    { payload: request },
    "POST",
    true
  );
  const obj = {
    invoiceDates: [],
    totalInvoices: [],
    totalPaid: [],
    totalOutstanding: [],
    InvoiceAvg: [],
  };

  data?.data?.Invoices?.forEach((invoice) => {
    obj.invoiceDates.push(moment(invoice.InvoiceDate).format(date_format));
    obj.totalInvoices.push(+invoice?.InvoiceTotal || 0);
    obj.totalPaid.push(+invoice?.Paid || 0);
    obj.InvoiceAvg.push(+invoice?.InvoiceAvg || 0);
  });

  return obj;
}

async function getInvoiceTableList({ start_date, end_date }) {
  const request2 = {
    get: "all",
    conditions: {
      "Invoices.workshop_id": getWorkshopId(),
      "Invoices.date_issued >=": start_date,
      "Invoices.date_issued <=": end_date,
      "Invoices.is_deleted": 0,
      "Invoices.status": 1,
    },
    contain: {
      "JobSchedules.Vehicles.Users.CustomersDetails": {
        conditions: {
          "CustomersDetails.workshop_id": getWorkshopId(),
        },
        fields: [
          "CustomersDetails.user_id",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.type",
          "CustomersDetails.company_name",
        ],
      },
      "JobSchedules.Customers":{
        "fields":["id","email"]
      },
      "JobSchedules.Customers.CustomersDetails":{
        "fields":["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code","customer_app","street_address","suburb","postcode","state","country"]
      },
      "InsuranceCompany":{
        "fields": ["id", "company_name", "email", "phone_number", "postcode", "suburb", "street_address"]
      },
      "JobSchedules.InvoiceCCustomer" : {
        "fields": [ "user_id","workshop_id","firstname","lastname","type","company_name","mobile_number","mobile_code","id"]
      },
      "JobSchedules.InvoiceCCustomer.Users": {
        "fields": [
            "id",              
            "email"
        ]
      },
      InvoicesPaymentsCredits: {
        fields: [
          "InvoicesPaymentsCredits.invoice_id",
          "InvoicesPaymentsCredits.payment_date",
          "InvoicesPaymentsCredits.payment_method",
          "InvoicesPaymentsCredits.amount_due",
          "InvoicesPaymentsCredits.amount_paid",
          "InvoicesPaymentsCredits.balance_remaining",
        ],
      },
      InvoicesPaymentsCreditsOtherDetails: {
        fields: [
          "InvoicesPaymentsCreditsOtherDetails.id",
          "InvoicesPaymentsCreditsOtherDetails.invoice_id",
          "InvoicesPaymentsCreditsOtherDetails.invoice_payment_credit_id",
          "InvoicesPaymentsCreditsOtherDetails.credit_no",
          "InvoicesPaymentsCreditsOtherDetails.amount_credit",
          "InvoicesPaymentsCreditsOtherDetails.amount_paid",
          "InvoicesPaymentsCreditsOtherDetails.payment_method",
        ],
      },
    },
    fields: [
      "Invoices.id",
        "Invoices.invoice_number",
        "Invoices.date_issued",
        "Invoices.total",
        "Invoices.job_id",
        "Invoices.invoice_status",
        "JobSchedules.id",
        "JobSchedules.parts_cost",
        "JobSchedules.sub_total",
        "JobSchedules.labour_cost",
        "JobSchedules.vehicle_id",
        "JobSchedules.profit_margin",
        "Vehicles.id",
        "Vehicles.user_id",
      // "Users.id",
        "JobSchedules.job_number",
        "JobSchedules.show_allocation_column",
        "JobSchedules.inv_c_cust_id",  
        "Invoices.customer_preview_invoice",
        "Invoices.insurance_preview_invoice",
        "Invoices.customer_invoice_allocation_total",
        "Invoices.customer_invoice_allocation_preview",
        "Invoices.customer_invoice_total",
        "Invoices.insurance_invoice_total",
        "Invoices.invoice_allocation_type",
    ],
  };
  const tableResponse = await getInvoicesList({ payload: request2 });

  let creditInvoiceData = [];

  const eodChartTable =
    tableResponse?.data?.Invoices?.map((invoice) => {
      let payment = "";

      if (invoice?.invoices_payments_credits.length) {
        payment = "Cash";
        if (invoice?.invoices_payments_credits[0]?.payment_method == 2) {
          payment = "Credit card";
        }
        if (invoice?.invoices_payments_credits[0]?.payment_method == 3) {
          payment = "EFT";
        }
        if (invoice?.invoices_payments_credits[0]?.payment_method == 4) {
          payment = "Payment assist";
        }
        if (invoice?.invoices_payments_credits[0]?.payment_method == 5) {
          payment = "Cheque";
        }
      }
      let profit = 0;

      if (invoice?.job_schedule?.parts_cost) {
        profit =
          +invoice?.job_schedule?.sub_total -
          +invoice?.job_schedule?.parts_cost;
      }

      if (invoice?.invoices_payments_credits_other_details?.length != 0) {
        invoice?.invoices_payments_credits_other_details?.map((invoiceCredit) => {
          let creditPayment = "Cash";
          if (invoiceCredit?.payment_method == 2) {
            creditPayment = "Credit card";
          }
          if (invoiceCredit?.payment_method == 3) {
            creditPayment = "EFT";
          }
          if (invoiceCredit?.payment_method == 4) {
            creditPayment = "Payment assist";
          }
          if (invoiceCredit?.payment_method == 5) {
            creditPayment = "Cheque";
          }

          creditInvoiceData.push({
            id: Number(invoice?.id + '' + invoiceCredit?.id),
            date: moment(invoice?.date_issued).format(date_format),
            // customer:getCustomer(invoice),
            job_number: invoice?.job_id,
            // invoice_number: invoice?.invoice_number,
            ...getCustomerType(invoice),
            status: "Credit",
            payment_type: creditPayment,
            invoice_total: +invoice?.total + '-credit' || '0-credit',
            cogs: 0,
            labour: 0,
            profit: 0,
            margin: "credit_margin",
            payment_date: invoice?.invoices_payments_credits[0]?.payment_date
              ? moment(invoice?.invoices_payments_credits[0]?.payment_date).format(
                date_format
              )
              : "",
            outstanding:
              +invoice?.invoices_payments_credits[0]?.balance_remaining || 0,
            paid: +invoiceCredit?.amount_credit + '-credit' || '0-credit',
            sdate: new Date(invoice?.date_issued)
          });
        });
      }

      return {
        id: invoice?.id,
        date: moment(invoice?.date_issued).format(date_format),
        // customer:getCustomer(invoice),
        job_number: invoice?.job_id,
        // invoice_number: invoice?.invoice_number,
        ...getCustomerType(invoice),
        status: invoice?.invoice_status == 1 ? "Pending" : "Completed",
        payment_type: payment,
        invoice_total: +invoice?.total,
        cogs: +invoice?.job_schedule?.parts_cost,
        labour: +invoice?.job_schedule?.labour_cost,
        profit: +profit.toFixed(2),
        margin: +invoice?.job_schedule?.profit_margin,
        payment_date: invoice?.invoices_payments_credits[0]?.payment_date
          ? moment(invoice?.invoices_payments_credits[0]?.payment_date).format(
            date_format
          )
          : "",
        outstanding:
          +invoice?.invoices_payments_credits[0]?.balance_remaining || 0,
        paid: +invoice?.invoices_payments_credits[0]?.amount_paid || 0,
        sdate: new Date(invoice?.date_issued)
      };
    }) || [];

  let combinedArray = [...eodChartTable, ...creditInvoiceData];
  combinedArray.sort((a, b) => a.sdate.getTime() - b.sdate.getTime());
  return combinedArray;
}

/** Single call utilities End */

export async function getEodInvoiceData({ start_date, end_date }) {
  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
  };

  const paymentChartData = await eodPaymentChart(request);
  const eodChartData = await eodChartdata(request);
  const eodChartTable = await getInvoiceTableList({ start_date, end_date });
  return {
    paymentChartData,
    eodChartData,
    eodChartTable,
  };
}

export async function getInvoiceValueData({ start_date, end_date }) {
  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
  };
  const chartData = await eodChartdata(request);

  const chartTable = await getInvoiceTableList({ start_date, end_date });

  return { chartData, chartTable };
}

export async function getInvoicePaymentData({ start_date, end_date }) {
  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
  };
  const chartData = await getPaymentChartData(request);
  const chartTable = await getInvoiceTableList({ start_date, end_date });
  return { chartData, chartTable };
}
