import moment from "moment";
import { useState } from "react";
import { Badge } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link, useNavigate } from "react-router-dom";
import CarMakeLogo from "views/common/UI/CarMakeLogo";
import "../inspection.scss";

const InspectionsList = ({ inspectionListData }) => {
  const { SearchBar } = Search;
  const navigate = useNavigate();
  const [currentPage] = useState(1);
  const [sizePerPage] = useState(10);

  const columns = [
    {
      dataField: "job_schedule",
      text: "customer",
      headerAttrs: {
        hidden: true,
      },
      formatter: (cell, row) => {
        return (
          <div class="d-flex align-items-center">
            <div class="profile">
              <i class="fa fa-user"></i>
            </div>
            <div class="content-block">
              {row?.job_schedule?.customer?.customers_details?.[0]?.type === '1' ? (
                <p>
                  {row?.job_schedule?.customer?.customers_details?.[0]?.company_name}
                </p>
              ) : (
                <p>
                  {row?.job_schedule?.customer?.customers_details?.[0]?.firstname}{" "}
                  {row?.job_schedule?.customer?.customers_details?.[0]?.lastname}
                </p>
              )}
              
              <p>
                {row?.job_schedule?.customer?.customers_details?.[0]?.mobile_number} {row?.job_schedule?.customer?.email}
              </p>
            </div>
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.Insp_status === "Pending") {
            navigate(`/job_detail/${row.job_schedule.id}#inspections`);
          } else {
            navigate(`/inspections/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "vehicle.rego",
      text: "vehicle",
      headerAttrs: {
        hidden: true,
      },
      formatter: (cell, row) => {
        return (
          <div class="d-flex align-items-center">
            <CarMakeLogo make={row.vehicle ? row.vehicle?.make : ""} />
            <div class="content-block">
              <span>Rego: {row?.job_schedule?.rego || cell}</span>
              <p>
              {row.vehicle.make} {row.vehicle.model} {row.vehicle.year}
              </p>
            </div>
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.Insp_status === "Pending") {
            navigate(`/job_detail/${row.job_schedule.id}#inspections`);
          } else {
            navigate(`/inspections/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "insp_template.name",
      text: "insp_template",
      headerAttrs: {
        hidden: true,
      },
      formatter: (cell, row) => {
        return <span>{row.insp_template.name}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.Insp_status === "Pending") {
            navigate(`/job_detail/${row.job_schedule.id}#inspections`);
          } else {
            navigate(`/inspections/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "id",
      text: "id",
      headerAttrs: {
        hidden: true,
      },
      formatter: (cell, row) => {
        return (
          <div>
            <span>Inspection # </span>
            <Link className="blue-link" to="#">
              {row.id}
            </Link>
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.Insp_status === "Pending") {
            navigate(`/job_detail/${row.job_schedule.id}#inspections`);
          } else {
            navigate(`/inspections/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "created_on",
      text: "Date",
      headerAttrs: {
        hidden: true,
      },
      formatter: (cell, row) => {
        return <span>{moment(row.created_on).format("DD/MM/YYYY")}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.Insp_status === "Pending") {
            navigate(`/job_detail/${row.job_schedule.id}#inspections`);
          } else {
            navigate(`/inspections/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "Insp_status",
      text: "Status",
      headerAttrs: {
        hidden: true,
      },
      style: { textAlign: "center" },
      formatter: (cell, row) => {
        return (
          <>
            {row.Insp_status === "Pending" && (
              <Badge pill bg="warning" text="dark">
                {row.Insp_status}
              </Badge>
            )}

            {row.Insp_status === "Sent" && (
              <Badge pill bg="primary">
                {row.Insp_status}
              </Badge>
            )}

            {row.Insp_status === "Jobs" && (
              <Badge pill bg="primary">
                {row.Insp_status}
              </Badge>
            )}

            {row.Insp_status === "Approved" && (
              <Badge pill bg="success">
                {row.Insp_status}
              </Badge>
            )}

            {row.Insp_status === "Declined" && (
              <Badge pill bg="danger">
                {row.Insp_status}
              </Badge>
            )}
          </>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.Insp_status === "Pending") {
            navigate(`/job_detail/${row.job_schedule.id}#inspections`);
          } else {
            navigate(`/inspections/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "customer.customers_details[0].firstname",
      text: "firstname",
      hidden: true,
      headerAttrs: {
        hidden: true,
      },
    },
    {
      dataField: "customer.customers_details[0].lastname",
      text: "lastname",
      hidden: true,
      headerAttrs: {
        hidden: true,
      },
    },
    {
      dataField: "customer.phone_number",
      text: "phone_number",
      hidden: true,
      headerAttrs: {
        hidden: true,
      },
    },
    {
      dataField: "vehicle.make",
      text: "make",
      hidden: true,
      headerAttrs: {
        hidden: true,
      },
    },
    {
      dataField: "vehicle.model",
      text: "model",
      hidden: true,
      headerAttrs: {
        hidden: true,
      },
    },
  ];
  
  const [emptyMessage,setEmptyMessage] = useState("No Results Found");

  const emptyDataMessage = () => {
    return (
      <div className= {(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  return (
    <>
      <div className="card mb-3 p-2">
        <ToolkitProvider
          keyField="id"
          data={inspectionListData}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex justify-flex-end card-header align-center">
                <div className="table-search-wrapper ml-3">
                  <SearchBar {...props.searchProps} />
                </div>
              </div>
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: sizePerPage,
                  totalSize: parseInt(inspectionListData.length),
                  nextPageText: "Next",
                  prePageText: "Previous",
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      keyField="id"
                      data={inspectionListData}
                      columns={columns}
                      noDataIndication={emptyDataMessage}
                      {...paginationTableProps}
                      {...props.baseProps}
                    />
                    <div>
                      <div className="table-pagination">
                        <div>
                          <span>Show</span>
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          <span>entries</span>
                        </div>
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default InspectionsList;
