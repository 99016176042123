import {
  saveCashSaleInvoice,
  saveCashSaleInvoiceLabour,
  saveCashSaleInvoicePart,
} from "helpers/backendHelper";

export async function createCashSale({
  saveInvoice,
  savePartsPayload,
  saveLabourPayload,
}) {
  const savedInvoice = await saveCashSaleInvoice({ payload: saveInvoice });
  if (savedInvoice?.code === 200) {

    const savedParts = await Promise.all(
      savePartsPayload.map((payload) => {
        return saveCashSaleInvoicePart({
          payload: {
            ...payload,
            cash_sale_invoice_id: savedInvoice?.data?.CashSaleInvoice.id || "",
          },
        });
      })
    );
    const savedLabour = await Promise.all(
      saveLabourPayload.map((payload) => {
        return saveCashSaleInvoiceLabour({
          payload: {
            ...payload,
            cash_sale_invoice_id: savedInvoice?.data?.CashSaleInvoice.id || "",
          },
        });
      })
    );
    return savedInvoice?.data?.CashSaleInvoice;
  } else {
    throw new Error("Fail to save invoice");
  }
}
