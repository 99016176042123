import React from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Mechanic from "../../../assets/images/mechanic.svg";
import AvatarMini from "../../../assets/images/avatar-mini.jpg";
import CustomerBookingRequest from "../../../assets/images/ic_customer_blue.svg";
import { downloadFileFromS3 } from "../../../utils/uploadFileToS3";
import { addComma } from "utils/numberFunction";

const Resources = ({ resource, view }) => {
    var techPercentage = resource.extendedProps.tech_percent;
    var techAvailablaePercentage = parseFloat(100) - parseFloat(techPercentage);
    // var techProfile = AvatarMini;

    let avatarPath = window.location.origin + "/Avatar/";
    var techProfile = avatarPath + "1.png";

    if (resource.extendedProps.user_profile !== "" && resource.extendedProps.user_profile !== undefined && resource.extendedProps.user_profile !== "bay" && resource.id !== "z") {
        let user_profile_name = resource.extendedProps.user_profile.split('/');
        if (user_profile_name[0] === 'user_profile') {
            techProfile = downloadFileFromS3(resource.extendedProps.user_profile);
        }
        else {
            techProfile = avatarPath + resource.extendedProps.user_profile;
        }
    }

    // if(resource.extendedProps.user_profile !== "" && resource.extendedProps.user_profile !== undefined && resource.extendedProps.user_profile !== "bay" && resource.id !== "z"){
    //     techProfile = downloadFileFromS3(resource.extendedProps.user_profile);
    // }

    // extendedProps is used to access additional event properties.
    const content = (
        <ResourcesDetail>
            <span className="resource-profile">
                {
                    (resource.id === "z") ?
                        <img className="mechanic-img" src={CustomerBookingRequest} alt={resource.title} />
                        : (resource.extendedProps.user_profile !== "bay") ?
                            <img className="technician-img" src={techProfile} alt={resource.title} />
                            : <img className="mechanic-img" src={Mechanic} alt={resource.title} />
                }</span>
            <div className="right-block">
                <p>{resource.title}</p>
                {(resource.id !== "z") ?
                    (resource.extendedProps.user_profile !== "bay") ?
                        <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-bottom" className="resource-tooltip">
                                    Booked :{addComma(techPercentage)}% Available : {addComma(techAvailablaePercentage)}%
                                </Tooltip>
                            }
                        >
                            {view.type === "resourceTimelineWeek" ? <span></span> : <ProgressBar now={techPercentage} placement="right" />}
                        </OverlayTrigger>
                        :
                        view.type === "resourceTimelineWeek" ? "" : <span className="booked-hours">{resource.extendedProps.available_hrs} Hrs</span>
                    : ""}
            </div>
        </ResourcesDetail>
    );
    return content;
};

export default Resources;

// This is only a wrapper so the component reads nicer in React Debugger. It is completely unnecessary.
const ResourcesDetail = ({ ...props }) => (
    <div className="resource-outer">{props.children}</div>
);
