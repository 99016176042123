export const SAVE_JOB_PART = "SAVE_JOB_PART";
export const SAVE_JOB_PART_SUCCESS = "SAVE_JOB_PART_SUCCESS";
export const SAVE_JOB_PART_FAIL = "SAVE_JOB_PART_FAIL";

export const GET_JOB_PART_LIST = "GET_JOB_PART_LIST";
export const GET_JOB_PART_LIST_SUCCESS = "GET_JOB_PART_LIST_SUCCESS";
export const GET_JOB_PART_LIST_FAIL = "GET_JOB_PART_LIST_FAIL";

export const DELETE_JOB_PART = "DELETE_JOB_PART";
export const DELETE_JOB_PART_SUCCESS = "DELETE_JOB_PART_SUCCESS";
export const DELETE_JOB_PART_FAIL = "DELETE_JOB_PART_FAIL";

export const UPDATE_JOB_PART = "UPDATE_JOB_PART";
export const UPDATE_JOB_PART_SUCCESS = "UPDATE_JOB_PART_SUCCESS";
export const UPDATE_JOB_PART_FAIL = "UPDATE_JOB_PART_FAIL";

export const INLINE_UPDATE_JOB_PART = "INLINE_UPDATE_JOB_PART";
export const INLINE_UPDATE_JOB_PART_SUCCESS = "INLINE_UPDATE_JOB_PART_SUCCESS";
export const INLINE_UPDATE_JOB_PART_FAIL = "INLINE_UPDATE_JOB_PART_FAIL";

export const EDIT_JOB_PART_MANY = "EDIT_JOB_PART_MANY";
export const EDIT_JOB_PART_MANY_SUCCESS = "EDIT_JOB_PART_MANY_SUCCESS";
export const EDIT_JOB_PART_MANY_FAIL = "EDIT_JOB_PART_MANY_FAIL";

export const GET_JOB_KIT_PART = "GET_JOB_KIT_PART";
export const GET_JOB_KIT_PART_SUCCESS = "GET_JOB_KIT_PART_SUCCESS";
export const GET_JOB_KIT_PART_FAIL = "GET_JOB_KIT_PART_FAIL";

export const GET_JOB_KIT_LABOUR = "GET_JOB_KIT_LABOUR";
export const GET_JOB_KIT_LABOUR_SUCCESS = "GET_JOB_KIT_LABOUR_SUCCESS";
export const GET_JOB_KIT_LABOUR_FAIL = "GET_JOB_KIT_LABOUR_FAIL";

export const SAVE_JOB_KIT_PART = "SAVE_JOB_KIT_PART";
export const SAVE_JOB_KIT_PART_SUCCESS = "SAVE_JOB_KIT_PART_SUCCESS";
export const SAVE_JOB_KIT_PART_FAIL = "SAVE_JOB_KIT_PART_FAIL";

export const DELETE_JOB_KIT_PART = "DELETE_JOB_KIT_PART";
export const DELETE_JOB_KIT_PART_SUCCESS = "DELETE_JOB_KIT_PART_SUCCESS";
export const DELETE_JOB_KIT_PART_FAIL = "DELETE_JOB_KIT_PART_FAIL";

export const INLINE_UPDATE_JOB_KIT_PART = "INLINE_UPDATE_JOB_KIT_PART";
export const INLINE_UPDATE_JOB_KIT_PART_SUCCESS = "INLINE_UPDATE_JOB_KIT_PART_SUCCESS";
export const INLINE_UPDATE_JOB_KIT_PART_FAIL = "INLINE_UPDATE_JOB_KIT_PART_FAIL";

export const EDIT_JOB_KIT_PART_MANY = "EDIT_JOB_KIT_PART_MANY";
export const EDIT_JOB_KIT_PART_MANY_SUCCESS = "EDIT_JOB_KIT_PART_MANY_SUCCESS";
export const EDIT_JOB_KIT_PART_MANY_FAIL = "EDIT_JOB_KIT_PART_MANY_FAIL";

export const EDIT_JOB_KIT_LABOUR_MANY = "EDIT_JOB_KIT_LABOUR_MANY";
export const EDIT_JOB_KIT_LABOUR_MANY_SUCCESS = "EDIT_JOB_KIT_LABOUR_MANY_SUCCESS";
export const EDIT_JOB_KIT_LABOUR_MANY_FAIL = "EDIT_JOB_KIT_LABOUR_MANY_FAIL";

export const INLINE_UPDATE_JOB_KIT_LABOUR = "INLINE_UPDATE_JOB_KIT_LABOUR";
export const INLINE_UPDATE_JOB_KIT_LABOUR_SUCCESS = "INLINE_UPDATE_JOB_KIT_LABOUR_SUCCESS";
export const INLINE_UPDATE_JOB_KIT_LABOUR_FAIL = "INLINE_UPDATE_JOB_KIT_LABOUR_FAIL";

export const GET_PO_PART_LIST = "GET_PO_PART_LIST";
export const GET_PO_PART_LIST_SUCCESS = "GET_PO_PART_LIST_SUCCESS";
export const GET_PO_PART_LIST_FAIL = "GET_PO_PART_LIST_FAIL";

export const GET_BURSON_PARTS = "GET_BURSON_PARTS";
export const GET_BURSON_PARTS_SUCCESS = "GET_BURSON_PARTS_SUCCESS";
export const GET_BURSON_PARTS_FAIL = "GET_BURSON_PARTS_FAIL";

export const CREATE_BURSON_PO = "CREATE_BURSON_PO";
export const CREATE_BURSON_PO_SUCCESS = "CREATE_BURSON_PO_SUCCESS";
export const CREATE_BURSON_PO_FAIL = "CREATE_BURSON_PO_FAIL";

export const GET_REPCO_PARTS = "GET_REPCO_PARTS";
export const GET_REPCO_PARTS_SUCCESS = "GET_REPCO_PARTS_SUCCESS";
export const GET_REPCO_PARTS_FAIL = "GET_REPCO_PARTS_FAIL";