const warn = () => console.log("haven't resgiter the loader yet");
let loader = {
  show: warn,
  hide: warn,
};

/**
 * this method is to save the referece of the singleton component
 */
export const registerLoader = (ref) => {
  if (ref) {
    loader.show = ref.show;
    loader.hide = ref.hide;
  } else {
    loader.show = warn;
    loader.hide = warn;
  }
};

export function showLoader() {
  loader.show();
}
export function hideLoader() {
  loader.hide();
}

export default loader;
