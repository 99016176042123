import moment from "moment";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Accordion from "react-bootstrap/Accordion";
import { secondToHours } from "utils/timeZoneConvert";
import { Vimeo } from "vimeo";
import customerBlue from "../../../assets/images/customer-blue.svg";
import videoIcon from "../../../assets/images/video-icon.png";
import "../trainee-video.scss";

const AllSectionVideos = ({
    handleVimeoPlayerShow, 
    listView, 
    searchKey, 
    vimoeId, 
    title, 
    pageId, 
    icon, 
    defaultActiveKey, 
    eventKey, 
    icon_class = ""
  }) => {

  const [moreContent, setMoreContent] = useState(false);
  const [allVideos, setAllVideos] = useState([]);
  const [fetchVideos, setFetchVideos] = useState([]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [isResponse, setIsResponse] = useState(false);
  const [searchVideo, setSearchVideo] = useState(false);

  // var Vimeo = require('vimeo').Vimeo;
  var client = new Vimeo('7d54b594d361e0bb6f79d6965c57f9ce88ed2091', 'itpqyycN6q/az8d2pVenDALbio+FI+GJlmVvNkQpv0YkSMsLjq6F89tVh3kVUHHOEcu84yIumcPE28m/QGCMLerRfDh+hHPGjIKq1bEhJ6iMNBSXM0otfLm+px7yg+fw', '1e776a175f4b4d993cefe70919e1fa5f');

  //fetch session storage data
  const storedData = JSON.parse(sessionStorage.getItem('stored_data'));

  //workshop id
  const dateFormat = storedData.date_format;
   
  useEffect(() => {
    getFetchVideos();
  },[])

  function getFetchVideos(){
    client.request(/*options*/{
      path: '/me/projects/'+vimoeId+'/videos',
    }, /*callback*/function (error, body, status_code, headers) {
      if (error) {
        setTotalVideos(0);
        setIsResponse(true);
      } else {
        setIsResponse(true);
        setAllVideos(body['data']);
        setFetchVideos(body['data']);
        setTotalVideos(body['total']);
      }
    });
  }


  // Search Video
  useEffect(() => {
    if(searchKey !== "" && allVideos){
      const fetchVideos = allVideos.filter(item => item.name.toLowerCase().includes(searchKey.toLowerCase()));
      if(fetchVideos.length > 0) {
        setAllVideos(fetchVideos);
        setTotalVideos(fetchVideos.length);
      } else {
        setTotalVideos(0);
      }
      setSearchVideo(true);
    }

    if(searchKey === "" && searchVideo === true && isResponse === true){
      setAllVideos(fetchVideos);
      setTotalVideos(fetchVideos.length);
    }
  },[searchKey])


  // Table Data
  const columns = [
    {
      dataField: "Play",
      text: "Play",
      sort: true,
      formatter: (cell, row) => {
        return (
          <Button className="video-btn">
            <img src={videoIcon} alt="video" ></img>
          </Button>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleVimeoPlayerShow(row['link'],row['name'],title)
        },
      },
    },
    {
      dataField: "Video Image",
      text: "Video",
      sort: true,
      style: { width: "10%" },
      formatter: (cell, row) => {
        return (
          <div className="preview-img">
            <img src={row['pictures']['sizes'][5]['link']} alt="video link" ></img>
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleVimeoPlayerShow(row['link'],row['name'],title)
        },
      },
    },
    {
      dataField: "name",
      text: "Title",
      style: { width: "60%" },
      sort: true,
      formatter: (cell, row) => {
        return (
          <span>{cell}</span>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleVimeoPlayerShow(row['link'],row['name'],title)
        },
      },
    },
    {
      dataField: "duration",
      text: "Duration",
      sort: true,
      formatter: (cell, row) => {
        return <span>{secondToHours(cell)}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleVimeoPlayerShow(row['link'],row['name'],title)
        },
      },
    },
    {
      dataField: "Views",
      text: "Views",
      sort: true,
      formatter: (cell, row) => {
        return <span>{row['stats']['plays']}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleVimeoPlayerShow(row['link'],row['name'],title)
        },
      },
    },
  ];

  if(searchKey !== "" && typeof searchKey !== "undefined" && allVideos){
    return (
      <>
        {totalVideos > 0 &&
          <Accordion defaultActiveKey={defaultActiveKey} className="small-accordion" id={pageId}>
            <Accordion.Item eventKey={eventKey} className="panel-wrapper">
              <Accordion.Header>
                <div className="panel-heading">
                  <div className="panel-title d-flex no-wrap align-center">

                    {icon_class ? (
                      <>
                        <i className={icon_class + " m-r-10"}></i>
                        
                      </>
                    ) : (
                      <>
                        <img className="m-r-10" src={icon} alt={pageId}></img>
                      </>
                    )}

                    <span className="m-r-10">{title}</span>
                    <span className="count">{totalVideos || 0}</span>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>

              {totalVideos === 0 && isResponse === false &&

                <div className="panel-body">
                  <h5>Loading ....</h5>
                </div>
              }

              {totalVideos === 0 && isResponse === true && 
                <div className="panel-body">
                  <h5>No Video Found.</h5>
                </div>
              }

                {totalVideos > 0 && isResponse === true &&
                <>

                  <div className="panel-body">
                  {listView === false ? (
                    <div className="row video-list-wrapper">

                      {allVideos &&

                      allVideos.map(function (vidoes, index) {

                        
                          return (

                            <div className="col-sm-3" key={index}>
                              <div className="video-outer mb-3">
                                <div className="video-block">
                                  <img src={vidoes['pictures']['sizes'][5]['link']} alt="vidoes" ></img>
                                  <div className="btn-wrapper d-flex align-center">
                                    <Button className="video-btn mr-2" 
                                    onClick={(e) => handleVimeoPlayerShow(vidoes['link'],vidoes['name'],title)} >
                                      <i className="fa fa-play-circle"></i>
                                    </Button>
                                    <a href={vidoes['player_embed_url']} target="_blank" className="video-btn" rel="noreferrer">
                                      <i className="fa fa-external-link-square"></i>
                                    </a>
                                  </div>
                                </div>
                                <div className="video-content-wrapper">
                                  <h3>{vidoes['name']}</h3>
                                  <ul className="grid-view-info d-flex align-center">
                                    <li>
                                      <span>{moment(vidoes['created_time']).format(dateFormat)}</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-eye"></i>
                                      <span>{vidoes['stats']['plays']}</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-clock-o"></i>
                                      <span>{secondToHours(vidoes['duration'])}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                          );
                        })

                      }

                      

                    </div>
                    ) : (
                      <div className="draggable-table video-list-wrapper table-view">
                        <BootstrapTable
                          bootstrap4
                          keyField="id"
                          data={allVideos}
                          columns={columns}
                        />
                      </div>
                    )}
                  </div>
                  
                  {totalVideos > 4 && 

                    <Accordion.Header className="secondary-accordion-header" onClick={(e) => setMoreContent(!moreContent)}  >
                      {moreContent === true ? "View Less" : "View More"}
                    </Accordion.Header>
                  }

                </>

                }

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
      </>
    );
  } else {
    return (
      <>
        {/* {totalVideos > 0 && */}
          <Accordion defaultActiveKey={defaultActiveKey} className="small-accordion" id={pageId}>
            <Accordion.Item eventKey={eventKey} className="panel-wrapper">
              <Accordion.Header>
                <div className="panel-heading">
                  <div className="panel-title d-flex no-wrap align-center">

                    {icon_class ? (
                      <>
                        <i className={icon_class + " m-r-10"}></i>
                        
                      </>
                    ) : (
                      <>
                        <img className="m-r-10" src={icon} alt={pageId}></img>
                      </>
                    )}

                    <span className="m-r-10">{title}</span>
                    <span className="count">{totalVideos || 0}</span>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>

              {totalVideos === 0 && isResponse === false &&

                <div className="panel-body">
                  <h5>Loading ....</h5>
                </div>
              }

              {totalVideos === 0 && isResponse === true && 
                <div className="panel-body">
                  <h5>No Video Found.</h5>
                </div>
              }

                {totalVideos > 0 && isResponse === true &&
                <>

                  <div className="panel-body">
                  {listView === false ? (
                    <div className="row video-list-wrapper">

                      {allVideos &&

                      allVideos.map(function (vidoes, index) {

                        
                          return (

                            <div className="col-sm-3" key={index}>
                              <div className="video-outer mb-3">
                                <div className="video-block">
                                  <img src={vidoes['pictures']['sizes'][5]['link']} alt="vidoes" ></img>
                                  <div className="btn-wrapper d-flex align-center">
                                    <Button className="video-btn mr-2" 
                                    onClick={(e) => handleVimeoPlayerShow(vidoes['link'],vidoes['name'],title)} >
                                      <i className="fa fa-play-circle"></i>
                                    </Button>
                                    <a href={vidoes['player_embed_url']} target="_blank" className="video-btn" rel="noreferrer">
                                      <i className="fa fa-external-link-square"></i>
                                    </a>
                                  </div>
                                </div>
                                <div className="video-content-wrapper">
                                  <h3>{vidoes['name']}</h3>
                                  <ul className="grid-view-info d-flex align-center">
                                    <li>
                                      <span>{moment(vidoes['created_time']).format(dateFormat)}</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-eye"></i>
                                      <span>{vidoes['stats']['plays']}</span>
                                    </li>
                                    <li>
                                      <i className="fa fa-clock-o"></i>
                                      <span>{secondToHours(vidoes['duration'])}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                          );
                        })

                      }

                      

                    </div>
                    ) : (
                      <div className="draggable-table video-list-wrapper table-view">
                        <BootstrapTable
                          bootstrap4
                          keyField="id"
                          data={allVideos}
                          columns={columns}
                        />
                      </div>
                    )}
                  </div>
                  
                  {totalVideos > 4 && 

                    <Accordion.Header className="secondary-accordion-header" onClick={(e) => setMoreContent(!moreContent)}  >
                      {moreContent === true ? "View Less" : "View More"}
                    </Accordion.Header>
                  }

                </>

                }

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        {/* } */}
      </>
    );
  }
};

export default AllSectionVideos;
