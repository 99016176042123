import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import { showSuccess } from "../../../utils/alertMessage";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  GET_REGISTER, EXIST_EMAIL, GET_COUNTRY, GET_STATE, GET_ACCOUNTING_PACKAGE,
  GET_SIZE_OF_WORKSHOP, GET_REFERRAL_ADVERTISE
} from "../../../store/register/actionTypes";
import Select from "react-select";
import crypto from 'crypto';
import axios from 'axios';
import capricorn from "assets/images/capricorn.jpg";

const SecondStep = (props) => {
  const NzCountryId = process.env.REACT_APP_REGO_NZ_ID;
  const AusCountryId = process.env.REACT_APP_REGO_AUS_ID;
  const { setStepSecond, setStep, formData } = props; // Get values from Register component 
  const dispatch = useDispatch(); // Dispatch variable
  const userData = useSelector((state) => state.RegisterReducer); // Get Regiser Reducer data
  const [ip, setIP] = useState('');//Set state for user IP Address
  const navigate = useNavigate(); // Redirect to other URL

  //creating function to load ip address from the API
  const getUserIdAddress = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }

  useEffect(() => {
    document.title = "Register | 5iQ";
  }, []);

  //For redirect to 
  useEffect(() => {
    if (userData?.userData?.WorkshopData) {
      showSuccess('We sent you an email. Please validate to Login to your account.');
      navigate("/login#register");
    }
  }, [userData.userData])

  //Set state of country,state,size,Account and Media
  const [state, setStateVal] = useState();
  const [country, setCountry] = useState();
  const [size, setSize] = useState(4);
  const [account, setAccount] = useState();
  const [isCapricorn, setIsCapricorn] = useState(false);
  // const [media,setMedia] = useState();


  let countryData = [];
  let stateData = [];



  //Form validation using Yup
  const validationSchema = yup.object({
    bussiness_name: yup.string().required("Please enter Bussiness Name"),
    // capricorn_number: yup.string().required("Please enter Capricorn Number"),
    // website: yup
    //   .string()
    //   .matches(/^[a-z]*$/, "Please enter a valid URL(Letters only Please)!")
    //   .required("Please enter a Login URL ")
    //   .test({
    //     message: () => "This website is already registered!",
    //     test: async (values) => {
    //       if (values) {
    //         dispatch({
    //           type: EXIST_EMAIL,
    //           payload: {
    //             get: "all",
    //             conditions: {
    //               url: values + ".fiiviq.com",
    //             },
    //           },
    //         });
    //         return userData.existEmail;
    //       }
    //     },
    //   }),
    country: yup.string()
      .ensure()
      .required("Please select a Country")
  });

  //Set initial values and validation schema on formik
  const formik = useFormik({
    initialValues: {
      bussiness_name: "",
      website: "",
      country: 9,
      capricorn_number: "",
    },
    validationSchema,
  });

  // Use Effect for get country,Accounting Package,Size of Workshop and Referral Advertize
  useEffect(() => {
    // return () => {
    dispatch({
      type: GET_COUNTRY,
      payload: {
        "get": "all",
        "conditions": {
          "status": 1
        },
        "fields": [
          "id",
          "country_name"
        ]
      },
    });

    // dispatch({
    //   type: GET_INDUSTRY,
    //   payload: {
    //     "get": "all",
    //     "order": {
    //       "priority": "asc"
    //     }
    //   }
    // });

    dispatch({
      type: GET_ACCOUNTING_PACKAGE,
      payload: {
        "get": "all",
        "order": {
          "priority": "asc"
        }
      }
    });

    dispatch({
      type: GET_SIZE_OF_WORKSHOP,
      payload: {
        "get": "all",
        "order": {
          "priority": "asc"
        }
      }
    });

    dispatch({
      type: GET_REFERRAL_ADVERTISE,
      payload: {
        "get": "all",
        "order": {
          "priority": "asc"
        }
      }
    });

    handleState(formik.values.country);
    // }
  }, []);

  // Create a list for display country data
  if (userData.countries != null) {
    const country = userData.countries;
    country.map((countryName) => (
      countryData.push({
        value: countryName.id,
        label: countryName.country_name
      })
    ))

  }

  // Go back to first step
  const goBack = () => {
    setStepSecond(true);
    setStep(true);
  };

  // Submit registration form on finish button
  function handleSubmitForm() {
    formik.handleSubmit();
    const form = document.getElementById("submit_form");
    if (form.checkValidity() !== false) {
      props.setLoader(true);
      getUserIdAddress();
      var workshopToken = crypto.randomBytes(5).toString('hex');
      var randomhour = Math.floor(Math.random() * (20 - 0 + 1)) + 0;

      dispatch({
        type: GET_REGISTER,
        payload: {
          username: (formData.firstname).toLowerCase() + " " + (formData.lastname).toLowerCase(),
          randomhour: randomhour,
          firstname: formData.firstname,
          lastname: formData.lastname,
          email: formData.email,
          password: formData.password,
          phone_number: formData.mobile_no,
          access_level: "0",
          desktop_app: "1",
          mobile_app: "0",
          suburb: "",
          postcode: "",
          city: "",
          state: state,
          country: country,
          street_address: "",
          workshop_id: "",
          role_id: 2,
          user_ip_address: ip,
          WorkshopData: {
            logo: "",
            email: formData.email,
            name: formik.values.bussiness_name,
            contact_name: formData.firstname + " " + formData.lastname,
            suburb: "",
            postcode: "",
            city: "",
            state: (state !== '') ? state : 1,
            country: country,
            phone: formData.mobile_no,
            number_of_staff: (size !== '') ? size : 6,
            industry_size_id: (size !== '') ? size : 6,
            accounting_package_id: (account !== '') ? account : 3,
            package_type: (account !== '') ? account : 3,
            // industries_id: "",
            url: formik.values.bussiness_name.split(' ').join('_') + ".fiiviq.com",
            version: '1.1',
            workshopToken: workshopToken,
            capricorn_number: formik.values.capricorn_number,
            payment_type: isCapricorn ? "1" : "0"
          },
          WorkshopAccountingData: {
            // industries_id: "",
            number_of_staff: (size !== '') ? size : 6,
            accounting_package_id: (account !== '') ? account : 3
          }
        },
      });
    }
  }

  //Dispatch function for get state based on selected country
  function handleState(countryId) {
    formik.values.country = countryId;
    // var countryId = e.value;
    setCountry(countryId);
    dispatch({
      type: GET_STATE,
      payload: {
        "get": "all",
        "conditions": {
          "is_deleted": 0,
          "country_id": countryId
        },
        "order": {
          "name": "asc"
        },
        "fields": [
          "id",
          "name"
        ]
      },
    });
  }

  // Create a list for display state data
  if (userData.states != null) {
    if (userData.success.message !== '') {
      const state = userData.states;
      state.map((stateName) => (
        stateData.push({
          value: stateName.id,
          label: stateName.name
        })
      ))
    }
  }

  return (
    <>
      <div className="register-form-wrapper pa-4 step-2">
        {/* <Form className="form-outer"> */}
        <h1 className="mb-2">Registration Step-2</h1>
        <Button
          type="button"
          variant="primary"
          className="primary-btn mb-2 go-back register-btn"
          onClick={goBack}
        >
          Go back
        </Button>

        <Form.Group className="form-group mb-3">
          <Form.Control
            placeholder="Business Name"
            className={
              formik.touched.bussiness_name && formik.errors.bussiness_name
                ? "is-invalid"
                : null
            }
            type="text"
            name="bussiness_name"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.bussiness_name}
          />
          {/* <Form.Control.Feedback type="invalid">This field is Required</Form.Control.Feedback> */}
          {formik.touched.bussiness_name && formik.errors.bussiness_name ? (
            <div className="invalidFeedback">
              {formik.errors.bussiness_name}
            </div>
          ) : null}
        </Form.Group>
        {/* <Form.Group className="form-group mb-3">
          <div className="url-wrapper">
            <Form.Control
              placeholder="Login Url"
              type="text"
              className={
                formik.touched.website && formik.errors.website
                  ? "is-invalid"
                  : null
              }
              required
              name="website"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.website}
            />
            <span className="post-text">.fiiviq.com</span>
          </div>
          {formik.touched.website && formik.errors.website ? (
            <div className="invalidFeedback">{formik.errors.website}</div>
          ) : null}
        </Form.Group> */}
        <div className="select-wrapper">
          <Form.Group className="form-group mb-3">
            {/* <Form.Label>Country</Form.Label> */}
            <Select placeholder="Select Country" className="form-control custom-select" required name="country" id="country" options={countryData} onChange={(e) => { handleState(e.value) }}
              value={countryData.filter(function (option) {
                return option.value === formik.values.country;
              })}
            />
            {formik.touched.country && formik.errors.country ? (<div className="invalidFeedback">{formik.errors.country}</div>) : null}
          </Form.Group>
          <Form.Group className="form-group mb-3">
            {/* <Form.Label>State</Form.Label> */}
            <Select placeholder={formik.values.country == NzCountryId ? "Select Region" : "Select State"} className="form-control custom-select" name="state" id="state" options={stateData} onChange={(e) => setStateVal(e.value)} />
          </Form.Group>
        </div>
        <div className="radio-group-wrapper">
          <div className="title-wrapper">Size of workshop</div>
          <div className="check-wrapper">
            {
              userData.industrySizes != null ?
                userData.industrySizes.map(size => {
                  return (
                    <Form.Check
                      className="custom-check radio-btn mb-2"
                      type="radio"
                      id={`size-${size.id}`} key={`size-${size.id}`}
                    >
                      <Form.Check.Input name="size" type="radio" id={`size-${size.id}`} isValid value={size.id} onChange={(e) => setSize(e.target.value)} />
                      <Form.Check.Label>
                        {size.name}
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  )
                })
                : ''
            }
          </div>
        </div>

        <div className="radio-group-wrapper">
          <div className="title-wrapper">Choose your accounting package</div>
          <div className="check-wrapper width-50">
            {
              userData.accountingPackage != null ?
                userData.accountingPackage.map(account => {
                  return (
                    <Form.Check
                      className="custom-check radio-btn mb-2"
                      type="radio"
                      name="account"
                      id={`account-${account.id}`} key={`account-${account.id}`}
                    >
                      <Form.Check.Input name="accounting" type="radio" value={account.id} isValid onChange={(e) => setAccount(e.target.value)} />
                      <Form.Check.Label>
                        {account.name}
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  )
                })
                : ''
            }
          </div>
        </div>


        {formik.values.country === +AusCountryId &&
          <div className="radio-group-wrapper">
            <div className="title-wrapper">Capricorn Member</div>
            <div className="check-wrapper" style={{ alignItems: "center" }}>

              <Form.Check
                className="custom-check radio-btn mb-2 width-33"
                type="radio"
                name="account"
                id={`is_capricorn-1`} key={`is_capricorn-1`}
              >
                <Form.Check.Input name="is_capricorn" type="checkbox" value={1} isValid onChange={(e) => {
                  console.log(e.target.checked)
                  setIsCapricorn(e.target.checked)
                }
                } />
                <Form.Check.Label>
                  Select
                  <span className="check"></span>
                </Form.Check.Label>
              </Form.Check>

              <Form.Group className="form-group width-33 mb-3">
                <Form.Control
                  placeholder="Capricorn Number"
                  className=""
                  type="text"
                  name="capricorn_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.capricorn_number}
                />


              </Form.Group>


              <img className="logo mb-3" style={{ height: "50px" }} src={capricorn} alt=""></img>

            </div>
          </div>
        }



        <div className="radio-group-wrapper">
          <div className="title-wrapper">How did you hear about us?</div>
          <div className="check-wrapper">
            {
              userData.advertisements != null ?
                userData.advertisements.map(advertise => {
                  return (
                    <Form.Check
                      className="custom-check radio-btn mb-2"
                      type="radio"
                      id={`advertise-${advertise.id}`}
                      key={`advertise-${advertise.id}`}
                    >
                      <Form.Check.Input name="media" type="radio" value={advertise.id} isValid />
                      <Form.Check.Label>
                        {advertise.name}
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  )
                })
                : ''
            }
          </div>
        </div>
        {/* </Form> */}
        <div className="btn-wrapper mt-3">
          <Button
            type="button"
            variant="primary"
            className="primary-btn"
            onClick={handleSubmitForm}
          >
            Finish
          </Button>
        </div>
      </div>
    </>
  );
};

export default SecondStep;
