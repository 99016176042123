import { GET_HQ_WORKSHOP_LIST, GET_HQ_WORKSHOP_LIST_COUNT, GET_HQ_WORKSHOP_LIST_COUNT_FAIL, GET_HQ_WORKSHOP_LIST_COUNT_SUCCESS, GET_HQ_WORKSHOP_LIST_FAIL, GET_HQ_WORKSHOP_LIST_SUCCESS, SET_HQ_WORKSHOP_SIGN_IN_TOKEN, SET_HQ_WORKSHOP_SIGN_IN_TOKEN_FAIL, SET_HQ_WORKSHOP_SIGN_IN_TOKEN_SUCCESS } from "./actionTypes";

const initialState = {
    loading: false,
    loadingTotalCount: false,
    workshops: [],
    totalCount: 0,
    error: {
        message: "",
    },
    success: {
        message: "",
    },
}

const HQWorkshopListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HQ_WORKSHOP_LIST:
            return {
                ...state,
                loading: true,
            }
        case GET_HQ_WORKSHOP_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                workshops: action.payload,
                success: {
                    message: action.message,
                },
            }
        case GET_HQ_WORKSHOP_LIST_FAIL:
            return {
                ...state,
                loading: false,
                workshops: [],
                error: {
                    message: action.message,
                },
            }
        case GET_HQ_WORKSHOP_LIST_COUNT:
            return {
                ...state,
                loadingTotalCount: true,
            }
        case GET_HQ_WORKSHOP_LIST_COUNT_SUCCESS:
            return {
                ...state,
                loadingTotalCount: false,
                totalCount: action.payload,
            }
        case GET_HQ_WORKSHOP_LIST_COUNT_FAIL:
            return {
                ...state,
                loadingTotalCount: false,
                totalCount: 0,
            }
        case SET_HQ_WORKSHOP_SIGN_IN_TOKEN:
            return {
                ...state,
                loading: true
            }
        case SET_HQ_WORKSHOP_SIGN_IN_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case SET_HQ_WORKSHOP_SIGN_IN_TOKEN_FAIL:
            return {
                ...state,
                loading: false,
                error: {
                    message: action.payload,
                },
            }
        default:
            return state
    }
}

export default HQWorkshopListReducer