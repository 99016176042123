import { takeLatest, put, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import {
  GET_CATEGORIES_LIST,
  GET_SECTION_LIST,
  GET_TEMPLATE_LIST,
  GET_TEMPLATE_DETAIL,
  TEMPLET_DELETE,
  ADD_TEMPLATE_CATEGORY,
  ADD_TEMPLATE_SECTION,
  ADD_TEMPLATE,
  GET_TEMPLATE_SECTIONS,
  CATEGORY_DELETE,
  SECTION_DELETE,
  UPDATE_TEMPLATE,
  COPY_TEMPLATE,
  UPDATE_CATEGORY,
  EDIT_CATEGORY,
  EDIT_SECTION,
  UPDATE_SECTION,
  DELETE_INSPECTION_TEMPLATE_QUE
} from "./actionType";
import {
  getTemplateList,
  getTemplateListSuccess,
  getTemplateListFail,
  getTemplateDetail,
  getTemplateDetailSuccess,
  getTemplateDetailFail,
  deleteTemplateSuccess,
  deleteTemplateFail,
  deleteCategorySuccess,
  deleteCategoryFail,
  deleteSectionSuccess,
  deleteSectionFail,
  getCategoriesListSuccess,
  getCategoriesListFail,
  getSectionListSuccess,
  getSectionListFail,
  getTemplateSections,
  getTemplateSectionsSuccess,
  getTemplateSectionsFail,
  addTemplateCategorySuccess,
  addTemplateCategoryFail,
  addTemplateSectionSuccess,
  addTemplateSectionFail,
  getCategoriesList,
  getSectionList,
  addTemplateSuccess,
  addTemplateFail,
  copyTemplateSuccess,
  copyTemplateFail,
  updateTemplateSuccess,
  updateTemplateFail,
  editCategorySuccess,
  editCategoryFail,
  updateCategorySuccess,
  updateCategoryFail,
  editSectionSuccess,
  editSectionFail,
  updateSectionSuccess,
  updateSectionFail,
  deleteInspectionTemplateQueSuccess,
  deleteInspectionTemplateQueFail,
} from "./action";

import {
  getTemplateListAPI,
  deleteTemplate,
  getCategoriesListAPI,
  getSectionListAPI,
  addTemplateCategory,
  addTemplateSection,
  addTemplate,
  deleteCategory,
  deleteSection,
  updateTemplate,
  copyTemplate,
  editCategory,
  updateCategory,
  updateSection,
  editSection,
  deleteInspectionTemplateQue
} from "../../helpers/backendHelper";
import { showSuccess, showError } from "utils/alertMessage";
import { getWorkshopId } from "utils/getWorkshopId";

function* onGetTemplateList(data) {
  try {
    const response = yield call(getTemplateListAPI, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTemplateListSuccess(response.data.InspTemplates));
      } else {
        yield put(getTemplateListFail(response.message));
      }
    } else {
      yield put(getTemplateListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTemplateListFail(error.response));
  }
}

function* onGetTemplateDetail(data) {
  try {
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let workshop_id = window.atob(storedData.workshop_id);
    const response = yield call(getTemplateListAPI, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTemplateDetailSuccess(response.data.InspTemplates[0]));
        yield put(
          getTemplateSections({
            get: "all",
            conditions: {
              categories_id: response.data.InspTemplates[0].categories_id,
              status: "1",
              workshop_id: workshop_id,
            },
          })
        );
      } else {
        yield put(getTemplateDetailFail(response.message));
      }
    } else {
      yield put(getTemplateDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTemplateDetailFail(error.response));
  }
}

function* onDeleteTemplate(data) {
  try {
    const response = yield call(deleteTemplate, data);
    if (response && response.code === 200) {
      const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
      let workshop_id = window.atob(storedData.workshop_id);
      yield put(
        getTemplateList({
          get: "all",
          conditions: {
            "InspTemplates.workshop_id": workshop_id,
          },
          contain: ["Categories", "Users"],
          order: {
            priority: "asc",
          },
        })
      );
      showSuccess(response.message);
      yield put(deleteTemplateSuccess(response));
    } else {
      yield put(deleteTemplateFail(response.message));
    }
  } catch (error) {
    yield put(deleteTemplateFail(error.response));
  }
}

function* onDeleteCategory(data) {
  try {
    const response = yield call(deleteCategory, data);
    if (response && response.code === 200) {
      const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
      let workshop_id = window.atob(storedData.workshop_id);
      yield put(
        getCategoriesList({
          get: "all",
          conditions: {
            workshop_id: workshop_id,
          },
        })
      );
      showSuccess(response.message);
      yield put(deleteCategorySuccess(response));
    } else {
      yield put(deleteCategoryFail(response.message));
    }
  } catch (error) {
    yield put(deleteCategoryFail(error.response));
  }
}

function* onDeleteSection(data) {
  try {
    const response = yield call(deleteSection, data);
    if (response && response.code === 200) {
      const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
      let workshop_id = window.atob(storedData.workshop_id);
      yield put(
        getSectionList({
          get: "all",
          conditions: {
            "InspGroups.workshop_id": workshop_id,
          },
          contain: ["Categories"],
        })
      );
      showSuccess(response.message);
      yield put(deleteCategorySuccess(response));
    } else {
      yield put(deleteCategoryFail(response.message));
    }
  } catch (error) {
    yield put(deleteCategoryFail(error.response));
  }
}

function* onGetCategoriesList(data) {
  try {
    const response = yield call(getCategoriesListAPI, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCategoriesListSuccess(response.data.Categories));
      } else {
        yield put(getCategoriesListFail(response.message));
      }
    } else {
      yield put(getCategoriesListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCategoriesListFail(error.response));
  }
}

function* onGetSectionList(data) {
  try {
    const response = yield call(getSectionListAPI, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSectionListSuccess(response.data.InspGroups));
      } else {
        yield put(getSectionListFail(response.message));
      }
    } else {
      yield put(getSectionListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSectionListFail(error.response));
  }
}

function* onAddTemplateCategory(data) {
  try {
    const navigate = data?.payload?.navigate;
    const response = yield call(addTemplateCategory, data);
    if (response) {
      if (response.code === 200) {
        if (navigate !== undefined) {
          navigate("/inspection/category-list");
        }
        yield put(addTemplateCategorySuccess(response));
        showSuccess("Category added successfully.");
      } else {
        yield put(addTemplateCategoryFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(addTemplateCategoryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(addTemplateCategoryFail(error.response));
  }
}

function* onAddTemplateSection(data) {
  try {
    const response = yield call(addTemplateSection, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getSectionList({
            get: "all",
            conditions: {
              categories_id: data.payload.categories_id,
            },
          })
        );
        yield put(addTemplateSectionSuccess(response));
        showSuccess("Section added successfully.");
      } else {
        yield put(addTemplateSectionFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(addTemplateSectionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(addTemplateSectionFail(error.response));
  }
}

function* onAddTemplate(data) {
  try {
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let workshop_id = window.atob(storedData.workshop_id);
    const response = yield call(addTemplate, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getTemplateList({
            get: "all",
            conditions: {
              "InspTemplates.workshop_id": workshop_id,
            },
            contain: ["Categories", "Users"],
            order: {
              priority: "asc",
            },
          })
        );
        yield put(addTemplateSuccess(response));
        showSuccess("Inspection template added.");
      } else {
        yield put(addTemplateFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(addTemplateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(addTemplateFail(error.response));
  }
}

function* onCopyTemplate(data) {
  try {
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let workshop_id = window.atob(storedData.workshop_id);
    const response = yield call(copyTemplate, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getTemplateList({
            get: "all",
            conditions: {
              "InspTemplates.workshop_id": workshop_id,
            },
            contain: ["Categories", "Users"],
            order: {
              priority: "asc",
            },
          })
        );
        yield put(addTemplateSuccess(response));
        showSuccess("Inspection template is copied.");
      } else {
        yield put(addTemplateFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(addTemplateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(addTemplateFail(error.response));
  }
}

function* onGetTemplateSections(data) {
  try {
    const response = yield call(getSectionListAPI, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTemplateSectionsSuccess(response.data.InspGroups));
      } else {
        yield put(getTemplateSectionsFail(response.message));
      }
    } else {
      yield put(getTemplateSectionsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTemplateSectionsFail(error.response));
  }
}

function* onUpdateTemplate(data) {
  try {
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let workshop_id = window.atob(storedData.workshop_id);
    const response = yield call(updateTemplate, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getTemplateDetail({
            get: "all",
            conditions: {
              id: data.payload.id,
            },
            contain: {
              InspQuestion: {
                sort: [
                  "InspQuestion.insp_group_id ASC",
                  "InspQuestion.position ASC",
                ],
              },
            },
          })
        );
        yield put(
          getTemplateList({
            get: "all",
            conditions: {
              "InspTemplates.workshop_id": workshop_id,
            },
            contain: ["Categories", "Users"],
            order: {
              priority: "asc",
            },
          })
        );
        yield put(updateTemplateSuccess(response));
        showSuccess("Inspection template updated.");
      } else {
        yield put(updateTemplateFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateTemplateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateTemplateFail(error.response));
  }
}

function* onEditCategory(data) {
  try {
    const response = yield call(editCategory, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(editCategorySuccess(response.data.Categories));
      } else {
        yield put(editCategoryFail(response.message));
      }
    } else {
      yield put(editCategoryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editCategoryFail(error.response));
  }
}

function* onUpdateCategory(data) {
  try {
    const navigate = data.payload.navigate;
    const response = yield call(updateCategory, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateCategorySuccess(response.data));
        navigate("/inspection/category-list");
        showSuccess(response.message);
      } else {
        yield put(updateCategoryFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateCategoryFail("Didn't get api response"));
      showError("Didn't get api response");
    }
  } catch (error) {
    yield put(updateCategoryFail(error.response));
  }
}

function* onEditSection(data) {
  try {
    const response = yield call(editSection, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(editSectionSuccess(response.data.InspGroups));
      } else {
        yield put(editSectionFail(response.message));
      }
    } else {
      yield put(editSectionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editSectionFail(error.response));
  }
}

function* onUpdateSection(data) {
  try {
    const navigate = data.payload.navigate;
    const response = yield call(updateSection, data);
    if (response) {
      if (response.code === 200) {
        if (navigate) {
          navigate("/inspection/group-list");
        }
        yield put(updateSectionSuccess(response.data));
        showSuccess(response.message);
      } else {
        yield put(updateSectionFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateSectionFail("Didn't get api response"));
      showError("Didn't get api response");
    }
  } catch (error) {
    yield put(updateSectionFail(error.response));
  }
}

function* onDeleteInspectionTemplateQue(data) {
  try {
    const response = yield call(deleteInspectionTemplateQue, data);
    if (response) {
        if (response.code == 200 || response.code == 400) {
            yield put(deleteInspectionTemplateQueSuccess(response));
        } else {
            yield put(deleteInspectionTemplateQueFail(response.message));
        }
    } else {
        yield put(deleteInspectionTemplateQueFail("Didn't get api response"));
    }
  } catch (error) {
      yield put(deleteInspectionTemplateQueFail(error.response));
  }
}

function* InspectionSaga() {
  yield takeLatest(GET_TEMPLATE_LIST, onGetTemplateList);
  yield takeLatest(GET_TEMPLATE_DETAIL, onGetTemplateDetail);
  yield takeLatest(TEMPLET_DELETE, onDeleteTemplate);
  yield takeLatest(GET_CATEGORIES_LIST, onGetCategoriesList);
  yield takeLatest(GET_SECTION_LIST, onGetSectionList);
  yield takeLatest(ADD_TEMPLATE_CATEGORY, onAddTemplateCategory);
  yield takeLatest(ADD_TEMPLATE_SECTION, onAddTemplateSection);
  yield takeLatest(ADD_TEMPLATE, onAddTemplate);
  yield takeLatest(GET_TEMPLATE_SECTIONS, onGetTemplateSections);
  yield takeLatest(CATEGORY_DELETE, onDeleteCategory);
  yield takeLatest(SECTION_DELETE, onDeleteSection);
  yield takeLatest(UPDATE_TEMPLATE, onUpdateTemplate);
  yield takeLatest(COPY_TEMPLATE, onCopyTemplate);

  yield takeLatest(EDIT_CATEGORY, onEditCategory);
  yield takeLatest(UPDATE_CATEGORY, onUpdateCategory);
  yield takeLatest(EDIT_SECTION, onEditSection);
  yield takeLatest(UPDATE_SECTION, onUpdateSection);
  yield takeLatest(DELETE_INSPECTION_TEMPLATE_QUE, onDeleteInspectionTemplateQue);
}

export default InspectionSaga;
