import { takeLatest, put, call } from "redux-saga/effects";
import {
    CHECK_MOBILE_EXIST,
    CHECK_PHONE_EXIST,
    ADD_CUSTOMER
} from "./actionTypes";
import {
    addCustomerSuccess,
    addCustomerFail,
    checkMobileExistSuccess,
    checkMobileExistFail,
    checkPhoneExistSuccess,
    checkPhoneExistFail
} from "./actions";

import {
    addCustomer,
    checkMobileExist,
    checkPhoneExist
} from "../../helpers/backendHelper";


function* onAddCustomer(data) {
    try {
        const response = yield call(addCustomer, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    addCustomerSuccess(response.data)
                );
            } else {
                yield put(addCustomerFail(response.message));
            }
        } else {
            yield put(addCustomerFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addCustomerFail(error.response));
    }
}

function* onCheckPhoneExist(data) {
    try {
        const response = yield call(checkPhoneExist, data);
        if (response) {
            if (response.code === 200) {
                yield put(checkPhoneExistSuccess(true));
            } else {
                yield put(checkPhoneExistFail(false));
            }
        } else {
            yield put(checkPhoneExistFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(checkPhoneExistFail(error.response));
    }
}

function* onCheckMobileExist(data) {
    try {
        const response = yield call(checkMobileExist, data);
        if (response) {
            if (response.code === 200) {
                yield put(checkMobileExistSuccess(true));
            } else {
                yield put(checkMobileExistFail(false));
            }
        } else {
            yield put(checkMobileExistFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(checkMobileExistFail(error.response));
    }
}

function* ContactsSaga() {
    yield takeLatest(CHECK_PHONE_EXIST, onCheckPhoneExist);
    yield takeLatest(CHECK_MOBILE_EXIST, onCheckMobileExist);
    yield takeLatest(ADD_CUSTOMER, onAddCustomer);
}

export default ContactsSaga;
