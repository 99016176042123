import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHQWorkshopList } from "views/hq/store/workshop/actions";

export default function WorkshopFilterDropdown({ current, onChange }) {
  const userData = JSON.parse(localStorage.getItem("data"));

  const [data, setData] = useState([
    {
      id: 0,
      name: "All workshops",
    },
  ]);
  const { loading, workshops } = useSelector(
    (state) => state.HQReducer.HQWorkshopListReducer
  );
  const dispatch = useDispatch();

  const loadData = useCallback(
    (page = 1, pageLimit = 10, search) => {
      const conditions = {
        "Workshops.head_quarters_id": userData.head_quarters_id,
        "Workshops.status": 1,
      };

      dispatch(
        getHQWorkshopList({
          get: "all",
          conditions,
          fields: ["Workshops.id", "Workshops.name"],
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    const workshopMap = [];
    if (!loading && Array.isArray(workshops) && workshops.length > 0) {
      workshopMap.push(...workshops.map((e) => ({ id: e.id, name: e.name })));
    }
    setData([
      {
        id: 0,
        name: "All workshops",
      },
      ...workshopMap,
    ]);
  }, [workshops, loading]);

  const currentWorkshop = useMemo(
    () => data.filter((e) => e.id === (current ? current : 0)).shift(),
    [current, data]
  );

  return (
    <>
      <label className="dropdown">
        <div className="dd-button btn btn-primary rounded-btn dropdown-toggle">
          <i className="fa fa-filter"> </i> {currentWorkshop.name}
        </div>
        <input type="checkbox" className="dd-input" id="workshop-select" />
        <ul className="dd-menu custom-scrollbar" style={{ maxHeight: 200, overflowY: 'auto' }}>
          {data.map((e) => (
            <li
              key={e.id}
              onClick={() => {
                if (onChange) {
                  onChange(e.id);
                } else {
                  console.log(
                    "No callback provided for workshop filter change"
                  );
                }
              }}
            >
              {e.name}
            </li>
          ))}
        </ul>
      </label>
    </>
  );
}
