import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Inspections = Loadable({
  loader: () => import("./components/Inspections"),
  loading: Loading,
});

const InspectionsStatusList = Loadable({
  loader: () => import("./components/InspectionsStatusList"),
  loading: Loading,
});

const InspectionsView = Loadable({
  loader: () => import("./components/InspectionsView"),
  loading: Loading,
});

export const routes = [
  {
    path: "/inspections",
    exact: true,
    name: "inspection",
    element: Inspections,
  },
  {
    path: "/inspections/list-inspection-by-status/:status",
    exact: true,
    name: "InspectionsStatusList",
    element: InspectionsStatusList,
  },
  {
    path: "/inspections/:id",
    exact: true,
    name: "InspectionsView",
    element: InspectionsView,
  },
];

export * from "./components";
