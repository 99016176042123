import {
  deleteArchiveInvoices,
  deleteArchiveInvoicesSales,
  deleteInvoiceCredit,
  deleteStatementInvoice,
  deleteInvoicePayment,
  getCashSaleCredit,
  getCashSalePayments,
  getInvoicesCredits,
  getInvoicesList,
  getInvoicesPayments,
  getInvoicesSalesList,
  getInvoicesStatements,
  updateInvoiceStatements,
  searchInvoice,
  searchCashsale,
  searchReceipt,
  searchCashsaleReceipt
} from "helpers/backendHelper";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  deleteArchiveCompletedInvoiceFail,
  deleteArchiveCompletedInvoiceSuccess,
  deleteArchiveCreditInvoiceFail,
  deleteArchiveCreditInvoiceSuccess,
  deleteArchivePaymentInvoiceFail,
  deleteArchivePaymentInvoiceSuccess,
  deleteArchiveRaisedInvoiceFail,
  deleteArchiveRaisedInvoiceSuccess,
  getCompletedInvoicesFail,
  getCompletedInvoicesSuccess,
  getInvoiceCreditsFail,
  getInvoiceCreditsSuccess,
  getInvoiceStatementsFail,
  getInvoiceStatementsSuccess,
  getInvoicePaymentsFail,
  getInvoicePaymentsSuccess,
  getRaisedInvoiceFail,
  getRaisedInvoicesSuccess,
  deleteStatementInvoiceFail,
  deleteStatementInvoiceSuccess,
  updateInvoiceStatementsFail,
  updateInvoiceStatementsSuccess,
  searchInvoiceFail,
  searchInvoiceSuccess,
  searchCashsaleFail,
  searchCashsaleSuccess,
  searchReceiptFail,
  searchReceiptSuccess,
  searchCashsaleReceiptFail,
  searchCashsaleReceiptSuccess,
} from "./actions";
import {
  DELETE_COMPLETED_INVOICES,
  DELETE_CREDIT_INVOICES,
  DELETE_PAYMENT_INVOICES,
  DELETE_RAISED_INVOICES,
  GET_COMPLETED_INVOICES,
  GET_INVOICE_CREDITS,
  GET_INVOICE_PAYMENTS,
  GET_RAISED_INVOICES,
  GET_INVOICES_STATEMENTS,
  DELETE_STATEMENT_INVOICES,
  UPDATE_INVOICE_STATEMENTS,
  SEARCH_INVOICE,
  SEARCH_RECEIPT,
  SEARCH_CASHSALE_RECEIPT,
  SEARCH_CASHSALE
} from "./actionTypes";
import {
  getCompletedInvoicePayload,
  getInvoiceCreditAction,
  getInvoicePaymentAction,
  getRaisedInvoicePayload,
  getInvoiceStatementAction
} from "./api";
import { formatInvoiceList, formatSaleInvoiceList } from "./formatter";

function* onGetRaisedInvoices(action) {

  const { invoiceListRequest,saleInvoiceRequest,listCount,saleInvoiceCount } = action.payload;
  const payload = {};
  try {
    
    if(saleInvoiceRequest != "cashsale"){

      const saleListResponse = yield call(getInvoicesSalesList, {
        payload: saleInvoiceRequest,
      });
      
      if (saleListResponse?.code === 200) {
        
        const saleListCountResp = yield call(getInvoicesSalesList, {
          payload: saleInvoiceCount,
        });
        
        
        if(saleListCountResp?.code === 200){
          payload.count = saleListCountResp?.data?.CashSaleInvoice[0].COUNT; 
        }
        
        // payload.list = formatInvoiceList(
        //   listResponse?.data?.Invoices || [],
        //   "Unpaid"
        // ).concat(
        payload.list = formatSaleInvoiceList(
            saleListResponse?.data?.CashSaleInvoice || [],
            "Unpaid"
          )
        // );
        // payload.count =
        // saleListResponse?.data?.CashSaleInvoice.length 
          // +
          // saleListResponse?.data?.CashSaleInvoice.length;
        
        payload.list.sort((a, b) => b.cash_sale_no - a.cash_sale_no);

        yield put(getRaisedInvoicesSuccess(payload));
      } else {
        if(saleListResponse?.code == 400) {
          payload.list = [];
          payload.count = 0;
          yield put(getRaisedInvoicesSuccess(payload));
        }else{
          yield put(getRaisedInvoiceFail("Failed to fetch invoice"));  
        }
      }
  
    }else{

      const listResponse = yield call(getInvoicesList, {
        payload: invoiceListRequest,
      });

      if (listResponse?.code === 200 ) {
        const listCountResp = yield call(getInvoicesList, {
          payload: listCount,
        });

        if(listCountResp?.code === 200){
          payload.count = listCountResp?.data?.Invoices[0].COUNT; 
        }

        payload.list = formatInvoiceList(
          listResponse?.data?.Invoices || [],
          "Unpaid"
        );

        // payload.count =
        //   listResponse?.data?.Invoices.length 
          
          // +
          // saleListResponse?.data?.CashSaleInvoice.length;
        
        payload.list.sort((a, b) => b.invoice_number - a.invoice_number);
    
        yield put(getRaisedInvoicesSuccess(payload));
      } else {
        if(listResponse?.code == 400) {
          payload.list = [];
          payload.count = 0;
          yield put(getRaisedInvoicesSuccess(payload));
        }else{
          yield put(getRaisedInvoiceFail("Failed to fetch invoice"));  
        }
      }
    }
   
    // if (listResponse?.code === 200 || saleListResponse?.code === 200) {
    //   payload.list = formatInvoiceList(
    //     listResponse?.data?.Invoices || [],
    //     "Unpaid"
    //   ).concat(
    //     formatSaleInvoiceList(
    //       saleListResponse?.data?.CashSaleInvoice || [],
    //       "Unpaid"
    //     )
    //   );
    //   payload.count =
    //     listResponse?.data?.Invoices.length 
        
    //     // +
    //     // saleListResponse?.data?.CashSaleInvoice.length;
      
    //   payload.list.sort((a, b) => b.invoice_number - a.invoice_number);
    // } else {
    //   yield put(getRaisedInvoiceFail("Failed to fetch invoice"));
    // }

    // yield put(getRaisedInvoicesSuccess(payload));
  } catch (err) {
    yield put(getRaisedInvoiceFail("Failed to fetch invoice"));
  }
}

function* onGetCompletedInvoices(action) {
  const { invoiceListRequest, saleInvoiceRequest,listCount,saleInvoiceCount } = action.payload;
  const payload = {};
  try {

    if(saleInvoiceRequest != "cashsale"){
      const saleListResponse = yield call(getInvoicesSalesList, {
        payload: saleInvoiceRequest,
      });

      if (saleListResponse?.code === 200) {

        const saleListCountResp = yield call(getInvoicesSalesList, {
          payload: saleInvoiceCount,
        });
        
        if(saleListCountResp?.code === 200){
          payload.count = saleListCountResp?.data?.CashSaleInvoice[0].COUNT; 
        }

        // payload.list = listResponse?.data?.Invoices || [];
        payload.list = 
          formatSaleInvoiceList(
            saleListResponse?.data?.CashSaleInvoice || [],
            "Paid"
          );

        // payload.count =
        //   saleListResponse?.data?.CashSaleInvoice.length;
        
        payload.list.sort((a, b) => b.cash_sale_no - a.cash_sale_no);
        yield put(getCompletedInvoicesSuccess(payload));
      } else {
        if(saleListResponse?.code == 400) {
          payload.list = [];
          payload.count = 0;
          yield put(getCompletedInvoicesSuccess(payload));
        }else{
          yield put(getCompletedInvoicesFail("Failed to fetch invoice"));
        }
      }
    }else{
      const listResponse = yield call(getInvoicesList, {
        payload: invoiceListRequest,
      });

      if (listResponse?.code === 200 ) {

        const listCountResp = yield call(getInvoicesList, {
          payload: listCount,
        });

        if(listCountResp?.code === 200){
          payload.count = listCountResp?.data?.Invoices[0].COUNT; 
        }

        // payload.list = listResponse?.data?.Invoices || [];
        payload.list = formatInvoiceList(
          listResponse?.data?.Invoices || [],
          "Paid"
        );
        // payload.count = listResponse?.data?.Invoices.length 
        payload.list.sort((a, b) => b.invoice_number - a.invoice_number);
        yield put(getCompletedInvoicesSuccess(payload));
      } else {
        if(listResponse?.code == 400) {
          payload.list = [];
          payload.count = 0;
          yield put(getCompletedInvoicesSuccess(payload));
        }else{
          yield put(getCompletedInvoicesFail("Failed to fetch invoice"));
        }
      }
      // yield put(getCompletedInvoicesSuccess(payload));
    }

    // if (listResponse?.code === 200 || saleListResponse?.code === 200) {
    //   // payload.list = listResponse?.data?.Invoices || [];
    //   payload.list = formatInvoiceList(
    //     listResponse?.data?.Invoices || [],
    //     "Paid"
    //   ).concat(
    //     formatSaleInvoiceList(
    //       saleListResponse?.data?.CashSaleInvoice || [],
    //       "Paid"
    //     )
    //   );
    //   payload.count =
    //     listResponse?.data?.Invoices.length +
    //     saleListResponse?.data?.CashSaleInvoice.length;
      
    //   payload.list.sort((a, b) => b.invoice_number - a.invoice_number);
    // } else {
    //   yield put(getCompletedInvoicesFail("Failed to fetch invoice"));
    // }

    
  } catch (err) {
    yield put(getCompletedInvoicesFail("Failed to fetch invoice"));
  }
}

function* onGetInvoicePayments(action) {
  // const { listRequest, cashSaleRequest } = action.payload;
  const { listRequest, saleInvoiceRequest,listCount,saleInvoiceCount } = action.payload;
  const payload = {};
  try {
    if(saleInvoiceRequest != "cashsale"){
      const cashSaleResponse = yield call(getCashSalePayments, {
        payload: saleInvoiceRequest,
      });

      if (cashSaleResponse?.code === 200) {
        
        const saleListCountResp = yield call(getCashSalePayments, {
          payload: saleInvoiceCount,
        });
        
        if(saleListCountResp?.code === 200){
          payload.count = saleListCountResp?.data?.CashSalePaymentsCredits[0].COUNT; 
        }

        payload.list = 
          cashSaleResponse?.data?.CashSalePaymentsCredits.map((z) => ({
            ...z,
            customer:
              z?.cash_sale_invoice?.user?.customers_details[0]?.type == 1
                ? z.cash_sale_invoice?.user?.customers_details[0]?.company_name
                : z.cash_sale_invoice?.user?.customers_details[0]?.firstname +
                  " " +
                  z.cash_sale_invoice?.user?.customers_details[0]?.lastname,
            invoice_number: z?.cash_sale_invoice?.cash_sale_no,
            receipt_no: z?.cash_sale_receipt_no,
            job_number: "Cash Sale",
          }))
        ;
        // payload.count = cashSaleResponse?.data?.CashSalePaymentsCredits.length;
        payload.list = payload.list.sort(
          (a, b) => b.receipt_no - a.receipt_no
        );
        yield put(getInvoicePaymentsSuccess(payload));
      }else {
        if(cashSaleResponse?.code == 400) {
          payload.list = [];
          payload.count = 0;
          yield put(getInvoicePaymentsSuccess(payload));
        }else{
          yield put(getInvoicePaymentsFail("Failed to fetch invoice"));
        }
      }
    }else{
      const listResponse = yield call(getInvoicesPayments, {
        payload: listRequest,
      });

      if (listResponse?.code == 200) {

        const listCountResp = yield call(getInvoicesPayments, {
          payload: listCount,
        });

        if(listCountResp?.code === 200){
          payload.count = listCountResp?.data?.InvoicesPaymentsCredits[0].COUNT; 
        }

        payload.list =
          listResponse?.data?.InvoicesPaymentsCredits.map(function(z){
            let invoiceNumberSuffix = ''; 
            if (z?.invoice?.customer_preview_invoice === 1) {
                invoiceNumberSuffix = ' - A';
            } else if (z?.invoice?.insurance_preview_invoice === 1) {
                invoiceNumberSuffix = ' - B';
            } else if (z?.invoice?.customer_invoice_allocation_preview === 1) {
                invoiceNumberSuffix = ' - C';
            }

            return {
              ...z,
              customer:
                z?.invoice?.job_schedule?.customer?.cust_detail
                  ?.type == 1
                  ? z.invoice?.job_schedule?.customer?.cust_detail
                      ?.company_name
                  : z.invoice?.job_schedule?.customer?.cust_detail
                      ?.firstname +
                    " " +
                    z.invoice?.job_schedule?.customer?.cust_detail
                      ?.lastname,
              invoice_number: z?.invoice?.invoice_number + invoiceNumberSuffix,
              job_number: z?.invoice.job_schedule.job_number
            }
          }) || [];
  
        // payload.count = listResponse?.data?.InvoicesPaymentsCredits.length;
        payload.list = payload.list.sort(
          (a, b) => b.receipt_no - a.receipt_no
        );
        yield put(getInvoicePaymentsSuccess(payload));
      } else {
        if(listResponse?.code == 400) {
          payload.list = [];
          payload.count = 0;
          yield put(getInvoicePaymentsSuccess(payload));
        }else{
          yield put(getInvoicePaymentsFail("Failed to fetch invoice"));
        }
      }
    }
  } catch (err) {
    yield put(getInvoicePaymentsFail("Failed to fetch invoice"));
  }
}

function* onGetInvoiceCredits(action) {
  const { listRequest, cashSaleListRequest } = action.payload;
  const payload = {
    list: [],
    count: 0,
  };
  try {
    const listResponse = yield call(getInvoicesCredits, {
      payload: listRequest,
    });

    const cashSaleRespone = yield call(getCashSaleCredit, {
      payload: cashSaleListRequest,
    });

    if (listResponse?.code == 200) {
      payload.list =
        listResponse?.data?.InvoicesPaymentsCreditsOtherDetails.map(function(z){

          let invoiceNumberSuffix = ''; 
            if (z?.invoices_payments_credit.invoice?.customer_preview_invoice === 1) {
                invoiceNumberSuffix = ' - A';
            } else if (z?.invoices_payments_credit.invoice?.insurance_preview_invoice === 1) {
                invoiceNumberSuffix = ' - B';
            } else if (z?.invoices_payments_credit.invoice?.customer_invoice_allocation_preview === 1) {
                invoiceNumberSuffix = ' - C';
            }

          return {
          ...z,
          customer:
            z?.invoices_payments_credit.invoice.job_schedule?.customer?.cust_detail?.type == 1
              ? z.invoices_payments_credit.invoice.job_schedule?.customer?.cust_detail?.company_name
              : z?.invoices_payments_credit.invoice.job_schedule?.customer?.cust_detail?.firstname +
                " " +
                z?.invoices_payments_credit.invoice.job_schedule?.customer?.cust_detail?.lastname,
            invoice_number:
              z?.invoices_payments_credit.invoice.invoice_number + invoiceNumberSuffix,
            job_number: z?.invoices_payments_credit.invoice.job_schedule.job_number,
            receipt_no: z?.invoices_payments_credit?.receipt_no,
            receipt_total:z?.invoices_payments_credit.invoice.total,
          }
        }) || [];
      payload.count =
        listResponse?.data?.InvoicesPaymentsCreditsOtherDetails.length;
    }

    if (cashSaleRespone?.code === 200) {
      payload.list = payload.list.concat(
        cashSaleRespone?.data?.CashSalePaymentsCreditsOtherDetails.map((z) => ({
          ...z,
          customer:
            z?.cash_sale_payments_credit?.cash_sale_invoice?.user
              ?.customers_details[0]?.type == 1
              ? z.cash_sale_payments_credit?.cash_sale_invoice?.user
                  ?.customers_details[0]?.company_name
              : z?.cash_sale_payments_credit?.cash_sale_invoice?.user
                  ?.customers_details[0]?.firstname +
                " " +
                z?.cash_sale_payments_credit?.cash_sale_invoice?.user
                  ?.customers_details[0]?.lastname,
          invoice_number:
            z?.cash_sale_payments_credit?.cash_sale_invoice?.cash_sale_no,
          job_number: "Cash Sale",
          receipt_no: z?.cash_sale_payments_credit?.cash_sale_receipt_no,
          credit_no: z?.cash_sale_credit_no,
          receipt_total:z?.amount_paid,
        }))
      );

      payload.count =
        payload.count +
        cashSaleRespone?.data?.CashSalePaymentsCreditsOtherDetails.length;
    }
    payload.list.sort((a,b)=>b.credit_no - a.credit_no);
    yield put(getInvoiceCreditsSuccess(payload));
  } catch (err) {
    yield put(getInvoiceCreditsFail("Failed to fetch invoice"));
  }
}

function* onGetInvoiceStatement(action) {
  const { listRequest } = action.payload;
  const payload = {
    list: [],
    count: 0,
  };
  try {
    const listResponse = yield call(getInvoicesStatements, {
      payload: listRequest,
    });

    if (listResponse?.code == 200) {
      payload.list =
        listResponse?.data?.InvoiceStatement.map((z) => ({
          ...z,
          customer:
            z?.user?.customers_details?.[0]?.type == 1
              ? z.user?.customers_details?.[0]?.company_name
              : z?.user?.customers_details?.[0]?.firstname +
                " " +
                z?.user?.customers_details?.[0]?.lastname,
        })) || [];
      payload.count =
        listResponse?.data?.InvoiceStatement?.length;
      payload.pivot_count =
        listResponse?.data?.InvoiceStatement?.[0]?.statement_invoice_pivot?.length;
    }

    // payload.list.sort((a,b)=>b.credit_no - a.credit_no);
    yield put(getInvoiceStatementsSuccess(payload));
  } catch (err) {
    yield put(getInvoiceStatementsFail("Failed to fetch invoice"));
  }
}

function* onDeleteArchiveRaisedInvoice(action) {
  try {
    const { saleInvoice, invoice } = action.payload;
    let saleInvoiceDelete;
    let invoiceDelete;

    if (invoice.ids.length !== 0) {
      invoiceDelete = yield call(deleteArchiveInvoices, { payload: invoice });
    }
    if (saleInvoice && saleInvoice.ids.length !== 0) {
      saleInvoiceDelete = yield call(deleteArchiveInvoicesSales, {
        payload: saleInvoice,
      });
    }

    if (invoiceDelete?.code === 200 || saleInvoiceDelete?.code === 200) {
      yield put(deleteArchiveRaisedInvoiceSuccess(invoice.action_type));
      const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
      let workshop_id = window.atob(storedData.workshop_id);
      yield put(getRaisedInvoicePayload(workshop_id));
    } else {
      yield put(deleteArchiveRaisedInvoiceFail());
    }
  } catch (error) {
    yield put(deleteArchiveRaisedInvoiceFail());
  }
}

function* onDeleteArchiveCompletedInvoice(action) {
  try {
    const { saleInvoice, invoice } = action.payload;
    let saleInvoiceDelete;
    let invoiceDelete;

    if (invoice.ids.length !== 0) {
      invoiceDelete = yield call(deleteArchiveInvoices, { payload: invoice });
    }
    if (saleInvoice.ids.length !== 0) {
      saleInvoiceDelete = yield call(deleteArchiveInvoicesSales, {
        payload: saleInvoice,
      });
    }

    if (invoiceDelete?.code === 200 || saleInvoiceDelete?.code === 200) {
      yield put(deleteArchiveCompletedInvoiceSuccess(invoice.action_type));
      const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
      let workshop_id = window.atob(storedData.workshop_id);
      yield put(getCompletedInvoicePayload(workshop_id));
    } else {
      yield put(deleteArchiveCompletedInvoiceFail());
    }
  } catch (error) {
    yield put(deleteArchiveCompletedInvoiceFail());
  }
}

function* onDeleteInvoicePayment(action) {
  try {
    const data = yield call(deleteInvoicePayment, { payload: action.payload });
    if (data?.code === 200) {
      yield put(deleteArchivePaymentInvoiceSuccess(action.payload.action_type));
      yield put(getInvoicePaymentAction());
    } else {
      yield put(deleteArchivePaymentInvoiceFail());
    }
  } catch (error) {
    yield put(deleteArchivePaymentInvoiceFail());
  }
}

function* onUpdateInvoiceStatement(action) {
  
  try {
    const response = yield call(updateInvoiceStatements, action);
    
    if (response?.code == 200) {
      yield put(updateInvoiceStatementsSuccess(response.data));
    }else{
      yield put(updateInvoiceStatementsFail("Didn't get api response"));
    }
  } catch (err) {
    yield put(updateInvoiceStatementsFail("Failed to update invoice"));
  }
}

// function* onUpdateJobTimerData(data) {
//   try {
//     const response = yield call(updateJobTimer, data);
//     if (response) {
//       if (response.code === 200) {
//         yield put(updateJobTimerSuccess(response.data));
//       } else {
//         yield put(updateJobTimerFail(response.message));
//       }
//     } else {
//       yield put(updateJobTimerFail("Didn't get api response"));
//     }
//   } catch (error) {
//     yield put(updateJobTimerFail(error.response));
//   }
// }


function* onDeleteInvoiceCredit(action) {
  try {
    const data = yield call(deleteInvoiceCredit, { payload: action.payload });
    if (data?.code === 200) {
      yield put(deleteArchiveCreditInvoiceSuccess(action.payload.action_type));
      yield put(getInvoiceCreditAction());
    } else {
      yield put(deleteArchiveCreditInvoiceFail());
    }
  } catch (error) {
    yield put(deleteArchiveCreditInvoiceFail());
  }
}

function* onDeleteInvoiceStatement(action) {
  try {
    const data = yield call(deleteStatementInvoice, { payload: action.payload });
    if (data?.code === 200) {
      yield put(deleteStatementInvoiceSuccess(action.payload.action_type));
      yield put(getInvoiceStatementAction());
    } else {
      yield put(deleteStatementInvoiceFail());
    }
  } catch (error) {
    yield put(deleteStatementInvoiceFail());
  }
}

function* onSearchInvoice(action) {
  try {
    const response = yield call(searchInvoice,  { payload: action.payload });
      if (response) {
        const payload = {};
          if (response.code === 200) {
            
            if(action.payload.type == 1){
              payload.list = formatInvoiceList(
                response?.data?.Invoices || [],
                "Unpaid"
              )
            }else{
              payload.list = formatInvoiceList(
                response?.data?.Invoices || [],
                "paid"
              )
            }
            payload.count = (response?.data?.Invoices)?.length;
            yield put(searchInvoiceSuccess(payload));
          } else {
              yield put(searchInvoiceFail(response.message));
          }
      } else {
          yield put(searchInvoiceFail("Didn't get api response"));
      }
  } catch (error) {
      yield put(searchInvoiceFail(error.response));
  }
}

function* onSearchCashsale(action) {
  try {
    const response = yield call(searchCashsale,  { payload: action.payload });
      if (response) {
        const payload = {};
          if (response.code === 200) {
            if(action.payload.type == 1){
              payload.list = formatSaleInvoiceList(
                response?.data?.CashSaleInvoice || [],
                "Unpaid"
              )
            }else{
              payload.list = formatSaleInvoiceList(
                response?.data?.CashSaleInvoice || [],
                "paid"
              )
            }
            
            payload.count = (response?.data?.CashSaleInvoice)?.length;
            
            yield put(searchCashsaleSuccess(payload));
            
          } else {
              yield put(searchCashsaleFail(response.message));
          }
      } else {
          yield put(searchCashsaleFail("Didn't get api response"));
      }
  } catch (error) {
      yield put(searchCashsaleFail(error.response));
  }
}

function* onSearchReceipt(action) {
  try {
    const response = yield call(searchReceipt,  { payload: action.payload });
      if (response) {
        const payload = {};
          if (response.code === 200) {
            payload.list =
            response?.data?.InvoicesPaymentsCredits.map(function(z){
              let invoiceNumberSuffix = ''; 
              if (z?.invoice?.customer_preview_invoice === 1) {
                  invoiceNumberSuffix = ' - A';
              } else if (z?.invoice?.insurance_preview_invoice === 1) {
                  invoiceNumberSuffix = ' - B';
              } else if (z?.invoice?.customer_invoice_allocation_preview === 1) {
                  invoiceNumberSuffix = ' - C';
              }

              return {
                ...z,
                customer:
                  z?.invoice?.job_schedule?.customer?.cust_detail
                    ?.type == 1
                    ? z.invoice?.job_schedule?.customer?.cust_detail
                        ?.company_name
                    : z.invoice?.job_schedule?.customer?.cust_detail
                        ?.firstname +
                      " " +
                      z.invoice?.job_schedule?.customer?.cust_detail
                        ?.lastname,
                invoice_number: z?.invoice?.invoice_number + invoiceNumberSuffix,
                job_number: z?.invoice.job_schedule.job_number,
              }
            }) || [];
            payload.count = (response?.data?.InvoicesPaymentsCredits)?.length;
            
            yield put(searchReceiptSuccess(payload));
          } else {
              yield put(searchReceiptFail(response.message));
          }
      } else {
          yield put(searchReceiptFail("Didn't get api response"));
      }
  } catch (error) {
      yield put(searchReceiptFail(error.response));
  }
}

function* onSearchCashsaleReceipt(action) {
  try {
    const response = yield call(searchCashsaleReceipt,  { payload: action.payload });
      if (response) {
        const payload = {};
          if (response.code === 200) {
            payload.list = 
              response?.data?.CashSalePaymentsCredits.map((z) => ({
                  ...z,
                  customer:
                    z?.cash_sale_invoice?.user?.customers_details[0]?.type == 1
                      ? z.cash_sale_invoice?.user?.customers_details[0]?.company_name
                      : z.cash_sale_invoice?.user?.customers_details[0]?.firstname +
                        " " +
                        z.cash_sale_invoice?.user?.customers_details[0]?.lastname,
                  invoice_number: z?.cash_sale_invoice?.cash_sale_no,
                  receipt_no: z?.cash_sale_receipt_no,
                  job_number: "Cash Sale",
                }));
            
            payload.count = (response?.data?.CashSalePaymentsCredits)?.length;
            
            yield put(searchCashsaleReceiptSuccess(payload));
            
          } else {
              yield put(searchCashsaleReceiptFail(response.message));
          }
      } else {
          yield put(searchCashsaleReceiptFail("Didn't get api response"));
      }
  } catch (error) {
      yield put(searchCashsaleReceiptFail(error.response));
  }
}


function* invoiceSagas() {
  yield takeLatest(GET_RAISED_INVOICES, onGetRaisedInvoices);
  // yield takeLatest(GET_RAISED_INVOICES_COUNT, onGetRaisedInvoicesCounts)
  yield takeLatest(GET_COMPLETED_INVOICES, onGetCompletedInvoices);
  // yield takeLatest(GET_COMPLETED_INVOICES_COUNT, onGetCompletedInvoicesCounts)
  yield takeLatest(GET_INVOICE_PAYMENTS, onGetInvoicePayments);
  yield takeLatest(GET_INVOICE_CREDITS, onGetInvoiceCredits);
  yield takeLatest(GET_INVOICES_STATEMENTS, onGetInvoiceStatement);
  yield takeLatest(UPDATE_INVOICE_STATEMENTS, onUpdateInvoiceStatement);  
  yield takeLatest(DELETE_RAISED_INVOICES, onDeleteArchiveRaisedInvoice);
  yield takeLatest(DELETE_COMPLETED_INVOICES, onDeleteArchiveCompletedInvoice);
  yield takeLatest(DELETE_PAYMENT_INVOICES, onDeleteInvoicePayment);
  yield takeLatest(DELETE_CREDIT_INVOICES, onDeleteInvoiceCredit);
  yield takeLatest(DELETE_STATEMENT_INVOICES, onDeleteInvoiceStatement);
  yield takeLatest(SEARCH_INVOICE, onSearchInvoice);
  yield takeLatest(SEARCH_CASHSALE, onSearchCashsale);
  yield takeLatest(SEARCH_RECEIPT, onSearchReceipt);
  yield takeLatest(SEARCH_CASHSALE_RECEIPT, onSearchCashsaleReceipt);
}

export default invoiceSagas;
