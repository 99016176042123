export const GET_VEHICLE_COUNT = "GET_VEHICLE_COUNT";
export const GET_VEHICLE_COUNT_SUCCESS = "GET_VEHICLE_COUNT_SUCCESS";
export const GET_VEHICLE_COUNT_FAIL = "GET_VEHICLE_COUNT_FAIL";

export const GET_SERVICE_DUE_COUNT = "GET_SERVICE_DUE_COUNT";
export const GET_SERVICE_DUE_COUNT_SUCCESS = "GET_SERVICE_DUE_COUNT_SUCCESS";
export const GET_SERVICE_DUE_COUNT_FAIL = "GET_SERVICE_DUE_COUNT_FAIL";

export const GET_CUSTOMER_AND_APP_COUNT = "GET_CUSTOMER_AND_APP_COUNT";
export const GET_CUSTOMER_AND_APP_COUNT_SUCCESS = "GET_CUSTOMER_AND_APP_COUNT_SUCCESS";
export const GET_CUSTOMER_AND_APP_COUNT_FAIL = "GET_CUSTOMER_AND_APP_COUNT_FAIL";

export const GET_BOOK_IN_SOURCE = "GET_BOOK_IN_SOURCE";
export const GET_BOOK_IN_SOURCE_SUCCESS = "GET_BOOK_IN_SOURCE_SUCCESS";
export const GET_BOOK_IN_SOURCE_FAIL = "GET_BOOK_IN_SOURCE_FAIL";

export const GET_CUSTOMER_SURVEY = "GET_CUSTOMER_SURVEY";
export const GET_CUSTOMER_SURVEY_SUCCESS = "GET_CUSTOMER_SURVEY_SUCCESS";
export const GET_CUSTOMER_SURVEY_FAIL = "GET_CUSTOMER_SURVEY_FAIL";

export const GET_NEWS_FEED = "GET_NEWS_FEED";
export const GET_NEWS_FEED_SUCCESS = "GET_NEWS_FEED_SUCCESS";
export const GET_NEWS_FEED_FAIL = "GET_NEWS_FEED_FAIL";

export const GET_DASHBOARD_JOB_COUNT = "GET_DASHBOARD_JOB_COUNT";
export const GET_DASHBOARD_JOB_COUNT_SUCCESS = "GET_DASHBOARD_JOB_COUNT_SUCCESS";
export const GET_DASHBOARD_JOB_COUNT_FAIL = "GET_DASHBOARD_JOB_COUNT_FAIL";

export const GET_DASHBOARD_WEEK_JOB_COUNT = "GET_DASHBOARD_WEEK_JOB_COUNT";
export const GET_DASHBOARD_WEEK_JOB_COUNT_SUCCESS = "GET_DASHBOARD_WEEK_JOB_COUNT_SUCCESS";
export const GET_DASHBOARD_WEEK_JOB_COUNT_FAIL = "GET_DASHBOARD_WEEK_JOB_COUNT_FAIL";

export const GET_DASHBOARD_INVOICE_COUNT = "GET_DASHBOARD_INVOICE_COUNT";
export const GET_DASHBOARD_INVOICE_COUNT_SUCCESS = "GET_DASHBOARD_INVOICE_COUNT_SUCCESS";
export const GET_DASHBOARD_INVOICE_COUNT_FAIL = "GET_DASHBOARD_INVOICE_COUNT_FAIL";

export const GET_DASHBOARD_WEEK_INVOICE_COUNT = "GET_DASHBOARD_WEEK_INVOICE_COUNT";
export const GET_DASHBOARD_WEEK_INVOICE_COUNT_SUCCESS = "GET_DASHBOARD_WEEK_INVOICE_COUNT_SUCCESS";
export const GET_DASHBOARD_WEEK_INVOICE_COUNT_FAIL = "GET_DASHBOARD_WEEK_INVOICE_COUNT_FAIL";

export const GET_DASHBOARD_PAYMENT_COUNT = "GET_DASHBOARD_PAYMENT_COUNT";
export const GET_DASHBOARD_PAYMENT_COUNT_SUCCESS = "GET_DASHBOARD_PAYMENT_COUNT_SUCCESS";
export const GET_DASHBOARD_PAYMENT_COUNT_FAIL = "GET_DASHBOARD_PAYMENT_COUNT_FAIL";

export const GET_DASHBOARD_WEEK_PAYMENT_COUNT = "GET_DASHBOARD_WEEK_PAYMENT_COUNT";
export const GET_DASHBOARD_WEEK_PAYMENT_COUNT_SUCCESS = "GET_DASHBOARD_WEEK_PAYMENT_COUNT_SUCCESS";
export const GET_DASHBOARD_WEEK_PAYMENT_COUNT_FAIL = "GET_DASHBOARD_WEEK_PAYMENT_COUNT_FAIL";

export const UPDATE_CHECK_IN_TIME = "UPDATE_CHECK_IN_TIME";
export const UPDATE_CHECK_IN_TIME_SUCCESS = "UPDATE_CHECK_IN_TIME_SUCCESS";
export const UPDATE_CHECK_IN_TIME_FAIL = "UPDATE_CHECK_IN_TIME_FAIL";