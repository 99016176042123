import TechnicianDetail from "./technicianDetail";

let TechnicianList = ({
    allTechnicianList, 
    activeTechnician, 
    setActiveTechnician,
    setActiveUserName,
    currentDay
}) => {
    
    const allTechList = allTechnicianList.filter((c) => c.role_id !== 10);
    const allAdminList = allTechnicianList.filter((c) => c.role_id === 10);

    return (
    <>
        <div className="col-md-4 workshop-list">

            {/* All Technician List */}
            <p className="clocking-title mb-3" >Workshop</p>
            <div className="row mb-5">
                {allTechList && allTechList.map((techician, i) => (
                    <TechnicianDetail 
                        techician={techician}
                        activeTechnician={activeTechnician}
                        setActiveTechnician={setActiveTechnician}
                        setActiveUserName={setActiveUserName}
                        currentDay={currentDay}
                        listType="tech"
                        key={"tech_"+i}
                    />
                ))}
            </div>

            {/* All Admin List */}
            <p className="clocking-title mb-3 full-border" >Admin</p>
            <div className="row mb-5">
                {allAdminList && allAdminList.map((techician, i) => (
                    <TechnicianDetail 
                        techician={techician}
                        activeTechnician={activeTechnician}
                        setActiveTechnician={setActiveTechnician}
                        setActiveUserName={setActiveUserName}
                        currentDay={currentDay}
                        listType="admin"
                        key={"admin_"+i}
                    />
                ))}
            </div>
        </div>
    </>
    );
};

export default TechnicianList