import * as actions from "../actionType";

const jobReportState = {
  jobRevenueReport: {
    chartTable: [],
    chartData: null,
    error: false,
    loading: false,
  },
  jobForecastReport: {
    forecastTable: [],
    forecastChart: null,
    error: false,
    loading: false,
  },
  jobStatsReport: {
    conversionChart: null,
    categoryChart: null,
    statsChart: null,
    error: false,
    loading: false,
  },
  
};

export const JobReportsReducer = (state = jobReportState, action) => {

  switch (action.type) {
    case actions.JOB_REPORT_TABLE:
      return {
        ...state,
        jobRevenueReport: {
          ...state.jobRevenueReport,
          loading: true,
          error: false,
        },
      };
    case actions.JOB_REPORT_TABLE_SUCCESS:
      return {
        ...state,
        jobRevenueReport: {
          ...state.jobRevenueReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.JOB_REPORT_TABLE_FAIL:
      return {
        ...state,
        jobRevenueReport: {
          ...state.jobRevenueReport,
          loading: false,
          error: true,
        },
      };


    case actions.JOB_REPORT_FORECAST_TABLE:
      return {
        ...state,
        jobForecastReport: {
          ...state.jobForecastReport,
          loading: true,
          error: false,
        },
      };
    case actions.JOB_REPORT_FORECAST_TABLE_SUCCESS:
      return {
        ...state,
        jobForecastReport: {
          ...state.jobForecastReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.JOB_REPORT_FORECAST_TABLE_FAIL:
      return {
        ...state,
        jobForecastReport: {
          ...state.jobForecastReport,
          loading: false,
          error: true,
        },
      };

    case actions.JOB_REPORT_STATS:
      return {
        ...state,
        jobStatsReport: {
          ...state.jobStatsReport,
          loading: true,
          error: false,
        },
      };
    case actions.JOB_REPORT_STATS_SUCCESS:
      return {
        ...state,
        jobStatsReport: {
          ...state.jobStatsReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.JOB_REPORT_STATS_FAIL:
      return {
        ...state,
        jobStatsReport: {
          ...state.jobStatsReport,
          loading: false,
          error: true,
        },
      };

      
    default:
      return state;
  }
};
