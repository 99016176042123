import { Route } from "react-router-dom";
import Wrapper from "views/common/navigation/components/Wrapper";
import HQWrapper from "views/hq/layout/wrapper";
export function withWrapperRoutes(routes) {
  return routes.map((route) => {
    return (
      <Route
        key={route.name}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={
          <Wrapper>
            <route.element />
          </Wrapper>
        }
      />
    );
  });
}

export function withHQWrapperRoutes(routes) {
  return routes.map((route) => {
    return (
      <Route
        key={route.name}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={
          <HQWrapper>
            <route.element />
          </HQWrapper>
        }
      />
    );
  });
}

export function withoutWrapperRoutes(routes) {
  return routes.map((route) => {
    return (
      <Route
        key={route.name}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={<route.element />}
      />
    );
  });
}
