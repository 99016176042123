import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { useDispatch, useSelector } from "react-redux";
import Resources from './Resources';
import { useDataProvider } from "utils/DataContext";
import {
    GET_TECHNICIAN_HOUR,
    GET_TECHNICIAN_SCHEDULE,
    GET_WORKSHOP_BAYS,
    CHECK_AVAILABLE_HOURS,
    GET_OPEN_REQUEST_JOB,
    UPDATE_JOB_ACTIVITY
} from "../../../store/scheduler/actionTypes";

import { GET_STATUS_COLOR, GET_LABOUR_CHARGES_LIST } from "../../../store/commonApiStore/actionTypes";
import { UPDATE_JOB_TIMER, GET_JOB_BOOKED_DETAILS } from '../../../store/jobDetails/actionTypes';
import { SAVE_JOB_LABOUR } from '../../../store/jobLabour/actionTypes';
import {
    utcToTimezone,
    convertToHours,
    timeDiffMinute,
    timeZoneToUTC,
    convertToMinute,
    convertToHoursMins
} from "../../../utils/timeZoneConvert";
import { unserializeArr } from "../../../utils/unserialize";
import moment from 'moment';
import CreateJob from './CreateJob';
import JobTicket from './JobTicket';
import LoaderImage from "../../../assets/images/page-loader.svg";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Reschedule from './Reschedule';
import { GET_UNASSIGNED_JOBS } from 'store/unassignedJobs/actionTypes';
import { showSuccess, showError } from "../../../utils/alertMessage";
import { getCustomerAndVehicleDetailsSuccess } from 'store/unassignedJobs/actions';
import NoEmployeeWarning from './NoEmployeeWarning';
import { getEstimateTimeSlot, getUserProfile } from 'helpers/backendHelper';
import PaymentComplete from 'views/invoices/components/PaymentComplete/PaymentComplete';

const Calendar = ({ clickDateMonth, clickDate, monthView, updateMonthView }) => {

    const [calendarType, setCalendarType] = useState("day");
    const calendarRef = useRef();
    const { data, setData } = useDataProvider(); // Create custome hook for go to perticular date

    // Get current calendar api
    const getCalendarApi = () => {
        const { current } = calendarRef;
        return current?.getApi();
    };

    useEffect(() => {
        loadEmployeeList();
    }, [])

    async function loadEmployeeList() {
        const request1 = {
            get: "all",
            conditions: {
                workshop_id: workshop_id,
                "role_id IN": [3, 7, 8, 9],
                "is_deleted": 0,
            },
            order: {
                id: "DESC",
            },
            fields: ["id", "email"],
        };

        const resp = await getUserProfile({ payload: request1 });

        if (resp?.data?.Users?.length === 0) {
            setLoading(false);
            setNoEmployeeWarning(true);
        }
    }

    // Go to specific date when create job from dashboard
    useEffect(() => {
        const calendarApi = getCalendarApi();
        if (data !== null && data !== undefined) {
            calendarApi.gotoDate(data);
        }
        return () => {
            setData(null);
        }
    }, [data])
    // Update view for sidebar menu
    useEffect(() => {
        const calendarApiView = getCalendarApi();
        if ((window.location.pathname.split("/").pop() === "scheduler_week")) {
            calendarApiView.changeView("resourceTimelineWeek")
        } else if ((window.location.pathname.split("/").pop() === "scheduler_month")) {
            calendarApiView.changeView("dayGridMonth")
        } else {
            calendarApiView.changeView("resourceTimelineDay")
        }
    }, [])

    const weekendsVisible = true;
    const status = [{ status: "Pending", name: "Pending" }, { status: "Reschedule", name: "Rescheduled" }, { status: "In Progress", name: "InProgress" }, { status: "On Hold", name: "OnHold" }, { status: "Awaiting Parts", name: "WaitingForParts" }, { status: "Invoice Ready", name: "ReadyForInvoice" }];

    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);
    let user_id = window.atob(storedData.user_id);
    let timezoneLable = window.atob(storedData.timezone_lable);

    let technician_timeslot = window.atob(storedData.technician_timeslot);
    let schedule_details = window.atob(storedData.schedule_details);

    const [monthJobCount, setMonthJobCount] = useState([]);
    const [countJob, setCountJob] = useState(0);
    const [billableHrs, setBillableHrs] = useState("00:00");
    const [availableHrs, setAvailableHrs] = useState("00:00");
    const [bookedHrs, setBookedHrs] = useState("00:00");

    const [billableHrsClass, setBillableHrsClass] = useState("");
    const [availableHrsClass, setAvailableHrsClass] = useState("");
    const [bookedHrsClass, setBookedHrsClass] = useState("");

    const [technicianListData, setTechnicianListData] = useState([]);
    const [estimateTimeSlot, setEstimateTimeSlot] = useState([]);
    const [startTime, setStartTime] = useState("08:00:00");
    const [workshopStartTime, setWorkshopStartTime] = useState("08:00:00");
    const [endTime, setEndTime] = useState("12:00:00");
    const [slotDuration, setSlotDuration] = useState("01:00:00");
    const [fromDate, setFromDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState();
    const [currentDay, setCurrentDay] = useState(new Date().getDay());
    const [workshopWorkingDay, setWorkshopWorkingDay] = useState([]);
    const [workshopOffDay, setWorkshopOffDay] = useState();
    const [dateRange, setDateRange] = useState([]);
    const [scheduleArr, setScheduleArr] = useState([]);
    const [currentJobData, setCurrentJobData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showMonthViewPopup, setMonthViewPopup] = useState(false);
    const [jobCreated, setJobCreated] = useState(false);

    const setCloseModal = () => {
        setMonthViewPopup(false);
        setShowModal(false)
        setNewAvailableHours(false)
    };
    const [calendarView, setCalendarView] = useState("day");
    const [showTag, setShowTag] = useState(false);
    const [startJobTime, setStartJobTime] = useState();
    const [endJobTime, setEndJobTime] = useState();
    const [technicianName, setTechnicianName] = useState();
    const [technicianId, setTechnicianId] = useState();
    const [technicianType, setTechnicianType] = useState('technician');
    const [bayArray, setBayArray] = useState({});

    // const [countEvent, setCount] = useState(count)
    const [loading, setLoading] = useState(false)
    const [dispatchToCreate, setDispatchToCreate] = useState(false)
    const [draggedEvent, setDraggedEvent] = useState(false)

    const dispatch = useDispatch(); // Dispatch variable
    const [slotLimit, setSlotLimitShow] = useState(false);
    const handleSlotLimitClose = () => setSlotLimitShow(false);

    const [updateCalendar, setUpdateCalendar] = useState(false);
    const [updateSchedule, setUpdateSchedule] = useState(false);
    const [baysUpdateSchedule, setBaysUpdateSchedule] = useState(false);
    const [baysJobUpdate, setBaysJobUpdate] = useState(false);
    const handleUnassignedJobData = (flag) => {
        setJobCreated(flag);
    }

    const [noEmployeeWarning, setNoEmployeeWarning] = useState(false);

    useEffect(() => {
        if (updateMonthView === true) {
            getJobList(moment().format("YYYY-MM-DD"))
            setUpdateSchedule(!updateSchedule);
        }
    }, [updateMonthView])

    // useEffect(() => {
    //     if(updateSchedule === true){
    //         setUpdateSchedule(false);
    //     }
    // },[updateSchedule])

    useEffect(() => {
        const calendarApi = getCalendarApi();
        calendarApi.removeAllEvents()
        setUpdateSchedule(!updateSchedule);
        if (calendarView === "month") {
            handleUnassignedJobData(!jobCreated)
        }
    }, [updateCalendar])

    let workshopWorkingDayArr = [];
    let workshopOffDayArr = [];
    let jobDataArr = [];

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_WORKSHOP_BAYS,
                payload: {
                    get: 'all',
                    conditions: {
                        workshop_id: workshop_id,
                        status: 1,
                        display_in_schedule: 1
                    },
                    fields: ['id', 'workshop_id', 'name', 'location']
                }
            });
        }
        fetchData()
            .catch(console.error)
    }, [dispatch, workshop_id]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_STATUS_COLOR,
                payload: {
                    get: "all",
                    conditions: {
                        workshop_id: workshop_id,
                        status: 1,
                    },
                    fields: ["name", "color"],
                },
            })
            dispatch({
                type: GET_LABOUR_CHARGES_LIST,
                payload: {
                    "get": "all",
                    "conditions": {
                        "workshop_id": workshop_id,
                        "labour_code !=": "",
                        "is_default": "1",
                        "is_deleted": 0
                    }
                }
            })
        }
        fetchData().catch(console.error);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // get technician list,bays list and technician and bays hours calculation
            dispatch({
                type: GET_TECHNICIAN_HOUR,
                payload: {
                    workshop_id: workshop_id,
                    from_date: fromDate + " " + startTime
                }
            });
        }
        fetchData()
            .catch(console.error)
    }, [fromDate, startTime, updateSchedule, baysUpdateSchedule])

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_JOB_BOOKED_DETAILS,
                payload: {
                    get: 'all',
                    conditions: {
                        'JobSchedules.workshop_id': workshop_id,
                        'JobSchedules.user_id': 0,
                        'JobSchedules.bay_id !=': 0,
                        'JobSchedules.from_date >=': fromDate,
                        'JobSchedules.to_date <': toDate,
                        'JobSchedules.is_copid_job': 0,
                        'JobSchedules.is_no_show': 0
                    },
                    contain: {
                        Vehicles: {
                            fields: [
                                'id', 'workshop_id', 'user_id', 'make', 'model', 'year', 'rego', 'vin_number'
                            ]
                        },
                        "Customers.CustDetails": {
                            conditions: {
                                'CustDetails.workshop_id': workshop_id
                            }
                        },
                        WorkshopDepartmentsAccountsDetails: {
                            fields: [
                                'id', 'name', 'job_type_parent_id', 'workshop_id'
                            ]
                        },
                        JobBookingTypes: {
                            fields: [
                                'id', 'name', 'parent_id', 'workshop_id'
                            ]
                        },
                        JobsParts: {
                            conditions: {
                                "JobsParts.workshop_id": workshop_id,
                                "JobsParts.is_deleted": 0,
                            },
                            fields: [
                                "id",
                                "job_schedule_id"
                            ],
                        },
                    },
                    fields: [
                        'id', 'user_id', 'rego', 'workshop_id', 'vehicle_id', 'activity_schedule_id',
                        'activity_job_id', 'customer_quotes_id', 'job_number', 'customer_id',
                        'inspections_id', 'insurance_company_id', 'job_department_id',
                        'job_type_parent_id', 'job_stage', 'job_status', 'from_date',
                        'from_time', 'to_date', 'to_time', 'pickup_time', 'pickup_time_only',
                        'description', 'job_type', 'bay_id', 'job_number', 'timer_status',
                        'employees_details_id', 'created_by', 'is_copid_job', 'is_copy_job',
                        'job_estimate_time', 'duration',
                        'CustDetails.id',
                        'CustDetails.user_id',
                        'CustDetails.firstname',
                        'CustDetails.lastname',
                        'CustDetails.user_id',
                        'CustDetails.customer_app',
                        'CustDetails.phone_number',
                        'CustDetails.firstname',
                        'CustDetails.lastname',
                        'CustDetails.type',
                        'CustDetails.company_name',
                        'Customers.id',
                        'Customers.email'
                    ]
                }
            })
        }
        fetchData()
            .catch(console.error)
    }, [fromDate, toDate, updateSchedule])

    useEffect(() => {
        const fetchData = async () => {
            let jobsCondition = {};

            if (calendarType == "week" || calendarType == "month") {
                jobsCondition = {
                    'JobSchedules.from_date >=': fromDate,
                    'JobSchedules.to_date <': toDate,
                    'JobSchedules.is_copid_job': 0,
                    'JobSchedules.is_no_show': 0
                }
            } else {
                jobsCondition = {
                    "OR": [
                        {
                            'JobSchedules.from_date <=': fromDate,
                            'JobSchedules.to_date >=': fromDate,
                        },
                        {
                            'JobSchedules.from_date': fromDate,
                            'JobSchedules.to_date <': toDate,
                        }
                    ],
                    // 'JobSchedules.from_date >=': fromDate,
                    // 'JobSchedules.to_date <': toDate,
                    'JobSchedules.is_copid_job': 0,
                    'JobSchedules.is_no_show': 0
                }
            }


            const payloadRequest = {
                get: 'all',
                conditions: {
                    'Users.workshop_id': workshop_id,
                    'role_id IN': [3, 7, 8, 9],
                    is_deleted: 0
                },
                contain: {
                    EmployeesDetails: {
                        fields: [
                            'EmployeesDetails.id',
                            'EmployeesDetails.user_id',
                            'EmployeesDetails.shift_working_day'
                        ]
                    },
                    JobSchedules: {
                        conditions: jobsCondition,
                        // conditions: {
                        //     'JobSchedules.from_date >=': fromDate,
                        //     'JobSchedules.to_date <': toDate,
                        //     'JobSchedules.is_copid_job': 0,
                        //     'JobSchedules.is_no_show': 0
                        // },
                        fields: [
                            'id',
                            'user_id',
                            'rego',
                            'workshop_id',
                            'customer_id',
                            'job_status',
                            'job_stage',
                            'activity_schedule_id',
                            'activity_job_id',
                            'customer_quotes_id',
                            'vehicle_id',
                            'from_date',
                            'to_date',
                            'from_time',
                            'to_time',
                            'pickup_time',
                            'is_break_time',
                            'employees_details_id',
                            'job_department_id',
                            'job_number',
                            'job_type',
                            'pickup_time_only',
                            'is_copid_job',
                            'is_copy_job',
                            'is_multiple_tech_job',
                            'description',
                            'job_estimate_time',
                            'duration',
                        ]
                    },
                    'JobSchedules.JobsParts': {
                        conditions: {
                            "JobsParts.workshop_id": workshop_id,
                            "JobsParts.is_deleted": 0,
                        },
                        fields: [
                            "id",
                            "job_schedule_id"
                        ],
                    },
                    'JobSchedules.WorkshopDepartmentsAccountsDetails': {
                        fields: ['id', 'workshop_id', 'name', 'job_type_parent_id']
                    },
                    'JobSchedules.VehiclesB': {
                        fields: ['id', 'rego', 'vin_number', 'make', 'model', 'year', 'odometer_reading']
                    },
                    'JobSchedules.VehiclesB.Customers': {
                        fields: ['id']
                    },
                    'JobSchedules.VehiclesB.Customers.CustomersDetails': {
                        fields: [
                            'id',
                            'user_id',
                            'firstname',
                            'lastname',
                            'CustomersDetails.user_id',
                            'CustomersDetails.customer_app',
                            'CustomersDetails.mobile_number',
                            'CustomersDetails.mobile_code',
                            'CustomersDetails.firstname',
                            'CustomersDetails.lastname',
                            'CustomersDetails.type',
                            'CustomersDetails.company_name'
                        ]
                    },
                    'JobSchedules.Customers': {
                        fields: ['id', 'email']
                    },
                    'JobSchedules.Customers.CustDetails': {
                        conditions: {
                            'CustDetails.workshop_id': workshop_id
                        },
                        fields: [
                            'CustDetails.id',
                            'CustDetails.user_id',
                            'CustDetails.firstname',
                            'CustDetails.lastname',
                            'CustDetails.user_id',
                            'CustDetails.customer_app',
                            'CustDetails.mobile_number',
                            'CustDetails.mobile_code',
                            'CustDetails.type',
                            'CustDetails.company_name'
                        ]
                    },
                    'JobSchedules.JobBookingTypes': {
                        fields: ['id', 'parent_id', 'name', 'workshop_id']
                    },
                    'JobSchedules.ActivitySchedule': {
                        fields: ['id', 'name', 'workshop_id','description']
                    },
                    'JobSchedules.ActivitySchedule.ActivityMaster': {
                        fields: ['id', 'name', 'type', 'workshop_id']
                    }

                },
                fields: [
                    'id', 'workshop_id', 'firstname', 'lastname', 'email', 'user_profile', 'phone_number'
                ]
            };

            dispatch({
                type: GET_TECHNICIAN_SCHEDULE,
                payload: payloadRequest
            });
        }
        fetchData()
            .catch(console.error)
    }, [fromDate, toDate, updateSchedule])

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_OPEN_REQUEST_JOB,
                payload: {
                    get: 'all',
                    conditions: {
                        'JobSchedules.workshop_id': workshop_id,
                        'JobSchedules.user_id': 0,
                        'JobSchedules.bay_id': 0,
                        'JobSchedules.from_date >=': fromDate,
                        'JobSchedules.to_date <': toDate,
                        'JobSchedules.job_status !=': 'Unassigned',
                        'JobSchedules.is_copid_job': 0,
                        'JobSchedules.is_no_show': 0
                    },
                    contain: {
                        Vehicles: {
                            fields: [
                                'id', 'workshop_id', 'user_id', 'make', 'model', 'year', 'rego', 'vin_number'
                            ]
                        },
                        WorkshopDepartmentsAccountsDetails: {
                            fields: [
                                'id', 'name', 'job_type_parent_id', 'workshop_id'
                            ]
                        },
                        JobBookingTypes: {
                            fields: [
                                'id', 'name', 'parent_id', 'workshop_id'
                            ]
                        },
                        'Customers.CustDetails': {
                            conditions: {
                                'CustDetails.workshop_id': workshop_id
                            }
                        },
                        JobsParts: {
                            conditions: {
                                "JobsParts.workshop_id": workshop_id,
                                "JobsParts.is_deleted": 0,
                            },
                            fields: [
                                "id",
                                "job_schedule_id"
                            ],
                        },
                    },
                    fields: [
                        'id', 'user_id', 'rego', 'workshop_id', 'vehicle_id', 'activity_schedule_id',
                        'activity_job_id', 'customer_quotes_id', 'job_number', 'customer_id',
                        'inspections_id', 'insurance_company_id', 'job_department_id',
                        'job_type_parent_id', 'job_stage', 'job_status', 'from_date',
                        'from_time', 'to_date', 'to_time', 'pickup_time', 'pickup_time_only',
                        'description', 'job_type', 'bay_id', 'job_number', 'timer_status',
                        'employees_details_id', 'created_by', 'is_copid_job', 'is_copy_job',
                        'job_estimate_time', 'duration',
                        "CustDetails.id",
                        "CustDetails.user_id",
                        "CustDetails.firstname",
                        "CustDetails.lastname",
                        "CustDetails.mobile_number",
                        "CustDetails.mobile_code",
                        "CustDetails.customer_app",
                        "CustDetails.type",
                        "CustDetails.street_address",
                        "CustDetails.suburb",
                        "CustDetails.postcode",
                        "CustDetails.company_name",
                        "Customers.id", "Customers.email",
                        'is_multiple_tech_job'
                    ]
                }
            })
        }
        fetchData()
            .catch(console.error)
    }, [fromDate, toDate, updateSchedule])

    const schedulerData = useSelector((state) => state.SchedulerReducer);
    const bayJobData = useSelector((state) => state.JobDetails.jobBookedData);

    const jobUpdated = useSelector((state) => state.JobDetails.jobTimerData);
    const JobLabourData = useSelector((state) => state.JobLabourReducer.jobLabourList);
    const { statusColor, labourChargesList } = useSelector((state) => state.CommonApiStoreReducer);
    const workshopBaysDetails = schedulerData.workshopBaysDetails;
    const technicianHourData = schedulerData.technicianHourData;
    const jobsData = schedulerData.technicianData;

    const availableHours = schedulerData.checkAvailableHours;
    const openRequest = schedulerData.getOpenRequest;
    const updateJobActivity = schedulerData.updateJobActivity;
    const [updateActivityFlag, setUpdateActivityFlag] = useState(false);
    const [newAvailableHours, setNewAvailableHours] = useState(false);

    useEffect(() => {
        if (availableHours) {
            setNewAvailableHours(true)
        } else {
            setNewAvailableHours(false)
        }
    }, [availableHours])

    useEffect(() => {
        if (updateJobActivity !== null && updateJobActivity !== undefined && updateActivityFlag === true) {
            if (updateJobActivity?.code == 200) {
                showSuccess(updateJobActivity?.message);
                setUpdateActivityFlag(false)
            } else if (updateJobActivity?.code == 400) {
                showError(updateJobActivity?.message);
                setUpdateActivityFlag(false)
            }
            setUpdateSchedule(!updateSchedule);
        }
    }, [updateJobActivity])

    useEffect(() => {
        setCurrentDay(new Date(fromDate).getDay())
    }, [fromDate])

    const [businessHours, setBusinessHours] = useState([])

    useEffect(() => {
        if (schedule_details != null) {
            // TODO : Please fix this code (scheduleDetails declared 2 times)
            let scheduleDetails = unserializeArr(schedule_details);

            setScheduleArr(scheduleDetails);
            var timeSlot = technician_timeslot;
            let bussinessHoursArr = [];

            let startTimeArr = [];
            let endTimeArr = [];

            scheduleDetails.map((schedule) => {
                if (schedule.status === "0" || schedule.status === 0) {
                    workshopOffDayArr.push(schedule.day);
                } else {
                    startTimeArr.push(utcToTimezone(schedule.open_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss'))
                    endTimeArr.push(utcToTimezone(schedule.close_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss'))
                    let business_hours = {
                        daysOfWeek: [schedule.day],
                        startTime: utcToTimezone(schedule.open_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss'),
                        endTime: utcToTimezone(schedule.close_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss'),
                    }
                    bussinessHoursArr.push(business_hours);
                    workshopWorkingDayArr.push(schedule.day);
                }
            })

            const datesStart = startTimeArr.map(time => new Date(`1970-01-01T${time}`));
            let minStartTime = new Date(Math.min.apply(null, datesStart)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', seconds: '2-digit' });

            minStartTime = moment(minStartTime,["hh:mm A"]).format("HH:mm:ss");
            const maxStartTime = new Date(Math.max.apply(null, datesStart)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', seconds: '2-digit' });

            const datesEnd = endTimeArr.map(time => new Date(`1970-01-01T${time}`));

            const minEndTime = new Date(Math.min.apply(null, datesEnd)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', seconds: '2-digit' });
            let maxEndTime = new Date(Math.max.apply(null, datesEnd)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', seconds: '2-digit' });
            maxEndTime = moment(maxEndTime,["hh:mm A"]).format("HH:mm:ss");

            setBusinessHours(bussinessHoursArr);

            if (calendarType == "day") {
                if (scheduleDetails[currentDay]['open_time'] !== '' && scheduleDetails[currentDay]['close_time'] !== '' && scheduleDetails[currentDay]['status'] !== 0 && scheduleDetails[currentDay]['status'] !== '0') {
                    setStartTime(utcToTimezone(scheduleDetails[currentDay]['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss'));
                    setWorkshopStartTime(scheduleDetails[currentDay]['open_time']);
                    setEndTime(utcToTimezone(scheduleDetails[currentDay]['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss'));
                    if (endTime === "00:00:00") {
                        setEndTime("24:00:00");
                    }
                }
            } else if (calendarType == "week") {
                setStartTime(minStartTime);
                setWorkshopStartTime(minStartTime);
                setEndTime(maxEndTime);
            }

            setSlotDuration(convertToHours(timeSlot));
            setWorkshopOffDay(workshopOffDayArr);
            setWorkshopWorkingDay(workshopWorkingDayArr);
        }
    }, [schedule_details, currentDay])

    useEffect(() => {
        if (technicianHourData != null) {
            setTechnicianListData(technicianHourData);
        }
    }, [technicianHourData])

    useEffect(() => {
        setLoading(true)
        var defaultKey = 0;
        let jobDetailsArr = [];
        var valueToPush = {};
        var valueToPushSec = {};
        var employeeWorkingDay = {};
        var totalAvailableEmployee = 0;
        var totalJobMinutes = 0;
        var assigneToPush = {};
        var openToPush = {};
        var defaultKeySec = 0;
        var workshopTotalMinutes = timeDiffMinute(startTime, endTime);
        var dateMonthArr = [];
        let monthViewCountArr = [];
        var countJob = 0;
        let totalWorkingDays = 0;

        if (jobsData !== null && jobsData !== undefined) {

            setShowTag(true)
            setLoading(false)
            // setCount(0);
            // let workingOffDays = [];
            let workingDays = workshopWorkingDay;
            let jobIdArr = [];

            jobsData.map((job, index) => {

                let workingOffDays = [];
                workingOffDays.push(workshopOffDay);
                // workingOffDays = workshopWorkingDay;
                defaultKey = index + 1;
                employeeWorkingDay = {};
                if (job.employees_details[0] !== "" && job.employees_details[0] !== undefined && job.employees_details[0] !== null) {
                    if (job.employees_details[0].shift_working_day) {
                        employeeWorkingDay = unserializeArr(job.employees_details[0].shift_working_day);
                    }
                }

                employeeWorkingDay = employeeWorkingDay.map(i => Number(i));
                if (dateRange.length !== 2 && dateRange.length < 9) {
                    totalWorkingDays = +totalWorkingDays + employeeWorkingDay.length;
                }

                let workingOffDaysData = [];
                let employeeOffDayArr = [];

                if (dateRange.length !== 2 && dateRange.length < 9) {
                    for (var m = 0; m <= 6; m++) {
                        if (employeeWorkingDay.length > 0) {
                            if (!employeeWorkingDay.includes(m)) {
                                employeeOffDayArr.push(m);
                            }
                        }
                    }
                    workingOffDaysData = workshopOffDay;
                } else {
                    if (!workshopOffDay.includes(currentDay)) {
                        for (var m = 0; m <= 6; m++) {
                            if (employeeWorkingDay.length > 0) {
                                if (!employeeWorkingDay.includes(m)) {
                                    employeeOffDayArr.push(m);
                                }
                            }
                        }
                        workingOffDaysData = employeeOffDayArr;
                    } else {
                        //workingOffDays for off days
                        // if(workshopOffDay?.[0]){
                        //     workingOffDaysData = workshopOffDay?.[0];
                        // }else{
                        //     workingOffDaysData = workshopOffDay;
                        // }
                        workingOffDaysData = workshopOffDay;
                    }
                }


                // For working days and changes timeslots every day in scheduler week view
                if (dateRange.length !== 2 && dateRange.length < 9) {
                    dateRange.map((dayDate, dateIndex) => {
                        if (dateIndex < 7) {
                            if (workshopWorkingDay.includes(new Date(dayDate).getDay())) {
                                if (employeeWorkingDay.length > 0) {
                                    if (employeeWorkingDay.includes(new Date(dayDate).getDay())) {
                                        // var scheduleData = scheduleArr[new Date(dayDate).getDay()];
                                        // if(!jobIdArr.includes(job.id)){
                                        //     jobIdArr.push(job.id);
                                        //     // var startScheduleTime = utcToTimezone(scheduleData['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                                        //     // var endScheduleTime = utcToTimezone(scheduleData['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                                        //     // defaultKeySec = dateIndex + defaultKey + 1;
                                        //     valueToPushSec = {};
                                        //     valueToPushSec['id'] = 'off' + dateIndex + defaultKeySec;
                                        //     valueToPushSec['resourceId'] = job.id;
                                        //     valueToPushSec['start'] = dateRange[0] + "T" + startTime;
                                        //     valueToPushSec['end'] = dateRange[1] + "T" + endTime;
                                        //     valueToPushSec['daysOfWeek'] = employeeWorkingDay; // workshop working days calculation left
                                        //     valueToPushSec['title'] = 'Off'
                                        //     valueToPushSec['tags'] = [];
                                        //     valueToPushSec['overlap'] = false;
                                        //     valueToPushSec['eventDisplay'] = 'background';
                                        //     valueToPushSec['allDaySlot'] = '2';
                                        //     valueToPushSec['color'] = '#e05151';
                                        //     valueToPushSec['className'] = ['noHover'];
                                        //     valueToPushSec['editable'] = false;
                                        //     jobDetailsArr.push(valueToPushSec);
                                        //     totalAvailableEmployee = totalAvailableEmployee + 1;
                                        // }
                                    } else {
                                        if (!jobIdArr.includes(job.id)) {
                                            jobIdArr.push(job.id);
                                            valueToPushSec = {};
                                            valueToPushSec['id'] = dateIndex + defaultKey + 1;
                                            valueToPushSec['resourceId'] = job.id;
                                            valueToPushSec['start'] = dateRange[0] + "T" + startTime;
                                            valueToPushSec['end'] = dateRange[1] + "T" + endTime;
                                            valueToPushSec['daysOfWeek'] = employeeOffDayArr; // workshop working days calculation left
                                            valueToPushSec['title'] = 'Off'
                                            valueToPushSec['tags'] = [];
                                            valueToPushSec['overlap'] = false;
                                            valueToPushSec['eventDisplay'] = 'background';
                                            valueToPushSec['allDaySlot'] = '2';
                                            // valueToPushSec['color'] = '#e05151';
                                            valueToPushSec['className'] = ['noHover'];
                                            valueToPushSec['editable'] = false;
                                            jobDetailsArr.push(valueToPushSec);
                                            totalAvailableEmployee = totalAvailableEmployee + 1;
                                        }
                                    }
                                }
                            }
                            // else{
                            // defaultKey = dateIndex + defaultKey + 1;
                            // console.log(defaultKeySec,'defaultKeySec in else')

                            // if(!jobIdArr.includes(job.id)){
                            //     jobIdArr.push(job.id);

                            //     valueToPush = {};
                            //     valueToPush['id'] = dateIndex + defaultKey + 1;
                            //     valueToPush['resourceId'] = job.id;
                            //     // valueToPush['eventColor'] = "#ffff";
                            //     valueToPush['start'] = dateRange[0] + "T" + startTime;
                            //     valueToPush['end'] = dateRange[1] + "T" + endTime;
                            //     valueToPush['daysOfWeek'] = workingOffDaysData; // workshop working days calculation left
                            //     valueToPush['title'] = 'Off'
                            //     valueToPush['tags'] = [];
                            //     valueToPush['overlap'] = false;
                            //     valueToPush['eventDisplay'] = 'background';
                            //     valueToPush['allDaySlot'] = '2';
                            //     valueToPush['className'] = ['noHover'];
                            //     valueToPush['editable'] = false;
                            //     jobDetailsArr.push(valueToPush);
                            //     totalAvailableEmployee = totalAvailableEmployee + 1;
                            // }
                            // }
                        }
                    })
                } else {

                    valueToPush = {};
                    valueToPush['id'] = defaultKey;
                    valueToPush['resourceId'] = job.id;
                    valueToPush['eventColor'] = "#fd1010";
                    valueToPush['start'] = dateRange[0] + "T" + startTime;
                    valueToPush['end'] = dateRange[1] + "T" + endTime;
                    valueToPush['daysOfWeek'] = workingOffDaysData; // workshop working days calculation left
                    valueToPush['title'] = 'Off'
                    valueToPush['tags'] = [];
                    valueToPush['overlap'] = false;
                    valueToPush['eventDisplay'] = 'background';
                    valueToPush['allDaySlot'] = '2';
                    valueToPush['className'] = ['noHover'];
                    valueToPush['editable'] = false;
                    jobDetailsArr.push(valueToPush);
                    if (dateRange.length != 2) {
                        dateRange.map((dayDate, dateIndex) => {
                            if (employeeWorkingDay.length > 0) {
                                if (employeeWorkingDay.indexOf(new Date(dayDate).getDay()) > -1) {
                                    totalAvailableEmployee = totalAvailableEmployee + 1;
                                }
                            }
                        })
                    } else {
                        if (employeeWorkingDay.length > 0) {
                            if (employeeWorkingDay.indexOf(new Date(fromDate).getDay()) > -1) {
                                totalAvailableEmployee = totalAvailableEmployee + 1;
                            }
                        }
                    }
                }

                // For job details array
                if (job.job_schedules.length !== 0) {
                    job.job_schedules.map((jobArr, jobIndex) => {
                        var jobStartDate = moment(new Date(jobArr.from_date)).format("YYYY-MM-DD");
                        var jobEndDate = moment(new Date(jobArr.to_date)).format("YYYY-MM-DD");
                        var jobStartTime = utcToTimezone(jobArr.from_time, timezoneLable, "", 'HH:mm:ss');
                        var jobEndTime = utcToTimezone(jobArr.to_time, timezoneLable, "", 'HH:mm:ss');
                        // console.log('id',jobArr.id)
                        // console.log('fromDate',fromDate)
                        // console.log('fromDatecompare',moment(jobArr.from_date).format("YYYY-MM-DD"))

                        if (dateRange.length === 2) {
                            if (fromDate !== moment(jobArr.from_date).format("YYYY-MM-DD")) {
                                jobStartTime = startTime;
                            } else {
                                jobStartTime = utcToTimezone(
                                    jobArr.from_time,
                                    timezoneLable,
                                    "",
                                    "HH:mm:ss"
                                );
                            }

                            if (fromDate !== moment(jobArr.to_date).format("YYYY-MM-DD")) {
                                jobEndTime = endTime;
                            } else {
                                jobEndTime = utcToTimezone(
                                    jobArr.to_time,
                                    timezoneLable,
                                    "",
                                    "HH:mm:ss"
                                );
                            }
                        }
                        // console.log('id',jobArr.id)
                        // console.log('jobStartTime',jobArr.from_time)
                        // console.log('jobEndTime',jobArr.to_time)
                        var minutes = timeDiffMinute(jobStartTime, jobEndTime);
                        if (dateRange.length !== 2 && dateRange.length < 9) {
                            minutes = +jobArr.duration;
                        }
                        var jobStartDateTime = moment(jobStartDate + " " + jobStartTime).format("YYYY-MM-DDTHH:mm:ss");
                        var jobEndDateTime = moment(jobEndDate + " " + jobEndTime).format("YYYY-MM-DDTHH:mm:ss");

                        totalJobMinutes = totalJobMinutes + minutes;
                        if (jobArr.is_break_time != 1) {
                            countJob = countJob + 1;

                            if (dateRange.length !== 2 && dateRange.length > 9) {
                                if (moment(jobStartDate).isSame(jobEndDate)) {

                                    if (!dateMonthArr.includes(jobStartDate)) {
                                        var monthViewCount = {};
                                        monthViewCount['count'] = 1;
                                        monthViewCount['day'] = jobStartDate;
                                        monthViewCountArr[jobStartDate] = monthViewCount;
                                        dateMonthArr.push(jobStartDate);
                                    } else {
                                        var monthViewCountNew = {};
                                        monthViewCountNew['count'] = monthViewCountArr?.[jobStartDate]?.['count'] + 1;
                                        monthViewCountNew['day'] = jobStartDate;
                                        monthViewCountArr[jobStartDate] = monthViewCountNew;
                                    }
                                } else {

                                    var start = new Date(jobArr.from_date);
                                    var end = new Date(jobArr.to_date);
                                    var loop = new Date(start);
                                    while (loop <= end) {
                                        var fdate = moment(new Date(loop)).format("YYYY-MM-DD");

                                        if (!dateMonthArr.includes(fdate)) {
                                            var monthViewCountElse = {};
                                            monthViewCountElse['count'] = 1;
                                            monthViewCountElse['day'] = fdate;
                                            monthViewCountArr[fdate] = monthViewCountElse;
                                            dateMonthArr.push(fdate);
                                        } else {
                                            var monthViewCountNewElse = {};
                                            monthViewCountNewElse['count'] = monthViewCountArr?.[fdate]?.['count'] + 1;
                                            monthViewCountNewElse['day'] = fdate;
                                            monthViewCountArr[fdate] = monthViewCountNewElse;
                                        }
                                        var newDate = loop.setDate(loop.getDate() + 1);
                                        loop = new Date(newDate);
                                    }
                                }
                            }

                            if (jobArr.activity_schedule_id && jobArr.activity_schedule_id != 0) {
                                // For jobs activity id
                                assigneToPush['editable'] = false;
                                if (jobArr.job_stage === "Bookin" && (jobArr.job_status === "Pending" || jobArr.job_status === "InProgress" || jobArr.job_status === "Rescheduled")) {
                                    assigneToPush['editable'] = true;
                                    assigneToPush['resourceEditable'] = true;
                                }                                
                                assigneToPush['id'] = `${jobArr.id}`;
                                assigneToPush['customer_quotes_id'] = `${jobArr.customer_quotes_id}`;
                                assigneToPush['resourceId'] = jobArr.user_id;
                                assigneToPush['start'] = jobStartDateTime;
                                assigneToPush['strt_time'] = jobStartDateTime;
                                assigneToPush['activity_schedule_id'] = jobArr.activity_schedule_id;
                                assigneToPush['end'] = jobEndDateTime;
                                assigneToPush['title'] = "";
                                var activityName = jobArr?.activity_schedule?.activity_master?.name || '';
                                if (activityName !== "") {
                                    assigneToPush['title'] = activityName;
                                    assigneToPush['activity_name'] = jobArr?.activity_schedule?.name || 'Holiday';
                                    assigneToPush['description'] = jobArr?.activity_schedule?.description || 'No Description';
                                }
                                assigneToPush['rendering'] = "";
                                assigneToPush['allDaySlot'] = 3;
                                assigneToPush['job'] = true;
                                assigneToPush['textColor'] = "#fff";
                                assigneToPush['background'] = "#000";
                                assigneToPush['overlap'] = false;
                                assigneToPush['statuses'] = '#47C9EA';
                                assigneToPush['tags'] = [jobArr?.activity_schedule?.name || ''];
                                assigneToPush['className'] = ["left-stripe-primary-black"];
                                jobDetailsArr.push(assigneToPush);
                                assigneToPush = {};
                            } else {

                                // if (dateMonthArr.includes(jobStartDate)) {
                                //     var countNew = monthViewCountArr[jobStartDate]['count'] + 1;
                                //     monthViewCountArr[jobStartDate]['count'] = countNew;
                                // } else {
                                //     var monthViewCount = {};
                                //     monthViewCount['count'] = 1;
                                //     monthViewCount['day'] = jobStartDate;
                                //     monthViewCountArr[jobStartDate] = monthViewCount;
                                //     dateMonthArr.push(jobStartDate);
                                // }

                                var pickupTime = utcToTimezone(new Date(jobArr.pickup_time), timezoneLable, "", 'HH:mm:ss');
                                // var pickupTimeOnly = utcToTimezone(new Date(jobArr.pickup_time_only), timezoneLable, "", 'HH:mm:ss');
                                var pickupTimeOnly = moment(jobArr.pickup_time_only).format("HH:mm A");

                                assigneToPush['id'] = `${jobArr.id}`;
                                assigneToPush['customer_quotes_id'] = `${jobArr.customer_quotes_id}`;
                                assigneToPush['resourceId'] = jobArr.user_id;
                                assigneToPush['start'] = jobStartDateTime;
                                assigneToPush['strt_time'] = jobStartDateTime;
                                assigneToPush['end'] = jobEndDateTime;
                                assigneToPush['title'] = "";
                                if (jobArr.workshop_departments_accounts_detail) {
                                    assigneToPush['title'] = jobArr.workshop_departments_accounts_detail.name;
                                }
                                assigneToPush['rendering'] = "";
                                assigneToPush['allDaySlot'] = 0;
                                assigneToPush['job'] = true;
                                assigneToPush['textColor'] = "#fff";
                                assigneToPush['background'] = "#000";
                                assigneToPush['overlap'] = false;
                                assigneToPush['rego'] = jobArr?.rego;
                                assigneToPush['vin_no'] = "";
                                if (jobArr?.vehicles_b?.vin_number) {
                                    assigneToPush['vin_no'] = jobArr?.vehicles_b?.vin_number;
                                }
                                assigneToPush['make'] = jobArr?.vehicles_b?.make;
                                assigneToPush['model'] = jobArr?.vehicles_b?.model;
                                assigneToPush['year'] = jobArr?.vehicles_b?.year;
                                assigneToPush['odometer_reading'] = jobArr?.vehicles_b?.odometer_reading;
                                assigneToPush['customer_id'] = jobArr?.customer_id;
                                assigneToPush['customer_name'] = "";
                                var custType = jobArr?.customer?.cust_detail?.type || '';
                                if (custType === "1" || custType === 1) {
                                    assigneToPush['customer_name'] = jobArr?.customer?.cust_detail?.company_name || '';
                                } else {
                                    assigneToPush['customer_name'] = jobArr?.customer?.cust_detail?.firstname + " " + jobArr?.customer?.cust_detail?.lastname;
                                }
                                assigneToPush['customer_mobile'] = jobArr?.customer?.cust_detail?.mobile_number || '';
                                assigneToPush['customer_mobile_code'] = jobArr?.customer?.cust_detail?.mobile_code || '';
                                assigneToPush['customer_detail_id'] = jobArr?.customer?.cust_detail?.id || '';
                                assigneToPush['from_date'] = jobArr?.from_date;
                                assigneToPush['job_number'] = jobArr.job_number;
                                assigneToPush['pickup_time'] = pickupTime;
                                assigneToPush['pickup_time_only'] = pickupTimeOnly;
                                assigneToPush['job_estimate_time'] = jobArr?.job_estimate_time;
                                assigneToPush['job_duration'] = jobArr?.duration;
                                assigneToPush['job_progress_status'] = jobArr.job_status;
                                assigneToPush['is_copid_job'] = jobArr.is_copid_job;
                                assigneToPush['is_copy_job'] = jobArr.is_copy_job;
                                assigneToPush['is_multiple_tech_job'] = jobArr.is_multiple_tech_job;
                                assigneToPush['description'] = jobArr.description;
                                assigneToPush['statuses'] = "";
                                if (jobArr.job_booking_type && jobArr.job_booking_type.name) {
                                    assigneToPush['tags'] = [jobArr.job_booking_type.name];
                                }
                                assigneToPush['jobs_parts'] = 0;
                                if (jobArr.jobs_parts && jobArr.jobs_parts?.length > 0) {
                                    assigneToPush['jobs_parts'] = jobArr.jobs_parts?.length;
                                }
                                assigneToPush['className'] = ["0", "fc-none"];
                                assigneToPush['type'] = 0;

                                var customerApp = jobArr?.customer?.cust_detail?.customer_app || '';
                                if (customerApp && customerApp === 1) {
                                    assigneToPush['type'] = 1;
                                }
                                assigneToPush['editable'] = false;
                                assigneToPush['resourceEditable'] = false;
                                if (jobArr.job_stage === "Bookin" && (jobArr.job_status === "Pending" || jobArr.job_status === "InProgress" || jobArr.job_status === "Rescheduled")) {
                                    assigneToPush['editable'] = true;
                                    assigneToPush['resourceEditable'] = true;
                                }
                                if (jobArr.job_stage === "Inprogress" && (jobArr.job_status === "InProgress" || jobArr.job_status === "OnHold")) {
                                    assigneToPush['editable'] = true;                                    
                                }
                                jobDetailsArr.push(assigneToPush);
                                assigneToPush = {};
                            }
                        }
                    })
                }
            })
        }

        if (workshopBaysDetails != null && workshopBaysDetails !== undefined) {
            workshopBaysDetails.map((bay, bayIndex) => {
                if (dateRange.length !== 2) {
                    dateRange.map((bayDayDate, bayDateIndex) => {
                        // if (bayDateIndex < 7) {
                        //     var bayScheduleData = scheduleArr[new Date(bayDayDate).getDay()]
                        //     var defaultKeyBaySec = bayDateIndex + defaultKeySec + 1;
                        //     if (bayScheduleData['status'] == 1) {
                        //         var startBayScheduleTime = utcToTimezone(bayScheduleData['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                        //         var endBayScheduleTime = utcToTimezone(bayScheduleData['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                        //         valueToPushSec = {};
                        //         valueToPushSec['id'] = 'off' + bayDateIndex + defaultKeyBaySec;
                        //         valueToPushSec['resourceId'] = 'bay_' + bay.id;
                        //         valueToPushSec['start'] = bayDayDate + "T" + startBayScheduleTime;
                        //         valueToPushSec['end'] = bayDayDate + "T" + endBayScheduleTime;
                        //         valueToPushSec['daysOfWeek'] = ['[' + new Date(bayDayDate).getDay() + ']']; // workshop working days calculation left
                        //         valueToPushSec['title'] = 'Off'
                        //         valueToPushSec['tags'] = [];
                        //         valueToPushSec['overlap'] = false;
                        //         valueToPushSec['eventDisplay'] = 'background';
                        //         valueToPushSec['allDaySlot'] = '2';
                        //         valueToPushSec['color'] = '#e05151';
                        //         valueToPushSec['className'] = ['noHover'];
                        //         valueToPushSec['editable'] = false;
                        //         jobDetailsArr.push(valueToPushSec);
                        //     }
                        // }
                    })
                }
            })
        }

        if (bayJobData !== null && bayJobData !== undefined) {

            bayJobData?.map((bayjobArr, index) => {
                var bayJobStartDate = moment(new Date(bayjobArr.from_date)).format("YYYY-MM-DD");
                var bayJobEndDate = moment(new Date(bayjobArr.to_date)).format("YYYY-MM-DD");
                var bayJobStartTime = moment(bayjobArr.from_time).format("HH:mm:ss")
                var bayJobEndTime = moment(bayjobArr.to_time).format("HH:mm:ss");
                var bayMinutes = timeDiffMinute(bayJobStartTime, bayJobEndTime);
                var bayJobStartDateTime = moment(bayJobStartDate + " " + bayJobStartTime).format("YYYY-MM-DDTHH:mm:ss");
                var bayJobEndDateTime = moment(bayJobEndDate + " " + bayJobEndTime).format("YYYY-MM-DDTHH:mm:ss");

                var duration = convertToHours(bayMinutes);
                totalJobMinutes = totalJobMinutes + bayMinutes;

                var pickupTime = utcToTimezone(new Date(bayjobArr.pickup_time), timezoneLable, "", 'HH:mm:ss');
                // var pickupTimeOnly = utcToTimezone(new Date(bayjobArr.pickup_time_only), timezoneLable, "", 'HH:mm:ss');
                var pickupTimeOnly = moment(bayjobArr.pickup_time_only).format("HH:mm A");

                if (dateMonthArr.includes(bayJobStartDate)) {
                    var countNew = monthViewCountArr[bayJobStartDate]['count'] + 1;
                    monthViewCountArr[bayJobStartDate]['count'] = countNew;
                } else {
                    var monthViewCount = {};
                    monthViewCount['count'] = 1;
                    monthViewCount['day'] = bayJobStartDate;
                    monthViewCountArr[bayJobStartDate] = monthViewCount;
                    dateMonthArr.push(bayJobStartDate);
                }

                assigneToPush['id'] = `${bayjobArr.id}`;
                assigneToPush['customer_quotes_id'] = `${bayjobArr.customer_quotes_id}`;
                assigneToPush['resourceId'] = bayjobArr.bay_id;
                assigneToPush['start'] = bayJobStartDateTime;
                assigneToPush['strt_time'] = bayJobStartDateTime;
                assigneToPush['end'] = bayJobEndDateTime;
                assigneToPush['title'] = "";
                if (bayjobArr?.workshop_departments_accounts_detail) {
                    assigneToPush['title'] = bayjobArr?.workshop_departments_accounts_detail?.name;
                }
                assigneToPush['rendering'] = "";
                assigneToPush['allDaySlot'] = 4;
                assigneToPush['job_type'] = bayjobArr.job_type;
                assigneToPush['duration'] = duration;
                assigneToPush['description'] = bayjobArr.description;
                assigneToPush['req_type'] = "bay";
                assigneToPush['textColor'] = "#dbdbe1";
                assigneToPush['background'] = "#000";
                assigneToPush['overlap'] = false;
                assigneToPush['rego'] = bayjobArr?.rego;
                assigneToPush['vin_no'] = "";
                if (bayjobArr?.vehicle?.vin_number) {
                    assigneToPush['vin_no'] = bayjobArr?.vehicle?.vin_number;
                }
                assigneToPush['make'] = bayjobArr?.vehicle?.make;
                assigneToPush['model'] = bayjobArr?.vehicle?.model;
                assigneToPush['year'] = bayjobArr?.vehicle?.year;
                assigneToPush['odometer_reading'] = bayjobArr?.vehicle?.odometer_reading;
                assigneToPush['customer_id'] = bayjobArr?.customer_id;
                assigneToPush['from_date'] = bayjobArr?.from_date;
                assigneToPush['customer_name'] = "";
                if (bayjobArr?.customer?.cust_detail) {
                    assigneToPush['customer_mobile'] = bayjobArr?.customer?.cust_detail?.mobile_number;
                    assigneToPush['customer_mobile_code'] = bayjobArr?.customer?.cust_detail?.mobile_code;
                    assigneToPush['customer_detail_id'] = bayjobArr?.customer?.cust_detail?.id || '';
                    if (bayjobArr?.customer?.cust_detail?.type === "2") {
                        assigneToPush['customer_name'] = bayjobArr?.customer?.cust_detail?.firstname + " " + bayjobArr?.customer?.cust_detail?.lastname;
                    } else if (bayjobArr?.customer?.cust_detail?.type === "1" || +bayjobArr?.customer?.cust_detail?.type === 1) {
                        assigneToPush['customer_name'] = bayjobArr?.customer?.cust_detail?.company_name;
                    }
                }
                assigneToPush['job_number'] = bayjobArr?.job_number;
                assigneToPush['pickup_time'] = pickupTime;
                assigneToPush['pickup_time_only'] = pickupTimeOnly;
                assigneToPush['job_progress_status'] = bayjobArr.job_status;
                assigneToPush['job_estimate_time'] = bayjobArr?.job_estimate_time;
                assigneToPush['job_duration'] = bayjobArr?.duration;
                assigneToPush['is_copid_job'] = bayjobArr.is_copid_job;
                assigneToPush['is_copy_job'] = bayjobArr.is_copy_job;
                assigneToPush['is_multiple_tech_job'] = bayjobArr.is_multiple_tech_job;
                assigneToPush['statuses'] = "";
                if (bayjobArr?.job_booking_type && bayjobArr?.job_booking_type?.name) {
                    assigneToPush['tags'] = [bayjobArr.job_booking_type.name];
                }
                assigneToPush['jobs_parts'] = 0;
                if (bayjobArr.jobs_parts && bayjobArr.jobs_parts?.length > 0) {
                    assigneToPush['jobs_parts'] = bayjobArr.jobs_parts?.length;
                }
                assigneToPush['className'] = ["fc-custom-event", "booked-job-event"];
                assigneToPush['editable'] = false;
                jobDetailsArr.push(assigneToPush);
                assigneToPush = {};
                countJob = countJob + 1;
            })
            setBayArray(toFindDuplicates(jobDetailsArr))
        }

        if (openRequest !== null && openRequest !== undefined) {
            openRequest.map((openArr, index) => {
                var openStartDate = moment(new Date(openArr.from_date)).format("YYYY-MM-DD");
                var openEndDate = moment(new Date(openArr.to_date)).format("YYYY-MM-DD");
                var openStartTime = moment(openArr.from_time).format("HH:mm:ss")
                var openEndTime = moment(openArr.to_time).format("HH:mm:ss");
                var openMinutes = timeDiffMinute(openStartTime, openEndTime);
                var openStartDateTime = moment(openStartDate + " " + openStartTime).format("YYYY-MM-DDTHH:mm:ss");
                var openEndDateTime = moment(openEndDate + " " + openEndTime).format("YYYY-MM-DDTHH:mm:ss");
                var duration = convertToHours(openMinutes);

                var pickupTime = utcToTimezone(new Date(openArr.pickup_time), timezoneLable, "", 'HH:mm:ss');
                // var pickupTimeOnly = utcToTimezone(new Date(openArr.pickup_time_only), timezoneLable, "", 'HH:mm:ss');
                var pickupTimeOnly = moment(openArr.pickup_time_only).format("HH:mm A");
                openToPush['id'] = `${openArr.id}`;
                openToPush['resourceId'] = openArr.bay_id;
                openToPush['start'] = openStartDateTime;
                openToPush['strt_time'] = openStartDateTime;
                openToPush['end'] = openEndDateTime;
                openToPush['title'] = "";
                if (openArr?.workshop_departments_accounts_detail) {
                    openToPush['title'] = openArr?.workshop_departments_accounts_detail?.name;
                }
                openToPush['rendering'] = "";
                openToPush['allDaySlot'] = 4;
                openToPush['job_type'] = openArr.job_type;
                openToPush['duration'] = duration;
                openToPush['description'] = openArr.description;
                openToPush['req_type'] = "bay";
                openToPush['textColor'] = "#dbdbe1";
                openToPush['background'] = "#000";
                openToPush['overlap'] = false;
                openToPush['rego'] = openArr?.rego;
                openToPush['vin_no'] = "";
                if (openArr?.vehicle?.vin_number) {
                    openToPush['vin_no'] = openArr?.vehicle?.vin_number;
                }
                openToPush['make'] = openArr?.vehicle?.make;
                openToPush['model'] = openArr?.vehicle?.model;
                openToPush['year'] = openArr?.vehicle?.year;
                openToPush['odometer_reading'] = openArr?.vehicle?.odometer_reading;
                openToPush['customer_id'] = openArr?.customer_id;
                openToPush['from_date'] = openArr?.from_date;
                openToPush['customer_name'] = "";
                if (openArr?.customer?.cust_detail) {
                    openToPush['customer_mobile'] = openArr?.customer?.cust_detail?.mobile_number;
                    openToPush['customer_mobile_code'] = openArr?.customer?.cust_detail?.mobile_code || '';
                    assigneToPush['customer_detail_id'] = openArr?.customer?.cust_detail?.id || '';
                    if (openArr?.customer?.cust_detail?.type === "2") {
                        openToPush['customer_name'] = openArr?.customer?.cust_detail?.firstname + " " + openArr?.customer?.cust_detail?.lastname;
                    } else if (openArr?.customer?.cust_detail?.type === "1" || +openArr?.customer?.cust_detail?.type === 1) {
                        openToPush['customer_name'] = openArr?.customer?.cust_detail?.company_name;
                    }
                }
                openToPush['job_number'] = openArr.job_number;
                openToPush['pickup_time'] = pickupTime;
                openToPush['pickup_time_only'] = pickupTimeOnly;
                openToPush['job_progress_status'] = openArr.job_status;
                openToPush['job_estimate_time'] = openArr?.job_estimate_time;
                openToPush['job_duration'] = openArr?.duration;
                openToPush['is_copid_job'] = openArr.is_copid_job;
                openToPush['is_copy_job'] = openArr.is_copy_job;
                openToPush['is_multiple_tech_job'] = openArr.is_multiple_tech_job;
                openToPush['statuses'] = "";
                if (openArr.job_booking_type && openArr.job_booking_type.name) {
                    openToPush['tags'] = [openArr.job_booking_type.name];
                }
                openToPush['jobs_parts'] = 0;
                if (openArr.jobs_parts && openArr.jobs_parts?.length > 0) {
                    openToPush['jobs_parts'] = openArr.jobs_parts?.length;
                }
                openToPush['className'] = ["fc-custom-event"];
                openToPush['editable'] = false;
                jobDetailsArr.push(openToPush);
                openToPush = {};
                countJob = countJob + 1;
            })
        }

        // For remove 1 hour default as a break time
        var techLunchBreak = totalAvailableEmployee * 60;

        //Total technician hours * Total Workshop hours = Available Workshop Hours
        var techTotalMin = totalAvailableEmployee * workshopTotalMinutes;

        // total working day for calculation billable hour week view
        if (totalWorkingDays > 0) {
            techLunchBreak = totalWorkingDays * 60;
            techTotalMin = totalWorkingDays * workshopTotalMinutes;
        }

        //for workshop total minutes(Workshop Hours)
        var workshopMinutes = techTotalMin - techLunchBreak;

        //for Available workshop Hours(Available Hours)
        var availableMin = workshopMinutes - totalJobMinutes;

        var totalWorkshopHrs = convertToHours(workshopMinutes);
        var totalBookingHrs = convertToHours(totalJobMinutes);
        var totalAvailableHrs = convertToHours(availableMin);

        setCountJob(countJob);
        // console.log({jobDetailsArr})
        setCurrentJobData(jobDetailsArr);
        setBillableHrs(totalWorkshopHrs);
        setAvailableHrs(totalAvailableHrs);
        setBookedHrs(totalBookingHrs)
        setMonthJobCount(monthViewCountArr);

    }, [jobsData, bayJobData])

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_UNASSIGNED_JOBS,
                payload: {
                    "get": "all",
                    "conditions": {
                        "JobSchedules.job_stage": "Unassigned",
                        "JobSchedules.job_status": "Unassigned",
                        "JobSchedules.user_id": "0",
                        "JobSchedules.workshop_id": workshop_id,
                        "JobSchedules.is_copid_job": 0,
                        "JobSchedules.is_no_show": 0
                    },
                    "contain": {
                        "Vehicles.Users": {
                            "fields": [
                                "id",
                                "email"
                            ]
                        },
                        "Vehicles.Users.CustomersDetails": {
                            "conditions": {
                                "workshop_id": workshop_id
                            },
                            "fields": [
                                "CustomersDetails.id",
                                "CustomersDetails.user_id",
                                "CustomersDetails.firstname",
                                "CustomersDetails.lastname",
                                "CustomersDetails.mobile_number",
                                "CustomersDetails.mobile_code",
                                "CustomersDetails.customer_app",
                                "CustomersDetails.type",
                                "CustomersDetails.street_address",
                                "CustomersDetails.suburb",
                                "CustomersDetails.postcode",
                                "CustomersDetails.company_name"
                            ]
                        },
                        "Customers": {
                            "fields": [
                                "id",
                                "email"
                            ]
                        },
                        "Customers.CustDetails": {
                            conditions: {
                                'CustDetails.workshop_id': workshop_id
                            },
                            fields: [
                                "CustDetails.id",
                                "CustDetails.user_id",
                                "CustDetails.firstname",
                                "CustDetails.lastname",
                                "CustDetails.mobile_number",
                                "CustDetails.mobile_code",
                                "CustDetails.customer_app",
                                "CustDetails.type",
                                "CustDetails.street_address",
                                "CustDetails.suburb",
                                "CustDetails.postcode",
                                "CustDetails.company_name"
                            ]
                        },
                        'WorkshopDepartmentsAccountsDetails': {
                            "fields": ['id', 'workshop_id', 'name', 'job_type_parent_id']
                        },
                        'JobBookingTypes': {
                            "fields": ['id', 'parent_id', 'name', 'workshop_id']
                        },
                    },
                    "fields": [
                        "JobSchedules.id",
                        "JobSchedules.workshop_id",
                        "JobSchedules.duration",
                        "JobSchedules.pickup_time",
                        "JobSchedules.description",
                        "JobSchedules.job_stage",
                        "JobSchedules.job_number",
                        "JobSchedules.job_status",
                        "JobSchedules.customer_id",
                        "JobSchedules.vehicle_id",
                        "Vehicles.id",
                        "Vehicles.user_id",
                        "Vehicles.rego",
                        "Vehicles.make",
                        "Vehicles.year",
                        "Vehicles.model",
                        "JobSchedules.job_department_id",
                        "JobSchedules.job_type_parent_id",
                        "JobSchedules.job_type",
                        "JobSchedules.is_copy_job",
                        "JobSchedules.is_copid_job",
                        "JobSchedules.rego",
                        "JobSchedules.is_multiple_tech_job"
                    ]
                }
            })
        }
        fetchData().catch(console.error)
    }, [jobCreated, updateSchedule])

    //To Find Duplicate element
    function toFindDuplicates(arr) {
        const uniqObjs = [];
        let dupeObjs = [];
        let abcd = [];
        let eventArray = []
        arr.map(value => {
            if (value.req_type === "bay") {
                let ObjToPush = {};
                ObjToPush[`${moment(value.start).format('YYYY-MM-DD-HH:mm')}${value.resourceId}`] = value;
                dupeObjs.push(ObjToPush)
            }
        })

        eventArray = dupeObjs.reduce(function (acc, curr) {
            var key = Object.keys(curr)[0];
            var found = acc.filter(function (i) {
                return i[key];
            })[0];

            if (!found) {
                acc.push(curr);
            } else {
                found[key] = [].concat(found[key], curr[key]);
            }
            return acc;
        }, []);

        return {
            uniqueObj: uniqObjs,
            dupeObjs: abcd,
            newArray: eventArray
        }
    }

    useEffect(() => {
        setDateRange(getDaysArray(fromDate, toDate));
    }, [fromDate, toDate, updateSchedule, baysUpdateSchedule])

    const [rescheduleShow, setRescheduleShow] = useState(false);
    const handleRescheduleClose = () => {
        setRescheduleShow(false);
    }

    const [startUnassignDate, setStartUnassignDate] = useState();
    const [unassignJobNumber, setUnassignJobNumber] = useState();
    const [unassignJobId, setUnassignJobId] = useState();

    const eventReceive = ({ event }) => {
        const calendar = getCalendarApi();;
        calendar.getEventById(event.id).remove()

        // let duration = convertToMinute(slotDuration);
        let duration = convertToMinute(event?._def?.extendedProps?.data?.job_estimate_time || slotDuration);
        if (calendarView === "month") {
            var jobNumber = event?._def?.extendedProps?.data?.job_number || 0;
            var jobId = event?._def?.extendedProps?.data?.id || 0;
            var startDate = timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD");
            setStartUnassignDate(startDate);
            setUnassignJobNumber(jobNumber);
            setUnassignJobId(jobId);
            setRescheduleShow(true);
        } else {
            let start_time = timeZoneToUTC(moment(event.startStr).format('HH:mm:ss'), timezoneLable, "HH:mm:ss", "HH:mm:ss");
            let end_time = moment(start_time, 'HH:mm:ss').add(duration, 'minutes').format('HH:mm:ss');
            var userId = 0;
            var bayId = 0;
            // var usersIdNew = event._def.extendedProps._id;
            var typeTech = event?.getResources()?.[0]?._resource?.extendedProps?.user_profile || 'user';
            if (typeTech === "bay") {
                userId = 0;
                bayId = event?.getResources()?.[0]?._resource?.id || 0;
            } else {
                userId = event?.getResources()?.[0]?._resource?.id || 0;
                bayId = 0;
            }

            if (event?._def?.extendedProps?.data?.req_type === "bay") {
                dispatch({
                    type: UPDATE_JOB_TIMER,
                    payload: {
                        "workshop_id": workshop_id,
                        "created_by": user_id,
                        "user_id": userId,
                        "bay_id": bayId,
                        "from_date": timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD"),
                        "to_date": timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD"),
                        "from_time": start_time,
                        "to_time": end_time,
                        "duration": duration,
                        "id": event.extendedProps.data.id
                    }
                })
                setBaysJobUpdate(true);
            } else {
                dispatch({
                    type: UPDATE_JOB_TIMER,
                    payload: {
                        "workshop_id": workshop_id,
                        "created_by": user_id,
                        "user_id": userId,
                        "bay_id": bayId,
                        "from_date": timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD"),
                        "to_date": timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD"),
                        "from_time": start_time,
                        "to_time": end_time,
                        "duration": duration,
                        "id": event?.extendedProps?.data?.id,
                        "pickup_time_only": "17:00",
                        "pickup_time": timeZoneToUTC(new Date(), timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"),
                        "tags_id": "[]",
                        "job_status": "Pending",
                        "job_stage": "Bookin",
                        "insp_reference_id": 0,
                        "bookin_source": 2,
                        "source_of_business": "Existing",
                        "labour_time": 0,
                        "parts_cost": 0,
                        "labour_cost": 0,
                        "parts_markup": 0,
                        "labour_markup": 0,
                        "parts_total_cost": 0,
                        "labour_total_cost": 0,
                        "sub_total": 0,
                        "gst": 0,
                        "total": 0,
                        "discount_rate": 0,
                    }
                })
                setDispatchToCreate(true)
            }
        }
    }
    // Add labour once unassigned to assign job 
    useEffect(() => {
        if (jobUpdated && jobUpdated.JobSchedules && dispatchToCreate === true) {
            handleUnassignedJobData(!jobCreated)
            if (labourChargesList !== null && labourChargesList !== undefined) {
                let objToSend = {
                    labour_charges_id: labourChargesList[0].id,
                    job_schedules_id: jobUpdated.JobSchedules.id,
                    cost_price: labourChargesList[0].cost_price,
                    margin: labourChargesList[0].margin,
                    sell_price: labourChargesList[0].sell_price,
                    retail_price: labourChargesList[0].retail_price,
                    profit: labourChargesList[0].profit_price,
                    labour_description: labourChargesList[0].description,
                    total_price: 0
                }
                dispatch({
                    type: SAVE_JOB_LABOUR,
                    payload: objToSend
                })
                // handleUnassignedJobData(!jobCreated)
                setDispatchToCreate(false)
                setUpdateSchedule(!updateSchedule);
            }
        } else {
            if (jobUpdated && jobUpdated.JobSchedules !== null && baysJobUpdate === true) {
                setUpdateSchedule(!updateSchedule);
                // setBaysUpdateSchedule(!baysUpdateSchedule);
                showSuccess('Your job has been updated Successfully.');
                setBaysJobUpdate(false);
            }
            if (jobUpdated && jobUpdated.JobSchedules !== null && draggedEvent === true) {
                showSuccess('Your job has been updated Successfully.');
                setDraggedEvent(false);
            }
        }
    }, [jobUpdated])

    // Get DateRange between two dates in (Y-m-d) format
    let getDaysArray = function (s, e) {
        for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
            a.push(moment(new Date(d)).format("YYYY-MM-DD"));
        }
        return a;
    };

    const eventAfterAllRender = (info) => {
        if (info.view.type === "resourceTimelineDay") {

        } else if (info.view.type === "resourceTimelineWeek") {

        } else if (info.view.type === "dayGridMonth") {

        }
    };

    const handleDateSelect = async (selectInfo) => {
        dispatch(getCustomerAndVehicleDetailsSuccess(null));
        if (selectInfo.resource.id !== 'z') {
            setStartJobTime(selectInfo.startStr);
            setEndJobTime(selectInfo.endStr);
            setTechnicianName(selectInfo.resource.title);
            setTechnicianId(selectInfo.resource.id);
            if (selectInfo.resource.extendedProps.user_profile === "bay") {
                setTechnicianType("bay");
            } else {
                setTechnicianType("technician");
            }
            var avilableData = moment(new Date(selectInfo.startStr)).format("YYYY-MM-DD");

            dispatch({
                type: CHECK_AVAILABLE_HOURS,
                payload: {
                    date: avilableData,
                    workshop_id: workshop_id
                }
            });

            let timeSlotData = {
                from_date: moment(selectInfo.startStr).format("YYYY-MM-DD"),
                from_time: moment(selectInfo.startStr).format("HH:mm:ss"),
                workshop_id: workshop_id,
                workshop_end_time: endTime,
                user_id: selectInfo.resource.id
            }

            const resp = await getEstimateTimeSlot({ payload: timeSlotData });

            if (resp?.data?.JobSchedules?.time_slot) {
                setEstimateTimeSlot(resp?.data?.JobSchedules?.time_slot);
            }

            setShowModal(true);
            setSlotLimitShow(true)
        }
    }

    async function getJobEstimateTimeData(from_date, from_time, user_id) {

        let timeSlotData = {
            from_date: moment(from_date).format("YYYY-MM-DD"),
            from_time: from_time,
            workshop_id: workshop_id,
            workshop_end_time: endTime,
            user_id: user_id
        }

        const resp = await getEstimateTimeSlot({ payload: timeSlotData });

        if (resp?.data?.JobSchedules?.time_slot) {
            setEstimateTimeSlot(resp?.data?.JobSchedules?.time_slot);
        }
    }

    useEffect(() => {
        document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-after-content', '→');
        document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-before-content', '');
        if (showTag) {
            setTimeout(() => {
                // let headerLength = document.getElementsByClassName('fc-timeline-header-row')[0] && document.getElementsByClassName('fc-timeline-header-row')[0].childNodes.length;
                let headerLength = document.getElementsByClassName('fc-timeline-header-row fc-timeline-header-row-chrono')[0] && document.getElementsByClassName('fc-timeline-header-row fc-timeline-header-row-chrono')[0].childNodes.length;
                let totalRows = document.getElementsByClassName('fc-timeline-events fc-scrollgrid-sync-inner')
                // let width = document.getElementsByClassName('fc-timeline-slot-frame')[0] && document.getElementsByClassName('fc-timeline-slot-frame')[0].offsetWidth;
                let width;
                if (document.getElementsByClassName('fc-slot-past')[0] && document.getElementsByClassName('fc-slot-past')[0].offsetWidth) {
                    // width = document.getElementsByClassName('fc-slot-past')[0].offsetWidth;	
                    width = parseFloat(getComputedStyle(document.getElementsByClassName('fc-slot-past')[0], null).getPropertyValue('width'));
                } else if (document.getElementsByClassName('fc-timeline-slot-frame')[0] && document.getElementsByClassName('fc-timeline-slot-frame')[0].offsetWidth) {
                    width = parseFloat(getComputedStyle(document.getElementsByClassName('fc-timeline-slot-frame')[0], null).getPropertyValue('width'));
                    // width =  document.getElementsByClassName('fc-timeline-slot-frame')[0].offsetWidth	
                }

                let left = 0;
                for (var i = 0; i < headerLength; i++) {
                    let tag = document.createElement('a');
                    tag.classList.add('row-event');
                    tag.style.width = `${width}px`;
                    tag.style.height = "100%";
                    tag.style.left = `${left}px`;
                    // tag.style.display = "flex";
                    tag.style.backgroundColor = "linear-gradient(90deg, #5BCFED, black) no-repeat !important";

                    var text = "<div style='color: #fff; font-weight: 600; text-align: center; margin-top: 18%;'>";
                    text += "<p><i class='fa fa-plus' aria-hidden='true'></i></p>";
                    text += "<p>Create Job</p></div>";
                    // var text = '<p  >Create Job</p>';
                    // tag.innerHTML(text);
                    tag.innerHTML = text;
                    left = left + width;
                    for (var j = 0; j < (totalRows.length - 1); j++) {
                        document.getElementsByClassName('fc-timeline-events fc-scrollgrid-sync-inner')[j].setAttribute('id', `${j}-row`)
                        document.getElementById(`${j}-row`).appendChild(tag.cloneNode(text));
                    }
                }
                setShowTag(false);
            }, 1000)
        }
    }, [showTag])

    const renderEventContent = (eventInfo) => {

        return (
            <>
                <JobTicket
                    // <JobTicket req_type
                    eventInfo={eventInfo}
                    dupeObjs={bayArray.dupeObjs}
                    view={eventInfo.view.type}
                    workshop_id={workshop_id}
                    eventArray={bayArray.newArray}
                    statusColor={statusColor}
                    setUpdateCalendar={setUpdateCalendar}
                    updateCalendar={updateCalendar}
                    slotDuration={slotDuration}
                    technicianName={technicianName}
                    setLoading1={setLoading}
                />
            </>
        )
    }
    // const renderMonthEvent = (eventInfo) => {return (<></>)}
    //Called when event is resize 
    const handleEventResize = (data) => {
        let workshopId = JSON.parse(localStorage.getItem('data')).workshop_id;
        let fromDate = moment(data.event.start).format("YYYY-MM-DD");
        let toDate = moment(data.event.end).format("YYYY-MM-DD");
        let fromTime = timeZoneToUTC(moment(data.event.start).format("HH:mm:ss"), timezoneLable, "HH:mm:ss", "HH:mm:ss");
        let toTime = timeZoneToUTC(moment(data.event.end).format("HH:mm:ss"), timezoneLable, "HH:mm:ss", "HH:mm:ss");
        let duration = new Date(data.event.start) - new Date(data.event.end);
        let userId = data.event["_def"].resourceIds[0];
        let status = data.event.extendedProps.job_progress_status;
        let jobId = data.event["_def"].publicId;
        let activityScheduleId = data?.event?.extendedProps?.activity_schedule_id || 0;
        duration = convertToMinute(Math.abs(duration));
        let job_estimate_time = convertToHoursMins(duration);

        if (fromDate !== toDate) {
            let newDuration = 0;
            let workshopWorkingHours = timeDiffMinute(startTime, endTime);
            // newDuration = convertToHoursMins(workshopWorkingHours);
            newDuration = workshopWorkingHours;

            fromDate = moment(fromDate).add(1, 'days');

            for (var m = moment(fromDate); m.diff(toDate, 'days') <= 0; m.add(1, 'days')) {
                if (m.format('YYYY-MM-DD') !== toDate) {
                    newDuration = +newDuration + +workshopWorkingHours;
                } else {
                    let duration1 = timeDiffMinute(startTime, moment(data.event.end).format("HH:mm:ss"));
                    newDuration = +newDuration + +duration1;
                }
            }
            duration = newDuration;
            job_estimate_time = convertToHoursMins(newDuration);
        }



        if (activityScheduleId == 0) {
            let objToSend = {
                workshop_id: workshopId,
                from_date: fromDate,
                to_date: toDate,
                from_time: fromTime,
                to_time: toTime,
                activity_schedule_id: activityScheduleId,
                duration: duration,
                // job_estimate_time: job_estimate_time,
                job_status: status,
                id: jobId,
                from: 'web'
            }
            // user_id: userId,
            if (data?.newResource?.extendedProps?.user_profile && data?.newResource?.extendedProps?.user_profile === "bay") {
                // if (data.event.extendedProps.req_type && data.event.extendedProps.req_type === "bay") {
                objToSend.bay_id = userId;
                objToSend.user_id = 0;
            } else {
                objToSend.user_id = userId;
                objToSend.bay_id = 0;
            }
            dispatch({
                type: UPDATE_JOB_TIMER,
                payload: objToSend
            });
        } else {
            let objToSendActivity = {
                workshop_id: workshopId,
                from_date: fromDate,
                starttime: fromTime,
                endtime: toTime,
                schedule_id: activityScheduleId,
                duration: duration,
                job_estimate_time: job_estimate_time,
                timezone: timezoneLable
            }
            // user_id: userId,
            if (data?.newResource?.extendedProps?.user_profile && data?.newResource?.extendedProps?.user_profile === "bay") {
                // if (data.event.extendedProps.req_type && data.event.extendedProps.req_type === "bay") {
                objToSendActivity.bay_id = userId;
                objToSendActivity.user_id = 0;
            } else {
                objToSendActivity.user_id = userId;
                objToSendActivity.bay_id = 0;
            }
            dispatch({
                type: UPDATE_JOB_ACTIVITY,
                payload: objToSendActivity
            });
            setUpdateActivityFlag(true)
        }
        setDispatchToCreate(false);
        setDraggedEvent(true)
    }

    //Called when event is dragged or changed
    const handleEventChange = (data) => {
        let workshopId = JSON.parse(localStorage.getItem('data')).workshop_id;
        let fromDate = moment(data.event.start).format("YYYY-MM-DD");
        let toDate = moment(data.event.end).format("YYYY-MM-DD");
        let fromTime = timeZoneToUTC(moment(data.event.start).format("HH:mm:ss"), timezoneLable, "HH:mm:ss", "HH:mm:ss");
        let toTime = timeZoneToUTC(moment(data.event.end).format("HH:mm:ss"), timezoneLable, "HH:mm:ss", "HH:mm:ss");
        let duration = new Date(data.event.start) - new Date(data.event.end);
        let userId = data.event["_def"].resourceIds[0];
        let status = data.event.extendedProps.job_progress_status;
        let jobId = data.event["_def"].publicId;
        let activityScheduleId = data?.event?.extendedProps?.activity_schedule_id || 0;
        if (activityScheduleId == 0) {
            let objToSend = {
                workshop_id: workshopId,
                from_date: fromDate,
                to_date: toDate,
                from_time: fromTime,
                to_time: toTime,
                activity_schedule_id: 0,
                duration: convertToMinute(Math.abs(duration)),
                job_status: status,
                id: jobId,
                from: 'web'
            }
            // user_id: userId,
            if (data?.newResource?.extendedProps?.user_profile && data?.newResource?.extendedProps?.user_profile === "bay") {
                // if (data.event.extendedProps.req_type && data.event.extendedProps.req_type === "bay") {
                objToSend.bay_id = userId;
                objToSend.user_id = 0;
            } else {
                objToSend.user_id = userId;
                objToSend.bay_id = 0;
            }
            dispatch({
                type: UPDATE_JOB_TIMER,
                payload: objToSend
            });
        }
        setDispatchToCreate(false);
        setDraggedEvent(true)
    }

    const getJobList = (jobdate) => {
        clickDate(jobdate);
        monthView(calendarView);
        var elem = document.getElementById("list-table");
        if (elem !== null) {
            elem.scrollIntoView();
        }
    }

    const handleMonthViewPopup = (flag, date) => {
        setStartJobTime(date)
        setShowModal(true)
        setMonthViewPopup(true)
    }

    useEffect(() => {

    }, [jobCreated])

    useEffect(() => {
        setAvailableHrsClass("");
        setBillableHrsClass('');
        setBookedHrsClass('');
        if (availableHrs) {
            if (availableHrs.split(":")[0] < 0 || +availableHrs.split(":")[1] < 0) {
                setAvailableHrsClass("text-danger")
            }
        }
        if (billableHrs) {
            if (+billableHrs.split(":")[0] < 0 || +billableHrs.split(":")[1] < 0) {
                setBillableHrsClass('text-danger')
            }
        }
        if (bookedHrs) {
            if (+bookedHrs.split(":")[0] < 0 || +bookedHrs.split(":")[1] < 0) {
                setBookedHrsClass('text-danger')
            }
        }
    }, [availableHrs, billableHrs, bookedHrs])

    const createJob = () => {
        setShowModal(true);
        setNewAvailableHours(true);
    }
    
    // useEffect(() => {
    //     const calendarApi = getCalendarApi();
    //     var startDate = calendarApi.currentDataManager.state.dateProfile.currentRange.start;
    //     var endDate = calendarApi.currentDataManager.state.dateProfile.currentRange.end;
    //     setFromDate(moment(new Date(startDate)).format("YYYY-MM-DD"))//start of the range the calendar date
    //     setToDate(moment(new Date(endDate)).format("YYYY-MM-DD"))//end of the range the calendar date
    // },[getCalendarApi])

    const handleDatesSet = (dateInfo) => {
        setFromDate(moment(new Date(dateInfo.start)).format("YYYY-MM-DD"))//start of the range the calendar date
        setToDate(moment(new Date(dateInfo.end)).format("YYYY-MM-DD"))//end of the range the calendar date
    }

    return (
        <>
            {
                loading ? <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div> : ""
            }
            {
                (availableHours !== null || showMonthViewPopup) ?
                    ((showModal && (availableHours === true || newAvailableHours == true)) || showMonthViewPopup) ?
                        <CreateJob
                            show={showModal}
                            showMonthViewPopup={showMonthViewPopup}
                            close={setCloseModal}
                            slotDuration={slotDuration}
                            technicianName={technicianName}
                            startTimeCalendar={startJobTime}
                            endTimeCalendar={endJobTime}
                            technicianId={technicianId}
                            technicianType={technicianType}
                            setUpdateCalendar={setUpdateCalendar}
                            updateCalendar={updateCalendar}
                            handleSlotLimitClose={handleSlotLimitClose}
                            estimateTimeSlot={estimateTimeSlot}
                            getJobEstimateTimeData={getJobEstimateTimeData}
                        ></CreateJob>
                        :
                        (availableHours === false) ?
                            <Modal
                                className="info-dialog info-dialog-popup"
                                size="md"
                                show={slotLimit}
                                onHide={handleSlotLimitClose}
                            >
                                <div className="heading-block d-flex justify-center">
                                    {/* <i className="la la-exclamation-circle info-icon" aria-hidden="true"></i> */}
                                    <p class="d-flex font-size-30 font-weight-600 heading-block justify-center text-danger">Warning</p>
                                    <p class="border p-2 rounded-circle available-hours" >!</p>
                                </div>
                                <Modal.Body>
                                    <div>
                                        <h2 className="font-size-24 mt-2" >Available hours exceeded,</h2>
                                        <h2 className="font-size-24" >Press continue to proceed</h2>
                                        <h2 class="mb-2 font-size-24"> or cancel</h2>
                                    </div>
                                    <div className="d-flex justify-center">
                                        <Button
                                            variant="primary"
                                            className="btn-primary rounded-btn"
                                            onClick={() => createJob()}
                                        >
                                            Continue
                                        </Button>
                                        <Button
                                            variant="primary"
                                            className="btn-danger btn-lg rounded-btn"
                                            onClick={() => handleSlotLimitClose()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ''
                    : ""
            }
            {
                (rescheduleShow === true) ?
                    <Reschedule
                        rescheduleShow={rescheduleShow}
                        handleRescheduleClose={handleRescheduleClose}
                        jobIdData={unassignJobId}
                        startEventTime={startUnassignDate}
                        technicianName=""
                        jobNumber={unassignJobNumber}
                        setUpdateCalendar={setUpdateCalendar}
                        updateCalendar={updateCalendar}
                        jobType="Unassigned"
                    ></Reschedule> : ''
            }
            <FullCalendar
                // timeZone='UTC'
                ref={calendarRef}
                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                plugins={[resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                customButtons={{
                    unassignedButton: {
                        text: 'Unassigned Jobs',
                        click: function () {
                            let html;
                            document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('id', 'unassigned-job-btn');
                            if (document.querySelector(".schedular-outer").classList.toggle('display-right-col')) {
                                html = '<div style="border:1px solid white; border-radius:100%;padding:3px;">UN</div>';
                                document.getElementById('unassigned-job-btn').innerHTML = html;
                                document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-after-content', '');
                                document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-before-content', '←');
                            } else {
                                html = 'Unassigned Jobs'
                                document.getElementById('unassigned-job-btn').innerHTML = html;
                                document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-after-content', '→');
                                document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-before-content', '');
                            }
                        },
                    },
                }}
                eventDidMount={eventAfterAllRender}
                headerToolbar={{
                    right: 'unassignedButton',
                    center: 'prev, title ,next',
                    left: 'resourceTimelineDay,resourceTimelineWeek,dayGridMonth'
                }}
                viewClassNames={
                    (arg) => {
                        if (arg.view.type == "resourceTimelineDay") {
                            setCalendarType("day")
                        } else if (arg.view.type == "resourceTimelineWeek") {
                            setCalendarType("week")
                        } else {
                            setCalendarType("month")
                        }
                    }
                }
                resourceLabelWillUnmount={
                    (arg) => {

                    }
                }
                viewDidMount={
                    (arg) => {
                        if (arg.view.type === "resourceTimelineWeek") {
                            setCalendarView("week");
                            monthView("week");
                            clickDate("");
                            document.getElementById('right-col').style.flex = '0 0 17%';
                            document.querySelector(".schedular-outer").classList.remove('display-right-col')
                            document.getElementsByClassName('fc-unassignedButton-button')[0].style.visibility = 'visible';
                        } else if (arg.view.type === "dayGridMonth") {
                            setCalendarView('month');
                            monthView("month");
                            clickDate("");
                            document.getElementById('right-col').style.flex = '0 0 30%';
                            document.querySelector(".schedular-outer").classList.add('display-right-col');
                            document.getElementsByClassName('fc-unassignedButton-button')[0].style.visibility = 'hidden';
                        } else {
                            setCalendarView('day');
                            monthView("day");
                            clickDate("");
                            document.getElementById('right-col').style.flex = '0 0 17%';
                            document.querySelector(".schedular-outer").classList.remove('display-right-col')
                            document.getElementsByClassName('fc-unassignedButton-button')[0].style.visibility = 'visible';
                        }
                    }
                }
                viewWillUnmount={
                    (arg) => {

                    }
                }
                dayCellContent={(info) => {
                    let highlight = false;
                    if (info.isToday === true) {
                        highlight = true
                    } else {
                        highlight = false;
                    }
                    var jobCounter = monthJobCount?.[moment(new Date(info.date)).format("YYYY-MM-DD")]?.['count'] || null;
                    return (
                        <>
                            <div className="main-content">
                                <span className={`${highlight ? "active-date" : ""}`}>{new Date(info.date).getDate()}</span>
                                <div className="inner-div">
                                    <span onClick={() => handleMonthViewPopup(true, moment(new Date(info.date)).format("YYYY-MM-DD"))}>
                                        <div className="create-job" >
                                            <p><i class="fa fa-plus" aria-hidden="true"></i></p>
                                            <p>Create Job</p>
                                        </div>
                                    </span>
                                </div>
                                {
                                    (jobCounter !== null) ? <div className="inner-in-div" onClick={() => getJobList(moment(new Date(info.date)).format("YYYY-MM-DD"))}> <div>{jobCounter}</div></div> : ''
                                }
                            </div>
                        </>
                    )
                }}
                navLinkDayClick={(info) => {

                }}
                slotMinTime={startTime}
                slotMaxTime={endTime}
                slotDuration={slotDuration}
                displayEventTime={true}
                firstDay={1}
                nowIndicator={true}
                initialView={"resourceTimelineDay"}
                // aspectRatio={1.8}
                droppable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={weekendsVisible}
                // weekNumbersWithinDays = {true}
                // width="auto"
                height="auto"
                // dateClick={handleDateClick}
                datesSet={handleDatesSet}
                businessHours={businessHours}
                // businessHours={[
                //     // Monday: 7am to 8pm
                //     {
                //       daysOfWeek: [1], // Monday
                //       startTime: '07:00',
                //       endTime: '20:00'
                //     },
                //     // Tuesday: 9am to 4pm
                //     {
                //       daysOfWeek: [2], // Tuesday
                //       startTime: '09:00',
                //       endTime: '16:00'
                //     },
                //     // Add more days and hours as needed
                // ]}
                resourceAreaHeaderContent={(info) => {
                    return (
                        <div className="d-flex align-center justify-center no-wrap">
                            <div className="time-zone d-flex align-center no-wrap">
                                <div className="time-slot">
                                    <p className={"time " + billableHrsClass}>{billableHrs} Hrs</p>
                                    <p>Billable</p>
                                </div>
                                <div className="time-slot">
                                    <p className={"time " + bookedHrsClass}>{bookedHrs} Hrs</p>
                                    <p>Booked</p>
                                </div>
                                <div className="time-slot">
                                    <p className={"time  " + availableHrsClass}>{availableHrs} Hrs</p>
                                    <p>Available</p>
                                </div>
                            </div>
                            <span className="count d-flex align-center justify-center">{countJob}</span>
                        </div>
                    )
                }
                }
                views={{
                    day: {
                        titleFormat: { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' },
                        // dayMaxEvents: 1
                    },
                    month: {
                        // dayMaxEvents: 1
                    }
                }}
                eventTimeFormat={
                    {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                        meridiem: 'short'
                    }
                }

                initialEvents={[]} // alternatively, use the `events` setting to fetch from a feed
                navLinks={true} // can click day/week names to navigate views
                // businessHours={true} // display business hours
                // Explore this events
                events={currentJobData}
                // events = {[
                //     {
                //     resourceId:18048,
                //       title: 'Workshop Hours',
                //       start: '2023-11-06T07:00:00',
                //       end: '2023-11-06T20:00:00',
                //       dow: [1], // Monday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
                //       rendering: 'background',
                //       eventDisplay:'background',
                //       allDaySlot:'2',
                //       color:"#e05151",
                //       className:['noHover'],
                //       editable:false
                //     },
                //     {
                //         resourceId:18048,
                //       title: 'Workshop Hours',
                //       start: '2023-11-07T09:00:00',
                //       end: '2023-11-07T16:00:00',
                //       dow: [2], // Tuesday
                //       rendering: 'background',
                //       eventDisplay:'background',
                //       allDaySlot:'2',
                //       color:"#e05151",
                //       className:['noHover'],
                //       editable:false
                //     },
                //     // Add more events for other workshop hours and days
                // ]}
                // businessHours= {[
                //  {   
                //         startTime: '00:00', // Display time slots from midnight
                //         endTime: '24:00', // Display time slots until midnight
                //         daysOfWeek: [0, 1, 2, 3, 4, 5, 6] // Show time slots for all days
                //  }
                // ]
                // }

                // businessHours={[
                //     {
                //       daysOfWeek: [1], // Monday
                //       startTime: '07:00', // 7:00 AM
                //       endTime: '20:00'   // 8:00 PM
                //     },
                //     {
                //       daysOfWeek: [2], // Tuesday
                //       startTime: '09:00', // 9:00 AM
                //       endTime: '16:00'   // 4:00 PM
                //     },
                //     {
                //       daysOfWeek: [3], // Wednesday
                //       startTime: '07:00', // 10:00 AM
                //       endTime: '20:00'   // 2:00 PM
                //     },
                //     {
                //       daysOfWeek: [4], // Thursday
                //       startTime: '08:00', // 8:00 AM
                //       endTime: '17:00'   // 5:00 PM
                //     },
                //     {
                //       daysOfWeek: [5], // Friday
                //       startTime: '11:00', // 11:00 AM
                //       endTime: '18:00'   // 6:00 PM
                //     },
                //     {
                //       daysOfWeek: [6], // Saturday
                //       startTime: '07:00', // 7:00 AM
                //       endTime: '13:00'   // 1:00 PM
                //     },
                // ]}

                eventMouseEnter={({ el }) => {
                    el.parentElement.style.zIndex = "9"
                }}
                eventMouseLeave={({ el }) => {
                    el.parentElement.style.zIndex = "2"
                }}
                resourceOrder={'sortOrder'}
                resources={technicianListData}
                // resources={ [
                //     // Define your resources (e.g., employees or rooms) with their working hours
                //     { id: 'a', title: 'Resource A', workingHours: { start: '08:00', end: '17:00' } }, // Monday
                //     { id: 'b', title: 'Resource B', workingHours: { start: '12:00', end: '16:00' } }, // Tuesday
                //     { id: 'c', title: 'Resource C', workingHours: { start: '11:00', end: '13:00' } }, // Wednesday
                //     { id: 'd', title: 'Resource D', workingHours: { start: '08:30', end: '10:30' } }, // Thursday
                //     { id: 'e', title: 'Resource E', workingHours: { start: '11:00', end: '18:00' } }, // Friday
                //     { id: 'f', title: 'Resource F', workingHours: { start: '07:00', end: '13:00' } }, // Saturday
                //     { id: 'g', title: 'Resource G', workingHours: { start: '00:00', end: '00:00' } }, // Sunday (Holiday)
                // ]}

                // resourcesInitiallyExpanded={false}
                resourceLabelContent={Resources}
                fixedWeekCount={false}
                selectOverlap={false}
                select={handleDateSelect}
                // selectConstraint={'businessHours'}
                // eventConstraint={'lesson-available'}
                // eventContent={calendarView === "month" ? renderMonthEvent : renderEventContent} // custom render function
                eventContent={renderEventContent} // custom render function
                eventClick={[]}
                eventsSet={[]} // called after events are initialized/added/changed/removed
                eventAdd={function () { }}
                // eventChange={handleEventChange}
                eventDrop={handleEventChange}
                eventRemove={function () { }}
                eventReceive={eventReceive}
                eventResize={handleEventResize}
            />
            <div className="px-5 mt-3 d-flex justify-content-center">
                {status && status.map((value, index) => (
                    <div className="d-flex flex-row align-center me-3">
                        <div className="dot-status me-2 rounded-circle" style={{ background: statusColor && statusColor[value.name] }}></div>
                        <span>{value.status}</span>
                    </div>
                ))}
            </div>

            {noEmployeeWarning &&
                <NoEmployeeWarning
                    setNoEmployeeWarning={setNoEmployeeWarning}
                />
            }
        </>
    )
}

export default Calendar;