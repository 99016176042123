import { Routes } from "react-router-dom";
// import { GET_LOGOUT } from "../store/login/actionTypes";
import { useRoutes } from "./routesV2";
import SecurityProvider from "./SecurityProvider";

// const renderSwitch = (user) => {
//   if (user) {
//     const role_id = user.role_id;
//     switch (role_id) {
//       case 2:
//         return ownerRoutes.map((route) => {
//           return route.element ? (
//             <Route
//               key={uuidv4()}
//               path={route.path}
//               exact={route.exact}
//               name={route.name}
//               element={
//                 <Wrapper>
//                   <route.element />
//                 </Wrapper>
//               }
//             />
//           ) : (
//             <Navigate to="/login" key={uuidv4()} />
//           );
//         });
//       case 3:
//         return TechRoutes.map((route) => {
//           return route.element ? (
//             <Route
//               key={uuidv4()}
//               path={route.path}
//               exact={route.exact}
//               name={route.name}
//               element={<route.element />}
//             />
//           ) : (
//             <Navigate to="/login" key={uuidv4()} />
//           );
//         });
//       case 5:
//         return accountRoutes.map((route) => {
//           return route.element ? (
//             <Route
//               key={uuidv4()}
//               path={route.path}
//               exact={route.exact}
//               name={route.name}
//               element={<route.element />}
//             />
//           ) : (
//             <Navigate to="/login" key={uuidv4()} />
//           );
//         });
//       case 6:
//         return officeRoutes.map((route) => {
//           return route.element ? (
//             <Route
//               key={uuidv4()}
//               path={route.path}
//               exact={route.exact}
//               name={route.name}
//               element={<route.element />}
//             />
//           ) : (
//             <Navigate to="/login" key={uuidv4()} />
//           );
//         });
//       case 7:
//         return seniorTechRoutes.map((route) => {
//           return route.element ? (
//             <Route
//               key={uuidv4()}
//               path={route.path}
//               exact={route.exact}
//               name={route.name}
//               element={<route.element />}
//             />
//           ) : (
//             <Navigate to="/login" key={uuidv4()} />
//           );
//         });
//       case 10:
//         return adminRoutes.map((route) => {
//           return route.element ? (
//             <Route
//               key={uuidv4()}
//               path={route.path}
//               exact={route.exact}
//               name={route.name}
//               element={<route.element />}
//             />
//           ) : (
//             <Navigate to="/login" key={uuidv4()} />
//           );
//         });
//       default:
//         return <Navigate to="/login" key={uuidv4()} />;
//     }
//   }
// };
const AllRoutes = () => {
  const { routes, currentSecurity } = useRoutes();
  return (
    <SecurityProvider currentSecurity={currentSecurity}>
      <Routes>
        {routes}
        {/* {renderSwitch(user)} */}
      </Routes>
    </SecurityProvider>
  );
};

export default AllRoutes;
