import
  {
    ADD_THIRD_PARTY_COMPANY_DETAILS,
    ADD_THIRD_PARTY_COMPANY_DETAILS_FAIL,
    ADD_THIRD_PARTY_COMPANY_DETAILS_SUCCESS, EDIT_THIRD_PARTY_COMPANY,
    EDIT_THIRD_PARTY_COMPANY_FAIL,
    EDIT_THIRD_PARTY_COMPANY_SUCCESS,
    UPDATE_THIRD_PARTY_COMPANY_DETAILS,
    UPDATE_THIRD_PARTY_COMPANY_DETAILS_FAIL,
    UPDATE_THIRD_PARTY_COMPANY_DETAILS_SUCCESS
  } from "./actionTypes";

const initialState = {
  loading: false,
  message: "",
  error: "",
  thirdPartyCompanyData: [],
  editThirdPartyCompanyData: {},
  updateThirdPartyCompanyData: {},
  addThirdPartyCompanyData: {},
};

const ThirdPartyCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case EDIT_THIRD_PARTY_COMPANY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_THIRD_PARTY_COMPANY_SUCCESS:
      state = {
        ...state,
        loading: false,
        editThirdPartyCompanyData: action.payload,
      };
      break;
    case EDIT_THIRD_PARTY_COMPANY_FAIL:
      state = {
        ...state,
        loading: false,
        editThirdPartyCompanyData: {},
        error: {
          message: action.payload,
        },
      };
      break;

    case UPDATE_THIRD_PARTY_COMPANY_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_THIRD_PARTY_COMPANY_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        updateThirdPartyCompanyData: action.payload,
      };
      break;
    case UPDATE_THIRD_PARTY_COMPANY_DETAILS_FAIL:
      state = {
        ...state,
        loading: false,
        updateThirdPartyCompanyData: {},
        error: {
          message: action.payload,
        },
      };
      break;

    case ADD_THIRD_PARTY_COMPANY_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_THIRD_PARTY_COMPANY_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        addThirdPartyCompanyData: action.payload,
      };
      break;
    case ADD_THIRD_PARTY_COMPANY_DETAILS_FAIL:
      state = {
        ...state,
        loading: false,
        addThirdPartyCompanyData: {},
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ThirdPartyCompanyReducer;
