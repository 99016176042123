import Loadable from "react-loadable"; 

import { Loading } from "../common/navigation";

const LeadsList = Loadable({
  loader: () => import("./components/LeadsList"),
  loading: Loading,
});
const EditLeads = Loadable({
  loader: () => import("./components/EditLeads"),
  loading: Loading,
});

export const routes = [
  {
    path: "/leads_list",
    exact: true,
    name: "Book Job Online",
    element: LeadsList,
  },
  {
    path: "/edit_lead/:id",
    exact: true,
    name: "Edit Leads",
    element: EditLeads,
  },
];

// export * from "./components";
