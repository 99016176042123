import * as actions from "../actionType";

const labourReportState = {
  labourHoursReport: {
    hoursChart: null,
    hoursTable: [],
    error: false,
    loading: false,
  },
  effectiveLabourReport: {
    effectiveChart: null,
    effectiveTable: [],
    error: false,
    loading: false,
  }   
};

export const LabourReportsReducer = (state = labourReportState, action) => {

  switch (action.type) {
    case actions.LABOUR_HOURS:
      return {
        ...state,
        labourHoursReport: {
          ...state.labourHoursReport,
          loading: true,
          error: false,
        },
      };
    case actions.LABOUR_HOURS_SUCCESS:
      return {
        ...state,
        labourHoursReport: {
          ...state.labourHoursReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.LABOUR_HOURS_FAIL:
      return {
        ...state,
        labourHoursReport: {
          ...state.labourHoursReport,
          loading: false,
          error: true,
        },
      };


    case actions.EFFECTIVE_LABOUR_RATE:
      return {
        ...state,
        effectiveLabourReport: {
          ...state.effectiveLabourReport,
          loading: true,
          error: false,
        },
      };
    case actions.EFFECTIVE_LABOUR_RATE_SUCCESS:
      return {
        ...state,
        effectiveLabourReport: {
          ...state.effectiveLabourReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.EFFECTIVE_LABOUR_RATE_FAIL:
      return {
        ...state,
        effectiveLabourReport: {
          ...state.effectiveLabourReport,
          loading: false,
          error: true,
        },
      };
      
    
    default:
      return state;
  }
};
