import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_QUOTE_BOOKED_DETAILS,
  GET_MESSAGE,
  GET_INSPECTION_VIEW,
  GET_QUOTE_INTERNAL_NOTES,
  GET_QUOTE_INTERNAL_NOTES_SCRIPTS,
  GET_EMAIL_QUOTE,
  GET_EMAIL_PREVIEW_QUOTE,
  GET_SCRIPT_LIST,
  CREATE_QUOTE,
  QUOTE_UPDATED_DATA,
  UPDATE_QUOTE,
} from "./actionTypes";
import {
  getQuoteBookedDetailsSuccess,
  getQuoteBookedDetailsFail,
  getMessageSuccess,
  getMessageFail,
  getInspectionViewSuccess,
  getInspectionViewFail,
  getQuoteInternalNotesSuccess,
  getQuoteInternalNotesFail,
  getQuoteInternalNotesScriptsSuccess,
  getQuoteInternalNotesScriptsFail,
  getEmailQuoteSuccess,
  getEmailQuoteFail,
  getScriptListSuccess,
  getScriptListFail,
  createQuoteSuccess,
  createQuoteFail,
  getEmailPreviewQuoteSuccess,
  getEmailPreviewQuoteFail,
  quoteUpdatedDataSuccess,
  quoteUpdatedDataFail,
  updatedQuoteSuccess,
  updatedQuoteFail,
} from "./actions";

import {
  getQuoteBookedDetails,
  getNotification,
  getInspectionView,
  getQuoteInternalNotes,
  getInternalNotesScripts,
  addQuote,
  updateQuote,
} from "../../helpers/backendHelper";

function* onGetQuoteBookedDetails(data) {
  try {
    const response = yield call(getQuoteBookedDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getQuoteBookedDetailsSuccess(response.data.CustomerQuotes[0])
        );
      } else {
        yield put(getQuoteBookedDetailsFail(response.message));
      }
    } else {
      yield put(getQuoteBookedDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getQuoteBookedDetailsFail(error.response));
  }
}

function* onGetMessage(data) {
  try {
    const response = yield call(getNotification, data);
    if (response) {
      if (response.code === 200) {
        yield put(getMessageSuccess(response.data.NotificationsData));
      } else {
        yield put(getMessageFail(response.message));
      }
    } else {
      yield put(getMessageFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getMessageFail(error.response));
  }
}

function* onGetInspectionView(data) {
  try {
    const response = yield call(getInspectionView, data);
    if (response) {
      if (response.code === 200) {
        yield put(getInspectionViewSuccess(response.data.InspInspections));
      } else {
        yield put(getInspectionViewFail(response.message));
      }
    } else {
      yield put(getInspectionViewFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionViewFail(error.response));
  }
}

function* onGetQuoteInternalNotes(data) {
  try {
    const response = yield call(getQuoteInternalNotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getQuoteInternalNotesSuccess(response.data.QuoteInternalNotes)
        );
      } else {
        yield put(getQuoteInternalNotesFail(response.message));
      }
    } else {
      yield put(getQuoteInternalNotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getQuoteInternalNotesFail(error.response));
  }
}

function* onGetQuoteInternalNotesScripts(data) {
  try {
    const response = yield call(getInternalNotesScripts, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getQuoteInternalNotesScriptsSuccess(
            response.data.WorkshopScriptsDetails
          )
        );
      } else {
        yield put(getQuoteInternalNotesScriptsFail(response.message));
      }
    } else {
      yield put(getQuoteInternalNotesScriptsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getQuoteInternalNotesScriptsFail(error.response));
  }
}

function* onGetEmailQuote(data) {
  try {
    const response = yield call(getQuoteBookedDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getEmailQuoteSuccess(response.data.CustomerQuotes[0]));
      } else {
        yield put(getEmailQuoteFail(response.message));
      }
    } else {
      yield put(getEmailQuoteFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getEmailQuoteFail(error.response));
  }
}

function* onGetScriptList(data) {
  try {
    const response = yield call(getInternalNotesScripts, data);
    if (response) {
      if (response.code === 200) {
        yield put(getScriptListSuccess(response.data.WorkshopScriptsDetails));
      } else {
        yield put(getScriptListFail(response.message));
      }
    } else {
      yield put(getScriptListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getScriptListFail(error.response));
  }
}

function* onCreateQuote(data) {
  try {
    const response = yield call(addQuote, data);
    if (response) {
      if (response.code === 200) {
        yield put(createQuoteSuccess(response));
      } else {
        yield put(createQuoteFail(response.message));
      }
    } else {
      yield put(createQuoteFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(createQuoteFail(error.response));
  }
}

function* onGetEmailPreviewQuote(data) {
  try {
    const response = yield call(getQuoteBookedDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getEmailPreviewQuoteSuccess(response.data.CustomerQuotes[0]));
      } else {
        yield put(getEmailPreviewQuoteFail(response.message));
      }
    } else {
      yield put(getEmailPreviewQuoteFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getEmailPreviewQuoteFail(error.response));
  }
}

function* onUpdateQuoteData(data) {
  try {
    yield put(quoteUpdatedDataSuccess(data.payload));
  } catch (error) {
    yield put(quoteUpdatedDataFail(error.response));
  }
}

function* onUpdateQuote(data) {
  try {
    const response = yield call(updateQuote, data);
    if (response) {
      if (response.code === 200) {
        yield put(updatedQuoteSuccess(response.data.CustomerQuotes[0]));
      } else {
        yield put(updatedQuoteFail(response.message));
      }
    } else {
      yield put(updatedQuoteFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updatedQuoteFail(error.response));
  }
}

function* QuoteDetails() {
  yield takeLatest(GET_QUOTE_BOOKED_DETAILS, onGetQuoteBookedDetails);
  yield takeLatest(GET_MESSAGE, onGetMessage);
  yield takeLatest(GET_INSPECTION_VIEW, onGetInspectionView);
  yield takeLatest(GET_QUOTE_INTERNAL_NOTES, onGetQuoteInternalNotes);
  yield takeLatest(
    GET_QUOTE_INTERNAL_NOTES_SCRIPTS,
    onGetQuoteInternalNotesScripts
  );
  yield takeLatest(GET_EMAIL_QUOTE, onGetEmailQuote);
  yield takeLatest(GET_SCRIPT_LIST, onGetScriptList);
  yield takeLatest(CREATE_QUOTE, onCreateQuote);
  yield takeLatest(GET_EMAIL_PREVIEW_QUOTE, onGetEmailPreviewQuote);
  yield takeLatest(QUOTE_UPDATED_DATA, onUpdateQuoteData);
  yield takeLatest(UPDATE_QUOTE, onUpdateQuote);
}

export default QuoteDetails;
