import { Button } from "react-bootstrap";


let ColorStatus = ({ continueJob, statusColor, currentTextCell, currentColourCell
}) => {
    return (
        <>
            <div className="col-md-5">
                <div style={{ textAlign: "end" }} >
                    <Button style={{ background: statusColor[currentColourCell], borderColor: statusColor[currentColourCell] }}
                        type="button"
                        variant="outline-secondary"
                        className="btn-outline rounded-btn statusDetail2"
                    >
                        {currentTextCell}
                    </Button>
                </div>
            </div>



        </>
    );
};

export default ColorStatus;
