import moment from "moment";
import { useEffect, useState } from "react";

export function useDates() {
     
     const [currentDate, setCurrentDate] = useState(moment().format('DD-MM-YYYY'));
     const [dates, setDates] = useState([]);
     const [currentActive, setCurrentActive] = useState(currentDate);

     const changeDate = (key) => {
          let cur = moment(currentDate, 'DD-MM-YYYY').subtract(7, "days").format('DD-MM-YYYY');
          if (key === "next") {
               cur = moment(currentDate, 'DD-MM-YYYY').add(7, "days").format('DD-MM-YYYY');
          }
          setCurrentDate(cur);
          setCurrentActive(cur);
     };

     const onNext = () => changeDate("next");
     const onPrevious = () => changeDate();

     const updateActive = (date) => setCurrentActive(date);

     //   const dates = [...previousThreeData, currentDate, ...nextThreeData];

     useEffect(() => {

          let previousDates = [];
          for (let i = 1; i <= 3; i++) {
               let date = moment(currentDate, 'DD-MM-YYYY');
               date.subtract(i, "days")
               previousDates.unshift(date.format('DD-MM-YYYY'));
          }

          let nextDates = [];
          for (let i = 1; i <= 3; i++) {
               let date = moment(currentDate, 'DD-MM-YYYY');
               date.add(i, "days");
               nextDates.push(date.format('DD-MM-YYYY'));
          }

          const newDates = [...previousDates, currentDate, ...nextDates];
          setDates(newDates);
     }, [currentDate])

     const updateCurrentDate = (date) => {
          setCurrentDate(moment(date).format('DD-MM-YYYY'));
          setCurrentActive(moment(date).format('DD-MM-YYYY'));
     }

     return {
          dates,
          onNext,
          onPrevious,
          updateActive,
          currentDate,
          currentActive,
          updateCurrentDate
     };
}
