import * as ACTION from './actionTypes';

// CREATE MARKETING

export const createMarketing = () => {
  return {
    type: ACTION.CREATE_MARKETING,
  };
};

export const createMarketingSuccess = (data) => {
  return {
    type: ACTION.CREATE_MARKETING_SUCCESS,
    payload: data,
  };
};

export const createMarketingFail = (error) => {
  return {
    type: ACTION.CREATE_MARKETING_FAIL,
    payload: error,
  };
};
// CREATE MARKETING


// SCHEDULED MARKETING
export const getScheduledMarketingList = () => {
  return {
    type: ACTION.GET_SCHEDULED_MARKETING_LIST,
   
  };
};

export const getScheduledMarketingListSuccess = (data) => {
  return {
    type: ACTION.GET_SCHEDULED_MARKETING_LIST_SUCCESS,
    payload: data,
  };
};
export const getScheduledMarketingCount = () => {
  return {
    type: ACTION.GET_SCHEDULED_MARKETING_LIST_COUNT,
  };
};
export const getScheduledMarketingCountSuccess = (data) => {
  return {
    type: ACTION.GET_SCHEDULED_MARKETING_LIST_COUNT_SUCCESS,
    payload: data,
  };
};
export const getScheduledMarketingCountFail = (error) => {
  return {
    type: ACTION.GET_SCHEDULED_MARKETING_LIST_COUNT_FAIL,
    payload: error,
  };
};

export const getScheduledMarketingFail = (error) => {
  return {
    type: ACTION.GET_SCHEDULED_MARKETING_LIST_FAIL,
    payload: error,
  };
};
// SCHEDULED MARKETING


//DELETE SCHEDULED MARKETING

export const deleteArchiveLiveMarketing = () => {
  return {
    type: ACTION.DELETE_LIVE_MARKETING_LIST,
  };
};

export const deleteArchiveLiveMarketingSuccess = (action_type) => {
  return {
    type: ACTION.DELETE_LIVE_MARKETING_LIST_SUCCESS,
    payload: {
      action_type,
    },
  };
};

export const deleteArchiveLiveMarketingFail = () => {
  return {
    type: ACTION.DELETE_LIVE_MARKETING_LIST_FAIL,
  };
};
//DELETE SCHEDULED MARKETING







// LIVE MARKETING
export const getLiveMarketingList = () => {
  return {
    type: ACTION.GET_LIVE_MARKETING_LIST,
    
  };
};

export const getLivetMarketingListSuccess = (data) => {
  return {
    type: ACTION.GET_LIVE_MARKETING_LIST_SUCCESS,
    payload: data,
  };
};

export const getLivetMarketingListfail = (error) => {
  return {
    type: ACTION.GET_LIVE_MARKETING_LIST_FAIL,
    payload: error,
  };
};
// LIVE MARKETING


//DELETE SCHEDULED MARKETING

export const deleteArchiveScheduledMarketing = () => {
  return {
    type: ACTION.DELETE_SCHEDULED_MARKETING_LIST,
  };
};

export const deleteArchiveScheduledMarketingSuccess = (action_type) => {
  return {
    type: ACTION.DELETE_SCHEDULED_MARKETING_LIST_SUCCESS,
    payload: {
      action_type,
    },
  };
};

export const deleteArchiveScheduledMarketingFail = () => {
  return {
    type: ACTION.DELETE_SCHEDULED_MARKETING_LIST_FAIL,
  };
};
//DELETE SCHEDULED MARKETING

// COMPLETED MARKETING


export const getCompletedMarketingList = () => {
  return {
    type: ACTION.GET_COMPLETED_MARKETING_LIST,
    
  };
};

export const getCompletedMarketingListSuccess = (data) => {
  return {
    type: ACTION.GET_COMPLETED_MARKETING_LIST_SUCCESS,
    payload: data,
  };
};

export const getCompletedMarketingListFail = (data) => {
  return {
    type: ACTION.GET_COMPLETED_MARKETING_LIST_FAIL,
    payload: data,
  };
};
// COMPLETED MARKETING

//DELETE SCHEDULED MARKETING

export const deleteArchiveCompletedMarketing = () => {
  return {
    type: ACTION.DELETE_COMPLETED_MARKETING_LIST,
  };
};

export const deleteArchiveCompletedMarketingSuccess = (action_type) => {
  return {
    type: ACTION.DELETE_COMPLETED_MARKETING_LIST_SUCCESS,
    payload: {
      action_type,
    },
  };
};

export const deleteArchiveCompletedMarketingFail = () => {
  return {
    type: ACTION.DELETE_COMPLETED_MARKETING_LIST_FAIL,
  };
};
//DELETE SCHEDULED MARKETING

//VIEW MARKETING

export const viewMarketing = () => {
  return {
    type: ACTION.VIEW_MARKETING,
  };
};

export const viewMarketingSuccess = (data) => {
  return {
    type: ACTION.VIEW_MARKETING_SUCCESS,
    payload: data,
  };
};

export const viewMarketingFail = () => {
  return {
    type: ACTION.VIEW_MARKETING_FAIL,
  };
};
//VIEW MARKETING


//EDIt MARKETING

export const editMarketing = () => {
  return {
    type: ACTION.EDIT_MARKETING,
  };
};

export const editMarketingSuccess = (data) => {
  return {
    type: ACTION.EDIT_MARKETING_SUCCESS,
    payload: data,
  };
};

export const editMarketingFail = () => {
  return {
    type: ACTION.EDIT_MARKETING_FAIL,
  };
};
//EDIt MARKETING