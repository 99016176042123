export const GET_JOB_BOOKED_DETAILS = "GET_JOB_BOOKED_DETAILS";
export const GET_JOB_BOOKED_DETAILS_SUCCESS = "GET_JOB_BOOKED_DETAILS_SUCCESS";
export const GET_JOB_BOOKED_DETAILS_FAIL = "GET_JOB_BOOKED_DETAILS_FAIL";

export const GET_JOB_BOOKED_KEY_TAGS = "GET_JOB_BOOKED_KEY_TAGS";
export const GET_JOB_BOOKED_KEY_TAGS_SUCCESS =
  "GET_JOB_BOOKED_KEY_TAGS_SUCCESS";
export const GET_JOB_BOOKED_KEY_TAGS_FAIL = "GET_JOB_BOOKED_KEY_TAGS_FAIL";

export const GET_REGO_LOOKUP_DATA = "GET_REGO_LOOKUP_DATA";
export const GET_REGO_LOOKUP_DATA_SUCCESS = "GET_REGO_LOOKUP_DATA_SUCCESS";
export const GET_REGO_LOOKUP_DATA_FAIL = "GET_REGO_LOOKUP_DATA_FAIL";

export const UPDATE_JOB_TIMER = "UPDATE_JOB_TIMER";
export const UPDATE_JOB_TIMER_SUCCESS = "UPDATE_JOB_TIMER_SUCCESS";
export const UPDATE_JOB_TIMER_FAIL = "UPDATE_JOB_TIMER_FAIL";

export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS";
export const UPDATE_VEHICLE_FAIL = "UPDATE_VEHICLE_FAIL";

export const GET_TOTAL_TIMER = "GET_TOTAL_TIMER";
export const GET_TOTAL_TIMER_SUCCESS = "GET_TOTAL_TIMER_SUCCESS";
export const GET_TOTAL_TIMER_FAIL = "GET_TOTAL_TIMER_FAIL";

export const GET_VEHICLE_HISTORY = "GET_VEHICLE_HISTORY";
export const GET_VEHICLE_HISTORY_SUCCESS = "GET_VEHICLE_HISTORY_SUCCESS";
export const GET_VEHICLE_HISTORY_FAIL = "GET_VEHICLE_HISTORY_FAIL";

export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAIL = "GET_MESSAGE_FAIL";

export const GET_INSPECTION_VIEW = "GET_INSPECTION_VIEW";
export const GET_INSPECTION_VIEW_SUCCESS = "GET_INSPECTION_VIEW_SUCCESS";
export const GET_INSPECTION_VIEW_FAIL = "GET_INSPECTION_VIEW_FAIL";

export const DELETE_INSPECTION = "DELETE_INSPECTION";
export const DELETE_INSPECTION_SUCCESS = "DELETE_INSPECTION_SUCCESS";
export const DELETE_INSPECTION_FAIL = "DELETE_INSPECTION_FAIL";

/********************************************
 ░░░░ JOB NOTES ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
 ********************************************/

/* GET JOB NOTS / INVOICE COMMENT START */
export const GET_JOB_NOTES = "GET_JOB_NOTES";
export const GET_JOB_NOTES_SUCCESS = "GET_JOB_NOTES_SUCCESS";
export const GET_JOB_NOTES_FAIL = "GET_JOB_NOTES_FAIL";
/* GET JOB NOTS / INVOICE COMMENT END */

export const GET_INTERNAL_NOTES = "GET_INTERNAL_NOTES";
export const GET_INTERNAL_NOTES_SUCCESS = "GET_INTERNAL_NOTES_SUCCESS";
export const GET_INTERNAL_NOTES_FAIL = "GET_INTERNAL_NOTES_FAIL";

export const GET_INTERNAL_NOTES_SCRIPTS = "GET_INTERNAL_NOTES_SCRIPTS";
export const GET_INTERNAL_NOTES_SCRIPTS_SUCCESS =
  "GET_INTERNAL_NOTES_SCRIPTS_SUCCESS";
export const GET_INTERNAL_NOTES_SCRIPTS_FAIL =
  "GET_INTERNAL_NOTES_SCRIPTS_FAIL";
/* ADD JOB NOTS / INVOICE COMMENT START */
export const ADD_JOB_NOTES = "ADD_JOB_NOTES";
export const ADD_JOB_NOTES_SUCCESS = "ADD_JOB_NOTES_SUCCESS";
export const ADD_JOB_NOTES_FAIL = "ADD_JOB_NOTES_FAIL";
/* ADD JOB NOTS / INVOICE COMMENT END */

/* UPDATE JOB NOTS / INVOICE COMMENT START */
export const UPDATE_JOB_NOTES = "UPDATE_JOB_NOTES";
export const UPDATE_JOB_NOTES_SUCCESS = "UPDATE_JOB_NOTES_SUCCESS";
export const UPDATE_JOB_NOTES_FAIL = "UPDATE_JOB_NOTES_FAIL";
/* UPDATE JOB NOTS / INVOICE COMMENT END */

/********************************************
 ░░░░ INSPECTION ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
 ********************************************/
// Inspections
export const GET_INSPECTIONS = "GET_INSPECTIONS";
export const GET_INSPECTIONS_SUCCESS = "GET_INSPECTIONS_SUCCESS";
export const GET_INSPECTIONS_FAIL = "GET_INSPECTIONS_FAIL";

// Inspection questions answers
/*
  - When user changes the questions answers will be updated here
  - Default will be loaded from the inspection if exists else it will be blank
*/
export const GET_INSPECTION_QUESTIONS_ANSWERS =
  "GET_INSPECTION_QUESTIONS_ANSWERS";
export const UPDATE_INSPECTION_QUESTIONS_ANSWERS =
  "UPDATE_INSPECTION_QUESTIONS_ANSWERS";

// Inspection templates
export const GET_INSPECTION_TEMPLATES = "GET_INSPECTION_TEMPLATES";
export const GET_INSPECTION_TEMPLATES_SUCCESS =
  "GET_INSPECTION_TEMPLATES_SUCCESS";
export const GET_INSPECTION_TEMPLATES_FAIL = "GET_INSPECTION_TEMPLATES_FAIL";

//Inspection Appove
export const APPROVE_MANUAL_INSPECTION = "APPROVE_MANUAL_INSPECTION";
export const APPROVE_MANUAL_INSPECTION_SUCCESS =
  "APPROVE_MANUAL_INSPECTION_SUCCESS";
export const APPROVE_MANUAL_INSPECTION_FAIL = "APPROVE_MANUAL_INSPECTION_FAIL";

//Inspection Group
export const GET_INSPECTION_GROUP = "GET_INSPECTION_GROUP";
export const GET_INSPECTION_GROUP_SUCCESS = "GET_INSPECTION_GROUP_SUCCESS";
export const GET_INSPECTION_GROUP_FAIL = "GET_INSPECTION_GROUP_FAIL";

//Add Approved inspection parts
export const ADD_PARTS_MANUAL_INSPECTION_APPROVED =
  "ADD_PARTS_MANUAL_INSPECTION_APPROVED";
export const ADD_PARTS_MANUAL_INSPECTION_APPROVED_SUCCESS =
  "ADD_PARTS_MANUAL_INSPECTION_APPROVED_SUCCESS";
export const ADD_PARTS_MANUAL_INSPECTION_APPROVED_FAIL =
  "ADD_PARTS_MANUAL_INSPECTION_APPROVED_FAIL";

//Inspection Resend
export const RESEND_INSPECTION = "RESEND_INSPECTION";
export const RESEND_INSPECTION_SUCCESS = "RESEND_INSPECTION_SUCCESS";
export const RESEND_INSPECTION_FAIL = "RESEND_INSPECTION_FAIL";

// Technicians and approved by service users
export const GET_TECHNICIAN_LIST = "GET_TECHNICIAN";
export const GET_TECHNICIAN_LIST_SUCCESS = "GET_TECHNICIAN_SUCCESS";
export const GET_TECHNICIAN_LIST_FAIL = "GET_TECHNICIAN_FAIL";

// Technicians and approved by service users
export const GET_ATTACHMENT = "GET_ATTACHMENT";
export const GET_ATTACHMENT_SUCCESS = "GET_ATTACHMENT_SUCCESS";
export const GET_ATTACHMENT_FAIL = "GET_ATTACHMENT_FAIL";

// Technicians and approved by service users
export const DEFAULT_PRICE_SUMMARY = "DEFAULT_PRICE_SUMMARY";
export const DEFAULT_PRICE_SUMMARY_SUCCESS = "DEFAULT_PRICE_SUMMARY_SUCCESS";
export const DEFAULT_PRICE_SUMMARY_FAIL = "DEFAULT_PRICE_SUMMARY_FAIL";

//Save Inspection
export const SAVE_INSPECTION = "SAVE_INSPECTION";
export const SAVE_INSPECTION_SUCCESS = "SAVE_INSPECTION_SUCCESS";
export const SAVE_INSPECTION_FAIL = "SAVE_INSPECTION_FAIL";
export const CLEAR_SAVE_INSPECTION_STATUS = "CLEAR_SAVE_INSPECTION_STATUS";

export const GET_INSPECTION_DETAIL = "GET_INSPECTION_DETAIL";
export const GET_INSPECTION_DETAIL_SUCCESS = "GET_INSPECTION_DETAIL_SUCCESS";
export const GET_INSPECTION_DETAIL_FAIL = "GET_INSPECTION_DETAIL_FAIL";

export const APPROVE_INSPECTIONS = "APPROVE_INSPECTIONS";
export const APPROVE_INSPECTIONS_SUCCESS = "APPROVE_INSPECTIONS_SUCCESS";
export const APPROVE_INSPECTIONS_FAIL = "APPROVE_INSPECTIONS_FAIL";

export const CLEAR_JOB_DATA = "CLEAR_JOB_DATA";

export const UPDATE_JOB_INFO = "UPDATE_JOB_INFO";

export const CLOCK_IN_JOB = "CLOCK_IN_JOB";
export const CLOCK_IN_JOB_SUCCESS = "CLOCK_IN_JOB_SUCCESS";
export const CLOCK_IN_JOB_FAIL = "CLOCK_IN_JOB_FAIL";

export const CLOCK_OUT_JOB = "CLOCK_OUT_JOB";
export const CLOCK_OUT_JOB_SUCCESS = "CLOCK_OUT_JOB_SUCCESS";
export const CLOCK_OUT_JOB_FAIL = "CLOCK_OUT_JOB_FAIL";

export const PAUSE_TIME_JOB = "PAUSE_TIME_JOB";
export const PAUSE_TIME_JOB_SUCCESS = "PAUSE_TIME_JOB_SUCCESS";
export const PAUSE_TIME_JOB_FAIL = "PAUSE_TIME_JOB_FAIL";

export const RESUME_TIME_JOB = "RESUME_TIME_JOB";
export const RESUME_TIME_JOB_SUCCESS = "RESUME_TIME_JOB_SUCCESS";
export const RESUME_TIME_JOB_FAIL = "RESUME_TIME_JOB_FAIL";

export const JOB_MOUNT = "JOB_MOUNT";
export const JOB_UNMOUNT = "JOB_UNMOUNT";

export const FINALIZE_INVOICE = "FINALIZE_INVOICE";

export const GET_JOB_TAG = "GET_JOB_TAG";
export const GET_JOB_TAG_SUCCESS = "GET_JOB_TAG_SUCCESS";
export const GET_JOB_TAG_FAIL = "GET_JOB_TAG_FAIL";

export const GET_JOBCANNED_LISTS = "GET_JOBCANNED_LISTS";
export const GET_JOBCANNED_LISTS_SUCCESS = "GET_JOBCANNED_LISTS_SUCCESS";
export const GET_JOBCANNED_LISTS_FAIL = "GET_JOBCANNED_LISTS_FAIL";

export const GET_JOB_RECOMMENDATIONS = "GET_JOB_RECOMMENDATIONS";
export const GET_JOB_RECOMMENDATIONS_SUCCESS = "GET_JOB_RECOMMENDATIONS_SUCCESS";
export const GET_JOB_RECOMMENDATIONS_FAIL = "GET_JOB_RECOMMENDATIONS_FAIL";

export const SENT_BOOKINGJOB_SMS = "SENT_BOOKINGJOB_SMS";
export const SENT_BOOKINGJOB_SMS_SUCCESS = "SENT_BOOKINGJOB_SMS_SUCCESS";
export const SENT_BOOKINGJOB_SMS_FAIL = "SENT_BOOKINGJOB_SMS_FAIL";

export const ADD_JOB_RECOMMENDATIONS = "ADD_JOB_RECOMMENDATIONS";
export const ADD_JOB_RECOMMENDATIONS_SUCCESS = "ADD_JOB_RECOMMENDATIONS_SUCCESS";
export const ADD_JOB_RECOMMENDATIONS_FAIL = "ADD_JOB_RECOMMENDATIONS_FAIL";

export const GET_PARENT_JOB_DETAILS = "GET_PARENT_JOB_DETAILS";
export const GET_PARENT_JOB_DETAILS_SUCCESS = "GET_PARENT_JOB_DETAILS_SUCCESS";
export const GET_PARENT_JOB_DETAILS_FAIL = "GET_PARENT_JOB_DETAILS_FAIL";

export const UPDATE_MANY_JOBS = "UPDATE_MANY_JOBS";
export const UPDATE_MANY_JOBS_SUCCESS = "UPDATE_MANY_JOBS_SUCCESS";
export const UPDATE_MANY_JOBS_FAIL = "UPDATE_MANY_JOBS_FAIL";
export const GET_JOB_CLOCKING_HISTORY_DATA = "GET_JOB_CLOCKING_HISTORY_DATA";
export const GET_JOB_CLOCKING_HISTORY_DATA_SUCCESS = "GET_JOB_CLOCKING_HISTORY_DATA_SUCCESS";
export const GET_JOB_CLOCKING_HISTORY_DATA_FAIL = "GET_JOB_CLOCKING_HISTORY_DATA_FAIL";
