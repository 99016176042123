import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
// import { precise_round  } from "utils/numberFunction";
import { decimalRegex } from "utils/validationRegex";
import { useCalculate } from "./useCalculate";

const PriceCalculation = (props) => {

  //fetch session storage data
  const { totalCost, totalRetail } = props;
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let currency = storedData.currency;

  //workshop id
  let workshop_id = getWorkshopId();
  let part_gst = getSessionData('part_gst');
  let default_margin =
    storedData.default_margin !== 0
      ? getSessionData('default_margin')
      : ""; // workshop default_margin
  let default_markup =
    storedData.default_markup !== 0
      ? getSessionData('default_markup')
      : "0"; // workshop default_markup

  const [change, setChange] = useState(false);

  const { handleChange, values, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      applyGst: true,
      costPrice: parseFloat(0).toFixed(2),
      gstPrice: parseFloat(0).toFixed(2),
      productMargin: parseFloat(+default_margin).toFixed(2),
      productMarkup: parseFloat(+default_markup).toFixed(2),
      profitPrice: parseFloat(0).toFixed(2),
      retailPrice: parseFloat(0).toFixed(2),
      sellPrice: parseFloat(0).toFixed(2),
    },
  });

  useEffect(() => {
    if (props?.partListData) {
      let partListData = props.partListData;
      let applyGst = partListData.gst_price > 0 ? true : false;
      setFieldValue("applyGst", applyGst);
      setFieldValue(
        "costPrice",
        parseFloat(partListData.cost_price).toFixed(2)
      );

      setFieldValue("gstPrice", parseFloat(partListData.gst_price).toFixed(2));
      setFieldValue(
        "productMargin",
        parseFloat(partListData.margin).toFixed(2)
      );
      setFieldValue(
        "productMarkup",
        parseFloat(partListData.cost_markup_price).toFixed(2)
      );
      setFieldValue(
        "profitPrice",
        parseFloat(partListData.profit_price).toFixed(2)
      );
      setFieldValue(
        "retailPrice",
        parseFloat(partListData.retail_price).toFixed(2)
      );
      setFieldValue(
        "sellPrice",
        parseFloat(partListData.sell_price).toFixed(2)
      );
    }
  }, [props?.partListData]);

  const calculate = useCalculate({
    values,
    part_gst,
    setFieldValue,
  });

  function setBlurPrice(key, value){
    setFieldValue(key, isNaN(parseFloat(value).toFixed(2)) ? "" : parseFloat(value).toFixed(2));
  }

  useEffect(() => {
    props?.setPriceDetail?.(values);
  }, [values]);

  useEffect(() => {
    if (totalCost && totalRetail) {
      setFieldValue("costPrice", totalCost.toFixed(2));
      setFieldValue("retailPrice", totalRetail.toFixed(2));
      setChange(true);
    }
  }, [totalCost, totalRetail, setFieldValue]);

  useEffect(() => {
    if (change) {
      calculate("retailPrice", values.retailPrice);
      setChange(false);
    }
  }, [change]);

  return (
    <>
      <div className="pricing-detail">
        <div className="heading-block">
          <h3>Pricing Details</h3>
        </div>
        <div className="d-flex">
          <div className="left-block">
            <div className="form-outer form-align-start">
              <Form.Group className="form-group width-33 mb-3">
                <label>Cost Price ({currency})*</label>
                <Form.Control
                  type="text"
                  required
                  name="costPrice"
                  value={values.costPrice || ""}
                  onChange={(e) => {
                    if (!decimalRegex.test(e.target.value)) return;
                    handleChange(e);
                    // calculate(e.target.name, e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    calculate(e.target.name, e.target.value);
                    setBlurPrice(e.target.name, e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group width-33 mb-3">
                <label>Markup %</label>
                <Form.Control
                  type="text"
                  name="productMarkup"
                  value={isNaN(values.productMarkup) ? 0 : values.productMarkup}
                  readOnly
                  disabled
                />
              </Form.Group>
              <Form.Group className="form-group width-33 mb-3">
                <label>Sell Price ({currency})*</label>
                <Form.Control
                  type="text"
                  required
                  value={values.sellPrice || ""}
                  name="sellPrice"
                  onChange={(e) => {
                    if (!decimalRegex.test(e.target.value)) return;
                    handleChange(e);
                    calculate(e.target.name, e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    setBlurPrice(e.target.name, e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </Form.Group>
              <div className="form-group width-50">
                <label>Markup % *</label>
                <div className="custom-perc-group d-flex">
                  <span>
                    <Form.Check className="radio-btn mb-2" type="radio" id="2">
                      <Form.Check.Input
                        type="radio"
                        value="15.00"
                        id="product-15"
                        name="productMarkup"
                        checked={values.productMarkup === "15.00"}
                        onChange={(e) => {
                          if (!decimalRegex.test(e.target.value)) return;
                          handleChange(e);
                          calculate(e.target.name, e.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                      />
                      <Form.Check.Label>
                        15%
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </span>
                  <span>
                    <Form.Check className="radio-btn mb-2" type="radio">
                      <Form.Check.Input
                        type="radio"
                        value="25.00"
                        id="product-25"
                        checked={values.productMarkup === "25.00"}
                        name="productMarkup"
                        onChange={(e) => {
                          if (!decimalRegex.test(e.target.value)) return;
                          handleChange(e);
                          calculate(e.target.name, e.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                      />
                      <Form.Check.Label>
                        25%
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </span>

                  {/* add default markup */}
                  {default_markup && (
                    <span>
                      <Form.Check className="radio-btn mb-2" type="radio">
                        <Form.Check.Input
                          type="radio"
                          value={parseFloat(default_markup).toFixed(2)}
                          id={`product-${default_markup}`}
                          name="productMarkup"
                          checked={
                            values.productMarkup ===
                            parseFloat(default_markup).toFixed(2)
                          }
                          onChange={(e) => {
                            if (!decimalRegex.test(e.target.value)) return;
                            handleChange(e);
                            calculate(e.target.name, e.target.value);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        />
                        <Form.Check.Label>
                          {default_markup}%<span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </span>
                  )}

                  <span>
                    <Form.Control
                      type="text"
                      required
                      name="productMarkup"
                      value={isNaN(values.productMarkup) ? 0 : values.productMarkup}
                      onChange={(e) => {
                        if (!decimalRegex.test(e.target.value)) return;
                        handleChange(e);
                        calculate(e.target.name, e.target.value);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setBlurPrice(e.target.name, e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required.
                    </Form.Control.Feedback>
                  </span>
                </div>
              </div>
              <div className="form-group width-50">
                <label>Margin % *</label>
                <div className="custom-perc-group d-flex">
                  <span>
                    <Form.Check className="radio-btn mb-2" type="radio" id="3">
                      <Form.Check.Input
                        type="radio"
                        value="15.00"
                        id="productMargin-15"
                        name="productMargin"
                        checked={values.productMargin === "15.00"}
                        onChange={(e) => {
                          if (!decimalRegex.test(e.target.value)) return;
                          handleChange(e);
                          calculate(e.target.name, e.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                      />
                      <Form.Check.Label>
                        15%
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </span>
                  <span>
                    <Form.Check className="radio-btn mb-2" type="radio">
                      <Form.Check.Input
                        type="radio"
                        value="25.00"
                        id="productMargin-25"
                        name="productMargin"
                        checked={values.productMargin === "25.00"}
                        onChange={(e) => {
                          if (!decimalRegex.test(e.target.value)) return;
                          handleChange(e);
                          calculate(e.target.name, e.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                      />
                      <Form.Check.Label>
                        25%
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </span>

                  {/* add default margin */}
                  {default_margin && (
                    <span>
                      <Form.Check className="radio-btn mb-2" type="radio">
                        <Form.Check.Input
                          type="radio"
                          value={parseFloat(default_margin).toFixed(2)}
                          id={`productMargin-${default_margin}`}
                          checked={
                            values.productMargin ===
                            parseFloat(default_margin).toFixed(2)
                          }
                          name="productMargin"
                          onChange={(e) => {
                            if (!decimalRegex.test(e.target.value)) return;
                            handleChange(e);
                            calculate(e.target.name, e.target.value);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        />
                        <Form.Check.Label>
                          {default_margin}%<span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </span>
                  )}

                  <span>
                    <Form.Control
                      type="text"
                      required
                      value={isNaN(values.productMargin) ? 0 : values.productMargin}
                      name="productMargin"
                      onChange={(e) => {
                        if (!decimalRegex.test(e.target.value)) return;
                        handleChange(e);
                        calculate(e.target.name, e.target.value);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setBlurPrice(e.target.name, e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required.
                    </Form.Control.Feedback>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="right-block">
            <div className="form-outer form-align-start">
              <Form.Group className="form-group width-50 mb-3">
                <label>Apply GST</label>
                <Form.Check
                  type="switch"
                  name={"applyGst"}
                  checked={values.applyGst}
                  onChange={(e) => {
                    setFieldValue("applyGst", e.target.checked);
                    calculate(e.target.name, e.target.checked);
                  }}
                  className="custom-switch"
                />
              </Form.Group>
              <Form.Group className="form-group width-50 mb-3">
                <label>GST {part_gst}%</label>
                <Form.Control
                  type="text"
                  name="product_gst_price"
                  id="product_gst_price"
                  readOnly
                  value={values.gstPrice || 0}
                />
              </Form.Group>
              <Form.Group className="form-group width-50 mb-3">
                <label>Profit ({currency})</label>
                <Form.Control
                  type="text"
                  name="profitPrice"
                  readOnly
                  value={values.profitPrice || 0}
                />
              </Form.Group>
              <Form.Group className="form-group width-50 mb-3">
                <label>Retail Price ({currency})*</label>
                <Form.Control
                  type="text"
                  required
                  name="retailPrice"
                  value={values.retailPrice || ""}
                  onChange={(e) => {
                    if (!decimalRegex.test(e.target.value)) return;
                    handleChange(e);
                    calculate(e.target.name, e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    setBlurPrice(e.target.name, e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
                <Form.Control
                  type="hidden"
                  name="purchasing_tax"
                  id="purchasing_tax"
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PriceCalculation;
