export const GET_TECHNICIAN_SCHEDULE = "GET_TECHNICIAN_SCHEDULE";
export const GET_TECHNICIAN_SCHEDULE_SUCCESS = "GET_TECHNICIAN_SCHEDULE_SUCCESS";
export const GET_TECHNICIAN_SCHEDULE_FAIL = "GET_TECHNICIAN_SCHEDULE_FAIL";

export const GET_WORKSHOP_OTHER_DETAILS = "GET_WORKSHOP_OTHER_DETAILS";
export const GET_WORKSHOP_OTHER_DETAILS_SUCCESS = "GET_WORKSHOP_OTHER_DETAILS_SUCCESS";
export const GET_WORKSHOP_OTHER_DETAILS_FAIL = "GET_WORKSHOP_OTHER_DETAILS_FAIL";

export const GET_TECHNICIAN_HOUR = "GET_TECHNICIAN_HOUR";
export const GET_TECHNICIAN_HOUR_SUCCESS = "GET_TECHNICIAN_HOUR_SUCCESS";
export const GET_TECHNICIAN_HOUR_FAIL = "GET_TECHNICIAN_HOUR_FAIL";

export const GET_WORKSHOP_BAYS = "GET_WORKSHOP_BAYS";
export const GET_WORKSHOP_BAYS_SUCCESS = "GET_WORKSHOP_BAYS_SUCCESS";
export const GET_WORKSHOP_BAYS_FAIL = "GET_WORKSHOP_BAYS_FAIL";

export const GET_INSURANCE = "GET_INSURANCE";
export const GET_INSURANCE_SUCCESS = "GET_INSURANCE_SUCCESS";
export const GET_INSURANCE_FAIL = "GET_INSURANCE_FAIL";

export const GET_JOB_TAGS = "GET_JOB_TAGS";
export const GET_JOB_TAGS_SUCCESS = "GET_JOB_TAGS_SUCCESS";
export const GET_JOB_TAGS_FAIL = "GET_JOB_TAGS_FAIL";

export const ADD_JOB_TAGS = "ADD_JOB_TAGS";
export const ADD_JOB_TAGS_SUCCESS = "ADD_JOB_TAGS_SUCCESS";
export const ADD_JOB_TAGS_FAIL = "ADD_JOB_TAGS_FAIL";

export const PRINT_JOB_CARD_PDF = "PRINT_JOB_CARD_PDF";
export const PRINT_JOB_CARD_PDF_SUCCESS = "PRINT_JOB_CARD_PDF_SUCCESS";
export const PRINT_JOB_CARD_PDF_FAIL = "PRINT_JOB_CARD_PDF_FAIL";

export const CHECK_AVAILABLE_HOURS = "CHECK_AVAILABLE_HOURS";
export const CHECK_AVAILABLE_HOURS_SUCCESS = "CHECK_AVAILABLE_HOURS_SUCCESS";
export const CHECK_AVAILABLE_HOURS_FAIL = "CHECK_AVAILABLE_HOURS_FAIL";

export const CREATE_QUOTE_TO_JOB = "CREATE_QUOTE_TO_JOB";
export const CREATE_QUOTE_TO_JOB_SUCCESS = "CREATE_QUOTE_TO_JOB_SUCCESS";
export const CREATE_QUOTE_TO_JOB_FAIL = "CREATE_QUOTE_TO_JOB_FAIL";

export const GET_AVAIALABLE_SLOTS = "GET_AVAIALABLE_SLOTS";
export const GET_AVAIALABLE_SLOTS_SUCCESS = "GET_AVAIALABLE_SLOTS_SUCCESS";
export const GET_AVAIALABLE_SLOTS_FAIL = "GET_AVAIALABLE_SLOTS_FAIL";

export const GET_CARRY_OVER_JOB = "GET_CARRY_OVER_JOB";
export const GET_CARRY_OVER_JOB_SUCCESS = "GET_CARRY_OVER_JOB_SUCCESS";
export const GET_CARRY_OVER_JOB_FAIL = "GET_CARRY_OVER_JOB_FAIL";

export const GET_JOBS_KIT_PARTS = "GET_JOBS_KIT_PARTS";
export const GET_JOBS_KIT_PARTS_SUCCESS = "GET_JOBS_KIT_PARTS_SUCCESS";
export const GET_JOBS_KIT_PARTS_FAIL = "GET_JOBS_KIT_PARTS_FAIL";

export const GET_JOBS_KIT_LABOURS = "GET_JOBS_KIT_LABOURS";
export const GET_JOBS_KIT_LABOURS_SUCCESS = "GET_JOBS_KIT_LABOURS_SUCCESS";
export const GET_JOBS_KIT_LABOURS_FAIL = "GET_JOBS_KIT_LABOURS_FAIL";

export const ADD_MANY_JOB_PARTS = "ADD_MANY_JOB_PARTS";
export const ADD_MANY_JOB_PARTS_SUCCESS = "ADD_MANY_JOB_PARTS_SUCCESS";
export const ADD_MANY_JOB_PARTS_FAIL = "ADD_MANY_JOB_PARTS_FAIL";

export const ADD_MANY_JOB_LABOURS = "ADD_MANY_JOB_LABOURS";
export const ADD_MANY_JOB_LABOURS_SUCCESS = "ADD_MANY_JOB_LABOURS_SUCCESS";
export const ADD_MANY_JOB_LABOURS_FAIL = "ADD_MANY_JOB_LABOURS_FAIL";

export const ADD_MANY_JOB_KIT_PARTS = "ADD_MANY_JOB_KIT_PARTS";
export const ADD_MANY_JOB_KIT_PARTS_SUCCESS = "ADD_MANY_JOB_KIT_PARTS_SUCCESS";
export const ADD_MANY_JOB_KIT_PARTS_FAIL = "ADD_MANY_JOB_KIT_PARTS_FAIL";

export const ADD_MANY_JOB_KIT_LABOURS = "ADD_MANY_JOB_KIT_LABOURS";
export const ADD_MANY_JOB_KIT_LABOURS_SUCCESS = "ADD_MANY_JOB_KIT_LABOURS_SUCCESS";
export const ADD_MANY_JOB_KIT_LABOURS_FAIL = "ADD_MANY_JOB_KIT_LABOURS_FAIL";

export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL";

export const GET_SCHEDULE_ACTIVITY = "GET_SCHEDULE_ACTIVITY";
export const GET_SCHEDULE_ACTIVITY_SUCCESS = "GET_SCHEDULE_ACTIVITY_SUCCESS";
export const GET_SCHEDULE_ACTIVITY_FAIL = "GET_SCHEDULE_ACTIVITY_FAIL";

export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_FAIL = "ADD_ACTIVITY_FAIL";

export const ADD_SCHEDULE_ACTIVITY = "ADD_SCHEDULE_ACTIVITY";
export const ADD_SCHEDULE_ACTIVITY_SUCCESS = "ADD_SCHEDULE_ACTIVITY_SUCCESS";
export const ADD_SCHEDULE_ACTIVITY_FAIL = "ADD_SCHEDULE_ACTIVITY_FAIL";

export const ADD_MANY_CREATE_JOB = "ADD_MANY_CREATE_JOB";
export const ADD_MANY_CREATE_JOB_SUCCESS = "ADD_MANY_CREATE_JOB_SUCCESS";
export const ADD_MANY_CREATE_JOB_FAIL = "ADD_MANY_CREATE_JOB_FAIL";

export const DELETE_JOB_ACTIVITY = "DELETE_JOB_ACTIVITY";
export const DELETE_JOB_ACTIVITY_SUCCESS = "DELETE_JOB_ACTIVITY_SUCCESS";
export const DELETE_JOB_ACTIVITY_FAIL = "DELETE_JOB_ACTIVITY_FAIL";

export const DELETE_JOB_SCHEDULE_ACTIVITY = "DELETE_JOB_SCHEDULE_ACTIVITY";
export const DELETE_JOB_SCHEDULE_ACTIVITY_SUCCESS = "DELETE_JOB_SCHEDULE_ACTIVITY_SUCCESS";
export const DELETE_JOB_SCHEDULE_ACTIVITY_FAIL = "DELETE_JOB_SCHEDULE_ACTIVITY_FAIL";

export const GET_MONTH_JOB_LIST = "GET_MONTH_JOB_LIST";
export const GET_MONTH_JOB_LIST_SUCCESS = "GET_MONTH_JOB_LIST_SUCCESS";
export const GET_MONTH_JOB_LIST_FAIL = "GET_MONTH_JOB_LIST_FAIL";

export const UPDATE_CARRY_OVER_JOB = "UPDATE_CARRY_OVER_JOB";
export const UPDATE_CARRY_OVER_JOB_SUCCESS = "UPDATE_CARRY_OVER_JOB_SUCCESS";
export const UPDATE_CARRY_OVER_JOB_FAIL = "UPDATE_CARRY_OVER_JOB_FAIL";

export const GET_TIMER_DETAILS = "GET_TIMER_DETAILS";
export const GET_TIMER_DETAILS_SUCCESS = "GET_TIMER_DETAILS_SUCCESS";
export const GET_TIMER_DETAILS_FAIL = "GET_TIMER_DETAILS_FAIL";

export const GET_OPEN_REQUEST_JOB = "GET_OPEN_REQUEST_JOB";
export const GET_OPEN_REQUEST_JOB_SUCCESS = "GET_OPEN_REQUEST_JOB_SUCCESS";
export const GET_OPEN_REQUEST_JOB_FAIL = "GET_OPEN_REQUEST_JOB_FAIL";

export const GET_QUOTE_DETAILS = "GET_QUOTE_DETAILS";
export const GET_QUOTE_DETAILS_SUCCESS = "GET_QUOTE_DETAILS_SUCCESS";
export const GET_QUOTE_DETAILS_FAIL = "GET_QUOTE_DETAILS_FAIL";

export const GET_QUOTE_KIT_PARTS = "GET_QUOTE_KIT_PARTS";
export const GET_QUOTE_KIT_PARTS_SUCCESS = "GET_QUOTE_KIT_PARTS_SUCCESS";
export const GET_QUOTE_KIT_PARTS_FAIL = "GET_QUOTE_KIT_PARTS_FAIL";

export const GET_QUOTE_KIT_LABOURS = "GET_QUOTE_KIT_LABOURS";
export const GET_QUOTE_KIT_LABOURS_SUCCESS = "GET_QUOTE_KIT_LABOURS_SUCCESS";
export const GET_QUOTE_KIT_LABOURS_FAIL = "GET_QUOTE_KIT_LABOURS_FAIL";

export const GET_INSPECTION_QUE_ANS = "GET_INSPECTION_QUE_ANS";
export const GET_INSPECTION_QUE_ANS_SUCCESS = "GET_INSPECTION_QUE_ANS_SUCCESS";
export const GET_INSPECTION_QUE_ANS_FAIL = "GET_INSPECTION_QUE_ANS_FAIL";

export const GET_BAYS_JOB_COUNT = "GET_BAYS_JOB_COUNT";
export const GET_BAYS_JOB_COUNT_SUCCESS = "GET_BAYS_JOB_COUNT_SUCCESS";
export const GET_BAYS_JOB_COUNT_FAIL = "GET_BAYS_JOB_COUNT_FAIL";

export const UPDATE_JOB_ACTIVITY = "UPDATE_JOB_ACTIVITY";
export const UPDATE_JOB_ACTIVITY_SUCCESS = "UPDATE_JOB_ACTIVITY_SUCCESS";
export const UPDATE_JOB_ACTIVITY_FAIL = "UPDATE_JOB_ACTIVITY_FAIL";
