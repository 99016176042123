import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link, useNavigate } from "react-router-dom";
import "../../style.scss";
import {
  GET_SUPPILERS_LIST,
  GET_SUPPILERS_COUNT,
  SUPPLIER_SEARCH,
} from "../../../../store/Contact/actionTypes";
import { supplierSearchSuccess } from "../../../../store/Contact/actions";
import { useDispatch, useSelector } from "react-redux";
import LoaderImage from "../../../../assets/images/page-loader.svg";
import DeleteRecords from "../../../../utils/deleteRecords";
import { deleteManySupplier } from "../../../../helpers/backendHelper";
import Form from "react-bootstrap/Form";

const SuppilersList = ({ vehicleType }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [search, setSearch] = useState("");
  const [emptyMessage,setEmptyMessage] = useState("No data available in table");
  const [totalRecords, setTotalRecords] = useState(0);
  const [customerData, setCustomerData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const workshop_id = window.atob(storedData.workshop_id);
  const dateFormat = storedData.date_format;
  const deleteMsg = "Supplier deleted successfully";
  const deletePayloadData = {
    payload: {
      action_type: "delete",
      ids: selectedRow,
    },
  };
  const ArchiveMsg = "Supplier Archived successfully";
  const ArchivePayloadData = {
    payload: {
      action_type: "archive",
      ids: selectedRow,
    },
  };

  const { loading, supplierList, supplierCount, supplierSearch } = useSelector(
    (state) => state.Contact
  );

  useEffect(() => {
    loadData();

    dispatch({
      type: GET_SUPPILERS_COUNT,
      payload: {
        get: "all",
        conditions: {
          workshop_id: workshop_id,
          is_deleted: 0,
          "name NOT IN ": ["Repco Supplier", "Burson Supplier", "BNT Supplier"],
        },

        order: {
          id: "desc",
        },
        fields: {
          COUNT: "COUNT(Suppliers.id)",
        },
      },
    });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(supplierSearchSuccess({}));
    };
  }, []);

  const loadData = (page = 1, pageLimit = 10) => {
    dispatch({
      type: GET_SUPPILERS_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: workshop_id,
          is_deleted: 0,
          "name NOT IN ": ["Repco Supplier", "Burson Supplier", "BNT Supplier"],
        },
        limit: pageLimit,
        page: page,
        order: {
          name: "asc",
        },
        fields: [
          "name",
          "id",
          "primary_firstname",
          "primary_lastname",
          "email",
          "phone",
          "suburb",
          "workshop_id",
        ],
      },
    });
  };

  const columns = [
    {
      dataField: "name",
      text: "Supplier Name",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_supplier/${row.id}`);
        },
      },
    },

    {
      dataField: "primary_firstname",
      text: "Contact Name",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_supplier/${row.id}`);
        },
      },
      formatter: (cell, row) => {
        return `${row.primary_firstname || " "} ${row.primary_lastname || ""}`;
      },
    },
    {
      dataField: "email",
      text: "Email",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_supplier/${row.id}`);
        },
      },
    },

    {
      dataField: "phone",
      text: "Contact Number",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_supplier/${row.id}`);
        },
      },
    },
    {
      dataField: "suburb",
      text: "Suburb",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_supplier/${row.id}`);
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <Link to={`/get_branch/${row.id}`} className="" title="Branch">
                  <i className=" fa fa-file-text text-info font-size-20"></i>
                  <span>Branch</span>
                </Link>
              </li>

              <li>
                <Link to={`/edit_supplier/${row.id}`} className="" title="EDIT">
                  <i className="fa fa-pencil text-info"></i>
                  <span>Edit</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setSelectedRow(row.id);
                  setShowDeleteModal(true);
                }}
              >
                <Link to="#" className="delete-link" title="DELETE">
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </Link>
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  const checkBox = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row.id]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.indexOf(row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
    onSelectAll: (isSelected) => {
      if (isSelected) {
        let ids = [];
        for (var key = 0; key < sizePerPage; key++) {
          if (customerData[key] !== undefined) ids.push(customerData[key].id);
        }
        setSelectedRow(ids);
      } else {
        setSelectedRow([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          checked={rest.checked}
          name={rest.name}
          id={rest.id}
          type="checkbox"
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          id={rest.id}
          checked={rest.checked}
          type={mode}
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
    loadData(page, sizePerPage);
  };

  const emptyDataMessage = () => {
    return (
      <div className= {(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setEmptyMessage("No Results Found")
    if (e.target.value !== "") {
      dispatch({
        type: SUPPLIER_SEARCH,
        payload: {
          workshop_id: workshop_id,
          keyword: e.target.value,
        },
      });
    } else {
      dispatch(supplierSearchSuccess({}));
      setCustomerData(supplierList);
      setTotalRecords(supplierCount);
    }
  };
  useEffect(() => {
    setTotalRecords(supplierCount);
  }, [supplierCount]);

  useEffect(() => {
    if (Object.keys(supplierSearch).length !== 0) {
      var text = [];
      let totalSearchCount = 0;

      if(supplierSearch != "No records found..!!"){
        for (var key = 0; key < 10; key++) {
          if (supplierSearch[key] !== undefined) {
            // totalSearchCount = supplierSearch.total_count;
            text.push(supplierSearch[key]);
          }
        }
      }
      
      setTotalRecords(supplierSearch.length);
      setCustomerData(text);
    } else {
      if (supplierList.length !== 0) {
        setCustomerData(supplierList);
      } else if (supplierList.length === 0) {
        setCustomerData([]);
      }
    }
  }, [supplierList, supplierSearch]);

  return (
    <div>
      {/* {loading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )} */}
      <div>
        <div className="table-search-wrapper">
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Search..."
            onChange={(e) => handleSearch(e)}
            value={search}
          />
          <Button
            className="btn-primary rounded-btn"
            onClick={() => navigate("/supplier")}
          >
            <i className="fa fa-plus"></i> Create Supplier
          </Button>
        </div>
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: currentPage,
            sizePerPage: sizePerPage,
            totalSize: parseInt(totalRecords),
            nextPageText: "Next",
            prePageText: "Previous",
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                remote
                keyField="id"
                data={customerData}
                columns={columns}
                onTableChange={onTableChange}
                selectRow={checkBox}
                noDataIndication={emptyDataMessage}
                {...paginationTableProps}
              />
              <div>
                <div className="table-pagination">
                  <div>
                    <span>Show</span>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <span>entries</span>
                  </div>
                  <PaginationTotalStandalone {...paginationProps} />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            </div>
          )}
        </PaginationProvider>

        {showDeleteModal && (
          <DeleteRecords
            showDeleteModal={setShowDeleteModal}
            refreshData={loadData}
            api={deleteManySupplier}
            selectedRow={selectedRow}
            message={deleteMsg}
            payloadData={deletePayloadData}
          />
        )}

        {showArchiveModal && (
          <DeleteRecords
            showDeleteModal={setShowArchiveModal}
            refreshData={loadData}
            api={deleteManySupplier}
            selectedRow={selectedRow}
            message={ArchiveMsg}
            payloadData={ArchivePayloadData}
          />
        )}

        <div className="d-flex custom-table-footer align-center">
          <Button
            variant="warning"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => setShowArchiveModal(true)}
          >
            <i className="la la-archive font-size-18"></i> Archive
          </Button>
          <Button
            variant="danger"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => setShowDeleteModal(true)}
          >
            <i className="la la-times-circle font-size-18"></i> Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuppilersList;
