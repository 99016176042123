import {
  GET_SYNC_ACCOUNT,
  GET_SYNC_ACCOUNT_SUCCESS,
  GET_SYNC_ACCOUNT_FAIL,
  GET_TAX_RATES,
  GET_TAX_RATES_SUCCESS,
  GET_TAX_RATES_FAIL,
  GET_PAYMENT_PROCESSING_FEES,
  GET_PAYMENT_PROCESSING_FEES_SUCCESS,
  GET_PAYMENT_PROCESSING_FEES_FAIL, 
  GET_PAYMENT_TERMS,
  GET_PAYMENT_TERMS_SUCCESS,
  GET_PAYMENT_TERMS_FAIL,
  EDIT_PAYMENT_TERM,
  EDIT_PAYMENT_TERM_SUCCESS,
  EDIT_PAYMENT_TERM_FAIL,
  ADD_PAYMENT_TERM,
  ADD_PAYMENT_TERM_SUCCESS,
  ADD_PAYMENT_TERM_FAIL,
  UPDATE_PAYMENT_TERM,
  UPDATE_PAYMENT_TERM_SUCCESS,
  UPDATE_PAYMENT_TERM_FAIL,
  UPDATE_PAYMENT_PROCESSING_FEES,
  UPDATE_PAYMENT_PROCESSING_FEES_SUCCESS,
  UPDATE_PAYMENT_PROCESSING_FEES_FAIL,
  WORKSHOP_TAX_UPDATE,
  WORKSHOP_TAX_UPDATE_SUCCESS,
  WORKSHOP_TAX_UPDATE_FAIL,
  ADD_WORKSHOP_OTHER_DETAILS,
  ADD_WORKSHOP_OTHER_DETAILS_FAIL,
  ADD_WORKSHOP_OTHER_DETAILS_SUCCESS,
  ADD_MANY_DEPARTMENT_ACCOUNT,
  ADD_MANY_DEPARTMENT_ACCOUNT_SUCCESS,
  ADD_MANY_DEPARTMENT_ACCOUNT_FAIL,
} from "./actionTypes";

export const getSyncAccount = () => {
  return {
    type: GET_SYNC_ACCOUNT,
  };
};

export const getSyncAccountSuccess = (data) => {
  return {
    type: GET_SYNC_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const getSyncAccountFail = (error) => {
  return {
    type: GET_SYNC_ACCOUNT_FAIL,
    payload: error,
  };
};

export const getTaxRates = () => {
  return {
    type: GET_TAX_RATES,
  };
};

export const getTaxRatesSuccess = (data) => {
  return {
    type: GET_TAX_RATES_SUCCESS,
    payload: data,
  };
};

export const getTaxRatesFail = (error) => {
  return {
    type: GET_TAX_RATES_FAIL,
    payload: error,
  };
};

export const getPaymentProcessing = () => {
  return {
    type: GET_PAYMENT_PROCESSING_FEES,
  };
};

export const getPaymentProcessingSuccess = (data) => {
  return {
    type: GET_PAYMENT_PROCESSING_FEES_SUCCESS,
    payload: data,
  };
};

export const getPaymentProcessingFail = (error) => {
  return {
    type: GET_PAYMENT_PROCESSING_FEES_FAIL,
    payload: error,
  };
};

export const getPaymentTerms = () => {
  return {
    type: GET_PAYMENT_TERMS,
  };
};

export const getPaymentTermsSuccess = (data) => {
  return {
    type: GET_PAYMENT_TERMS_SUCCESS,
    payload: data,
  };
};

export const getPaymentTermsFail = (error) => {
  return {
    type: GET_PAYMENT_TERMS_FAIL,
    payload: error,
  };
};

export const editPaymentTerms = () => {
  return {
    type: EDIT_PAYMENT_TERM,
  };
};

export const editPaymentTermsSuccess = (data) => {
  return {
    type: EDIT_PAYMENT_TERM_SUCCESS,
    payload: data,
  };
};

export const editPaymentTermsFail = (error) => {
  return {
    type: EDIT_PAYMENT_TERM_FAIL,
    payload: error,
  };
};

export const addPaymentTerm = () => {
  return {
    type: ADD_PAYMENT_TERM,
  };
};

export const addPaymentTermSuccess = (data) => {
  return {
    type: ADD_PAYMENT_TERM_SUCCESS,
    payload: data,
  };
};

export const addPaymentTermFail = (error) => {
  return {
    type: ADD_PAYMENT_TERM_FAIL,
    payload: error,
  };
};

export const updatePaymentTerm = () => {
  return {
    type: UPDATE_PAYMENT_TERM,
  };
};

export const updatePaymentTermSuccess = (data) => {
  return {
    type: UPDATE_PAYMENT_TERM_SUCCESS,
    payload: data,
  };
};

export const updatePaymentTermFail = (error) => {
  return {
    type: UPDATE_PAYMENT_TERM_FAIL,
    payload: error,
  };
};

export const updatePaymentProcessingFees = () => {
  return {
    type: UPDATE_PAYMENT_PROCESSING_FEES,
  };
};

export const updatePaymentProcessingFeesSuccess = (data) => {
  return {
    type: UPDATE_PAYMENT_PROCESSING_FEES_SUCCESS,
    payload: data,
  };
};

export const updatePaymentProcessingFeesFail = (error) => {
  return {
    type: UPDATE_PAYMENT_PROCESSING_FEES_FAIL,
    payload: error,
  };
};

export const updateWorkShopTaxRate = () => {
  return {
    type: WORKSHOP_TAX_UPDATE,
  };
};

export const updateWorkShopTaxRateSuccess = (data) => {
  return {
    type: WORKSHOP_TAX_UPDATE_SUCCESS,
    payload: data,
  };
};

export const updateWorkShopTaxRateFail = (error) => {
  return {
    type: WORKSHOP_TAX_UPDATE_FAIL,
    payload: error,
  };
};

export const workshopOtherDetails = () => {
  return {
    type: ADD_WORKSHOP_OTHER_DETAILS,
  };
};

export const workshopOtherDetailsSuccess = (data) => {
  return {
    type: ADD_WORKSHOP_OTHER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const workshopOtherDetailsFail = (error) => {
  return {
    type: ADD_WORKSHOP_OTHER_DETAILS_FAIL,
    payload: error,
  };
};

export const addManyDepartmentAccount = () => {
  return {
    type: ADD_MANY_DEPARTMENT_ACCOUNT,
  };
};

export const addManyDepartmentAccountSuccess = (data) => {
  return {
    type: ADD_MANY_DEPARTMENT_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const addManyDepartmentAccountFail = (error) => {
  return {
    type: ADD_MANY_DEPARTMENT_ACCOUNT_FAIL,
    payload: error,
  };
};
