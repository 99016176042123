import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { routerReducer } from "react-router-redux";

import LoginReducer from "./login/reducer";
import RegisterReducer from "./register/reducer";
import ForgotPasswordReducer from "./forgotPassword/reducer";
import ForgotEmailReducer from "./forgotEmail/reducer";
import NotificationReducer from "./notification/reducer";
import NotificationListReducer from "./notificationList/reducer";
import UserProfileReducer from "./userProfile/reducer";
import ChangePasswordReducer from "./changePassword/reducer";
import SubscriptionReducer from "./subscription/reducer";
import SubscriptionAddonReducer from "./subscriptionAddon/reducer";
import GlobalSearchReducer from "./globalSearch/reducer";
import SchedulerReducer from "./scheduler/reducer";
import OpenQuotesReducer from "./openQuotes/reducer";
import WonQuotesReducer from "./wonQuotes/reducer";
import LostQuotesReducer from "./lostQuotes/reducer";
import QuoteDetails from "./QuoteDetails/reducer";
import UnassignedJobsReducer from "./unassignedJobs/reducer";
import assignedJobsReducer from "./assignedJobs/reducer";
import inProgressJobsReducer from "./inProcressJobs/reducer";
import completedJobsReducer from "./completedJobs/reducer";
import CommonApiStoreReducer from "./commonApiStore/reducer";
import JobPartReducer from "./jobParts/reducer";
import PartReducer from "./parts/reducer";
import JobDetails from "./jobDetails/reducer";
import SupplierReducer from "./supplier/reducer";
import jobReducer from "./Jobs/reducer";
import CompanyDetailReducer from "./companyDetails/reducer";
import defaultSettingsReducer from "./defaultSettings/reducer";
import JobLabourReducer from "./jobLabour/reducer";
import ContactsReducer from "./contacts/reducer";
import SecurityGroupReducer from "./securityGroup/reducer";
import QuotePartReducer from "./QuoteParts/reducer";
import AssetsReducer from "./assets/reducer";
import invoicesReducer from "./invoices/reducer";
import StatusReducer from "./statuses/reducer";
import invoicePaymentReducer from "./invoicePayment/reducer";
import WorkshopBaysReducer from "./workshopBays/reducer";
import { invoiceDetailReducer } from "./invoiceDetail/reducer";
import QuoteLabourReducer from "./quoteLabour/reducer";
import PurchaseOrderReducer from "./purchaseOrder/reducer";
import StorageDevicesReducer from "./storageDevices/reducer";
import DashboardReducer from "./dashboard/reducer";
import InspectionReducer from "./inspection/reducer";
import VehicleReducer from "./vehicles/reducer";
import ChartsOfAccountReducer from "./chartOfAccount/reducer";
import List from "./list/reducer";
import AccountSyncReducer from "./accountSync/reducer";
import bookJobOnlineReducer from "./bookJobOnline/reducer";
import leadsReducer from "./leads/reducer";
import PaymentIntegrationReducer from "./PaymentIntegration/reducer";
import SupplierIntegrationReducer from "./SupplierIntegration/reducer";
import CannedResponsesReducer from "./cannedResponses/reducer";
import MessageTemplateReducer from "./notificationMessageTemplate/reducer";
import ThirdPartyCompanyReducer from "./thirdPartyCompany/reducer";
import Contact from "./Contact/reducer";
import DigitalInspectionReducer from "./digitalInspection/reducer";
import StockReducer from "./Stock/reducer";
import InspectionDetailsReducer from "./InspectionDetails/reducer";
import marketingListReducers from "./marketing/reducer";
import { reportReducer } from "./reports/reducer";
import SubscriptionTransactionReducer from "./subscriptionTransaction/reducer";
import { firebaseNotificationReducer } from "./firebaseNotification/reducer";
import EmployeeClockingHistoryReducer from "./employeeClockingHistory/reducer";

// HQ Reducer start
import HQReducer from "views/hq/store/reducer";
// HQ Reducer end

const rootReducer = combineReducers({
  LoginReducer,
  RegisterReducer,
  ForgotPasswordReducer,
  ForgotEmailReducer,
  NotificationReducer,
  NotificationListReducer,
  UserProfileReducer,
  ChangePasswordReducer,
  GlobalSearchReducer,
  SchedulerReducer,
  OpenQuotesReducer,
  WonQuotesReducer,
  LostQuotesReducer,
  UnassignedJobsReducer,
  assignedJobsReducer,
  inProgressJobsReducer,
  completedJobsReducer,
  CommonApiStoreReducer,
  SubscriptionReducer,
  SubscriptionAddonReducer,
  JobPartReducer,
  PartReducer,
  JobDetails,
  SupplierReducer,
  jobs: jobReducer,
  CompanyDetailReducer,
  defaultSettingsReducer,
  JobLabourReducer,
  ContactsReducer,
  SecurityGroupReducer,
  QuoteDetails,
  QuotePartReducer,
  AssetsReducer,
  StatusReducer,
  WorkshopBaysReducer,
  form: formReducer,
  invoice: invoicesReducer,
  invoiceDetail: invoiceDetailReducer,
  invoicePaymentReducer,
  QuoteLabourReducer,
  PurchaseOrderReducer,
  StorageDevicesReducer,
  DashboardReducer,
  InspectionReducer,
  routing: routerReducer,
  VehicleReducer,
  ChartsOfAccountReducer,
  List,
  AccountSyncReducer,
  bookJobOnline: bookJobOnlineReducer,
  leads: leadsReducer,
  PaymentIntegrationReducer,
  SupplierIntegrationReducer,
  CannedResponsesReducer,
  MessageTemplateReducer,
  ThirdPartyCompanyReducer,
  Contact,
  DigitalInspectionReducer,
  StockReducer,
  InspectionDetailsReducer,
  marketing: marketingListReducers,
  reports: reportReducer,
  SubscriptionTransactionReducer,
  firebaseNotifications: firebaseNotificationReducer,
  EmployeeClockingHistoryReducer:EmployeeClockingHistoryReducer ,
  // HQ combined reducer
  HQReducer
});

export default rootReducer;
