import {
  GET_UPCOMING_LIST,
  GET_UPCOMING_LIST_SUCCESS,
  GET_UPCOMING_LIST_FAIL,
  GET_OVERDUE_LIST,
  GET_OVERDUE_LIST_SUCCESS,
  GET_OVERDUE_LIST_FAIL,
  GET_SERVICEREMINDER_HISTORY,
  GET_SERVICEREMINDER_HISTORY_SUCCESS,
  GET_SERVICEREMINDER_HISTORY_FAIL,
  GET_SENTVIEW_LIST,
  GET_SENTVIEW_LIST_SUCCESS,
  GET_SENTVIEW_LIST_FAIL,
  GET_INSPECTION_UPCOMING_LIST,
  GET_INSPECTION_UPCOMING_LIST_SUCCESS,
  GET_INSPECTION_UPCOMING_LIST_FAIL,
  GET_INSPECTION_OVERDUE_LIST,
  GET_INSPECTION_OVERDUE_LIST_SUCCESS,
  GET_INSPECTION_OVERDUE_LIST_FAIL,
  GET_INSPECTIONREMINDER_HISTORY,
  GET_INSPECTIONREMINDER_HISTORY_SUCCESS,
  GET_INSPECTIONREMINDER_HISTORY_FAIL,
  GET_VEHICLE_UPCOMING_LIST,
  GET_VEHICLE_UPCOMING_LIST_SUCCESS,
  GET_VEHICLE_UPCOMING_LIST_FAIL,
  GET_VEHICLE_OVERDUE_LIST,
  GET_VEHICLE_OVERDUE_LIST_SUCCESS,
  GET_VEHICLE_OVERDUE_LIST_FAIL,
  GET_VEHICLEREMINDER_HISTORY,
  GET_VEHICLEREMINDER_HISTORY_SUCCESS,
  GET_VEHICLEREMINDER_HISTORY_FAIL,
  GET_VEHICLE_LIST,
  GET_VEHICLE_LIST_SUCCESS,
  GET_VEHICLE_LIST_FAIL,
  GET_VEHICLES_COUNT,
  GET_VEHICLES_COUNT_SUCCESS,
  GET_VEHICLES_COUNT_FAIL,
  GET_CARAVAN_LIST,
  GET_CARAVAN_LIST_SUCCESS,
  GET_CARAVAN_LIST_FAIL,
  GET_MARINE_LIST,
  GET_MARINE_LIST_SUCCESS,
  GET_MARINE_LIST_FAIL,
  GET_TRUCK_LIST,
  GET_TRUCK_LIST_SUCCESS,
  GET_TRUCK_LIST_FAIL,
  GET_TRAILER_LIST,
  GET_TRAILER_LIST_SUCCESS,
  GET_TRAILER_LIST_FAIL,
  GET_MOTORBIKE_LIST,
  GET_MOTORBIKE_LIST_SUCCESS,
  GET_MOTORBIKE_LIST_FAIL,
  GET_MACHINE_LIST,
  GET_MACHINE_LIST_SUCCESS,
  GET_MACHINE_LIST_FAIL,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_FAIL,
  REGOEXIST,
  REGOEXIST_SUCCESS,
  REGOEXIST_FAIL,
  HINEXIST,
  HINEXIST_SUCCESS,
  HINEXIST_FAIL,
  GET_VEHICLE_DETAIL,
  GET_VEHICLE_DETAIL_SUCCESS,
  GET_VEHICLE_DETAIL_FAIL,
  GET_JOB_LIST,
  GET_JOB_LIST_SUCCESS,
  GET_JOB_LIST_FAIL,
  GET_ACTIVE_ASSETS,
  GET_ACTIVE_ASSETS_SUCCESS,
  GET_ACTIVE_ASSETS_FAIL,
  SEARCH_VEHICLE,
  SEARCH_VEHICLE_SUCCESS,
  SEARCH_VEHICLE_FAIL,
  GET_VEHICLE_LEGACY_HISTORY,
  GET_VEHICLE_LEGACY_HISTORY_SUCCESS,
  GET_VEHICLE_LEGACY_HISTORY_FAIL,
} from "./actionTypes";

export const getUpcomingList = () => {
  return {
    type: GET_UPCOMING_LIST,
  };
};

export const getUpcomingListSuccess = (data) => {
  return {
    type: GET_UPCOMING_LIST_SUCCESS,
    payload: data,
  };
};

export const getUpcomingListFail = (error) => {
  return {
    type: GET_UPCOMING_LIST_FAIL,
    payload: error,
  };
};

export const getOverDueList = () => {
  return {
    type: GET_OVERDUE_LIST,
  };
};

export const getOverDueListSuccess = (data) => {
  return {
    type: GET_OVERDUE_LIST_SUCCESS,
    payload: data,
  };
};

export const getOverDueListFail = (error) => {
  return {
    type: GET_OVERDUE_LIST_FAIL,
    payload: error,
  };
};

export const getServiceReminderHistory = () => {
  return {
    type: GET_SERVICEREMINDER_HISTORY,
  };
};

export const getServiceReminderHistorySuccess = (data) => {
  return {
    type: GET_SERVICEREMINDER_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getServiceReminderHistoryFail = (error) => {
  return {
    type: GET_SERVICEREMINDER_HISTORY_FAIL,
    payload: error,
  };
};

export const getSentViewList = () => {
  return {
    type: GET_SENTVIEW_LIST,
  };
};

export const getSentViewListSuccess = (data) => {
  return {
    type: GET_SENTVIEW_LIST_SUCCESS,
    payload: data,
  };
};

export const getSentViewListFail = (error) => {
  return {
    type: GET_SENTVIEW_LIST_FAIL,
    payload: error,
  };
};

export const getInspectionUpcomingList = () => {
  return {
    type: GET_INSPECTION_UPCOMING_LIST,
  };
};

export const getInspectionUpcomingListSuccess = (data) => {
  return {
    type: GET_INSPECTION_UPCOMING_LIST_SUCCESS,
    payload: data,
  };
};

export const getInspectionUpcomingListFail = (error) => {
  return {
    type: GET_INSPECTION_UPCOMING_LIST_FAIL,
    payload: error,
  };
};

export const getInspectionOverDueList = () => {
  return {
    type: GET_INSPECTION_OVERDUE_LIST,
  };
};

export const getInspectionOverDueListSuccess = (data) => {
  return {
    type: GET_INSPECTION_OVERDUE_LIST_SUCCESS,
    payload: data,
  };
};

export const getInspectionOverDueListFail = (error) => {
  return {
    type: GET_INSPECTION_OVERDUE_LIST_FAIL,
    payload: error,
  };
};

export const getInspectionReminderHistory = () => {
  return {
    type: GET_INSPECTIONREMINDER_HISTORY,
  };
};

export const getInspectionReminderHistorySuccess = (data) => {
  return {
    type: GET_INSPECTIONREMINDER_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getInspectionReminderHistoryFail = (error) => {
  return {
    type: GET_INSPECTIONREMINDER_HISTORY_FAIL,
    payload: error,
  };
};

export const getVehicleUpcomingList = () => {
  return {
    type: GET_VEHICLE_UPCOMING_LIST,
  };
};

export const getVehicleUpcomingListSuccess = (data) => {
  return {
    type: GET_VEHICLE_UPCOMING_LIST_SUCCESS,
    payload: data,
  };
};

export const getVehicleUpcomingListFail = (error) => {
  return {
    type: GET_VEHICLE_UPCOMING_LIST_FAIL,
    payload: error,
  };
};
export const getVehicleOverDueList = () => {
  return {
    type: GET_VEHICLE_OVERDUE_LIST,
  };
};

export const getVehicleOverDueListSuccess = (data) => {
  return {
    type: GET_VEHICLE_OVERDUE_LIST_SUCCESS,
    payload: data,
  };
};

export const getVehicleOverDueListFail = (error) => {
  return {
    type: GET_VEHICLE_OVERDUE_LIST_FAIL,
    payload: error,
  };
};

export const getVehicleReminderHistory = () => {
  return {
    type: GET_VEHICLEREMINDER_HISTORY,
  };
};

export const getVehicleReminderHistorySuccess = (data) => {
  return {
    type: GET_VEHICLEREMINDER_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getVehicleReminderHistoryFail = (error) => {
  return {
    type: GET_VEHICLEREMINDER_HISTORY_FAIL,
    payload: error,
  };
};

export const getVehicleList = () => {
  return {
    type: GET_VEHICLE_LIST,
  };
};

export const getVehicleListSuccess = (data) => {
  return {
    type: GET_VEHICLE_LIST_SUCCESS,
    payload: data,
  };
};

export const getVehicleListFail = (error) => {
  return {
    type: GET_VEHICLE_LIST_FAIL,
    payload: error,
  };
};

export const getVehicleCount = () => {
  return {
    type: GET_VEHICLES_COUNT,
  };
};

export const getVehicleCountSuccess = (data) => {
  return {
    type: GET_VEHICLES_COUNT_SUCCESS,
    payload: data,
  };
};

export const getVehicleCountFail = (error) => {
  return {
    type: GET_VEHICLES_COUNT_FAIL,
    payload: error,
  };
};

export const getCaravanList = () => {
  return {
    type: GET_CARAVAN_LIST,
  };
};

export const getCaravanListSuccess = (data) => {
  return {
    type: GET_CARAVAN_LIST_SUCCESS,
    payload: data,
  };
};

export const getCaravanListFail = (error) => {
  return {
    type: GET_CARAVAN_LIST_FAIL,
    payload: error,
  };
};

export const getMarineList = () => {
  return {
    type: GET_MARINE_LIST,
  };
};

export const getMarineListSuccess = (data) => {
  return {
    type: GET_MARINE_LIST_SUCCESS,
    payload: data,
  };
};

export const getMarineListFail = (error) => {
  return {
    type: GET_MARINE_LIST_FAIL,
    payload: error,
  };
};

export const getTruckList = () => {
  return {
    type: GET_TRUCK_LIST,
  };
};

export const getTruckListSuccess = (data) => {
  return {
    type: GET_TRUCK_LIST_SUCCESS,
    payload: data,
  };
};

export const getTruckListFail = (error) => {
  return {
    type: GET_TRUCK_LIST_FAIL,
    payload: error,
  };
};

export const getTrailerList = () => {
  return {
    type: GET_TRAILER_LIST,
  };
};

export const getTrailerListSuccess = (data) => {
  return {
    type: GET_TRAILER_LIST_SUCCESS,
    payload: data,
  };
};

export const getTrailerListFail = (error) => {
  return {
    type: GET_TRAILER_LIST_FAIL,
    payload: error,
  };
};

export const getMotorBikeList = () => {
  return {
    type: GET_MOTORBIKE_LIST,
  };
};

export const getMotorBikeListSuccess = (data) => {
  return {
    type: GET_MOTORBIKE_LIST_SUCCESS,
    payload: data,
  };
};

export const getMotorBikeListFail = (error) => {
  return {
    type: GET_MOTORBIKE_LIST_FAIL,
    payload: error,
  };
};


export const getMachineList = () => {
  return {
    type: GET_MACHINE_LIST,
  };
};

export const getMachineListSuccess = (data) => {
  return {
    type: GET_MACHINE_LIST_SUCCESS,
    payload: data,
  };
};

export const getMachineListFail = (error) => {
  return {
    type: GET_MACHINE_LIST_FAIL,
    payload: error,
  };
};

export const getCustomerList = () => {
  return {
    type: GET_CUSTOMER_LIST,
  };
};

export const getCustomerListSuccess = (data) => {
  return {
    type: GET_CUSTOMER_LIST_SUCCESS,
    payload: data,
  };
};

export const getCustomerListFail = (error) => {
  return {
    type: GET_CUSTOMER_LIST_FAIL,
    payload: error,
  };
};

export const regoExist = () => {
  return {
    type: REGOEXIST,
  };
};

export const hinExist = () => {
  return {
    type: HINEXIST,
  };
};

export const regoExistSuccess = (data) => {
  return {
    type: REGOEXIST_SUCCESS,
    payload: data,
  };
};

export const hinExistSuccess = (data) => {
  return {
    type: HINEXIST_SUCCESS,
    payload: data,
  };
};

export const regoExistFail = (error) => {
  return {
    type: REGOEXIST_FAIL,
    payload: error,
  };
};

export const hinExistFail = (error) => {
  return {
    type: HINEXIST_FAIL,
    payload: error,
  };
};

export const getVehicleDetail = () => {
  return {
    type: GET_VEHICLE_DETAIL,
  };
};

export const getVehicleDetailSuccess = (data) => {
  return {
    type: GET_VEHICLE_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getVehicleDetailFail = (error) => {
  return {
    type: GET_VEHICLE_DETAIL_FAIL,
    payload: error,
  };
};

export const getVehicleLegacyHistory = (data) => {
  return {
    type: GET_VEHICLE_LEGACY_HISTORY,
    payload: data
  }
};

export const getVehicleLegacyHistorySuccess = (data) => {
  return {
    type: GET_VEHICLE_LEGACY_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getVehicleLegacyHistoryFail = (error) => {
  return {
    type: GET_VEHICLE_LEGACY_HISTORY_FAIL,
    payload: error,
  };
};

export const getJobList = () => {
  return {
    type: GET_JOB_LIST,
  };
};

export const getJobListSuccess = (data) => {
  return {
    type: GET_JOB_LIST_SUCCESS,
    payload: data,
  };
};

export const getJobListFail = (error) => {
  return {
    type: GET_JOB_LIST_FAIL,
    payload: error,
  };
};

export const getActiveAssets = () => {
  return {
    type: GET_ACTIVE_ASSETS,
  };
};

export const getActiveAssetsSuccess = (data) => {
  return {
    type: GET_ACTIVE_ASSETS_SUCCESS,
    payload: data,
  };
};

export const getActiveAssetsFail = (error) => {
  return {
    type: GET_ACTIVE_ASSETS_FAIL,
    payload: error,
  };
};

export const searchVehicle = () => {
  return {
    type: SEARCH_VEHICLE,
  };
};

export const searchVehicleSuccess = (data) => {
  return {
    type: SEARCH_VEHICLE_SUCCESS,
    payload: data,
  };
};

export const searchVehicleFail = (error) => {
  return {
    type: SEARCH_VEHICLE_FAIL,
    payload: error,
  };
};