import {
  GET_OPEN_QUOTES,
  GET_OPEN_QUOTES_SUCCESS,
  GET_OPEN_QUOTES_FAIL,
  EDIT_QUOTES,
  EDIT_QUOTES_SUCCESS,
  EDIT_QUOTES_FAIL,
  GET_OPEN_QUOTES_COUNT,
  GET_OPEN_QUOTES_COUNT_SUCCESS,
  GET_OPEN_QUOTES_COUNT_FAIL,
  CHECK_STATUS_CHANGE,
} from "./actionTypes";

const initialState = {
  loading: false,
  message: "",
  error: "",
  openQuotesData: null,
  editQuotesData: null,
  openQuotesCount: 0,
  statusChange: false,
};

const OpenQuotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPEN_QUOTES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_OPEN_QUOTES_SUCCESS:
      state = {
        ...state,
        loading: false,
        openQuotesData: action.payload,
      };
      break;
    case GET_OPEN_QUOTES_FAIL:
      state = {
        ...state,
        loading: false,
        openQuotesData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case EDIT_QUOTES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_QUOTES_SUCCESS:
      state = {
        ...state,
        loading: false,
        editQuotesData: action.payload,
      };
      break;
    case EDIT_QUOTES_FAIL:
      state = {
        ...state,
        loading: false,
        editQuotesData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_OPEN_QUOTES_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_OPEN_QUOTES_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        openQuotesCount: action.payload,
      };
      break;
    case GET_OPEN_QUOTES_COUNT_FAIL:
      state = {
        ...state,
        loading: false,
        openQuotesCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    case CHECK_STATUS_CHANGE:
      state = {
        ...state,
        statusChange: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default OpenQuotesReducer;
