import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from 'react-bootstrap';

const RegoLookup = ({ showRegoLookupModal, handleCloseRegoLookupModal, verifyVehicleDetail, handleShowRegoLookupModal, vehicleRegoData, setVehicleDetailData, action }) => {

  return (
    <>
      <Modal
        className="vehicle-details-dialog"
        size="lg"
        show={showRegoLookupModal}
        onHide={handleCloseRegoLookupModal}
        centered
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-space-between">
            <span>Vehicle Details</span>
            <Button type="button" className="close-btn" onClick={handleCloseRegoLookupModal}><i className="la la-times"></i> </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="lookup-table">
            <div className="detail-header">
              <div className="row">
                <div className="col-sm-3">Make:</div>
                <div className="col-sm-3">Model:</div>
                <div className="col-sm-3">Sub Model:</div>
                <div className="col-sm-3">Configration:</div>
              </div>
            </div>
            <div className="detail-value">
              <div className="row">
                <div className="col-sm-3">{vehicleRegoData.make}</div>
                <div className="col-sm-3">{vehicleRegoData.model}</div>
                <div className="col-sm-3">{vehicleRegoData.sub_model}</div>
                <div className="col-sm-3">{vehicleRegoData.description}</div>
              </div>
            </div>
            <div className="detail-header">
              <div className="row">
                <div className="col-sm-3">Year Produced:</div>
                <div className="col-sm-3">Body Type:</div>
                <div className="col-sm-3">Doors:</div>
                <div className="col-sm-3">CC:</div>
              </div>
            </div>
            <div className="detail-value">
              <div className="row">
                <div className="col-sm-3">{vehicleRegoData.year}</div>
                <div className="col-sm-3">{vehicleRegoData.body_type}</div>
                <div className="col-sm-3">{vehicleRegoData.doors}</div>
                <div className="col-sm-3">{vehicleRegoData.cc}</div>
              </div>
            </div>
            <div className="detail-header">
              <div className="row">
                <div className="col-sm-3">Vin:	</div>
                <div className="col-sm-3">Chassis:</div>
                <div className="col-sm-3">Series:	</div>
                <div className="col-sm-3">Engine:</div>
              </div>
            </div>
            <div className="detail-value">
              <div className="row">
                <div className="col-sm-3">{vehicleRegoData.vin}</div>
                <div className="col-sm-3">{vehicleRegoData.chasis_number}</div>
                <div className="col-sm-3">{vehicleRegoData.series}</div>
                <div className="col-sm-3">{vehicleRegoData.engine_number}</div>
              </div>
            </div>
            <div className="detail-header">
              <div className="row">
                <div className="col-sm-3">Country of Origin:	</div>
                <div className="col-sm-3">Drive:	</div>
                <div className="col-sm-3">Transmission:	</div>
                <div className="col-sm-3">Cam:</div>
              </div>
            </div>
            <div className="detail-value">
              <div className="row">
                <div className="col-sm-3">{vehicleRegoData.country}</div>
                <div className="col-sm-3">{vehicleRegoData.category}</div>
                <div className="col-sm-3">{vehicleRegoData.transmission_val}</div>
                <div className="col-sm-3">{vehicleRegoData.cam}</div>
              </div>
            </div>
            <div className="detail-header">
              <div className="row">
                <div className="col-sm-3">Cam Type:	</div>
                <div className="col-sm-3">Valves:	</div>
                <div className="col-sm-3">KW:</div>
                <div className="col-sm-3">Fuel System:</div>
              </div>
            </div>
            <div className="detail-value">
              <div className="row">
                <div className="col-sm-3">-</div>
                <div className="col-sm-3">{vehicleRegoData.valve}</div>
                <div className="col-sm-3">{vehicleRegoData.kw}</div>
                <div className="col-sm-3">{vehicleRegoData.fuel_system}</div>
              </div>
            </div>
            <div className="detail-header">
              <div className="row">
                <div className="col-sm-3">Fuel:</div>
                <div className="col-sm-3">Induction:</div>
                <div className="col-sm-3">Cylinders:</div>
                <div className="col-sm-3"></div>
              </div>
            </div>
            <div className="detail-value">
              <div className="row">
                <div className="col-sm-3">{vehicleRegoData.fuel_type}</div>
                <div className="col-sm-3">-</div>
                <div className="col-sm-3">{vehicleRegoData.cyliners}</div>
                <div className="col-sm-3">-</div>
              </div>
            </div>
            <h6 className="title">Other Details</h6>
            <div className="detail-header">
              <div className="row">
                <div className="col-sm-3">Registration:</div>
                <div className="col-sm-3">Year:</div>
                <div className="col-sm-3">Vin:</div>
                <div className="col-sm-3">Vehicle ID:</div>
              </div>
            </div>
            <div className="detail-value">
              <div className="row">
                <div className="col-sm-3">{vehicleRegoData.rego}</div>
                <div className="col-sm-3">{vehicleRegoData.monthyear}</div>
                <div className="col-sm-3">{vehicleRegoData.vin_number}</div>
                <div className="col-sm-3">{vehicleRegoData.VehicleId}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            className="btn-outline rounded-btn"
            onClick={handleCloseRegoLookupModal}
          >
            Cancel
          </Button>

          {action === 'set_vehicle' ? (

            <Button
              className="rounded-btn btn-success"
              variant="primary"
              onClick={setVehicleDetailData}
            >
              Verify &amp; Set Vehicle Details
            </Button>

          ) : (

            <Button
              className="rounded-btn btn-success"
              variant="primary"
              onClick={verifyVehicleDetail}
            >
              Verify &amp; Update Vehicle Details
            </Button>

          )}

        </Modal.Footer>
      </Modal>
    </>
  )



}
export default RegoLookup;