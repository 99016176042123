import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getPartListNew } from "store/parts/actions";
import { getWorkshopId } from "utils/getWorkshopId";
import { getPartsPayload } from "views/job/components/jobDetails/payloads";

const PartsSearch = ({ onChangeEvent, searchKit = false, searchSupplier, createNewPart, setSearchPartName, setSearchStatus = "" }) => {
  // const [partOptions, setPartOptions] = useState([]);
  const [query, setQuery] = useState("");
  const PartReducerData = useSelector((state) => state.PartReducer);
  const dispatch = useDispatch();
  const inpRef = useRef();
  const mounted = useRef(false);

  const partOptions = useMemo(() => {
    if (Array.isArray(PartReducerData?.partListData)) {
      const allSearchPartList = PartReducerData?.partListData?.map((parts) => ({
        label: parts.name,
        id: parts.id,
        part_number: parts.product_number,
        part_name: parts.name,
        retail_price: parts.retail_price,
        qty: 1,
        table_qty: 1,
        total: parts.retail_price,
        more: "",
        product_type: parts.product_type,
        is_kit: parts.product_type === '2' ? 1 : 0,
        canned_response: parts?.canned_response,
        cost_price: parts.cost_price,
        margin_markup: "",
        sell_price: parts.sell_price,
        margin: parts.margin,
        is_gst: parts.sell_price !== parts.retail_price ? 1 : 0,
        gst_total: parseFloat(parts.gst_price) * parseFloat(1),
        sell_total: parseFloat(parts.sell_price) * parseFloat(1),
        gst_price: parts.gst_price,
        gst_percentage: "",
        profit_price: parts.profit_price,
        purchase_tax_code_is_default: parts?.purchase_tax_code_is_default,
        purchase_tax_code: parts?.purchase_tax_code,
        // job_part_priority: job_part_priority,
        price: parts.price,
        is_misc_part: 0,
        supplier_name: parts?.suppliers_search?.name,
        stock_in_hand: parts?.inventory_stock_search?.stocks || 0,
        invoice_type: 0,
        selected_invoice_allocation: 0,
        add_from: parts?.add_from
      }));

      if (allSearchPartList.length > 0) {
        return allSearchPartList;
      } else {
        return ['no records found'];
      }
    } else {
      return [];
    }
  }, [PartReducerData]);

  const { jobBookedData } = useSelector(
    (state) => state.JobDetails
  );
  const [jobDetails, setJobDetails] = useState({});

  useEffect(() => {
    if (jobBookedData) {
      let getJobDetailData = jobBookedData ? jobBookedData[0] : "";
      setJobDetails(getJobDetailData)
    }
  }, [jobBookedData])

  const storage_device_id = useMemo(() => {
    let employees_details = jobDetails?.user?.employees_details[0] ?? {};
    return employees_details?.storage_device_id || 0;
  }, [jobDetails]);

  useEffect(() => {
    if (query !== "") {
       if(setSearchStatus){
        setSearchStatus(true);
       }
      
    }
    const notInIds = PartReducerData.parts.filter(item => (item?.product !== null)).map((c) => c.id);
    dispatch(
      getPartListNew(
        getPartsPayload(query, getWorkshopId(), notInIds, searchKit, searchSupplier, storage_device_id)
      )
    );
  }, [query, dispatch]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onSearchEvent = (q) => {

  };


  const renderMenuItemChildren = (option, props, index) => {
    if(setSearchStatus){
    setSearchStatus(false);
    }
    if (option === "no records found") {
      return (
        <div className="no-result text-white">
          No Results Found for {props.text}
        </div>
      );
    } else {
      return (
        <Fragment>
          <p>
            <span className="part-name">{`${option.part_name}`}</span>
            <span>{`${option?.supplier_name || "-"}`}</span>
          </p>
          <p>
            <span>{`${option.part_number}`}</span>
            <span>{`Qty : ${option.stock_in_hand}`}</span>
          </p>
        </Fragment>
      );
    }
  };



  return (
    <AsyncTypeahead
      allowNew={true}
      isLoading={!PartReducerData?.isLoadData}
      ref={inpRef}
      onFocus={(e) => {
        inpRef.current.clear();
        setQuery("");
      }}
      filterBy={() => true}
      newSelectionPrefix="Create new part"
      id="search-part"
      labelKey="part_number"
      minLength={0}
      onSearch={onSearchEvent}
      onInputChange={(e) => {
        if(setSearchStatus){
        setSearchStatus(true);
      }
        setQuery(e);
        setSearchPartName?.(e);
      }}
      options={partOptions}
      onChange={(e) => {
        const notInIds = PartReducerData.parts.map((c) => c.id);

        if (notInIds.includes(e[0].id)) {
          toast.error("Part already added to list");
          inpRef.current.clear();
          setQuery("");
          return;
        }
        if (e[0]?.customOption) {
          createNewPart?.(e[0].part_number);
          return;
        }
        onChangeEvent?.(e);
        inpRef.current.clear();
        setQuery("");

        // dispatch(clearPartList());
      }}
      useCache={false}
      placeholder="Search for a parts..."
      renderMenuItemChildren={renderMenuItemChildren}

    // renderMenuItemChildren={(option, props) => (

    //   (option == "no records found") ? <div className="no-result text-white">
    //     No Results Found for {props.text}
    //   </div> :
    //     <Fragment>
    //       <p>
    //         <span className="part-name">{`${option.part_name}`}</span>
    //         <span>{`${option?.supplier_name || "-"}`}</span>
    //       </p>
    //       <p>
    //         <span>{`${option.part_number}`}</span>
    //         <span>{`Qty : ${option.stock_in_hand}`}</span>
    //       </p>
    //     </Fragment>
    // )}
    />
  );
};

export default PartsSearch;
