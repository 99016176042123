import React, { useEffect, useState, useMemo } from "react";
import { Form } from "react-bootstrap";

const TrailerFields = ({
    vehiclesFormData,
    handleVehiclesInputChange,
    checkVinData,
    checkVinExist,
    checkChasisData,
    checkChasisExist,
    checkFleetExist,
    checkFleetData,
    fieldShow
}) => {

    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    const currentYear = parseInt(new Date().getFullYear());

    const axlesOption = [];
    axlesOption.push(<option value="">Select Axles</option>);
    for (let i = 1; i <= 6; i++) {
        axlesOption.push(<option value={i}>{i}</option>);
    }

    const wheelOption = [];
    wheelOption.push(<option value="">Select Axles</option>);
    for (let i = 1; i <= 12; i++) {
        if (i % 2 == 0)
            wheelOption.push(<option value={i}>{i}</option>);
    }

    return (
        <>
            {/* First Section START */}
            <div className="form-outer vin-engine-section" >
                {/* Make */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Make</label>
                    <Form.Control
                        type="text"
                        name="make"
                        placeholder="Enter make"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.make}
                    />
                </Form.Group>

                {/* Modal */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Model</label>
                    <Form.Control
                        type="text"
                        name="model"
                        placeholder="Enter model"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.model}
                    />
                </Form.Group>

                {/* Series */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Series</label>
                    <Form.Control
                        type="text"
                        name="model_series"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.model_series}
                        placeholder="Enter series"
                    />
                </Form.Group>

                {/* Breaks */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Brakes</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="brakes"
                        value={vehiclesFormData.brakes}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        <option value="">Select Brakes</option>
                        <option value="N/A">N/A</option>
                        <option value="Hydraulic">Hydraulic</option>
                        <option value="Air">Air</option>
                    </Form.Select>
                </Form.Group>

                {/* Trailer Type */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Trailer Type</Form.Label>
                    <Form.Control
                        type="text"
                        name="trailer_type"
                        value={vehiclesFormData.trailer_type}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>


                <div className="form-group width-33 mb-3 d-flex gap-2">
                    {/* Month */}
                    <div className="p-0 m-0" style={{ maxWidth: 50 }}>
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Month</label>
                            <Form.Control
                                type="text"
                                name="month"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                onBlur={(e) => handleVehiclesInputChange(e, true)}
                                value={vehiclesFormData.month}
                                autoComplete="off"
                            />
                        </Form.Group>
                    </div>
                    <div className="flex-1 p-0">
                        {/* Year */}
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Year</label>
                            <Form.Select
                                aria-label="Default select example"
                                name="year"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                value={vehiclesFormData.year}
                            >
                                <option value="">Select Year</option>
                                {[...Array(currentYear - 1949)].map(
                                    (data, index) => (
                                        <option
                                            value={currentYear - index}
                                            key={index}
                                        >
                                            {currentYear - index}
                                        </option>
                                    )
                                )}
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                {/* Number Of Axles */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Number Of Axles</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="no_of_axles"
                        value={vehiclesFormData.no_of_axles}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {axlesOption}
                    </Form.Select>
                </Form.Group>

                {/* Number Of Wheels */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Number Of Wheels</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="number_of_wheels"
                        value={vehiclesFormData.number_of_wheels}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >
                        {wheelOption}
                    </Form.Select>
                </Form.Group>

                {/* Colour */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Colour</label>
                    <Form.Control
                        type="text"
                        name="colour"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.colour}
                    />
                </Form.Group>

                {/* Gross Weight */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Gross Weight</Form.Label>
                    <Form.Control
                        type="text"
                        name="gross_weight"
                        value={vehiclesFormData.gross_weight}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Tyre Size - Front */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Tyre Size - Front</label>
                    <Form.Control
                        type="text"
                        name="tyre_size_front"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.tyre_size_front}
                    />
                </Form.Group>

                {/* Tyre Size - Rear */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Tyre Size - Rear</label>
                    <Form.Control
                        type="text"
                        name="tyre_size_rear"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.tyre_size_rear}
                    />
                </Form.Group>

                {/* Tare Weight */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Tare Weight</Form.Label>
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="tare_weight"
                        value={vehiclesFormData.tare_weight}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Hitch Type */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Hitch Type</Form.Label>
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="hitch_type"
                        value={vehiclesFormData.hitch_type}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>

                {/* Hitch Size */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Hitch Size</Form.Label>
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="hitch_size"
                        value={vehiclesFormData.hitch_size}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>
            </div>

            {/* Second Section Vin / Engine Section START */}
            <div className={`form-outer mb-2 vin-engine-section mt-2  hidden-form ${fieldShow ? "active" : ""}`}>
                <Form.Group className=" form-group width-50 mb-3">
                    <label> VIN Number</label>
                    <Form.Control
                        type="text"
                        name="vin_number"
                        className={
                            checkVinData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        onBlur={(e) => checkVinExist(e)}
                        value={vehiclesFormData.vin_number}
                    />
                    {checkVinData &&
                        <div className="invalid-feedback">This Vin Number is already registered.</div>
                    }
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <label>Chassis Number</label>
                    <Form.Control
                        type="text"
                        name="chasis_number"
                        className={
                            checkChasisData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        onBlur={(e) => checkChasisExist(e)}
                        value={vehiclesFormData.chasis_number}
                    />
                    {checkChasisData &&
                        <div className="invalid-feedback">
                            This Chassis Number is already registered.
                        </div>
                    }
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <Form.Label>Trailer Size</Form.Label>
                    <Form.Control
                        type="text"
                        name="trailer_size"
                        value={vehiclesFormData.trailer_size}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    />
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <label>Fleet Number</label>
                    <Form.Control
                        type="text"
                        name="fleet_number"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.fleet_number}
                        onBlur={(e) => checkFleetExist(e)}
                        className={
                            checkFleetData && "form-control is-invalid"
                        }
                    />
                    {checkFleetData &&
                        <div className="invalid-feedback">
                            This Fleet Number is already registered.
                        </div>
                    }
                </Form.Group>
            </div>
            {/* Second Section Vin / Engine Section END */}
        </>
    )
}

export default TrailerFields;
