import {
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAIL,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAIL,
  GET_DEPARTMENT_LIST,
  GET_DEPARTMENT_LIST_SUCCESS,
  GET_DEPARTMENT_LIST_FAIL,
  ADD_SCRIPT,
  ADD_SCRIPT_SUCCESS,
  ADD_SCRIPT_FAIL,
  GET_SCRIPT_DETAIL,
  GET_SCRIPT_DETAIL_SUCCESS,
  GET_SCRIPT_DETAIL_FAIL,
  EDIT_SCRIPT,
  EDIT_SCRIPT_SUCCESS,
  EDIT_SCRIPT_FAIL,
  COPY_SCRIPT,
  COPY_SCRIPT_FAIL,
  COPY_SCRIPT_SUCCESS
} from "./actionTypes";

export const getTemplates = (data) => {
  return {
    type: GET_TEMPLATES,
    payload: data
  };
};

export const getTemplatesSuccess = (data) => {
  return {
    type: GET_TEMPLATES_SUCCESS,
    payload: data.WorkshopScriptsDetails,
    message: data.message
  };
};

export const getTemplatesFail = (error) => {
  return {
    type: GET_TEMPLATES_FAIL,
    payload: error
  };
};

export const getDepartmentList = (data) => {
  return {
    type: GET_DEPARTMENT_LIST,
    payload: data
  };
};

export const getDepartmentListSuccess = (data) => {
  return {
    type: GET_DEPARTMENT_LIST_SUCCESS,
    payload: data.WorkshopDepartmentsAccountsDetails,
    message: data.message
  };
};

export const getDepartmentListFail = (error) => {
  return {
    type: GET_DEPARTMENT_LIST_FAIL,
    payload: error
  };
};

export const deleteTemplate = (data) => {
  return {
    type: DELETE_TEMPLATE,
    payload: data,
    loading: true
  }
}

export const deleteTemplateSuccess = (data) => {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    payload: data,
    loading: false,
    message: data.message
  }
}

export const deleteTemplateFail = (error) => {
  return {
    type: DELETE_TEMPLATE_FAIL,
    payload: error,
    loading: false
  }
}

export const addScript = (data) => {
  return {
    type: ADD_SCRIPT,
  }
}

export const addScriptSuccess = (data) => {
  return {
    type: ADD_SCRIPT_SUCCESS,
    payload: data,
    message: data?.message || ""
  }
}

export const addScriptFail = (error) => {
  return {
    type: ADD_SCRIPT_FAIL,
    payload: error
  }
}

export const getScriptDetail = (data) => {
  return {
    type: GET_SCRIPT_DETAIL,
    payload: data
  };
};

export const getScriptDetailSuccess = (data) => {
  return {
    type: GET_SCRIPT_DETAIL_SUCCESS,
    payload: data.WorkshopScriptsDetails[0],
    message: data.message
  };
};

export const getScriptDetailFail = (error) => {
  return {
    type: GET_SCRIPT_DETAIL_FAIL,
    payload: error
  };
};

export const editScript = (data) => {
  return {
    type: EDIT_SCRIPT,
  }
}

export const editScriptSuccess = (data) => {
  return {
    type: EDIT_SCRIPT_SUCCESS,
    payload: data.WorkshopScriptsDetails,
    message: data.message
  }
}

export const editScriptFail = (error) => {
  return {
    type: EDIT_SCRIPT_FAIL,
    payload: error
  }
}

export const copyScript = () => {
  return {
    type: COPY_SCRIPT,
  }
}

export const copyScriptSuccess = (data) => {
  return {
    type: COPY_SCRIPT_SUCCESS,
    payload: data,
    message: data.message
  }
}

export const copyScriptFail = (error) => {
  return {
    type: COPY_SCRIPT_FAIL,
    payload: error
  }
}