import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_OPEN_QUOTES,
  EDIT_QUOTES,
  GET_OPEN_QUOTES_COUNT,
} from "./actionTypes";
import {
  getOpenQuotesSuccess,
  getOpenQuotesFail,
  editQuotesSuccess,
  editQuotesFail,
  getOpenQuotesCountSuccess,
  getOpenQuotesCountFail,
} from "./actions";

import { getOpenQuotes, editQuotes } from "../../helpers/backendHelper";

function* onGetOpenQuotes(data) {
  try {
    const response = yield call(getOpenQuotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(getOpenQuotesSuccess(response.data.CustomerQuotes));
      } else {
        yield put(getOpenQuotesFail(response.message));
      }
    } else {
      yield put(getOpenQuotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getOpenQuotesFail(error.response));
  }
}

function* onEditQuotes(data) {
  try {
    const response = yield call(editQuotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(editQuotesSuccess(response.data.CustomerQuotes));
      } else {
        yield put(editQuotesFail(response.message));
      }
    } else {
      yield put(editQuotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editQuotesFail(error.response));
  }
}

function* onGetOpenQuotesCount(data) {
  try {
    const response = yield call(getOpenQuotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getOpenQuotesCountSuccess(response.data.CustomerQuotes[0].COUNT)
        );
      } else {
        yield put(getOpenQuotesCountFail(response.message));
      }
    } else {
      yield put(getOpenQuotesCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getOpenQuotesCountFail(error.response));
  }
}

function* OpenQuotesSaga() {
  yield takeLatest(GET_OPEN_QUOTES, onGetOpenQuotes);
  yield takeLatest(EDIT_QUOTES, onEditQuotes);
  yield takeLatest(GET_OPEN_QUOTES_COUNT, onGetOpenQuotesCount);
}

export default OpenQuotesSaga;
