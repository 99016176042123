import {
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAIL,
  GET_DEPARTMENT_LIST,
  GET_DEPARTMENT_LIST_SUCCESS,
  GET_DEPARTMENT_LIST_FAIL,
  ADD_SCRIPT,
  ADD_SCRIPT_SUCCESS,
  ADD_SCRIPT_FAIL,
  GET_SCRIPT_DETAIL,
  GET_SCRIPT_DETAIL_SUCCESS,
  GET_SCRIPT_DETAIL_FAIL,
  EDIT_SCRIPT,
  EDIT_SCRIPT_SUCCESS,
  EDIT_SCRIPT_FAIL,
  COPY_SCRIPT,
  COPY_SCRIPT_SUCCESS,
  COPY_SCRIPT_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: {
    message: ""
  },
  success: {
    message: ""
  },
  templates: null,
  scriptDetail: null,
  departments: null,
  isFetching: false,
  copyScript:null
};

const CannedResponsesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES:
      state = {
        ...state,
        loading: true,

      }
      break;
    case GET_TEMPLATES_SUCCESS:
      state = {
        ...state,
        loading: false,
        templates: action.payload,
        success: {
          message: action.message
        },
        copyScript:null,
      }
      break;
    case GET_TEMPLATES_FAIL:
      state = {
        ...state,
        loading: false,
        error: {
          message: action.payload
        },
        copyScript:null,
      }
      break;
    case GET_DEPARTMENT_LIST:
      state = {
        ...state,
        isFetching: true,
      }
      break;
    case GET_DEPARTMENT_LIST_SUCCESS:
      state = {
        ...state,
        isFetching: false,
        departments: action.payload,
        success: {
          message: action.message
        }
      }
      break;
    case GET_DEPARTMENT_LIST_FAIL:
      state= {
        ...state,
        isFetching: false,
        departments: [],
        error: {
          message: action.message
        }
      }
      break;
    case ADD_SCRIPT:
      state = {
        ...state,
        loading: true,
        success: {
          message: ""
        }
      }
      break;
    case ADD_SCRIPT_SUCCESS:
      state = {
        ...state,
        loading: false,
        copyScript:null,
        scriptDetail:null,
        success: {
          message: action.payload
        }
      }
      break;
    case ADD_SCRIPT_FAIL:
      state = {
        ...state,
        loading: false,
        copyScript:null,
        success: {
          message: ""
        },
        error: {
          message: action.payload
        }
      }
      break;
    case GET_SCRIPT_DETAIL:
      state = {
        ...state,
        loading: true,

      }
      break;
    case GET_SCRIPT_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        scriptDetail: action.payload,
        copyScript:null,
        success: {
          message: action.message
        }
      }
      break;
    case GET_SCRIPT_DETAIL_FAIL:
      state = {
        ...state,
        loading: false,
        copyScript:null,
        error: {
          message: action.payload
        }
      }
      break;
    case EDIT_SCRIPT:
      state = {
        ...state,
        loading: true,
        success: {
          message: ""
        }
      }
      break;
    case EDIT_SCRIPT_SUCCESS:
      state = {
        ...state,
        loading: false,
        scriptDetail: action.payload,
        copyScript:null,
        success: {
          message: action.message
        }
      }
      break;
    case EDIT_SCRIPT_FAIL:
      state = {
        ...state,
        loading: false,
        copyScript:null,
        success: {
          message: ""
        },
        error: {
          message: action.payload
        }
      }
      break;
    case COPY_SCRIPT:
      state = {
        ...state,
        loading: true,
        success: {
          message: ""
        }
      }
      break;
    case COPY_SCRIPT_SUCCESS:
      state = {
        ...state,
        loading: false,
        scriptDetail: action.payload,
        copyScript:1,
        success: {
          message: action.message
        }
      }
      break;
    case COPY_SCRIPT_FAIL:
      state = {
        ...state,
        loading: false,
        copyScript:null,
        success: {
          message: ""
        },
        error: {
          message: action.payload
        }
      }
      break;
    default:
      state = {...state};
      break;
  }
  return state;
}

export default CannedResponsesReducer;