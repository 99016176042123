import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  CALLBACK_ERROR_ARGUMENT,
  ERROR_ACTION,
  WAIT_FOR_ACTION,
} from "redux-wait-for-action";
import {
  HQ_LOCAL_STORAGE_BACKUP_KEY,
  destroyLocalStorage,
  dumpLocalStorage,
} from "utils/localstorage";
import {
  HQ_SESSION_STORAGE_BACKUP_KEY,
  destroySessionStorage,
  dumpSessionStorage,
} from "utils/setSessiondata";
import { v4 as generateUUID } from "uuid";
import {
  SET_HQ_WORKSHOP_SIGN_IN_TOKEN_FAIL,
  SET_HQ_WORKSHOP_SIGN_IN_TOKEN_SUCCESS,
} from "views/hq/store/workshop/actionTypes";
import { setHQWorkshopSigninToken } from "views/hq/store/workshop/actions";

export default function ConfirmModal({ clickedWorkshop, ...props }) {
  const dispatch = useDispatch();
  const handleDelete = () => {
    if (props.handleClose) {
      props.handleClose();
    }

    const signinToken = generateUUID();

    dispatch({
      ...setHQWorkshopSigninToken({
        id: clickedWorkshop.user_id,
        dbms_login_token: signinToken,
      }),
      [WAIT_FOR_ACTION]: SET_HQ_WORKSHOP_SIGN_IN_TOKEN_SUCCESS,
      [ERROR_ACTION]: SET_HQ_WORKSHOP_SIGN_IN_TOKEN_FAIL,
      [CALLBACK_ERROR_ARGUMENT]: (action) =>
        typeof action.payload === "string"
          ? action.payload
          : "Something went wrong!",
    })
      .then(() => {
        // Take backup of localStorage and sessionStorage
        const localStorageBackup = dumpLocalStorage();
        const sessionStorageBackup = dumpSessionStorage();
        // Keep backup for restoration process later on.
        localStorage.setItem(
          HQ_LOCAL_STORAGE_BACKUP_KEY,
          JSON.stringify(localStorageBackup)
        );
        sessionStorage.setItem(
          HQ_SESSION_STORAGE_BACKUP_KEY,
          JSON.stringify(sessionStorageBackup)
        );
        // Clear localStorage and sessionStorage
        destroyLocalStorage(true);
        destroySessionStorage(true);

        window.open(
          `${process.env.REACT_APP_SITE_URL}login-with-token/${signinToken}`,
          "_self"
        );
      })
      .catch((error) => {
        let errorMessage = "Something went wrong!";
        if (typeof error === "string") {
          errorMessage = error;
        }
        toast.error(errorMessage);
      });
  };

  return (
    <Modal
      className="info-dialog"
      size="sm"
      show={typeof clickedWorkshop !== "undefined"}
      onHide={() => {
        if (props.handleClose) {
          props.handleClose();
        }
      }}
    >
      <Modal.Header>
        <h5 className="text-white">Login into workshop</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="heading-block d-flex justify-center bg-warning">
          <i
            className="la las la-exclamation-circle close-icon "
            aria-hidden="true"
          ></i>
        </div>
        <div className="text-center mb-3">
          <p className="mb-2" style={{ fontSize: 16 }}>
            Are you sure?
          </p>
        </div>
        <div className="d-flex justify-center mt-3">
          <Button
            variant="outline-secondary"
            className="btn-outline rounded-btn"
            onClick={() => {
              if (props.handleClose) {
                props.handleClose();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outline-secondary"
            className="btn btn-danger rounded-btn font-size-13 text-white"
            onClick={() => {
              handleDelete();
            }}
          >
            Yes, continue
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
