import * as actions from "../actionType";

const invoiceReportState = {
  eodCharts: {
    paymentChartData: null,
    eodChartData: null,
    eodChartTable: [],
    loading: false,
    error: false,
  },
  valueChart: {
    chartData: null,
    chartTable: [],
  },
  paymentChart: {
    chartData: null,
    chartTable: [],
  },
  statements: {
    count: 0,
    table: [],
    error: false,
    loading: false,
  },
  jobReport: {
    table: [],
    chartData: null,
    error: false,
    loading: false,
  },
  statementsPrint: {
    columns: undefined,
    rows: undefined,
    cb: undefined
  }
};

export const InvoiceReportsReducer = (state = invoiceReportState, action) => {
  switch (action.type) {
    case actions.GET_INVOICE_EOD_CHART:
      return {
        ...state,
        eodCharts: {
          ...state.eodCharts,
          loading: true,
        },
      };
    case actions.SET_STATEMENT_PRINT_DATA:
      return {
        ...state,
        statementsPrint: {
          columns: action.payload.columns,
          rows: action.payload.rows,
          cb: action.payload.cb
        }
      };
    case actions.GET_INVOICE_EOD_CHART_SUCCESS:
      return {
        ...state,
        eodCharts: {
          ...state.eodCharts,
          loading: false,
          ...action.payload,
        },
      };
    case actions.GET_INVOICE_EOD_CHART_FAIL:
      return {
        ...state,
        eodCharts: {
          ...state.eodCharts,
          loading: false,
          error: true,
        },
      };
    case actions.GET_INVOICE_VALUE_CHART:
      return {
        ...state,
        valueChart: {
          ...state.valueChart,
          loading: true,
        },
      };
    case actions.GET_INVOICE_VALUE_CHART_SUCCESS:
      return {
        ...state,
        valueChart: {
          ...state.valueChart,
          loading: false,
          ...action.payload,
        },
      };
    case actions.GET_INVOICE_VALUE_CHART_FAIL:
      return {
        ...state,
        valueChart: {
          ...state.valueChart,
          loading: false,
          error: true,
        },
      };
    case actions.GET_INVOICE_PAYMENT_CHART:
      return {
        ...state,
        paymentChart: {
          ...state.paymentChart,
          loading: true,
          error: false,
        },
      };
    case actions.GET_INVOICE_PAYMENT_CHART_SUCCESS:
      return {
        ...state,
        paymentChart: {
          ...state.paymentChart,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.GET_INVOICE_PAYMENT_CHART_FAIL:
      return {
        ...state,
        paymentChart: {
          ...state.paymentChart,
          loading: false,
          error: true,
        },
      };
    case actions.SEND_STATEMENT_REPORT:
      return {
        ...state,
        statements: {
          ...state.statements,
          loading: true,
          error: false,
        },
      };
    case actions.SEND_STATEMENT_REPORT_SUCCESS:
      return {
        ...state,
        statements: {
          sendEmail: action.payload,
          loading: false,
          error: false,
        },
      };
    case actions.SEND_STATEMENT_REPORT_FAIL:
      return {
        ...state,
        statements: {
          ...state.statements,
          loading: false,
          error: true,
        },
      };
    case actions.GET_INVOICE_STATEMENTS:
      return {
        ...state,
        statements: {
          ...state.statements,
          loading: true,
          error: false,
        },
      };
    case actions.GET_INVOICE_STATEMENTS_SUCCESS:
      return {
        ...state,
        statements: {
          table: action.payload,
          loading: false,
          error: false,
        },
      };
    case actions.GET_INVOICE_STATEMENTS_FAIL:
      return {
        ...state,
        statements: {
          ...state.statements,
          loading: false,
          error: true,
        },
      };
    case actions.GET_INVOICE_STATEMENTS_COUNT:
      return {
        ...state,
        statements: {
          ...state.statements,
          count: 0,
        },
      };
    case actions.GET_INVOICE_STATEMENTS_COUNT_SUCCESS:
      return {
        ...state,
        statements: {
          ...state.statements,
          count: action.payload,
        },
      };
    case actions.GET_INVOICE_STATEMENTS_COUNT_FAIL:
      return {
        ...state,
        statements: {
          ...state.statements,
          count: 0,
        },
      };

    case actions.JOB_REPORT_TABLE:
      return {
        ...state,
        jobReport: {
          ...state.jobReport,
          loading: true,
          error: false,
        },
      };
    case actions.JOB_REPORT_TABLE_SUCCESS:
      return {
        ...state,
        jobReport: {
          table: action.payload,
          loading: false,
          error: false,
        },
      };
    case actions.JOB_REPORT_TABLE_FAIL:
      return {
        ...state,
        jobReport: {
          ...state.jobReport,
          loading: false,
          error: true,
        },
      };


    default:
      return state;
  }
};
