import {
  GET_STRIPE_PAYMENT_INTEGRATION,
  GET_STRIPE_PAYMENT_INTEGRATION_SUCCESS,
  GET_STRIPE_PAYMENT_INTEGRATION_FAIL,
  SAVE_STRIPE_PAYMENT_INTEGRATION,
  SAVE_STRIPE_PAYMENT_INTEGRATION_SUCCESS,
  SAVE_STRIPE_PAYMENT_INTEGRATION_FAIL,
  GET_GOPAY_PAYMENT_INTEGRATION,
  GET_GOPAY_PAYMENT_INTEGRATION_SUCCESS,
  GET_GOPAY_PAYMENT_INTEGRATION_FAIL,
  SAVE_GOPAY_PAYMENT_INTEGRATION,
  SAVE_GOPAY_PAYMENT_INTEGRATION_SUCCESS,
  SAVE_GOPAY_PAYMENT_INTEGRATION_FAIL,
} from "./actionTypes";

const initialState = {
  loadingLogins: false,
  message: "",
  error: "",
  stripePaymentIntegration: {},
  gopayPaymentIntegration: {}
};

const PaymentIntegrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STRIPE_PAYMENT_INTEGRATION:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_STRIPE_PAYMENT_INTEGRATION_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        stripePaymentIntegration: action.payload,
      };
      break;
    case GET_STRIPE_PAYMENT_INTEGRATION_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        stripePaymentIntegration: {},
        error: {
          message: action.payload,
        },
      };
      break;

    case SAVE_STRIPE_PAYMENT_INTEGRATION:
      state = {
        ...state,
      };
      break;
    case SAVE_STRIPE_PAYMENT_INTEGRATION_SUCCESS:
      state = {
        ...state,
        stripePaymentIntegration: action.payload,
      };
      break;
    case SAVE_STRIPE_PAYMENT_INTEGRATION_FAIL:
      state = {
        ...state,
        stripePaymentIntegration: {},
        error: {
          message: action.payload,
        },
      };
      break;

      case GET_GOPAY_PAYMENT_INTEGRATION:
        state = {
          ...state,
          loadingLogins: true,
        };
        break;
      case GET_GOPAY_PAYMENT_INTEGRATION_SUCCESS:
        state = {
          ...state,
          loadingLogins: false,
          gopayPaymentIntegration: action.payload,
        };
        break;
      case GET_GOPAY_PAYMENT_INTEGRATION_FAIL:
        state = {
          ...state,
          loadingLogins: false,
          gopayPaymentIntegration: {},
          error: {
            message: action.payload,
          },
        };
        break;
  
      case SAVE_GOPAY_PAYMENT_INTEGRATION:
        state = {
          ...state,
        };
        break;
      case SAVE_GOPAY_PAYMENT_INTEGRATION_SUCCESS:
        state = {
          ...state,
          gopayPaymentIntegration: action.payload,
        };
        break;
      case SAVE_GOPAY_PAYMENT_INTEGRATION_FAIL:
        state = {
          ...state,
          gopayPaymentIntegration: {},
          error: {
            message: action.payload,
          },
        };
        break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PaymentIntegrationReducer;
