import React, { useState } from "react";
import LabourChargeForm from "./components/labourChargeForm";
import LabourChargesList from "./components/labourChargesList";

const LabourCharges = () => {
  const [handleSubmit, setHandleSubmit] = useState(false);
  return (
    <>
      <div className="card-header d-flex justify-space-between">
        <h6 className="mb-0 text-uppercase py-2">LABOUR CHARGES</h6>
        <button
          type="button"
          className="btn btn-success px-4 btn-rounded"
          onClick={() => setHandleSubmit(true)}
        >
          Add
        </button>
      </div>
      <div className="card-body">
        <LabourChargeForm handleSubmit={handleSubmit} setHandleSubmit={setHandleSubmit}/>

        <LabourChargesList />
      </div>
    </>
  );
};

export default LabourCharges;
