import { DatePicker, TimePicker } from "antd";
import { ContentState, EditorState, Modifier, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { addScheduleActivity, deleteActivity, getSubscriptionAddon } from "helpers/backendHelper";
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import CustomerAndVehicle from "../../modals/customerAndVehicle";
import { showSuccess, showError, showInfo } from "../../../utils/alertMessage";
import MonthViewCreateJob from "./MonthViewCreateJob";
import DeleteConfirm from '../../../utils/deleteConfirm';
import smsImage from '../../../../src/assets/images/sms-vector.png';
import IcCustomerBlue from "../../../assets/images/ic_customer_blue.svg";
import { SENT_BOOKINGJOB_SMS } from "../../../store/jobDetails/actionTypes";
import { sentBookingJobSmsSuccess } from "../../../store/jobDetails/actions";
import {
    ADD_ACTIVITY,
    ADD_MANY_CREATE_JOB,
    ADD_MANY_JOB_KIT_LABOURS,
    ADD_MANY_JOB_KIT_PARTS,
    ADD_MANY_JOB_LABOURS,
    ADD_MANY_JOB_PARTS,
    ADD_SCHEDULE_ACTIVITY,
    CREATE_QUOTE_TO_JOB,
    DELETE_JOB_SCHEDULE_ACTIVITY,
    GET_ACTIVITY,
    GET_AVAIALABLE_SLOTS,
    GET_BAYS_JOB_COUNT,
    GET_INSPECTION_QUE_ANS,
    GET_INSURANCE,
    GET_JOB_TAGS,
    ADD_JOB_TAGS,
    GET_QUOTE_DETAILS,
    GET_QUOTE_KIT_PARTS,
    GET_QUOTE_KIT_LABOURS,
    GET_SCHEDULE_ACTIVITY
} from "../../../store/scheduler/actionTypes";
import { convertDurationToDecimal, convertToHoursMins, convertToMinute, timeZoneToUTC, utcToTimezone } from "../../../utils/timeZoneConvert";

import { addManyRecommendations, updateInspection } from "helpers/backendHelper";
import { getQuoteDetailsSuccess } from 'store/scheduler/actions';
import { getCustomerAndVehicleDetailsSuccess } from 'store/unassignedJobs/actions';
import { useDataProvider } from "utils/DataContext";
import CarMakeLogo from "views/common/UI/CarMakeLogo";
import Recommendations from "views/job/components/jobDetails/jobDetailsTab/Recommendations";
import AskMobileNumber from "./AskMobileNumber";
import JobSmsBalance from "views/modals/JobSmsBalance";
import { GET_ASSIGNED_JOBS } from "../../../store/assignedJobs/actionTypes";
import { GET_JOBCANNED_LIST, GET_JOBDEPARTMENT_LIST, GET_JOBTYPE_LIST } from "../../../store/commonApiStore/actionTypes";
import { CHECK_MOBILE_EXIST } from "../../../store/contacts/actionTypes";
import { EDIT_QUOTES, GET_OPEN_QUOTES } from "../../../store/openQuotes/actionTypes";
import { CREATE_JOB, GET_CUSTOMERANDVEHICLE_DETAILS, GET_CUSTOMERS_LIST } from "../../../store/unassignedJobs/actionTypes";
import { unserializeArr } from "../../../utils/unserialize";
import TimeField from "./TimeField";
import { useInitialData } from "views/common/InitialDataContext/InitialDataContext";

let CreateJob = ({ setUpdateCalendar, close, technicianType, technicianId, startTimeCalendar, endTimeCalendar,
    technicianName, show, slotDuration, showMonthViewPopup, dashboardJob, updateCalendar, handleSlotLimitClose,
    estimateTimeSlot = [], getJobEstimateTimeData
}) => {
    let { vehicle_id } = useParams();
    useEffect(() => {
        if (vehicle_id) {
            getVehicleDetails(vehicle_id, true)
        }
    }, [vehicle_id])

    // Set current active date from dashboard
    const { data, setData } = useDataProvider();

    const navigate = useNavigate(); // Navigate URL
    const dispatch = useDispatch(); // Dispatch variable
    const [isLoading, setIsLoading] = useState(true);
    const [customerShow, setCustomerShow] = useState(false);
    const [activeStep, setActiveStep] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [manageStateModal, setManageStateModal] = useState('parent');
    const [jobFormValue, setJobFormValue] = useState([]);
    const [createJobFlag, setCreateJobFlag] = useState(false);
    const [createLabourFlag, setCreateLabourFlag] = useState(false);
    const [createQuoteFlag, setCreateQuoteFlag] = useState(false);
    const [technicianNameNew, setTechnicianName] = useState("");
    const [updateActivityList, setUpdateActivityList] = useState(false);
    const [updateActivity, setUpdateActivity] = useState(false);
    const [monthViewDisabled, setMonthViewDisabled] = useState();
    const [totalLabour, setTotalLabour] = useState(0); // For inspection labour time
    // const [parentJobId,setParentJobId] = useState(0); // job id for add inspection 
    const [clickBaysSlot, setClickBaysSlot] = useState(false);
    const [bookingConfirmationCheckbox, setBookingConfirmationCheckbox] = useState(false);

    const [showSmsBalanceModal, setShowSmsBalanceModal] = useState(false);
    const handleShowSmsBalanceModal = () => setShowSmsBalanceModal(true);
    const handleCloseSmsBalanceModal = () => setShowSmsBalanceModal(false);
    const [isSmsSubscribe, setIsSmsSubscribe] = useState(0);
    const [validateMobile, setValidateMobile] = useState(false);
    const [isUpdateMobile, setIsUpdateMobile] = useState(false);
    // const [createJobEstimateTime, setCreateJobEstimateTime] = useState({ value: "00:30", label: "00:30" });
    const [createJobEstimateTime, setCreateJobEstimateTime] = useState("00:30");
    const [showJobEstimateSlot, setShowJobEstimateSlot] = useState(false);

    const [pickupTimeSlot, setPickupTimeSlot] = useState([]);
    const [afterPickupTimeSlot, setAfterPickupTimeSlot] = useState([]);
    const [showPickupTimeSlot, setShowPickupTimeSlot] = useState(false);
    const [selectedPickupTimeSlot, setSelectedPickupTimeSlot] = useState();
    const [saveIsDisable, setSaveIsDisable] = useState(false);


    let MobileExist = useSelector(
        (state) => state.ContactsReducer.checkMobileData
    );
    const checkMobileExist = (e) => {
        if (e.target.value.length !== 0) {
            if (e.target.value.length >= 9 && e.target.value.length <= 11) {
                // if (e.target.value.length === 10) {
                setValidateMobile(false);

                dispatch({
                    type: CHECK_MOBILE_EXIST,
                    payload: {
                        get: "all",
                        conditions: {
                            mobile_number: '0' + e.target.value,
                            is_deleted: 0,
                            workshop_id: workshop_id,
                        },
                    },
                });
            } else {
                setValidateMobile(true);
            }
        } else {
            MobileExist = false;
        }
    };

    useEffect(() => {
        setJobFormValue({ ...jobFormValue, 'source_of_business': "Existing" })
    }, [])

    useEffect(() => {
        if (showMonthViewPopup === true) {
            setMonthViewDisabled('disabled')
        }
    }, [showMonthViewPopup])

    const setFormValue = (e) => {
        setJobFormValue({ ...jobFormValue, [e.target.name]: e.target.value })
    }

    const ChangeActiveStep = () => {
        setActiveStep(!activeStep);
    };

    const onEditorStateChange = (editorState) => {
        let draftToHtmlData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setJobFormValue(
            {
                ...jobFormValue,
                'script_description': draftToHtmlData
            }
        );
        setEditorState(editorState)
    };

    const updateJobDescription = (recommendationContent, id, showStatus) => {

        const currentRecommendation = selectedUser?.recommendations.find((c) => c.id === id)

        let draftToHtmlData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let lengthOfCurrData = draftToHtmlData.trim().replace("<p></p>", "").length;

        if (showStatus) {
            currentRecommendation.add_is_show = '1';
            var descriptionVal = recommendationContent;
            if (lengthOfCurrData > 0) {
                descriptionVal = "<br/><p>" + recommendationContent + "</p>";
            }

            let { contentBlocks, entityMap } = htmlToDraft(descriptionVal);
            let contentState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
            );
            onEditorStateChange(EditorState.push(editorState, contentState, 'insert-characters'));
        } else {
            currentRecommendation.add_is_show = '0';
        }
    }

    // Create Customer and Vehicle
    const [customerFormType, setCustomerFormType] = useState();
    const [customerCreatedData, setCustomerDetails] = useState();
    const [vehicleCreatedData, setVehicleDetails] = useState();

    useEffect(() => {

        if (manageStateModal === 'parent' &&
            (customerCreatedData !== undefined || vehicleCreatedData !== undefined) &&
            (customerCreatedData !== null || vehicleCreatedData !== null)
        ) {
            if (customerFormType === "add") {
                getVehicleDetails(vehicleCreatedData?.rego || vehicleCreatedData?.rego_val);
            }

            if (customerFormType != "add") {

                var customerId = customerCreatedData?.id || '';
                var custType = customerCreatedData?.customers_details?.[0]?.type || '';

                if (customerCreatedData !== null && customerCreatedData !== undefined && selectedUser == null) {
                    var firstname = customerCreatedData?.customers_details?.[0]?.firstname || '';
                    var lastname = customerCreatedData?.customers_details?.[0]?.lastname || '';
                    var customertype = customerCreatedData?.customers_details?.[0]?.type || null;
                    if (customertype == 1) {
                        setCustomerName(customerCreatedData?.customers_details?.[0]?.company_name || '');
                        setFirstName(customerCreatedData?.customers_details?.[0]?.company_name || '');
                    } else {
                        setCustomerName(firstname + " " + lastname);
                        setFirstName(firstname);
                    }
                    setMobileNumber(customerCreatedData?.customers_details?.[0]?.mobile_number || '');
                    setMobileCode(customerCreatedData?.customers_details?.[0]?.mobile_code || '');
                    setEmail(customerCreatedData?.email || '');
                } else {
                    customerId = selectedUser?.user_id || '';
                    custType = selectedUser?.type || '';

                    var firstnameSelected = selectedUser?.firstname || '';
                    var lastnameSelected = selectedUser?.lastname || '';
                    var customertypeSelected = selectedUser?.type || null;
                    if (customertypeSelected == 1) {
                        setCustomerName(selectedUser?.company_name || '');
                        setFirstName(selectedUser?.company_name || '');
                    } else {
                        setCustomerName(firstnameSelected + " " + lastnameSelected);
                        setFirstName(firstnameSelected);
                    }
                    setMobileNumber(selectedUser.mobile || '');
                    setMobileCode(selectedUser?.mobile_code || '');
                    setEmail(selectedUser?.email || '');
                }

                if (vehicleCreatedData !== null && vehicleCreatedData !== undefined && customerCreatedData == null) {
                    setMobileNumber(vehicleCreatedData?.mobile_number || '');
                    setMobileCode(vehicleCreatedData?.mobile_code || '');
                    setCustomerName(vehicleCreatedData?.customer_name1 || '');
                    setFirstName(vehicleCreatedData?.customer_name1 || '');
                    setEmail(vehicleCreatedData?.users_details?.user?.email || vehicleCreatedData?.email);
                    customerId = vehicleCreatedData.user_id;
                }

                setRego(vehicleCreatedData?.rego || vehicleCreatedData?.rego_val);
                setMake(vehicleCreatedData?.make || '');
                setModal(vehicleCreatedData?.model || '');
                setYear(vehicleCreatedData?.year || '');
                // setInspectionRoType(customerVehicleData?.[0]?.insp_inspections || null);
                setActiveSearchList(false);
                setVechicleSection('');
                if (rego !== "" && rego !== undefined) {
                    setCustomerVehicleFlag('');
                }

                var tempJobformData = jobFormValue;
                tempJobformData.rego = vehicleCreatedData?.rego || vehicleCreatedData?.rego_val;
                tempJobformData.vehicle_id = vehicleCreatedData?.id || '';
                tempJobformData.customer_id = customerId;
                tempJobformData.veh_type = customerVehicleData?.[0]?.type || '';
                tempJobformData.veh_type_name = vehicleCreatedData?.vehicle_type || '';
                tempJobformData.cust_type = custType;
                setJobFormValue(tempJobformData);

                if (customerFormType == "view") {
                    if (rego !== "" && rego !== undefined) {
                        getVehicleDetails(rego);
                    } else {
                        let filterVinDataSelected = vinData.filter(function (item) {
                            let mobileNumberNew = (item.mobile).toLowerCase();
                            if (mobileNumberNew.indexOf(mobileNumber) !== -1) {
                                return vinData;
                            }
                        });
                        setSelectedUser(filterVinDataSelected?.[0]);
                        setCustomerVehicleFlag('disabled');
                    }
                }
            }
        }
    }, [customerCreatedData, vehicleCreatedData])

    const [selectedUser, setSelectedUser] = useState({});
    const [showCustomersAndVehiclesModal, setShowCustomersAndVehiclesModal] = useState(false);
    const [updateCustList, setUpdateCustList] = useState(false)
    useEffect(() => {
        if (showCustomersAndVehiclesModal === false) {
            setUpdateCustList(!updateCustList)
        }
    }, [showCustomersAndVehiclesModal])

    const handleCustomerShow = () => {
        setCustomerFormType('add');
        setSelectedUser({});
        (showCustomersAndVehiclesModal === true) ? setShowCustomersAndVehiclesModal(false) : setShowCustomersAndVehiclesModal(true);
        setManageStateModal('child');
        setCustomerShow(true);
    };

    const viewCustomerVehicle = () => {
        selectedUser.rego = rego;
        setCustomerFormType('view');
        if (rego !== "" && rego !== undefined) {
            getVehicleDetails(rego);
        }
        setShowCustomersAndVehiclesModal(true);
        setManageStateModal('child');
    }

    // for dynamic value of job popup
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    const localStoredData = JSON.parse(localStorage.getItem('data'));
    const defaultCountryId = localStoredData?.workshop?.country;
    const NzCountryId = process.env.REACT_APP_REGO_NZ_ID;
    let workshop_id = window.atob(storedData.workshop_id);
    let user_id = window.atob(storedData.user_id);
    let date_format = storedData.date_format;
    let timezoneLable = window.atob(storedData.timezone_lable);
    let schedule_details = window.atob(storedData.schedule_details);
    let part_gst = window.atob(storedData.part_gst);
    let startJobTime = moment(startTimeCalendar).format('DD/MM/YYYY hh:mm a');
    let endJobTime = moment(endTimeCalendar).format('DD/MM/YYYY hh:mm a');
    let startEventTime = moment(startTimeCalendar).format('DD/MM/YYYY');
    let startTimeNew = moment(startTimeCalendar).format('YYYY-MM-DD');

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [durationJob, setDurationJob] = useState();
    const [job_user_id, setUserId] = useState();
    const [job_bay_id, setBayId] = useState();
    const [validated, setValidated] = useState(false);
    const [activityValidated, setActivityValidated] = useState(false);
    const [createEvent, setCreateEvent] = useState('Jobs');
    const [createdJobId, setCreatedJobId] = useState();
    const [createdJobCustomer, setCreatedJobCustomer] = useState();
    const [jobNumber, setJobNumber] = useState();

    // const [udpateJobBookingConfirmation, setUdpateJobBookingConfirmation] = useState(false);

    //Customer & vehicle section 

    const [updateMobileNumber, setUpdateMobileNumber] = useState(false);
    const [vehicleSection, setVechicleSection] = useState('hide');
    const [filterVinDatas, setFilterVinDatas] = useState([]);

    const [vinData, setVinData] = useState([]);

    const [activeSearchList, setActiveSearchList] = useState(false);
    const [rego, setRego] = useState();
    const [customerName, setCustomerName] = useState();
    const [firstName, setFirstName] = useState();
    const [mobileNumber, setMobileNumber] = useState();

    const [mobileCode, setMobileCode] = useState();
    const [email, setEmail] = useState();
    const [make, setMake] = useState();
    const [modal, setModal] = useState();
    const [year, setYear] = useState();

    const [inspectionRoType, setInspectionRoType] = useState(null);
    const [customerVehicleFlag, setCustomerVehicleFlag] = useState('disabled');

    //Job type filter data 
    const [filterJobType, setFilterJobType] = useState([]);

    //job canned response filter data
    const [filterCannedResponse, setCannedResponse] = useState([]);

    //job canned response filter data
    const [jobTagsData, setJobTagsData] = useState([]);
    const [getJobTagsData, setGetJobTagsData] = useState([]);
    const [tagsArrId, setTagsArrId] = useState([]);

    //Quote listing 
    const [quoteList, setQuoteList] = useState([]);
    const [makeQuote, setMakeQuote] = useState('MAKE');
    const [modelQuote, setModelQuote] = useState('MODEL');
    const [yearQuote, setYearQuote] = useState('YEAR');
    const [jobDeptQuote, setJobDeptQuote] = useState('DEPARTMENT');
    const [jobTypeQuote, setJobTypeQuote] = useState('TYPE');
    const [descQuote, setDescQuote] = useState('DESCRIPTION');
    const [showJob, setShow] = useState(false);

    const formRef = useRef(null);
    let vin_data = [];
    let filterVinData = [];

    // Reducer (data store)

    // get booking confirmation
    const jobBookingConfirmation = useSelector((state) => state?.JobDetails?.jobBookSmsSent || false);
    const customerVehicleData = useSelector((state) => state.UnassignedJobsReducer.customerAndVehicleData);
    const getCustomerData = useSelector((state) => state.UnassignedJobsReducer.customerData);

    // for quote to job 
    // const quoteToJobResp = useSelector((state) => state.UnassignedJobsReducer.createJob);
    const quoteToJobResp = null;
    const insuranceData = useSelector((state) => state.SchedulerReducer.insuranceData);
    const jobDepartmentData = useSelector((state) => state?.CommonApiStoreReducer?.jobDepartmentData || null);
    const jobTypeData = useSelector((state) => state?.CommonApiStoreReducer?.jobTypeData || null);
    const cannedResponse = useSelector((state) => state?.CommonApiStoreReducer?.cannedData || null);
    const labourChargesList = useSelector((state) => state?.CommonApiStoreReducer?.labourChargesList || null);
    const tagsData = useSelector((state) => state.SchedulerReducer.tagsData);
    const baysJobCount = useSelector((state) => state.SchedulerReducer.getBaysJobCount);

    const inspectionQueAns = useSelector((state) => state.SchedulerReducer.getInspectionQueAns);
    // for quote details
    const quoteDetailsData = useSelector((state) => state.SchedulerReducer.createQuoteDetails);

    const quoteKitParts = useSelector((state) => state.SchedulerReducer.getQuoteKitParts);
    const quoteKitLabours = useSelector((state) => state.SchedulerReducer.getQuoteKitLabours);

    const quotesData = useSelector((state) => state.OpenQuotesReducer.openQuotesData);
    const quotesDetailsData = useSelector((state) => state.SchedulerReducer.quoteDetails);

    const saveQuoteDetails = useSelector((state) => state.WonQuotesReducer.wonQuotesData);

    const updateQuotesData = useSelector((state) => state.OpenQuotesReducer.editQuotesData);
    const addTagsData = useSelector((state) => state.SchedulerReducer.addtagsData);
    const createJobData = useSelector((state) => state.UnassignedJobsReducer.createJob);
    // For activity 
    const activityData = useSelector((state) => state.SchedulerReducer.activityData);
    const activityScheduleData = useSelector((state) => state.SchedulerReducer.activityScheduleData);
    const addActivityData = useSelector((state) => state.SchedulerReducer.addActivityData);
    const addActivityScheduleData = useSelector((state) => state.SchedulerReducer.addActivityScheduleData);
    const addCreateActivityJob = useSelector((state) => state.SchedulerReducer.createJobActivity);
    const deleteJobActivity = useSelector((state) => state.SchedulerReducer.deleteJobScheduleActivity);

    const getJobDetailsActivity = useSelector((state) => state.assignedJobsReducer.jobData);

    useEffect(() => {

        if (jobBookingConfirmation) {
            showSuccess('Job booking confirmation sent successfully!');
            setBookingConfirmationCheckbox(true);
            // setUdpateJobBookingConfirmation(false);
            dispatch(sentBookingJobSmsSuccess(null))
        }
    }, [jobBookingConfirmation])

    useEffect(() => {
        if (baysJobCount !== null && baysJobCount !== undefined && clickBaysSlot === true) {
            if (baysJobCount > 0) {
                showInfo(baysJobCount + " Jobs are booked in this slot.");
            }
            setClickBaysSlot(false);
        }
    }, [baysJobCount])

    useEffect(() => {
        if (createdJobId !== null && createJobFlag === true && inspectionQueAns !== null && inspectionQueAns !== undefined) {
            let partsArrJob = [];
                let totalLabourTime = 0;
                inspectionQueAns.map((insp, index) => {
                    if (insp.product) {
                        let getAns = JSON.parse(insp.answers);
                        if (getAns?.ans?.estimatedHours) {
                            let job_part = {};
                            let minutes = getAns?.ans?.estimatedHours;
                            let purchase_order_qty = 0;
                            let basic_stock = insp?.product?.stock_in_hand || 0;
                            let price = getAns?.ans?.estimatedCost;
                            let margin = 0;
                            let profit = 0;
                            let estimateCost = +getAns?.ans?.estimatedCost || 0; // new Retail
                            let costPrice = insp?.product?.cost_price || 0; // original cost
                            let originalSell = insp?.product?.sell_price || 0;
                            let originalRetail = insp?.product?.retail_price || 0;
                            let newSell = 0;
                            let newProfitPrice = 0;
                            let markupPrec = 0;
                            let gst_price = 0;
                            let applyGst = false;
                            let product_name = insp?.product?.name;
                            let product_description = insp?.product?.description;

                            if (insp.product.stock_in_hand <= 0) {
                                purchase_order_qty = 1;
                                basic_stock = 0;
                            }

                            if (insp.product.purchase_tax_code_is_default == 1) {
                                if (!part_gst) {
                                    part_gst = 0;
                                }
                                price = estimateCost * part_gst;
                            }

                            if (originalRetail != 0) {
                                newSell = (estimateCost * originalSell) / originalRetail;
                            }

                            profit = originalSell - costPrice;

                            if (newSell >= costPrice) {
                                newProfitPrice = newSell - costPrice;
                                markupPrec = (newProfitPrice * 100) / costPrice;
                            }

                            if (markupPrec != 0) {
                                margin = ((newSell - costPrice) / newSell) * 100;
                            }

                            if (newSell !== estimateCost) {
                                applyGst = true;
                            }

                            if (applyGst) {
                                gst_price = parseFloat(newSell) * parseFloat(part_gst) / 100;
                            }


                            job_part['job_part_name'] = product_name;
                            job_part['job_part_description'] = product_description || product_name;
                            job_part['cost'] = parseFloat(costPrice);
                            job_part['margin'] = parseFloat(markupPrec);
                            job_part['product_id'] = insp?.part_id || 0;
                            job_part['sell'] = parseFloat(newSell);
                            job_part['retail'] = parseFloat(estimateCost);
                            job_part['qty'] = 1;
                            job_part['price'] = parseFloat(estimateCost);
                            job_part['profit'] = parseFloat(profit);
                            job_part['time'] = parseFloat(minutes);
                            job_part['gst'] = (applyGst) ? 1 : 0;
                            job_part['gst_price'] = parseFloat(gst_price).toFixed(2);
                            job_part['gst_total'] = parseFloat(gst_price).toFixed(2);
                            job_part['sell_total'] = parseFloat(newSell).toFixed(2);
                            job_part['basic_stock'] = basic_stock;
                            job_part['purchase_order_qty'] = purchase_order_qty;
                            job_part['job_schedule_id'] = createdJobId;
                            job_part['workshop_id'] = workshop_id;
                            job_part['is_inspection_part'] = 1;
                            job_part['margin_markup'] = margin;
                            partsArrJob.push(job_part);
                            totalLabourTime = totalLabourTime + minutes;
                        }
                    }
                })
                setTotalLabour(totalLabourTime);
                dispatch({
                    type: ADD_MANY_JOB_PARTS,
                    payload: {
                        data: partsArrJob
                    }
                })

                if (labourChargesList !== null && labourChargesList !== undefined) {
                    let jobLaboursArr = [];

                    let labour_cost_pr_minute = labourChargesList[0].cost_price / 60;
                    let labour_sell_pr_minute = labourChargesList[0].sell_price / 60;
                    let labour_cost_price = labourChargesList?.[0]?.cost_price || 0;
                    let labour_sell_price = labourChargesList?.[0]?.sell_price || 0;
                    let labour_retail_price = labourChargesList?.[0]?.retail_price || 0;
                    let labour_total_price_pr_minute = labourChargesList?.[0]?.retail_price / 60;
                    let labour_desc = labourChargesList?.[0]?.description || '';
                    let hours = convertToHoursMins(totalLabourTime);
                    let labour_ph = 0;
                    if (hours) {
                        let [hr, mn] = hours.split(":");
                        if (isNaN(parseInt(hr))) hr = "00";
                        if (isNaN(parseInt(mn))) mn = "00";
                        labour_ph = ((parseInt(hr) * 60 + parseInt(mn)) / 60).toFixed(2);
                    }

                    let laboursDataArr = {
                        labour_charges_id: labourChargesList[0].id,
                        job_schedules_id: createdJobId,
                        is_inspection: 1,
                        cost_price: labour_cost_price,
                        margin: labourChargesList[0].margin,
                        sell_price: labour_sell_price,
                        retail_price: labour_retail_price,
                        profit: (labour_sell_pr_minute * totalLabourTime) - (labour_cost_pr_minute * totalLabourTime),
                        labour_time: hours,
                        labour_description: labour_desc,
                        labour_ph: labour_ph,
                        sell_total: parseFloat(labour_sell_price) * parseFloat(labour_ph),
                        gst_total: parseFloat(labourChargesList[0].gst_price) * parseFloat(labour_ph),
                        total_price: (labour_total_price_pr_minute * totalLabourTime)
                    };
                    jobLaboursArr.push(laboursDataArr);
                    dispatch({
                        type: ADD_MANY_JOB_LABOURS,
                        payload: {
                            data: jobLaboursArr
                        }
                    })
                }

                showSuccess('Job Created Successfully!');
                setCreateJobFlag(false);
                setManageStateModal('save')
                handleShow();
        }
    }, [createdJobId])

    useEffect(() => {
        if (jobDepartmentData !== null && jobDepartmentData !== undefined) {
            let selectedJobDept = jobDepartmentData.filter((item) => item.name === "Services");
            setJobFormValue({ ...jobFormValue, ['job_department']: selectedJobDept[0].id });
            if (jobTypeData !== null && jobTypeData !== undefined) {
                let filterJobTypeData = jobTypeData.filter((item) => JSON.stringify(item.parent_id) == selectedJobDept[0].job_type_parent_id);
                // WOF | Warrant of Fitness
                if (+defaultCountryId === +NzCountryId && +selectedJobDept[0].job_type_parent_id === 30) {
                    filterJobTypeData = filterJobTypeData.filter((item) => item.name === "WOF | Warrant of Fitness");
                    setFilterJobType(filterJobTypeData);
                } else {
                    setFilterJobType(filterJobTypeData);
                }

            }

            if (cannedResponse !== null && cannedResponse !== undefined) {
                let filterCannedResponseData = cannedResponse.filter((item) => JSON.stringify(item.department_id) == selectedJobDept[0].id)
                setCannedResponse(filterCannedResponseData)
            }
        }
    }, [jobDepartmentData, jobTypeData, cannedResponse])

    useEffect(() => {

        if (createJobData !== null && createJobData !== undefined) {
            if (createJobData?.data?.JobSchedules.length !== 0 && createJobFlag === true) {
                var id = createJobData?.data?.JobSchedules?.id || null;
                var job_customer_id = createJobData?.data?.JobSchedules?.customer_id || null;
                setCreatedJobId(id);
                setCreatedJobCustomer(job_customer_id);
                setJobNumber(createJobData?.data?.JobSchedules?.job_number);
                if (labourChargesList !== null && labourChargesList !== undefined && id !== null) {
                    let jobLaboursArr = [];
                    let laboursDataArr = {
                        labour_charges_id: labourChargesList[0].id,
                        job_schedules_id: id,
                        cost_price: labourChargesList[0].cost_price,
                        margin: labourChargesList[0].margin,
                        sell_price: labourChargesList[0].sell_price,
                        retail_price: labourChargesList[0].retail_price,
                        profit: labourChargesList[0].profit_price,
                        labour_description: labourChargesList[0].description,
                        gst_price: labourChargesList[0].gst_price
                    };

                    ["1", "2", "3"].map(cv => {
                        const copiedData = { ...laboursDataArr, labour_charge_type: cv }
                        
                        if (cv === "3") {
                            copiedData["labour_time"] = createJobData?.data?.JobSchedules?.job_estimate_time
                            copiedData["labour_ph"] = convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                            copiedData["sell_total"] = parseFloat(laboursDataArr.sell_price) * parseFloat(convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time))
                            copiedData["total_price"] = parseFloat(labourChargesList[0].retail_price) * convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                            copiedData["gst_total"] = parseFloat(labourChargesList[0].gst_price) * convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                        } else {
                            copiedData["sell_total"] = "0.00"
                            copiedData["total_price"] = "0.00"
                            copiedData["gst_total"] = "0.00"
                        }
                        return jobLaboursArr.push(copiedData)
                    })
                    
                    dispatch({
                        type: ADD_MANY_JOB_LABOURS,
                        payload: {
                            data: jobLaboursArr
                        }
                    })

                    let inspectionId = jobFormValue?.inspection_name || null;
                    if (inspectionId !== null && inspectionId !== "") {
                        updateInspection({ payload: { id: inspectionId, 'Insp_status': "Jobs" } });
                    } else {
                        showSuccess('Job Created Successfully!');
                        setCreateJobFlag(false);
                        setManageStateModal('save')
                        handleShow();
                    }
                }

                if (selectedUser?.recommendations) {
                    addRecommendations(id);
                }
            } else {

                if (createJobFlag === true) {
                    showError(createJobData.message);
                    setCreateJobFlag(false);
                    setManageStateModal('parent')
                    // handleShow();
                    handleClose();
                }
            }
        }
    }, [createJobData])


    // add recommendation list
    async function addRecommendations(id) {
        let recommendation_payload = [];
        selectedUser?.recommendations.map((recomm, index) => {
            // if(recomm?.add_is_show === "1") {
            let recomm_data = {};
            recomm_data['job_id'] = id;
            recomm_data['workshop_id'] = recomm?.workshop_id;
            recomm_data['vehicle_id'] = recomm?.vehicle_id;
            recomm_data['recommendation'] = recomm?.recommendation;
            recomm_data['recommendation_color'] = recomm?.recommendation_color;
            recomm_data['recommendation_status'] = recomm?.recommendation_status;
            recomm_data['is_show'] = recomm?.add_is_show ? recomm?.add_is_show : "0";
            // recomm_data['is_deleted'] = recomm?.add_is_show ? recomm?.add_is_show : "0" ;
            recommendation_payload.push(recomm_data);
            // }
        })

        let recommDetail = {
            payload: recommendation_payload,
        };

        await addManyRecommendations(recommDetail);
    }


    const clearModalData = () => {
        setCustomerFormType('');
        setCustomerDetails(null);
        setVehicleDetails(null);
        setCustomerVehicleFlag('disabled');
        dispatch(getCustomerAndVehicleDetailsSuccess(null))
        dispatch(getQuoteDetailsSuccess(null));
        setMakeQuote('MAKE');
        setModelQuote('MODEL');
        setYearQuote('YEAR');
        setJobDeptQuote('DEPARTMENT');
        setJobTypeQuote('TYPE');
        setDescQuote('DESCRIPTION');
        setBookingConfirmationCheckbox(false);
    }

    const handleClose = () => {
        clearModalData()
        setManageStateModal('parent');
        close();
        setShow(false);
        handleSlotLimitClose();
    }
    const handleShow = () => {
        setShow(true)
    };

    const handleTagsChange = (skills) => {
        setGetJobTagsData(skills || []);
    };

    const handleSchedule = () => {
        // if(dashboardJob === "dashboard-create-job"){
        //     // setUpdateGoToDate(true);
        //     setUpdateCalendar(true);
        // }else{
        if (vehicle_id) {
            clearModalData();
            setUpdateCalendar(!updateCalendar);
            close();
            setShow(false);
            navigate("/scheduler");
        }
        clearModalData();
        setUpdateCalendar(!updateCalendar);
        close();
        setShow(false);
        // }
        handleSlotLimitClose();
    }

    const handleViewJob = () => {
        clearModalData()
        close();
        setShow(false)
        if (createdJobId !== null && createdJobId !== undefined) {
            navigate("/job_detail/" + createdJobId);
        }
    }

    const handleDashboard = () => {
        if (dashboardJob === "dashboard-create-job") {
            setData(new Date(startTimeCalendar));
            clearModalData();
            setShow(false);
            close();
            navigate("/scheduler");
        } else {
            clearModalData()
            close();
            navigate("/booking-diary");
            setShow(false)
        }
    }

    useEffect(() => {
        if (rego !== "" && rego !== undefined) {
            setCustomerVehicleFlag('');
        } else {
            setCustomerVehicleFlag('disabled');
        }
    }, [rego])

    useEffect(() => {
        var startTimeInFormat = moment(new Date(startTimeCalendar)).format('YYYY-MM-DD HH:mm:ss');
        var fromData = timeZoneToUTC(startTimeInFormat, timezoneLable, "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD HH:mm:ss');
        var startDateJob = moment(new Date(startTimeCalendar)).format('YYYY-MM-DD');
        var startTimeJob = moment(fromData).format('HH:mm:ss');
        var durationMinutes = convertToMinute(slotDuration);
        var endDateJob = moment(new Date(startTimeCalendar)).format('YYYY-MM-DD');
        var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(durationMinutes, 'minutes').format('HH:mm:ss');

        setStartDate(startDateJob);
        setEndDate(endDateJob);
        setStartTime(startTimeJob);
        setEndTime(endTimeJob);
        setDurationJob(durationMinutes);

        if (technicianType === "bay") {
            setUserId(0);
            setBayId(technicianId);
        } else {
            setUserId(technicianId)
            setBayId(0);
        }
    }, [])

    useEffect(
        () => {
            setIsLoading(true);
            dispatch({
                type: GET_CUSTOMERS_LIST,
                payload: {
                    "get": "all",
                    "fields": [
                        "CustomersDetails.id",
                        "CustomersDetails.user_id",
                        "CustomersDetails.type",
                        "CustomersDetails.firstname",
                        "CustomersDetails.lastname",
                        "CustomersDetails.company_name",
                        "CustomersDetails.mobile_number",
                        "CustomersDetails.mobile_code",
                        "CustomersDetails.phone_number",
                        "CustomersDetails.customer_app",
                        "CustomersDetails.postcode",
                        "CustomersDetails.suburb",
                        "CustomersDetails.street_address",
                        "CustomersDetails.abn",
                        "CustomersDetails.position",
                        "CustomersDetails.website"
                    ],
                    "order": {
                        "CustomersDetails.firstname": "asc"
                    },
                    "conditions": {
                        "CustomersDetails.workshop_id": workshop_id,
                        "role_id": 4,
                        "CustomersDetails.is_deleted": 0,
                        "CustomersDetails.status": 1
                    },
                    "contain": {
                        "Users": {
                            "fields": [
                                "id",
                                "workshop_id",
                                "email"
                            ]
                        },
                        "Users.States": {
                            "fields": [
                                "id",
                                "name"
                            ]
                        },
                        "Users.Countries": {
                            "fields": [
                                "id",
                                "country_name"
                            ]
                        },
                        "Users.Vehicles": {
                            "fields": [
                                "Vehicles.user_id",
                                "Vehicles.rego"
                            ],
                            "conditions": {
                                "Vehicles.workshop_id": workshop_id,
                                "Vehicles.is_deleted": 0
                            }
                        }
                    }
                },
            });
        }, [updateCustList]);

    useEffect(() => {
        var fullname = "";
        var icon = false;
        var img = "";
        let address = "";
        let vehiclesData = "";
        let vinDataArr = {};
        let vehicleDataArr = {};
        if (getCustomerData !== null && getCustomerData !== undefined) {
            getCustomerData.map((customer, index) => {
                if (customer.type === "1") {
                    fullname = customer.company_name;
                } else {
                    fullname = customer.firstname + " " + customer.lastname;
                }
                icon = false;
                if (customer.customer_app === 1) {
                    icon = true;
                }
                address = customer.street_address + ",";
                address += customer.suburb + ",";
                // eslint-disable-next-line no-useless-concat
                address += customer?.user?.state?.name || '' + ",";
                // eslint-disable-next-line no-useless-concat
                address += customer?.user?.country?.country_name || '' + ",";
                address += customer.postcode;
                vehiclesData = customer?.user?.vehicles || null;
                vinDataArr = {};
                if (vehiclesData !== null && vehiclesData.length !== 0) {
                    vehiclesData.map((data, custIndex) => {
                        vehicleDataArr = {};
                        vehicleDataArr['user_id'] = customer.user_id;
                        vehicleDataArr['cust_id'] = customer.id;
                        vehicleDataArr['name'] = fullname;
                        vehicleDataArr['type'] = customer.type;
                        vehicleDataArr['firstname'] = customer.firstname;
                        vehicleDataArr['lastname'] = customer.lastname;
                        vehicleDataArr['companyname'] = customer.company_name;
                        vehicleDataArr['abn'] = customer.abn;
                        vehicleDataArr['website'] = customer.website;
                        vehicleDataArr['position'] = customer.position;
                        vehicleDataArr['mobile'] = customer.mobile_number;
                        vehicleDataArr['mobile_code'] = customer.mobile_code;
                        vehicleDataArr['phone'] = customer.phone_number;
                        vehicleDataArr['rego'] = (data.rego).toUpperCase();
                        vehicleDataArr['icon'] = icon;
                        vehicleDataArr['address'] = address;
                        vehicleDataArr['email'] = customer.user.email;
                        vehicleDataArr['is_vehicle_available'] = 1;
                        vehicleDataArr['suburb'] = customer.suburb;
                        vehicleDataArr['postcode'] = customer.postcode;
                        vehicleDataArr['state'] = customer?.user?.state?.name || "";
                        vehicleDataArr['state_id'] = customer?.user?.state?.id || "";
                        vehicleDataArr['country'] = customer?.user?.country?.country_name || "";
                        vehicleDataArr['country_id'] = customer?.user?.country?.id || "";
                        vin_data.push(vehicleDataArr);
                    })
                } else {
                    vinDataArr['user_id'] = customer.user_id;
                    vinDataArr['cust_id'] = customer.id;
                    vinDataArr['name'] = fullname;
                    vinDataArr['firstname'] = customer.firstname;
                    vinDataArr['lastname'] = customer.lastname;
                    vinDataArr['companyname'] = customer.company_name;
                    vinDataArr['mobile'] = customer.mobile_number;
                    vinDataArr['mobile_code'] = customer.mobile_code;
                    vinDataArr['phone'] = customer.phone_number;
                    vinDataArr['rego'] = "";
                    vinDataArr['icon'] = icon;
                    vinDataArr['address'] = address;
                    vinDataArr['email'] = customer?.user?.email || "";
                    vinDataArr['is_vehicle_available'] = 0;
                    vinDataArr['suburb'] = customer.suburb;
                    vinDataArr['postcode'] = customer.postcode;
                    vinDataArr['state'] = customer?.user?.state?.name || "";
                    vinDataArr['state_id'] = customer?.user?.state?.id || "";
                    vinDataArr['country'] = customer?.user?.country?.country_name || "";
                    vinDataArr['country_id'] = customer?.user?.country?.id || "";
                    vinDataArr['type'] = customer.type;
                    vinDataArr['abn'] = customer.abn;
                    vinDataArr['website'] = customer.website;
                    vinDataArr['position'] = customer.position;
                    vin_data.push(vinDataArr);
                }
            })
        }

        setVinData(vin_data);
        setIsLoading(false);
    }, [getCustomerData]);

    const [searchText, setSearchText] = useState();

    const getChildSearchVehicle = (e) => {
        let value = e.target.value.toLowerCase();
        setSearchText(value);
        if (value !== '') {
            searchVehicle(value);
            setActiveSearchList(true);
        } else {
            setActiveSearchList(false);
        }
    };

    const searchVehicle = (value) => {
        filterVinData = vinData.filter(function (item) {
            let vinData1 = (item.name + item.mobile + item.phone + item.rego).toLowerCase();
            let vinData = (item.name + item.mobile + item.phone + item.rego + item.icon).toLowerCase();
            if (vinData1.indexOf(value) !== -1) {
                return vinData;
            }
        });

        if (filterVinData.length <= 0) {
            filterVinData = ["No results found"];
        }

        setFilterVinDatas(filterVinData);
    };

    const getVehicleDetails = (id, defaultCustomer = false) => {
        setIsLoading(true);
        let condition = {
            "Vehicles.workshop_id": workshop_id,
            "Vehicles.status": 1,
            "Vehicles.rego": id,
        };
        if (defaultCustomer) {
            condition = {
                "Vehicles.workshop_id": workshop_id,
                "Vehicles.status": 1,
                "Vehicles.id": id,
            };
        }

        dispatch({
            type: GET_CUSTOMERANDVEHICLE_DETAILS,
            payload: {
                "get": "all",
                conditions: condition,
                "contain": {
                    "Users": {
                        "fields": [
                            "id",
                            "workshop_id",
                            "email"
                        ]
                    },
                    "Users.CustomersDetails": {
                        "conditions": {
                            "workshop_id": workshop_id
                        },
                        "fields": [
                            "id",
                            "user_id",
                            "workshop_id",
                            "mobile_number",
                            "mobile_code",
                            "firstname",
                            "lastname",
                            "type",
                            "company_name",
                            "customer_app",
                            "street_address",
                            "suburb",
                            "postcode",
                            "phone_number",
                            "abn",
                            "position",
                            "website"
                        ]
                    },
                    "Users.States": {
                        "fields": [
                            "id",
                            "name"
                        ]
                    },
                    "Users.Countries": {
                        "fields": [
                            "id",
                            "country_name"
                        ]
                    },
                    "InspInspections": {
                        "conditions": {
                            "InspInspections.Insp_status": "Approved",
                            "InspInspections.insp_work_type": "Need to book a job"
                        }
                    },
                    "0": "InspInspections.InspTemplates"
                },
                "fields": [
                    "Vehicles.id",
                    "Vehicles.user_id",
                    "Vehicles.workshop_id",
                    "Vehicles.rego",
                    "Vehicles.rego_state",
                    "Vehicles.make",
                    "Vehicles.model",
                    "Vehicles.month",
                    "Vehicles.year",
                    "Vehicles.type",
                    "Vehicles.vehicle_type",
                    "Vehicles.is_verified",
                    "Vehicles.odometer_reading",
                    "Vehicles.is_odometer",
                    "Vehicles.assets_master_id",
                    "Vehicles.assets_detail_id",
                    "Vehicles.vehicle_custom_fields",
                    "Vehicles.series",
                    "Vehicles.fuel_type",
                    "Vehicles.transmission",
                    "Vehicles.body_type",
                    "Vehicles.category",
                    "Vehicles.seat_capacity",
                    "Vehicles.tyre_size_front",
                    "Vehicles.tyre_size_rear",
                    "Vehicles.color",
                    "Vehicles.type",
                    "Vehicles.vin_number",
                    "Vehicles.chasis_number",
                    "Vehicles.engine_number",
                    "Vehicles.fleet_number",
                    "Vehicles.engine_hours",
                    "Vehicles.engine_type",
                    "Vehicles.engine_size",
                    "Vehicles.cyliners",
                    "Vehicles.no_of_axles",
                    "Vehicles.bike_type",
                    "Vehicles.brakes",
                    "Vehicles.trailer_type",
                    "Vehicles.number_of_wheels",
                    "Vehicles.gross_weight",
                    "Vehicles.tare_weight",
                    "Vehicles.hitch_type",
                    "Vehicles.hitch_size",
                    "Vehicles.trailer_size",
                    "Vehicles.travel_height",
                    "Vehicles.travel_length",
                    "Vehicles.aggregate_trailer_mass",
                    "Vehicles.tow_ball_weight",
                    "Vehicles.tare_weight",
                    "Vehicles.sleeps_up_to",
                    "Vehicles.configuration",
                    "Vehicles.date_of_manufacture",
                    "Vehicles.boat_construction",
                    "Vehicles.engine_location",
                    "Vehicles.number_of_engines",
                    "Vehicles.boat_type",
                    "Vehicles.boat_length",
                    "Vehicles.engine_output",
                    "Vehicles.number_of_gears",
                    "Vehicles.driving_position"
                ]
            }
        });
        setJobFormValue({ ...jobFormValue, 'inspection_name': "" })
    };

    useEffect(() => {
        if (customerVehicleData !== null && customerVehicleData.length !== 0) {
            var firstname = customerVehicleData?.[0]?.user?.customers_details?.[0]?.firstname || '';
            var lastname = customerVehicleData?.[0]?.user?.customers_details?.[0]?.lastname || '';
            // setCustomerAndVehicleData(customerVehicleData)
            var customertype = customerVehicleData?.[0]?.user?.customers_details?.[0]?.type || null;

            if (customerVehicleData.length == 1) {
                if (customertype == 1) {
                    setCustomerName(customerVehicleData?.[0]?.user?.customers_details?.[0]?.company_name || '')
                    setFirstName(customerVehicleData?.[0]?.user?.customers_details?.[0]?.company_name || '')
                } else {
                    setCustomerName(firstname + " " + lastname);
                    setFirstName(firstname);
                }
                setRego(customerVehicleData?.[0].rego || '');
                setMobileNumber(customerVehicleData?.[0]?.user?.customers_details?.[0]?.mobile_number || '')
                setMobileCode(customerVehicleData?.[0]?.user?.customers_details?.[0]?.mobile_code || '');
                setEmail(customerVehicleData?.[0]?.user?.email || '');
                setMake(customerVehicleData?.[0]?.make || '');
                setModal(customerVehicleData?.[0]?.model || '');
                setYear(customerVehicleData?.[0]?.year || '');
                setInspectionRoType(customerVehicleData?.[0]?.insp_inspections || null);
                setActiveSearchList(false);
                setVechicleSection('');
                setCustomerVehicleFlag('');

                let vinDataUser = {};
                vinDataUser['user_id'] = customerVehicleData?.[0]?.user_id || '';
                vinDataUser['cust_id'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.id || '';
                vinDataUser['firstname'] = firstname;
                vinDataUser['lastname'] = lastname;
                vinDataUser['company_name'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.company_name || '';
                vinDataUser['type'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.type || '';
                vinDataUser['mobile'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.mobile_number || '';
                vinDataUser['mobile_code'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.mobile_code || '';
                vinDataUser['phone'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.phone_number || '';
                vinDataUser['suburb'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.suburb || '';
                vinDataUser['street_address'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.street_address || '';
                vinDataUser['postcode'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.postcode || '';
                vinDataUser['vehicle_type'] = customerVehicleData?.[0]?.vehicle_type || '';
                vinDataUser['asset_type_val'] = customerVehicleData?.[0]?.vehicle_type || '';
                vinDataUser['email'] = customerVehicleData?.[0]?.user?.email || '';
                vinDataUser['is_vehicle_available'] = 1;
                vinDataUser['state'] = customerVehicleData?.[0]?.user?.state?.name || "";
                vinDataUser['state_id'] = customerVehicleData?.[0]?.user?.state?.id || "";
                vinDataUser['country'] = customerVehicleData?.[0]?.user?.country?.country_name || "";
                vinDataUser['country_id'] = customerVehicleData?.[0]?.user?.country?.id || "";
                vinDataUser['abn'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.abn || "";
                vinDataUser['website'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.website || "";
                vinDataUser['position'] = customerVehicleData?.[0]?.user?.customers_details?.[0]?.position || "";

                vinDataUser['vehicle_id'] = customerVehicleData?.[0].id || '';
                vinDataUser['rego'] = customerVehicleData?.[0].rego || '';
                vinDataUser['rego_state'] = customerVehicleData?.[0].rego_state || '';
                vinDataUser['make'] = customerVehicleData?.[0].make || '';
                vinDataUser['model'] = customerVehicleData?.[0].model || '';
                vinDataUser['series'] = customerVehicleData?.[0].series || '';
                vinDataUser['is_verified'] = customerVehicleData?.[0].is_verified || '';
                vinDataUser['odometer_reading'] = customerVehicleData?.[0].odometer_reading || '';
                vinDataUser['is_odometer'] = customerVehicleData?.[0].is_odometer || '';
                vinDataUser['assets_master_id'] = customerVehicleData?.[0].assets_master_id || '';
                vinDataUser['assets_detail_id'] = customerVehicleData?.[0].assets_detail_id || '';
                vinDataUser['vehicle_type'] = customerVehicleData?.[0].vehicle_type || '';
                vinDataUser['asset_type_val'] = customerVehicleData?.[0]?.vehicle_type || '';
                vinDataUser['vehicle_custom_fields'] = customerVehicleData?.[0].vehicle_custom_fields || '';
                vinDataUser['month'] = customerVehicleData?.[0].month || '';
                vinDataUser['year'] = customerVehicleData?.[0].year || '';
                vinDataUser['fuel_type'] = customerVehicleData?.[0].fuel_type || '';
                vinDataUser['transmission'] = customerVehicleData?.[0].transmission || '';
                vinDataUser['category'] = customerVehicleData?.[0].category || '';
                vinDataUser['body_type'] = customerVehicleData?.[0].body_type || '';
                vinDataUser['seat_capacity'] = customerVehicleData?.[0].seat_capacity || '';
                vinDataUser['tyre_size_front'] = customerVehicleData?.[0].tyre_size_front || '';
                vinDataUser['tyre_size_rear'] = customerVehicleData?.[0].tyre_size_rear || '';
                vinDataUser['color'] = customerVehicleData?.[0].color || '';
                vinDataUser['vehicle_type_id'] = customerVehicleData?.[0].type || '';
                vinDataUser['vin_number'] = customerVehicleData?.[0].vin_number || '';
                vinDataUser['chasis_number'] = customerVehicleData?.[0].chasis_number || '';
                vinDataUser['engine_number'] = customerVehicleData?.[0].engine_number || '';
                vinDataUser['engine_type'] = customerVehicleData?.[0].engine_type || '';
                vinDataUser['engine_size'] = customerVehicleData?.[0].engine_size || '';
                vinDataUser['cyliners'] = customerVehicleData?.[0].cyliners || '';
                vinDataUser['engine_hours'] = customerVehicleData?.[0].engine_hours || '';
                vinDataUser['no_of_axles'] = customerVehicleData?.[0].no_of_axles || '';

                vinDataUser['bike_type'] = customerVehicleData?.[0].bike_type || '';
                vinDataUser['brakes'] = customerVehicleData?.[0].brakes || '';
                vinDataUser['trailer_type'] = customerVehicleData?.[0].trailer_type || '';
                vinDataUser['number_of_wheels'] = customerVehicleData?.[0].number_of_wheels || '';
                vinDataUser['gross_weight'] = customerVehicleData?.[0].gross_weight || '';
                vinDataUser['tare_weight'] = customerVehicleData?.[0].tare_weight || '';
                vinDataUser['hitch_type'] = customerVehicleData?.[0].hitch_type || '';
                vinDataUser['hitch_size'] = customerVehicleData?.[0].hitch_size || '';
                vinDataUser['trailer_size'] = customerVehicleData?.[0].trailer_size || '';
                vinDataUser['travel_height'] = customerVehicleData?.[0].travel_height || '';
                vinDataUser['travel_length'] = customerVehicleData?.[0].travel_length || '';
                vinDataUser['tare_weight'] = customerVehicleData?.[0].tare_weight || '';
                vinDataUser['aggregate_trailer_mass'] = customerVehicleData?.[0].aggregate_trailer_mass || '';
                vinDataUser['tow_ball_weight'] = customerVehicleData?.[0].tow_ball_weight || '';
                vinDataUser['sleeps_up_to'] = customerVehicleData?.[0].sleeps_up_to || '';
                vinDataUser['configuration'] = customerVehicleData?.[0].configuration || '';
                vinDataUser['date_of_manufacture'] = customerVehicleData?.[0].date_of_manufacture || '';
                vinDataUser['boat_construction'] = customerVehicleData?.[0].boat_construction || '';
                vinDataUser['engine_location'] = customerVehicleData?.[0].engine_location || '';
                vinDataUser['number_of_engines'] = customerVehicleData?.[0].number_of_engines || '';
                vinDataUser['boat_type'] = customerVehicleData?.[0].boat_type || '';
                vinDataUser['boat_length'] = customerVehicleData?.[0].boat_length || '';
                vinDataUser['engine_output'] = customerVehicleData?.[0].engine_output || '';
                vinDataUser['number_of_gears'] = customerVehicleData?.[0].number_of_gears || '';
                vinDataUser['date_of_manufacture'] = customerVehicleData?.[0].date_of_manufacture || '';
                vinDataUser['driving_position'] = customerVehicleData?.[0].driving_position || '';
                vinDataUser['engine_location'] = customerVehicleData?.[0].engine_location || '';

                vinDataUser['fleet_number'] = customerVehicleData?.[0].fleet_number || '';
                vinDataUser['recommendations'] = customerVehicleData?.[0].recommendations || '';

                setSelectedUser(vinDataUser);
                let tempJobform = jobFormValue;
                tempJobform.rego = customerVehicleData?.[0].rego || '';
                tempJobform.vehicle_id = customerVehicleData?.[0].id || '';
                tempJobform.customer_id = customerVehicleData?.[0]?.user_id || '';
                tempJobform.veh_type = customerVehicleData?.[0]?.type || '';
                tempJobform.veh_type_name = customerVehicleData?.[0]?.vehicle_type || '';
                tempJobform.cust_type = customerVehicleData?.[0]?.user?.customers_details?.[0]?.type || '';
                setJobFormValue(tempJobform);
            }
        }
    }, [customerVehicleData])

    // Get insurance details
    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_INSURANCE,
                payload: {
                    get: 'all',
                    conditions: {
                        workshop_id: workshop_id
                    },
                    fields: ['id', 'company_name', 'contact_name']
                }
            });
        }
        fetchData()
            .catch(console.error)
    }, [])

    //Get job department 
    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_JOBDEPARTMENT_LIST,
                payload: {
                    "get": "all",
                    "conditions": {
                        "workshop_id": workshop_id
                    },
                    "fields": ["id", "job_type_parent_id", "name", "is_custom"]
                },
            });
        }
        fetchData()
            .catch(console.error)
    }, [])

    //Get job type 
    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_JOBTYPE_LIST,
                payload: {
                    "get": "all",
                    "conditions": {
                        "status": 1,
                        "parent_id !=": 0
                    },
                    "fields": ['id', 'parent_id', 'name']
                },
            });
        }
        fetchData()
            .catch(console.error)
    }, [])

    // Get job type filter based on job dept
    const getJobType = (elem) => {
        setFormValue(elem)
        var index = elem.target.selectedIndex;
        var optionElement = elem.target.childNodes[index];
        var jobTypeParentId = optionElement.getAttribute('data-id');
        if (jobTypeData !== null && jobTypeData !== undefined) {
            var filterJobTypeData = jobTypeData.filter((item) => JSON.stringify(item.parent_id) === jobTypeParentId)
            // setFilterJobType(filterJobTypeData);
            if (+defaultCountryId === +NzCountryId && +jobTypeParentId === 30) {
                filterJobTypeData = filterJobTypeData.filter((item) => item.name === "WOF | Warrant of Fitness");
                setFilterJobType(filterJobTypeData);
            } else {
                setFilterJobType(filterJobTypeData);
            }
        }

        if (cannedResponse !== null && cannedResponse !== undefined) {
            var filterCannedResponseData = cannedResponse.filter((item) => JSON.stringify(item.department_id) === elem.target.value)
            setCannedResponse(filterCannedResponseData)
        }
    }

    // Get job canned response
    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_JOBCANNED_LIST,
                payload: {
                    "get": "all",
                    "conditions": {
                        "workshop_id": workshop_id,
                        "status": 1,
                        "type IN": [2, 8]
                    },
                    "fields": [
                        "id",
                        "department_id",
                        "workshop_id",
                        "name",
                        "description",
                        "type"
                    ]
                },
            });
        }
        fetchData()
            .catch(console.error)
    }, [])

    const jobCannedResp = [];
    if (filterCannedResponse.length !== 0 && filterCannedResponse !== null) {
        filterCannedResponse.map((canned) => (
            jobCannedResp.push({
                value: canned.id,
                label: canned.name,
                description: canned.description
            })
        ))
    }

    // const jobEstimateTimeSlot = [];
    // if (estimateTimeSlot.length !== 0 && estimateTimeSlot !== null) {
    //     estimateTimeSlot.map((timeSlot) => (
    //         jobEstimateTimeSlot.push({
    //             value: timeSlot,
    //             label: timeSlot
    //         })
    //     ))
    // }


    // Get description based on selected script
    const handleCannedScript = (e) => {
        // var preDescription = "";
        // if(jobFormValue?.script_description){
        //     preDescription = jobFormValue?.script_description
        // }
        var tempJobformData = jobFormValue;
        tempJobformData.script_id = e.value;
        setJobFormValue(tempJobformData);
        // setJobFormValue(
        //     {
        //         ...jobFormValue,
        //         'script_id': e.value,
        //         // 'script_description':e.description
        //     }
        // );
        var descriptionVal = e.description;
        let { contentBlocks, entityMap } = htmlToDraft(descriptionVal);
        let contentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
        );
        onEditorStateChange(EditorState.push(editorState, contentState, 'insert-characters'));
    }

    // Get Job tags 
    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_JOB_TAGS,
                payload: {
                    "get": "all",
                    "conditions": {
                        workshop_id: workshop_id
                    },
                    "fields": ['id', 'name', 'type']
                },
            });
        }
        fetchData()
            .catch(console.error)
    }, [])

    let jobTagsArr = [];
    useEffect(() => {
        if (tagsData !== null && tagsData !== undefined) {
            tagsData.map((tags) => {
                jobTagsArr.push({
                    value: tags.id,
                    label: tags.name
                })
            })
            setJobTagsData(jobTagsArr);
        }
    }, [tagsData])

    useEffect(() => {
        let newTagsArr = tagsArrId;
        if (addTagsData !== null && addTagsData !== undefined) {
            addTagsData.map((data, index) => {
                newTagsArr.push(data.id)
            })
            setTagsArrId(newTagsArr);
        }
    }, [addTagsData])

    function handleActivitySubmitForm(e) {
        const form = e.currentTarget;
        if (form.checkValidity() !== false) {

        } else {

        }
        e.preventDefault();
        setActivityValidated(true);
    }

    async function handleSubmitForm(e) {
        let tagsIdArrPush = [];
        let addTagsIdArr = [];
        if (getJobTagsData.length !== 0) {
            getJobTagsData.map((tags, index) => {
                if (tags.__isNew__ === true) {
                    let tagsRequest = {};
                    tagsRequest['name'] = tags.value;
                    tagsRequest['workshop_id'] = workshop_id;
                    tagsRequest['type'] = 'aircon';
                    addTagsIdArr.push(tagsRequest);
                } else {
                    tagsIdArrPush.push(tags.value)
                }
            })
        }
        setTagsArrId(tagsIdArrPush);
        if (addTagsIdArr.length !== 0) {
            // dispatch({
            //     type: ADD_JOB_TAGS,
            //     payload: addTagsIdArr
            // })
        }

        e.preventDefault();

        const form = e.currentTarget;
        var jobEstimateTime = form.job_estimate_time.value || "00:30";
        var pickupDate = moment(form.pickup_time.value, date_format).format('YYYY-MM-DD');
        var pickupTime = form.am_hours.value;

        //If Job is created from month view use this
        if (showMonthViewPopup) {
            var startDateJob = moment(new Date(startTimeNew)).format('YYYY-MM-DD');
            var endDateJob = moment(new Date(startTimeNew)).format('YYYY-MM-DD');
            var timeslotsSelected = moment(selectedSlot, 'hh:mm A').format('HH:mm:ss');
            var dateandtime = startTimeNew + ' ' + timeslotsSelected;
            var startTimeJob = timeZoneToUTC(dateandtime, timezoneLable, "YYYY-MM-DD HH:mm:ss", 'HH:mm:ss');
            var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(durationJob, 'minutes').format('HH:mm:ss');



        }

        if (form.checkValidity() !== false) {
            setSaveIsDisable(true);
            let createJob = {};
            createJob['insurance_company_id'] = jobFormValue?.customer_name || 0;
            createJob['claim_number'] = jobFormValue?.request_claim || '';
            createJob['purchase_order_number'] = jobFormValue?.purchase_order_number || '';
            createJob['pickup_time'] = pickupDate;
            createJob['workshop_id'] = workshop_id;
            createJob['created_by'] = user_id;
            createJob['customer_id'] = jobFormValue.customer_id;
            createJob['user_id'] = showMonthViewPopup ? empDataType === "res_tech" ? employeeId : 0 : job_user_id;
            createJob['bay_id'] = showMonthViewPopup ? empDataType === "res_bay" ? employeeId : 0 : job_bay_id;
            createJob['vehicle_id'] = jobFormValue.vehicle_id;
            createJob['job_type'] = jobFormValue.job_type;
            createJob['job_department_id'] = jobFormValue.job_department;
            createJob['from_date'] = showMonthViewPopup ? startDateJob : startDate;
            createJob['to_date'] = showMonthViewPopup ? endDateJob : endDate;
            createJob['from_time'] = showMonthViewPopup ? startTimeJob : startTime;
            createJob['to_time'] = showMonthViewPopup ? endTimeJob : endTime;
            createJob['duration'] = durationJob || 30;
            createJob['tags_id'] = tagsArrId;
            createJob['all_tags'] = getJobTagsData || [];
            createJob['description'] = jobFormValue?.script_description || '';
            createJob['script_id'] = jobFormValue?.script_id || '';
            createJob['job_status'] = 'Pending';
            createJob['job_stage'] = 'Bookin';
            createJob['pickup_time_only'] = pickupTime;
            createJob['customer_vehicle_option'] = jobFormValue?.customer_type || 0;
            createJob['insp_reference_id'] = jobFormValue?.inspection_name || '';
            createJob['bookin_source'] = 2;
            createJob['source_of_business'] = jobFormValue?.source_of_business || '';
            createJob['job_estimate_time'] = jobEstimateTime || "00:30";
            createJob['is_default_time'] = '3';

            var job_labour_estimate_time = convertDurationToDecimal(jobEstimateTime || "00:30");
            var labour_cost = parseFloat(labourChargesList?.[0]?.cost_price) * job_labour_estimate_time;
            var labour_total_sell = parseFloat(labourChargesList?.[0]?.sell_price) * job_labour_estimate_time;
            var sub_total = parseFloat(labourChargesList?.[0]?.sell_price) * job_labour_estimate_time;
            var gst = parseFloat(labour_total_sell) * 10 / 100;
            var total = gst + sub_total;

            createJob['labour_cost'] = labour_cost;
            createJob['labour_total_sell'] = labour_total_sell;
            createJob['sub_total'] = sub_total;
            createJob['gst'] = gst;
            createJob['total'] = total;

            let inspectionId = jobFormValue?.inspection_name || null;
            if (inspectionId !== null && inspectionId !== "") {
                dispatch({
                    type: GET_INSPECTION_QUE_ANS,
                    payload: {
                        get: "all",
                        conditions: {
                            "insp_inspection_id": inspectionId,
                            "qn_status": 'Approved'
                        },
                        contain: {
                            'Products': {}
                        }
                    }
                })
            }
            const fetchData = async () => {
                setCreateJobFlag(true);
                setCreateLabourFlag(true);
                dispatch({
                    type: CREATE_JOB,
                    payload: createJob
                })
            }
            fetchData()
                .catch(console.error);



        }
        setValidated(true);
    }

    const getCreateEvent = (key) => {
        setCreateEvent(key)
    }

    // Quote submit form 
    const [quoteSelectedFlag, setQuoteSelectedFlag] = useState('disabled');
    const [selectedQuoteId, setSelectedQuoteId] = useState();
    const [quotesParts, setQuotesParts] = useState([]);
    const [quotesLabours, setQuotesLabours] = useState([]);

    const handleQuoteSubmitForm = () => {
        if (selectedQuoteId !== undefined && selectedQuoteId !== "" && selectedQuoteId !== null) {
            if (quotesDetailsData !== null && quotesDetailsData !== undefined) {
                //If Job is created from month view use this
                if (showMonthViewPopup) {
                    var startDateJob = moment(new Date(startTimeNew)).format('YYYY-MM-DD');
                    var endDateJob = moment(new Date(startTimeNew)).format('YYYY-MM-DD');
                    var timeslotsSelected = moment(selectedSlot, 'hh:mm A').format('HH:mm:ss');
                    var dateandtime = startTimeNew + ' ' + timeslotsSelected;
                    var startTimeJob = timeZoneToUTC(dateandtime, timezoneLable, "YYYY-MM-DD HH:mm:ss", 'HH:mm:ss');
                    var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(durationJob, 'minutes').format('HH:mm:ss');
                }
                let quoteToJob = {};
                quoteToJob['workshop_id'] = workshop_id;
                quoteToJob['created_by'] = user_id;
                quoteToJob['customer_id'] = quotesDetailsData[0].user_id;
                // quoteToJob['bay_id'] = job_bay_id;
                // quoteToJob['user_id'] = job_user_id;
                quoteToJob['user_id'] = showMonthViewPopup ? empDataType === "res_tech" ? employeeId : 0 : job_user_id;
                quoteToJob['bay_id'] = showMonthViewPopup ? empDataType === "res_bay" ? employeeId : 0 : job_bay_id;
                quoteToJob['vehicle_id'] = quotesDetailsData[0].vehicle_id;
                quoteToJob['job_type'] = quotesDetailsData?.[0]?.job_type || '';
                quoteToJob['job_department_id'] = quotesDetailsData?.[0]?.job_department || '';
                // quoteToJob['from_date'] = startDate;
                // quoteToJob['to_date'] = endDate;
                // quoteToJob['from_time'] = startTime;
                // quoteToJob['to_time'] = endTime;
                quoteToJob['from_date'] = showMonthViewPopup ? startDateJob : startDate;
                quoteToJob['to_date'] = showMonthViewPopup ? endDateJob : endDate;
                quoteToJob['from_time'] = showMonthViewPopup ? startTimeJob : startTime;
                quoteToJob['to_time'] = showMonthViewPopup ? endTimeJob : endTime;
                quoteToJob['duration'] = durationJob || 30;
                quoteToJob['tags_id'] = {};
                quoteToJob['description'] = quotesDetailsData?.[0]?.description || '';
                quoteToJob['job_status'] = 'Pending';
                quoteToJob['job_stage'] = 'Bookin';
                quoteToJob['insp_reference_id'] = 0;
                quoteToJob['bookin_source'] = 2;
                quoteToJob['source_of_business'] = 'Existing';
                quoteToJob['customer_quotes_id'] = selectedQuoteId;
                quoteToJob['parts_cost'] = quotesDetailsData?.[0]?.parts_cost || '0.00';
                quoteToJob['labour_cost'] = quotesDetailsData?.[0]?.labour_cost || '0.00';
                quoteToJob['parts_markup'] = quotesDetailsData?.[0]?.parts_markup || '0.00';
                quoteToJob['labour_markup'] = quotesDetailsData?.[0]?.labour_markup || '0.00';
                quoteToJob['parts_total_cost'] = quotesDetailsData?.[0]?.parts_total_cost || '0.00';
                quoteToJob['labour_total_cost'] = quotesDetailsData?.[0]?.labour_total_cost || '0.00';
                quoteToJob['sub_total'] = quotesDetailsData?.[0]?.labour_parts_total_cost || '0.00';
                quoteToJob['gst'] = quotesDetailsData?.[0]?.gst || '0.00';
                quoteToJob['total'] = quotesDetailsData?.[0]?.total_cost || '0.00';
                quoteToJob['discount_rate'] = quotesDetailsData?.[0]?.discount_rate || '0.00';
                quoteToJob['insurance_company_id'] = quotesDetailsData?.[0]?.insurance_company_id || '0';
                quoteToJob['claim_number'] = quotesDetailsData?.[0]?.claim_number || '';
                quoteToJob['purchase_order_number'] = quotesDetailsData?.[0]?.purchase_order_number || '';

                dispatch({
                    type: CREATE_QUOTE_TO_JOB,
                    payload: quoteToJob,
                });
                dispatch({
                    type: EDIT_QUOTES,
                    payload: {
                        'id': selectedQuoteId,
                        'quote_status': 'Won'
                    },
                });
                setCreateQuoteFlag(true);
            }
        }
    }
    useEffect(() => {
        if (quoteDetailsData !== null && quoteDetailsData !== undefined && createQuoteFlag === true) {

            var job_id = quoteDetailsData?.id || null;
            var job_customer_id = quoteDetailsData?.customer_id || null;
            let partsArr = [];
            if (quotesParts.length !== 0 && quotesParts !== null) {
                quotesParts.map((partData, index) => {
                    let parts = {};
                    parts['workshop_id'] = workshop_id;
                    parts['job_schedule_id'] = job_id;
                    parts['product_id'] = partData?.product_id || 0;
                    parts['is_inspection_part'] = partData?.is_inspection_part || 0;
                    var productType = partData?.product?.product_type || 0;
                    if (productType === 1) {
                        parts['is_kit'] = partData?.is_kit || 0;
                    } else {
                        parts['is_kit'] = partData?.is_kit || 1;
                    }
                    parts['job_part_name'] = partData?.quote_part_name || 0;
                    parts['job_part_priority'] = partData?.quote_part_priority || 0;
                    parts['cost'] = partData?.cost || 0;
                    parts['po_qty_sent'] = partData?.po_qty_sent || 0;
                    parts['price'] = partData?.price || 0;
                    parts['is_demo_data'] = partData?.is_demo_data || 0;
                    parts['margin'] = partData?.margin || 0;
                    parts['sell'] = partData?.sell || 0;
                    parts['profit'] = partData?.profit || 0;
                    parts['gst'] = partData?.gst || 0;
                    parts['time'] = partData?.time || 0;
                    parts['qty'] = partData?.qty || 0;
                    parts['retail'] = partData?.retail || 0;
                    parts['assigned_qty'] = partData?.assigned_qty || 0;
                    parts['purchase_order_qty'] = partData?.purchase_order_qty || 0;
                    parts['basic_stock'] = partData?.basic_stock || 0;
                    parts['is_assigned'] = partData?.is_assigned || 0;
                    parts['repco_extra_part'] = partData?.repco_extra_part || 0;
                    partsArr.push(parts);
                })

                dispatch({
                    type: ADD_MANY_JOB_PARTS,
                    payload: {
                        data: partsArr
                    }
                })
            }

            if (labourChargesList !== null && labourChargesList !== undefined) {
                let jobLaboursArr = [];
                let laboursDataArr = {
                    labour_charges_id: labourChargesList[0].id,
                    job_schedules_id: job_id,
                    cost_price: labourChargesList[0].cost_price,
                    margin: labourChargesList[0].margin,
                    sell_price: labourChargesList[0].sell_price,
                    retail_price: labourChargesList[0].retail_price,
                    profit: labourChargesList[0].profit_price,
                    labour_description: labourChargesList[0].description,
                    gst_price: labourChargesList[0].gst_price,
                    total_price: parseFloat(labourChargesList[0].retail_price) * convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                };

                ["2", "3"].map(cv => {
                    const copiedData = { ...laboursDataArr, labour_charge_type: cv }
                    if (cv === "3") {
                        copiedData["labour_time"] = createJobData?.data?.JobSchedules?.job_estimate_time
                        copiedData["labour_ph"] = convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                        copiedData["sell_total"] = parseFloat(laboursDataArr.sell_price) * parseFloat(convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time))
                        copiedData["total_price"] = parseFloat(labourChargesList[0].retail_price) * convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                        copiedData["gst_total"] = parseFloat(labourChargesList[0].gst_price) * convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                    } else {
                        copiedData["sell_total"] = "0.00"
                        copiedData["total_price"] = "0.00"
                        copiedData["gst_total"] = "0.00"
                    }
                    return jobLaboursArr.push(copiedData)
                })


                dispatch({
                    type: ADD_MANY_JOB_LABOURS,
                    payload: {
                        data: jobLaboursArr
                    }
                })
            }


            if (quotesLabours !== null && quotesLabours.length !== 0) {
                let laboursArr = [];
                quotesLabours.map((labourData, index) => {


                    let labours = {};
                    labours['labour_charges_id'] = labourData?.type || 0;
                    labours['cost_price'] = labourData?.cost || 0;
                    labours['margin_markup'] = labourData?.markup || 0;
                    labours['sell_price'] = labourData?.sell || 0;
                    labours['retail_price'] = labourData?.retail || 0;
                    labours['labour_time'] = labourData?.time || 0;
                    labours['total_price'] = labourData?.total || 0;
                    labours['job_schedules_id'] = job_id;
                    labours['margin'] = labourData?.margin || 0;
                    labours['labour_description'] = labourData?.labour_description || 0;
                    labours['labour_ph'] = labourData?.labour_ph || 0;
                    labours['profit'] = labourData?.profit || 0;
                    labours['priority'] = labourData?.priority || 0;
                    labours['is_inspection'] = labourData?.is_inspection || 0;
                    labours['labour_charge_type'] = "1";
                    return laboursArr.push(labours);
                })

                dispatch({
                    type: ADD_MANY_JOB_LABOURS,
                    payload: {
                        data: laboursArr
                    }
                })
            }

            if (quoteKitParts !== null && quoteKitParts !== undefined) {
                let jobKitPartsArr = [];
                quoteKitParts.map((jobKitPart, index) => {
                    let kitPart = {};
                    kitPart['workshop_id'] = workshop_id;
                    kitPart['job_id'] = job_id;
                    kitPart['job_parts_id'] = jobKitPart.quotes_parts_id;
                    kitPart['job_kit_labour_id'] = jobKitPart.quote_kit_labour_id;
                    kitPart['kit_id'] = jobKitPart.kit_id;
                    kitPart['part_id'] = jobKitPart.part_id;
                    kitPart['priority'] = jobKitPart.priority;
                    kitPart['cost'] = jobKitPart.cost;
                    kitPart['margin'] = jobKitPart.margin;
                    kitPart['sell'] = jobKitPart.sell;
                    kitPart['qty'] = jobKitPart.qty;
                    kitPart['retail'] = jobKitPart.retail;
                    kitPart['margin_markup'] = jobKitPart.margin_markup;
                    kitPart['gst'] = jobKitPart.gst;
                    kitPart['gst_price'] = jobKitPart.gst_price;
                    kitPart['assigned_qty'] = jobKitPart.assigned_qty;
                    kitPart['purchase_order_qty'] = jobKitPart.purchase_order_qty;
                    kitPart['po_qty_sent'] = jobKitPart.po_qty_sent;
                    kitPart['basic_stock'] = jobKitPart.basic_stock;
                    kitPart['is_assigned'] = jobKitPart.is_assigned;
                    jobKitPartsArr.push(kitPart);
                })

                dispatch({
                    type: ADD_MANY_JOB_KIT_PARTS,
                    payload: {
                        data: jobKitPartsArr
                    }
                })
            }

            if (quoteKitLabours !== null && quoteKitLabours !== undefined) {
                let jobKitLabourArr = [];
                quoteKitLabours.map((jobKitLabour, index) => {
                    let kitLabour = {};
                    kitLabour['workshop_id'] = workshop_id;
                    kitLabour['job_id'] = job_id;
                    kitLabour['kit_id'] = jobKitLabour.kit_id;
                    kitLabour['labour_charge_id'] = jobKitLabour.labour_charge_id;
                    kitLabour['cost_price'] = jobKitLabour.cost_price;
                    kitLabour['margin'] = jobKitLabour.margin;
                    kitLabour['sell_price'] = jobKitLabour.sell_price;
                    kitLabour['qty_hours'] = jobKitLabour.qty_hours;
                    kitLabour['retail_price'] = jobKitLabour.retail_price;
                    kitLabour['margin_markup'] = jobKitLabour.margin_markup;
                    kitLabour['gst'] = jobKitLabour.gst;
                    kitLabour['gst_price'] = jobKitLabour.gst_price;
                    kitLabour['discription'] = jobKitLabour.discription;
                    jobKitLabourArr.push(kitLabour);
                })

                dispatch({
                    type: ADD_MANY_JOB_KIT_LABOURS,
                    payload: {
                        data: jobKitLabourArr
                    }
                })
            }
            showSuccess('Quote to Job converted Successfully!');
            setCreatedJobId(job_id);
            setCreatedJobCustomer(job_customer_id);
            setJobNumber(quoteDetailsData?.job_number);
            handleShow();
            setManageStateModal('save');
        }
        setCreateQuoteFlag(false);

    }, [quoteDetailsData])

    useEffect(() => {
        if (createEvent === "quotes") {
            dispatch({
                type: GET_OPEN_QUOTES,
                payload: {
                    get: "all",
                    conditions: {
                        "CustomerQuotes.workshop_id": workshop_id,
                        "CustomerQuotes.quote_status": 'Open'
                    },
                    contain: {
                        "Vehicles": {
                            "fields": ['id', 'rego']
                        },
                        "Users.CustomersDetails": {
                            "fields": ['id', 'user_id']
                        }
                    },
                    fields: ['id', 'vehicle_id', 'quote_number', 'Users.id', 'user_id']
                },
            });
        }

        if (createEvent === "activities") {
            dispatch({
                type: GET_ACTIVITY,
                payload: {
                    "get": "all",
                    "conditions": {
                        "workshop_id": workshop_id,
                        "is_deleted": 0
                    },
                    "fields": ["id", "name", "is_default", "type"]
                }
            });

            setUpdateActivity(false);

        }
    }, [createEvent, updateActivity])

    useEffect(() => {

        getActivitySchedulerList();

    }, [createEvent])

    function getActivitySchedulerList() {

        if (createEvent === "activities") {
            dispatch({
                type: GET_SCHEDULE_ACTIVITY,
                payload: {
                    "get": "all",
                    "conditions": {
                        "ActivitySchedule.workshop_id": workshop_id,
                        "ActivitySchedule.activity_master_id !=": 0,
                        "ActivitySchedule.is_deleted": 0
                    },
                    "group": [
                        "ActivitySchedule.name"
                    ],
                    "contain": {
                        "ActivityMaster": {
                            "fields": ["id", "name", "type"]
                        }
                    },
                    "fields": ["id", "activity_master_id", "name", "description"]
                }
            })
        }
    }

    // Convert quote to job 
    useEffect(() => {
        if (quotesData !== null && quotesData !== undefined) {
            setQuoteList(quotesData);
        }
    }, [quotesData])

    async function getQuoteDetails(id) {
        setSelectedQuoteId(id);
        dispatch({
            type: GET_QUOTE_DETAILS,
            payload: {
                get: "all",
                conditions: {
                    "CustomerQuotes.id": id,
                    "CustomerQuotes.workshop_id": workshop_id,
                },
                contain: {
                    "Vehicles": {
                        "fields": [
                            'id',
                            'user_id',
                            'rego',
                            'make',
                            'model',
                            'year',
                            'transmission',
                            'service_due_date',
                            'created_on'
                        ]
                    },
                    "WorkshopDepartmentsAccountsDetails": {
                        "fields": ['id', 'name', 'job_type_parent_id']
                    },
                    "JobSubTypes": {
                        "fields": ['id', 'name']
                    },
                    "Users.CustomersDetails": {
                        conditions: {
                            "CustomersDetails.workshop_id": workshop_id,
                        },
                        "fields": ['id', 'workshop_id', 'user_id', 'firstname', 'lastname', 'mobile_number', 'type', 'company_name', 'mobile_code']
                    },
                    "CustomerQuotesParts": {
                        "fields": [
                            'product_id',
                            'cost',
                            'margin',
                            'sell',
                            'retail',
                            'qty',
                            'price',
                            'profit',
                            'time',
                            'qty',
                            'gst',
                            'quote_part_priority',
                            'customer_quotes_id',
                            'quote_part_name',
                            'quote_part_description',
                            'id'
                        ]
                    },
                    "CustomerQuotesParts.Products": {
                        "fields": ['id', 'product_type']
                    },
                    "CustomerQuotesLabours": {
                        "fields": [
                            'id',
                            'type',
                            'cost',
                            'markup',
                            'sell',
                            'retail',
                            'labour_description',
                            'time',
                            'total',
                            'profit',
                            'customer_quotes_id',
                            'labour_ph'
                        ]
                    }
                },
                fields: [
                    'workshop_id',
                    'Users.id',
                    'id',
                    'vehicle_id',
                    'user_id',
                    'job_department',
                    'description',
                    'job_type',
                    'reason_description',
                    'total_cost',
                    'quote_number',
                    'parts_cost',
                    'labour_cost',
                    'parts_markup',
                    'labour_markup',
                    'parts_total_cost',
                    'labour_total_cost',
                    'labour_parts_total_cost',
                    'gst',
                    'total_cost',
                    'discount_rate'
                ]
            }
        });
        dispatch({
            type: GET_QUOTE_KIT_PARTS,
            payload: {
                get: "all",
                conditions: {
                    "quote_id": id,
                    "workshop_id": workshop_id
                }
            }
        })
        dispatch({
            type: GET_QUOTE_KIT_LABOURS,
            payload: {
                get: "all",
                conditions: {
                    "quote_id": id,
                    "workshop_id": workshop_id
                }
            }
        })
    }

    useEffect(() => {
        if (quotesDetailsData !== null && quotesDetailsData !== undefined) {
            setQuotesParts(quotesDetailsData?.[0]?.customer_quotes_parts || [])
            setQuotesLabours(quotesDetailsData?.[0]?.customer_quotes_labours || [])
            setQuoteSelectedFlag('');
            setSelectedQuoteId(quotesDetailsData?.[0]?.id);
            setRego(quotesDetailsData?.[0]?.vehicle?.rego || '');
            setMakeQuote(quotesDetailsData?.[0]?.vehicle?.make || 'MAKE');
            setModelQuote(quotesDetailsData?.[0]?.vehicle?.model || 'MODEL');
            setYearQuote(quotesDetailsData?.[0]?.vehicle?.year || 'YEAR');
            setJobDeptQuote(quotesDetailsData?.[0]?.workshop_departments_accounts_detail?.name || 'DEPARTMENT');
            setJobTypeQuote(quotesDetailsData?.[0]?.job_sub_type?.name || 'TYPE');
            setDescQuote(quotesDetailsData?.[0]?.description || 'DESCRIPTION');
            let quoteCustomerType = quotesDetailsData?.[0]?.user?.customers_details?.[0]?.type || "2";
            if (quoteCustomerType == 1) {
                setCustomerName(quotesDetailsData?.[0].user?.customers_details?.[0]?.company_name || '')
                setFirstName(quotesDetailsData?.[0].user?.customers_details?.[0]?.company_name || '')
            } else {
                var quoteFirstname = quotesDetailsData?.[0].user?.customers_details?.[0]?.firstname || "";
                var quoteLastname = quotesDetailsData?.[0].user?.customers_details?.[0]?.lastname || "";
                setCustomerName(quoteFirstname + " " + quoteLastname);
                setFirstName(quoteFirstname);
            }
            setMobileNumber(quotesDetailsData?.[0].user?.customers_details?.[0]?.mobile_number || '')
            setMobileCode(quotesDetailsData?.[0]?.user?.customers_details?.[0]?.mobile_code || '');
        }
    }, [quotesDetailsData])

    const getSearchQuote = (e) => {
        let value = e.target.value.toLowerCase();
        if (value !== '') {
            searchQuote(value);
        } else {
            setQuoteList(quotesData);
        }
    };

    let quotesDataArr = [];
    const searchQuote = (value) => {
        quotesDataArr = quotesData.filter((item) => {
            let vinData1 = (item.quote_number + item.vehicle.rego).toLowerCase();
            if (vinData1.indexOf(value) !== -1) {
                return vinData1;
            }
        });
        setQuoteList(quotesDataArr);
    };

    //If Month View
    const employeeDetails = useSelector((state) => state?.CommonApiStoreReducer?.Workshops?.users || null);
    const workshopBayData = useSelector((state) => state?.SchedulerReducer?.workshopBaysDetails || null);
    // const currentDay = new Date().getDay();
    const [currentDay, setCurrentDay] = useState(new Date().getDay());
    const [employeeId, setEmployeeId] = useState('');
    const [timeSlotsList, setTimeSlotsList] = useState([]);
    const [eveTimeSlotsList, setEveTimeSlotsList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [empDataType, setEmpDataType] = useState('res_tech');
    const [fromDate, setfromdate] = useState();
    const [disabledSave, setDisabledSave] = useState('disabled');


    const [workshopEndTime, setWorkshopEndTime] = useState();

    let defaultSelect = true;
    
    useEffect(() => {
        var startTime = utcToTimezone(workshopOtherDetails?.[0]?.shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var endTime = utcToTimezone(workshopOtherDetails?.[0]?.shift_finish_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var workshopEndTime1 = utcToTimezone(workshopOtherDetails?.[0]?.shift_finish_time, timezoneLable, "HH:mm", 'HH:mm');

        if (schedule_details != null) {
            let scheduleDetails = unserializeArr(schedule_details);
            if (scheduleDetails[currentDay]['open_time'] !== '' && scheduleDetails[currentDay]['close_time'] !== '' && scheduleDetails[currentDay]['status'] !== 0 && scheduleDetails[currentDay]['status'] !== '0') {
                startTime = utcToTimezone(scheduleDetails[currentDay]['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss')
                endTime = utcToTimezone(scheduleDetails[currentDay]['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss')
                workshopEndTime1 = utcToTimezone(scheduleDetails[currentDay]['close_time'], timezoneLable, "HH:mm", 'HH:mm')
            }
        }

        // var pickTimeSlot = moment(endTime, 'HH:mm:ss').hours() + ":" + moment(endTime, 'HH:mm:ss').minutes();
        setSelectedPickupTimeSlot(workshopEndTime1);

        setWorkshopEndTime(endTime);
        // var startTime = utcToTimezone(workshopOtherDetails[0].shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var fromdate = startTimeNew + " " + startTime;
        setCurrentDay(moment(startTimeCalendar, 'YYYY-MM-DD').day());
        setfromdate(fromdate);

        if (pickupTimeSlot.length === 0) {
            var startTime1 = moment().utc().set({ hour: moment(startTime, 'HH:mm:ss').hours(), minute: moment(startTime, 'HH:mm:ss').minutes() });
            var endTime1 = moment().utc().set({ hour: moment(endTime, 'HH:mm:ss').hours(), minute: moment(endTime, 'HH:mm:ss').minutes() });

            var timeStops = [];
            var afterTimeStops = [];
            while (startTime1 < endTime1) {
                if (new moment(startTime1).format('HH:mm A') >= "12:00") {
                    afterTimeStops.push(new moment(startTime1).format('HH:mm'));
                    startTime1.add(30, 'minutes');
                } else {
                    timeStops.push(new moment(startTime1).format('HH:mm'));
                    startTime1.add(30, 'minutes');
                }

            }
            setPickupTimeSlot(timeStops);
            setAfterPickupTimeSlot(afterTimeStops);
        }

    }, [])



    const handleTechnicianSlot = (e, emp) => {

        setMonthViewDisabled('disabled');
        setEmpDataType(e.dataset.id);
        setTechnicianName(emp['name']);
        var startTime = utcToTimezone(workshopOtherDetails?.[0]?.shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        if (schedule_details != null) {
            let scheduleDetails = unserializeArr(schedule_details);
            if (scheduleDetails[currentDay]['open_time'] !== '' && scheduleDetails[currentDay]['close_time'] !== '' && scheduleDetails[currentDay]['status'] !== 0 && scheduleDetails[currentDay]['status'] !== '0') {
                startTime = utcToTimezone(scheduleDetails[currentDay]['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss')
            }
        }
        // var startTime = utcToTimezone(workshopOtherDetails[0].shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var fromdate = startTimeNew + " " + startTime;
        setfromdate(fromdate);
    }

    const handleBayJobMessage = (val) => {
        if (empDataType === "res_bay") {
            dispatch({
                type: GET_BAYS_JOB_COUNT,
                payload: {
                    date: startTimeNew,
                    id: employeeId,
                    timeSlots: val.slots,
                    timezoneLable: timezoneLable,
                    workshop_id: workshop_id
                }
            })
            setClickBaysSlot(true);
        }
    }

    useEffect(() => {
        if (showMonthViewPopup) {
            if (employeeId !== "") {
                setTimeSlot(null);
                setDisabledSave('disabled');
                if (empDataType === "res_tech") {
                    dispatch({
                        type: GET_AVAIALABLE_SLOTS,
                        payload: {
                            workshop_id: workshop_id,
                            from_date: fromDate,
                            days: currentDay,
                            'job_stage NOT IN': ['Archive'],
                            user_id: employeeId,
                            'is_copid_job': 0
                        }
                    })
                } else {
                    dispatch({
                        type: GET_AVAIALABLE_SLOTS,
                        payload: {
                            workshop_id: workshop_id,
                            from_date: fromDate,
                            days: currentDay,
                            'job_stage NOT IN': ['Archive'],
                            user_id: 0,
                            bay_id: employeeId,
                            'is_copid_job': 0
                        }
                    })
                }
            }
        }
    }, [fromDate, employeeId])

    useEffect(() => {
        if (showMonthViewPopup) {
            let technicianList = [];

            if ((employeeDetails != null || employeeDetails != undefined)) {
                employeeDetails.map((employee, index) => {
                    let technicianListArr = [];
                    technicianListArr['data_type'] = 'res_tech';
                    technicianListArr['name'] = employee.firstname + " " + employee.lastname;
                    technicianListArr['id'] = employee.id;
                    var employeeWorkingDayArr = [];
                    if (employee.employees_details[0] !== "" && employee.employees_details[0] !== undefined && employee.employees_details[0] !== null) {
                        if (employee.employees_details[0].shift_working_day) {
                            employeeWorkingDayArr = unserializeArr(employee.employees_details[0].shift_working_day);
                        }
                    }
                    employeeWorkingDayArr = employeeWorkingDayArr.map(parseFloat);
                    technicianListArr['working_days'] = 0;
                    if (employeeWorkingDayArr.includes(currentDay)) {
                        technicianListArr['working_days'] = 1;
                        if (defaultSelect === true) {
                            setEmployeeId(employee.id);
                            setEmpDataType('res_tech');
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            defaultSelect = false;
                        }
                    }
                    technicianList.push(technicianListArr);
                })
            }

            if (workshopBayData !== null && workshopBayData !== undefined && createEvent != "activities") {
                workshopBayData.map((bays, index) => {
                    let baysArr = [];
                    baysArr['data_type'] = 'res_bay';
                    baysArr['name'] = bays.name;
                    baysArr['id'] = bays.id;
                    baysArr['working_days'] = 1;
                    if (defaultSelect === true) {
                        setEmpDataType('res_bay');
                        setEmployeeId(bays.id);
                        defaultSelect = false;
                    }
                    technicianList.push(baysArr);
                })
            }

            setTechnicianName(technicianList?.[0]?.['name']);
            setEmployeeList(technicianList);
        }
    }, [workshopBayData, employeeDetails, currentDay, createEvent])
    // Month View Fetch Timeslot List
    const workshopOtherDetails = useSelector((state) => state?.CommonApiStoreReducer?.Workshops?.workshop_other_details || null);
    const schedulerData = useSelector((state) => state?.SchedulerReducer?.getAvailableSlots || null);
    useEffect(() => {
        if (showMonthViewPopup) {
            if (schedulerData !== null && schedulerData !== undefined) {
                var techTimeSlots = workshopOtherDetails?.[0]?.technician_timeslot;
                let slotsArrList = [];
                let eveSlotsArrList = [];
                for (var key in schedulerData) {
                    let slotsArr = [];
                    var startTimeSlots = moment.utc(key, 'hh:mm:ss').format('hh:mm A');
                    var endTimeSlots = moment.utc(key, 'hh:mm:ss').add(techTimeSlots, 'minutes').format('hh:mm A');
                    slotsArr['start_time'] = key;
                    // slotsArr['slots'] = startTimeSlots + " - " + endTimeSlots;
                    slotsArr['slots'] = startTimeSlots;
                    slotsArr['available'] = schedulerData[key][0];
                    // slotsArrList.push(slotsArr);
                    if (key >= "12:00:00") {
                        eveSlotsArrList.push(slotsArr);
                    } else {
                        slotsArrList.push(slotsArr);
                    }
                }
                setTimeSlotsList(slotsArrList);
                setEveTimeSlotsList(eveSlotsArrList);
            }
        }
    }, [schedulerData])

    const setTimeSlot = (slot) => {
        if (slot !== null && slot !== undefined) {
            setMonthViewDisabled('');
            setSelectedSlot(moment(slot, 'HH:mm:ss').format('hh:mm A'));

            var fromTime = moment(slot, 'HH:mm:ss').format('HH:mm:ss');
            if (showMonthViewPopup) {
                getJobEstimateTimeData(startTimeCalendar, fromTime, employeeId);
            }
        }



        // setMonthViewDisabled('')
    }

    //activity
    const [selectedActivitySchedule, setSelectedActivitySchedule] = useState();
    const [selectedActivityName, setSelectedActivityName] = useState();
    const [selectedActivityDescription, setSelectedActivityDescription] = useState();
    const [activityScheduleList, setActivityScheduleList] = useState(null);
    const [openCreateActivity, setOpenCreateActivity] = useState(false);
    const [openCreateActivitySchedule, setOpenCreateActivitySchedule] = useState(false);
    const [activityName, setActivityName] = useState();
    const [activitySchedulerName, setActivitySchedulerName] = useState("");
    const [activityEmployee, setActivityEmployee] = useState([]);
    const [defaultActivity, setDefaultActivity] = useState();
    const [actionType, setActionType] = useState();
    const [activityId, setActivityId] = useState();
    const [activityEmpArr, setActivityEmpArr] = useState([{ value: technicianId, label: technicianName }]);

    const [activityTechnicianId, setActivityTechnicianId] = useState(technicianId);

    const [saveActivityFlag, setSaveActivityFlag] = useState(false);
    const [activityDeleteId, setActivityDeleteId] = useState();
    const [activityDeleted, setActivityDeleted] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [actvitySelectedFlag, setActvitySelectedFlag] = useState('disabled');

    const [confirmDeleteActivity, setConfirmDeleteActivity] = useState(false);
    const [deleteActivityId, setDeleteActivityId] = useState("");


    const handleDeleteClose = () => {
        setShowDeleteModal(false);
        setConfirmDeleteActivity(false);
    }

    // Delete Category


    useEffect(() => {
        if (technicianId != undefined && technicianName != undefined) {
            setDefaultActivity([{ value: technicianId, label: technicianName }]);
        }

    }, [technicianId, technicianName])

    useEffect(() => {
        if (deleteJobActivity !== null && deleteJobActivity !== undefined) {
            if (activityDeleted === true) {
                showSuccess('Activity deleted successfully!')
                // setUpdateActivityList(true);
                setActivityDeleted(false);
                getActivitySchedulerList();
            }
        }
    }, [deleteJobActivity])

    function removeDuplicates(originalArray, objKey) {
        var trimmedArray = [];
        var values = [];
        var value;

        for (var i = 0; i < originalArray.length; i++) {
            value = originalArray[i][objKey];

            if (values.indexOf(value) === -1) {
                trimmedArray.push(originalArray[i]);
                values.push(value);
            }
        }
        return trimmedArray;
    }

    const handleEmployeeChange = (employee) => {
        setActivityEmpArr(removeDuplicates(employee, 'label'));
    }

    useEffect(() => {
        if (addActivityData !== null && addActivityData !== undefined) {
            showSuccess('Category created successfully!')
            activityData.push(addActivityData);
            setOpenCreateActivity(false);
        }
    }, [addActivityData])

    const handleChangeActivity = (e) => {
        const inputText = e.target.value;
        
        if (inputText.length <= 30) {
            setActivityName(inputText);
        }
    }

    const saveCreateActivity = () => {
        
        var alreadyExist = false;
        activityData.filter((item) => {
            let activityArr = item.name;
            if (activityArr.indexOf(activityName) !== -1) {
                alreadyExist = true;
            }
        });
        if (alreadyExist === true) {
            showError('Category already exist!')
        }
        else {
            if (activityName != undefined) {
                let name = activityName.trimStart();
                if (name != undefined && name != "") {
                    dispatch({
                        type: ADD_ACTIVITY,
                        payload: {
                            "workshop_id": workshop_id,
                            "name": activityName,
                            "type": 1,
                            "is_default": 0
                        }
                    })
                } else {
                    showError('Please add Category name')
                }
            } else {
                showError('Please add Category name')
            }
        }

        setActivityName("");
    }

    useEffect(() => {
        if (activityScheduleData !== null && activityScheduleData !== undefined) {
            // 
            if (activityId > 0 && activityId !== 'undefined') {
                let activityListDataArr = activityScheduleData.filter((item) => {
                    if (item?.activity_master_id === activityId) {
                        return item;
                    }
                });
                setActivityScheduleList(activityListDataArr);
            }

        }
    }, [activityScheduleData])

    const getSearchActivity = (e) => {
        let value = e.target.value.toLowerCase();
        if (value !== '') {
            searchActivity(value);
        } else {
            let activityListDataArr = activityScheduleData.filter((item) => {
                if (item?.activity_master_id === activityId) {
                    return item;
                }
            });
            setActivityScheduleList(activityListDataArr);
            if (activityListDataArr?.length === 0) {
                setActivitySchedulerName(value);
            }
        }
    };


    const searchActivity = (value) => {
        let activityDataArr = [];
        if (activityScheduleList !== null && activityScheduleList !== undefined) {
            activityDataArr = activityScheduleList.filter((item) => {
                let activityArr = (item.name).toLowerCase();
                if (activityArr.indexOf(value) !== -1) {
                    return activityArr;
                }
            });
        }
        setActivityScheduleList(activityDataArr);
        if (activityDataArr?.length === 0) {
            setActivitySchedulerName(value);
        } else {
            setActivitySchedulerName();
        }
    };

    useEffect(() => {
        let employeeActivity = [];
        if (employeeDetails !== null && employeeDetails !== undefined) {
            employeeDetails.map((emp, index) => {
                let empArr = {};
                empArr['value'] = emp.id;
                empArr['label'] = emp.firstname + " " + emp.lastname;
                employeeActivity.push(empArr);
            })
        }
        if (workshopBayData !== null && workshopBayData !== undefined && createEvent != "activities") {
            workshopBayData.map((bay, index) => {
                let bayArr = {};
                bayArr['value'] = bay.id;
                bayArr['label'] = bay.name;
                employeeActivity.push(bayArr);
            })
        }

        // [{ value: technicianId, label: technicianName }];
        setActivityEmployee(employeeActivity);
    }, [employeeDetails, workshopBayData, createEvent])

    useEffect(() => {

        if (activityId !== undefined && activityId > 0) {
            if (activityScheduleData !== null && activityScheduleData !== undefined) {

                let activityListDataArr = activityScheduleData.filter((item) => {
                    if (item?.activity_master_id === activityId) {
                        return item;
                    }
                });
                // if (actionType === "Meeting") {
                //     activityListDataArr = activityScheduleData.filter((item) => {
                //         if (item?.activity_master?.type === "1") {
                //             return item;
                //         }
                //     });
                // } else if (actionType === "Holiday") {
                //     activityListDataArr = activityScheduleData.filter((item) => {
                //         if (item?.activity_master?.type === "2") {
                //             return item;
                //         }
                //     });
                // } else if (actionType === "Job") {
                //     activityListDataArr = activityScheduleData.filter((item) => {
                //         if (item?.activity_master?.type === "3") {
                //             return item;
                //         }
                //     });
                // }
                setActivityScheduleList(activityListDataArr);
            }

            if (selectedActivityName != undefined && defaultActivity != undefined && defaultActivity.length != 0) {
                setActvitySelectedFlag(" ")
            } else {
                setActvitySelectedFlag("disabled")
            }
        } else {
            setActvitySelectedFlag("disabled")
        }
    }, [actionType, defaultActivity, selectedActivityName, activityId])



    const [checkAvailableJob, setCheckAvailableJob] = useState(false);

    const saveActivity = async() => {

        if (actionType) {
            if (selectedActivityName !== undefined && selectedActivityName !== null) {
                // if (actionType === "Job") {
                    // dispatch({
                    //     type: GET_ASSIGNED_JOBS,
                    //     payload: {
                    //         get: 'all',
                    //         conditions: {
                    //             workshop_id: workshop_id,
                    //             job_number: selectedActivityName,
                    //             activity_schedule_id: 0
                    //         },
                    //         fields: ['id', 'workshop_id']
                    //     },
                    // });
                // } else {

                    // if (actionType === "Job") {
                    //     setCheckAvailableJob(true)
                    // }
                    if (selectedActivitySchedule === '' || selectedActivityName === '') {

                        showError('Please select activity');
                        return false;
                    }
                    // dispatch({
                    //     type: ADD_SCHEDULE_ACTIVITY,
                    //     payload: {
                    //         workshop_id: workshop_id,
                    //         activity_master_id: activityId,
                    //         name: selectedActivityName,
                    //         description: selectedActivityDescription
                    //     },
                    // });
                    // setSaveActivityFlag(true);
                    var activityScheduleId = selectedActivitySchedule;
                    // if(actionType === "Job"){
                        let invoiceUpdateData = {
                            payload: {
                                workshop_id: workshop_id,
                                activity_master_id: activityId,
                                name: selectedActivityName,
                                description: selectedActivityDescription
                            },
                        }
                        
                        const activityScheduleResp = await addScheduleActivity(invoiceUpdateData);
                        if(activityScheduleResp && activityScheduleResp?.code == 200){
                            setSaveActivityFlag(true);
                            activityScheduleId = activityScheduleResp?.data?.ActivitySchedule?.id;
                        }
                    // }

                    // if (addActivityScheduleData !== null && addActivityScheduleData !== undefined && saveActivityFlag === true) {
                    //     var activityScheduleId = addActivityScheduleData.id;

                    //     console.log(activityScheduleId,'activityScheduleId activityScheduleId  activityScheduleId')
                    // }
                    // var activityScheduleId = addActivityScheduleData?.id;
                    
                    //If Job is created from month view use this
                    if (showMonthViewPopup) {
                        var startDateJob = moment(new Date(startTimeNew)).format('YYYY-MM-DD');
                        var endDateJob = moment(new Date(startTimeNew)).format('YYYY-MM-DD');
                        var timeslotsSelected = moment(selectedSlot, 'hh:mm A').format('HH:mm:ss');
                        var dateandtime = startTimeNew + ' ' + timeslotsSelected;
                        var startTimeJob = timeZoneToUTC(dateandtime, timezoneLable, "YYYY-MM-DD HH:mm:ss", 'HH:mm:ss');
                        var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(durationJob, 'minutes').format('HH:mm:ss');
                    }

                    let activityCreateArr = [];
                    if (activityEmpArr) {
                        activityEmpArr.map((emp, index) => {
                            let activityJob = {};
                            activityJob['workshop_id'] = workshop_id;
                            activityJob['created_by'] = user_id;
                            activityJob['user_id'] = emp.value;
                            activityJob['activity_schedule_id'] = activityScheduleId; 
                            // activityJob['activity_schedule_id'] = selectedActivitySchedule;
                            activityJob['from_date'] = showMonthViewPopup ? startDateJob : startDate;
                            activityJob['to_date'] = showMonthViewPopup ? endDateJob : endDate;
                            activityJob['from_time'] = showMonthViewPopup ? startTimeJob : startTime;
                            activityJob['to_time'] = showMonthViewPopup ? endTimeJob : endTime;
                            activityJob['duration'] = durationJob || 30;
                            activityJob['job_status'] = 'Pending';
                            activityJob['job_stage'] = 'Bookin';
                            activityJob['is_on_leave'] = 0;
                            activityJob['bookin_source'] = 2;
                            activityJob['source_of_business'] = 'Signage';
                            activityJob['description'] = selectedActivityDescription;
                            activityCreateArr.push(activityJob);
                        })

                        if (activityCreateArr.length !== 0) {
                            dispatch({
                                type: ADD_MANY_CREATE_JOB,
                                payload: {
                                    data: activityCreateArr
                                },
                            });
                            setSaveActivityFlag(false);
                            setSavaManyActivityFlag(true);
                        }
                    }

                // }
            } else {
                showError('Please select activity.')
            }
        } else {
            showError('Please select category.')
        }
    }

    // useEffect(() => {
    //     // if (getJobDetailsActivity !== null && getJobDetailsActivity !== undefined && checkAvailableJob === true) {
    //     if (checkAvailableJob === true) {
    //         // if (getJobDetailsActivity?.[0]) {
    //             dispatch({
    //                 type: ADD_SCHEDULE_ACTIVITY,
    //                 payload: {
    //                     workshop_id: workshop_id,
    //                     activity_master_id: activityId,
    //                     name: selectedActivityName,
    //                     description: selectedActivityDescription
    //                 },
    //             });
    //             setSaveActivityFlag(true);
    //         // } else {
    //         //     showError('This job number is not valid');
    //         // }
    //         setCheckAvailableJob(false)
    //     } 
    //     // else if (checkAvailableJob === true) {
    //     //     setCheckAvailableJob(false)
    //     //     showError('This job number is not valid');
    //     // }
    // }, [checkAvailableJob])

    const [saveManyActivityFlag, setSavaManyActivityFlag] = useState(false)

    useEffect(() => {
        if (addCreateActivityJob !== null && addCreateActivityJob !== undefined && saveManyActivityFlag === true) {
            showSuccess('Activity created Successfully!');
            setSavaManyActivityFlag(false);
            setUpdateCalendar(!updateCalendar);
            clearModalData()
            close();
        }
    }, [addCreateActivityJob])

    useEffect(() => {
        if (addActivityScheduleData !== null && addActivityScheduleData !== undefined && saveActivityFlag === true) {

            let typeData = '1';
            if (actionType === "Holiday") {
                typeData = '2';
            }
            if (actionType === "Job") {
                typeData = '3';
            }
            let activityMaster = {
                id: activityId,
                name: activityName,
                type: typeData
            }

            let addData = {
                activity_master_id: addActivityScheduleData?.activity_master_id,
                description: addActivityScheduleData?.description,
                id: addActivityScheduleData?.id,
                name: addActivityScheduleData?.name,
                activity_master: activityMaster
            }


            activityScheduleData.push(addData);


            let activityListDataArr = activityScheduleData.filter((item) => {
                if (item?.activity_master_id === activityId) {
                    return item;
                }
            });

            // let activityListDataArr = [];
            // if (actionType === "Meeting") {
            //     activityListDataArr = activityScheduleData.filter((item) => {
            //         if (item?.activity_master?.type === "1") {
            //             return item;
            //         }
            //     });
            // } else if (actionType === "Holiday") {
            //     activityListDataArr = activityScheduleData.filter((item) => {
            //         if (item?.activity_master?.type === "2") {
            //             return item;
            //         }
            //     });
            // } else if (actionType === "Job") {
            //     activityListDataArr = activityScheduleData.filter((item) => {
            //         if (item?.activity_master?.type === "3") {
            //             return item;
            //         }
            //     });
            // }

            setActivityScheduleList(activityListDataArr);
            // setActivitySchedulerName("");
            setOpenCreateActivitySchedule(false);

            //     var activityScheduleId = addActivityScheduleData.id;
            //     //If Job is created from month view use this
            //     if (showMonthViewPopup) {
            //         var startDateJob = moment(new Date(startTimeNew)).format('YYYY-MM-DD');
            //         var endDateJob = moment(new Date(startTimeNew)).format('YYYY-MM-DD');
            //         var timeslotsSelected = moment(selectedSlot, 'hh:mm A').format('HH:mm:ss');
            //         var dateandtime = startTimeNew + ' ' + timeslotsSelected;
            //         var startTimeJob = timeZoneToUTC(dateandtime, timezoneLable, "YYYY-MM-DD HH:mm:ss", 'HH:mm:ss');
            //         var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(durationJob, 'minutes').format('HH:mm:ss');
            //     }
            //     let activityCreateArr = [];
            //     if (activityEmpArr) {
            //         activityEmpArr.map((emp, index) => {
            //             let activityJob = {};
            //             activityJob['workshop_id'] = workshop_id;
            //             activityJob['created_by'] = user_id;
            //             activityJob['user_id'] = emp.value;
            //             activityJob['activity_schedule_id'] = activityScheduleId;
            //             activityJob['from_date'] = showMonthViewPopup ? startDateJob : startDate;
            //             activityJob['to_date'] = showMonthViewPopup ? endDateJob : endDate;
            //             activityJob['from_time'] = showMonthViewPopup ? startTimeJob : startTime;
            //             activityJob['to_time'] = showMonthViewPopup ? endTimeJob : endTime;
            //             activityJob['duration'] = durationJob || 30;
            //             activityJob['job_status'] = 'Pending';
            //             activityJob['job_stage'] = 'Bookin';
            //             activityJob['is_on_leave'] = 0;
            //             activityJob['bookin_source'] = 2;
            //             activityJob['source_of_business'] = 'Signage';
            //             activityCreateArr.push(activityJob);
            //         })

            //         if (activityCreateArr.length !== 0) {
            //             dispatch({
            //                 type: ADD_MANY_CREATE_JOB,
            //                 payload: {
            //                     data: activityCreateArr
            //                 },
            //             });
            //             setSaveActivityFlag(false);
            //             setSavaManyActivityFlag(true);
            //         }
            //     }

        }
    }, [addActivityScheduleData])

    const deleteActivitySchedule = async () => {
        dispatch({
            type: DELETE_JOB_SCHEDULE_ACTIVITY,
            payload: {
                id: activityDeleteId
            }
        });
        setActivityDeleted(true);
        setShowDeleteModal(false);
        setUpdateCalendar(!updateCalendar);
    }

    const handleDeleteOpen = (delete_id) => {
        if (delete_id !== "" && delete_id !== undefined) {
            setShowDeleteModal(true);
            setActivityDeleteId(delete_id);
        }
    }

    // save create activity scheduler
    const saveCreateActivitySchedule = () => {

        let typeData = 1;
        if (actionType === "Holiday") {
            typeData = 2;
        }
        if (actionType === "Job") {
            typeData = 3;
        }
        let activityMaster = {
            id: activityId,
            name: activityName,
            type: typeData
        }
        var alreadyExist = false;

        activityScheduleList.filter((item) => {
            let activityArr = item.name.trim();
            // if (activityArr.indexOf(activitySchedulerName) !== -1) {
            //     console.log('index of activityArr => ' + activityArr);
            //     alreadyExist = true;
            // }
            var activitySchedulerNameTrim = activitySchedulerName.trim();
            if (activityArr === activitySchedulerNameTrim) {
                alreadyExist = true;
            }
        });

        if (alreadyExist === true) {
            showError('Activity already exist!')
        }
        else {
            if (activitySchedulerName != undefined) {
                let name = activitySchedulerName.trimStart();
                if (name != undefined && name != "") {
                    dispatch({
                        type: ADD_SCHEDULE_ACTIVITY,
                        payload: {
                            "workshop_id": workshop_id,
                            "name": activitySchedulerName,
                            "type": 1,
                            "is_default": 0,
                            "activity_master_id": activityId,
                            "description": ""
                        }
                    })
                    setActivitySchedulerName();
                    setSaveActivityFlag(true);
                } else {
                    showError('Please add Activity name')
                }
            } else {
                showError('Please add Activity name')
            }
        }
    }

    // delete category confirmation popup 
    const handleDeleteActivity = (delete_id) => {
        if (delete_id !== "" && delete_id !== undefined) {
            setConfirmDeleteActivity(true);
            setDeleteActivityId(delete_id);
        }
    }

    // delete from activity_master table 
    const deleteActivityRecord = async () => {
        let data = {
            id: deleteActivityId
        };

        const response = await deleteActivity({ payload: data });
        if (response.code === 200) {
            showSuccess('Category deleted successfully!');
            setDeleteActivityId("");
            setConfirmDeleteActivity(false);
            setUpdateCalendar(!updateCalendar);
            setUpdateActivity(true);
            setActionType();
            setActivityId();
            setActivityScheduleList();
        }
    }

    const {
        expDayCount,
        isTrialMode,
        subscriptionType,
    } = useInitialData();

    async function getSMSBalanceData() {
        if (subscriptionType === "Trial" && isTrialMode && expDayCount > 0) {
            setIsSmsSubscribe(1);
            setIsLoading(false);
            if (mobileNumber) {
                if (mobileNumber.length >= 9 && mobileNumber.length <= 11) {
                    setValidateMobile(false);
                    if (isUpdateMobile) {
                        setUpdateMobileNumber(true);
                    }
                    setShow(true);
                    setBookingConfirmationCheckbox(true);
                    handleJobBookSmsSent(mobileNumber);
                } else {
                    setValidateMobile(true);
                }
            } else {
                setValidateMobile(true);
            }
        } else {
            let data = {
                payload: {
                    get: "all",
                    conditions: {
                        "WorkshopSubscriptionDetails.plan_type": "sms",
                        "WorkshopSubscriptionDetails.current_sms_limit >": 0,
                        "WorkshopSubscriptionDetails.workshop_id": workshop_id,
                    },
                    fields: [
                        "id",
                        "workshop_subscription_id",
                        "subscription_plan_id",
                        "sms_limit",
                        "current_sms_limit",
                    ],
                    order: { "WorkshopSubscriptionDetails.id": "DESC" },
                },
            };
            let response = await getSubscriptionAddon(data);

            if (response.code === 200) {
                setIsSmsSubscribe(1);
                setIsLoading(false);
                if (mobileNumber) {
                    if (mobileNumber.length >= 9 && mobileNumber.length <= 11) {
                        setValidateMobile(false);
                        if (isUpdateMobile) {
                            setUpdateMobileNumber(true);
                        }
                        setShow(true);
                        setBookingConfirmationCheckbox(true);
                        handleJobBookSmsSent(mobileNumber);
                    } else {
                        setValidateMobile(true);
                    }
                } else {
                    setValidateMobile(true);
                }
            } else {
                setIsLoading(false);
                setIsSmsSubscribe(0);
                handleShowSmsBalanceModal();
            }
        }
    }

    const handleJobBookSmsSent = (newMobileNumber = "") => {
        let smsFormData = {};
        let workshop_phone = localStoredData?.workshop?.phone;
        smsFormData.workshop_id = workshop_id;
        smsFormData.job_id = createdJobId;
        smsFormData.customer_id = createdJobCustomer;
        smsFormData.send_method = 'sms';
        smsFormData.table = 'chkBookin';
        smsFormData.send_to = newMobileNumber ? newMobileNumber : mobileNumber;
        smsFormData.to = newMobileNumber ? newMobileNumber : mobileNumber;
        smsFormData.rego = rego;
        smsFormData.message = "Hi " + firstName + " confirming your vehicle " + rego + " is booked on " + moment(startTimeCalendar).format('DD/MM/YYYY') + " at " + (showMonthViewPopup ? selectedSlot : moment(startTimeCalendar).format('hh:mm a')) + ". Contact us if you need to change this appointment on " + workshop_phone;
        smsFormData.phone_code = mobileCode;
        smsFormData.subject = "SMS";
        smsFormData.update_mobile = "0";
        smsFormData.number = jobNumber;
        if (updateMobileNumber) {
            smsFormData.update_mobile = "1";
        }

        // setUdpateJobBookingConfirmation(true);
        dispatch({
            type: SENT_BOOKINGJOB_SMS,
            payload: smsFormData,
        });

    }

    // useEffect(() => {
    //     if(updateMobileNumber){
    //         handleJobBookSmsSent()
    //         // setShowAddMobileModal(false);
    //     }
    // },[updateMobileNumber])

    return (
        <>
            {
                (manageStateModal === 'parent') ?
                    <Modal
                        className={"create-job " + dashboardJob}
                        size="lg"
                        show={show}
                        onHide={() => { clearModalData(); close() }}
                    >
                        <Modal.Header>
                            <Modal.Title className="d-flex justify-space-between">
                                {showMonthViewPopup ?
                                    <>
                                        <div>
                                            <span className="name">{technicianNameNew}</span>
                                            <span> | </span>
                                            <span className="time">
                                                {startEventTime} {selectedSlot}
                                            </span>
                                        </div>
                                    </> :
                                    <div>
                                        <span className="name">{technicianName}</span>
                                        <span> | </span>
                                        <span className="time">{startJobTime}</span>
                                    </div>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="rounded-tabs">
                                <Tabs defaultActiveKey="Jobs" onSelect={(key) => getCreateEvent(key)}>
                                    <Tab eventKey="Jobs" title="Jobs">
                                        {showMonthViewPopup ?
                                            <MonthViewCreateJob
                                                timeSlotsList={timeSlotsList}
                                                eveTimeSlotsList={eveTimeSlotsList}
                                                employeeList={employeeList}
                                                setTimeSlot={setTimeSlot}
                                                timeSlot={selectedSlot}
                                                setEmployeeId={setEmployeeId}
                                                employeeId={employeeId}
                                                handleTechnicianSlot={handleTechnicianSlot}
                                                handleBayJobMessage={handleBayJobMessage}
                                                type={"job"}

                                            /> : ""}
                                        {/* step 1 for jobs */}
                                        <Form ref={formRef} onSubmit={handleSubmitForm} noValidate validated={validated} id="jobCreate">
                                            {!activeStep ? (
                                                <div className="tab-wrapper">
                                                    <div className="search-wrapper">
                                                        <span className="title">
                                                            Vehicle & Customer
                                                        </span>
                                                        <div className={`general-search-wrapper ${activeSearchList ? 'active-search-list' : ''}`}>
                                                            <Form.Group className="form-group">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Search for an Asset,Customer or Mobile "
                                                                    onKeyUp={getChildSearchVehicle}
                                                                    id="select_activity_new"
                                                                    name="select_activity_new"
                                                                />
                                                                <Button
                                                                    className="btn-primary rounded-btn"
                                                                    onClick={() => handleCustomerShow()}
                                                                >
                                                                    <span>+</span> Customers & Vehicles
                                                                </Button>
                                                            </Form.Group>
                                                            <ul className="search-list with-icon">
                                                                <Scrollbars autoHeight id="customerVehicleData"
                                                                    autoHeightMax={185}
                                                                    className="custom-scrollbar" style={{ width: '100%' }}
                                                                    renderThumbVertical={props => <div {...props} className="thumb-vertical black-thumb" />}>
                                                                    {
                                                                        filterVinDatas.map((filter, index) => {
                                                                            if (filter == "No results found") {
                                                                                return <li key={index}>
                                                                                    <Link className="d-flex justify-space-between align-center no-wrap no-result-search-bg" to="">
                                                                                        <span>
                                                                                            <span className="title">{filter + " for " + searchText}</span>
                                                                                        </span>

                                                                                        <Button
                                                                                            className="btn-white font-size-14 p-1 min-width-100"
                                                                                            // variant="deafult"
                                                                                            onClick={() => {
                                                                                                setCustomerFormType('add')
                                                                                                setSelectedUser(filter);
                                                                                                setShowCustomersAndVehiclesModal(true);
                                                                                                setManageStateModal('child');
                                                                                            }}>Add New</Button>

                                                                                        {/* <OverlayTrigger
                                                                                            key="bottom"
                                                                                            placement="bottom"
                                                                                            overlay={
                                                                                                <Tooltip id="tooltip-bottom">
                                                                                                    Add vehicles
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <span className="add-icon" >
                                                                                                <i className="fa fa-plus"></i>
                                                                                            </span>
                                                                                        </OverlayTrigger> */}
                                                                                    </Link>
                                                                                </li>
                                                                            }
                                                                            var is_vehicle_available = filter.is_vehicle_available;
                                                                            let img = "";
                                                                            if (filter.icon === true) {
                                                                                img = <img src={IcCustomerBlue} className="filter_ic_customer_blue" alt=""></img>;
                                                                            }
                                                                            if (is_vehicle_available === 1) {
                                                                                return <li key={index}>
                                                                                    <Link className="d-flex justify-space-between align-center no-wrap no-result-search-list" to="" onClick={() => { getVehicleDetails(filter.rego) }}>
                                                                                        <span>
                                                                                            <span className="title">{filter.rego} | {filter.name} | {filter.mobile}</span>
                                                                                            {img}
                                                                                        </span>
                                                                                        <Button
                                                                                            className="btn-primary font-size-14 p-1 min-width-100"
                                                                                            // variant="deafult"
                                                                                            onClick={() => {
                                                                                                setCustomerFormType('edit')
                                                                                                setSelectedUser(filter);
                                                                                                setShowCustomersAndVehiclesModal(true);
                                                                                                setManageStateModal('child');
                                                                                            }}>Add vehicles</Button>

                                                                                        {/* <OverlayTrigger
                                                                                            key="bottom"
                                                                                            placement="bottom"
                                                                                            overlay={
                                                                                                <Tooltip id="tooltip-bottom">
                                                                                                    Add vehicles
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <span className="add-icon" onClick={() => {
                                                                                                setCustomerFormType('edit')
                                                                                                setSelectedUser(filter);
                                                                                                setShowCustomersAndVehiclesModal(true);
                                                                                                setManageStateModal('child');
                                                                                            }}>
                                                                                                <i className="fa fa-plus"></i>
                                                                                            </span>
                                                                                            
                                                                                        </OverlayTrigger> */}
                                                                                    </Link>
                                                                                </li>
                                                                            } else {
                                                                                return <li key={index}>
                                                                                    <Link className="d-flex justify-space-between align-center no-wrap no-result-search-list" to="" onClick={() => {
                                                                                        setCustomerFormType('edit')
                                                                                        setSelectedUser(filter);
                                                                                        setShowCustomersAndVehiclesModal(true);
                                                                                        setManageStateModal('child');
                                                                                    }}>
                                                                                        <span>
                                                                                            <span className="title">{filter.rego} | {filter.name} | {filter.mobile}</span>
                                                                                            {img}
                                                                                        </span>
                                                                                        <Button
                                                                                            className="btn-primary font-size-14 p-1 min-width-100"
                                                                                            onClick={() => {
                                                                                                setCustomerFormType('edit')
                                                                                                setSelectedUser(filter);
                                                                                                setShowCustomersAndVehiclesModal(true);
                                                                                                setManageStateModal('child');
                                                                                            }}>Add vehicles</Button>

                                                                                        {/* <OverlayTrigger
                                                                                            key="bottom"
                                                                                            placement="bottom"
                                                                                            overlay={
                                                                                                <Tooltip id="tooltip-bottom">
                                                                                                    Add vehicles
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <span className="add-icon" onClick={() => {
                                                                                                setCustomerFormType('edit')
                                                                                                setSelectedUser(filter);
                                                                                                setShowCustomersAndVehiclesModal(true);
                                                                                                setManageStateModal('child');
                                                                                            }}>
                                                                                                <i className="fa fa-plus"></i>
                                                                                            </span>
                                                                                        </OverlayTrigger> */}
                                                                                    </Link>
                                                                                </li>
                                                                            }
                                                                        })
                                                                    }
                                                                </Scrollbars>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className={"panel-wrapper " + vehicleSection}>
                                                        <div className="panel">
                                                            <div className="number-plate">
                                                                <span>{rego}</span>
                                                            </div>
                                                            <div className="panel-right">
                                                                <div>
                                                                    <div className="top-block">
                                                                        <i className="fa fa-user"></i>
                                                                        <span>{customerName}</span>
                                                                        <span>{mobileNumber}</span>
                                                                        <span>{email}</span>
                                                                    </div>
                                                                    <div className="bottom-block d-flex align-items-center">
                                                                        <CarMakeLogo make={make || ""} />
                                                                        <span>{make}</span>
                                                                        <span>{modal}</span>
                                                                        <span>{year}</span>
                                                                    </div>
                                                                </div>
                                                                <Button className="btn-black rounded-btn" style={{ minWidth: "80px" }} onClick={() => viewCustomerVehicle()}>
                                                                    <i className="fa fa-eye"></i>
                                                                    <span>View</span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="select-wrapper d-flex justify-space-between create-job-third-party">
                                                        <div className="select-block custom-normal-select d-flex align-center no-wrap mtpx-15">
                                                            <div className="inspection-list">
                                                                <label >Inspection RO </label>
                                                                {(inspectionRoType !== null && inspectionRoType.length !== 0) &&
                                                                    <span className="circle-icon"><i style={{ color: "red", fontSize: "18px", marginLeft: "10px" }} className="align-items-center justify-content-center"></i> </span>
                                                                }
                                                            </div>
                                                            <select className="form-control custom-select" name="inspection_name" id="inspection_name" onChange={(e) => setFormValue(e)}>
                                                                <option>Select</option>
                                                                {
                                                                    (inspectionRoType !== null && inspectionRoType.length !== 0) ?
                                                                        inspectionRoType.map((insp) => {
                                                                            return <option key={insp.id} value={insp.id}>{`${insp?.insp_template?.name || ''} (${insp.id})`}</option>
                                                                        })
                                                                        : ''
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="select-block custom-normal-select d-flex align-center no-wrap mtpx-15">
                                                            <label>Booking Source</label>
                                                            <Form.Select aria-label="Default select example" id="source_of_business" name="source_of_business" onChange={(e) => setFormValue(e)}>
                                                                <option value="Existing">Existing</option>
                                                                <option value="Walk In">Walk In</option>
                                                                <option value="Referral">Referral</option>
                                                                <option value="Social Media">Social Media</option>
                                                                <option value="Customer App">Customer App</option>
                                                                <option value="Signage">Signage</option>
                                                                <option value="Other">Other</option>
                                                            </Form.Select>
                                                        </div>
                                                        <div className="select-block custom-normal-select d-flex align-center no-wrap">
                                                            <label>Bill To | 3rd Party</label>
                                                            <select name="customer_name" id="customer_name_assets" onChange={(e) => { setFormValue(e); }}>
                                                                <option value="">Select</option>
                                                                {
                                                                    (insuranceData !== null && insuranceData !== undefined) ?
                                                                        insuranceData.map((insurance) => {
                                                                            return <option key={insurance.id} value={insurance.id}>{insurance.company_name}</option>
                                                                        })
                                                                        : ''
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="form-group select-block custom-normal-select d-flex align-center no-wrap">
                                                            <label>Claim No</label>
                                                            <Form.Group className="form-group">
                                                                <Form.Control type="text" id="request_claim" name="request_claim" onKeyUp={(e) => setFormValue(e)} />

                                                            </Form.Group>
                                                        </div>
                                                        <div className="select-block custom-normal-select d-flex align-center no-wrap">
                                                            <label>Company PO</label>
                                                            <Form.Group className="form-group">
                                                                <Form.Control type="text" id="purchase_order_number" name="purchase_order_number" onKeyUp={(e) => setFormValue(e)} />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="tab-wrapper jobs-description">
                                                    <div className="form-outer">
                                                        <div className="form-group custom-normal-select d-flex align-center mb-3">
                                                            <label>Job Department</label>
                                                            <Form.Select aria-label="Default select example" name="job_department" id="job_department" onChange={getJobType} required>
                                                                <option value="">Select Job Department</option>
                                                                {
                                                                    (jobDepartmentData !== null && jobDepartmentData !== undefined) ?
                                                                        jobDepartmentData.map((jobDept) => {
                                                                            return <option
                                                                                key={jobDept.id}
                                                                                value={jobDept.id}
                                                                                data-id={jobDept.job_type_parent_id}
                                                                                selected={jobDept.name === "Services" ? true : false}
                                                                            >
                                                                                {jobDept.name}
                                                                            </option>
                                                                        })
                                                                        : ''
                                                                }
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">Please select Job Department</Form.Control.Feedback>
                                                        </div>
                                                        <div className="form-group custom-normal-select d-flex align-center mb-3">
                                                            <label>Job Type</label>
                                                            <Form.Select aria-label="Default select example" name="job_type" id="job_type" required onChange={(e) => setFormValue(e)}>
                                                                <option value="">Select Job Type</option>
                                                                {
                                                                    (filterJobType !== null && filterJobType.length !== 0) ?
                                                                        filterJobType.map((jobType) => {
                                                                            return <option key={jobType.id} value={jobType.id} data-id={jobType.job_type_parent_id}>{jobType.name}</option>
                                                                        })
                                                                        : ''
                                                                }
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">Please select Job Type</Form.Control.Feedback>
                                                        </div>
                                                        <div className="select-block select-wrapper d-flex justify-space-between">
                                                            <Form.Group className="form-group mb-3">
                                                                <label>Job Description</label>
                                                                <Select
                                                                    className="form-control custom-select"
                                                                    name="script_name" id="script_name"
                                                                    options={jobCannedResp}
                                                                    onChange={handleCannedScript}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <Form.Group className="form-group mb-3">
                                                            <label>Description</label>
                                                            <Editor
                                                                name="script_description"
                                                                id="script_description"
                                                                editorState={editorState}
                                                                wrapperClassName="wrapper-class"
                                                                editorClassName="editor-class"
                                                                onEditorStateChange={onEditorStateChange}
                                                                spellCheck
                                                            />
                                                        </Form.Group>

                                                        {/* Recommendations START */}
                                                        {selectedUser?.recommendations &&
                                                            <div className="col-md-12 mb-3">
                                                                <Recommendations
                                                                    recommendationList={selectedUser?.recommendations}
                                                                    isEditable={false}
                                                                    actionPage="createJob"
                                                                    updateJobDescription={updateJobDescription}
                                                                />
                                                            </div>
                                                        }
                                                        {/* Recommendations END */}

                                                        <div className="date-time-wrapper d-flex justify-space-between  mb-3">

                                                            <div className="width-33">
                                                                <Form.Group className="form-group time-picker">
                                                                    <label>Job Estimate Time</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={createJobEstimateTime}
                                                                        name="job_estimate_time"
                                                                        id="job_estimate_time"
                                                                        onClick={(e) => {
                                                                            if (estimateTimeSlot.length > 0) {
                                                                                setShowJobEstimateSlot(!showJobEstimateSlot);
                                                                            }
                                                                        }}
                                                                        className="form-select"
                                                                    />
                                                                </Form.Group>
                                                                {showJobEstimateSlot && estimateTimeSlot.length !== 0 &&
                                                                    <div className="estimate-time-slot-div">
                                                                        <div className="time-slots">
                                                                            {
                                                                                (estimateTimeSlot.length !== 0) ?
                                                                                    estimateTimeSlot.map((slot, index) => {
                                                                                        return <Button
                                                                                            key={index}
                                                                                            type="button"
                                                                                            className={"estimate-time-slot"}
                                                                                            onClick={(e) => {
                                                                                                setCreateJobEstimateTime(slot);
                                                                                                setShowJobEstimateSlot(!showJobEstimateSlot);
                                                                                                var startTimeInFormat = moment(new Date(startTimeCalendar)).format('YYYY-MM-DD HH:mm:ss');
                                                                                                var fromData = timeZoneToUTC(startTimeInFormat, timezoneLable, "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD HH:mm:ss');
                                                                                                var startTimeJob = moment(fromData).format('HH:mm:ss');
                                                                                                var durationMinutes = convertToMinute(slot);
                                                                                                var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(durationMinutes, 'minutes').format('HH:mm:ss');
                                                                                                setDurationJob(durationMinutes);
                                                                                                setEndTime(endTimeJob);
                                                                                                setJobFormValue({
                                                                                                    ...jobFormValue,
                                                                                                    ["to_time"]: endTimeJob,
                                                                                                    ["job_estimate_time"]: slot,
                                                                                                });
                                                                                            }} >
                                                                                            <strong>{slot} {slot === "00:30" ? 'Mins' : 'Hrs'}</strong>
                                                                                        </Button>
                                                                                    }) : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>


                                                            <Form.Group className="form-group date-picker width-33">
                                                                <label>Select Pickup Date</label>
                                                                <DatePicker className="form-control" id="pickup_time" name="pickup_time"
                                                                    defaultValue={moment(startTimeCalendar)}
                                                                    format={date_format}
                                                                />{" "}
                                                            </Form.Group>

                                                            <div className="width-33">
                                                                <Form.Group className="form-group time-picker">
                                                                    <label>Select Pickup Time</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={selectedPickupTimeSlot}
                                                                        name="am_hours"
                                                                        id="am_hours"
                                                                        onClick={(e) => setShowPickupTimeSlot(!showPickupTimeSlot)}
                                                                        className="form-select"
                                                                    />
                                                                </Form.Group>
                                                                {showPickupTimeSlot &&
                                                                    <div className="estimate-time-slot-div scroll-container">
                                                                        <div className="time-slots row">

                                                                            <div className="col-md-6">
                                                                                <p className="text-center fw-bold" > Morning </p>
                                                                                {
                                                                                    (pickupTimeSlot.length !== 0) ?
                                                                                        pickupTimeSlot.map((slot, index) => {
                                                                                            return <Button
                                                                                                key={index}
                                                                                                type="button"
                                                                                                className={"estimate-time-slot"}
                                                                                                onClick={(e) => {
                                                                                                    setSelectedPickupTimeSlot(slot);
                                                                                                    setShowPickupTimeSlot(!showPickupTimeSlot);
                                                                                                    setJobFormValue({
                                                                                                        ...jobFormValue,
                                                                                                        ["am_hours"]: slot,
                                                                                                    });
                                                                                                }} >
                                                                                                <strong>{slot + ' am'}</strong>
                                                                                            </Button>
                                                                                        }) : ''
                                                                                }
                                                                            </div>

                                                                            <div className="col-md-6">

                                                                                <p className="text-center fw-bold" > Afternoon </p>
                                                                                {
                                                                                    (afterPickupTimeSlot.length !== 0) ?
                                                                                        afterPickupTimeSlot.map((slot, index) => {
                                                                                            return <Button
                                                                                                key={index}
                                                                                                type="button"
                                                                                                className={"estimate-time-slot"}
                                                                                                onClick={(e) => {
                                                                                                    setSelectedPickupTimeSlot(slot);
                                                                                                    setShowPickupTimeSlot(!showPickupTimeSlot);
                                                                                                    setJobFormValue({
                                                                                                        ...jobFormValue,
                                                                                                        ["am_hours"]: slot,
                                                                                                    });
                                                                                                }} >
                                                                                                <strong>{slot + ' pm'}</strong>
                                                                                            </Button>
                                                                                        }) : ''
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>


                                                        </div>



                                                        <div className="select-options d-flex justify-space-between">
                                                            <div className="multi-select-wrapper">
                                                                <span>Job Tags</span>
                                                                <CreatableSelect
                                                                    defaultValue={[]}
                                                                    isMulti
                                                                    name="jobTags"
                                                                    options={jobTagsData}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    onChange={handleTagsChange}
                                                                />
                                                            </div>
                                                            <div className="radio-wrapper">
                                                                <label>Customer Options</label>
                                                                <Form.Check
                                                                    className="custom-check radio-btn secondary-radio mb-2"
                                                                    type="radio"
                                                                    id="1"
                                                                >
                                                                    <Form.Check.Input
                                                                        name="customer_type"
                                                                        type="radio"
                                                                        isValid
                                                                        value="1"
                                                                        onClick={(e) => setFormValue(e)}
                                                                    />
                                                                    <Form.Check.Label>
                                                                        Waiting Instore
                                                                        <span className="check"></span>
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                                <Form.Check
                                                                    className="custom-check radio-btn secondary-radio mb-2"
                                                                    type="radio"
                                                                    id="2"
                                                                >
                                                                    <Form.Check.Input
                                                                        name="customer_type"
                                                                        type="radio"
                                                                        isValid
                                                                        value="2"
                                                                        onClick={(e) => setFormValue(e)}
                                                                    />
                                                                    <Form.Check.Label>
                                                                        Loan Vehicle
                                                                        <span className="check"></span>
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                                <Form.Check
                                                                    className="custom-check radio-btn secondary-radio mb-2"
                                                                    type="radio"
                                                                    id="3"
                                                                >
                                                                    <Form.Check.Input
                                                                        name="customer_type"
                                                                        type="radio"
                                                                        isValid
                                                                        value="3"
                                                                        onClick={(e) => setFormValue(e)}
                                                                    />
                                                                    <Form.Check.Label>
                                                                        Pickup / Drop Off
                                                                        <span className="check"></span>
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                                <Form.Check
                                                                    className="custom-check radio-btn secondary-radio mb-2"
                                                                    type="radio"
                                                                    id="4"
                                                                >
                                                                    <Form.Check.Input
                                                                        name="customer_type"
                                                                        type="radio"
                                                                        isValid
                                                                        value="4"
                                                                        onClick={(e) => setFormValue(e)}
                                                                    />
                                                                    <Form.Check.Label>
                                                                        Ride Sharing
                                                                        <span className="check"></span>
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="modal-footer">
                                                <Button
                                                    variant="outline-secondary"
                                                    className={"btn-outline rounded-btn " + (activeStep === false ? '' : "hide")}
                                                    onClick={() => { clearModalData(); close(); }}
                                                >
                                                    Cancel
                                                </Button>
                                                {activeStep ? (
                                                    <Button
                                                        variant="outline-secondary"
                                                        className="btn-outline rounded-btn"
                                                        onClick={ChangeActiveStep}
                                                    >
                                                        <i className="fa fa-arrow-left m-r-5"></i> Back
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className={"rounded-btn " + customerVehicleFlag + "  " + monthViewDisabled}
                                                        variant="primary"
                                                        onClick={() => { ChangeActiveStep(); }}
                                                    >
                                                        Next
                                                    </Button>
                                                )}
                                                <Button
                                                    type="submit"
                                                    className={"rounded-btn btn-success " + (activeStep === false ? 'hide' : "")}
                                                    variant="success"
                                                    disabled={saveIsDisable}
                                                // onClick={handleShow}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="quotes" title="Quotes" >
                                        {showMonthViewPopup ?
                                            <MonthViewCreateJob
                                                timeSlotsList={timeSlotsList}
                                                eveTimeSlotsList={eveTimeSlotsList}
                                                employeeList={employeeList}
                                                setTimeSlot={setTimeSlot}
                                                timeSlot={selectedSlot}
                                                setEmployeeId={setEmployeeId}
                                                employeeId={employeeId}
                                                handleTechnicianSlot={handleTechnicianSlot}
                                                handleBayJobMessage={handleBayJobMessage}
                                                type={"quote"}
                                            /> : ""}
                                        <Form id="quoteToJobCreate">
                                            <div className="tab-wrapper mb-3">
                                                <div className="search-wrapper full-width">
                                                    <div className="input-wrapper">
                                                        <i className="fa fa-search search-icon"></i>
                                                        <Form.Group className="form-group mb-3">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Convert your Quote to a Job and Schedule it in one simple step!"
                                                                onKeyUp={getSearchQuote}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <ul className="search-list">
                                                        <Scrollbars
                                                            autoHeight
                                                            autoHeightMax={250}
                                                            className="custom-scrollbar"
                                                            style={{ width: "100%" }}
                                                            renderThumbVertical={(props) => (
                                                                <div
                                                                    {...props}
                                                                    className="thumb-vertical black-thumb"
                                                                />
                                                            )}
                                                            renderTrackHorizontal={(props) => (
                                                                <div {...props} style={{ display: "none" }} />
                                                            )}
                                                        >
                                                            {
                                                                (quoteList.length !== 0) ?
                                                                    quoteList.map((quote) => {
                                                                        return <li key={quote.id} className={quote.id === selectedQuoteId ? "active" : ""}>
                                                                            <Link to="#" onClick={(e) => { getQuoteDetails(quote.id) }} >
                                                                                <strong>Quote </strong>
                                                                                {quote.quote_number}
                                                                                <span> | </span>
                                                                                <strong>Rego </strong>
                                                                                {quote.vehicle.rego}
                                                                            </Link>
                                                                        </li>
                                                                    }) : ''
                                                            }
                                                        </Scrollbars>
                                                    </ul>
                                                </div>
                                                <div className="form-outer mt-3 border-box">
                                                    <div className="date-time-wrapper d-flex justify-space-between  mb-3">
                                                        <Form.Group className="form-group width-33 time-input">
                                                            <label>Bill To | 3rd Party</label>
                                                            <select className="form-control custom-select" name="insurance_company_id" id="insurance_company_id"
                                                                onChange={(e) => {
                                                                    quotesDetailsData[0]['insurance_company_id'] = e.target.value;
                                                                }}
                                                            >
                                                                <option>Select</option>
                                                                {
                                                                    (insuranceData !== null && insuranceData !== undefined) ?
                                                                        insuranceData.map((insurance) => {
                                                                            return <option key={insurance.id} value={insurance.id}>{insurance.company_name}</option>
                                                                        })
                                                                        : ''
                                                                }
                                                            </select>
                                                        </Form.Group>
                                                        <Form.Group className="form-group date-picker width-33">
                                                            <label>Claim No</label>
                                                            <Form.Control type="text" id="claim_number" name="claim_number"
                                                                onChange={(e) => {
                                                                    quotesDetailsData[0]['claim_number'] = e.target.value;
                                                                }}
                                                            />{" "}
                                                        </Form.Group>
                                                        <Form.Group className="form-group width-33 time-input">
                                                            <label>Company PO</label>
                                                            <Form.Control type="text" id="purchase_order_number" name="purchase_order_number"
                                                                onChange={(e) => {
                                                                    quotesDetailsData[0]['purchase_order_number'] = e.target.value;
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="quote-detail-wrapper d-flex justify-space-between">
                                                    <div className="quote-detail">
                                                        <h3>Quote Details</h3>
                                                        <div className="quote-block-outer d-flex justify-space-between">
                                                            <div className="quote-block width-33 center-data">
                                                                {makeQuote && makeQuote !== "MAKE" &&
                                                                    <CarMakeLogo make={(makeQuote && makeQuote) || ""} />
                                                                }
                                                                {makeQuote}
                                                            </div>
                                                            <div className="quote-block width-33 center-data">{modelQuote}</div>
                                                            <div className="quote-block width-33 center-data">{yearQuote}</div>
                                                        </div>
                                                        <div className="quote-block-outer d-flex justify-space-between">
                                                            <div className="quote-block width-50 center-data">{jobDeptQuote}</div>
                                                            <div className="quote-block width-50 center-data">{jobTypeQuote}</div>
                                                        </div>
                                                    </div>
                                                    <div className="quote-detail">
                                                        <div className="quote-block-outer d-flex justify-space-between">
                                                            <div className="quote-block description-block d-flex justify-center align-center">
                                                                <span dangerouslySetInnerHTML={{ __html: descQuote }}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <Button
                                                    variant="outline-secondary"
                                                    className={"btn-outline rounded-btn"}
                                                    onClick={() => { clearModalData(); close() }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className={"rounded-btn btn-success " + quoteSelectedFlag + "  " + monthViewDisabled}
                                                    variant="success"
                                                    onClick={() => {
                                                        handleQuoteSubmitForm()
                                                    }
                                                    }
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="activities" title="Activities" >
                                        {showMonthViewPopup ?
                                            <MonthViewCreateJob
                                                timeSlotsList={timeSlotsList}
                                                eveTimeSlotsList={eveTimeSlotsList}
                                                employeeList={employeeList}
                                                setTimeSlot={setTimeSlot}
                                                timeSlot={selectedSlot}
                                                setEmployeeId={setEmployeeId}
                                                employeeId={employeeId}
                                                handleTechnicianSlot={handleTechnicianSlot}
                                                handleBayJobMessage={handleBayJobMessage}
                                                type={"activity"}
                                            /> : ""}
                                        {/* <Form ref={formRef} onSubmit={handleActivitySubmitForm} noValidate validated={activityValidated}> */}

                                        <Form>
                                            <div className="tab-wrapper mb-3">
                                                <div className="activity-wrapper d-flex justify-space-between">

                                                    <ul className="activity-list d-flex">
                                                        {
                                                            (activityData !== null && activityData !== undefined) ?
                                                                activityData.map((activity, index) => {
                                                                    var activityType = activity.type;
                                                                    if (activity.type === "1") {
                                                                        activityType = "Meeting";
                                                                    } else if (activity.type === "2") {
                                                                        activityType = "Holiday";
                                                                    } else if (activity.type === "3") {
                                                                        activityType = "Job";
                                                                    }
                                                                    return (
                                                                        <>
                                                                            <li key={activity.id}
                                                                                onClick={
                                                                                    () => {
                                                                                        setActionType(activityType);
                                                                                        setActivityId(activity.id);
                                                                                        setActivityName(activity.name);
                                                                                    }
                                                                                }
                                                                                style={{ maxWidth: "100%" }}
                                                                            >
                                                                                <Form.Check className="radio-btn mb-2" type="radio" id={activity.id}>
                                                                                    <Form.Check.Input
                                                                                        name="activity"
                                                                                        type="radio"
                                                                                        isValid
                                                                                        value={activity.name}
                                                                                        required
                                                                                    />
                                                                                    <Form.Check.Label className="d-flex justify-content-between align-items-center" >
                                                                                        <div className="ml-1" style={{ maxWidth: "80%", overflow: "hidden", textOverflow: "ellipsis" }}>{activity.name}</div>
                                                                                        <span className="check" onClick={() => handleDeleteActivity(activity.id)}>
                                                                                            <i className="la la-times font-size-14 mr-1 ml-1 mt-1"></i>
                                                                                        </span>
                                                                                    </Form.Check.Label>
                                                                                </Form.Check>


                                                                            </li>
                                                                        </>
                                                                    )
                                                                })
                                                                : ''
                                                        }
                                                    </ul>
                                                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                                    <div className="add-activity">
                                                        <Button className="btn-primary rounded-btn activity-btn"
                                                            // onClick={() => setOpenCreateActivity(true)}
                                                            onClick={() => {
                                                                setOpenCreateActivity(true);
                                                                setOpenCreateActivitySchedule(false);
                                                            }}
                                                        >
                                                            <span>+</span> Category{" "}
                                                        </Button>
                                                    </div>
                                                </div>

                                                {
                                                    openCreateActivity === true ?
                                                        <div className="add-activity d-flex">
                                                            <Form.Group className="form-group mb-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Category Name"
                                                                    onChange={handleChangeActivity}
                                                                    value={activityName}
                                                                    maxLength={31}
                                                                />
                                                                <div className="btn-wrapper">
                                                                    <Button className="btn-default" onClick={() => setOpenCreateActivity(false)} >Cancel</Button>
                                                                    <Button className="btn-success" variant="success" onClick={() => saveCreateActivity()}>Save</Button>
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                        : ''
                                                }


                                                {
                                                    openCreateActivitySchedule === true ?
                                                        <div className="add-activity d-flex">
                                                            <Form.Group className="form-group mb-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    value={activitySchedulerName || ""}
                                                                    placeholder="Activity Name"
                                                                    onChange={(e) => setActivitySchedulerName(e.target.value)}
                                                                />
                                                                <div className="btn-wrapper">
                                                                    <Button className="btn-default" onClick={() => setOpenCreateActivitySchedule(false)} >Cancel</Button>
                                                                    <Button className="btn-success" variant="success" onClick={() => saveCreateActivitySchedule()}>Save</Button>
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                        : ''
                                                }

                                                <div className="search-wrapper full-width">
                                                    <div className="input-wrapper">
                                                        <i className="fa fa-search search-icon"></i>
                                                        <Form.Group className="form-group mb-3">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Search for an Activity"
                                                                onKeyUp={(e) => { getSearchActivity(e) }}
                                                                onChange={(e) => {
                                                                    setSelectedActivityName(e.target.value);
                                                                    // setActivitySchedulerName(e.target.value);
                                                                    // setSelectedActivityDescription(e.target.value)
                                                                }}
                                                            />
                                                        </Form.Group>

                                                        <Button className="btn-primary rounded-btn activity-btn"
                                                            onClick={() => {
                                                                if (activityId > 0) {
                                                                    setOpenCreateActivitySchedule(true);
                                                                    setOpenCreateActivity(false);
                                                                } else {
                                                                    showError('Please select category first');
                                                                }
                                                            }}
                                                        >
                                                            <span>+</span> Activity{" "}
                                                        </Button>
                                                    </div>

                                                    <ul className="search-list with-close-icon">
                                                        <Scrollbars
                                                            autoHeight
                                                            autoHeightMax={250}
                                                            className="custom-scrollbar"
                                                            style={{ width: "100%" }}
                                                            renderThumbVertical={(props) => (
                                                                <div
                                                                    {...props}
                                                                    className="thumb-vertical black-thumb"
                                                                />
                                                            )}
                                                            renderTrackHorizontal={(props) => (
                                                                <div {...props} style={{ display: "none" }} />
                                                            )}
                                                        >
                                                            {
                                                                (activityScheduleList !== null && activityScheduleList !== undefined) ?
                                                                    activityScheduleList.map((activitySchedule, index) => {
                                                                        return (
                                                                            <>
                                                                                <li
                                                                                    key={activitySchedule.name}
                                                                                    className={activitySchedule.id === selectedActivitySchedule ? "active" : ''}
                                                                                    onClick={
                                                                                        () => {
                                                                                            setSelectedActivitySchedule(activitySchedule.id);
                                                                                            setSelectedActivityName(activitySchedule.name);
                                                                                            // setSelectedActivityDescription(activitySchedule.description)
                                                                                        }
                                                                                    }
                                                                                >
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="d-flex justify-space-between"
                                                                                    >
                                                                                        <span>{activitySchedule.name}</span>
                                                                                        {/* <i className="font-size-16 fa fa-close" onClick={() => handleDeleteOpen(activitySchedule.id)}></i> */}
                                                                                        {/* <Button
                                                                                            variant="danger"
                                                                                            className="rounded-btn d-flex align-center mr-10"
                                                                                            onClick={() => handleDeleteOpen(activitySchedule.id)}
                                                                                        >
                                                                                            <i className="la la-times font-size-14 mr-1"></i> Delete
                                                                                        </Button> */}
                                                                                        <i style={{color:"red"}} className="la la-times font-size-16" onClick={() => handleDeleteOpen(activitySchedule.id)}></i>
                                                                                    </Link>
                                                                                </li>
                                                                            </>
                                                                        )
                                                                    })
                                                                    : (actionType === "Holiday") ? <li
                                                                        key="On Leave"
                                                                        // className={activitySchedule.id === selectedActivitySchedule ? "active" : ''}
                                                                        onClick={
                                                                            () => {
                                                                                setSelectedActivitySchedule("On Leave");
                                                                                setSelectedActivityName("On Leave");
                                                                                // setSelectedActivityDescription("On Leave")
                                                                            }
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to="#"
                                                                            className="d-flex justify-space-between"
                                                                        >
                                                                            <span>On Leave</span>
                                                                        </Link>
                                                                    </li> : ''
                                                            }
                                                        </Scrollbars>
                                                    </ul>
                                                    <div className="multi-select-wrapper">
                                                        <span>Employees *</span>
                                                        <Select
                                                            // defaultValue={activityEmployee}
                                                            isMulti
                                                            name="employees"
                                                            value={defaultActivity}
                                                            options={activityEmployee}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={(e) => { handleEmployeeChange(e); setDefaultActivity(e) }}
                                                        />
                                                    </div>
                                                    <div className="description">
                                                        <span>
                                                            Description - This activity description will
                                                            display in the schedule
                                                        </span>
                                                        <Form.Control as="textarea" rows={3} defaultValue={selectedActivityDescription}
                                                            onBlur={(e) => setSelectedActivityDescription(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <Button
                                                    variant="outline-secondary"
                                                    className={"btn-outline rounded-btn "}
                                                    onClick={() => { clearModalData(); close() }}
                                                >Cancel
                                                </Button>
                                                <Button
                                                    // type="submit"
                                                    className={"rounded-btn btn-success  " + monthViewDisabled}
                                                    variant="success"
                                                    onClick={() => saveActivity()}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Form>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Modal.Body>
                    </Modal>
                    :
                    (manageStateModal === 'child') ?
                        <CustomerAndVehicle
                            showModal={showCustomersAndVehiclesModal}
                            setShowModal={setShowCustomersAndVehiclesModal}
                            selectedUser={selectedUser}
                            setManageStateModal={setManageStateModal}
                            customerFormType={customerFormType}
                            setCustomerDetails={setCustomerDetails}
                            setVehicleDetails={setVehicleDetails}
                        />
                        : ''
            }
            {
                (manageStateModal === "save") ?
                    <Modal size="md" centered className="option-after-jobCreate" show={showJob} onHide={handleClose} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <Modal.Title className="d-flex justify-space-between">Awesome! You have created a Job.</Modal.Title>
                            <Button type="button" className="close-btn" onClick={handleSchedule}><i className="la la-times"></i> </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-6 border-right pt-3">
                                    <div className="d-flex justify-center">
                                        <Button
                                            className={"rounded-btn btn-success "}
                                            variant="success"
                                            onClick={handleDashboard}
                                        >
                                            {(dashboardJob === "dashboard-create-job") ? "Go To Scheduler" : "Back To Dashboard"}
                                        </Button>
                                    </div>
                                    <div className="d-flex justify-center">
                                        <Button
                                            className={"rounded-btn btn-success "}
                                            variant="success"
                                            onClick={handleViewJob}
                                        >
                                            View Job
                                        </Button>
                                        <Button
                                            className={"rounded-btn btn-success "}
                                            variant="success"
                                            onClick={handleSchedule}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <div class="d-flex justify-center">
                                            <p class="font-bold-600 mb-1">Booking Confirmation</p>
                                        </div>
                                        <div >
                                            <div className="row">
                                                <div className="align-items-center col-sm-12 d-flex">
                                                    <img alt="sms image" src={smsImage} style={{ maxWidth: "40px" }}></img>
                                                    <Form.Group className="form-group">
                                                        <Form.Control
                                                            type="text"
                                                            id="customer_mobile_number"
                                                            name="customer_mobile_number"
                                                            minLength={9}
                                                            maxLength={11}
                                                            required
                                                            value={mobileNumber}
                                                            onBlur={(e) => {
                                                                if (isUpdateMobile) {
                                                                    checkMobileExist(e)
                                                                }
                                                            }
                                                            }
                                                            onChange={(e) => {
                                                                if (isUpdateMobile) {
                                                                    checkMobileExist(e)
                                                                }
                                                                setMobileNumber(e.target.value)
                                                            }}
                                                            placeholder="Mobile number" />
                                                        {MobileExist && (
                                                            <div className="err-msg">
                                                                This Mobile Number is already in use.
                                                            </div>
                                                        )}

                                                        {validateMobile && (
                                                            <div className="err-msg">
                                                                Please enter a valid Mobile Number.
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="align-items-center d-flex" style={{ marginLeft: "2.5rem" }}>
                                                <Form.Check className="custom-check form-check table-checkbox">
                                                    <Form.Check.Input
                                                        id="update_message"
                                                        type="checkbox"
                                                        className="custom-check booking-confirmation"
                                                        onChange={() => {
                                                            setIsUpdateMobile(!isUpdateMobile)
                                                        }}
                                                    />
                                                    <Form.Check.Label>
                                                        <span className="check"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                                <div className="ml-1">Update Customer Mobile #</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around mt-2">
                                        <div className="btn-wrapper">
                                            <Button type="button"
                                                variant="outline-secondary"
                                                className="btn-primary rounded-btn mb-0 "
                                                disabled={bookingConfirmationCheckbox}
                                                onClick={getSMSBalanceData}
                                            >Send SMS</Button>
                                        </div>

                                        <Form.Check className="custom-check form-check table-checkbox">
                                            {bookingConfirmationCheckbox ?
                                                <Form.Check.Input
                                                    id="booking_confirmation"
                                                    disabled
                                                    checked
                                                    type="checkbox"
                                                    className="custom-check booking-confirmation"
                                                />
                                                : <Form.Check.Input
                                                    id="booking_confirmation"
                                                    disabled
                                                    type="checkbox"
                                                    className="custom-check booking-confirmation"
                                                />
                                            }
                                            <Form.Check.Label>
                                                <span className="check"></span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ''
            }
            {showDeleteModal && (
                <DeleteConfirm
                    handleDeleteClose={() => handleDeleteClose()}
                    deleteRecord={() => deleteActivitySchedule()}
                    msg={"Are you sure?"}
                />)
            }

            {confirmDeleteActivity && (
                <DeleteConfirm
                    handleDeleteClose={() => handleDeleteClose()}
                    deleteRecord={() => deleteActivityRecord()}
                    msg={"Are you sure?"}
                />)
            }

            {showSmsBalanceModal && (
                <JobSmsBalance
                    showSmsBalanceModal={showSmsBalanceModal}
                    handleShowSmsBalanceModal={handleShowSmsBalanceModal}
                    handleCloseSmsBalanceModal={handleCloseSmsBalanceModal}
                />
            )}

        </>
    );
};


export default CreateJob;
