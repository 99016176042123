import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import smsImage from "../../../src/assets/images/sms-vector.png";
import { useNavigate } from "react-router-dom";

const JobSmsBalance = (props) => {
  
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const navigate = useNavigate();
  
    return (
        <>
          <Modal
            className="sms-balance-active"
            size="sm"
            centered
            show={props.showSmsBalanceModal}
            onHide={props.handleCloseSmsBalanceModal}
          >
            <Modal.Body>
              <div className="img-block d-flex justify-center">
                <img src={smsImage} alt="SMS Balance"></img>
              </div>
              <p className="text-center">It looks like you haven't </p>
              <p className="text-center">activated SMS messaging.</p>
              <div className="btn-wrapper">
                <Button
                  type="button"
                  variant="outline-secondary"
                  className="btn-outline-primary rounded-btn"
                  onClick={() => {
                    navigate("/edit-subscription");
                    props.handleCloseSmsBalanceModal();
                  }}
                >
                  Activate Now
                </Button>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-center">
                <Button
                  variant="outline-secondary"
                  className="btn-outline rounded-btn"
                  id="closeButton"
                  onClick={props.handleCloseSmsBalanceModal}
                >
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
    );
};
export default JobSmsBalance;
