import { WAIT_FOR_ACTION } from "redux-wait-for-action";
import { getInvoceCreateDetail, getInvoiceRest } from "./action";
import { GET_DETAIL_FOR_CREATE_INVOICE_SUCCESS } from "./actionType";

export function getBookedJobPayload(id, workshop_id) {
  return {
    get: "all",
    conditions: {
      "JobSchedules.id": id,
    },
    contain: {
      0: "Vehicles",
      1: "WorkshopDepartmentsAccountsDetails",
      2: "JobBookingTypes",
      3: "Users.EmployeesDetails.LabourCharges",
      4: "Users.Workshops",
      5: "Vehicles.VehicleMake",
      6: "Vehicles.VehicleModel",
      7: "JobAttachments",
      8: "JobLabours.LabourCharges",
      9: "JobsParts.Products",
      10: "InsuranceCompany.States",
      11: "InsuranceCompany.Countries",
      12: "CreatedByUser",
      13: "JobBookingTypes",
      14: "Invoices",
      15: "Workshops.WorkshopOtherDetails",
      16: "Vehicles.Users.CustomersDetails.States",
      17: "Vehicles.Users.CustomersDetails.Countries",
      // 18: "InspInspections",
      "JobAttachmentList": {
        "conditions": {
          // "JobAttachmentList.Insp_status IN":["Sent","Approved"]
        },
      },
      "Vehicles.Users.CustomersDetails": {
        conditions: {
          "CustomersDetails.workshop_id": workshop_id,
        },
      },
      "Customers.CustDetails": {},
      "Customers.CustDetails.States": {},
      "Customers.CustDetails.Countries": {},
      JobsParts: {
        conditions: {
          "JobsParts.status": 1,
        },
        sort: {
          "JobsParts.job_part_priority": "asc",
        },
      },
      JobLabours: {
        conditions: {
          "JobLabours.status": 1,
        },
        sort: {
          "JobLabours.priority": "asc",
        },
      },
      Recommendations: {
        conditions: {
          "Recommendations.workshop_id": workshop_id,
          "Recommendations.job_id": id,
          "Recommendations.is_deleted": "0",
          "Recommendations.is_show": "0",
          "Recommendations.is_completed": "0"
        },
      },
      "JobsParts.Products.KitPartsDetailsA.Products": {
        fields: ["Products.id", "Products.name", "Products.product_number"]
      },
      "InvoiceCCustomer": {
        fields: [
            "id",
            "firstname",
            "lastname",
            "mobile_number",
            "mobile_code",
            "type",
            "company_name",
            "user_id"
        ]
      },
      "InvoiceCCustomer.Users": {
        fields: [
            "id",              
            "email"
        ]
      }, 
    },
  };
}

export function getInvoiceListPayload(workshop_id) {
  return {
    get: "all",
    conditions: { "Invoices.workshop_id": workshop_id },
    contain: ["JobSchedules.Vehicles.Users"],
    order: {
      'Invoices.id': 'DESC'
    },
    'limit': 1,
  };
}

export function getCashSaleListPayload(workshop_id) {
  return {
    get: "all",
    conditions: { "CashSaleInvoice.workshop_id": workshop_id },
    order: {
      'CashSaleInvoice.id': 'DESC'
    },
    'limit': 1,
  };
}

export function getWorkshopOtherDetailViewPayload(workshop_id) {
  return { workshop_id: workshop_id };
}

export function getJobNotesPayload(id) {
  return {
    get: "all",
    conditions: {
      job_id: id
    },
    'order': {
      'id': 'DESC'
    }
  };
}

export function getInsurancePayload(workshop_id) {
  return {
    get: "all",
    conditions: { workshop_id: workshop_id },
    fields: ["id", "company_name", "email", "phone_number", "postcode", "suburb", "street_address"],
  };
}

export function getJobCannedListPayload(workshop_id) {
  return {
    get: "all",
    conditions: { workshop_id: workshop_id, status: 1, "type In": [6, 3, 8] },
    fields: ["id", "department_id", "name", "description"],
  };
}

export function getMessageTemplatePayload(workshop_id) {
  return {
    get: "all",
    conditions: { workshop_id: workshop_id, status: 1, "template_type In": [3], "is_email": "1" },
  };
}

export function getLabourChargeListPayload(workshop_id) {
  return {
    get: "all",
    conditions: { workshop_id: workshop_id, is_default: "1", is_deleted: 0 },
  };
}

export const getInvoiceCreateDetailAPI = (id, workshop_id) => {
  const payload = {
    getBookedJobRequest: getBookedJobPayload(id, workshop_id),
    getInvoiceListRequest: getInvoiceListPayload(workshop_id),
    getCashSaleListRequest: getCashSaleListPayload(workshop_id),
    getWorkShopOtherDetailRequest:
      getWorkshopOtherDetailViewPayload(workshop_id),
    getJobNotesRequest: getJobNotesPayload(id),
    getInsuranceRequest: getInsurancePayload(workshop_id),
    getJobCannedRequest: getJobCannedListPayload(workshop_id),
    getLabourChargeRequest: getLabourChargeListPayload(workshop_id),
    getMessageTemplateRequest: getMessageTemplatePayload(workshop_id),
  };

  return payload;
};
