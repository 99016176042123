import React, { useState } from 'react';
import AWS from 'aws-sdk'

const S3_BUCKET = '5iq-website-uploads';
const REGION = 'ap-southeast-2';


AWS.config.update({
    accessKeyId: 'AKIA2HR5ROK6ITNVW6PH',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,

})

const UploadImageToS3WithNativeSdk = () => {

    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadFile = (file) => {

        const d = new Date();
        var time = d.getTime();
        var filename = file.name.replace(/ /g, "_");
        // var extenstion = file.type.split("/");
        filename = time + "_" + filename;

        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: "user_profile/" + filename
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) console.log(err)
            })
    }


    return <div>
        <div>Native SDK File Upload Progress is {progress}%</div>
        <input type="file" onChange={handleFileInput} />
        <button onClick={() => uploadFile(selectedFile)}> Upload to S3</button>
    </div>
}

export default UploadImageToS3WithNativeSdk;