import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Default from "./default";
import CustomerPricingLevels from "./customerPricingLevels";
import { GET_WORKSHOP_OTHER_DETAIL } from "../../../../store/companyDetails/actionTypes";
import { GET_LABOUR_CHARGES_LIST } from "../../../../store/commonApiStore/actionTypes";
import LoaderImage from "../../../../../src/assets/images/page-loader.svg";
import LabourCharges from "./labourCharges";
import "./defaultsettings.scss";
import AssetType from "./assetType";
import { Nav } from "react-bootstrap";

const DefaultSettings = () => {
  const dispatch = useDispatch(); // Dispatch variable

  const companyDetailData = useSelector((state) => state.CompanyDetailReducer); // GET Company Detail Reducer data
  const commonApiData = useSelector((state) => state.CommonApiStoreReducer);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: GET_WORKSHOP_OTHER_DETAIL,
      payload: {
        workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });
    dispatch({
      type: GET_LABOUR_CHARGES_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
        },
      },
    });
  }, []);

  useEffect(() => {
    setIsLoading(companyDetailData.loading);
  }, [companyDetailData]);

  useEffect(() => {
    setIsLoading(commonApiData.loadingLogins);
  }, [commonApiData]);

  return (
    <>
      <div className="right-section-wrapper default-setting-wrapper">
        <div className="table-header">
          <div className="table-header-tabs">
            <Tab.Container defaultActiveKey="default">
              <div className="row top-block">
                <div className="col-md-2">
                  <div className="table-title">
                    <i className="fa fa-gears text-primary me-2 font-size-24"></i>
                    <span>DEFAULTS</span>
                  </div>
                </div>
                <div className="col-md-8">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="default">Default</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="customer-pricing-levels">
                      Customer Pricing Levels (Parts)
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="labour-charges">
                        Labour Charges
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="asset-type">Vehicle Type</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-2">
                  <div className="video-link-outer">
                    <div className="video-tutorial">
                      <i className="fa fa-icon"></i>
                      {/* <span className="help-text">Help</span> */}
                      <Link to="/trainee-video#settings" ><span className="tutorial-title">Settings Videos</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="default">
                  <Default
                    workShopOtherDetails={
                      companyDetailData.workShopOtherDetails
                    }
                    labourData={commonApiData.labourChargesList}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="customer-pricing-levels">
                  <CustomerPricingLevels />
                </Tab.Pane>
                <Tab.Pane eventKey="labour-charges">
                  <LabourCharges />
                </Tab.Pane>
                <Tab.Pane eventKey="asset-type">
                  <AssetType />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>

      {isLoading === true && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
    </>
  );
};

export default DefaultSettings;
