import { get, post, apiCall, thirdPartyApiCall } from "./apiHelper";
import * as url from "./urlHelper";

//Login
export const getLogin = (data) => post(url.GET_LOGIN, data);

//Registration
export const getRegister = (data) => post(url.GET_REGISTER, data);
export const sendOtp = (data) => post(url.SEND_OTP, data);
export const confirmOtp = (data) => post(url.CONFIRM_OTP, data);
export const existEmail = (data) => post(url.EXIST_EMAIL, data);
export const getCountry = (data) => post(url.GET_COUNTRY, data);
export const getState = (data) => post(url.GET_STATE, data);
export const getIndustry = (data) => post(url.GET_INDUSTRY, data);
export const getAccountingPackage = (data) =>
  post(url.GET_ACCOUNTING_PACKAGE, data);
export const getSizeOfWorkshop = (data) => post(url.GET_SIZE_OF_WORKSHOP, data);
export const getReferralAdvertise = (data) =>
  post(url.GET_REFERRAL_ADVERTISE, data);

//Forgot Password
export const getForgotPassword = (data) => post(url.GET_FORGOT_PASSWORD, data);

//Forgot Email
export const getForgotEmail = (data) => post(url.GET_FORGOT_EMAIL, data);

//Check Reset Password Link
export const checkResetLink = (data) => post(url.CHECK_RESET_LINK, data);

//Update User Password
export const updatePassword = (data) => post(url.USER_RESET_PASSWORD, data);



/***************************************
 ░░░░ Jobs ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
****************************************/

/*Unassigned jobs*/
export const getJobs = (data) => apiCall(url.GET_JOBS, data, "POST", true);

export const getUnassignedJobs = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);

//assigned jobs
export const getassignedJobs = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);

//inProgress job
export const getinProgressJobs = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);

//completed job
export const getcompletedJobs = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);

// delete job
export const deleteJobs = (data) =>
  apiCall(url.DELETE_JOBS, data, "POST", true);

//User Profile
export const getUserProfile = (data) =>
  apiCall(url.GET_USER_PROFILE, data, "POST", true);
export const updateUserProfile = (data) =>
  apiCall(url.UPDATE_USER_PROFILE, data, "POST", true);

//Notification
export const getNotification = (data) =>
  apiCall(url.GET_NOTIFICATION, data, "POST", true);

//Notification
export const deleteNotificationList = (data) =>
  apiCall(url.DELETE_NOTIFICATION_LIST, data, "POST", true);

//Copy Notification
export const addManyNotification = (data) =>
  apiCall(url.COPY_NOTIFICATION_DATA, data, "POST", true);

//Marketing Notification
export const getMarketingNotification = (data) =>
  apiCall(url.GET_MARKETING_NOTIFICATION, data, "POST", true);


//Change Password
export const getChangePassword = (data) =>
  apiCall(url.GET_CHANGE_PASSWORD, data, "POST", true);

//Get subscription other detail (SMS and REgo lookup data)
export const getSubscriptionAddon = (data) =>
  apiCall(url.GET_SUBSCRIPTION_ADDON, data, "POST", true);
export const getSubscription = (data) =>
  apiCall(url.GET_SUBSCRIPTION, data, "POST", true);

// Customer lists
export const getCustomerLists = (data) =>
  apiCall(url.GET_CUSTOMER_LIST, data, "POST", true);

export const addManySecondaryContact = (data) =>
  apiCall(url.ADD_MANY_SECONDARY_CUSTOMER, data, "POST", true);

// Vehicle history 
export const addVehicleHistory = (data) =>
  apiCall(url.ADD_VEHICLE_HISTORY, data, "POST", true);

// Customer and Vehicle details
export const getCustomerAndVehicleDetails = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

// Job department list
export const getJobDepartmentList = (data) =>
  apiCall(url.GET_JOB_DEPARTMENT_LIST, data, "POST", true);

// Job type list
export const getJobTypeList = (data) =>
  apiCall(url.GET_JOB_TYPE_LIST, data, "POST", true);

//Job canned list
export const getJobCannedList = (data) =>
  apiCall(url.GET_JOBCANNED_LIST, data, "POST", true);

//Job sms template list
export const getJobSmsTemplateList = (data) =>
  apiCall(url.GET_JOBTEMPLATE_LIST, data, "POST", true);

//Job email template list
export const getJobEmailTemplateList = (data) =>
  apiCall(url.GET_JOBTEMPLATE_LIST, data, "POST", true);

//Job submit email template
export const submitJobEmailTemplate = (data) =>
  apiCall(url.Save_JOBEMAILTEMPLATE, data, "POST", true);

//Job submit sms template
export const submitJobSmsTemplate = (data) =>
  apiCall(url.Save_JOBSMSTEMPLATE, data, "POST", true);

//Job submit sms-email template
export const submitJobSmsEmailTemplate = (data) =>
  apiCall(url.Save_JOBSMSTEMPLATE, data, "POST", true);

//Job Creat
export const createJob = (data) => apiCall(url.CREATE_JOB, data, "POST", true);

//edit customer submit email template
export const submitUserEmailTemplate = (data) =>
  apiCall(url.SAVE_USER_EMAIL_TEMPLATE, data, "POST", true);

//edit customer submit sms template
export const submitUserSmsTemplate = (data) =>
  apiCall(url.SAVE_USER_SMS_TEMPLATE, data, "POST", true);


//Marketing Create
export const createMarketing = (data) =>
  apiCall(url.CREATE_MARKETING, data, "POST", true);

//Marketing Edit
export const viewMarketing = (data) =>
  apiCall(url.VIEW_MARKETING + "/" + data + ".json", data, "GET", true);

//Marketing Edit
export const viewHQMarketing = (data) =>
  apiCall(url.VIEW_HQ_MARKETING + "/" + data + ".json", data, "GET", true);

//Marketing Edit
export const editMarketing = (data) =>
  apiCall(url.EDIT_MARKETING, data, "POST", true);

// list of selected vehicle model
export const getVehicles = (data) =>
  apiCall(url.GET_VEHICLES, data, "POST", true);

// list of all vehicles 
export const getVehiclesHistory = (data) =>
  apiCall(url.GET_VEHICLES_HISTORY, data, "POST", true);

// list of selected vehicle model
export const getVehicleModel = (data) =>
  apiCall(url.GET_VEHICLESMODEL, data, "POST", true);

// list of selected vehicle series

export const getVehicleSeries = (data) =>
  apiCall(url.GET_VEHICLESERIES, data, "POST", true);

// list of assets details
export const getAssets = (data) => apiCall(url.GET_ASSETS, data, "POST", true);

// list of custom fields
export const getCustomFields = (data) =>
  apiCall(url.GET_CUSTOM_FIELDS, data, "POST", true);

//check vin number exist or not
export const checkVinExist = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

//check chasis number exist or not
export const checkChasisExist = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

//Check engine exist or not
export const checkEngineExist = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

//check fleet exist or not
export const checkFleetExist = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

// Get assets master table details
export const getAssetsMaster = (data) =>
  apiCall(url.GET_VEHICLES, data, "POST", true);

// Get assets details table details
export const getAssetsDetails = (data) =>
  apiCall(url.GET_VEHICLESERIES, data, "POST", true);

// Add assets details
export const addAssetsDetails = (data) =>
  apiCall(url.ADD_ASSETS_DETAILS, data, "POST", true);

// Add assets master details
export const addAssetsMaster = (data) =>
  apiCall(url.ADD_ASSETS_MASTER, data, "POST", true);

// Add vehicle details
export const addAssets = (data) => apiCall(url.ADD_ASSETS, data, "POST", true);

export const saveUserRequest = (data) => {
  apiCall(url.SAVE_USER_REQUEST, data, "POST", true);
};

export const getVehicleHistoryDetails = (data) =>
  apiCall(url.GET_JOB_BOOKED_DETAILS, data, "POST", true);

export const addCustomer = (data) => post(url.ADD_CUSTOMER, data);
export const updateCustomer = (data) => post(url.UPDATE_CUSTOMER, data);
export const addUserLogin = (data) =>
  apiCall(url.ADD_USER_LOGIN, data, "POST", true);
/***************************************
 ░░░░ Job Detail START ░░░░░░░░░░░░░░░░░
****************************************/
// get job notes
export const getJobNotes = (data) =>
  apiCall(url.GET_JOB_NOTES, data, "POST", true);

// add job notes
export const addJobNotes = (data) =>
  apiCall(url.ADD_JOB_NOTES, data, "POST", true);

// update job notes
export const updateJobNotes = (data) =>
  apiCall(url.ADD_JOB_NOTES, data, "POST", true);

/***************************************
 ░░░░ Job Detail END ░░░░░░░░░░░░░░░░░
****************************************/

/***************************************
 ░░░░ Global Search ░░░░░░░░░░░░░░░░░░░░
****************************************/
export const getGlobalSearch = (data) =>
  apiCall(url.GET_GLOBAL_SEARCH, data, "POST", true);

/***************************************
 ░░░░ Scheduler ░░░░░░░░░░░░░░░░░░░░░░░░
****************************************/
export const getTechnician = (data) =>
  apiCall(url.GET_TECHNICIAN, data, "POST", true);
export const getWorkshopOtherDetailsView = (data) =>
  apiCall(url.GET_WORKSHOP_OTHER_DETAILS_VIEW, data, "POST", true);
export const getWorkshopOtherDetails = (data) =>
  apiCall(url.GET_WORKSHOP_OTHER_DETAILS, data, "POST", true);
export const getTechnicianHour = (data) =>
  apiCall(url.GET_TECHNICIAN_HOUR, data, "POST", true);
export const getWorkshopBays = (data) =>
  apiCall(url.GET_WORKSHOP_BAYS, data, "POST", true);
export const getInsurance = (data) =>
  apiCall(url.GET_INSURANCE, data, "POST", true);
export const getJobTags = (data) =>
  apiCall(url.GET_JOB_TAGS, data, "POST", true);
export const addJobTags = (data) => post(url.ADD_JOB_TAGS, data);
export const printJobCardPdf = (data) => post(url.PRINT_JOB_CARD_PDF, data);
export const checkAvailableHours = (data) =>
  apiCall(url.CHECK_AVAILABLE_HOURS, data, "POST", true);
export const getWorkshopTechnician = (data) =>
  apiCall(url.GET_WORKSHOP_TECHNICIAN, data, "POST", false);
export const createQuote = (data) =>
  apiCall(url.CREATE_JOB, data, "POST", true);
export const createQuoteToJob = (data) =>
  apiCall(url.CREATE_JOB, data, "POST", true);
export const getAvailableSlots = (data) =>
  apiCall(url.GET_AVAIALABLE_SLOTS, data, "POST", true);
export const getCarryOverJob = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);
export const getJobsKitParts = (data) =>
  apiCall(url.GET_JOBS_KIT_PARTS, data, "POST", true);
export const getJobsKitLabours = (data) =>
  apiCall(url.GET_JOBS_KIT_LABOURS, data, "POST", true);
export const addManyJobParts = (data) =>
  apiCall(url.ADD_MANY_JOB_PARTS, data, "POST", true);
export const addManyJobLabours = (data) =>
  apiCall(url.ADD_MANY_JOB_LABOURS, data, "POST", true);
export const addManyJobKitParts = (data) =>
  apiCall(url.ADD_MANY_JOB_KIT_PARTS, data, "POST", true);
export const addManyJobKitLabours = (data) =>
  apiCall(url.ADD_MANY_JOB_KIT_LABOURS, data, "POST", true);
export const getActivity = (data) =>
  apiCall(url.GET_ACTIVITY, data, "POST", true);
export const getScheduleActivity = (data) =>
  apiCall(url.GET_SCHEDULE_ACTIVITY, data, "POST", true);
export const addActivity = (data) =>
  apiCall(url.ADD_ACTIVITY, data, "POST", true);
export const deleteActivity = (data) =>
  apiCall(url.DELETE_ACTIVITY, data, "POST", true);
export const addScheduleActivity = (data) =>
  apiCall(url.ADD_SCHEDULE_ACTIVITY, data, "POST", true);
export const addManyCreateJob = (data) =>
  apiCall(url.ADD_MANY_CREATE_JOB, data, "POST", true);
export const deleteJobActivity = (data) =>
  apiCall(url.DELETE_JOB_ACTIVITY, data, "POST", true);
export const deleteJobScheduleActivity = (data) =>
  apiCall(url.DELETE_JOB_SCHEDULE_ACTIVITY, data, "POST", true);
export const getMonthJobList = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);
export const updateCarryOverJob = (data) =>
  apiCall(url.UPDATE_JOB_TIMER, data, "POST", true);
export const getTimerDetails = (data) =>
  apiCall(url.GET_JOB_BOOKED_DETAILS, data, "POST", true);
export const getOpenRequestJob = (data) =>
  apiCall(url.GET_JOB_BOOKED_DETAILS, data, "POST", true);

export const getQuoteDetails = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);
export const getQuoteKitParts = (data) =>
  apiCall(url.GET_QUOTE_KIT_PART, data, "POST", true);
export const getQuoteKitLabours = (data) =>
  apiCall(url.GET_QUOTE_KIT_LABOUR, data, "POST", true);

export const updateJobActivity = (data) =>
  apiCall(url.UPDATE_JOB_ACTIVITY, data, "POST", true);
/***************************************
 ░░░░ Dashboard ░░░░░░░░░░░░░░░░░░░░░░░░  
****************************************/

export const getVehicleCount = (data) =>
  apiCall(url.GET_VEHICLE_COUNT, data, "POST", true);
export const getServiceDueCount = (data) =>
  apiCall(url.GET_SERVICE_DUE_COUNT, data, "POST", true);
export const getCustomerAndAppCount = (data) =>
  apiCall(url.GET_CUSTOMER_AND_APP_COUNT, data, "POST", true);
export const getBookInSource = (data) =>
  apiCall(url.GET_JOB_BOOKED_DETAILS, data, "POST", true);
export const getCustomerSurvey = (data) =>
  apiCall(url.GET_CUSTOMER_SURVEY, data, "POST", true);
export const getNewsFeed = (data) =>
  apiCall(url.GET_NEWS_FEED, data, "POST", true);
export const getDashboardJobCount = (data) =>
  apiCall(url.GET_DASHBOARD_JOB_COUNT, data, "POST", true);
export const getDashboardWeekJobCount = (data) =>
  apiCall(url.GET_DASHBOARD_WEEK_JOB_COUNT, data, "POST", true);
export const getDashboardInvoiceCount = (data) =>
  apiCall(url.GET_DASHBOARD_INVOICE_COUNT, data, "POST", true);
export const getDashboardWeekInvoiceCount = (data) =>
  apiCall(url.GET_DASHBOARD_WEEK_INVOICE_COUNT, data, "POST", true);
export const getDashboardPaymentCount = (data) =>
  apiCall(url.GET_DASHBOARD_PAYMENT_COUNT, data, "POST", true);
export const getDashboardWeekPaymentCount = (data) =>
  apiCall(url.GET_DASHBOARD_WEEK_PAYMENT_COUNT, data, "POST", true);
export const updateCheckInTime = (data) =>
  apiCall(url.UPDATE_JOB_TIMER, data, "POST", true);

/****************************************
 ░░░░ Quotes ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*****************************************/
export const getOpenQuotes = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);
export const getWonQuotes = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);
export const getLostQuotes = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);
export const editQuotes = (data) =>
  apiCall(url.EDIT_QUOTES, data, "POST", true);

export const getQuoteBookedDetails = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);

/******************************************************************
 ░░░░ Workshop / Workshop Other Detail END ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/
export const updateWorkshopOtherDetail = (data) =>
  apiCall(url.UPDATE_WORKSHOP_OTHER_DETAILS, data, "POST", true);
export const getWorkshopSubscriptionDetailsList = (data) =>
  apiCall(url.GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST, data, "POST", true);

// get workshop markup detail
export const getWorkshopMarkupDetail = (data) =>
  apiCall(url.GET_WORKSHOP_MARKUP_DETAILS, data, "POST", true);

// get workshop markup lists
export const getWorkshopMarkupList = (data) =>
  apiCall(url.GET_WORKSHOP_MARKUP_LIST, data, "POST", true);

// delete workshop markup
export const deleteWorkshopMarkup = (data) =>
  apiCall(url.DELETE_WORKSHOP_MARKUP, data, "POST", true);

// get workshop TAX / RATES detail
export const getWorkshopTaxDetail = (data) =>
  apiCall(url.GET_WORKSHOP_TAX_DETAILS, data, "POST", true);

/******************************************************************
 ░░░░ Workshop / Workshop Other Detail END ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/

/***************
 ░░░░ PARTS ░░░░
****************/
// get parts / product list, Search parts
export const getPartList = (data) =>
  apiCall(url.GET_PART_LIST, data, "POST", true);

// get parts / product list, Search parts
export const getSearchPartList = (data) =>
  apiCall(url.GET_SEARCH_PART_LIST, data, "POST", true);

// get kit parts / product list, Search parts
export const getSearchKitPartList = (data) =>
  apiCall(url.GET_SEARCH_KIT_PART_LIST, data, "POST", true);

export const partCategoryList = (data) =>
  apiCall(url.PART_CATEGORY_LIST, data, "POST", true);

// get Status
export const getStatusColor = (data) =>
  apiCall(url.GET_STATUS_COLOR, data, "POST", true);

//save parts / products
export const savePart = (data) => apiCall(url.SAVE_PART, data, "POST", true);

//delete parts / products
export const deletePart = (data) =>
  apiCall(url.DELETE_PART, data, "POST", true);

//delete parts / products
export const editManyPart = (data) =>
  apiCall(url.EDIT_MANY_PART, data, "POST", true);

//delete parts / products
export const editPart = (data) => apiCall(url.EDIT_PART, data, "POST", true);

//save parts / products
export const savePartReact = (data) =>
  apiCall(url.SAVE_PART_REACT, data, "POST", true);

//save parts / products
export const editPartReact = (data) =>
  apiCall(url.EDIT_PART_REACT, data, "POST", true);

export const viewPart = (data) =>
  apiCall(url.VIEW_PART + "/" + data + ".json", data, "GET", true);
export const getUserData = (id) =>
  apiCall(url.GET_USER_DATA_BY_ID + "/" + id + ".json", {}, "GET", true);

/***************
 ░░░░ PARTS ░░░░
****************/

/***************
 ░░░░ JOB PARTS ░░░░
****************/
// get job parts
export const getJobPartList = (data) =>
  apiCall(url.GET_JOB_PART_LIST, data, "POST", true);

// save job parts
export const saveJobPart = (data) =>
  apiCall(url.SAVE_JOB_PART, data, "POST", true);

// delete job parts
export const deleteJobPart = (data) =>
  apiCall(url.DELETE_JOB_PART, data, "POST", true);

export const updateJobPart = (data) =>
  apiCall(url.UPDATE_JOB_PART, data, "POST", true);

// save job parts many
export const editJobPartMany = (data) =>
  apiCall(url.EDIT_JOB_PART_MANY, data, "POST", true);

// save job kit parts many
export const editJobKitPartMany = (data) =>
  apiCall(url.EDIT_JOB_KIT_PART_MANY, data, "POST", true);

// save job kit parts many
export const editJobKitLabourMany = (data) =>
  apiCall(url.EDIT_JOB_KIT_LABOUR_MANY, data, "POST", true);

export const editInventoryStockMany = (data) =>
  apiCall(url.EDIT_INVENTORY_STOCK_MANY, data, "POST", true);

/***************
 ░░░░ JOB  PARTS ░░░░
****************/

// get job booked details
export const getJobBookedDetails = (data) =>
  apiCall(url.GET_JOB_BOOKED_DETAILS, data, "POST", true);

// get recommendations
export const getRecommendations = (data) =>
  apiCall(url.GET_JOB_RECOMMENDATIONS_LIST, data, "POST", true);

// job booking sms sent
export const sentJobBookSms = (data) =>
  apiCall(url.SENT_JOB_BOOK_SMS, data, "POST", true);


// add multiple recommendations
export const addManyRecommendations = (data) =>
  apiCall(url.ADD_MANY_RECOMMENDATIONS, data, "POST", true);

// delete recommendations
export const deleteRecommendations = (data) =>
  apiCall(url.DELETE_RECOMMENDATIONS, data, "POST", true);

// delete recommendations
export const editRecommendations = (data) =>
  apiCall(url.EDIT_RECOMMENDATIONS, data, "POST", true);


// get rego look up data
export const getRegoLookUpDetails = (data) =>
  thirdPartyApiCall(url.GET_REGO_LOOKUP_DETAILS, data, "POST", false);


export const getRegoLookUpDetails1 = (data) =>
  apiCall(url.GET_REGO_LOOKUP_DETAILS, data, "POST", false);

// update job timer data
export const updateJobTimer = (data) =>
  apiCall(url.UPDATE_JOB_TIMER, data, "POST", true);

// update job timer data
export const updateJob = (data) =>
  apiCall(url.UPDATE_JOB_TIMER, data, "POST", true);

// update job timer data
export const updateJobStatus = (data) =>
  apiCall(url.UPDATE_JOB_STATUS, data, "POST", true);

// update vehicle data
export const updateVehicle = (data) =>
  apiCall(url.UPDATE_VEHICLE, data, "POST", true);

// get job kit part list
export const getJobKitPart = (data) =>
  apiCall(url.GET_JOB_KIT_PART, data, "POST", true);

// get job kit part list
export const getJobKitLabour = (data) =>
  apiCall(url.GET_JOB_KIT_LABOUR, data, "POST", true);

export const saveJobKitPart = (data) =>
  apiCall(url.SAVE_JOB_KIT_PART, data, "POST", true);

export const deleteJobKitPart = (data) =>
  apiCall(url.DELETE_JOB_KIT_PART, data, "POST", true);

/***********************
 ░░░░ Labour START ░░░░
************************/

// get default labour charge list
export const getLabourChargesList = (data) =>
  apiCall(url.GET_LABOUR_CHARGES_LIST, data, "POST", true);

// get labour list
export const getJobLabourList = (data) =>
  apiCall(url.GET_JOB_LABOUR_LIST, data, "POST", true);

//save job labour
export const saveJobLabour = (data) =>
  apiCall(url.SAVE_JOB_LABOUR, data, "POST", true);

// delete job Labour
export const deleteLabourPart = (data) =>
  apiCall(url.DELETE_LABOUR_PART, data, "POST", true);

// delete job Labour
export const updateLabourPart = (data) =>
  apiCall(url.UPDATE_LABOUR_PART, data, "POST", true);

//update job labour
export const updateJobLabour = (data) =>
  apiCall(url.UPDATE_LABOUR_PART, data, "POST", true);

//edit job labour many
export const editJobLabourMany = (data) =>
  apiCall(url.EDIT_JOB_LABOUR_MANY, data, "POST", true);
// quote start
//save quote labour
export const saveQuoteLabour = (data) =>
  apiCall(url.SAVE_QUOTE_LABOUR, data, "POST", true);
// get labour list
export const getQuoteLabourList = (data) =>
  apiCall(url.GET_QUOTE_LABOUR_LIST, data, "POST", true);
// delete job Labour
export const deleteQuoteLabourPart = (data) =>
  apiCall(url.DELETE_QUOTE_LABOUR_PART, data, "POST", true);
// delete job Labour
export const updateQuoteLabour = (data) =>
  apiCall(url.UPDATE_QUOTE_LABOUR_PART, data, "POST", true);
//edit job labour many
export const editQuoteLabourMany = (data) =>
  apiCall(url.EDIT_QUOTE_LABOUR_MANY, data, "POST", true);

/***********************
 ░░░░ Labour END ░░░░
************************/
/************************************
 ░░░░░░░░░░░░ Inspection ░░░░░░░░░░░░ 
*************************************/
export const getInspections = (data) =>
  apiCall(url.GET_INSPECTIONS, data, "POST", true);

export const getInspectionTemplates = (data) =>
  apiCall(url.GET_INSPECTION_TEMPLATES, data, "POST", true);

export const updateInspection = (data) =>
  apiCall(url.UPDATE_INPECTION, data, "POST", true);

export const deleteInspection = (data) =>
  apiCall(url.DELETE_INPECTION, data, "POST", true);

export const deleteInspectionTemplateQue = (data) =>
  apiCall(url.DELETE_INSPECTION_TEMPLATE_QUE, data, "POST", true);

/***********************
 ░░░░ Supplier START ░░░░
************************/

// get Supplier / Supplier list, Search Supplier
export const getSupplierList = (data) =>
  apiCall(url.GET_SUPPLIER_LIST, data, "POST", true);

export const getHQSupplierList = (data) =>
  apiCall(url.GET_HQ_SUPPLIER_LIST, data, "POST", true);

//save Supplier
export const addSupplier = (data) =>
  apiCall(url.ADD_SUPPLIER, data, "POST", true);

export const addHQSupplier = (data) =>
  apiCall(url.ADD_HQ_SUPPLIER, data, "POST", true);

//delete HQ supplier
export const deleteHQSupplier = (data) =>
  apiCall(url.DELETE_HQ_SUPPLIER, data, "POST", true);

/***********************
 ░░░░ Supplier END ░░░░
************************/
// Settings Company Detail Page
export const getTimeZone = (data) => post(url.GET_TIMEZONE, data);
export const getWorkShopDetail = (data) => post(url.GET_WORK_SHOP_DETAIL, data);
export const getWorkShopOtherDetail = (data) =>
  post(url.GET_WORK_SHOP_OTHER_DETAIL, data);
export const updateCompanyDetail = (data) =>
  apiCall(url.UPDATE_COMPANY_DETAIL, data, "POST", true);
export const updateCompanyOtherDetail = (data) =>
  apiCall(url.UPDATE_COMPANY_OTHER_DETAIL, data, "POST", true);
export const getWorkShopSMS = (data) => post(url.GET_WORK_SHOP_SMS, data);
export const updateWorkShopSMS = (data) =>
  apiCall(url.UPDATE_WORKSHOP_SMS, data, "POST", true);
export const addWorkShopMarkup = (data) =>
  apiCall(url.SAVE_WORKSHOP_MARKUP_DETAILS, data, "POST", true);
export const updateWorkShopMarkup = (data) =>
  apiCall(url.UPDATE_WORKSHOP_MARKUP_DETAILS, data, "POST", true);

export const updateDefaultWorkShopMarkup = (data) =>
  apiCall(url.UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAILS, data, "POST", true);

// Setting Default Page

//get inspections view
export const getInspectionView = (data) =>
  apiCall(url.GET_INSPECTION_VIEW, data, "POST", true);

//get internal notes
export const getInternalNotes = (data) =>
  apiCall(url.GET_INTERNAL_NOTES, data, "POST", true);

//Job internalnotes scripts
export const getInternalNotesScripts = (data) =>
  apiCall(url.GET_JOBCANNED_LIST, data, "POST", true);

// add internal  notes
export const addInternalNotes = (data) =>
  apiCall(url.ADD_INTERNAL_NOTES, data, "POST", true);

// Check mobile number exist
export const checkMobileExist = (data) =>
  apiCall(url.GET_CUSTOMER_LIST, data, "POST", true);

// Check phone number exist
export const checkPhoneExist = (data) =>
  apiCall(url.GET_USER_PROFILE, data, "POST", true);

// delete internal notes
export const deleteInternalNotes = (data) =>
  apiCall(url.DELETE_INTERNAL_NOTES, data, "POST", true);

/******************************************************************
 ░░░░ Setting / Default / Labour Charge Start ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/

// get labour charge list
export const getLabourChargeList = (data) =>
  apiCall(url.GET_LABOUR_CHARGE, data, "POST", true);

// Add labour charge
export const saveLabourChargeDetails = (data) =>
  apiCall(url.SAVE_LABOUR_CHARGE, data, "POST", true);

// Update labour charge
export const updateLabourChargeDetail = (data) =>
  apiCall(url.UPDATE_LABOUR_CHARGE, data, "POST", true);

// delete labour charge
export const deleteLabourCharge = (data) =>
  apiCall(url.DELETE_LABOUR_CHARGE, data, "POST", true);

// update all labour charge default as 0
export const updateDefaultLabourCharge = (data) =>
  apiCall(url.UPDATE_DEFAULT_LABOUR_CHARGE, data, "POST", true);

/******************************************************************
░░░░ Setting / Default / Labour Charge END ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/

/******************************************************************
 ░░░░ Setting / Default / Asset Type Start ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/

// get labour charge list
export const getCustomVehicleLists = (data) =>
  apiCall(url.GET_CUSTOM_VEHICLE, data, "POST", true);

// Add labour charge
export const saveCustomVehicleDetails = (data) =>
  apiCall(url.SAVE_CUSTOM_VEHICLE, data, "POST", true);

// Update labour charge
export const updateCustomVehicleDetail = (data) =>
  apiCall(url.UPDATE_CUSTOM_VEHICLE, data, "POST", true);

// delete labour charge
export const deleteCustomVehicle = (data) =>
  apiCall(url.DELETE_CUSTOM_VEHICLE, data, "POST", true);

/******************************************************************
░░░░ Setting / Default / Asset Type END ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/
// save internal notes attachments
export const saveInternalNotesAttachments = (data) =>
  apiCall(url.SAVE_INTERNAL_NOTES_ATTACHMENTS, data, "POST", true);
// search JobSchedules
export const jobSchedulesSearch = (data) =>
  apiCall(url.JOBSCHEDULESSEARCH, data, "POST", true);

// check referal code vaild or not
export const applyCode = (data) => apiCall(url.APPLY_CODE, data, "POST", true);

/***********************
 ░░░░ Settings: Security Group START  ░░░░
************************/
// GET Security group
export const getSecruityGroup = (data) =>
  apiCall(url.GETSECURITYGROUP, data, "POST", true);

// ADD security group
export const addSecruityGroup = (data) =>
  apiCall(url.ADDSECURITYGROUP, data, "POST", true);
// update security group
export const updateSecruityGroup = (data) =>
  apiCall(url.UPDATESECURITYGROUP, data, "POST", true);

/***********************
 ░░░░ Settings: Security Group END  ░░░░
************************/

//get Quote internal notes
export const getQuoteInternalNotes = (data) =>
  apiCall(url.GET_QUOTE_INTERNAL_NOTES, data, "POST", true);

// add Quote internal  notes
export const addQuoteInternalNotes = (data) =>
  apiCall(url.ADD_QUOTE_INTERNAL_NOTES, data, "POST", true);

// delete Quote internal notes
export const deleteQuoteInternalNotes = (data) =>
  apiCall(url.DELETE_QUOTE_INTERNAL_NOTES, data, "POST", true);

// save Quote internal notes attachments
export const saveQuoteInternalNotesAttachments = (data) =>
  apiCall(url.SAVE_QUOTE_INTERNAL_NOTES_ATTACHMENTS, data, "POST", true);

// search Quotes
export const quoteSearch = (data) =>
  apiCall(url.QUOTE_SEARCH, data, "POST", true);

// create Quotes
export const addQuote = (data) => apiCall(url.ADD_QUOTES, data, "POST", true);

// update Quotes
export const updateQuote = (data) =>
  apiCall(url.UPDATE_QUOTES, data, "POST", true);

// get Quote parts
export const getQuotePartList = (data) =>
  apiCall(url.GET_QUOTE_PART_LIST, data, "POST", true);

//save Quote parts
export const saveQuotePart = (data) =>
  apiCall(url.SAVE_QUOTE_PART, data, "POST", true);

//update Quote parts
export const updateQuotePart = (data) =>
  apiCall(url.UPDATE_QUOTE_PART, data, "POST", true);

//delete Quote parts
export const deleteQuotePart = (data) =>
  apiCall(url.DELETE_QUOTE_PART, data, "POST", true);

// save Quote parts many
export const editQuotePartMany = (data) =>
  apiCall(url.EDIT_QUOTE_PART_MANY, data, "POST", true);

// get Quote kit part list
export const getQuoteKitPart = (data) =>
  apiCall(url.GET_QUOTE_KIT_PART, data, "POST", true);

// get Quote kit part list
export const getQuoteKitLabour = (data) =>
  apiCall(url.GET_QUOTE_KIT_LABOUR, data, "POST", true);

export const saveQuoteKitPart = (data) =>
  apiCall(url.SAVE_QUOTE_KIT_PART, data, "POST", true);

export const deleteQuoteKitPart = (data) =>
  apiCall(url.DELETE_QUOTE_KIT_PART, data, "POST", true);

// save Quote kit parts many
export const editQuoteKitPartMany = (data) =>
  apiCall(url.EDIT_QUOTE_KIT_PART_MANY, data, "POST", true);

// save Quote kit parts many
export const editQuoteKitLabourMany = (data) =>
  apiCall(url.EDIT_QUOTE_KIT_LABOUR_MANY, data, "POST", true);

//COPY QUOTES
export const copyQuotes = (data) => apiCall(url.COPYQUOTES, data, "POST", true);

//delete QUOTES
export const deleteQuotes = (data) =>
  apiCall(url.DELETEQUOTES, data, "POST", true);

//send QUOTES mail
export const sendQuoteMail = (data) =>
  apiCall(url.SENDQUOTEMAIL, data, "POST", true);

//get invoices list
export const getInvoicesList = (data) =>
  apiCall(url.GET_INVOICES_LIST, data, "POST", true);

//get supplier statement
export const getSupplierStatement = (data) =>
  apiCall(url.GET_SUPPLIER_STATEMENT, data, "POST", true);

export const getSupplierListStatement = (data) =>
  apiCall(url.GET_SUPPLIER_LIST_STATEMENT, data, "POST", true);

export const searchInvoice = (data) =>
  apiCall(url.SEARCH_INVOICE, data, "POST", true);

export const searchReceipt = (data) =>
  apiCall(url.SEARCH_RECEIPT, data, "POST", true);

export const searchCashsaleReceipt = (data) =>
  apiCall(url.SEARCH_CASHSALE_RECEIPT, data, "POST", true);

export const searchCashsale = (data) =>
  apiCall(url.SEARCH_CASHSALE, data, "POST", true);

//update invoices list
export const updateInvoiceList = (data) =>
  apiCall(url.UPDATE_INVOICE_CREDIT, data, "POST", true);

export const getInvoicesPayments = (data) =>
  apiCall(url.GET_INVOICES_PAYMENT, data, "POST", true);

export const getInvoicesCredits = (data) =>
  apiCall(url.GET_INVOICE_CREDIT, data, "POST", true);

export const getInvoicesStatements = (data) =>
  apiCall(url.GET_INVOICES_STATEMENTS, data, "POST", true);

export const updateInvoiceStatements = (data) =>
  apiCall(url.UPDATE_INVOICE_STATEMENTS, data, "POST", true);


export const getInvoicesSalesList = (data) =>
  apiCall(url.GET_INVOICE_CASH_SALE_LIST, data, "POST", true);

export const deleteArchiveInvoices = (data) =>
  apiCall(url.DELETE_INVOICES, data, "POST", true);

export const deleteArchiveInvoicesSales = (data) =>
  apiCall(url.DELETE_INVOICES_SALES, data, "POST", true);

/////////get marketing list
export const getMarketingsList = (data) =>
  apiCall(url.GET_MARKETINGS_LIST, data, "POST", true);

export const getLiveMarketingsList = (data) =>
  apiCall(url.GET_LIVE_MARKETINGS_LIST, data, "POST", true);

export const getCompletedMarketingsList = (data) =>
  apiCall(url.GET_COMPLETED_MARKETINGS_LIST, data, "POST", true);

export const deleteArchiveMarketings = (data) =>
  apiCall(url.DELETE_MARKETINGS, data, "POST", true);

/////////get marketing list

///Approve inspection
export const setApproveManualInspection = (data) =>
  apiCall(url.APPROVE_MANUAL_INSPECTION, data, "POST", true);

//Add approved parts
export const addApprovedPartsInspection = (data) =>
  apiCall(url.ADD_APPROVED_PARTS_INSPECTION, data, "POST", true);

/// Resend inspection
export const resendInspection = (data) =>
  apiCall(url.RESEND_INSPECTION, data, "POST", true);

/// Inspection groups
export const getInspectionGroups = (data) =>
  apiCall(url.INSPECTION_GROUP, data, "POST", true);

export const addInpections = (data) =>
  apiCall(url.ADD_INSPECTION, data, "POST", true);
export const sendEmailInvoice = (data) =>
  apiCall(url.SEND_INVOICE_MAIL, data, "POST", true);

export const sendStatementEmail = (data) =>
  apiCall(url.SEND_STATEMENT_EMAIL, data, "POST", true);

export const sendStatementReport = (data) =>
  apiCall(url.SEND_STATEMENT_REPORT, data, "POST", true);

export const sendCashsaleEmailInvoice = (data) =>
  apiCall(url.SEND_CASHSALE_INVOICE_MAIL, data, "POST", true);

export const addInvoiceCreate = (data) =>
  apiCall(url.ADD_INVOICE_CREDIT, data, "POST", true);

export const addCashSaleCreate = (data) =>
  apiCall(url.CASH_SALE_CREDITS_SAVE, data, "POST", true);

export const updateInvoice = (data) =>
  apiCall(url.UPDATE_INVOICE, data, "POST", true);

export const deleteInvoicePayment = (data) =>
  apiCall(url.DELETE_INVOICE_PAYMENT, data, "POST", true);

export const deleteInvoiceCredit = (data) =>
  apiCall(url.DELETE_INVOICE_CREDIT, data, "POST", true);

export const deleteStatementInvoice = (data) =>
  apiCall(url.DELETE_INVOICE_STATEMENT, data, "POST", true);

/***********************
 ░░░░ Settings: Statuses  ░░░░
************************/

// GET workshop bays
export const getStatusesList = (data) =>
  apiCall(url.GET_STATUSES_LIST, data, "POST", true);

// ADD workshop bays
export const addStatus = (data) => apiCall(url.ADD_STATUS, data, "POST", true);

// EDIT workshop bays
export const updateStatus = (data) =>
  apiCall(url.UPDATE_STATUS, data, "POST", true);

// VIEW workshop bays
export const editStatus = (data) =>
  apiCall(url.GET_STATUSES_LIST, data, "POST", true);

// DELETE workshop bays
export const deleteStatus = (data) =>
  apiCall(url.DELETE_STATUS, data, "POST", true);

export const deleteManyStatus = (data) =>
  apiCall(url.DELETE_MANY_STATUS, data, "POST", true);

// GET job remiminder
export const getJobReminder = (data) =>
  apiCall(url.GET_JOB_REMINDER, data, "POST", true);


// ADD job remiminder
export const addJobReminder = (data) =>
  apiCall(url.ADD_JOB_REMINDER, data, "POST", true);

// update job remiminder
export const updateJobReminder = (data) =>
  apiCall(url.UPDATE_JOB_REMINDER, data, "POST", true);

// delete job remiminder
export const deleteJobReminder = (data) =>
  apiCall(url.DELETE_JOB_REMINDER, data, "POST", true);

// add attachment
export const addAttachment = (data) =>
  apiCall(url.ADD_ATTACHMENT, data, "POST", true);

// get attachment
export const getAttachment = (data) =>
  apiCall(url.GET_ATTACHMENT, data, "POST", true);

// copy attachment
export const copyAttachment = (data) =>
  apiCall(url.COPY_ATTACHMENT, data, "POST", true);

/***********************
 ░░░░ KIT PART START ░░░░
************************/
export const getKitPartList = (data) =>
  apiCall(url.GET_KIT_PART_LIST, data, "POST", true);


export const editDeleteKitPart = (data) =>
  apiCall(url.DELETE_KIT_PART, data, "POST", true);


/***********************
 ░░░░ KIT PART START ░░░░
************************/

/***********************
 ░░░░ Workshop Bays ░░░░
************************/

// GET workshop bays
export const getWorkshopBaysList = (data) =>
  apiCall(url.GET_WORKSHOP_BAYS_LIST, data, "POST", true);

// ADD workshop bays
export const addWorkshopBay = (data) =>
  apiCall(url.ADD_WORKSHOP_BAY, data, "POST", true);

// EDIT workshop bays
export const updateWorkshopBay = (data) =>
  apiCall(url.UPDATE_WORKSHOP_BAY, data, "POST", true);

// VIEW workshop bays
export const editWorkshopBay = (data) =>
  apiCall(url.VIEW_WORKSHOP_BAY, data, "POST", true);

// DELETE workshop bays
export const deleteWorkshopBay = (data) =>
  apiCall(url.DELETE_WORKSHOP_BAY, data, "POST", true);

// Get Subscription Plans Data
export const getSubscriptionPlans = (data) =>
  apiCall(url.GET_SUBSCRIPTION_PLANS, data, "POST", true);

export const doSubscriptionCheckout = (data) =>
  apiCall(url.SUBSCRIPTION_CHECKOUT, data, "POST", true);

export const saveCashSaleInvoice = (data) =>
  apiCall(url.SAVE_CASH_SALE_INVOICE, data, "POST", true);

export const saveCashSaleInvoicePart = (data) =>
  apiCall(url.SAVE_CASH_SALE_INVOICE_PARTS, data, "POST", true);

export const saveCashSaleInvoiceLabour = (data) =>
  apiCall(url.SAVE_CASH_SALE_INVOICE_LABOUR, data, "POST", true);

export const deleteCashSalePart = (data) =>
  apiCall(url.DELETE_CASH_SALE_PART, data, "POST", true);

export const getCashSalePayments = (data) =>
  apiCall(url.GET_CASH_SALE_PAYMENTS_CREDITS, data, "POST", true);

export const getCashSaleCredit = (data) =>
  apiCall(url.GET_CASH_SALE_CREDIT, data, "POST", true);

// Get Supplier Integration Data
export const getSupplierIntegration = (data) =>
  apiCall(url.GET_SUPPLIER_INTEGRATION, data, "POST", true);

// sync account data
export const getSyncAccount = (data) =>
  apiCall(url.GET_SYNC_ACCOUNT, data, "POST", true);

// Tax rates
export const getTaxRates = (data) =>
  apiCall(url.GET_TAX_RATES, data, "POST", true);

// payment processing fees
export const getPaymentProcessingFees = (data) =>
  apiCall(url.GET_PAYMENT_PROCESSING_FEES, data, "POST", true);

// update payment processing fees
export const updatePaymentProcessingFees = (data) =>
  apiCall(url.UPDATE_PAYMENT_PROCESSING_FEES, data, "POST", true);

export const getDigitalInspections = (data) =>
  apiCall(url.GET_DIGITAL_INSPECTIONS, data, "POST", true);

/******************************
 ░░░░ PURCHASE ORDER START ░░░░
*******************************/

// Get Purchase Order list by type
export const purchaseOrder = (data) =>
  apiCall(url.GET_PURCHASE_ORDER, data, "POST", true);

export const receiptPurchaseOrderList = (data) =>
  apiCall(url.GET_RECEIPT_PURCHASE_ORDER, data, "POST", true);

export const receiptSearchPurchaseOrderList = (data) =>
  apiCall(url.RECEIPTS_SEARCH_PURCHASE_ORDER_LIST, data, "POST", true);

export const receiptsPurchaseOrderDetail = (data) =>
  apiCall(
    url.GET_RECEIPT_PURCHASE_ORDER_DETAIL + "/" + data + ".json",
    data,
    "GET",
    true
  );

export const getPreviousReceipts = (data) =>
  apiCall(url.GET_RECEIPT_PURCHASE_ORDER, data, "POST", true);

export const creditPurchaseOrderList = (data) =>
  apiCall(url.GET_CREDIT_PURCHASE_ORDER, data, "POST", true);

export const raisedSearchPurchaseOrderList = (data) =>
  apiCall(url.RAISED_SEARCH_PURCHASE_ORDER_LIST, data, "POST", true);

export const completedSearchPurchaseOrderList = (data) =>
  apiCall(url.COMPLETED_SEARCH_PURCHASE_ORDER_LIST, data, "POST", true);

export const creditSearchPurchaseOrderList = (data) =>
  apiCall(url.CREDIT_SEARCH_PURCHASE_ORDER_LIST, data, "POST", true);

export const getCreditOrderView = (data) =>
  apiCall(url.CREDIT_ORDER_VIEW + "/" + data + ".json", data, "GET", true);

export const getPurchaseOrderView = (data) =>
  apiCall(
    url.GET_PURCHASE_ORDER_VIEW + "/" + data + ".json",
    data,
    "GET",
    true
  );

export const fetchPOJObWiseList = (data) =>
  apiCall(url.FETCH_PO_JOB_WISE_LIST, data, "POST", true);

export const savePOJobWise = (data) =>
  apiCall(url.SAVE_PO_JOB_WISE, data, "POST", true);

export const saveAndSendPOJobWise = (data) =>
  apiCall(url.SAVE_AND_SEND_PO_JOB_WISE, data, "POST", true);

export const createBursonPO = (data) =>
  apiCall(url.CREATE_BURSON_PO, data, "POST", true);

export const createBursonPONZ = (data) =>
  apiCall(url.CREATE_BURSON_PO_NZ, data, "POST", true);

export const editPurchaseOrder = (data) =>
  apiCall(url.EDIT_PURCHASE_ORDER, data, "POST", true);

export const createPurchaseOrderReceipts = (data) =>
  apiCall(url.CREATE_PURCHASE_ORDER_RECEIPTS, data, "POST", true);

export const deletePurchaseOrder = (data) =>
  apiCall(url.DELETE_PURCHASE_ORDER, data, "POST", true);

export const deletePurchaseOrderPart = (data) =>
  apiCall(url.DELETE_PURCHASE_ORDER_PART, data, "POST", true);

export const deleteReceiptPurchaseOrder = (data) =>
  apiCall(url.DELETE_RECEIPTS_PURCHASE_ORDER, data, "POST", true);

export const deleteCreditPurchaseOrder = (data) =>
  apiCall(url.DELETE_CREDIT_PURCHASE_ORDER, data, "POST", true);

export const createPurchaseOrderCredit = (data) =>
  apiCall(url.CREATE_PURCHASE_ORDER_CREDIT, data, "POST", true);

export const createReceiptCreditNote = (data) =>
  apiCall(url.CREATE_RECEIPT_CREDIT_NOTE, data, "POST", true);

export const createStatementPayment = (data) =>
  apiCall(url.START_STATEMENT_PAYMENT, data, "POST", true);

export const sendPurchaseOrderEmail = (data) =>
  apiCall(url.SEND_PURCHASE_ORDER_EMAIL, data, "POST", true);

/****************************
 ░░░░ PURCHASE ORDER END ░░░░
*****************************/

/****************************
 ░░░░ STORAGE DEVICE END ░░░░
*****************************/

export const getStorageDevices = (data) =>
  apiCall(url.GET_STORAGE_DEVICES_LIST, data, "POST", true);

/****************************
 ░░░░ STORAGE DEVICE END ░░░░
*****************************/

/*****************************
 ░░░░ Inspection Settings ░░░░
******************************/
export const getTemplateListAPI = (data) =>
  apiCall(url.GET_TEMPLATE_LIST, data, "post", true);

export const deleteTemplate = (data) =>
  apiCall(url.DELETE_TEMPLATE, data, "post", true);

export const getCategoriesListAPI = (data) =>
  apiCall(url.GET_CATEGORIES_LIST, data, "post", true);

export const getSectionListAPI = (data) =>
  apiCall(url.GET_SECTION_LIST, data, "post", true);

export const addTemplateCategory = (data) =>
  apiCall(url.ADD_TEMPLATE_CATEGORY, data, "post", true);

export const addTemplateSection = (data) =>
  apiCall(url.ADD_TEMPLATE_SECTION, data, "post", true);

export const addTemplate = (data) =>
  apiCall(url.ADD_TEMPLATE, data, "post", true);

export const updateTemplate = (data) =>
  apiCall(url.UPDATE_TEMPLATE, data, "post", true);

export const updateCategory = (data) =>
  apiCall(url.UPDATE_CATEGORY, data, "post", true);

export const updateSection = (data) =>
  apiCall(url.UPDATE_SECTION, data, "post", true);

export const editCategory = (data) =>
  apiCall(url.EDIT_CATEGORY + data + ".json", data, "GET", true);

export const editSection = (data) =>
  apiCall(url.EDIT_SECTION + data + ".json", data, "GET", true);

export const deleteMultipleCategories = (data) =>
  apiCall(url.DELETE_MULTIPLE_CATEGORIES, data, "post", true);

export const deleteMultipleSections = (data) =>
  apiCall(url.DELETE_MULTIPLE_SECTIONS, data, "post", true);
export const deleteCategory = (data) =>
  apiCall(url.DELETE_CATEGORY, data, "post", true);

export const deleteSection = (data) =>
  apiCall(url.DELETE_SECTION, data, "post", true);

export const copyTemplate = (data) =>
  apiCall(url.COPY_TEMPLATE, data, "post", true);

// GET service reminder list
export const getServiceReminderList = (data) =>
  apiCall(url.GET_SERVICEREMINDER_LIST, data, "POST", true);

// GET service reminder History
export const getServiceReminderHistory = (data) =>
  apiCall(url.GET_SERVICEREMINDER_HISTORY, data, "POST", true);

// send reminder
export const sendReminder = (data) =>
  apiCall(url.SEND_REMINDER, data, "POST", true);

export const getTimeSlotsApi = (data) =>
  apiCall(url.GET_TIME_SLOTS, data, "POST", true);

//GET ONLINE LEADS LIST
export const getOnlineJobs = (data) =>
  apiCall(url.DISPLAY_ONLINE_JOB, data, "POST", true);

export const addOnlineJobs = (data) =>
  apiCall(url.ADD_ONLINE_JOB, data, "POST", true);

export const sendLeadNotification = (data) =>
  apiCall(url.SEND_LEAD_NOTIFICATION, data, "POST", true);

export const sendLeadNotificationWorkshop = (data) =>
  apiCall(url.SEND_LEAD_NOTIFICATION_WORKSHOP, data, "POST", true);

export const updateOnlineJob = (data) =>
  apiCall(url.EDIT_ONLINE_JOB, data, "POST", true);

export const getInternalNoteForLead = (data) =>
  apiCall(url.GET_INTERNAL_NOTE_FOR_LEADS, data, "POST", true);

export const addInternalNoteToLead = (data) =>
  apiCall(url.ADD_INTERNAL_NOTE_FOR_LEAD, data, "POST", true);

export const deleteInternalNoteToLead = (data) =>
  apiCall(url.DELETE_INTERNAL_NOTE_FOR_LEAD, data, "POST", true);

export const addInternalNoteAttachmentToLead = (data) =>
  apiCall(url.ADD_INTERNAL_NOTE_ATTACHMENT_FOR_LEAD, data, "POST", true);

/****************************
 ░░░░ Settings: Payment  ░░░░
*****************************/

// Get Stripe Payment Integration Data
export const getStripePaymentIntegration = (data) =>
  apiCall(url.GET_STRIPE_PAYMENT_INTEGRATION, data, "POST", true);

// SAVE Stripe Payment Integration Data
export const saveStripePaymentIntegration = (data) =>
  apiCall(url.SAVE_STRIPE_PAYMENT_INTEGRATION, data, "POST", true);

// Get Gopay Payment Integration Data
export const getGopayPaymentIntegration = (data) =>
  apiCall(url.GET_GOPAY_PAYMENT_INTEGRATION, data, "POST", true);

// SAVE Gopay Payment Integration Data
export const saveGopayPaymentIntegration = (data) =>
  apiCall(url.SAVE_GOPAY_PAYMENT_INTEGRATION, data, "POST", true);

// UPDATE Supplier Integration Data
export const updateSupplierIntegration = (data) =>
  apiCall(url.UPDATE_SUPPLIER_INTEGRATION, data, "POST", true);

// SAVE Supplier Integration Data
export const saveSupplierIntegration = (data) =>
  apiCall(url.SAVE_SUPPLIER_INTEGRATION, data, "POST", true);

/*****************************
 ░░░░ Canned Responses Settings ░░░░
 ******************************/

export const getCannedResponseList = (data) =>
  apiCall(url.GET_JOBCANNED_LIST, data, "post", true);

export const deleteCannedResponse = (data) =>
  apiCall(url.DELETE_SCRIPT, data, "post", true);

export const addScript = (data) => apiCall(url.ADD_SCRIPT, data, "post", true);

export const editScript = (data) =>
  apiCall(url.EDIT_SCRIPT, data, "post", true);

export const copyScript = (uri) => get(uri, []);

// Get payment terms
export const getPaymentTerms = (data) =>
  apiCall(url.GET_PAYMENT_TERMS, data, "POST", true);

// Delete Payment Term
export const deletePaymentTerm = (data) =>
  apiCall(url.DELETE_PAYMENT_TERM, data, "POST", true);

// Edit payment term
export const editPaymentTerm = (data) =>
  apiCall(url.EDIT_PAYMENT_TERM + "/" + data + ".json", data, "GET", true);

// Add Payment term
export const addPaymentTerm = (data) =>
  apiCall(url.ADD_PAYMENT_TERM, data, "POST", true);

export const updatePaymentTerm = (data) =>
  apiCall(url.UPDATE_PAYMENT_TERM, data, "POST", true);

// edit Message Template
export const editMessageTemplate = (data) =>
  apiCall(
    url.EDIT_MESSAGE_TEMPLATE_DETAILS + data + ".json",
    data,
    "GET",
    true
  );

// Add - Update Message Template
export const addUpdateMessageTemplate = (data) =>
  apiCall(url.ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS, data, "POST", true);

// Delete Message Template
export const deleteMessageTemplate = (data) =>
  apiCall(url.DELETE_MESSAGE_TEMPLATE_DETAILS, data, "POST", true);

/****************************
 ░░░░ 3rd Party Company ░░░░
*****************************/

/* Delete Third Party Company Data */
export const deleteThirdPartyCompany = (data) =>
  apiCall(url.DELETE_THIRD_PARTY_COMPANY, data, "POST", true);

/* Edit Third Party Company Data */
export const editThirdPartyCompany = (data) =>
  apiCall(url.EDIT_THIRD_PARTY_COMPANY, data, "POST", true);

/* Update Third Party Company Data */
export const updateThirdPartyCompanyDetails = (data) =>
  apiCall(url.UPDATE_THIRD_PARTY_COMPANY_DETAILS, data, "POST", true);

/* Add Third Party Company Data */
export const addThirdPartyCompanyDetails = (data) =>
  apiCall(url.ADD_THIRD_PARTY_COMPANY_DETAILS, data, "POST", true);

// get Vehicle detail
export const getVehicleDetail = (data) =>
  apiCall(
    url.VEHICLEDETAIL + "/" + data.id + "/" + data.workshop_id + ".json",
    data,
    "GET",
    true
  );

// get Vehicle legacy history
export const getVehicleLegacyHistory = (data) =>
  apiCall(url.VEHICLELEGACYHISTORY, data, "POST", true);

// Delete Vehicle
export const deleteVehicle = (data) =>
  apiCall(url.DELETEVEHICLE, data, "POST", true);

// Delete multiple Vehicle
export const deleteMultiVehicle = (data) =>
  apiCall(url.DELETEMULTIVEHICLE, data, "POST", true);

// Search Vehicle
export const searchVehicle = (data) =>
  apiCall(url.SEARCHVEHICLE, data, "POST", true);

// Delete Customer
export const deleteCustomer = (data) =>
  apiCall(url.DELETE_CUSTOMER, data, "POST", true);

// Delete Supplier
export const deleteSupplier = (data) =>
  apiCall(url.DELETE_SUPPLIERS, data, "POST", true);

// Delete Employee
export const deleteEmployee = (data) =>
  apiCall(url.DELETE_EMPLOYEE, data, "POST", true);

// Delete HQ Employee
export const deleteHQEmployee = (data) =>
  apiCall(url.DELETE_HQ_EMPLOYEE, data, "POST", true);

// get Supplier branch
export const getSuppilerBranch = (data) =>
  apiCall(url.GETSUPPLIERBRANCH, data, "POST", true);

// Delete Supplier branch
export const deleteSupplierBranch = (data) =>
  apiCall(url.DELETE_SUPPLIERS_BRANCH, data, "POST", true);

//get cuetomer details
export const getCustomerDetails = (data) =>
  apiCall(url.GET_CUSTOMER_DETAILS + data + ".json", data, "GET", true);

// Delete vehicle
// export const deleteVehicle = (data) =>
// apiCall(url.DELETE_VEHICLE, data, "POST", true);

// Get Customer notes
export const getCustomerNotes = (data) =>
  apiCall(url.GET_CUSTOMER_NOTES, data, "POST", true);

// delete Customer notes
export const deleteCustomerNotes = (data) =>
  apiCall(url.DELETE_CUSTOMER_NOTES, data, "POST", true);

// add Customer notes
export const addCustomerNotes = (data) =>
  apiCall(url.ADD_CUSTOMER_NOTES, data, "POST", true);

// get Customer attachment
export const getCustomerAttachment = (data) =>
  apiCall(url.GET_CUSTOMER_ATTACHMENT, data, "POST", true);

//get supplier
export const getSupplier = (data) =>
  apiCall(url.GET_SUPPLIERS + data + ".json", data, "GET", true);

//get HQ supplier
export const getHQSingleSupplier = (data) =>
  apiCall(url.HQ_GET_SUPPLIERS + data + ".json", data, "GET", true);

// update supplier
export const updateSupplier = (data) =>
  apiCall(url.UPDATE_SUPPLIERS, data, "POST", true);

// update HQ supplier
export const updateHQSupplier = (data) =>
  apiCall(url.HQ_UPDATE_SUPPLIERS, data, "POST", true);

// Add supplier Branch
export const addSupplierBranch = (data) =>
  apiCall(url.ADD_SUPPLIER_BRANCHE, data, "POST", true);

// get Employee Roles
export const getEmployeeRoles = (data) =>
  apiCall(url.GET_EMPLOYEE_ROLES, data, "POST", true);

// get WorkShop Subscription
export const getWorkShopSubscription = (data) =>
  apiCall(url.GET_WROKSHOP_SUBSCRIPTION, data, "POST", true);

// view Subscription Plan
export const viewSubscriptionPlan = (data) =>
  apiCall(url.VIEW_SUBSCRIPTION_PLAN + data + ".json", data, "GET", true);

// get Employee Notes
export const getEmployeeNotes = (data) =>
  apiCall(url.GET_EMPLOYEE_NOTES, data, "POST", true);

// add Employee Notes
export const addEmployeeNotes = (data) =>
  apiCall(url.ADD_EMPLOYEE_NOTES, data, "POST", true);

// delete Employee Notes
export const deleteEmployeeNotes = (data) =>
  apiCall(url.DELETE_EMPLOYEE_NOTES, data, "POST", true);

// delete Employee attachment
export const deleteEmployeeAttachment = (data) =>
  apiCall(url.DELETE_EMPLOYEE_ATTACHMENT, data, "POST", true);

// add Employee attachment
export const addEmployeeAttachment = (data) =>
  apiCall(url.ADD_EMPLOYEE_ATTACHMENT, data, "POST", true);

// send user credentials
export const sendUserCredentials = (data) =>
  apiCall(url.SEND_USER_CREDENTIALS, data, "POST", true);

// edit Supplier Branch
export const editSupplierBranch = (data) =>
  apiCall(url.EDIT_SUPPLIER_BRANCH, data, "POST", true);

// view Supplier Branch
export const viewSupplierBranch = (data) =>
  apiCall(url.VIEW_SUPPLIER_BRANCH + data + ".json", data, "GET", true);

// edit Supplier Branch
export const addCustomerAttachment = (data) =>
  apiCall(url.ADD_CUSTOMER_ATTACHMENT, data, "POST", true);

// DELETE Supplier Branch
export const deleteCustomerAttachment = (data) =>
  apiCall(url.DELETE_CUSTOMER_ATTACHMENT, data, "POST", true);

// delete many customer
export const deleteManyCustomer = (data) =>
  apiCall(url.DELETE_MANY_CUSTOMER, data, "POST", true);

// delete many Supplier
export const deleteManySupplier = (data) =>
  apiCall(url.DELETE_MANY_SUPPLIER, data, "POST", true);

// delete many Supplier
export const deleteManyEmployee = (data) =>
  apiCall(url.DELETE_MANY_EMPLOYEE, data, "POST", true);

// customer Search
export const customerSearch = (data) =>
  apiCall(url.CUSTOMER_SEARCH, data, "POST", true);

// supplier Search
export const supplierSearch = (data) =>
  apiCall(url.SUPPLIER_SEARCH, data, "POST", true);

// send customer credential
export const sendCustomerCredential = (data) =>
  apiCall(url.SEND_CUSTOMER_CREDENTIALS, data, "POST", true);

// edit customer login access
export const editCustomerLoginAccess = (data) =>
  apiCall(url.EDIT_CUSTOMER_LOGIN_ACCESS, data, "POST", true);

// Edit (View) Storage Device
export const editStorageDevice = (data) =>
  apiCall(url.EDIT_STORAGE_DEVICE + data + ".json", data, "GET", true);

// Add Storage Device
export const addStorageDevice = (data) =>
  apiCall(url.ADD_STORAGE_DEVICE, data, "POST", true);

// Update Storage Device
export const updateStorageDevice = (data) =>
  apiCall(url.UPDATE_STORAGE_DEVICE, data, "POST", true);

// Delete Storage Device
export const deleteStorageDevice = (data) =>
  apiCall(url.DELETE_STORAGE_DEVICE, data, "POST", true);

// add import utility
export const addImportUtility = (data) =>
  apiCall(url.ADD_IMPORT_UTILITY, data, "POST", true);

/*****************************
 ░░░░░░░░ Account Sync ░░░░░░░░
******************************/

export const connectQuickbook = (data) =>
  apiCall(url.CONNECT_QUICKBOOK, data, "POST", true);

export const disconnectQuickbook = (data) =>
  apiCall(url.DISCONNECT_QUICKBOOK, data, "POST", true);

export const disconnectMyob = (data) =>
  apiCall(url.UPDATE_COMPANY_DETAIL, data, "POST", true);

export const updateWorkshopDetail = (data) =>
  apiCall(url.UPDATE_WORKSHOP_DETAIL, data, "POST", true);

export const getSyncAccountData = (data) =>
  apiCall(url.GET_SYNC_ACCOUNT_DATA, data, "POST", true);

export const getSyncTaxData = (data) =>
  apiCall(url.GET_SYNC_TAX_DATA, data, "POST", true);

export const updateSyncAccountData = (data) =>
  apiCall(url.UPDATE_SYNC_ACCOUNT_DATA, data, "POST", true);

export const updateSyncTaxData = (data) =>
  apiCall(url.UPDATE_SYNC_TAX_DATA, data, "POST", true);

export const getUsers = (data) => apiCall(url.GET_USERS, data, "POST", true);

export const syncTransfer = (data) =>
  apiCall(url.SYNC_TRANSFER, data, "POST", true);

export const cronSyncTransfer = (data) =>
  apiCall(url.CRON_SYNC_TRANSFER, data, "POST", true);

export const getSyncData = (data) =>
  apiCall(url.GET_SYNC_DATA, data, "POST", true);

export const updateManyWorkshopTaxDetails = (data) =>
  apiCall(url.UPDATE_MANY_WORKSHOP_TAX_DETAILS, data, "POST", true);

export const updateManyWorkshopDepartmentDetails = (data) =>
  apiCall(url.UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS, data, "POST", true);

export const deleteSyncAccounts = (data) =>
  apiCall(url.DELETE_SYNC_ACCOUNTS, data, "POST", true);

export const deleteSyncTax = (data) =>
  apiCall(url.DELETE_SYNC_TAX, data, "POST", true);

export const connectXero = (data) =>
  apiCall(url.CONNECT_XERO, data, "POST", true);

export const disconnectXero = (data) =>
  apiCall(url.DISCONNECT_XERO, data, "POST", true);

export const importMyob = (data) =>
  apiCall(url.IMPORT_MYOB, data, "POST", true);
export const sendVerificationSMS = (data) =>
  apiCall(url.INSPECTION_SEND_VERIFICATION_MESSAGE, data, "POST", true);

export const getInspectionAttachment = (data) =>
  apiCall(url.INSPECTION_ATTACHMENT, data, "POST", true);

export const deleteInspectionQuestion = (data) =>
  apiCall(url.DELETE_INSPECTION_QUESTION, data, "POST", true);

// Stock List
export const getStockList = (data) =>
  apiCall(url.GET_INVENTORY_STOCK_LIST, data, "post", true);

// save Stock List
export const saveStock = (data) => apiCall(url.SAVE_STOCK, data, "post", true);

// update Stock List
export const updatePartData = (data) =>
  apiCall(url.UPDATE_PART_DATA, data, "post", true);

//delete stock
export const deleteStockData = (data) =>
  apiCall(url.DELETE_STOCK_DATA, data, "post", true);

// Stock List
export const getStockTakeList = (data) =>
  apiCall(url.GET_INVENTORY_STOCK_TAKE_LIST, data, "post", true);

export const editStockTakeDetails = (data) =>
  apiCall(url.EDIT_STOCK_TAKE_DETAILS + data + ".json", data, "GET", true);

//view storage device details
export const viewStorageDeviceDetails = (data) =>
  apiCall(url.VIEW_STORAGE_DEVICE_DETAILS + data + ".json", data, "GET", true);

// save Stock Take
export const saveStockTakeData = (data) =>
  apiCall(url.SAVE_STOCK_TAKE, data, "post", true);

// update parts QTY
export const updatePartsQty = (data) =>
  apiCall(url.UPDATE_PARTS_QTY, data, "post", true);

// edit subscription
export const editSubscription = (data) =>
  apiCall(url.EDIT_SUBSCRIPTION, data, "POST", true);

// add capricorn subscription
export const capricornSubscription = (data) =>
  apiCall(url.CAPRICORN_SUBSCRIPTION, data, "POST", true);

// edit capricorn subscription
export const editcapricornSubscription = (data) =>
  apiCall(url.EDIT_CAPRICORN_SUBSCRIPTION, data, "POST", true);

/****************************
 ░░░░ REPORTS START ░░░░
*****************************/
export const jobReportTableData = (data) =>
  apiCall(url.JOB_REPORT_TABLE, data, "POST", true);

export const jobReportChartData = (data) =>
  apiCall(url.JOB_REPORT_CHART, data, "POST", true);

export const jobReportStatsData = (data) =>
  apiCall(url.JOB_STATS_CHART, data, "POST", true);

export const quoteReportTableData = (data) =>
  apiCall(url.QUOTE_REPORT_TABLE, data, "POST", true);

export const quoteReportConversion = (data) =>
  apiCall(url.QUOTE_COVERSION_DATA, data, "POST", true);

export const quoteReportCategory = (data) =>
  apiCall(url.QUOTE_BY_CATEGORY, data, "POST", true);

export const quoteReportChartData = (data) =>
  apiCall(url.QUOTE_COVERSION_DATA, data, "POST", true);

export const quoteReportStatsData = (data) =>
  apiCall(url.QUOTE_BY_CATEGORY, data, "POST", true);

export const makeTypeCustomerReport = (data) =>
  apiCall(url.MAKE_TYPE_CUSTOMER_REPORT, data, "POST", true);

export const jobTypeCustomerReport = (data) =>
  apiCall(url.JOB_TYPE_CUSTOMER_REPORT, data, "POST", true);

export const customerRevenueReport = (data) =>
  apiCall(url.CUSTOMER_REVENUE_REPORT, data, "POST", true);

export const getLabourHoursData = (data) =>
  apiCall(url.LABOUR_HOURS_DATA, data, "POST", true);

export const getEffectiveLabourData = (data) =>
  apiCall(url.EFFECTIVE_LABOUR_DATA, data, "POST", true);

export const getMaterialPurchaseOrderData = (data) =>
  apiCall(url.MATERIAL_PURCHASE_ORDER, data, "POST", true);

export const materialPurchaseOrderChartData = (data) =>
  apiCall(url.MATERIAL_PURCHASE_ORDER_CHART, data, "POST", true);

export const getInventoryStockData = (data) =>
  apiCall(url.INVENTORY_STOCK_DATA, data, "POST", true);

export const getInventoryStockTakeData = (data) =>
  apiCall(url.INVENTORY_STOCK_TAKE_DATA, data, "POST", true);
/****************************
 ░░░░ REPORTS START ░░░░
*****************************/

// workshop tax get
export const getWorkShopTaxRate = (data) =>
  apiCall(url.GET_WORKSHOP_TAX, data, "POST", true);

// workshop tax update
export const updateWorkShopTaxRate = (data) =>
  apiCall(url.WORKSHOP_TAX_UPDATE, data, "POST", true);

// workshop tax update
export const workshopOtherDetails = (data) =>
  apiCall(url.ADD_WORKSHOP_OTHER_DETAILS, data, "POST", true);

export const addManyDepartmentAccount = (data) =>
  apiCall(url.ADD_MANY_DEPARTMENT_ACCOUNT, data, "POST", true);

// Get Workshop subscription history list
export const getSubscriptionHistoryList = (data) =>
  apiCall(url.GET_SUBSCRIPTION_HISTORY_LIST, data, "POST", true);

// Get Workshop subscription Deatil history list
export const getSubscriptionHistoryDetailsList = (data) =>
  apiCall(url.GET_SUBSCRIPTION_HISTORY_DETAIL_LIST, data, "POST", true);

export const sendAwsEmailVerification = (data) =>
  apiCall(url.SEND_AWS_EMAIL_VERIFICATION, data, "POST", true);

export const addNotificationToken = (data) =>
  apiCall(url.ADD_NOTIFICATION_TOKEN, data, "POST", true);

export const deleteNotificationToken = (data) =>
  apiCall(url.DELETE_NOTIFICATION_TOKEN, data, "POST", true);

export const getInspectionQueAns = (data) =>
  apiCall(url.GET_INSPECTION_QUE_ANS, data, "POST", true);

// delete leads
export const deleteLeads = (data) =>
  apiCall(url.DELETE_LEADS, data, "POST", true);

// addTags
export const addTags = (data) => apiCall(url.Add_tags, data, "POST", true);

// bays job count
export const getBaysJobCount = (data) => apiCall(url.GET_BAYS_JOB_COUNT, data, "POST", true);

// Update many jobs
export const updateManyJob = (data) => apiCall(url.UPDATE_MANY_JOBS, data, "POST", true);


/***********************
 ░░░░ HQ Backend Helpers  ░░░░
************************/
export const getHQWorkshopList = (data) => apiCall(url.GET_HQ_WORKSHOP_LIST, data, "POST", true)


/***********************
 ░░░░ NEW ZEALAND PARTS  ░░░░
************************/

export const nzAddBursonPart = (data) => apiCall(url.NZ_ADD_BURSON_PART, data, "POST", true)

/******************************************
 ░░░░ EMPLOYEE CLOCKING HISTORY START ░░░░
*******************************************/
export const getEmployeeClockingHistory = (data) => post(url.GET_EMPLOYEE_CLOCKING_HISTORY, data);

export const getEmployeeWiseClockingHistory = (data) => post(url.GET_EMPLOYEE_WISE_CLOCKING_HISTORY, data);

export const addEmployeeClockingHistory = (data) => post(url.ADD_EMPLOYEE_CLOCKING_HISTORY, data);

export const updateEmployeeClockingHistory = (data) => post(url.UPDATE_EMPLOYEE_CLOCKING_HISTORY, data);

export const dailyClockingReport = (data) => post(url.DAILY_CLOCKING_REPORT, data);

/******************************************
 ░░░░ EMPLOYEE CLOCKING HISTORY END ░░░░
*******************************************/


/******************************************
 ░░░░ JOB CLOCKING HISTORY START ░░░░
*******************************************/
export const getJobClockingHistory = (data) => post(url.GET_JOB_CLOCKING_HISTORY, data);

export const getJobWiseClockingHistory = (data) => post(url.GET_JOB_WISE_CLOCKING_HISTORY, data);

export const addJobClockingHistory = (data) => post(url.ADD_JOB_CLOCKING_HISTORY, data);

export const updateJobClockingHistory = (data) => post(url.UPDATE_JOB_CLOCKING_HISTORY, data);

export const updateJobLabourByClocking = (data) => post(url.UPDATE_JOB_LABOUR_BY_CLOCKING, data);

export const jobClockingReport = (data) => post(url.JOB_CLOCKING_REPORT, data);

export const checkJobSlot = (data) => post(url.CHECK_JOB_SLOT, data);

export const getJobClockingHistoryData = (data) =>
  apiCall(url.GET_JOB_CLOCKING_HISTORY_DATA, data, "POST", true);

export const closeAllClockingbyJobId = (data) =>
  apiCall(url.CLOSE_ALL_CLOCKING, data, "POST", true);

export const isClockingRunning = (data) =>
  apiCall(url.IS_CLOCKING_RUNNING, data, "POST", true);


/******************************************
 ░░░░ JOB CLOCKING HISTORY END ░░░░
*******************************************/

export const getEstimateTimeSlot = (data) =>
  apiCall(url.GET_ESTIMATE_TIME_SLOT, data, "POST", true);