import * as Actions from "./actionType";
const initialState = {
  timeSlots: {
    Morning: [],
    Afternoon: [],
    // Evening: [],
  },
};
export default function bookJobOnlineReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_WORKSHOP_DETAIL_ONLINE_JOB:
      return {
        ...state,
      };
    case Actions.GET_WORKSHOP_DETAIL_ONLINE_JOB_SUCCESS:
      return {
        ...state,
        workshopDetails: action.payload,
      };
    case Actions.GET_WORKSHOP_DETAIL_ONLINE_JOB_FAIL:
      return {
        ...state,
        error: "Something went wrong",
      };
    case Actions.GET_INITIAL_DATA:
      return {
        ...state,
        loading: true,
      };
    case Actions.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Actions.GET_INITIAL_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case Actions.GET_TIME_SLOTS:
      return {
        ...state,
        timeSlotLoading: true,
        timeSlots: {
          Morning: [],
          Afternoon: [],
          // Evening: [],
        },
      };
    case Actions.GET_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        timeSlotLoading: false,
        timeSlots: action.payload,
      };
    case Actions.GET_TIME_SLOTS_FAIL:
      return {
        ...state,
        timeSlotLoading: false,
        timeSlots: {
          Morning: [],
          Afternoon: [],
          // Evening: [],
        },
      };
    case Actions.CREATE_ONLINE_JOB:
      return {
        ...state,
        create: "started",
      };
    case Actions.CREATE_ONLINE_JOB_SUCCESS:
      return {
        ...state,
        create: "success",
      };
    case Actions.CREATE_ONLINE_JOB_FAIL:
      return {
        ...state,
        create: "fail",
      };
    default:
      return state;
  }
}
