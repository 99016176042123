import {
  RAISED_PURCHASE_ORDER,
  RAISED_PURCHASE_ORDER_SUCCESS,
  RAISED_PURCHASE_ORDER_FAIL,
  RAISED_PURCHASE_ORDER_COUNT,
  RAISED_PURCHASE_ORDER_COUNT_SUCCESS,
  RAISED_PURCHASE_ORDER_COUNT_FAIL,
  RAISED_SEARCH_PURCHASE_ORDER,
  RAISED_SEARCH_PURCHASE_ORDER_SUCCESS,
  RAISED_SEARCH_PURCHASE_ORDER_FAIL,

  COMPLETED_PURCHASE_ORDER,
  COMPLETED_PURCHASE_ORDER_SUCCESS,
  COMPLETED_PURCHASE_ORDER_FAIL,
  COMPLETED_PURCHASE_ORDER_COUNT,
  COMPLETED_PURCHASE_ORDER_COUNT_SUCCESS,
  COMPLETED_PURCHASE_ORDER_COUNT_FAIL,
  COMPLETED_SEARCH_PURCHASE_ORDER,
  COMPLETED_SEARCH_PURCHASE_ORDER_SUCCESS,
  COMPLETED_SEARCH_PURCHASE_ORDER_FAIL,

  RECEIPTS_PURCHASE_ORDER,
  RECEIPTS_PURCHASE_ORDER_SUCCESS,
  RECEIPTS_PURCHASE_ORDER_FAIL,
  RECEIPTS_PURCHASE_ORDER_COUNT,
  RECEIPTS_PURCHASE_ORDER_COUNT_SUCCESS,
  RECEIPTS_PURCHASE_ORDER_COUNT_FAIL,
  RECEIPTS_SEARCH_PURCHASE_ORDER,
  RECEIPTS_SEARCH_PURCHASE_ORDER_SUCCESS,
  RECEIPTS_SEARCH_PURCHASE_ORDER_FAIL,
  RECEIPTS_PURCHASE_ORDER_VIEW,
  RECEIPTS_PURCHASE_ORDER_VIEW_SUCCESS,
  RECEIPTS_PURCHASE_ORDER_VIEW_FAIL,
  RECEIPTS_PURCHASE_ORDER_CREATE,
  RECEIPTS_PURCHASE_ORDER_CREATE_SUCCESS,
  RECEIPTS_PURCHASE_ORDER_CREATE_FAIL,

  CREDIT_PURCHASE_ORDER,
  CREDIT_PURCHASE_ORDER_SUCCESS,
  CREDIT_PURCHASE_ORDER_FAIL,
  CREDIT_PURCHASE_ORDER_COUNT,
  CREDIT_PURCHASE_ORDER_COUNT_SUCCESS,
  CREDIT_PURCHASE_ORDER_COUNT_FAIL,
  CREDIT_SEARCH_PURCHASE_ORDER,
  CREDIT_SEARCH_PURCHASE_ORDER_SUCCESS,
  CREDIT_SEARCH_PURCHASE_ORDER_FAIL,
  CREDIT_PURCHASE_ORDER_VIEW,
  CREDIT_PURCHASE_ORDER_VIEW_SUCCESS,
  CREDIT_PURCHASE_ORDER_VIEW_FAIL,
  CREDIT_PURCHASE_ORDER_CREATE,
  CREDIT_PURCHASE_ORDER_CREATE_SUCCESS,
  CREDIT_PURCHASE_ORDER_CREATE_FAIL,
  CREATE_RECEIPT_CREDIT_NOTE,
  CREATE_RECEIPT_CREDIT_NOTE_SUCCESS,
  CREATE_RECEIPT_CREDIT_NOTE_FAIL,
  

  PURCHASE_ORDER_VIEW,
  PURCHASE_ORDER_VIEW_SUCCESS,
  PURCHASE_ORDER_VIEW_FAIL,

  PURCHASE_ORDER_DELETE_PART,
  PURCHASE_ORDER_DELETE_PART_SUCCESS,
  PURCHASE_ORDER_DELETE_PART_FAIL,

  FETCH_PO_JOB_WISE,
  FETCH_PO_JOB_WISE_SUCCESS,
  FETCH_PO_JOB_WISE_FAIL,

  PO_CREATE_JOB_WISE,
  PO_CREATE_JOB_WISE_SUCCESS,
  PO_CREATE_JOB_WISE_FAIL,

  EDIT_PURCHASE_ORDER,
  EDIT_PURCHASE_ORDER_SUCCESS,
  EDIT_PURCHASE_ORDER_FAIL,
  
  SEND_PURCHASE_ORDER_EMAIL,
  SEND_PURCHASE_ORDER_EMAIL_SUCCESS,
  SEND_PURCHASE_ORDER_EMAIL_FAIL,
  
} from "./actionTypes";

const initialState = {
  raisedList: [],
  raisedListCount: 0,
  raisedSearchList: [],
  
  completedList: [],
  completedListCount: 0,
  completedSearchList: [],
  receiptsList: [],
  receiptsListCount: 0,
  receiptsSearchList: [],
  receiptsDetail: [],

  creditList: [],
  creditListCount: 0,
  creditSearchList: [],
  creditDetail: [],

  purchaseView: [],
  deletePOpart: [],

  fetchPOJobData: [],

  createdPOData: [],
  updatePOata:[],
  
  createReceiptPOData: [],

  createCreditPOData: [],

  sendPOEmailData: [],
  
  error: {
    message: "",
  }
};

const PurchaseOrderReducer = (state = initialState, action) => {

  switch (action.type) {

    // Raised purchase order START

    case RAISED_PURCHASE_ORDER:
      state = {
        ...state,
        raisedListLoading: true,
      };
    break;

    case RAISED_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        raisedList: action.payload,
        raisedListLoading: false,
      };
    break;

    case RAISED_PURCHASE_ORDER_FAIL:
      state = {
        ...state,
        raisedList: action.payload,
        raisedListLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;

    case RAISED_PURCHASE_ORDER_COUNT:
      state = {
        ...state,
      };
    break;

    case RAISED_PURCHASE_ORDER_COUNT_SUCCESS:
      state = {
        ...state,
        raisedListCount: action.payload
      };
    break;

    case RAISED_PURCHASE_ORDER_COUNT_FAIL:
      state = {
        ...state,
        raisedListCount: 0,
        error: {
          message: action.payload,
        }
      };
    break;

    case RAISED_SEARCH_PURCHASE_ORDER:
      state = {
        ...state,
      };
    break;

    case RAISED_SEARCH_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        raisedSearchList: action.payload
      };
    break;

    case RAISED_SEARCH_PURCHASE_ORDER_FAIL:
      state = {
        ...state,
        raisedSearchList: action.payload,
        error: {
          message: action.payload,
        }
      };
    break;

    

    // Raised purchase order END


    // Complted purchase order START

    case COMPLETED_PURCHASE_ORDER:
      state = {
        ...state,
        completedListLoading: true,
      };
    break;

    case COMPLETED_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        completedList: action.payload,
        completedListLoading: false,
      };
    break;

    case COMPLETED_PURCHASE_ORDER_FAIL:
      state = {
        ...state,
        completedList: action.payload,
        completedListLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;


    case COMPLETED_PURCHASE_ORDER_COUNT:
      state = {
        ...state,
      };
    break;

    case COMPLETED_PURCHASE_ORDER_COUNT_SUCCESS:
      state = {
        ...state,
        completedListCount: action.payload
      };
    break;

    case COMPLETED_PURCHASE_ORDER_COUNT_FAIL:
      state = {
        ...state,
        completedListCount: 0,
        error: {
          message: action.payload,
        }
      };
    break;

    case COMPLETED_SEARCH_PURCHASE_ORDER:
      state = {
        ...state,
      };
    break;

    case COMPLETED_SEARCH_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        completedSearchList: action.payload
      };
    break;

    case COMPLETED_SEARCH_PURCHASE_ORDER_FAIL:
      state = {
        ...state,
        completedSearchList: action.payload,
        error: {
          message: action.payload,
        }
      };
    break;

   

    // Complted purchase order END


    // Receipt purchase order START

    case RECEIPTS_PURCHASE_ORDER:
      state = {
        ...state,
        receiptsListLoading: true,
      };
    break;

    case RECEIPTS_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        receiptsList: action.payload,
        receiptsListLoading: false,
      };
    break;

    case RECEIPTS_PURCHASE_ORDER_FAIL:
      state = {
        ...state,
        receiptsList: action.payload,
        receiptsListLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;


    case RECEIPTS_PURCHASE_ORDER_COUNT:
      state = {
        ...state,
      };
    break;

    case RECEIPTS_PURCHASE_ORDER_COUNT_SUCCESS:
      state = {
        ...state,
        receiptsListCount: action.payload
      };
    break;

    case RECEIPTS_PURCHASE_ORDER_COUNT_FAIL:
      state = {
        ...state,
        receiptsListCount: 0,
        error: {
          message: action.payload,
        }
      };
    break;


    case RECEIPTS_SEARCH_PURCHASE_ORDER:
      state = {
        ...state,
      };
    break;

    case RECEIPTS_SEARCH_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        receiptsSearchList: action.payload
      };
    break;

    case RECEIPTS_SEARCH_PURCHASE_ORDER_FAIL:
      state = {
        ...state,
        receiptsSearchList: action.payload,
        error: {
          message: action.payload,
        }
      };
    break;


    case RECEIPTS_PURCHASE_ORDER_VIEW:
      state = {
        ...state,
        creditViewLoading: true,
      };
    break;

    case RECEIPTS_PURCHASE_ORDER_VIEW_SUCCESS:
      state = {
        ...state,
        receiptsDetail: action.payload,
        creditViewLoading: false,
      };
    break;

    case RECEIPTS_PURCHASE_ORDER_VIEW_FAIL:
      state = {
        ...state,
        receiptsDetail: action.payload,
        creditViewLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;

    


    // Receipt purchase order END


    // Credit purchase order START


    case CREDIT_PURCHASE_ORDER:
      state = {
        ...state,
        creditListLoading: true,
      };
    break;

    case CREDIT_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        creditList: action.payload,
        creditListLoading: false,
      };
    break;

    case CREDIT_PURCHASE_ORDER_FAIL:
      state = {
        ...state,
        creditList: action.payload,
        creditListLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;


    case CREDIT_PURCHASE_ORDER_COUNT:
      state = {
        ...state,
      };
    break;

    case CREDIT_PURCHASE_ORDER_COUNT_SUCCESS:
      state = {
        ...state,
        creditListCount: action.payload
      };
    break;

    case CREDIT_PURCHASE_ORDER_COUNT_FAIL:
      state = {
        ...state,
        creditListCount: 0,
        error: {
          message: action.payload,
        }
      };
    break;


    case CREDIT_SEARCH_PURCHASE_ORDER:
      state = {
        ...state,
      };
    break;

    case CREDIT_SEARCH_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        creditSearchList: action.payload
      };
    break;

    case CREDIT_SEARCH_PURCHASE_ORDER_FAIL:
      state = {
        ...state,
        creditSearchList: action.payload,
        error: {
          message: action.payload,
        }
      };
    break;

    case CREDIT_PURCHASE_ORDER_VIEW:
      state = {
        ...state,
        creditLoading: true,
      };
    break;

    case CREDIT_PURCHASE_ORDER_VIEW_SUCCESS:
      state = {
        ...state,
        creditDetail: action.payload,
        creditLoading: false,
      };
    break;

    case CREDIT_PURCHASE_ORDER_VIEW_FAIL:
      state = {
        ...state,
        creditDetail: action.payload,
        creditLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;

    // Credit purchase order END

    

    // View purchase order START

    case PURCHASE_ORDER_VIEW:
      state = {
        ...state,
        orderViewLoading: true,
      };
    break;

    case PURCHASE_ORDER_VIEW_SUCCESS:
      state = {
        ...state,
        purchaseView: action.payload,
        orderViewLoading: false,
      };
    break;

    case PURCHASE_ORDER_VIEW_FAIL:
      state = {
        ...state,
        purchaseView: action.payload,
        orderViewLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;


    // View purchase order END


    // purchase order delete START

    case PURCHASE_ORDER_DELETE_PART:
      state = {
        ...state,
        poDeletePartLoading: true,
      };
    break;

    case PURCHASE_ORDER_DELETE_PART_SUCCESS:
      state = {
        ...state,
        deletePOpart: action.payload,
        poDeletePartLoading: false,
      };
    break;

    case PURCHASE_ORDER_DELETE_PART_FAIL:
      state = {
        ...state,
        deletePOpart: action.payload,
        poDeletePartLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;


    // purchase order delete END

    


    // fetch Po job wise START

    case FETCH_PO_JOB_WISE:
      state = {
        ...state,
        fetchPOJobLoading: true,
      };
    break;

    case FETCH_PO_JOB_WISE_SUCCESS:
      state = {
        ...state,
        fetchPOJobData: action.payload,
        fetchPOJobLoading: false,
      };
    break;

    case FETCH_PO_JOB_WISE_FAIL:
      state = {
        ...state,
        fetchPOJobData: action.payload,
        fetchPOJobLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;


    // fetch Po job wise END


    case PO_CREATE_JOB_WISE:
      state = {
        ...state,
        savePOLoading: true,
      };
    break;

    case PO_CREATE_JOB_WISE_SUCCESS:
      state = {
        ...state,
        savePOLoading: false,
        createdPOData: action.payload,
      };
    break;

    case PO_CREATE_JOB_WISE_FAIL:
      state = {
        ...state,
        savePOLoading: false,
        createdPOData: action.payload,
        error: {
          message: action.payload,
        }
      };
    break;
    

    // edit po START

    case EDIT_PURCHASE_ORDER:
      state = {
        ...state,
        editPOLoading: true,
      };
    break;

    case EDIT_PURCHASE_ORDER_SUCCESS:
      state = {
        ...state,
        editPOLoading: false,
        updatePOata: action.payload
      };
    break;

    case EDIT_PURCHASE_ORDER_FAIL:
      state = {
        ...state,
        editPOLoading: false,
        updatePOata: action.payload,
        error: {
          message: action.payload,
        }
      };
    break;

      // edit po END


       // Create Purchase order receipts START

    case RECEIPTS_PURCHASE_ORDER_CREATE:
      state = {
        ...state,
        createReceiptLoading: true,
      };
    break;

    case RECEIPTS_PURCHASE_ORDER_CREATE_SUCCESS:
      state = {
        ...state,
        createReceiptLoading: false,
        createReceiptPOData: action.payload
      };
    break;

    case RECEIPTS_PURCHASE_ORDER_CREATE_FAIL:
      state = {
        ...state,
        createReceiptLoading: false,
        createReceiptPOData: action.payload,
        error: {
          message: action.payload,
        }
      };
    break;

      // Create Purchase order receipts START


       // Sent Purchase order email START

    case SEND_PURCHASE_ORDER_EMAIL:
      state = {
        ...state,
        sendPOLoading: true,
      };
    break;

    case SEND_PURCHASE_ORDER_EMAIL_SUCCESS:
      state = {
        ...state,
        sendPOLoading: false,
        sendPOEmailData: action.payload
      };
    break;

    case SEND_PURCHASE_ORDER_EMAIL_FAIL:
      state = {
        ...state,
        sendPOLoading: false,
        sendPOEmailData: action.payload,
        error: {
          message: action.payload,
        }
      };
    break;

      // Sent Purchase order email START


      // Create Purchase order receipts START

    case CREDIT_PURCHASE_ORDER_CREATE:
      state = {
        ...state,
        createCreditLoading: true,
      };
    break;

    case CREDIT_PURCHASE_ORDER_CREATE_SUCCESS:
      state = {
        ...state,
        createCreditLoading: false,
        createCreditPOData: action.payload
      };
    break;

    case CREDIT_PURCHASE_ORDER_CREATE_FAIL:
      state = {
        ...state,
        createCreditLoading: false,
        createCreditPOData: action.payload,
        error: {
          message: action.payload,
        }
      };
    break;


    case CREATE_RECEIPT_CREDIT_NOTE:
      state = {
        ...state,
        createReceiptNoteLoading: true,
      };
    break;

    case CREATE_RECEIPT_CREDIT_NOTE_SUCCESS:
      state = {
        ...state,
        createReceiptNoteLoading: false,
      };
    break;

    case CREATE_RECEIPT_CREDIT_NOTE_FAIL:
      state = {
        ...state,
        createReceiptNoteLoading: false,
        error: {
          message: action.payload,
        }
      };
    break;
      // Create Purchase order receipts START


    default:
      state = { ...state };
    break;
  }
  return state;
};

export default PurchaseOrderReducer;
