import {
  addInvoiceCreate,
  addCashSaleCreate,
  deleteCashSalePart,
  getInsurance,
  getInvoicesList,
  getJobCannedList,
  getJobNotes,
  getLabourChargesList,
  getUnassignedJobs,
  getWorkshopOtherDetailsView,
  sendCashsaleEmailInvoice,
  sendEmailInvoice,
  getInvoicesSalesList,
  sendStatementEmail
} from "helpers/backendHelper";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { getWorkshopId } from "utils/getWorkshopId";
import { getJobCannedList as getScripts } from "../commonApiStore/actions";
import {
  createInvoiceCreditFail,
  createInvoiceCreditSuccess,
  createInvoiceFail,
  createInvoiceSuccess,
  getCreditEmailDetailFail,
  getCreditEmailDetailSuccess,
  getInsuranceDetailFail,
  getInsuranceDetailSuccess,
  getInvoiceCreateDetailFail,
  getInvoiceCreateDetailSuccess,
  getInvoiceCreditInfoFail,
  getInvoiceCreditInfoSuccess,
  getInvoiceCreditViewFail,
  getInvoiceCreditViewSuccess,
  getInvoiceEditDetailFail,
  getInvoiceEditDetailSuccess, getInvoiceRestSuccess,
  getReceiptDetailFail,
  getReceiptDetailSuccess,
  getReceiptEmailDetailFail,
  getReceiptEmailDetailSuccess,
  sendCashsaleEmailInvoiceFail,
  sendCashsaleEmailInvoiceSuccess,
  sendEmailInvoiceFail,
  sendEmailInvoiceSuccess,
  createCashSaleCreditSuccess,
  createCashSaleCreditFail,
  getInvoiceDetailSuccess,
  getInvoiceDetailFail,
  sendStatementEmailSuccess,
  sendStatementEmailFail
} from "./action";
import * as ACTIONS from "./actionType";
import { getJobCannedListPayload } from "./api";
import { createCashSale } from "./api/createCashSale";
import { createInvoice } from "./api/createInvoice";
import { getEditCashSaleDetail } from "./api/editCashSaleDetail";
import { getCashSaleInvoiceDetail } from "./api/getCashSaleInvoice";
import { getInsuranceDetails } from "./api/getInsuranceDetail";
import { getInvoiceCreditDetail } from "./api/getInvoiceCreditDetail";
import { getInvoiceCreditedViewDetail } from "./api/getInvoiceCreditedViewDetail";
import { getInvoiceCreditEmailDetail } from "./api/getInvoiceCreditEmailDetails";
import { getInvoiceEditDetail } from "./api/getInvoiceEditDetail";
import { getInvoiceReceiptDetail } from "./api/getInvoiceReceiptDetail";
import { getInvoiceReceiptEmail } from "./api/getInvoiceReceiptEmail";
import { formatInvoiceDetail, restInvoiceData } from "./formatter";

function* onGetInvoiceCreateDetail(action) {
  try {
    const {
      getBookedJobRequest,
      getInvoiceListRequest,
      getCashSaleListRequest,
      // getWorkShopOtherDetailRequest,
      // getJobNotesRequest,
      // getInsuranceRequest,
      // getJobCannedRequest,
      // getLabourChargeRequest,
    } = action.payload;
    const getBookedJobResponse = yield call(getUnassignedJobs, {
      payload: getBookedJobRequest,
    });
    const getInvoiceListResponse = yield call(getInvoicesList, {
      payload: getInvoiceListRequest,
    });
    const getCashsaleListResponse = yield call(getInvoicesSalesList, {
      payload: getCashSaleListRequest,
    });

    const payload = formatInvoiceDetail({
      getBookedJobResponse,
      getInvoiceListResponse,
      getCashsaleListResponse,
      // getWorkShopOtherDetailResponse,
      // getJobNotesResponse,
      // getInsuranceResponse,
      // getJobCannedResponse,
      // getLabourChargeResponse,
    });


    yield put(getInvoiceCreateDetailSuccess(payload));
  } catch (err) {
    yield put(getInvoiceCreateDetailFail());
    toast.error("Some internal error occured");
  }
}

function* onGetInvoiceRestData(action) {
  try {
    const {
      // getBookedJobRequest,
      // getInvoiceListRequest,
      getWorkShopOtherDetailRequest,
      getJobNotesRequest,
      getInsuranceRequest,
      getJobCannedRequest,
      getLabourChargeRequest,
    } = action.payload;

    const getWorkShopOtherDetailResponse = yield call(
      getWorkshopOtherDetailsView,
      { payload: getWorkShopOtherDetailRequest }
    );
    const getJobNotesResponse = yield call(getJobNotes, {
      payload: getJobNotesRequest,
    });
    const getInsuranceResponse = yield call(getInsurance, {
      payload: getInsuranceRequest,
    });
    const getJobCannedResponse = yield call(getJobCannedList, {
      payload: getJobCannedRequest,
    });
    const getLabourChargeResponse = yield call(getLabourChargesList, {
      payload: getLabourChargeRequest,
    });

    const restData = restInvoiceData({
      getJobNotesResponse,
      getInsuranceResponse,
      getJobCannedResponse,
      getWorkShopOtherDetailResponse,
      getLabourChargeResponse,
    });
    yield put(getInvoiceRestSuccess(restData));
  } catch (err) { }

  // yield put(getInvoiceCreateDetailSuccess(payload));
}

function* onCreateInvoiceSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(createInvoice, payload);
    yield put(createInvoiceSuccess(response));
  } catch (err) {
    yield put(createInvoiceFail("Error creating invoice"));
    toast.error("Error Creating Invoice");
  }
}

function* onGetInvoiceEditDetail(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceEditDetail, id);
    yield put(getInvoiceEditDetailSuccess(data));
  } catch (error) {
    yield put(getInvoiceEditDetailFail(error));
  }
}

function* onSendInvoiceEmail(action) {
  try {
    const { request, navigate } = action.payload;
    const response = yield call(sendEmailInvoice, { payload: request });
    if (response?.code === 200) {
      yield put(sendEmailInvoiceSuccess());
      navigate("/invoice");
      toast.success("Email sent successfully");
    } else {
      yield put(sendEmailInvoiceFail());
      toast.error("Failed to send email");
    }
  } catch (error) {
    yield put(sendEmailInvoiceFail());
    toast.error("Failed to send email");
  }
}

function* onSendStatementEmail(action) {
  try {
    const { request, navigate } = action.payload;
    const response = yield call(sendStatementEmail, { payload: request });
    if (response?.code === 200) {
      yield put(sendStatementEmailSuccess());
      navigate("/invoice");
      toast.success("Email sent successfully");
    } else {
      yield put(sendStatementEmailFail());
      toast.error("Failed to send email");
    }
  } catch (error) {
    yield put(sendStatementEmailFail());
    toast.error("Failed to send email");
  }
}

function* onSendCashsaleInvoiceEmail(action) {
  try {
    const { request, navigate } = action.payload;
    const response = yield call(sendCashsaleEmailInvoice, { payload: request });
    if (response?.code === 200) {
      yield put(sendCashsaleEmailInvoiceSuccess());
      navigate("/invoice");
      toast.success("Email sent successfully");
    } else {
      yield put(sendCashsaleEmailInvoiceFail());
      toast.error("Failed to send email");
    }
  } catch (error) {
    yield put(sendCashsaleEmailInvoiceFail());
    toast.error("Failed to send email");
  }
}


function* onGetInvoiceCreditInfo(action) {
  try {
    const { id } = action.payload;
    const response = yield call(getInvoiceCreditDetail, id);
    yield put(getInvoiceCreditInfoSuccess(response));
  } catch (error) {
    yield put(
      getInvoiceCreditInfoFail("Fail to fetch invoice credit information")
    );
  }
}

function* onCreateInvoiceCredit(action) {
  try {
    const { data, navigate } = action.payload;
    const response = yield call(addInvoiceCreate, { payload: data });
    if (response?.code === 200) {
      yield put(createInvoiceCreditSuccess());
      navigate("/invoice#invoice-credit");
    } else {
      yield put(createInvoiceCreditFail());
    }
  } catch (error) {
    yield put(createInvoiceCreditFail());
  }
}

function* onCreateCashSaleCredit(action) {
  try {
    const { data, navigate } = action.payload;
    const response = yield call(addCashSaleCreate, { payload: data });
    if (response?.code === 200) {
      yield put(createCashSaleCreditSuccess());
      navigate("/invoice#invoice-credit");
    } else {
      yield put(createCashSaleCreditFail());
    }
  } catch (error) {
    yield put(createCashSaleCreditFail());
  }
}

function* onGetInvoiceCreditView(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceCreditedViewDetail, id);
    yield put(getInvoiceCreditViewSuccess(data));
  } catch (error) {
    yield put(getInvoiceCreditViewFail(error));
  }
}

function* onGetInsuranceDetails(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInsuranceDetails, id);
    yield put(getInsuranceDetailSuccess(data));
  } catch (err) {
    yield put(getInsuranceDetailFail());
  }
}

function* onGetReceiptDetail(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceReceiptDetail, id);
    yield put(getReceiptDetailSuccess(data));
  } catch (error) {
    yield put(getReceiptDetailFail("Failed to fetch receipt detail"));
  }
}

function* onGetReceiptEmailDetail(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceReceiptEmail, id);
    yield put(getReceiptEmailDetailSuccess(data));
  } catch (error) {
    yield put(getReceiptEmailDetailFail("Failed to fetch receipt detail"));
  }
}

function* onGetCashSaleDetail(action) {

  try {
    const data = yield call(getCashSaleInvoiceDetail, {});
    yield put(getScripts(getJobCannedListPayload(getWorkshopId())));
    yield put({
      type: ACTIONS.GET_CASH_SALE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // yield put({
    //   type: ACTIONS.GET_CASH_SALE_DETAILS_FAIL,
    //   payload: "error",
    // });
  }
}

function* onGetCreditEmailDetail(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceCreditEmailDetail, id);
    yield put(getCreditEmailDetailSuccess(data));
  } catch (error) {
    yield put(getCreditEmailDetailFail("Failed to fetch receipt detail"));
  }
}

function* onSaveCashSale(action) {
  try {
    const id = yield call(createCashSale, action.payload);
    yield put({
      type: ACTIONS.SAVE_CASH_SALE_DETAILS_SUCCESS,
      payload: id,
    });
  } catch (error) {
    yield put({
      type: ACTIONS.SAVE_CASH_SALE_DETAILS_FAIL,
    });
  }
}

function* onGetEditCashSaleDetail(action) {
  try {
    const data = yield call(getEditCashSaleDetail, action.id);
    yield put({
      type: ACTIONS.GET_EDIT_CASH_SALE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: ACTIONS.GET_EDIT_CASH_SALE_DETAIL_FAIL,
    });
  }
}

function* onDeleteCashSalePart(action) {
  try {
    debugger;
    const data = yield call(deleteCashSalePart, { payload: action.payload });
    if (data?.code === 200) {
      yield put({
        type: ACTIONS.DELETE_CASH_SALE_PART_SUCCESS,
      });
    } else {
      throw new Error("");
    }
  } catch (error) {
    yield put({
      type: ACTIONS.DELETE_CASH_SALE_PART_FAIL,
    });
  }
}

function* onGetInvoiceDetail(data) {
  try {
    const response = yield call(getInvoicesList, data);
    if (response) {
      if (response?.code === 200) {
        yield put({
          type: ACTIONS.GET_INVOICE_DETAIL_SUCCESS,
          payload: response?.data?.Invoices?.[0],
        });
      } else {
        throw new Error("");
      }
    } else {
      yield put({
        type: ACTIONS.GET_INVOICE_DETAIL_FAIL,
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.GET_INVOICE_DETAIL_FAIL,
    });
  }
}

function* onGetInvoiceCashsaleDetail(data) {
  try {
    const response = yield call(getInvoicesSalesList, data);

    if (response) {
      if (response?.code === 200) {
        yield put({
          type: ACTIONS.GET_INVOICE_CASHSALE_DETAIL_SUCCESS,
          payload: response?.data?.CashSaleInvoice?.[0],
        });
      } else {
        throw new Error("");
      }
    } else {
      yield put({
        type: ACTIONS.GET_INVOICE_CASHSALE_DETAIL_FAIL,
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.GET_INVOICE_CASHSALE_DETAIL_FAIL,
    });
  }
}

function* invoiceDetailSaga() {
  yield takeLatest(
    ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE,
    onGetInvoiceCreateDetail
  );
  yield takeLatest(ACTIONS.GET_INVOICE_REST_DATA, onGetInvoiceRestData);
  yield takeLatest(ACTIONS.CREATE_INVOICE, onCreateInvoiceSaga);
  yield takeLatest(ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE, onGetInvoiceEditDetail);
  yield takeLatest(ACTIONS.SEND_EMAIL, onSendInvoiceEmail);
  yield takeLatest(ACTIONS.SEND_STATEMENT_EMAIL, onSendStatementEmail);
  yield takeLatest(ACTIONS.GET_INVOICE_CREDIT_INFO, onGetInvoiceCreditInfo);
  yield takeLatest(ACTIONS.ADD_INVOICE_CREDIT, onCreateInvoiceCredit);
  yield takeLatest(ACTIONS.CASH_SALE_CREDITS_SAVE, onCreateCashSaleCredit);
  yield takeLatest(ACTIONS.GET_INVOCE_CREDIT_VIEW, onGetInvoiceCreditView);
  yield takeLatest(ACTIONS.GET_INSURANCE_DETAILS, onGetInsuranceDetails);
  yield takeLatest(ACTIONS.GET_INVOICE_RECEIPT, onGetReceiptDetail);
  yield takeLatest(ACTIONS.GET_INVOICE_RECEIPT_EMAIL, onGetReceiptEmailDetail);
  yield takeLatest(ACTIONS.GET_CASH_SALE_DETAILS, onGetCashSaleDetail);
  yield takeLatest(ACTIONS.SAVE_CASH_SALE_DETAILS, onSaveCashSale);
  yield takeLatest(ACTIONS.GET_EDIT_CASH_SALE_DETAIL, onGetEditCashSaleDetail);
  yield takeLatest(ACTIONS.DELETE_CASH_SALE_PART, onDeleteCashSalePart);
  yield takeLatest(ACTIONS.GET_INVOICE_CREDIT_EMAIL, onGetCreditEmailDetail);
  yield takeLatest(ACTIONS.SEND_CASHSALE_EMAIL, onSendCashsaleInvoiceEmail);
  yield takeLatest(ACTIONS.GET_INVOICE_DETAIL, onGetInvoiceDetail);
  yield takeLatest(ACTIONS.GET_INVOICE_CASHSALE_DETAIL, onGetInvoiceCashsaleDetail);
}

export default invoiceDetailSaga;