import {
  GET_INSPECTIONS_DIGITAL_INSPECTION,
  GET_INSPECTIONS_DIGITAL_INSPECTION_SUCCESS,
  GET_INSPECTIONS_DIGITAL_INSPECTION_FAIL,
  GET_INSPECTION_GROUP_DIGITAL_INSPECTION,
  GET_INSPECTION_GROUP_DIGITAL_INSPECTION_FAIL,
  GET_INSPECTION_GROUP_DIGITAL_INSPECTION_SUCCESS,
  APPROVE_DIGITAT_INSPECTION,
  APPROVE_DIGITAT_INSPECTION_SUCCESS,
  APPROVE_DIGITAT_INSPECTION_FAIL,
  REJECT_INSPECTION_SUCCESS,
} from "./actionTypes";

const initialState = {
  message: [],
  error: {
    message: "",
  },
  inspDigitalInspections: [], // inspection list
  inspDigitalInspectionGroups: [], // inspection groups
  approval: "",
  loadingData: true,
};

const DigitalInspection = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSPECTIONS_DIGITAL_INSPECTION:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingData: true,
      };
      break;
    case GET_INSPECTIONS_DIGITAL_INSPECTION_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspDigitalInspections: action.payload,
      };
      break;
    case GET_INSPECTIONS_DIGITAL_INSPECTION_FAIL:
      state = {
        ...state,
        inspDigitalInspections: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_INSPECTION_GROUP_DIGITAL_INSPECTION:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_INSPECTION_GROUP_DIGITAL_INSPECTION_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspDigitalInspectionGroups: action.payload,
      };
      break;
    case GET_INSPECTION_GROUP_DIGITAL_INSPECTION_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
        inspDigitalInspectionGroups: [],
      };
      break;
    case APPROVE_DIGITAT_INSPECTION:
      state = {
        ...state,
        approval: "inprogress",
      };
      break;
    case APPROVE_DIGITAT_INSPECTION_SUCCESS:
      const inpection = { ...state.inspDigitalInspections[0] };
      inpection.Insp_status = "Approved";
      state = {
        ...state,
        approval: "success",
        inspDigitalInspections: [inpection],
      };
      break;
    case REJECT_INSPECTION_SUCCESS:
      {
        const inpection = { ...state.inspDigitalInspections[0] };
        inpection.Insp_status = "Delcined";
        state = {
          ...state,
          approval: "success",
          inspDigitalInspections: [inpection],
        };
      }
      break;
    case APPROVE_DIGITAT_INSPECTION_FAIL:
      state = {
        ...state,
        approval: "failed",
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default DigitalInspection;
