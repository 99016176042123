export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST";
export const GET_SUBSCRIPTION_LIST_SUCCESS = "GET_SUBSCRIPTION_LIST_SUCCESS";
export const GET_SUBSCRIPTION_LIST_FAIL = "GET_SUBSCRIPTION_LIST_FAIL";

export const GET_SUBSCRIPTION_HISTORY_LIST = "GET_SUBSCRIPTION_HISTORY_LIST";
export const GET_SUBSCRIPTION_HISTORY_LIST_SUCCESS =
  "GET_SUBSCRIPTION_HISTORY_LIST_SUCCESS";
export const GET_SUBSCRIPTION_HISTORY_LIST_FAIL =
  "GET_SUBSCRIPTION_HISTORY_LIST_FAIL";

export const GET_SUBSCRIPTION_HISTORY_DETAIL_LIST =
  "GET_SUBSCRIPTION_HISTORY_DETAIL_LIST";
export const GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_SUCCESS =
  "GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_SUCCESS";
export const GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_FAIL =
  "GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_FAIL";

export const GET_SUBSCRIPTION_PLAN_DATA = "GET_SUBSCRIPTION_PLAN_DATA";
export const GET_SUBSCRIPTION_PLAN_DATA_SUCCESS =
  "GET_SUBSCRIPTION_PLAN_DATA_SUCCESS";
export const GET_SUBSCRIPTION_PLAN_DATA_FAIL =
  "GET_SUBSCRIPTION_PLAN_DATA_FAIL";

export const GET_SUBSCRIPTION_DETAILS_LIST = "GET_SUBSCRIPTION_DETAILS_LIST";
export const GET_SUBSCRIPTION_DETAILS_LIST_SUCCESS =
  "GET_SUBSCRIPTION_DETAILS_LIST_SUCCESS";
export const GET_SUBSCRIPTION_DETAILS_LIST_FAIL =
  "GET_SUBSCRIPTION_DETAILS_LIST_FAIL";


export const GET_SUBSCRIPTION_MONTHLY_HISTORY = "GET_SUBSCRIPTION_MONTHLY_HISTORY";
export const GET_SUBSCRIPTION_MONTHLY_HISTORY_SUCCESS = "GET_SUBSCRIPTION_MONTHLY_HISTORY_SUCCESS";
export const GET_SUBSCRIPTION_MONTHLY_HISTORY_FAIL = "GET_SUBSCRIPTION_MONTHLY_HISTORY_FAIL";
