import { deleteThirdPartyCompany } from "helpers/backendHelper";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GET_INSURANCE } from "store/scheduler/actionTypes";
import { getWorkshopId } from "utils/getWorkshopId";
import LoaderImage from "../../../../assets/images/page-loader.svg";
import DeleteRecords from "../../../../utils/deleteRecords";
import "../../style.scss";
import Form from "react-bootstrap/Form";

const ThirdPartyList = () => {
  const { SearchBar } = Search;
  const [companyData, setcompanyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const deleteMsg = "Company Deleted Sucessfully";
  const deletePayloadData = {
    payload: {
      action_type: "delete",
      id: selectedRow,
    },
  };

  const columns = [
    {
      dataField: "",
      text: "All",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_insurance_company/${row.id}`);
        },
      },
    },
    {
      dataField: "company_name",
      text: "Company Name",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_insurance_company/${row.id}`);
        },
      },
    },
    {
      dataField: "contact_name",
      text: "Contact Name",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_insurance_company/${row.id}`);
        },
      },
    },
    {
      dataField: "email",
      text: "Email",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_insurance_company/${row.id}`);
        },
      },
    },
    {
      dataField: "phone_number",
      text: "Contact Number",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_insurance_company/${row.id}`);
        },
      },
    },
    {
      dataField: "suburb",
      text: "Suburb",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_insurance_company/${row.id}`);
        },
      },
    },

    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div>
            <button
              className="btn"
              type="button"
              onClick={() => {
                navigate(`/edit_insurance_company/${row.id}`);
              }}
            >
              <i
                className="fa fa-pencil text-info font-size-20"
                title="EDIT"
              ></i>
            </button>
            <button
              className="btn btn-danger btn-icon btn-rounded delete-button"
              type="button"
              onClick={() => {
                setShowDeleteModal(!showDeleteModal);
                setSelectedRow(row.id);
              }}
            >
              <i className="fa fa-minus fa-xs" title="DELETE"></i>
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    dispatch({
      type: GET_INSURANCE,
      payload: {
        get: "all",
        conditions: {
          workshop_id: getWorkshopId(),
        },
      },
    });
  };

  const { insuranceData, loadingLogins } = useSelector(
    (state) => state.SchedulerReducer
  );

  useEffect(() => {
    if (insuranceData !== null && insuranceData !== undefined) setcompanyData(insuranceData);
  }, [insuranceData]);

  const checkBox = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row.id]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.indexOf(row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          {...rest}
          type="checkbox"
          className="custom-check"
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input {...rest} type={mode} className="custom-check" />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const [emptyMessage,setEmptyMessage] = useState("No Results Found");

  const emptyDataMessage = () => {
    return (
      <div className= {(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  return (
    <>
      {/* {loadingLogins && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )} */}

      <div>
        <div className="third-party-company-table p-2">
          <ToolkitProvider
            keyField="id"
            data={companyData}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div className="table-search-block  d-flex align-center justify-flex-end">
                  <SearchBar 
                    {...props.searchProps}
                    // onSearch={() => console.warn('search') }  
                  />
                  <Button
                    className="btn-primary rounded-btn"
                    onClick={() => navigate("/insurance_company")}
                  >
                    <i className="fa fa-plus"></i> Create
                  </Button>
                </div>
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    page: currentPage,
                    sizePerPage: sizePerPage,
                    totalSize: companyData !== undefined ? parseInt(companyData.length) : 0,
                    nextPageText: "Next",
                    prePageText: "Previous",
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <div>
                      <BootstrapTable
                        keyField="id"
                        data={companyData}
                        columns={columns}
                        onTableChange={onTableChange}
                        selectRow={checkBox}
                        noDataIndication={emptyDataMessage}
                        {...paginationTableProps}
                        {...props.baseProps}
                      />
                      <div>
                        <div className="table-pagination">
                          <div>
                            <span>Show</span>
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                            <span>entries</span>
                          </div>
                          <PaginationTotalStandalone {...paginationProps} />
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </div>
                    </div>
                  )}
                </PaginationProvider>
              </div>
            )}
          </ToolkitProvider>

          {showDeleteModal && (
            <DeleteRecords
              showDeleteModal={setShowDeleteModal}
              refreshData={loadData}
              api={deleteThirdPartyCompany}
              selectedRow={selectedRow}
              message={deleteMsg}
              payloadData={deletePayloadData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ThirdPartyList;
