import { call, put, takeLatest } from "redux-saga/effects";
import * as Action from "./actionType";
import {
  cashSaleCreditEmail,
  getCashSaleCreditReceipt,
  getCashSaleEmailReceipt,
  viewCashSaleCredit,
} from "./api/cashSalePaymentsApi";
function* onGetCashSaleCreditReceipt({ id }) {
  try {
    
    const data = yield call(getCashSaleCreditReceipt, id);
    yield put({
      type: Action.GET_CASH_SALE_CREDIT_RECEIPT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: Action.GET_CASH_SALE_CREDIT_RECEIPT_FAIL,
    });
  }
}

function* onViewCashSaleCredit({ id }) {
  try {
    const data = yield call(viewCashSaleCredit, id);
    yield put({
      type: Action.VIEW_CASH_SALE_CREDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: Action.VIEW_CASH_SALE_CREDIT_FAIL,
    });
  }
}

function* onGetCashSaleEmailReceipt({ id }) {
  try {
    const data = yield call(getCashSaleEmailReceipt, id);
    yield put({
      type: Action.GET_CASH_SALE_EMAIL_RECEIPT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Action.GET_CASH_SALE_EMAIL_RECEIPT_FAIL,
    });
  }
}

// function* onViewCashSaleReceipt({ id }) {}

function* onCashSaleCreditEmail({ id }) {
  try {
    // debugger;
    const data = yield call(cashSaleCreditEmail, id);
    yield put({
      type: Action.CASH_SALE_CREDIT_EMAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: Action.CASH_SALE_CREDIT_EMAIL_FAIL,
    });
  }
}

export default function* cashSaleSaga() {
  // yield takeLatest(Action.VIEW_CASH_SALE_RECEIPT, onViewCashSaleReceipt);
  yield takeLatest(
    Action.GET_CASH_SALE_EMAIL_RECEIPT,
    onGetCashSaleEmailReceipt
  );
  yield takeLatest(
    Action.GET_CASH_SALE_CREDIT_RECEIPT,
    onGetCashSaleCreditReceipt
  );

  yield takeLatest(Action.VIEW_CASH_SALE_CREDIT, onViewCashSaleCredit);
  yield takeLatest(Action.CASH_SALE_CREDIT_EMAIL, onCashSaleCreditEmail);
}
