import {
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_FAIL,
  GET_SECONDARY_CUSTOMER_LIST,
  GET_SECONDARY_CUSTOMER_LIST_SUCCESS,
  GET_SECONDARY_CUSTOMER_LIST_FAIL,
  GET_CUSTOMER_COUNT,
  GET_CUSTOMER_COUNT_SUCCESS,
  GET_CUSTOMER_COUNT_FAIL,
  GET_SUPPILERS_LIST,
  GET_SUPPILERS_LIST_SUCCESS,
  GET_SUPPILERS_LIST_FAIL,
  GET_SUPPILERS_COUNT,
  GET_SUPPILERS_COUNT_SUCCESS,
  GET_SUPPILERS_COUNT_FAIL,
  GET_EMPLOYEE_LIST,
  GET_EMPLOYEE_LIST_SUCCESS,
  GET_EMPLOYEE_LIST_FAIL,
  GET_SUPPILERS_BRANCH,
  GET_SUPPILERS_BRANCH_SUCCESS,
  GET_SUPPILERS_BRANCH_FAIL,
  GET_ONSITE_STATE,
  GET_ONSITE_STATE_SUCCESS,
  GET_ONSITE_STATE_FAIL,
  GET_CUSTOMER_DETAIL,
  GET_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMER_DETAIL_FAIL,
  GET_CUSTOMER_VEHICLE,
  GET_CUSTOMER_VEHICLE_SUCCESS,
  GET_CUSTOMER_VEHICLE_FAIL,
  GET_CUSTOMER_NOTES,
  GET_CUSTOMER_NOTES_SUCCESS,
  GET_CUSTOMER_NOTES_FAIL,
  GET_CUSTOMER_ATTACHMENT,
  GET_CUSTOMER_ATTACHMENT_SUCCESS,
  GET_CUSTOMER_ATTACHMENT_FAIL,
  CHECK_SUPPILER_EMAIL,
  CHECK_SUPPILER_EMAIL_SUCCESS,
  CHECK_SUPPILER_EMAIL_FAIL,
  CHECK_SUPPILER_MOBILE,
  CHECK_SUPPILER_MOBILE_SUCCESS,
  CHECK_SUPPILER_MOBILE_FAIL,
  GET_SUPPILERS,
  GET_SUPPILERS_SUCCESS,
  GET_SUPPILERS_FAIL,
  HQ_GET_SUPPLIER,
  HQ_GET_SUPPLIER_SUCCESS,
  HQ_GET_SUPPLIER_FAIL,
  CHECK_EMPLOYEE_EMAIL,
  CHECK_EMPLOYEE_EMAIL_SUCCESS,
  CHECK_EMPLOYEE_EMAIL_FAIL,
  CHECK_EMPLOYEE_MOBILE,
  CHECK_EMPLOYEE_MOBILE_SUCCESS,
  CHECK_EMPLOYEE_MOBILE_FAIL,
  GET_EMPLOYEE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  GET_EMPLOYEE_NOTES,
  GET_EMPLOYEE_NOTES_SUCCESS,
  GET_EMPLOYEE_NOTES_FAIL,
  GET_CUSTOMER_HISTORY,
  GET_CUSTOMER_HISTORY_SUCCESS,
  GET_CUSTOMER_HISTORY_FAIL,
  GET_POS_HISTORY,
  GET_POS_HISTORY_SUCCESS,
  GET_POS_HISTORY_FAIL,
  CHECK_BRANCH_EMAIL,
  CHECK_BRANCH_EMAIL_SUCCESS,
  CHECK_BRANCH_EMAIL_FAIL,
  CHECK_BRANCH_MOBILE,
  CHECK_BRANCH_MOBILE_SUCCESS,
  CHECK_BRANCH_MOBILE_FAIL,
  VIEW_SUPPILERS_BRANCH,
  VIEW_SUPPILERS_BRANCH_SUCCESS,
  VIEW_SUPPILERS_BRANCH_FAIL,
  SUPPLIER_SEARCH,
  SUPPLIER_SEARCH_SUCCESS,
  SUPPLIER_SEARCH_FAIL,
  CUSTOMER_SEARCH,
  CUSTOMER_SEARCH_SUCCESS,
  CUSTOMER_SEARCH_FAIL,
  HQ_GET_SUPPILERS_LIST,
  HQ_GET_SUPPILERS_LIST_SUCCESS,
  HQ_GET_SUPPILERS_LIST_FAIL,
  HQ_GET_SUPPILERS_COUNT,
  HQ_GET_SUPPILERS_COUNT_SUCCESS,
  HQ_GET_SUPPILERS_COUNT_FAIL,
  HQ_SUPPLIER_SEARCH,
  HQ_SUPPLIER_SEARCH_SUCCESS,
  HQ_SUPPLIER_SEARCH_FAIL,
} from "./actionTypes";

export const getCustomerList = () => {
  return {
    type: GET_CUSTOMER_LIST,
  };
};

export const getCustomerListSuccess = (data) => {
  return {
    type: GET_CUSTOMER_LIST_SUCCESS,
    payload: data,
  };
};

export const getCustomerListFail = (error) => {
  return {
    type: GET_CUSTOMER_LIST_FAIL,
    payload: error,
  };
};

export const getSecondaryCustomerList = () => {
  return {
    type: GET_SECONDARY_CUSTOMER_LIST,
  };
};

export const getSecondaryCustomerListSuccess = (data) => {
  return {
    type: GET_SECONDARY_CUSTOMER_LIST_SUCCESS,
    payload: data,
  };
};

export const getSecondaryCustomerListFail = (error) => {
  return {
    type: GET_SECONDARY_CUSTOMER_LIST_FAIL,
    payload: error,
  };
};

export const getCustomerCount = () => {
  return {
    type: GET_CUSTOMER_COUNT,
  };
};

export const getCustomerCountSuccess = (data) => {
  return {
    type: GET_CUSTOMER_COUNT_SUCCESS,
    payload: data,
  };
};

export const getCustomerCountFail = (error) => {
  return {
    type: GET_CUSTOMER_COUNT_FAIL,
    payload: error,
  };
};

export const getSuppliersList = () => {
  return {
    type: GET_SUPPILERS_LIST,
  };
};

export const getSuppliersListSuccess = (data) => {
  return {
    type: GET_SUPPILERS_LIST_SUCCESS,
    payload: data,
  };
};

export const getSuppliersListFail = (error) => {
  return {
    type: GET_SUPPILERS_LIST_FAIL,
    payload: error,
  };
};

export const getHQSuppliersList = (data) => {
  return {
    type: HQ_GET_SUPPILERS_LIST,
    payload: data
  };
};

export const getHQSuppliersListSuccess = (data) => {
  return {
    type: HQ_GET_SUPPILERS_LIST_SUCCESS,
    payload: data,
  };
};

export const getHQSuppliersListFail = (error) => {
  return {
    type: HQ_GET_SUPPILERS_LIST_FAIL,
    payload: error,
  };
};

export const getSuppliersCount = () => {
  return {
    type: GET_SUPPILERS_COUNT,
  };
};

export const getSuppliersCountSuccess = (data) => {
  return {
    type: GET_SUPPILERS_COUNT_SUCCESS,
    payload: data,
  };
};

export const getSuppliersCountFail = (error) => {
  return {
    type: GET_SUPPILERS_COUNT_FAIL,
    payload: error,
  };
};

export const getHQSuppliersCount = (data) => {
  return {
    type: HQ_GET_SUPPILERS_COUNT,
    payload: data
  };
};

export const getHQSuppliersCountSuccess = (data) => {
  return {
    type: HQ_GET_SUPPILERS_COUNT_SUCCESS,
    payload: data,
  };
};

export const getHQSuppliersCountFail = (error) => {
  return {
    type: HQ_GET_SUPPILERS_COUNT_FAIL,
    payload: error,
  };
};

export const getEmployeeList = () => {
  return {
    type: GET_EMPLOYEE_LIST,
  };
};

export const getEmployeeListSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_LIST_SUCCESS,
    payload: data,
  };
};

export const getEmployeeListFail = (error) => {
  return {
    type: GET_EMPLOYEE_LIST_FAIL,
    payload: error,
  };
};

export const getSuppliersBranch = () => {
  return {
    type: GET_SUPPILERS_BRANCH,
  };
};

export const getSuppliersBranchSuccess = (data) => {
  return {
    type: GET_SUPPILERS_BRANCH_SUCCESS,
    payload: data,
  };
};

export const getSuppliersBranchFail = (error) => {
  return {
    type: GET_SUPPILERS_BRANCH_FAIL,
    payload: error,
  };
};

export const getOnSiteState = () => {
  return {
    type: GET_ONSITE_STATE,
  };
};

export const getOnSiteStateSuccess = (data) => {
  return {
    type: GET_ONSITE_STATE_SUCCESS,
    payload: data,
  };
};

export const getOnSiteStateFail = (error) => {
  return {
    type: GET_ONSITE_STATE_FAIL,
    payload: error,
  };
};

export const getCustomerDetail = () => {
  return {
    type: GET_CUSTOMER_DETAIL,
  };
};

export const getCustomerDetailSuccess = (data) => {
  return {
    type: GET_CUSTOMER_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getCustomerDetailFail = (error) => {
  return {
    type: GET_CUSTOMER_DETAIL_FAIL,
    payload: error,
  };
};

export const getCustomerVehicle = () => {
  return {
    type: GET_CUSTOMER_VEHICLE,
  };
};

export const getCustomerVehicleSuccess = (data) => {
  return {
    type: GET_CUSTOMER_VEHICLE_SUCCESS,
    payload: data,
  };
};

export const getCustomerVehicleFail = (error) => {
  return {
    type: GET_CUSTOMER_VEHICLE_FAIL,
    payload: error,
  };
};

export const getCustomerNotes = () => {
  return {
    type: GET_CUSTOMER_NOTES,
  };
};

export const getCustomerNotesSuccess = (data) => {
  return {
    type: GET_CUSTOMER_NOTES_SUCCESS,
    payload: data,
  };
};

export const getCustomerNotesFail = (error) => {
  return {
    type: GET_CUSTOMER_NOTES_FAIL,
    payload: error,
  };
};

export const getCustomerAttachment = () => {
  return {
    type: GET_CUSTOMER_ATTACHMENT,
  };
};

export const getCustomerAttachmentSuccess = (data) => {
  return {
    type: GET_CUSTOMER_ATTACHMENT_SUCCESS,
    payload: data,
  };
};

export const getCustomerAttachmentFail = (error) => {
  return {
    type: GET_CUSTOMER_ATTACHMENT_FAIL,
    payload: error,
  };
};

export const checkSuppilerEmail = () => {
  return {
    type: CHECK_SUPPILER_EMAIL,
  };
};

export const checkSuppilerEmailSuccess = (data) => {
  return {
    type: CHECK_SUPPILER_EMAIL_SUCCESS,
    payload: data,
  };
};

export const checkSuppilerEmailFail = (error) => {
  return {
    type: CHECK_SUPPILER_EMAIL_FAIL,
    payload: error,
  };
};

export const checkSuppilerMobile = () => {
  return {
    type: CHECK_SUPPILER_MOBILE,
  };
};

export const checkSuppilerMobileSuccess = (data) => {
  return {
    type: CHECK_SUPPILER_MOBILE_SUCCESS,
    payload: data,
  };
};

export const checkSuppilerMobileFail = (error) => {
  return {
    type: CHECK_SUPPILER_MOBILE_FAIL,
    payload: error,
  };
};

export const getSuppliers = () => {
  return {
    type: GET_SUPPILERS,
  };
};

export const getSuppliersSuccess = (data) => {
  return {
    type: GET_SUPPILERS_SUCCESS,
    payload: data,
  };
};

export const getSuppliersFail = (error) => {
  return {
    type: GET_SUPPILERS_FAIL,
    payload: error,
  };
};

export const getHQSingleSupplier = (data) => {
  return {
    type: HQ_GET_SUPPLIER,
    payload: data
  };
};

export const getHQSingleSupplierSuccess = (data) => {
  return {
    type: HQ_GET_SUPPLIER_SUCCESS,
    payload: data,
  };
};

export const getHQSingleSuppliersFail = (error) => {
  return {
    type: HQ_GET_SUPPLIER_FAIL,
    payload: error,
  };
};

export const checkEmployeeEmail = () => {
  return {
    type: CHECK_EMPLOYEE_EMAIL,
  };
};

export const checkEmployeeEmailSuccess = (data) => {
  return {
    type: CHECK_EMPLOYEE_EMAIL_SUCCESS,
    payload: data,
  };
};

export const checkEmployeeEmailFail = (error) => {
  return {
    type: CHECK_EMPLOYEE_EMAIL_FAIL,
    payload: error,
  };
};

export const checkEmployeeMobile = () => {
  return {
    type: CHECK_EMPLOYEE_MOBILE,
  };
};

export const checkEmployeeMobileSuccess = (data) => {
  return {
    type: CHECK_EMPLOYEE_MOBILE_SUCCESS,
    payload: data,
  };
};

export const checkEmployeeMobileFail = (error) => {
  return {
    type: CHECK_EMPLOYEE_MOBILE_FAIL,
    payload: error,
  };
};

export const getEmpmployee = () => {
  return {
    type: GET_EMPLOYEE,
  };
};

export const getEmpmployeeSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const getEmpmployeeFail = (error) => {
  return {
    type: GET_EMPLOYEE_FAIL,
    payload: error,
  };
};

export const getEmployeeNotes = () => {
  return {
    type: GET_EMPLOYEE_NOTES,
  };
};

export const getEmployeeNotesSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_NOTES_SUCCESS,
    payload: data,
  };
};

export const getEmployeeNotesFail = (error) => {
  return {
    type: GET_EMPLOYEE_NOTES_FAIL,
    payload: error,
  };
};

export const getCustomerHistory = () => {
  return {
    type: GET_CUSTOMER_HISTORY,
  };
};

export const getCustomerHistorySuccess = (data) => {
  return {
    type: GET_CUSTOMER_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getCustomerHistoryFail = (error) => {
  return {
    type: GET_CUSTOMER_HISTORY_FAIL,
    payload: error,
  };
};

export const getPosHistory = () => {
  return {
    type: GET_POS_HISTORY,
  };
};

export const getPosHistorySuccess = (data) => {
  return {
    type: GET_POS_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getPosHistoryFail = (error) => {
  return {
    type: GET_POS_HISTORY_FAIL,
    payload: error,
  };
};

export const checkBranchEmail = () => {
  return {
    type: CHECK_BRANCH_EMAIL,
  };
};

export const checkBranchEmailSuccess = (data) => {
  return {
    type: CHECK_BRANCH_EMAIL_SUCCESS,
    payload: data,
  };
};

export const checkBranchEmailFail = (error) => {
  return {
    type: CHECK_BRANCH_EMAIL_FAIL,
    payload: error,
  };
};

export const checkBranchMobile = () => {
  return {
    type: CHECK_BRANCH_MOBILE,
  };
};

export const checkBranchMobileSuccess = (data) => {
  return {
    type: CHECK_BRANCH_MOBILE_SUCCESS,
    payload: data,
  };
};

export const checkBranchMobileFail = (error) => {
  return {
    type: CHECK_BRANCH_MOBILE_FAIL,
    payload: error,
  };
};

export const viewSuppliersBranch = () => {
  return {
    type: VIEW_SUPPILERS_BRANCH,
  };
};

export const viewSuppliersBranchSuccess = (data) => {
  return {
    type: VIEW_SUPPILERS_BRANCH_SUCCESS,
    payload: data,
  };
};

export const viewSuppliersBranchFail = (error) => {
  return {
    type: VIEW_SUPPILERS_BRANCH_FAIL,
    payload: error,
  };
};

export const customerSearch = () => {
  return {
    type: CUSTOMER_SEARCH,
  };
};

export const customerSearchSuccess = (data) => {
  return {
    type: CUSTOMER_SEARCH_SUCCESS,
    payload: data,
  };
};

export const customerSearchFail = (error) => {
  return {
    type: CUSTOMER_SEARCH_FAIL,
    payload: error,
  };
};

export const supplierSearch = () => {
  return {
    type: SUPPLIER_SEARCH,
  };
};

export const supplierSearchSuccess = (data) => {
  return {
    type: SUPPLIER_SEARCH_SUCCESS,
    payload: data,
  };
};

export const supplierSearchFail = (error) => {
  return {
    type: SUPPLIER_SEARCH_FAIL,
    payload: error,
  };
};

export const hqSupplierSearch = (data) => {
  return {
    type: HQ_SUPPLIER_SEARCH,
    payload: data
  };
};

export const hqSupplierSearchSuccess = (data) => {
  return {
    type: HQ_SUPPLIER_SEARCH_SUCCESS,
    payload: data,
  };
};

export const hqSupplierSearchFail = (error) => {
  return {
    type: HQ_SUPPLIER_SEARCH_FAIL,
    payload: error,
  };
};
