import { takeLatest, put, call, throttle } from "redux-saga/effects";
import {
  RAISED_PURCHASE_ORDER, RAISED_PURCHASE_ORDER_COUNT, RAISED_SEARCH_PURCHASE_ORDER,
  COMPLETED_PURCHASE_ORDER, COMPLETED_PURCHASE_ORDER_COUNT, COMPLETED_SEARCH_PURCHASE_ORDER,
  CREDIT_PURCHASE_ORDER, CREDIT_PURCHASE_ORDER_COUNT, CREDIT_SEARCH_PURCHASE_ORDER,
  RECEIPTS_PURCHASE_ORDER, RECEIPTS_PURCHASE_ORDER_COUNT, RECEIPTS_SEARCH_PURCHASE_ORDER, 
  RECEIPTS_PURCHASE_ORDER_VIEW, CREDIT_PURCHASE_ORDER_VIEW,
  PURCHASE_ORDER_VIEW,
  FETCH_PO_JOB_WISE, PO_CREATE_JOB_WISE, EDIT_PURCHASE_ORDER,
  RECEIPTS_PURCHASE_ORDER_CREATE,CREDIT_PURCHASE_ORDER_CREATE,
  SEND_PURCHASE_ORDER_EMAIL,
  CREATE_RECEIPT_CREDIT_NOTE,
  PURCHASE_ORDER_DELETE_PART
  
} from "./actionTypes";
import {
  raisedOrderSuccess,
  raisedOrderFail,
  raisedOrderCountSuccess,
  raisedOrderCountFail,
  raisedSearchPurchaseOrderSuccess,
  raisedSearchPurchaseOrderFail,
  completedOrderSuccess,
  completedOrderFail,
  completedOrderCountSuccess,
  completedOrderCountFail,
  completedSearchPurchaseOrderSuccess,
  completedSearchPurchaseOrderFail,
  receiptsOrderSuccess,
  receiptsOrderFail,
  receiptsOrderCountSuccess,
  receiptsOrderCountFail,
  receiptsSearchPurchaseOrderSuccess,
  receiptsSearchPurchaseOrderFail,
  receiptsOrderViewSuccess,
  receiptsOrderViewFail,
  creditOrderSuccess,
  creditOrderFail,
  creditOrderCountSuccess,
  creditOrderCountFail,
  creditSearchPurchaseOrderSuccess,
  creditSearchPurchaseOrderFail,
  creditOrderViewSuccess,
  creditOrderViewFail,
  purchaseOrderViewSuccess,
  purchaseOrderViewFail,
  fetchPOJobWiseSuccess,
  fetchPOJobWiseFail,
  poCreateJobWiseSuccess,
  poCreateJobWiseFail,
  editPOSuccess,
  editPOFail,
  receiptsOrderCreateSuccess,
  receiptsOrderCreateFail,
  sendPOEmailSuccess,
  sendPOEmailFail,
  creditOrderCreateSuccess,
  creditOrderCreateFail,
  createReceiptCreditNoteSuccess,
  createReceiptCreditNoteFail,
  deletePurchaseOrderPartSuccess,
  deletePurchaseOrderPartFail
} from "./actions";

import {
  creditPurchaseOrderList,
  purchaseOrder,
  receiptPurchaseOrderList,
  raisedSearchPurchaseOrderList,
  receiptsPurchaseOrderDetail,
  completedSearchPurchaseOrderList,
  receiptSearchPurchaseOrderList,
  creditSearchPurchaseOrderList,
  getPurchaseOrderView,
  getCreditOrderView,
  fetchPOJObWiseList,
  savePOJobWise,
  editPurchaseOrder,
  createPurchaseOrderReceipts,
  sendPurchaseOrderEmail,
  createPurchaseOrderCredit,
  createReceiptCreditNote,
  deletePurchaseOrderPart
} from "../../helpers/backendHelper";

function* raisedPurchaseOrder(data) {
  try {
    const response = yield call(purchaseOrder, data);
    if (response) {
      if (response.code === 200) {
        yield put(raisedOrderSuccess(response));
      } else {
        yield put(raisedOrderFail(response));
      }
    } else {
      yield put(raisedOrderFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(raisedOrderFail(error.response));
  }
}

function* raisedPurchaseOrderCount(data) {
  try {
    const response = yield call(purchaseOrder, data);
    if (response) {
      if (response.code === 200) {
        yield put(raisedOrderCountSuccess(response.data.PurchaseOrders[0].COUNT));
      } else {
        yield put(raisedOrderCountFail(response.message));
      }
    } else {
      yield put(raisedOrderCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(raisedOrderCountFail(error.response));
  }
}

function* raisedSearchPurchaseOrder(data) {
  try {
    const response = yield call(raisedSearchPurchaseOrderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(raisedSearchPurchaseOrderSuccess(response));
      } else {
        yield put(raisedSearchPurchaseOrderFail(response));
      }
    } else {
      yield put(raisedSearchPurchaseOrderFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(raisedSearchPurchaseOrderFail(error.response));
  }
}



function* onGetPurchaseOrder(data) {
  try {
    const response = yield call(purchaseOrder, data);
    if (response) {
      if (response.code === 200) {
        yield put(completedOrderSuccess(response));
      } else {
        yield put(completedOrderFail(response));
      }
    } else {
      yield put(completedOrderFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(completedOrderFail(error.response));
  }
}

function* completedPurchaseOrderCount(data) {
  try {
    const response = yield call(purchaseOrder, data);
    if (response) {
      if (response.code === 200) {
        yield put(completedOrderCountSuccess(response.data.PurchaseOrders[0].COUNT));
      } else {
        yield put(completedOrderCountFail(response.message));
      }
    } else {
      yield put(completedOrderCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(completedOrderCountFail(error.response));
  }
}

function* completedSearchPurchaseOrder(data) {
  try {
    const response = yield call(completedSearchPurchaseOrderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(completedSearchPurchaseOrderSuccess(response));
      } else {
        yield put(completedSearchPurchaseOrderFail(response));
      }
    } else {
      yield put(completedSearchPurchaseOrderFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(completedSearchPurchaseOrderFail(error.response));
  }
}




function* receiptPurchaseOrder(data) {
  try {
    const response = yield call(receiptPurchaseOrderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(receiptsOrderSuccess(response));
      } else {
        yield put(receiptsOrderFail(response));
      }
    } else {
      yield put(receiptsOrderFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(receiptsOrderFail(error.response));
  }
}

function* receiptsPurchaseOrderCount(data) {
  try {
    const response = yield call(receiptPurchaseOrderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(receiptsOrderCountSuccess(response.data.PurchaseOrdersReciepts[0].COUNT));
      } else {
        yield put(receiptsOrderCountFail(response.message));
      }
    } else {
      yield put(receiptsOrderCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(receiptsOrderCountFail(error.response));
  }
}

function* receiptSearchPurchaseOrder(data) {
  try {
    const response = yield call(receiptSearchPurchaseOrderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(receiptsSearchPurchaseOrderSuccess(response));
      } else {
        yield put(receiptsSearchPurchaseOrderFail(response));
      }
    } else {
      yield put(receiptsSearchPurchaseOrderFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(receiptsSearchPurchaseOrderFail(error.response));
  }
}

function* receiptsPurchaseOrderView(data) {
  try {
    const response = yield call(receiptsPurchaseOrderDetail, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(receiptsOrderViewSuccess(response));
      } else {
        yield put(receiptsOrderViewFail(response));
      }
    } else {
      yield put(receiptsOrderViewFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(receiptsOrderViewFail(error.response));
  }
}






function* creditPurchaseOrder(data) {
  try {
    const response = yield call(creditPurchaseOrderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(creditOrderSuccess(response));
      } else {
        yield put(creditOrderFail(response));
      }
    } else {
      yield put(creditOrderFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(creditOrderFail(error.response));
  }
}

function* creditPurchaseOrderCount(data) {
  try {
    const response = yield call(creditPurchaseOrderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(creditOrderCountSuccess(response.data.PurchaseOrdersCredits[0].COUNT));
      } else {
        yield put(creditOrderCountFail(response.message));
      }
    } else {
      yield put(creditOrderCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(creditOrderCountFail(error.response));
  }
}

function* creditSearchPurchaseOrder(data) {
  try {
    const response = yield call(creditSearchPurchaseOrderList, data);
    if (response) {
      if (response.code === 200) {
        yield put(creditSearchPurchaseOrderSuccess(response));
      } else {
        yield put(creditSearchPurchaseOrderFail(response));
      }
    } else {
      yield put(creditSearchPurchaseOrderFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(creditSearchPurchaseOrderFail(error.response));
  }
}


function* purchaseOrderView(data) {
  try {
    const response = yield call(getPurchaseOrderView, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(purchaseOrderViewSuccess(response));
      } else {
        yield put(purchaseOrderViewFail(response));
      }
    } else {
      yield put(purchaseOrderViewFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(purchaseOrderViewFail(error.response));
  }
}


function* purchaseOrderDeletePart(data) {
  try {
    const response = yield call(deletePurchaseOrderPart, data);
    if (response) {
      if (response.code === 200) {
        yield put(deletePurchaseOrderPartSuccess(response));
      } else {
        yield put(deletePurchaseOrderPartFail(response));
      }
    } else {
      yield put(deletePurchaseOrderPartFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(deletePurchaseOrderPartFail(error.response));
  }
}


function* creditPurchaseOrderView(data) {
  try {
    const response = yield call(getCreditOrderView, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(creditOrderViewSuccess(response));
      } else {
        yield put(creditOrderViewFail(response));
      }
    } else {
      yield put(creditOrderViewFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(creditOrderViewFail(error.response));
  }
}

function* onFetchPOJObWiseList(data) {
  try {
    const response = yield call(fetchPOJObWiseList, data);
    if (response) {
      if (response.code === 200) {
        yield put(fetchPOJobWiseSuccess(response));
      } else {
        yield put(fetchPOJobWiseFail(response));
      }
    } else {
      yield put(fetchPOJobWiseFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(fetchPOJobWiseFail(error.response));
  }
}

function* onPOCreateJobWise(data) {
  try {
    const response = yield call(savePOJobWise, data);
    if (response) {
      if (response.code === 200) {
        yield put(poCreateJobWiseSuccess(response));
      } else {
        yield put(poCreateJobWiseFail(response));
      }
    } else {
      yield put(poCreateJobWiseFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(poCreateJobWiseFail(error.response));
  }
}

function* onEditPO(data) {
  try {
    const response = yield call(editPurchaseOrder, data);
    if (response) {
      if (response.code === 200) {
        yield put(editPOSuccess(response));
      } else {
        yield put(editPOFail(response));
      }
    } else {
      yield put(editPOFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editPOFail(error.response));
  }
}


function* onReceiptsPurchaseOrderCreate(data) {
  try {
    const response = yield call(createPurchaseOrderReceipts, data);
    if (response) {
      if (response.code === 200) {
        yield put(receiptsOrderCreateSuccess(response));
      } else {
        yield put(receiptsOrderCreateFail(response));
      }
    } else {
      yield put(receiptsOrderCreateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(receiptsOrderCreateFail(error.response));
  }
}


function* onCreditPurchaseOrderCreate(data) {
  try {
    const response = yield call(createPurchaseOrderCredit, data);
    if (response) {
      if (response.code === 200) {
        yield put(creditOrderCreateSuccess(response));
      } else {
        yield put(creditOrderCreateFail(response));
      }
    } else {
      yield put(creditOrderCreateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(creditOrderCreateFail(error.response));
  }
}

function* onSendPOEmail(data) {
  try {
    const response = yield call(sendPurchaseOrderEmail, data);
    if (response) {
      if (response.code === 200) {
        yield put(sendPOEmailSuccess(response));
      } else {
        yield put(sendPOEmailFail(response));
      }
    } else {
      yield put(sendPOEmailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(sendPOEmailFail(error.response));
  }
}

function* onCreateReceiptCreditNote(data) {
  try {
    const response = yield call(createReceiptCreditNote, data);
    if (response) {
      if (response.code === 200) {
        yield put(createReceiptCreditNoteSuccess(response));
      } else {
        yield put(createReceiptCreditNoteFail(response));
      }
    } else {
      yield put(createReceiptCreditNoteFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(createReceiptCreditNoteFail(error.response));
  }
}


function* PurchaseOrderSaga() {
  yield takeLatest(RAISED_PURCHASE_ORDER, raisedPurchaseOrder);
  yield takeLatest(RAISED_PURCHASE_ORDER_COUNT, raisedPurchaseOrderCount);
  yield throttle(1500,RAISED_SEARCH_PURCHASE_ORDER, raisedSearchPurchaseOrder);
  
  yield takeLatest(COMPLETED_PURCHASE_ORDER, onGetPurchaseOrder);
  yield takeLatest(COMPLETED_PURCHASE_ORDER_COUNT, completedPurchaseOrderCount);
  yield throttle(1500,COMPLETED_SEARCH_PURCHASE_ORDER, completedSearchPurchaseOrder);
  
  yield takeLatest(RECEIPTS_PURCHASE_ORDER, receiptPurchaseOrder);
  yield takeLatest(RECEIPTS_PURCHASE_ORDER_COUNT, receiptsPurchaseOrderCount);
  yield throttle(1500,RECEIPTS_SEARCH_PURCHASE_ORDER, receiptSearchPurchaseOrder);
  yield takeLatest(RECEIPTS_PURCHASE_ORDER_VIEW, receiptsPurchaseOrderView);

  yield takeLatest(CREDIT_PURCHASE_ORDER, creditPurchaseOrder);
  yield takeLatest(CREDIT_PURCHASE_ORDER_COUNT, creditPurchaseOrderCount);
  yield throttle(1500,CREDIT_SEARCH_PURCHASE_ORDER, creditSearchPurchaseOrder);
  yield takeLatest(CREDIT_PURCHASE_ORDER_VIEW, creditPurchaseOrderView);

  yield takeLatest(PURCHASE_ORDER_VIEW, purchaseOrderView);
  yield takeLatest(PURCHASE_ORDER_DELETE_PART, purchaseOrderDeletePart);

  yield takeLatest(FETCH_PO_JOB_WISE, onFetchPOJObWiseList);
  yield takeLatest(PO_CREATE_JOB_WISE, onPOCreateJobWise);
  
  yield takeLatest(EDIT_PURCHASE_ORDER, onEditPO);

  yield takeLatest(RECEIPTS_PURCHASE_ORDER_CREATE, onReceiptsPurchaseOrderCreate);
  yield takeLatest(CREDIT_PURCHASE_ORDER_CREATE, onCreditPurchaseOrderCreate);

  yield takeLatest(CREATE_RECEIPT_CREDIT_NOTE, onCreateReceiptCreditNote);

  yield takeLatest(SEND_PURCHASE_ORDER_EMAIL, onSendPOEmail);
}

export default PurchaseOrderSaga;
