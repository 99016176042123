export const GET_UNASSIGNED_JOBS = "GET_UNASSIGNED_JOBS";
export const GET_UNASSIGNED_JOBS_SUCCESS = "GET_UNASSIGNED_JOBS_SUCCESS";
export const GET_UNASSIGNED_JOBS_FAIL = "GET_UNASSIGNED_JOBS_FAIL";

export const GET_CUSTOMERS_LIST = "GET_CUSTOMERS_LIST";
export const GET_CUSTOMERS_LIST_SUCCESS = "GET_CUSTOMERS_LIST_SUCCESS";
export const GET_CUSTOMERS_LIST_FAIL = "GET_CUSTOMERS_LIST_FAIL";

export const GET_CUSTOMERANDVEHICLE_DETAILS = "GET_CUSTOMERANDVEHICLE_DETAILS";
export const GET_CUSTOMERANDVEHICLE_DETAILS_SUCCESS =
  "GET_CUSTOMERANDVEHICLE_DETAILS_SUCCESS";
export const GET_CUSTOMERANDVEHICLE_DETAILS_FAIL =
  "GET_CUSTOMERANDVEHICLE_DETAILS_FAIL";

export const CREATE_JOB = "CREATE_JOB";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAIL = "CREATE_JOB_FAIL";

export const REGO_EXIST = "REGO_EXIST";
export const REGO_EXIST_SUCCESS = "REGO_EXIST_SUCCESS";
export const REGO_EXIST_FAIL = "REGO_EXIST_FAIL";

export const GET_UNASSIGNED_JOBS_COUNT = "GET_UNASSIGNED_JOBS_COUNT";
export const GET_UNASSIGNED_JOBS_COUNT_SUCCESS =
  "GET_UNASSIGNED_JOBS_COUNT_SUCCESS";
export const GET_UNASSIGNED_JOBS_COUNT_FAIL = "GET_UNASSIGNED_JOBS_COUNT_FAIL";

export const CLEAN_DATA = "CLEAN_DATA";
