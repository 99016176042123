import Loadable from "react-loadable";

import { Loading } from "../../../common/navigation";

const HQDashboard = Loadable({
  loader: () => import("./components/Dashboard"),
  loading: Loading,
});

// const NativeDashboard = Loadable({
//   loader: () => import("../../../dashboard/components/Dashboard"),
//   loading: Loading,
// });

export const routes = [
  {
    path: "/Dashboard",
    exact: true,
    name: "Dashboard",
    element: HQDashboard,
  },
  // {
  //   path: "/home",
  //   exact: true,
  //   name: "home",
  //   element: NativeDashboard,
  // },
];

export * from "./components";
