import {
  GET_TIMEZONE,
  GET_TIMEZONE_SUCCESS,
  GET_TIMEZONE_FAIL,
  GET_WORKSHOP_DETAIL,
  GET_WORKSHOP_DETAIL_SUCCESS,
  GET_WORKSHOP_DETAIL_FAIL,
  GET_WORKSHOP_OTHER_DETAIL,
  GET_WORKSHOP_OTHER_DETAIL_SUCCESS,
  GET_WORKSHOP_OTHER_DETAIL_FAIL,
  UPDATE_COMPANY_DETAIL,
  UPDATE_COMPANY_DETAIL_SUCCESS,
  UPDATE_COMPANY_DETAIL_FAIL,
  UPDATE_COMPANY_OTHER_DETAIL,
  UPDATE_COMPANY_OTHER_DETAIL_SUCCESS,
  UPDATE_COMPANY_OTHER_DETAIL_FAIL,
  GET_WORKSHOP_SMS,
  GET_WORKSHOP_SMS_SUCCESS,
  GET_WORKSHOP_SMS_FAIL,
  UPDATE_WORKSHOP_SMS,
  UPDATE_WORKSHOP_SMS_SUCCESS,
  UPDATE_WORKSHOP_SMS_FAIL,
  UPDATE_WORKSHOP_MARKUP_DETAIL,
  UPDATE_WORKSHOP_MARKUP_DETAIL_SUCCESS,
  UPDATE_WORKSHOP_MARKUP_DETAIL_FAIL,
  GET_WORKSHOP_MARKUP_DETAIL,
  GET_WORKSHOP_MARKUP_DETAIL_SUCCESS,
  GET_WORKSHOP_MARKUP_DETAIL_FAIL,
  DELETE_WORKSHOP_MARKUP,
  DELETE_WORKSHOP_MARKUP_SUCCESS,
  DELETE_WORKSHOP_MARKUP_FAIL,
  UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL,
} from "./actionTypes";

export const getTimeZone = () => {
  return {
    type: GET_TIMEZONE,
  };
};

export const getTimeZoneSuccess = (data) => {
  return {
    type: GET_TIMEZONE_SUCCESS,
    payload: data.data.Timezones,
    message: data.message,
  };
};

export const getTimeZoneFail = (error) => {
  return {
    type: GET_TIMEZONE_FAIL,
    payload: error,
  };
};

export const getWorkShopDetail = (data) => {
  return {
    type: GET_WORKSHOP_DETAIL,
    payload: data,
  };
};

export const getWorkShopDetailSuccess = (data) => {
  return {
    type: GET_WORKSHOP_DETAIL_SUCCESS,
    payload: data.data.Workshops,
    message: data.message,
  };
};

export const getWorkShopDetailFail = (error) => {
  return {
    type: GET_WORKSHOP_DETAIL_FAIL,
    payload: error,
  };
};

export const getWorkShopOtherDetail = () => {
  return {
    type: GET_WORKSHOP_OTHER_DETAIL,
  };
};

export const getWorkShopOtherDetailSuccess = (data) => {
  return {
    type: GET_WORKSHOP_OTHER_DETAIL_SUCCESS,
    payload: data.data.WorkshopOtherDetails,
    message: data.message,
  };
};

export const getWorkShopOtherDetailFail = (error) => {
  return {
    type: GET_WORKSHOP_OTHER_DETAIL_FAIL,
    payload: error,
  };
};

export const updateCompanyDetail = () => {
  return {
    type: UPDATE_COMPANY_DETAIL,
  };
};

export const updateCompanyDetailSuccess = (data) => {
  return {
    type: UPDATE_COMPANY_DETAIL_SUCCESS,
    payload: data.data.Workshops,
    message: data.message,
  };
};

export const updateCompanyDetailFail = (error) => {
  return {
    type: UPDATE_COMPANY_DETAIL_FAIL,
    payload: error,
  };
};

export const updateCompanyOtherDetail = () => {
  return {
    type: UPDATE_COMPANY_OTHER_DETAIL,
  };
};

export const updateCompanyOtherDetailSuccess = (data) => {
  return {
    type: UPDATE_COMPANY_OTHER_DETAIL_SUCCESS,
    payload: data.data.WorkshopOtherDetails,
    message: data.message,
  };
};

export const updateCompanyOtherDetailFail = (error) => {
  return {
    type: UPDATE_COMPANY_OTHER_DETAIL_FAIL,
    payload: error,
  };
};

export const getWorkShopMarkupDetail = (data) => {
  return {
    type: GET_WORKSHOP_MARKUP_DETAIL,
    payload: data,
  };
};

export const getWorkShopMarkupDetailSuccess = (data) => {
  return {
    type: GET_WORKSHOP_MARKUP_DETAIL_SUCCESS,
    payload: data.data.WorkshopMarkupDetails,
    message: data.message,
  };
};

export const getWorkShopMarkupDetailFail = (error) => {
  return {
    type: GET_WORKSHOP_MARKUP_DETAIL_FAIL,
    payload: error,
  };
};

export const updateWorkshopMarkupDetail = (data) => {
  return {
    type: UPDATE_WORKSHOP_MARKUP_DETAIL,
    payload: data,
  };
};

export const updateDefaultWorkshopMarkupDetail = () => {
  return {
    type: UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL,
  };
};

export const updateWorkshopMarkupDetailSuccess = (data) => {
  return {
    type: UPDATE_WORKSHOP_MARKUP_DETAIL_SUCCESS,
    message: data.message,
    payload: data.data.WorkshopMarkupDetails,
  };
};

export const updateWorkshopMarkupDetailFail = (error) => {
  return {
    type: UPDATE_WORKSHOP_MARKUP_DETAIL_FAIL,
    payload: error,
  };
};

export const deleteWorkshopMarkupDetail = () => {
  return {
    type: DELETE_WORKSHOP_MARKUP,
  };
};

export const deleteWorkshopMarkupDetailSuccess = (data) => {
  return {
    type: DELETE_WORKSHOP_MARKUP_SUCCESS,
    message: data.message,
    payload: data.WorkshopMarkupDetails,
  };
};

export const deleteWorkshopMarkupDetailFail = (error) => {
  return {
    type: DELETE_WORKSHOP_MARKUP_FAIL,
    payload: error,
  };
};

export const getWorkShopSMS = () => {
  return {
    type: GET_WORKSHOP_SMS,
  };
};

export const getWorkShopSMSSuccess = (data) => {
  return {
    type: GET_WORKSHOP_SMS_SUCCESS,
    payload: data.data.WorkshopSms,
    message: data.message,
  };
};

export const getWorkShopSMSFail = (error) => {
  return {
    type: GET_WORKSHOP_SMS_FAIL,
    payload: error,
  };
};

export const updateWorkShopSMS = () => {
  return {
    type: UPDATE_WORKSHOP_SMS,
  };
};

export const updateWorkShopSMSSuccess = (data) => {
  return {
    type: UPDATE_WORKSHOP_SMS_SUCCESS,
    payload: data.data.WorkshopSms,
    message: data.message,
  };
};

export const updateWorkShopSMSFail = (error) => {
  return {
    type: UPDATE_WORKSHOP_SMS_FAIL,
    payload: error,
  };
};
