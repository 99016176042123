export const GET_QUOTE_BOOKED_DETAILS = "GET_QUOTE_BOOKED_DETAILS";
export const GET_QUOTE_BOOKED_DETAILS_SUCCESS =
  "GET_QUOTE_BOOKED_DETAILS_SUCCESS";
export const GET_QUOTE_BOOKED_DETAILS_FAIL = "GET_QUOTE_BOOKED_DETAILS_FAIL";

export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAIL = "GET_MESSAGE_FAIL";

export const GET_INSPECTION_VIEW = "GET_INSPECTION_VIEW";
export const GET_INSPECTION_VIEW_SUCCESS = "GET_INSPECTION_VIEW_SUCCESS";
export const GET_INSPECTION_VIEW_FAIL = "GET_INSPECTION_VIEW_FAIL";

export const GET_QUOTE_INTERNAL_NOTES = "GET_QUOTE_INTERNAL_NOTES";
export const GET_QUOTE_INTERNAL_NOTES_SUCCESS =
  "GET_QUOTE_INTERNAL_NOTES_SUCCESS";
export const GET_QUOTE_INTERNAL_NOTES_FAIL = "GET_QUOTE_INTERNAL_NOTES_FAIL";

export const GET_QUOTE_INTERNAL_NOTES_SCRIPTS =
  "GET_QUOTE_INTERNAL_NOTES_SCRIPTS";
export const GET_QUOTE_INTERNAL_NOTES_SCRIPTS_SUCCESS =
  "GET_QUOTE_INTERNAL_NOTES_SCRIPTS_SUCCESS";
export const GET_QUOTE_INTERNAL_NOTES_SCRIPTS_FAIL =
  "GET_QUOTE_INTERNAL_NOTES_SCRIPTS_FAIL";

export const GET_EMAIL_QUOTE = "GET_EMAIL_QUOTE";
export const GET_EMAIL_QUOTE_SUCCESS = "GET_EMAIL_QUOTE_SUCCESS";
export const GET_EMAIL_QUOTE_FAIL = "GET_EMAIL_QUOTE_FAIL";

export const GET_SCRIPT_LIST = "GET_SCRIPT_LIST";
export const GET_SCRIPT_LIST_SUCCESS = "GET_SCRIPT_LIST_SUCCESS";
export const GET_SCRIPT_LIST_FAIL = "GET_SCRIPT_LIST_FAIL";

export const CREATE_QUOTE = "CREATE_QUOTE";
export const CREATE_QUOTE_SUCCESS = "CREATE_QUOTE_SUCCESS";
export const CREATE_QUOTE_FAIL = "CREATE_QUOTE_FAIL";

export const GET_EMAIL_PREVIEW_QUOTE = "GET_EMAIL_PREVIEW_QUOTE";
export const GET_EMAIL_PREVIEW_QUOTE_SUCCESS =
  "GET_EMAIL_PREVIEW_QUOTE_SUCCESS";
export const GET_EMAIL_PREVIEW_QUOTE_FAIL = "GET_EMAIL_PREVIEW_QUOTE_FAIL";

export const QUOTE_UPDATED_DATA = "QUOTE_UPDATED_DATA";
export const QUOTE_UPDATED_DATA_SUCCESS = "QUOTE_UPDATED_DATA_SUCCESS";
export const QUOTE_UPDATED_DATA_FAIL = "QUOTE_UPDATED_DATA_FAIL";

export const UPDATE_QUOTE = "UPDATE_QUOTE";
export const UPDATE_QUOTE_SUCCESS = "UPDATE_QUOTE_SUCCESS";
export const UPDATE_QUOTE_FAIL = "UPDATE_QUOTE_FAIL";

export const UPDATE_QUOTE_INFO = "UPDATE_QUOTE_INFO";
