//Login
export const GET_LOGIN = "users/commonlogin.json";

//Register
export const GET_REGISTER = "users/signupReact.json";
export const SEND_OTP = "UsersOtpDetails/add.json";
export const CONFIRM_OTP = "UsersOtpDetails/verifyOtp.json";
export const EXIST_EMAIL = "Users/checkEmail.json";
export const GET_COUNTRY = "Countries/index.json";
export const GET_STATE = "States/index.json";
export const GET_INDUSTRY = "industries/index.json";
export const GET_ACCOUNTING_PACKAGE = "AccountingPackages/index.json";
export const GET_SIZE_OF_WORKSHOP = "IndustrySizes/index.json";
export const GET_REFERRAL_ADVERTISE = "advertisements/index.json";

//Forgot Password
export const GET_FORGOT_PASSWORD = "users/forgotPassword.json";

//Forgot Email
export const GET_FORGOT_EMAIL = "users/forgotPassword.json";

//Check Rest Password Link
export const CHECK_RESET_LINK = "users/checkResetLink.json";

//Check Rest Password Link
export const USER_RESET_PASSWORD = "users/resetPassword.json";

//Job list
export const GET_JOBS = "JobSchedules/index.json";

// delete job
export const DELETE_JOBS = "JobSchedules/deleteMany.json";

//User Profile
export const GET_USER_PROFILE = "users/index.json";
export const UPDATE_USER_PROFILE = "users/edit.json";

//Customer listing
export const GET_CUSTOMER_LIST = "CustomersDetails/index.json";

export const ADD_MANY_SECONDARY_CUSTOMER = "CustomersDetails/addManySecondaryContact.json";

//Vehicle History listing
export const ADD_VEHICLE_HISTORY = "VehicleHistory/add.json";

//Customer and vehicle details
export const GET_CUSTOMER_AND_VEHICLE_DETAILS = "Vehicles/index/desktop.json";

// department Account list
export const GET_JOB_DEPARTMENT_LIST =
  "WorkshopDepartmentsAccountsDetails/index.json";

//Job type list
export const GET_JOB_TYPE_LIST = "JobBookingTypes/index.json";

//Job canned list
export const GET_JOBCANNED_LIST = "WorkshopScriptsDetails/index.json";

//Job sms template list
export const GET_JOBTEMPLATE_LIST = "CustomNotifications/index.json";
export const GET_JOBSMSTEMPLATE_LIST = "CustomNotifications/index.json";

//Job email template list
export const GET_JOBEMAILTEMPLATE_LIST = "CustomNotifications/index.json";

//Job email template save
export const Save_JOBEMAILTEMPLATE = "JobSchedules/sendScriptOfJobs.json";

//Job sms template save
export const Save_JOBSMSTEMPLATE = "JobSchedules/sendScriptOfJobs.json";

//Edit Customer email template save
export const SAVE_USER_EMAIL_TEMPLATE = "JobSchedules/sendScriptOfUser.json";

//Edit Customer sms template save
export const SAVE_USER_SMS_TEMPLATE = "JobSchedules/sendScriptOfUser.json";

//Job Create
export const CREATE_JOB = "JobSchedules/add.json";

//Marketing Create
export const CREATE_MARKETING = 'WorkshopMarketing/add.json';

//Marketing View
export const VIEW_MARKETING = 'WorkshopMarketing/view';

//Marketing View
export const VIEW_HQ_MARKETING = 'WorkshopMarketing/viewHeadQuarters';


//Marketing EDIT
export const EDIT_MARKETING = 'WorkshopMarketing/edit.json';


//Notification
export const GET_NOTIFICATION = "NotificationsData/index.json";

//Delete Notification
export const DELETE_NOTIFICATION_LIST = "NotificationsData/deleteReact.json";

//Notification
export const COPY_NOTIFICATION_DATA = "NotificationsData/addMany.json";

//Get Marketing Notificaiton 
export const GET_MARKETING_NOTIFICATION = "CronjobData/index.json";

//Change Password
export const GET_CHANGE_PASSWORD = "users/changePassword.json";

//Subscription other detail
export const GET_SUBSCRIPTION = "WorkshopSubscription/index.json";

//Subscription other detail
export const GET_SUBSCRIPTION_ADDON = "WorkshopSubscriptionDetails/index.json";

//Global Search
export const GET_GLOBAL_SEARCH = "Users/globalSearch.json";

//Scheduler
export const GET_TECHNICIAN = "users/index.json";
export const GET_WORKSHOP_BAYS = "WorkshopBaysDetails/index.json";
export const GET_TECHNICIAN_HOUR = "JobSchedules/getTechnicianHours.json";
export const GET_INSURANCE = "InsuranceCompany/index.json";
export const GET_JOB_TAGS = "Tags/index.json";
export const ADD_JOB_TAGS = "Tags/addMany.json";
export const PRINT_JOB_CARD_PDF = "JobSchedules/jobCardPdf.json";
export const CHECK_AVAILABLE_HOURS = "JobSchedules/checkHoursCalculation.json";
export const GET_WORKSHOP_TECHNICIAN = "workshops/index.json";
export const GET_AVAIALABLE_SLOTS =
  "JobSchedules/technicianAvailableSlots.json";
export const GET_JOBS_KIT_PARTS = "JobKitParts/index.json";
export const GET_JOBS_KIT_LABOURS = "JobKitLabours/index.json";
export const ADD_MANY_JOB_PARTS = "JobsParts/addMany.json";
export const ADD_MANY_JOB_LABOURS = "JobLabours/AddMany.json";
export const ADD_MANY_JOB_KIT_PARTS = "JobKitParts/AddMany.json";
export const ADD_MANY_JOB_KIT_LABOURS = "JobKitLabours/AddMany.json";
export const GET_ACTIVITY = "ActivityMaster/index.json";
export const GET_SCHEDULE_ACTIVITY = "ActivitySchedule/index.json";
export const ADD_ACTIVITY = "ActivityMaster/add.json";
export const DELETE_ACTIVITY = "ActivityMaster/delete.json";
export const ADD_SCHEDULE_ACTIVITY = "ActivitySchedule/add.json";
export const ADD_MANY_CREATE_JOB = "JobSchedules/addMany.json";
export const DELETE_JOB_ACTIVITY = "JobSchedules/delete.json";
export const DELETE_JOB_SCHEDULE_ACTIVITY = "ActivitySchedule/delete.json";
export const GET_ESTIMATE_TIME_SLOT = "JobSchedules/getEstimateScheduleTime.json";
// Dashboard

export const GET_VEHICLE_COUNT = "Vehicles/index/desktop.json";
export const GET_SERVICE_DUE_COUNT = "ServiceReminderHistory/index.json";
export const GET_CUSTOMER_AND_APP_COUNT = "Users/dashboardWidgetCounts.json";
export const GET_CUSTOMER_SURVEY = "SurveyFeedback/index.json";
export const GET_NEWS_FEED = "NewsManagement/index.json";
export const GET_DASHBOARD_JOB_COUNT = "JobSchedules/dashboardJobCount.json";
export const GET_DASHBOARD_WEEK_JOB_COUNT =
  "JobSchedules/dashboardJobCountWeek.json";
export const GET_DASHBOARD_INVOICE_COUNT =
  "Invoices/dashboardInvoiceCount.json";
export const GET_DASHBOARD_WEEK_INVOICE_COUNT =
  "Invoices/dashboardInvoiceWeekCount.json";
export const GET_DASHBOARD_PAYMENT_COUNT =
  "InvoicesPaymentsCredits/dashboardPaymentCount.json";
export const GET_DASHBOARD_WEEK_PAYMENT_COUNT =
  "InvoicesPaymentsCredits/dashboardPaymentWeekCount.json";

/* Customer  */
export const ADD_CUSTOMER = "Users/add.json";

//Update Customer
export const UPDATE_CUSTOMER = "CustomersDetails/edit.json";

export const ADD_USER_LOGIN = "UserLogin/giveLoginAccess.json";

/* Quotes */
export const GET_QUOTES = "CustomerQuotes/index.json";
export const EDIT_QUOTES = "CustomerQuotes/edit.json";
export const ADD_QUOTES = "CustomerQuotes/add.json";
export const UPDATE_QUOTES = "CustomerQuotes/add.json";

/* Workshop & Workshop Other Detail */
export const GET_WORKSHOP_OTHER_DETAILS_VIEW = "WorkshopOtherDetails/view.json";
export const GET_WORKSHOP_OTHER_DETAILS = "WorkshopOtherDetails/index.json";
export const UPDATE_WORKSHOP_OTHER_DETAILS = "WorkshopOtherDetails/edit.json";

/*Workshop subscription detail*/
export const GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST =
  "WorkshopSubscriptionDetails/index.json";

/* Parts */
// get list or search parts / products
export const GET_PART_LIST = "Products/index.json";

// get list or search parts / products
export const GET_SEARCH_PART_LIST = "Products/globalSearch.json";

// get list or search parts / products
export const GET_SEARCH_KIT_PART_LIST = "Products/kitPartSearch.json";

export const PART_CATEGORY_LIST = "ProductCategory/index.json";

// save parts / products
export const SAVE_PART = "Products/add.json";

// delete parts / products
export const DELETE_PART = "Products/delete.json";

// update many parts / products
export const EDIT_MANY_PART = "Products/deleteMany.json";

// update many parts / products
export const EDIT_PART = "Products/edit.json";

// save parts / products
export const SAVE_PART_REACT = "Products/addReact.json";


// save parts / products
export const EDIT_PART_REACT = "Products/editReact.json";

// view parts / products
export const VIEW_PART = "Products/view";


//get job part
export const GET_JOB_PART_LIST = "JobsParts/index.json";

//save job parts
export const SAVE_JOB_PART = "JobsParts/add.json";

//delete job parts
export const DELETE_JOB_PART = "JobsParts/delete.json";

//update job parts
export const UPDATE_JOB_PART = "JobsParts/add.json";

//save job parts Many
export const EDIT_JOB_PART_MANY = "JobsParts/editMany.json";

//save job kit parts Many
export const EDIT_JOB_KIT_PART_MANY = "JobKitParts/editMany.json";

//save job kit labour Many
export const EDIT_JOB_KIT_LABOUR_MANY = "JobKitLabours/editMany.json";

// get job kit part
export const GET_JOB_KIT_PART = "JobKitParts/index.json";

// get job kit part
export const GET_JOB_KIT_LABOUR = "JobKitLabours/index.json";

// save job kit part
export const SAVE_JOB_KIT_PART = "JobKitParts/add.json";

// save job kit part
export const DELETE_JOB_KIT_PART = "JobKitParts/delete.json";


export const EDIT_INVENTORY_STOCK_MANY = "InventoryStock/editMany.json";



/************************
 ░░░░ Job Labour START ░░░░
 *************************/

//GET LABOUR CHARGE LIST
export const GET_LABOUR_CHARGES_LIST = "LabourCharges/index.json";

//get job Labour
export const GET_JOB_LABOUR_LIST = "JobLabours/index.json";

//save job Labour
export const SAVE_JOB_LABOUR = "JobLabours/add.json";

//delete job Labour
export const DELETE_LABOUR_PART = "JobLabours/delete.json";

//update job Labour
export const UPDATE_LABOUR_PART = "JobLabours/edit.json";

//edit job Labour many
export const EDIT_JOB_LABOUR_MANY = "JobLabours/editMany.json";
//save job Labour
export const SAVE_QUOTE_LABOUR = "CustomerQuotesLabours/add.json";
//get job Labour
export const GET_QUOTE_LABOUR_LIST = "CustomerQuotesLabours/index.json";
//get job Labour
export const DELETE_QUOTE_LABOUR_PART = "CustomerQuotesLabours/delete.json";
//get job Labour
export const UPDATE_QUOTE_LABOUR_PART = "CustomerQuotesLabours/edit.json";
//get job Labour
export const EDIT_QUOTE_LABOUR_MANY = "CustomerQuotesLabours/editMany.json";


/************************
 ░░░░ Job Labour END ░░░░
 *************************/


/************************
 ░░░░ Job detail START ░░░░
 *************************/

// get job booked details
export const GET_JOB_BOOKED_DETAILS = "JobSchedules/index.json";

//sendBookSms
export const SENT_JOB_BOOK_SMS = "JobSchedules/sendBookSms.json";

// get recommendations list
export const GET_JOB_RECOMMENDATIONS_LIST = "Recommendations/index.json";

export const ADD_MANY_RECOMMENDATIONS = "Recommendations/addMany.json";

export const DELETE_RECOMMENDATIONS = "Recommendations/delete.json";

export const EDIT_RECOMMENDATIONS = "Recommendations/edit.json";

// get job notes
export const GET_JOB_NOTES = "JobNotes/index.json";

// add job notes
export const ADD_JOB_NOTES = "JobNotes/add.json";

// update job notes
export const UPDATE_JOB_NOTES = "JobNotes/add.json";

/************************
 ░░░░ Workshop START ░░░░
 *************************/

//GET workshop markup LIST
export const GET_WORKSHOP_MARKUP_DETAILS = "WorkshopMarkupDetails/index.json";

// GET workshop markup list
export const GET_WORKSHOP_MARKUP_LIST = "WorkshopMarkupDetails/view.json";

// DELETE workshop markup list
export const DELETE_WORKSHOP_MARKUP = "WorkshopMarkupDetails/delete.json";

// SAVE workshop markup
export const SAVE_WORKSHOP_MARKUP_DETAILS = "WorkshopMarkupDetails/add.json";

// UPDATE workshop markup
export const UPDATE_WORKSHOP_MARKUP_DETAILS = "WorkshopMarkupDetails/edit.json";

// UPDATE default workshop markup
export const UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAILS =
  "WorkshopMarkupDetails/updateMany.json";

/************************
 ░░░░ Labour Charge START ░░░░
 *************************/

//get Labour charge
export const GET_LABOUR_CHARGE = "LabourCharges/index.json";

//save Labour charge
export const SAVE_LABOUR_CHARGE = "LabourCharges/add.json";

//delete Labour charge
export const DELETE_LABOUR_CHARGE = "LabourCharges/delete.json";

//update Labour charge
export const UPDATE_LABOUR_CHARGE = "LabourCharges/edit.json";

// UPDATE default labour charges
export const UPDATE_DEFAULT_LABOUR_CHARGE = "LabourCharges/updateMany.json";

/************************
 ░░░░ Labour Charge END ░░░░
 *************************/

/************************
 ░░░░ Custom Vehicle START ░░░░
 *************************/

//get Labour charge
export const GET_CUSTOM_VEHICLE = "VehiclesCustomFields/index.json";

//save Labour charge
export const SAVE_CUSTOM_VEHICLE = "VehiclesCustomFields/add.json";

//delete Labour charge
export const DELETE_CUSTOM_VEHICLE = "VehiclesCustomFields/delete.json";

//update Labour charge
export const UPDATE_CUSTOM_VEHICLE = "VehiclesCustomFields/edit.json";

/************************
 ░░░░ Custom Vehicle END ░░░░
 *************************/

//GET workshop Tax / Rates LIST
export const GET_WORKSHOP_TAX_DETAILS = "WorkshopTaxRatesDetails/index.json";

/************************
 ░░░░ Supplier START ░░░░
 *************************/

// get list of supplier
export const GET_SUPPLIER_LIST = "Suppliers/index.json";
export const GET_HQ_SUPPLIER_LIST = "HeadQuartersSuppliers/index.json";

// save Supplier
export const ADD_SUPPLIER = "Suppliers/add.json";

// Save HQ supplier
export const ADD_HQ_SUPPLIER = "HeadQuartersSuppliers/add.json";

// Delete HQ supplier
export const DELETE_HQ_SUPPLIER = "HeadQuartersSuppliers/delete.json";

/************************
 ░░░░ Supplier END ░░░░
 *************************/

/* list of all vehicle */
export const GET_VEHICLES = "AssetsMaster/index.json";

/* vehicle history */
export const GET_VEHICLES_HISTORY = "VehicleHistory/index.json";

/* list of selected vehicle model  */
export const GET_VEHICLESMODEL = "AssetsMaster/index.json";

/* list of selected vehicle series  */
export const GET_VEHICLESERIES = "AssetsDetails/index.json";

/* list of selected vehicle engine type,type and transmission for marine  */
export const GET_ASSETS = "Assets/index.json";

/* list of custom fields  */
export const GET_CUSTOM_FIELDS = "VehiclesCustomFields/index.json";

/* Add assets details  */
export const ADD_ASSETS_DETAILS = "AssetsDetails/add.json";

/* Add assets master  */
export const ADD_ASSETS_MASTER = "AssetsMaster/add.json";

/* Add assets details (Vehicle,Marine,Truck,etc...)  */
export const ADD_ASSETS = "Vehicles/add.json";

/* save user request  */
export const SAVE_USER_REQUEST = "users/add.json";

/* get status color */
export const GET_STATUS_COLOR = "Statuses/index.json";

/*rego lookup details*/
export const GET_REGO_LOOKUP_DETAILS = "/Vehicles/getRegoLookupDetails.json";

/*update job timer details*/
export const UPDATE_JOB_TIMER = "JobSchedules/edit.json";

/** Update job status */
export const UPDATE_JOB_STATUS = "JobSchedules/updateJobStatus.json";

/*update vehicle details*/
export const UPDATE_VEHICLE = "Vehicles/edit.json";

/* Settings Company Detail */
export const GET_TIMEZONE = "Timezones/index.json";
export const GET_WORK_SHOP_DETAIL = "Workshops/getdata.json";
export const GET_WORK_SHOP_OTHER_DETAIL = "WorkshopOtherDetails/view.json";
export const UPDATE_COMPANY_DETAIL = "Workshops/edit.json";
export const UPDATE_COMPANY_OTHER_DETAIL = "WorkshopOtherDetails/edit.json";
export const GET_WORK_SHOP_SMS = "WorkshopSms/index.json";
export const UPDATE_WORKSHOP_SMS = "WorkshopSms/add.json";

/****************************
 ░░░░░░░░ JOB DETAILS ░░░░░░░░
 ****************************/
export const GET_INSPECTIONS = "InspInspections/index.json";
export const GET_INSPECTION_TEMPLATES = "InspTemplates/index.json";
/* get inspections view */
export const GET_INSPECTION_VIEW = "InspInspections/view.json";
//Update Inspection
export const UPDATE_INPECTION = "InspInspections/edit.json";
//Delete Inspection
export const DELETE_INPECTION = "InspInspections/delete.json";
/* get internal  notes */
export const GET_INTERNAL_NOTES = "InternalNotes/index.json";

/* add internal  notes */
export const ADD_INTERNAL_NOTES = "InternalNotes/add.json";

/* delete internal  notes */
export const DELETE_INTERNAL_NOTES = "InternalNotes/delete.json";

/* save internal notes attachments */
export const SAVE_INTERNAL_NOTES_ATTACHMENTS =
  "InternalNotesAttachments/addMany.json";

/* search JobSchedules */
export const JOBSCHEDULESSEARCH = "JobSchedules/searchJobs.json";

/****************************
 ░░░░░░░░ SETTINGS: SECURITY GROUP ░░░░░░░░
 ****************************/
// GET secuity groups
export const GETSECURITYGROUP = "SecurityGroupsDetails/getData.json";
// ADD security group
export const ADDSECURITYGROUP = "SecurityGroupsDetails/add.json";
// UPDATE security group
export const UPDATESECURITYGROUP = "SecurityGroupsDetails/edit.json";

/* get Quote internal  notes */
export const GET_QUOTE_INTERNAL_NOTES = "QuoteInternalNotes/index.json";

/* add Quote internal  notes */
export const ADD_QUOTE_INTERNAL_NOTES = "QuoteInternalNotes/add.json";

/* delete Quote internal  notes */
export const DELETE_QUOTE_INTERNAL_NOTES = "QuoteInternalNotes/delete.json";

/* save Quote internal notes attachments */
export const SAVE_QUOTE_INTERNAL_NOTES_ATTACHMENTS =
  "QuoteInternalNotesAttachment/addMany.json";

/* search Quotes */
export const QUOTE_SEARCH = "CustomerQuotes/searchQuotes.json";

// get list of quote parts
export const GET_QUOTE_PART_LIST = "CustomerQuotesParts/index.json";

// save quote parts
export const SAVE_QUOTE_PART = "CustomerQuotesParts/add.json";

// update quote parts
export const UPDATE_QUOTE_PART = "CustomerQuotesParts/edit.json";

//  delete quote parts
export const DELETE_QUOTE_PART = "CustomerQuotesParts/delete.json";

// edit quote many parts
export const EDIT_QUOTE_PART_MANY = "CustomerQuotesParts/editMany.json";

// get quote kit part
export const GET_QUOTE_KIT_PART = "QuoteKitParts/index.json";

// add quote kit part
export const SAVE_QUOTE_KIT_PART = "QuoteKitParts/add.json";

// delete quote kit part
export const DELETE_QUOTE_KIT_PART = "QuoteKitParts/delete.json";

// get quote kit labour
export const GET_QUOTE_KIT_LABOUR = "QuoteKitLabours/index.json";

//save quote kit parts Many
export const EDIT_QUOTE_KIT_PART_MANY = "QuoteKitParts/editMany.json";

//save quote kit labour Many
export const EDIT_QUOTE_KIT_LABOUR_MANY = "QuoteKitLabours/editMany.json";

/* COPY QUOTES */
export const COPYQUOTES = "CustomerQuotes/copyQuotes.json";

/* delete QUOTES */
export const DELETEQUOTES = "CustomerQuotes/deleteMany.json";

/* send QUOTES mail */
export const SENDQUOTEMAIL = "CustomerQuotes/sendQuotesMail.json";

/* apply referal code */
export const APPLY_CODE = "JobSchedules/verifyMarketingCodes.json";

/** Get Supplier statements */
export const GET_SUPPLIER_STATEMENT = "SupplierStatement/index.json";

export const GET_SUPPLIER_LIST_STATEMENT = "supplierStatementDetail/index.json";

/** GET INVOICES */
export const GET_INVOICES_LIST = "Invoices/index.json";
export const SEARCH_INVOICE = "Invoices/searchInvoice.json";
export const SEARCH_RECEIPT = "InvoicesPaymentsCredits/searchReceipt.json";
export const SEARCH_CASHSALE_RECEIPT = "CashSalePaymentsCredits/searchCashsaleReceipt.json";
export const SEARCH_CASHSALE = "CashSaleInvoice/searchCashsale.json";

export const GET_INVOICES_PAYMENT = "InvoicesPaymentsCredits/index.json";
export const UPDATE_INVOICE_CREDIT =
  "InvoicesPaymentsCredits/updateMany.json";

export const GET_INVOICE_CREDIT =
  "InvoicesPaymentsCreditsOtherDetails/index.json";
export const GET_CASH_SALE_CREDIT =
  "CashSalePaymentsCreditsOtherDetails/index.json";
export const GET_INVOICE_CASH_SALE_LIST = "CashSaleInvoice/index.json";

export const GET_INVOICES_STATEMENTS = "InvoiceStatement/index.json";
export const UPDATE_INVOICE_STATEMENTS = "InvoiceStatement/add.json";

export const SEND_STATEMENT_REPORT = "InvoiceStatement/sendReport.json";

/**DELETE/ARCHIVE INVOICES */
export const DELETE_INVOICES = "Invoices/deleteMany.json";
export const DELETE_INVOICES_SALES = "CashSaleInvoice/deleteMany.json";

/** GET MARKETING LIST */
export const GET_MARKETINGS_LIST = 'WorkshopMarketing/index.json';
export const GET_LIVE_MARKETINGS_LIST = 'WorkshopMarketing/index.json';
export const GET_COMPLETED_MARKETINGS_LIST = 'WorkshopMarketing/index.json';

/**DELETE/ARCHIVE MARKETINGS */
export const DELETE_MARKETINGS = 'WorkshopMarketing/deleteMany.json';
// export const DELETE_MARKETINGS = 'Invoices/deleteMany.json';

/* Inspection Approve */
export const APPROVE_MANUAL_INSPECTION = "InspInspections/InspEdit.json";
export const ADD_APPROVED_PARTS_INSPECTION =
  "InspInspections/addInspectionParts.json";

/* Inspection Resend */
export const RESEND_INSPECTION = "InspInspections/resendInspPDF.json";

/* Get inspections groups */
export const INSPECTION_GROUP = "InspGroups/index.json";

export const ADD_INSPECTION = "InspInspections/add.json";
//Update Invoice
export const UPDATE_INVOICE = "Invoices/edit.json";
//Add Invoice
export const ADD_INVOICE = "Invoices/add.json";

export const EDIT_CASH_SALE_INVOICE = "CashSaleInvoice/edit.json";

export const CASH_SALE_PAYMENTS_CREDITS_SAVE =
  "CashSalePaymentsCredits/add.json";
export const INVOICES_PAYMENTS_CREDITS_SAVE =
  "InvoicesPaymentsCredits/add.json";

export const START_STATEMENT_PAYMENT =
  "InvoiceStatement/payment.json";

//Payment credit
export const GET_CASH_SALE_PAYMENTS_CREDITS =
  "CashSalePaymentsCredits/index.json";
export const SEND_INVOICE_MAIL = "Invoices/sendInvoiceMail.json";
export const SEND_STATEMENT_EMAIL = "InvoiceStatement/sendMail.json";

export const SEND_CASHSALE_INVOICE_MAIL = "CashSaleInvoice/sendCashSaleInvoiceMail.json";
export const CASH_SALE_CREDITS_SAVE =
  "CashSalePaymentsCreditsOtherDetails/add.json";

export const ADD_INVOICE_CREDIT =
  "InvoicesPaymentsCreditsOtherDetails/add.json";
/****************************
 ░░░░░░░░ SETTINGS: STATUSES ░░░░░░░░
 ****************************/
export const GET_STATUSES_LIST = "Statuses/index.json";
export const ADD_STATUS = "Statuses/add.json";
export const UPDATE_STATUS = "Statuses/edit.json";
export const DELETE_STATUS = "Statuses/delete.json";
export const DELETE_MANY_STATUS = "Statuses/deleteMany.json";

/* get job reminder */
export const GET_JOB_REMINDER = "JobsReminder/index.json";

/* add job reminder */
export const ADD_JOB_REMINDER = "JobsReminder/add.json";

/* update job reminder */
export const UPDATE_JOB_REMINDER = "JobAttachments/edit.json";

/* DELETE job reminder */
export const DELETE_JOB_REMINDER = "JobAttachments/delete.json";

/* add attachment */
export const ADD_ATTACHMENT = "JobAttachments/add.json";

/* GET attachment */
export const GET_ATTACHMENT = "JobAttachments/index.json";

/* Copy attachment */
export const COPY_ATTACHMENT = "JobAttachments/copyMany.json";

// KIT PART
export const GET_KIT_PART_LIST = "KitPartsDetails/index.json";

// KIT PART DELETE
export const DELETE_KIT_PART = "KitPartsDetails/delete.json";

/****************************
 ░░░░░░░░ WORKSHOP BAYS ░░░░░░░░
 ****************************/
export const GET_WORKSHOP_BAYS_LIST = "WorkshopBaysDetails/index.json";
export const ADD_WORKSHOP_BAY = "WorkshopBaysDetails/add.json";
export const VIEW_WORKSHOP_BAY = "WorkshopBaysDetails/view.json";
export const UPDATE_WORKSHOP_BAY = "WorkshopBaysDetails/edit.json";
export const DELETE_WORKSHOP_BAY = "WorkshopBaysDetails/delete.json";

export const DELETE_INVOICE_PAYMENT = "InvoicesPaymentsCredits/deleteMany.json";
export const DELETE_INVOICE_CREDIT =
  "InvoicesPaymentsCreditsOtherDetails/deleteMany.json";

export const DELETE_INVOICE_STATEMENT = "InvoiceStatement/deleteMany.json";

/* GET Subscription Plans Data */
export const GET_SUBSCRIPTION_PLANS = "SubscriptionPlan/index.json";
export const SUBSCRIPTION_CHECKOUT = "SubscriptionsCheckout/saveOrder.json";

export const SAVE_CASH_SALE_INVOICE = "CashSaleInvoice/add.json";
export const SAVE_CASH_SALE_INVOICE_PARTS = "CashSaleInvoiceParts/add.json";
export const SAVE_CASH_SALE_INVOICE_LABOUR = "CashSaleInvoiceLabours/add.json";
export const DELETE_CASH_SALE_PART = "CashSaleInvoiceParts/delete.json";

/* GET Subscription Plans Data */
/***************************************
 ░░░░░░░░ SUPPLIER INTEGRATION ░░░░░░░░
 ***************************************/

export const GET_SUPPLIER_INTEGRATION = "SupplierIntegration/index.json";
export const UPDATE_SUPPLIER_INTEGRATION = "SupplierIntegration/edit.json";
export const SAVE_SUPPLIER_INTEGRATION = "SupplierIntegration/add.json";

/* Digital Inspection*/
export const GET_DIGITAL_INSPECTIONS = "InspInspections/inspIndex.json";


/******************************
 ░░░░ PURCHASE ORDER START ░░░░
*******************************/

/* GET purchase order list */
export const GET_PURCHASE_ORDER = "PurchaseOrders/index.json";

/* GET receipt purchase order list */
export const GET_RECEIPT_PURCHASE_ORDER = "PurchaseOrdersReciepts/index.json";

/* GET credit purchase order list */
export const GET_CREDIT_PURCHASE_ORDER = "PurchaseOrdersCredits/index.json";

/* GET raised search purchase order list */
export const RAISED_SEARCH_PURCHASE_ORDER_LIST =
  "PurchaseOrders/globalSearch.json";

/* GET completed search purchase order list */
export const COMPLETED_SEARCH_PURCHASE_ORDER_LIST =
  "PurchaseOrders/globalSearch.json";

/* GET receipts search purchase order list */
export const RECEIPTS_SEARCH_PURCHASE_ORDER_LIST =
  "PurchaseOrdersReciepts/globalSearch.json";

/* GET receipts search purchase order list */
export const GET_RECEIPT_PURCHASE_ORDER_DETAIL = "PurchaseOrdersReciepts/view";


/* GET credit search purchase order list */
export const CREDIT_SEARCH_PURCHASE_ORDER_LIST =
  "PurchaseOrdersCredits/globalSearch.json";

/* GET credit search purchase order list */
export const CREDIT_ORDER_VIEW = "PurchaseOrdersCredits/view";


/* GET purchase order view detail*/
export const GET_PURCHASE_ORDER_VIEW = "PurchaseOrders/view";

/* GET purchase order view detail*/
export const FETCH_PO_JOB_WISE_LIST = "PurchaseOrders/poDataJobWise.json";

/* Save po job wise */
export const SAVE_PO_JOB_WISE = "PurchaseOrders/add.json";

/* Save po job wise */
export const SAVE_AND_SEND_PO_JOB_WISE = "PurchaseOrders/addWithSend.json";


/* CREATE BURSON PO  */
export const CREATE_BURSON_PO = "PurchaseOrders/createBursonPO.json";

/* CREATE BURSON PO NZ */
export const CREATE_BURSON_PO_NZ = "PurchaseOrders/createBursonPONZ.json";


/* Edit purchase order */
export const EDIT_PURCHASE_ORDER = "PurchaseOrders/edit.json";

/* DELETE purchase order */
export const DELETE_PURCHASE_ORDER = "PurchaseOrders/deleteMany.json";

/* DELETE purchase order part*/
export const DELETE_PURCHASE_ORDER_PART = "PurchaseOrdersParts/delete.json";


export const DELETE_RECEIPTS_PURCHASE_ORDER = "PurchaseOrdersReciepts/deleteMany.json";


export const DELETE_CREDIT_PURCHASE_ORDER = "PurchaseOrdersCredits/deleteMany.json";
// delete job

/* GET receipts search purchase order list */
export const CREATE_PURCHASE_ORDER_RECEIPTS = "PurchaseOrdersReciepts/addReact.json"

/* GET receipts search purchase order list */
export const CREATE_PURCHASE_ORDER_CREDIT = "PurchaseOrdersCredits/addReact.json"

export const CREATE_RECEIPT_CREDIT_NOTE = "PurchaseOrdersCredits/add.json"

export const SEND_PURCHASE_ORDER_EMAIL = "PurchaseOrders/sendPOMail.json"

/****************************
 ░░░░ PURCHASE ORDER END ░░░░
*****************************/

/****************************
 ░░░░ STORAGE DEVICE START ░░░░
*****************************/
/* GET storage device list */
export const GET_STORAGE_DEVICES_LIST = "StorageDevices/index.json";

/****************************
 ░░░░ STORAGE DEVICE END ░░░░
*****************************/

/* Inspection Settings  */
export const GET_TEMPLATE_LIST = "InspTemplates/index.json";
export const DELETE_TEMPLATE = "InspTemplates/delete.json";
export const GET_CATEGORIES_LIST = "Categories/index.json";
export const GET_SECTION_LIST = "InspGroups/index.json";
export const ADD_TEMPLATE_CATEGORY = "Categories/add.json";
export const ADD_TEMPLATE_SECTION = "InspGroups/add.json";
export const ADD_TEMPLATE = "InspTemplates/add.json";
export const UPDATE_TEMPLATE = "InspTemplates/edit.json";
export const DELETE_CATEGORY = "Categories/delete.json";
export const DELETE_SECTION = "InspGroups/delete.json";
export const COPY_TEMPLATE = "InspTemplates/copyTemplate.json";
export const EDIT_CATEGORY = "Categories/view/";
export const UPDATE_CATEGORY = "Categories/edit.json";
export const EDIT_SECTION = "InspGroups/view/";
export const UPDATE_SECTION = "InspGroups/edit.json";
export const DELETE_MULTIPLE_CATEGORIES = "Categories/deleteMany.json";
export const DELETE_MULTIPLE_SECTIONS = "InspGroups/deleteMany.json";

/* GET service reminder list */
// export const GET_SERVICEREMINDER_LIST =
//   "ServiceReminderHistory/reminderList.json";
export const GET_SERVICEREMINDER_LIST =
  "VehicleServiceReminder/index.json";

/* GET service reminder History */
export const GET_SERVICEREMINDER_HISTORY = "ServiceReminderHistory/index.json";

/* SEND REMINDER */
export const SEND_REMINDER = "/ServiceReminderHistory/sendServiceReminder.json";

//GET TIMESLOTS
export const GET_TIME_SLOTS =
  "/JobSchedules/customerAvailibilityTimeSlots.json";

//GET LEAD LIST
export const DISPLAY_ONLINE_JOB = "OnlineSchedule/index.json";
export const ADD_ONLINE_JOB = "OnlineSchedule/add.json";
export const EDIT_ONLINE_JOB = "OnlineSchedule/edit.json";
export const SEND_LEAD_NOTIFICATION = "JobSchedules/sendLeadNotification.json";
export const SEND_LEAD_NOTIFICATION_WORKSHOP = "JobSchedules/sendLeadNotificationWorkshop.json";

export const GET_CUSTOMER_QUOTE_HISTORY = "EmailQuoteHistory/index.json";

export const GET_INTERNAL_NOTE_FOR_LEADS = 'OnlineScheduleInternalNotes/index.json';

export const ADD_INTERNAL_NOTE_FOR_LEAD = "OnlineScheduleInternalNotes/add.json";
export const ADD_INTERNAL_NOTE_ATTACHMENT_FOR_LEAD = "OnlineScheduleInternalNotesAttachment/addMany.json";
export const DELETE_INTERNAL_NOTE_FOR_LEAD = "OnlineScheduleInternalNotes/delete.json";

/****************************
 ░░░░ Settings: Payment  ░░░░
*****************************/

/* Get Stripe Payment Integration Data */
export const GET_STRIPE_PAYMENT_INTEGRATION = "workshops/index.json";

/* Save Stripe Payment Integration Data */
export const SAVE_STRIPE_PAYMENT_INTEGRATION = "workshops/edit.json";

/* Get Gopay Payment Integration Data */
export const GET_GOPAY_PAYMENT_INTEGRATION = "WorkshopOtherDetails/index.json";

/* Save Gopay Payment Integration Data */
export const SAVE_GOPAY_PAYMENT_INTEGRATION = "WorkshopOtherDetails/edit.json";

// setting sync account data
export const GET_SYNC_ACCOUNT = "SyncedAccounts/index.json";

// Tax rates
export const GET_TAX_RATES = "SyncedTaxes/index.json";

// payment processing fees 
export const GET_PAYMENT_PROCESSING_FEES = "PaymentProcessingFees/index.json";

// update payment processing fees 
export const UPDATE_PAYMENT_PROCESSING_FEES = "PaymentProcessingFees/addMany.json";

/* Canned Responses Settings  */
export const DELETE_SCRIPT = "WorkshopScriptsDetails/delete.json";
export const ADD_SCRIPT = "WorkshopScriptsDetails/add.json";
export const EDIT_SCRIPT = "WorkshopScriptsDetails/edit.json";
export const VIEW_SCRIPT = "WorkshopScriptsDetails/view/";

// Get payment terms 
export const GET_PAYMENT_TERMS = "PaymentTerms/index.json";

//delete Payment term
export const DELETE_PAYMENT_TERM = "PaymentTerms/delete.json";

//edit Payment term
export const EDIT_PAYMENT_TERM = "PaymentTerms/view";

//add Payment term
export const ADD_PAYMENT_TERM = "PaymentTerms/add.json";

//update Payment term
export const UPDATE_PAYMENT_TERM = "PaymentTerms/edit.json";
/* Edit Message template data */
export const EDIT_MESSAGE_TEMPLATE_DETAILS = "CustomNotifications/view/";

/* Add - Update Message template data */
export const ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS = "CustomNotifications/add.json";

/* Delete Message template data */
export const DELETE_MESSAGE_TEMPLATE_DETAILS = "CustomNotifications/delete.json";

/****************************
 ░░░░ 3rd Party Company ░░░░
*****************************/

/* Edit Third party company data */
export const EDIT_THIRD_PARTY_COMPANY = "InsuranceCompany/index.json";

/* Delete Third party company data */
export const DELETE_THIRD_PARTY_COMPANY = "InsuranceCompany/delete.json";

/* Add Third party company data */
export const ADD_THIRD_PARTY_COMPANY_DETAILS = "InsuranceCompany/add.json";

/* Update Third party company data */
export const UPDATE_THIRD_PARTY_COMPANY_DETAILS = "InsuranceCompany/edit.json";

/* GET VEHICLE DETAIL */
export const VEHICLEDETAIL = "Vehicles/view";

/* GET VEHICLE LEGACY HISTORY */
export const VEHICLELEGACYHISTORY = "LegacyHistories/index.json";

/* Delete VEHICLE  */
export const DELETEVEHICLE = "Vehicles/delete.json";

/* Delete multiple VEHICLE  */
export const DELETEMULTIVEHICLE = "Vehicles/deleteMany.json";

/* Search VEHICLE  */
export const SEARCHVEHICLE = "/Vehicles/searchVehicles.json";

/* Delete Customer */
export const DELETE_CUSTOMER = "CustomersDetails/delete.json";

/* Delete SUPPLIERS */
export const DELETE_SUPPLIERS = "Suppliers/delete.json";

/* Delete Employee */
export const DELETE_EMPLOYEE = "users/delete.json";

/* Delete HQ Employee */
export const DELETE_HQ_EMPLOYEE = "HeadQuartersEmployeesDetails/delete.json";

/* Delete Employee */
export const GETSUPPLIERBRANCH = "SuppliersBranches/index.json";

/* Delete SUPPLIERS BRANCH */
export const DELETE_SUPPLIERS_BRANCH = "SuppliersBranches/delete.json";

/* GET CUSTOMER DETAILS */
export const GET_CUSTOMER_DETAILS = "CustomersDetails/view/";

/* Delete Vehicle */
export const DELETE_VEHICLE = "Vehicles/delete.json";

/* GET CUSTOMER NOTES */
export const GET_CUSTOMER_NOTES = "CustomerNotes/index.json";

/* DELETE CUSTOMER NOTES */
export const DELETE_CUSTOMER_NOTES = "CustomerNotes/delete.json";

/* ADD CUSTOMER NOTES */
export const ADD_CUSTOMER_NOTES = "CustomerNotes/add.json";

/* GET CUSTOMER ATTACHMENT */
export const GET_CUSTOMER_ATTACHMENT = "CustomerAttachment/index.json";

/* GET SUPPLIERS */
export const GET_SUPPLIERS = "Suppliers/view/";

/* GET HQ SUPPLIERS */
export const HQ_GET_SUPPLIERS = "HeadQuartersSuppliers/view/";

/* UPDATE SUPPLIERS */
export const UPDATE_SUPPLIERS = "Suppliers/edit.json";

/* UPDATE HQ SUPPLIERS */
export const HQ_UPDATE_SUPPLIERS = "HeadQuartersSuppliers/edit.json";

/* ADD SUPPLIERS BRANCH */
export const ADD_SUPPLIER_BRANCHE = "SuppliersBranches/add.json";

/* GET EMPLOYEE ROLES */
export const GET_EMPLOYEE_ROLES = "Roles/index.json";

/* GET WROKSHOP SUBSCRIPTION */
export const GET_WROKSHOP_SUBSCRIPTION = "WorkshopSubscription/index.json";

/* VIEW SUBSCRIPTION PLAN */
export const VIEW_SUBSCRIPTION_PLAN = "SubscriptionPlan/view/";

/* GET EMPLOYEE NOTES */
export const GET_EMPLOYEE_NOTES = "EmployeeNotes/index.json";

/* ADD EMPLOYEE NOTES */
export const ADD_EMPLOYEE_NOTES = "EmployeeNotes/add.json";

/* DELETE EMPLOYEE NOTES */
export const DELETE_EMPLOYEE_NOTES = "EmployeeNotes/delete.json";

/* DELETE EMPLOYEE ATTACHMENT */
export const DELETE_EMPLOYEE_ATTACHMENT = "UserAttachmentDetails/delete.json";

/* ADD EMPLOYEE ATTACHMENT */
export const ADD_EMPLOYEE_ATTACHMENT = "UserAttachmentDetails/add.json";

/* SEND USER CREDENTIALS */
export const SEND_USER_CREDENTIALS = "users/sendCredentialsToUser.json";

/* EDIT SUPPLIER BRANCH */
export const EDIT_SUPPLIER_BRANCH = "SuppliersBranches/edit.json";

/* VIEW SUPPLIER BRANCH */
export const VIEW_SUPPLIER_BRANCH = "SuppliersBranches/view/";

/* ADD CUSTOMER ATTACHMENT */
export const ADD_CUSTOMER_ATTACHMENT = "CustomerAttachment/add.json";

/* DELETE CUSTOMER ATTACHMENT */
export const DELETE_CUSTOMER_ATTACHMENT = "CustomerAttachment/delete.json";

// DELETE MANY CUSTOMER
export const DELETE_MANY_CUSTOMER = "CustomersDetails/deleteMany.json";

// SUPPLIERS MANY CUSTOMER
export const DELETE_MANY_SUPPLIER = "Suppliers/deleteMany.json";

// SUPPLIERS MANY EMPLOYEE
export const DELETE_MANY_EMPLOYEE = "EmployeesDetails/deleteMany.json";

// CUSTOMER SEARCH
export const CUSTOMER_SEARCH = "CustomersDetails/searchCustomer.json";

// SUPPLIER SEARCH
export const SUPPLIER_SEARCH = "Suppliers/searchSupplier.json";

// SEND CUSTOMER CREDENTIALS 
export const SEND_CUSTOMER_CREDENTIALS = "users/sendCredentialsToUserForCustomer.json";

// EDIT CUSTOMER LOGIN ACCESS 
export const EDIT_CUSTOMER_LOGIN_ACCESS = "UserLogin/giveLoginAccess.json";

// Edit Storage Device
export const EDIT_STORAGE_DEVICE = "StorageDevices/view/";

// Add Storage Device
export const ADD_STORAGE_DEVICE = "StorageDevices/add.json";

// Update Storage Device
export const UPDATE_STORAGE_DEVICE = "StorageDevices/edit.json";

// Delete Storage Device
export const DELETE_STORAGE_DEVICE = "StorageDevices/delete.json";

export const ADD_IMPORT_UTILITY = "UtilityImportExport/add.json";

/* Account Sync */
export const CONNECT_QUICKBOOK = "QuickBook/connectQuickBook.json";
export const DISCONNECT_QUICKBOOK = "QuickBook/disconnectQuickBook.json";
export const UPDATE_WORKSHOP_DETAIL = "Workshops/edit.json";
export const GET_SYNC_ACCOUNT_DATA = "SyncedAccounts/index.json";
export const GET_SYNC_TAX_DATA = "SyncedTaxes/index.json";
export const UPDATE_SYNC_ACCOUNT_DATA = "SyncedAccounts/syncAccounts.json";
export const UPDATE_SYNC_TAX_DATA = "SyncedTaxes/syncTaxes.json";
export const GET_USERS = "Users/index.json";
export const SYNC_TRANSFER = "SyncData/transferSync.json";
export const CRON_SYNC_TRANSFER = "SyncData/cronTransferSync.json";
export const GET_SYNC_DATA = "SyncData/index.json";
export const UPDATE_MANY_WORKSHOP_TAX_DETAILS = "WorkshopTaxRatesDetails/editMany.json";
export const UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS = "WorkshopDepartmentsAccountsDetails/editMany.json";
export const DELETE_SYNC_ACCOUNTS = "SyncedAccounts/deleteByWorkshop.json";
export const DELETE_SYNC_TAX = "SyncedTaxes/deleteByWorkshop.json";
export const CONNECT_XERO = "Xero/xeroConnect.json";
export const DISCONNECT_XERO = "Xero/xeroDisconnect.json";
export const IMPORT_MYOB = "SyncData/importData.json";
export const INSPECTION_SEND_VERIFICATION_MESSAGE =
  "InspInspections/sendVerificationSMS.json";

export const INSPECTION_ATTACHMENT = "JobAttachments/digitalInspection.json";

export const DELETE_INSPECTION_QUESTION = "/InspQuestionAns/deleteMany.json";
export const DELETE_INSPECTION_TEMPLATE_QUE = "/InspQuestion/delete.json";

export const GET_INVENTORY_STOCK_LIST = "InventoryStock/index.json";

export const SAVE_STOCK = "InventoryStock/add.json";

export const UPDATE_PART_DATA = "Products/edit.json";

export const DELETE_STOCK_DATA = "InventoryStock/delete.json";

export const GET_INVENTORY_STOCK_TAKE_LIST = "InventoryStockTake/index.json";

export const EDIT_STOCK_TAKE_DETAILS = "InventoryStockTake/view/";

export const VIEW_STORAGE_DEVICE_DETAILS = "StorageDevices/view/";

export const SAVE_STOCK_TAKE = "InventoryStockTake/add.json";

export const UPDATE_PARTS_QTY = "InventoryStock/updatePartsQnty.json";

export const GET_USER_DATA_BY_ID = "users/userViewData";

// EDIT SUBSCRIPTION
export const EDIT_SUBSCRIPTION = "SubscriptionsCheckout/editSubscriptions.json";

// Capricorn SUBSCRIPTION
export const CAPRICORN_SUBSCRIPTION = "SubscriptionsCheckout/addCapriCornSubscriptions.json";
export const EDIT_CAPRICORN_SUBSCRIPTION = "SubscriptionsCheckout/editCapriCornSubscriptions.json";

/* REPORTS URL */
export const INVOICE_EOD_PAYMENT_CHART = "Invoices/invoiceEndofDayPaymentChart.json";
export const INVOICE_EOD_CHART = "Invoices/invoiceEndofDayChart.json";
export const INVOICE_PAYMENT_CHART = "Invoices/invoicePaymentChart.json";
// export const INVOICE_EOD_PAYMENT_CHART = "/Invoices/invoiceEndofDayPaymentChart.json";
/* REPORTS URL END */

/****************************
 ░░░░ REPORTS START ░░░░
*****************************/


export const JOB_REPORT_TABLE = "/JobSchedules/JobReportTable.json";

export const JOB_REPORT_CHART = "/JobSchedules/JobChartData.json";

export const JOB_STATS_CHART = "/JobSchedules/JobStatsData.json";


export const QUOTE_REPORT_TABLE = "CustomerQuotes/QuoteTable.json";

export const QUOTE_COVERSION_DATA = "CustomerQuotes/QuoteConversion.json";

export const QUOTE_BY_CATEGORY = "CustomerQuotes/QuoteByCategory.json";

export const MAKE_TYPE_CUSTOMER_REPORT = 'JobSchedules/avgSpendPerMakeTypeCustReport.json';

export const JOB_TYPE_CUSTOMER_REPORT = 'JobSchedules/avgSpendPerJobTypeCustReport.json';

export const CUSTOMER_REVENUE_REPORT = 'JobSchedules/getRevenueCustomer.json';

export const LABOUR_HOURS_DATA = 'JobSchedules/HoursLabour.json';

export const EFFECTIVE_LABOUR_DATA = 'JobSchedules/EffectiveLabour.json';

export const MATERIAL_PURCHASE_ORDER = 'PurchaseOrders/index.json';

export const MATERIAL_PURCHASE_ORDER_CHART = 'PurchaseOrders/POReportChartData.json';

export const INVENTORY_STOCK_DATA = 'InventoryStock/index.json';

export const INVENTORY_STOCK_TAKE_DATA = 'InventoryStockTake/index.json';

/****************************
 ░░░░ REPORTS END ░░░░
*****************************/

// workshop tax get 
export const GET_WORKSHOP_TAX = "WorkshopTaxRatesDetails/index.json";

// workshop tax update 
export const WORKSHOP_TAX_UPDATE = "WorkshopTaxRatesDetails/editMany.json";

// account detail select account  
export const ADD_WORKSHOP_OTHER_DETAILS = "WorkshopOtherDetails/add.json";

export const ADD_MANY_DEPARTMENT_ACCOUNT = "WorkshopDepartmentsAccountsDetails/addmany.json";

// Get Workshop subscription history list
export const GET_SUBSCRIPTION_HISTORY_LIST = "SubscriptionHistory/index.json";

// Get Workshop subscription Deatil history list
export const GET_SUBSCRIPTION_HISTORY_DETAIL_LIST = "SubscriptionHistoryDetails/index.json";

export const SEND_AWS_EMAIL_VERIFICATION = "AwsEmailVerification/custom_email_verification.json";


export const ADD_NOTIFICATION_TOKEN = "PushNotification/add.json";
export const DELETE_NOTIFICATION_TOKEN = "PushNotification/delete.json";

// Inspection question answer list
export const GET_INSPECTION_QUE_ANS = "InspQuestionAns/index.json";

// Delete Leads
export const DELETE_LEADS = "OnlineSchedule/deleteMany.json";

// Add_tags
export const Add_tags = "Tags/addMany.json";

// bays job count
export const GET_BAYS_JOB_COUNT = "JobSchedules/baysJobCount.json";

// update job activity
export const UPDATE_JOB_ACTIVITY = "JobSchedules/updateMulitpleActivity.json";

// bays job count
export const UPDATE_MANY_JOBS = "JobSchedules/addMany.json";

// Get pos history 
export const GET_POS_HISTORY = "workshops/index.json";


/***********************
 ░░░░ HQ URLs  ░░░░
************************/
export const GET_HQ_WORKSHOP_LIST = "Workshops/index.json"

/***********************
 ░░░░ NEW ZEALAND PARTS   ░░░░
************************/
export const NZ_ADD_BURSON_PART = "Products/redirectBursonPart.json"

/******************************************
 ░░░░ EMPLOYEE CLOCKING HISTORY START ░░░░
*******************************************/

// Get pos history
export const GET_EMPLOYEE_CLOCKING_HISTORY = "EmployeeClockingHistory/index.json";

export const GET_EMPLOYEE_WISE_CLOCKING_HISTORY = "EmployeeClockingHistory/empWiseClocking.json";

export const ADD_EMPLOYEE_CLOCKING_HISTORY = "EmployeeClockingHistory/add.json";

export const UPDATE_EMPLOYEE_CLOCKING_HISTORY = "EmployeeClockingHistory/edit.json";

export const DAILY_CLOCKING_REPORT = "EmployeeClockingHistory/dailyClockingReports.json";


/******************************************
 ░░░░ EMPLOYEE CLOCKING HISTORY END ░░░░
*******************************************/


/******************************************
 ░░░░ JOB CLOCKING HISTORY START ░░░░
*******************************************/

// Get pos history
export const GET_JOB_CLOCKING_HISTORY = "JobClockingHistory/index.json";

export const GET_JOB_WISE_CLOCKING_HISTORY = "JobClockingHistory/empWiseClocking.json";

export const ADD_JOB_CLOCKING_HISTORY = "JobClockingHistory/add.json";

export const UPDATE_JOB_CLOCKING_HISTORY = "JobClockingHistory/edit.json";

export const UPDATE_JOB_LABOUR_BY_CLOCKING = "JobClockingHistory/updateJobLabour.json";

export const JOB_CLOCKING_REPORT = "JobClockingHistory/jobClockingReports.json";

export const CHECK_JOB_SLOT = "JobSchedules/checkJobSlots.json";

export const GET_JOB_CLOCKING_HISTORY_DATA = "JobClockingHistory/getJobClockingHistory.json";

export const CLOSE_ALL_CLOCKING = "JobClockingHistory/jobClockingStopByTechnician.json";

export const IS_CLOCKING_RUNNING = "JobClockingHistory/isClockingRunning.json";
/******************************************
 ░░░░ EMPLOYEE CLOCKING HISTORY END ░░░░
*******************************************/