import { async } from '@firebase/util';
import { checkResetLink, updatePassword } from 'helpers/backendHelper';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { showError, showSuccess } from 'utils/alertMessage';
import Loader from 'views/common/UI/Loader';
import logo from "assets/images/5iQ_logo_black.png";
import { GET_FORGOT_EMAIL } from "../../../store/forgotEmail/actionTypes";
import { Link } from 'react-router-dom';

let ResetPassword = () => {

  let { token } = useParams();
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    checkResetPasswordLink(token);
  },[token])

  async function checkResetPasswordLink(token){
    
    let data = {
      payload: {
        reset_code: token
      }
    }

    let response = await checkResetLink(data);
    
    if(response.code != 200){
      showError("Invalid Reset Password code.")
      // navigate("/login");
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {

      setIsLoading(true);

      let data = {
        payload: {
          reset_code: token,
          password: password,
        }
      }

      let response = await updatePassword(data);
      if(response.code === 200){
        showSuccess(response.message);
        setIsLoading(false);
        navigate("/login");
      } else {
        showError(response.message);
        setIsLoading(false);
        navigate("/login");
      }

      
    }
    setValidated(true);
  };


  if(isLoading){
    return (<Loader />)
  }
  

  return (
    <div>
      <div className="pre-login-wrapper" style={{marginTop:"200px"}}> 

        {/* main div section START */}
        <div class="row">

          {/* left content START */}
          <div class="column left-column" >
            <img className="logo" src={logo} alt=""></img>
            <p className="mt-4"> "Together Let's Shape Tomorrow" </p>
          </div>
          {/* left content END */}

          {/* right content START */}
          <div class="column right-column" >

          <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <h1 className="mb-2">Reset password</h1>
            <Form.Group className="form-group mb-3 has-fa-icon">
              <Form.Control
                values={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                type={passwordShow ? "text" : "password"}
                placeholder="Password"
                onBlur={(e) => setPassword(e.target.value)}
              />
              <span style={{cursor:"pointer"}} onClick={(e) => setPasswordShow(!passwordShow)} className={passwordShow ? "fa fa-eye-slash"  : "fa fa-eye"}></span>
              <Form.Control.Feedback type="invalid">
                Please Enter Password
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group mb-3 has-fa-icon">
              <Form.Control
                required
                values={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={confirmPasswordShow ? "text" : "password"}
                placeholder="Confirm Password"
                onBlur={(e) => setConfirmPassword(e.target.value)}
              />
              <span style={{cursor:"pointer"}} onClick={(e) => setConfirmPasswordShow(!confirmPasswordShow)} className={confirmPasswordShow ? "fa fa-eye-slash"  : "fa fa-eye"}></span>
              <Form.Control.Feedback type="invalid">
                Please Enter Confirm Password
              </Form.Control.Feedback>
            </Form.Group>
            <div className="btn-wrapper">
              <Button type="submit" variant="primary" className="primary-btn">
                UPDATE
              </Button>
            </div>
            </Form>

            <div className="pre-login-footer mt-3">
              <p className="mb-2">By<Link to="/register" className="primary-link">signing up</Link>for 5iQ account, you accept 5iQ</p>
              <p>
                <a href={"https://fiiviq.com/terms-conditions"} className="primary-link">
                  Terms of Use
                </a>
                <span>and</span>
                <a href={"https://fiiviq.com/privacy-policy"} className="primary-link">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
          {/* right content END */}

        </div>
        {/* main div section END */}

        
      </div>
      
    </div>
  );


 }

export default ResetPassword;