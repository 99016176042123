import { 
    GET_SUPPLIER_STATEMENT, 
    GET_SUPPLIER_STATEMENT_FAILURE, 
    GET_SUPPLIER_STATEMENT_SUCCESS,
    GET_SUPPLIER_LIST_STATEMENT, 
    GET_SUPPLIER_LIST_STATEMENT_FAILURE, 
    GET_SUPPLIER_LIST_STATEMENT_SUCCESS,
    GET_SUPPLIER_STATEMENT_COUNT,
    GET_SUPPLIER_STATEMENT_COUNT_FAILURE,
    GET_SUPPLIER_STATEMENT_COUNT_SUCCESS,
    GET_SUPPLIER_LIST_STATEMENT_COUNT, 
    GET_SUPPLIER_LIST_STATEMENT_COUNT_FAILURE, 
    GET_SUPPLIER_LIST_STATEMENT_COUNT_SUCCESS,
} from "../actionType";

const supplierStatementState = {
    loading: true,
    data: [],
    error: undefined,
    count:0,
    dataSupplier: [],
    countSupplier:0,
};

export const SupplierStatementReducer = (state = supplierStatementState, action) => {
    switch (action.type) {
        case GET_SUPPLIER_STATEMENT:
            return {
                ...state,
                loading: true,
                data: [],
                error: undefined
            };
        case GET_SUPPLIER_STATEMENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: undefined
            };
        case GET_SUPPLIER_STATEMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: []
            };

        case GET_SUPPLIER_STATEMENT_COUNT:
            return {
                ...state,
                data: {
                ...state.data,
                count: 0,
                },
            };
        case GET_SUPPLIER_STATEMENT_COUNT_SUCCESS:
            return {
                ...state,
                data: {
                ...state.data,
                count: action.payload,
                },
            };
        case GET_SUPPLIER_STATEMENT_COUNT_FAILURE:
            return {
                ...state,
                data: {
                ...state.data,
                count: 0,
                },
            };

        case GET_SUPPLIER_LIST_STATEMENT:
            return {
                ...state,
                loading: true,
                dataSupplier: [],
                error: undefined
            };
        case GET_SUPPLIER_LIST_STATEMENT_SUCCESS:
            return {
                ...state,
                dataSupplier: action.payload,
                loading: false,
                error: undefined
            };
        case GET_SUPPLIER_LIST_STATEMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                dataSupplier: []
            };
        case GET_SUPPLIER_LIST_STATEMENT_COUNT:
            return {
                ...state,
                dataSupplier: {
                ...state.dataSupplier,
                countSupplier: 0,
                },
            };
        case GET_SUPPLIER_LIST_STATEMENT_COUNT_SUCCESS:
            return {
                ...state,
                dataSupplier: {
                ...state.dataSupplier,
                countSupplier: action.payload,
                },
            };
        case GET_SUPPLIER_LIST_STATEMENT_COUNT_FAILURE:
            return {
                ...state,
                dataSupplier: {
                ...state.dataSupplier,
                countSupplier: 0,
                },
            };

        default:
            return state;
    }
}