import noCarImage from "assets/images/no-car-icon-2.png";
import { updateJob } from "helpers/backendHelper";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { showError } from "utils/alertMessage";

const NoShowWarning2 = ({
  showNoShowModal,
  setShowNoShowModal,
  selectedRow,
  setLoading1,
  setShowSmsPreviewModal
}) => {

  const handleClose = () => setShowNoShowModal(!showNoShowModal);

  const handleSubmitSmsTemplateModalForm = async (isSend = "") => {
    if (selectedRow.length !== 0) {
      setLoading1(true);

      let data = {
        payload: {
          id: selectedRow,
          is_no_show: 1,
          job_status: "NoShow",
          job_stage: "Completed",
          description: "<p>No Show Job</p>",
        },
      };

      const response = await updateJob(data);

      if (response.code === 200) {

        setShowSmsPreviewModal(true);
        setLoading1(false);

      } else {
        showError(response.message);
        handleClose();
        setLoading1(false);
      }
    } else {
      showError("Select at least One Record");
      handleClose();
      setLoading1(false);
    }
  };

  return (
    <>
      <Modal
        style={{ marginTop: "140px" }}
        className="info-dialog rounded-0"
        size="sm"
        show={true}
        onHide={handleClose}

      >
        <Modal.Body className="p-4">
          <div className="text-end">
            <CloseButton
              aria-label="Close"
              onClick={() => {
                handleClose();
                setShowNoShowModal(false);
              }}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-2 bg-white d-flex justify-center align-items-center bg-info">
                <img
                  src={noCarImage}
                  alt=""
                  style={{ maxWidth: 60, maxHeight: 60 }}
                />
                <h2 className="ml-1">No Show</h2>
              </div>
              <div className="mb-2">
                <p className="mb-0 text-center">
                  Are You sure you want to record this Job <br /> as a NO Show?
                </p>
                {/* <p>Inspection #.</p> */}
              </div>

              <div className="d-flex justify-center">
                <Button
                  variant="light"
                  className="rounded-btn border"
                  onClick={() => {
                    handleClose();
                    setShowNoShowModal(false);
                    setShowSmsPreviewModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-success rounded-btn font-size-13"
                  onClick={() => {
                    handleSubmitSmsTemplateModalForm();
                    handleClose();
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>

          </div>
        </Modal.Body>
      </Modal>


    </>
  );
};
export default NoShowWarning2;
