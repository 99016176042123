import "react-bootstrap-typeahead/css/Typeahead.css";
import PartsSearch from "./PartsSearch";
import { useSettingContext } from "./SettingProvider";

const Typeahead = ({ onPartClick, createNewPart, setSearchStatus }) => {
  const setting = useSettingContext();

  return (
    <div>
      <PartsSearch
        setSearchPartName={setting?.setSearchPartName}
        createNewPart={createNewPart}
        searchKit={setting?.searchKit}
        searchSupplier={setting?.supplierID}
        onChangeEvent={(e) => {
          onPartClick?.(e[0]);
        }}
        setSearchStatus={setSearchStatus}
      />
      {/* <div style={{ marginTop: "10px", display: "none" }}>
        <Button id="clear-auto-search">Clear</Button>
      </div> */}
    </div>
  );
};

export default Typeahead;
