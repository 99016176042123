import React, { useContext } from "react";

export const SecurityContext = React.createContext();

const SecurityProvider = ({ currentSecurity, children }) => {
  return (
    <SecurityContext.Provider value={currentSecurity}>
      {children}
    </SecurityContext.Provider>
  );
};

export default SecurityProvider;

export function useSecurityProvider() {
  return useContext(SecurityContext);
}
