export const getPartsPayload = (
  query,
  workshop_id,
  notInIds = [],
  serchKit = false,
  searchSupplier,
  storage_devices_id
) => {

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let default_storage_device_id = storedData?.default_storage_device_id ? window.atob(storedData?.default_storage_device_id) : "";

  if (storage_devices_id) {
    default_storage_device_id = storage_devices_id;
  } 
  
  const payload = {
    get: "all",
    conditions: {
      'Products.workshop_id': workshop_id,
      'Products.status': 1,
      "Products.product_type IN": [1],
      'Products.add_from': 1,
      'Products.is_deleted': 0,
      OR: {
        "Products.name LIKE": "%" + query + "%",
        "Products.product_number LIKE": "%" + query + "%",
      },
    },
    contain: {
      // "InventoryStockSearch": {
      //   conditions: {
      //     "InventoryStockSearch.storage_devices_id": default_storage_device_id,
      //     "InventoryStockSearch.workshop_id": workshop_id,
      //   },
      //   sort: { "InventoryStockSearch.id": "desc" },
      // },
      "SuppliersSearch": {
        conditions: {
          "SuppliersSearch.is_deleted": 0,
        },
      },
    },
    // 'contain': ['SuppliersSearch'],
    // contain: {
    //   // 'Workshops.WorkshopTaxRatesDetails': {
    //   //     'conditions': {
    //   //         'application': 'part'
    //   //     }
    //   // },
    //   'Suppliers': {
    //       'conditions': {
    //           'Suppliers.status': 1
    //       }
    //   }
    // },
    limit: 50,
    q: query,
  };

  if (serchKit) {
    payload["conditions"]["Products.product_type IN"] = [1, 2];
  }

  if (notInIds.length !== 0) {
    payload.conditions["Products.id NOT IN"] = notInIds;
  }
  if (searchSupplier) {
    payload["conditions"]["Products.supplier_id"] = searchSupplier;
  }
  return payload;
};
