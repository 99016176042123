import {
  GET_UPDATE_VEHICLE,
  GET_UPDATE_VEHICLE_SUCCESS,
  GET_UPDATE_VEHICLE_FAIL,
  GET_VEHICLES_HISTORY,
  GET_VEHICLES_HISTORY_SUCCESS,
  GET_VEHICLES_HISTORY_FAIL,
  ADD_VEHICLES_HISTORY,
  ADD_VEHICLES_HISTORY_SUCCESS,
  ADD_VEHICLES_HISTORY_FAIL
} from "./actionTypes";


export const getUpdateVehicle = () => {
  return {
    type: GET_UPDATE_VEHICLE,
  };
};

export const getUpdateVehicleSuccess = (data) => {
  return {
    type: GET_UPDATE_VEHICLE_SUCCESS,
    payload: data,
  };
};

export const getUpdateVehicleFail = (error) => {
  return {
    type: GET_UPDATE_VEHICLE_FAIL,
    payload: error,
  };
};

export const getVehiclesHistory = () => {
  return {
    type: GET_VEHICLES_HISTORY,
  };
};

export const getVehiclesHistorySuccess = (data) => {
  return {
    type: GET_VEHICLES_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getVehiclesHistoryFail = (error) => {
  return {
    type: GET_VEHICLES_HISTORY_FAIL,
    payload: error,
  };
};

export const addVehiclesHistory = () => {
  return {
    type: ADD_VEHICLES_HISTORY,
  };
};

export const addVehiclesHistorySuccess = (data) => {
  return {
    type: ADD_VEHICLES_HISTORY_SUCCESS,
    payload: data,
  };
};

export const addVehiclesHistoryFail = (error) => {
  return {
    type: ADD_VEHICLES_HISTORY_FAIL,
    payload: error,
  };
};
