export const GET_LEADS = "GET_LEADS";
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS";
export const GET_LEADS_FAIL = "GET_LEADS_FAIL";

export const GET_LEAD_DETAIL = "GET_LEAD_DETAIL";
export const GET_LEAD_DETAIL_SUCCESS = "GET_LEAD_DETAIL_SUCCESS";
export const GET_LEAD_DETAIL_FAIL = "GET_LEAD_DETAIL_FAIL";

export const CONVERT_LEAD_TO_JOB = "CONVERT_LEAD_TO_JOB";
export const CONVERT_LEAD_TO_JOB_SUCCESS = "CONVERT_LEAD_TO_JOB_SUCCESS";
export const CONVERT_LEAD_TO_JOB_FAIL = "CONVERT_LEAD_TO_JOB_FAIL";

export const CONVERT_LEAD_TO_QUOTE = "CONVERT_LEAD_TO_QUOTE";
export const CONVERT_LEAD_TO_QUOTE_SUCCESS = "CONVERT_LEAD_TO_QUOTE_SUCCESS";
export const CONVERT_LEAD_TO_QUOTE_FAIL = "CONVERT_LEAD_TO_QUOTE_FAIL";

export const UPDATE_LEAD = "UPDATE_LEAD";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL";

export const GET_INTERNAL_NOTE_WITH_SCRIPTS = "GET_INTERNAL_NOTE_WITH_SCRIPTS";
export const GET_INTERNAL_NOTE_WITH_SCRIPTS_SUCCESS =
  "GET_INTERNAL_NOTE_WITH_SCRIPTS_SUCCESS";
export const GET_INTERNAL_NOTE_WITH_SCRIPTS_FAIL =
  "GET_INTERNAL_NOTE_WITH_SCRIPTS_FAIL";

export const ADD_INTERNAL_NOTE_TO_LEAD = "ADD_INTERNAL_NOTE_TO_LEAD";
export const ADD_INTERNAL_NOTE_TO_LEAD_SUCCESS =
  "ADD_INTERNAL_NOTE_TO_LEAD_SUCCESS";
export const ADD_INTERNAL_NOTE_TO_LEAD_FAIL = "ADD_INTERNAL_NOTE_TO_LEAD_FAIL";

export const DELETE_INTERNAL_NOTE_TO_LEAD = "DELETE_INTERNAL_NOTE_TO_LEAD";
export const DELETE_INTERNAL_NOTE_TO_LEAD_SUCCESS =
  "DELETE_INTERNAL_NOTE_TO_LEAD_SUCCESS";
export const DELETE_INTERNAL_NOTE_TO_LEAD_FAIL =
  "DELETE_INTERNAL_NOTE_TO_LEAD_FAIL";
