import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import { Formik } from "formik";
import { useEffect, useRef, useState, useMemo } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { useDispatch } from "react-redux";
import Select from "react-select";

import
  {
    GET_TIMEZONE,
    UPDATE_COMPANY_DETAIL, UPDATE_COMPANY_OTHER_DETAIL
  } from "../../../../store/companyDetails/actionTypes";
import { GET_COUNTRY, GET_STATE } from "../../../../store/register/actionTypes";

import { downloadFileFromS3, UploadFileToS3 } from "../../../../utils/uploadFileToS3";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { getSessionData } from "utils/getWorkshopId";
import { mobileRegex, numberRegex } from "utils/validationRegex";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const CompanyDetailForm = ({ userData, companyDetailData,setUpdateCompanyDetails,updateCompanyDetails }) => {
  const dispatch = useDispatch(); // Dispatch variable
  const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
  const [workShopDetails, setWorkShopDetails] = useState();
  const [workShopOtherDetails, setWorkShopOtherDetails] = useState();
  const [selectedCurrency,setSelectedCurrency] = useState("$");
  const filePondRef = useRef();
  const [countryTimezone,setCountryTimezone] = useState([]);
  const [allTimezone,setAllTimezone] = useState([]);
  const [workshopCountry,setWorkshopCountry] = useState();
  const NzCountryId = process.env.REACT_APP_REGO_NZ_ID;
  
  const [workshopState,setWorkshopState] = useState();

  // const [updateCompanyDetails,setUpdateCompanyDetails] = useState(false);
  let worshopLogo = getSessionData("workshop_logo", false);
  const odometerOptions = [
    { value: "1", label: "Km" },
    { value: "2", label: "Mileage" },
  ];

  let countryData = [];
  let languageData = [];
  let currencyData = [];
  let stateData = [];
  let timeZoneData = [];

  useEffect(() => {
    dispatch({
      type: GET_COUNTRY,
      payload: {
        get: "all",
        conditions: {
          status: 1,
        },
      },
    });

    dispatch({
      type: GET_TIMEZONE,
      payload: {
        get: "all",
        conditions: {
          status: 1,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (companyDetailData.workShopDetails) {
      if(companyDetailData?.workShopDetails?.logo){
        storedData.workshop_logo = companyDetailData?.workShopDetails?.logo || '';
        sessionStorage.setItem('stored_data',JSON.stringify(storedData));
        let worshopLogo = companyDetailData?.workShopDetails?.logo || '';
      }
      setWorkShopDetails(companyDetailData.workShopDetails);
      if(!workshopCountry){
        setWorkshopCountry(companyDetailData?.workShopDetails?.country?.id);
      }
      if(!workshopState){
        setWorkshopState(companyDetailData?.workShopDetails?.state?.id);
      }
      // setUpdateCompanyDetails(!updateCompanyDetails);
    }
  }, [companyDetailData]);

  const workShopIcon = useMemo(() => {
    if (worshopLogo) {
      const url = downloadFileFromS3(worshopLogo);
      return url;
    }
  }, [worshopLogo]);

  useEffect(() => {
    if (companyDetailData.workShopOtherDetails) {
      setWorkShopOtherDetails(companyDetailData.workShopOtherDetails);
    }
  }, [companyDetailData]);

  useEffect(() => {
    if (workShopDetails) {
      dispatch({
        type: GET_STATE,
        payload: {
          get: "all",
          conditions: {
            is_deleted: 0,
            country_id: workshopCountry,
          },
          order: {
            name: "asc",
          },
          fields: ["id", "name"],
        },
      });
    }
  }, [workShopDetails]);

  // Create a list for display country data
  if (userData.countries != null) {
    const country = userData.countries;
    country.map((countryName) =>
      countryData.push({
        value: countryName.id,
        label: countryName.country_name,
      })
    );
  }

  useEffect(() => {
    if(workShopDetails?.country?.id){
      let timezoneSelectedNew = allTimezone.filter(
        (option) => option.country_id === workShopDetails.country.id
      )
      setCountryTimezone(timezoneSelectedNew);
    }
    
  },[workShopDetails])

  if (userData.countries != null) {
    const country = userData.countries;
    country.map((countryName) =>
      languageData.push({
        value: countryName.id,
        label: countryName.language,
      })
    );
  }

  if (userData.countries != null) {
    const country = userData.countries;
    country.map((countryName) =>
      currencyData.push({
        value: countryName.id,
        label: `${countryName.currency} (${countryName.symbol})`,
        symbol:`${countryName.symbol}`
      })
    );
  }

  // Create a list for display timezone data
  useEffect(() => {
    if (companyDetailData.timezones != null) {
      const timezones = companyDetailData.timezones;
      timezones.map((timezone) =>
        timeZoneData.push({
          value: timezone.time_label,
          label: timezone.timezone,
          country_id:timezone.country_id
        })
      );
      setAllTimezone(timeZoneData);
      
      // let countryTimezoneSelected = timeZoneData.filter(
      //   (option) => option.country_id === workShopDetails?.country?.id
      // )
      // setCountryTimezone(countryTimezoneSelected);
    }
  },[companyDetailData])

  useEffect(() => {
    let countryTimezoneSelected = allTimezone.filter(
      (option) => option.country_id === workshopCountry
    )
    setCountryTimezone(countryTimezoneSelected);
  },[allTimezone])

  //Dispatch function for get state based on selected country
  function handleState(e) {
    var countryId = e.value;
    let timezoneSelected = allTimezone.filter(
      (option) => option.country_id === countryId
    )
    setCountryTimezone(timezoneSelected);
    dispatch({
      type: GET_STATE,
      payload: {
        get: "all",
        conditions: {
          is_deleted: 0,
          country_id: countryId,
        },
        order: {
          name: "asc",
        },
        fields: ["id", "name"],
      },
    });
  }

  // Create a list for display state data
  if (userData.states != null) {
    if (userData.success.message !== "") {
      const state = userData.states;
      state.map((stateName) =>
        stateData.push({
          value: stateName.id,
          label: stateName.name,
        })
      );
    }
  }
  const formInitialValues = {
    companyName: workShopDetails ? workShopDetails.name : "",
    phone: workShopDetails ? workShopDetails.phone : "",
    email: workShopDetails ? workShopDetails.email : "",
    website: workShopDetails ? workShopDetails.websiteUrl : "",
    logo: workShopDetails ? workShopDetails.logo : "",
    streetAddress: workShopDetails ? workShopDetails?.street_address : "",
    country: workShopDetails ? workShopDetails?.country?.id : "",
    state: workShopDetails ? workShopDetails?.state?.id : "",
    zipcode: workShopDetails ? workShopDetails.postcode : "",
    suburb: workShopDetails ? workShopDetails.suburb : "",
    abn: workShopOtherDetails ? workShopOtherDetails.abn : "",
    acn: workShopOtherDetails ? workShopOtherDetails.acn : "",
    gst_number: workShopOtherDetails ? workShopOtherDetails.gst_number : "",
    odometer: workShopOtherDetails ? workShopOtherDetails.odometer_type : "",
    language: workShopOtherDetails
      ? workShopOtherDetails.language
      : workShopDetails
      ? workShopDetails.country.id
      : "",
    currency: workShopOtherDetails ? workShopOtherDetails.currency : "",
    bsb: workShopOtherDetails ? workShopOtherDetails.bsb : "",
    accountNumber: workShopOtherDetails
      ? workShopOtherDetails.account_number
      : "",
    accountName: workShopOtherDetails ? workShopOtherDetails.account_name : "",
    timezone: workShopOtherDetails
      ? `${workShopOtherDetails.timezone}_${workShopOtherDetails.timezone_label}`
      : "",
    onlineScheduleCode: workShopDetails
      ? `<a href="${process.env.REACT_APP_SITE_URL}bookJob?token=${workShopDetails.workshopToken}" target="_blank" style="display: inline-block;font: bold 15px!important;background-color: hsl(188, 86%, 63%);color: #ffffff;text-decoration:none;border-color:hsl(188, 86%, 63%);border-radius:3px;padding: 10px;" rel="noreferrer">Book A Job</a>`
      : "",
    app_url:workShopDetails?.url || "",
  };

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize={true}
      // innerRef={formRef}
      onSubmit={async (values) => {
        var filename = "";
        const timezone = values.timezone.split("_");
        if (values.file) {
          const d = new Date();
          var time = d.getTime();
          filename = values.file.name.replace(/ /g,"_");
          var extenstion = values.file.type.split("/");
          filename = "logo/"+time+"_"+filename;
          UploadFileToS3(values.file,filename);
        }

        //update session schedule details
        storedData.currency =  selectedCurrency;
        storedData.workshop_name = window.btoa(values.companyName);
        storedData.country = values.country;
        storedData.state = values.state;
        sessionStorage.setItem('stored_data',JSON.stringify(storedData));

        const companyFormData = {
          name: values.companyName,
          phone: values.phone,
          email: values.email,
          websiteUrl: values.website,
          street_address: values.streetAddress,
          suburb: values.suburb,
          postcode: values.zipcode,
          country: values.country,
          state: values.state,
          logo: filename || values.logo,
          id: JSON.parse(localStorage.getItem("data")).workshop_id,
          initial_setup: 1,
          app_url:values.app_url
        };

        const companyOtherData = {
          acn: values.acn,
          abn: values.abn,
          gst_number: values.gst_number,
          workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
          odometer_type: values.odometer,
          language: values.language,
          currency: values.currency,
          bsb: values.bsb,
          account_number: values.accountNumber,
          account_name: values.accountName,
          timezone: timezone[0],
          timezone_label: timezone[1],
          id: workShopOtherDetails.id,
        };

        ////////Start : add phone number & country in localstorage////
        let localstore = JSON.parse(localStorage.getItem("data"));
        localstore.workshop.phone = values.phone;
        localstore.workshop.country = values.country;
        localStorage.setItem("data", JSON.stringify(localstore));
        ////////End : add phone number in localstorage////

        dispatch({
          type: UPDATE_COMPANY_DETAIL,
          payload: companyFormData,
        });
        dispatch({
          type: UPDATE_COMPANY_OTHER_DETAIL,
          payload: companyOtherData,
        });
        filePondRef.current && filePondRef.current.removeFile();
        setUpdateCompanyDetails(!updateCompanyDetails)
      }}
      validate={(values) => {

        const error = {};
        const re = /^[0-9\b]+$/;
        if (!values.companyName) {
          error.companyName = "Please enter company name";
        }
        
        if (!values.phone) {
          error.phone = "Please enter a phone number";
        }else if(!re.test(values.phone)) {
          error.phone = "Your phone no must be Numuric";
        }else if (values.phone.length < 6) {
          error.phone = "Your phone no must be at least 6 characters long";
        } 

        if (!values.email) {
          error.email = "Please enter an email address";
        } else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            values.email.trim().toLowerCase()
          )
        ) {
          error.email = "Please enter a valid email address";
        }

        if (!values.timezone) {
          error.timezone = "This field is required.";
        }
        if ((values?.streetAddress)?.length > 31) {
          error.streetAddress = "Maximum character limit is 30.";
        }
        return error;
      }}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <div className="card company-detail-page">
          <form onSubmit={handleSubmit}>
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="text-uppercase mb-0">Company Details</h6>
              <div className="cta">
                <button
                  type="submit"
                  className="btn btn-success btn-rounded"
                  disabled={companyDetailData.formLoading}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="p-3">
              <div className="row"> 
                <div className="col-md-12 col-xl-6">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.companyName &&
                            touched.companyName &&
                            "is-invalid"
                          }`}
                          value={values.companyName}
                          onChange={(e) =>
                            setFieldValue("companyName", e.target.value)
                          }
                        />
                        {errors.companyName && touched.companyName && (
                          <div className="form-error text-danger">
                            {errors.companyName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Main Phone
                        </label>
                        <input
                          // type="number"
                          className={`form-control ${
                            errors.phone && touched.phone && "is-invalid"
                          }`}
                          minLength={6}
                          maxLength={10}
                          value={values.phone}
                          // onChange={(e) =>
                          //   setFieldValue("phone", e.target.value)
                          // }
                          onChange={(e) => {
                            if (!numberRegex.test(e.target.value)) return;
                            setFieldValue("phone", e.target.value)
                          }}
                        />
                        {errors.phone && touched.phone && (
                          <div className="form-error text-danger">
                            {errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.email && touched.email && "is-invalid"
                          }`}
                          value={values.email}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                        />
                        {errors.email && touched.email && (
                          <div className="form-error text-danger">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Website
                        </label>
                        <input
                          type="text"
                          className={`form-control`}
                          value={values.website}
                          onChange={(e) =>
                            setFieldValue("website", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Company Desktop URL
                        </label>
                        <div>{values.app_url}</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Street Address
                        </label>
                        <textarea
                          className={`form-control ${
                            errors.streetAddress && touched.streetAddress && "is-invalid"
                          }`}
                          name="street_address"
                          value={values.streetAddress}
                          onChange={(e) =>
                            setFieldValue("streetAddress", e.target.value)
                          }
                        />
                        {errors.streetAddress && touched.streetAddress && (
                          <div className="form-error text-danger">
                            {errors.streetAddress}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Suburb
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.suburb}
                          onChange={(e) =>
                            setFieldValue("suburb", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          {
                            workshopCountry == NzCountryId ? "Region": "State"
                          }
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          name="state"
                          id="state"
                          options={stateData}
                          value={stateData.filter(
                            (option) => option.value === workshopState
                          )}
                          onChange={(e) =>{
                             setFieldValue("state", e.value)
                             setWorkshopState(e.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Post code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.zipcode}
                          onChange={(e) =>
                            setFieldValue("zipcode", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Country
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          name="country"
                          id="country"
                          isDisabled={true}
                          options={countryData}
                          value={countryData.filter(
                            (option) => option.value === workshopCountry
                          )}
                          onChange={(e) => {
                            setWorkshopCountry(e.value);
                            setFieldValue("country", e.value);
                            handleState(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-6">
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        {values.logo !== "" && 
                          // <div className="user-profile-image ms-0">
                            <div className="ms-0">
                                <img className="profile-img" 
                                        src={workShopIcon}
                                            alt="Profile">
                                </img>
                            </div>
                        }
                    </div>
                    <div className="col-md-12">
                    {/* <div className={values.logo !== "" ? "col-md-7" : "col-md-12"}> */}
                      <div className="form-group pb-3">
                        <label>Company Logo</label>
                        <div className="file-upload-box">
                          <FilePond
                            ref={filePondRef}
                            id="file"
                            allowMultiple={false}
                            instantUpload={false}
                            onaddfile={async (err, fileEx) => {
                              if (!err) {
                                setFieldValue("file", fileEx.file);
                              }
                            }}
                            onremovefile={() => {
                              // setFileError(false);
                              setFieldValue("file", "");
                            }}
                            onerror={() => {
                              // setFileError(true);
                              // setFieldTouched("file", true);
                            }}
                            name="files"
                            acceptedFileTypes={["image/png", "image/jpeg"]}
                            maxFileSize="2MB"
                            labelIdle='<div class="dropzone-placeholder">
                              <div class="txt pb-1">
                                Drag and drop your file here
                                <br /> <strong>or</strorng>
                              </div>
                              <span class="btn btn-sm btn-primary btn-rounded px-3">Select File</span>
                              </div>'
                          />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <h6 className="text-uppercase border-bottom pb-2 pt-2 mb-3">
                Other
              </h6>
              <div className="row">
                <div className="col-md-12 col-xl-6">
                  <div className="row">
                    <div className={workshopCountry == NzCountryId ? 'col-md-6' : 'col-md-6'}>
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          {workshopCountry == NzCountryId ? 'NZBN' : 'ABN'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.abn}
                          onChange={(e) => setFieldValue("abn", e.target.value)}
                        />
                      </div>
                    </div>
                    {
                      (workshopCountry == NzCountryId) ? 
                      <>
                        <div className='col-md-6'>
                          <div className="form-group pb-3">
                            <label htmlFor="" className="form-label">
                              GST Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={values.gst_number}
                              onChange={(e) => setFieldValue("gst_number", e.target.value)}
                            />
                          </div>
                        </div>
                      </> : <></>
                    }
                    <div className={workshopCountry == NzCountryId ? 'hide' : 'col-md-6'}>
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          ACN
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.acn}
                          onChange={(e) => setFieldValue("acn", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className={workshopCountry == NzCountryId ? 'hide' : 'col-md-4'}>
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          BSB
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.bsb}
                          onChange={(e) => setFieldValue("bsb", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className={workshopCountry == NzCountryId ? 'col-md-6' : 'col-md-4'}>
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Account Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.accountNumber}
                          onChange={(e) =>
                            setFieldValue("accountNumber", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className={workshopCountry == NzCountryId ? 'col-md-6' : 'col-md-4'}>
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Account Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.accountName}
                          onChange={(e) =>
                            setFieldValue("accountName", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-6">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Odometer Default
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          options={odometerOptions}
                          onChange={(e) => {
                            setFieldValue("odometer", e.value);
                          }}
                          value={odometerOptions.filter(
                            (option) => option.value === values.odometer
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Language
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          options={languageData}
                          onChange={(e) => {
                            setFieldValue("language", e.value)
                          }}
                          value={languageData.filter(
                            (option) => option.value == values.country
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Currency
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          options={currencyData}
                          onChange={(e) => {
                            setFieldValue("currency", e.value);
                            setSelectedCurrency(e.symbol)
                          }}
                          value={currencyData.filter(
                            (option) => option.value == values.currency
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Time Zone
                        </label>
                        <Select
                          className={`custom-select ${
                            errors.timezone && touched.timezone && "is-invalid"
                          }`}
                          classNamePrefix="custom-select"
                          name="timezone"
                          id="timezone"
                          options={countryTimezone}
                          onChange={(e) => {
                            setFieldValue("timezone", e.value);
                          }}
                          value={countryTimezone.filter(
                            (option) => option.value == values.timezone
                          )}
                        />
                        {errors.timezone && touched.timezone && (
                          <div className="form-error text-danger">
                            {errors.timezone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group pb-3 schedule-code-editor">
                    <label htmlFor="" className="form-label">
                      Online Schedule Code
                    </label>
                    <textarea
                      className="form-control"
                      cols="40"
                      rows="2"
                      onChange={(e) =>
                        setFieldValue("onlineScheduleCode", e.target.value)
                      }
                      value={values.onlineScheduleCode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default CompanyDetailForm;
