export const GET_UPDATE_VEHICLE = "GET_UPDATE_VEHICLE";
export const GET_UPDATE_VEHICLE_SUCCESS = "GET_UPDATE_VEHICLE_SUCCESS";
export const GET_UPDATE_VEHICLE_FAIL = "GET_UPDATE_VEHICLE_FAIL";

export const GET_VEHICLES_HISTORY = "GET_VEHICLES_HISTORY";
export const GET_VEHICLES_HISTORY_SUCCESS = "GET_VEHICLES_HISTORY_SUCCESS";
export const GET_VEHICLES_HISTORY_FAIL = "GET_VEHICLES_HISTORY_FAIL";

export const ADD_VEHICLES_HISTORY = "ADD_VEHICLES_HISTORY";
export const ADD_VEHICLES_HISTORY_SUCCESS = "ADD_VEHICLES_HISTORY_SUCCESS";
export const ADD_VEHICLES_HISTORY_FAIL = "ADD_VEHICLES_HISTORY_FAIL";