import {
  SAVE_JOB_LABOUR,
  SAVE_JOB_LABOUR_SUCCESS,
  SAVE_JOB_LABOUR_FAIL,
  GET_JOB_LABOUR_LIST,
  GET_JOB_LABOUR_LIST_SUCCESS,
  GET_JOB_LABOUR_LIST_FAIL,
  DELETE_JOB_LABOUR,
  DELETE_JOB_LABOUR_SUCCESS,
  DELETE_JOB_LABOUR_FAIL,
  UPDATE_JOB_LABOUR,
  UPDATE_JOB_LABOUR_SUCCESS,
  UPDATE_JOB_LABOUR_FAIL,
  INLINE_UPDATE_JOB_LABOUR,
  INLINE_UPDATE_JOB_LABOUR_SUCCESS,
  INLINE_UPDATE_JOB_LABOUR_FAIL,
  EDIT_JOB_LABOUR_MANY,
  EDIT_JOB_LABOUR_MANY_SUCCESS,
  EDIT_JOB_LABOUR_MANY_FAIL,
} from "./actionTypes";

export const saveJobLabour = () => {
  return {
    type: SAVE_JOB_LABOUR,
  };
};

export const saveJobLabourSuccess = (data) => {
  return {
    type: SAVE_JOB_LABOUR_SUCCESS,
    payload: data,
  };
};

export const saveJobLabourFail = (error) => {
  return {
    type: SAVE_JOB_LABOUR_FAIL,
    payload: error,
  };
};

// Get Job Labour START
export const getJobLabourList = (payload) => {
  return {
    type: GET_JOB_LABOUR_LIST,
    payload
  };
};

export const getJobLabourListSuccess = (data) => {
  return {
    type: GET_JOB_LABOUR_LIST_SUCCESS,
    payload: data,
  };
};

export const getJobLabourListFail = (error) => {
  return {
    type: GET_JOB_LABOUR_LIST_FAIL,
    payload: error,
  };
};

// Get Job Labour END


export const deleteJobLabour = () => {
  return {
    type: DELETE_JOB_LABOUR,
  };
};

export const deleteJobLabourSuccess = (data) => {
  return {
    type: DELETE_JOB_LABOUR_SUCCESS,
    payload: data,
  };
};

export const deleteJobLabourFail = (error) => {
  return {
    type: DELETE_JOB_LABOUR_FAIL,
    payload: error,
  };
};

export const updateJobLabour = () => {
  return {
    type: UPDATE_JOB_LABOUR,
  };
};

export const updateJobLabourSuccess = (data) => {
  return {
    type: UPDATE_JOB_LABOUR_SUCCESS,
    payload: data,
  };
};

export const updateJobLabourFail = (error) => {
  return {
    type: UPDATE_JOB_LABOUR_FAIL,
    payload: error,
  };
};

export const inlineUpdateLabour = () => {
  return {
    type: INLINE_UPDATE_JOB_LABOUR,
  };
};

export const inlineUpdateLabourSuccess = (data) => {
  return {
    type: INLINE_UPDATE_JOB_LABOUR_SUCCESS,
    payload: data,
  };
};

export const inlineUpdateLabourFail = (error) => {
  return {
    type: INLINE_UPDATE_JOB_LABOUR_FAIL,
    payload: error,
  };
};

export const editJobLabourMany = () => {
  return {
    type: EDIT_JOB_LABOUR_MANY,
  };
};

export const editJobLabourManySuccess = (data) => {
  return {
    type: EDIT_JOB_LABOUR_MANY_SUCCESS,
    payload: data,
  };
};

export const editJobLabourManyFail = (error) => {
  return {
    type: EDIT_JOB_LABOUR_MANY_FAIL,
    payload: error,
  };
};