import { takeLatest, put, call } from "redux-saga/effects";
import {
  UPDATE_SUPPLIER_INTEGRATION,
  SAVE_SUPPLIER_INTEGRATION
} from "./actionTypes";
import {
  updateSupplierIntegrationSuccess,
  updateSupplierIntegrationFail,
  saveSupplierIntegrationSuccess,
  saveSupplierIntegrationFail
} from "./actions";

import {
  updateSupplierIntegration,
  saveSupplierIntegration
} from "../../helpers/backendHelper";

import { showError, showSuccess } from "../../utils/alertMessage";

// UPDATE supplier Data
function* onUpdateSupplierIntegration(data) {
  try {
    const response = yield call(updateSupplierIntegration, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateSupplierIntegrationSuccess(response));
        // showSuccess(response.message);
      } else {
        yield put(updateSupplierIntegrationFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateSupplierIntegrationFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateSupplierIntegrationFail(error.response));
  }
}

//SAVE supplier Data
function* onSaveSupplierIntegration(data) {
  try {
    const response = yield call(saveSupplierIntegration, data);
    if (response) {
      if (response.code === 200) {
        yield put(saveSupplierIntegrationSuccess(response));
        // showSuccess(response.message);
      } else {
        yield put(saveSupplierIntegrationFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(saveSupplierIntegrationFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(saveSupplierIntegrationFail(error.response));
  }
}

function* SupplierIntegrationSaga() {
  yield takeLatest(UPDATE_SUPPLIER_INTEGRATION, onUpdateSupplierIntegration);
  yield takeLatest(SAVE_SUPPLIER_INTEGRATION, onSaveSupplierIntegration);
}

export default SupplierIntegrationSaga;
