import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link, useNavigate } from "react-router-dom";
import {
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_COUNT,
  CUSTOMER_SEARCH,
} from "../../../../store/Contact/actionTypes";
import { customerSearchSuccess } from "../../../../store/Contact/actions";
import { useDispatch, useSelector } from "react-redux";
import LoaderImage from "../../../../assets/images/page-loader.svg";
import DeleteRecords from "../../../../utils/deleteRecords";
import { deleteManyCustomer } from "../../../../helpers/backendHelper";
import Form from "react-bootstrap/Form";

const CustomerList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [search, setSearch] = useState("");
  const [emptyMessage, setEmptyMessage] = useState("No data available in table");
  const [totalRecords, setTotalRecords] = useState(0);
  const [customerData, setCustomerData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const workshop_id = window.atob(storedData.workshop_id);
  const deleteMsg = "Customer deleted successfully";
  const deletePayloadData = {
    payload: {
      action_type: "delete",
      ids: selectedRow,
    },
  };
  const ArchiveMsg = "Customer Archived successfully";
  const ArchivePayloadData = {
    payload: {
      action_type: "archive",
      ids: selectedRow,
    },
  };

  const { loading, customerList, customerCount, customerSearch } = useSelector(
    (state) => state.Contact
  );

  useEffect(() => {
    return () => {
      dispatch(customerSearchSuccess({}));
    };
  }, []);

  useEffect(() => {
    loadData();
    dispatch({
      type: GET_CUSTOMER_COUNT,
      payload: {
        get: "all",
        conditions: {
          "CustomersDetails.workshop_id": workshop_id,
          role_id: 4,
          "CustomersDetails.is_deleted": 0,
          "CustomersDetails.status": 1,
        },
        contain: {
          Users: {
            fields: ["Users.email", "Users.id"],
          },
          "Users.Vehicles": {
            fields: ["Vehicles.user_id"],
            conditions: {
              "Vehicles.workshop_id": workshop_id,
              "Vehicles.is_deleted": 0,
            },
          },
          CustomerContact: {
            conditions: {
              "CustomerContact.is_deleted": 0,
              "CustomerContact.status": 1,
            },
          },
        },

        order: {
          "CustomersDetails.id": "desc",
        },
        fields: {
          COUNT: "COUNT(CustomersDetails.id)",
        },
      },
    });
  }, []);

  const loadData = (page = 1, pageLimit = 10) => {
    dispatch({
      type: GET_CUSTOMER_LIST,
      payload: {
        get: "all",
        conditions: {
          "CustomersDetails.workshop_id": workshop_id,
          role_id: 4,
          "CustomersDetails.is_deleted": 0,
          "CustomersDetails.status": 1,
        },
        contain: {
          Users: {
            fields: ["Users.email", "Users.id", "Users.customer_account"],
          },
          "Users.Vehicles": {
            fields: ["Vehicles.user_id"],
            conditions: {
              "Vehicles.workshop_id": workshop_id,
              "Vehicles.is_deleted": 0,
            },
          },
          CustomerContact: {
            conditions: {
              "CustomerContact.is_deleted": 0,
              "CustomerContact.status": 1,
            },
          },
        },
        limit: pageLimit,
        page: page,
        // order: {
        //   "CustomersDetails.firstname": "asc",
        // },
        fields: [
          "CustomersDetails.id",
          "CustomersDetails.type",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.company_name",
          "CustomersDetails.phone_number",
          "CustomersDetails.mobile_number",
          "CustomersDetails.workshop_id",
          "CustomersDetails.suburb",
          "CustomersDetails.street_address",
          "CustomersDetails.user_id",
          "CustomersDetails.customer_app",
          "CustomersDetails.customer_account",
        ],
      },
    });
  };

  const columns = [
    {
      dataField: "firstname",
      text: "Name",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
      formatter: (cell, row) => {
        return row.company_name
          ? row.company_name
          : row.firstname + " " + row.lastname;
      },
    },
    {
      dataField: "mobile_number",
      text: "Contact",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
    },
    {
      dataField: "user.email",
      text: "Email",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
    },
    {
      dataField: "street_address",
      text: "Address",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
    },
    {
      dataField: "suburb",
      text: "Suburb",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
    },
    {
      dataField: "user.vehicles",
      text: "Vehicles",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
      formatter: (cell, row) => {
        return row?.user?.vehicles?.length;
      },
    },
    {
      dataField: "customer_app",
      text: "Other",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_customer/${row.id}`);
        },
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-center">
            {row.customer_app === '1' ?
              // <i className="fa fa-mobile text-info font-size-24 col-md-1" aria-hidden="true"></i>
              <span className="col-md-1" ></span>
              :
              <span className="col-md-1" ></span>
            }
            {row?.customer_account === '1' &&
              <span className="acc-tag m-l-10">Acc</span>
            }
          </div>

        );
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <Link to={`/edit_customer/${row.id}`} className="" title="EDIT">
                  <i className="fa fa-pencil text-info"></i>
                  <span>Edit</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setSelectedRow(row.id);
                  setShowDeleteModal(true);
                }}
              >
                <Link to="#" className="delete-link" title="DELETE">
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </Link>
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  const checkBox = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row.id]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.indexOf(row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
    onSelectAll: (isSelected) => {
      if (isSelected) {
        let ids = [];
        for (var key = 0; key < sizePerPage; key++) {
          if (customerData[key] !== undefined) ids.push(customerData[key].id);
        }
        setSelectedRow(ids);
      } else {
        setSelectedRow([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          checked={rest.checked}
          name={rest.name}
          id={rest.id}
          type="checkbox"
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          id={rest.id}
          checked={rest.checked}
          type={mode}
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
  };

  const onTableChange = (type, { page, sizePerPage }) => {

    setCurrentPage(page);
    setSizePerPage(sizePerPage);

    if (search === "") {
      loadData(page, sizePerPage);
    } else {

      let text = [];
      for (
        var key = (page - 1) * sizePerPage;
        key < sizePerPage * page;
        key++
      ) {
        if (customerSearch[key] !== undefined) text.push(customerSearch[key]);
      }
      setCustomerData(text);
    }

    // loadData(page, sizePerPage);
  };

  const emptyDataMessage = () => {
    return (
      <div className={(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setEmptyMessage("No Results Found")
    if (e.target.value !== "") {
      dispatch({
        type: CUSTOMER_SEARCH,
        payload: {
          workshop_id: workshop_id,
          keyword: e.target.value,
        },
      });
    } else {
      dispatch(customerSearchSuccess({}));
      setCustomerData(customerList || []);
      setTotalRecords(customerCount);
    }
  };

  useEffect(() => {
    setTotalRecords(customerCount);
  }, [customerCount]);

  useEffect(() => {
    if (Object.keys(customerSearch).length !== 0) {
      var text = [];
      let totalSearchCount = 0;
      if (customerSearch != "No records found..!!") {
        for (var key = 0; key < 10; key++) {
          if (customerSearch[key] !== undefined) {
            // totalSearchCount = customerSearch.total_count;
            text.push(customerSearch[key]);
          }
        }
      }
      setTotalRecords(customerSearch.length);
      setCustomerData(text);
    } else {
      if (customerList) {
        setCustomerData(customerList);
        setTotalRecords(customerCount);
      } else if (customerList === null) {
        setCustomerData([]);
        setTotalRecords(0);
      }

      // setCustomerData(customerList || []);
    }
  }, [customerList, customerSearch]);

  return (
    <div>
      {/* {loading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )} */}
      <div>
        <div className="table-search-wrapper">
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Search..."
            onChange={(e) => handleSearch(e)}
            value={search}
          />
          <Button
            className="btn-primary rounded-btn"
            onClick={() => navigate("/customer_company")}
          >
            <i className="fa fa-plus"></i> Create Customer
          </Button>
        </div>

        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: currentPage,
            sizePerPage: sizePerPage,
            totalSize: parseInt(totalRecords),
            nextPageText: "Next",
            prePageText: "Previous",
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                remote
                keyField="id"
                data={customerData}
                columns={columns}
                onTableChange={onTableChange}
                selectRow={checkBox}
                noDataIndication={emptyDataMessage}
                {...paginationTableProps}
              />
              <div>
                <div className="table-pagination">
                  <div>
                    <span>Show</span>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <span>entries</span>
                  </div>
                  <PaginationTotalStandalone {...paginationProps} />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            </div>
          )}
        </PaginationProvider>

        {showDeleteModal && (
          <DeleteRecords
            showDeleteModal={setShowDeleteModal}
            refreshData={loadData}
            api={deleteManyCustomer}
            selectedRow={selectedRow}
            message={deleteMsg}
            payloadData={deletePayloadData}
          />
        )}

        {showArchiveModal && (
          <DeleteRecords
            showDeleteModal={setShowArchiveModal}
            refreshData={loadData}
            api={deleteManyCustomer}
            selectedRow={selectedRow}
            message={ArchiveMsg}
            payloadData={ArchivePayloadData}
          />
        )}

        <div className="d-flex custom-table-footer align-center">
          <Button
            variant="warning"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => setShowArchiveModal(true)}
          >
            <i className="la la-archive font-size-18"></i> Archive
          </Button>
          <Button
            variant="danger"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => setShowDeleteModal(true)}
          >
            <i className="la la-times-circle font-size-18"></i> Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
