import capricorn from "assets/images/capricorn.jpg";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInitialData } from "views/common/InitialDataContext/InitialDataContext";
import LoaderImage from "../../../assets/images/page-loader.svg";
import { CAPRICORN_SUBSCRIPTION_PLANS, DO_SUBSCRIPTION_CHECKOUT } from "../../../store/subscription/actionTypes";
import { showError, showSuccess } from "../../../utils/alertMessage";
import { capricornSubscription, editcapricornSubscription } from "helpers/backendHelper";

const CapricornForm = ({
  user_plan_id,
  sms_plan_id,
  vin_plan_id,
  total_amount,
  payment_processing_fees,
  desktop_user,
  desktop_user_price,
  subscriptionCycle,
  mobile_user,
  mobile_user_price,
  app_config_plan_id,
  success,
  capricornNumber = '',
  setCapricornNumber,
  paymentType,
  actionType
}) => {

  const {
    setIsPlanExpire,
    setIsTrialMode
  } = useInitialData();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stripeLoading, setStripeLoading] = useState(false);
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const userId = window.atob(storedData['user_id'])
  const stripe_customer_id = window.atob(storedData.stripe_customer_id);

  // useEffect(() => {
  //   if (success && success.message) {
  //     setIsPlanExpire(false);
  //     setIsTrialMode(false);
  //     showSuccess(success.message);
  //     navigate("/home");
  //   }
  // }, [success]);



  const handleCapriCorn = async () => {

    if (!user_plan_id && actionType === 'add') {
      showError("You didn't select any main plan, Please select Growth, Pro or Ultimate plan");
      return false;
    }

    setStripeLoading(true);


    let editFormValues = {
      user_id: userId,
      workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
      email: JSON.parse(localStorage.getItem("data")).email,
      user_plan_id,
      plan_type: subscriptionCycle,
      total_amount,
      payment_processing_fees,
      desktop_user,
      desktop_user_price,
      mobile_user,
      mobile_user_price,
      sms_plan_id,
      vin_plan_id,
      app_config_plan_id,
      capricorn_number: capricornNumber,
      actionType: actionType
    };


    if (actionType === 'add') {
      await capricornSubscription({ payload: editFormValues }).then((res) => {
        if (res.code === 200) {
          setIsPlanExpire(false);
          setIsTrialMode(false);
          showSuccess(res?.message);
          navigate("/home");
        } else {
          setStripeLoading(false);
          showError(res?.message || "Something went wrong !!! Please try after sometime.");
        }
      });
    }


    if (actionType === 'edit') {
      await editcapricornSubscription({ payload: editFormValues }).then((res) => {
        if (res.code === 200) {
          setIsPlanExpire(false);
          setIsTrialMode(false);
          showSuccess(res?.message);
          navigate("/home");
        } else {
          setStripeLoading(false);
          showError(res?.message || "Something went wrong !!! Please try after sometime.");
        }
      });
    }


    // dispatch({
    //   type: CAPRICORN_SUBSCRIPTION_PLANS,
    //   payload: editFormValues
    // });


  }


  return (
    <>
      <div className="col-md-6 d-flex justify-content-center">
        <p className='text-center' >
          Subscription will be activated today and billed monthly directly through your Capricorn account
        </p>

        <div className={"col-md-10 " + (paymentType === '0' ? 'disableDiv' : '')} >
          <div className="card shadow">
            <div className="card-body">

              <div className="row" style={{ alignItems: 'center' }} >
                <div className="col-4">
                  <div className="form-group">

                    <img src={capricorn} style={{ height: '100px !important' }} alt="" />

                  </div>
                </div>
                <div className="col-8">
                  <Form.Group className="form-group">
                    <label> Capricorn Number </label>
                    <Form.Control
                      readOnly={paymentType === '0'}
                      disabled={paymentType === '0'}
                      placeholder="Capricorn Number"
                      type="text"
                      name="capricorn_number"
                      defaultValue={capricornNumber || ""}
                      onKeyUp={(e) => setCapricornNumber(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-12 text-end">
                  <button
                    readOnly={paymentType === '0'}
                    disabled={paymentType === '0'}
                    className="btn btn-primary btn-rounded"
                    onClick={(e) => {
                      handleCapriCorn();
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>
      {stripeLoading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
    </>
  );
}

export default CapricornForm;