import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import { addComma, precise_round } from "utils/numberFunction";
import "./styles.scss";
import LoaderImage from "assets/images/page-loader.svg";
import { PartListContext } from "./PartListContext";
import { INLINE_PO_DATA_JOB_WISE } from "store/purchaseOrder/actionTypes";
import { getSessionData } from "utils/getWorkshopId";

const POCreateJob = ({ jobID, supplierPartsData, fetchPOJobLoading, selectedJobData }) => {

  const dispatch = useDispatch();

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const currencySymbol = storedData?.currency || "$";

  let countryID = getSessionData('country', false);
  let taxPercentage = 10;
  if (countryID === 10) {
    taxPercentage = 15;
  }

  const { allList, setAllList } = useContext(PartListContext);

  const job_ID = (jobID) ? jobID : "";

  useEffect(() => {

    var supplierList = [];

    for (const property in supplierPartsData) {
      let a = JSON.stringify(supplierPartsData[property]);
      supplierList.push(JSON.parse(a));
    }

    const suppList = supplierList.map((supp) => (
      {
        id: supp?.supplier?.id,
        name: supp?.supplier?.name,
        street_address: supp?.supplier?.street_address,
        suburb: supp?.supplier?.suburb,
        state: supp?.supplier?.States?.name,
        country_name: supp?.supplier?.Countries?.country_name,
        postcode: supp?.supplier?.postcode,
        parts: supp.parts,
        suppliers_branches: supp?.supplier?.suppliers_branches,
        suppliers_branches_id: "Default",
        sub_total: 0.00,
        gst: 0,
        freight: 0,
        total_amount: 0,
        job_id: selectedJobData?.job_id,
        job_number: selectedJobData?.job_number,
      }
    ));

    setAllList(suppList);

  }, [supplierPartsData])


  function updatePO(id, value, part_id = '') {

    const parts = [...allList];
    const indexPart = parts.findIndex(c => c.id === id);
    if (indexPart !== -1) {
      if (part_id) {

        const poData = parts[indexPart];

        let partsdata = parts[indexPart].parts;
        const indexPart1 = partsdata.findIndex(c => c.id === part_id);
        if (indexPart1 !== -1) {

          const singlePartData = partsdata[indexPart1];
          let ptaxper = 0;
          let final_part_gst = 0;
          let sub_total_amount = 0;
          let final_total_amount = 0;
          let part_gst = 0;
          let single_part_gst = 0;

          let total_part_price = singlePartData.cost_price * value;

          if (singlePartData.purchase_tax_code_is_default === '1') {
            ptaxper = singlePartData?.purchase_tax_code || 0;

            part_gst = (parseFloat(total_part_price) * parseFloat(ptaxper)) / 100;
            single_part_gst = (parseFloat(singlePartData.cost_price) * parseFloat(ptaxper)) / 100;
          }

          partsdata[indexPart1] = {
            ...partsdata[indexPart1],
            po_part_gst: parseFloat(single_part_gst),
            all_part_gst: parseFloat(part_gst),
            po_order_qty: value,
            po_total_price: parseFloat(total_part_price)
          }


          partsdata.forEach((pdata) => {

            sub_total_amount = parseFloat(sub_total_amount) + parseFloat(pdata?.po_total_price || 0);
            final_part_gst = parseFloat(final_part_gst) + parseFloat(pdata?.all_part_gst || 0);
            // final_total_amount = parseFloat(final_total_amount) + parseFloat(pdata?.po_total_price || 0) + parseFloat(pdata?.po_part_gst || 0);

          });

          final_total_amount = parseFloat(sub_total_amount) + parseFloat(final_part_gst) + parseFloat(poData?.freight_gst || 0) + parseFloat(poData?.freight || 0);
          // update calculation
          parts[indexPart] = {
            ...parts[indexPart],
            parts: partsdata,
            sub_total: parseFloat(sub_total_amount),
            gst: parseFloat(final_part_gst),
            total_amount: parseFloat(final_total_amount)
          }

        }



      } else {

        // parts[indexPart] = {
        //   ...parts[indexPart],
        //   name:nameasdf
        // }

      }
    }

    setAllList(parts);

  }


  function updatePOSupplierID(id, value) {

    const parts = [...allList];

    const indexPart = parts.findIndex(c => c.id === id);
    if (indexPart !== -1) {
      parts[indexPart] = {
        ...parts[indexPart],
        suppliers_branches_id: value
      }
    }

    setAllList(parts);

  }

  function updateSupplierComment(id, value) {

    const parts = [...allList];

    const indexPart = parts.findIndex(c => c.id === id);
    if (indexPart !== -1) {
      parts[indexPart] = {
        ...parts[indexPart],
        delivery_comment: value
      }
    }

    setAllList(parts);

  }

  function updateFreightDetail(id, value) {
    const parts = [...allList];

    const indexPart = parts.findIndex(c => c.id === id);
    if (indexPart !== -1) {
      const poData = parts[indexPart];
      let freightGst = (parseFloat(value) * parseFloat(taxPercentage) / 100);
      let total = parseFloat(poData?.sub_total) + parseFloat(poData?.gst) + parseFloat(freightGst) + parseFloat(value);
      parts[indexPart] = {
        ...parts[indexPart],
        freight: value,
        freight_gst: freightGst,
        total_amount: total
      }
    }

    setAllList(parts);

  }

  if (fetchPOJobLoading) {
    return <div className="page-loader d-flex align-center justify-center" >
      <img src={LoaderImage} alt="Loading"></img>
    </div>
  }


  return (
    <>

      {allList &&
        allList.map((supp, key) => (

          <div className={"order-detail-outer mb-4 " + (job_ID === "" ? "d-none" : "")} key={key}>
            <div className="order-for-job">
              <div className="title-block">
                <h4 className="text-uppercase" >{supp?.name}</h4>
              </div>
              <div className="data-block-wrapper d-flex">
                <div className="data-block d-flex no-wrap mb-3">
                  <i className="fa fa-info-circle text-primary font-size-20 mr-10"></i>
                  <div className="select-block custom-normal-select">
                    <label>Branch Name</label>
                    <select onChange={(e) => updatePOSupplierID(supp?.id, e.target.value)} >
                      <option value="Default">Default</option>
                      {supp &&
                        supp?.suppliers_branches?.map((branch, key) => (
                          <option key={"branch_" + key} value={branch?.id}>{branch?.branch_name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="data-block mb-3">
                  <div className="mb-2">
                    <i className="fa fa-hashtag text-primary font-size-20 mr-10"></i>
                    <span>PO No. 403</span>
                  </div>
                  <div>
                    <i className="fa fa-map-marker text-primary font-size-20"></i>
                    <span>
                      {(supp?.street_address) ? (supp?.street_address + ",") : ""}
                      {(supp?.suburb) ? supp?.suburb + ", " : ""}
                      {(supp?.state) ? supp?.state + ", " : ""}
                      {(supp?.country_name) ? supp?.country_name + ", " : ""}
                      {supp?.postcode}
                    </span>

                  </div>
                </div>
                <div className="data-block mb-3">
                  <i className="fa fa-envelope text-primary font-size-20 mr-10"></i>
                  <Link to="/">{supp?.supplier?.email}</Link>
                </div>
                <div className="data-block mb-3">
                  <i className="fa fa-phone text-primary font-size-20 mr-10"></i>
                  <span>{supp?.supplier?.phone}</span>
                </div>
              </div>
              <div className="draggable-table mb-5">

                <div className="react-bootstrap-table">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Part No.</th>
                        <th>Part Name</th>
                        <th>Cost</th>
                        <th>Qty Required</th>
                        <th>Qty Ordered</th>
                        <th>Sub Total</th>
                        {/* <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {supp &&
                        supp.parts.map((part, key) => (
                          <tr key={"table_key_" + key}>
                            <td>{part?.product_number}</td>
                            <td>{part?.name}</td>
                            <td>{currencySymbol}{addComma(precise_round(part?.cost_price))}</td>
                            <td>{part?.order_qty}</td>
                            <td>
                              <Form.Group className="form-group ">
                                <Form.Control type="text" className="small-input width-50" onBlur={(e) => updatePO(supp?.id, e.target.value, part?.id)} defaultValue={part?.po_order_qty || 0} />
                              </Form.Group></td>
                            <td>{currencySymbol}{addComma(precise_round(part?.po_total_price || 0))}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="comment-total-block d-flex align-end">
                <div className="comment-block">
                  <label>Delivery Comments:</label>
                  <Form.Control
                    as="textarea"
                    onBlur={(e) => updateSupplierComment(supp?.id, e.target.value)}
                    rows={4}
                  />
                </div>
                <div className="form-outer">
                  <Form.Group className="form-group d-flex align-center mb-1 no-wrap">
                    <label>Freight {currencySymbol}</label>
                    <Form.Control type="text" onBlur={(e) => updateFreightDetail(supp?.id, e.target.value)} defaultValue={addComma(precise_round(supp?.freight))} />
                  </Form.Group>
                  <Form.Group className="form-group d-flex align-center mb-1 no-wrap disable">
                    <label>Sub Total {currencySymbol}</label>
                    <Form.Control type="text" readOnly value={addComma(precise_round(parseFloat(supp?.sub_total) + parseFloat(supp?.freight || 0)))} />
                  </Form.Group>
                  <Form.Group className="form-group d-flex align-center mb-1 no-wrap disable">
                    <label>GST {currencySymbol}</label>
                    <Form.Control type="text" readOnly value={addComma(precise_round(parseFloat(supp?.gst) + parseFloat(supp?.freight_gst || 0)))} />
                  </Form.Group>
                  <Form.Group className="form-group d-flex align-center mb-1 no-wrap disable">
                    <label className="total-amount">TOTAL {currencySymbol}</label>
                    <Form.Control type="text" readOnly value={addComma(precise_round(supp?.total_amount))} />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>

        ))}

    </>
  );
};

export default POCreateJob;
