export const GET_HQ_WORKSHOP_LIST = "GET_HQ_WORKSHOP_LIST"
export const GET_HQ_WORKSHOP_LIST_SUCCESS = "GET_HQ_WORKSHOP_LIST_SUCCESS"
export const GET_HQ_WORKSHOP_LIST_FAIL = "GET_HQ_WORKSHOP_LIST_FAIL"

export const GET_HQ_WORKSHOP_LIST_COUNT = "GET_HQ_WORKSHOP_LIST_COUNT"
export const GET_HQ_WORKSHOP_LIST_COUNT_SUCCESS = "GET_HQ_WORKSHOP_LIST_COUNT_SUCCESS"
export const GET_HQ_WORKSHOP_LIST_COUNT_FAIL = "GET_HQ_WORKSHOP_LIST_COUNT_FAIL"


export const SET_HQ_WORKSHOP_SIGN_IN_TOKEN = "SET_HQ_WORKSHOP_SIGN_IN_TOKEN"
export const SET_HQ_WORKSHOP_SIGN_IN_TOKEN_SUCCESS = "SET_HQ_WORKSHOP_SIGN_IN_TOKEN_SUCCESS"
export const SET_HQ_WORKSHOP_SIGN_IN_TOKEN_FAIL = "SET_HQ_WORKSHOP_SIGN_IN_TOKEN_FAIL"