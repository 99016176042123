import { toast } from "react-toastify";

export function useInvoiceAction(setDeletePayload) {
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteRecords = (selectedRecords) => {
    if (selectedRecords.length === 0) {
      toast.error("Please select at least one record.");
      setDeletePayload?.(false);
      return;
    }
    setDeletePayload?.(selectedRecords);
  };

  const archiveRecord = (selectedRecords) => {
    if (selectedRecords.length === 0) {
      toast.error("Please select at least one record.");
      setDeletePayload?.(false);
      return;
    }
    setDeletePayload?.(selectedRecords);
  };

  const deleteMsg = "Job Deleted Sucessfully";

  return { deleteRecords, archiveRecord, deleteMsg };
}
