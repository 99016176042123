// import Login from '../components/Login/index';
import { routes as Login } from "../views/login";
import { routes as Dashboard } from "../views/dashboard";
import { routes as Jobs } from "../views/job";
import { routes as Register } from "../views/register";
import { routes as Scheduler } from "../views/scheduler";
import { routes as UserProfile } from "../views/userProfile";
import { routes as Calender } from "../views/calender";
import { routes as Notification } from "../views/notification";
import { routes as Quote } from "../views/quotes";
import { routes as Setting } from "../views/settings";
import { routes as InvoicePreview } from "../views/invoicePreview";
import { routes as Invoice } from "../views/invoices";
import { routes as PurchasesOrder } from "../views/purchaseOrder";
import { routes as Subscription } from "../views/subscription";
import { routes as Sandbox } from "../views/sandbox";
import { routes as List } from "../views/list";
import { routes as Leads } from "../views/leads";
import { routes as PartsAndKit } from "../views/PartsAndKit";
import { routes as Contacts } from "../views/Contacts";
import { routes as Global } from "../views/globalRoutes";
import { routes as Inspections } from "../views/inspections";
import { routes as Marketing } from '../views/marketing';
import { routes as ContactHelp } from '../views/contactHelp';
import { routes as video } from '../views/traineeVideo';
import { routes as Reports } from '../views/Reports';
import { routes as Materials } from '../views/materials';
import { routes as Welcome } from '../views/welcome';

// HQ Routes start here
import { routes as HQDashboard } from "../views/hq/views/dashboard";
import { routes as HQCustomer } from "../views/hq/views/customers";
import { routes as HQSuppliers } from "../views/hq/views/suppliers";
import { routes as HQEmployees } from "../views/hq/views/employees";
import { routes as HQVehicles } from "../views/hq/views/vehicles";
import { routes as HQInvoices } from "../views/hq/views/invoices";
import { routes as HQStores } from "../views/hq/views/stores";
import { routes as HQProfile } from "../views/hq/views/profile";
import { routes as HQMarketing } from "../views/hq/views/marketing";

// HQ Routes ends here

import { routes as TimeClocking } from '../views/timeClocking';
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { withHQWrapperRoutes, withoutWrapperRoutes, withWrapperRoutes } from "./withRoute";
import { Navigate, Route } from "react-router-dom";
import NotFound from "./404";

/**
 * HQ Routes
 */

export const hqRoutes = [
  ...HQDashboard,
  ...HQCustomer,
  ...HQSuppliers,
  ...HQEmployees,
  ...HQVehicles,
  ...HQInvoices,
  ...HQStores,
  ...HQProfile,
  ...HQMarketing
]


export const ownerRoutes = [
  ...Dashboard,
  ...Jobs,
  ...Scheduler,
  ...UserProfile,
  ...Calender,
  ...Notification,
  ...Quote,
  ...Setting,
  ...InvoicePreview,
  ...Invoice,
  ...PurchasesOrder,
  ...Subscription,
  ...Sandbox,
  ...List,
  ...Leads,
  ...PartsAndKit,
  ...Contacts,
  ...Marketing,
  ...video,
  ...ContactHelp,
  ...Reports,
  ...Materials
];

export const adminRoutes = [
  ...Dashboard,
  ...Jobs,
  ...Scheduler,
  ...UserProfile,
  ...Notification,
  ...Quote,
  ...Invoice,
  ...Sandbox,
];

export const accountRoutes = [...Dashboard];

export const officeRoutes = [...Dashboard];

export const seniorTechRoutes = [...Dashboard];

export const TechRoutes = [...Dashboard];

export const authRoutes = [...Login, ...Register, ...Global, ...Welcome];

export const SubscriptionRoutes = [...Subscription, ...Global];

export function useRoutes() {
  const { role_id, expired, is_inspection_business } = useSelector((state) => state.LoginReducer);
  const { securities } = useSelector((state) => state.SecurityGroupReducer);

  const isPlanExpire = localStorage.getItem("isPlanExpire");

  const currentSecurity = useMemo(() => {
    let sec;
    if (securities && Object.keys(securities).length !== 0) {
      sec = securities[role_id];
    }
    sec = sec || {};
    sec.role_id = role_id;
    return sec;
  }, [securities, role_id]);
  const routes = useMemo(() => {
    // return withoutWrapperRoutes(authRoutes);

    const loginNavigation = (
      <Route
        key="loginPath"
        path="*"
        element={<Navigate replace to="/login" />}
      />
    );
    const subscriptionNavigation = (
      <Route
        key="subscription_plan"
        path="*"
        element={<Navigate replace to="/subscription-plan" />}
      />
    );

    const homeNavigation = (
      <Route key="homePath" path="/*" element={<NotFound />} />
    );


    if (role_id == 0) {
      //No Login
      return [...withoutWrapperRoutes(authRoutes), loginNavigation];
    }

    if (expired) {
      return [...withWrapperRoutes(SubscriptionRoutes), subscriptionNavigation];
    }

    if (role_id == 2) {
      //Owner should be 2
      if (+is_inspection_business === 1) {
        ownerRoutes.push(...Inspections)
      }
      ownerRoutes.push(...TimeClocking)
      return [
        ...withWrapperRoutes(ownerRoutes),
        ...withoutWrapperRoutes(Global),
        homeNavigation,
      ];
    }

    if (role_id == 12) {
      return [
        ...withHQWrapperRoutes(hqRoutes),
        ...withoutWrapperRoutes(Global),
        <Route key="dashboard" path="/*" element={<NotFound path="/dashboard" />} />
      ]
    }


    const newRoutes = [...Dashboard, ...UserProfile, ...Notification, ...video, ...ContactHelp];
    // const newRoutes = [];

    // if (currentSecurity["'dashboard'"]) {
    //   newRoutes.push();
    // }
    if (+is_inspection_business === 1) {
      newRoutes.push(...Inspections)
    }
    if (+role_id === 2 || +role_id === 10 || +role_id === 3 || +role_id === 7) {
      newRoutes.push(...TimeClocking);
    }
    if (currentSecurity["'analytical'"]) {
    }
    if (currentSecurity["''catalogue(parts&kits)'"]) {
      newRoutes.push(...PartsAndKit);
    }
    if (currentSecurity["'contacts'"]) {
      newRoutes.push(...Contacts);
    }
    if (currentSecurity["'customerassets'"]) {
    }
    if (currentSecurity["'inventory'"]) {
      newRoutes.push(...PartsAndKit);
    }
    if (currentSecurity["'invoices'"]) {
      newRoutes.push(...Invoice);
    }
    if (currentSecurity["'marketing'"]) {
      newRoutes.push(...Marketing);
    }
    if (currentSecurity["'operational'"]) {
    }
    if (currentSecurity["'purchases'"]) {
      newRoutes.push(...PurchasesOrder);
    }
    if (currentSecurity["'reports'"]) {
      newRoutes.push(...Reports);
    }
    if (currentSecurity["'scheduler'"]) {
      newRoutes.push(...Scheduler);
    }
    if (currentSecurity["'settings'"]) {
      newRoutes.push(...Setting);
    }
    if (currentSecurity["'slowdays,lastminute'"]) {
    }
    if (currentSecurity["'special,referral&feedback'"]) {
    }
    if (currentSecurity["'stockmanagements&stocktakes'"]) {
      newRoutes.push(...Materials);
    }
    if (currentSecurity["'sync'"]) {
    }
    if (currentSecurity["'workshop'"]) {
      newRoutes.push(...Jobs);
      newRoutes.push(...Leads);
      newRoutes.push(...List);
      newRoutes.push(...Quote);
    }
    return [
      ...withWrapperRoutes(newRoutes),
      ...withoutWrapperRoutes(Global),
      homeNavigation,
    ];
  }, [role_id, currentSecurity, expired, is_inspection_business]);

  return { routes, currentSecurity };
}
