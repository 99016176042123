import {
  GET_STATUSES_DETAILS,
  GET_STATUSES_DETAILS_SUCCESS,
  GET_STATUSES_DETAILS_FAIL,
  ADD_STATUSES_DETAILS,
  GET_STATUS_TECHNICIAN_DETAILS,
  GET_STATUS_TECHNICIAN_DETAILS_SUCCESS,
  GET_STATUS_TECHNICIAN_DETAILS_FAIL,
  ADD_STATUSES_DETAILS_SUCCESS,
  ADD_STATUSES_DETAILS_FAIL,
  EDIT_STATUSES_DETAILS,
  EDIT_STATUSES_DETAILS_SUCCESS,
  EDIT_STATUSES_DETAILS_FAIL,
  UPDATE_STATUSES_DETAILS,
  UPDATE_STATUSES_DETAILS_SUCCESS,
  UPDATE_STATUSES_DETAILS_FAIL,
  DELETE_STATUSES_DETAILS,
  DELETE_STATUSES_DETAILS_SUCCESS,
  DELETE_STATUSES_DETAILS_FAIL,
  DELETE_MANY_STATUSES_DETAILS,
  DELETE_MANY_STATUSES_DETAILS_SUCCESS,
  DELETE_MANY_STATUSES_DETAILS_FAIL,
  CHECK_NAME_STATUSES_DETAILS,
  CHECK_NAME_STATUSES_DETAILS_SUCCESS,
  CHECK_NAME_STATUSES_DETAILS_FAIL,
  GET_STATUSES_COUNT,
  GET_STATUSES_COUNT_SUCCESS,
  GET_STATUSES_COUNT_FAIL
} from "./actionTypes";

const initialState = {
  loading: false,
  formLoading: false,
  error: {
    message: "",
  },
  success: {
    message: "",
  },
  isAddOrUpdate: false,
  isNameAvailable: true,
  statuses: [],
  statusesDeleted:null,
  statusesDeletedMany:null,
  technicianStatuses: [],
  statusDetails: null,
  statusDesktopCount: 0
};

const statusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUSES_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_STATUSES_DETAILS_SUCCESS:
      state = {
        ...state,
        statuses: action.payload.Statuses,
        loading: false,
        success: {
          message: action.message,
        },
      };
      break;
    case GET_STATUSES_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case GET_STATUS_TECHNICIAN_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_STATUS_TECHNICIAN_DETAILS_SUCCESS:
      state = {
        ...state,
        technicianStatuses: action.payload.Statuses,
        loading: false,
        success: {
          message: action.message,
        },
      };
      break;
    case GET_STATUS_TECHNICIAN_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case ADD_STATUSES_DETAILS:
      state = {
        ...state,
        loading: true,
        isAddOrUpdate: false,
      };
      break;
    case ADD_STATUSES_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        isAddOrUpdate: true,
        success: {
          message: action.message,
        },
      };
      break;
    case ADD_STATUSES_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case CHECK_NAME_STATUSES_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_NAME_STATUSES_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        isNameAvailable: action.payload.length > 0 ? false : true,
      };
      break;
    case CHECK_NAME_STATUSES_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case EDIT_STATUSES_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_STATUSES_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        statusDetails: action.payload.Statuses[0],
        success: {
          message: action.message,
        },
      };
      break;
    case EDIT_STATUSES_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case UPDATE_STATUSES_DETAILS:
      state = {
        ...state,
        loading: true,
        isAddOrUpdate: false,
      };
      break;
    case UPDATE_STATUSES_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        isAddOrUpdate: true,
        statusDetails: null,
        success: {
          message: action.message,
        },
      };
      break;
    case UPDATE_STATUSES_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case DELETE_STATUSES_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_STATUSES_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        statusesDeleted: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case DELETE_STATUSES_DETAILS_FAIL:
      state = {
        ...state,
        statusesDeleted:null,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case DELETE_MANY_STATUSES_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_MANY_STATUSES_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        statusesDeletedMany: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case DELETE_MANY_STATUSES_DETAILS_FAIL:
      state = {
        ...state,
        statusesDeletedMany:null,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case GET_STATUSES_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_STATUSES_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        statusDesktopCount: action.payload,
      };
      break;
    case GET_STATUSES_COUNT_FAIL:
      state = {
        ...state,
        loading: false,
        statusDesktopCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default statusesReducer;
