import
  {
    ADD_THIRD_PARTY_COMPANY_DETAILS,
    ADD_THIRD_PARTY_COMPANY_DETAILS_FAIL,
    ADD_THIRD_PARTY_COMPANY_DETAILS_SUCCESS, EDIT_THIRD_PARTY_COMPANY, EDIT_THIRD_PARTY_COMPANY_FAIL,
    EDIT_THIRD_PARTY_COMPANY_SUCCESS,
    UPDATE_THIRD_PARTY_COMPANY_DETAILS,
    UPDATE_THIRD_PARTY_COMPANY_DETAILS_FAIL,
    UPDATE_THIRD_PARTY_COMPANY_DETAILS_SUCCESS
  } from "./actionTypes";

export const editThirdPartyCompany = () => {
  return {
    type: EDIT_THIRD_PARTY_COMPANY,
  };
};

export const editThirdPartyCompanySuccess = (data) => {
  return {
    type: EDIT_THIRD_PARTY_COMPANY_SUCCESS,
    payload: data,
  };
};

export const editThirdPartyCompanyFail = (error) => {
  return {
    type: EDIT_THIRD_PARTY_COMPANY_FAIL,
    payload: error,
  };
};

export const updateThirdPartyCompanyDetails = () => {
  return {
    type: UPDATE_THIRD_PARTY_COMPANY_DETAILS,
  };
};

export const updateThirdPartyCompanyDetailsSuccess = (data) => {
  return {
    type: UPDATE_THIRD_PARTY_COMPANY_DETAILS_SUCCESS,
    payload: data,
  };
};

export const updateThirdPartyCompanyDetailsFail = (error) => {
  return {
    type: UPDATE_THIRD_PARTY_COMPANY_DETAILS_FAIL,
    payload: error,
  };
};

export const addThirdPartyCompanyDetails = () => {
  return {
    type: ADD_THIRD_PARTY_COMPANY_DETAILS,
  };
};

export const addThirdPartyCompanyDetailsSuccess = (data) => {
  return {
    type: ADD_THIRD_PARTY_COMPANY_DETAILS_SUCCESS,
    payload: data,
  };
};

export const addThirdPartyCompanyDetailsFail = (error) => {
  return {
    type: ADD_THIRD_PARTY_COMPANY_DETAILS_FAIL,
    payload: error,
  };
};
