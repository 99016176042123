export function formatInvoiceList(list, status) {

    return list.map(c => {

        let fullname = "";
        // if (c.job_schedule?.customer?.cust_detail?.type == 1) {
        //     fullname = c.job_schedule?.customer?.cust_detail?.company_name;
        // }
        // else {
        //     fullname = c.job_schedule?.customer?.cust_detail?.firstname + " " + c.job_schedule?.customer?.cust_detail?.lastname;
        // }
        if (c.job_schedule?.vehicle?.user?.customers_details?.[0]?.type == 1) {
            fullname = c.job_schedule?.vehicle?.user?.customers_details?.[0]?.company_name;
        }
        else {
            fullname = c.job_schedule?.vehicle?.user?.customers_details?.[0]?.firstname + " " + c.job_schedule?.vehicle?.user?.customers_details?.[0]?.lastname;
        }

        let invoice_type = "Progress Claim";
        let invoice_total = c.claim_amount;
        let invoice_number = c.invoice_number;

        if (c.tax_invoice?.toString() === "1") {
            invoice_type = "Full";
            invoice_total = c.total;
        }
        else if (c.tax_invoice?.toString() === "2") {
            invoice_type = "Deposit";
            invoice_total = c.claim_amount;
        }
        else if (c.tax_invoice?.toString() === "4") {
            
            if(c?.job_schedule?.show_allocation_column == 1){
                invoice_type = "Split (Allocation)";
            }else{
                invoice_type = "Split";
            }

            if (c?.customer_preview_invoice === 1) {
                invoice_total = c.customer_invoice_total;
                invoice_number = c.invoice_number + " - A";
            } else if(c?.insurance_preview_invoice === 1){
                invoice_total = c.insurance_invoice_total;
                invoice_number = c.invoice_number + " - B";
            } else if(c?.customer_invoice_allocation_preview === 1) {
                invoice_total = c.customer_invoice_allocation_total;
                invoice_number = c.invoice_number + " - C";
            }
        }

        return {
            id: c.id,
            invoice_number: invoice_number,
            date: c.date_issued,
            customer: fullname,
            job_no: c.job_schedule.job_number,
            job_total: c.job_schedule.total,
            invoice_type: invoice_type,
            invoice_balance: c.remain_balance,
            // invoice_total: c.tax_invoice.toString() === "1" ? c.total : c.claim_amount,
            invoice_total: invoice_total,
            invoice_status: status,
            comments: c.comments,
            type: "normal",
            data: {
                ...c,
            },
            invoices_payments_credits: c?.invoices_payments_credits || [],
        }
    })
}

export function formatSaleInvoiceList(list, status) {
    return list.map(c => {

        return {
            id: c.id,
            invoice_number: c.cash_sale_no,
            date: c.created_on,
            customer: c.user.customers_details[0].type === "1" ?
                c.user.customers_details[0].company_name :
                (c.user.customers_details[0].firstname
                    + " " +
                    c.user.customers_details[0].lastname),
            job_no: "Cash Sale",
            job_total: "0",
            invoice_type: "Full",
            invoice_balance: 0,
            invoice_total: c.total,
            invoice_status: status,
            comments: c.description,
            type: "sale",
            data: {
                ...c,
            }
        }
    })
}


