import { apiCall } from "helpers/apiHelper";
import {
  CASH_SALE_PAYMENTS_CREDITS_SAVE,
  EDIT_CASH_SALE_INVOICE,
  INVOICES_PAYMENTS_CREDITS_SAVE,
  UPDATE_INVOICE,
  UPDATE_JOB_TIMER,
  START_STATEMENT_PAYMENT
} from "helpers/urlHelper";

export async function createInvoicePayment(payload) {
  if (parseFloat(payload.balance_remaining) === 0) {
    /*
            Full Payment
            Update invoice status = 2
            for normal invoice update job status and job stage
        */

    const req = {
      invoice_status: "2",
      id: payload.invoice_id,
    };

    if (payload.type === "cash_sale") {
      await apiCall(EDIT_CASH_SALE_INVOICE, { payload: req }, "POST", true);
    } else {
      const p = await apiCall(UPDATE_INVOICE, { payload: req }, "POST", true);

      let job_id = p.data.Invoices.job_id;
      let tax_invoice = p.data.Invoices.tax_invoice;

      let job_detail;
      let job_stage = "";
      let job_status = "";

      if(payload?.job_stage == "Unassigned"){
        job_stage = "Unassigned";
        job_status = "Unassigned";
      }

      if (tax_invoice === "4") {
        if (payload?.previou_bill_paid === false) {
          // job_detail = {
          //   job_status: job_status ? job_status : "Pending",
          //   job_stage:job_stage ? job_stage : "Bookin",
          //   id: job_id,
          // };
          job_detail = {
            job_status: job_status ? job_status : "Invoiced",
            job_stage:job_stage ? job_stage : "Completed",
            id: job_id,
          };
        } else {
          job_detail = {
            job_status: job_status ? job_status : "Payment",
            job_stage:job_stage ? job_stage : "Completed",
            id: job_id,
          };
        }
      } else {
        if (payload?.remain_payment > 0) {
          job_detail = {
            job_status:job_status ? job_status : "Pending",
            job_stage: job_stage ? job_stage : "Bookin",
            id: job_id,
          };
        } else {
          job_detail = {
            job_status: job_status ? job_status :"Payment",
            job_stage: job_stage ? job_stage : "Completed",
            id: job_id,
          };
        }
      }

      await apiCall(UPDATE_JOB_TIMER, { payload: job_detail }, "POST", true);
    }
  }

  const pr = {
    ...payload,
    cash_sale_invoice_id: payload?.invoice_id,
  };
  let url;
  delete pr.type;

  pr["balance_remaining"] = pr["balance_remaining"].toFixed(2);
  pr["payment_method"] = +pr["payment_method"];

  //   return;
  if (payload.type === "cash_sale") {
    url = CASH_SALE_PAYMENTS_CREDITS_SAVE;
  } else {
    url = INVOICES_PAYMENTS_CREDITS_SAVE;
  }

  const data = await apiCall(url, { payload: pr }, "POST", true);
  if (data?.code === 200) {
    const key =
      payload.type !== "cash_sale"
        ? "InvoicesPaymentsCredits"
        : "CashSalePaymentsCredits";
    return { ...data.data[key], type: payload.type };
  } else {
    throw new Error("Failed to create payment");
  }
}


export async function createStatementPayment(payload){
  var data = await apiCall(START_STATEMENT_PAYMENT, { payload: payload }, "POST", true);
  if (data?.code === 200) {
    return { ...data.data, type: payload.type };
  } else {
    throw new Error("Failed to create payment");
  }
}