import { Modal } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import "../../../style.scss";
import "../../style.scss";
import Highcharts from 'highcharts';
// import drilldow from "highcharts/modules/drilldown";

export default function BarchartModal({
  open = false,
  details,
  typeText,
  workshopList,
  setBarChartDetails
}) {
  // drilldow(Highcharts);

  const handleClose = () => {
    setBarChartDetails(false)
  };

  // Create the chart
  const barChartOptions = {
    colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
      return {
        radialGradient: {
          cx: 0.5,
          cy: 0.3,
          r: 0.7
        },
        stops: [
          [0, color],
          [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
        ]
      };
    }),
    chart: {
      type: 'column'
    },
    title: {
      align: 'left',
      text: ""
    },
    accessibility: {
      announceNewData: {
        enabled: false
      }
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: 'Total '+typeText
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          // format: '{point.y:.1f}%'
          format: '{point.y}'
        }
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    },
    series: [
      {
        name: 'Workshops',
        colorByPoint: true,
        data: [
          {
            name: 'test business',
            y: 63,
          },
          {
            name: 'Madhavi Company T',
            y: 84,
          },
          {
            name: 'Demo Business',
            y: 4,
          },
          {
            name: 'Business 1',
            y: 10,
          },
          {
            name: 'Business 2',
            y: 2,
          },
          {
            name: 'DRD',
            y: 20,
          },
          {
            name: 'TECH TEST',
            y: 63,
          },
          {
            name: 'TECH TEST1',
            y: 84,
          },
          {
            name: 'TECH TEST2',
            y: 4,
          },
          {
            name: 'TECH TEST3',
            y: 10,
          },
          {
            name: 'TECH TEST4',
            y: 2,
          },
          {
            name: 'TECH TEST5',
            y: 20,
          },
          {
            name: 'TECH TEST6',
            y: 20,
          },
          {
            name: 'TECH TEST7',
            y: 30,
          },
          {
            name: 'TECH TEST8',
            y: 4,
          },
          {
            name: 'TECH TEST9',
            y: 10,
          },
          {
            name: 'TECH TEST10',
            y: 2,
          },
          {
            name: 'TECH TEST11',
            y: 20,
          },
          {
            name: 'Other',
            y: 5,
          }
        ]
      }
    ]
  }

  return (
    <Modal show={open} size="lg" onHide={handleClose} centered>
      <Modal.Header>
        <h5 className="text-white">{typeText} Details ({details}) </h5>
        <i
          className="la la-times text-white"
          style={{ cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <HighchartsReact
          // containerProps={{ style: { height: "300px", width: '100px' } }}
          highcharts={Highcharts}
          options={barChartOptions}
          allowChartUpdate={true}
        />
      </Modal.Body>
    </Modal>
  );
}
