import { MANAGE_STATUS_CHANGE } from "./actionTypes";

const initialState = {
  statusChangeTab: null,
};

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_STATUS_CHANGE:
      state = {
        ...state,
        statusChangeTab: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default jobReducer;
