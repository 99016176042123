import { call, put, takeLatest } from "redux-saga/effects";
import { getInspections } from "../../helpers/backendHelper";
import { showError } from "../../utils/alertMessage";
import { getInspectionListFail, getInspectionListSuccess } from "./actions";
import { GET_INSPECTION_LIST } from "./actionTypes";

function* onGetInspectionList(data) {
  try {
    const response = yield call(getInspections, data);
    if (response) {
      if (response.code === 200) {
        yield put(getInspectionListSuccess(response.data.InspInspections));
      } else {
        showError(response.message);
        yield put(getInspectionListFail(response.message));
      }
    } else {
      yield put(getInspectionListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionListFail(error.response));
  }
}

function* InspectionDetailsSaga() {
  yield takeLatest(GET_INSPECTION_LIST, onGetInspectionList);
}

export default InspectionDetailsSaga;
