import LoaderImage from "assets/images/page-loader.svg";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { precise_round, removeCommasNew } from "utils/numberFunction";
import { decimalRegex } from "utils/validationRegex";
import { useCalculate } from "./useCalculate";


const PriceDetailModal = (props) => {
  const allPriceDetail = props.priceDetail;

  //fetch session storage data
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let currency = storedData.currency;

  let part_gst = window.atob(storedData.part_gst); // workshop part gst percentage
  
  let labour_gst = window.atob(storedData.labour_gst); // workshop part gst percentage
  let default_margin =
    storedData.default_margin !== 0
      ? window.atob(storedData.default_margin)
      : ""; // workshop default_margin
  let default_markup =
    storedData.default_markup !== 0
      ? window.atob(storedData.default_markup)
      : ""; // workshop default_markup

  let gst_percent = 0;
  if (props.action === "part") {
    gst_percent = part_gst;
  }
  if (props.action === "labour") {
    gst_percent = labour_gst;
  }


  const [validated, setValidated] = useState(false);
 
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    
    // let applyGst = allPriceDetail?.sell !== allPriceDetail?.retail  ? true : false;
    
    // setFieldValue("applyGst", applyGst);
    // setFieldValue("gstPrice", parseFloat(allPriceDetail?.gst_price).toFixed(2));

    // setFieldValue("costPrice",parseFloat(allPriceDetail?.cost_price).toFixed(2));

    

    if (allPriceDetail?.cost_price) {

      const retail_price = parseFloat(removeCommasNew(allPriceDetail?.retail_price));

      let gst_price = 0;

      if (allPriceDetail?.margin_markup > 0) {
        
        setFieldValue("productMarkup",parseFloat(allPriceDetail?.margin_markup).toFixed(2));
        
      } else {
          let margin_markup = 0;
          if(allPriceDetail?.cost_price > 0 && allPriceDetail?.sell_price > 0){
            let profit_price = parseFloat(allPriceDetail?.sell_price) - parseFloat(allPriceDetail?.cost_price);
            margin_markup = (parseFloat(profit_price) / parseFloat(allPriceDetail?.cost_price)) * 100;
          }
          setFieldValue("productMarkup",parseFloat(margin_markup).toFixed(2));
      }

      if (allPriceDetail?.margin > 0) {
        setFieldValue("productMargin",parseFloat(allPriceDetail?.margin).toFixed(2));
      } else {
        let margin = 0;
        if(allPriceDetail?.cost_price > 0 && allPriceDetail?.sell_price > 0){
          margin = ((allPriceDetail?.sell_price - allPriceDetail?.cost_price) /
            allPriceDetail?.sell_price) *
          100;
        }
        setFieldValue("productMargin",parseFloat(margin).toFixed(2));
      }

     
      setFieldValue("costPrice",parseFloat(allPriceDetail?.cost_price).toFixed(2));

      let profit_price =
          parseFloat(allPriceDetail?.sell_price) -
          parseFloat(allPriceDetail?.cost_price);
      setFieldValue("profitPrice",parseFloat(profit_price).toFixed(2));
     
      setFieldValue("sellPrice",parseFloat(allPriceDetail?.sell_price).toFixed(2));
      
      // setFieldValue("applyGst", allPriceDetail?.is_gst);

      
      let applyGst = "";
      // let applyGst = allPriceDetail?.sell_price !== retail_price  ? true : false;
      if (props.action === "part") {
        applyGst = allPriceDetail?.is_gst === 1  ? true : false;
      }

      if (props.action === "labour") {
        if(gst_price){
          applyGst = allPriceDetail?.gst_price > 0  ? true : false;
        } else {
          applyGst = allPriceDetail?.sell_price !== retail_price  ? true : false;
        }
      }

     
      setFieldValue("applyGst", applyGst);
      
      if(applyGst){
        gst_price = (allPriceDetail?.sell_price * gst_percent) / 100;
      }

      setFieldValue("gstPrice", parseFloat(gst_price || 0).toFixed(2));
      
      setFieldValue("sellPrice",parseFloat(allPriceDetail?.sell_price).toFixed(2));
     
      setFieldValue("retailPrice",parseFloat(retail_price).toFixed(2));
      
    }
  }, [allPriceDetail]);

  
  const { handleChange, values, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      applyGst: true,
      costPrice: 0,
      gstPrice: 0,
      productMargin: +default_margin,
      productMarkup: +default_markup,
      profitPrice: 0,
      retailPrice: 0,
      sellPrice: 0,
    },
  });

  const calculate = useCalculate({
    values,
    part_gst,
    setFieldValue,
  });

  function setBlurPrice(key, value){
    setFieldValue(key, isNaN(parseFloat(value).toFixed(2)) ? "" : parseFloat(value).toFixed(2));
  }

  async function updatePrice() {
    let data = "";

    if (props.action === "part") {
      let profit = values.profitPrice * parseInt(allPriceDetail?.qty);
      let price = values.retailPrice * parseInt(allPriceDetail?.qty);

      data = {
        id: allPriceDetail?.id,
        main_id: allPriceDetail?.main_id,
        cost_price: values.costPrice,
        margin: values.productMargin,
        sell_price: values.sellPrice,
        retail_price: values.retailPrice,
        total: price.toFixed(2),
        gst_price: values.gstPrice,
        margin_markup: values.productMarkup,
        profit_price: profit,
        price: price.toFixed(2),
        gst_total:(parseFloat(allPriceDetail?.qty || 0) * values.gstPrice).toFixed(2),
        sell_total:(parseFloat(allPriceDetail?.qty || 0) * values.sellPrice).toFixed(2)
      };
    }

    if (props.action === "labour") {
      let profit = values.profitPrice * parseFloat(allPriceDetail?.labour_ph);
      let price = values.retailPrice * parseFloat(allPriceDetail?.labour_ph);

      data = {
        id: allPriceDetail?.id,
        cost_price: values.costPrice,
        margin: values.productMargin,
        sell_price: values.sellPrice,
        retail_price: values.retailPrice,
        gst_price: values.gstPrice,
        margin_markup: values.productMarkup,
        profit: profit,
        total_price: price,
        sell_total:parseFloat(values.sellPrice) * parseFloat(allPriceDetail?.labour_ph),
        gst_total:parseFloat(values.gstPrice || 0) * parseFloat(allPriceDetail?.labour_ph),
      };
    }
   
    props.updatePriceDetail(data);
  }

  return (
    <>
      <Modal
        className="create-new-part"
        size="lg"
        show={props.show}
        onHide={props.handleClose}
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-space-between">
            <span>Pricing Details</span>
            <Button
              type="button"
              className="close-btn"
              onClick={props.handleClose}
            >
              <i className="la la-times"></i>{" "}
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated}>
          <Modal.Body>
            <div className="pricing-detail">
              <div className="d-flex">
                <div className="left-block">
                  <div className="form-outer form-align-start">
                    <Form.Group className="form-group width-33 mb-3">
                      <label>Cost Price ({currency})*</label>
                      <Form.Control
                        type="text"
                        required
                        name="costPrice"
                        value={values?.costPrice || ""}
                        onChange={(e) => {
                          if (!decimalRegex.test(e.target.value)) return;
                          handleChange(e);
                          // calculate(e.target.name, e.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          calculate(e.target.name, e.target.value);
                          setBlurPrice(e.target.name, e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group width-33 mb-3">
                      <label>Markup %</label>
                      <Form.Control
                        type="text"
                        name="productMarkup"
                        value={isNaN(values.productMarkup) ? 0 : values.productMarkup}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="form-group width-33 mb-3">
                      <label>Sell Price ({currency})*</label>
                      <Form.Control
                        type="text"
                        required
                        value={values?.sellPrice  || ""}
                        name="sellPrice"
                        onChange={(e) => {
                          if (!decimalRegex.test(e.target.value)) return;
                          handleChange(e);
                          calculate(e.target.name, e.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          setBlurPrice(e.target.name, e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="form-group width-50">
                      <label>Markup % *</label>
                      <div className="custom-perc-group d-flex">
                        <span>
                          <Form.Check className="radio-btn mb-2" type="radio" id="2">
                            <Form.Check.Input
                              type="radio"
                              value="15.00"
                              id="product-15"
                              name="productMarkup"
                              checked={values.productMarkup === "15.00"}
                              onChange={(e) => {
                                if (!decimalRegex.test(e.target.value)) return;
                                handleChange(e);
                                calculate(e.target.name, e.target.value);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                            />
                            <Form.Check.Label>
                              15%
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </span>
                        <span>
                          <Form.Check className="radio-btn mb-2" type="radio">
                            <Form.Check.Input
                              type="radio"
                              value="25.00"
                              id="product-25"
                              checked={values.productMarkup === "25.00"}
                              name="productMarkup"
                              onChange={(e) => {
                                if (!decimalRegex.test(e.target.value)) return;
                                handleChange(e);
                                calculate(e.target.name, e.target.value);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                            />
                            <Form.Check.Label>
                              25%
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </span>

                        {/* add default markup */}
                        {default_markup && (
                          <span>
                            <Form.Check className="radio-btn mb-2" type="radio">
                              <Form.Check.Input
                                type="radio"
                                value={parseFloat(default_markup).toFixed(2)}
                                id={`product-${default_markup}`}
                                name="productMarkup"
                                checked={
                                  values.productMarkup ===
                                  parseFloat(default_markup).toFixed(2)
                                }
                                onChange={(e) => {
                                  if (!decimalRegex.test(e.target.value)) return;
                                  handleChange(e);
                                  calculate(e.target.name, e.target.value);
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                }}
                              />
                              <Form.Check.Label>
                                {default_markup}%<span className="check"></span>
                              </Form.Check.Label>
                            </Form.Check>
                          </span>
                        )}

                        <span>
                          <Form.Control
                            type="text"
                            required
                            name="productMarkup"
                            value={isNaN(values.productMarkup) ? 0 : values.productMarkup}
                            onChange={(e) => {
                              if (!decimalRegex.test(e.target.value)) return;
                              handleChange(e);
                              calculate(e.target.name, e.target.value);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              setBlurPrice(e.target.name, e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            This field is required.
                          </Form.Control.Feedback>
                        </span>
                      </div>
                    </div>
                    <div className="form-group width-50">
                      <label>Margin % *</label>
                      <div className="custom-perc-group d-flex">
                        <span>
                          <Form.Check className="radio-btn mb-2" type="radio" id="3">
                            <Form.Check.Input
                              type="radio"
                              value="15.00"
                              id="productMargin-15"
                              name="productMargin"
                              checked={values.productMargin === "15.00"}
                              onChange={(e) => {
                                if (!decimalRegex.test(e.target.value)) return;
                                handleChange(e);
                                calculate(e.target.name, e.target.value);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                            />
                            <Form.Check.Label>
                              15%
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </span>
                        <span>
                          <Form.Check className="radio-btn mb-2" type="radio">
                            <Form.Check.Input
                              type="radio"
                              value="25.00"
                              id="productMargin-25"
                              name="productMargin"
                              checked={values.productMargin === "25.00"}
                              onChange={(e) => {
                                if (!decimalRegex.test(e.target.value)) return;
                                handleChange(e);
                                calculate(e.target.name, e.target.value);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                            />
                            <Form.Check.Label>
                              25%
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </span>

                        {/* add default margin */}
                        {default_margin && (
                          <span>
                            <Form.Check className="radio-btn mb-2" type="radio">
                              <Form.Check.Input
                                type="radio"
                                value={parseFloat(default_margin).toFixed(2)}
                                id={`productMargin-${default_margin}`}
                                checked={
                                  values.productMargin ===
                                  parseFloat(default_margin).toFixed(2)
                                }
                                name="productMargin"
                                onChange={(e) => {
                                  if (!decimalRegex.test(e.target.value)) return;
                                  handleChange(e);
                                  calculate(e.target.name, e.target.value);
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                }}
                              />
                              <Form.Check.Label>
                                {default_margin}%<span className="check"></span>
                              </Form.Check.Label>
                            </Form.Check>
                          </span>
                        )}

                        <span>
                          <Form.Control
                            type="text"
                            required
                            value={isNaN(values.productMargin) ? 0 : values.productMargin}
                            name="productMargin"
                            onChange={(e) => {
                              if (!decimalRegex.test(e.target.value)) return;
                              handleChange(e);
                              calculate(e.target.name, e.target.value);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              setBlurPrice(e.target.name, e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            This field is required.
                          </Form.Control.Feedback>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-block">
                  <div className="form-outer form-align-start">
                    <Form.Group className="form-group width-50 mb-3">
                      <label>GST {part_gst}%</label>
                      <Form.Control
                        type="text"
                        name="product_gst_price"
                        id="product_gst_price"
                        readOnly
                        value={values?.gstPrice || 0}
                      />
                    </Form.Group>
                    <Form.Group className="form-group width-50 mb-3">
                      <label>Profit ({currency})</label>
                      <Form.Control
                        type="text"
                        name="profitPrice"
                        readOnly
                        value={values?.profitPrice || 0}
                      />
                    </Form.Group>
                    <Form.Group className="form-group width-100 mb-3">
                      <label>Retail Price ({currency})*</label>
                      <Form.Control
                        type="text"
                        required
                        name="retailPrice"
                        value={values?.retailPrice || ""}
                        onChange={(e) => {
                          if (!decimalRegex.test(e.target.value)) return;
                          handleChange(e);
                          calculate(e.target.name, e.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          setBlurPrice(e.target.name, e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                      <Form.Control
                        type="hidden"
                        name="purchasing_tax"
                        id="purchasing_tax"
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="outline-secondary"
              className="btn-outline rounded-btn"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <Button
              className="rounded-btn btn-success"
              variant="primary"
              type="button"
              onClick={updatePrice}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* {isLoading === true && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )} */}
    </>
  );
};
export default PriceDetailModal;
