import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const timeClockingTab = Loadable({
  loader: () => import("./components/timeClockingTab"),
  loading: Loading,
});


export const routes = [
  {
    path: "/time-clocking",
    exact: true,
    name: "timeClockingTab",
    element: timeClockingTab,
  }
 
];

export * from "./components";
