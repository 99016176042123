import { useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import { downloadFileFromS3 } from "utils/uploadFileToS3";
import logo from "../../../assets/images/5iQ_logo.png";
import { UPDATE_WORKSHOP_OTHER_DETAIL } from "../../../store/commonApiStore/actionTypes";
import { GET_GLOBAL_SEARCH } from "../../../store/globalSearch/actionTypes";
import { GET_NOTIFICATION } from "../../../store/notification/actionTypes";
import { useInitialData } from "../../../views/common/InitialDataContext/InitialDataContext";
import InspectionNotification from "../../modals/inspectionNotification";
import LeadNotification from "../../modals/leadNotification";
import LookupBalance from "../../modals/lookupBalance";
import SmsBalance from "../../modals/smsBalance";
import ChangePassword from "./ChangePassword";

// import { onReceiveMessage } from "firbaseInit";
import {
  getInspectionNotificationSuccess,
  getOnlineScheduleNotificationSuccess,
} from "../../../store/commonApiStore/actions";
const HQHeader = (props) => {
  const [isShown, setIsShown] = useState(false);

  const {
    expDayCount,
    isTrialMode,
    setChecked,
    subscriptionType,
    isChecked,
    isPlanExpire,
    userName,
  } = useInitialData();

  
  // const [isSearchOpen, setSearchOpen] = useState(false);
  
  
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(0);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showLookupBalanceModal, setShowLookupBalanceModal] = useState(false);
  const [showSmsBalanceModal, setShowSmsBalanceModal] = useState(false);
  const [balanceType, setBalanceType] = useState();
  const [searchType, setSearchType] = useState("customer");
  const [eventKey, setEventKey] = useState("customer");
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));

  // const storedData = JSON.parse(sessionStorage.getItem("stored_data"));

  // storedData.is_inspection_business = 0;

  // let user_id = window.atob(storedData.user_id);
  // let workshop_id = window.atob(storedData.workshop_id);
  // let workshop_other_id = window.atob(storedData.workshop_other_id);
  let userProfile = getSessionData("userProfile", false);
  let workShopData = JSON.parse(localStorage.getItem("data"));
  // let workShopIcon = workShopData?.workshop?.logo || "";

  let worshopLogo = getSessionData("workshop_logo", false);
  const workShopIcon = useMemo(() => {
    if (worshopLogo) {
      // return worshopLogo;
      const url = downloadFileFromS3(worshopLogo);
      return url;
    } else {
      // return "";
      return logo;
    }
  }, [worshopLogo]);

  let workShopName = workShopData?.workshop?.name || "";
  let workshopSessionName = window.atob(storedData.workshop_name);
  const workshopName = useMemo(() => {
    return workshopSessionName;
  }, [workshopSessionName]);

  const profileImage = useMemo(() => {
    if (userProfile) {
      const url = downloadFileFromS3(userProfile);
      return url;
    } else {
      return logo;
    }
  }, [userProfile]);

  const ref = useRef();

  const handleChange = () => {
    setChecked(!isChecked);
  };

  const dispatch = useDispatch();

  // change theme mode
  const handleClick = (ev) => {
    document
      .querySelector("body")
      .classList[ev.target.checked ? "remove" : "add"]("dark-theme");
    var theme_value = 0;
    if (ev.target.checked === true) {
      storedData.theme_mode = "1";
      theme_value = 1;
    } else {
      storedData.theme_mode = "0";
    }
    sessionStorage.setItem("stored_data", JSON.stringify(storedData));

    dispatch({
      type: UPDATE_WORKSHOP_OTHER_DETAIL,
      payload: {
        workshop_id: getWorkshopId(),
        is_light_mode: theme_value,
        id: getSessionData("workshop_other_id"),
      },
    });
  };

  // header menu dropdown active / inactive
  useEffect(() => {
    function checkIfClickedOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // onClickOutside();
        setActiveDropdown(0);
        // document.getElementById("search_value").value = "";
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  // get notification count from push notification
  const { onlineScheduleNotification, inspectionNotification } = useSelector(
    (state) => state.CommonApiStoreReducer
  );

  const [leadNotificationData, setLeadNotifictionData] = useState(null);
  useEffect(() => {
    if (
      onlineScheduleNotification !== null &&
      onlineScheduleNotification !== undefined
    ) {
      let successVal = onlineScheduleNotification?.success || 0;
      if (successVal == 1) {
        handleLeadReceivedShow();
        setLeadNotifictionData(onlineScheduleNotification);
      }
    }
  }, [onlineScheduleNotification]);

  const [inspectionNotificationData, setInspectionNotificationData] =
    useState(null);
  useEffect(() => {
    if (
      inspectionNotification !== null &&
      inspectionNotification !== undefined
    ) {
      let successVar = inspectionNotification?.success || 0;
      if (successVar == 1) {
        handleInspectionShow();
        setInspectionNotificationData(inspectionNotification);
      }
    }
  }, [inspectionNotification]);

  // all default dispatch
  useEffect(() => {
    // get notificaiton list
    dispatch({
      type: GET_NOTIFICATION,
      payload: {
        get: "all",
        conditions: {
          to_id: getSessionData("user_id"),
          is_read: 0,
          "type !=": "inspection_verification_sms",
          workshop_id: getWorkshopId(),
        },
        limit: 5,
        order: {
          id: "desc",
        },
        fields: ["id", "msg", "type"],
      },
    });
  }, [dispatch]);

  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);

  const handleCloseLookupBalanceModal = () => setShowLookupBalanceModal(false);
  const handleShowLookupBalanceModal = () => setShowLookupBalanceModal(true);

  const handleCloseSmsBalanceModal = () => setShowSmsBalanceModal(false);
  const handleShowSmsBalanceModal = () => setShowSmsBalanceModal(true);

  // SMS and REGO Lookup popup START
  function getBalanceDetail(type) {
    setBalanceType(type);

    // document.getElementById('page-loader').style.display = 'flex';
    if (type === "lookup") {
      handleShowLookupBalanceModal();
    }

    if (type === "sms") {
      handleShowSmsBalanceModal();
    }
  }
  // SMS and REGO Lookup popup END

  // Global Search START
  function getGlobalSearchdata() {
    var search_value = document.getElementById("search_value").value;

    if (search_value.length > 2) {
      dispatch({
        type: GET_GLOBAL_SEARCH,
        payload: {
          workshop_id: getWorkshopId(),
          keyword: search_value,
          type: searchType,
        },
      });

      setActiveDropdown(1);
    } else {
      // setActiveDropdown(0);
    }
  }

  //set search type
  const handleSearchType = (key) => {
    var search_type = key;
    setEventKey(key);
    if (key === "order") {
      search_type = "supplier";
    }

    var search_value = document.getElementById("search_value").value;

    dispatch({
      type: GET_GLOBAL_SEARCH,
      payload: {
        workshop_id: getWorkshopId(),
        keyword: search_value,
        type: search_type,
      },
    });

    setActiveDropdown(1);
    setSearchType(search_type);
  };

  const globalSearchData = useSelector((state) => state.GlobalSearchReducer);
  // Global Search END

  const [dashboardTitle, setDashboardTitle] = useState("Business Stats");
  const [dashboardLink, setDashboardLink] = useState("Business Stats");
  useEffect(() => {
    if (window.location.pathname.split("/").pop() === "home") {
      setDashboardTitle("Business Stats");
      setDashboardLink("/home");
    } else if (window.location.pathname.split("/").pop() === "booking-diary") {
      // setDashboardTitle("Booking Diary");
      // setDashboardLink("/booking-diary");
    }
  }, [window.location.pathname.split("/").pop()]);

  const [inspectionShow, setInspectionShow] = useState(false);
  const handleInspectionClose = () => {
    setInspectionShow(false);
    dispatch(getInspectionNotificationSuccess(null));
  };
  const handleInspectionShow = () => {
    setInspectionShow(true);
  };

  const [leadReceivedShow, setLeadReceivedShow] = useState(false);
  const handleLeadReceivedClose = () => {
    setLeadReceivedShow(false);
    dispatch(getOnlineScheduleNotificationSuccess(null));
  };
  const handleLeadReceivedShow = () => {
    setLeadReceivedShow(true);
  };

  const bookDemoUrl = (url) => {
    // 👇️ setting target to _blank with window.open
    window.open(
      "https://koalendar.com/e/system-demo",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <div className="header-outer">
        {/* <div className="inspection-banner d-flex align-center justify-space-between">
          <div className="d-flex align-center">
            <b className="mr-5">Inspection has been Approved By:</b>
            <span className="mr-5">madhavi testt | </span>
            <span className="d-flex align-center m-r-10">
              <i className="font-size-20 fa fa-user mr-5"></i>
              <span>madhavi testt</span>
            </span>
            <span className="d-flex align-center m-r-10">
              <i className="font-size-20 ficon-number-plate"></i>
              <span>rt56</span>
            </span>
            <Link to="/" className="view-link">
              <i className="fa fa-eye m-r-5 m-l-5"></i>
            </Link>
          </div>
          <Button className="close-btn">
            <i class="la la-times"></i>
          </Button>
        </div> */}

        <Container fluid>
          {/* {/ Trial period expire message /} */}
          {subscriptionType === "Trial" && isTrialMode && (
            <div className="align-center d-flex justify-space-between subscribe-header ">
              <div className="center-align ml-5">
                {expDayCount > 0 ? (
                  // <p>{expDayCount} Days Remaining Before Your Trial Expires</p>
                  <p>
                    Trial mode
                    <span
                      className="border border-danger font-bold-600 p-1 text-danger m-1"
                      style={{ borderRadius: "15px" }}
                    >
                      {expDayCount} Days
                    </span>
                    Remaining{" "}
                  </p>
                ) : (
                  <p>Your Trial Expired</p>
                )}
                <Link to="/subscription-plan">
                  <Button
                    variant="success"
                    className="btn-rounded btn-green-gradient"
                  >
                    Subscribe Now
                  </Button>
                </Link>
              </div>

              <div className="align-center d-flex right-align header-m-5">
                <p className="m-r-10">Learn Faster</p>
                <Button
                  variant="success"
                  className="btn-rounded btn-gradient"
                  onClick={() => bookDemoUrl()}
                >
                  Book Demo
                </Button>
              </div>
            </div>
          )}

          {/* {/ Subscribe expire message /} */}
          {isPlanExpire && subscriptionType === "Subscription" && (
            <div className="subscribe-header d-flex align-center justify-center ">
              <div className="center-align">
                <p>Your Subscription Expired</p>
                <Link to="/subscription-plan">
                  <Button
                    variant="success "
                    className="btn-rounded btn-green-gradient"
                  >
                    Subscribe Now
                  </Button>
                </Link>
              </div>
            </div>
          )}

          {subscriptionType === "Trial" && !isTrialMode && isPlanExpire && (
            <div className="subscribe-header d-flex align-center justify-center ">
              <div className="center-align">
                <p>Your Subscription Expired</p>
                <Link to="/subscription-plan" className="btn-rounded">
                  <Button variant="success" className="btn-green-gradient">
                    Subscribe Now
                  </Button>
                </Link>
              </div>
            </div>
          )}

          {/* main header */}
          <div className="header-wrapper">
            <div className="left-header">
              <div className="profile-wrapper toggle-link">
                <i className="profile-icon">
                  <img
                    src={workShopIcon}
                    // src={
                    //   workShopIcon == ""
                    //     ? logo
                    //     : `https://5iq-website-uploads.s3.ap-southeast-2.amazonaws.com/${workShopIcon}`
                    // }
                    alt="Workshop Logo"
                  ></img>
                </i>
              </div>
              <Link to="/dashboard" className="workshop-name">
                {workshopName || "My Workshop"}
              </Link>
              {/* <Link className="business-dropdown-link" to={dashboardLink}>{dashboardTitle}</Link>
              <DropdownButton
                className="business-dropdown custom-dropdown"
                title=""
              >
                <Dropdown.Item eventKey="Business Stats">
                  <Link
                    to="/home"
                    onClick={() => setDashboardTitle("Business Stats")}
                  >
                    Business Stats
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey="Booking Diary">
                  <Link
                    to="/booking-diary"
                    onClick={() => setDashboardTitle("Booking Diary")}
                  >
                    Booking Diary
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey="Mobile Dashboard">
                  <Link to="/home">Mobile Dashboard</Link>
                </Dropdown.Item>
              </DropdownButton> */}
            </div>
            <div className="right-header">
              <ul ref={ref}>
                <li
                  className={`account-block ${
                    activeDropdown === 5 ? "active-menu" : ""
                  }`}
                  onClick={() =>
                    setActiveDropdown(
                      activeDropdown === 5 ? (isProfileOpen ? 5 : 0) : 5
                    )
                  }
                >
                  <div
                    className="profile-wrapper toggle-link"
                    onClick={() => setProfileOpen((oldState) => !oldState)}
                  >
                    <i className="profile-icon">
                      <img src={profileImage} alt="No Result"></img>
                    </i>
                    <div className="profile-name">
                      <span id="header_username">{userName}</span>
                      <i className="fa fa-angle-down"></i>
                    </div>
                  </div>
                  {activeDropdown === 5 && (
                    <ul className="profile-list custom-header-dropdown">
                      <li>
                        <Link to="/profile">
                          <span>Profile</span>
                        </Link>
                      </li>
                      <li onClick={handleShowPasswordModal}>
                        <Link to="">
                          <span>Change Password</span>
                        </Link>
                      </li>
                      <li className="switch-wrapper">
                        <span>Switch Light Mode</span>
                        <Form.Check
                          type="switch"
                          checked={isChecked}
                          onChange={handleChange}
                          onClick={handleClick}
                          className="custom-switch"
                          id="change-theme"
                        />
                      </li>
                      <li>
                        <Link to="/logout">
                          <span>Log Out</span>
                          <i className="fa fa-sign-out"></i>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>

      {showPasswordModal && (
        <ChangePassword
          showPasswordModal={showPasswordModal}
          handleShowPasswordModal={handleShowPasswordModal}
          handleClosePasswordModal={handleClosePasswordModal}
        />
      )}

      {showLookupBalanceModal && (
        <LookupBalance
          showLookupBalanceModal={showLookupBalanceModal}
          handleShowLookupBalanceModal={handleShowLookupBalanceModal}
          handleCloseLookupBalanceModal={handleCloseLookupBalanceModal}
        />
      )}

      {showSmsBalanceModal && (
        <SmsBalance
          showSmsBalanceModal={showSmsBalanceModal}
          handleShowSmsBalanceModal={handleShowSmsBalanceModal}
          handleCloseSmsBalanceModal={handleCloseSmsBalanceModal}
        />
      )}

      {inspectionShow && (
        <InspectionNotification
          inspectionShow={inspectionShow}
          handleInspectionShow={handleInspectionShow}
          handleInspectionClose={handleInspectionClose}
          inspectionNotificationData={inspectionNotificationData}
        />
      )}

      {leadReceivedShow && (
        <LeadNotification
          leadReceivedShow={leadReceivedShow}
          handleLeadReceivedShow={handleLeadReceivedShow}
          handleLeadReceivedClose={handleLeadReceivedClose}
          leadNotificationData={leadNotificationData}
        />
      )}
    </>
  );
};

export default HQHeader;
