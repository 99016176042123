import { combineReducers } from "redux";
import { InvoiceReportsReducer } from "./miniReducer/invoice";
import { JobReportsReducer } from "./miniReducer/job";
import { QuoteReportsReducer } from "./miniReducer/quote";
import { CustomerReportsReducer } from "./miniReducer/customer";
import { LabourReportsReducer } from "./miniReducer/labour";
import { MaterialReportsReducer } from "./miniReducer/material";
import { SupplierStatementReducer } from "./miniReducer/statement";

export const reportReducer = combineReducers({
  statementReports: SupplierStatementReducer,
  invoiceReports: InvoiceReportsReducer,
  jobReports: JobReportsReducer,
  quoteReports: QuoteReportsReducer,
  customerReports: CustomerReportsReducer,
  labourReports: LabourReportsReducer,
  materialReports: MaterialReportsReducer,
});
