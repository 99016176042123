import { usePresence } from "framer-motion";
import { Fragment, useEffect, useState } from "react";

export const Presence = ({ children }) => {
  const [isPresent, safeToRemove] = usePresence();
  const [show, setShow] = useState(isPresent);

  useEffect(() => {
    let cl;
    if (!isPresent) {
      setShow(false);
      cl = setTimeout(safeToRemove, 100);
    }
    return () => {
      if (cl) {
        clearTimeout(cl);
      }
    };
  }, [isPresent]);

  return <Fragment>{children(show)}</Fragment>;
};
