import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const ContactsList = Loadable({
  loader: () => import("./components/Contacts"),
  loading: Loading,
});

const BranchList = Loadable({
  loader: () => import("./components/suppliers/Branch"),
  loading: Loading,
});

const CreateCustomer = Loadable({
  loader: () => import("./components/Customer/CreateCustomer"),
  loading: Loading,
});

const EditCustomer = Loadable({
  loader: () => import("./components/Customer/EditCustomer"),
  loading: Loading,
});

const CreateEmployee = Loadable({
  loader: () => import("./components/Employees/CreateEmployee"),
  loading: Loading,
});

const EditEmployee = Loadable({
  loader: () => import("./components/Employees/EditEmployee"),
  loading: Loading,
});

const ViewEmployee = Loadable({
  loader: () => import("./components/Employees/ViewEmployee/ViewEmployee"),
  loading: Loading,
});

const CreateSupplier = Loadable({
  loader: () => import("./components/suppliers/CreateSupplier"),
  loading: Loading,
});

const EditSupplier = Loadable({
  loader: () => import("./components/suppliers/EditSupplier"),
  loading: Loading,
});

const EditBranch = Loadable({
  loader: () => import("./components/suppliers/components/EditBranch"),
  loading: Loading,
});

const CreateBranch = Loadable({
  loader: () => import("./components/suppliers/components/CreateBranch"),
  loading: Loading,
});

export const routes = [
  {
    path: "/contacts_list",
    exact: true,
    name: "ContactsList",
    element: ContactsList,
  },
  {
    path: "/get_branch/:id",
    exact: true,
    name: "BranchList",
    element: BranchList,
  },
  {
    path: "/customer_company",
    exact: true,
    name: "CreateCustomer",
    element: CreateCustomer,
  },
  {
    path: "/edit_customer/:id",
    exact: true,
    name: "EditCustomer",
    element: EditCustomer,
  },
  {
    path: "/employee",
    exact: true,
    name: "CreateEmployee",
    element: CreateEmployee,
  },
  {
    path: "/edit_employee/:id",
    exact: true,
    name: "EditEmployee",
    element: EditEmployee,
  },
  {
    path: "/supplier",
    exact: true,
    name: "CreateSupplier",
    element: CreateSupplier,
  },
  {
    path: "/edit_supplier/:id",
    exact: true,
    name: "EditSupplier",
    element: EditSupplier,
  },
  {
    path: "/edit_branch/:id",
    exact: true,
    name: "EditBranch",
    element: EditBranch,
  },
  {
    path: "/branch/:id",
    exact: true,
    name: "CreateBranch",
    element: CreateBranch,
  },
  {
    path: "/view_employee/:id",
    exact: true,
    name: "ViewEmployee",
    element: ViewEmployee,
  },
  
];

export * from "./components";
