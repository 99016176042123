import React, { useContext, useState } from 'react';
export const DataContext = React.createContext(null);

const DataProvider = (props) => {
     const [data, setData] = useState();
     return (
          <DataContext.Provider value={{ data, setData }}>
               {props.children}
          </DataContext.Provider>
     )
}
export default DataProvider

export function useDataProvider() {
     return useContext(DataContext);
}