import { getInvoicesCredits, getJobCannedList, getJobEmailTemplateList, getUserProfile, getWorkshopOtherDetailsView } from "helpers/backendHelper";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import { getMessageTemplatePayload } from "../api";

export async function getInvoiceCreditEmailDetail(id) {
  let invoices_payments_credits_other,
    invoice,
    job_detail,
    credit_number,
    email,
    scripts,
    comp_logo,
    address,
    otherdata,
    customer,
    message_template,
    type = "credit";
  const request1 = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCreditsOtherDetails.id": id,
    },
    contain: {
      0: "Workshops",
      1: "Invoices.Users.States",
      2: "Invoices.JobSchedules.Vehicles",
      3: "Invoices.JobSchedules.CreatedByUser",
      "Invoices.Users.CustomersDetails": {
        conditions: {
          workshop_id: getWorkshopId(),
        },
      },
      "Invoices.JobSchedules.Customers.CustDetails": {
        conditions: {
          "CustDetails.workshop_id": getWorkshopId(),
        },
      },
      "Invoices.JobSchedules.Customers.States":{
        "fields" : ["id","name"]
      },
      "Invoices.JobSchedules.Customers.Countries":{
        "fields" : ["id","country_name"]
      },
      "Invoices.JobSchedules.Customers.CustDetails.Countries":{
        "fields" : ["id","country_name"]
      },
      "Invoices.JobSchedules.Customers.CustDetails.States":{
          "fields" : ["id","name"]
      },
      "Invoices.JobSchedules.InsuranceCompany":{
          "fields" : ["id","company_name","contact_name","phone_number","email","website","street_address","country","state","postcode","suburb","abn","acn"]
      },
      "Invoices.JobSchedules.InsuranceCompany.States":{
        "fields" : ["id","name"]
      },
      "Invoices.JobSchedules.InsuranceCompany.Countries":{
          "fields" : ["id","country_name"]
      },
      "Invoices.JobSchedules.InvoiceCCustomer": {
        "fields" : ["id","firstname","lastname","mobile_number","mobile_code","type","company_name","user_id","country","postcode","state","street_address","suburb"]
      },
      "Invoices.JobSchedules.InvoiceCCustomer.Users": {
        "fields" : ["id","email"]
      },      
      "Invoices.JobSchedules.InvoiceCCustomer.Countries":{
        "fields" : ["id","country_name"]
      },
      "Invoices.JobSchedules.InvoiceCCustomer.States":{
          "fields" : ["id","name"]
      },
    },
  };

  const get_invoices = await getInvoicesCredits({ payload: request1 });

  if (get_invoices?.code === 200) {
    invoices_payments_credits_other =
      get_invoices?.data?.InvoicesPaymentsCreditsOtherDetails[0];
      invoice = invoices_payments_credits_other?.invoice;
      credit_number = invoices_payments_credits_other?.credit_no;

      const request2 = {
        get: "all",
        conditions: {
          "Users.id": getSessionData("user_id"),
        },
        contain: [
          "Workshops.States",
          "Workshops.Countries",
          "Workshops.WorkshopOtherDetails",
        ],
      };
  
      const users = await getUserProfile({ payload: request2 });
      if (users?.code === 200) {
        address = users?.data?.Users[0];
      }
  
      customer = invoice?.job_schedule?.customer;
      // email = invoice?.user?.email;
      email = invoice?.job_schedule?.customer?.email;

  } else {
    throw new Error("No Invoices found");
  }


  const request3 = {
    get: "all",
    conditions: { workshop_id: getWorkshopId(), status: 1, "type IN": [3, 8] },
  };

  const get_scripts = await getJobCannedList({ payload: request3 });
  if (get_scripts?.code === 200) {
    scripts = get_scripts?.data?.WorkshopScriptsDetails;
  }

  // get messsage template start
  const get_message_template = await getJobEmailTemplateList({
    payload: getMessageTemplatePayload(getWorkshopId()),
  });

  
  if (get_message_template?.code === 200) {
    message_template = get_message_template?.data?.CustomNotifications;
  }
  // get messsage template end

  const request4 = { workshop_id: getWorkshopId() };
  const getworkshopotherdata = await getWorkshopOtherDetailsView({
    payload: request4,
  });
  if (getworkshopotherdata?.code === 200) {
    otherdata = getworkshopotherdata?.data?.WorkshopOtherDetails;
  }

  return {
    invoices_payments_credits_other,
    invoice,
    job_detail,
    credit_number,
    email,
    scripts,
    comp_logo,
    address,
    otherdata,
    customer,
    type,
    message_template
  };
}
