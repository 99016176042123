export const GET_SUPPLIER_LIST = "GET_SUPPLIER_LIST";
export const GET_SUPPLIER_LIST_SUCCESS = "GET_SUPPLIER_LIST_SUCCESS";
export const GET_SUPPLIER_LIST_FAIL = "GET_SUPPLIER_LIST_FAIL";

export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS";
export const ADD_SUPPLIER_FAIL = "ADD_SUPPLIER_FAIL";

export const CHECK_SUPPLIER_EXIST = "CHECK_SUPPLIER_EXIST";
export const CHECK_SUPPLIER_EXIST_SUCCESS = "CHECK_SUPPLIER_EXIST_SUCCESS";
export const CHECK_SUPPLIER_EXIST_FAIL = "CHECK_SUPPLIER_EXIST_FAIL";

export const GET_SUPPLIER_DETAIL = "GET_SUPPLIER_DETAIL";
export const GET_SUPPLIER_DETAIL_SUCCESS = "GET_SUPPLIER_DETAIL_SUCCESS";
export const GET_SUPPLIER_DETAIL_FAIL = "GET_SUPPLIER_DETAIL_FAIL";