import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_NOTIFICATION_LIST } from "../../../store/notificationList/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import LoaderImage from "../../../../src/assets/images/page-loader.svg";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import bookJobWhite from "assets/images/book-job-white.svg";
import inspectionsWhite from "assets/images/inspections-white.svg";
import customerWhite from "assets/images/customer-white.svg";
import materialsWhite from "assets/images/materials-white.svg";
import { deleteNotificationList } from "helpers/backendHelper";
import { showError, showSuccess } from "utils/alertMessage";

let Notification = () => {
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));

  const dateFormat = storedData.date_format;

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);

    return responseDate;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [notificationListData, setNotificationListData] = useState([]);
  const [notificationLimit, setNotificationLimit] = useState(5);
  const [categoryData, setCategory] = useState("all_category");

  const dispatch = useDispatch();

  // get notification by reducer
  const getNotificationData = useSelector(
    (state) => state.NotificationListReducer
  );

  let user_id = window.atob(storedData.user_id);
  let workshop_id = window.atob(storedData.workshop_id);

  const [title, setTitle] = useState("5iQ | Notification");

  useEffect(() => {
    document.title = "5iQ | Notification";
  }, [title]);

  // query for notification data
  useEffect(() => {
    loadNotificationData();
  }, []);

  function handleChange(e) {
    setCategory(e.target.value);
  }

  function loadNotificationData(action = "") {
    var cat = categoryData;
    var newLimit = 5;
    if (action === "load_more") {
      newLimit = parseInt(notificationLimit) + 5;
    }

    setNotificationLimit(newLimit);
    setIsLoading(true);
    if (cat === "" || cat === "all_category") {
      dispatch({
        type: GET_NOTIFICATION_LIST,
        payload: {
          get: "all",
          conditions: {
            to_id: user_id,
            is_read: 0,
            workshop_id: workshop_id,
            "type !=": "inspection_verification_sms",
          },
          limit: newLimit,
          offset: 0,
          order: {
            id: "desc",
          },
        },
      });
    } else {
      setNotificationLimit(newLimit);
      dispatch({
        type: GET_NOTIFICATION_LIST,
        payload: {
          get: "all",
          conditions: {
            to_id: user_id,
            is_read: 0,
            workshop_id: workshop_id,
            "type !=": "inspection_verification_sms",
            type: cat,
          },
          limit: newLimit,
          offset: 0,
          order: {
            id: "desc",
          },
        },
      });
    }
  }

  async function clearNotificationData(){
    
    let notification_ids = notificationListData.map(a => a.id);
   
    let data = {
      payload: {
        ids: notification_ids
      },
    };

    const response = await deleteNotificationList(data);
    if (response.code === 200) {
      showSuccess("Status changed successfully");
      loadNotificationData();
    } else {
      showError(response.message);
    }
    
  }

  useEffect(() => {
    if (getNotificationData.isFetching === true) {
      setNotificationListData(getNotificationData.notificationListData);
      setIsLoading(false);
    }
  }, [getNotificationData]);

  const columns = [
    {
      dataField: "type1",
      isDummyField: true,
      text: "",
      formatter: (cell, row) => {
        if (row.type === "open_request_job_count") {
          return (
            <div className="d-flex align-center justify-center notification-icon-image">
              <i className="icon-block">
                <img src={bookJobWhite} alt="New Job"></img>
              </i>
            </div>
          );
        } else if (row.type === "inspection_report_response") {
          return (
            <div className="d-flex align-center justify-center notification-icon-image">
              <i className="icon-block">
                <img src={inspectionsWhite} alt="Inspection"></img>
              </i>
            </div>
          );
        } else if (row.type === "special_marketing_expiry") {
          return (
            <span className="d-flex align-center justify-center notification-icon">
              <i className="fa fa-bullhorn"></i>
            </span>
          );
        } else if (row.type === "technician_license_expire") {
          return (
            <div className="d-flex align-center justify-center notification-icon-image">
              <i className="icon-block">
                <img src={customerWhite} alt="Techincian"></img>
              </i>
            </div>
            
          );
        } else if (row.type === "po_reciept_reminder") {
          return (
            <div className="d-flex align-center justify-center notification-icon-image">
              <i className="icon-block">
                <img src={materialsWhite} alt="Pruchase Receipt"></img>
              </i>
            </div>
          );
        } else {
          return (
            <span className="d-flex align-center justify-center notification-icon">
              <i className="fa fa-envelope-o"></i>
            </span>
          );
        }
      },
    },
    {
      dataField: "type",
      text: "Category",
      formatter: (cell, row) => {
        if (row.type === "open_request_job_count") {
          return (
            <span>New Job</span>
          );
        } else if (row.type === "inspection_report_response") {
          return (
            <span>Customer Approval</span>
          );
        } else if (row.type === "special_marketing_expiry") {
          return (
            <span>Campaign Expiry Date</span>
          );
        } else if (row.type === "technician_license_expire") {
          return (
            <span>Technician Licence Expiry</span>
          );
        } else if (row.type === "po_reciept_reminder") {
          return (
            <span>Purchase Order Due Date</span>
          );
        } else {
          return (
            <span>Category</span>
          );
        }
      },
    },
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "msg",
      text: "Message",
    },
    {
      dataField: "created_on",
      text: "Date",
      formatter: dateFormatter,
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cell,row) => {
        
        let redirect_url = "";
        if(row?.type === "open_request_job_count"){
          redirect_url = "/job_detail/"+row?.foreign_id;
        }
        if(row?.type === "inspection_report_response"){
          redirect_url = "/job_detail/"+row?.job_id+"#inspections";
        }
        if(row?.type === "special_marketing_expiry"){
          redirect_url = "/marketing/campaign";
        }
        if(row?.type === "technician_license_expire"){
          redirect_url = "/edit_employee/"+row?.foreign_id;
        }
        if(row?.type === "po_reciept_reminder"){
          redirect_url = "/purchase-view/"+row?.foreign_id;
        }
        
        return (
          <Link to={redirect_url} className="" title="View">
            <span>View</span>
          </Link>
        );
      },
    },
  ];

  return (
    <>


      {isLoading === true && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
      <div className="right-section-wrapper has-white-bg notification-page">
        <div className="table-header row align-center mb-3">
          <div className="col-md-2">
            <div className="table-title m-0">
            <i className="fa fa-bell m-r-10 m-l-5 font-size-24" style={{"color":'red'}}></i>
            
              <span>Notification</span>
            </div>
          </div>
          <div className="col-md-4">
            <Form.Select
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="all_category">All category</option>
              <option value="open_request_job_count">
                New Job
              </option>
              <option value="inspection_report_response">
                Customer Approval
              </option>
              <option value="special_marketing_expiry">
                Campaign Expiry Date
              </option>
              <option value="technician_license_expire">
                Technician Licence Expiry
              </option>
              <option value="po_reciept_reminder">Purchase Order Due Date</option>
            </Form.Select>
          </div>
          <div className="col-md-4">
            <Button
              type="button"
              onClick={() => loadNotificationData()}
              variant="primary"
              className="btn-primary rounded-btn height-34"
            >
              Search
            </Button>
          </div>
        </div>
        <div className="m-b-10">
          {notificationListData && notificationListData.length > 0 ? (
            <BootstrapTable
              keyField="id"
              data={notificationListData}
              columns={columns}
            />
          ) : (
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0 text-uppercase font-size-18">No Data Found...</h5>
              </div>
            </div>
          )}

        </div>

        {notificationListData && notificationListData.length > 0 && (
          <div className="d-flex justify-center">
            <Button
              type="button"
              onClick={() => loadNotificationData("load_more")}
              variant="success"
              className="btn-success rounded-btn height-34 m-r-10"
            >
              Load More
            </Button>

            <Button
              type="button"
              onClick={() => clearNotificationData()}
              variant="danger"
              className="btn-danger rounded-btn height-34"
            >
              Clear All Data
            </Button>

          </div>
        )}

      </div>
    </>
  );
};

export default Notification;
