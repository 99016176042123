import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Scrollbars } from "react-custom-scrollbars";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  EditorState,
  Modifier,
  convertToRaw,
  ContentState,
  convertFromHTML,
  stateToHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { DatePicker, TimePicker } from "antd";
import Accordion from "react-bootstrap/Accordion";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import check from "../../../assets/images/tick-mark.png";
import leadImg from "../../../assets/images/lead-image.png";

let Calender = () => {
  const [show, setShow] = useState(false);
  const [customerShow, setCustomerShow] = useState(false);
  const [rescheduleShow, setRescheduleShow] = useState(false);
  const [MonthViewShow, setMonthViewShow] = useState(false);
  const [lookUpShow, setLookUpShow] = useState(false);
  const [fieldShow, setFieldShow] = useState(false);
  const [bookedInDate, setBookedInDate] = useState(new Date());
  const [activeStep, setActiveStep] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [value, setValue] = useState("10:00");
  const [dateEx3, setDateEx3] = useState("10:12");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [purchaseOrderShow, setPurchaseOrderShow] = useState(false);
  const [inspectionShow, setInspectionShow] = useState(false);
  const [leadReceivedShow, setLeadReceivedShow] = useState(false);

  const handleLookupShow = () => {
    setLookUpShow(!lookUpShow);
  };
  const expandFields = () => {
    setFieldShow(!fieldShow);
  };
  const ChangeActiveStep = () => {
    setActiveStep(!activeStep);
  };

  const handleLeadReceivedClose = () => setLeadReceivedShow(false);
  const handleLeadReceivedShow = () => {
    setLeadReceivedShow(true);
  };

  const handleInspectionClose = () => setInspectionShow(false);
  const handleInspectionShow = () => {
    setInspectionShow(true);
  };

  const handleCustomerClose = () => setCustomerShow(false);
  const handleCustomerShow = () => {
    setCustomerShow(true);
    setShow(false);
  };

  const handleRescheduleClose = () => setRescheduleShow(false);
  const handleRescheduleShow = () => {
    setRescheduleShow(true);
  };

  const handleMonthViewClose = () => setMonthViewShow(false);
  const handleMonthViewJob = () => {
    setMonthViewShow(true);
  };

  const handlePurchaseOrderClose = () => setPurchaseOrderShow(false);
  const handlePurchaseOrderShow = () => {
    setPurchaseOrderShow(true);
  };

  const onEditorStateChange = (editorState) => {
    let draftToHtmlData = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setEditorState(editorState);
  };

  const options = [
    { value: "1", label: "Michelle Burch" },
    { value: "2", label: "Ricky Lee" },
    { value: "3", label: "Darrel Skinner" },
    { value: "4", label: "Donald Lee" },
    { value: "5", label: "Pujan Shah" },
  ];
  const jobOptions = [
    { value: "1", label: "Michelle Burch" },
    { value: "2", label: "Ricky Lee" },
    { value: "3", label: "Darrel Skinner" },
    { value: "4", label: "Donald Lee" },
    { value: "5", label: "Pujan Shah" },
  ];
  const products = [
    {
      id: "1",
    },
    {
      id: "2",
    },
  ];
  const columns = [
    {
      dataField: "Product_No.",
      text: "Product No.",
      sort: true,
      formatter: () => {
        return <div>1223</div>;
      },
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
      formatter: (cell, row) => {
        return <div>Mtest 2</div>;
      },
    },
    {
      dataField: "Supplier",
      text: "Supplier",
      sort: true,
      formatter: (cell, row) => {
        return <span>555.00</span>;
      },
    },
    {
      dataField: "Cost",
      text: "Cost",
      sort: true,
      formatter: (cell, row) => {
        return <span></span>;
      },
    },
    {
      dataField: "Required_Qty",
      text: "Required Qty",
      sort: true,
      formatter: (cell, row) => {
        return <span>1</span>;
      },
    },
    {
      dataField: "Order_Qty",
      text: "Order Qty",
      sort: true,
      style: { width: "180px" },
      formatter: (cell, row) => {
        return (
          <Form.Group className="form-group ">
            <Form.Control type="text" className="small-input" />
          </Form.Group>
        );
      },
    },
  ];
  const labourProducts = [
    {
      id: "1",
    },
    {
      id: "2",
    },
  ];
  const labourColumns = [
    {
      dataField: "Labour",
      text: "Labour",
      sort: true,
      style: { width: "160px" },
      formatter: () => {
        return (
          <div className="select-block custom-normal-select d-flex align-center no-wrap">
            <select>
              <option>Select Labour</option>
              <option>Senior Technician</option>
              <option>Technician</option>
              <option>Labour new</option>
              <option>LCODE</option>
            </select>
          </div>
        );
      },
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
      style: { width: "45%" },
      formatter: (cell, row) => {
        return (
          <div>
            <Form.Group className="form-group">
              <Form.Control type="text" />
            </Form.Group>
          </div>
        );
      },
    },
    {
      dataField: "Retail",
      text: "Retail",
      sort: true,
      style: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <Form.Group className="form-group ">
            <Form.Control type="text" />
          </Form.Group>
        );
      },
    },
    {
      dataField: "Qty Hrs.",
      text: "Qty Hrs.",
      sort: true,
      style: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <div className="date-time-wrapper">
            <Form.Group className="form-group width-33 time-input">
              <Form.Control type="time" />
            </Form.Group>
          </div>
        );
      },
    },
    {
      dataField: "Total",
      text: "Total",
      sort: true,
      style: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <Form.Group className="form-group ">
            <Form.Control type="text" className="small-input" readOnly />
          </Form.Group>
        );
      },
    },
  ];
  const checkBox = {
    mode: "checkbox",
  };
  return (
    <>
      <div className="right-section-wrapper">
        <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button>
        <Button variant="primary" onClick={handleRescheduleShow}>
          Launch Reschedule modal
        </Button>
        <Button variant="primary" onClick={handleMonthViewJob}>
          Launch month view modal
        </Button>
        <Button variant="primary" onClick={handlePurchaseOrderShow}>
          Launch Purchase order dialog
        </Button>
        <Button variant="primary" onClick={handleInspectionShow}>
          Inspection approved
        </Button>
        <Button variant="primary" onClick={handleLeadReceivedShow}>
          Inspection approved
        </Button>

        <Modal
          className="create-job"
          size="lg"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title className="d-flex justify-space-between">
              <div>
                <span className="name">Workshop</span>
                <span> | </span>
                <span className="time">25/03/2022 08:30 am</span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="rounded-tabs">
              <Tabs defaultActiveKey="Jobs">
                <Tab eventKey="Jobs" title="Jobs">
                  {/* step 1 for jobs */}
                  {!activeStep ? (
                    <div className="tab-wrapper">
                      <div className="search-wrapper">
                        <span className="title">Vehicle &amp; Customer</span>
                        <Form.Group className="form-group mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Search for an Asset,Customer or Mobile "
                          />
                          <Button
                            className="btn-primary rounded-btn"
                            onClick={handleCustomerShow}
                          >
                            <span>+</span> Customers &amp; Vehicles
                          </Button>
                        </Form.Group>
                      </div>
                      <div className="panel-wrapper">
                        <div className="panel">
                          <div className="number-plate">
                            <span>985630</span>
                          </div>
                          <div className="panel-right">
                            <div>
                              <div className="top-block">
                                <i className="fa fa-user"></i>
                                <span>Madhvi Y</span>
                                <span>9724310808</span>
                                <span>newcust@mailinator.com</span>
                              </div>
                              <div className="bottom-block">
                                <i className="fa fa-user"></i>
                                <span>AUDI</span>
                                <span>A1</span>
                              </div>
                            </div>
                            <Button className="btn-black rounded-btn">
                              <i className="fa fa-eye"></i>
                              <span>View</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="select-wrapper d-flex justify-space-between">
                        <div className="select-block custom-normal-select d-flex align-center no-wrap">
                          <label>Inspection RO</label>
                          <select>
                            <option>Select</option>
                            <option>Select</option>
                          </select>
                        </div>
                        <div className="select-block custom-normal-select d-flex align-center no-wrap">
                          <label>Booking Source</label>
                          <select>
                            <option value="Existing">Existing</option>
                            <option value="Walk In">Walk In</option>
                            <option value="Referral">Referral</option>
                            <option value="Social Media">Social Media</option>
                            <option value="Customer App">Customer App</option>
                            <option value="Signage">Signage</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div className="select-block custom-normal-select d-flex align-center no-wrap">
                          <label>C/O | 3rd Party</label>
                          <select>
                            <option value="">Select</option>
                            <option value="2">Cover-More</option>
                            <option value="9">AJ Insurance</option>
                            <option value="11">demo</option>
                            <option value="13">Test M</option>
                          </select>
                        </div>
                        <div className="select-block custom-normal-select d-flex align-center no-wrap">
                          <label>Request | Claim</label>
                          <Form.Group className="form-group">
                            <Form.Control type="text" />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="tab-wrapper jobs-description">
                      <div className="form-outer">
                        <div className="form-group custom-normal-select d-flex align-center mb-3">
                          <label>Country *</label>
                          <Form.Select aria-label="Default select example">
                            <option>Job Department</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <div className="form-group custom-normal-select d-flex align-center mb-3">
                          <label>Job Type</label>
                          <Form.Select aria-label="Default select example">
                            <option>Job Department</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <div className="select-block select-wrapper d-flex justify-space-between">
                          <Form.Group className="form-group mb-3">
                            <label>Job Description</label>
                            <Select
                              className="form-control custom-select"
                              required
                              name="country"
                              id="country"
                              options={jobOptions}
                            />
                          </Form.Group>
                        </div>
                        <Form.Group className="form-group mb-3">
                          <label>Description</label>
                          <Editor
                            name="script_description"
                            id="script_description"
                            editorState={editorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            onEditorStateChange={onEditorStateChange}
                            spellCheck
                          />
                        </Form.Group>
                        <div className="date-time-wrapper d-flex justify-space-between  mb-3">
                          <Form.Group className="form-group width-33 time-input">
                            <label>Job Estimate Time</label>
                            <Form.Control type="time" defaultValue="08:56" />
                            <span>Hrs</span>
                          </Form.Group>
                          <Form.Group className="form-group date-picker width-33">
                            <label>Select Pickup Date</label>
                            {/* <DatePicker
                                    selected={bookedInDate}
                                    onChange={(date) => setBookedInDate(date)}
                                    showMonthYearPicker
                                  /> */}
                            <DatePicker className="form-control" />{" "}
                            <button
                              type="button"
                              className="btn btn-primary date-set btn-icon"
                            >
                              <i className="fa fa-calendar"></i>
                            </button>
                          </Form.Group>
                          <Form.Group className="form-group time-picker width-33">
                            <label>Select Pickup Date</label>
                            <TimePicker
                              className="form-control"
                              use12Hours
                              format="h:mm a"
                            />
                          </Form.Group>
                        </div>
                        <div className="select-options d-flex justify-space-between">
                          <div className="multi-select-wrapper">
                            <span>Job Tags</span>
                            <Select
                              defaultValue={[options[2], options[3]]}
                              isMulti
                              name="colors"
                              options={options}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </div>
                          <div className="radio-wrapper">
                            <label>Customer Options</label>
                            <Form.Check
                              className="custom-check radio-btn secondary-radio mb-2"
                              type="radio"
                              id="1"
                            >
                              <Form.Check.Input
                                name="size"
                                type="radio"
                                isValid
                                value="0-5"
                              />
                              <Form.Check.Label>
                                Waiting Instore
                                <span className="check"></span>
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check
                              className="custom-check radio-btn secondary-radio mb-2"
                              type="radio"
                              id="2"
                            >
                              <Form.Check.Input
                                name="size"
                                type="radio"
                                isValid
                                value="0-5"
                              />
                              <Form.Check.Label>
                                Loan Vehicle
                                <span className="check"></span>
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check
                              className="custom-check radio-btn secondary-radio mb-2"
                              type="radio"
                              id="3"
                            >
                              <Form.Check.Input
                                name="size"
                                type="radio"
                                isValid
                                value="0-5"
                              />
                              <Form.Check.Label>
                                Pickup / Drop Off
                                <span className="check"></span>
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check
                              className="custom-check radio-btn secondary-radio mb-2"
                              type="radio"
                              id="4"
                            >
                              <Form.Check.Input
                                name="size"
                                type="radio"
                                isValid
                                value="0-5"
                              />
                              <Form.Check.Label>
                                Ride Sharing
                                <span className="check"></span>
                              </Form.Check.Label>
                            </Form.Check>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="quotes" title="Quotes">
                  <div className="tab-wrapper">
                    <div className="search-wrapper full-width">
                      <div className="input-wrapper">
                        <i className="fa fa-search search-icon"></i>
                        <Form.Group className="form-group mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Convert your Quote to a Job and Schedule it in one simple step!"
                          />
                        </Form.Group>
                      </div>
                      <ul className="search-list">
                        <Scrollbars
                          autoHeight
                          autoHeightMax={250}
                          className="custom-scrollbar"
                          style={{ width: "100%" }}
                          renderThumbVertical={(props) => (
                            <div
                              {...props}
                              className="thumb-vertical black-thumb"
                            />
                          )}
                          renderTrackHorizontal={(props) => (
                            <div {...props} style={{ display: "none" }} />
                          )}
                        >
                          <li className="active">
                            <Link to="">
                              <strong>Quote </strong>
                              276
                              <span> | </span>
                              <strong>Rego </strong>
                              276
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <strong>Quote </strong>
                              276
                              <span> | </span>
                              <strong>Rego </strong>
                              276
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <strong>Quote </strong>
                              276
                              <span> | </span>
                              <strong>Rego </strong>
                              276
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <strong>Quote </strong>
                              276
                              <span> | </span>
                              <strong>Rego </strong>
                              276
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <strong>Quote </strong>
                              276
                              <span> | </span>
                              <strong>Rego </strong>
                              276
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <strong>Quote </strong>
                              276
                              <span> | </span>
                              <strong>Rego </strong>
                              276
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <strong>Quote </strong>
                              276
                              <span> | </span>
                              <strong>Rego </strong>
                              276
                            </Link>
                          </li>
                        </Scrollbars>
                      </ul>
                    </div>
                    <div className="quote-detail-wrapper d-flex justify-space-between">
                      <div className="quote-detail">
                        <h3>Quote Details</h3>
                        <div className="quote-block-outer d-flex justify-space-between">
                          <div className="quote-block width-33">AUDI</div>
                          <div className="quote-block width-33">AUDI</div>
                          <div className="quote-block width-33">AUDI</div>
                        </div>
                        <div className="quote-block-outer d-flex justify-space-between">
                          <div className="quote-block width-50">AUDI</div>
                          <div className="quote-block width-50">AUDI</div>
                        </div>
                      </div>
                      <div className="quote-detail">
                        <div className="quote-block-outer d-flex justify-space-between">
                          <div className="quote-block description-block d-flex justify-center align-center">
                            Description
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="activities" title="Activities">
                  <div className="tab-wrapper">
                    <div className="activity-wrapper d-flex justify-space-between">
                      <ul className="activity-list d-flex">
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="1"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              isValid
                              value="0-5"
                            />
                            <Form.Check.Label>
                              Meeting
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="2"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Holiday
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="3"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Job
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="4"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              test
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="5"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Activity
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="6"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              123
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="7"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Activity
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="8"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Activity
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="9"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Job
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="10"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              test
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="11"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Activity
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="12"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              123
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="13"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Activity
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                      </ul>
                      <div className="add-activity">
                        <Button className="btn-primary rounded-btn activity-btn">
                          <span>+</span> Activity{" "}
                        </Button>
                      </div>
                    </div>
                    <div className="add-activity d-flex">
                      <Form.Group className="form-group mb-3">
                        <Form.Control type="text" placeholder="Activity Name" />
                        <div className="btn-wrapper">
                          <Button className="btn-default">Cancel</Button>
                          <Button className="btn-success">Save</Button>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="search-wrapper full-width">
                      <div className="input-wrapper">
                        <i className="fa fa-search search-icon"></i>
                        <Form.Group className="form-group mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Search for an Activity"
                          />
                        </Form.Group>
                      </div>
                      <ul className="search-list with-close-icon">
                        <Scrollbars
                          autoHeight
                          autoHeightMax={250}
                          className="custom-scrollbar"
                          style={{ width: "100%" }}
                          renderThumbVertical={(props) => (
                            <div
                              {...props}
                              className="thumb-vertical black-thumb"
                            />
                          )}
                          renderTrackHorizontal={(props) => (
                            <div {...props} style={{ display: "none" }} />
                          )}
                        >
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="d-flex justify-space-between"
                            >
                              <span>Mark</span>
                              <i className="font-size-16 fa fa-close"></i>
                            </Link>
                          </li>
                        </Scrollbars>
                      </ul>
                      <div className="multi-select-wrapper">
                        <span>Employees *</span>
                        <Select
                          defaultValue={[options[2], options[3]]}
                          isMulti
                          name="colors"
                          options={options}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                      <div className="description">
                        <span>
                          Description - This activity description will display
                          in the schedule
                        </span>
                        <Form.Control as="textarea" rows={3} />
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              className="btn-outline rounded-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            {activeStep ? (
              <Button
                variant="outline-secondary"
                className="btn-outline rounded-btn"
                onClick={ChangeActiveStep}
              >
                <i className="fa fa-arrow-left m-r-5"></i> Back
              </Button>
            ) : (
              <Button
                className="rounded-btn"
                variant="primary"
                onClick={ChangeActiveStep}
              >
                Next
              </Button>
            )}
            <Button
              className="rounded-btn btn-success"
              variant="primary"
              onClick={handleClose}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        {/* add customer and vehicle dialog   */}
        <Modal
          className="add-customer-vehicle"
          size="md"
          show={customerShow}
          onHide={handleCustomerClose}
          centered
        >
          <Modal.Body>
            <div className="custom-tabs">
              <Tabs defaultActiveKey="customers">
                <Tab eventKey="customers" title="Customers">
                  <Scrollbars
                    autoHeight
                    autoHeightMax={300}
                    className="custom-scrollbar"
                    style={{ width: "100%" }}
                    renderThumbVertical={(props) => (
                      <div {...props} className="thumb-vertical grey-thumb" />
                    )}
                    renderTrackHorizontal={(props) => (
                      <div {...props} style={{ display: "none" }} />
                    )}
                  >
                    <div className="content-wrapper">
                      <div className="radio-wrapper">
                        <Form.Check
                          className="custom-check radio-btn secondary-radio mb-2"
                          type="radio"
                          id="1"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            isValid
                            value="0-5"
                          />
                          <Form.Check.Label>
                            Individual
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                        <Form.Check
                          className="custom-check radio-btn secondary-radio mb-2"
                          type="radio"
                          id="2"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            isValid
                            value="0-5"
                          />
                          <Form.Check.Label>
                            Company
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                      {/* for individual radio tab */}
                      {/* <div className="form-outer">
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>First Name *</label>
                                                    <Form.Control type="text" />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Last Name *</label>
                                                    <Form.Control type="text" />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Main Phone</label>
                                                    <Form.Control type="text" />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Email Address</label>
                                                    <Form.Control type="text" />
                                                </Form.Group>
                                                <div className="form-group custom-normal-select d-flex align-center width-50 mb-3">
                                                    <label>Country *</label>
                                                    <Form.Select aria-label="Default select example">
                                                        <option>Open this select menu</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </Form.Select>
                                                </div>
                                                  <div className="mobile-wrapper form-group width-50 mb-3">
                                                    <Form.Group className="mobile-code-input form-group">
                                                        <label>Code</label>
                                                        <Form.Control value="+91" type="text" />
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <label>Mobile No. *</label>
                                                        <Form.Control type="text" />
                                                    </Form.Group>
                                                </div>
                                                <Form.Group className="form-group mb-3">
                                                    <label>Street Address</label>
                                                    <Form.Control as="textarea" rows={8} />
                                                </Form.Group> <Form.Group className="form-group width-50 mb-3">
                                                    <label>Suburb</label>
                                                    <Form.Control type="text" />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Post code</label>
                                                    <Form.Control type="text" />
                                                </Form.Group>
                                                <div className="form-group custom-normal-select d-flex align-center">
                                                    <label>State</label>
                                                    <Form.Select aria-label="Default select example">
                                                        <option>Open this select menu</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </Form.Select>
                                                </div>
                                            </div> */}
                      {/* for company block */}
                      <div className="form-outer">
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Company Name *</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>ABN</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Main Phone</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Email Address</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Website</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Position</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>First Name</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Last Name</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <div className="form-group custom-normal-select d-flex align-center width-50 mb-3">
                          <label>Country *</label>
                          <Form.Select aria-label="Default select example">
                            <option>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <div className="mobile-wrapper form-group width-50 mb-3">
                          <Form.Group className="mobile-code-input form-group">
                            <label>Code</label>
                            <Form.Control value="+91" type="text" />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <label>Mobile No. *</label>
                            <Form.Control type="text" />
                          </Form.Group>
                        </div>
                        <Form.Group className="form-group mb-3">
                          <label>Street Address</label>
                          <Form.Control as="textarea" rows={8} />
                        </Form.Group>{" "}
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Suburb</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Post code</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <div className="form-group custom-normal-select d-flex align-center">
                          <label>State</label>
                          <Form.Select aria-label="Default select example">
                            <option>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </Scrollbars>
                </Tab>
                <Tab eventKey="vehicles" title="Vehicles">
                  <Scrollbars
                    autoHeight
                    autoHeightMax={300}
                    className="custom-scrollbar"
                    style={{ width: "100%" }}
                    renderThumbVertical={(props) => (
                      <div {...props} className="thumb-vertical grey-thumb" />
                    )}
                    renderTrackHorizontal={(props) => (
                      <div {...props} style={{ display: "none" }} />
                    )}
                  >
                    <div className="content-wrapper">
                      <div className="form-outer">
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Asset Type *</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Rego *</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group mb-3">
                          <label>Customer Name *</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <div className="form-group custom-normal-select d-flex align-center width-33 mb-3">
                          <label>Make</label>
                          <Form.Select aria-label="Default select example">
                            <option>Select Make</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <Form.Group className="form-group width-33 mb-3">
                          <label>Model</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-33 mb-3">
                          <label>Series</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <div className="form-group custom-normal-select d-flex align-center width-33 mb-3">
                          <label>Fuel Type</label>
                          <Form.Select aria-label="Default select example">
                            <option>Select Make</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <div className="form-group custom-normal-select d-flex align-center width-33 mb-3">
                          <label>Body Type</label>
                          <Form.Select aria-label="Default select example">
                            <option>Select Make</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <div className="form-group custom-normal-select d-flex align-center width-33 mb-3">
                          <label>Year</label>
                          <Form.Select aria-label="Default select example">
                            <option>Select Make</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <div className="form-group custom-normal-select d-flex align-center width-33 mb-3">
                          <label>Transmission</label>
                          <Form.Select aria-label="Default select example">
                            <option>Select Make</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <div className="form-group custom-normal-select d-flex align-center width-33 mb-3">
                          <label>Drivetrain</label>
                          <Form.Select aria-label="Default select example">
                            <option>Select Make</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <Form.Group className="form-group width-33 mb-3">
                          <label>Colour</label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </div>
                      <div
                        className={`form-outer hidden-form ${
                          fieldShow ? "active" : ""
                        }`}
                      >
                        <div className="form-group custom-normal-select d-flex align-center width-33 mb-3">
                          <label>Seat Capacity</label>
                          <Form.Select aria-label="Default select example">
                            <option>Select Make</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        <Form.Group className="form-group width-33 mb-3">
                          <label>Tyre Size - Front</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-33 mb-3">
                          <label>Tyre Size - Rear</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>
                            VIN Number
                            <div
                              className={`lookup-dropdown ${
                                lookUpShow ? "active" : ""
                              }`}
                            >
                              <Button
                                className="rounded-btn"
                                variant="primary"
                                onClick={handleLookupShow}
                              >
                                <i className="fa fa-chevron-circle-down m-r-5 font-size-14"></i>
                                Lookup
                              </Button>
                              <div className="dropdown-list">
                                <ul>
                                  <Scrollbars
                                    autoHeight
                                    autoHeightMax={150}
                                    className="custom-scrollbar"
                                    style={{ width: "100%" }}
                                    renderThumbVertical={(props) => (
                                      <div
                                        {...props}
                                        className="thumb-vertical grey-thumb"
                                      />
                                    )}
                                    renderTrackHorizontal={(props) => (
                                      <div
                                        {...props}
                                        style={{ display: "none" }}
                                      />
                                    )}
                                  >
                                    <li>
                                      <Link to="/">ACT</Link>
                                    </li>
                                    <li>
                                      <Link to="/">NSW</Link>
                                    </li>
                                    <li>
                                      <Link to="/">NT</Link>
                                    </li>
                                    <li>
                                      <Link to="/">QLD</Link>
                                    </li>
                                    <li>
                                      <Link to="/">SA</Link>
                                    </li>
                                    <li>
                                      <Link to="/">TAS</Link>
                                    </li>
                                    <li>
                                      <Link to="/">WA</Link>
                                    </li>
                                    <li>
                                      <Link to="/">Safety Recalls</Link>
                                    </li>
                                    <li>
                                      <Link to="/">NZ</Link>
                                    </li>
                                    <li>
                                      <Link to="/">UK</Link>
                                    </li>
                                  </Scrollbars>
                                </ul>
                              </div>
                            </div>
                          </label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Chassis Number</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Engine Number</label>
                          <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="form-group width-50 mb-3">
                          <label>Fleet Number</label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </div>
                      <div className="link-wrapper d-flex justify-center">
                        <Button
                          type="button"
                          variant="outline-primary"
                          className="expand-link"
                          onClick={expandFields}
                        >
                          {fieldShow ? "See Less" : "See More"}
                        </Button>
                      </div>
                    </div>
                  </Scrollbars>
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              className="btn-outline rounded-btn"
              onClick={handleCustomerClose}
            >
              Close
            </Button>
            <Button
              className="rounded-btn btn-success"
              variant="primary"
              onClick={handleCustomerClose}
            >
              Save
            </Button>
            <Button
              className="rounded-btn btn-success"
              variant="primary"
              onClick={handleCustomerClose}
            >
              Save &amp; Next
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Reschedule modal  */}
        <Modal
          className="reschedule-job-modal"
          size="lg"
          show={rescheduleShow}
          onHide={handleRescheduleClose}
          centered
        >
          <Modal.Header>
            <Modal.Title className="d-flex justify-space-between">
              <div>
                <span className="name">Michelle Burch</span>
                <span> | </span>
                <span className="time">21/04/2022 04:37 pm</span>
              </div>
              <div>JOB # 2707</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="content-block">
              <div className="date-block-outer mb-3">
                <Form.Group className="form-group date-picker d-flex align-center no-wrap">
                  <label>Select Date</label>
                  {/* <DatePicker
                              selected={bookedInDate}
                              onChange={(date) => setBookedInDate(date)}
                              showMonthYearPicker
                            /> */}
                  <DatePicker className="form-control" />{" "}
                  <button
                    type="button"
                    className="btn btn-primary date-set btn-icon"
                  >
                    <i className="fa fa-calendar"></i>
                  </button>
                </Form.Group>
              </div>
              <div className="schedular-blocks d-flex mb-4">
                <ul className="scheduler-person-list activity-list">
                  <Scrollbars
                    autoHeight
                    autoHeightMax={160}
                    className="custom-scrollbar"
                    style={{ width: "100%" }}
                    renderThumbVertical={(props) => (
                      <div className="thumb-vertical grey-thumb" />
                    )}
                    renderTrackHorizontal={(props) => (
                      <div style={{ display: "none" }} />
                    )}
                  >
                    <li>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="1"
                      >
                        <Form.Check.Input
                          name="size"
                          type="radio"
                          isValid
                          value="0-5"
                        />
                        <Form.Check.Label>
                          Michelle Burch
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </li>
                    <li>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="2"
                      >
                        <Form.Check.Input
                          name="size"
                          type="radio"
                          value="5-10"
                          isValid
                        />
                        <Form.Check.Label>
                          Ricky Lee
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </li>
                    <li>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="3"
                      >
                        <Form.Check.Input
                          name="size"
                          type="radio"
                          value="5-10"
                          isValid
                        />
                        <Form.Check.Label>
                          mar one
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </li>
                    <li>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="4"
                      >
                        <Form.Check.Input
                          name="size"
                          type="radio"
                          value="5-10"
                          isValid
                        />
                        <Form.Check.Label>
                          Michelle Burch
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </li>
                    <li>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="5"
                      >
                        <Form.Check.Input
                          name="size"
                          type="radio"
                          value="5-10"
                          isValid
                        />
                        <Form.Check.Label>
                          mar one
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </li>
                    <li>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="6"
                      >
                        <Form.Check.Input
                          name="size"
                          type="radio"
                          value="5-10"
                          isValid
                        />
                        <Form.Check.Label>
                          Darrel Skinner
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </li>
                  </Scrollbars>
                </ul>
                <ul className="schedular-list d-flex">
                  <li>
                    <Form.Check
                      className="radio-btn mb-2"
                      type="radio"
                      id="time-1"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        isValid
                        value="0-5"
                      />
                      <Form.Check.Label>
                        07:30 AM - 09:00 AM
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </li>
                  <li>
                    <Form.Check
                      className="radio-btn mb-2"
                      type="radio"
                      id="time-2"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        value="5-10"
                        isValid
                      />
                      <Form.Check.Label>
                        09:00 AM - 10:30 AM
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </li>
                  <li>
                    <Form.Check
                      className="radio-btn mb-2"
                      type="radio"
                      id="time-3"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        value="5-10"
                        isValid
                      />
                      <Form.Check.Label>
                        10:30 AM - 12:00 PM
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </li>
                  <li>
                    <Form.Check
                      className="radio-btn mb-2"
                      type="radio"
                      id="time-4"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        value="5-10"
                        isValid
                      />
                      <Form.Check.Label>
                        12:00 PM - 01:30 PM
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </li>
                  <li>
                    <Form.Check
                      className="radio-btn mb-2"
                      type="radio"
                      id="time-5"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        value="5-10"
                        isValid
                      />
                      <Form.Check.Label>
                        01:30 PM - 03:00 PM
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </li>
                  <li>
                    <Form.Check
                      className="radio-btn mb-2"
                      type="radio"
                      id="time-6"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        value="5-10"
                        isValid
                      />
                      <Form.Check.Label>
                        03:00 PM - 04:30 PM
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </li>
                  <li>
                    <Form.Check
                      className="radio-btn mb-2"
                      type="radio"
                      id="time-7"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        value="5-10"
                        isValid
                      />
                      <Form.Check.Label>
                        04:30 PM - 06:00 PM
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </li>
                </ul>
              </div>
              <Accordion defaultActiveKey="0" className="small-accordion">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>Pickup Time</span>
                    <i className="la la-angle-down"></i>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="form-outer">
                      <div className="date-time-wrapper d-flex justify-space-between  mb-3">
                        <Form.Group className="form-group width-33 time-input">
                          <label>Job Estimate Time</label>
                          <Form.Control type="time" defaultValue="08:56" />
                          <span>Hrs</span>
                        </Form.Group>
                        <Form.Group className="form-group date-picker width-33">
                          <label>Select Pickup Date</label>
                          <DatePicker className="form-control" />{" "}
                          <button
                            type="button"
                            className="btn btn-primary date-set btn-icon"
                          >
                            <i className="fa fa-calendar"></i>
                          </button>
                        </Form.Group>
                        <Form.Group className="form-group width-33 time-input">
                          <label>Select Pickup Time</label>
                          <Form.Control type="time" defaultValue="08:56" />
                        </Form.Group>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <span>Customer</span>
                    <i className="la la-angle-down"></i>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="customer-assets-block d-flex">
                      <div className="left-block block-wrapper">
                        <div className="heading-block">Asset Details</div>
                        <div className="d-flex detail-outer">
                          <div className="detail-wrapper width-50">
                            <div className="d-flex detail-block">
                              <label>
                                <i class="fa fa-car text-primary m-r-5 font-size-16 w-20"></i>
                                <b>Rego</b>
                              </label>
                              <span>ytr6757</span>
                            </div>
                            <div className="d-flex detail-block">
                              <label>
                                <i class="fa fa-calendar m-r-5 text-primary font-size-16 w-20"></i>
                                <b>Year</b>
                              </label>
                              <span>2000</span>
                            </div>
                          </div>
                          <div className="detail-wrapper width-50">
                            <div className="d-flex detail-block">
                              <label>
                                <b>Make</b>
                              </label>
                              <span>tEst</span>
                            </div>
                            <div className="d-flex detail-block">
                              <label>
                                <i class="fa fa-car text-primary m-r-5 font-size-16 w-20"></i>
                                <b>Model</b>
                              </label>
                              <span>2000</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="right-block block-wrapper">
                        <div className="heading-block">Customer Details</div>
                        <div className="d-flex detail-outer">
                          <div className="detail-wrapper">
                            <div className="d-flex detail-block">
                              <label>
                                <i class="fa fa-car text-primary m-r-5 font-size-16 w-20"></i>
                                <b>Customer</b>
                              </label>
                              <span>Madhavi Yadav</span>
                            </div>
                            <div className="d-flex detail-block">
                              <div className="d-flex">
                                <label>
                                  <i class="m-r-5 font-size-16 w-20 fa fa-2x text-primary fa-phone"></i>
                                  <b>Contact</b>
                                </label>
                                <span>849562310</span>
                              </div>
                              <div className="d-flex">
                                <label>
                                  <i class="m-r-5 font-size-16 w-20 fa fa-2x text-primary fa-envelope"></i>
                                  <b>Email</b>
                                </label>
                                <span>mnewww@gmail.com</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              className="btn-outline rounded-btn"
              onClick={handleRescheduleClose}
            >
              Cancel
            </Button>
            <Button
              className="rounded-btn btn-success"
              variant="primary"
              onClick={handleRescheduleClose}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* month view dialog */}
      <Modal
        className="reschedule-job-modal create-job monthView-create-job"
        size="lg"
        show={MonthViewShow}
        onHide={handleMonthViewClose}
        centered
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-space-between">
            <div>
              <span className="name">Michelle Burch</span>
              <span> | </span>
              <span className="time">21/04/2022 04:37 pm</span>
            </div>
            <div>JOB # 2707</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="rounded-tabs">
            <Tabs defaultActiveKey="Jobs">
              <Tab eventKey="Jobs" title="Jobs">
                {/* step 1 for jobs */}
                <div className="tab-wrapper">
                  <div className="radio-wrapper mb-3">
                    <label className="m-r-10">
                      <b>Assign To:</b>
                    </label>
                    <Form.Check
                      className="custom-check radio-btn secondary-radio mb-2"
                      type="radio"
                      id="1"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        isValid
                        value="0-5"
                      />
                      <Form.Check.Label>
                        Bay
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check
                      className="custom-check radio-btn secondary-radio mb-2"
                      type="radio"
                      id="2"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        isValid
                        value="0-5"
                      />
                      <Form.Check.Label>
                        Technician
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div className="schedular-blocks d-flex mb-4">
                    <ul className="scheduler-person-list activity-list">
                      <Scrollbars
                        autoHeight
                        autoHeightMax={160}
                        className="custom-scrollbar"
                        style={{ width: "100%" }}
                        renderThumbVertical={(props) => (
                          <div className="thumb-vertical grey-thumb" />
                        )}
                        renderTrackHorizontal={(props) => (
                          <div style={{ display: "none" }} />
                        )}
                      >
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="1"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              isValid
                              value="0-5"
                            />
                            <Form.Check.Label>
                              Michelle Burch
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="2"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Ricky Lee
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="3"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              mar one
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="4"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Michelle Burch
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="5"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              mar one
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="6"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Darrel Skinner
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                      </Scrollbars>
                    </ul>
                    <ul className="schedular-list d-flex">
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-1"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            isValid
                            value="0-5"
                          />
                          <Form.Check.Label>
                            07:30 AM - 09:00 AM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-2"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            09:00 AM - 10:30 AM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-3"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            10:30 AM - 12:00 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-4"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            12:00 PM - 01:30 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-5"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            01:30 PM - 03:00 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-6"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            03:00 PM - 04:30 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-7"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            04:30 PM - 06:00 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                    </ul>
                  </div>
                  <div className="border-block"></div>
                  <div className="search-wrapper">
                    <span className="title">Vehicle &amp; Customer</span>
                    <Form.Group className="form-group mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Search for an Asset,Customer or Mobile "
                      />
                      <Button
                        className="btn-primary rounded-btn"
                        onClick={handleCustomerShow}
                      >
                        <span>+</span> Customers &amp; Vehicles
                      </Button>
                    </Form.Group>
                  </div>
                  <div className="panel-wrapper">
                    <div className="panel">
                      <div className="number-plate">
                        <span>985630</span>
                      </div>
                      <div className="panel-right">
                        <div>
                          <div className="top-block">
                            <i className="fa fa-user"></i>
                            <span>Madhvi Y</span>
                            <span>9724310808</span>
                            <span>newcust@mailinator.com</span>
                          </div>
                          <div className="bottom-block">
                            <i className="fa fa-user"></i>
                            <span>AUDI</span>
                            <span>A1</span>
                          </div>
                        </div>
                        <Button className="btn-black rounded-btn">
                          <i className="fa fa-eye"></i>
                          <span>View</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="select-wrapper d-flex justify-space-between">
                    <div className="select-block custom-normal-select d-flex align-center no-wrap">
                      <label>Inspection RO</label>
                      <select>
                        <option>Select</option>
                        <option>Select</option>
                      </select>
                    </div>
                    <div className="select-block custom-normal-select d-flex align-center no-wrap">
                      <label>Booking Source</label>
                      <select>
                        <option value="Existing">Existing</option>
                        <option value="Walk In">Walk In</option>
                        <option value="Referral">Referral</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Customer App">Customer App</option>
                        <option value="Signage">Signage</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="select-block custom-normal-select d-flex align-center no-wrap">
                      <label>C/O | 3rd Party</label>
                      <select>
                        <option value="">Select</option>
                        <option value="2">Cover-More</option>
                        <option value="9">AJ Insurance</option>
                        <option value="11">demo</option>
                        <option value="13">Test M</option>
                      </select>
                    </div>
                    <div className="select-block custom-normal-select d-flex align-center no-wrap">
                      <label>Request | Claim</label>
                      <Form.Group className="form-group">
                        <Form.Control type="text" />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="quotes" title="Quotes">
                <div className="tab-wrapper">
                  <div className="radio-wrapper mb-3">
                    <label className="m-r-10">
                      <b>Assign To:</b>
                    </label>
                    <Form.Check
                      className="custom-check radio-btn secondary-radio mb-2"
                      type="radio"
                      id="quotes1"
                    >
                      <Form.Check.Input
                        name="quotes"
                        type="radio"
                        isValid
                        value="0-5"
                      />
                      <Form.Check.Label>
                        Bay
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check
                      className="custom-check radio-btn secondary-radio mb-2"
                      type="radio"
                      id="quotes2"
                    >
                      <Form.Check.Input
                        name="quotes"
                        type="radio"
                        isValid
                        value="0-5"
                      />
                      <Form.Check.Label>
                        Technician
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div className="schedular-blocks d-flex mb-4">
                    <ul className="scheduler-person-list activity-list">
                      <Scrollbars
                        autoHeight
                        autoHeightMax={160}
                        className="custom-scrollbar"
                        style={{ width: "100%" }}
                        renderThumbVertical={(props) => (
                          <div className="thumb-vertical grey-thumb" />
                        )}
                        renderTrackHorizontal={(props) => (
                          <div style={{ display: "none" }} />
                        )}
                      >
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="list1"
                          >
                            <Form.Check.Input
                              name="nameListQuotes"
                              type="radio"
                              isValid
                              value="0-5"
                            />
                            <Form.Check.Label>
                              Michelle Burch
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="list2"
                          >
                            <Form.Check.Input
                              name="nameListQuotes"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Ricky Lee
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="list3"
                          >
                            <Form.Check.Input
                              name="nameListQuotes"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              mar one
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="list4"
                          >
                            <Form.Check.Input
                              name="nameListQuotes"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Michelle Burch
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="list5"
                          >
                            <Form.Check.Input
                              name="nameListQuotes"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              mar one
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="list6"
                          >
                            <Form.Check.Input
                              name="nameListQuotes"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Darrel Skinner
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                      </Scrollbars>
                    </ul>
                    <ul className="schedular-list d-flex">
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="Quotetime-1"
                        >
                          <Form.Check.Input
                            name="QuoteTime"
                            type="radio"
                            isValid
                            value="0-5"
                          />
                          <Form.Check.Label>
                            07:30 AM - 09:00 AM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="Quotetime-2"
                        >
                          <Form.Check.Input
                            name="QuoteTime"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            09:00 AM - 10:30 AM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="Quotetime-3"
                        >
                          <Form.Check.Input
                            name="QuoteTime"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            10:30 AM - 12:00 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="Quotetime-4"
                        >
                          <Form.Check.Input
                            name="QuoteTime"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            12:00 PM - 01:30 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="Quotetime-5"
                        >
                          <Form.Check.Input
                            name="QuoteTime"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            01:30 PM - 03:00 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="Quotetime-6"
                        >
                          <Form.Check.Input
                            name="QuoteTime"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            03:00 PM - 04:30 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-7"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            04:30 PM - 06:00 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                    </ul>
                  </div>
                  <div className="border-block"></div>
                  <div className="search-wrapper full-width">
                    <div className="input-wrapper">
                      <i className="fa fa-search search-icon"></i>
                      <Form.Group className="form-group mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Convert your Quote to a Job and Schedule it in one simple step!"
                        />
                      </Form.Group>
                    </div>
                    <ul className="search-list">
                      <Scrollbars
                        autoHeight
                        autoHeightMax={250}
                        className="custom-scrollbar"
                        style={{ width: "100%" }}
                        renderThumbVertical={(props) => (
                          <div
                            {...props}
                            className="thumb-vertical black-thumb"
                          />
                        )}
                        renderTrackHorizontal={(props) => (
                          <div {...props} style={{ display: "none" }} />
                        )}
                      >
                        <li className="active">
                          <Link to="">
                            <strong>Quote </strong>
                            276
                            <span> | </span>
                            <strong>Rego </strong>
                            276
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <strong>Quote </strong>
                            276
                            <span> | </span>
                            <strong>Rego </strong>
                            276
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <strong>Quote </strong>
                            276
                            <span> | </span>
                            <strong>Rego </strong>
                            276
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <strong>Quote </strong>
                            276
                            <span> | </span>
                            <strong>Rego </strong>
                            276
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <strong>Quote </strong>
                            276
                            <span> | </span>
                            <strong>Rego </strong>
                            276
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <strong>Quote </strong>
                            276
                            <span> | </span>
                            <strong>Rego </strong>
                            276
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <strong>Quote </strong>
                            276
                            <span> | </span>
                            <strong>Rego </strong>
                            276
                          </Link>
                        </li>
                      </Scrollbars>
                    </ul>
                  </div>
                  <div className="quote-detail-wrapper d-flex justify-space-between">
                    <div className="quote-detail">
                      <h3>Quote Details</h3>
                      <div className="quote-block-outer d-flex justify-space-between">
                        <div className="quote-block width-33">AUDI</div>
                        <div className="quote-block width-33">AUDI</div>
                        <div className="quote-block width-33">AUDI</div>
                      </div>
                      <div className="quote-block-outer d-flex justify-space-between">
                        <div className="quote-block width-50">AUDI</div>
                        <div className="quote-block width-50">AUDI</div>
                      </div>
                    </div>
                    <div className="quote-detail">
                      <div className="quote-block-outer d-flex justify-space-between">
                        <div className="quote-block description-block d-flex justify-center align-center">
                          Description
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="activities" title="Activities">
                <div className="tab-wrapper">
                  <div className="radio-wrapper mb-3">
                    <label className="m-r-10">
                      <b>Assign To:</b>
                    </label>
                    <Form.Check
                      className="custom-check radio-btn secondary-radio mb-2"
                      type="radio"
                      id="activities1"
                    >
                      <Form.Check.Input
                        name="activities"
                        type="radio"
                        isValid
                        value="0-5"
                      />
                      <Form.Check.Label>
                        Bay
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check
                      className="custom-check radio-btn secondary-radio mb-2"
                      type="radio"
                      id="activities2"
                    >
                      <Form.Check.Input
                        name="activities"
                        type="radio"
                        isValid
                        value="0-5"
                      />
                      <Form.Check.Label>
                        Technician
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div className="schedular-blocks d-flex mb-4">
                    <ul className="scheduler-person-list activity-list">
                      <Scrollbars
                        autoHeight
                        autoHeightMax={160}
                        className="custom-scrollbar"
                        style={{ width: "100%" }}
                        renderThumbVertical={(props) => (
                          <div className="thumb-vertical grey-thumb" />
                        )}
                        renderTrackHorizontal={(props) => (
                          <div style={{ display: "none" }} />
                        )}
                      >
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="1"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              isValid
                              value="0-5"
                              disabled
                            />
                            <Form.Check.Label className="red-disable">
                              Michelle Burch
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="2"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Ricky Lee
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="3"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              mar one
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="4"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Michelle Burch
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="5"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              mar one
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                        <li>
                          <Form.Check
                            className="radio-btn mb-2"
                            type="radio"
                            id="6"
                          >
                            <Form.Check.Input
                              name="size"
                              type="radio"
                              value="5-10"
                              isValid
                            />
                            <Form.Check.Label>
                              Darrel Skinner
                              <span className="check"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </li>
                      </Scrollbars>
                    </ul>
                    <ul className="schedular-list d-flex">
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-1"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            isValid
                            value="0-5"
                            disabled
                          />
                          <Form.Check.Label>
                            07:30 AM - 09:00 AM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-2"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                            disabled
                          />
                          <Form.Check.Label>
                            09:00 AM - 10:30 AM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-3"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            10:30 AM - 12:00 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-4"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            12:00 PM - 01:30 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-5"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            01:30 PM - 03:00 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-6"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            03:00 PM - 04:30 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="time-7"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            04:30 PM - 06:00 PM
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                    </ul>
                  </div>
                  <div className="border-block"></div>
                  <div className="activity-wrapper d-flex justify-space-between">
                    <ul className="activity-list d-flex">
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="1"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            isValid
                            value="0-5"
                          />
                          <Form.Check.Label>
                            Meeting
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="2"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            Holiday
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="3"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            Job
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="4"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            test
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="5"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            Activity
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="6"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            123
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="7"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            Activity
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="8"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            Activity
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="9"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            Job
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="10"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            test
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="11"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            Activity
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="12"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            123
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                      <li>
                        <Form.Check
                          className="radio-btn mb-2"
                          type="radio"
                          id="13"
                        >
                          <Form.Check.Input
                            name="size"
                            type="radio"
                            value="5-10"
                            isValid
                          />
                          <Form.Check.Label>
                            Activity
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                      </li>
                    </ul>
                    <div className="add-activity">
                      <Button className="btn-primary rounded-btn activity-btn">
                        <span>+</span> Activity{" "}
                      </Button>
                    </div>
                  </div>
                  <div className="add-activity d-flex">
                    <Form.Group className="form-group mb-3">
                      <Form.Control type="text" placeholder="Activity Name" />
                      <div className="btn-wrapper">
                        <Button className="btn-default">Cancel</Button>
                        <Button className="btn-success">Save</Button>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="search-wrapper full-width">
                    <div className="input-wrapper">
                      <i className="fa fa-search search-icon"></i>
                      <Form.Group className="form-group mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Search for an Activity"
                        />
                      </Form.Group>
                    </div>
                    <ul className="search-list with-close-icon">
                      <Scrollbars
                        autoHeight
                        autoHeightMax={250}
                        className="custom-scrollbar"
                        style={{ width: "100%" }}
                        renderThumbVertical={(props) => (
                          <div
                            {...props}
                            className="thumb-vertical black-thumb"
                          />
                        )}
                        renderTrackHorizontal={(props) => (
                          <div {...props} style={{ display: "none" }} />
                        )}
                      >
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="d-flex justify-space-between">
                            <span>Mark</span>
                            <i className="font-size-16 fa fa-close"></i>
                          </Link>
                        </li>
                      </Scrollbars>
                    </ul>
                    <div className="multi-select-wrapper">
                      <span>Employees *</span>
                      <Select
                        defaultValue={[options[2], options[3]]}
                        isMulti
                        name="colors"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                    <div className="description">
                      <span>
                        Description - This activity description will display in
                        the schedule
                      </span>
                      <Form.Control as="textarea" rows={3} />
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            className="btn-outline rounded-btn"
            onClick={handleMonthViewClose}
          >
            Cancel
          </Button>
          <Button
            className="rounded-btn btn-success"
            variant="primary"
            onClick={handleMonthViewClose}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* purchase order dialog */}
      <Modal
        className="assign-part"
        size="lg"
        show={purchaseOrderShow}
        onHide={handlePurchaseOrderClose}
        centered
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-space-between align-center">
            <div>Assign Part</div>
            <i onClick={handlePurchaseOrderClose} class="la la-times"></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-block">
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                page: currentPage,
                sizePerPage: sizePerPage,
                totalSize: parseInt(totalRecords),
                nextPageText: "Next",
                prePageText: "Previous",
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    remote
                    keyField="id"
                    data={products}
                    columns={columns}
                    selectRow={checkBox}
                    {...paginationTableProps}
                  />
                  <div>
                    <div className="table-pagination">
                      <div>
                        <span>Show</span>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <span>entries</span>
                      </div>
                      <PaginationTotalStandalone {...paginationProps} />
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-btn btn-primary"
            variant="primary"
            onClick={handlePurchaseOrderClose}
          >
            Save
          </Button>
          <Button
            variant="outline-secondary"
            className="btn-outline rounded-btn"
            onClick={handlePurchaseOrderClose}
          >
            Cancel
          </Button>
          <Button
            className="rounded-btn btn-primary"
            variant="primary"
            onClick={handlePurchaseOrderClose}
          >
            Save &amp; send
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Inspection approved dialog */}
      <Modal
        className="inspection-dialog"
        size="sm"
        show={inspectionShow}
        onHide={handleInspectionClose}
      >
        <Modal.Body>
          <div className="d-flex justify-flex-end">
            <i onClick={handleInspectionClose} class="la la-times font-size-20 close-icon mb-2"></i>
          </div>
          <div className="content-block">
            <div className="heading-block d-flex align-center justify-center mb-2">
              <h4>Inspection Approved</h4>
              <img className="check-icon" src={check}></img>
            </div>
            <p className="mb-2">Rego ~ rt56 ASIA</p>
            <p className="mb-2">Service inspection Checklist # <b>2227</b></p>
            <p className="mb-2">Approved By <b>madhavi testt</b></p>
          </div>
          <div className="d-flex justify-center mt-3">
            <Button
              className="btn-outline rounded-btn height-34"
              variant="outline"
              onClick={handleLeadReceivedClose}
            >
              View
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/*  Lead Received dialog */}
      <Modal
        className="Lead-received-dialog"
        size="sm"
        show={leadReceivedShow}
        onHide={handleLeadReceivedClose}
      >
        <Modal.Body>
          <div className="d-flex justify-flex-end">
            <i onClick={handleLeadReceivedClose} class="la la-times font-size-20 close-icon mb-2"></i>
          </div>
          <div className="content-block d-flex">
              <img className="img-block" src={leadImg}></img>
              <div className='right-block'>
                <h4>1</h4>
                <p className="mb-3">New Lead Received.</p>
                <Button
                className="btn-outline rounded-btn height-34"
                variant="outline"
                onClick={handleLeadReceivedClose}
              >
                View
              </Button>
              </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Calender;
