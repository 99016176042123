import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SupplierList from "./SupplierList";
import HeadQuartersSupplierList from "./HeadQuartersSupplierList";
import { useDispatch, useSelector } from "react-redux";
import "../../style.scss";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Loader from "views/common/UI/Loader";

const SupplierHeader = () => {
  const dispatch = useDispatch();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const loading = false;
  //   const { activeAssets, loading } = useSelector((state) => state.List);
  const [activeTab, setActiveTab] = useState("HeadQuartersSupplierList");
  const location = useLocation();

  useEffect(() => {
    document.title = "Suppliers List | 5iQ";
    // dispatch({
    //   type: GET_ACTIVE_ASSETS,
    //   payload: {
    //     id: workshop_id,
    //   },
    // });
  }, []);

  const [supplierType, setSupplierType] = useState("HeadQuartersSupplierList");

  return (
    <>
      {loading && <Loader />}
      <div className="right-section-wrapper">
        <div className="table-header">
          <div className="table-header-tabs template-lists">
            <Tab.Container
              defaultActiveKey={"HeadQuartersSupplierList"}
              activeKey={activeTab}
              onSelect={(k) => {
                setSupplierType(k);
                setActiveTab(k);
              }}
            >
              <div className="row top-block">
                <div className="col-md-2">
                  <div className="table-title">
                    <i
                      className="fa fa-industry text-center text-primary font-size-30 m-r-10"
                      aria-hidden="true"
                    ></i>
                    <span className="mx-2 font-size-18">Suppliers</span>
                  </div>
                </div>
                <div className="col-md-8">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="HeadQuartersSupplierList">
                        Head Quarters Supplier List
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="SupplierList">Store Supplier List</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-2">
                  <div className="video-link-outer">
                    <div className="video-tutorial">
                      <i className="fa fa-icon"></i>
                      <Link to="/trainee-video#contact">
                        <span className="tutorial-title">Supplier Videos</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="SupplierList">
                  <SupplierList supplierType={supplierType} />
                </Tab.Pane>

                <Tab.Pane eventKey="HeadQuartersSupplierList">
                  <HeadQuartersSupplierList supplierType={supplierType} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierHeader;
