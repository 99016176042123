import React from "react";
import { Link, useLocation } from "react-router-dom";
import { settingLinks } from "./links";
import { useRoleLink } from "./useLinkRole";

const SettingsBar = () => {
  const { filterCondition } = useRoleLink();
  const location = useLocation();

  const activeLink = (item) => {
    // window.scrollTo(0, 0);
    return item.link === location.pathname;
   };
  return (
    <div className="sidebar-wrapper secondary-sidebar">
      <label>
        <Link to="/settings" className="d-flex align-center direction-column">
          <i className="fa fa-gears"></i>
          <p>Settings</p>
        </Link>
      </label>
      <ul className="child-menu-list">
        {settingLinks.filter(filterCondition).map((c) => (
          <li className={activeLink(c) ? 'active' : ''} key={c.label}>
            <Link to={c.link}>{c.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingsBar;
