import { call, put, takeLatest } from "redux-saga/effects";
import {
  addUpdateMessageTemplate,
  editMessageTemplate,
} from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";
import {
  addUpdateMessageTemplateFail,
  addUpdateMessageTemplateSuccess,
  editMessageTemplateFail,
  editMessageTemplateSuccess,
} from "./actions";
import {
  ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS,
  EDIT_MESSAGE_TEMPLATE_DETAILS,
} from "./actionTypes";

// ADD - UPDATE Message Template
function* onAddUpdateMessageTemplateDetails(data) {
  try {
    const response = yield call(addUpdateMessageTemplate, data);
    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);
        yield put(addUpdateMessageTemplateSuccess(response));
      } else {
        showError(response.message);
        yield put(addUpdateMessageTemplateFail(response.message));
      }
    } else {
      showError("Didn't get api response");
      yield put(addUpdateMessageTemplateFail("Didn't get api response"));
    }
  } catch (error) {
    showError(error.message);
    yield put(addUpdateMessageTemplateFail(error.response));
  }
}

// EDIT Message Template
function* onEditMessageTemplateDetails(data) {
  try {
    const response = yield call(editMessageTemplate, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(
          editMessageTemplateSuccess(response.data.CustomNotifications)
        );
      } else {
        yield put(editMessageTemplateFail(response.message));
      }
    } else {
      yield put(editMessageTemplateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editMessageTemplateFail(error.response));
  }
}

function* MessageTemplateSaga() {
  yield takeLatest(EDIT_MESSAGE_TEMPLATE_DETAILS, onEditMessageTemplateDetails);
  yield takeLatest(
    ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS,
    onAddUpdateMessageTemplateDetails
  );
}

export default MessageTemplateSaga;
