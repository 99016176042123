import React, { useContext } from "react";

export const SettingContext = React.createContext(null);

export const SettingProvider = (props) => {
  return (
    <SettingContext.Provider value={props.value}>
      {props.children}
    </SettingContext.Provider>
  );
};

export function useSettingContext() {
  return useContext(SettingContext);
}
