import { deleteRecommendations } from "helpers/backendHelper";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import { showSuccess } from "utils/alertMessage";
import { v4 as uuid } from "uuid";
import RecommendationRow from "./RecommendationRow";

const Recommendations = ({recommendationList,jobId = "", updateRecommendations = "", isEditable, workshop_id, actionPage = "", updateJobDescription = "", isJobView = false}) => {

  const [recommendationFeilds, setRecommendationFeilds] = useState([]);
  
  useEffect(() => {

    if(recommendationList){
      setRecommendationFeilds(recommendationList);
    }

  },[recommendationList]);


  const defaultRecommendationFeilds = {
    id: uuid(),
    job_id: jobId,
    recommendation: "",
    workshop_id: parseInt(workshop_id),
    recommendation_color: "#f90027",
    recommendation_status: "Red",
    is_completed: "0",
    status: 1
  };

  const addNewRecommendation = () => {

    const newRecommendationFeilds = {
      id: uuid(),
      job_id: jobId,
      recommendation: "",
      workshop_id: parseInt(workshop_id),
      recommendation_color: "#f90027",
      recommendation_status: "Red",
      status: 1,
      is_show: "0",
      is_completed: "0"
    };

    setRecommendationFeilds([...recommendationFeilds, newRecommendationFeilds]);
    updateRecommendations([...recommendationFeilds, newRecommendationFeilds]);
  }

  async function deleteRecommendation(recommId){

    const request = {
      id: recommId,
    };
    
    await deleteRecommendations({payload: request}).then(
      (response) => {
          showSuccess('Recommendation deleted')
      }).catch((err) => {
          
      })
      
  }

  return (
    <>
      {/* Recommendations START */}

      <Accordion id={isEditable === false ? "recommendation-according" : ""} >
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <div className="align-items-center" >
            
            <div className="circle">
            <i style={{color:"red", fontSize:"18px"}} className="fa fa-bell align-items-center justify-content-center"></i> 
            </div>
            <span className="ml-2" style={{fontSize:"14px"}}>{" "}Recommendations </span>
              
            <div className="ml-0 status-count" style={{lineHeight:"initial", height:"20px", width:"20px"}} >
              <span>{recommendationFeilds?.length}</span>
            </div>
            </div> 
            </Accordion.Header>
          <Accordion.Body style={{padding:"1rem 0.1rem !important"}} >
              
              {recommendationFeilds?.length > 0 &&
                <div className={isEditable === false ? "row" : ""}>
                  
            
                  {/* first row */}
                  {recommendationFeilds.map((input, index) => (

                    <RecommendationRow 
                      updateJobDescription={updateJobDescription}
                      isEditable={isEditable}
                      actionPage={actionPage}
                      key={input.id}
                      setLocalData={(localData) => {
                        const inputs = [...recommendationFeilds];
                        inputs[index] = localData;
                        setRecommendationFeilds(inputs);
                        updateRecommendations(inputs);
                      }}
                      handleDeleteClose={(cb) => {
                        const inputs = [...recommendationFeilds];
                        const currentInput = inputs[index];
                        inputs.splice(index, 1);
                        setRecommendationFeilds(inputs);
                        if(updateRecommendations){
                          updateRecommendations(inputs);
                        }
                        if(typeof currentInput?.id == "number"){
                          deleteRecommendation(currentInput?.id);
                        }
                      }}
                      addNewRecommendation={addNewRecommendation}
                      input={input}
                      isJobView={isJobView}
                      totalRecommendation={recommendationList?.length}
                    /> 

                    

                  ))}

                  

                </div>
              }


              {recommendationFeilds?.length === 0 &&
                <div className="row">
                  
            
                  {/* defualt row */}
                  

                    <RecommendationRow 
                      isEditable={isEditable}
                      key={"default_id"}
                      setLocalData={(localData) => {
                        const inputs = [...recommendationFeilds];
                        inputs[0] = localData;
                        setRecommendationFeilds(inputs);
                        updateRecommendations(inputs);
                      }}
                      handleDeleteClose={(cb) => {
                        const inputs = [...recommendationFeilds];
                        const currentInput = inputs[0];
                        inputs.splice(0, 1);
                        setRecommendationFeilds(inputs);
                        updateRecommendations(inputs);
                        if(typeof currentInput?.id == "number"){
                          deleteRecommendation(currentInput?.id);
                        }
                      }}
                      addNewRecommendation={addNewRecommendation}
                      input={defaultRecommendationFeilds}
                      isJobView={isJobView}
                      totalRecommendation={recommendationList?.length}
                    /> 
                </div>
              }
              
            
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

            
      {/* Recommendations END */}
    </>
  );
};
export default Recommendations;
