import {
  GET_EMPLOYEE_CLOCKING_HISTORY,
  GET_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
  GET_EMPLOYEE_CLOCKING_HISTORY_FAIL,
  GET_EMPLOYEE_WISE_CLOCKING_HISTORY,
  GET_EMPLOYEE_WISE_CLOCKING_HISTORY_SUCCESS,
  GET_EMPLOYEE_WISE_CLOCKING_HISTORY_FAIL,
  // ADD_EMPLOYEE_CLOCKING_HISTORY,
  // ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
  // ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL,
  // UPDATE_EMPLOYEE_CLOCKING_HISTORY,
  // UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
  // UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL,
} from "./actionTypes";

const initialState = {
  loadingHistory: false,
  clockingHistory:[],
  empWiseHistory:[],
  error: {
    message: "",
  },
};

const EmployeeClockingHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_CLOCKING_HISTORY:
      state = { 
        ...state, 
        loadingHistory: true,
      };
    break;
    case GET_EMPLOYEE_CLOCKING_HISTORY_SUCCESS:
      state = { 
        ...state, 
        loadingHistory: false,
        clockingHistory: action.payload,
      };
    break;
    case GET_EMPLOYEE_CLOCKING_HISTORY_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingHistory: false,
      };
    break;
    case GET_EMPLOYEE_WISE_CLOCKING_HISTORY:
      state = { 
        ...state, 
        loadingHistory: true,
      };
    break;
    case GET_EMPLOYEE_WISE_CLOCKING_HISTORY_SUCCESS:
      state = { 
        ...state, 
        loadingHistory: false,
        empWiseHistory: action.payload,
      };
    break;
    case GET_EMPLOYEE_WISE_CLOCKING_HISTORY_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingHistory: false,
      };
    break;
    // case ADD_EMPLOYEE_CLOCKING_HISTORY:
    //   state = { 
    //     ...state, 
    //     // loadingHistory: true,
    //   };
    // break;
    // case ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS:
    //   state = { 
    //     ...state, 
    //     loadingHistory: false,
    //     clockingHistory: [...state.clockingHistory,action.payload],
    //   };
    // break;
    // case ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL:
    //   state = {
    //     ...state,
    //     error: {
    //       message: action.payload,
    //     },
    //     loadingHistory: false,
    //   };
    // break;
    // case UPDATE_EMPLOYEE_CLOCKING_HISTORY:
    //   state = { 
    //     ...state, 
    //     // loadingHistory: true,
    //   };
    // break;
    // case UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS:

    //   const histories = [...state.clockingHistory];
    //   const indexPart = histories.findIndex((c) => c.id === action.payload.id);
    //   if (indexPart !== -1) {
    //     histories[indexPart] = {
    //       ...histories[indexPart],
    //       ...action.payload,
    //     };
    //   }

    //   state = { 
    //     ...state, 
    //     loadingHistory: false,
    //     clockingHistory: histories,
    //   };
    // break;
    // case UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL:
    //   state = {
    //     ...state,
    //     error: {
    //       message: action.payload,
    //     },
    //     loadingHistory: false,
    //   };
    // break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EmployeeClockingHistoryReducer;
