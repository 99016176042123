import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ERROR_ACTION, WAIT_FOR_ACTION } from "redux-wait-for-action";
import { EXIST_EMAIL, EXIST_EMAIL_FAIL, EXIST_EMAIL_SUCCESS } from "store/register/actionTypes";
import { emailRegex, numberRegex } from "utils/validationRegex";

export function useFirstStep() {
    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {}

        if (!values?.firstname?.trim()) {
            errors.firstname = "Please enter your first name"
        }

        if (!values?.lastname?.trim()) {
            errors.lastname = "Please enter your last name"
        }

        if (!values?.email?.trim()) {
            errors.email = "Please enter a email address"
        } else if (!emailRegex.test(values.email)) {
            errors.email = "Please enter a valid email address"
        }

        if (!values?.password?.trim()) {
            errors.password = "Please enter a password"
        }

        if (values?.password !== values?.confirmpassword) {
            errors.confirmpassword = "Please enter the same password as above"
        }

        if (!values?.terms_and_condition) {
            errors.terms_and_condition = 'This field is required'
        }

        if (!values.mobileno) {
            errors.mobileno = "Please enter a phone"
        }

        if (!numberRegex.test(values.mobileno)) {
            errors.mobileno = "Please specify a valid phone number"
        }

        if (values.emailExist) {
            errors.emailExist = "This email is already registered."
        }


        if (values.mobileExist) {
            errors.mobileExist = "This phone number is already registered."
        }


        return errors;
    }




    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            confirmpassword: "",
            mobileno: "",
            terms_and_condition: false,
            emailExist: false,
            mobileExist: false,

        },
        validate: validate

    });

    const { touched, errors, values, setFieldValue } = formik;

    useEffect(() => {

        if (touched.email && !errors.email) {
            dispatch({
                type: EXIST_EMAIL,
                payload: {
                    "get": "all",
                    "conditions": {
                        "email": values.email
                    }
                },
                [WAIT_FOR_ACTION]: EXIST_EMAIL_SUCCESS,
                [ERROR_ACTION]: EXIST_EMAIL_FAIL
            }).then(() => {
                setFieldValue("emailExist", true)
            }).catch(() => {
                setFieldValue("emailExist", false)
            })
        }

    }, [values.email, dispatch, setFieldValue, touched.email, errors.email])


    useEffect(() => {
        if (touched.mobileno && !errors.mobileno) {
            dispatch({
                type: EXIST_EMAIL,
                payload: {
                    "get": "all",
                    "conditions": {
                        "phone_number": values.mobileno
                    }
                },
                [WAIT_FOR_ACTION]: EXIST_EMAIL_SUCCESS,
                [ERROR_ACTION]: EXIST_EMAIL_FAIL
            }).then(() => {
                setFieldValue("mobileExist", true)
            }).catch(() => {
                setFieldValue("mobileExist", false)
            })
        }
    }, [dispatch, errors.mobileno, setFieldValue, touched.mobileno, values.mobileno])

    return { formik };
}