export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS";
export const GET_CUSTOMER_LIST_FAIL = "GET_CUSTOMER_LIST_FAIL";

export const GET_SECONDARY_CUSTOMER_LIST = "GET_SECONDARY_CUSTOMER_LIST";
export const GET_SECONDARY_CUSTOMER_LIST_SUCCESS = "GET_SECONDARY_CUSTOMER_LIST_SUCCESS";
export const GET_SECONDARY_CUSTOMER_LIST_FAIL = "GET_SECONDARY_CUSTOMER_LIST_FAIL";

export const GET_CUSTOMER_COUNT = "GET_CUSTOMER_COUNT";
export const GET_CUSTOMER_COUNT_SUCCESS = "GET_CUSTOMER_COUNT_SUCCESS";
export const GET_CUSTOMER_COUNT_FAIL = "GET_CUSTOMER_COUNT_FAIL";

export const GET_SUPPILERS_LIST = "GET_SUPPILERS_LIST";
export const GET_SUPPILERS_LIST_SUCCESS = "GET_SUPPILERS_LIST_SUCCESS";
export const GET_SUPPILERS_LIST_FAIL = "GET_SUPPILERS_LIST_FAIL";

export const HQ_GET_SUPPILERS_LIST = "HQ_GET_SUPPILERS_LIST";
export const HQ_GET_SUPPILERS_LIST_SUCCESS = "HQ_GET_SUPPILERS_LIST_SUCCESS";
export const HQ_GET_SUPPILERS_LIST_FAIL = "HQ_GET_SUPPILERS_LIST_FAIL";

export const GET_SUPPILERS_COUNT = "GET_SUPPILERS_COUNT";
export const GET_SUPPILERS_COUNT_SUCCESS = "GET_SUPPILERS_COUNT_SUCCESS";
export const GET_SUPPILERS_COUNT_FAIL = "GET_SUPPILERS_COUNT_FAIL";

export const HQ_GET_SUPPILERS_COUNT = "HQ_GET_SUPPILERS_COUNT";
export const HQ_GET_SUPPILERS_COUNT_SUCCESS = "HQ_GET_SUPPILERS_COUNT_SUCCESS";
export const HQ_GET_SUPPILERS_COUNT_FAIL = "HQ_GET_SUPPILERS_COUNT_FAIL";

export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const GET_EMPLOYEE_LIST_SUCCESS = "GET_EMPLOYEE_LIST_SUCCESS";
export const GET_EMPLOYEE_LIST_FAIL = "GET_EMPLOYEE_LIST_FAIL";

export const GET_SUPPILERS_BRANCH = "GET_SUPPILERS_BRANCH";
export const GET_SUPPILERS_BRANCH_SUCCESS = "GET_SUPPILERS_BRANCH_SUCCESS";
export const GET_SUPPILERS_BRANCH_FAIL = "GET_SUPPILERS_BRANCH_FAIL";

export const GET_ONSITE_STATE = "GET_ONSITE_STATE";
export const GET_ONSITE_STATE_SUCCESS = "GET_ONSITE_STATE_SUCCESS";
export const GET_ONSITE_STATE_FAIL = "GET_ONSITE_STATE_FAIL";

export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL";
export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS";
export const GET_CUSTOMER_DETAIL_FAIL = "GET_CUSTOMER_DETAIL_FAIL";

export const GET_CUSTOMER_VEHICLE = "GET_CUSTOMER_VEHICLE";
export const GET_CUSTOMER_VEHICLE_SUCCESS = "GET_CUSTOMER_VEHICLE_SUCCESS";
export const GET_CUSTOMER_VEHICLE_FAIL = "GET_CUSTOMER_VEHICLE_FAIL";

export const GET_CUSTOMER_NOTES = "GET_CUSTOMER_NOTES";
export const GET_CUSTOMER_NOTES_SUCCESS = "GET_CUSTOMER_NOTES_SUCCESS";
export const GET_CUSTOMER_NOTES_FAIL = "GET_CUSTOMER_NOTES_FAIL";

export const GET_CUSTOMER_ATTACHMENT = "GET_CUSTOMER_ATTACHMENT";
export const GET_CUSTOMER_ATTACHMENT_SUCCESS =
  "GET_CUSTOMER_ATTACHMENT_SUCCESS";
export const GET_CUSTOMER_ATTACHMENT_FAIL = "GET_CUSTOMER_ATTACHMENT_FAIL";

export const CHECK_SUPPILER_EMAIL = "CHECK_SUPPILER_EMAIL";
export const CHECK_SUPPILER_EMAIL_SUCCESS = "CHECK_SUPPILER_EMAIL_SUCCESS";
export const CHECK_SUPPILER_EMAIL_FAIL = "CHECK_SUPPILER_EMAIL_FAIL";

export const CHECK_SUPPILER_MOBILE = "CHECK_SUPPILER_MOBILE";
export const CHECK_SUPPILER_MOBILE_SUCCESS = "CHECK_SUPPILER_MOBILE_SUCCESS";
export const CHECK_SUPPILER_MOBILE_FAIL = "CHECK_SUPPILER_MOBILE_FAIL";

export const GET_SUPPILERS = "GET_SUPPILERS";
export const GET_SUPPILERS_SUCCESS = "GET_SUPPILERS_SUCCESS";
export const GET_SUPPILERS_FAIL = "GET_SUPPILERS_FAIL";

export const HQ_GET_SUPPLIER = "HQ_GET_SUPPILER";
export const HQ_GET_SUPPLIER_SUCCESS = "HQ_GET_SUPPILER_SUCCESS";
export const HQ_GET_SUPPLIER_FAIL = "HQ_GET_SUPPILER_FAIL";

export const CHECK_EMPLOYEE_EMAIL = "CHECK_EMPLOYEE_EMAIL";
export const CHECK_EMPLOYEE_EMAIL_SUCCESS = "CHECK_EMPLOYEE_EMAIL_SUCCESS";
export const CHECK_EMPLOYEE_EMAIL_FAIL = "CHECK_EMPLOYEE_EMAIL_FAIL";

export const CHECK_EMPLOYEE_MOBILE = "CHECK_EMPLOYEE_MOBILE";
export const CHECK_EMPLOYEE_MOBILE_SUCCESS = "CHECK_EMPLOYEE_MOBILE_SUCCESS";
export const CHECK_EMPLOYEE_MOBILE_FAIL = "CHECK_EMPLOYEE_MOBILE_FAIL";

export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAIL = "GET_EMPLOYEE_FAIL";

export const GET_EMPLOYEE_NOTES = "GET_EMPLOYEE_NOTES";
export const GET_EMPLOYEE_NOTES_SUCCESS = "GET_EMPLOYEE_NOTES_SUCCESS";
export const GET_EMPLOYEE_NOTES_FAIL = "GET_EMPLOYEE_NOTES_FAIL";

export const GET_CUSTOMER_HISTORY = "GET_CUSTOMER_HISTORY";
export const GET_CUSTOMER_HISTORY_SUCCESS = "GET_CUSTOMER_HISTORY_SUCCESS";
export const GET_CUSTOMER_HISTORY_FAIL = "GET_CUSTOMER_HISTORY_FAIL";

export const GET_POS_HISTORY = "GET_POS_HISTORY";
export const GET_POS_HISTORY_SUCCESS = "GET_POS_HISTORY_SUCCESS";
export const GET_POS_HISTORY_FAIL = "GET_POS_HISTORY_FAIL";

export const CHECK_BRANCH_EMAIL = "CHECK_BRANCH_EMAIL";
export const CHECK_BRANCH_EMAIL_SUCCESS = "CHECK_BRANCH_EMAIL_SUCCESS";
export const CHECK_BRANCH_EMAIL_FAIL = "CHECK_BRANCH_EMAIL_FAIL";

export const CHECK_BRANCH_MOBILE = "CHECK_BRANCH_MOBILE";
export const CHECK_BRANCH_MOBILE_SUCCESS = "CHECK_BRANCH_MOBILE_SUCCESS";
export const CHECK_BRANCH_MOBILE_FAIL = "CHECK_BRANCH_MOBILE_FAIL";

export const VIEW_SUPPILERS_BRANCH = "VIEW_SUPPILERS_BRANCH";
export const VIEW_SUPPILERS_BRANCH_SUCCESS = "VIEW_SUPPILERS_BRANCH_SUCCESS";
export const VIEW_SUPPILERS_BRANCH_FAIL = "VIEW_SUPPILERS_BRANCH_FAIL";

export const SUPPLIER_SEARCH = "SUPPLIER_SEARCH";
export const SUPPLIER_SEARCH_SUCCESS = "SUPPLIER_SEARCH_SUCCESS";
export const SUPPLIER_SEARCH_FAIL = "SUPPLIER_SEARCH_FAIL";

//HQ Suppliers search
export const HQ_SUPPLIER_SEARCH = "HQ_SUPPLIER_SEARCH";
export const HQ_SUPPLIER_SEARCH_SUCCESS = "HQ_SUPPLIER_SEARCH_SUCCESS";
export const HQ_SUPPLIER_SEARCH_FAIL = "HQ_SUPPLIER_SEARCH_FAIL";

export const CUSTOMER_SEARCH = "CUSTOMER_SEARCH";
export const CUSTOMER_SEARCH_SUCCESS = "CUSTOMER_SEARCH_SUCCESS";
export const CUSTOMER_SEARCH_FAIL = "CUSTOMER_SEARCH_FAIL";

export const CLEAN_CUSTOMER_DATA = "CLEAN_CUSTOMER_DATA";

export const CLEAN_EMPLOYEE_DATA = "CLEAN_CUSTOMER_DATA";
