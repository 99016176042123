import {
  addApprovedPartsInspection,
  setApproveManualInspection,
} from "helpers/backendHelper";

export async function approveInspection(payload) {
  const data = await setApproveManualInspection({ payload });
  const request = {
    parts: payload.insp_question_ans.map((c) => c.id),
    workshop_id: data?.data?.InspInspections?.workshop_id,
  };
  await addApprovedPartsInspection({ payload: request });
  if (data?.code === 200) {
    return true;
  } else {
    throw new Error("Internal error occured");
  }
}

export async function rejectInspection(payload) {
  const data = await setApproveManualInspection({ payload });
  if (data?.code === 200) {
    return true;
  } else {
    throw new Error("Internal error occured");
  }
}
