import { call, put, takeLatest } from "redux-saga/effects";
import {
  getSubscriptionHistoryDetailsListFail,
  getSubscriptionHistoryDetailsListSuccess,
  getSubscriptionHistoryListFail,
  getSubscriptionHistoryListSuccess,
  getSubscriptionListFail,
  getSubscriptionListSuccess,
  getSubscriptionDetailsListFail,
  getSubscriptionDetailsListSuccess,
  getMonthlySubsciptionDetailSuccess,
  getMonthlySubsciptionDetailFail
} from "./actions";
import {
  GET_SUBSCRIPTION_HISTORY_DETAIL_LIST,
  GET_SUBSCRIPTION_HISTORY_LIST,
  GET_SUBSCRIPTION_LIST,
  GET_SUBSCRIPTION_PLAN_DATA,
  GET_SUBSCRIPTION_PLAN_DATA_FAIL,
  GET_SUBSCRIPTION_PLAN_DATA_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_LIST,
  GET_SUBSCRIPTION_DETAILS_LIST_FAIL,
  GET_SUBSCRIPTION_DETAILS_LIST_SUCCESS,
  GET_SUBSCRIPTION_MONTHLY_HISTORY,
  GET_SUBSCRIPTION_MONTHLY_HISTORY_SUCCESS,
  GET_SUBSCRIPTION_MONTHLY_HISTORY_FAIL,
} from "./actionTypes";

import { getWorkshopId } from "utils/getWorkshopId";
import {
  getSubscription,
  getSubscriptionAddon,
  getSubscriptionHistoryDetailsList,
  getSubscriptionHistoryList,
} from "../../helpers/backendHelper";

function* onGetSubscription(data) {
  try {
    const response = yield call(getSubscription, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getSubscriptionListSuccess(response.data.WorkshopSubscription)
        );
      } else {
        yield put(getSubscriptionListFail(response.message));
      }
    } else {
      yield put(getSubscriptionListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSubscriptionListFail(error.response));
  }
}

function* onGetSubscriptionDetails(data) {
  try {
    const response = yield call(getSubscriptionAddon, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getSubscriptionDetailsListSuccess(response.data.WorkshopSubscriptionDetails)
        );
      } else {
        yield put(getSubscriptionDetailsListFail(response.message));
      }
    } else {
      yield put(getSubscriptionDetailsListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSubscriptionDetailsListFail(error.response));
  }
}

function* onGetSubscriptionHistory(data) {
  try {
    const response = yield call(getSubscriptionHistoryList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getSubscriptionHistoryListSuccess(response.data.SubscriptionHistory)
        );
      } else if (response.code === 400) {
        yield put(getSubscriptionHistoryListSuccess({}));
      } else {
        yield put(getSubscriptionHistoryListFail(response.message));
      }
    } else {
      yield put(getSubscriptionHistoryListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSubscriptionHistoryListFail(error.response));
  }
}

function* onGetSubscriptionHistoryDetailsList(data) {
  try {
    const response = yield call(getSubscriptionHistoryDetailsList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getSubscriptionHistoryDetailsListSuccess(
            response.data.SubscriptionHistoryDetails
          )
        );
      } else {
        yield put(getSubscriptionHistoryDetailsListFail(response.message));
      }
    } else {
      yield put(
        getSubscriptionHistoryDetailsListFail("Didn't get api response")
      );
    }
  } catch (error) {
    yield put(getSubscriptionHistoryDetailsListFail(error.response));
  }
}

function* onGetSubscriptionPlanData() {
  try {
    yield call(onGetSubscription, {
      type: GET_SUBSCRIPTION_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: getWorkshopId(),
        },
        fields: [
          "id",
          "workshop_id",
          "subscription_plan_id",
          "plan_name",
          "plan_type",
          "plan_amount",
          "paid_amount",
          "plan_expiry_date",
          "status",
        ],
      },
    });

    yield call(onGetSubscriptionDetails, {
      type: GET_SUBSCRIPTION_DETAILS_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: getWorkshopId(),
          status: 1,
          is_deleted: 0
        },
        fields: [
          "id",
          "workshop_subscription_id",
          "subscription_plan_id",
          "subscription_type",
          "plan_type",
          "plan_name",
          "subscription_type",
          "paid_amount",
          "end_date",
          "sms_limit",
          "min_sms_limit",
          "current_sms_limit",
          "status",
          "extra_desktop_user",
          "mobile_user_price",
          "desktop_user_price",
          "extra_mobile_user",
          "is_sms_rollover",
          "vin_request",
          "min_vin_request",
          "is_vin_rollover",
          "created_on",
        ]
      },
    });

    yield call(onGetSubscriptionHistory, {
      type: GET_SUBSCRIPTION_HISTORY_LIST,
      payload: { get: "all", conditions: { workshop_id: getWorkshopId() } },
    });

    yield call(onGetSubscriptionHistoryDetailsList, {
      type: GET_SUBSCRIPTION_HISTORY_DETAIL_LIST,
      payload: { get: "all", conditions: { workshop_id: getWorkshopId() } },
    });

    yield put({
      type: GET_SUBSCRIPTION_PLAN_DATA_SUCCESS,
    });

  } catch (error) {
    yield put({
      type: GET_SUBSCRIPTION_PLAN_DATA_FAIL,
    });
  }
}

function* onGetSubscriptionMonthlyDetail(data) {
  try {
    const response = yield call(getSubscriptionHistoryList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getMonthlySubsciptionDetailSuccess(
            response.data.SubscriptionHistory
          )
        );
      } else {
        yield put(getMonthlySubsciptionDetailFail(response.message));
      }
    } else {
      yield put(
        getMonthlySubsciptionDetailFail("Didn't get api response")
      );
    }
  } catch (error) {
    yield put(getMonthlySubsciptionDetailFail(error.response));
  }
}

function* SubscriptionTransactionSaga() {
  yield takeLatest(GET_SUBSCRIPTION_LIST, onGetSubscription);
  yield takeLatest(GET_SUBSCRIPTION_HISTORY_LIST, onGetSubscriptionHistory);
  yield takeLatest(GET_SUBSCRIPTION_HISTORY_DETAIL_LIST, onGetSubscriptionHistoryDetailsList);

  yield takeLatest(GET_SUBSCRIPTION_PLAN_DATA, onGetSubscriptionPlanData);

  yield takeLatest(GET_SUBSCRIPTION_MONTHLY_HISTORY, onGetSubscriptionMonthlyDetail);
}

export default SubscriptionTransactionSaga;