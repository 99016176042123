import moment from "moment";
import _ from "underscore";
import { date_format, getCurrency } from "utils/getCurrency";
import { addComma, formatNumber } from "utils/numberFunction";
import DropdownMenu from "./DropdownMenu";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function useInvoiceColumns({
  onCreditClick,
  onView,
  onEdit,
  onPayment,
  onMail,
  onSend,
  onPrint,
  onGoPay,
  onDelete,
  onSms
}) {

  const dateFormatter = (cell) => {
    return moment(cell).format(date_format);
  };

  const Menu = (row) => (
    <>
      <DropdownMenu
        onEdit={onEdit ? () => onEdit(row) : undefined}
        onPayment={onPayment ? () => onPayment(row) : undefined}
        onMail={onMail ? () => onMail?.(row) : undefined}
        onSend={onSend ? () => onSend?.(row) : undefined}
        onSms={onSms ? () => onSms?.(row) : undefined}
        onPrint={onPrint ? () => onPrint?.(row) : undefined}
        onGoPay={onGoPay ? () => onGoPay?.(row) : undefined}
        onDelete={onDelete ? () => onDelete?.(row) : undefined}
        onView={onView ? () => onView?.(row) : undefined}
        onCreditClick={onCreditClick ? () => onCreditClick?.(row) : undefined}
      />
    </>
  );

  const checkStatusColor = (cell, row) => {
    let invoice_balance = "0.00";
    if (row?.invoices_payments_credits) {
      let lastElement = row?.invoices_payments_credits[row?.invoices_payments_credits.length - 1];
      invoice_balance = lastElement?.balance_remaining || "0.00";
    }
    let color = cell?.toLowerCase() === "paid" ? "#00c335" : invoice_balance !== "0.00" ? "#00c335" : "#ff6600";
    let cellStatus = cell;
    if (row.tax_invoice == 2 || row.tax_invoice == 3 || (invoice_balance !== "0.00" && row.invoice_status !== "Paid")) {
      cellStatus = "Part Payment";
    }
    return (
      <div
        style={{
          background: color,
          // fontSize: 12,
          // padding: "2px 15px",
          // display: "inline-block",
          // width: "max-content",
          // fontWeight: "700",
        }}
        className="jobStatus"
      >
        {cellStatus}
      </div>
    );
  };

  const moneyFormat = (cell, row) => {
    let price = 0;
    if (cell) {
      price = parseFloat(cell);
    }
    return <span>{getCurrency + " " + addComma(price.toFixed(2))}</span>;
  };

  const moneyFormatWithColour = (cell, row) => {
    let price = 0;
    if (cell) {
      price = parseFloat(cell);
    }
    return <span className={price !== 0 ? "text-danger" : ''}>{getCurrency + " " + addComma(price.toFixed(2))}</span>;
  };

  const raisedInvoiceColumns = [
    {
      dataField: "date",
      text: "Date",
      formatter: dateFormatter,
    },
    {
      dataField: "job_no",
      text: "Job No.",
    },
    {
      dataField: "customer",
      text: "Customer",
      formatter: (cell, row) => {
        let fullname = "";
        if(row?.data?.user?.customers_details[0] == undefined){
          if (row?.data?.job_schedule?.customer?.customers_details?.[0]?.type == 1) {
            fullname = row?.data?.job_schedule?.customer?.customers_details?.[0]?.company_name;
          } else {
            fullname =
            row?.data?.job_schedule?.customer?.customers_details?.[0]?.firstname +
              " " +
              row?.data?.job_schedule?.customer?.customers_details?.[0]?.lastname;
          }
        }else {
          if (row?.data?.user?.customers_details?.[0]?.type == 1) {
            fullname = row?.data?.user?.customers_details?.[0]?.company_name;
          } else {
            fullname =
            row?.data?.user?.customers_details?.[0]?.firstname +
              " " +
              row?.data?.user?.customers_details?.[0]?.lastname;
          }
        }
        
        
        return <div>{fullname}</div>;
      },
    },
    {
      dataField: "invoice_number",
      text: "Invoice No.",
      formatter: (cell, row) => {
        let jobData = row?.data?.job_schedule;

        let customerData = row.type == "sale" ? row?.data?.user?.customers_details?.[0]
        :jobData?.customer?.customers_details?.[0]

        let customerName = "";
        if((jobData?.show_allocation_column == "1" && row?.data?.invoice_allocation_type == "2") || (jobData?.show_allocation_column == "0" && row?.data?.insurance_preview_invoice == "1")){           
          customerName = row?.data?.insurance_company?.company_name;            
        }else if(jobData?.show_allocation_column == "1" && row?.data?.invoice_allocation_type == "3"){
          customerName = jobData?.invoice_c_customer?.type == 2
            ? jobData?.invoice_c_customer?.firstname + " " + jobData?.invoice_c_customer?.lastname
            : jobData?.invoice_c_customer?.company_name                     
        }else{
          customerName = customerData?.type == 2
            ? customerData?.firstname + " " + customerData?.lastname
            : customerData?.company_name    
        }
        return (
          <>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="tooltip-bottom">
                {customerName}
              </Tooltip>
            }
          >
            <div>
              <span className="text-blue">{cell}</span>
            </div>
          </OverlayTrigger>                 
          </>
        )
      }
    },
    {
      dataField: "invoice_total",
      text: "Invoice Total",
      formatter: moneyFormat,
    },
    {
      dataField: "paid amount",
      text: "Paid Amount",
      formatter: (cell, row) => {
        let price = 0;
        let total_paid_amount = 0;
        if (row?.invoice_total) {
          price = parseFloat(row?.invoice_total);
          if (row?.invoices_payments_credits) {
            let total_paid_price_array = _.pluck(row?.invoices_payments_credits, 'amount_paid')
            total_paid_amount = total_paid_price_array.reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
          }
        }
        return <span>{getCurrency + " " + addComma(total_paid_amount.toFixed(2))}</span>;
      }
    },
    {
      dataField: "invoice_balance",
      text: "Invoice Balance",
      formatter: (cell, row) => {
        let invoice_balance = cell || "0.00";
        if (row?.invoices_payments_credits?.length > 0) {
          let lastElement = row?.invoices_payments_credits[row?.invoices_payments_credits.length - 1];
          invoice_balance = lastElement?.balance_remaining || "0.00";
        }
        return <span>{getCurrency} {invoice_balance}</span>
      },
    },
    // {
    //   dataField: "job_total",
    //   text: "Job Total",
    //   formatter: moneyFormat,
    // },
    {
      dataField: "invoice_type",
      text: "Invoice Type",
    },
    {
      dataField: "invoice_status",
      text: "Invoice Status",
      formatter: checkStatusColor,
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return <Menu row={row} />;
      },
    },
  ];

  const completedInvoiceColumns = [
    {
      dataField: "date",
      text: "Date",
      formatter: dateFormatter,
    },
    {
      dataField: "job_no",
      text: "Job No.",
    },
    {
      dataField: "customer",
      text: "Customer",
      formatter: (cell, row) => {
        let fullname = "";
        if(row?.data?.user?.customers_details[0] == undefined) {
          if (row?.data?.job_schedule?.customer?.customers_details?.[0]?.type == 1) {
            fullname = row?.data?.job_schedule?.customer?.customers_details?.[0]?.company_name;
          } else {
            fullname =
            row?.data?.job_schedule?.customer?.customers_details?.[0]?.firstname +
              " " +
              row?.data?.job_schedule?.customer?.customers_details?.[0]?.lastname;
          }
        }else {
          if (row?.data?.user?.customers_details?.[0]?.type == 1) {
            fullname = row?.data?.user?.customers_details?.[0]?.company_name;
          } else {
            fullname =
            row?.data?.user?.customers_details?.[0]?.firstname +
              " " +
              row?.data?.user?.customers_details?.[0]?.lastname;
          }
        }

        return <div>{fullname}</div>;
      },
    },
    {
      dataField: "invoice_number",
      text: "Invoice No.",
      formatter: (cell, row) => {
        let jobData = row?.data?.job_schedule;

        let customerData = row.type == "sale" ? row?.data?.user?.customers_details?.[0]
        :jobData?.customer?.customers_details?.[0]

        let customerName = "";
        if((jobData?.show_allocation_column == "1" && row?.data?.invoice_allocation_type == "2") || (jobData?.show_allocation_column == "0" && row?.data?.insurance_preview_invoice == "1")){           
          customerName = row?.data?.insurance_company?.company_name;            
        }else if(jobData?.show_allocation_column == "1" && row?.data?.invoice_allocation_type == "3"){
          customerName = jobData?.invoice_c_customer?.type == 2
            ? jobData?.invoice_c_customer?.firstname + " " + jobData?.invoice_c_customer?.lastname
            : jobData?.invoice_c_customer?.company_name                     
        }else{
          customerName = customerData?.type == 2
            ? customerData?.firstname + " " + customerData?.lastname
            : customerData?.company_name    
        }
        return (
          <>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="tooltip-bottom">
                {customerName}
              </Tooltip>
            }
          >
            <div>
              <span className="text-blue">{cell}</span>
            </div>
          </OverlayTrigger>                 
          </>
        )
      }
    },
    {
      dataField: "invoice_total",
      text: "Invoice Total",
      formatter: moneyFormat,
    },
    {
      dataField: "invoice_balance",
      text: "Invoice Balance",
      // formatter: moneyFormat,
      formatter: (cell, row) => {
        let invoice_balance = cell || "0.00";
        if (invoice_balance === "0.00") {
          if (row?.invoices_payments_credits?.length > 0) {
            let lastElement = row?.invoices_payments_credits[row?.invoices_payments_credits.length - 1];
            invoice_balance = lastElement?.balance_remaining || "0.00";
          }
        }
        return <span>{getCurrency} {invoice_balance}</span>
      },
    },
    // {
    //   dataField: "job_total",
    //   text: "Job Total",
    //   formatter: moneyFormat,
    // },
    {
      dataField: "invoice_type",
      text: "Invoice Type",
    },
    {
      dataField: "invoice_status",
      text: "Invoice Status",
      formatter: checkStatusColor,
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return <Menu row={row} hideCredit={row.type === "sale"} />;
      },
    },
  ];

  const invoicePaymentsColumns = [
    {
      dataField: "created_on",
      text: "Date",
      formatter: dateFormatter,
    },
    {
      dataField: "job_number",
      text: "Job No.",
    },

    {
      dataField: "customer",
      text: "Customer",
      formatter: (cell, row) => {
        let fullname = "";

        if(row?.invoice?.job_schedule?.customer?.customers_details == undefined && row?.cash_sale_invoice?.user?.customers_details == undefined){

          if (row?.invoice?.job_schedule?.customer?.cust_detail?.type == 1) {
            fullname = row?.invoice?.job_schedule?.customer?.cust_detail?.company_name;
          } else {
            fullname =
            row?.invoice?.job_schedule?.customer?.cust_detail?.firstname +
              " " +
              row?.invoice?.job_schedule?.customer?.cust_detail?.lastname;
          }
        } else if(row?.cash_sale_invoice?.user?.customers_details?.[0] != undefined){
         
          if (row?.cash_sale_invoice?.user?.customers_details?.[0]?.type == 1) {
            fullname = row?.cash_sale_invoice?.user?.customers_details?.[0]?.company_name;
          } else {
            fullname =
            row?.cash_sale_invoice?.user?.customers_details?.[0]?.firstname +
              " " +
              row?.cash_sale_invoice?.user?.customers_details?.[0]?.lastname;
          }
        } else if(row?.cash_sale_invoice?.user?.customers_details[0] == undefined){
         
          if (row?.invoice?.job_schedule?.customer?.customers_details?.[0]?.type == 1) {
            fullname = row?.invoice?.job_schedule?.customer?.customers_details?.[0]?.company_name;
          } else {
            fullname =
            row?.invoice?.job_schedule?.customer?.customers_details?.[0]?.firstname +
              " " +
              row?.invoice?.job_schedule?.customer?.customers_details?.[0]?.lastname;
          }
        }else {
        
            if (row?.cash_sale_invoice?.user?.customers_details?.[0]?.type == 1) {
              fullname = row?.cash_sale_invoice?.user?.customers_details?.[0]?.company_name;
            } else {
              fullname =
              row?.cash_sale_invoice?.user?.customers_details?.[0]?.firstname +
                " " +
                row?.cash_sale_invoice?.user?.customers_details?.[0]?.lastname;
            }
        }
        return <div>{fullname}</div>;
      },
    },
    {
      dataField: "invoice_number",
      text: "Invoice No.",
      formatter: (cell, row) => {            
        let customerName = "";
        if(row.job_number === 'Cash Sale'){                    
          let customerData = row?.cash_sale_invoice?.user?.customers_details?.[0];         
          customerName = customerData?.type == 2
              ? customerData?.firstname + " " + customerData?.lastname
              : customerData?.company_name 
        }else{
          let jobData = row?.invoice?.job_schedule; 
          let customerData = jobData?.customer?.customers_details?.[0];
          if((jobData?.show_allocation_column == "1" && row?.invoice?.invoice_allocation_type == "2") || (jobData?.show_allocation_column == "0" && row?.invoice?.insurance_preview_invoice == "1")){           
            customerName = row?.invoice?.insurance_company?.company_name;            
          }else if(jobData?.show_allocation_column == "1" && row?.invoice?.invoice_allocation_type == "3"){
            customerName = jobData?.invoice_c_customer?.type == 2
              ? jobData?.invoice_c_customer?.firstname + " " + jobData?.invoice_c_customer?.lastname
              : jobData?.invoice_c_customer?.company_name                     
          }else{
            customerName = customerData?.type == 2
              ? customerData?.firstname + " " + customerData?.lastname
              : customerData?.company_name    
          }
        }                    
        return (
          <>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="tooltip-bottom">
                {customerName}
              </Tooltip>
            }
          >
            <div>
              <span className="text-blue">{cell}</span>
            </div>
          </OverlayTrigger>                 
          </>
        )
      }
    },
    {
      dataField: "invoice.claim_amount",
      text: "Invoice Total",
      // formatter: moneyFormat,
      formatter: (cell, row) => {
        let price = 0;
        if (row?.invoice?.claim_amount) {
          price = parseFloat(row?.invoice?.claim_amount || row?.cash_sale_invoice?.claim_amount);
        } else {
          price = parseFloat(row?.invoice?.total || row?.cash_sale_invoice?.total);
        }

        return (
          <div>
            <span >{getCurrency + " " + addComma(price)}</span>
          </div>
        )
      }
    },
    {
      dataField: "amount_paid",
      text: "Payment Received",
      formatter: moneyFormat,
    },
    {
      dataField: "balance_remaining",
      text: "Invoice Balance",
      formatter: moneyFormatWithColour,
    },
    {
      dataField: "payment_method",
      text: "Payment Method",
      formatter: (cell, row) => {
        let met = "";
        switch (cell) {
          case "1":
            met = "Cash";
            break;
          case "2":
            met = "Card";
            break;
          case "3":
            met = "EFT";
            break;
          case "4":
            met = "Assist";
            break;
          case "5":
            met = "Cheque";
            break;

          default:
            met = "Other";
            break;
        }

        return met;
      },
    },
    {
      dataField: "receipt_no",
      text: "Receipt No.",
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return <Menu row={{ ...row }} />;
      },
    },
  ];

  const invoiceCreditsColumns = [
    {
      dataField: "invoices_payments_credit.invoice.created_on",
      text: "Date",
      formatter: dateFormatter,
    },
    {
      dataField: "job_number",
      text: "Job No.",
    },

    {
      dataField: "customer",
      text: "Customer",
      formatter: (cell, row) => {
        return <div>{cell}</div>;
      },
    },
    {
      dataField: "invoice_number",
      text: "Invoice No.",
      formatter: (cell, row) => {
        let customerName = "";
        if(row.job_number === 'Cash Sale'){              
          let customerData = row?.cash_sale_payments_credit?.cash_sale_invoice?.user?.customers_details?.[0];         
          customerName = customerData?.type == 2
              ? customerData?.firstname + " " + customerData?.lastname
              : customerData?.company_name 
        }else{              
          let jobData = row?.invoices_payments_credit?.invoice?.job_schedule; 
          let customerData = jobData?.customer?.customers_details?.[0];
          if((jobData?.show_allocation_column == "1" && row?.invoices_payments_credit?.invoice?.invoice_allocation_type == "2") || (jobData?.show_allocation_column == "0" && row?.invoices_payments_credit?.invoice?.insurance_preview_invoice == "1")){           
            customerName = row?.invoices_payments_credit?.invoice?.insurance_company?.company_name;            
          }else if(jobData?.show_allocation_column == "1" && row?.invoices_payments_credit?.invoice?.invoice_allocation_type == "3"){
            customerName = jobData?.invoice_c_customer?.type == 2
              ? jobData?.invoice_c_customer?.firstname + " " + jobData?.invoice_c_customer?.lastname
              : jobData?.invoice_c_customer?.company_name                     
          }else{
            customerName = customerData?.type == 2
              ? customerData?.firstname + " " + customerData?.lastname
              : customerData?.company_name    
          }
        }      
        return (
          <>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="tooltip-bottom">
                {customerName}
              </Tooltip>
            }
          >
            <div>
              <span className="text-blue">{cell}</span>
            </div>
          </OverlayTrigger>                 
          </>
        )
      }
    },
    {
      dataField: "receipt_total",
      text: "Invoice Total",
      formatter: moneyFormat,
    },
    {
      dataField: "amount_credit",
      text: "Credit Total",
      formatter: moneyFormat,
    },

    {
      dataField: "credit_no",
      text: "Credit Note No.",
    },

    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return <Menu row={row} />;
      },
    },
  ];

  const invoiceStatementsColumns = [
    {
      dataField: "created_on",
      text: "Date",
      formatter: dateFormatter,
    },
    {
      dataField: "statement_number",
      text: "Statement No.",
    },
    {
      dataField: "customer",
      text: "Customer",
      formatter: (cell, row) => {
        return <div>{cell}</div>;
      },
    },
    {
      dataField: "statement_total",
      text: "Statement Total",
      formatter: moneyFormat,
      // formatter: (cell, row) => {
      //   return (
      //     <div>
      //       <span className="text-blue">{cell}</span>
      //     </div>
      //   )
      // }
    },
    {
      dataField: "payment_received",
      text: "Payment Received",
      formatter: moneyFormat,
    },
    {
      dataField: "remain_balance",
      text: "Statement Balance",
      formatter: (cell, row) => {
        if (row.payment_received <= 0 && cell <= 0) {
          return moneyFormat(row.statement_total)
        } else {
          return moneyFormat(cell)
        }
      }
    },
    {
      dataField: "statement_due_date",
      text: "Statement Due Date",
      formatter: dateFormatter,
    },
    {
      dataField: "payment_method",
      text: "Payment Method",
      formatter: (cell, row) => {
        let met = "";
        switch (cell) {
          case "1":
            met = "Cash";
            break;
          case "2":
            met = "Card";
            break;
          case "3":
            met = "EFT";
            break;
          case "4":
            met = "Assist";
            break;
          case "5":
            met = "Cheque";
            break;
          default:
            met = "Other";
            break;
        }
        return met;
      }
    },
    {
      dataField: "payment_date",
      text: "Payment Date",
      formatter: (cell, row) => {
        if (cell) {
          return dateFormatter(cell)
        } else {
          return '-'
        }
      },
    },
    {
      dataField: "statement_status",
      text: "Status",
      formatter: (cell, row) => {
        if (cell == 1) {
          return (<div
            style={{
              background: "#dcabff",
            }}
            className="jobStatus"
          >
            Created
          </div>);
        } else if (cell == 2) {
          return (<div
            style={{
              background: "#ffa377",
            }}
            className="jobStatus"
          >
            Sent
          </div>);
        } else if (cell == 3) {
          return (<div
            style={{
              background: "#00c335",
            }}
            className="jobStatus"
          >
            Paid
          </div>);
        } else if (cell == 4) {
          return (<div
            style={{
              background: "#00c335",
            }}
            className="jobStatus"
          >
            Partial Paid
          </div>);
        } else if (cell == 5) {
          return (<div
            style={{
              background: "#6bbbff",
            }}
            className="jobStatus"
          >
            Printed
          </div>);
        }
      }
      // formatter: checkStatusColor,
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return <Menu row={row} />;
      },
    },
  ];

  const invoiceStatementsReportsColumns = [
    {
      dataField: "created_on",
      text: "Date Created",
      formatter: dateFormatter,
    },
    {
      dataField: "statement_number",
      text: "Statement No.",
    },
    {
      dataField: "customer",
      text: "Customer",
      formatter: (cell, row) => {
        let fullname = "";
        if (row?.data?.job_schedule?.customer?.customers_details?.[0]?.type == 1) {
          fullname = row?.data?.job_schedule?.customer?.customers_details?.[0]?.company_name;
        } else {
          fullname =
          row?.data?.job_schedule?.customer?.customers_details?.[0]?.firstname +
            " " +
            row?.data?.job_schedule?.customer?.customers_details?.[0]?.lastname;
        }
        return <div>{fullname}</div>;
      },
    },
    {
      dataField: "statement_total",
      text: "Statement Total",
      formatter: moneyFormat,
    },
    {
      dataField: "payment_received",
      text: "Payment Received",
      formatter: moneyFormat,
    },
    {
      dataField: "remain_balance",
      text: "Statement Balance",
      formatter: (cell, row) => {
        if (row.payment_received <= 0 && cell <= 0) {
          return moneyFormat(row.statement_total)
        } else {
          return moneyFormat(cell)
        }
      }
    },
    {
      dataField: "payment_date",
      text: "Payment Date",
      formatter: (cell, row) => {
        if (cell) {
          return dateFormatter(cell)
        } else {
          return '-'
        }
      },
    },
    {
      dataField: "statement_status",
      text: "Status",
      formatter: (cell, row) => {
        if (cell == 1) {
          return (<div
            style={{
              background: "#dcabff",
            }}
            className="jobStatus"
          >
            Created
          </div>);
        } else if (cell == 2) {
          return (<div
            style={{
              background: "#ffa377",
            }}
            className="jobStatus"
          >
            Sent
          </div>);
        } else if (cell == 3) {
          return (<div
            style={{
              background: "#00c335",
            }}
            className="jobStatus"
          >
            Paid
          </div>);
        } else if (cell == 4) {
          return (<div
            style={{
              background: "#00c335",
            }}
            className="jobStatus"
          >
            Partial Paid
          </div>);
        } else if (cell == 5) {
          return (<div
            style={{
              background: "#6bbbff",
            }}
            className="jobStatus"
          >
            Printed
          </div>);
        }
      }
      // formatter: checkStatusColor,
    },
    // {
    //   dataField: "df1",
    //   isDummyField: true,
    //   text: " ",
    //   formatter: (cell, row) => {
    //     return <Menu row={row} />;
    //   },
    // },
  ];

  return {
    raisedInvoiceColumns,
    completedInvoiceColumns,
    invoicePaymentsColumns,
    invoiceCreditsColumns,
    invoiceStatementsColumns,
    invoiceStatementsReportsColumns
  };
}
