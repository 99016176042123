import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import afterPay from 'assets/images/afterpay-logo.png';
import autopay from 'assets/images/auto-pay.jpg';
// import gopay from 'assets/images/gopay.png';
import openpay from 'assets/images/openpay.png';
import humm from 'assets/images/Humm-logo.png';
import bottom_icon from 'assets/images/bnpl.png';
import zipmoney from 'assets/images/zip-money-logo.png';
import otherText from 'assets/images/Others-text.png';
import PayHead from './PayHead';
import { usePaymentInfo } from './PaymentInfoProvider';
import PayOptions from './PayOptions';
import Wrapped from './Wrapped';
import { useDispatch, useSelector } from 'react-redux';
import {
    GET_PAYMENT_PROCESSING_FEES
} from "store/chartOfAccount/actionTypes";
import { getWorkshopId } from 'utils/getWorkshopId';

const BNPLPayment = ({ onChangeClick, show, onClose }) => {
    
    const [selectOption, setSelectOption] = useState("");
    const { setRequest,request } = usePaymentInfo()

    const dispatch = useDispatch();

    const { paymentProcessing } = useSelector(
      (state) => state.ChartsOfAccountReducer
    );

    const loadPaymentProcessingFees = () => {
        dispatch({
            type: GET_PAYMENT_PROCESSING_FEES,
            payload: {
                get: "all",
                conditions: { 
                    workshop_id: getWorkshopId(),
                    card_payment: 0
                },
            },
        });
    };

    useEffect(() => {
        loadPaymentProcessingFees()
    },[])

    const [payoptions,setPayoptions] = useState([]);

    useEffect(() => {
        let cardData = [];
        if(paymentProcessing){
            paymentProcessing.map((card) => {
                if(card.payment_types == "Afterpay" || card.payment_types == "Humm" || card.payment_types == "Zip" || card.payment_types == "Others"){
                    let cardArr = {};
                    if(card.payment_types == "Afterpay"){
                        cardArr['img'] = afterPay;
                        setSelectedPaymentFees(card.processing_fees)
                        setRequest(p => {
                            return {
                                ...p,
                                payment_processing_fee:card.processing_fees
                            }
                        })
                    }else if(card.payment_types == "Humm"){
                        cardArr['img'] = humm;
                    }else if(card.payment_types == "Zip"){
                        cardArr['img'] = zipmoney;
                    }else if(card.payment_types == "Others"){
                        cardArr['img'] = otherText;
                    }
                    cardArr['value'] = card.payment_types;
                    cardArr['dbValue'] = card.id;
                    cardArr['processing_fees'] = card.processing_fees;
                    cardData.push(cardArr);
                }
            })
            setPayoptions(cardData)
        }
    },[paymentProcessing])

    const [selectedCard, setSelectedCard] = useState("Afterpay");
    const [selectedPaymentFees, setSelectedPaymentFees] = useState(0.00);
    
    return (
        <Wrapped 
            show={show} 
            onHide={onClose} 
            selectedCard={selectedCard || "Afterpay"} 
            selectedPaymentFees={selectedPaymentFees}
            >
            <div className="px-2">
                <PayHead onChangeClick={onChangeClick} image={bottom_icon} label="BNPL Payments" />
                <div>
                    <PayOptions activeSelection={selectOption} cards={payoptions} onClick={(value, index, dbValue,processing_fees) => {
                        setSelectOption(value)
                        setSelectedCard(value)
                        setRequest(p => {
                            return {
                                ...p,
                                payment_type: dbValue,
                                payment_processing_fee:processing_fees
                            }
                        })
                    }} />
                </div>
                <div className="row my-2">
                    <div className="col-5">
                        <label htmlFor="date">Date</label>
                        <DatePicker id='date' style={{ padding: ".375rem .75rem", fontSize: 16, lineHeight: 1.5, display: 'block' }} className='form-control' />
                    </div>
                    <div className="col-7">
                        <label htmlFor="note">Note (Optional)</label>
                        <input id='note' placeholder='e.g. Payment for entire order' className='form-control' />
                    </div>
                </div>
            </div>
        </Wrapped>
    )
}

export default BNPLPayment