import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { startPaymentReceipt,startStatementPayment } from "store/invoicePayment/actions";
import { showError } from "utils/alertMessage";
import makePayment from "../../../../assets/images/ic_make_payment.png";
import { getCurrency } from "../../../../utils/getCurrency";
import { usePaymentInfo } from "./PaymentInfoProvider";
import { payBtn } from "./styles.module.scss";

const RecordPayment = () => {
  let {
    paymentAmount,
    setPaymentAmount,
    payment_amount,
    request,
    disableInput,
    tax_invoice,
    invoiceData,
    remain_balance,
    invoice_type,
    customer_preview_invoice,
    insurance_preview_invoice,
    customer_invoice_total,
    insurance_invoice_total,
    customer_invoice_allocation_preview,
    customer_invoice_allocation_total,
    summary
  } = usePaymentInfo();

  const decimalRegex = /^\d*\.?\d*$/;
  const dispatch = useDispatch();

  if (tax_invoice === "4") {
    if (insurance_preview_invoice === 1) {
      paymentAmount = insurance_invoice_total;
      disableInput = true;
    }
    if (customer_preview_invoice === 1) {
      paymentAmount = customer_invoice_total;
      disableInput = true;
    }
    if (customer_invoice_allocation_preview === 1) {
      paymentAmount = customer_invoice_allocation_total;
      disableInput = true;
    }
  }
  
  if(request?.invoice_id?.length > 1 && request?.paymentType == "statementPayment"){
    disableInput = true;
  }

  useEffect(() => {
    if(paymentAmount){
      if(request?.invoice_id?.length == 1 && request?.paymentType == "statementPayment"){
        request.partial_payment = paymentAmount;
      }
    }
  },[paymentAmount,request])

  const [paymentAmountError, setPaymentAmountError] = useState(false);

  return (
    <div className="px-2">
      {paymentAmountError && <span style={{ color: "red" }}>This field is required.</span>}
      <div className="d-flex justify-content-between align-items-center">

        <label htmlFor="">Payment Amount</label>
        <input
          disabled={disableInput}
          style={{ width: "30%", backgroundColor: "transparent" }}
          type="text"
          value={`${getCurrency} ${paymentAmount}`}
          onChange={(e) => {
            if (e.target.value.includes(getCurrency)) {
              const [c, value] = e.target.value.split(getCurrency);
              let v = value.trim();
              if (+v > parseFloat(payment_amount)) {
                setPaymentAmount(payment_amount);
              } else {
                if (decimalRegex.test(v)) {
                  setPaymentAmount(v);
                }
              }
            } else {
              if (decimalRegex.test(e.target.value)) {
                setPaymentAmount(e.target.value);
              }
            }
          }}
          className="form-control"
        />

      </div>
      <div className={payBtn}>
        <button
          className="btn"
          onClick={() => {
            let payError = false;
            if (request.payment_method === '5') {
              if (request.cheque_number === "") {
                showError("Please enter cheque number.");
                payError = true;
              }
            }

            if (payError) {
              return;
            } else {
              if (!paymentAmountError) {
                if(request?.paymentType == "statementPayment"){
                  dispatch(startStatementPayment(request));
                }else{
                  dispatch(startPaymentReceipt(request));
                }
              }
            }
          }}
        >
          <img src={makePayment} alt="Make Payment" />
          Record Payment
          <span>
            {getCurrency}
            {request?.receipt_total || paymentAmount}
          </span>
        </button>
      </div>
    </div>
  );
};

export default RecordPayment;
