import React from 'react'
import { getCurrentTime } from "utils/timeZoneConvert";
import { useTime } from 'react-timer-hook';

const Timer = () => {
    const storedData = JSON.parse(sessionStorage.getItem('stored_data')); // Get session storage data
    let timezone = window.atob(storedData.timezone_lable); // workshop id
    const { seconds, minutes, hours } = useTime({ format: "24-hour" });
    var timerTime = hours+":"+minutes+":"+seconds;
    
    return (
        <>
            {getCurrentTime(timerTime,timezone)}
        </>
    )
}
export default Timer
