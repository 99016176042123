import {
  addInternalNoteAttachmentToLead,
  addInternalNoteToLead,
} from "helpers/backendHelper";
import moment from "moment";
import { getSessionData } from "utils/getWorkshopId";
import { utcToTimezone } from "utils/timeZoneConvert";

export async function addInternalNotes({ internalNoteData, attachments }) {
  const data = await addInternalNoteToLead({ payload: internalNoteData });

  const note = data.data?.OnlineScheduleInternalNotes;
  const imgs = [];
  const attachmentsWithId = attachments?.map((attachment) => {
    imgs.push({
      name: attachment.name,
      url: attachment.attachment_path,
    });
    return {
      ...attachment,
      online_schedule_internal_notes_id: note?.id,
    };
  });
  if (attachmentsWithId.length !== 0) {
    await addInternalNoteAttachmentToLead({
      payload: attachmentsWithId,
    });
  }

  return {
    id: note.id,
    images: imgs,
    date: moment(note.created_on).format("DD-MM-YYYY"),
    time: utcToTimezone(
      note.created_on,
      getSessionData("timezone_lable"),
      "",
      "hh:mm A"
    ),
    name: note?.Users?.firstname + " " + note?.Users?.lastname,
    content: note?.notes || "",
  };
}
