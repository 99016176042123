import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";
import { DatePicker } from "antd";
import BootstrapTable from "react-bootstrap-table-next";
import Accordion from 'react-bootstrap/Accordion';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";
import "./styles.scss";
import POCreateJob from "./poCreateJob";
import POCreateWearehouse from "./poCreateWearehouse";

import moment from "moment";
import { GET_STORAGE_DEVICES } from "store/storageDevices/actionTypes";
import { GET_SUPPLIER_DETAIL, GET_SUPPLIER_LIST } from "store/supplier/actionTypes";
import { GET_UNASSIGNED_JOBS } from "store/unassignedJobs/actionTypes";
import { GET_JOB_PART_LIST } from "store/jobParts/actionTypes";
import { FETCH_PO_JOB_WISE, PO_CREATE_JOB_WISE, PO_CREATE_JOB_WISE_SUCCESS } from "store/purchaseOrder/actionTypes";
import { GET_PART_LIST } from "store/parts/actionTypes";
import PartListProvider from "./PartListContext";
import { showError, showSuccess } from "utils/alertMessage";
import { CALLBACK_ARGUMENT, WAIT_FOR_ACTION } from "redux-wait-for-action";
import LoaderImage from "assets/images/page-loader.svg";
import { addInitialParts } from "store/parts/actions";

export const PartListContext = React.createContext(null);

const PurchasesCreate = () => {

    const todayDate = moment();
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let workshop_id = window.atob(storedData.workshop_id);
    const user_id = window.atob(storedData.user_id);
    const user_name = window.atob(storedData.user_name);
    const dateFormat = storedData.date_format;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currencySymbol = storedData.currency;

    const [createdFor, setCreatedFor] = useState("Warehouse");
    const [dueDate, setDueDate] = useState();
    const [storageList, setStorageList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [suppDetails, setSuppDetails] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [jobList, setJobList] = useState([]);
    const [supplierPartsData, setSupplierPartsData] = useState([]);
    const [selectedJobData, setSelectedJobData] = useState([]);
    const [allList, setAllList] = useState([]);

    const [jobID, setJobID] = useState();
    const [jobNumber, setJobNumber] = useState("");
    const [warehouseID, setWarehouseID] = useState();
    const [warehouseName, setWarehouseName] = useState();
    const [defaultWarehouseID, setDefaultWarehouseID] = useState();
    const [supplierID, setSupplierID] = useState();
    const [storageID, setStorageID] = useState("default");
    const [POSupplierData, setPOSupplierData] = useState();

    const [btnDisabled, setBtnDisabled] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const [stockSupplier, setStockSupplier] = useState(0);
    const [partDetails, setPartDetails] = useState(false);

    useEffect(() => {
        if (searchParams.get("id") !== null) {
            dispatch({
                type: GET_PART_LIST,
                payload: {
                    get: "all",
                    conditions: {
                        "Products.workshop_id": workshop_id,
                        "Products.is_deleted": "0",
                        "Products.id": searchParams.get("id"),
                        "Products.status": 1,
                    },
                    contain: ["Suppliers", "ProductCategory"],
                },
            })
            setPartDetails(true);
        }
    }, [searchParams.get("id")])

    function selectCreatedFor(createdType) {
        setCreatedFor(createdType);
        if (createdType === "Job") {
            loadJobList();
            let branches = [{
                value: "default",
                label: "Default"
            }];
            setBranchList(branches);
        }
    }

    const { storageDevicesList } = useSelector((state) => state.StorageDevicesReducer);

    // supplier reducer
    const { supplierListData, SupplierDetail, SupplierDetailLoading } = useSelector((state) => state.SupplierReducer);

    // job reducer
    const { jobData } = useSelector((state) => state.UnassignedJobsReducer);

    // Get parts data when create PO from stock
    const { partListData } = useSelector((state) => state.PartReducer);

    // jobPartsList reducer
    const { jobPartsList } = useSelector((state) => state.JobPartReducer);

    // jobPartsList reducer
    const { fetchPOJobData, fetchPOJobLoading, savePOLoading } = useSelector((state) => state.PurchaseOrderReducer);

    const [cancelValue, setCancelValue] = useState('/purchase-list');

    useEffect(() => {
        loadStroageData();
        loadSupplierList();
        loadJobList();
        if (searchParams.get("storage") !== null) {
            setCancelValue(`/manage_stock/${searchParams.get("storage")}`)
        }
    }, []);

    useEffect(() => {
        if (partListData !== null && partListData !== undefined && partDetails === true) {
            if (partListData?.[0]) {
                setStockSupplier(partListData?.[0]?.supplier?.id || 0)
                purchasesSupplier(partListData?.[0]?.supplier?.id || 0);

                const initialParts = partListData.map(
                    (part) => ({
                        label: part.name,
                        id: part.id,
                        main_id: part.id, // table main id
                        part_number: part.product_number,
                        part_name: part.name,
                        retail_price: part.cost_price,
                        qty: 0,
                        total: (+0 * +part.cost_price).toFixed(2),
                        more: part.description,
                        is_kit: part.product_type === 2 ? 1 : 0,
                        cost_price: part.cost_price,
                        margin_markup: part.margin,
                        sell_price: part.sell_price,
                        margin: 0,
                        is_gst: +part.gst_price > 0 ? 1 : 0,
                        gst_price: part.gst_price,
                        gst_percentage: "",
                        profit_price: part.profit_price,
                        is_misc_part: 0,
                        price: (+1 * +part.cost_price).toFixed(2),
                        purchase_tax_code_is_default: part.purchase_tax_code_is_default,
                        purchase_tax_code: part.purchase_tax_code
                    })
                );
                dispatch(addInitialParts(initialParts));
            }
            setPartDetails(false)
        }
    }, [partListData])

    //Load storage data
    function loadStroageData() {
        dispatch({
            type: GET_STORAGE_DEVICES,
            payload: {
                'get': 'all',
                'conditions': {
                    'workshop_id': workshop_id,
                    'is_deleted': 0
                }
            },
        });
    }

    //storage list use effect
    useEffect(() => {
        if (storageDevicesList !== null && storageDevicesList !== undefined) {
            let warehouses = [];
            const chargeList = storageDevicesList.data.StorageDevices;
            if (chargeList.length > 0) {

                chargeList.map((charges, index) => {

                    if (charges.id > 0) {
                        // if(index === 0) {
                        //   setWarehouseID(charges.id);
                        //   setWarehouseName(charges.name);
                        // }

                        if (charges.is_default === '1') {
                            setDefaultWarehouseID(charges.id);
                            setWarehouseID(charges.id);
                            setWarehouseName(charges.name);
                        }

                        if (searchParams.get("storage") !== null) {
                            if (charges.id == searchParams.get("storage")) {
                                setDefaultWarehouseID(charges.id);
                                setWarehouseID(charges.id);
                                setWarehouseName(charges.name);
                            }
                        }
                    }

                    warehouses.push({
                        value: charges.id,
                        label: charges.name
                    });

                });

                setStorageList(warehouses);
            }
        }
    }, [storageDevicesList]);



    // load supplier list function
    function loadSupplierList() {

        dispatch({
            type: GET_SUPPLIER_LIST,
            payload: {
                'get': 'all',
                'conditions': {
                    'workshop_id': workshop_id,
                    "is_deleted": 0,
                    "name NOT IN ": ["Repco Supplier", "Burson Supplier", "BNT Supplier"],
                },
                'order': {
                    'name': 'asc'
                }
            },
        });

    }

    // supplier list use effect
    useEffect(() => {

        if (supplierListData !== null && typeof supplierListData !== "undefined") {

            let suppliers = [];
            supplierListData.map((supp) => (
                suppliers.push({
                    value: supp.id,
                    label: supp.name
                })
            ));
            setSupplierList(suppliers);
        }


    }, [supplierListData]);


    function loadJobList() {

        dispatch({
            type: GET_JOB_PART_LIST,
            payload: {
                "get": "all",
                "conditions": {
                    "JobsParts.workshop_id": workshop_id,
                    "JobsParts.purchase_order_qty": 0,
                    "JobsParts.is_deleted": 0,
                    "JobSchedules.job_stage IN": ["Inprogress", "Bookin"],
                    "JobSchedules.is_copid_job": 0
                },
                "contain": {
                    "JobSchedules": {
                        "fields": [
                            "JobSchedules.id",
                            "JobSchedules.job_number",
                            "JobSchedules.job_status",
                            "JobSchedules.job_stage"
                        ]
                    }
                },
                "group": ["JobsParts.job_schedule_id"],
                "fields": [
                    "JobsParts.job_schedule_id",
                    "JobsParts.job_part_name",
                    "JobsParts.purchase_order_qty",
                    "JobsParts.id",
                    "JobsParts.is_deleted",
                    "JobsParts.workshop_id"
                ]
            },
        });


    }



    useEffect(() => {

        let jobs = [];
        if (jobPartsList) {

            jobPartsList.map((job) => (
                jobs.push({
                    job_id: job?.job_schedule?.id,
                    job_number: job?.job_schedule?.job_number,
                    value: job?.job_schedule?.id,
                    label: job?.job_schedule?.job_number + " - " + job?.job_schedule?.job_status
                })
            ));

            setJobList(jobs);

        }

    }, [jobPartsList]);


    // get supplier purchases details
    function purchasesSupplier(supplierID) {
        setBranchList([]);

        setSupplierID(supplierID);
        setStockSupplier(supplierID)

        dispatch({
            type: GET_SUPPLIER_DETAIL,
            payload: {
                'get': 'all',
                'conditions': {
                    'Suppliers.id': supplierID,
                    'Suppliers.workshop_id': workshop_id,
                    'Suppliers.status': 1
                },
                'contain': {
                    'Products': {
                        'conditions': {
                            'Products.status': 1
                        },
                    },
                    'SuppliersBranches': {
                        'conditions': {
                            'SuppliersBranches.status': 1
                        },
                    },
                    'SuppliersBranches.Countries': {
                        'conditions': {
                            'Countries.status': 1
                        },
                    },
                    'SuppliersBranches.States': {
                        'conditions': {
                            'States.status': 1
                        },
                    },
                    'Countries': {
                        'conditions': {
                            'Countries.status': 1
                        },
                    },
                    'States': {
                        'conditions': {
                            'States.status': 1
                        },
                    }
                }
            },
        });

    }


    // purchase supplier detail use effect
    useEffect(() => {
        if (SupplierDetail) {
            setSuppDetails(SupplierDetail[0]);
            var branchListData = SupplierDetail[0].suppliers_branches;
            let branches = [{
                value: "default",
                label: "Default"
            }];
            branchListData.map((supp) => (
                branches.push({
                    value: supp.id,
                    label: supp.branch_name
                })
            ));

            setBranchList(branches);

        }


    }, [SupplierDetail]);


    useEffect(() => {


    }, [branchList]);

    function JobPurchasesSupplier(job_data) {

        setSelectedJobData(job_data);

        setJobID(job_data.value);
        setJobNumber(job_data.job_number);

        dispatch({
            type: FETCH_PO_JOB_WISE,
            payload: {
                workshop_id: workshop_id,
                job_schedule_id: job_data.value
            },
        });
    }

    useEffect(() => {
        if (fetchPOJobData.code === 200) {
            if (fetchPOJobData.data.PurchaseOrders.length === 0) {
                showError("No parts found in this job #" + jobNumber);
                setSupplierPartsData([]);
            } else {
                setSupplierPartsData(fetchPOJobData.data.PurchaseOrders);
            }
        }
    }, [fetchPOJobData, jobNumber]);

    useEffect(() => {

        if (createdFor === "Job") {
            const updatedList = allList.filter(item => (item.sub_total > 0));
            if (allList.length > 0) {
                if (updatedList.length === allList.length) {
                    setBtnDisabled(false);
                } else {
                    setBtnDisabled(true);
                }
            }
        }

    }, [allList])


    const dateFormatter = (cell, row) => {
        var responseDate = moment(cell).format(dateFormat);
        return responseDate;
    };


    function updatedPOWarehouseData(data) {

        if (createdFor === "Warehouse") {
            if (data?.sub_total > 0) {
                setBtnDisabled(false);
            } else {
                setBtnDisabled(true);
            }
        }
        setPOSupplierData(data);
    }


    // create PO function
    function createPO(save_type) {

        if (createdFor === "Job") {

            allList.forEach((parts) => {

                parts.parts.forEach((partsss) => {

                    if (partsss.po_order_qty === 0 || typeof partsss.po_order_qty === "undefined") {
                        showError('Please enter qty more than 0');
                    }

                });

                //update purchase order part list
                const purchase_orders_parts_list = parts.parts.map((part) => (
                    {
                        product_id: part.id,
                        cost: part.cost_price,
                        qty: (part?.po_order_qty) ? part?.po_order_qty : 0,
                        qty_backorder: (part?.po_order_qty) ? part?.po_order_qty : 0,
                        qty_credit: (part?.po_order_qty) ? part?.po_order_qty : 0,
                        gst: part.po_part_gst,
                        price: part.po_total_price,
                        workshop_id: workshop_id,
                        job_id: jobID
                    }

                ));



                let data = {

                    workshop_id: workshop_id,
                    purchased_by: user_id,
                    job_id: parts?.job_id,
                    job_number: parts?.job_number,
                    po_status: 'open',
                    due_date: moment(dueDate).format('YYYY-MM-DD'),
                    supplier_id: parts?.id,
                    comments: parts?.delivery_comment,
                    sub_total: parseFloat(parts?.sub_total) + parseFloat(parts?.freight || 0),
                    gst: parseFloat(parts?.gst) + parseFloat(parts?.freight_gst || 0),
                    total: parts?.total_amount,
                    freight: parts?.freight,
                    suppliers_branches_id: parts?.suppliers_branches_id,
                    storage_device_id: defaultWarehouseID,
                    purchase_orders_parts: purchase_orders_parts_list

                }

                dispatch({
                    type: PO_CREATE_JOB_WISE,
                    payload: data,
                    [WAIT_FOR_ACTION]: PO_CREATE_JOB_WISE_SUCCESS,
                    [CALLBACK_ARGUMENT]: (action) => action.payload,
                }).then((createdPOData) => {
                    if (createdPOData.code === 200) {
                        showSuccess("Purchase order added successfully.");
                        if (save_type === "save_close") {
                            navigate(`/purchase-list`);
                        } else {
                            navigate(`/purchase-edit/` + createdPOData?.data?.PurchaseOrders?.id);
                        }
                    }
                });

            })

        }

        if (createdFor === "Warehouse") {

            if (POSupplierData?.purchase_orders_parts.length === 0) {
                showError("Please add at least one part");
                return false;
            }

            const purchase_orders_parts_list = POSupplierData?.purchase_orders_parts.map((part) => (
                {
                    product_id: part.id,
                    cost: part.cost_price,
                    qty: (part?.qty) ? part?.qty : 0,
                    // gst: part.gst_price,
                    gst: part?.po_part_gst,
                    // price: part.total,
                    price: +part.cost_price * + part?.qty || 0,
                    workshop_id: workshop_id,
                    qty_backorder: (part?.qty) ? part?.qty : 0,
                    qty_credit: (part?.qty) ? part?.qty : 0,
                    job_id: 0
                }
            ));

            let data = {
                workshop_id: workshop_id,
                purchased_by: user_id,
                job_id: 0,
                job_number: 0,
                po_status: "open",
                due_date: moment(dueDate).format('YYYY-MM-DD'),
                supplier_id: supplierID,
                comments: POSupplierData?.comments,
                sub_total: parseFloat(POSupplierData?.sub_total) + parseFloat(POSupplierData?.freight),
                total: POSupplierData?.total,
                gst: parseFloat(POSupplierData?.gst) + parseFloat(POSupplierData?.freightGST || 0),
                freight: POSupplierData?.freight,
                suppliers_branches_id: (storageID === "Default") ? 0 : storageID,
                storage_device_id: warehouseID,
                purchase_orders_parts: purchase_orders_parts_list
            }

            dispatch({
                type: PO_CREATE_JOB_WISE,
                payload: data,
                [WAIT_FOR_ACTION]: PO_CREATE_JOB_WISE_SUCCESS,
                [CALLBACK_ARGUMENT]: (action) => action.payload,
            }).then((createdPOData) => {
                if (createdPOData.code === 200) {
                    showSuccess("Purchase order added successfully.");
                    if (save_type === "save_close") {
                        navigate(`/purchase-list`);
                    } else {
                        navigate(`/purchase-edit/` + createdPOData?.data?.PurchaseOrders?.id);
                    }
                }
            });


        }

    }

    if (savePOLoading) {
        return <div className="page-loader d-flex align-center justify-center" >
            <img src={LoaderImage} alt="Loading"></img>
        </div>
    }

    return (
        <PartListProvider value={{ allList, setAllList }}>
            <div className="right-section-wrapper has-white-bg purchase-order-wrapper">
                <div className="purchase-order-heading d-flex justify-space-between align-center">
                    <h3>PURCHASE ORDER ~
                        {/* <span className="text-uppercase" > {createdFor} </span> */}
                        <span className="text-uppercase" > {(createdFor === "Job" ? createdFor + ' ' + jobNumber : warehouseName)} </span>
                    </h3>
                    <div className="btn-wrapper">
                        <Link to={cancelValue} >
                            <Button variant="outline-secondary" className="btn-outline rounded-btn height-34">
                                Cancel
                            </Button>
                        </Link>
                        <Button onClick={(e) => createPO('save')} className={"rounded-btn btn-success height-34 " + (btnDisabled ? "disabled" : "")} variant="success">
                            Save
                        </Button>
                        <Button onClick={(e) => createPO('save_close')} className={"btn btn-primary rounded-btn height-34 " + (btnDisabled ? "disabled" : "")}>
                            Save &amp; Close
                        </Button>
                    </div>
                </div>
                <div className="purchase-order-filter">
                    <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <span>PURCHASE ORDER</span>
                                <i className="la la-angle-down"></i>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="form-outer d-flex align-center mb-3">
                                    <div className="date-created width-33">
                                        <i className="fa fa-calendar text-primary font-size-20 m-r-5"></i>
                                        <span>Date Created :</span>
                                        <span>{dateFormatter(todayDate)}</span>
                                    </div>
                                    <Form.Group className="form-group date-picker d-flex width-33 align-center">
                                        <label>Due Date</label>
                                        <DatePicker className="form-control" readOnly
                                            defaultValue={moment(todayDate, dateFormat)}
                                            format={dateFormat}
                                            // onChange={setDueDate(moment(todayDate, dateFormat))}
                                            onChange={(value) => {
                                                setDueDate(value);
                                            }}
                                        />

                                    </Form.Group>
                                    <Form.Group className="form-group d-flex width-33 align-center">
                                        <div className="profile"><i className="fa fa-user"></i></div>
                                        <label>Created By : </label>
                                        <span>{user_name || ""}</span>
                                    </Form.Group>
                                </div>
                                <div className="radio-wrapper align-center mb-3">
                                    <label>Created For: </label>
                                    <div className="radio-block d-flex align-center">
                                        <Form.Check
                                            className="custom-check radio-btn secondary-radio"
                                            type="radio"
                                            id="Job"
                                        >
                                            <Form.Check.Input
                                                name="size"
                                                type="radio"
                                                defaultValue="Job"
                                                onChange={() => selectCreatedFor("Job")}
                                                checked={
                                                    createdFor === "Job" ? true : false
                                                }
                                            />
                                            <Form.Check.Label>
                                                <span className="check"></span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <div className={"select-wrapper form-group width-50 d-flex align-center " + (createdFor === "Warehouse" ? 'select2-disable' : '')} >
                                            <label>Job</label>
                                            <Form.Group className="form-group mx-0">
                                                <Select
                                                    className="form-control custom-select"
                                                    required
                                                    onChange={(e) => JobPurchasesSupplier(e)}
                                                    options={jobList}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="radio-block d-flex align-center">
                                        <Form.Check
                                            className="custom-check radio-btn secondary-radio"
                                            type="radio"
                                            id="Warehouse"
                                        >
                                            <Form.Check.Input
                                                name="size"
                                                type="radio"
                                                onChange={() => selectCreatedFor("Warehouse")}
                                                checked={
                                                    createdFor === "Warehouse" ? true : false
                                                }
                                            />
                                            <Form.Check.Label>
                                                <span className="check"></span>
                                            </Form.Check.Label>
                                        </Form.Check>

                                        <div className={"select-wrapper form-group width-50 d-flex align-center " + (createdFor === "Job" ? 'select2-disable' : '')} >
                                            <label>Warehouse</label>
                                            <Form.Group className="form-group mx-0">
                                                <Form.Select aria-label="Default select example"
                                                    onChange={(e) => {
                                                        setWarehouseID(e.target.value)
                                                        setWarehouseName(e.target.options[e.target.selectedIndex].text)
                                                    }} >
                                                    {
                                                        (storageList !== null && storageList !== undefined) ?
                                                            storageList.map((storage) => {
                                                                return <option
                                                                    key={storage.value}
                                                                    value={storage.value}
                                                                    selected={
                                                                        searchParams.get("storage") && searchParams.get("storage") == storage.value ? true : false
                                                                    }
                                                                >{storage.label}</option>
                                                            })
                                                            : ''
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-outer form-align-start">
                                    <div className={"select-wrapper form-group width-50 " + (createdFor === "Job" ? 'select2-disable' : '')}>
                                        <Form.Group className="form-group mx-0">
                                            <label>Supplier</label>
                                            <Select
                                                disabled={createdFor === "Job" ? true : false}
                                                className="form-control custom-select"
                                                required
                                                onChange={(e) => purchasesSupplier(e.value)}
                                                value={
                                                    supplierList.filter(option =>
                                                        option.value == stockSupplier)
                                                }
                                                options={supplierList}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* <div className="form-group custom-normal-select d-flex align-center mb-3 width-50"> */}
                                    <div className="select-wrapper form-group width-50 ">
                                        <label>Branch Name</label>
                                        <Form.Group className="form-group mx-0">
                                            {/* <Select
                          placeholder="Select"
                          value="default"
                          className="form-control custom-select"
                          required
                          options={branchList}
                          onChange={(e) => setStorageID(e.value) }
                        /> */}
                                            <Form.Select aria-label="Default select example" onChange={(e) => setStorageID(e.target.value)} >
                                                {
                                                    (branchList !== null && branchList !== undefined) ?
                                                        branchList.map((branch) => {
                                                            return <option key={branch.value} value={branch.value} >{branch.label}</option>
                                                        })
                                                        : ''
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {createdFor === "Job" && supplierPartsData && (
                        <POCreateJob
                            jobID={jobID}
                            supplierPartsData={supplierPartsData}
                            fetchPOJobLoading={fetchPOJobLoading}
                            selectedJobData={selectedJobData}
                        />
                    )}
                    {createdFor === "Warehouse" && (
                        <POCreateWearehouse
                            suppDetails={suppDetails}
                            warehouseID={warehouseID}
                            supplierID={supplierID || 0}
                            storageID={storageID}
                            SupplierDetailLoading={SupplierDetailLoading}
                            updatedPOWarehouseData={updatedPOWarehouseData}
                        />
                    )}
                </div>
            </div>
        </PartListProvider>
    );
};

export default PurchasesCreate;