import React, { useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import check from "../../../src/assets/images/tick-mark.png";
import { useNavigate } from "react-router-dom";

const InspectionNotification = ({ 
    inspectionShow,
    handleInspectionClose,
    handleInspectionShow,
    inspectionNotificationData
}) => {
    
    const navigate = useNavigate(); // Navigate URL

    const viewJob = (createdJobId) => {
        navigate("/job_detail/" + createdJobId + "#inspections");
        handleInspectionClose();
    }
    
    return (
        <>
            {/* Inspection approved dialog */}
            <Modal
                className="inspection-dialog"
                size="sm"
                show={inspectionShow}
                onHide={handleInspectionClose}
            >
                <Modal.Body>
                    <div className="d-flex justify-flex-end">
                        <i onClick={handleInspectionClose} class="la la-times font-size-20 close-icon mb-2"></i>
                    </div>
                    <div className="content-block">
                        <div className="heading-block d-flex align-center justify-center mb-2">
                            <h4>Inspection Approved</h4>
                            <img className="check-icon" src={check}></img>
                        </div>
                        <p className="mb-2">Rego ~ {inspectionNotificationData.rego} {inspectionNotificationData.make}</p>
                        <p className="mb-2">Service inspection Checklist # <b>{inspectionNotificationData.insp_id}</b></p>
                        <p className="mb-2">Approved By <b>{inspectionNotificationData.approve_name}</b></p>
                    </div>
                    <div className="d-flex justify-center mt-3">
                        <Button
                            className="btn-outline rounded-btn height-34"
                            variant="outline"
                            onClick={() => {viewJob(inspectionNotificationData.job_id)}}
                        >
                            View
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}
export default InspectionNotification;