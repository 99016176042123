export const EDIT_THIRD_PARTY_COMPANY = "EDIT_THIRD_PARTY_COMPANY";
export const EDIT_THIRD_PARTY_COMPANY_SUCCESS = "EDIT_THIRD_PARTY_COMPANY_SUCCESS";
export const EDIT_THIRD_PARTY_COMPANY_FAIL = "EDIT_THIRD_PARTY_COMPANY_FAIL";

export const UPDATE_THIRD_PARTY_COMPANY_DETAILS = "UPDATE_THIRD_PARTY_COMPANY_DETAILS";
export const UPDATE_THIRD_PARTY_COMPANY_DETAILS_SUCCESS = "UPDATE_THIRD_PARTY_COMPANY_DETAILS_SUCCESS";
export const UPDATE_THIRD_PARTY_COMPANY_DETAILS_FAIL = "UPDATE_THIRD_PARTY_COMPANY_DETAILS_FAIL";

export const ADD_THIRD_PARTY_COMPANY_DETAILS = "ADD_THIRD_PARTY_COMPANY_DETAILS";
export const ADD_THIRD_PARTY_COMPANY_DETAILS_SUCCESS = "ADD_THIRD_PARTY_COMPANY_DETAILS_SUCCESS";
export const ADD_THIRD_PARTY_COMPANY_DETAILS_FAIL = "ADD_THIRD_PARTY_COMPANY_DETAILS_FAIL";
