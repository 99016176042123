import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_UNASSIGNED_JOBS,
  GET_CUSTOMERS_LIST,
  GET_CUSTOMERANDVEHICLE_DETAILS,
  CREATE_JOB,
  REGO_EXIST,
  GET_UNASSIGNED_JOBS_COUNT,
  CLEAN_DATA,
} from "./actionTypes";
import {
  getUnassignedJobsSuccess,
  getUnassignedJobsFail,
  getCustomerListFail,
  getCustomerListSuccess,
  getCustomerAndVehicleDetailsFail,
  getCustomerAndVehicleDetailsSuccess,
  createJobSuccess,
  createJobFail,
  regoExistSuccess,
  regoExistFail,
  getUnassignedJobsCountSuccess,
  getUnassignedJobsCountFail,
} from "./actions";

import {
  checkVinExistSuccess,
  checkChasisExistSuccess,
  checkEngineExistSuccess,
  checkFleetExistSuccess,
} from "../assets/actions";

import { checkEmialExistSuccess } from "../userProfile/actions";

import {
  getvehicleSeriesSuccess,
  getvehicleModelSuccess,
} from "../commonApiStore/actions";

import {
  checkPhoneExistSuccess,
  checkMobileExistSuccess,
} from "../contacts/actions";

import {
  getCustomerAndVehicleDetails,
  getCustomerLists,
  getUnassignedJobs,
  createJob,
} from "../../helpers/backendHelper";

function* onGetUnassignedJobs(data) {
  try {
    const response = yield call(getUnassignedJobs, data);
    if (response) {
      if (response.code === 200) {
        yield put(getUnassignedJobsSuccess(response.data.JobSchedules));
      } else {
        yield put(getUnassignedJobsFail(response.message));
      }
    } else {
      yield put(getUnassignedJobsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getUnassignedJobsFail(error.response));
  }
}

function* onGetCustomersList(data) {
  try {
    const response = yield call(getCustomerLists, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCustomerListSuccess(response.data.CustomersDetails));
      } else {
        yield put(getCustomerListFail(response.message));
      }
    } else {
      yield put(getCustomerListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerListFail(error.response));
  }
}

function* onGetCustomerAndVehicleDetails(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCustomerAndVehicleDetailsSuccess(response.data.Vehicles));
      } else {
        yield put(getCustomerAndVehicleDetailsFail(response.message));
      }
    } else {
      yield put(getCustomerAndVehicleDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomerAndVehicleDetailsFail(error.response));
  }
}

function* onCreateJob(data) {
  try {
    const response = yield call(createJob, data);
    if (response) {
      if (response.code === 200 || response.code === 400) {
        yield put(createJobSuccess(response));
      } else {
        yield put(createJobFail(response.message));
      }
    } else {
      yield put(createJobFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(createJobFail(error.response));
  }
}

function* onReogExist(data) {
  try {
    const response = yield call(getCustomerAndVehicleDetails, data);

    if (response) {
      if (response.code === 200 || response.code === 400) {
        yield put(
          regoExistSuccess(
            response.message == "No records found..!!" ? false : true
          )
        );
      } else {
        yield put(regoExistFail(response.message));
      }
    } else {
      yield put(regoExistFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(regoExistFail(error.response));
  }
}

function* onGetUnassignedJobsCount(data) {
  try {
    const response = yield call(getUnassignedJobs, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getUnassignedJobsCountSuccess(response.data.JobSchedules[0].COUNT)
        );
      } else {
        yield put(getUnassignedJobsCountFail(response.message));
      }
    } else {
      yield put(getUnassignedJobsCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getUnassignedJobsCountFail(error.response));
  }
}

function* onCleanData() {
  yield put(checkVinExistSuccess(false));
  yield put(checkChasisExistSuccess(false));
  yield put(checkEngineExistSuccess(false));
  yield put(checkFleetExistSuccess(false));
  yield put(checkEmialExistSuccess(false));
  yield put(checkPhoneExistSuccess(false));
  yield put(checkMobileExistSuccess(false));
  yield put(regoExistSuccess(false));
  yield put(getvehicleSeriesSuccess([]));
  yield put(getvehicleModelSuccess([]));
}

function* UnassignedJobsSaga() {
  yield takeLatest(GET_UNASSIGNED_JOBS, onGetUnassignedJobs);
  yield takeLatest(GET_CUSTOMERS_LIST, onGetCustomersList);
  yield takeLatest(
    GET_CUSTOMERANDVEHICLE_DETAILS,
    onGetCustomerAndVehicleDetails
  );
  yield takeLatest(CREATE_JOB, onCreateJob);
  yield takeLatest(REGO_EXIST, onReogExist);
  yield takeLatest(GET_UNASSIGNED_JOBS_COUNT, onGetUnassignedJobsCount);
  yield takeLatest(CLEAN_DATA, onCleanData);
}

export default UnassignedJobsSaga;
