export const GET_UPCOMING_LIST = "GET_UPCOMING_LIST";
export const GET_UPCOMING_LIST_SUCCESS = "GET_UPCOMING_LIST_SUCCESS";
export const GET_UPCOMING_LIST_FAIL = "GET_UPCOMING_LIST_FAIL";

export const GET_OVERDUE_LIST = "GET_OVERDUE_LIST";
export const GET_OVERDUE_LIST_SUCCESS = "GET_OVERDUE_LIST_SUCCESS";
export const GET_OVERDUE_LIST_FAIL = "GET_OVERDUE_LIST_FAIL";

export const GET_SERVICEREMINDER_HISTORY = "GET_SERVICEREMINDER_HISTORY";
export const GET_SERVICEREMINDER_HISTORY_SUCCESS =
  "GET_SERVICEREMINDER_HISTORY_SUCCESS";
export const GET_SERVICEREMINDER_HISTORY_FAIL =
  "GET_SERVICEREMINDER_HISTORY_FAIL";

export const GET_SENTVIEW_LIST = "GET_SENTVIEW_LIST";
export const GET_SENTVIEW_LIST_SUCCESS = "GET_SENTVIEW_LIST_SUCCESS";
export const GET_SENTVIEW_LIST_FAIL = "GET_SENTVIEW_LIST_FAIL";

export const GET_INSPECTION_UPCOMING_LIST = "GET_INSPECTION_UPCOMING_LIST";
export const GET_INSPECTION_UPCOMING_LIST_SUCCESS =
  "GET_INSPECTION_UPCOMING_LIST_SUCCESS";
export const GET_INSPECTION_UPCOMING_LIST_FAIL =
  "GET_INSPECTION_UPCOMING_LIST_FAIL";

export const GET_INSPECTION_OVERDUE_LIST = "GET_INSPECTION_OVERDUE_LIST";
export const GET_INSPECTION_OVERDUE_LIST_SUCCESS =
  "GET_INSPECTION_OVERDUE_LIST_SUCCESS";
export const GET_INSPECTION_OVERDUE_LIST_FAIL =
  "GET_INSPECTION_OVERDUE_LIST_FAIL";

export const GET_INSPECTIONREMINDER_HISTORY = "GET_INSPECTIONREMINDER_HISTORY";
export const GET_INSPECTIONREMINDER_HISTORY_SUCCESS =
  "GET_INSPECTIONREMINDER_HISTORY_SUCCESS";
export const GET_INSPECTIONREMINDER_HISTORY_FAIL =
  "GET_INSPECTIONREMINDER_HISTORY_FAIL";

export const GET_VEHICLE_UPCOMING_LIST = "GET_VEHICLE_UPCOMING_LIST";
export const GET_VEHICLE_UPCOMING_LIST_SUCCESS =
  "GET_VEHICLE_UPCOMING_LIST_SUCCESS";
export const GET_VEHICLE_UPCOMING_LIST_FAIL = "GET_VEHICLE_UPCOMING_LIST_FAIL";

export const GET_VEHICLE_OVERDUE_LIST = "GET_VEHICLE_OVERDUE_LIST";
export const GET_VEHICLE_OVERDUE_LIST_SUCCESS =
  "GET_VEHICLE_OVERDUE_LIST_SUCCESS";
export const GET_VEHICLE_OVERDUE_LIST_FAIL = "GET_VEHICLE_OVERDUE_LIST_FAIL";

export const GET_VEHICLEREMINDER_HISTORY = "GET_VEHICLEREMINDER_HISTORY";
export const GET_VEHICLEREMINDER_HISTORY_SUCCESS =
  "GET_VEHICLEREMINDER_HISTORY_SUCCESS";
export const GET_VEHICLEREMINDER_HISTORY_FAIL =
  "GET_VEHICLEREMINDER_HISTORY_FAIL";

export const GET_VEHICLE_LIST = "GET_VEHICLE_LIST";
export const GET_VEHICLE_LIST_SUCCESS = "GET_VEHICLE_LIST_SUCCESS";
export const GET_VEHICLE_LIST_FAIL = "GET_VEHICLE_LIST_FAIL";

export const GET_VEHICLES_COUNT = "GET_VEHICLES_COUNT";
export const GET_VEHICLES_COUNT_SUCCESS = "GET_VEHICLES_COUNT_SUCCESS";
export const GET_VEHICLES_COUNT_FAIL = "GET_VEHICLES_COUNT_FAIL";

export const GET_CARAVAN_LIST = "GET_CARAVAN_LIST";
export const GET_CARAVAN_LIST_SUCCESS = "GET_CARAVAN_LIST_SUCCESS";
export const GET_CARAVAN_LIST_FAIL = "GET_CARAVAN_LIST_FAIL";

export const GET_MARINE_LIST = "GET_MARINE_LIST";
export const GET_MARINE_LIST_SUCCESS = "GET_MARINE_LIST_SUCCESS";
export const GET_MARINE_LIST_FAIL = "GET_MARINE_LIST_FAIL";

export const GET_TRUCK_LIST = "GET_TRUCK_LIST";
export const GET_TRUCK_LIST_SUCCESS = "GET_TRUCK_LIST_SUCCESS";
export const GET_TRUCK_LIST_FAIL = "GET_TRUCK_LIST_FAIL";

export const GET_TRAILER_LIST = "GET_TRAILER_LIST";
export const GET_TRAILER_LIST_SUCCESS = "GET_TRAILER_LIST_SUCCESS";
export const GET_TRAILER_LIST_FAIL = "GET_TRAILER_LIST_FAIL";

export const GET_MOTORBIKE_LIST = "GET_MOTORBIKE_LIST";
export const GET_MOTORBIKE_LIST_SUCCESS = "GET_MOTORBIKE_LIST_SUCCESS";
export const GET_MOTORBIKE_LIST_FAIL = "GET_MOTORBIKE_LIST_FAIL";

export const GET_MACHINE_LIST = "GET_MACHINE_LIST";
export const GET_MACHINE_LIST_SUCCESS = "GET_MACHINE_LIST_SUCCESS";
export const GET_MACHINE_LIST_FAIL = "GET_MACHINE_LIST_FAIL";

export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS";
export const GET_CUSTOMER_LIST_FAIL = "GET_CUSTOMER_LIST_FAIL";

export const REGOEXIST = "REGOEXIST";
export const REGOEXIST_SUCCESS = "REGOEXIST_SUCCESS";
export const REGOEXIST_FAIL = "REGOEXIST_FAIL";

export const HINEXIST = "HINEXIST";
export const HINEXIST_SUCCESS = "HINEXIST_SUCCESS";
export const HINEXIST_FAIL = "HINEXIST_FAIL";

export const GET_VEHICLE_DETAIL = "GET_VEHICLE_DETAIL";
export const GET_VEHICLE_DETAIL_SUCCESS = "GET_VEHICLE_DETAIL_SUCCESS";
export const GET_VEHICLE_DETAIL_FAIL = "GET_VEHICLE_DETAIL_FAIL";

export const GET_VEHICLE_LEGACY_HISTORY = "GET_VEHICLE_LEGACY_HISTORY";
export const GET_VEHICLE_LEGACY_HISTORY_SUCCESS = "GET_VEHICLE_LEGACY_HISTORY_SUCCESS";
export const GET_VEHICLE_LEGACY_HISTORY_FAIL = "GET_VEHICLE_LEGACY_HISTORY_FAIL";

export const GET_JOB_LIST = "GET_JOB_LIST";
export const GET_JOB_LIST_SUCCESS = "GET_JOB_LIST_SUCCESS";
export const GET_JOB_LIST_FAIL = "GET_JOB_LIST_FAIL";

export const GET_ACTIVE_ASSETS = "GET_ACTIVE_ASSETS";
export const GET_ACTIVE_ASSETS_SUCCESS = "GET_ACTIVE_ASSETS_SUCCESS";
export const GET_ACTIVE_ASSETS_FAIL = "GET_ACTIVE_ASSETS_FAIL";

export const SEARCH_VEHICLE = "SEARCH_VEHICLE";
export const SEARCH_VEHICLE_SUCCESS = "SEARCH_VEHICLE_SUCCESS";
export const SEARCH_VEHICLE_FAIL = "SEARCH_VEHICLE_FAIL";
