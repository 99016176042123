import {
  GET_UPCOMING_LIST,
  GET_UPCOMING_LIST_SUCCESS,
  GET_UPCOMING_LIST_FAIL,
  GET_OVERDUE_LIST,
  GET_OVERDUE_LIST_SUCCESS,
  GET_OVERDUE_LIST_FAIL,
  GET_SERVICEREMINDER_HISTORY,
  GET_SERVICEREMINDER_HISTORY_SUCCESS,
  GET_SERVICEREMINDER_HISTORY_FAIL,
  GET_SENTVIEW_LIST,
  GET_SENTVIEW_LIST_SUCCESS,
  GET_SENTVIEW_LIST_FAIL,
  GET_INSPECTION_UPCOMING_LIST,
  GET_INSPECTION_UPCOMING_LIST_SUCCESS,
  GET_INSPECTION_UPCOMING_LIST_FAIL,
  GET_INSPECTION_OVERDUE_LIST,
  GET_INSPECTION_OVERDUE_LIST_SUCCESS,
  GET_INSPECTION_OVERDUE_LIST_FAIL,
  GET_INSPECTIONREMINDER_HISTORY,
  GET_INSPECTIONREMINDER_HISTORY_SUCCESS,
  GET_INSPECTIONREMINDER_HISTORY_FAIL,
  GET_VEHICLE_UPCOMING_LIST,
  GET_VEHICLE_UPCOMING_LIST_SUCCESS,
  GET_VEHICLE_UPCOMING_LIST_FAIL,
  GET_VEHICLE_OVERDUE_LIST,
  GET_VEHICLE_OVERDUE_LIST_SUCCESS,
  GET_VEHICLE_OVERDUE_LIST_FAIL,
  GET_VEHICLEREMINDER_HISTORY,
  GET_VEHICLEREMINDER_HISTORY_SUCCESS,
  GET_VEHICLEREMINDER_HISTORY_FAIL,
  GET_VEHICLE_LIST,
  GET_VEHICLE_LIST_SUCCESS,
  GET_VEHICLE_LIST_FAIL,
  GET_VEHICLES_COUNT,
  GET_VEHICLES_COUNT_SUCCESS,
  GET_VEHICLES_COUNT_FAIL,
  GET_CARAVAN_LIST,
  GET_CARAVAN_LIST_SUCCESS,
  GET_CARAVAN_LIST_FAIL,
  GET_MARINE_LIST,
  GET_MARINE_LIST_SUCCESS,
  GET_MARINE_LIST_FAIL,
  GET_TRUCK_LIST,
  GET_TRUCK_LIST_SUCCESS,
  GET_TRUCK_LIST_FAIL,
  GET_TRAILER_LIST,
  GET_TRAILER_LIST_SUCCESS,
  GET_TRAILER_LIST_FAIL,
  GET_MOTORBIKE_LIST,
  GET_MOTORBIKE_LIST_SUCCESS,
  GET_MOTORBIKE_LIST_FAIL,
  GET_MACHINE_LIST,
  GET_MACHINE_LIST_SUCCESS,
  GET_MACHINE_LIST_FAIL,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_FAIL,
  REGOEXIST,
  REGOEXIST_SUCCESS,
  REGOEXIST_FAIL,
  HINEXIST,
  HINEXIST_SUCCESS,
  HINEXIST_FAIL,
  GET_VEHICLE_DETAIL,
  GET_VEHICLE_DETAIL_SUCCESS,
  GET_VEHICLE_DETAIL_FAIL,
  GET_VEHICLE_LEGACY_HISTORY,
  GET_VEHICLE_LEGACY_HISTORY_SUCCESS,
  GET_VEHICLE_LEGACY_HISTORY_FAIL,
  GET_JOB_LIST,
  GET_JOB_LIST_SUCCESS,
  GET_JOB_LIST_FAIL,
  GET_ACTIVE_ASSETS,
  GET_ACTIVE_ASSETS_SUCCESS,
  GET_ACTIVE_ASSETS_FAIL,
  SEARCH_VEHICLE,
  SEARCH_VEHICLE_SUCCESS,
  SEARCH_VEHICLE_FAIL
} from "./actionTypes";

const initialState = {
  loading: false,
  message: "",
  error: "",
  upcoming: [],
  overDue: [],
  serviceReminderHistory: [],
  sentStatusViewList: [],
  inspectionUpcoming: [],
  inspectionOverDue: [],
  inspectionReminderHistory: [],
  vehicleUpcoming: [],
  vehicleOverDue: [],
  vehicleLegacyHistory: [],
  vehicleReminderHistory: [],
  vehicles: [],
  caravan: [],
  marine: [],
  truck: [],
  trailer: [],
  motorBike: [],
  machine: [],
  customerList: [],
  regoExist: false,
  hinExist: false,
  vehicleDetail: [],
  jobList: [],
  vehicleCount: null,
  activeAssets: null,
  searchVehicle: [],
  vehicleLegacyHistory: [],
};

const List = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPCOMING_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_UPCOMING_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        upcoming: action.payload,
      };
      break;
    case GET_UPCOMING_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        upcoming: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_OVERDUE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_OVERDUE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        overDue: action.payload,
      };
      break;
    case GET_OVERDUE_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        overDue: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_SERVICEREMINDER_HISTORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SERVICEREMINDER_HISTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        serviceReminderHistory: action.payload,
      };
      break;
    case GET_SERVICEREMINDER_HISTORY_FAIL:
      state = {
        ...state,
        loading: false,
        serviceReminderHistory: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_SENTVIEW_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SENTVIEW_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        sentStatusViewList: action.payload,
      };
      break;
    case GET_SENTVIEW_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        sentStatusViewList: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_INSPECTION_UPCOMING_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_INSPECTION_UPCOMING_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        inspectionUpcoming: action.payload,
      };
      break;
    case GET_INSPECTION_UPCOMING_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        inspectionUpcoming: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_INSPECTION_OVERDUE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_INSPECTION_OVERDUE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        inspectionOverDue: action.payload,
      };
      break;
    case GET_INSPECTION_OVERDUE_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        inspectionOverDue: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_INSPECTIONREMINDER_HISTORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_INSPECTIONREMINDER_HISTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        inspectionReminderHistory: action.payload,
      };
      break;
    case GET_INSPECTIONREMINDER_HISTORY_FAIL:
      state = {
        ...state,
        loading: false,
        inspectionReminderHistory: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_VEHICLE_UPCOMING_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VEHICLE_UPCOMING_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        vehicleUpcoming: action.payload,
      };
      break;
    case GET_VEHICLE_UPCOMING_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        vehicleUpcoming: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_VEHICLE_OVERDUE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VEHICLE_OVERDUE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        vehicleOverDue: action.payload,
      };
      break;
    case GET_VEHICLE_OVERDUE_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        vehicleOverDue: [],
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_VEHICLEREMINDER_HISTORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VEHICLEREMINDER_HISTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        vehicleReminderHistory: action.payload,
      };
      break;
    case GET_VEHICLEREMINDER_HISTORY_FAIL:
      state = {
        ...state,
        loading: false,
        vehicleReminderHistory: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_VEHICLE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VEHICLE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        vehicles: action.payload,
      };
      break;
    case GET_VEHICLE_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        vehicles: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_VEHICLES_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VEHICLES_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        vehicleCount: action.payload,
      };
      break;
    case GET_VEHICLES_COUNT_FAIL:
      state = {
        ...state,
        loading: false,
        vehicleCount: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_CARAVAN_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CARAVAN_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        caravan: action.payload,
      };
      break;
    case GET_CARAVAN_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        caravan: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_MARINE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_MARINE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        marine: action.payload,
      };
      break;
    case GET_MARINE_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        marine: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_TRUCK_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_TRUCK_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        truck: action.payload,
      };
      break;
    case GET_TRUCK_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        truck: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_TRAILER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_TRAILER_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        trailer: action.payload,
      };
      break;
    case GET_TRAILER_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        trailer: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_MOTORBIKE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_MOTORBIKE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        motorBike: action.payload,
      };
      break;
    case GET_MOTORBIKE_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        motorBike: [],
        error: {
          message: action.payload,
        },
      };
      break;


    case GET_MACHINE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_MACHINE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        machine: action.payload,
      };
      break;
    case GET_MACHINE_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        machine: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_CUSTOMER_LIST:
      state = {
        ...state,
      };
      break;
    case GET_CUSTOMER_LIST_SUCCESS:
      state = {
        ...state,
        customerList: action.payload,
      };
      break;
    case GET_CUSTOMER_LIST_FAIL:
      state = {
        ...state,
        customerList: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case REGOEXIST:
      state = {
        ...state,
      };
      break;
    case REGOEXIST_SUCCESS:
      state = {
        ...state,
        regoExist: action.payload,
      };
      break;
    case REGOEXIST_FAIL:
      state = {
        ...state,
        regoExist: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case HINEXIST:
      state = {
        ...state,
      };
      break;
    case HINEXIST_SUCCESS:
      state = {
        ...state,
        hinExist: action.payload,
      };
      break;
    case HINEXIST_FAIL:
      state = {
        ...state,
        hinExist: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_VEHICLE_DETAIL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_VEHICLE_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        vehicleDetail: action.payload,
      };
      break;
    case GET_VEHICLE_DETAIL_FAIL:
      state = {
        ...state,
        loading: false,
        vehicleDetail: [],
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_VEHICLE_LEGACY_HISTORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VEHICLE_LEGACY_HISTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        vehicleLegacyHistory: action.payload ?? [],
      };
      break;
    case GET_VEHICLE_LEGACY_HISTORY_FAIL:
      state = {
        ...state,
        loading: false,
        vehicleLegacyHistory: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_JOB_LIST:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_JOB_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        jobList: action.payload,
      };
      break;
    case GET_JOB_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        jobList: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_ACTIVE_ASSETS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ACTIVE_ASSETS_SUCCESS:
      state = {
        ...state,
        loading: false,
        activeAssets: action.payload,
      };
      break;
    case GET_ACTIVE_ASSETS_FAIL:
      state = {
        ...state,
        loading: false,
        activeAssets: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case SEARCH_VEHICLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SEARCH_VEHICLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        searchVehicle: action.payload,
      };
      break;
    case SEARCH_VEHICLE_FAIL:
      state = {
        ...state,
        loading: false,
        searchVehicle: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_VEHICLE_LEGACY_HISTORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VEHICLE_LEGACY_HISTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        vehicleLegacyHistory: action.payload ?? [],
      };
      break;
    case GET_VEHICLE_LEGACY_HISTORY_FAIL:
      state = {
        ...state,
        loading: false,
        vehicleLegacyHistory: [],
        error: {
          message: action.payload,
        },
      };


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default List;
