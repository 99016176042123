import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";

import useClickOutside from "../../../../utils/useClickOutside";

export const PopoverPicker = ({ color, setColor, rowId }) => {

  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className="picker">
      {/* <div
        className="swatch"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      /> */}
      <div className="d-flex input-group">
        <span className="color-thumb input-group-text">
          <span style={{ background: color }} />
        </span>
        <input
          type="text"
          className="form-control"
          value={color}
          onBlur={(e) => {
            rowId ? setColor(e.target.value, rowId) : setColor(e.target.value);
          }}
          onFocus={() => toggle(true)}
        />
        {isOpen && (
          <div className="popover top-100" ref={popover}>
            <HexColorPicker
              color={color}
              onChange={(e) => (rowId ? setColor(e, rowId) : setColor(e))}
            />
          </div>
        )}
      </div>
    </div>
  );
};
