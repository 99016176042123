import moment from "moment";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { downloadFileFromS3 } from "utils/uploadFileToS3";
import CarMakeLogo from "views/common/UI/CarMakeLogo";
import ColorStatus from "./colorStatus";

let DefaultTechnicianList = ({ techician, techCounter, jobData,
    statusColor, fromDate, setJobData
}) => {


    let avatarPath = window.location.origin + "/Avatar/";
    var techProfile = avatarPath + "1.png";

    if (techician?.user_profile !== "" && techician?.user_profile !== undefined && techician?.user_profile !== "bay" && techician?.id !== "z") {
        let user_profile_name = techician?.user_profile.split('/');
        if (user_profile_name[0] === 'user_profile') {
            techProfile = downloadFileFromS3(techician?.user_profile);
        }
        else {
            techProfile = avatarPath + techician?.user_profile;
        }
    }






    return (
        <>
            <div className="col-4 " style={{ padding: "0px 15px 0px 15px" }} >
                <div className="job-outer-clocking">

                    {/* first row */}
                    <div className="technician-detail row text-center" style={{ alignItems: "center" }} >
                        {/* Technician Detail */}
                        <div className="col-md-7">
                            <div className={"job-resource-box "}>
                                <div className="job-resource-profile">
                                    <img className="technician-img" src={techProfile} alt={"Technician"} />
                                </div>
                                <div className="job-right-block">
                                    <p>{techician?.firstname} {techician?.lastname}</p>
                                </div>
                            </div>
                        </div>

                        {/* clocking detail */}
                        <div className="col-md-5 job-clock-detail" style={{ paddingLeft: "0px" }}>

                            <div style={{ textAlign: "end", marginRight: "15px" }} >

                                <Button style={{ background: "red" }}
                                    type="button"
                                    variant="outline-secondary"
                                    className="btn-outline rounded-btn statusDetail2"
                                >
                                    Clocked Off
                                </Button>

                            </div>
                        </div>
                    </div>

                    <hr />

                    {/* job detail START */}
                    <div className="job-detail px-3">
                        <div className="table-summary mt-3">
                            <p className="summary-title mb-3" >Job Detail</p>

                            <div className="user-rego-detail row mt-3 " style={{ alignItems: "center" }} >
                                <div className="col-md-7">
                                    <div className={"select-wrapper form-group align-center "} style={{ display: "block" }} >

                                        <Form.Group className="form-group mx-0">
                                            <Select
                                                className="form-control custom-select"
                                                required
                                                placeholder="No Job Found"
                                                isDisabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>


                            </div>



                        </div>
                    </div>
                    {/* job detail END */}


                    {/* Time Clocking START */}
                    <div className="job-detail px-3 ">
                        <div className="job-clocking-button mt-3">
                            <p className="summary-title mb-3" >Time Clocking</p>

                            <div className="page-title mb-3 text-center">
                                <p className="job-clocking-history mt-4">
                                    <span >00:00:00</span>
                                </p>
                            </div>

                            <div className="d-flex mb-2">
                                <div className="col-md-4 px-1">
                                    <Button style={{ width: "100%" }} type="button" variant="success" className="btn-success rounded-btn start"
                                        disabled={true}
                                    >
                                        Clock On
                                    </Button>
                                </div>
                                <div className="col-md-4 px-1">
                                    <Button style={{ width: "100%", background: "red", borderColor: "red" }} type="button" className="btn btn-primary rounded-btn remove paush-button"
                                        disabled={true}
                                    >Clock Off</Button>
                                </div>
                                <div className="col-md-4 px-1">
                                    <Button style={{ width: "100%", background: "black", borderColor: "black" }} type="button" variant="danger" className="btn-danger rounded-btn remove"
                                        disabled={true}
                                    >
                                        Finish Job
                                    </Button>
                                </div>
                            </div>



                        </div>
                    </div>

                    {/* Time Clocking END */}

                    {/* View more button START */}
                    <div className="view-more-time-log  mt-3  mb-3">


                        <Button
                            type="button"
                            className="btn btn-primary rounded-btn view-log-btn"
                            disabled={true}
                        >

                            <i className="fa fa-chevron-down mr-1" aria-hidden="true"></i>

                            View Log
                        </Button>
                    </div>
                    {/* View more button END */}




                </div>
            </div>

            {techCounter % 3 === 0 &&

                <div className="col-12 mb-3 mt-3">
                    <p style={{ border: "1px solid black" }} ></p>
                </div>

            }




        </>
    );
};

export default DefaultTechnicianList;
