import { apiCall } from "helpers/apiHelper";
import {
  getJobDepartmentList,
  getJobEmailTemplateList,
  getJobTypeList,
  getNotification,
  getOnlineJobs,
  getWorkshopOtherDetails,
} from "helpers/backendHelper";
import { EDIT_ONLINE_JOB, GET_CUSTOMER_QUOTE_HISTORY } from "helpers/urlHelper";
import { getWorkshopId } from "utils/getWorkshopId";

export async function getLeadDetail(id) {
  const workshop_id = getWorkshopId();
  const request1 = {
    get: "all",
    conditions: {
      "OnlineSchedule.id": id,
    },
    contain: {
      0: "Vehicles",
      JobBookingTypes: {
        fields: ["JobBookingTypes.name", "JobBookingTypes.parent_id"],
      },
      "Users.customersDetails": {
        conditions: {
          workshop_id: workshop_id,
        },
        fields: [
          "id",
          "user_id",
          "firstname",
          "lastname",
          "street_address",
          "suburb",
          "postcode",
          "phone_number",
          "mobile_number",
          "mobile_code"
        ],
      },
      "Users.States": {
        fields: ["id", "name"],
      },
      "Users.Countries": {
        fields: ["id", "country_name"],
      },
    },
  };
  const leadDetail = await getOnlineJobs({ payload: request1 });
  if (leadDetail?.code === 200) {
    const request2 = {
      get: "all",
      conditions: {
        workshop_id: workshop_id,
        "name IN": ["Services", "Repairs"],
      },
      fields: ["id", "job_type_parent_id", "name", "is_custom"],
    };

    const account_detail = await getJobDepartmentList({ payload: request2 });

    const request3 = {
      get: "all",
      conditions: { status: 1, "parent_id IN": [27, 28] },
      fields: ["id", "parent_id", "name"],
    };

    const get_job_departments = await getJobTypeList({ payload: request3 });

    const request4 = {
      get: "all",
      conditions: {
        workshop_id: workshop_id,
        customer_quote_id: id,
      },
      fields: ["id", "sent_date", "message", "email"],
    };

    const history = await apiCall(
      GET_CUSTOMER_QUOTE_HISTORY,
      { payload: request4 },
      "POST",
      true
    );

    const request5 = {
      get: "all",
      conditions: {
        workshop_id: workshop_id,
        type: "script",
        is_deleted: 0,
      },
    };

    const notificationTemplates = await getJobEmailTemplateList({
      payload: request5,
    });

    const request6 = {
      get: "all",
      conditions: {
        workshop_id: workshop_id,
        is_read: 0,
        foreign_id: id,
        type: "lead-script",
      },
      fields: [
        "created_on",
        "title",
        "payload",
        "email_status",
        "to_id",
        "is_read",
        "modified",
        "mail_body",
        "msg",
      ],
    };

    const historyResponse = await getNotification({ payload: request6 });
    const lead_data = {
      id,
      is_notify: 1,
    };

    const save_leads = await apiCall(
      EDIT_ONLINE_JOB,
      { payload: lead_data },
      "POST",
      true
    );

    const request7 = {
      get: "all",
      conditions: {
        workshop_id: workshop_id,
      },
      fields: [
        "id",
        "workshop_id",
        "technician_timeslot",
        "schedule_details",
        "shift_start_day",
        "shift_finish_day",
        "shift_start_time",
        "shift_finish_time",
        "break_start",
        "break_finish",
        "timezone_label",
        "is_sunday",
        "is_saturday",
      ],
    };
    const workshop_other_detail = await getWorkshopOtherDetails({
      payload: request7,
    });

    return {
      lead: leadDetail?.data?.OnlineSchedule[0],
      account_data: account_detail?.data?.WorkshopDepartmentsAccountsDetails,
      departments: get_job_departments?.data?.JobBookingTypes,
      customer_quote_history: history?.data?.EmailQuoteHistory,
      templates: notificationTemplates?.data?.CustomNotifications,
      histories: historyResponse?.data?.NotificationsData,
      workshopDetail:
        workshop_other_detail?.data?.WorkshopOtherDetails[0],
    };
  } else {
    throw new Error("No Lead found");
  }
}
