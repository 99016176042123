import { apiCall } from "helpers/apiHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import { groupBy } from "lodash";
import { date_format, getCurrency } from "utils/getCurrency";
import moment from "moment";
import { customerRevenueReport, getEffectiveLabourData, getJobBookedDetails, getLabourHoursData, jobTypeCustomerReport, makeTypeCustomerReport } from "helpers/backendHelper";
import { addComma } from "utils/numberFunction";
import { convertMsToHM, convertToHoursMins, explodeTime, getMillisecond } from "utils/timeZoneConvert";

/** Single call utilities */

// Labour Hours functions START 
async function getLabourHoursTableData({ start_date, end_date }) {

  const request = {
    workshop_id: getWorkshopId(),
    start_date: start_date,
    end_date: end_date,
  };
  
  const data = [];
  const chartResponse = await getLabourHoursData({ payload: request });

  const localStorageData = JSON.parse(localStorage.getItem('data'));
  const workshopOtherDetails = localStorageData?.workshop?.workshop_other_details[0];

  let technician_timeslot = workshopOtherDetails?.technician_timeslot;

  let start_time = workshopOtherDetails?.shift_start_time || 0;
  let end_time = workshopOtherDetails?.shift_finish_time || 0;
  let break_start_time = workshopOtherDetails?.break_start || 0;
  let break_end_time = workshopOtherDetails?.break_finish || 0;

  let startday = workshopOtherDetails?.shift_start_day;
  let endday = workshopOtherDetails?.shift_finish_day;

  // shift duration
  const shift_duration_mili = getMillisecond(start_time,end_time);

  // break duration
  const break_duration_mili = getMillisecond(moment(break_start_time).format('hh:mm:ss'),moment(break_end_time).format('hh:mm:ss'));

  // workshop dureation
  const workshop_working_time_milli = +shift_duration_mili - +break_duration_mili;
  const workshop_working_time = convertMsToHM(workshop_working_time_milli);

  let workshop_working_explod = workshop_working_time.split(":");

  let hour = workshop_working_explod[0];
  let minut = workshop_working_explod[1];
  let second = workshop_working_explod[2];

  let t_second = 0;
  let t_minut = 0;

  if(minut!==0){
    t_minut = Math.round((+minut*100)/60);
  }
  if(second!==0){
      t_second = Math.round((+second*100)/60);
  }
  let addData = +t_minut + +t_second;
  let total_workshop_hr = hour+'.'+addData;


  const total_off_day = [];
  const total_working_day = [];

  ////////////////count working days of workshop////////////////
  for (var i= 1; i <= 5 ; i++) {
    if(i<startday || i>endday){
        total_off_day.push(i);
    }
    if(i>=startday && i<=endday){
        total_working_day.push(i);
    }
  }

   ///////////sunday working or not////////
  if(workshopOtherDetails?.is_sunday === '0'){
    total_off_day.push(0);
  }else{
    total_working_day.push(0);
  }

  ///////////saturday working or not////////
  if(workshopOtherDetails?.is_saturday === '0'){
      total_off_day.push(6);
  }else{
      total_working_day.push(6);
  }

  let begin = new Date(start_date );
  let end   = new Date(end_date );


  let count_no_days = 0;
  for (var d = begin; d <= end; d.setDate(d.getDate() + 1)) {
      let weekday = new Date(d).getDay();
      if(total_working_day.includes(weekday)===true){
        ++count_no_days;
      }

  }

  //for chart data
  const obj = {
    empData: [],
    hoursAvail: [],
    eff: [],
    paidProd: [],
  };
  
  const tableData =
    chartResponse?.data?.JobSchedules?.map((job) => {

      // paid_hours
      let paid_hours = explodeTime(workshop_working_time) * +count_no_days;

      // paid time
      let paid_time = convertToHoursMins(job?.paid_time || 0);

      // other time || non_billable
      let others_time = convertToHoursMins(job?.non_billable || 0);

      //hours available 
      let final_hours_paid = explodeTime(workshop_working_time) * +count_no_days;
      let final_paid_hours = explodeTime(paid_time);
      let final_non_billable_hours = explodeTime(others_time);
      let working_hours = convertToHoursMins(job?.job_expected_time);
      let final_hours_available = +final_hours_paid - (+final_paid_hours + +final_non_billable_hours) - +explodeTime(working_hours);
      
      //hours_clocked
      let final_hours_clocked = (job?.job_timer_time > 0) ? explodeTime(job?.job_timer_time) : 0;

      //hours_charged
      let estimate_time = "0.00";
      if(job?.labour_estimate_time){
          estimate_time = explodeTime(job?.labour_estimate_time);
      }
      let actual_time = explodeTime(job?.labour_actual_time);
      let final_hours_charged = +estimate_time + +actual_time;


      //avail_recovery
      // formula: hours clocked / hours available
      let avail_recovery = +final_hours_clocked / +final_hours_available;

      //efficiency
      // formula: hours charged / hours clocked
      let efficiency = 0.00;
      if(final_hours_clocked > 0) {
        efficiency = +final_hours_charged / +final_hours_clocked;
      }

      //avail_productivity
      // formula: hours charged / (hours paid total - (paid time off + others))
      let paid_time_other = +final_hours_paid - (+final_paid_hours + +final_non_billable_hours);
      let final_paid_productivity = 0.00;
      if(final_hours_charged > 0 && paid_time_other > 0){
          final_paid_productivity = (+final_hours_charged / +paid_time_other) * 100;
      }


      obj.empData.push(job?.customer);
      obj.hoursAvail.push(+final_hours_available.toFixed(2) || 0);
      obj.eff.push(+efficiency.toFixed(2) || 0);
      obj.paidProd.push(+final_paid_productivity.toFixed(2) || 0);

      return {
        id: job?.id,
        customer: job?.customer,
        paid_hours:paid_hours.toFixed(2),
        paid_time:explodeTime(paid_time),
        others_time:explodeTime(others_time),
        hours_available: final_hours_available.toFixed(2),
        hours_clocked: final_hours_clocked,
        hours_charged: final_hours_charged.toFixed(2),
        avail_recovery: avail_recovery.toFixed(2),
        efficiency: efficiency.toFixed(2),
        paid_productivity: final_paid_productivity.toFixed(2)
      };
    }) || [];


    data['table'] = tableData;
    data['chart'] = obj;

    return data;

}



// Labour Hours functions END 

// Effective Labour Rate function START

async function getEffectiveLabourTableData({ start_date, end_date }) {

  const request = {
    workshop_id: getWorkshopId(),
    start_date: start_date,
    end_date: end_date,
  };
  
  const data = [];
  const tableResponse = await getEffectiveLabourData({ payload: request });

  //for chart data
  const obj = {
    invoiceDates: [],
    totalSales: [],
    totalLabour: [],
    noOfCars: [],
    billable: [],
    unbillable: [],
  };

  const tableData =
    tableResponse?.data?.JobSchedules?.map((job,index) => {

      let billable = explodeTime(job?.billable);
      let unbillable = explodeTime(job?.unbillable);
      let avg_sale_per_car  = +job?.total_sale / +job?.no_of_cars;
      let avg_sale_per_tech  = +job?.total_sale / +job?.no_of_techs;
      let total_hours = +billable + +unbillable;
      let eff_labour_rate = +job?.total_labour / +total_hours;


      //for chart data
      obj.invoiceDates.push(moment(job?.invoice_date).format(date_format));
      obj.totalSales.push(+parseFloat(job?.total_sale).toFixed(2) || 0);
      obj.totalLabour.push(+job?.total_labour || 0);
      obj.noOfCars.push(+job?.no_of_cars || 0);
      obj.billable.push(+parseFloat(billable).toFixed(2) || 0);
      obj.unbillable.push(+parseFloat(unbillable).toFixed(2) || 0);
      
      return {
          id:  index,
          billable: +parseFloat(billable).toFixed(2),
          invoice_date: moment(job?.invoice_date).format(date_format),
          no_of_cars: +job?.no_of_cars || 0,
          avg_sale_per_car: +avg_sale_per_car.toFixed(2),
          no_of_techs: +job?.no_of_techs || 0,
          avg_sale_per_tech: +avg_sale_per_tech.toFixed(2),
          total_labour: +job?.total_labour || 0,
          total_sale: +parseFloat(job?.total_sale).toFixed(2) || 0,
          unbillable: +parseFloat(unbillable).toFixed(2),
          total_hours: total_hours.toFixed(2),
          eff_labour_rate: eff_labour_rate.toFixed(2),
      };
      
    }) || [];
  

   

  data['table'] = tableData;
  data['chart'] = obj;

  return data;

}
// Effective Labour Rate function END

/** Single call utilities End */


// labour hours
export async function getLabourHours({ start_date, end_date }) {
  
  const labourData = await getLabourHoursTableData({ start_date, end_date });

  const hoursTable = labourData['table'];
  const hoursChart = labourData['chart'];
 
  return { hoursTable, hoursChart };
}

export async function getEffectiveLabourRate({ start_date, end_date }) {
  
  const labourData = await getEffectiveLabourTableData({ start_date, end_date });
 
  const effectiveTable = labourData['table'];
  const effectiveChart = labourData['chart'];

  return { effectiveTable, effectiveChart };
}





