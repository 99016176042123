import { checkJobSlot, updateJobLabourByClocking, updateJobStatus } from "helpers/backendHelper";
import moment from "moment";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { convertToHoursMinSec, convertToMinute, timeZoneToUTC } from "utils/timeZoneConvert";
import { useJobTimer } from "./useTimer";

let TimeClocking = ({
    selectedJob, onAddClockToList, onUpdateClockToList, lastActivity, setLastActivity,
    setSelectedJob, jobList, jobData, setJobData, fromDate, techicianID, jobHistory, workshop_id
}) => {

    const navigate = useNavigate();

    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let timezoneLable = window.atob(storedData.timezone_lable);
    const [timerStartTime, setTimerStartTime] = useState("0000-00-00 00:00:00");
    const [timerTotalTime, setTimerTotalTime] = useState("00:00:00");
    const todayDate = moment().format('YYYY-MM-DD');

    useEffect(() => {

        return () => {
            setTimerStartTime("0000-00-00 00:00:00");
            setTimerTotalTime("00:00:00");
        };

    }, [])
    useEffect(() => {
        let duration = 0;
        if (jobHistory?.length > 0) {
            let day_start_time = jobHistory[jobHistory.length - 1]?.start_time;
            // let day_start_time = jobHistory[0]?.start_time;
            setTimerStartTime(day_start_time);


            jobHistory.map(function (c) {
                if (c?.activity === "Clock In") {
                    duration = parseFloat(duration) + parseFloat(convertToMinute(c?.time_duration));
                }
            });
        }

        let totalDuration = convertToHoursMinSec(duration.toFixed(2));
        setTimerTotalTime(totalDuration);

    }, [jobHistory])

    const { time, tag } = useJobTimer({
        total_time: timerTotalTime,
        start_time: timerStartTime,
        running: lastActivity === "Clock In",
    });

    function finishJob() {
        Swal.fire({
            title: "Ready to Finish Job?",
            text: "",
            icon: "error",
            position: "top",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonColor: "#65cc88",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancel",
            confirmButtonText: "Yes",
            customClass: {
                container: 'clock-out-dialog',
            },
        }).then((result) => {
            if (result !== "" && result.isConfirmed === true) {
                onUpdateClockToList();
                setTimeout(() => {
                    onAddClockToList({
                        "activity": "Finish Job",
                        "message": "Finished Job",
                        "time_duration": time
                    });
                    editJobScheduler("stop");
                }, 1000);
            }
        });
    }

    // 
    function changeJobSlot() {
        Swal.fire({
            title: "This job is not assigned to you... <br/> Do you still want to Clock On?",
            text: "",
            icon: "error",
            position: "top",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonColor: "#65cc88",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancel",
            confirmButtonText: "Yes",
            customClass: {
                container: 'clock-out-dialog',
            },
        }).then(async (result) => {
            // if confirm job start by another technician
            if (result !== "" && result.isConfirmed === true) {

                const jobSlotPayload = {
                    "user_id": techicianID,
                    "workshop_id": selectedJob['workshop_id'],
                    "from_date": moment(selectedJob['from_date']).format('YYYY-MM-DD'),
                    "from_time": timeZoneToUTC(moment(selectedJob['from_time']).format("HH:mm:ss"), timezoneLable, "HH:mm:ss", "HH:mm:ss"),
                    "to_time": timeZoneToUTC(moment(selectedJob['to_time']).format("HH:mm:ss"), timezoneLable, "HH:mm:ss", "HH:mm:ss")
                }

                // check time slot available or not
                const jobSlot = await checkJobSlot({ payload: jobSlotPayload });

                if (jobSlot?.code === 200) {

                    onAddClockToList({
                        "activity": "Clock In",
                        "message": "Clocked On"
                    });
                    setTimeout(() => {
                        editJobScheduler("start");
                    }, 1000);

                } else {

                    let prevJobId = jobSlot?.data?.JobSchedules?.id;
                    Swal.fire({
                        title: `You already have Job #${jobSlot?.data?.JobSchedules?.job_number} at this time, if you will start this then that job will move to bays.`,
                        text: "",
                        icon: "error",
                        position: "top",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        confirmButtonColor: "#65cc88",
                        cancelButtonColor: "#d33",
                        cancelButtonText: "Cancel",
                        confirmButtonText: "Yes",
                        customClass: {
                            container: 'clock-out-dialog',
                        },
                    }).then(async (result) => {

                        if (result !== "" && result.isConfirmed === true) {

                            onAddClockToList({
                                "activity": "Clock In",
                                "message": "Clocked On"
                            });
                            setTimeout(() => {
                                // Job move to bay
                                editJobScheduler("start", prevJobId);
                            }, 1000);

                        }

                    });

                }
            }
        });
    }


    async function editJobScheduler(timerStatus, prevJobId = 0) {

        let jobPayload = "";
        if (timerStatus === "start") {
            jobPayload = {
                id: selectedJob?.id,
                timer_status: "start",
                timer_start_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                job_stage: "Inprogress",
                job_status: "InProgress",
                clocked_in_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                // user_id: selectedJob?.is_same_technician ? selectedJob?.user_id : techicianID,
                user_id: techicianID,
                colourCell: "InProgress",
                textCell: "In Progress",
                prevJobId: prevJobId
            }
        }

        if (timerStatus === "pause") {
            jobPayload = {
                id: selectedJob?.id,
                timer_status: "pause",
                job_stage: "Inprogress",
                job_status: "OnHold",
                // timer_total_time: time,
                colourCell: "OnHold",
                textCell: "On Hold",
                clocked_off_time: moment().utc().format('YYYY-MM-DD HH:mm:ss')
            }
        }

        if (timerStatus === "resume") {
            jobPayload = {
                id: selectedJob?.id,
                timer_status: "start",
                timer_start_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                job_stage: "Inprogress",
                job_status: "InProgress",
                colourCell: "InProgress",
                textCell: "In Progress"
            }
        }

        if (timerStatus === "stop") {
            jobPayload = {
                id: selectedJob?.id,
                timer_status: "stop",
                // timer_total_time: time,
                labour_time: time,
                job_stage: "Completed",
                job_status: "ReadyForInvoice",
                clocked_off_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                colourCell: "ReadyForInvoice",
                textCell: "Ready For Invoice"
            }
        }

        let jobUpdate = "";
        // if (timerStatus === "start") {
        //     jobUpdate = await updateJob({ payload: jobPayload });
        // } else {
        jobUpdate = await updateJobStatus({ payload: jobPayload });
        // }

        if (jobUpdate?.code === 200) {


            let pData = {
                job_id: selectedJob?.id,
                workshop_id: workshop_id,
                techician_id: techicianID
            }

            if (timerStatus === "pause" || timerStatus === "stop") {
                const addLabour = await updateJobLabourByClocking({ payload: pData });
                console.log(addLabour);
            }

            let JobSchedules = jobUpdate?.data?.JobSchedules;
            let jobStatus = jobUpdate?.data?.JobSchedules?.job_status;
            var colourCell = jobStatus;
            var textCell = jobStatus;
            if (colourCell === "Paid") {
                colourCell = "Payment";
            }
            if (textCell === "Payment") {
                colourCell = "Payment";
                textCell = "Paid";
            }
            if (textCell === "InProgress") {
                textCell = "In Progress";
            }

            if (textCell === "ReadyForInvoice") {
                textCell = "Ready For Invoice";
            }
            if (colourCell === "Pending") {
                textCell = "Booked";
                colourCell = "Pending"
                if (JobSchedules?.checkIn_date !== null) {
                    textCell = "Arrived";
                    colourCell = "Arrived"
                }
            }

            jobPayload['textCell'] = textCell;
            jobPayload['colourCell'] = colourCell;
            jobPayload['job_status'] = jobStatus;
            jobPayload['job_stage'] = jobUpdate?.data?.JobSchedules?.job_stage;

            setSelectedJob({
                ...selectedJob,
                ...jobPayload
            })

            const updatedParts = jobData.map(function (c) {
                if (c.id === selectedJob?.id) {
                    const pop = {
                        ...c,
                        ...jobPayload,
                    }
                    c = pop;
                }
                return c;
            });
            setJobData(updatedParts);
        }

    }

    if (fromDate !== todayDate) {

        return (
            <>
                {/* Time Clocking START */}
                <div className="job-detail px-3 ">
                    <div className="job-clocking-button mt-3">
                        <p className="summary-title mb-3" >Time Clocking</p>

                        <div className="page-title mb-3 text-center">
                            <p className="job-clocking-history mt-4">
                                <span >{time}</span>
                            </p>
                        </div>

                        <div className="d-flex mb-2">
                            <div className="col-md-4 px-1">
                                <Button style={{ width: "100%" }} type="button" variant="success" className="btn-success rounded-btn start"
                                    disabled={true}
                                >
                                    Clock On
                                </Button>
                            </div>
                            <div className="col-md-4 px-1">
                                <Button style={{ width: "100%", background: "red", borderColor: "red" }} type="button" className="btn btn-primary rounded-btn remove paush-button"
                                    disabled={true}
                                >Clock Off</Button>
                            </div>
                            <div className="col-md-4 px-1">
                                <Button style={{ width: "100%", background: "black", borderColor: "black" }} type="button" variant="danger" className="btn-danger rounded-btn remove"
                                    disabled={true}
                                >
                                    Finish Job
                                </Button>
                            </div>
                        </div>



                    </div>
                </div>
                {/* Time Clocking END */}

            </>
        );

    } else {

        return (
            <>
                {/* Time Clocking START */}
                <div className="job-detail px-3 ">
                    <div className="job-clocking-button mt-3">
                        <p className="summary-title mb-3" >Time Clocking</p>

                        <div className="page-title mb-3 text-center">
                            <p className="job-clocking-history mt-4">
                                <span >{time}</span>
                            </p>
                        </div>

                        <div className="d-flex mb-2">
                            <div className="col-md-4 px-1">
                                <Button style={{ width: "100%" }} type="button" variant="success" className="btn-success rounded-btn start"
                                    disabled={
                                        (lastActivity === "" && !selectedJob?.id) ||
                                        lastActivity === "Clock In" ||
                                        lastActivity === "Finish Job"
                                    }
                                    onClick={(e) => {
                                        if (lastActivity !== "" && typeof lastActivity !== "undefined" & lastActivity !== null) {
                                            setTimerTotalTime(time);
                                            onUpdateClockToList();
                                            setTimeout(() => {
                                                onAddClockToList({
                                                    "activity": "Clock In",
                                                    "message": "Clocked On"
                                                });
                                                editJobScheduler("resume");
                                            }, 1000);
                                        } else {
                                            if (!selectedJob?.is_same_technician) {
                                                changeJobSlot();
                                            } else {
                                                setTimerTotalTime(time);
                                                onAddClockToList({
                                                    "activity": "Clock In",
                                                    "message": "Clocked On"
                                                });
                                                setTimeout(() => {
                                                    editJobScheduler("start");
                                                }, 1000);
                                            }
                                        }
                                    }}
                                >
                                    Clock On
                                </Button>
                            </div>
                            <div className="col-md-4 px-1">
                                <Button style={{ width: "100%", background: "red", borderColor: "red" }} type="button" className="btn btn-primary rounded-btn remove paush-button"
                                    // disabled={typeof lastActivity === "undefined" || lastActivity === "" || lastActivity === "Clock Off" || lastActivity === "Finish Job"}
                                    disabled={
                                        lastActivity === "" ||
                                        lastActivity === "Clock Off" ||
                                        lastActivity === "Finish Job"
                                    }
                                    onClick={(e) => {
                                        onUpdateClockToList({
                                            "activity": "Clock Off"
                                        });
                                        setTimeout(() => {
                                            onAddClockToList({
                                                "activity": "Clock Off",
                                                "message": "Clocked Off"
                                            });
                                            editJobScheduler("pause");
                                        }, 1000);
                                    }}
                                >Clock Off</Button>
                            </div>
                            <div className="col-md-4 px-1">
                                <Button style={{ width: "100%", background: "black", borderColor: "black" }} type="button" variant="danger" className="btn-danger rounded-btn remove"
                                    // disabled={typeof lastActivity === "undefined" || lastActivity === "" || lastActivity === "Finish Job"}
                                    disabled={lastActivity === "" || lastActivity === "Finish Job"}
                                    onClick={(e) => {
                                        finishJob();
                                    }}
                                >
                                    Finish Job
                                </Button>
                            </div>
                        </div>



                    </div>
                </div>
                {/* Time Clocking END */}

            </>
        );

    }
};

export default TimeClocking;
