import Loadable from "react-loadable";

import { Loading } from "views/common/navigation";

const Profile = Loadable({
  loader: () => import("./components/Profile"),
  loading: Loading,
});

export const routes = [
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    element: Profile,
  }
];

export * from "./components";
