import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "views/common/navigation/components/Wrapper";

const NotFound = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(props.path ?? "/home");
  }, []);

  return <Wrapper>NotFound</Wrapper>;
};

export default NotFound;
