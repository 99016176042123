import { DatePicker } from "antd";
import moment from 'moment';
import { useEffect, useMemo, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Scrollbars } from "react-custom-scrollbars";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WAIT_FOR_ACTION } from "redux-wait-for-action";
import { useDataProvider } from "utils/DataContext";
import SendSMSToUser from "views/modals/SendSMSToUser";
import LoaderImage from "../../../assets/images/page-loader.svg";
import { addInternalNotes, addJobNotes, addManyNotification, addManyRecommendations, copyAttachment, editQuotes, getEstimateTimeSlot, saveInternalNotesAttachments } from "../../../helpers/backendHelper";
import { GET_QUOTE_BOOKED_DETAILS } from "../../../store/QuoteDetails/actionTypes";
import { GET_LABOUR_CHARGES_LIST } from "../../../store/commonApiStore/actionTypes";
import { GET_ATTACHMENT, GET_INTERNAL_NOTES, GET_MESSAGE, UPDATE_JOB_TIMER, GET_JOB_NOTES } from '../../../store/jobDetails/actionTypes';
import { SAVE_JOB_LABOUR } from '../../../store/jobLabour/actionTypes';
import {
    ADD_MANY_JOB_KIT_LABOURS,
    ADD_MANY_JOB_KIT_PARTS,
    ADD_MANY_JOB_LABOURS,
    ADD_MANY_JOB_PARTS,
    GET_AVAIALABLE_SLOTS,
    GET_CARRY_OVER_JOB,
    GET_INSURANCE,
    GET_JOBS_KIT_LABOURS,
    GET_JOBS_KIT_PARTS,
    GET_QUOTE_KIT_LABOURS,
    GET_QUOTE_KIT_PARTS,
    GET_TIMER_DETAILS,
    GET_TIMER_DETAILS_SUCCESS,
    UPDATE_CARRY_OVER_JOB
} from "../../../store/scheduler/actionTypes";
import { CREATE_JOB } from "../../../store/unassignedJobs/actionTypes";
import { showError, showSuccess } from "../../../utils/alertMessage";
import { convertDurationToDecimal, convertSecondsToHHMMSS, convertToHours, convertToMinute, timeZoneToUTC, utcToTimezone } from "../../../utils/timeZoneConvert";
import { unserializeArr } from "../../../utils/unserialize";
import TimeField from "./TimeField";
import { getSessionData } from "utils/getWorkshopId";
import Select from "react-select";

const Reschedule = ({
    rescheduleShow,
    handleRescheduleClose,
    jobIdData,
    startEventTime,
    technicianName,
    jobNumber,
    setUpdateCalendar,
    jobType,
    job_details,
    refreshData,
    activePage,
    updateCalendar
}) => {


    const { data, setData } = useDataProvider(); // Create custome hook for go to perticular date

    const [loading, setLoading] = useState(false);
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);
    let timezoneLable = window.atob(storedData.timezone_lable);
    let user_id = window.atob(storedData.user_id);
    let date_format = storedData.date_format;
    let time_format = storedData.time_format;
    let schedule_details = window.atob(storedData.schedule_details);
    const [currentDay, setCurrentDay] = useState(new Date(startEventTime).getDay());
    const [currentDate, setCurrentDate] = useState(moment(startEventTime).format('YYYY-MM-DD'));
    const dispatch = useDispatch(); // Dispatch action variable
    const navigate = useNavigate();
    const [modalType, setModalType] = useState('');
    const [unassignedFlag, setUnassignedFlag] = useState(false);
    const [showAddMobileModal, setShowAddMobileModal] = useState();
    const [createJobLabours, setCreateJobLabours] = useState([]);
    const [createJobParts, setCreateJobParts] = useState([]);
    const [createJobRecommendation, setCreateJobRecommendation] = useState([]);
    // const [createJobMessages, setCreateJobMessages] = useState([]);
    const [parentJobId, setParentJobId] = useState(null);
    const [titleDate, setTitleDate] = useState(moment(startEventTime).format(date_format));
    const [titleTime, setTitleTime] = useState(moment(startEventTime).format(time_format));
    const [employeeName, setEmployeeName] = useState(technicianName);
    const [employeeId, setEmployeeId] = useState('');
    const [fromDate, setFromDate] = useState();
    const [empDataType, setEmpDataType] = useState('res_tech');
    const [totalLabourTime, setTotalLabourTime] = useState("00:00")
    const [timeSlot, setTimeSlot] = useState(null);

    const [callDispatchAction, setCallDispatchAction] = useState(false);
    const [callDispatchActionForCarryOver, setCallDispatchActionForCarryOver] = useState(false);

    const [disabledSave, setDisabledSave] = useState('disabled');
    const [employeeList, setEmployeeList] = useState([]);
    const [timeSlotsList, setTimeSlotsList] = useState([]);
    const [eveTimeSlotsList, setEveTimeSlotsList] = useState([]);
    const [updateMobileNumber, setUpdateMobileNumber] = useState(false);
    const [mobileNumber, setMobileNumber] = useState();

    const [custId, setCustId] = useState();
    const [jobId, setJobId] = useState();
    const [customerId, setCustomerId] = useState();
    const [mobileCode, setMobileCode] = useState();
    const [rego, setRego] = useState();
    const [smsJobNumber, setSmsJobNumber] = useState();
    const [customerName, setCustomerName] = useState();
    const [estimateTimeSlot, setEstimateTimeSlot] = useState([]);
    const [startTime, setStartTime] = useState("08:00:00");
    const [endTime, setEndTime] = useState("12:00:00");
    const [createJobEstimateTime, setCreateJobEstimateTime] = useState("00:30");
    const [showJobEstimateSlot, setShowJobEstimateSlot] = useState(false);
    const [jobEstimateTimeSlot, setJobEstimateTimeSlot] = useState([]);
    const [durationJob, setDurationJob] = useState(0);

    const [pickupTimeSlot, setPickupTimeSlot] = useState([]);
    const [afterPickupTimeSlot, setAfterPickupTimeSlot] = useState([]);
    const [showPickupTimeSlot, setShowPickupTimeSlot] = useState(false);
    const [selectedPickupTimeSlot, setSelectedPickupTimeSlot] = useState();

    const insuranceData = useSelector((state) => state.SchedulerReducer.insuranceData);

    const part_gst = +getSessionData("part_gst");

    const sentSMSType = useMemo(() => {
        if (jobType == "Open") {
            return "quoteToJob";
        } else {
            return "job";
        }
    }, [jobType])

    let defaultSelect = true;
    const [rescheduleFormData, setRescheduleFormData] = useState({
        type: "reschedule",
        make: "",
        model: "",
        year: "",
        rego: "",
        customer_name: "",
        mobile_number: "",
        email: "",
        pickup_date: "",
        pickup_time: "",
        job_estimate_time: ""
    })

    const [unassignedDialog, setUnassignedDialogShow] = useState(false);
    const unassignedDialogClose = () => {
        setModalType('');
        setUnassignedFlag(false);
        setUnassignedDialogShow(false);
    }
    const unassignedDialogShow = () => {
        setModalType('unassigned');
        setUnassignedFlag(true);
        setUnassignedDialogShow(true);
    };

    useEffect(() => {
        if (startEventTime) {
            setTitleDate(moment(startEventTime).format(date_format));
            setTitleTime(moment(startEventTime).format(time_format));
        }
    }, [startEventTime])

    useEffect(() => {
        if (showAddMobileModal == false) {
            handleRescheduleClose();
            if (jobType == "reschedule" || jobType == "Rescheduled") {
                setCallDispatchAction(false);
                if (activePage === "jobDetail") {
                    navigate("/job-list#assigned-job")
                } else {
                    setUpdateCalendar(!updateCalendar);
                    setData(new Date(currentDate));
                    navigate("/scheduler");
                }
            } else if (jobType == "Open") {
                if (activePage === "quote_Detail") {
                    navigate("/quote-list");
                } else {
                    refreshData();
                }
            }
        }
    }, [showAddMobileModal])

    const workshopOtherDetails = useSelector((state) => state.CommonApiStoreReducer?.Workshops?.workshop_other_details);
    const { labourChargesList } = useSelector((state) => state.CommonApiStoreReducer);
    const employeeDetails = useSelector((state) => state?.CommonApiStoreReducer?.Workshops?.users);
    const workshopBayData = useSelector((state) => state.SchedulerReducer.workshopBaysDetails);
    const schedulerData = useSelector((state) => state.SchedulerReducer.getAvailableSlots);
    const { jobTimerData, message, internalNotes, attachment, jobNotesData } = useSelector((state) => state.JobDetails);
    const jobDetailsData = useSelector((state) => state.SchedulerReducer.getCarryOverJob);
    const jobKitPartsData = useSelector((state) => state.SchedulerReducer.getJobsKitParts);
    const jobKitLaboursData = useSelector((state) => state.SchedulerReducer.getJobsKitLabours);

    const createJobData = useSelector((state) => state.UnassignedJobsReducer.createJob);
    const { jobBookedData } = useSelector((state) => state.QuoteDetails);
    const quoteKitParts = useSelector((state) => state.SchedulerReducer.getQuoteKitParts);
    const quoteKitLabours = useSelector((state) => state.SchedulerReducer.getQuoteKitLabours);
    useEffect(() => {
        if (callDispatchAction === true) {
            if (jobTimerData !== null && jobTimerData !== undefined) {

                if (jobType === "Unassigned") {
                    if (labourChargesList !== null && labourChargesList !== undefined) {
                        let jobLaboursArr = [];
                        let laboursDataArr = {
                            labour_charges_id: labourChargesList[0].id,
                            job_schedules_id: jobTimerData.JobSchedules.id,
                            cost_price: labourChargesList[0].cost_price,
                            margin: labourChargesList[0].margin,
                            sell_price: labourChargesList[0].sell_price,
                            retail_price: labourChargesList[0].retail_price,
                            profit: labourChargesList[0].profit_price,
                            labour_description: labourChargesList[0].description,
                            gst_price: labourChargesList[0].gst_price
                        };

                        ["1", "2", "3"].map(cv => {
                            const copiedData = { ...laboursDataArr, labour_charge_type: cv }
                            if (cv === "3") {
                                copiedData["labour_time"] = createJobData?.data?.JobSchedules?.job_estimate_time
                                copiedData["labour_ph"] = convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                                copiedData["sell_total"] = parseFloat(laboursDataArr.sell_price) * parseFloat(convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time))
                                copiedData["total_price"] = parseFloat(labourChargesList[0].retail_price) * convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                                copiedData["gst_total"] = parseFloat(labourChargesList[0].gst_price) * convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                            } else {
                                copiedData["sell_total"] = "0.00"
                                copiedData["total_price"] = "0.00"
                                copiedData["gst_total"] = "0.00"
                            }
                            return jobLaboursArr.push(copiedData)
                        })
                        dispatch({
                            type: ADD_MANY_JOB_LABOURS,
                            payload: {
                                data: jobLaboursArr
                            }
                        })
                        // let objToSend = {
                        //     labour_charges_id: labourChargesList[0].id,
                        //     job_schedules_id: jobTimerData.JobSchedules.id,
                        //     cost_price: labourChargesList[0].cost_price,
                        //     margin: labourChargesList[0].margin,
                        //     sell_price: labourChargesList[0].sell_price,
                        //     retail_price: labourChargesList[0].retail_price,
                        //     profit: labourChargesList[0].profit_price,
                        //     labour_description: labourChargesList[0].description,
                        //     total_price: 0
                        // }
                        // dispatch({
                        //     type: SAVE_JOB_LABOUR,
                        //     payload: objToSend
                        // })
                    }
                }

                if (activePage === "jobDetail") {
                    if (jobType == "reschedule" || jobType == "Rescheduled") {
                        showSuccess('Job updated successfully!');
                        return setShowAddMobileModal(true);
                    }
                    navigate("/job-list#unassigned-job")
                } else {
                    if (jobType == "reschedule" || jobType == "Rescheduled") {
                        showSuccess('Job updated successfully!');
                        return setShowAddMobileModal(true);
                    }
                }
                showSuccess('Job updated successfully!');
                setUpdateCalendar(!updateCalendar);
            } else {
                showError('Job is not updated');
            }

            handleRescheduleClose();
            setCallDispatchAction(false);
        }
    }, [jobTimerData])

    useEffect(() => {
        if (jobType === "Unassigned") {
            dispatch({
                type: GET_LABOUR_CHARGES_LIST,
                payload: {
                    "get": "all",
                    "conditions": {
                        "workshop_id": workshop_id,
                        "labour_code !=": "",
                        "is_default": "1",
                        "is_deleted": 0
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        if (callDispatchActionForCarryOver === true) {
            if (createJobData !== null && createJobData !== undefined) {
                setJobId(createJobData?.data?.JobSchedules?.id);
                setSmsJobNumber(createJobData?.data?.JobSchedules?.job_number);
                setParentJobId(createJobData?.data?.JobSchedules?.id || null);
                showSuccess('Job created successfully!');
                setUpdateCalendar(!updateCalendar);
            } else {
                showError('Job is not updated');
            }
            // handleRescheduleClose();
            // setCallDispatchActionForCarryOver(false);
        }
    }, [createJobData])

    // Get insurance details
    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_INSURANCE,
                payload: {
                    get: 'all',
                    conditions: {
                        workshop_id: workshop_id
                    },
                    fields: ['id', 'company_name', 'contact_name']
                }
            });
        }
        fetchData()
            .catch(console.error)
    }, [])

    const changeQuoteStatus = async () => {
        let data = {
            payload: {
                id: jobIdData,
                quote_status: "Won",
            },
        };
        let response = await editQuotes(data);
        if (response.code === 200) {
            setLoading(false);
            return setShowAddMobileModal(true);
            // if (activePage === "quote_Detail") {
            //     return setShowAddMobileModal(true);
            // }
            // else {
            //     refreshData();
            // }
        }

    };

    useEffect(() => {

        if (parentJobId !== null && callDispatchActionForCarryOver === true) {

            let partsArr = [];
            if (createJobParts.length !== 0 && createJobParts !== null) {
                createJobParts.map((partData, index) => {
                    let parts = {};
                    parts['workshop_id'] = workshop_id;
                    parts['product_id'] = partData?.product_id || 0;
                    parts['is_kit'] = partData?.is_kit || 0;
                    parts['job_schedule_id'] = parentJobId;
                    if (jobType === "Open") {
                        parts['job_part_name'] = partData?.quote_part_name || 0;
                        parts['job_part_priority'] = partData?.quote_part_priority || 0;
                    } else {
                        parts['job_part_name'] = partData?.job_part_name || 0;
                        parts['job_part_priority'] = partData?.job_part_priority || 0;
                        parts['is_carried_over_parts'] = 1;
                        parts['assigned_qty'] = partData?.assigned_qty || 0;
                        parts['purchase_order_qty'] = partData?.purchase_order_qty || 0;
                        parts['basic_stock'] = partData?.basic_stock || 0;
                        parts['is_assigned'] = partData?.is_assigned || 0;
                        parts['po_qty_sent'] = partData?.po_qty_sent || 0;
                        parts['is_inspection_part'] = partData?.is_inspection_part || 0;
                    }
                    parts['cost'] = partData?.cost || 0;
                    parts['price'] = partData?.price || 0;
                    parts['is_demo_data'] = partData?.is_demo_data || 0;
                    parts['margin'] = partData?.margin || 0;
                    parts['sell'] = partData?.sell || 0;

                    if (partData?.gst) {
                        parts['gst_price'] = partData?.sell * (part_gst / 100) || 0;
                    }

                    parts['sell_total'] = partData?.sell_total || 0;
                    parts['gst_total'] = partData?.gst_total || 0;
                    parts['profit'] = partData?.profit || 0;
                    parts['gst'] = partData?.gst || 0;
                    parts['time'] = partData?.time || 0;
                    parts['qty'] = partData?.qty || 0;
                    parts['retail'] = partData?.retail || 0;
                    parts['repco_extra_part'] = partData?.repco_extra_part || 0;
                    partsArr.push(parts);
                })
                // setJobParts(partsArr);

                dispatch({
                    type: ADD_MANY_JOB_PARTS,
                    payload: {
                        data: partsArr
                    }
                })
            }

            let laboursArr = [];
            if (createJobLabours !== null && createJobLabours.length !== 0) {
                createJobLabours.map((labourData, index) => {
                    let labours = {};
                    labours['job_schedules_id'] = parentJobId;
                    if (jobType === "Open") {
                        labours['labour_charges_id'] = labourData?.type || 0;
                        labours['cost_price'] = labourData?.cost || 0;
                        labours['margin_markup'] = labourData?.markup || 0;
                        labours['sell_price'] = labourData?.sell || 0;
                        labours['retail_price'] = labourData?.retail || 0;
                        labours['labour_time'] = labourData?.time || 0;
                        labours['total_price'] = labourData?.total || 0;
                    } else {
                        labours['labour_charges_id'] = labourData?.labour_charges_id || 0;
                        labours['cost_price'] = labourData?.cost_price || 0;
                        labours['margin_markup'] = labourData?.margin_markup || 0;
                        labours['sell_price'] = labourData?.sell_price || 0;
                        labours['retail_price'] = labourData?.retail_price || 0;
                        labours['labour_time'] = labourData?.labour_time || 0;
                        labours['total_price'] = labourData?.total_price || 0;
                        labours['is_carried_over_labour'] = 1;
                        labours['is_inspection'] = labourData?.is_inspection || 0;
                    }
                    labours['labour_charge_type'] = labourData?.labour_charge_type || "1";
                    labours['margin'] = labourData?.margin || 0;
                    labours['labour_description'] = labourData?.labour_description || 0;
                    labours['sell_total'] = labourData?.sell_total || 0;
                    labours['gst_total'] = labourData?.gst_total || 0;
                    labours['labour_ph'] = labourData?.labour_ph || 0;
                    labours['profit'] = labourData?.profit || 0;
                    labours['priority'] = labourData?.priority || 0;
                    laboursArr.push(labours);
                })

                laboursArr = laboursArr.map(iLabour => {
                    if (iLabour.labour_charge_type === "2") {
                        if (totalLabourTime && totalLabourTime !== "00:00") {
                            iLabour["labour_ph"] = convertDurationToDecimal(totalLabourTime)
                            iLabour["labour_time"] = totalLabourTime
                        }
                    }
                    return iLabour
                })

                if (jobType === "Open") {
                    if (labourChargesList !== null && labourChargesList !== undefined) {
                        let jobLaboursArr = [];
                        let laboursDataArr = {
                            labour_charges_id: labourChargesList[0].id,
                            job_schedules_id: parentJobId,
                            cost_price: labourChargesList[0].cost_price,
                            margin: labourChargesList[0].margin,
                            sell_price: labourChargesList[0].sell_price,
                            retail_price: labourChargesList[0].retail_price,
                            profit: labourChargesList[0].profit_price,
                            labour_description: labourChargesList[0].description,
                            gst_price: labourChargesList[0].gst_price
                        };

                        ["1", "2", "3"].map(cv => {
                            const copiedData = { ...laboursDataArr, labour_charge_type: cv }
                            if (cv === "3") {
                                copiedData["labour_time"] = createJobData?.data?.JobSchedules?.job_estimate_time
                                copiedData["labour_ph"] = convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                                copiedData["sell_total"] = parseFloat(laboursDataArr.sell_price) * parseFloat(convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time))
                                copiedData["total_price"] = parseFloat(labourChargesList[0].retail_price) * convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                                copiedData["gst_total"] = parseFloat(labourChargesList[0].gst_price) * convertDurationToDecimal(createJobData?.data?.JobSchedules?.job_estimate_time)
                            } else {
                                copiedData["sell_total"] = "0.00"
                                copiedData["total_price"] = "0.00"
                                copiedData["gst_total"] = "0.00"
                            }
                            return jobLaboursArr.push(copiedData)
                        })
                        dispatch({
                            type: ADD_MANY_JOB_LABOURS,
                            payload: {
                                data: jobLaboursArr
                            }
                        })
                    }
                }

                // setJobLabours(laboursArr);
                dispatch({
                    type: ADD_MANY_JOB_LABOURS,
                    payload: {
                        data: laboursArr
                    }
                })
            }
            let jobKitPartsArr = [];
            let jobKitLabourArr = [];
            if (jobType === "Open") {
                if (quoteKitParts !== null && quoteKitParts !== undefined) {
                    quoteKitParts.map((jobKitPart, index) => {
                        let kitPart = {};
                        kitPart['workshop_id'] = workshop_id;
                        kitPart['job_id'] = parentJobId;
                        kitPart['job_parts_id'] = jobKitPart.quotes_parts_id;
                        kitPart['job_kit_labour_id'] = jobKitPart.quote_kit_labour_id;
                        kitPart['kit_id'] = jobKitPart.kit_id;
                        kitPart['part_id'] = jobKitPart.part_id;
                        kitPart['priority'] = jobKitPart.priority;
                        kitPart['cost'] = jobKitPart.cost;
                        kitPart['margin'] = jobKitPart.margin;
                        kitPart['sell'] = jobKitPart.sell;
                        kitPart['qty'] = jobKitPart.qty;
                        kitPart['retail'] = jobKitPart.retail;
                        kitPart['margin_markup'] = jobKitPart.margin_markup;
                        kitPart['gst'] = jobKitPart.gst;
                        kitPart['gst_price'] = jobKitPart.gst_price;
                        kitPart['assigned_qty'] = jobKitPart.assigned_qty;
                        kitPart['purchase_order_qty'] = jobKitPart.purchase_order_qty;
                        kitPart['po_qty_sent'] = jobKitPart.po_qty_sent;
                        kitPart['basic_stock'] = jobKitPart.basic_stock;
                        kitPart['is_assigned'] = jobKitPart.is_assigned;
                        jobKitPartsArr.push(kitPart);
                    })
                    // setJobKitParts(jobKitPartsArr);
                }
            } else {
                if (jobKitPartsData !== null && jobKitPartsData !== undefined) {
                    jobKitPartsData.map((jobKitPart, index) => {
                        let kitPart = {};
                        kitPart['workshop_id'] = workshop_id;
                        kitPart['job_id'] = parentJobId;
                        kitPart['job_parts_id'] = jobKitPart.job_parts_id;
                        kitPart['job_kit_labour_id'] = jobKitPart.job_kit_labour_id;
                        kitPart['is_carried_over_parts'] = 1;
                        kitPart['kit_id'] = jobKitPart.kit_id;
                        kitPart['part_id'] = jobKitPart.part_id;
                        kitPart['priority'] = jobKitPart.priority;
                        kitPart['cost'] = jobKitPart.cost;
                        kitPart['margin'] = jobKitPart.margin;
                        kitPart['sell'] = jobKitPart.sell;
                        kitPart['qty'] = jobKitPart.qty;
                        kitPart['retail'] = jobKitPart.retail;
                        kitPart['margin_markup'] = jobKitPart.margin_markup;
                        kitPart['gst'] = jobKitPart.gst;
                        kitPart['gst_price'] = jobKitPart.gst_price;
                        kitPart['assigned_qty'] = jobKitPart.assigned_qty;
                        kitPart['purchase_order_qty'] = jobKitPart.purchase_order_qty;
                        kitPart['po_qty_sent'] = jobKitPart.po_qty_sent;
                        kitPart['basic_stock'] = jobKitPart.basic_stock;
                        kitPart['is_assigned'] = jobKitPart.is_assigned;
                        jobKitPartsArr.push(kitPart);
                    })
                    // setJobKitParts(jobKitPartsArr);
                }
            }
            dispatch({
                type: ADD_MANY_JOB_KIT_PARTS,
                payload: {
                    data: jobKitPartsArr
                }
            })

            if (jobType === "Open") {
                if (quoteKitLabours !== null && quoteKitLabours !== undefined) {
                    quoteKitLabours.map((jobKitLabour, index) => {
                        let kitLabour = {};
                        kitLabour['workshop_id'] = workshop_id;
                        kitLabour['job_id'] = parentJobId;
                        kitLabour['kit_id'] = jobKitLabour.kit_id;
                        kitLabour['labour_charge_id'] = jobKitLabour.labour_charge_id;
                        kitLabour['cost_price'] = jobKitLabour.cost_price;
                        kitLabour['margin'] = jobKitLabour.margin;
                        kitLabour['sell_price'] = jobKitLabour.sell_price;
                        kitLabour['qty_hours'] = jobKitLabour.qty_hours;
                        kitLabour['retail_price'] = jobKitLabour.retail_price;
                        kitLabour['margin_markup'] = jobKitLabour.margin_markup;
                        kitLabour['gst'] = jobKitLabour.gst;
                        kitLabour['gst_price'] = jobKitLabour.gst_price;
                        kitLabour['discription'] = jobKitLabour.discription;
                        jobKitLabourArr.push(kitLabour);
                    })
                }
            } else {
                if (jobKitLaboursData !== null && jobKitLaboursData !== undefined) {
                    let jobKitLabourArr = [];
                    jobKitLaboursData.map((jobKitLabour, index) => {
                        let kitLabour = {};
                        kitLabour['is_carried_over_labour'] = 1;
                        kitLabour['workshop_id'] = workshop_id;
                        kitLabour['job_id'] = parentJobId;
                        kitLabour['kit_id'] = jobKitLabour.kit_id;
                        kitLabour['labour_charge_id'] = jobKitLabour.labour_charge_id;
                        kitLabour['cost_price'] = jobKitLabour.cost_price;
                        kitLabour['margin'] = jobKitLabour.margin;
                        kitLabour['sell_price'] = jobKitLabour.sell_price;
                        kitLabour['qty_hours'] = jobKitLabour.qty_hours;
                        kitLabour['retail_price'] = jobKitLabour.retail_price;
                        kitLabour['margin_markup'] = jobKitLabour.margin_markup;
                        kitLabour['gst'] = jobKitLabour.gst;
                        kitLabour['gst_price'] = jobKitLabour.gst_price;
                        kitLabour['discription'] = jobKitLabour.discription;
                        jobKitLabourArr.push(kitLabour);
                    })

                    // setJobKitLabours(jobKitLabourArr);

                }
            }
            dispatch({
                type: ADD_MANY_JOB_KIT_LABOURS,
                payload: {
                    data: jobKitLabourArr
                }
            })

            // Add Recommendation

            if (createJobRecommendation.length !== 0 && createJobRecommendation !== null) {
                addRecommendations();
            }

            if (message.length !== 0 && message !== null) {
                addJobMessages();
            }

            if (internalNotes.length !== 0 && internalNotes !== null) {
                addJobInternalNotes();
            }

            if (attachment.length !== 0 && attachment !== null) {
                addJobAttachment();
            }
            if (jobNotesData?.length !== 0 && jobNotesData !== null) {
                addJobNotesData();
            }

            if (jobType === "Open") {
                changeQuoteStatus();
            }
            // setLoading(false);
            setUpdateCalendar(!updateCalendar);
            if (jobType != "Open") {
                handleRescheduleClose();
            }
            setCallDispatchActionForCarryOver(false);
        }
    }, [parentJobId, callDispatchActionForCarryOver])

    // Add Recommendation
    async function addRecommendations() {
        let recommendationsArr = [];
        createJobRecommendation.map((recommendationData, index) => {
            let recommendations = {};
            recommendations['job_id'] = parentJobId;
            recommendations['workshop_id'] = workshop_id;
            recommendations['vehicle_id'] = recommendationData?.vehicle_id;
            recommendations['recommendation'] = recommendationData?.recommendation;
            recommendations['recommendation_color'] = recommendationData?.recommendation_color;
            recommendations['recommendation_status'] = recommendationData?.recommendation_status;
            recommendationsArr.push(recommendations);
        });

        let recommDetail = {
            payload: recommendationsArr,
        };

        await addManyRecommendations(recommDetail);
    }

    // Add Job Messages
    async function addJobMessages() {
        let messagesArr = [];
        message.map((messageData, index) => {
            let messages = {};
            messages['to_id'] = messageData?.to_id;
            messages['from_id'] = messageData?.from_id;
            messages['foreign_id'] = parentJobId;
            messages['workshop_id'] = workshop_id;
            messages['job_id'] = messageData?.job_id;
            messages['type'] = messageData?.type;
            messages['title'] = messageData?.title;
            messages['msg'] = messageData?.msg;
            messages['mail_body'] = messageData?.mail_body;
            messages['email_status'] = messageData?.email_status;
            messages['msg_status'] = messageData?.msg_status;
            messages['notification_status'] = messageData?.notification_status;
            messages['is_read'] = messageData?.is_read || 0;
            if (messageData?.read_datetime) {
                messages['read_datetime'] = messageData?.read_datetime;
            }
            messages['payload'] = messageData?.payload;
            if (messageData?.budget_sms_status) {
                messages['budget_sms_status'] = messageData?.budget_sms_status;
            }
            messagesArr.push(messages);
        });

        let notificationDetail = {
            payload: messagesArr,
        };

        await addManyNotification(notificationDetail);
    }

    // Add Job Internal Notes
    async function addJobInternalNotes() {

        internalNotes.map(async (notesData, index) => {

            let data = {
                payload: {
                    job_id: parentJobId,
                    notes: notesData.notes,
                    workshop_id: workshop_id,
                    created_by: window.atob(storedData.user_id),
                },
            };

            let res = await addInternalNotes(data);

            if (notesData?.internal_notes_attachments.length > 0) {

                let attachArr = [];
                notesData?.internal_notes_attachments.map(async (attchData, index) => {

                    let attach = {};
                    attach['internal_notes_id'] = res?.data?.InternalNotes?.id;
                    attach['workshop_id'] = workshop_id;
                    attach['attachment_path'] = attchData?.attachment_path;
                    attach['name'] = attchData?.name;
                    attach['type'] = attchData?.type;
                    attach['size'] = attchData?.size;
                    attach['path'] = attchData?.path;

                    attachArr.push(attach);

                });

                let attachDetail = {
                    payload: attachArr,
                };

                await saveInternalNotesAttachments(attachDetail);
            }
        });
    }

    // Add Job Attachment
    async function addJobAttachment() {

        let attachmentsArr = [];
        attachment.map((attachmentData, index) => {
            let attachments = {};
            attachments['workshop_id'] = workshop_id;
            attachments['job_id'] = parentJobId;
            attachments['type'] = attachmentData?.type;
            attachments['pdf_type'] = attachmentData?.pdf_type;
            attachments['pdf_reference_id'] = attachmentData?.pdf_reference_id;
            attachments['size'] = attachmentData?.size;
            attachments['path'] = attachmentData?.path;
            if (attachmentData?.thumb_path) {
                attachments['thumb_path'] = attachmentData?.thumb_path;
            }
            if (attachmentData?.invoice_attachment) {
                attachments['invoice_attachment'] = attachmentData?.invoice_attachment;
            }
            attachments['label'] = attachmentData?.label;
            attachments['quote_id'] = attachmentData?.quote_id;
            attachmentsArr.push(attachments);
        });

        let attachmentDetail = {
            payload: attachmentsArr,
        };

        await copyAttachment(attachmentDetail);

        // copyAttachment
    }

    // Add Job Notes
    async function addJobNotesData() {


        let jobNotes = {};
        jobNotes['job_id'] = parentJobId;
        jobNotes['work_notes'] = jobNotesData?.work_notes;


        let jobNotesDetail = {
            payload: jobNotes,
        };

        await addJobNotes(jobNotesDetail);

        // copyJobNotes
    }

    useEffect(() => {
        if (jobIdData !== undefined) {
            if (jobType === "carryover") {
                dispatch({
                    type: GET_CARRY_OVER_JOB,
                    payload: {
                        get: "all",
                        conditions: {
                            "JobSchedules.id": jobIdData
                        },
                        contain: {
                            "JobsParts": {},
                            "JobLabours": {},
                            "Recommendations": {},
                            "Vehicles.Users.CustomersDetails": {
                                conditions: {
                                    workshop_id: workshop_id
                                }
                            },
                            "Customers.CustDetails": {
                                conditions: {
                                    "CustDetails.workshop_id": workshop_id
                                }
                            }
                        }
                    }
                })
                dispatch({
                    type: GET_JOBS_KIT_PARTS,
                    payload: {
                        get: "all",
                        conditions: {
                            "job_id": jobIdData
                        }
                    }
                })
                dispatch({
                    type: GET_JOBS_KIT_LABOURS,
                    payload: {
                        get: "all",
                        conditions: {
                            "job_id": jobIdData
                        }
                    }
                })
                dispatch({
                    type: GET_MESSAGE,
                    payload: {
                        get: "all",
                        conditions: {
                            "workshop_id": workshop_id,
                            // "is_read": 0,
                            "foreign_id": jobIdData,
                            "type": "job-script",
                        },
                        order: { id: "asc" },
                    },
                });
                dispatch({
                    type: GET_INTERNAL_NOTES,
                    payload: {
                        get: "all",
                        conditions: {
                            job_id: jobIdData,
                        },
                        contain: ["InternalNotesAttachments", "Users"],
                        order: {
                            "InternalNotes.id": "asc",
                        },
                    },
                });
                dispatch({
                    type: GET_ATTACHMENT,
                    payload: {
                        get: "all",
                        conditions: {
                            "workshop_id": workshop_id,
                            "job_id": jobIdData,
                        },
                    },
                });
                dispatch({
                    type: GET_JOB_NOTES,
                    payload: {
                        get: "all",
                        conditions: {
                            "workshop_id": workshop_id,
                            "job_id": jobIdData,
                        },
                    },
                });
            } else if (jobType === "Open") {
                // dispatch({
                //     type: GET_QUOTE_BOOKED_DETAILS,
                //     payload: {
                //         get: "all",
                //         conditions: {
                //             "CustomerQuotes.id": jobIdData,
                //         },
                //         contain: [
                //             'Vehicles',
                //             'Users.CustomersDetails',
                //             'CustomerQuotesParts',
                //             'CustomerQuotesLabours'
                //         ]

                //     },
                // });

                dispatch({
                    type: GET_QUOTE_BOOKED_DETAILS,
                    payload: {
                        get: "all",
                        conditions: {
                            "CustomerQuotes.id": jobIdData,
                            "CustomerQuotes.workshop_id": workshop_id,
                        },
                        contain: {
                            "Vehicles": {
                                "fields": [
                                    'id',
                                    'user_id',
                                    'rego',
                                    'make',
                                    'model',
                                    'year',
                                    'transmission',
                                    'service_due_date',
                                    'created_on'
                                ]
                            },
                            "WorkshopDepartmentsAccountsDetails": {
                                "fields": ['id', 'name', 'job_type_parent_id']
                            },
                            "JobSubTypes": {
                                "fields": ['id', 'name']
                            },
                            "Users": {
                                "fields": ['id', 'email']
                            },
                            "Users.CustomersDetails": {
                                "fields": ['id', 'user_id', 'firstname', 'lastname', 'type', 'mobile_number', 'company_name', 'mobile_code']
                            },
                            "CustomerQuotesParts": {
                                "fields": [
                                    'product_id',
                                    'cost',
                                    'margin',
                                    'sell',
                                    'retail',
                                    'qty',
                                    'price',
                                    'profit',
                                    'time',
                                    'qty',
                                    'gst',
                                    'quote_part_priority',
                                    'customer_quotes_id',
                                    'quote_part_name',
                                    'quote_part_description',
                                    'id',
                                    'gst_total',
                                    'sell_total'
                                ]
                            },
                            "CustomerQuotesParts.Products": {
                                "fields": ['id', 'product_type']
                            },
                            "CustomerQuotesLabours": {
                                "fields": [
                                    'id',
                                    'type',
                                    'cost',
                                    'markup',
                                    'sell',
                                    'retail',
                                    'labour_description',
                                    'time',
                                    'total',
                                    'profit',
                                    'customer_quotes_id',
                                    'labour_ph',
                                    'sell_total',
                                    'gst_total'
                                ]
                            }
                        },
                        fields: [
                            'workshop_id',
                            'Users.id',
                            'id',
                            'rego',
                            'vehicle_id',
                            'user_id',
                            'job_department',
                            'description',
                            'job_type',
                            'reason_description',
                            'total_cost',
                            'quote_number',
                            'parts_cost',
                            'labour_cost',
                            'parts_markup',
                            'labour_markup',
                            'parts_total_cost',
                            'labour_total_cost',
                            'labour_parts_total_cost',
                            'gst',
                            'total_cost',
                            'discount_rate'
                        ]
                    }
                });
                dispatch({
                    type: GET_QUOTE_KIT_PARTS,
                    payload: {
                        get: "all",
                        conditions: {
                            "quote_id": jobIdData,
                            "workshop_id": workshop_id
                        }
                    }
                })
                dispatch({
                    type: GET_QUOTE_KIT_LABOURS,
                    payload: {
                        get: "all",
                        conditions: {
                            "quote_id": jobIdData,
                            "workshop_id": workshop_id
                        }
                    }
                })
            } else {
                dispatch({
                    type: GET_CARRY_OVER_JOB,
                    payload: {
                        get: "all",
                        conditions: {
                            "JobSchedules.id": jobIdData
                        },
                        contain: {
                            "Vehicles.Users.CustomersDetails": {
                                conditions: {
                                    workshop_id: workshop_id
                                }
                            },
                            "Customers.CustDetails": {
                                conditions: {
                                    "CustDetails.workshop_id": workshop_id
                                }
                            }
                        }
                    }
                })
            }
        }
    }, [jobIdData, jobType])

    useEffect(() => {

        if (jobDetailsData !== null || jobDetailsData !== undefined) {
            var custname = '';
            var contact = '';
            var make = '';
            var model = '';
            var year = '';
            var rego = jobDetailsData?.[0]?.rego;
            var custDetails = jobDetailsData?.[0]?.customer?.cust_detail || null;

            var customerApp = jobDetailsData?.[0]?.customer?.cust_detail?.customer_app || null;
            if (custDetails !== null) {
                if (custDetails.type === "2") {
                    custname = custDetails.firstname + " " + custDetails.lastname;
                } else {
                    custname = custDetails.company_name;
                }
                contact = custDetails.mobile_number;
            }
            var email = jobDetailsData?.[0]?.vehicle?.user?.email || '';
            var vehicleDetails = jobDetailsData?.[0]?.vehicle || null;
            if (vehicleDetails !== null) {
                make = vehicleDetails.make;
                model = vehicleDetails.model;
                year = vehicleDetails.year;
                // rego = vehicleDetails.rego;
            }
            var jobEstimate = jobDetailsData?.[0]?.job_estimate_time || '00:00';
            var pickupDate = jobDetailsData?.[0]?.pickup_time || '';
            if (pickupDate !== "") {
                pickupDate = moment(pickupDate).format('YYYY-MM-DD');
            }
            var pickupTime = jobDetailsData?.[0]?.pickup_time_only || '00:00';
            if (pickupTime !== "") {
                pickupTime = moment(pickupTime).format('HH:mm');
            }

            setRescheduleFormData({
                ...rescheduleFormData,
                customer_name: custname,
                mobile_number: contact,
                mobile_code: custDetails?.mobile_code,

                job_number: jobDetailsData?.[0]?.job_number,

                job_id: jobDetailsData?.[0]?.id,

                cust_id: custDetails?.id,
                customer_id: custDetails?.user_id,

                email: email,
                make: make,
                model: model,
                year: year,
                rego: rego,
                job_estimate_time: jobEstimate,
                pickup_date: pickupDate,
                pickup_time: pickupTime,
                customer_app: customerApp
            });

            if (jobType === "carryover") {
                var jobPartsArr = jobDetailsData?.[0]?.['jobs_parts'] || [];
                var jobLaboursArr = jobDetailsData?.[0]?.['job_labours'] || [];
                var jobRecommendationsArr = jobDetailsData?.[0]?.['recommendations'] || [];
                var copyJobCount = jobDetailsData?.[0]?.is_copy_job || 0;
                copyJobCount = copyJobCount + 1;
                setCreateJobParts(jobPartsArr);
                setCreateJobLabours(jobLaboursArr);
                setCreateJobRecommendation(jobRecommendationsArr);

                // if(message.length > 0){
                //     setCreateJobMessages(message);
                // }

                var custId = jobDetailsData?.[0]?.customer?.cust_detail?.user_id || null;
                var vehId = jobDetailsData?.[0]?.vehicle?.id || null;
                var createJobType = jobDetailsData?.[0]?.job_type || null;
                var jobDeptId = jobDetailsData?.[0]?.job_department_id || null;
                var tags = jobDetailsData?.[0]?.tags_id || [];
                var description = jobDetailsData?.[0]?.description || null;
                var jobStatus = jobDetailsData?.[0]?.job_status || null;
                var jobStage = jobDetailsData?.[0]?.job_stage || null;
                var customerType = jobDetailsData?.[0]?.customer_vehicle_option || 0;
                var InspectionId = jobDetailsData?.[0]?.inspections_id || 0;
                var inspReferenceId = jobDetailsData?.[0]?.insp_reference_id || 0;
                var inspTechnicianId = jobDetailsData?.[0]?.insp_technician_id || 0;
                var insuranceCompanyId = jobDetailsData?.[0]?.insurance_company_id || 0;
                var inspCertificateId = jobDetailsData?.[0]?.insp_certificate_id || 0;
                var claimNumber = jobDetailsData?.[0]?.claim_number || 0;
                var bookinSource = jobDetailsData?.[0]?.bookin_source || null;
                var sourceOfBusiness = jobDetailsData?.[0]?.source_of_business || null;
                var vehicleServiceDepartment = jobDetailsData?.[0]?.vehicle_service_department || null;
                var jobTypeParentId = jobDetailsData?.[0]?.job_type_parent_id || null;
                var employeesDetailsId = jobDetailsData?.[0]?.employees_details_id || 0;
                var isOnLeave = jobDetailsData?.[0]?.is_on_leave || 0;
                var partsCost = jobDetailsData?.[0]?.parts_cost || 0;
                var labourCost = jobDetailsData?.[0]?.labour_cost || 0;
                var partsMarkup = jobDetailsData?.[0]?.parts_markup || 0;
                var labourMarkup = jobDetailsData?.[0]?.labour_markup || 0;
                var partsTotalCost = jobDetailsData?.[0]?.parts_total_cost || 0;
                var labourTotalCost = jobDetailsData?.[0]?.labour_total_cost || 0;
                var labourTime = jobDetailsData?.[0]?.labour_time || 0;
                var subTotal = jobDetailsData?.[0]?.sub_total || 0;
                var gst = jobDetailsData?.[0]?.gst || 0;
                var discountRate = jobDetailsData?.[0]?.discount_rate || 0;
                var discountName = jobDetailsData?.[0]?.discount_name || 0;
                var discountCost = jobDetailsData?.[0]?.discount_cost || 0;
                var discountType = jobDetailsData?.[0]?.discount_type || 0;
                var discountCode = jobDetailsData?.[0]?.discount_code || 0;
                var jobDiscountFromId = jobDetailsData?.[0]?.jobs_discount_from_id || 0;
                var referralUserId = jobDetailsData?.[0]?.referral_user_id || 0;
                var isDiscountAppliedByCustomer = jobDetailsData?.[0]?.is_discount_applied_by_customer || 0;
                var jobAddInCalendar = jobDetailsData?.[0]?.job_add_in_calendar || 0;
                var discountEndDate = jobDetailsData?.[0]?.discount_end_date || "";
                var total = jobDetailsData?.[0]?.total || 0;
                var nextServiceId = jobDetailsData?.[0]?.next_service_id || 0;
                var time = jobDetailsData?.[0]?.time || 0;
                var nextServiceDue = jobDetailsData?.[0]?.next_service_due || "";
                var motWotDuration = jobDetailsData?.[0]?.mot_wot_duration || "";
                var motWotDue = jobDetailsData?.[0]?.mot_wot_due || "";
                var odometerReading = jobDetailsData?.[0]?.odometer_reading || 0;
                var duration = jobDetailsData?.[0]?.duration || 0;
                var clocked_in_time = jobDetailsData?.[0]?.clocked_in_time || null;
                var is_default_time = jobDetailsData?.[0]?.is_default_time || 1;

                // For carry over job details 
                setRescheduleFormData({
                    ...rescheduleFormData,
                    customer_name: custname,
                    mobile_number: contact,
                    email: email,
                    make: make,
                    model: model,
                    year: year,
                    rego: rego,
                    job_estimate_time: jobEstimate,
                    pickup_date: pickupDate,
                    pickup_time: pickupTime,
                    customer_app: customerApp,
                    duration: duration,
                    cust_id: custId,
                    vehicle_id: vehId,
                    job_type: createJobType,
                    job_department_id: jobDeptId,
                    tags_id: tags,
                    description: description,
                    job_status: jobStatus,
                    job_stage: jobStage,
                    customer_vehicle_option: customerType,
                    job_number: jobNumber,
                    inspections_id: InspectionId,
                    insp_reference_id: inspReferenceId,
                    insp_technician_id: inspTechnicianId,
                    insurance_company_id: insuranceCompanyId,
                    insp_certificate_id: inspCertificateId,
                    claim_number: claimNumber,
                    bookin_source: bookinSource,
                    source_of_business: sourceOfBusiness,
                    vehicle_service_department: vehicleServiceDepartment,
                    job_type_parent_id: jobTypeParentId,
                    timer_status: 'stop',
                    employees_details_id: employeesDetailsId,
                    is_on_leave: isOnLeave,
                    parts_cost: partsCost,
                    labour_cost: labourCost,
                    parts_markup: partsMarkup,
                    labour_markup: labourMarkup,
                    parts_total_cost: partsTotalCost,
                    labour_total_cost: labourTotalCost,
                    labour_time: labourTime,
                    sub_total: subTotal,
                    gst: gst,
                    discount_rate: discountRate,
                    discount_name: discountName,
                    discount_cost: discountCost,
                    discount_type: discountType,
                    discount_code: discountCode,
                    jobs_discount_from_id: jobDiscountFromId,
                    referral_user_id: referralUserId,
                    is_discount_applied_by_customer: isDiscountAppliedByCustomer,
                    job_add_in_calendar: jobAddInCalendar,
                    discount_end_date: discountEndDate,
                    total: total,
                    next_service_id: nextServiceId,
                    time: time,
                    next_service_due: nextServiceDue,
                    mot_wot_duration: motWotDuration,
                    mot_wot_due: motWotDue,
                    odometer_reading: odometerReading,
                    job_parent_id: jobIdData,
                    is_copy_job: copyJobCount,
                    clocked_in_time: clocked_in_time,
                    is_default_time: is_default_time
                });
            } else {
                setRescheduleFormData({
                    ...rescheduleFormData,
                    customer_name: custname,
                    mobile_number: contact,
                    mobile_code: custDetails?.mobile_code,
                    job_number: jobDetailsData?.[0]?.job_number,
                    job_id: jobDetailsData?.[0]?.id,
                    cust_id: custDetails?.id,
                    customer_id: custDetails?.user_id,
                    email: email,
                    make: make,
                    model: model,
                    year: year,
                    rego: rego,
                    job_estimate_time: jobEstimate,
                    pickup_date: pickupDate,
                    pickup_time: pickupTime,
                    customer_app: customerApp
                });
            }

            setCustId(custDetails?.id);
            setJobId(jobDetailsData?.[0]?.id);
            setCustomerId(custDetails?.user_id);
            setMobileCode(custDetails?.mobile_code)
            setRego(rego);
            setSmsJobNumber(jobDetailsData?.[0]?.job_number);
            setCustomerName(custname);
            setMobileNumber(contact);
        }
    }, [jobDetailsData])

    useEffect(() => {
        if (jobBookedData !== null && jobBookedData !== undefined) {
            var custname = "";
            var contact = "";
            var make = "";
            var model = "";
            var year = "";
            var rego = jobBookedData?.rego;
            var custDetails = jobBookedData?.user?.customers_details?.[0] || null;
            var customerApp = jobBookedData?.user?.customers_details?.[0]?.customer_app || null;
            if (custDetails !== null) {
                if (custDetails.type == "2") {
                    custname = custDetails.firstname + " " + custDetails.lastname;
                } else {
                    custname = custDetails.company_name;
                }
                contact = custDetails.mobile_number;
            }
            var email = jobBookedData?.user?.email || "";
            var vehicleDetails = jobBookedData?.vehicle || null;
            if (vehicleDetails !== null) {
                make = vehicleDetails.make;
                model = vehicleDetails.model;
                year = vehicleDetails.year;
                rego = vehicleDetails.rego;
            }

            if (jobType === "Open") {
                var jobPartsArr = jobBookedData?.customer_quotes_parts || [];
                var jobLaboursArr = jobBookedData?.customer_quotes_labours || [];
                setCreateJobParts(jobPartsArr);
                setCreateJobLabours(jobLaboursArr);
                var techTimeSlots = workshopOtherDetails[0].technician_timeslot;

                var custId = jobBookedData?.user_id || null;
                var vehId = jobBookedData?.vehicle_id || null;
                var jobDeptId = jobBookedData?.job_department || null;
                var description = jobBookedData?.description || null;
                var partsCost = jobBookedData?.parts_cost || 0;
                var labourCost = jobBookedData?.labour_cost || 0;
                var partsMarkup = jobBookedData?.parts_markup || 0;
                var labourMarkup = jobBookedData?.labour_markup || 0;
                var partsTotalCost = jobBookedData?.parts_total_cost || 0;
                var labourTotalCost = jobBookedData?.labour_total_cost || 0;
                var subTotal = jobBookedData?.labour_parts_total_cost || 0;
                var gst = jobBookedData?.gst || 0;
                var discountRate = jobBookedData?.discount_rate || 0;
                var discountCost = jobBookedData?.discount_cost || 0;
                var total = jobBookedData?.total_cost || 0;
                var job_type = jobBookedData?.job_type || 0;
                var duration = techTimeSlots;
                var job_estimate_time = convertToHours(techTimeSlots);

                setCustId(custDetails?.id);
                setCustomerId(custDetails?.user_id);
                setMobileCode(custDetails?.mobile_code)
                setRego(rego);
                setCustomerName(custname);
                setMobileNumber(contact);

                // For convert quote to job details
                setRescheduleFormData({
                    ...rescheduleFormData,
                    customer_name: custname,
                    mobile_number: contact,
                    email: email,
                    make: make,
                    model: model,
                    year: year,
                    rego: rego,
                    customer_app: customerApp,
                    job_estimate_time: job_estimate_time,
                    duration: duration,
                    cust_id: custId,
                    vehicle_id: vehId,
                    job_department_id: jobDeptId,
                    job_type: job_type,
                    description: description,
                    job_number: jobNumber,
                    timer_status: "stop",
                    parts_cost: partsCost,
                    labour_cost: labourCost,
                    parts_markup: partsMarkup,
                    labour_markup: labourMarkup,
                    parts_total_cost: partsTotalCost,
                    labour_total_cost: labourTotalCost,
                    sub_total: subTotal,
                    gst: gst,
                    discount_rate: discountRate,
                    discount_cost: discountCost,
                    total: total
                });
            }
        }
    }, [jobBookedData]);

    useEffect(() => {
        let technicianList = [];
        if ((employeeDetails !== null || employeeDetails !== undefined)) {
            employeeDetails?.map((employee, index) => {
                let technicianListArr = [];
                technicianListArr['data_type'] = 'res_tech';
                technicianListArr['name'] = employee.firstname + " " + employee.lastname;
                technicianListArr['id'] = employee.id;
                var employeeWorkingDayArr = [];
                if (employee.employees_details[0] !== "" && employee.employees_details[0] !== undefined && employee.employees_details[0] !== null) {
                    if (employee.employees_details[0].shift_working_day) {
                        employeeWorkingDayArr = unserializeArr(employee.employees_details[0].shift_working_day);
                    }
                }
                employeeWorkingDayArr = employeeWorkingDayArr.map(parseFloat);
                technicianListArr['working_days'] = 0;
                if (employeeWorkingDayArr.includes(currentDay)) {
                    technicianListArr['working_days'] = 1;
                    if (defaultSelect === true) {
                        var name = employee.firstname + " " + employee.lastname;
                        setEmployeeName(name);
                        setEmployeeId(employee.id);
                        setEmpDataType('res_tech');
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        defaultSelect = false;
                    }
                }
                technicianList.push(technicianListArr);
            })
        }

        if (workshopBayData !== null || workshopBayData !== undefined) {
            workshopBayData?.map((bays, index) => {
                let baysArr = [];
                baysArr['data_type'] = 'res_bay';
                baysArr['name'] = bays.name;
                baysArr['id'] = bays.id;
                baysArr['working_days'] = 1;
                if (defaultSelect === true) {
                    setEmployeeName(bays.name);
                    setEmpDataType('res_bay');
                    setEmployeeId(bays.id);
                    defaultSelect = false;
                }
                technicianList.push(baysArr);
            })
        }
        setEmployeeList(technicianList);
    }, [employeeDetails, workshopBayData, currentDay])

    const changeBookedDate = (date) => {
        setEmpDataType('res_tech');
        // setEmployeeId('');
        var currentDate = moment(date).format('YYYY-MM-DD');
        setTitleDate(moment(date).format(date_format));
        setCurrentDate(currentDate);
        setCurrentDay(new Date(currentDate).getDay());
    }

    useEffect(() => {
        var startTime = utcToTimezone(workshopOtherDetails?.[0]?.shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var endTimeWorkshop = utcToTimezone(workshopOtherDetails?.[0]?.shift_finish_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');

        if (schedule_details != null) {
            let scheduleDetails = unserializeArr(schedule_details);
            if (scheduleDetails[currentDay]['open_time'] !== '' && scheduleDetails[currentDay]['close_time'] !== '' && scheduleDetails[currentDay]['status'] !== 0 && scheduleDetails[currentDay]['status'] !== '0') {
                startTime = utcToTimezone(scheduleDetails[currentDay]['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                endTimeWorkshop = utcToTimezone(scheduleDetails[currentDay]['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');

                setStartTime(utcToTimezone(scheduleDetails[currentDay]['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss'));
                setEndTime(utcToTimezone(scheduleDetails[currentDay]['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss'));
                if (endTime === "00:00:00") {
                    setEndTime("24:00:00");
                }

            }
        }
        // var startTime = utcToTimezone(workshopOtherDetails[0].shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var fromDate = currentDate + " " + startTime;
        setFromDate(fromDate);

        if (pickupTimeSlot.length === 0) {
            var startTime1 = moment().utc().set({ hour: moment(startTime, 'HH:mm:ss').hours(), minute: moment(startTime, 'HH:mm:ss').minutes() });
            var endTime1 = moment().utc().set({ hour: moment(endTimeWorkshop, 'HH:mm:ss').hours(), minute: moment(endTimeWorkshop, 'HH:mm:ss').minutes() });


            var timeStops = [];
            var afterTimeStops = [];
            while (startTime1 < endTime1) {
                if (new moment(startTime1).format('HH:mm A') >= "12:00") {
                    afterTimeStops.push(new moment(startTime1).format('HH:mm'));
                    startTime1.add(30, 'minutes');
                } else {
                    timeStops.push(new moment(startTime1).format('HH:mm'));
                    startTime1.add(30, 'minutes');
                }

            }

            setPickupTimeSlot(timeStops);
            setAfterPickupTimeSlot(afterTimeStops);


        }

        // var pickTimeSlot = moment(endTimeWorkshop, 'HH:mm:ss').hours() + ":" + moment(endTimeWorkshop, 'HH:mm:ss').minutes();
        // setSelectedPickupTimeSlot(pickTimeSlot);

    }, [currentDate])

    const handleTechnicianSlot = (e) => {
        setEmpDataType(e.dataset.id);
        var startTime = utcToTimezone(workshopOtherDetails[0].shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        if (schedule_details != null) {
            let scheduleDetails = unserializeArr(schedule_details);
            if (scheduleDetails[currentDay]['open_time'] !== '' && scheduleDetails[currentDay]['close_time'] !== '' && scheduleDetails[currentDay]['status'] !== 0 && scheduleDetails[currentDay]['status'] !== '0') {
                startTime = utcToTimezone(scheduleDetails[currentDay]['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss')
            }
        }
        // var startTime = utcToTimezone(workshopOtherDetails[0].shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var fromDate = currentDate + " " + startTime;
        setFromDate(fromDate);
    }

    useEffect(() => {
        if (employeeId !== "") {
            setTimeSlot(null);
            setDisabledSave('disabled');
            if (empDataType === "res_tech") {
                dispatch({
                    type: GET_AVAIALABLE_SLOTS,
                    payload: {
                        workshop_id: workshop_id,
                        from_date: fromDate,
                        days: currentDay,
                        'job_stage NOT IN': ['Archive'],
                        user_id: employeeId,
                        'is_copid_job': 0
                    }
                })
            } else {
                dispatch({
                    type: GET_AVAIALABLE_SLOTS,
                    payload: {
                        workshop_id: workshop_id,
                        from_date: fromDate,
                        days: currentDay,
                        'job_stage NOT IN': ['Archive'],
                        user_id: 0,
                        bay_id: employeeId,
                        'is_copid_job': 0
                    }
                })
            }
        }
    }, [fromDate, employeeId])

    useEffect(() => {
        if (schedulerData !== null && schedulerData !== undefined) {
            var techTimeSlots = workshopOtherDetails[0].technician_timeslot;
            let slotsArrList = [];
            let eveSlotsArrList = [];
            for (var key in schedulerData) {
                let slotsArr = [];
                var startTimeSlots = moment.utc(key, 'hh:mm:ss').format('hh:mm A');
                var endTimeSlots = moment.utc(key, 'hh:mm:ss').add(techTimeSlots, 'minutes').format('hh:mm A');
                slotsArr['start_time'] = key;
                // slotsArr['slots'] = startTimeSlots + " - " + endTimeSlots;
                slotsArr['slots'] = startTimeSlots;
                slotsArr['available'] = schedulerData[key][0];
                // slotsArrList.push(slotsArr);
                if (key >= "12:00:00") {
                    eveSlotsArrList.push(slotsArr);
                } else {
                    slotsArrList.push(slotsArr);
                }
            }
            setTimeSlotsList(slotsArrList);
            setEveTimeSlotsList(eveSlotsArrList);
        }
    }, [schedulerData])

    const handlePickupDate = (date) => {
        rescheduleFormData.pickup_date = moment(date).format('YYYY-MM-DD');
    }
    const handleRescheduleFormData = (e) => {
        const { name, value } = e.target;
        setRescheduleFormData({
            ...rescheduleFormData,
            [name]: value,
        });
    }



    async function getJobEstimateTimeData(from_date, from_time, user_id) {

        let timeSlotData = {
            from_date: moment(from_date).format("YYYY-MM-DD"),
            from_time: from_time,
            workshop_id: workshop_id,
            workshop_end_time: endTime,
            user_id: user_id
        }

        const resp = await getEstimateTimeSlot({ payload: timeSlotData });

        if (resp?.data?.JobSchedules?.time_slot) {
            setEstimateTimeSlot(resp?.data?.JobSchedules?.time_slot);
            var timeSlotList = [];
            if (resp?.data?.JobSchedules?.time_slot.length !== 0 && resp?.data?.JobSchedules?.time_slot !== null) {
                resp?.data?.JobSchedules?.time_slot.map((timeSlot) => (
                    timeSlotList.push({
                        value: timeSlot,
                        label: timeSlot
                    })
                ))
            }

            setJobEstimateTimeSlot(timeSlotList);
        }
    }

    const handleSaveForm = () => {
        var startDate = currentDate;
        var startTime = timeSlot;
        var startTimeSlots = moment(startDate + " " + startTime).format('YYYY-MM-DD HH:mm:ss');
        // var techTimeSlots = workshopOtherDetails[0].technician_timeslot;
        var techTimeSlots = durationJob > 0 ? durationJob : workshopOtherDetails[0].technician_timeslot;
        var startTimeJob = timeZoneToUTC(startTimeSlots, timezoneLable, "YYYY-MM-DD HH:mm:ss", 'HH:mm:ss');
        var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(techTimeSlots, 'minutes').format('HH:mm:ss');
        var userId = 0;
        var bayId = 0;
        if (empDataType === "res_tech") {
            userId = employeeId;
        } else {
            bayId = employeeId;
        }
        let createJobArr = {};

        createJobArr['user_id'] = userId;
        createJobArr['bay_id'] = bayId;
        createJobArr['from_date'] = startDate;
        createJobArr['to_date'] = startDate;
        createJobArr['from_time'] = startTimeJob;
        createJobArr['to_time'] = endTimeJob;
        createJobArr['pickup_time_only'] = rescheduleFormData.pickup_time;
        createJobArr['pickup_time'] = rescheduleFormData.pickup_date;
        createJobArr['insurance_company_id'] = rescheduleFormData.insurance_company_id;
        createJobArr['claim_number'] = rescheduleFormData.claim_number;
        createJobArr['purchase_order_number'] = rescheduleFormData.purchase_order_number;

        if (jobType === "carryover") {

            createJobArr['duration'] = rescheduleFormData.duration;
            createJobArr['workshop_id'] = workshop_id;
            createJobArr['created_by'] = user_id;
            createJobArr['customer_id'] = rescheduleFormData.cust_id;
            // createJobArr['customer_id'] = jobDetailsData[0]?.customer_id;
            createJobArr['vehicle_id'] = rescheduleFormData.vehicle_id;
            createJobArr['job_type'] = rescheduleFormData.job_type;
            createJobArr['job_department_id'] = rescheduleFormData.job_department_id;
            createJobArr['tags_id'] = rescheduleFormData.tags_id;
            createJobArr['description'] = rescheduleFormData.description;
            createJobArr['job_status'] = 'Pending';
            createJobArr['job_stage'] = 'Bookin';
            // createJobArr['clocked_in_time'] = rescheduleFormData?.clocked_in_time;
            // createJobArr['timer_start_time'] = rescheduleFormData?.clocked_in_time;
            if (unassignedFlag === true) {
                createJobArr['job_status'] = 'Unassigned';
                createJobArr['job_stage'] = 'Unassigned';
                createJobArr['carry_over_to_unassign'] = 1;
                createJobArr['user_id'] = 0;
            }
            createJobArr['customer_vehicle_option'] = rescheduleFormData.customer_vehicle_option;
            createJobArr['job_number'] = rescheduleFormData.job_number;
            createJobArr['inspections_id'] = rescheduleFormData.inspections_id;
            createJobArr['insp_reference_id'] = rescheduleFormData.insp_reference_id;
            createJobArr['insp_technician_id'] = rescheduleFormData.insp_technician_id;
            createJobArr['insurance_company_id'] = rescheduleFormData.insurance_company_id;
            createJobArr['insp_certificate_id'] = rescheduleFormData.insp_certificate_id;
            createJobArr['claim_number'] = rescheduleFormData.claim_number;
            createJobArr['bookin_source'] = rescheduleFormData.bookin_source;
            createJobArr['source_of_business'] = rescheduleFormData.source_of_business;
            createJobArr['vehicle_service_department'] = rescheduleFormData.vehicle_service_department;
            createJobArr['job_type_parent_id'] = rescheduleFormData.job_type_parent_id;
            createJobArr['timer_status'] = 'stop';
            // createJobArr['employees_details_id'] = rescheduleFormData.employees_details_id;
            createJobArr['is_on_leave'] = rescheduleFormData.is_on_leave;
            createJobArr['parts_cost'] = rescheduleFormData.parts_cost;
            createJobArr['labour_cost'] = rescheduleFormData.labour_cost;
            createJobArr['parts_markup'] = rescheduleFormData.parts_markup;
            createJobArr['labour_markup'] = rescheduleFormData.labour_markup;
            createJobArr['parts_total_cost'] = rescheduleFormData.parts_total_cost;
            createJobArr['labour_total_cost'] = rescheduleFormData.labour_total_cost;
            createJobArr['labour_time'] = rescheduleFormData.labour_time;
            createJobArr['sub_total'] = rescheduleFormData.sub_total;
            createJobArr['gst'] = rescheduleFormData.gst;
            createJobArr['discount_rate'] = rescheduleFormData.discount_rate;
            createJobArr['discount_name'] = rescheduleFormData.discount_name;
            createJobArr['discount_cost'] = rescheduleFormData.discount_cost;
            createJobArr['discount_type'] = rescheduleFormData.discount_type;
            createJobArr['discount_code'] = rescheduleFormData.discount_code;
            createJobArr['jobs_discount_from_id'] = rescheduleFormData.jobs_discount_from_id;
            createJobArr['referral_user_id'] = rescheduleFormData.referral_user_id;
            createJobArr['is_discount_applied_by_customer'] = rescheduleFormData.is_discount_applied_by_customer;
            createJobArr['job_add_in_calendar'] = rescheduleFormData.job_add_in_calendar;
            createJobArr['discount_end_date'] = rescheduleFormData.discount_end_date;
            createJobArr['total'] = rescheduleFormData.total;
            createJobArr['next_service_id'] = rescheduleFormData.next_service_id;
            createJobArr['time'] = rescheduleFormData.time;
            createJobArr['next_service_due'] = rescheduleFormData.next_service_due;
            createJobArr['mot_wot_duration'] = rescheduleFormData.mot_wot_duration;
            createJobArr['mot_wot_due'] = rescheduleFormData.mot_wot_due;
            createJobArr['odometer_reading'] = rescheduleFormData.odometer_reading;
            createJobArr['job_parent_id'] = rescheduleFormData.job_parent_id;
            createJobArr['is_copy_job'] = rescheduleFormData.is_copy_job;
            createJobArr['job_estimate_time'] = rescheduleFormData.job_estimate_time;
            createJobArr['is_default_time'] = rescheduleFormData.is_default_time;
            createJobArr["duration"] = durationJob;
            copyJob(createJobArr);
        } else if (jobType === "Open") {
            // let quoteToJob = {};
            createJobArr['workshop_id'] = workshop_id;
            createJobArr['created_by'] = user_id;
            createJobArr['customer_id'] = rescheduleFormData.cust_id;
            // createJobArr['customer_id'] = jobDetailsData[0]?.customer_id;
            createJobArr['vehicle_id'] = rescheduleFormData.vehicle_id;
            createJobArr['job_type'] = rescheduleFormData.job_type || '';
            createJobArr['job_department_id'] = rescheduleFormData.job_department_id || '';
            createJobArr['duration'] = rescheduleFormData.duration;
            createJobArr['tags_id'] = {};
            createJobArr['description'] = rescheduleFormData.description || '';
            createJobArr['job_status'] = 'Pending';
            createJobArr['job_stage'] = 'Bookin';
            createJobArr['insp_reference_id'] = 0;
            createJobArr['bookin_source'] = 2;
            createJobArr['source_of_business'] = 'Existing';
            createJobArr['customer_quotes_id'] = jobIdData;
            createJobArr['parts_cost'] = rescheduleFormData.parts_cost || '0.00';
            createJobArr['labour_cost'] = rescheduleFormData.labour_cost || '0.00';
            createJobArr['parts_markup'] = rescheduleFormData.parts_markup || '0.00';
            createJobArr['labour_markup'] = rescheduleFormData.labour_markup || '0.00';
            createJobArr['parts_total_cost'] = rescheduleFormData.parts_total_cost || '0.00';
            createJobArr['labour_total_cost'] = rescheduleFormData.labour_total_cost || '0.00';
            createJobArr['sub_total'] = rescheduleFormData.sub_total || '0.00';
            createJobArr['gst'] = rescheduleFormData.gst || '0.00';
            createJobArr['total'] = rescheduleFormData.total || '0.00';
            createJobArr['discount_rate'] = rescheduleFormData.discount_rate || '0.00';
            createJobArr['discount_cost'] = rescheduleFormData.discount_cost;
            createJobArr['job_estimate_time'] = rescheduleFormData.job_estimate_time;
            createJobArr["duration"] = durationJob;
            if (unassignedFlag === true) {
                createJobArr['job_status'] = 'Unassigned';
                createJobArr['job_stage'] = 'Unassigned';
                createJobArr['carry_over_to_unassign'] = 1;
                createJobArr['user_id'] = 0;
            }
            convertQuoteToJob(createJobArr);
        } else if (jobType === "Unassigned") {
            createJobArr["workshop_id"] = workshop_id;
            createJobArr["created_by"] = user_id;
            createJobArr["tags_id"] = [];
            createJobArr["job_status"] = "Pending";
            createJobArr["job_stage"] = "Bookin";
            createJobArr["insp_reference_id"] = 0;
            createJobArr["bookin_source"] = 2;
            createJobArr["source_of_business"] = "Existing";
            createJobArr["job_estimate_time"] = rescheduleFormData.job_estimate_time;
            createJobArr["duration"] = durationJob;
            // createJobArr["labour_time"] = 0;
            // createJobArr["parts_cost"] = 0;
            // createJobArr["labour_cost"] = 0;
            // createJobArr["parts_markup"] = 0;
            // createJobArr["labour_markup"] = 0;
            // createJobArr["parts_total_cost"] = 0;
            // createJobArr["labour_total_cost"] = 0;
            // createJobArr["sub_total"] = 0;
            // createJobArr["gst"] = 0;
            // createJobArr["total"] = 0;
            // createJobArr["discount_rate"] = 0;
            createJobArr["id"] = jobIdData;
            updateJob(createJobArr);
        } else if (jobType === "Pending") {
            createJobArr["workshop_id"] = workshop_id;
            createJobArr["created_by"] = user_id;
            createJobArr["tags_id"] = [];
            createJobArr["job_status"] = "Rescheduled";
            createJobArr["job_stage"] = "Bookin";
            createJobArr["insp_reference_id"] = 0;
            createJobArr["bookin_source"] = 2;
            createJobArr["source_of_business"] = "Existing";
            createJobArr["labour_time"] = 0;
            createJobArr["parts_cost"] = 0;
            createJobArr["labour_cost"] = 0;
            createJobArr["parts_markup"] = 0;
            createJobArr["labour_markup"] = 0;
            createJobArr["parts_total_cost"] = 0;
            createJobArr["labour_total_cost"] = 0;
            createJobArr["sub_total"] = 0;
            createJobArr["gst"] = 0;
            createJobArr["total"] = job_details.total;
            createJobArr["discount_rate"] = 0;
            createJobArr["id"] = jobIdData;
            createJobArr["job_estimate_time"] = rescheduleFormData.job_estimate_time;
            createJobArr["duration"] = durationJob;
            updateJob(createJobArr);
        } else {
            createJobArr['id'] = jobIdData;
            createJobArr["job_estimate_time"] = rescheduleFormData.job_estimate_time;
            createJobArr["duration"] = durationJob;
            if (jobType === "Rescheduled" || jobType === "reschedule") {
                createJobArr["job_status"] = "Rescheduled";
                createJobArr["job_stage"] = "Bookin";
            }
            if (rescheduleFormData.customer_app === "1") {
                createJobArr['job_status'] = 'Rescheduled';
            }
            if (unassignedFlag === true) {
                createJobArr['job_status'] = 'Unassigned';
                createJobArr['job_stage'] = 'Unassigned';
                createJobArr['carry_over_to_unassign'] = 1;
                createJobArr['user_id'] = 0;
            }
            if (createJobArr['id']) {
                updateJob(createJobArr);
            }
        }
    }

    const updateJob = (createJobArr) => {
        setLoading(true);
        setCallDispatchAction(true);
        dispatch({
            type: UPDATE_JOB_TIMER,
            payload: createJobArr
        })
    }

    const dispatchCopyJob = (createJobArr, payload) => {
        dispatch({
            type: UPDATE_CARRY_OVER_JOB,
            payload
        })

        dispatch({
            type: CREATE_JOB,
            payload: createJobArr
        })
    }


    const copyJob = (createJobArr) => {
        setLoading(true);
        setCallDispatchActionForCarryOver(true);

        // Prepare default payload
        const payload = {
            id: jobIdData,
            is_copid_job: "1",
            timer_status: "stop",
            job_stage: "Inprogress",
            job_status: "InProgress",
            clocked_off_time: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
            // timer_status: "pause",
            // job_stage: "Inprogress",
            // job_status: "OnHold"
        }

        payload['activity_schedule_id'] = 0;

        if (jobType === "carryover") {
            // Get running timer data to calculate actual work time
            dispatch({
                type: GET_TIMER_DETAILS,
                payload: {
                    get: "all",
                    conditions: {
                        "JobSchedules.id": jobIdData
                    },
                    fields: [
                        "timer_total_time",
                        "timer_status",
                        "clocked_in_time",
                        "clocked_off_time",
                        "job_stage",
                        "timer_start_time",
                    ]
                },
                [WAIT_FOR_ACTION]: GET_TIMER_DETAILS_SUCCESS
            }).then(response => {

                if (response && Array.isArray(response)) {
                    // Get first indexed data from response
                    const responseData = response.shift()

                    // Get total timer time and start time
                    const total_time = responseData?.timer_total_time
                    const start_time = responseData?.timer_start_time

                    // In certain scenarios, start time could be null. Hence, check before proceeding.
                    if (total_time && start_time) {
                        // Convert total time to seconds
                        const totalTimeToSeconds = moment.duration(total_time).asSeconds();

                        // Find difference between current time and start time
                        const dateTime = moment.utc().unix() - moment.utc(start_time, "YYYY-MM-DD HH:mm:ss").unix();

                        // Sum total time and difference time
                        const timeInSeconds = dateTime + totalTimeToSeconds

                        const time = convertSecondsToHHMMSS(timeInSeconds)

                        // Bind timer_total_time and labour_time to payload
                        payload['timer_total_time'] = total_time;
                        payload['labour_time'] = total_time;

                        setTotalLabourTime(time);
                    }
                }

                // Dispatch final job edit action
                dispatchCopyJob(createJobArr, payload)
            })
        } else {
            dispatchCopyJob(createJobArr, payload)
        }

    }

    const convertQuoteToJob = (createJobArr) => {
        setLoading(true);
        setCallDispatchActionForCarryOver(true);
        dispatch({
            type: CREATE_JOB,
            payload: createJobArr
        })
    }

    const createUnassignedJob = () => {
        setUnassignedFlag(true);
        handleSaveForm();
    }

    return (
        <>
            {
                loading ? <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div> : ""
            }
            {
                modalType === '' ?
                    <Modal
                        className={"reschedule-job-modal " + (showAddMobileModal ? " hide" : "")}
                        size="lg"
                        show={rescheduleShow}
                        onHide={handleRescheduleClose}
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title className="">
                                <div className="row d-flex">
                                    <div className="col-sm-6">
                                        <span className="name">{employeeName}</span>
                                        <span> | </span>
                                        <span className="time">{titleDate} {titleTime}</span>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="job-id">JOB # {jobNumber}
                                            {
                                                (jobType === "carryover") ? <label className="job-label m-l-5" title="Carry Over Job">CO {jobDetailsData?.[0]?.is_copy_job}</label> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="content-block">
                                <div className="row mb-3">
                                    <div className="col-sm-6 d-flex align-center">
                                        {
                                            (jobType === "carryover" || jobType === "reschedule" || jobType === "Open") ? <span className="display-index d-flex align-center justify-center m-r-10">1</span> : ''
                                        }

                                        <div className="date-block-outer">
                                            <Form.Group className="form-group date-picker d-flex align-center no-wrap">
                                                <label>Select Date</label>
                                                <DatePicker className="form-control"
                                                    disabledDate={(current) => {
                                                        let customDate = moment().format("YYYY-MM-DD");
                                                        if (jobType === "carryover" || jobType === "reschedule" || jobType === "Open") {
                                                            return current && current < moment(customDate, "YYYY-MM-DD");
                                                        }
                                                    }
                                                    }
                                                    defaultValue={moment(startEventTime)}
                                                    format={date_format}
                                                    onChange={(date) => changeBookedDate(date)}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {
                                        (jobType === "carryover" || jobType === "reschedule" || jobType === "Open") ?
                                            <div className="col-sm-6 d-flex align-center no-wrap">
                                                <span className="display-index d-flex align-center justify-center m-r-10">2</span>
                                                <div className="date-block-outer d-flex align-center move-to-co">
                                                    <Form.Group className="form-group date-picker d-flex align-center no-wrap">
                                                        <label>{jobType === "reschedule" ? "Reschedule Job" : "Create Job"}</label>
                                                        <Button variant="outline-secondary" className="btn-outline outline-black height-34" onClick={unassignedDialogShow}>
                                                            Move to Unassigned
                                                        </Button>
                                                    </Form.Group>
                                                </div>
                                            </div> : ''
                                    }
                                </div>

                                <div className="schedular-blocks d-flex">
                                    <ul className="scheduler-person-list activity-list text-center">
                                        <p> Technician Details </p>
                                    </ul>
                                    <ul className="schedular-list row">
                                        <div className="col-md-6 text-center" > Morning </div>
                                        <div className="col-md-6 text-center" > Afternoon </div>
                                    </ul>
                                </div>
                                <div className="schedular-blocks d-flex mb-4">
                                    <ul className="scheduler-person-list activity-list">
                                        <Scrollbars
                                            autoHeight
                                            autoHeightMax={160}
                                            className="custom-scrollbar"
                                            style={{ width: "100%" }}
                                            renderThumbVertical={(props) => (
                                                <div className="thumb-vertical grey-thumb" />
                                            )}
                                            renderTrackHorizontal={(props) => (
                                                <div style={{ display: "none" }} />
                                            )}
                                        >
                                            {
                                                employeeList.length !== 0 ?
                                                    employeeList.map((emp, index) => {
                                                        return (
                                                            <li key={emp.id} >
                                                                <Form.Check
                                                                    className="radio-btn mb-2"
                                                                    type="radio"
                                                                    id={emp.id}
                                                                >
                                                                    <Form.Check.Input
                                                                        checked={emp.id === employeeId ? true : false}
                                                                        name="tech"
                                                                        type="radio"
                                                                        data-id={emp.data_type}
                                                                        isValid
                                                                        value={emp.id}
                                                                        onChange={
                                                                            (e) => {
                                                                                setEmployeeId(emp.id);
                                                                                handleTechnicianSlot(e.target)
                                                                                setEmployeeName(emp.name);
                                                                            }
                                                                        }
                                                                        disabled={(emp.working_days === 0) ? true : false}
                                                                    />
                                                                    <Form.Check.Label className={(emp.working_days === 0) ? 'red-disable' : ''}>
                                                                        {emp.name}
                                                                        <span className="check"></span>
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                        )
                                                    }) : ""
                                            }

                                        </Scrollbars>
                                    </ul>
                                    <ul className="schedular-list d-flex left">
                                        {
                                            timeSlotsList.length !== 0 ?
                                                timeSlotsList.map((time, index) => {
                                                    // disabledSlots = (time.available === 0) ? disabled : '';
                                                    return (
                                                        <li key={time.start_time}>
                                                            <Form.Check
                                                                className="radio-btn mb-2"
                                                                type="radio"
                                                                id={time.slots}
                                                            >
                                                                <Form.Check.Input
                                                                    checked={time.start_time === timeSlot ? true : false}
                                                                    name="slots"
                                                                    type="radio"
                                                                    data-time={time.start_time}
                                                                    isValid
                                                                    value={time.slots}
                                                                    data-available={time.available}
                                                                    disabled={(time.available === 0) ? true : false}
                                                                    onChange={
                                                                        (e) => {
                                                                            setTimeSlot(time.start_time)
                                                                            setTitleTime(moment(time.start_time, 'HH:mm:ss').format(time_format))
                                                                            setDisabledSave('');
                                                                            getJobEstimateTimeData(moment(startEventTime), time.start_time, employeeId)
                                                                        }
                                                                    }
                                                                />
                                                                <Form.Check.Label>
                                                                    {time.slots}
                                                                    <span className="check"></span>
                                                                </Form.Check.Label>
                                                            </Form.Check>
                                                        </li>
                                                    )
                                                })
                                                : <div style={{ textAlign: "center" }}> No Slots available . </div>
                                        }
                                    </ul>
                                    <ul className="schedular-list d-flex right">
                                        {
                                            eveTimeSlotsList.length !== 0 ?
                                                eveTimeSlotsList.map((time, index) => {
                                                    // disabledSlots = (time.available === 0) ? disabled : '';
                                                    return (
                                                        <li key={time.start_time}>
                                                            <Form.Check
                                                                className="radio-btn mb-2"
                                                                type="radio"
                                                                id={time.slots}
                                                            >
                                                                <Form.Check.Input
                                                                    checked={time.start_time === timeSlot ? true : false}
                                                                    name="slots"
                                                                    type="radio"
                                                                    data-time={time.start_time}
                                                                    isValid
                                                                    value={time.slots}
                                                                    data-available={time.available}
                                                                    disabled={(time.available === 0) ? true : false}
                                                                    onChange={
                                                                        (e) => {
                                                                            setTimeSlot(time.start_time)
                                                                            setTitleTime(moment(time.start_time, 'HH:mm:ss').format(time_format))
                                                                            setDisabledSave('');
                                                                            getJobEstimateTimeData(moment(startEventTime), time.start_time, employeeId)
                                                                        }
                                                                    }
                                                                />
                                                                <Form.Check.Label>
                                                                    {time.slots}
                                                                    <span className="check"></span>
                                                                </Form.Check.Label>
                                                            </Form.Check>
                                                        </li>
                                                    )
                                                })
                                                : <div style={{ textAlign: "center" }}> No Slots available . </div>
                                        }
                                    </ul>
                                </div>
                                {/* <Accordion defaultActiveKey="0" className="small-accordion"> */}
                                <Accordion className="small-accordion" defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <span>Insurance / PO Details</span>
                                            <i className="la la-angle-down"></i>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="form-outer">
                                                <div className="date-time-wrapper d-flex justify-space-between  mb-3">
                                                    <Form.Group className="form-group width-33 time-input">
                                                        <label>Bill To | 3rd Party</label>
                                                        <select className="form-control custom-select" name="insurance_company_id" id="insurance_company_id" onChange={(e) => { handleRescheduleFormData(e); }} >
                                                            <option>Select</option>
                                                            {
                                                                (insuranceData !== null && insuranceData !== undefined) ?
                                                                    insuranceData.map((insurance) => {
                                                                        return <option key={insurance.id} value={insurance.id}>{insurance.company_name}</option>
                                                                    })
                                                                    : ''
                                                            }
                                                        </select>
                                                    </Form.Group>
                                                    <Form.Group className="form-group date-picker width-33">
                                                        <label>Claim No</label>
                                                        <Form.Control type="text" id="claim_number" name="claim_number" onChange={(e) => handleRescheduleFormData(e)} />{" "}
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-33 time-input">
                                                        <label>Company PO</label>
                                                        <Form.Control type="text" id="purchase_order_number" name="purchase_order_number" onChange={(e) => handleRescheduleFormData(e)} />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <span>Pickup Time</span>
                                            <i className="la la-angle-down"></i>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="form-outer">
                                                <div className="date-time-wrapper d-flex justify-space-between  mb-3">


                                                    <div className="width-33">
                                                        <Form.Group className="form-group time-picker">
                                                            <label>Job Estimate Time</label>
                                                            <Form.Control
                                                                type="text"
                                                                value={rescheduleFormData.job_estimate_time}
                                                                name="job_estimate_time"
                                                                id="job_estimate_time"
                                                                onClick={(e) => {
                                                                    if (estimateTimeSlot.length > 0) {
                                                                        setShowJobEstimateSlot(!showJobEstimateSlot);
                                                                    }
                                                                }}
                                                                className="form-select"
                                                            />
                                                        </Form.Group>
                                                        {showJobEstimateSlot && estimateTimeSlot.length !== 0 &&
                                                            <div className="estimate-time-slot-div-1">
                                                                <div className="time-slots">
                                                                    {
                                                                        (estimateTimeSlot.length !== 0) ?
                                                                            estimateTimeSlot.map((slot, index) => {
                                                                                return <Button
                                                                                    key={index}
                                                                                    type="button"
                                                                                    className={"estimate-time-slot"}
                                                                                    onClick={(e) => {
                                                                                        setCreateJobEstimateTime(slot);
                                                                                        setShowJobEstimateSlot(!showJobEstimateSlot);
                                                                                        var startTimeInFormat = moment(new Date(timeSlot)).format('YYYY-MM-DD HH:mm:ss');
                                                                                        var fromData = timeZoneToUTC(startTimeInFormat, timezoneLable, "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD HH:mm:ss');
                                                                                        var startTimeJob = moment(fromData).format('HH:mm:ss');
                                                                                        var durationMinutes = convertToMinute(slot);
                                                                                        var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(durationMinutes, 'minutes').format('HH:mm:ss');
                                                                                        setDurationJob(durationMinutes);
                                                                                        setEndTime(endTimeJob);
                                                                                        setRescheduleFormData({
                                                                                            ...rescheduleFormData,
                                                                                            ["to_time"]: endTimeJob,
                                                                                            ["job_estimate_time"]: slot,
                                                                                        });
                                                                                    }} >
                                                                                    <strong>{slot} {slot === "00:30" ? 'Mins' : 'Hrs'}</strong>
                                                                                </Button>
                                                                            }) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    {/* <div className="select-block select-wrapper d-flex justify-space-between " style={{ width: "calc(50% - 155px)" }} >
                                                        <Form.Group className="form-group">
                                                            <label>Job Estimate Time</label>
                                                            <Select
                                                                className="form-control custom-select"
                                                                name="job_estimate_time" id="job_estimate_time"
                                                                options={jobEstimateTimeSlot}
                                                                onChange={(e) => {
                                                                    // setFormValue(e);
                                                                    // setCreateJobEstimateTime(e.value);
                                                                    setCreateJobEstimateTime([{ value: e.value, label: e.value }]);
                                                                    var startTimeInFormat = moment(new Date(timeSlot)).format('YYYY-MM-DD HH:mm:ss');
                                                                    var fromData = timeZoneToUTC(startTimeInFormat, timezoneLable, "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD HH:mm:ss');
                                                                    var startTimeJob = moment(fromData).format('HH:mm:ss');
                                                                    var durationMinutes = convertToMinute(e.value);
                                                                    var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(durationMinutes, 'minutes').format('HH:mm:ss');
                                                                    setDurationJob(durationMinutes);
                                                                    setEndTime(endTimeJob);
                                                                    setRescheduleFormData({
                                                                        ...rescheduleFormData,
                                                                        ["to_time"]: endTimeJob,
                                                                        ["job_estimate_time"]: e.value,
                                                                    });

                                                                }}
                                                                value={createJobEstimateTime}
                                                            />
                                                        </Form.Group>
                                                    </div> */}

                                                    {/* <Form.Group className="form-group width-33 time-input">
                                                        <label>Job Estimate Time</label>
                                                        <TimeField
                                                            fieldName="job_estimate_time"
                                                            slotDuration={rescheduleFormData.job_estimate_time || rescheduleFormData.duration}
                                                            onChange={(e) => handleRescheduleFormData(e)}
                                                        />
                                                        <span>Hrs</span>
                                                    </Form.Group> */}
                                                    <Form.Group className="form-group date-picker width-33">
                                                        <label>Select Pickup Date</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            format={date_format}
                                                            name="pickup_date"
                                                            // defaultValue={rescheduleFormData.pickup_date} 
                                                            onChange={(date) => handlePickupDate(date)}
                                                        />{" "}
                                                    </Form.Group>


                                                    <div className="width-33">
                                                        <Form.Group className="form-group time-picker">
                                                            <label>Select Pickup Time</label>
                                                            <Form.Control
                                                                type="text"
                                                                value={rescheduleFormData.pickup_time}
                                                                name="pickup_time"
                                                                id="pickup_time"
                                                                onClick={(e) => setShowPickupTimeSlot(!showPickupTimeSlot)}
                                                                className="form-select"
                                                            />
                                                        </Form.Group>
                                                        {showPickupTimeSlot &&
                                                            <div className="estimate-time-slot-div-1">
                                                                <div className="time-slots row">
                                                                    <div className="col-md-6">
                                                                        <p className="text-center fw-bold" > Morning </p>
                                                                        {
                                                                            (pickupTimeSlot.length !== 0) ?
                                                                                pickupTimeSlot.map((slot, index) => {
                                                                                    return <Button
                                                                                        key={index}
                                                                                        type="button"
                                                                                        className={"estimate-time-slot"}
                                                                                        onClick={(e) => {
                                                                                            setSelectedPickupTimeSlot(slot);
                                                                                            setShowPickupTimeSlot(!showPickupTimeSlot);
                                                                                            setRescheduleFormData({
                                                                                                ...rescheduleFormData,
                                                                                                ["pickup_time"]: slot,
                                                                                            });
                                                                                        }} >
                                                                                        <strong>{slot + ' Hrs'}</strong>
                                                                                    </Button>
                                                                                }) : ''
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p className="text-center fw-bold" > Afternoon </p>
                                                                        {
                                                                            (afterPickupTimeSlot.length !== 0) ?
                                                                                afterPickupTimeSlot.map((slot, index) => {
                                                                                    return <Button
                                                                                        key={index}
                                                                                        type="button"
                                                                                        className={"estimate-time-slot"}
                                                                                        onClick={(e) => {
                                                                                            setSelectedPickupTimeSlot(slot);
                                                                                            setShowPickupTimeSlot(!showPickupTimeSlot);
                                                                                            setRescheduleFormData({
                                                                                                ...rescheduleFormData,
                                                                                                ["pickup_time"]: slot,
                                                                                            });
                                                                                        }} >
                                                                                        <strong>{slot + ' Hrs'}</strong>
                                                                                    </Button>
                                                                                }) : ''
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    {/* <Form.Group className="form-group width-33 time-input">
                                                        <label>Select Pickup Time</label>
                                                        <Form.Control type="time"
                                                            name="pickup_time"
                                                            defaultValue={rescheduleFormData.pickup_time}
                                                            onChange={(e) => handleRescheduleFormData(e)}
                                                        />
                                                    </Form.Group> */}
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            <span>Customer</span>
                                            <i className="la la-angle-down"></i>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="customer-assets-block d-flex">
                                                <div className="left-block block-wrapper">
                                                    <div className="heading-block">
                                                        Asset Details
                                                    </div>
                                                    <div className="d-flex detail-outer">
                                                        <div className="detail-wrapper width-50">
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <i className="fa fa-car text-primary m-r-5 font-size-16 w-20"></i>
                                                                    <b>Rego</b>
                                                                </label>
                                                                <span>{rescheduleFormData.rego}</span>
                                                            </div>
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <i className="fa fa-calendar m-r-5 text-primary font-size-16 w-20"></i>
                                                                    <b>Year</b>
                                                                </label>
                                                                <span>{rescheduleFormData.year}</span>
                                                            </div>
                                                        </div>
                                                        <div className="detail-wrapper width-50">
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <b>Make</b>
                                                                </label>
                                                                <span>{rescheduleFormData.make}</span>
                                                            </div>
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <i className="fa fa-car text-primary m-r-5 font-size-16 w-20"></i>
                                                                    <b>Model</b>
                                                                </label>
                                                                <span>{rescheduleFormData.model}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-block block-wrapper">
                                                    <div className="heading-block">
                                                        Customer Details
                                                    </div>
                                                    <div className="d-flex detail-outer">
                                                        <div className="detail-wrapper">
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <i className="fa fa-car text-primary m-r-5 font-size-16 w-20"></i>
                                                                    <b>Customer</b>
                                                                </label>
                                                                <span>{rescheduleFormData.customer_name}</span>
                                                            </div>
                                                            <div className="d-flex detail-block">
                                                                <div className="d-flex">
                                                                    <label>
                                                                        <i className="m-r-5 font-size-16 w-20 fa fa-2x text-primary fa-phone"></i>
                                                                        <b>Contact</b>
                                                                    </label>
                                                                    <span>{rescheduleFormData.mobile_number}</span>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <label>
                                                                        <i className="m-r-5 font-size-16 w-20 fa fa-2x text-primary fa-envelope"></i>
                                                                        <b>Email</b>
                                                                    </label>
                                                                    <span>{rescheduleFormData.email}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-secondary"
                                className="btn-outline rounded-btn"
                                onClick={handleRescheduleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={"rounded-btn btn-success " + disabledSave}
                                variant="success"
                                onClick={() => { handleSaveForm(); }}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal> :
                    <Modal
                        className="move-unassigned-dialog info-dialog"
                        size="sm"
                        show={unassignedDialog}
                        onHide={unassignedDialogClose}
                    >
                        {jobType === "carryover" &&
                            <div className="dialog-title text-center mb-3">
                                Move CO Job to <span className="text-danger">Unassigned?</span>
                            </div>
                        }

                        {jobType === "reschedule" &&
                            <div className="dialog-title text-center mb-3">
                                Move Reschedule Job to <span className="text-danger">Unassigned?</span>
                            </div>
                        }

                        {jobType === "Open" &&
                            <div className="dialog-title text-center mb-3">
                                Move This Job to <span className="text-danger">Unassigned?</span>
                            </div>
                        }

                        <Modal.Body>
                            <div className="d-flex justify-center">
                                <Button
                                    variant="outline-secondary"
                                    className="btn-outline rounded-btn"
                                    onClick={() => unassignedDialogClose()}
                                >
                                    No
                                </Button>
                                <Button
                                    className="btn btn-primary rounded-btn"
                                    onClick={() => createUnassignedJob()}
                                >
                                    Yes
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
            }

            {showAddMobileModal && (
                <SendSMSToUser
                    cust_id={custId}
                    workshop_id={workshop_id}
                    type={sentSMSType}
                    id={jobId}
                    customer_id={customerId}
                    mobileCode={mobileCode}
                    rego={rego}
                    setShowAddMobileModal={setShowAddMobileModal}
                    updateMobileNumber={updateMobileNumber}
                    setUpdateMobileNumber={setUpdateMobileNumber}
                    mobileNumberForCustomer={mobileNumber}
                    setMobileNumberForCustomer={setMobileNumber}
                    customerName={customerName}
                    number={smsJobNumber}
                    rescheduleDate={moment(currentDate).format('DD/MM/YYYY')}
                    rescheduleTime={moment(timeSlot, 'HH:mm:ss').format('hh:mm a')}
                />)
            }
        </>
    )
}

export default Reschedule;
