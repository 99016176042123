import { takeLatest, put, call } from "redux-saga/effects";

import {
  DELETE_CUSTOM_VEHICLE_DETAILS,
  DELETE_LABOUR_CHARGE_DETAILS,
  EDIT_CUSTOM_VEHICLE_DETAILS,
  EDIT_LABOUR_CHARGES_DETAILS,
  GET_CUSTOM_VEHICLE_LIST,
  SAVE_CUSTOM_VEHICLE,
  SAVE_LABOUR_CHARGE,
  UPDATE_CUSTOM_VEHICLE_ASSET_TYPE,
  UPDATE_CUSTOM_VEHICLE_DETAILS,
  UPDATE_DEFAULT_CUSTOM_VEHICLE_DETAIL,
  UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL,
  UPDATE_LABOUR_CHARGE_DETAILS,
} from "./actionTypes";

import {
  saveLabourChargeSuccess,
  saveLabourChargeFail,
  updateLabourChargeDetails,
  updateLabourChargeDetailsFail,
  deleteLabourChargeDetailSuccess,
  deleteLabourChargeDetailFail,
  updateLabourChargeDetailsSuccess,
  editLabourChargeSuccess,
  editLabourChargeFail,
  getCustomVehicleListSuccess,
  getCustomVehicleListFail,
  getCustomVehiclesLists,
  saveCustomVehicleSuccess,
  saveCustomVehicleFail,
  editCustomVehicleSuccess,
  editCustomVehicleFail,
  updateCustomVehicleDetailsSuccess,
  updateCustomVehicleDetailsFail,
  deleteCustomVehicleDetailSuccess,
  deleteCustomVehicleDetailFail,
  updateCustomVehicleAssetTypeFail,
  updateCustomVehicleAssetTypeSuccess,
} from "./actions";

import {
  deleteLabourCharge,
  saveLabourChargeDetails,
  updateDefaultLabourCharge,
  updateLabourChargeDetail,
  getLabourChargesList as getLabourChargesListAPI,
  getCustomVehicleLists,
  saveCustomVehicleDetails,
  updateCustomVehicleDetail,
  deleteCustomVehicle,
  updateCompanyDetail,
} from "../../helpers/backendHelper";

import { showError, showSuccess } from "../../utils/alertMessage";
import { getLabourChargesList } from "../commonApiStore/actions";
import { getWorkShopDetail } from "../companyDetails/actions";

// Save Labour charge
function* onSaveLabourCharge(data) {
  try {
    const response = yield call(saveLabourChargeDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(saveLabourChargeSuccess(response));
        showSuccess(response.message);
        yield put(
          getLabourChargesList({
            get: "all",
            conditions: {
              workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
            },
          })
        );
      } else {
        yield put(saveLabourChargeFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(saveLabourChargeFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(saveLabourChargeFail(error.response));
  }
}

// Edit labour charge details
function* onEditLabourCharge(data) {
  try {
    const response = yield call(getLabourChargesListAPI, data);
    if (response) {
      if (response.code === 200) {
        yield put(editLabourChargeSuccess(response));
      } else {
        yield put(editLabourChargeFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(editLabourChargeFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editLabourChargeFail(error.response));
  }
}

// Update labour charge details

function* onUpdateLabourChargeDetails(data) {
  try {
    const response = yield call(updateLabourChargeDetail, data);
    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);
        yield put(updateLabourChargeDetailsSuccess(response));
        yield put(
          getLabourChargesList({
            get: "all",
            conditions: {
              workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
            },
          })
        );
      } else {
        yield put(updateLabourChargeDetailsFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateLabourChargeDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateLabourChargeDetailsFail(error.response));
  }
}

// Update default labour charge
function* onUpdateDefaultLabourCharge(data) {
  try {
    const editData = {
      type: data.type,
      payload: {
        is_default: "0",
        workshop_id: data.payload.workshop_id,
      },
    };
    const response = yield call(updateDefaultLabourCharge, editData);
    if (response) {
      if (response.code === 200) {
        yield put(
          updateLabourChargeDetails({
            is_default: "1",
            id: data.payload.id,
          })
        );
      } else {
        yield put(updateLabourChargeDetailsFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateLabourChargeDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateLabourChargeDetailsFail(error.response));
  }
}

// DELETE Labour Charge
function* onDeleteLabourCharge(data) {
  try {
    const response = yield call(deleteLabourCharge, data);
    if (response) {
      if (response.code === 200) {
        yield put(deleteLabourChargeDetailSuccess(response));
        yield put(
          getLabourChargesList({
            get: "all",
            conditions: {
              workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
            },
          })
        );
        showSuccess(response.message);
      } else {
        yield put(deleteLabourChargeDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(deleteLabourChargeDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(deleteLabourChargeDetailFail(error.response));
  }
}

// Get custom vehicle list
function* getCustomVehicleList(data) {
  try {
    const response = yield call(getCustomVehicleLists, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCustomVehicleListSuccess(response.data));
      } else {
        yield put(getCustomVehicleListFail(response.message));
        // showError(response.message);
      }
    } else {
      yield put(getCustomVehicleListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCustomVehicleListFail(error.response));
  }
}

// Save Custom vehicle
function* onSaveCustomVehicle(data) {
  try {
    const response = yield call(saveCustomVehicleDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(saveCustomVehicleSuccess(response));
        showSuccess(response.message);
        yield put(
          getCustomVehiclesLists({
            get: "all",
            conditions: {
              workshop_id: window.atob(
                JSON.parse(sessionStorage.getItem("stored_data")).workshop_id
              ),
            },
          })
        );
      } else {
        yield put(saveCustomVehicleFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(saveCustomVehicleFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(saveCustomVehicleFail(error.response));
  }
}

// Edit custom vehicle details
function* onEditCustomVehicle(data) {
  try {
    const response = yield call(getCustomVehicleLists, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          editCustomVehicleSuccess(response.data.VehiclesCustomFields[0])
        );
      } else {
        yield put(editCustomVehicleFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(editCustomVehicleFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editCustomVehicleFail(error.response));
  }
}

// Update custom vehicle  details

function* onUpdateCustomVehicleDetails(data) {
  try {
    const response = yield call(updateCustomVehicleDetail, data);
    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);
        yield put(updateCustomVehicleDetailsSuccess(response));
        yield put(
          getCustomVehiclesLists({
            get: "all",
            conditions: {
              workshop_id: window.atob(
                JSON.parse(sessionStorage.getItem("stored_data")).workshop_id
              ),
            },
          })
        );
      } else {
        yield put(updateCustomVehicleDetailsFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateCustomVehicleDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateCustomVehicleDetailsFail(error.response));
  }
}

// DELETE custom vehicle
function* onDeleteCustomVehicle(data) {
  try {
    const response = yield call(deleteCustomVehicle, data);
    if (response) {
      if (response.code === 200) {
        yield put(deleteCustomVehicleDetailSuccess(response));
        yield put(
          getCustomVehiclesLists({
            get: "all",
            conditions: {
              workshop_id: window.atob(
                JSON.parse(sessionStorage.getItem("stored_data")).workshop_id
              ),
            },
          })
        );
        showSuccess(response.message);
      } else {
        yield put(deleteCustomVehicleDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(deleteCustomVehicleDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(deleteCustomVehicleDetailFail(error.response));
  }
}

// UPDATE asset type
function* onUpdateCustomVehicleAsset(data) {
  try {
    const response = yield call(updateCompanyDetail, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateCustomVehicleAssetTypeSuccess(response));
        yield put(
          getWorkShopDetail({
            id: window.atob(
              JSON.parse(sessionStorage.getItem("stored_data")).workshop_id
            ),
          })
        );
        if(data?.payload?.is_checked === 1){
          showSuccess(data?.payload?.vehType + " Turned On");
        } else {
          showSuccess(data?.payload?.vehType + " Turned Off");
        }
        
      } else {
        yield put(updateCustomVehicleAssetTypeFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateCustomVehicleAssetTypeFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateCustomVehicleAssetTypeFail(error.response));
  }
}

function* CompanyDetailSaga() {
  yield takeLatest(SAVE_LABOUR_CHARGE, onSaveLabourCharge);
  yield takeLatest(EDIT_LABOUR_CHARGES_DETAILS, onEditLabourCharge);
  yield takeLatest(UPDATE_LABOUR_CHARGE_DETAILS, onUpdateLabourChargeDetails);
  yield takeLatest(
    UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL,
    onUpdateDefaultLabourCharge
  );
  yield takeLatest(DELETE_LABOUR_CHARGE_DETAILS, onDeleteLabourCharge);

  yield takeLatest(GET_CUSTOM_VEHICLE_LIST, getCustomVehicleList);
  yield takeLatest(SAVE_CUSTOM_VEHICLE, onSaveCustomVehicle);
  yield takeLatest(EDIT_CUSTOM_VEHICLE_DETAILS, onEditCustomVehicle);
  yield takeLatest(UPDATE_CUSTOM_VEHICLE_DETAILS, onUpdateCustomVehicleDetails);

  yield takeLatest(DELETE_CUSTOM_VEHICLE_DETAILS, onDeleteCustomVehicle);
  yield takeLatest(
    UPDATE_CUSTOM_VEHICLE_ASSET_TYPE,
    onUpdateCustomVehicleAsset
  );
}

export default CompanyDetailSaga;
