import { onReceiveMessage } from "firbaseInit";
import { useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { showSuccess, showError } from "../alertMessage";
import { getNotificationCountSuccess,
    getInspectionNotificationSuccess,
    getOnlineScheduleNotificationSuccess
} from "../../store/commonApiStore/actions";

const Firebase = () => {
    const { firebase } = useSelector((state) => state.LoginReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (firebase?.token) {
            onReceiveMessage((payload) => {
                if (payload !== null && payload !== undefined) {

                    var connection_type = payload?.notification?.type || null;
                    var type = payload?.data?.type || null;
                    if (connection_type !== null) {
                        var success = payload?.notification?.success || 0;
                        var error = payload?.notification?.error || 0;
                        if (connection_type === "account_connection") {
                            if (success == 1) {
                                showSuccess(payload.notification.body);
                            } else if (error == 1) {
                                showError(payload.notification.body);
                            }
                        }
                    }else if(type !== null){
                        var success = payload?.data?.success || 0;
                        var error = payload?.data?.error || 0;
                        if (type === "import_data") {
                            if (success == 1) {
                                showSuccess(payload.data.bodyText);
                            } else if (error == 1) {
                                showError(payload.data.bodyText);
                            }
                        }else if(type === "notification"){
                            dispatch(getNotificationCountSuccess(payload.data.bodyText));
                        }else if(type === "inspection_report_response"){
                            dispatch(getInspectionNotificationSuccess(payload.data));
                        }else if(type === "online_schedule"){
                            dispatch(getOnlineScheduleNotificationSuccess(payload.data));
                        }
                    }
                }
            });
        }
    }, [firebase]);

    return <></>;
};


// const Firebase = () => <div></div>
export default Firebase;