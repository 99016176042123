import * as Actions from "./actionType";
const initialState = {};

export function firebaseNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.TEST_NOTIFICATION:
      return { ...state };
    default:
      return state;
  }
}
