import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link, useNavigate } from "react-router-dom";
import WorkshopFilterDropdown from "views/hq/views/common/components/WorkshopFilterDropdown";
import LoaderImage from "../../../../../../assets/images/page-loader.svg";
import "../../../style.scss";

const emptyDataMessage = () => {
  return <div style={{ textAlign: "center" }}>No data available in table</div>;
};

const CommonListLayout = ({
  loading = false,
  totalRecords,
  searchQuery = "",
  onSearchQueryChange,
  selectedWorkshop,
  onSelectedWorkshopChange,
  setSelectedWorkshop,
  columns = [],
  rows = [],
  selectRowHandler,
  tableChangeHandler,
  currentPage = 1,
  sizePerPage = 10,
  remotePagination = false,
  type = "",
}) => {
  const navigate = useNavigate();

  return (
    <div>
      {loading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
      <div>
        <div className="d-flex justify-content-end">
          <div className="table-search-wrapper">
            <input
              type="text"
              className="form-control"
              id="search"
              placeholder="Search..."
              onChange={(e) => {
                if (onSearchQueryChange) {
                  onSearchQueryChange(e.target.value);
                }
              }}
              value={searchQuery}
            />
            {type == "suppliers" ? (
              <WorkshopFilterDropdown
                current={selectedWorkshop}
                onChange={(newWorkshop) => {
                  setSelectedWorkshop(newWorkshop);
                }}
              />
            ) : (
              <Button
                className="btn-primary rounded-btn"
                onClick={() => {
                  navigate("/create-supplier")
                }}
              >
                <i className="fa fa-plus"></i> Create Supplier
              </Button>
            )}
          </div>
        </div>

        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: currentPage,
            sizePerPage: sizePerPage,
            totalSize: parseInt(totalRecords),
            nextPageText: "Next",
            prePageText: "Previous",
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                remote={remotePagination}
                keyField="id"
                data={rows}
                columns={columns}
                onTableChange={tableChangeHandler}
                selectRow={selectRowHandler}
                noDataIndication={emptyDataMessage}
                {...paginationTableProps}
              />
              <div>
                <div className="table-pagination">
                  <div>
                    <span>Show</span>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <span>entries</span>
                  </div>
                  <PaginationTotalStandalone {...paginationProps} />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            </div>
          )}
        </PaginationProvider>
      </div>
    </div>
  );
};

export default CommonListLayout;
