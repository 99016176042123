import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import "./styles.scss";
import { Typeahead } from 'react-bootstrap-typeahead';
import POPartsListTable from "views/common/PartsLabour/POPartsListTable";
import { CALLBACK_ARGUMENT, WAIT_FOR_ACTION } from "redux-wait-for-action";
import { addComma, precise_round } from "utils/numberFunction";
import moment from "moment";
import Loader from "views/common/UI/Loader";
import { getSessionData } from "utils/getWorkshopId";

const POCreateWearehouse = (props) => {

  const todayDate = moment();

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);
    return responseDate;
  };

  let countryID = getSessionData('country', false);
  let taxPercentage = 10;
  if (countryID === 10) {
    taxPercentage = 15;
  }

  const warehouseId = (props.warehouseID) ? props.warehouseID : "";
  const supplierID = (props.supplierID) ? props.supplierID : "";

  const storageID = (props.storageID) ? props.storageID : "";

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const dateFormat = storedData.date_format;
  const currencySymbol = storedData?.currency || "$";

  const [subTotal, setSubTotal] = useState(0);
  const [freight, setFreight] = useState(0);
  const [GST, setGST] = useState(0);
  const [freightGST, setFreightGST] = useState(0);
  const [purchaseTax, setPurchaseTax] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);

  const [singleSelections, setSingleSelections] = useState([]);
  const [multiSelections, setMultiSelections] = useState([]);
  const [supplierPartsList, setSupplierPartsList] = useState([]);


  const { parts } = useSelector((state) => state.PartReducer);

  const supplierDetail = props.suppDetails;

  let branchDetail;
  if (storageID > 0) {
    branchDetail = supplierDetail.suppliers_branches.filter(item => (item.id === parseInt(storageID)));
  }

  const intialPODetail = {
    workshop_id: workshop_id,
    purchased_by: "",
    job_id: 0,
    job_number: 0,
    po_status: "open",
    due_date: dateFormatter(todayDate),
    supplier_id: "",
    comments: "",
    sub_total: 0,
    total: 0,
    gst: 0,
    freight: 0,
    suppliers_branches_id: "Default",
    storage_device_id: "",
    purchase_orders_parts: [],
  }

  const [PODetail, setPODetail] = useState(intialPODetail);

  useEffect(() => {

    if (supplierDetail.products) {

      if (supplierDetail.products.length > 0) {

        const allPartList = supplierDetail.products.map((parts) => (
          {
            label: parts.name,
            id: parts.id,
            product_id: parts.id,
            part_number: parts.product_number,
            part_name: parts.name,
            retail_price: parts.retail_price,
            qty: 1,
            total: parts.retail_price,
            more: "",
            is_kit: (parts.product_type === '2') ? 1 : 0,
            product_type: parts.product_type,
            cost_price: parts.cost_price,
            margin_markup: "",
            sell_price: parts.sell_price,
            margin: parts.margin,
            is_gst: (parts.gst_price) ? 1 : 0,
            gst_price: parts.gst_price,
            gst_percentage: "",
            profit_price: parts.profit_price,
            job_part_priority: parts.priority,
            price: parts.retail_price,
            is_misc_part: 0
          }
        ));

        setSupplierPartsList(allPartList);

      }

    }

  }, [props.suppDetails])


  function updatePODetail(value, key) {

    const podata = PODetail;

    podata[key] = value;

    if (key === "freight") {
      let freightGst = (parseFloat(value) * parseFloat(taxPercentage) / 100);
      setFreightGST(freightGst);
      let total = parseFloat(podata.sub_total) + parseFloat(podata.gst) + parseFloat(freightGst) + parseFloat(value);
      podata['total'] = total;
      podata['freightGST'] = freightGst;
      setFreight(value);
      setFinalTotal(total);
    }

    // podata.comments = value;

    setPODetail(podata);

    props.updatedPOWarehouseData(PODetail);

  }

  useEffect(() => {

    const podata = PODetail;

    let sub_total = 0;
    let gst = 0;
    let gstPercentage = 0;

    parts.forEach((partsss, i) => {
      sub_total = parseFloat(sub_total) + (parseFloat(partsss.cost_price) * partsss.qty);
      // gst = parseFloat(gst) + (parseFloat(partsss.gst_price) * partsss.qty);
      if (partsss.purchase_tax_code_is_default !== null && partsss.purchase_tax_code_is_default > 0) {

        let gst_value = (parseFloat(partsss.cost_price) * parseFloat(partsss.purchase_tax_code) / 100);
        gst = parseFloat(gst) + (parseFloat(gst_value) * partsss.qty);
        parts[i].po_part_gst = gst_value;
        if (gstPercentage === 0) {
          gstPercentage = partsss.purchase_tax_code;
        }
      }


    });



    podata.purchase_orders_parts = parts;
    podata.sub_total = parseFloat(sub_total);
    podata.gst = parseFloat(gst);
    let total = parseFloat(sub_total) + parseFloat(gst) + parseFloat(freightGST) + parseFloat(freight);
    podata.total = total;

    setPODetail(podata);

    props.updatedPOWarehouseData(podata);

    setSubTotal(sub_total);
    setGST(gst);
    setFinalTotal(total);
    setPurchaseTax(gstPercentage);

  }, [parts]);

  if (props.SupplierDetailLoading) {
    return <Loader />
  }

  return (
    <>
      <div className={"warehouse-class " + (supplierID === "" ? "d-none" : "")}>
        <div className="info-block mb-3 none">
          <div className="d-flex outer-div">
            <div className="d-flex width-50 mb-2">
              <i className="fa fa-envelope text-primary font-size-20 mr-10"></i>
              <Link to="/">{supplierDetail?.email}</Link>
            </div>
            <div className="d-flex width-50">
              <i className="fa fa-phone text-primary font-size-20"></i>
              <span>{supplierDetail?.phone}</span>
            </div>
          </div>
          <div className="d-flex">
            <i className="fa fa-map-marker text-primary font-size-20"></i>
            {storageID === "default" ? (
              <>
                <span>
                  {(supplierDetail?.street_address) ? (supplierDetail?.street_address + ",") : ""}
                  {(supplierDetail?.suburb) ? supplierDetail?.suburb + ", " : ""}
                  {(supplierDetail?.States?.name) ? supplierDetail?.States?.name + ", " : ""}
                  {(supplierDetail?.Countries?.country_name) ? supplierDetail?.Countries?.country_name + ", " : ""}
                  {supplierDetail?.postcode}
                </span>
              </>
            ) : (
              <>
                <span>
                  {(branchDetail[0]?.street_address) ? (branchDetail[0]?.street_address + ",") : ""}
                  {(branchDetail[0]?.suburb) ? branchDetail[0]?.suburb + ", " : ""}
                  {(branchDetail[0]?.state?.name) ? branchDetail[0]?.state?.name + ", " : ""}
                  {(branchDetail[0]?.country?.country_name) ? branchDetail[0]?.country?.country_name + ", " : ""}
                  {branchDetail[0]?.postcode}
                </span>
              </>
            )}
          </div>
        </div>

        <div className="order-detail-outer ">
          <div className="order-for-warehouse">
            <div className="draggable-table mb-5">

              <POPartsListTable
                settings={{ "supplierID": supplierID }}
                supplierID={supplierID}
                onAddPartToList={(e, cb) => {
                  cb();
                }}
                deleteItem={(row, cb) => {
                  cb();
                }}
                updateApi={(row, cb) => {
                  cb();
                }}
              />


            </div>
            <div className="comment-total-block d-flex align-end">
              <div className="comment-block">
                <label>Delivery Comments:</label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  onBlur={(e) => updatePODetail(e.target.value, "comments")}
                />
              </div>
              <div className="form-outer">
                <Form.Group className="form-group d-flex align-center mb-1 no-wrap">
                  <label>Freight {currencySymbol}</label>
                  <Form.Control type="text" onBlur={(e) => updatePODetail(e.target.value, "freight")} defaultValue={addComma(precise_round(freight))} />
                </Form.Group>
                <Form.Group className="form-group d-flex align-center mb-1 no-wrap disable">
                  <label>Sub Total {currencySymbol}</label>
                  <Form.Control type="text" readOnly value={addComma(precise_round(parseFloat(subTotal) + parseFloat(freight)))} />
                </Form.Group>
                <Form.Group className="form-group d-flex align-center mb-1 no-wrap disable">
                  <label>GST {currencySymbol}</label>
                  <Form.Control type="text" readOnly value={addComma(precise_round(parseFloat(GST) + parseFloat(freightGST)))} />
                </Form.Group>
                <Form.Group className="form-group d-flex align-center mb-1 no-wrap disable">
                  <label className="total-amount">TOTAL {currencySymbol}</label>
                  <Form.Control type="text" readOnly value={addComma(precise_round(finalTotal))} />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default POCreateWearehouse;