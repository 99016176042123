import { UPDATE_STORAGE_DEVICE } from "helpers/urlHelper";
import {
  GET_STORAGE_DEVICES,
  GET_STORAGE_DEVICES_SUCCESS,
  GET_STORAGE_DEVICES_FAIL,
  EDIT_STORAGE_DEVICE,
  EDIT_STORAGE_DEVICE_SUCCESS,
  EDIT_STORAGE_DEVICE_FAIL,
  UPDATE_STORAGE_DEVICE_SUCCESS,
  UPDATE_STORAGE_DEVICE_FAIL,
  ADD_STORAGE_DEVICE,
  ADD_STORAGE_DEVICE_SUCCESS,
  ADD_STORAGE_DEVICE_FAIL,
  VIEW_STORAGE_DEVICE_DETAILS,
  VIEW_STORAGE_DEVICE_DETAILS_SUCCESS,
  VIEW_STORAGE_DEVICE_DETAILS_FAIL,
} from "./actionTypes";

export const getStorageDevices = () => {
  return {
    type: GET_STORAGE_DEVICES,
  };
};

export const getStorageDevicesSuccess = (data) => {
  return {
    type: GET_STORAGE_DEVICES_SUCCESS,
    payload: data,
  };
};

export const getStorageDevicesFail = (error) => {
  return {
    type: GET_STORAGE_DEVICES_FAIL,
    payload: error,
  };
};

export const editStorageDevice = () => {
  return {
    type: EDIT_STORAGE_DEVICE,
  };
};

export const editStorageDeviceSuccess = (data) => {
  return {
    type: EDIT_STORAGE_DEVICE_SUCCESS,
    payload: data,
  };
};

export const editStorageDeviceFail = (error) => {
  return {
    type: EDIT_STORAGE_DEVICE_FAIL,
    payload: error,
  };
};

export const updateStorageDevice = () => {
  return {
    type: UPDATE_STORAGE_DEVICE,
  };
};

export const updateStorageDeviceSuccess = (data) => {
  return {
    type: UPDATE_STORAGE_DEVICE_SUCCESS,
    payload: data,
  };
};

export const updateStorageDeviceFail = (error) => {
  return {
    type: UPDATE_STORAGE_DEVICE_FAIL,
    payload: error,
  };
};

export const addStorageDevice = () => {
  return {
    type: ADD_STORAGE_DEVICE,
  };
};

export const addStorageDeviceSuccess = (data) => {
  return {
    type: ADD_STORAGE_DEVICE_SUCCESS,
    payload: data,
  };
};

export const addStorageDeviceFail = (error) => {
  return {
    type: ADD_STORAGE_DEVICE_FAIL,
    payload: error,
  };
};
export const viewStorageDeviceDetails = () => {
  return {
    type: VIEW_STORAGE_DEVICE_DETAILS,
  };
};

export const viewStorageDeviceDetailsSuccess = (data) => {
  return {
    type: VIEW_STORAGE_DEVICE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const viewStorageDeviceDetailsFail = (error) => {
  return {
    type: VIEW_STORAGE_DEVICE_DETAILS_FAIL,
    payload: error,
  };
};