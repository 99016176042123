import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTime } from "react-timer-hook";

export default function AutoLogout({
  showSaveProgressAlert,
  setShowSaveProgressAlert,
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const performingAutoLogout = useRef(false);
  const { hours, minutes, seconds } = useTime({ format: "24-hour" });
  const isAuthenticated = localStorage.getItem("data") !== null;
  const currentTime = `${hours}:${minutes}:${seconds}`;

  // This effect will show a warning dialog to asking to save their progress.
  useEffect(() => {
    if (
      isAuthenticated &&
      currentTime === "23:55:0" &&
      !showSaveProgressAlert
    ) {
      setShowSaveProgressAlert(true);
    }
  }, [
    currentTime,
    isAuthenticated,
    setShowSaveProgressAlert,
    showSaveProgressAlert,
  ]);

  // This effect will logout user at 11:59 PM.
  useEffect(() => {
    if (
      isAuthenticated &&
      !performingAutoLogout.current &&
      currentTime === "23:59:0" &&
      pathname !== "/login" &&
      pathname !== "logout"
    ) {
      if (showSaveProgressAlert) {
        setShowSaveProgressAlert(false);
      }
      // localStorage.setItem("lastLogoutTime", new Date())
      performingAutoLogout.current = true;
      navigate("/logout?auto=true");
      performingAutoLogout.current = false;
    }
  }, [
    currentTime,
    isAuthenticated,
    navigate,
    pathname,
    setShowSaveProgressAlert,
    showSaveProgressAlert,
  ]);

  // This effect will check when was user auto logged out last time. If user was auto logged out more than 1 day before then this effect will auto logout user immediately.
  useEffect(() => {
    if (
      isAuthenticated &&
      !performingAutoLogout.current &&
      pathname !== "/login" &&
      pathname !== "/logout"
    ) {
      let lastLogoutTime = localStorage.getItem("lastLogoutTime");
      if (!lastLogoutTime) {
        lastLogoutTime = moment().subtract(1).endOf("day").toDate();
        localStorage.setItem("lastLogoutTime", lastLogoutTime);
      } else {
        lastLogoutTime = moment(lastLogoutTime).endOf("day").toDate();
      }
      // If diff is greater than 0 then logout the user
      if (moment().diff(lastLogoutTime, "days") > 0) {
        performingAutoLogout.current = true;
        navigate("/logout?auto=true");
        performingAutoLogout.current = false;
      }
    }
  }, [currentTime, isAuthenticated, navigate, pathname]);

  return <></>;
}
