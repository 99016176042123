export function removeCommas(str) {
  if (!str || str === undefined) {
    return str;
  } else {
    while (str.search(",") >= 0) {
      str = (str + "").replace(",", "");
    }
    return str;
  }
}

export function precisionFixed(number) {
  return parseFloat(+number.toPrecision(5)).toFixed(2);
}

export function precise_round(num) {
  var numNew = removeCommasNew(num);
  var decimals = 2;
  var sign = numNew >= 0 ? 1 : -1;
  return (
    Math.round(numNew * Math.pow(10, decimals) + sign * 0.001) /
    Math.pow(10, decimals)
  ).toFixed(decimals);
}

export function removeCommasNew(str) {
 return typeof str === 'string' ? str.replaceAll(',','') : str;
  if (!str) return str;
  str = str.toString();
  if (!str || str === undefined) {
    return str;
  } else {
    while (str.search(",") >= 0) {
      str = (str + "").replace(",", "");
    }
    return str;
  }
}
export function textPercSuffix(ele) {
  if (ele === "") return ele;
  ele = ele.split("%").join("") + "%";
  const value = ele;
  // ele.selectionStart = value.length - 1;
  // ele.selectionEnd = value.length - 1;
  return value;
}

export function allowNumber(elem) {
  return elem.replace(/[^0-9.]/g, "");
}

export function commaSeparateNumber(val) {
  val = val.toString().replace(/,/g, ""); //remove existing commas first
  var valRZ = val; //remove leading zeros, optional
  // var valRZ = val.replace(/^0+/, ''); //remove leading zeros, optional
  var valSplit = valRZ.split("."); //then separate decimals

  while (/(\d+)(\d{3})/.test(valSplit[0].toString())) {
    valSplit[0] = valSplit[0]
      .toString()
      .replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
  }

  if (valSplit.length == 2) {
    //if there were decimals
    val = valSplit[0] + "." + valSplit[1]; //add decimals back
  } else {
    val = valSplit[0];
  }

  return val;
}

export function numberWithCommas(x) {
  return new Intl.NumberFormat("en-IN").format(x);
  // return
  // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
export function formatNumber(num) {
  return new Intl.NumberFormat("en-IN").format(num);
}
export function addComma(num, minFractionDigit = 2) {
  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: minFractionDigit,
  }).format(num);
}

const obj = {
  removeCommas,
  precise_round,
  removeCommasNew,
  textPercSuffix,
  allowNumber,
  commaSeparateNumber,
  numberWithCommas,
};
export default obj;
