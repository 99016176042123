import smsImage from "assets/images/sms-vector.png";
import { checkMobileExist, submitUserSmsTemplate, updateCustomer } from "helpers/backendHelper";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { showError, showSuccess } from "utils/alertMessage";

const SMSPreview = ({
  showSmsPreviewModal,
  setShowSmsPreviewModal,
  workshop_id,
  getPhoneCodeForPhoneModal,
  getPhoneForPhoneModal,
  selectedRow,
  setLoading1,
  refreshData
}) => {

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const dateFormat = storedData.date_format;
  let workshopPhone = storedData?.workshop_phone

  const handleClose = () => {
    if (refreshData) {
      refreshData();
    }
    setShowSmsPreviewModal(!showSmsPreviewModal);
  }

  const [validateMobile, setValidateMobile] = useState(false);
  const [isUpdateMobile, setIsUpdateMobile] = useState(false);

  const [existValidation, setExistValidation] = useState();
  const [updateMobileNumber, setUpdateMobileNumber] = useState(false);



  let phoneCode = getPhoneCodeForPhoneModal?.phoneCode
    ? getPhoneCodeForPhoneModal?.phoneCode
    : getPhoneCodeForPhoneModal?.phone;
  let phone = getPhoneForPhoneModal ? getPhoneForPhoneModal.phone : "";
  if (phone) {
    phone = phone.replace(phoneCode, "");
  }

  let customer_id = getPhoneForPhoneModal
    ? getPhoneForPhoneModal.customer_id
    : "";

  let customer_detail_id = getPhoneForPhoneModal
    ? getPhoneForPhoneModal.customer_detail_id
    : "";

  let from_date = getPhoneForPhoneModal ? getPhoneForPhoneModal.from_date : "";
  let rego = getPhoneForPhoneModal ? getPhoneForPhoneModal.rego : "";
  let customerName = getPhoneForPhoneModal ? getPhoneForPhoneModal.customer_name : "";

  const [mobileNumber, setMobileNumber] = useState(phone);

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);
    return responseDate;
  };

  const [smsValue, setSmsValue] = useState({
    template: "",
    send_to: phone ? phone : "",
    message_body: `Hi ${customerName ?? "customer"} you missed an appointment ${dateFormatter(from_date)} for ${rego}. Contact us to make another appointment. ${workshopPhone ?? ""} (no reply)`
  });

  useEffect(() => {
    if (!isUpdateMobile) {
      setExistValidation(false);
    }
  }, [isUpdateMobile])

  async function checkMobileExists(e) {

    const requestMobile = {
      get: "all",
      conditions: {
        mobile_number: "0" + e,
        is_deleted: 0,
        workshop_id: workshop_id
      },
    };

    if (e.length !== 0) {
      if (e.length >= 9 && e.length <= 11) {
        setValidateMobile(false);
        const mobileDataResponse = await checkMobileExist({ payload: requestMobile });
        if (mobileDataResponse?.code === 200) {
          setExistValidation(true);
          setUpdateMobileNumber(false);
        } else {
          setExistValidation(false);
          setSmsValue({
            send_to: e,
            template: smsValue?.template,
            message_body: smsValue?.message_body
          });
          setUpdateMobileNumber(true);
        }
      } else {
        setExistValidation(false);
        setValidateMobile(true);
      }
    } else {
      setExistValidation(false);
    }

  };

  async function sendSMSToUser() {
    console.log('sendSMSToUser');
    if (updateMobileNumber) {

      const updateMobile = {
        mobile_number: smsValue?.send_to,
        id: customer_detail_id,
        workshop_id: workshop_id
      };


      await updateCustomer({ payload: updateMobile });

    }
    sendSMS('send');
  }


  const sendSMS = async (isSend = "") => {
    console.log('call send sms function');
    if (selectedRow.length !== 0) {
      setLoading1(true);

      let smsFormData = {};
      smsFormData.workshop_id = workshop_id;
      smsFormData.job_id = selectedRow;
      smsFormData.template_id = "";
      smsFormData.send_method = "sms";
      smsFormData.foreign_type = "Job";
      smsFormData.subject = "Job Reminder";
      smsFormData.send_to = [smsValue.send_to];
      smsFormData.to = [smsValue.send_to];
      smsFormData.message = smsValue.message_body;
      smsFormData.to_id = customer_id ? customer_id : "";
      smsFormData.foreign_id = selectedRow ? selectedRow : "";
      smsFormData.phone_code = phoneCode;
      smsFormData.typeScript = "job-script";
      smsFormData.foreign_table = "job_schedules";
      smsFormData.is_send_sms = isSend === "send" ? 1 : 0;

      const resp = await submitUserSmsTemplate({ payload: smsFormData });
      if (resp?.code === 200) {
        // Refetch data upon job update
        if (refreshData) {
          refreshData();
        }
        showSuccess("Notifications sent successfully.");
        handleClose();
        setLoading1(false);
      } else {
        showError(resp?.message || "Notifications sent failed.");
        handleClose();
        setLoading1(false);
      }

    } else {
      showError("Select at least One Record");
      handleClose();
      setLoading1(false);
    }
  };

  return (
    <>
      <Modal
        style={{ marginTop: "230px" }}
        className="preview-dialog"
        size="lg"
        show={showSmsPreviewModal}
        onHide={handleClose}
      >

        <Modal.Body>

          <div className="column-wrapper d-flex">
            <div className="left-column">
              <span className="panel-heading">PREVIEW</span>
              <hr />
              <div className="preview-outer">
                <div className="preview-wrapper">
                  <span className="panel-heading">MESSAGES</span>
                  <div className="panel-body">
                    <div className="msg-content">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: smsValue?.message_body
                            ? smsValue?.message_body
                            : "",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-column">

              <div className="col-sm-12 align-items-center d-flex ">
                <div className="align-items-center col-sm-2 text-align-center">
                  <div className="">
                    <img
                      src={smsImage}
                      alt="SMS"
                      style={{ maxWidth: "40px" }}
                    ></img>
                  </div>

                </div>
                <div className="col-sm-10 ">
                  <Form.Group className="form-group m-2">
                    <Form.Control
                      type="text"
                      minLength={9}
                      maxLength={11}
                      value={mobileNumber}
                      required
                      onChange={(e) => {
                        if (isUpdateMobile) {
                          checkMobileExists(e.target.value);
                        }
                        setMobileNumber(e.target.value);
                        setSmsValue({
                          template: smsValue?.template,
                          send_to: e.target.value,
                          message_body: smsValue?.message_body
                        })
                        if (e.target.value.length >= 9 && e.target.value.length <= 11) {
                          setValidateMobile(false);
                        }else {
                          setValidateMobile(true);
                        }
                      }}
                      placeholder="Mobile Number"
                    />
                    {existValidation && (
                      <div className="err-msg">
                        This Mobile Number is already in use.
                      </div>
                    )}
                    {validateMobile && (
                      <div className="err-msg">
                        Please enter a valid Mobile Number.
                      </div>
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="col-md-12 align-items-center d-flex mb-1">
                <div className="col-md-2">

                </div>

                <div className="col-sm-1 mb-1 d-flex justify-content-center">
                  <Form.Check className="custom-check form-check table-checkbox">
                    <Form.Check.Input
                      id="update_message"
                      type="checkbox"
                      className="custom-check booking-confirmation"
                      onChange={(e) => {
                        setIsUpdateMobile(!isUpdateMobile);
                        if (mobileNumber.length >= 9 && mobileNumber.length <= 11 && e.target.checked === true) {
                          checkMobileExists(mobileNumber);
                        }
                      }}
                    />
                    <Form.Check.Label>
                      <span className="check"></span>
                    </Form.Check.Label>
                  </Form.Check>
                </div>

                <div className="col-sm-9">
                  <div className="d-flex justify-space-between m-l-5">
                    <div className="font-size-12">Update Customer Mobile #</div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 align-items-center d-flex mt-2">

                <div className="col-md-2">

                </div>

                <div className="col-sm-9">

                  <Button
                    variant="outline-secondary"
                    className="btn-outline rounded-btn"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="rounded-btn ml-1"
                    variant="primary"
                    type="button"
                    disabled={existValidation === true || validateMobile === true}
                    onClick={(e) => {
                      sendSMSToUser('send')
                    }}
                  >
                    Send SMS
                  </Button>

                </div>
              </div>
            </div>
          </div>

        </Modal.Body>

      </Modal>


    </>
  );
};
export default SMSPreview;
