import Button from "@restart/ui/esm/Button";
import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { utcToTimezone } from '../../../utils/timeZoneConvert';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { PRINT_JOB_CARD_PDF, DELETE_JOB_ACTIVITY, CHECK_AVAILABLE_HOURS } from "../../../store/scheduler/actionTypes";
import { downloadFileFromS3 } from "../../../utils/uploadFileToS3";
import DeleteConfirm from '../../../utils/deleteConfirm';
import Timer from "./Timer";
import Reschedule from "./Reschedule";
import CreateJob from './CreateJob';
import LoaderImage from "../../../assets/images/page-loader.svg";
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import { Draggable } from '@fullcalendar/interaction';
import ApproveJobModal from "./ApproveJob";
import { showSuccess } from "../../../utils/alertMessage";
import ClockInWarningModal from "views/modals/ClockInWarningModal";
import PoWarning from "views/modals/PoWarning";
import NoShowWarning2 from "views/modals/NoShowWarning2";
import SMSPreview from "views/modals/SMSPreview";
import CarMakeLogo from "views/common/UI/CarMakeLogo";
import RescheduleOnly from "./RescheduleOnly";

const JobTicket = ({ eventInfo, isDuplicate, dupeObjs, view, workshop_id, eventArray, statusColor, setUpdateCalendar, technicianName, slotDuration, updateCalendar, setLoading1 }) => {
    const [loading, setLoading] = useState(false)
    const [slotLimit, setSlotLimitShow] = useState(false);
    const [showPOWarningModal, setShowPOWarningModal] = useState(false);
    const [showNoShowModal, setShowNoShowModal] = useState(false);
    const [showSmsPreviewModal, setShowSmsPreviewModal] = useState(false);
    const [selectedRowPhone, setSelectedRowPhone] = useState({});
    const [selectedRowPhoneCode, setSelectedRowPhoneCode] = useState({});
    const [selectedRow, setSelectedRow] = useState([]);


    const handleSlotLimitClose = () => setSlotLimitShow(false);
    var startEventTime = eventInfo.event._def.extendedProps.strt_time;
    var technicianName = "";
    if (eventInfo.view.type === "resourceTimelineDay" && eventInfo.view.type === "resourceTimelineWeek") {
        technicianName = eventInfo.event.getResources()[0]._resource.title;
    }
    let rescheduleDisable = '';
    let timerDisabled = '';
    let carryOverDisable = '';
    let noShowDisable = '';

    if ((eventInfo.event.extendedProps.job_progress_status !== "InProgress" && eventInfo.event.extendedProps.job_progress_status !== "OnHold") || eventInfo.event.extendedProps.is_copid_job === 1) {
        carryOverDisable = 'disable-option';
    }

    if (eventInfo.event.extendedProps.job_progress_status !== "Pending") {
        rescheduleDisable = 'disable-option';
        noShowDisable = 'disable-option';
    }

    if (eventInfo.event.extendedProps.job_progress_status === "Rescheduled") {
        rescheduleDisable = '';
        carryOverDisable = 'disable-option';
        timerDisabled = 'disable-option';
        noShowDisable = '';
    }

    const [eventLeft, setEventLeft] = useState(0)
    const [eventTop, setEventTop] = useState(0)
    const checkCoordinate = (e) => {
        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        const event_offsetX = currentTargetRect.left,
            event_offsetY = currentTargetRect.top
        setEventLeft(event_offsetX)
        setEventTop(event_offsetY)
    }

    var jobId = eventInfo.event.id;

    const [clickForPdf, setClickForPdf] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDeleteClose = () => setShowDeleteModal(false);
    const dispatch = useDispatch(); // Dispatch variable
    const navigate = useNavigate();

    const localStorageData = JSON.parse(localStorage.getItem('data'));
    const timezoneLable = localStorageData?.workshop?.workshop_other_details[0]?.timezone_label || 'Asia/Kolkata';
    let events = 0;
    let commonArray = [];
    if (eventInfo.event.extendedProps.req_type && eventInfo.event.extendedProps.req_type === "bay") {

        // let EventDate = utcToTimezone(eventInfo.event.start, timezoneLable, "YYYY-MM-DD-HH:mm", "YYYY-MM-DD-HH:mm");
        let EventDate = moment(eventInfo.event.start).format("YYYY-MM-DD-HH:mm");
        let resourceId = eventInfo.event["_def"].resourceIds[0];
        let ObjToGet = EventDate.toString() + resourceId.toString();

        eventArray && eventArray.forEach(value => {
            if (value[ObjToGet]) {
                commonArray.push(value[ObjToGet])
            }
        });
        var lengthCommon = commonArray?.[0] || null;
        if (lengthCommon !== null) {
            if (commonArray[0].length) {
                events = commonArray[0].length;
                commonArray = commonArray[0];
            } else {
                events = commonArray.length;
            }
        } else {
            events = commonArray.length;
        }
        var elements = document.querySelectorAll(`[data-id="${eventInfo.event.startStr}"]`);
        for (var i = 1; i < elements.length; i++) {
            elements[i].remove();
        }
    }

    const getJobCardPdf = (id) => {
        dispatch({
            type: PRINT_JOB_CARD_PDF,
            payload: {
                id: id,
                workshop_id: workshop_id
            }
        });
        setClickForPdf(true)
    }

    const jobCardPdf = useSelector((state) => state.SchedulerReducer.jobCardPdfPath);
    useEffect(() => {
        if (jobCardPdf !== null && jobCardPdf !== undefined && clickForPdf === true) {
            const downloadUrl = downloadFileFromS3(jobCardPdf.path);
            const newWindow = window.open(downloadUrl, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
            setClickForPdf(false);
        }
    }, [jobCardPdf])
    const [jobIdData, setJobIdData] = useState();
    const [employeeName, setEmployeeName] = useState();
    const [jobNumber, setJobNumber] = useState();
    const [timerShow, setTimerShow] = useState(false);
    const [clockInShow, setClockInShow] = useState(false);
    const [jobType, setJobType] = useState('reschedule');

    useEffect(() => {
        if (clockInShow) {
            setTimerShow(false);
        }
    }, [clockInShow])

    const handleTimerClose = () => setTimerShow(false);
    const handleTimerShow = (id) => {
        setJobIdData(id);
        setTimerShow(true);
    };

    const [rescheduleShow, setRescheduleShow] = useState(false);
    const handleRescheduleClose = () => {
        setRescheduleShow(false);
    }

    const handleRescheduleShow = (id, name, extProps, type) => {
        setJobIdData(id);
        setEmployeeName(name);
        setJobNumber(extProps.job_number);
        if (type == "time_reschedule") {
            setJobType("Rescheduled");
        } else {
            setJobType(type);
            setRescheduleShow(true);
        }
    };
    // Approve Job for reschedule
    const [approveJobId, setApproveJobId] = useState(null);
    const [approveJobFlag, setApproveJobFlag] = useState(false);
    const [approveJobModal, setApproveJobModal] = useState(false);
    const approveJob = (id) => {
        setApproveJobId(id)
        setApproveJobModal(true);
    }
    const approvedJobData = useSelector((state) => state.JobDetails.jobTimerData);
    useEffect(() => {
        if (approvedJobData !== null && approvedJobData !== undefined && approveJobFlag === true) {
            showSuccess('Job Approved Successfully');
            setApproveJobFlag(false);
            setUpdateCalendar(!updateCalendar);
        }
    }, [approvedJobData])

    const [activityDeleteId, setActivityDeleteId] = useState(0);
    const deleteRecord = async () => {
        dispatch({
            type: DELETE_JOB_ACTIVITY,
            payload: {
                id: activityDeleteId
            }
        });
        //TODO: Call DELETE API HERE
        setShowDeleteModal(false);
        setUpdateCalendar(!updateCalendar);
    }
    const handleDeleteOpen = (delete_id) => {
        if (delete_id !== "") {
            setShowDeleteModal(true);
            setActivityDeleteId(delete_id);
        }
    }
    const availableHours = useSelector((state) => state.SchedulerReducer.checkAvailableHours);
    const [newAvailableHours, setNewAvailableHours] = useState(false);
    useEffect(() => {
        if (availableHours) {
            setNewAvailableHours(true)
        } else {
            setNewAvailableHours(false)
        }
    }, [availableHours])

    const [showModal, setShowModal] = useState(false);
    const [showMonthViewPopup, setMonthViewPopup] = useState(false)
    const setCloseModal = () => {
        setMonthViewPopup(false);
        setShowModal(false)
        setNewAvailableHours(false)
    };

    const [bayStartTime, setBayStartTime] = useState();
    const [bayEndTime, setBayEndTime] = useState();
    const [bayUserId, setBayUserId] = useState();
    const [bayUserName, setBayUserName] = useState();

    const createBayJob = (startTime, id, name) => {
        var avilableData = moment(new Date(startTime)).format("YYYY-MM-DD");
        dispatch({
            type: CHECK_AVAILABLE_HOURS,
            payload: {
                date: avilableData,
                workshop_id: workshop_id
            }
        });

        setBayStartTime(startTime);
        setBayEndTime(startTime);
        setBayUserId(id);
        setBayUserName(name);
        setShowModal(true);
    }
    useEffect(() => {
        if (eventInfo.event.extendedProps.req_type && eventInfo.event.extendedProps.req_type === "bay") {
            var bayJobId = eventInfo.event.id;
            let draggableElBays = document.getElementsByClassName("events-list-" + bayJobId);
            if (draggableElBays.length !== 0) {
                let newDragg = new Draggable(draggableElBays[0], {
                    itemSelector: ".eventBays",
                    eventData: function (eventEl) {
                        let data = JSON.parse(eventEl.getAttribute("data-value"));
                        let id = eventEl.getAttribute("data");
                        return {
                            _id: id,
                            title: data.title,
                            rego: data.rego,
                            make: data.make,
                            model: data.model,
                            year: data.year,
                            customer_name: data.customer_name,
                            customer_mobile: data.customer_mobile,
                            data: data
                        };
                    }
                });
                return () => newDragg.destroy();
            }
        }
    }, [])

    const createJob = () => {
        setShowModal(true);
        setNewAvailableHours(true);
    }


    const loadData = () => {
        setUpdateCalendar(!updateCalendar);
    }


    return (
        <>
            {showDeleteModal && (
                <DeleteConfirm
                    handleDeleteClose={() => handleDeleteClose()}
                    deleteRecord={() => deleteRecord()}
                    msg={"Are you sure?"}
                />
            )}
            {
                approveJobModal && <ApproveJobModal setApproveJobModal={setApproveJobModal} approveJobId={approveJobId} setApproveJobFlag={setApproveJobFlag} />
            }
            {
                eventInfo.event.extendedProps.req_type && eventInfo.event.extendedProps.req_type === "bay" ? (
                    <div data-id={eventInfo.event.startStr} className="job-content-main">
                        <div id={eventInfo.event.startStr} className="total-events font-weight-bold d-flex justify-content-center">{events}</div>
                        <div className="font-weight-light d-flex justify-content-center">Jobs Booked</div>
                        <div className="d-flex justify-content-center">
                            <button className="create-job-btn" onClick={() => { createBayJob(eventInfo.event.startStr, eventInfo.event["_def"].resourceIds[0], eventInfo.event.getResources()[0]._resource.title) }}>Create Job</button>
                        </div>
                        <div className="show-events-box" onMouseOver={checkCoordinate}>
                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                            <ul className={"events-list events-list-" + eventInfo.event.id} style={{ position: "fixed", left: eventLeft + "px", top: eventTop + "px" }} >
                                {commonArray.map((value, index) => (
                                    <li className={"px-2 mb-1 eventBays " + eventInfo?.event?.extendedProps?.job_progress_status} data={value.id} key={index} data-value={JSON.stringify(value)} >
                                        <OverlayTrigger
                                            key={index}
                                            placement="auto"
                                            overlay={
                                                <Tooltip id="tooltip-bottom" className="calender-tooltip">
                                                    <div
                                                        className={"tooltip-header d-flex justify-space-between "}
                                                        style={{ background: `${statusColor[value.job_progress_status || 'Pending']}` }}
                                                    >
                                                        {/* <span>Job {value.job_number} | {utcToTimezone(value.pickup_time, timezoneLable, "HH:mm", "HH:mm")} </span> */}
                                                        {/* <span>Job {value.job_number} | P/U Time {utcToTimezone(value.pickup_time, timezoneLable, "HH:mm", "HH:mm")} </span> */}
                                                        <span>Job {value.job_number} | P/U Time {value?.pickup_time_only}</span>
                                                        <span>{(value.job_progress_status == "Payment") ? "Paid" : value.job_progress_status}</span>
                                                    </div>
                                                    <div className="tooltip-content">
                                                        {/* <ul key={value.make}>
                                                             <li>{value.rego} |</li>
                                                            <li>{value.make} {value.model} {value.year}</li>
                                                            <li>{value.title} | {value.tags.length ? value.tags[0] : ""}</li> 
                                                            <li>{value.customer_name ? value.customer_name : ""} | {value.customer_mobile ? value.customer_mobile : ""}</li>
                                                        </ul> */}
                                                        <div className="popup-border">
                                                            <p className="popup-border-top" key={value.make} >
                                                                {value.customer_name ? value.customer_name : ""} | {value.customer_mobile ? value.customer_mobile : ""}
                                                            </p>
                                                            {value?.description &&
                                                                <div className="popup-border-bottom"
                                                                    dangerouslySetInnerHTML={{ __html: value?.description }}
                                                                ></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div className="event p-1"
                                                style={{ borderLeft: `3px solid ${statusColor[value.job_progress_status || 'Pending']}` }}
                                            >

                                                <span className="d-flex align-items-center">
                                                    <CarMakeLogo
                                                        make={value.make && value.make || ""}
                                                        size='small'
                                                    />
                                                    <span className="time-block rego-border">{value.rego || ''}</span>
                                                </span>
                                                <span>
                                                    <span className="time-block">{value.make || ''} {value.model || ''} {value?.year || ''}</span><br />
                                                    {value?.job_duration > 30 &&
                                                        <span>{value?.tags[0] || ''}</span>
                                                    }
                                                </span>

                                                {/* <p>{value.rego}| {value.make}</p>
                                                <p>{value.customer_name ? value.customer_name : ""} | {value.customer_mobile ? value.customer_mobile : ""}</p> */}
                                            </div>
                                        </OverlayTrigger>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : eventInfo.event.classNames.length > 0 && eventInfo.event.classNames.includes('left-stripe-primary-black') ? (
                    <>
                        <OverlayTrigger
                            key="bottom"
                            placement="auto"
                            overlay={
                                <Tooltip id="tooltip-bottom1" className="calender-tooltip">
                                    <div
                                        className="tooltip-header d-flex align-center justify-space-between"
                                        style={{ background:"black" }}
                                    >
                                        <span>{eventInfo?.event?.title} </span>
                                    </div>
                                    <div>
                                        <div className="tooltip-content">
                                            <div className="popup-border">
                                                <div className="border-bottom p-1">{eventInfo?.event?.extendedProps?.activity_name}</div>
                                                <div className="p-1">{eventInfo?.event?.extendedProps?.description || 'No Description'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <div
                                data-id={eventInfo.event["_def"].resourceIds[0]}
                                className="event-slide d-flex justify-space-between direction-column"
                                // style={{ borderColor: "#f9990b" }}
                                style={{ borderLeft: `4px solid ${statusColor[eventInfo?.event?.extendedProps?.job_progress_status || "#f9990b"]}` }}
                            >
                                {/* <span className="label"><strong>{eventInfo.timeText}</strong></span> */}
                                {/* <span>{eventInfo?.event?.extendedProps?.activity_name || ''}</span> */}
                                <span>{eventInfo.event.extendedProps.activity_name}</span>
                                <span>{eventInfo.event.title}</span>
                                <span className="time-block">
                                    <i className="fa fa-clock-o font-size-16 text-warning text-warning"></i>
                                    {eventInfo.timeText}
                                </span>
                            </div>
                        </OverlayTrigger>
                        {/* <div className="close-job-icon"> */}
                        <Button className="close-job-icon" onClick={() => handleDeleteOpen(eventInfo?.event?.id || '')} style={{ borderRadius: "100%", background: "#8080805c" }}>
                            <i className="fa fa-times fa-w-16"></i>
                        </Button>
                        {/* </div> */}
                    </>
                ) : (
                    <>
                        <OverlayTrigger
                            key="bottom"
                            placement="auto"
                            overlay={
                                <Tooltip id="tooltip-bottom" className="calender-tooltip"  >
                                    <div
                                        className="tooltip-header d-flex align-center justify-space-between"
                                        style={{ background: `${statusColor[eventInfo?.event?.extendedProps?.job_progress_status || 'Pending']}` }}
                                    >
                                        {/* <span>Job {eventInfo?.event?.extendedProps?.job_number || 0} | {utcToTimezone(eventInfo?.event?.extendedProps?.pickup_time_only || "00:00", timezoneLable, "HH:mm:ss", "HH:mm")} </span> */}
                                        <span>Job {eventInfo?.event?.extendedProps?.job_number || 0} | P/U Time {eventInfo?.event?.extendedProps?.pickup_time_only} </span>
                                        <div className="job-id ml-1">{eventInfo?.event?.extendedProps?.job_progress_status || ''}
                                            {(eventInfo?.event?.extendedProps?.customer_quotes_id && Number(eventInfo?.event?.extendedProps?.customer_quotes_id) > 0) && <label className="job-label pj-tag m-l-5" title="Job from Quote">Q</label>}
                                            {(+eventInfo?.event?.extendedProps?.is_copy_job === "" || +eventInfo?.event?.extendedProps?.is_copy_job === 0) && +eventInfo?.event?.extendedProps?.is_copid_job === 1 ? <label className="job-label pj-tag m-l-5" title="Parent Job">CO</label> : ''}
                                            {parseInt(eventInfo?.event?.extendedProps?.is_copy_job) || 0 > 0 ? <label className="job-label co-tag m-l-5" title="Carry Over Job">CO{eventInfo?.event?.extendedProps?.is_copy_job}</label> : ''}
                                        </div>
                                    </div>
                                    <div className="tooltip-content">
                                        {/* <ul key={eventInfo?.event?.extendedProps?.rego}>
                                            <li>{eventInfo?.event?.extendedProps?.rego || ''} | { }</li>
                                            <li>{eventInfo?.event?.extendedProps?.make || ''} {eventInfo?.event?.extendedProps?.model || ''} {eventInfo?.event?.extendedProps?.year || ''}</li>
                                            <li>{eventInfo?.event?.title || ''} | {eventInfo?.event?.extendedProps?.tags?.[0] || ""}</li>
                                            <li>{eventInfo?.event?.extendedProps?.customer_name || ""} | {eventInfo?.event?.extendedProps?.customer_mobile || ""}</li>
                                        </ul> */}
                                        <div className="popup-border">
                                            <p className="popup-border-top" key={eventInfo?.event?.extendedProps?.rego} >
                                                {eventInfo?.event?.extendedProps?.customer_name || ""} | {eventInfo?.event?.extendedProps?.customer_mobile || ""}
                                            </p>
                                            {eventInfo?.event?.extendedProps?.description ?
                                                <div className="popup-border-bottom"
                                                    dangerouslySetInnerHTML={{ __html: eventInfo?.event?.extendedProps?.description }}
                                                ></div>
                                                :
                                                <div className="popup-border-bottom">
                                                    No description.
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <div
                                data-id={eventInfo?.event["_def"]?.resourceIds?.[0] || []}
                                className="event-slide d-flex justify-space-between direction-column"
                                style={{ borderLeft: `4px solid ${statusColor[eventInfo?.event?.extendedProps?.job_progress_status || 'Pending']}` }}
                            >
                                {/* <span className="d-flex align-items-center"> */}
                                <span className="d-flex align-items-center">
                                    <CarMakeLogo
                                        make={eventInfo?.event?.extendedProps?.make && eventInfo?.event?.extendedProps?.make || ""}
                                        size='small'
                                    />
                                    <span className="time-block rego-border">{eventInfo?.event?.extendedProps?.rego || ''}</span>
                                    {eventInfo?.event?.extendedProps?.job_duration > 60 &&
                                        <span>{parseInt(eventInfo?.event?.extendedProps?.is_copy_job) || 0 > 0 ? <label className="job-label co-tag m-l-5" title="Carry Over Job">CO{eventInfo?.event?.extendedProps?.is_copy_job}</label> : ''}</span>
                                    }
                                </span>
                                <span>
                                    <span className="time-block">{eventInfo?.event?.extendedProps?.make || ''} {eventInfo?.event?.extendedProps?.model || ''} {eventInfo?.event?.extendedProps?.year || ''}</span>
                                    {eventInfo?.event?.extendedProps?.job_duration > 30 &&
                                        <span>{eventInfo?.event?.extendedProps?.tags[0] || ''}</span>
                                    }
                                    {/* <span>{eventInfo?.event?.extendedProps?.odometer_reading > 0 && eventInfo?.event?.extendedProps?.odometer_reading !== "undefined" ? eventInfo?.event?.extendedProps?.odometer_reading + ' Km' : ''} {eventInfo?.event?.title || ''}</span> */}
                                </span>
                                {/* </span> */}
                                {/* <CarMakeLogo make={eventInfo?.event?.extendedProps?.make && eventInfo?.event?.extendedProps?.make || ""} />
                                <span className="label">Rego: {eventInfo?.event?.extendedProps?.rego || ''}</span>
                                <div className="d-flex justify-space-between" >
                                    <span>{eventInfo?.event?.extendedProps?.make || ''} {eventInfo?.event?.extendedProps?.model || ''} {eventInfo?.event?.extendedProps?.year || ''}</span>
                                    {+eventInfo?.event?.extendedProps?.is_multiple_tech_job > 0 &&
                                        <span className="co-tag mr-5">{+eventInfo?.event?.extendedProps?.is_multiple_tech_job}</span>
                                    }
                                </div>
                                <span className="time-block">
                                    <i className="fa fa-clock-o font-size-16 text-warning text-warning"></i>
                                    {eventInfo?.event?.extendedProps?.odometer_reading + ' Km' || ''} {eventInfo?.event?.title || ''}
                                </span> */}
                            </div>
                        </OverlayTrigger >
                        <div className="more-options" onMouseOver={checkCoordinate}>
                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i> <br />
                            {+eventInfo?.event?.extendedProps?.is_multiple_tech_job > 0 &&
                                <span className="co-tag mr-5">{+eventInfo?.event?.extendedProps?.is_multiple_tech_job}</span>
                            }
                            <ul className="options-list" key="jobList" style={{ position: "fixed", left: eventLeft + "px", top: eventTop + "px" }}>
                                {eventInfo.event.extendedProps.is_copid_job === 1 ? (
                                    <li>
                                        <span onClick={() => navigate("/view_job/" + eventInfo.event.id)}>
                                            View Job
                                        </span>
                                    </li>
                                ) : (
                                    <li>
                                        <span onClick={() => navigate("/job_detail/" + eventInfo.event.id)}>
                                            View Job
                                        </span>
                                    </li>
                                )}

                                {/* <li className={timerDisabled}>
                                    <span onClick={() => {
                                        if (moment(startEventTime).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
                                            setClockInShow(true);
                                            handleRescheduleShow(jobId, eventInfo.event.getResources()[0]._resource.title, eventInfo.event.extendedProps, 'time_reschedule')
                                        } else {
                                            handleTimerShow(eventInfo.event.id)
                                        }
                                    }}>
                                        Time Clock
                                    </span>
                                </li> */}
                                <li>
                                    <Link target="_blank" to={`/booking-diary/print-job-cards?id=${eventInfo.event.id}`}>
                                        <span >
                                            Job Card
                                        </span>
                                    </Link>
                                </li>
                                <li className={rescheduleDisable} onClick={() => {
                                    handleRescheduleShow(jobId, eventInfo.event.getResources()[0]._resource.title, eventInfo.event.extendedProps, 'reschedule')
                                }}>
                                    <span >
                                        Reschedule
                                    </span>
                                </li>
                                <li className={" carry-over-option " + carryOverDisable}>
                                    <span onClick={() => {
                                        handleRescheduleShow(jobId, eventInfo.event.getResources()[0]._resource.title, eventInfo.event.extendedProps, 'carryover')
                                    }}>
                                        Carry Over Job
                                    </span>
                                </li>
                                {
                                    // (eventInfo.event.extendedProps.job_progress_status === "Rescheduled") ?
                                    //     <li className="approve-job-option">
                                    //         <span onClick={() => approveJob(eventInfo.event.id)}>
                                    //             Approve Job
                                    //         </span>
                                    //     </li>
                                    //     : ''
                                }
                                <li className={" no-show-option " + noShowDisable}
                                    onClick={() => {
                                        setSelectedRow("");
                                        setShowPOWarningModal(false);
                                        setShowNoShowModal(false);
                                        setSelectedRowPhone({});
                                        setSelectedRowPhoneCode({});

                                        setSelectedRowPhone({
                                            phone:
                                                eventInfo.event._def.extendedProps.customer_mobile_code +
                                                eventInfo.event._def.extendedProps.customer_mobile,
                                            customer_id: eventInfo.event._def.extendedProps?.customer_id,
                                            customer_detail_id: eventInfo.event._def.extendedProps?.customer_detail_id,
                                            from_date: eventInfo.event._def.extendedProps?.from_date,
                                            rego: eventInfo.event._def.extendedProps?.rego,
                                            customer_name: eventInfo.event._def.extendedProps?.customer_name,
                                        });
                                        setSelectedRowPhoneCode({
                                            phoneCode:
                                                eventInfo.event._def.extendedProps.customer_mobile_code
                                            // row.customer.cust_detail.mobile_number,
                                        });
                                        setSelectedRow(eventInfo.event._def?.publicId);

                                        if (eventInfo.event._def.extendedProps?.jobs_parts > 0) {
                                            setShowPOWarningModal(!showPOWarningModal);
                                        } else {
                                            setShowNoShowModal(!showNoShowModal);
                                        }
                                    }}
                                >
                                    <span>No Show Job </span>
                                </li>
                            </ul>
                        </div>
                    </>
                )
            }
            {
                (timerShow === true) ?
                    <Timer
                        timerShow={timerShow}
                        handleTimerClose={handleTimerClose}
                        jobIdData={jobIdData}
                        setUpdateCalendar={setUpdateCalendar}
                        updateCalendar={updateCalendar}
                        setClockInShow={setClockInShow}
                    ></Timer>
                    : ''
            }
            {
                clockInShow &&
                <ClockInWarningModal
                    clockInShow={clockInShow}
                    setClockInShow={setClockInShow}
                    setRescheduleShow={setRescheduleShow}
                />
            }

            {
                (rescheduleShow === true) ?
                    (jobType === 'reschedule' || jobType === 'carryover') ?

                        <RescheduleOnly
                            rescheduleShow={rescheduleShow}
                            handleRescheduleClose={handleRescheduleClose}
                            jobIdData={jobIdData}
                            startEventTime={startEventTime}
                            technicianName={employeeName}
                            jobNumber={jobNumber}
                            setUpdateCalendar={setUpdateCalendar}
                            updateCalendar={updateCalendar}
                            jobType={jobType}
                        ></RescheduleOnly>
                        :
                        <Reschedule
                            rescheduleShow={rescheduleShow}
                            handleRescheduleClose={handleRescheduleClose}
                            jobIdData={jobIdData}
                            startEventTime={startEventTime}
                            technicianName={employeeName}
                            jobNumber={jobNumber}
                            setUpdateCalendar={setUpdateCalendar}
                            updateCalendar={updateCalendar}
                            jobType={jobType}
                        ></Reschedule> : ''
            }
            {
                loading ? <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div> : ""
            }
            {
                (availableHours !== null || showMonthViewPopup) ?
                    ((showModal && (availableHours === true || newAvailableHours == true)) || showMonthViewPopup) ?
                        <CreateJob
                            show={showModal}
                            showMonthViewPopup={showMonthViewPopup}
                            close={setCloseModal}
                            slotDuration={slotDuration}
                            technicianName={bayUserName}
                            startTimeCalendar={bayStartTime}
                            endTimeCalendar={bayEndTime}
                            technicianId={bayUserId}
                            technicianType="bay"
                            setUpdateCalendar={setUpdateCalendar}
                            updateCalendar={updateCalendar}
                            dashboardJob=""
                            handleSlotLimitClose={handleSlotLimitClose}
                        ></CreateJob> :
                        (availableHours === false) ?
                            <Modal
                                className="info-dialog info-dialog-popup"
                                size="sm"
                                show={slotLimit}
                                onHide={handleSlotLimitClose}
                            >
                                <div className="heading-block d-flex justify-center">
                                    {/* <i className="la la-exclamation-circle info-icon" aria-hidden="true"></i> */}
                                    <p class="d-flex font-size-30 font-weight-600 heading-block justify-center text-danger">Warning</p>
                                    <p class="border p-2 rounded-circle available-hours" >!</p>
                                </div>
                                <Modal.Body>
                                    <div>
                                        <h2 className="font-size-24 mt-2" >Available hours exceeded,</h2>
                                        <h2 className="font-size-24" >Press continue to proceed</h2>
                                        <h2 class="mb-2 font-size-24"> or cancel</h2>
                                    </div>
                                    <div className="d-flex justify-center">
                                        <Button
                                            variant="primary"
                                            className="btn-primary rounded-btn"
                                            onClick={() => createJob()}
                                        >
                                            Continue
                                        </Button>
                                        <Button
                                            variant="primary"
                                            className="btn-danger btn-lg rounded-btn"
                                            onClick={() => handleSlotLimitClose()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ''
                    : ''

            }

            {
                showPOWarningModal &&
                <PoWarning
                    showPOWarningModal={showPOWarningModal}
                    setShowPOWarningModal={setShowPOWarningModal}
                    setShowNoShowModal={setShowNoShowModal}
                />
            }

            {
                showNoShowModal &&
                <NoShowWarning2
                    showNoShowModal={showNoShowModal}
                    setShowNoShowModal={setShowNoShowModal}
                    selectedRow={selectedRow}
                    refreshData={loadData}
                    getPhoneForPhoneModal={selectedRowPhone}
                    getPhoneCodeForPhoneModal={selectedRowPhoneCode}
                    setLoading1={setLoading1}
                    setShowSmsPreviewModal={setShowSmsPreviewModal}
                />
            }

            {
                showSmsPreviewModal &&
                <SMSPreview
                    showSmsPreviewModal={showSmsPreviewModal}
                    setShowSmsPreviewModal={setShowSmsPreviewModal}
                    getPhoneForPhoneModal={selectedRowPhone}
                    getPhoneCodeForPhoneModal={selectedRowPhoneCode}
                    workshop_id={workshop_id}
                    selectedRow={selectedRow}
                    setLoading1={setLoading}
                    refreshData={loadData}
                />
            }

        </>
    );
};

export default JobTicket;
