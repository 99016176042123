export const GET_OPEN_QUOTES = "GET_OPEN_QUOTES";
export const GET_OPEN_QUOTES_SUCCESS = "GET_OPEN_QUOTES_SUCCESS";
export const GET_OPEN_QUOTES_FAIL = "GET_OPEN_QUOTES_FAIL";

export const EDIT_QUOTES = "EDIT_QUOTES";
export const EDIT_QUOTES_SUCCESS = "EDIT_QUOTES_SUCCESS";
export const EDIT_QUOTES_FAIL = "EDIT_QUOTES_FAIL";

export const GET_OPEN_QUOTES_COUNT = "GET_OPEN_QUOTES_COUNT";
export const GET_OPEN_QUOTES_COUNT_SUCCESS = "GET_OPEN_QUOTES_COUNT_SUCCESS";
export const GET_OPEN_QUOTES_COUNT_FAIL = "GET_OPEN_QUOTES_COUNT_FAIL";

export const CHECK_STATUS_CHANGE = "CHECK_STATUS_CHANGE";
