import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { searchJobsSuccess } from "store/commonApiStore/actions";
import bookJobBlue from "../../../assets/images/book-job-blue.svg";
import AssignedJobList from "./assignedJobList";
import CompletedJobList from "./completedJobList";
import InProgressJobList from "./inProgressJobList";
import UnassignedJobList from "./unassignedjobs/unassignedJobList";
import { GET_WORKSHOP_TECHNICIAN } from "store/commonApiStore/actionTypes";

let JobList = () => {
  const [activeTab, setActiveTab] = useState("assigned-job");
  const location = useLocation();
  const dispatch = useDispatch();

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);

  useEffect(() => {
    document.title = "5iQ | Job List";

    const hash = location.hash;
    setActiveTab(hash.split("#")[1]);
    var url = window.location.href;
    url = url.split('#');
    const nextURL = url[0];
    const nextTitle = "5iQ | Job List";
    const nextState = {};
    window.history.replaceState(nextState, nextTitle, nextURL);
  }, [location.hash]);

  dispatch(searchJobsSuccess({}));

  if (activeTab === "assigned-job") {
    const fetchTechData = async () => {
      dispatch({
        type: GET_WORKSHOP_TECHNICIAN,
        payload: {
          get: "all",
          conditions: { "Workshops.id": workshop_id },
          contain: {
            Users: {
              conditions: {
                "Users.workshop_id": workshop_id,
                "Users.role_id IN": [3, 7, 8, 9],
                "Users.is_deleted": 0,
              },
              fields: ["id", "workshop_id", "firstname", "lastname"],
            },
            "Users.EmployeesDetails": {
              fields: [
                "EmployeesDetails.id",
                "EmployeesDetails.user_id",
                "EmployeesDetails.shift_working_day",
                "EmployeesDetails.workshop_id",
              ],
            },
            WorkshopOtherDetails: {
              fields: [
                "shift_start_time",
                "shift_finish_time",
                "shift_start_day",
                "shift_finish_day",
                "id",
                "workshop_id",
                "schedule_details",
                "technician_timeslot",
              ],
            },
          },
          fields: ["id"],
        },
      });
    };
    fetchTechData().catch(console.error);
  }

  return (
    <>
      <div className="right-section-wrapper">
        <div className="table-header">
          <div className="table-header-tabs">
            <Tab.Container
              defaultActiveKey={"assigned-job"}
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <div className="row top-block">
                <div className="col-md-2">
                  <div className="table-title">
                    <img src={bookJobBlue}></img>
                    <span>Jobs</span>
                  </div>
                </div>
                <div className="col-md-8">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="unassigned-job">
                        Unassigned Jobs
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="assigned-job">Assigned Jobs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="inProgress-job">
                        In Progress Jobs
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="complete-job">
                        Completed Jobs
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-2">
                  <div className="video-link-outer">
                    <div className="video-tutorial">
                      <i className="fa fa-icon"></i>
                      {/* <span className="help-text">Help</span> */}
                      <Link to="/trainee-video#jobs" ><span className="tutorial-title">Jobs Videos</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="unassigned-job">
                  {activeTab === "unassigned-job" &&
                    <UnassignedJobList activeTab={activeTab} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="assigned-job">
                  {(activeTab === "assigned-job" || activeTab === undefined) &&
                    <AssignedJobList activeTab={activeTab} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="inProgress-job">
                  {activeTab === "inProgress-job" &&
                    <InProgressJobList activeTab={activeTab} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="complete-job">
                  {activeTab === "complete-job" &&
                    <CompletedJobList activeTab={activeTab} />
                  }
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobList;
