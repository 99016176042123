import * as actions from "./actionTypes";

const initialState = {
  saveInpection: "",
  token: "",
  jobBookedData: null,
  jobBookedKeyTagData: null,
  jobTimerData: null,
  vehicleData: null,
  vehicleHistoryData: null,
  totalTimerData: null,
  regoLookUpData: null,
  loadingData: false,
  jobNotesData: null,
  message: [],
  inspectionView: [],
  internalNotes: [],
  internalNotesScripts: [],
  error: {
    message: "",
  },
  attachment: [],
  inspTemplates: [], // inspection templates list
  inspInspections: [], // inspection list
  techniciansList: [], // technician list
  inspectionQuestionsAnswers: [], // default or not stored inspection questions
  approveManualInspectionResponse: [], // approved inspection response
  inspectionGroup: [], //get inspection groups
  inspectionLoading: true,
  inspectionError: false,
  mount: false,
  finalize: false,
  jobTag: null,
  jobTagOption: null,
  cannedData: null,
  deleteInspection: null,
  recommendationData: [],
  parentJobData: [],
  jobBookSmsSent: false,
  updateManyJob: [],
  jobClockingHistory: []
};

const JobDetails = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_JOB_BOOKED_DETAILS:
      state = {
        ...state,
        loadingData: true,
        cleared: false,
      };
      break;
    case actions.GET_JOB_BOOKED_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobBookedData: action.payload,
        error: {
          message: ""
        }
      };
      break;
    case actions.GET_JOB_BOOKED_DETAILS_FAIL:
      state = {
        ...state,
        jobBookedData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_JOB_BOOKED_KEY_TAGS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_JOB_BOOKED_KEY_TAGS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobBookedKeyTagData: action.payload,
      };
      break;
    case actions.GET_JOB_BOOKED_KEY_TAGS_FAIL:
      state = {
        ...state,
        jobBookedKeyTagData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_REGO_LOOKUP_DATA:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_REGO_LOOKUP_DATA_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        regoLookUpData: action.payload,
      };
      break;
    case actions.GET_REGO_LOOKUP_DATA_FAIL:
      state = {
        ...state,
        regoLookUpData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.UPDATE_JOB_TIMER:
      state = {
        ...state,
        loadingData: true,
        is_job_updated: false,
      };
      break;
    case actions.UPDATE_JOB_TIMER_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobTimerData: action.payload,
        is_job_updated: true,
      };
      break;
    case actions.UPDATE_JOB_TIMER_FAIL:
      state = {
        ...state,
        jobTimerData: null,
        error: {
          message: action.payload,
        },
        is_job_updated: false,
        loadingData: false,
      };
      break;
    case actions.UPDATE_VEHICLE:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.UPDATE_VEHICLE_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        vehicleData: action.payload,
      };
      break;
    case actions.UPDATE_VEHICLE_FAIL:
      state = {
        ...state,
        vehicleData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_TOTAL_TIMER:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_TOTAL_TIMER_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        totalTimerData: action.payload,
      };
      break;
    case actions.GET_TOTAL_TIMER_FAIL:
      state = {
        ...state,
        totalTimerData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_VEHICLE_HISTORY:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_VEHICLE_HISTORY_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        vehicleHistoryData: action.payload,
      };
      break;
    case actions.UPDATE_JOB_INFO:
      const [job] = state.jobBookedData;
      const newJob = {
        ...job,
        ...action.payload,
      };
      state = {
        ...state,
        jobBookedData: [newJob],
      };
      break;
    case actions.GET_VEHICLE_HISTORY_FAIL:
      state = {
        ...state,
        vehicleHistoryData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.GET_JOB_NOTES:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_JOB_NOTES_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobNotesData: action.payload,
      };
      break;
    case actions.GET_JOB_NOTES_FAIL:
      state = {
        ...state,
        jobNotesData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.ADD_JOB_NOTES:
      state = {
        ...state,
      };
      break;
    case actions.ADD_JOB_NOTES_SUCCESS:
      state = {
        ...state,
        jobNotesData: action.payload,
      };
      break;
    case actions.ADD_JOB_NOTES_FAIL:
      state = {
        ...state,
        jobNotesData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case actions.UPDATE_JOB_NOTES:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.UPDATE_JOB_NOTES_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobNotesData: action.payload,
      };
      break;
    case actions.UPDATE_JOB_NOTES_FAIL:
      state = {
        ...state,
        jobNotesData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.GET_MESSAGE:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_MESSAGE_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        message: action.payload,
      };
      break;
    case actions.GET_MESSAGE_FAIL:
      state = {
        ...state,
        message: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.GET_INSPECTION_VIEW:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_INSPECTION_VIEW_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspectionView: action.payload,
      };
      break;
    case actions.GET_INSPECTION_VIEW_FAIL:
      state = {
        ...state,
        inspectionView: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_INSPECTIONS:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_INSPECTIONS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspInspections: action.payload,
      };
      break;
    case actions.GET_INSPECTIONS_FAIL:
      state = {
        ...state,
        inspInspections: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_INSPECTION_TEMPLATES:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_INSPECTION_TEMPLATES_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspTemplates: action.payload,
      };
      break;
    case actions.GET_INSPECTION_TEMPLATES_FAIL:
      state = {
        ...state,
        inspTemplates: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_TECHNICIAN_LIST:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case actions.GET_TECHNICIAN_LIST_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        techniciansList: action.payload,
      };
      break;
    case actions.GET_TECHNICIAN_LIST_FAIL:
      state = {
        ...state,
        // techniciansList: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.GET_INTERNAL_NOTES:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_INTERNAL_NOTES_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        internalNotes: action.payload,
      };
      break;
    case actions.GET_INTERNAL_NOTES_FAIL:
      state = {
        ...state,
        internalNotes: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.GET_INTERNAL_NOTES_SCRIPTS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_INTERNAL_NOTES_SCRIPTS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        internalNotesScripts: action.payload,
      };
      break;
    case actions.GET_INTERNAL_NOTES_SCRIPTS_FAIL:
      state = {
        ...state,
        internalNotesScripts: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.GET_ATTACHMENT:
      state = {
        ...state,
        loadingData: true,
      };
      break;

    case actions.GET_ATTACHMENT_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        attachment: action.payload,
      };
      break;
    case actions.GET_ATTACHMENT_FAIL:
      state = {
        ...state,
        attachment: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.DEFAULT_PRICE_SUMMARY:
      state = {
        ...state,
        loadingData: true,
      };
      break;

    case actions.DEFAULT_PRICE_SUMMARY_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        psummaryData: action.payload,
      };
      break;
    case actions.DEFAULT_PRICE_SUMMARY_FAIL:
      state = {
        ...state,
        psummaryData: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.GET_INSPECTION_QUESTIONS_ANSWERS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.UPDATE_INSPECTION_QUESTIONS_ANSWERS:
      state = {
        ...state,
        loadingData: false,
        inspectionQuestionsAnswers: action.payload,
      };
      break;
    case actions.APPROVE_MANUAL_INSPECTION:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.APPROVE_MANUAL_INSPECTION_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        approveManualInspectionResponse: [],
        loadingData: false,
      };
      break;
    case actions.APPROVE_MANUAL_INSPECTION_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        approveManualInspectionResponse: action.payload,
      };
      break;
    case actions.RESEND_INSPECTION:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.RESEND_INSPECTION_SUCCESS:
      state = {
        ...state,
        loadingData: false,
      };
      break;
    case actions.RESEND_INSPECTION_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
      };
      break;
    case actions.ADD_PARTS_MANUAL_INSPECTION_APPROVED:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.ADD_PARTS_MANUAL_INSPECTION_APPROVED_SUCCESS:
      state = {
        ...state,
        loadingData: false,
      };
      break;
    case actions.ADD_PARTS_MANUAL_INSPECTION_APPROVED_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
      };
      break;
    case actions.GET_INSPECTION_GROUP:
      state = {
        ...state,
        loadingData: true
      };
      break;
    case actions.GET_INSPECTION_GROUP_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspectionGroup: action.payload,
      };
      break;
    case actions.GET_INSPECTION_GROUP_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
        inspectionGroup: [],
      };
      break;
    case actions.SAVE_INSPECTION:
      state = {
        ...state,
        saveInpection: "processing",
      };
      break;
    case actions.SAVE_INSPECTION_SUCCESS:
      const jobDetail = { ...state.jobBookedData[0] };
      jobDetail.inspection_id = action.payload.inspection_id || 0;
      state = {
        ...state,
        saveInpection: "success",
        jobBookedData: [jobDetail],
      };
      break;
    case actions.SAVE_INSPECTION_FAIL:
      state = {
        ...state,
        saveInpection: "failed",
      };
      break;
    case actions.CLEAR_SAVE_INSPECTION_STATUS:
      state = {
        ...state,
        saveInpection: "",
      };
      break;
    case actions.GET_INSPECTION_DETAIL:
      state = {
        ...state,
        inspectionLoading: true,
        inspectionError: false,
      };
      break;
    case actions.GET_INSPECTION_DETAIL_SUCCESS:
      state = {
        ...state,
        inspectionLoading: false,
        inspectionError: false,
      };
      break;
    case actions.GET_INSPECTION_DETAIL_FAIL:
      state = {
        ...state,
        inspectionLoading: false,
        inspectionError: true,
      };
      break;
    case actions.CLEAR_JOB_DATA:
      state = {
        ...state,
        jobBookedData: null,
        inspInspections: [],
        cleared: true,
        finalize: false,
      };
      break;
    case actions.FINALIZE_INVOICE:
      state = {
        ...state,
        finalize: true,
      };
      break;
    /**Clock in reducer */
    case actions.CLOCK_IN_JOB:
      return { ...state };
    case actions.CLOCK_IN_JOB_SUCCESS:
      return { ...state, jobBookedData: [action.payload] };
    case actions.CLOCK_IN_JOB_FAIL:
      return { ...state };
    case actions.CLOCK_OUT_JOB:
      return { ...state };
    case actions.CLOCK_OUT_JOB_SUCCESS:
      return { ...state, jobBookedData: [action.payload] };
    case actions.CLOCK_OUT_JOB_FAIL:
      return { ...state };
    case actions.PAUSE_TIME_JOB:
      return { ...state };
    case actions.PAUSE_TIME_JOB_SUCCESS:
      return { ...state, jobBookedData: [action.payload] };
    case actions.PAUSE_TIME_JOB_FAIL:
      return { ...state };
    case actions.RESUME_TIME_JOB:
      return { ...state };
    case actions.RESUME_TIME_JOB_SUCCESS:
      return { ...state, jobBookedData: [action.payload] };
    case actions.RESUME_TIME_JOB_FAIL:
      return { ...state };
    case actions.JOB_MOUNT:
      return { ...state, mount: true };
    case actions.JOB_UNMOUNT:
      return { ...state, mount: false };
    /**Clock in reducer */

    case actions.GET_JOB_TAG:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_JOB_TAG_SUCCESS:
      let tag = [];
      let tagOption = [];

      action.payload.forEach((tags) => {
        tag[tags.id] = { value: tags.id, label: tags.name };
        tagOption.push({ value: tags.id, label: tags.name });
      });
      state = {
        ...state,
        loadingData: false,
        jobTag: tag,
        jobTagOption: tagOption,
      };
      break;
    case actions.GET_JOB_TAG_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
        jobTag: [],
      };
      break;

    case actions.GET_JOBCANNED_LISTS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case actions.GET_JOBCANNED_LISTS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        cannedData: action.payload,
      };
      break;
    case actions.GET_JOBCANNED_LISTS_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        cannedData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case actions.DELETE_INSPECTION:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case actions.DELETE_INSPECTION_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        deleteInspection: action.payload,
      };
      break;
    case actions.DELETE_INSPECTION_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        deleteInspection: null,
        error: {
          message: action.payload,
        },
      };
      break;


    case actions.GET_JOB_RECOMMENDATIONS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_JOB_RECOMMENDATIONS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        recommendationData: action.payload,
      };
      break;
    case actions.GET_JOB_RECOMMENDATIONS_FAIL:
      state = {
        ...state,
        recommendationData: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;


    case actions.GET_PARENT_JOB_DETAILS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_PARENT_JOB_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        parentJobData: action.payload,
      };
      break;
    case actions.GET_PARENT_JOB_DETAILS_FAIL:
      state = {
        ...state,
        parentJobData: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;


    case actions.SENT_BOOKINGJOB_SMS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.SENT_BOOKINGJOB_SMS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobBookSmsSent: action.payload,
      };
      break;
    case actions.SENT_BOOKINGJOB_SMS_FAIL:
      state = {
        ...state,
        jobBookSmsSent: action.payload,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.UPDATE_MANY_JOBS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.UPDATE_MANY_JOBS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        updateManyJob: action.payload,
      };
      break;
    case actions.UPDATE_MANY_JOBS_FAIL:
      state = {
        ...state,
        updateManyJob: action.payload,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case actions.GET_JOB_CLOCKING_HISTORY_DATA:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case actions.GET_JOB_CLOCKING_HISTORY_DATA_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobClockingHistory: action.payload,
      };
      break;
    case actions.GET_JOB_CLOCKING_HISTORY_DATA_FAIL:
      state = {
        ...state,
        jobClockingHistory: action.payload,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JobDetails;
