import Loadable from "react-loadable";

import { Loading } from "../common/navigation";
// import InspectionEmailVerification from 'views/job/components/jobDetails/InspectionTab/InspectionEmailVerification'

const Login = Loadable({
  loader: () => import("./components/login"),
  loading: Loading,
});
const Logout = Loadable({
    loader: () => import("./components/logout"),
    loading: Loading,
  });
const ForgotPassword = Loadable({
  loader: () => import("./components/forgot-password"),
  loading: Loading,
});

const ForgotEmail = Loadable({
  loader: () => import("./components/forgot-email"),
  loading: Loading,
});

const InspectionEmailVerification = Loadable({
  loader: () => import("views/job/components/jobDetails/InspectionTab/InspectionEmailVerification"),
  loading: Loading,
});

const DigitalQuotePdf = Loadable({
  loader: () => import("views/quotes/components/EmailQuote/DigitalQuotePdf"),
  loading: Loading,
});

const DigitalInvoicePdf = Loadable({
  loader: () => import("views/invoices/components/InvoiceDetailComponents/InvoicePreview/DigitalInvoicePdf"),
  loading: Loading,
});

const DigitalStatementPdf = Loadable({
  loader: () => import("views/invoices/components/StatementInvoiceEdit/DigitalStatementPdf"),
  loading: Loading,
});

const DigitalReportStatement = Loadable({
  loader: () =>import("views/Reports/components/Invoices/DigitalStatementReport"),
  loading: Loading,
});

const DigitalVehicleReport = Loadable({
  loader: () =>import("views/Reports/components/Invoices/DigitalStatementReport"),
  loading: Loading,
});

const ResetPassword = Loadable({
  loader: () => import("./components/resetpwd"),
  loading: Loading,
});

export const routes = [
  {
    path: "/inspection-email-verification/:id",
    exact: true,
    name: "InspectionEmailVerification",
    element: InspectionEmailVerification,
  },
  {
    path: "/digital-quote-pdf/:id",
    exact: true,
    name: "DigitalQuotePdf",
    element: DigitalQuotePdf,
  },
  {
    path: "/digital-invoice-pdf/:id",
    exact: true,
    name: "DigitalInvoicePdf",
    element: DigitalInvoicePdf,
  },
  {
    path: "/digital-statement-pdf/:id",
    exact: true,
    name: "DigitalStatementPdf",
    element: DigitalStatementPdf,
  },
  {
    path: "/digital-report-statement/:id",
    exact: true,
    name: "DigitalReportStatement",
    element: DigitalReportStatement,
  },
  {
    path: "/digital-report-statement",
    exact: true,
    name: "DigitalReportStatement",
    element: DigitalReportStatement,
  },
  {
    path: "/digital-vehicle-report",
    exact: true,
    name: "DigitalVehicleReport",
    element: DigitalVehicleReport,
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "forgot-password",
    element: ForgotPassword,
  },
  {
    path: "/forgot-email",
    exact: true,
    name: "forgot-email",
    element: ForgotEmail,
  },
  {
    path: "/resetpwd/:token",
    exact: true,
    name: "resetpwd",
    element: ResetPassword,
  },
  
];

export * from "./components";
