import {
  addApprovedPartsInspection,
  setApproveManualInspection,
} from "helpers/backendHelper";

const { getWorkshopId, getSessionData } = require("utils/getWorkshopId");

export async function approveInspectionManually({ ids, inspection }) {
  const request1 = {
    "InspInspections.approval_token": inspection?.approval_token,
    id: inspection?.id,
    customer_comment: "",
    Insp_status: "Approved",
    insp_approved_by_id: getSessionData("user_id"),
    insp_question_ans: ids?.map((c) => {
      return {
        id: c,
        qn_status: "Approved",
      };
    }),
  };

  const request2 = {
    parts: ids,
    workshop_id: getWorkshopId(),
  };

  await setApproveManualInspection({ payload: request1 });
  await addApprovedPartsInspection({ payload: request2 });
}
