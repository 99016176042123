import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ClockInWarningModal = ({clockInShow,setClockInShow,setRescheduleShow,setJobStatus=""}) => {
  const handleClose = () => {setClockInShow(false)}
  return (
    <>
        <Modal
            className="info-dialog info-timer-dialog"
            size="sm"
            show={clockInShow}
            onHide={handleClose}
            centered
        >
            <div className="heading-block d-flex justify-center bg-warning align-items-center" >
                <span className="font-size-30" style={{color:"red"}} > Warning </span>
                <i
                    className="la las la-exclamation-circle close-icon1"
                    aria-hidden="true"
                ></i>
            </div>
            <Modal.Body className="m-0">
                <div className="text-center mb-2">
                    <p className="font-size-16 mt-3 mb-2">Please reschedule this job to the current day to allow clocking.</p>
                </div>
                <div className="d-flex justify-center mt-4">
                    <Button
                        variant="outline-secondary"
                        className="btn btn-outline font-size-13 rounded-btn"
                        onClick={() => handleClose()}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        className="btn rounded-btn"
                        onClick={() => {
                            handleClose()
                            if(setJobStatus!=""){
                                setJobStatus("Rescheduled")
                            }
                            setRescheduleShow(true)
                        }}
                    >
                        Reschedule
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    </>
);
};

export default ClockInWarningModal;
