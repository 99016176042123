import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const NoEmployeeWarning = ({setNoEmployeeWarning}) => {
    const handleClose = () => setNoEmployeeWarning(false);
    const navigate = useNavigate();
    return (
        <>
            <Modal
                className="info-dialog"
                size="sm"
                show={true}
                onHide={handleClose}
            >
                <div className="heading-block d-flex justify-center bg-info">
                    <i
                        className="la las la-exclamation-circle close-icon "
                        aria-hidden="true"
                    ></i>
                </div>
                <Modal.Body>
                    <div className="text-center mb-2">
                        <h2 className="mb-2">You don't have any Employees, Please create first</h2>
                    </div>
                    <div className="d-flex justify-center">
                        <Button
                            variant="outline-secondary"
                            className="btn btn-primary rounded-btn font-size-13"
                            onClick={() => navigate("/employee")}
                        >
                            Create Employee
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default NoEmployeeWarning;
