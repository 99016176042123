import "bootstrap-daterangepicker/daterangepicker.css";
import { set } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteModal from "utils/DeleteModal";
import DeleteRecords from "utils/deleteRecords";
import { useTablePaginate } from "utils/useTablePaginate";
import Loader from "views/common/UI/Loader";
import TableWithPagination from "views/common/UI/TableWithPagination/TableWithPagination";
import { useInvoiceAction } from "./useInvoiceAction";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Dropdown, Form } from "react-bootstrap";
// import { SEARCH_INVOICE } from "helpers/urlHelper";
import {
  SEARCH_INVOICE, SEARCH_CASHSALE, SEARCH_RECEIPT, SEARCH_CASHSALE_RECEIPT
} from "store/invoices/actionTypes";
import { getRaisedInvoicesSuccess, searchInvoiceSuccess } from "store/invoices/actions";

const CommonSecond = ({
  onSearch,
  onCreateCashSale,
  columns,
  main,
  api,
  onRowClick,
  deleteInvoices,
  deletePayload,
  setDeletePayload,
  checkBoxDisable = "",
  onStatementDate = "",
  startdate = "",
  enddate = "",
  label = "",
  handleCallback = "",
  range = "",
  baseProps,
  statementTotal = "",
  statementDeleteMsg = "",
  invoiceListType = "",
  isReport = false,
  setCurrentPageLimit = () => {},
  setCurrentPageNumber = () => {},
  setCurrentSize= () => {}
}) => {

  const [invoiceType, setInvoiceType] = useState("invoice")
  const [selectedRow, setSelectedRow] = useState([]);
  const [query, setQuery] = useState("");
  const [list, setList] = useState([]);
  const [isArchive, setIsArchive] = useState(false);

  const dispatch = useDispatch();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const {
    currentPage,
    sizePerPage,
    totalRecords,
    setTotalRecords,
    setCurrentPage,
    setSizePerPage,
  } = useTablePaginate({ size: 10, currPage: 1, total: 1 });

  const [pageLimit, setPageLimit] = useState(sizePerPage);
  const [page, setPage] = useState(currentPage);
  const [searchFlag, setSearchFlag] = useState(false);

  const { invoiceCount, saleInvoiceCount, count } = main;
  const navigate = useNavigate();
  const size = useMemo(() => {
    let tempSize = { invoicePerPage: 0, saleInvoicePerPage: 0 };
    if (count === 0) {
      setCurrentSize(tempSize);
      return tempSize;
    }
    const invoiceFraction = invoiceCount / count;
    const saleFraction = saleInvoiceCount / count;

    const invoicePerPage = (invoiceFraction * sizePerPage).toFixed(0);
    const saleInvoicePerPage = (saleFraction * sizePerPage).toFixed(0);
    tempSize = {
      invoicePerPage: +invoicePerPage,
      saleInvoicePerPage: +saleInvoicePerPage,
    };
    setCurrentSize(tempSize);
    return tempSize;
  }, [invoiceCount, saleInvoiceCount, sizePerPage, count]);  

  const { searchInvoice } = useSelector((state) => state.invoice);

  const { archiveRecord, deleteRecords, deleteMsg } = useInvoiceAction(setDeletePayload);

  useEffect(() => {
    setCurrentPageLimit(pageLimit);
    setCurrentPageNumber(page);
    api(main)(dispatch, invoiceType, pageLimit, page)(workshop_id, 1, size, true);
  }, [invoiceType, pageLimit, page]);

  const [emptyMessage, setEmptyMessage] = useState('No data available in table')

  useEffect(() => {
    if (invoiceListType != "") {
      if (main?.list) {
        setList(main?.list);
        setTotalRecords(main?.count);
      } else if (main?.list === null) {
        setList([]);
        setTotalRecords(0);
      }
    } else {
      const list = main?.list
        ?.filter((z) => {
          const regex = new RegExp(query, "i");
          return regex.test(JSON.stringify(z));
        })
        .slice((currentPage - 1) * sizePerPage, currentPage * sizePerPage);
      setList(list);
    }
    if (searchFlag) {
      setEmptyMessage('No Results Found')
    } else {
      setEmptyMessage('No data available in table')
    }
  }, [main?.list, currentPage, sizePerPage, query]);

  useEffect(() => {
    setTotalRecords(main?.count || 0);
  }, [main?.count]);

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);

    if (query === "") {
      setPageLimit(sizePerPage)
      setPage(page)
    } else {
      let text = [];
      for (
        var key = (page - 1) * sizePerPage;
        key < sizePerPage * page;
        key++
      ) {
        if (searchInvoice?.list?.[key] !== undefined) text.push(searchInvoice?.list?.[key]);
      }
      setList(text);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      onRowClick?.(row);
    },
  };

  const handleSearch = (e) => {
    setSearchFlag(true);
    if (invoiceListType == "") {
      setQuery(e.target.value);
    } else {
      setQuery(e.target.value);
      if (e.target.value !== "") {
        if (invoiceListType == 3) {
          if (invoiceType == "invoice") {
            dispatch({
              type: SEARCH_RECEIPT,
              payload: {
                workshop_id: workshop_id,
                type: 1,
                keyword: e.target.value,
              },
            });
          } else {
            dispatch({
              type: SEARCH_CASHSALE_RECEIPT,
              payload: {
                workshop_id: workshop_id,
                type: 2,
                keyword: e.target.value,
              },
            });
          }
        } else {
          if (invoiceType == "invoice") {
            dispatch({
              type: SEARCH_INVOICE,
              payload: {
                workshop_id: workshop_id,
                type: invoiceListType,
                keyword: e.target.value,
              },
            });
          } else {
            dispatch({
              type: SEARCH_CASHSALE,
              payload: {
                workshop_id: workshop_id,
                type: invoiceListType,
                keyword: e.target.value,
              },
            });
          }
        }
      } else {
        dispatch(searchInvoiceSuccess({}));
        setList(main?.list);
        setTotalRecords(main?.count);
      }
    }
  };

  useEffect(() => {
    if (query !== "") {
      if (Object.keys(searchInvoice).length !== 0) {

        var text = [];
        let totalSearchCount = 0;
        for (var key = 0; key < 10; key++) {
          if (searchInvoice?.list?.[key] !== undefined) {
            totalSearchCount = searchInvoice?.count;
            text.push(searchInvoice?.list?.[key]);
          }
        }
        setTotalRecords(totalSearchCount);
        setList(text);
      }
    }
  }, [searchInvoice])

  return (
    <>
      {main?.loading && <Loader />}

      <div>
        <div
          className={`table-search-wrapper ${(onCreateCashSale || invoiceListType == 3) ? "justify-content-between" : "d-flex align-items-center "
            }`}
        >
          {(onCreateCashSale || invoiceListType == 3) && (
            <>
              <div className="radio-wrapper tab-radio-btn yes-no-radio">
                <Form.Check
                  key={"invoice"}
                  className="radio-btn mb-2"
                  type="radio"
                  id={"invoice"}
                >
                  <Form.Check.Input
                    value={"invoice"}
                    type="radio"
                    // readOnly
                    name="invoice_type"
                    checked={invoiceType === "invoice"}
                    onChange={() => setInvoiceType("invoice")}
                  />
                  <Form.Check.Label>
                    Job Invoices
                    <span className="check"></span>
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check
                  key={"cashsale"}
                  className="radio-btn mb-2"
                  type="radio"
                  id={"cashsale"}
                >
                  <Form.Check.Input
                    value={"cashsale"}
                    type="radio"
                    // readOnly
                    name="invoice_type"
                    checked={invoiceType === "cashsale"}
                    onChange={() => setInvoiceType("cashsale")}
                  />
                  <Form.Check.Label>
                    Cash Sale Invoices
                    <span className="check"></span>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </>
          )}
          <input
            type="text"
            className={"form-control " + (onStatementDate ? "statement-search" : "")}
            id="search"
            placeholder="Search..."
            onChange={(e) => handleSearch(e)}
            value={query}
          // value={""}
          />
          {onCreateCashSale && (
            <Button
              className="btn-primary rounded-btn"
              onClick={() => {
                navigate("/cash_sale");
              }}
            >
              <i className="fa fa-plus"></i> Create Cash Sale
            </Button>
          )}
          {onStatementDate && (
            <DateRangePicker
              initialSettings={{
                ranges: range,
                startDate: startdate,
                endDate: enddate,
              }}
              onCallback={handleCallback}
            >
              <div id="reportrange" className="date-range-picker datepicker-statement">
                <span className="calender-icon">
                  <i className="fa fa-calendar"></i>
                </span>
                <span>{label}</span>
              </div>
            </DateRangePicker>
          )}
        </div>
        {
          <TableWithPagination
            columns={columns}
            data={list}
            onSelectedRowChange={(data) => {
              setSelectedRow(data);
            }}
            onTableChange={onTableChange}
            page={currentPage}
            descriptionKey="comments"
            rowEvents={rowEvents}
            sizePerPage={sizePerPage}
            totalSize={totalRecords}
            checkBoxDisable={checkBoxDisable}
            baseProps={baseProps}
            statementTotal={statementTotal}
            emptyMessage={emptyMessage}
            invoiceListType={invoiceListType}
            onStatementDate={onStatementDate}
          />
        }
        {isReport === false &&
          <div className="d-flex custom-table-footer align-center">

            <>
              <Button
                variant="warning"
                className="rounded-btn d-flex align-center mr-10"
                onClick={() => {
                  archiveRecord(selectedRow);

                  setIsArchive(true);
                }}
              >
                <i className="la la-archive font-size-18"></i> Archive
              </Button>
              <Button
                onClick={() => {
                  deleteRecords(selectedRow);
                  setIsArchive(false);
                }}
                variant="danger"
                className="rounded-btn d-flex align-center mr-10"
              >
                <i className="la la-times-circle font-size-18"></i> Delete
              </Button>
            </>
          </div>
        }


        {!!deletePayload && (
          <DeleteModal
            handleOutSide
            showDeleteModal={deletePayload}
            msg={statementDeleteMsg ? (isArchive ? "Archive Statement Records?" : "Delete Statement Records?") : (isArchive ? "Archive this records?" : "Delete this records?")}
            setShowDeleteModal={setDeletePayload}
            // refreshData={loadData}
            api={async () => {
              return deleteInvoices(() => {
                toast.success(
                  isArchive
                    ? "Record Archived successfully"
                    : "Record deleted successfully"
                );
                setCurrentPage(1);
                setDeletePayload?.(null);
                setSelectedRow([]);
              }, isArchive);
            }}
            selectedRow={selectedRow}
            message={deleteMsg}
            payloadData={{}}
          />
        )}
      </div>
    </>
  );
};

export default CommonSecond;
