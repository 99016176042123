import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link, useNavigate } from "react-router-dom";
import "../../style.scss";
import { GET_EMPLOYEE_LIST } from "../../../../../store/Contact/actionTypes";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import LoaderImage from "../../../../../assets/images/page-loader.svg";
import DeleteRecords from "../../../../../utils/deleteRecords";
import {
  deleteEmployee,
  deleteHQEmployee,
  deleteManyEmployee,
} from "../../../../../helpers/backendHelper";
import Form from "react-bootstrap/Form";
import WorkshopFilterDropdown from "../../common/components/WorkshopFilterDropdown";
import { toast } from "react-toastify";

const EmployeeList = ({ vehicleType }) => {
  document.title = "5iQ | Employees";
  const [selectedWorkshop, setSelectedWorkshop] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState([]);
  const [search, setSearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [tableRecordCount, setTableRecordCount] = useState(0);
  const [searchRecord, setSearchRecord] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const workshop_id = window.atob(storedData.workshop_id);
  const headQuartersId = window.atob(storedData.hq_id);
  const dateFormat = storedData.date_format;
  let user_id = window.atob(storedData.user_id);
  const deleteMsg = "Employee deleted successfully";
  const deletePayloadData = {
    payload: {
      // action_type: "delete",
      id: selectedRow,
    },
  };
  const ArchiveMsg = "Employee Archived successfully";
  const ArchivePayloadData = {
    payload: {
      action_type: "archive",
      ids: selectedRow,
    },
  };
  const { loading, employeeList } = useSelector((state) => state.Contact);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (employeeList.length !== 0) {
      setTableRecordCount(employeeList.length);
      if (employeeList.length > sizePerPage) {
        setEmployees(
          showSelectedRecords(currentPage, sizePerPage, employeeList)
        );
      } else {
        setEmployees(employeeList);
      }
    } else {
      setEmployees([]);
    }
  }, [employeeList]);
  const showSelectedRecords = (page, sizePerPage, allData) => {
    let records = [];
    for (
      let index = (page - 1) * sizePerPage;
      index < sizePerPage * page;
      index++
    ) {
      if (allData[index] !== undefined) {
        records.push(allData[index]);
      }
    }
    return records;
  };

  const loadData = () => {
    dispatch({
      type: GET_EMPLOYEE_LIST,
      payload: {
        get: "all",
        conditions: {
          "Users.head_quarters_id": headQuartersId,
          //TODO: Replace these roles with actual head quarter employees role id
          "Users.role_id IN": [13, 14, 15],
          "Users.is_deleted": 0,
        },
        order: {
          "Users.id": "desc",
        },
        contain: {
          HeadQuartersEmployeesDetails: {
            fields: [
              "HeadQuartersEmployeesDetails.id",
              "HeadQuartersEmployeesDetails.user_id",
              "HeadQuartersEmployeesDetails.created_on",
            ],
          },
          Roles: {
            fields: ["Roles.id", "Roles.name"],
          },
        },
        fields: [
          "Users.id",
          "Users.head_quarters_id",
          "Users.firstname",
          "Users.lastname",
          "Users.email",
          "Users.phone_number",
          "Users.suburb",
          "Users.postcode",
          "Users.role_id",
          "Users.created_on",
        ],
      },
    });
  };

  const handleRowClick = (e, column, columnIndex, row, rowIndex) => {
    if (row.role.id == 2) {
      navigate(`/view_employee/${row.id}`);
    } else {
      navigate(`/edit-employee/${row.id}`);
    }
  };

  const columns = [
    {
      dataField: "firstname",
      text: "Name",
      events: {
        onClick: handleRowClick,
      },
      formatter: (cell, row) => {
        return `${row.firstname || " "} ${row.lastname || ""}`;
      },
    },

    {
      dataField: "email",
      text: "Email Address",
      events: {
        onClick: handleRowClick,
      },
    },
    {
      dataField: "phone_number",
      text: "Contact Number",
      events: {
        onClick: handleRowClick,
      },
    },
    {
      dataField: "role.name",
      text: "Access Level",
      events: {
        onClick: handleRowClick,
      },
      formatter: (cell, row) => {
        if (row.role.id == 8 || row.role.id == 9) {
          return row.role.name + "(MA)";
        } else if (row.role.id == 2) {
          return "Super Admin";
        } else {
          return row.role.name + "(DA)";
        }
      },
    },
    {
      dataField: "created_on",
      text: "Registration Date",
      events: {
        onClick: handleRowClick,
      },
      formatter: (cell, row) => {
        return moment(cell).format(dateFormat);
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <>
                <li>
                  <Link
                    to={`/edit-employee/${row.id}`}
                    className=""
                    title="EDIT"
                  >
                    <i className="fa fa-pencil text-info"></i>
                    <span>Edit</span>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    let hqEmployeeId;
                    if (
                      row && row.head_quarters_employees_details &&
                      Array.isArray(row.head_quarters_employees_details) &&
                      row.head_quarters_employees_details.length > 0
                    ) {
                      hqEmployeeId = row.head_quarters_employees_details[0].id;
                    }

                    if (hqEmployeeId) {
                      setSelectedRow(hqEmployeeId);
                      setShowDeleteModal(true);
                    } else {
                      toast.error("Unable to process your request at the moment!");
                    }
                  }}
                >
                  <Link to="#" className="delete-link" title="DELETE">
                    <span className="red-bg">
                      <i className="fa fa-minus"></i>
                    </span>
                    <span>Delete</span>
                  </Link>
                </li>
              </>
            </div>
          </div>
        );
      },
    },
  ];

  // const checkBox = {
  //   mode: "checkbox",
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     if (isSelect) {
  //       setSelectedRow([...selectedRow, row.id]);
  //     } else {
  //       let rowSelected = [...selectedRow];

  //       const index = rowSelected.indexOf(row.id);
  //       if (index > -1) {
  //         rowSelected.splice(index, 1);
  //       }
  //       setSelectedRow(rowSelected);
  //     }
  //   },
  //   onSelectAll: (isSelected) => {
  //     if (isSelected) {
  //       let ids = [];
  //       for (var key = 0; key < sizePerPage; key++) {
  //         if (employees[key] !== undefined) ids.push(employees[key].id);
  //       }
  //       setSelectedRow(ids);
  //     } else {
  //       setSelectedRow([]);
  //     }
  //   },
  //   selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         ref={(input) => {
  //           if (input) input.indeterminate = indeterminate;
  //         }}
  //         checked={rest.checked}
  //         name={rest.name}
  //         id={rest.id}
  //         type="checkbox"
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  //   selectionRenderer: ({ mode, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         id={rest.id}
  //         checked={rest.checked}
  //         type={mode}
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  // };

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
    if (searchRecord.length !== 0)
      setEmployees(showSelectedRecords(page, sizePerPage, searchRecord));
    else setEmployees(showSelectedRecords(page, sizePerPage, employeeList));
  };

  const emptyDataMessage = () => {
    return (
      <div style={{ textAlign: "center" }}>No data available in table </div>
    );
  };

  const handleSearch = (value) => {
    setSearch(value);
    let filtervalue = [];
    if (value !== "") {
      filtervalue = employeeList.filter((user) => {
        return (
          (user.firstname &&
            user.firstname.toLowerCase().includes(value.toLowerCase())) ||
          (user.lastname &&
            user.lastname.toLowerCase().includes(value.toLowerCase())) ||
          (user.email &&
            user.email.toLowerCase().includes(value.toLowerCase())) ||
          (user.phone_number &&
            user.phone_number.toLowerCase().includes(value.toLowerCase())) ||
          (user.role.name &&
            user.role.name.toLowerCase().includes(value.toLowerCase())) ||
          (user.created_on &&
            user.created_on.toLowerCase().includes(value.toLowerCase()))
        );
      });
      setSearchRecord(filtervalue);
    } else {
      filtervalue = employeeList;
      setSearchRecord([]);
    }
    setTableRecordCount(filtervalue.length);

    if (filtervalue.length > sizePerPage) {
      setEmployees(showSelectedRecords(currentPage, sizePerPage, filtervalue));
    } else {
      setEmployees(filtervalue);
    }
  };

  return (
    <div className="right-section-wrapper">
      {loading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
      <div className="table-header">
        <div className="row top-block">
          <div className="col-md-2">
            <div className="table-title">
              {/* <img src={customerBlue} alt=""></img> */}
              <i
                class="fa fa-user-circle text-center text-primary font-size-30 m-r-10"
                aria-hidden="true"
              ></i>
              <span>EMPLOYEES</span>
            </div>
          </div>
          <div className="col-md-2">
            <div className="video-link-outer">
              <div className="video-tutorial">
                <i className="fa fa-icon"></i>
                {/* <span className="help-text">Help</span> */}
                <Link to="/trainee-video#contact">
                  <span className="tutorial-title">Employees Videos</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div className="table-search-wrapper">
            <input
              type="text"
              className="form-control"
              id="search"
              placeholder="Search..."
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {/* <WorkshopFilterDropdown
              current={selectedWorkshop}
              onChange={(newWorkshop) => {
                setSelectedWorkshop(newWorkshop);
              }}
            /> */}
            <Button
              className="btn-primary rounded-btn"
              onClick={() => navigate("/create-employee")}
            >
              <i className="fa fa-plus"></i> Create Employee
            </Button>
          </div>

          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: currentPage,
              sizePerPage: sizePerPage,
              totalSize: parseInt(tableRecordCount),
              nextPageText: "Next",
              prePageText: "Previous",
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable
                  remote
                  keyField="id"
                  data={employees}
                  columns={columns}
                  onTableChange={onTableChange}
                  // selectRow={checkBox}
                  noDataIndication={emptyDataMessage}
                  {...paginationTableProps}
                />
                <div>
                  <div className="table-pagination">
                    <div>
                      <span>Show</span>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <span>entries</span>
                    </div>
                    <PaginationTotalStandalone {...paginationProps} />
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </div>
            )}
          </PaginationProvider>

          {showDeleteModal && (
            <DeleteRecords
              showDeleteModal={setShowDeleteModal}
              refreshData={loadData}
              api={deleteHQEmployee}
              selectedRow={selectedRow}
              message={deleteMsg}
              payloadData={deletePayloadData}
              title="Delete this employee?"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;
