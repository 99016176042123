import {
  GET_TEMPLATE_LIST,
  GET_TEMPLATE_LIST_SUCCESS,
  GET_TEMPLATE_LIST_FAIL,
  GET_TEMPLATE_DETAIL,
  GET_TEMPLATE_DETAIL_SUCCESS,
  GET_TEMPLATE_DETAIL_FAIL,
  TEMPLET_DELETE,
  TEMPLET_DELETE_SUCCESS,
  TEMPLET_DELETE_FAIL,
  CATEGORY_DELETE,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  GET_CATEGORIES_LIST,
  GET_CATEGORIES_LIST_SUCCESS,
  GET_CATEGORIES_LIST_FAIL,
  GET_SECTION_LIST,
  GET_SECTION_LIST_SUCCESS,
  GET_SECTION_LIST_FAIL,
  ADD_TEMPLATE_CATEGORY,
  ADD_TEMPLATE_CATEGORY_SUCCESS,
  ADD_TEMPLATE_CATEGORY_FAIL,
  ADD_TEMPLATE_SECTION,
  ADD_TEMPLATE_SECTION_SUCCESS,
  ADD_TEMPLATE_SECTION_FAIL,
  ADD_TEMPLATE,
  ADD_TEMPLATE_SUCCESS,
  ADD_TEMPLATE_FAIL,
  GET_TEMPLATE_SECTIONS,
  GET_TEMPLATE_SECTIONS_SUCCESS,
  GET_TEMPLATE_SECTIONS_FAIL,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAIL,
  COPY_TEMPLATE,
  COPY_TEMPLATE_SUCCESS,
  COPY_TEMPLATE_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  UPDATE_SECTION,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAIL,
  EDIT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_FAIL,
  EDIT_SECTION,
  EDIT_SECTION_SUCCESS,
  EDIT_SECTION_FAIL,
  DELETE_INSPECTION_TEMPLATE_QUE,
  DELETE_INSPECTION_TEMPLATE_QUE_SUCCESS,
  DELETE_INSPECTION_TEMPLATE_QUE_FAIL
} from "./actionType";

const initialState = {
  loading: false,
  error: {
    message: ""
  },
  success: {
    message: ""
  },
  templateList: null,
  templateDetail: null,
  categories: null,
  sections: null,
  templateSections: null,
  isFetching: false,
  isFetchingSections: false,
  categoryFormSuccess: false,
  categoryDetail: null,
  sectionDetail: null,
  deleteInspectionTemplateQue:null,
  updateTemplateData:null
};

const InspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATE_LIST:
      state = {
        ...state,
        loading: true
      }
      break;
    case GET_TEMPLATE_LIST_SUCCESS:
      state = {
        ...state,
        templateList: action.payload,
        loading: false,
        success: {
          message: action.message
        }
      }
      break;
    case GET_TEMPLATE_LIST_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false
      }
      break;
    case GET_TEMPLATE_DETAIL:
      state = {
        ...state,
        loading: true
      }
      break;
    case GET_TEMPLATE_DETAIL_SUCCESS:
      state = {
        ...state,
        templateDetail: action.payload,
        loading: false,
        success: {
          message: action.message
        }
      }
      break;
    case GET_TEMPLATE_DETAIL_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false
      }
      break;
    case TEMPLET_DELETE:
      state = {
        ...state,
        loading: true
      }
      break;
    case TEMPLET_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: {
          message: action.message
        }
      }
      break;
    case TEMPLET_DELETE_FAIL:
      state = {
        ...state,
        loading: false,
        error: {
          message: action.payload
        }
      }
      break;
    case CATEGORY_DELETE:
      state = {
        ...state,
        loading: true
      }
      break;
    case CATEGORY_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: {
          message: action.message
        }
      }
      break;
    case CATEGORY_DELETE_FAIL:
      state = {
        ...state,
        loading: false,
        error: {
          message: action.payload
        }
      }
      break;
    case GET_CATEGORIES_LIST:
      state = {
        ...state,
        loading: true,
        isFetching: true,
        categoryFormSuccess: false
      }
      break;
    case GET_CATEGORIES_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isFetching: false,
        categoryFormSuccess: false,
        categories: action.payload,
        success: {
          message: action.message
        }
      }
      break;
    case GET_CATEGORIES_LIST_FAIL:
      state= {
        ...state,
        loading: false,
        isFetching: false,
        categoryFormSuccess: false,
        categories: [],
        error: {
          message: action.message
        }
      }
      break;
    case GET_SECTION_LIST:
      state = {
        ...state,
        isFetchingSections: true,
        categoryFormSuccess: false
      }
      break;
    case GET_SECTION_LIST_SUCCESS:
      state = {
        ...state,
        isFetchingSections: false,
        categoryFormSuccess: false,
        sections: action.payload,
        success: {
          message: action.message
        }
      }
      break;
    case GET_SECTION_LIST_FAIL:
      state= {
        ...state,
        isFetchingSections: false,
        categoryFormSuccess: false,
        sections: [],
        error: {
          message: action.message
        }
      }
      break;
    case GET_TEMPLATE_SECTIONS:
      state = {
        ...state,
        loading: true
      }
      break;
    case GET_TEMPLATE_SECTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        templateSections: action.payload,
        success: {
          message: action.message
        }
      }
      break;
    case GET_TEMPLATE_SECTIONS_FAIL:
      state= {
        ...state,
        loading: false,
        templateSections: [],
        error: {
          message: action.message
        }
      }
      break;
    case ADD_TEMPLATE_CATEGORY:
      state = {
        ...state,
        loading: true,
        categoryFormSuccess: false
      }
      break;
    case ADD_TEMPLATE_CATEGORY_SUCCESS:
      const cats = state.categories ? [...state.categories] : [];
      const newCat = action.payload.data.Categories;
      cats.push(newCat)
      state = {
        ...state,
        loading: false,
        categories:cats, 
        categoryFormSuccess: true,
      }
      break;
    case ADD_TEMPLATE_CATEGORY_FAIL:
      state = {
        ...state,
        loading: false,
        categoryFormSuccess: false,
        error: {
          message: action.payload
        }
      }
      break;
    case ADD_TEMPLATE_SECTION:
      state = {
        ...state,
        loading: true,
        categoryFormSuccess: false
      }
      break;
    case ADD_TEMPLATE_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        categoryFormSuccess: true,
        // success: {
        //   message: action.message
        // }
      }
      break;
    case ADD_TEMPLATE_SECTION_FAIL:
      state = {
        ...state,
        loading: false,
        categoryFormSuccess: false,
        error: {
          message: action.payload
        }
      }
      break;
    case ADD_TEMPLATE:
      state = {
        ...state,
        loading: true,
        success: {
          message: ""
        }
      }
      break;
    case ADD_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: {
          message: action.payload
        }
      }
      break;
    case ADD_TEMPLATE_FAIL:
      state = {
        ...state,
        loading: false,
        success: {
          message: ""
        },
        error: {
          message: action.payload
        }
      }
      break;
    case COPY_TEMPLATE:
      state = {
        ...state,
        loading: true,
        success: {
          message: ""
        }
      }
      break;
    case COPY_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: {
          message: action.message
        }
      }
      break;
    case COPY_TEMPLATE_FAIL:
      state = {
        ...state,
        loading: false,
        success: {
          message: ""
        },
        error: {
          message: action.payload
        }
      }
      break;
    case UPDATE_TEMPLATE:
      state = {
        ...state,
        loading: true,
        success: {
          message: ""
        },
        updateTemplateData:""
      }
      break;
    case UPDATE_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: {
          message: action.message
        },
        updateTemplateData:action.payload
      }
      break;
    case UPDATE_TEMPLATE_FAIL:
      state = {
        ...state,
        loading: false,
        success: {
          message: ""
        },
        error: {
          message: action.payload
        },
        updateTemplateData:action.payload
      }
      break;

      case EDIT_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break;
    case EDIT_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        categoryDetail: action.payload
      }
      break;
    case EDIT_CATEGORY_FAIL:
      state = {
        ...state,
        loading: false,
        error: {
          message: action.payload
        }
      }
      break;

      case UPDATE_CATEGORY:
      state = {
        ...state,
        loading: true,
        categoryFormSuccess: false,
        success: {
          message: ""
        }
      }
      break;
    case UPDATE_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        categoryFormSuccess: true,
        success: {
          message: action.message
        }
      }
      break;
    case UPDATE_CATEGORY_FAIL:
      state = {
        ...state,
        loading: false,
        categoryFormSuccess: false,
        success: {
          message: ""
        },
        error: {
          message: action.payload
        }
      }
      break;

      case EDIT_SECTION:
        state = {
          ...state,
          loading: true,
          categoryFormSuccess: false
        }
        break;
      case EDIT_SECTION_SUCCESS:
        state = {
          ...state,
          loading: false,
          categoryFormSuccess: false,
          sectionDetail: action.payload
        }
        break;
      case EDIT_SECTION_FAIL:
        state = {
          ...state,
          loading: false,
          categoryFormSuccess: false,
          error: {
            message: action.payload
          }
        }
        break;

      case UPDATE_SECTION:
      state = {
        ...state,
        loading: true,
        categoryFormSuccess: false,
        success: {
          message: ""
        }
      }
      break;
    case UPDATE_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        categoryFormSuccess: true,
        success: {
          message: action.message
        }
      }
      break;
    case UPDATE_SECTION_FAIL:
      state = {
        ...state,
        loading: false,
        categoryFormSuccess: false,
        success: {
          message: ""
        },
        error: {
          message: action.payload
        }
      }
      break;
      case DELETE_INSPECTION_TEMPLATE_QUE:
        state = {
        ...state,
        loading: true,
        deleteInspectionTemplateQue: false,
        success: {
          message: ""
        }
      }
      break;
      case DELETE_INSPECTION_TEMPLATE_QUE_SUCCESS:
        state = {
        ...state,
        loading: false,
        deleteInspectionTemplateQue: true,
        success: {
          message: action.message
        }
      }
      break;
      case DELETE_INSPECTION_TEMPLATE_QUE_FAIL:
        state = {
        ...state,
        loading: false,
        deleteInspectionTemplateQue: false,
        success: {
          message: ""
        },
        error: {
          message: action.payload
          }
      }
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default InspectionReducer;