export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAIL = "GET_TEMPLATES_FAIL";

export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAIL = "DELETE_TEMPLATE_FAIL";

export const GET_DEPARTMENT_LIST = "GET_DEPARTMENT_LIST";
export const GET_DEPARTMENT_LIST_SUCCESS = "GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_FAIL = "GET_DEPARTMENT_LIST_FAIL";

export const ADD_SCRIPT = "ADD_SCRIPT";
export const ADD_SCRIPT_SUCCESS = "ADD_SCRIPT_SUCCESS";
export const ADD_SCRIPT_FAIL = "ADD_SCRIPT_FAIL";

export const GET_SCRIPT_DETAIL = "GET_SCRIPT";
export const GET_SCRIPT_DETAIL_SUCCESS = "GET_SCRIPT_SUCCESS";
export const GET_SCRIPT_DETAIL_FAIL = "GET_SCRIPT_FAIL";

export const EDIT_SCRIPT = "EDIT_SCRIPT";
export const EDIT_SCRIPT_SUCCESS = "EDIT_SCRIPT_SUCCESS";
export const EDIT_SCRIPT_FAIL = "EDIT_SCRIPT_FAIL";

export const COPY_SCRIPT = "COPY_SCRIPT";
export const COPY_SCRIPT_SUCCESS = "COPY_SCRIPT_SUCCESS";
export const COPY_SCRIPT_FAIL = "COPY_SCRIPT_FAIL";
