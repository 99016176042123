import { useCallback, useEffect, useMemo, useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { WAIT_FOR_ACTION } from "redux-wait-for-action";
import { getLoginSuccess } from "store/login/actions";
import {
  SET_EXPIRED,
  SET_FIREBASE_TOKEN,
  SET_FIREBASE_TOKEN_SUCCESS,
} from "store/login/actionTypes";
import {
  LOAD_SECURITY_GROUP,
  LOAD_SECURITY_GROUP_SUCCESS,
} from "store/securityGroup/actionTypes";
import DataProvider from "utils/DataContext";
import Firebase from "utils/Firebase/Firebase";
import { registerLoader } from "utils/Loader";
import { LoaderContainer } from "utils/Loader/loader";
import { setSessionData } from "utils/setSessiondata";
import { InitialDataProvider } from "views/common/InitialDataContext/InitialDataProvider";
import Loader from "views/common/UI/Loader";
import LoaderImage from "../src/assets/images/page-loader.svg";
import AllRoutes from "./routes/allRoutes";
import SaveWorkAlert from "views/common/UI/CommonAlerts/SaveWorkAlert";
import AutoLogout from "views/login/components/AutoLogout";

function App() {
  const [showSaveProgressAlert, setShowSaveProgressAlert] = useState(false)
  const userData = JSON.parse(localStorage.getItem("data"));
  const firebase = JSON.parse(localStorage.getItem("firebase"));
  const expired = localStorage.getItem("isPlanExpire") === "true";

  const state = {
    loadingLogins: false,
    token: userData?.email + ":" + userData?.api_plainkey,
    userData: userData,
    role_id: userData?.role_id ?? 0,
  };

  const sesData = setSessionData(state);

  const [loading, setLoading] = useState(true);
  const sessionStorageData = useMemo(() => sesData, []);
  const dispatch = useDispatch();
  const { securitiesLoading } = useSelector(
    (state) => state.SecurityGroupReducer
  );

  const getInitialData = useCallback(async () => {
    if (userData) {
      const data = {};
      data.data = {};
      data.data.Users = userData;
      dispatch(getLoginSuccess(data));

      if (expired) {
        dispatch({
          type: SET_EXPIRED,
        });
      }
    }

    if (firebase) {
      dispatch({
        type: SET_FIREBASE_TOKEN_SUCCESS,
        payload: firebase,
      });
    }

    dispatch({
      type: LOAD_SECURITY_GROUP,
      payload: {
        workshop_id: userData?.workshop_id || "",
      },
      [WAIT_FOR_ACTION]: LOAD_SECURITY_GROUP_SUCCESS,
    });
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  if (loading || securitiesLoading) return <Loader />;
  return (
    <>
      <DataProvider>
        <div className="App">
          <AllRoutes />
          <AutoLogout showSaveProgressAlert={showSaveProgressAlert} setShowSaveProgressAlert={setShowSaveProgressAlert} />
          <Firebase />
          <LoaderContainer ref={registerLoader} />
          <ToastContainer />
          <div
            className="page-loader align-center justify-center"
            id="page-loader"
            style={{ display: "none" }}
          >
            <img src={LoaderImage} alt="Loading"></img>
          </div>
        </div>
        <div className="print" id="print"></div>
        {
          showSaveProgressAlert && <SaveWorkAlert positiveAction={() => setShowSaveProgressAlert(false)} />
        }
      </DataProvider>
    </>
  );
}

export default App;
