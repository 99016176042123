import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  SAVE_CUSTOM_VEHICLE,
  UPDATE_CUSTOM_VEHICLE_ASSET_TYPE,
  UPDATE_CUSTOM_VEHICLE_DETAILS,
} from "../../../../../store/defaultSettings/actionTypes";
import LoaderImage from "../../../../../../src/assets/images/page-loader.svg";
import { GET_WORKSHOP_DETAIL } from "../../../../../store/companyDetails/actionTypes";
import { serializeArr, unserializeArr } from "../../../../../utils/unserialize";
import VehicleAsset from "../../../../../assets/images/Vehicle-5iQ.png";
import MotorBikeAsset from "../../../../../assets/images/Motorbike-5iQ.png";
import CaravanAsset from "../../../../../assets/images/Caravan5iQ.png";
import TrailerAsset from "../../../../../assets/images/Trailer-5iQ.png";
import MarineAsset from "../../../../../assets/images/Marine-5iQ.png";
import TruckAsset from "../../../../../assets/images/Truck-5iQ.png";

const options = [
  { value: "Vehicle", label: "Vehicle" },
  { value: "Motorbike", label: "Motorbike" },
  { value: "Caravan", label: "Caravan" },
  { value: "Trailer", label: "Trailer" },
  { value: "Marine", label: "Marine" },
  { value: "Truck", label: "Truck" },
  { value: "Machine", label: "Machine" },
];

const AssetTypeForm = ({ handleSubmit, setHandleSubmit }) => {
  const dispatch = useDispatch(); // Dispatch variable
  const { formLoading, success, customVehicleDetails } = useSelector(
    (state) => state.defaultSettingsReducer
  ); // GET Form loader from Company Detail Reducer data
  const { workShopDetails, formLoading: assetTypeLoader } = useSelector(
    (state) => state.CompanyDetailReducer
  );
  useEffect(() => {
    // Get worksop details for vehcile type to show
    dispatch({
      type: GET_WORKSHOP_DETAIL,
      payload: {
        id: window.atob(
          JSON.parse(sessionStorage.getItem("stored_data")).workshop_id
        ),
      },
    });
  }, []);

  const [show, setShow] = useState({ isShow: false, isFooter: true });
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (isShow, isFooter) => setShow({ isShow, isFooter });
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const workshop_id = window.atob(storedData.workshop_id);
  useEffect(() => {
    if (workShopDetails && workShopDetails.vehicle_types) {
      setVehicleTypes(unserializeArr(workShopDetails.vehicle_types));
    }
  }, [workShopDetails]);

  const formik = useFormik({
    initialValues: {
      id: customVehicleDetails ? customVehicleDetails.id : "",
      workshop_id,
      label: customVehicleDetails ? customVehicleDetails.label : "",
      name: customVehicleDetails ? customVehicleDetails.name : "",
      vehicle_type: customVehicleDetails
        ? customVehicleDetails.vehicle_type
        : options[0].value,
      field_type: customVehicleDetails ? customVehicleDetails.field_type : "1",
      list_value: customVehicleDetails ? customVehicleDetails.list_value : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        id: values.id,
        workshop_id,
        label: values.label,
        name: values.label.toLowerCase().replaceAll(" ", "_"),
        vehicle_type: values.vehicle_type,
        field_type: values.field_type,
        list_value: values.list_value,
      };
      if (values.id) {
        dispatch({
          type: UPDATE_CUSTOM_VEHICLE_DETAILS,
          payload: { ...data, id: values.id },
        });
      } else {
        dispatch({
          type: SAVE_CUSTOM_VEHICLE,
          payload: data,
        });
      }
    },
    validate: (values) => {
      const error = {};
      if (!values.label) {
        error.label = "Please enter value";
      }
      return error;
    },
  });

  useEffect(() => {
    if (handleSubmit) {
      formik.handleSubmit();
    }
    setHandleSubmit(false);
  }, [handleSubmit]);

  useEffect(() => {
    if (success) {
      formik.resetForm();
    }
  }, [success]);
  
  const updateVehicleTypes = (vehType, isAdd = "", isRemove = "") => {
    let is_checked = 1;
    var data = vehicleTypes;
    if (isAdd) {
      // data = [...vehicleTypes, selectedVehicle];
      data = [...vehicleTypes, vehType];
    }
    if (isRemove) {
      is_checked = 0;
      data = data.filter((type) => type !== isRemove);
    }
    data = data.filter(item => item !== "")
    setVehicleTypes(data);
    const editFormData = {
      vehicle_types: serializeArr(data),
      id: workshop_id,
      vehType: vehType,
      is_checked: is_checked
    };
    dispatch({
      type: UPDATE_CUSTOM_VEHICLE_ASSET_TYPE,
      payload: editFormData,
    });
    setShow(false);
  };

  useEffect(() => {
    storedData.assets_type = vehicleTypes;
    sessionStorage.setItem("stored_data", JSON.stringify(storedData));

   },[ storedData, vehicleTypes]);
  return (
    <>
      <div className="d-flex flex-md-nowrap align-items-center mb-2">
        <div className="flex-shrink-0 me-3">
          <h5 className="font-size-16 pb-3 mb-0">Select to show:</h5>
        </div>
        <div className="w-100 d-flex asset-vehicle-type">
          <div className="me-3 pb-3">
            <div className="d-flex flex-no-wrap form-group align-items-center">
              <label htmlFor="" className="form-label mb-0 me-2">
                Vehicle
              </label>
              {vehicleTypes.includes("Vehicle") && (
                <button
                  className="btn btn-simple btn-icon btn-icon-sm me-2"
                  onClick={() => {
                    setSelectedVehicle("Vehicle");
                    handleShow(true, false);
                  }}
                >
                  <i className="font-size-20 la la-info-circle"></i>
                </button>
              )}
              <Form.Check
                className="custom-switch"
                type="switch"
                id="custom-switch"
                checked={vehicleTypes.includes("Vehicle")}
                onChange={(e) => {
                  setSelectedVehicle("Vehicle");
                  if (e.target.checked) {
                    // setShow({ isShow: true, isFooter: true });
                    updateVehicleTypes("Vehicle", "add");
                  } else {
                    updateVehicleTypes("Vehicle", "", "Vehicle");
                  }
                }}
              />
            </div>
          </div>
          <div className="me-3 pb-3">
            <div className="d-flex flex-no-wrap form-group align-items-center">
              <label htmlFor="" className="form-label mb-0 me-2">
                Motorbike
              </label>
              {vehicleTypes.includes("Motorbike") && (
                <button
                  className="btn btn-simple btn-icon btn-icon-sm me-2"
                  onClick={() => {
                    setSelectedVehicle("Motorbike");
                    handleShow(true, false);
                  }}
                >
                  <i className="font-size-20 la la-info-circle"></i>
                </button>
              )}
              <Form.Check
                className="custom-switch"
                type="switch"
                id="custom-switch2"
                checked={vehicleTypes.includes("Motorbike")}
                onChange={(e) => {
                  setSelectedVehicle("Motorbike");
                  if (e.target.checked) {
                    // setShow({ isShow: true, isFooter: true });
                    updateVehicleTypes("Motorbike", "add");
                  } else {
                    updateVehicleTypes("Motorbike", "", "Motorbike");
                  }
                }}
              />
            </div>
          </div>
          <div className="me-3 pb-3">
            <div className="d-flex flex-no-wrap form-group align-items-center">
              <label htmlFor="" className="form-label mb-0 me-2">
                Trailer
              </label>
              {vehicleTypes.includes("Trailer") && (
                <button
                  className="btn btn-simple btn-icon btn-icon-sm me-2"
                  onClick={() => {
                    setSelectedVehicle("Trailer");
                    handleShow(true, false);
                  }}
                >
                  <i className="font-size-20 la la-info-circle"></i>
                </button>
              )}
              <Form.Check
                className="custom-switch"
                type="switch"
                id="custom-switch3"
                checked={vehicleTypes.includes("Trailer")}
                onChange={(e) => {
                  setSelectedVehicle("Trailer");
                  if (e.target.checked) {
                    // setShow({ isShow: true, isFooter: true });
                    updateVehicleTypes("Trailer", "add");
                  } else {
                    updateVehicleTypes("Trailer", "", "Trailer");
                  }
                }}
              />
            </div>
          </div>
          <div className="me-3 pb-3">
            <div className="d-flex flex-no-wrap form-group align-items-center">
              <label htmlFor="" className="form-label mb-0 me-2">
                Caravan
              </label>
              {vehicleTypes.includes("Caravan") && (
                <button
                  className="btn btn-simple btn-icon btn-icon-sm me-2"
                  onClick={() => {
                    setSelectedVehicle("Caravan");
                    handleShow(true, false);
                  }}
                >
                  <i className="font-size-20 la la-info-circle"></i>
                </button>
              )}
              <Form.Check
                className="custom-switch"
                type="switch"
                id="custom-switch4"
                checked={vehicleTypes.includes("Caravan")}
                onChange={(e) => {
                  setSelectedVehicle("Caravan");
                  if (e.target.checked) {
                    // setShow({ isShow: true, isFooter: true });
                    updateVehicleTypes("Caravan", "add");
                  } else {
                    updateVehicleTypes("Caravan", "", "Caravan");
                  }
                }}
              />
            </div>
          </div>
          <div className="me-3 pb-3">
            <div className="d-flex flex-no-wrap form-group align-items-center">
              <label htmlFor="" className="form-label mb-0 me-2">
                Marine
              </label>
              {vehicleTypes.includes("Marine") && (
                <button
                  className="btn btn-simple btn-icon btn-icon-sm me-2"
                  onClick={() => {
                    setSelectedVehicle("Marine");
                    handleShow(true, false);
                  }}
                >
                  <i className="font-size-20 la la-info-circle"></i>
                </button>
              )}
              <Form.Check
                className="custom-switch"
                type="switch"
                id="custom-switch5"
                checked={vehicleTypes.includes("Marine")}
                onChange={(e) => {
                  setSelectedVehicle("Marine");
                  if (e.target.checked) {
                    // setShow({ isShow: true, isFooter: true });
                    updateVehicleTypes("Marine", "add");
                  } else {
                    updateVehicleTypes("Marine", "", "Marine");
                  }
                }}
              />
            </div>
          </div>
          <div className="me-3 pb-3">
            <div className="d-flex flex-no-wrap form-group align-items-center">
              <label htmlFor="" className="form-label mb-0 me-2">
                Truck
              </label>
              {vehicleTypes.includes("Truck") && (
                <button
                  className="btn btn-simple btn-icon btn-icon-sm me-2"
                  onClick={() => {
                    setSelectedVehicle("Truck");
                    handleShow(true, false);
                  }}
                >
                  <i className="font-size-20 la la-info-circle"></i>
                </button>
              )}
              <Form.Check
                className="custom-switch"
                type="switch"
                id="custom-switch6"
                checked={vehicleTypes.includes("Truck")}
                onChange={(e) => {
                  setSelectedVehicle("Truck");
                  if (e.target.checked) {
                    // setShow({ isShow: true, isFooter: true });
                    updateVehicleTypes("Truck", "add");
                  } else {
                    updateVehicleTypes("Truck", "", "Truck");
                  }
                }}
              />
            </div>
          </div>
          <div className="me-3 pb-3">
            <div className="d-flex flex-no-wrap form-group align-items-center">
              <label htmlFor="" className="form-label mb-0 me-2">
                Machine
              </label>
              {vehicleTypes.includes("Machine") && (
                <button
                  className="btn btn-simple btn-icon btn-icon-sm me-2"
                  onClick={() => {
                    setSelectedVehicle("Machine");
                    handleShow(true, false);
                  }}
                >
                  <i className="font-size-20 la la-info-circle"></i>
                </button>
              )}
              <Form.Check
                className="custom-switch"
                type="switch"
                id="custom-switch7"
                checked={vehicleTypes.includes("Machine")}
                onChange={(e) => {
                  setSelectedVehicle("Machine");
                  if (e.target.checked) {
                    // setShow({ isShow: true, isFooter: true });
                    updateVehicleTypes("Machine", "add");
                  } else {
                    updateVehicleTypes("Machine", "", "Machine");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <h6 className="mb-3 text-uppercase py-3 border-bottom font-size-13">
        CUSTOM FIELD - VEHICLE TYPES
      </h6>
      <div className="row">
        <div className="col-md-6 col-xl-4">
          <div className="form-group pb-3">
            <label htmlFor="" className="form-label">
              Field Name *
            </label>
            <input
              type="text"
              className={
                formik.touched.label && formik.errors.label
                  ? "form-control is-invalid"
                  : "form-control"
              }
              value={formik.values.label}
              onChange={(e) => formik.setFieldValue("label", e.target.value)}
            />
            {formik.errors.label && formik.touched.label && (
              <div className="invalidFeedback">{formik.errors.label}</div>
            )}
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="form-group pb-3">
            <label htmlFor="" className="form-label">
              Vehicle Type
            </label>
            <Select
              className="custom-select"
              classNamePrefix="custom-select"
              options={options}
              value={options.filter(
                (data) => data.value === formik.values.vehicle_type
              )}
              onChange={(e) => formik.setFieldValue("vehicle_type", e.value)}
            />
          </div>
        </div>
        <div className="col-md-12 col-xl-4">
          <div className="form-group ">
            <label htmlFor="" className="form-label w-100 d-block">
              &nbsp;
            </label>
            <div className="radio-wrapper">
              <Form.Check
                className="custom-check radio-btn secondary-radio mb-2"
                type="radio"
                id="1"
              >
                <Form.Check.Input
                  name="size"
                  type="radio"
                  id="radio-1"
                  checked={formik.values.field_type === "1"}
                  onChange={() => formik.setFieldValue("field_type", "1")}
                />
                <Form.Check.Label htmlFor="radio-1">
                  Text
                  <span className="check"></span>
                </Form.Check.Label>
              </Form.Check>
              <Form.Check
                className="custom-check radio-btn secondary-radio mb-2"
                type="radio"
                id="2"
              >
                <Form.Check.Input
                  name="size"
                  type="radio"
                  id="radio-2"
                  checked={formik.values.field_type === "2"}
                  onChange={() => formik.setFieldValue("field_type", "2")}
                />
                <Form.Check.Label htmlFor="radio-2">
                  Date
                  <span className="check"></span>
                </Form.Check.Label>
              </Form.Check>
              <Form.Check
                className="custom-check radio-btn secondary-radio mb-2"
                type="radio"
                id="3"
              >
                <Form.Check.Input
                  name="size"
                  type="radio"
                  id="radio-3"
                  checked={formik.values.field_type === "3"}
                  onChange={() => formik.setFieldValue("field_type", "3")}
                />
                <Form.Check.Label htmlFor="radio-3">
                  List
                  <span className="check"></span>
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          {formik.values.field_type === "3" && (
            <div className="form-group ">
              <textarea
                className="form-control"
                value={formik.values.list_value}
                onChange={(e) =>
                  formik.setFieldValue("list_value", e.target.value)
                }
              />
            </div>
          )}
        </div>
      </div>

      <Modal
        className="create-new-part"
        size="lg"
        show={show.isShow}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-space-between">
            <span>Asset</span>
            <Button type="button" className="close-btn" onClick={handleClose}>
              <i className="la la-times"></i>{" "}
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img
              src={
                selectedVehicle === "Vehicle"
                  ? VehicleAsset
                  : selectedVehicle === "Motorbike"
                  ? MotorBikeAsset
                  : selectedVehicle === "Caravan"
                  ? CaravanAsset
                  : selectedVehicle === "Trailer"
                  ? TrailerAsset
                  : selectedVehicle === "Marine"
                  ? MarineAsset
                  : selectedVehicle === "Truck"
                  ? TruckAsset
                  : selectedVehicle === "Machine"
                  ? TruckAsset
                  : ""
              }
              alt=""
            />
          </div>
        </Modal.Body>
        {show.isFooter && (
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              className="btn-outline rounded-btn"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              className="rounded-btn btn-success"
              variant="primary"
              type="button"
              onClick={() => {
                setVehicleTypes([...vehicleTypes, selectedVehicle]);
                updateVehicleTypes("add");
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      {/* {(formLoading || assetTypeLoader) && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )} */}
    </>
  );
};

export default AssetTypeForm;
