// Raised purchase order START
export const RAISED_PURCHASE_ORDER = "RAISED_PURCHASE_ORDER";
export const RAISED_PURCHASE_ORDER_SUCCESS = "RAISED_PURCHASE_ORDER_SUCCESS";
export const RAISED_PURCHASE_ORDER_FAIL = "RAISED_PURCHASE_ORDER_FAIL";

export const RAISED_PURCHASE_ORDER_COUNT = "RAISED_PURCHASE_ORDER_COUNT";
export const RAISED_PURCHASE_ORDER_COUNT_SUCCESS = "RAISED_PURCHASE_ORDER_COUNT_SUCCESS";
export const RAISED_PURCHASE_ORDER_COUNT_FAIL = "RAISED_PURCHASE_ORDER_COUNT_FAIL";

export const RAISED_SEARCH_PURCHASE_ORDER = "RAISED_SEARCH_PURCHASE_ORDER";
export const RAISED_SEARCH_PURCHASE_ORDER_SUCCESS = "RAISED_SEARCH_PURCHASE_ORDER_SUCCESS";
export const RAISED_SEARCH_PURCHASE_ORDER_FAIL = "RAISED_SEARCH_PURCHASE_ORDER_FAIL";

// Raised purchase order END

// Completed purchase order START
export const COMPLETED_PURCHASE_ORDER = "COMPLETED_PURCHASE_ORDER";
export const COMPLETED_PURCHASE_ORDER_SUCCESS = "COMPLETED_PURCHASE_ORDER_SUCCESS";
export const COMPLETED_PURCHASE_ORDER_FAIL = "COMPLETED_PURCHASE_ORDER_FAIL";

export const COMPLETED_PURCHASE_ORDER_COUNT = "COMPLETED_PURCHASE_ORDER_COUNT";
export const COMPLETED_PURCHASE_ORDER_COUNT_SUCCESS = "COMPLETED_PURCHASE_ORDER_COUNT_SUCCESS";
export const COMPLETED_PURCHASE_ORDER_COUNT_FAIL = "COMPLETED_PURCHASE_ORDER_COUNT_FAIL";

export const COMPLETED_SEARCH_PURCHASE_ORDER = "COMPLETED_SEARCH_PURCHASE_ORDER";
export const COMPLETED_SEARCH_PURCHASE_ORDER_SUCCESS = "COMPLETED_SEARCH_PURCHASE_ORDER_SUCCESS";
export const COMPLETED_SEARCH_PURCHASE_ORDER_FAIL = "COMPLETED_SEARCH_PURCHASE_ORDER_FAIL";

// Completed purchase order END

// Receipts purchase order START

export const RECEIPTS_PURCHASE_ORDER = "RECEIPTS_PURCHASE_ORDER";
export const RECEIPTS_PURCHASE_ORDER_SUCCESS = "RECEIPTS_PURCHASE_ORDER_SUCCESS";
export const RECEIPTS_PURCHASE_ORDER_FAIL = "RECEIPTS_PURCHASE_ORDER_FAIL";

export const RECEIPTS_PURCHASE_ORDER_COUNT = "RECEIPTS_PURCHASE_ORDER_COUNT";
export const RECEIPTS_PURCHASE_ORDER_COUNT_SUCCESS = "RECEIPTS_PURCHASE_ORDER_COUNT_SUCCESS";
export const RECEIPTS_PURCHASE_ORDER_COUNT_FAIL = "RECEIPTS_PURCHASE_ORDER_COUNT_FAIL";

export const RECEIPTS_SEARCH_PURCHASE_ORDER = "RECEIPTS_SEARCH_PURCHASE_ORDER";
export const RECEIPTS_SEARCH_PURCHASE_ORDER_SUCCESS = "RECEIPTS_SEARCH_PURCHASE_ORDER_SUCCESS";
export const RECEIPTS_SEARCH_PURCHASE_ORDER_FAIL = "RECEIPTS_SEARCH_PURCHASE_ORDER_FAIL";

export const RECEIPTS_PURCHASE_ORDER_VIEW = "RECEIPTS_PURCHASE_ORDER_VIEW";
export const RECEIPTS_PURCHASE_ORDER_VIEW_SUCCESS = "RECEIPTS_PURCHASE_ORDER_VIEW_SUCCESS";
export const RECEIPTS_PURCHASE_ORDER_VIEW_FAIL = "RECEIPTS_PURCHASE_ORDER_VIEW_FAIL";

export const RECEIPTS_PURCHASE_ORDER_CREATE = "RECEIPTS_PURCHASE_ORDER_CREATE";
export const RECEIPTS_PURCHASE_ORDER_CREATE_SUCCESS = "RECEIPTS_PURCHASE_ORDER_CREATE_SUCCESS";
export const RECEIPTS_PURCHASE_ORDER_CREATE_FAIL = "RECEIPTS_PURCHASE_ORDER_CREATE_FAIL";

// Receipts purchase order END

// Credit purchase order START
export const CREDIT_PURCHASE_ORDER = "CREDIT_PURCHASE_ORDER";
export const CREDIT_PURCHASE_ORDER_SUCCESS = "CREDIT_PURCHASE_ORDER_SUCCESS";
export const CREDIT_PURCHASE_ORDER_FAIL = "CREDIT_PURCHASE_ORDER_FAIL";

export const CREDIT_PURCHASE_ORDER_COUNT = "CREDIT_PURCHASE_ORDER_COUNT";
export const CREDIT_PURCHASE_ORDER_COUNT_SUCCESS = "CREDIT_PURCHASE_ORDER_COUNT_SUCCESS";
export const CREDIT_PURCHASE_ORDER_COUNT_FAIL = "CREDIT_PURCHASE_ORDER_COUNT_FAIL";

export const CREDIT_SEARCH_PURCHASE_ORDER = "CREDIT_SEARCH_PURCHASE_ORDER";
export const CREDIT_SEARCH_PURCHASE_ORDER_SUCCESS = "CREDIT_SEARCH_PURCHASE_ORDER_SUCCESS";
export const CREDIT_SEARCH_PURCHASE_ORDER_FAIL = "CREDIT_SEARCH_PURCHASE_ORDER_FAIL";

export const CREDIT_PURCHASE_ORDER_VIEW = "CREDIT_PURCHASE_ORDER_VIEW";
export const CREDIT_PURCHASE_ORDER_VIEW_SUCCESS = "CREDIT_PURCHASE_ORDER_VIEW_SUCCESS";
export const CREDIT_PURCHASE_ORDER_VIEW_FAIL = "CREDIT_PURCHASE_ORDER_VIEW_FAIL";

export const CREDIT_PURCHASE_ORDER_CREATE = "CREDIT_PURCHASE_ORDER_CREATE";
export const CREDIT_PURCHASE_ORDER_CREATE_SUCCESS = "CREDIT_PURCHASE_ORDER_CREATE_SUCCESS";
export const CREDIT_PURCHASE_ORDER_CREATE_FAIL = "CREDIT_PURCHASE_ORDER_CREATE_FAIL";


export const CREATE_RECEIPT_CREDIT_NOTE = "CREATE_RECEIPT_CREDIT_NOTE";
export const CREATE_RECEIPT_CREDIT_NOTE_SUCCESS = "CREATE_RECEIPT_CREDIT_NOTE_SUCCESS";
export const CREATE_RECEIPT_CREDIT_NOTE_FAIL = "CREATE_RECEIPT_CREDIT_NOTE_FAIL";

// Credit purchase order END


// view purchase order START

export const PURCHASE_ORDER_VIEW = "PURCHASE_ORDER_VIEW";
export const PURCHASE_ORDER_VIEW_SUCCESS = "PURCHASE_ORDER_VIEW_SUCCESS";
export const PURCHASE_ORDER_VIEW_FAIL = "PURCHASE_ORDER_VIEW_FAIL";


// view purchase order END


// delete purchase order part START

export const PURCHASE_ORDER_DELETE_PART = "PURCHASE_ORDER_DELETE_PART";
export const PURCHASE_ORDER_DELETE_PART_SUCCESS = "PURCHASE_ORDER_DELETE_PART_SUCCESS";
export const PURCHASE_ORDER_DELETE_PART_FAIL = "PURCHASE_ORDER_DELETE_PART_FAIL";

// delete purchase order part END


export const FETCH_PO_JOB_WISE = "FETCH_PO_JOB_WISE";
export const FETCH_PO_JOB_WISE_SUCCESS = "FETCH_PO_JOB_WISE_SUCCESS";
export const FETCH_PO_JOB_WISE_FAIL = "FETCH_PO_JOB_WISE_FAIL";


export const PO_CREATE_JOB_WISE = "PO_CREATE_JOB_WISE";
export const PO_CREATE_JOB_WISE_SUCCESS = "PO_CREATE_JOB_WISE_SUCCESS";
export const PO_CREATE_JOB_WISE_FAIL = "PO_CREATE_JOB_WISE_FAIL";


// edit purchase order START
 
export const EDIT_PURCHASE_ORDER = "EDIT_PURCHASE_ORDER";
export const EDIT_PURCHASE_ORDER_SUCCESS = "EDIT_PURCHASE_ORDER_SUCCESS";
export const EDIT_PURCHASE_ORDER_FAIL = "EDIT_PURCHASE_ORDER_FAIL";


// edit purchase order END


// send purchase order START

export const SEND_PURCHASE_ORDER_EMAIL = "SEND_PURCHASE_ORDER_EMAIL";
export const SEND_PURCHASE_ORDER_EMAIL_SUCCESS = "SEND_PURCHASE_ORDER_EMAIL_SUCCESS";
export const SEND_PURCHASE_ORDER_EMAIL_FAIL = "SEND_PURCHASE_ORDER_EMAIL_FAIL";


// send purchase order END
