import { takeLatest, put, call } from "redux-saga/effects";
import {
    CONNECT_QUICKBOOK,
    DISCONNECT_QUICKBOOK,
    DISCONNECT_MYOB,
    UPDATE_WORKSHOP_DETAIL,
    GET_SYNC_ACCOUNT_DATA,
    GET_SYNC_TAX_DATA,
    UPDATE_SYNC_ACCOUNT_DATA,
    UPDATE_SYNC_TAX_DATA,
    GET_USERS,
    GET_INVOICE,
    GET_INVOICE_PAYMENTS_DATA,
    GET_INVOICE_PAYMENTS_CREDIT,
    SYNC_TRANSFER,
    GET_SYNC_DATA,
    UPDATE_MANY_WORKSHOP_TAX_DETAILS,
    UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS,
    DELETE_SYNC_ACCOUNTS,
    DELETE_SYNC_TAX,
    CONNECT_XERO,
    DISCONNECT_XERO,
    IMPORT_MYOB,
    GET_CASH_SALE_INVOICE,
    GET_CASH_SALE_INVOICE_PAYMENT,
    GET_CASH_SALE_INVOICE_PAYMENT_CREDIT,
    ADD_EMPLOYEE_CLOCKING_HISTORY,
    UPDATE_EMPLOYEE_CLOCKING_HISTORY,
    GET_USERS_TECHNICIAN,
    CRON_SYNC_TRANSFER
} from "./actionTypes";
import {
    connectQuickbookSuccess,
    connectQuickbookFail,
    disconnectQuickbookSuccess,
    disconnectQuickbookFail,
    disconnectMyobSuccess,
    disconnectMyobFail,
    updateWorkshopDetailSuccess,
    updateWorkshopDetailFail,
    getSyncAccountDataSuccess,
    getSyncAccountDataFail,
    getSyncTaxDataSuccess,
    getSyncTaxDataFail,
    updateSyncAccountDataSuccess,
    updateSyncAccountDataFail,
    updateSyncTaxDataSuccess,
    updateSyncTaxDataFail,
    getUsersSuccess,
    getUsersFail,
    getInvoiceSuccess,
    getInvoiceFail,
    getInvoicePaymentsCreditSuccess,
    getInvoicePaymentsCreditFail,
    getInvoicePaymentsDataSuccess,
    getInvoicePaymentsDataFail,
    syncTransferSuccess,
    syncTransferFail,
    getSyncDataSuccess,
    getSyncDataFail,
    updateManyWorkshopTaxDetailsSuccess,
    updateManyWorkshopTaxDetailsFail,
    updateManyWorkshopDepartmentDetailsSuccess,
    updateManyWorkshopDepartmentDetailsFail,
    deleteSyncAccountsSuccess,
    deleteSyncAccountsFail,
    deleteSyncTaxSuccess,
    deleteSyncTaxFail,
    connectXeroSuccess,
    connectXeroFail,
    disconnectXeroSuccess,
    disconnectXeroFail,
    importMyobSuccess,
    importMyobFail,
    getCashSaleInvoiceFail,
    getCashSaleInvoiceSuccess,
    getCashSaleInvoicePaymentFail,
    getCashSaleInvoicePaymentSuccess,
    getCashSaleInvoicePaymentCreditFail,
    getCashSaleInvoicePaymentCreditSuccess,
    addEmployeeClockingHistorySuccess,
    addEmployeeClockingHistoryFail,
    updateEmployeeClockingHistorySuccess,
    updateEmployeeClockingHistoryFail,
    getUsersTechnicianSuccess,
    getUsersTechnicianFail,
    cronSyncTransferSuccess,
    cronSyncTransferFail
} from "./actions";


import {
    connectQuickbook,
    disconnectQuickbook,
    disconnectMyob,
    updateWorkshopDetail,
    getSyncAccountData,
    getSyncTaxData,
    updateSyncAccountData,
    updateSyncTaxData,
    getUsers,
    getInvoicesList,
    getInvoicesPayments,
    getInvoicesCredits,
    syncTransfer,
    getSyncData,
    updateManyWorkshopTaxDetails,
    updateManyWorkshopDepartmentDetails,
    deleteSyncAccounts,
    deleteSyncTax,
    connectXero,
    disconnectXero,
    importMyob,
    getInvoicesSalesList,
    getCashSalePayments,
    getCashSaleCredit,
    addEmployeeClockingHistory,
    updateEmployeeClockingHistory,
    cronSyncTransfer
} from "../../helpers/backendHelper";
import { showError } from "utils/alertMessage";

function* onConnectQuickbook(data) {
    try {
        const response = yield call(connectQuickbook, data);
        if (response) {
            if (response.code === 200) {
                yield put(connectQuickbookSuccess(response.data.QuickBook));
            } else {
                yield put(connectQuickbookFail(response.message));
            }
        } else {
            yield put(connectQuickbookFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(connectQuickbookFail(error.response));
    }
}

function* onDisconnectQuickbook(data) {
    try {
        const response = yield call(disconnectQuickbook, data);
        if (response) {
            if (response.code === 200) {
                yield put(disconnectQuickbookSuccess(response.data.QuickBook));
            } else {
                yield put(disconnectQuickbookFail(response.message));
            }
        } else {
            yield put(disconnectQuickbookFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(disconnectQuickbookFail(error.response));
    }
}

function* onDisconnectMyob(data) {
    try {
        const response = yield call(disconnectMyob, data);
        if (response) {
            if (response.code === 200) {
                yield put(disconnectMyobSuccess(response.data.Workshops));
            } else {
                yield put(disconnectMyobFail(response.message));
            }
        } else {
            yield put(disconnectMyobFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(disconnectMyobFail(error.response));
    }
}

function* onUpdateWorkshopDetail(data) {
    try {
        const response = yield call(updateWorkshopDetail, data);
        if (response) {
            if (response.code === 200) {
                yield put(updateWorkshopDetailSuccess(response.data.Workshops));
            } else {
                yield put(updateWorkshopDetailFail(response.message));
            }
        } else {
            yield put(updateWorkshopDetailFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(updateWorkshopDetailFail(error.response));
    }
}

function* onGetSyncAccountData(data) {
    try {
        const response = yield call(getSyncAccountData, data);
        if (response) {
            if (response.code === 200) {
                yield put(getSyncAccountDataSuccess(response.data.SyncedAccounts));
            } else {
                yield put(getSyncAccountDataFail(response.message));
            }
        } else {
            yield put(getSyncAccountDataFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getSyncAccountDataFail(error.response));
    }
}

function* onGetSyncTaxData(data) {
    try {
        const response = yield call(getSyncTaxData, data);
        if (response) {
            if (response.code === 200) {
                yield put(getSyncTaxDataSuccess(response.data.SyncedTaxes));
            } else {
                yield put(getSyncTaxDataFail(response.message));
            }
        } else {
            yield put(getSyncTaxDataFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getSyncTaxDataFail(error.response));
    }
}

function* onUpdateSyncAccountData(data) {
    try {
        const response = yield call(updateSyncAccountData, data);
        if (response) {
            if (response.code === 200) {
                yield put(updateSyncAccountDataSuccess(response.data.SyncedAccounts));
            } else {
                yield put(updateSyncAccountDataFail(response.message));
            }
        } else {
            yield put(updateSyncAccountDataFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(updateSyncAccountDataFail(error.response));
    }
}

function* onUpdateSyncTaxData(data) {
    try {
        const response = yield call(updateSyncTaxData, data);
        if (response) {
            if (response.code === 200) {
                yield put(updateSyncTaxDataSuccess(response.data.SyncedTaxes));
            } else {
                yield put(updateSyncTaxDataFail(response.message));
            }
        } else {
            yield put(updateSyncTaxDataFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(updateSyncTaxDataFail(error.response));
    }
}

function* onGetUsers(data) {
    try {
        const response = yield call(getUsers, data);
        if (response) {
            if (response.code === 200) {
                yield put(getUsersSuccess(response.data.Users));
            } else {
                yield put(getUsersFail(response.message));
            }
        } else {
            yield put(getUsersFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getUsersFail(error.response));
    }
}

function* onGetUsersTechnician(data) {
    try {
        const response = yield call(getUsers, data);
        if (response) {
            if (response.code === 200) {
                yield put(getUsersTechnicianSuccess(response.data.Users));
            } else {
                yield put(getUsersTechnicianFail(response.message));
            }
        } else {
            yield put(getUsersTechnicianFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getUsersTechnicianFail(error.response));
    }
}

function* onGetInvoice(data) {
    try {
        const response = yield call(getInvoicesList, data);
        if (response) {
            if (response.code === 200) {
                yield put(getInvoiceSuccess(response.data.Invoices));
            } else {
                yield put(getInvoiceFail(response.message));
            }
        } else {
            yield put(getInvoiceFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getInvoiceFail(error.response));
    }
}

function* onGetInvoicePaymentsData(data) {
    try {
        const response = yield call(getInvoicesPayments, data);
        if (response) {
            if (response.code === 200) {
                yield put(getInvoicePaymentsDataSuccess(response.data.InvoicesPaymentsCredits));
            } else {
                yield put(getInvoicePaymentsDataFail(response.message));
            }
        } else {
            yield put(getInvoicePaymentsDataFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getInvoicePaymentsDataFail(error.response));
    }
}

function* onGetInvoicePaymentsCredit(data) {
    try {
        const response = yield call(getInvoicesCredits, data);
        if (response) {
            if (response.code === 200) {
                yield put(getInvoicePaymentsCreditSuccess(response.data.InvoicesPaymentsCreditsOtherDetails));
            } else {
                yield put(getInvoicePaymentsCreditFail(response.message));
            }
        } else {
            yield put(getInvoicePaymentsCreditFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getInvoicePaymentsCreditFail(error.response));
    }
}

function* onSyncTransfer(data) {
    try {
        const response = yield call(syncTransfer, data);
        if (response) {
            if (response.code === 200) {
                yield put(syncTransferSuccess(response.data.SyncData));
            } else {
                yield put(syncTransferFail(response.message));
            }
        } else {
            yield put(syncTransferFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(syncTransferFail(error.response));
    }
}

function* onGetSyncData(data) {
    try {
        const response = yield call(getSyncData, data);
        if (response) {
            if (response.code === 200) {
                yield put(getSyncDataSuccess(response.data.SyncData));
            } else {
                yield put(getSyncDataFail(response.message));
            }
        } else {
            yield put(getSyncDataFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getSyncDataFail(error.response));
    }
}

function* onUpdateManyWorkshopTaxDetails(data) {
    try {
        const response = yield call(updateManyWorkshopTaxDetails, data);
        if (response) {
            if (response.code === 200) {
                yield put(updateManyWorkshopTaxDetailsSuccess(response.data.WorkshopTaxRatesDetails));
            } else {
                yield put(updateManyWorkshopTaxDetailsFail(response.message));
            }
        } else {
            yield put(updateManyWorkshopTaxDetailsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(updateManyWorkshopTaxDetailsFail(error.response));
    }
}

function* onUpdateManyWorkshopDepartmentDetails(data) {
    try {
        const response = yield call(updateManyWorkshopDepartmentDetails, data);
        if (response) {
            if (response.code === 200) {
                yield put(updateManyWorkshopDepartmentDetailsSuccess(response.data.WorkshopDepartmentsAccountsDetails));
            } else {
                yield put(updateManyWorkshopDepartmentDetailsFail(response.message));
            }
        } else {
            yield put(updateManyWorkshopDepartmentDetailsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(updateManyWorkshopDepartmentDetailsFail(error.response));
    }
}

function* onDeleteSyncAccounts(data) {
    try {
        const response = yield call(deleteSyncAccounts, data);
        if (response) {
            if (response.code === 200) {
                yield put(deleteSyncAccountsSuccess(response.data.SyncedAccounts));
            } else {
                yield put(deleteSyncAccountsFail(response.message));
            }
        } else {
            yield put(deleteSyncAccountsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(deleteSyncAccountsFail(error.response));
    }
}

function* onDeleteSyncTax(data) {
    try {
        const response = yield call(deleteSyncTax, data);
        if (response) {
            if (response.code === 200) {
                yield put(deleteSyncTaxSuccess(response.data.SyncedTaxes));
            } else {
                yield put(deleteSyncTaxFail(response.message));
            }
        } else {
            yield put(deleteSyncTaxFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(deleteSyncTaxFail(error.response));
    }
}

function* onConnectXero(data) {
    try {
        const response = yield call(connectXero, data);
        if (response) {
            if (response.code === 200) {
                yield put(connectXeroSuccess(response.data.Xero));
            } else {
                yield put(connectXeroFail(response.message));
            }
        } else {
            yield put(connectXeroFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(connectXeroFail(error.response));
    }
}

function* onDisconnectXero(data) {
    try {
        const response = yield call(disconnectXero, data);
        if (response) {
            if (response.code === 200) {
                yield put(disconnectXeroSuccess(response.data.Xero));
            } else {
                yield put(disconnectXeroFail(response.message));
            }
        } else {
            yield put(disconnectXeroFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(disconnectXeroFail(error.response));
    }
}

function* onImportMyob(data) {
    try {
        const response = yield call(importMyob, data);
        if (response) {
            if (response.code === 200) {
                yield put(importMyobSuccess(response.data.SyncData));
            } else {
                yield put(importMyobFail(response.message));
            }
        } else {
            yield put(importMyobFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(importMyobFail(error.response));
    }
}

function* onGetCashSaleInvoice(data) {
    try {
        const response = yield call(getInvoicesSalesList, data);
        if (response) {
            if (response.code === 200) {
                yield put(getCashSaleInvoiceSuccess(response.data.CashSaleInvoice));
            } else {
                yield put(getCashSaleInvoiceFail(response.message));
            }
        } else {
            yield put(getCashSaleInvoiceFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getCashSaleInvoiceFail(error.response));
    }
}

function* onGetCashSaleInvoicePayment(data) {
    try {
        const response = yield call(getCashSalePayments, data);
        if (response) {
            if (response.code === 200) {
                yield put(getCashSaleInvoicePaymentSuccess(response.data.CashSalePaymentsCredits));
            } else {
                yield put(getCashSaleInvoicePaymentFail(response.message));
            }
        } else {
            yield put(getCashSaleInvoicePaymentFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getCashSaleInvoicePaymentFail(error.response));
    }
}

function* onGetCashSaleInvoicePaymentCredit(data) {
    try {
        const response = yield call(getCashSaleCredit, data);
        if (response) {
            if (response.code === 200) {
                yield put(getCashSaleInvoicePaymentCreditSuccess(response.data.CashSalePaymentsCreditsOtherDetails));
            } else {
                yield put(getCashSaleInvoicePaymentCreditFail(response.message));
            }
        } else {
            yield put(getCashSaleInvoicePaymentCreditFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getCashSaleInvoicePaymentCreditFail(error.response));
    }
}

function* onAddEmployeeClockingHistory(data) {
    try {
        const response = yield call(addEmployeeClockingHistory, data);
        if (response) {
            if (response.code === 200) {
                yield put(addEmployeeClockingHistorySuccess(response?.data?.EmployeeClockingHistory));
            } else {
                yield put(addEmployeeClockingHistoryFail(response.message));
                showError(response.message);
            }
        } else {
            yield put(addEmployeeClockingHistoryFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addEmployeeClockingHistoryFail(error.response));
    }
}

function* onUpdateEmployeeClockingHistory(data) {
    try {
        const response = yield call(updateEmployeeClockingHistory, data);
        if (response) {
            if (response.code === 200) {
                yield put(updateEmployeeClockingHistorySuccess(response?.data?.EmployeeClockingHistory));
            } else {
                yield put(updateEmployeeClockingHistoryFail(response.message));
                showError(response.message);
            }
        } else {
            yield put(updateEmployeeClockingHistoryFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(updateEmployeeClockingHistoryFail(error.response));
    }
}

function* onCronSyncTransfer(data) {
    try {
        const response = yield call(cronSyncTransfer, data);
        if (response) {
            if (response.code === 200) {
                yield put(cronSyncTransferSuccess(response.data.SyncData));
            } else {
                yield put(cronSyncTransferFail(response.message));
            }
        } else {
            yield put(cronSyncTransferFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(syncTransferFail(error.response));
    }
}

function* AccountSyncSaga() {
    yield takeLatest(CONNECT_QUICKBOOK, onConnectQuickbook);
    yield takeLatest(DISCONNECT_QUICKBOOK, onDisconnectQuickbook);
    yield takeLatest(DISCONNECT_MYOB, onDisconnectMyob);
    yield takeLatest(UPDATE_WORKSHOP_DETAIL, onUpdateWorkshopDetail);
    yield takeLatest(GET_SYNC_ACCOUNT_DATA, onGetSyncAccountData);
    yield takeLatest(GET_SYNC_TAX_DATA, onGetSyncTaxData);
    yield takeLatest(UPDATE_SYNC_ACCOUNT_DATA, onUpdateSyncAccountData);
    yield takeLatest(UPDATE_SYNC_TAX_DATA, onUpdateSyncTaxData);
    yield takeLatest(GET_USERS, onGetUsers);
    yield takeLatest(GET_USERS_TECHNICIAN, onGetUsersTechnician);
    yield takeLatest(GET_INVOICE, onGetInvoice);
    yield takeLatest(GET_INVOICE_PAYMENTS_DATA, onGetInvoicePaymentsData);
    yield takeLatest(GET_INVOICE_PAYMENTS_CREDIT, onGetInvoicePaymentsCredit);
    yield takeLatest(SYNC_TRANSFER, onSyncTransfer);
    yield takeLatest(GET_SYNC_DATA, onGetSyncData);
    yield takeLatest(UPDATE_MANY_WORKSHOP_TAX_DETAILS, onUpdateManyWorkshopTaxDetails);
    yield takeLatest(UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS, onUpdateManyWorkshopDepartmentDetails);
    yield takeLatest(DELETE_SYNC_ACCOUNTS, onDeleteSyncAccounts);
    yield takeLatest(DELETE_SYNC_TAX, onDeleteSyncTax);
    yield takeLatest(CONNECT_XERO, onConnectXero);
    yield takeLatest(DISCONNECT_XERO, onDisconnectXero);
    yield takeLatest(IMPORT_MYOB, onImportMyob);
    yield takeLatest(GET_CASH_SALE_INVOICE, onGetCashSaleInvoice);
    yield takeLatest(GET_CASH_SALE_INVOICE_PAYMENT, onGetCashSaleInvoicePayment);
    yield takeLatest(GET_CASH_SALE_INVOICE_PAYMENT_CREDIT, onGetCashSaleInvoicePaymentCredit);
    yield takeLatest(ADD_EMPLOYEE_CLOCKING_HISTORY, onAddEmployeeClockingHistory);
    yield takeLatest(UPDATE_EMPLOYEE_CLOCKING_HISTORY, onUpdateEmployeeClockingHistory);
    yield takeLatest(CRON_SYNC_TRANSFER, onCronSyncTransfer);
}

export default AccountSyncSaga;
