import React from "react";
import { Button, Carousel } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import "../../style.scss";
import "../style.scss";
import moment from "moment";
import serviceImg from "assets/images/book-job-red.svg";
import news1 from "assets/images/news1.png";
import news2 from "assets/images/news2.png";
import news3 from "assets/images/news3.jpg";
import { downloadFileFromS3 } from "utils/uploadFileToS3";
import labourImg from "assets/images/labour.png";
import totalSales from "assets/images/totalSales.png";
import noShows from "assets/images/noShows.png";
import serviceDue from "assets/images/serviceDue.png";

const RightSection = () => {
    return (
        <div className="outing-border dashboard-wrapper">
            {/* Stack */}
            <div className="m-b-10">
                <div className="outing-border orange-border">
                    <div className="d-flex align-center block-wrapper orange-tiles">
                        <img src={serviceDue} alt=""></img>
                        <div className="right-block">
                            <h1>5</h1>
                            <p>Service Due</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-b-10">
                <div className="outing-border blue-border">
                    <div className="d-flex align-center block-wrapper blue-tiles">
                        {/* <i className="fa fa-car"></i> */}
                        <img src={noShows} alt=""></img>
                        <div className="right-block">
                            {/* <h1>50</h1> */}
                            <p>No Shows</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-b-10">
                <div className="outing-border purple-border">
                    <div className="d-flex align-center block-wrapper purple-tiles">
                        {/* <i className="fa fa-users"></i> */}
                        <img src={labourImg} alt=""></img>
                        <div className="right-block">
                            {/* <h1>5598</h1> */}
                            <p>Labour Productivity - 50%</p>
                            <p>Efficiency - 10% </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-b-10">
                <div className="outing-border green-border">
                    <div className="d-flex align-center block-wrapper green-tiles">
                        {/* <i className="fa fa-cloud-download" aria-hidden="true"></i> */}
                        <img src={totalSales} alt=""></img>
                        <div className="right-block">
                            <h1>$500 </h1>
                            <p>Total Sales Gross</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="m-b-10 flex-1">
                <div className="outing-border dashboard-news">
                    <div className="title">
                        <span >News</span>
                    </div>
                    <div className="dashboard-chart d-flex direction-column chart-wrapper flex-1">
                        <Carousel className="custom-slider custom-news-slider">
                            <Carousel.Item className="carousel-item">
                                <img
                                    className="d-block w-100"
                                    src={news3}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3 className="text-success">Title</h3>
                                    <p className="date">{moment().format("DD MMMM YYYY")} </p>
                                    <p className="title">Description</p>
                                    <Button className="view-all" onClick={()=>Navigate('/news_list')}>
                                        View All
                                    </Button>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item className="carousel-item">
                                <img
                                    className="d-block w-100"
                                    src={news2}
                                    alt="Middle slide"
                                />
                                <Carousel.Caption>
                                    <h3 className="text-success">Title</h3>
                                    <p className="date">{moment().format("DD MMMM YYYY")} </p>
                                    <p className="title">Description</p>
                                    <Button className="view-all" onClick={()=>Navigate('/news_list')}>
                                        View All
                                    </Button>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item className="carousel-item">
                                <img
                                    className="d-block w-100"
                                    src={news1}
                                    alt="Last slide"
                                />
                                <Carousel.Caption>
                                    <h3 className="text-success">Title</h3>
                                    <p className="date">{moment().format("DD MMMM YYYY")} </p>
                                    <p className="title">Description</p>
                                    <Button className="view-all" onClick={()=>Navigate('/news_list')}>
                                        View All
                                    </Button>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div> 
    );
};

export default RightSection;
