import React, { useEffect } from "react";
import Swal from "sweetalert2";

const PoWarning = ({
  showPOWarningModal,
  setShowPOWarningModal,
  setShowNoShowModal
}) => {
  const handleClose = () => setShowPOWarningModal(false);


  useEffect(() => {
    Swal.fire({
      title: "This Job has parts,<br/> are you sure you want to continue?",
      text: "",
      icon: "error",
      position: "top",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: "#65cc88",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      customClass: {
        container: "delete-record",
      },
      width: '400'
    }).then((result) => {
      if (result.isConfirmed) {
        setShowNoShowModal(true);
        handleClose();
      } else {
        handleClose();
        setShowNoShowModal(false);
      }

    });
  }, []);

  return "";
};
export default PoWarning;
