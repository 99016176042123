import {
  editJobLabourMany,
  editJobPartMany,
  updateInvoice,
  updateJob,
} from "helpers/backendHelper";
import {
  CALLBACK_ERROR_ARGUMENT,
  ERROR_ACTION,
  WAIT_FOR_ACTION,
} from "redux-wait-for-action";
import {
  EDIT_JOB_LABOUR_MANY,
  EDIT_JOB_LABOUR_MANY_FAIL,
  EDIT_JOB_LABOUR_MANY_SUCCESS,
} from "store/jobLabour/actionTypes";
import {
  EDIT_JOB_PART_MANY,
  EDIT_JOB_PART_MANY_FAIL,
  EDIT_JOB_PART_MANY_SUCCESS,
} from "store/jobParts/actionTypes";
import { getWorkshopId } from "utils/getWorkshopId";
import { removeCommasNew } from "utils/numberFunction";
import {
  UPDATE_JOB_TIMER,
  UPDATE_JOB_TIMER_FAIL,
  UPDATE_JOB_TIMER_SUCCESS,
} from "../actionTypes";

export async function jobUnmount({ parts, job, labours, dispatch }) {

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));

  const invoice = job?.invoices;
  let invoice_id = storedData?.invoice_id;
  let isInvoiced = storedData?.isInvoiced;

  const partsPayload = parts.map((part, index) => {
    return {
      id: part.main_id,
      workshop_id: getWorkshopId(),
      job_schedule_id: job.id,
      product_id: part.id,
      is_inspection_part: part?.is_inspection_part ?? 0,
      is_kit: part?.is_kit ?? 0,
      job_part_name: part?.job_part_name,
      job_part_description: part.part_number,
      // job_part_priority: index,
      cost: part.cost_price,
      po_qty_sent: part?.po_qty_sent ?? 0,
      price: removeCommasNew(part.retail_price),
      is_demo_data: part?.is_demo_data ?? 0,
      margin: part?.margin ?? 0,
      sell: part?.sell_price ?? 0,
      gst_price: part?.gst_price ?? 0,
      margin_markup: part?.margin_markup ?? 0,
      retail: removeCommasNew(part?.retail_price),
      profit: part?.profit_price,
      gst: part?.is_gst,
      time: 0,
      qty: part?.qty,
      assigned_qty: part?.assigned_qty ?? 0,
      purchase_order_qty: part?.purchase_order_qty ?? 0,
      basic_stock: part?.basic_stock ?? 0,
      is_assigned: part?.is_assigned ?? 0,
      is_carried_over_parts: part?.is_carried_over_parts,
      invoice_type: part?.invoice_type,
      selected_invoice_allocation:part?.selected_invoice_allocation,
      status: "1",
      is_deleted: "0",
      repco_extra_part: part?.repco_extra_part ?? "0",
      is_misc_part: part?.is_misc_part,
      job_part_number: part?.part_number,
      sell_total:parseFloat(part?.sell_price) * parseFloat(part?.qty),
      gst_total:parseFloat(part?.gst_price) * parseFloat(part?.qty),
      product_type: part?.product_type ?? "1",
      job_id: job.id,
    };
  });

  const laboursPayload = labours?.map((labour, index) => {
    return {
      id: labour.id,
      labour_charges_id: labour.labour_charges_id,
      job_schedules_id: job.id,
      cost_price: labour.cost_price,
      sell_price: labour.sell_price,
      retail_price: removeCommasNew(labour.retail_price),
      labour_description: labour.labour_description,
      labour_ph: labour.labour_ph,
      labour_time: labour.labour_time,
      total_price: labour.total_price,
      profit: labour.profit,
      sell_total:labour.sell_total,
      gst_total:labour.gst_total,
      // priority: 0,
      is_inspection: labour.is_inspection,
      is_carried_over_labour: labour?.is_carried_over_labour ?? undefined,
      invoice_type: labour?.invoice_type,
      selected_invoice_allocation:labour?.selected_invoice_allocation
    };
  });

  const summaryPayload = {
    is_update: true,
    id: job.id,
    parts_cost: job.parts_cost,
    labour_cost: job.labour_cost,
    parts_markup: job.parts_markup,
    labour_markup: job.labour_markup,
    parts_total_cost: job.parts_total_cost,
    parts_total_sell: job.parts_total_sell,
    labour_total_cost: job.labour_total_cost,
    labour_total_sell: job.labour_total_sell,
    labour_time: job.labour_time,
    timer_total_time: job.timer_total_time,
    sub_total: job.sub_total,
    gst: job.gst,
    discount_rate: job.discount_rate,
    discount_cost: job.discount_cost,
    discount_type: job.discount_type,
    discount_claim_type: job.discount_claim_type,
    total: job.total,
    profit_margin: job.profit_margin,
    job_estimate_time: job.job_estimate_time,
    is_default_time: job?.is_default_time ?? 0,
  };

  const invoiceDetail = {
    id: invoice_id,
    sub_total: job?.sub_total,
    total_with_discount: job?.total,
    gst: job?.gst,
    total: job?.total,
    // remain_balance: parseFloat(job?.total) - parseFloat(invoice[0]?.claim_amount || 0),
    remain_balance: invoice[0]?.claim_amount > 0 && parseFloat(job?.total) - parseFloat(invoice[0]?.claim_amount || 0),
  }


  // const request = [
  //   updateJob({
  //     payload: summaryPayload,
  //   }),
  // ];
  // if (partsPayload.length !== 0) {
  //   request.push(
  //     editJobPartMany({
  //       payload: partsPayload,
  //     })
  //   );
  // }

  // if (laboursPayload.length !== 0) {
  //   request.push(
  //     editJobLabourMany({
  //       payload: laboursPayload,
  //     })
  //   );
  // }

  const request = [];

  request.push(() => updateJob({ payload: summaryPayload }));

  if (partsPayload.length !== 0) {
    request.push(() => editJobPartMany({ payload: partsPayload }));
  }

  if (laboursPayload.length !== 0) {
    request.push(() => editJobLabourMany({ payload: laboursPayload }));
  }

  const executeSequentially = (tasks) => {
    return tasks.reduce((promise, task) => {
      return promise.then(() => task());
    }, Promise.resolve());
  };

  executeSequentially(request)
    .catch((error) => {
      console.error('An error occurred:', error);
    });

    if (isInvoiced === true && invoice_id > 0) {
      const get_customer_data = await updateInvoice({ payload: invoiceDetail });
    }

  // try {
  //   await Promise.all(request);
  // } catch (error) {
  // }
}
