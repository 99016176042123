import { useEffect, useMemo, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/5iQ_logo.png";
import logoBlack from "../../assets/images/5iQ_logo_black.png";
import { bottomList as bottomLinks, settingLinks, topLinks, reportLinks } from "./links";
import ScrollPoints from "./ScrollPoints";
import SettingsBar from "./SettingsBar";
import ReportBar from "./ReportBar";
import SidebarLinkItem from "./SidebarLinkItem";
import { useRoleLink } from "./useLinkRole";
import { useSelector } from "react-redux";

const Sidebar = ({ children }) => {
  // const navigate = useNavigate();
  const { is_inspection_business } = useSelector((state) => state.LoginReducer);

  const [isShown, setIsShown] = useState(false);
  const { filterCondition } = useRoleLink();
  const bottomList = useMemo(() => bottomLinks.filter(filterCondition), [is_inspection_business, filterCondition])

  const inSidebars = useRef(false);

  const location = useLocation();
  
  const showSettingBar =
    settingLinks.map((c) => c.link).includes(location.pathname) && !isShown;

  const showReportBar =
    reportLinks.map((c) => c.link).includes(location.pathname) && !isShown;

  let showMainSidebar = true;

  if (showSettingBar || showReportBar) {
    showMainSidebar = false;
  }
  const activeLink = (item) => {
    // window.scrollTo(0, 0);
    return item.main_link === location.pathname || item.sub_items.map(c => c.link).includes(location.pathname);
  };
  // const showMainSidebar = !showSettingBar;
  return (
    <>
      <Button
        className="side-menu-btn"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={(e) => !inSidebars.current && setIsShown(false)}
      >
        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
      </Button>
      {showMainSidebar && (
        <div
          className="sidebar-wrapper"
          onMouseEnter={() => {
            inSidebars.current = true;
          }}
          onMouseLeave={() => {
            inSidebars.current = false;
            setIsShown(false);
          }}
        >
          <ul className="top-list">
            {topLinks.filter(filterCondition).map((link) => (
              <>
                <SidebarLinkItem {...link} active={activeLink(link)} key={link.label} />
              </>
            ))}
          </ul>
          <ul className="bottom-list">
            {bottomList.map((link) => (
              <SidebarLinkItem {...link} active={activeLink(link)} key={link.label} />
            ))}

            <li className="sidebar-logo">
              <Link to="/home">
                <img className="light-theme" src={logoBlack} alt=""></img>
                <img className="dark-theme" src={logo} alt=""></img>
              </Link>
            </li>
          </ul>
        </div>
      )}
      {showSettingBar && <SettingsBar />}
      {showReportBar && <ReportBar />}
      <ScrollPoints />
    </>
  );
};

export default Sidebar;
