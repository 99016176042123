import { getWorkshopTechnician } from "helpers/backendHelper";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_JOBTYPE_LIST,
  GET_VEHICLES,
} from "store/commonApiStore/actionTypes";
import * as Actions from "./actionType";
import { createOnlineJob } from "./createOnlineJob";
import { getTimeSlots } from "./getTimeSlots";
function* onGetWorkShopDetail({ token }) {
  const request = {
    get: "all",
    conditions: {
      workshopToken: token,
    },
    fields: ["id", "url", "country", "state", "logo", "country"],
    contain: ["WorkshopOtherDetails"],
  };
  try {
    const getWorkshop = yield call(getWorkshopTechnician, { payload: request });
    if (getWorkshop?.code === 200) {
      yield put({
        type: Actions.GET_WORKSHOP_DETAIL_ONLINE_JOB_SUCCESS,
        payload: getWorkshop?.data?.Workshops[0],
      });
    } else {
      yield put({
        type: Actions.GET_WORKSHOP_DETAIL_ONLINE_JOB_FAIL,
      });
    }
  } catch (error) {
    yield put({
      type: Actions.GET_WORKSHOP_DETAIL_ONLINE_JOB_FAIL,
    });
  }
}

function* onGetInitialData({ token }) {
  try {
    yield put({
      type: Actions.GET_WORKSHOP_DETAIL_ONLINE_JOB,
      token,
    });
    yield put({
      type: GET_JOBTYPE_LIST,
      payload: {
        get: "all",
        conditions: {
          status: 1,
        },
        fields: ["id", "parent_id", "name", "workshop_id"],
      },
    });

    yield put({
      type: GET_VEHICLES,
      payload: {
        get: "all",
        group: "assets_make",
      },
    });

    yield put({
      type: Actions.GET_INITIAL_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: Actions.GET_INITIAL_DATA_FAIL,
    });
  }
}

function* onGetTimeSlots({ payload }) {
  try {
    const timeSlots = yield call(getTimeSlots, payload);
    yield put({
      type: Actions.GET_TIME_SLOTS_SUCCESS,
      payload: timeSlots,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_TIME_SLOTS_FAIL,
    });
  }
}

function* onCreateOnlineJob({ payload }) {
  try {
    const success = yield call(createOnlineJob, payload);
    if (success) {
      yield put({
        type: Actions.CREATE_ONLINE_JOB_SUCCESS,
      })
    } else {
      yield put({
        type: Actions.CREATE_ONLINE_JOB_FAIL,
      })
    }
  } catch (err) {
    yield put({
      type: Actions.CREATE_ONLINE_JOB_FAIL,
    })
  }
}

function* bookJobOnlineSaga() {
  yield takeLatest(Actions.GET_WORKSHOP_DETAIL_ONLINE_JOB, onGetWorkShopDetail);
  yield takeLatest(Actions.GET_INITIAL_DATA, onGetInitialData);
  yield takeLatest(Actions.GET_TIME_SLOTS, onGetTimeSlots);
  yield takeLatest(Actions.CREATE_ONLINE_JOB, onCreateOnlineJob);
}
export default bookJobOnlineSaga;
