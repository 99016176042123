import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from 'react-bootstrap';
import Noresultfound from 'assets/images/Noresultfound2.png';

const EmptyRegoLookup = ({ showEmptyRegoLookUpModal,
  handleCloseEmptyRegoLookupModal,
  handleShowEmptyRegoLookupModal,
  closeButton = "Return To Search",
  isOnline = false
}) => {

  return (
    <>
      <Modal
        className={isOnline ? "rego-online" : "vehicle-details-dialog"}
        size={isOnline === false && "md"}
        show={showEmptyRegoLookUpModal}
        onHide={handleCloseEmptyRegoLookupModal}
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-space-between">
            <span>Vehicle Details</span>
            <Button type="button" className="close-btn" onClick={handleCloseEmptyRegoLookupModal}><i className="la la-times"></i> </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={Noresultfound} alt="No Result Found"></img>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            className="btn-outline rounded-btn bg-danger text-white"
            onClick={handleCloseEmptyRegoLookupModal}
          >
            {closeButton}
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  )



}
export default EmptyRegoLookup;