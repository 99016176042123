import {
  SAVE_JOB_PART,
  SAVE_JOB_PART_SUCCESS,
  SAVE_JOB_PART_FAIL,
  GET_JOB_PART_LIST,
  GET_JOB_PART_LIST_SUCCESS,
  GET_JOB_PART_LIST_FAIL,
  DELETE_JOB_PART,
  DELETE_JOB_PART_SUCCESS,
  DELETE_JOB_PART_FAIL,
  UPDATE_JOB_PART,
  UPDATE_JOB_PART_SUCCESS,
  UPDATE_JOB_PART_FAIL,
  INLINE_UPDATE_JOB_PART,
  INLINE_UPDATE_JOB_PART_SUCCESS,
  INLINE_UPDATE_JOB_PART_FAIL,
  EDIT_JOB_PART_MANY,
  EDIT_JOB_PART_MANY_SUCCESS,
  EDIT_JOB_PART_MANY_FAIL,
  GET_JOB_KIT_PART,
  GET_JOB_KIT_PART_SUCCESS,
  GET_JOB_KIT_PART_FAIL,
  GET_JOB_KIT_LABOUR,
  GET_JOB_KIT_LABOUR_SUCCESS,
  GET_JOB_KIT_LABOUR_FAIL,
  SAVE_JOB_KIT_PART,
  SAVE_JOB_KIT_PART_SUCCESS,
  SAVE_JOB_KIT_PART_FAIL,
  DELETE_JOB_KIT_PART,
  DELETE_JOB_KIT_PART_SUCCESS,
  DELETE_JOB_KIT_PART_FAIL,
  INLINE_UPDATE_JOB_KIT_PART,
  INLINE_UPDATE_JOB_KIT_PART_SUCCESS,
  INLINE_UPDATE_JOB_KIT_PART_FAIL,
  EDIT_JOB_KIT_PART_MANY,
  EDIT_JOB_KIT_PART_MANY_SUCCESS,
  EDIT_JOB_KIT_PART_MANY_FAIL,
  EDIT_JOB_KIT_LABOUR_MANY,
  EDIT_JOB_KIT_LABOUR_MANY_SUCCESS,
  EDIT_JOB_KIT_LABOUR_MANY_FAIL,
  INLINE_UPDATE_JOB_KIT_LABOUR,
  INLINE_UPDATE_JOB_KIT_LABOUR_SUCCESS,
  INLINE_UPDATE_JOB_KIT_LABOUR_FAIL,
  GET_PO_PART_LIST,
  GET_PO_PART_LIST_SUCCESS,
  GET_PO_PART_LIST_FAIL,
  GET_BURSON_PARTS,
  GET_BURSON_PARTS_SUCCESS,
  GET_BURSON_PARTS_FAIL,
  CREATE_BURSON_PO,
  CREATE_BURSON_PO_SUCCESS,
  CREATE_BURSON_PO_FAIL,
  GET_REPCO_PARTS,
  GET_REPCO_PARTS_SUCCESS,
  GET_REPCO_PARTS_FAIL,
} from "./actionTypes";

export const saveJobPart = () => {
  return {
    type: SAVE_JOB_PART,
  };
};

export const saveJobPartSuccess = (data) => {
  return {
    type: SAVE_JOB_PART_SUCCESS,
    payload: data,
  };
};

export const saveJobPartFail = (error) => {
  return {
    type: SAVE_JOB_PART_FAIL,
    payload: error,
  };
};

// Get Job part START
export const getJobPartList = () => {
  return {
    type: GET_JOB_PART_LIST,
  };
};

export const getJobPartListSuccess = (data) => {
  return {
    type: GET_JOB_PART_LIST_SUCCESS,
    payload: data,
  };
};

export const getJobPartListFail = (error) => {
  return {
    type: GET_JOB_PART_LIST_FAIL,
    payload: error,
  };
};

// Get Job part END


// delete job part start
export const deleteJobPart = () => {
  return {
    type: DELETE_JOB_PART,
  };
};

export const deleteJobPartSuccess = (data) => {
  return {
    type: DELETE_JOB_PART_SUCCESS,
    payload: data,
  };
};

export const deleteJobPartFail = (error) => {
  return {
    type: DELETE_JOB_PART_FAIL,
    payload: error,
  };
};
// delete job part end


export const updateJobPart = () => {
  return {
    type: UPDATE_JOB_PART,
  };
};

export const updateJobPartSuccess = (data) => {
  return {
    type: UPDATE_JOB_PART_SUCCESS,
    payload: data,
  };
};

export const updateJobPartFail = (error) => {
  return {
    type: UPDATE_JOB_PART_FAIL,
    payload: error,
  };
};

export const inlineUpdateJobPart = () => {
  return {
    type: INLINE_UPDATE_JOB_PART,
  };
};

export const inlineUpdateJobPartSuccess = (data) => {
  return {
    type: INLINE_UPDATE_JOB_PART_SUCCESS,
    payload: data,
  };
};

export const inlineUpdateJobPartFail = (error) => {
  return {
    type: INLINE_UPDATE_JOB_PART_FAIL,
    payload: error,
  };
};

export const editJobPartMany = () => {
  return {
    type: EDIT_JOB_PART_MANY,
  };
};

export const editJobPartManySuccess = (data) => {
  return {
    type: EDIT_JOB_PART_MANY_SUCCESS,
    payload: data,
  };
};

export const editJobPartManyFail = (error) => {
  return {
    type: EDIT_JOB_PART_MANY_FAIL,
    payload: error,
  };
};


// Get Job kit part START
export const getJobKitPart = () => {
  return {
    type: GET_JOB_KIT_PART,
  };
};

export const getJobKitPartSuccess = (data) => {
  return {
    type: GET_JOB_KIT_PART_SUCCESS,
    payload: data,
  };
};

export const getJobKitPartFail = (error) => {
  return {
    type: GET_JOB_KIT_PART_FAIL,
    payload: error,
  };
};

// Get Job kit part END

// Get Job kit labour START
export const getJobKitLabour = () => {
  return {
    type: GET_JOB_KIT_LABOUR,
  };
};

export const getJobKitLabourSuccess = (data) => {
  return {
    type: GET_JOB_KIT_LABOUR_SUCCESS,
    payload: data,
  };
};

export const getJobKitLabourFail = (error) => {
  return {
    type: GET_JOB_KIT_LABOUR_FAIL,
    payload: error,
  };
};

// Get Job kit labour END


// Save Job kit part START
export const saveJobKitPart = () => {
  return {
    type: SAVE_JOB_KIT_PART,
  };
};

export const saveJobKitPartSuccess = (data) => {
  return {
    type: SAVE_JOB_KIT_PART_SUCCESS,
    payload: data,
  };
};

export const saveJobKitPartFail = (error) => {
  return {
    type: SAVE_JOB_KIT_PART_FAIL,
    payload: error,
  };
};
// Save Job kit labour END

// Delete Job kit part START
export const deleteJobKitPart = () => {
  return {
    type: DELETE_JOB_KIT_PART,
  };
};

export const deleteJobKitPartSuccess = (data) => {
  return {
    type: DELETE_JOB_KIT_PART_SUCCESS,
    payload: data,
  };
};

export const deleteJobKitPartFail = (error) => {
  return {
    type: DELETE_JOB_KIT_PART_FAIL,
    payload: error,
  };
};
// Delete Job kit labour END


// Inline Update Job kit labour START
export const inlineUpdateJobKitPart = () => {
  return {
    type: INLINE_UPDATE_JOB_KIT_PART,
  };
};

export const inlineUpdateJobKitPartSuccess = (data) => {
  return {
    type: INLINE_UPDATE_JOB_KIT_PART_SUCCESS,
    payload: data,
  };
};

export const inlineUpdateJobKitPartFail = (error) => {
  return {
    type: INLINE_UPDATE_JOB_KIT_PART_FAIL,
    payload: error,
  };
};
// Inline Update Job kit labour END

export const editJobKitPartMany = () => {
  return {
    type: EDIT_JOB_KIT_PART_MANY,
  };
};

export const editJobKitPartManySuccess = (data) => {
  return {
    type: EDIT_JOB_KIT_PART_MANY_SUCCESS,
    payload: data,
  };
};

export const editJobKitPartManyFail = (error) => {
  return {
    type: EDIT_JOB_KIT_PART_MANY_FAIL,
    payload: error,
  };
};

export const editJobKitLabourMany = () => {
  return {
    type: EDIT_JOB_KIT_LABOUR_MANY,
  };
};

export const editJobKitLabourManySuccess = (data) => {
  return {
    type: EDIT_JOB_KIT_LABOUR_MANY_SUCCESS,
    payload: data,
  };
};

export const editJobKitLabourManyFail = (error) => {
  return {
    type: EDIT_JOB_KIT_LABOUR_MANY_FAIL,
    payload: error,
  };
};

// Inline Update Job kit labour START
export const inlineUpdateJobKitLabour = () => {
  return {
    type: INLINE_UPDATE_JOB_KIT_LABOUR,
  };
};

export const inlineUpdateJobKitLabourSuccess = (data) => {
  return {
    type: INLINE_UPDATE_JOB_KIT_LABOUR_SUCCESS,
    payload: data,
  };
};

export const inlineUpdateJobKitLabourFail = (error) => {
  return {
    type: INLINE_UPDATE_JOB_KIT_LABOUR_FAIL,
    payload: error,
  };
};
// Inline Update Job kit labour END

// get burson PO START
export const getPOPartList = () => {
  return {
    type: GET_PO_PART_LIST,
  };
};

export const getPOPartListSuccess = (data) => {
  return {
    type: GET_PO_PART_LIST_SUCCESS,
    payload: data,
  };
};

export const getPOPartListFail = (error) => {
  return {
    type: GET_PO_PART_LIST_FAIL,
    payload: error,
  };
};
// get burson PO END


// get burson PO START
export const getBursonParts = () => {
  return {
    type: GET_BURSON_PARTS,
  };
};

export const getBursonPartsSuccess = (data) => {
  return {
    type: GET_BURSON_PARTS_SUCCESS,
    payload: data,
  };
};

export const getBursonPartsFail = (error) => {
  return {
    type: GET_BURSON_PARTS_FAIL,
    payload: error,
  };
};
// get burson PO END

// create burson PO START
export const createBursonPO = () => {
  return {
    type: CREATE_BURSON_PO,
  };
};

export const createBursonPOSuccess = (data) => {
  return {
    type: CREATE_BURSON_PO_SUCCESS,
    payload: data,
  };
};

export const createBursonPOFail = (error) => {
  return {
    type: CREATE_BURSON_PO_FAIL,
    payload: error,
  };
};
// create burson PO END

// get repco PO START
export const getRepcoParts = () => {
  return {
    type: GET_REPCO_PARTS,
  };
};

export const getRepcoPartsSuccess = (data) => {
  return {
    type: GET_REPCO_PARTS_SUCCESS,
    payload: data,
  };
};

export const getRepcoPartsFail = (error) => {
  return {
    type: GET_REPCO_PARTS_FAIL,
    payload: error,
  };
};
// get repco PO END