import {
  GET_SYNC_ACCOUNT,
  GET_SYNC_ACCOUNT_SUCCESS,
  GET_SYNC_ACCOUNT_FAIL,
  GET_TAX_RATES,
  GET_TAX_RATES_SUCCESS,
  GET_TAX_RATES_FAIL,
  GET_PAYMENT_PROCESSING_FEES,
  GET_PAYMENT_PROCESSING_FEES_SUCCESS,
  GET_PAYMENT_PROCESSING_FEES_FAIL,
  GET_PAYMENT_TERMS,
  GET_PAYMENT_TERMS_SUCCESS,
  GET_PAYMENT_TERMS_FAIL,
  EDIT_PAYMENT_TERM,
  EDIT_PAYMENT_TERM_SUCCESS,
  EDIT_PAYMENT_TERM_FAIL,
  ADD_PAYMENT_TERM,
  ADD_PAYMENT_TERM_SUCCESS,
  ADD_PAYMENT_TERM_FAIL,
  UPDATE_PAYMENT_TERM,
  UPDATE_PAYMENT_TERM_SUCCESS,
  UPDATE_PAYMENT_TERM_FAIL,
  UPDATE_PAYMENT_PROCESSING_FEES,
  UPDATE_PAYMENT_PROCESSING_FEES_SUCCESS,
  UPDATE_PAYMENT_PROCESSING_FEES_FAIL,
  WORKSHOP_TAX_UPDATE,
  WORKSHOP_TAX_UPDATE_SUCCESS,
  WORKSHOP_TAX_UPDATE_FAIL,
  ADD_WORKSHOP_OTHER_DETAILS,
  ADD_WORKSHOP_OTHER_DETAILS_SUCCESS,
  ADD_WORKSHOP_OTHER_DETAILS_FAIL,
  ADD_MANY_DEPARTMENT_ACCOUNT,
  ADD_MANY_DEPARTMENT_ACCOUNT_SUCCESS,
  ADD_MANY_DEPARTMENT_ACCOUNT_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  syncData: [],
  TaxRates: null,
  paymentProcessing: null,
  updatePaymentProcessing: null,
  paymentTerms: [],
  editPaymentTerm: {},
  addPaymentTerm: null,
  updatePaymentTerm: null,
  updateTaxRate: null,
  workspaceDetail: null,
  accountDetails: null,
  error: {
    message: "",
  },
};

const ChartsOfAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYNC_ACCOUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SYNC_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        syncData: action.payload,
      };
      break;
    case GET_SYNC_ACCOUNT_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
        syncData: null,
      };
      break;
    case GET_TAX_RATES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_TAX_RATES_SUCCESS:
      state = {
        ...state,
        loading: false,
        TaxRates: action.payload,
      };
      break;
    case GET_TAX_RATES_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
        TaxRates: null,
      };
      break;
    case GET_PAYMENT_PROCESSING_FEES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PAYMENT_PROCESSING_FEES_SUCCESS:
      state = {
        ...state,
        loading: false,
        paymentProcessing: action.payload,
      };
      break;
    case GET_PAYMENT_PROCESSING_FEES_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
        paymentProcessing: null,
      };
      break;
    case GET_PAYMENT_TERMS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PAYMENT_TERMS_SUCCESS:
      state = {
        ...state,
        loading: false,
        paymentTerms: action.payload,
      };
      break;
    case GET_PAYMENT_TERMS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
        paymentTerms: [],
      };
      break;
    case EDIT_PAYMENT_TERM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_PAYMENT_TERM_SUCCESS:
      state = {
        ...state,
        loading: false,
        editPaymentTerm: action.payload,
      };
      break;
    case EDIT_PAYMENT_TERM_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
        editPaymentTerm: null,
      };
      break;
    case ADD_PAYMENT_TERM:
      state = {
        ...state,
        addPaymentFlag: false,
      };
      break;
    case ADD_PAYMENT_TERM_SUCCESS:
      state = {
        ...state,
        addPaymentFlag: true,
        addPaymentTerm: action.payload,
      };
      break;
    case ADD_PAYMENT_TERM_FAIL:
      state = {
        ...state,
        addPaymentFlag: false,
        error: {
          message: action.payload,
        },
        addPaymentTerm: null,
      };
      break;
    case UPDATE_PAYMENT_TERM:
      state = {
        ...state,
        updatePaymentFlag: false,
      };
      break;
    case UPDATE_PAYMENT_TERM_SUCCESS:
      state = {
        ...state,
        updatePaymentFlag: true,
        updatePaymentTerm: action.payload,
      };
      break;
    case UPDATE_PAYMENT_TERM_FAIL:
      state = {
        ...state,
        updatePaymentFlag: false,
        error: {
          message: action.payload,
        },
        updatePaymentTerm: null,
      };
      break;
    case UPDATE_PAYMENT_PROCESSING_FEES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_PAYMENT_PROCESSING_FEES_SUCCESS:
      state = {
        ...state,
        loading: false,
        updatePaymentProcessing: action.payload,
      };
      break;
    case UPDATE_PAYMENT_PROCESSING_FEES_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
        updatePaymentProcessing: null,
      };
      break;
    case WORKSHOP_TAX_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case WORKSHOP_TAX_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        updateTaxRate: action.payload,
      };
      break;
    case WORKSHOP_TAX_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
        updateTaxRate: null,
      };
      break;
    case ADD_WORKSHOP_OTHER_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_WORKSHOP_OTHER_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        workspaceDetail: action.payload,
      };
      break;
    case ADD_WORKSHOP_OTHER_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
        workspaceDetail: null,
      };
      break;

      case ADD_MANY_DEPARTMENT_ACCOUNT:
        state = {
          ...state,
          loading: true,
        };
        break;
      case ADD_MANY_DEPARTMENT_ACCOUNT_SUCCESS:
        state = {
          ...state,
          loading: false,
          accountDetails: action.payload,
        };
        break;
      case ADD_MANY_DEPARTMENT_ACCOUNT_FAIL:
        state = {
          ...state,
          error: {
            message: action.payload,
          },
          loading: false,
          accountDetails: null,
        };
        break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ChartsOfAccountReducer;
