import { apiCall } from "helpers/apiHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import { groupBy } from "lodash";
import { date_format, getCurrency } from "utils/getCurrency";
import moment from "moment";
import { jobReportChartData, jobReportStatsData, jobReportTableData } from "helpers/backendHelper";
import { addComma } from "utils/numberFunction";

/** Single call utilities */

// revunue functions START 
async function getRevenueTableList(request) {
  
  const tableResponse = await jobReportTableData({ payload: request });
  
  const revenueTable =
    tableResponse?.data?.JobSchedules?.map((schedules) => {
      
      let customer_name = schedules?.type === '1' ? schedules?.company_name : schedules?.customer;
      return {
        id: schedules?.id,
        date: moment(schedules?.job_date).format(date_format),
        customer: customer_name,
        job_category: schedules?.job_category,
        job_number: schedules?.job_number,
        job_stage: schedules?.job_stage,
        job_status: schedules?.job_status,
        total: +schedules?.total || 0.00
      };
    }) || [];

  return revenueTable;

  
}

async function getRevenueChartData(request) {

  const chartResponse = await jobReportChartData({ payload: request });

  const obj = {
    jobDates: [],
    totalJobs: [],
    pendingJobs: [],
    InvoicedJobs: [],
    InprogressJobs: [],
    UnassignedJobs: [],
    jobAvg: [],
    revenue: [],
  };

  chartResponse?.data?.JobSchedules?.forEach((schedules) => {

    let jobAvgValue = 0;
      if(schedules?.revenue){
          jobAvgValue = +schedules?.revenue / +schedules?.revenue_jobs;
      }

    obj.jobDates.push(moment(schedules.job_date).format(date_format));
    obj.totalJobs.push(+schedules?.total_jobs || 0);
    obj.pendingJobs.push(+schedules?.pending_jobs || 0);

    obj.InvoicedJobs.push(+schedules?.invoiced_jobs || 0);
    obj.InprogressJobs.push(+schedules?.inprogress_jobs || 0);
    obj.UnassignedJobs.push(+schedules?.unassigned_jobs || 0);
    obj.jobAvg.push(+jobAvgValue || 0);
    obj.revenue.push(+schedules?.revenue || 0);

  });

  return obj;

}
// revunue functions END 



// forecast functions START 
async function getForecastTableList(request) {
  
  const tableResponse = await jobReportTableData({ payload: request });

  const forecastTable =
    tableResponse?.data?.JobSchedules?.map((schedules) => {
      
      let customer_name = schedules?.type === '1' ? schedules?.company_name : schedules?.customer;

      return {
        id: schedules?.id,
        date: moment(schedules?.job_date).format(date_format),
        customer: customer_name,
        job_category: schedules?.job_category,
        job_number: schedules?.job_number,
        job_stage: schedules?.job_stage,
        job_status: schedules?.job_status,
        total: +schedules?.total || 0.00
      };
    }) || [];

  return forecastTable;

  
}

async function getForecastChartData(request) {

  const chartResponse = await jobReportChartData({ payload: request });

  const obj = {
    jobDates: [],
    totalJobs: [],
    pendingJobs: [],
    InvoicedJobs: [],
    InprogressJobs: [],
    UnassignedJobs: [],
    jobAvg: [],
    revenue: [],
  };

  chartResponse?.data?.JobSchedules?.forEach((schedules) => {

    let jobAvgValue = 0;
      if(schedules?.revenue){
          jobAvgValue = +schedules?.revenue / +schedules?.revenue_jobs;
      }

    obj.jobDates.push(moment(schedules.job_date).format(date_format));
    obj.totalJobs.push(+schedules?.total_jobs || 0);
    obj.pendingJobs.push(+schedules?.pending_jobs || 0);

    obj.InvoicedJobs.push(+schedules?.invoiced_jobs || 0);
    obj.InprogressJobs.push(+schedules?.inprogress_jobs || 0);
    obj.UnassignedJobs.push(+schedules?.unassigned_jobs || 0);
    obj.jobAvg.push(+jobAvgValue || 0);
    obj.revenue.push(+schedules?.revenue || 0);

  });

  return obj;

}
// forecast functions END 


// stats report functions START

async function getConversionChartData({ start_date, end_date }) {

  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
    type: "conversion"
  };

  const chartResponse = await jobReportStatsData({ payload: request });

  const conversionChart = chartResponse?.data?.JobSchedules[0];

  const data = [];
  data['totalJobs'] = conversionChart?.total_jobs;
  data['completedjobs'] = conversionChart?.invoiced_jobs;
  data['pendingjobs'] = conversionChart?.pending_jobs;

  let totalColor = "#"+Math.floor(Math.random()*16777215).toString(16);
  let completedColor = "#"+Math.floor(Math.random()*16777215).toString(16);
  let pendingColor = "#"+Math.floor(Math.random()*16777215).toString(16);

  const seriesData = [
    {
      "name": "Total", 'y' : parseInt(conversionChart?.total_jobs), 'color' : totalColor
    },
    {
      "name": "Completed", 'y' : parseInt(conversionChart?.invoiced_jobs), 'color' : completedColor
    },
    {
      "name": "Pending", 'y' : parseInt(conversionChart?.pending_jobs), 'color' : pendingColor
    },  
  ];

  data['series'] = seriesData;

  return data;
}


async function getCategoryChartData({ start_date, end_date }) {

  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
    type: "category"
  };

  const chartResponse = await jobReportStatsData({ payload: request });

  const data = [];
  const seriesData = [];
  
  chartResponse?.data?.JobSchedules?.forEach((schedules) => {

    let categoryColor = "#"+Math.floor(Math.random()*16777215).toString(16);
    const a = {};
    a['name'] = schedules?.name;
    a['y'] = parseInt(schedules?.total_jobs);
    a['color'] = categoryColor
    
    seriesData.push(a);

  });

  data['series'] = seriesData;
  
  return data;
 
}


async function getStatsChartData({ start_date, end_date }) {

  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
    type: "stats"
  };

  const chartResponse = await jobReportStatsData({ payload: request });

  const conversionChart = chartResponse?.data?.JobSchedules[0];

  const data = [];
  const seriesData = [];

  Object.keys(conversionChart).forEach(key => {
      
      if(key !== 'job_total' && key !== 'job_avg' && key !== 'incompleted_jobs') {

        let title = key.replace("_jobs", "");

        if(key === 'readyforinvoice_jobs'){
            title = "Ready Invoice";
        } 

        let statsColor = "#"+Math.floor(Math.random()*16777215).toString(16);

        
        const a = {};
        a['name'] = title.toUpperCase();
        a['y'] = parseInt(conversionChart[key]);
        a['color'] = statsColor

        seriesData.push(a);

      }
      
  })

  
  data['series'] = seriesData;
  
  return data;

}
// stats report functions END

/** Single call utilities End */


// job report by revenue
export async function getJobReportData({ start_date, end_date }) {
  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
    type: "revenue"
  };
  
  const chartData = await getRevenueChartData(request);
  const chartTable = await getRevenueTableList(request);

  return { chartData, chartTable };
}


// job report by forecast
export async function getJobReportForecastData({ start_date, end_date }) {
  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
    type: "forecast"
  };
  
  const forecastChart = await getForecastChartData(request);
  const forecastTable = await getForecastTableList(request);

  return { forecastChart, forecastTable };
}

// job report by stats
export async function getJobReportStatsData({ start_date, end_date }) {
 
  const conversionChart = await getConversionChartData({ start_date, end_date });
  const categoryChart = await getCategoryChartData({ start_date, end_date });
  const statsChart = await getStatsChartData({ start_date, end_date });

  return { conversionChart, categoryChart, statsChart };
}