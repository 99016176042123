import React from "react";
import { Accordion, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getCurrency } from "utils/getCurrency";
import {
  header,
  body,
  p_15,
  acc_header,
  card,
  bnpl,
  disabledClass,
} from "./styles.module.scss";
import card_img from "assets/images/credit-card.svg";
import cash_img from "assets/images/money.svg";
import bottom_icon from "assets/images/bnpl.png";
import cheque_img from "assets/images/cheque.png";
import { usePaymentInfo } from "./PaymentInfoProvider";

export const Row = ({ label, value, red }) => (
  <div className="d-flex justify-content-between">
    <p dangerouslySetInnerHTML={{ __html: label }}></p>
    <p className={red ? "text-danger" : ""}>
      {getCurrency} {value}
    </p>
  </div>
);

const Card = ({ imgSrc, label, onClick, disabled, title }) => (

  <OverlayTrigger
    key="top"
    placement="top"
    overlay={
      <Tooltip id="tooltip-top">
        {title}
      </Tooltip>
    }
  >
    <div title="">
      <div className={`${card} ${disabled ? disabledClass : ""}`} onClick={onClick}>
        <span className="card-img" >
          <img src={imgSrc} alt={label} />
        </span>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
      </div>
    </div>
  </OverlayTrigger>

);

const PaymentModal = ({ show, onClose, onPaymentMethodClick, onConfirmationMethod }) => {
  const summary = usePaymentInfo();  

  let payment_amount = summary?.payment_amount;
  let total_due = summary?.total_due;
  let remain_balance = summary?.remain_balance;
  let remaining_due = summary?.remaining_due;
  let paid_to_date = summary?.paid_to_date;

  if (summary?.tax_invoice === "4") {
    if (summary?.insurance_preview_invoice === 1) {
      payment_amount = summary?.insurance_invoice_total;
      remain_balance = summary?.insurance_invoice_total;
      total_due = summary?.insurance_invoice_total;
    }
    if (summary?.customer_preview_invoice === 1) {
      payment_amount = summary?.customer_invoice_total;
      remain_balance = summary?.customer_invoice_total;
      total_due = summary?.customer_invoice_total;
    }
    if (summary?.customer_invoice_allocation_preview === 1) {
      payment_amount = summary?.customer_invoice_allocation_total;
      remain_balance = summary?.customer_invoice_allocation_total;
      total_due = summary?.customer_invoice_allocation_total;
    }
  }

  const disablePayment = parseFloat(total_due) === 0;

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header className={header}>
        <h4 className="text-center">New Payment</h4>
        <Button className="btn btn-close" variant="light" onClick={onClose}>
          {/* <i className='la la-times font-size-18'></i> */}
        </Button>
      </Modal.Header>
      <Modal.Body className={body}>
        <div data-amount>
          <span>
            {getCurrency} {payment_amount}
          </span>
        </div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0" className="border-0">
            <Accordion.Header className={acc_header}>
              Remaining Balance Due : {getCurrency} {remaining_due}
              <i className="la la-angle-down"></i>
            </Accordion.Header>
            <Accordion.Body className="py-0">
              <div data-panel>
                <div className={`${p_15} py-0`}>
                  <Row label="Total Due" value={total_due} />
                </div>
                <div
                  className={p_15}
                  style={{
                    borderBottom: "1px solid #dee2e6",
                  }}
                >
                  <Row
                    label="Paid To Date"
                    value={paid_to_date || "0.00"}
                  />
                  <Row label="Payment Amount" value={payment_amount} />
                </div>
                <div className={`${p_15} pb-0`}>
                  <Row
                    red
                    label="Remaining Balance"
                    value={remain_balance || "0.00"}
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div
          className="d-flex justify-content-center"
          style={{ margin: "1px 0" }}
        >
          <Card
            disabled={disablePayment}
            imgSrc={card_img}
            title={disablePayment ? "$0.00 Cash Only" : "Card Payment"}
            label="Card"
            onClick={() =>
              !disablePayment && onPaymentMethodClick?.("card-payment")
            }
          />
          <Card
            // disabled={disablePayment}
            imgSrc={cash_img}
            label="Cash"
            title="Cash Payment"
            onClick={() => {
              if (parseFloat(total_due) === 0) {
                onConfirmationMethod();
              } else {
                onPaymentMethodClick?.("cash-payment")
              }
            }
            }
          />
          <Card
            disabled={disablePayment}
            imgSrc={bottom_icon}
            title={disablePayment ? "$0.00 Cash Only" : "BNPL Payment"}
            label="BNPL Payment"
            onClick={() =>
              !disablePayment && onPaymentMethodClick?.("bnpl-payment")
            }
          />
          <Card
            disabled={disablePayment}
            imgSrc={cheque_img}
            title={disablePayment ? "$0.00 Cash Only" : "Cheque Payment"}
            label="Cheque"
            onClick={() =>
              !disablePayment && onPaymentMethodClick?.("cheque-payment")
            }
          />
        </div>
        {/* <div
          className={`${bnpl} ${disablePayment ? disabledClass : ""}`}
          onClick={() =>
            !disablePayment && onPaymentMethodClick?.("bnpl-payment")
          }
        >
          <span>
            <img src={bottom_icon} alt="" />
          </span>
          <p>BNPL Payments</p>
        </div> */}
      </Modal.Body>
      <Modal.Footer className="text-center d-block">
        <p>Accept payments online or in-person with FiiViQ Payments.</p>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentModal;
