import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getHQSuppliersCount,
  getHQSuppliersList,
} from "../../../../../store/Contact/actions";
import "../../style.scss";
import CommonListLayout from "./common/ListLayout";
import DeleteRecords from "utils/deleteRecords";
import { deleteHQSupplier } from "helpers/backendHelper";

const HeadQuartersSuppliersList = () => {
  document.title = "5iQ | Suppliers";
  const [selectedWorkshop, setSelectedWorkshop] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState([]);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  // const workshop_id = window.atob(storedData.workshop_id);
  const hq_id = useMemo(() => window.atob(storedData.hq_id), [storedData]);

  const { loading, hqSupplierList, hqSupplierCount, supplierSearch } =
    useSelector((state) => state.Contact);

  const deleteMsg = "Supplier deleted successfully";
  const deletePayloadData = {
    payload: {
      action_type: "delete",
      id: selectedRow,
    },
  };

  const searchConditions = (search) => {
    return {
      "name LIKE": `%${search}%`,
      "email LIKE": `%${search}%`,
      "phone LIKE": `%${search}%`,
      "suburb LIKE": `%${search}%`,
      "primary_firstname LIKE": `%${search}%`,
      "primary_lastname LIKE": `%${search}%`,
    };
  };

  const loadData = useCallback(
    (page = 1, pageLimit = 10, searchTerm = "") => {
      const conditions = {
        head_quarters_id: hq_id,
        is_deleted: 0,
        "name NOT IN ": ["Repco Supplier", "Burson Supplier", "BNT Supplier"],
      };

      if (searchTerm !== "") {
        conditions["OR"] = searchConditions(searchTerm.trim());
      }

      let loadDataPayload = {
        get: "all",
        conditions,
        limit: pageLimit,
        page: page,
        order: {
          name: "asc",
        },
        fields: [
          "name",
          "id",
          "primary_firstname",
          "primary_lastname",
          "email",
          "phone",
          "suburb",
          // "workshop_id",
          "head_quarters_id",
        ],
      };

      dispatch(getHQSuppliersList(loadDataPayload));
    },
    [hq_id, dispatch]
  );

  const loadCount = useCallback(
    (searchTerm = "") => {
      const conditions = {
        head_quarters_id: hq_id,
        is_deleted: 0,
        "name NOT IN ": ["Repco Supplier", "Burson Supplier", "BNT Supplier"],
      };

      if (searchTerm !== "") {
        conditions["OR"] = searchConditions(searchTerm.trim());
      }

      let loadDataPayload = {
        get: "all",
        conditions,
        order: {
          name: "asc",
        },
        fields: {
          COUNT: "COUNT(HeadQuartersSuppliers.id)",
        },
      };

      dispatch(getHQSuppliersCount(loadDataPayload));
    },
    [dispatch, hq_id]
  );

  useEffect(() => {
    loadData(currentPage, sizePerPage, search);
    loadCount(search);
  }, [currentPage, dispatch, hq_id, loadData, search, sizePerPage, loadCount]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(supplierSearchSuccess({}));
  //   };
  // }, []);

  const gotoEditSupplier = (e, column, columnIndex, row, rowIndex) => {
    navigate(`/edit-supplier/${row.id}`);
  };

  const columns = [
    {
      dataField: "name",
      text: "Supplier Name",
      events: {
        onClick: gotoEditSupplier,
      },
    },

    {
      dataField: "primary_firstname",
      text: "Contact Name",
      events: {
        onClick: gotoEditSupplier,
      },
      formatter: (cell, row) => {
        return `${row.primary_firstname || " "} ${row.primary_lastname || ""}`;
      },
    },
    {
      dataField: "email",
      text: "Email",
      events: {
        onClick: gotoEditSupplier,
      },
    },

    {
      dataField: "phone",
      text: "Contact Number",
      events: {
        onClick: gotoEditSupplier,
      },
    },
    {
      dataField: "suburb",
      text: "Suburb",
      events: {
        onClick: gotoEditSupplier,
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              {/* <li>
                <Link to={`/get_branch/${row.id}`} className="" title="Branch">
                  <i className=" fa fa-file-text text-info font-size-20"></i>
                  <span>Branch</span>
                </Link>
              </li> */}

              <li>
                <Link to={`/edit-supplier/${row.id}`} className="" title="EDIT">
                  <i className="fa fa-pencil text-info"></i>
                  <span>Edit</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setSelectedRow(row.id);
                  setShowDeleteModal(true);
                }}
              >
                <Link to="#" className="delete-link" title="DELETE">
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </Link>
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  // const checkBox = {
  //   mode: "checkbox",
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     if (isSelect) {
  //       setSelectedRow([...selectedRow, row.id]);
  //     } else {
  //       let rowSelected = [...selectedRow];

  //       const index = rowSelected.indexOf(row.id);
  //       if (index > -1) {
  //         rowSelected.splice(index, 1);
  //       }
  //       setSelectedRow(rowSelected);
  //     }
  //   },
  //   onSelectAll: (isSelected) => {
  //     if (isSelected) {
  //       let ids = [];
  //       for (var key = 0; key < sizePerPage; key++) {
  //         if (customerData[key] !== undefined) ids.push(customerData[key].id);
  //       }
  //       setSelectedRow(ids);
  //     } else {
  //       setSelectedRow([]);
  //     }
  //   },
  //   selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         ref={(input) => {
  //           if (input) input.indeterminate = indeterminate;
  //         }}
  //         checked={rest.checked}
  //         name={rest.name}
  //         id={rest.id}
  //         type="checkbox"
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  //   selectionRenderer: ({ mode, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         id={rest.id}
  //         checked={rest.checked}
  //         type={mode}
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  // };

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
    loadData(page, sizePerPage);
  };

  const handleSearch = (e) => {
    setCurrentPage(1);
    setSearch(e);
  };

  useEffect(() => {
    setTotalRecords(hqSupplierCount);
  }, [hqSupplierCount]);

  useEffect(() => {
    if (hqSupplierList.length !== 0) {
      setCustomerData(hqSupplierList);
    } else if (hqSupplierList.length === 0) {
      setCustomerData([]);
    }
  }, [hqSupplierList]);

  return (
    <>
      <CommonListLayout
        loading={loading}
        totalRecords={totalRecords}
        searchQuery={search}
        onSearchQueryChange={handleSearch}
        columns={columns}
        rows={customerData}
        // selectRowHandler={checkBox}
        tableChangeHandler={onTableChange}
        currentPage={currentPage}
        sizePerPage={sizePerPage}
        remotePagination={true}
        selectedWorkshop={selectedWorkshop}
        setSelectedWorkshop={setSelectedWorkshop}
        type={"headQuartersSupplier"}
      />
      {showDeleteModal && (
        <DeleteRecords
          showDeleteModal={setShowDeleteModal}
          refreshData={() => {
            loadData()
            loadCount()
          }}
          api={deleteHQSupplier}
          selectedRow={selectedRow}
          message={deleteMsg}
          payloadData={deletePayloadData}
        />
      )}
    </>
  );
};

export default HeadQuartersSuppliersList;
