import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  addPartToList,
  removePartFromList,
  removePOPartFromList,
  updatePartToList,
} from "store/parts/actions";
import DeleteModal from "utils/DeleteModal";
import { getSessionData } from "utils/getWorkshopId";
import { addComma } from "utils/numberFunction";
import { decimalRegex } from "utils/validationRegex";
import CreateNewPart from "./CreateNewPart";
import PriceDetailModal from "./PriceDetailModal";
import Typeahead from "./Typeahead";
import { Link, useSearchParams } from "react-router-dom";
import { RESET_PARTS } from "store/parts/actionTypes";
import { SettingProvider } from "./SettingProvider";

const POPartsListTable = ({
  onAddPartToList,
  searchPartSelected,
  getKitDetail,
  hiddenParts,
  deleteItem,
  updateApi,
  settings,
  supplierID = 0,
  reset = true,
}) => {
  const { parts, testPart } = useSelector((state) => state.PartReducer);
  const dispatch = useDispatch();
  const [showNewPartModal, setShowNewPartModal] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [kitUpdateRow, setKitUpdateRow] = useState(null);
  const [searchPartName, setSearchPartName] = useState();
  const [searchStatus, setSearchStatus] = useState(false);

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const currencySymbol = storedData.currency;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    return () => {
      if (reset && searchParams.get("id") === null) {
        dispatch({ type: RESET_PARTS });
      }
    };
  }, []);

  const changePartRow = (e, row, key) => {
    // setShow(false);
    const obj = { ...row };
    obj[key] = +e;
    const part_gst = +getSessionData("part_gst");
    // obj.cost_price = "0.00";
    let gst_fraction = part_gst / 100;
    if (obj.is_gst) {
      obj.sell_price =
        parseFloat(obj.retail_price) / (1 + gst_fraction).toFixed(2);
    } else {
      obj.sell_price = parseFloat(obj.retail_price).toFixed(2);
    }
    obj.profit_price = (+obj.sell_price - +obj.cost_price).toFixed(2);
    obj.total = (parseFloat(obj.qty) * parseFloat(obj.retail_price)).toFixed(2);
    obj.price = (parseFloat(obj.qty) * parseFloat(obj.retail_price)).toFixed(2);
    dispatch(updatePartToList(obj));
    // setTimeout(() => {
    //   setShow(true);
    //  })
  };

  const cols = [
    {
      dataField: "id",
      text: "",
      sort: true,
      style: { width: "20px" },
      formatter: () => {
        return (
          <div>
            <i className="fa fa-arrows"></i>
          </div>
        );
      },
    },
    {
      dataField: "part_number",
      text: "Part No.",
      sort: true,
      hidden: hiddenParts,
      style: { width: "20%" },
      formatter: (cell, row) => {
        return (
          <Form.Group className="form-group disable d-flex align-center no-wrap">
            {row.is_carried_over_parts === "1" && (
              <span className="co-tag mr-5">CO</span>
            )}
            <Form.Control type="text" disabled defaultValue={cell} />
          </Form.Group>
        );
      },
    },
    {
      dataField: "part_name",
      text: "Part Name",
      sort: true,
      formatter: (cell, row) => {
        return (
          <Form.Group className="part-name ">
            {row.part_name === "" ? (
              <>
                <div id="typeahead">
                  {/* <Typeahead onPartClick={onNewPartAddToList} /> */}
                  <Typeahead
                    createNewPart={(e) => {
                      setShowNewPartModal(true);
                      setSearchPartName(e);
                    }}
                    onPartClick={onNewPartAddToList}
                    setSearchStatus={setSearchStatus}
                  />
                </div>

                {searchStatus &&
                  <span className="searching-text"> Searching ... </span>
                }

                <button
                  type="button"
                  className="d-flex align-center"
                  onClick={() => {
                    setShowNewPartModal(true);
                  }}
                >
                  <i className="fa fa-plus"></i>
                  <span>create new part</span>
                </button>
              </>
            ) : (
              <Form.Control
                type="text"
                // readOnly
                disabled={row.is_carried_over_parts === "1" ? true : false}
                defaultValue={cell}
              />
            )}
          </Form.Group>
        );
      },
    },
    {
      dataField: "cost_price",
      text: "Cost",
      sort: true,
      style: { width: "90px" },
      formatter: (cell, row) => {
        return (
          <Form.Group className="form-group">
            {row.part_name === "" ? (
              <>
                <Form.Control type="text" className="small-input" readOnly disabled />
              </>
            ) : (
              <>
                <Form.Control type="text" className="small-input" readOnly value={addComma(cell)} />
              </>
            )}
          </Form.Group>
        );
      },
    },
    {
      dataField: "qty",
      text: "Qty",
      sort: true,
      style: { width: "90px" },
      formatter: (cell, row) => {
        let disabled = false;
        if (row.is_carried_over_parts === "1") {
          disabled = true;
        } else {
          if (row.part_name === "") {
            disabled = true;
          }
        }
        return (
          <Form.Group className="form-group disable">
            <Form.Control
              type="text"
              className="small-input"
              disabled={disabled}
              defaultValue={cell}
              onChange={(e) => {
                if (!decimalRegex.test(e.target.value)) return;
                // changePartRow(e.target.value, row, "qty");
              }}
              onBlur={(e) => {
                if (decimalRegex.test(e.target.value)) {
                  changePartRow(e.target.value, row, "qty");
                } else {
                  changePartRow(1, row, "qty");
                }
              }}
            />
          </Form.Group>
        );
      },
    },
    {
      dataField: "total",
      text: "Sub Total",
      sort: true,
      style: { width: "90px" },
      formatter: (cell, row) => {
        let disabled = false;
        if (row.is_carried_over_parts === "1") {
          disabled = true;
        } else {
          if (row.part_name === "") {
            disabled = true;
          }
        }

        let totalPrice = "";
        if (row.qty > 0) {
          totalPrice = parseFloat(row.cost_price) * row.qty;
        }

        return (
          <Form.Group className="form-group disable">
            <Form.Control
              type="text"
              disabled={disabled}
              className="small-input"
              id={"total_sell_" + row.id}
              readOnly
              value={row.part_name === "" ? "" : addComma(totalPrice)}
            // defaultValue={addComma(totalPrice)}
            />
          </Form.Group>
        );
      },
    },
    {
      dataField: "more",
      text: "",
      sort: true,
      style: { width: "25px" },
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            {row.part_name === "" ? (
              <>
                <Link style={{ pointerEvents: 'none' }} to="/" readOnly disabled className="remove-icon align-center justify-center">
                  <i className="fa fa-minus"></i>
                </Link>
              </>
            ) : (
              <>
                <a
                  href="#delete"
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteRow(row);
                  }}
                  className="remove-icon align-center justify-center delete-link"
                  title="DELETE"
                >
                  <i className="fa fa-minus"></i>
                </a>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const blankList = {
    id: 0,
    more: "",
    part_name: "",
    part_number: "",
    qty: "",
    retail_price: "",
    total: "",
  };

  const [list, setList] = useState([blankList]);

  const [columns, setColumns] = useState(cols);

  useEffect(() => {
    if (columns.length !== 0) {
      const newColumns = columns.map((column) => {
        if (column.dataField !== "part_number") return column;
        return {
          ...column,
          hidden: hiddenParts,
        };
      });
      setColumns(newColumns);
    }
  }, [hiddenParts]);

  useEffect(() => {
    setList([...parts, blankList]);
  }, [parts]);

  const onNewPartAddToList = (part) => {
    if (!part?.product) {
      let product = [];
      product['purchase_orders_parts'] = [];
      part['product'] = product;
    }
    onAddPartToList?.(part, () => {
      dispatch(addPartToList(part));
    });
  };
  if (columns.length === 0) return <></>;

  return (
    <>
      <SettingProvider value={{ ...settings, setSearchPartName, setSearchStatus }}>
        <div className="draggable-table">
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={list}
            columns={columns}
          ></BootstrapTable>

          <CreateNewPart
            savePartDetail={(e) => {
              const obj = {
                ...e,
                gst_percentage: "",
                is_gst: e.gst_price > 0 ? 1 : 0,
                is_kit: 0,
                total: e.retail_price,
                label: e.name,
                margin_markup: "",
                more: "",
                part_name: e.name,
                part_number: e.product_number,
                qty: 1,
              };
              onNewPartAddToList(obj);
            }}
            supplierID={supplierID}
            handleClose={() => { setShowNewPartModal(false); setSearchPartName("") }}
            show={showNewPartModal}
            addType="purchase_order"
            searchPartName={searchPartName}
          />
          {deleteRow && (
            <DeleteModal
              showDeleteModal={!!deleteRow}
              setShowDeleteModal={() => {
                setDeleteRow(null);
              }}
              api={() => {
                deleteItem?.(deleteRow, () => {
                  if (deleteRow?.main_id && typeof deleteRow?.main_id !== undefined) {
                    dispatch(removePartFromList(deleteRow.main_id));
                  } else {
                    dispatch(removePOPartFromList(deleteRow.id));
                  }
                  setDeleteRow(null);
                });
              }}
              msg={"Delete this record?"}
            />
          )}

          {!!editRow && (
            <PriceDetailModal
              show={!!editRow}
              action={"part"}
              handleClose={() => {
                setEditRow(null);
              }}
              priceDetail={editRow}
              updatePriceDetail={(data) => {
                let object = parts.find((c) => c.id === data.id);
                object = {
                  ...object,
                  ...data,
                };
                updateApi?.(object, () => {
                  dispatch(updatePartToList(object));
                  setEditRow(null);
                });
              }}
            />
          )}
          {/* {parts.map(c=>c.total)} */}
        </div>
      </SettingProvider>
    </>
  );
};
export default POPartsListTable;
