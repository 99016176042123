const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;

export async function get(url, config) {
  const apiurl = `${REACT_APP_APP_URL}/` + url;
  try {
    let response = await fetch(apiurl, {
      method: "GET",
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          window.btoa(
            "marksp@fiiviq.com:01fc90ec159bc788525050a677590d34c52c4d50"
          ),
      },
      body: JSON.stringify(config.payload),
    });
    return response.json();
  } catch (error) {
  }
}

export async function post(url, config) {
  const apiurl = `${REACT_APP_APP_URL}/` + url;
  try {
    let response = await fetch(apiurl, {
      //mode: 'no-cors',
      method: "POST",
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          window.btoa(
            "marksp@fiiviq.com:01fc90ec159bc788525050a677590d34c52c4d50"
          ),
      },
      body: JSON.stringify(config.payload),
    });
    return response.json();
  } catch (error) {
  }
}

export async function apiCall(url, config, method = "GET", headers = false) {
  const apiurl = `${REACT_APP_APP_URL}/` + url;
  const options = {
    method: method,
  };
  let storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const token =
    storedData?.token ||
    window.btoa("marksp@fiiviq.com:01fc90ec159bc788525050a677590d34c52c4d50");
  if (headers) {
    options.headers = {
      // 'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/json",
      Authorization: "Basic " + token,
    };
  } else {
    options.headers = {
      // 'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/json",
      Authorization: "Basic " + token,
    };
  }
  if (config !== null) {
    options.body = JSON.stringify(config.payload);
  }
  let response = await fetch(apiurl, options);

  return response.json();
}

export async function thirdPartyApiCall(
  url,
  config,
  method = "GET",
  headers = false
) {
  const apiurl = `${REACT_APP_APP_URL}/` + url;
  const options = {
    method: method,
  };
  let storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let token = window.atob(storedData.token);
  if (headers) {
    options.headers = {
      // 'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/json",
      Authorization: "Basic " + window.btoa(token),
    };
  } else {
    options.headers = {
      // 'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/json",
      Authorization: "Basic " + token,
    };
  }
  if (config !== null) {
    options.body = JSON.stringify(config.payload);
  }
  let response = await fetch(apiurl, options);

  return response.json();
}
