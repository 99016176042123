import Loadable from "react-loadable";

import { Loading } from "../../../common/navigation";

const HQEmployeeList = Loadable({
  loader: () => import("./components/EmployeeList"),
  loading: Loading,
});
const HQCreateEmployee = Loadable({
  loader: () => import("./components/EmployeeCreate"),
  loading: Loading,
});

const HQEditEmployee = Loadable({
  loader: () => import("./components/EmployeeEdit"),
  loading: Loading,
});

export const routes = [
  {
    path: "/employees",
    exact: true,
    name: "employees",
    element: HQEmployeeList,
  },
  {
    path: "/create-employee",
    exact: true,
    name: "create-employee",
    element: HQCreateEmployee,
  },
  {
    path: "/edit-employee/:id",
    exact: true,
    name: "edit-employee",
    element: HQEditEmployee,
  },
];

export * from "./components";
