import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import RecordPayment from './RecordPayment';
import { cardContent, header } from './styles.module.scss';
import Summary from './Summary';

const Wrapped = (props) => {

    return (
        <Modal show={props.show} onHide={props.onHide} dialogClassName={cardContent}>
            <Modal.Body className='p-0'>
                <div className={header}>

                    <Button className='btn btn-close' variant='light' style={{ top: "5px", right: "5px" }} onClick={props.onHide}>

                    </Button>
                </div>
                <div className="row">
                    <div className="col-6" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}>
                        {props.children}
                        <hr />
                        <RecordPayment />
                    </div>
                    <Summary 
                        selectedCard={props.selectedCard}
                        checked={props.checked}
                        selectedPaymentFees={props.selectedPaymentFees}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Wrapped