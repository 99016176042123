import React, { useEffect } from "react";
import Swal from "sweetalert2";

const CompleteWarningModal = ({
  setShowCompleteModal,
  showCompleteModal,
  msg,
  api,
  icon,
  closeModal
}) => {
//   const handleClose = () => setShowCompleteModal(false);
  const handleClose = async () => {
    return closeModal();
  };
  const deleteRecords = async () => {
    return api();
  };

  useEffect(() => {
    if (showCompleteModal) {
      Swal.fire({
        title: msg,
        text: "",
        icon: icon || "error",
        position: 'top',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: "#65cc88",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes",
        customClass: {
          container: 'clock-out-dialog',
        },
        preConfirm: () => {
          if (showCompleteModal) {
            deleteRecords();
            handleClose();
            setShowCompleteModal(false);
            return false;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) deleteRecords();
        else handleClose(); setShowCompleteModal(false);
      });
    }

    return () => {
      Swal.close();
    };
  }, [showCompleteModal]);

  return "";
};
export default CompleteWarningModal;
