import * as ACTION from "./actionTypes";

const initialState = {
  raisedInvoices: {
    loading: false,
    error: "",
    list: [],
    count: 0,
    invoiceCount: 0,
    saleInvoiceCount: 0,
    invoiceDeleteStatus: "",
  },
  searchInvoice:{
    // loading: false,
    // error: "",
    // list: [],
    // count: 0
  },
  completedInvoices: {
    list: [],
    count: 0,
    loading: false,
    invoiceCount: 0,
    saleInvoiceCount: 0,
    invoiceDeleteStatus: "",
    error: "",
  },
  invoicePayments: {
    list: [],
    count: 0,
    loading: false,
    error: "",
  },
  invoiceCredits: {
    list: [],
    count: 0,
    loading: false,
    error: "",
  },
  invoiceStatements:{
    list: [],
    count: 0,
    pivot_count:0,
    loading: false,
    error: "",
  },
  displayPart: false,
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_RAISED_INVOICES_COUNT:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: true,
        },
      };
    case ACTION.GET_RAISED_INVOICES_COUNT_SUCCESS:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.GET_RAISED_INVOICES_COUNT_FAIL:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          error: action.payload,
          loading: false,
        },
      };
    case ACTION.GET_RAISED_INVOICES:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: false,
        },
      };
    case ACTION.GET_RAISED_INVOICES_SUCCESS:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.GET_RAISED_INVOICES_FAIL:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.SEARCH_INVOICE:
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          loading: true,
        },
      };
    case ACTION.SEARCH_INVOICE_SUCCESS:
      return {
        ...state,
        searchInvoice: {
          // ...state.searchInvoice,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.SEARCH_INVOICE_FAIL:
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.SEARCH_RECEIPT:
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          loading: true,
        },
      };
    case ACTION.SEARCH_RECEIPT_SUCCESS:
      return {
        ...state,
        searchInvoice: {
          // ...state.searchInvoice,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.SEARCH_RECEIPT_FAIL:
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.SEARCH_CASHSALE_RECEIPT:
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          loading: true,
        },
      };
    case ACTION.SEARCH_CASHSALE_RECEIPT_SUCCESS:
      return {
        ...state,
        searchInvoice: {
          // ...state.searchInvoice,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.SEARCH_CASHSALE_RECEIPT_FAIL:
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.SEARCH_CASHSALE:
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          loading: true,
        },
      };
    case ACTION.SEARCH_CASHSALE_SUCCESS:
      return {
        ...state,
        searchInvoice: {
          // ...state.searchInvoice,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.SEARCH_CASHSALE_FAIL:
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          error: action.payload,
          loading: false,
        },
      };
    case ACTION.GET_COMPLETED_INVOICES: {
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: false,
        },
      };
    }
    case ACTION.GET_COMPLETED_INVOICES_SUCCESS:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.GET_COMPLETED_INVOICES_FAIL:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          error: action.payload,
          loading: false,
        },
      };
    case ACTION.GET_COMPLETED_INVOICES_COUNT: {
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: true,
        },
      };
    }
    case ACTION.GET_COMPLETED_INVOICES_COUNT_SUCCESS:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.GET_COMPLETED_INVOICES_COUNT_FAIL:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          error: action.payload,
          loading: false,
        },
      };
    case ACTION.GET_INVOICE_PAYMENTS: {
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          loading: false,
        },
      };
    }
    case ACTION.GET_INVOICE_PAYMENTS_SUCCESS:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.GET_INVOICE_PAYMENTS_FAIL:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.GET_INVOICE_CREDITS: {
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          loading: false,
        },
      };
    }
    case ACTION.GET_INVOICE_CREDITS_SUCCESS:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.GET_INVOICE_CREDITS_FAIL:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.GET_INVOICES_STATEMENTS: {
      return {
        ...state,
        invoiceStatements: {
          ...state.invoiceStatements,
          loading: false,
        },
      };
    }
    case ACTION.GET_INVOICES_STATEMENTS_SUCCESS:
      return {
        ...state,
        invoiceStatements: {
          ...state.invoiceStatements,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.GET_INVOICES_STATEMENTS_FAIL:
      return {
        ...state,
        invoiceStatements: {
          ...state.invoiceStatements,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.UPDATE_INVOICE_STATEMENTS: {
      return {
        ...state,
        updateInvoiceStatements: {
          ...state.invoiceStatements,
          loading: true,
        },
      };
    }
    case ACTION.UPDATE_INVOICE_STATEMENTS_SUCCESS:
      return {
        ...state,
        updateInvoiceStatements: {
          ...state.invoiceStatements,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.UPDATE_INVOICE_STATEMENTS_FAIL:
      return {
        ...state,
        updateInvoiceStatements: {
          ...state.invoiceStatements,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.DELETE_RAISED_INVOICES:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: false,
          invoiceDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_RAISED_INVOICES_SUCCESS:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: true,
          invoiceDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_RAISED_INVOICES_FAIL:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: false,
          invoiceDeleteStatus: "failed",
          action_type: action.payload,
        },
      };
    case ACTION.DELETE_COMPLETED_INVOICES:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: false,
          invoiceDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_COMPLETED_INVOICES_SUCCESS:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: true,
          invoiceDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_COMPLETED_INVOICES_FAIL:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: false,
          invoiceDeleteStatus: "failed",
          action_type: action.payload,
        },
      };
    case ACTION.CLEAR_RECEIPT_DETAILS:
      return {
        ...state,
        recieptDetail: {
          loading: false,
          data: null,
        },
      };
    case ACTION.DELETE_PAYMENT_INVOICES:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          loading: false,
          invoiceDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_PAYMENT_INVOICES_SUCCESS:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          loading: true,
          invoiceDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_PAYMENT_INVOICES_FAIL:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          loading: false,
          invoiceDeleteStatus: "failed",
          action_type: action.payload,
        },
      };
    case ACTION.DELETE_CREDIT_INVOICES:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          loading: false,
          invoiceDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_CREDIT_INVOICES_SUCCESS:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          loading: true,
          invoiceDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_CREDIT_INVOICES_FAIL:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          loading: false,
          invoiceDeleteStatus: "failed",
          action_type: action.payload,
        },
      };

    case ACTION.DELETE_STATEMENT_INVOICES:
      return {
        ...state,
        invoiceStatements: {
          ...state.invoiceStatements,
          loading: false,
          invoiceStatementDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_STATEMENT_INVOICES_SUCCESS:
      return {
        ...state,
        invoiceStatements: {
          ...state.invoiceStatements,
          loading: true,
          invoiceStatementDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_STATEMENT_INVOICES_FAIL:
      return {
        ...state,
        invoiceStatements: {
          ...state.invoiceStatements,
          loading: false,
          invoiceStatementDeleteStatus: "failed",
          action_type: action.payload,
        },
      };

    case ACTION.DISPLAY_PARTS:
      return {
        ...state,
        displayPart: action.payload,
      };
    default:
      return state;
  }
};

export default invoiceReducer;
