import React, { useEffect, useState, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";

const VehicleFields = ({
    vehiclesFormData,
    handleVehiclesInputChange,
    setVehiclesFormData,
    VehicleModelData,
    vehicles,
    setVehicleModeldata,
    VehicleSeriesData,
    setVehicleSeriesdata,
    transmissionData,
    lookUpShow,
    handleLookupShow,
    checkVinData,
    checkVinExist,
    checkChasisData,
    checkChasisExist,
    checkEngineData,
    checkEngineExist,
    checkFleetExist,
    checkFleetData,
    fieldShow
}) => {

    const currentYear = parseInt(new Date().getFullYear());

    const cylinderOption = [];
    cylinderOption.push(<option value="">Select Cylinder</option>);
    for (let i = 1; i <= 6; i++) {
        cylinderOption.push(<option value={i}>{i}</option>);
    }

    const seatOption = [];
    seatOption.push(<option value="">Select Seat</option>);
    for (let i = 2; i <= 22; i++) {
        seatOption.push(<option value={i}>{i}</option>);
    }

    const [clearModel, setClearModel] = useState(false)
    const clearModelData = () => {
        setVehicleModeldata([]);
        setClearModel(false)
    }

    const [clearSeries, setClearSeries] = useState(false)
    const clearSeriesData = () => {
        setVehicleSeriesdata([]);
        setClearSeries(false)
    }

    return (
        <>
            {/* First Section START */}
            <div className="form-outer vin-engine-section" >
                {/* Make */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Make</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="make"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.make}
                    >
                        <option value="">Select Make</option>
                        {vehicles.length !== 0 &&
                            vehicles.map(({ assets_make }, index) => (
                                <option value={assets_make} key={index}>
                                    {assets_make}
                                </option>
                            ))}
                    </Form.Select>
                </Form.Group>

                {/* Modal */}
                <Form.Group className="form-group auto-complete-wrapper width-33 mb-3">
                    <label>Model</label>
                    <Form.Control
                        type="text"
                        name="model"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.model}
                        autoComplete="off"
                        placeholder="Search for model"
                    />
                    {VehicleModelData.length !== 0 && vehiclesFormData.model !== "" && (
                        <ul className="auto-complete-list">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={200}
                                className="custom-scrollbar"
                                style={{ width: "100%" }}
                                renderThumbVertical={(props) => (
                                    <div
                                        {...props}
                                        className="thumb-vertical grey-thumb"
                                    />
                                )}
                                onUpdate={() => {
                                    if (clearModel) {
                                        clearModelData()
                                    }
                                }}
                            // universal={true}
                            >
                                {VehicleModelData.map(
                                    ({ assets_model, id }, index) => (
                                        <li
                                            key={index}
                                            name="model"
                                            onClick={() => {
                                                setClearModel(true)
                                                setVehiclesFormData({
                                                    ...vehiclesFormData,
                                                    model: assets_model,
                                                    model_id: id,
                                                })

                                            }
                                            }
                                        >
                                            {assets_model}
                                        </li>
                                    )
                                )}
                            </Scrollbars>
                        </ul>
                    )}
                </Form.Group>

                {/* Series */}
                <Form.Group className="form-group auto-complete-wrapper width-33 mb-3">
                    <label>Series</label>
                    <Form.Control
                        type="text"
                        name="model_series"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.model_series}
                        autoComplete="off"
                        placeholder="Search for series"
                    />
                    {VehicleSeriesData.length !== 0 && vehiclesFormData.model_series !== "" && (
                        <ul className="auto-complete-list">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={200}
                                className="custom-scrollbar"
                                style={{ width: "100%" }}
                                renderThumbVertical={(props) => (
                                    <div
                                        {...props}
                                        className="thumb-vertical grey-thumb"
                                    />
                                )}
                                onUpdate={() => {
                                    if (clearSeries) {
                                        clearSeriesData()
                                    }
                                }}
                            >
                                {VehicleSeriesData.map(
                                    ({ series, id }, index) => (
                                        <li
                                            key={index}
                                            name="model_series"
                                            onClick={() => {
                                                setClearSeries(true)
                                                setVehiclesFormData({
                                                    ...vehiclesFormData,
                                                    model_series: series,
                                                    model_series_id: id,
                                                })
                                            }
                                            }
                                        >
                                            {series}
                                        </li>
                                    )
                                )}
                            </Scrollbars>
                        </ul>
                    )}
                </Form.Group>

                {/* Fuel Type */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Fuel Type</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="fuel_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.fuel_type}
                    >
                        <option value="">Select Fuel Type</option>
                        <option value="PETROL">PETROL</option>
                        <option value="DIESEL">DIESEL</option>
                        <option value="HYBRID">HYBRID</option>
                        <option value="LPG">LPG</option>
                        <option value="ELECTRIC">ELECTRIC</option>
                        <option value="ELEC/DIESEL">ELEC/DIESEL</option>
                        <option value="ELEC/PETROL">ELEC/PETROL</option>
                        <option value="PETROL/LPG">PETROL/LPG</option>
                    </Form.Select>
                </Form.Group>

                {/* Body Type */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Body Type</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="body_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.body_type}
                    >
                        <option value="">Select Body Type</option>
                        <option value="Hatchback">Hatchback</option>
                        <option value="Sedan">Sedan</option>
                        <option value="Wagon">Wagon</option>
                        <option value="Coupe">Coupe</option>
                        <option value="Convertible">Convertible</option>
                        <option value="SUV">SUV</option>
                        <option value="Roadster">Roadster</option>
                        <option value="Utility">Utility</option>
                        <option value="Minivan">Minivan</option>
                        <option value="Pickup Truck">Pickup Truck</option>
                        <option value="Compact">Compact</option>
                        <option value="Off Road">Off Road</option>
                        <option value="Crossover">Crossover</option>
                        <option value="Hardtop">Hardtop</option>
                        <option value="Flat Bed Truck">Flat Bed Truck</option>
                        <option value="Tipper Truck">Tipper Truck</option>
                        <option value="Curtain-Siders Truck">Curtain-Siders Truck</option>
                        <option value="Box Body Truck">Box Body Truck</option>
                        <option value="Road Tankers">Road Tankers</option>
                    </Form.Select>
                </Form.Group>


                <div className="form-group width-33 mb-3 d-flex gap-2">
                    {/* Month */}
                    <div className="p-0 m-0" style={{ maxWidth: 50 }}>
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Month</label>
                            <Form.Control
                                type="text"
                                name="month"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                onBlur={(e) => handleVehiclesInputChange(e, true)}
                                value={vehiclesFormData.month}
                                autoComplete="off"
                            />
                        </Form.Group>
                    </div>
                    <div className="flex-1 p-0">
                        {/* Year */}
                        <Form.Group className="form-group m-0 mw-100">
                            <label>Year</label>
                            <Form.Select
                                aria-label="Default select example"
                                name="year"
                                onChange={(e) => handleVehiclesInputChange(e)}
                                value={vehiclesFormData.year}
                            >
                                <option value="">Select Year</option>
                                {[...Array(currentYear - 1949)].map(
                                    (data, index) => (
                                        <option
                                            value={currentYear - index}
                                            key={index}
                                        >
                                            {currentYear - index}
                                        </option>
                                    )
                                )}
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                {/* Transmission */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Transmission</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="transmission"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.transmission}
                    >
                        <option value="">Select transmission</option>
                        {
                            transmissionData.length !== 0 &&
                            transmissionData.map((trans, index) => (
                                <option key={index} value={trans.id}>{trans.name}</option>
                                // ((vehiclesFormData.asset_type_val == "Caravan") && (trans.name == "Manual" || trans.name == "Automatic" || trans.name == "CVT")) ?
                                //     (<option key={index} value={trans.id}>{trans.name}</option>):
                                //         (
                                //             (vehiclesFormData.asset_type_val != "Caravan") ?
                                //                 (<option key={index} value={trans.id}>{trans.name}</option>):""
                                //         )

                            ))
                        }
                    </Form.Select>
                </Form.Group>

                {/* Drivetrain */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Drivetrain</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="category"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.category}
                    >
                        <option value="">Select Drivetrain</option>
                        <option value="Front Wheel Drive(FWD)">
                            Front Wheel Drive(FWD)
                        </option>
                        <option value="Rear Wheel Drive(RWD)">
                            Rear Wheel Drive(RWD)
                        </option>
                        <option value="4 Wheel Drive(4WD)">
                            4 Wheel Drive(4WD)
                        </option>
                        <option value="All Wheel Drive(AWD)">
                            All Wheel Drive(AWD)
                        </option>
                    </Form.Select>
                </Form.Group>

                {/* Colour */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Colour</label>
                    <Form.Control
                        type="text"
                        name="colour"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.colour}
                    />
                </Form.Group>

                {/* Seat Capacity */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Seat Capacity</label>
                    <Form.Select
                        aria-label="Default select example"
                        name="seat_capacity"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.seat_capacity}
                    >
                        {seatOption}
                    </Form.Select>
                </Form.Group>

                {/* Tyre Size - Front */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Tyre Size - Front</label>
                    <Form.Control
                        type="text"
                        name="tyre_size_front"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.tyre_size_front}
                    />
                </Form.Group>

                {/* Tyre Size - Rear */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Tyre Size - Rear</label>
                    <Form.Control
                        type="text"
                        name="tyre_size_rear"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.tyre_size_rear}
                    />
                </Form.Group>

                {/* Cylinder */}
                <Form.Group className="form-group width-33 mb-3">
                    <Form.Label>Cylinder</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        name="cyliners"
                        value={vehiclesFormData.cyliners}
                        onChange={(e) => handleVehiclesInputChange(e)}
                    >{cylinderOption}</Form.Select>
                </Form.Group>

                {/* Engine Type */}
                <Form.Group className=" form-group width-33 mb-3">
                    <label>Engine Type</label>
                    <Form.Control
                        type="text"
                        name="engine_type"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.engine_type}
                    />
                </Form.Group>

                {/* Engine Size */}
                <Form.Group className="form-group width-33 mb-3">
                    <label>Engine Size</label>
                    <Form.Control
                        type="text"
                        name="engine_size"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.engine_size}
                    />
                </Form.Group>
            </div>

            {/* Second Section Vin / Engine Section START */}
            <div className={`form-outer vin-engine-section mt-2 hidden-form ${fieldShow ? "active" : ""}`}>
                <Form.Group className=" form-group width-50 mb-3">
                    <label>VIN Number</label>
                    <Form.Control
                        type="text"
                        name="vin_number"
                        className={
                            checkVinData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        onBlur={(e) => checkVinExist(e)}
                        value={vehiclesFormData.vin_number}
                    />
                    {checkVinData &&
                        <div className="invalid-feedback">This Vin Number is already registered.</div>
                    }
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <label>Chassis Number</label>
                    <Form.Control
                        type="text"
                        name="chasis_number"
                        className={
                            checkChasisData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        onBlur={(e) => checkChasisExist(e)}
                        value={vehiclesFormData.chasis_number}
                    />
                    {checkChasisData &&
                        <div className="invalid-feedback">
                            This Chassis Number is already registered.
                        </div>
                    }
                </Form.Group>
                <Form.Group
                    className={`form-group width-50 mb-3`}
                >
                    <label>Engine Number</label>
                    <Form.Control
                        type="text"
                        name="engine_number"
                        className={
                            checkEngineData && "form-control is-invalid"
                        }
                        onChange={(e) => handleVehiclesInputChange(e)}
                        // onBlur={(e) => checkEngineExist(e)}
                        value={vehiclesFormData.engine_number}
                    />
                    {checkEngineData &&
                        <div className="invalid-feedback">
                            This Engine Number is already registered.
                        </div>
                    }
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <label>Fleet Number</label>
                    <Form.Control
                        type="text"
                        name="fleet_number"
                        onChange={(e) => handleVehiclesInputChange(e)}
                        value={vehiclesFormData.fleet_number}
                        onBlur={(e) => checkFleetExist(e)}
                        className={
                            checkFleetData && "form-control is-invalid"
                        }
                    />
                    {checkFleetData &&
                        <div className="invalid-feedback">
                            This Fleet Number is already registered.
                        </div>
                    }
                </Form.Group>
            </div>
            {/* Second Section Vin / Engine Section END */}
        </>
    )
}

export default VehicleFields;
