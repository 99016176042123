import { takeLatest, put, call } from "redux-saga/effects";
import {
    GET_VEHICLE_COUNT,
    GET_SERVICE_DUE_COUNT,
    GET_CUSTOMER_AND_APP_COUNT,
    GET_BOOK_IN_SOURCE,
    GET_CUSTOMER_SURVEY,
    GET_NEWS_FEED,
    GET_DASHBOARD_JOB_COUNT,
    GET_DASHBOARD_WEEK_JOB_COUNT,
    GET_DASHBOARD_INVOICE_COUNT,
    GET_DASHBOARD_WEEK_INVOICE_COUNT,
    GET_DASHBOARD_PAYMENT_COUNT,
    GET_DASHBOARD_WEEK_PAYMENT_COUNT,
    UPDATE_CHECK_IN_TIME
} from "./actionTypes";
import {
    getVehicleCountSuccess,
    getVehicleCountFail,
    getServiceDueCountSuccess,
    getServiceDueCountFail,
    getCustomerAndAppCountSuccess,
    getCustomerAndAppCountFail,
    getBookInSourceSuccess,
    getBookInSourceFail,
    getCustomerSurveySuccess,
    getCustomerSurveyFail,
    getNewsFeedSuccess,
    getNewsFeedFail,
    getDashboardJobCountSuccess,
    getDashboardJobCountFail,
    getDashboardWeekJobCountSuccess,
    getDashboardWeekJobCountFail,
    getDashboardInvoiceCountSuccess,
    getDashboardInvoiceCountFail,
    getDashboardWeekInvoiceCountSuccess,
    getDashboardWeekInvoiceCountFail,
    getDashboardPaymentCountSuccess,
    getDashboardPaymentCountFail,
    getDashboardWeekPaymentCountSuccess,
    getDashboardWeekPaymentCountFail,
    updateCheckInTimeSuccess,
    updateCheckInTimeFail,
} from "./actions";

import {
    getVehicleCount,
    getServiceDueCount,
    getCustomerAndAppCount,
    getBookInSource,
    getCustomerSurvey,
    getNewsFeed,
    getDashboardJobCount,
    getDashboardWeekJobCount,
    getDashboardInvoiceCount,
    getDashboardWeekInvoiceCount,
    getDashboardPaymentCount,
    getDashboardWeekPaymentCount,
    updateCheckInTime
} from "../../helpers/backendHelper";

function* onGetVehicleCount(data) {
    try {
        const response = yield call(getVehicleCount, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getVehicleCountSuccess(response.data.Vehicles)
                );
            } else {
                yield put(getVehicleCountFail(response.message));
            }
        } else {
            yield put(getVehicleCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getVehicleCountFail(error.response));
    }
}

function* onGetServiceDueCount(data) {
    try {
        const response = yield call(getServiceDueCount, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getServiceDueCountSuccess(response.data.ServiceReminderHistory)
                );
            } else {
                yield put(getServiceDueCountFail(response.message));
            }
        } else {
            yield put(getServiceDueCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getServiceDueCountFail(error.response));
    }
}

function* onGetCustomerAndAppCount(data) {
    try {
        const response = yield call(getCustomerAndAppCount, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getCustomerAndAppCountSuccess(response.data.Users)
                );
            } else {
                yield put(getCustomerAndAppCountFail(response.message));
            }
        } else {
            yield put(getCustomerAndAppCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getCustomerAndAppCountFail(error.response));
    }
}

function* onGetBookInSource(data) {
    try {
        const response = yield call(getBookInSource, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getBookInSourceSuccess(response.data.JobSchedules)
                );
            } else {
                yield put(getBookInSourceFail(response.message));
            }
        } else {
            yield put(getBookInSourceFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getBookInSourceFail(error.response));
    }
}

function* onGetCustomerSurvey(data) {
    try {
        const response = yield call(getCustomerSurvey, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getCustomerSurveySuccess(response.data.SurveyFeedback)
                );
            } else {
                yield put(getCustomerSurveyFail(response.message));
            }
        } else {
            yield put(getCustomerSurveyFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getCustomerSurveyFail(error.response));
    }
}

function* onGetNewsFeed(data) {
    try {
        const response = yield call(getNewsFeed, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getNewsFeedSuccess(response.data.NewsManagement)
                );
            } else {
                yield put(getNewsFeedFail(response.message));
            }
        } else {
            yield put(getNewsFeedFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getNewsFeedFail(error.response));
    }
}

function* onGetDashboardJobCount(data) {
    try {
        const response = yield call(getDashboardJobCount, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getDashboardJobCountSuccess(response.data.JobSchedules)
                );
            } else {
                yield put(getDashboardJobCountFail(response.message));
            }
        } else {
            yield put(getDashboardJobCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getDashboardJobCountFail(error.response));
    }
}

function* onGetDashboardWeekJobCount(data) {
    try {
        const response = yield call(getDashboardWeekJobCount, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getDashboardWeekJobCountSuccess(response.data.JobSchedules)
                );
            } else {
                yield put(getDashboardWeekJobCountFail(response.message));
            }
        } else {
            yield put(getDashboardWeekJobCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getDashboardWeekJobCountFail(error.response));
    }
}

function* onGetDashboardInvoiceCount(data) {
    try {
        const response = yield call(getDashboardInvoiceCount, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getDashboardInvoiceCountSuccess(response.data.Invoices)
                );
            } else {
                yield put(getDashboardInvoiceCountFail(response.message));
            }
        } else {
            yield put(getDashboardInvoiceCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getDashboardInvoiceCountFail(error.response));
    }
}

function* onGetDashboardWeekInvoiceCount(data) {
    try {
        const response = yield call(getDashboardWeekInvoiceCount, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getDashboardWeekInvoiceCountSuccess(response.data.Invoices)
                );
            } else {
                yield put(getDashboardWeekInvoiceCountFail(response.message));
            }
        } else {
            yield put(getDashboardWeekInvoiceCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getDashboardWeekInvoiceCountFail(error.response));
    }
}

function* onGetDashboardPaymentCount(data) {
    try {
        const response = yield call(getDashboardPaymentCount, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getDashboardPaymentCountSuccess(response.data.InvoicesPaymentsCredits)
                );
            } else {
                yield put(getDashboardPaymentCountFail(response.message));
            }
        } else {
            yield put(getDashboardPaymentCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getDashboardPaymentCountFail(error.response));
    }
}

function* onGetDashboardWeekPaymentCount(data) {
    try {
        const response = yield call(getDashboardWeekPaymentCount, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getDashboardWeekPaymentCountSuccess(response.data.InvoicesPaymentsCredits)
                );
            } else {
                yield put(getDashboardWeekPaymentCountFail(response.message));
            }
        } else {
            yield put(getDashboardWeekPaymentCountFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getDashboardWeekPaymentCountFail(error.response));
    }
}

function* onUpdateCheckInTime(data) {
    try {
        const response = yield call(updateCheckInTime, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    updateCheckInTimeSuccess(response.data.JobSchedules)
                );
            } else {
                yield put(updateCheckInTimeFail(response.message));
            }
        } else {
            yield put(updateCheckInTimeFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(updateCheckInTimeFail(error.response));
    }
}

function* DashboardSaga() {
    yield takeLatest(GET_VEHICLE_COUNT, onGetVehicleCount);
    yield takeLatest(GET_SERVICE_DUE_COUNT, onGetServiceDueCount);
    yield takeLatest(GET_CUSTOMER_AND_APP_COUNT, onGetCustomerAndAppCount);
    yield takeLatest(GET_BOOK_IN_SOURCE, onGetBookInSource);
    yield takeLatest(GET_CUSTOMER_SURVEY, onGetCustomerSurvey);
    yield takeLatest(GET_NEWS_FEED, onGetNewsFeed);
    yield takeLatest(GET_DASHBOARD_JOB_COUNT, onGetDashboardJobCount);
    yield takeLatest(GET_DASHBOARD_WEEK_JOB_COUNT, onGetDashboardWeekJobCount);
    yield takeLatest(GET_DASHBOARD_INVOICE_COUNT, onGetDashboardInvoiceCount);
    yield takeLatest(GET_DASHBOARD_WEEK_INVOICE_COUNT, onGetDashboardWeekInvoiceCount);
    yield takeLatest(GET_DASHBOARD_PAYMENT_COUNT, onGetDashboardPaymentCount);
    yield takeLatest(GET_DASHBOARD_WEEK_PAYMENT_COUNT, onGetDashboardWeekPaymentCount);
    yield takeLatest(UPDATE_CHECK_IN_TIME, onUpdateCheckInTime);
}

export default DashboardSaga;
