import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const CopyQuoteConfirmationPopup = ({
        copyQuoteConfirmation,
        setCopyQuoteConfirmation,
    }) => {

    const handleClose = () => {
        setCopyQuoteConfirmation(false)
    }

    return (
        <>
            <Modal
                className="info-dialog info-timer-dialog"
                size="md"
                show={copyQuoteConfirmation}
                onHide={handleClose}
                centered
            >
                <div className="align-items-center d-flex heading-block justify-center border-bottom border-primary border-teal">
                    <span className="font-size-24 text-primary" > Information </span>
                </div>
                <Modal.Body className="m-0">
                    <div className="text-center ">
                        <p className="font-size-16 mt-2 ">
                            Your Quote has been copied
                        </p>
                        <p className="font-size-16 mt-2 ">
                            Please make sure you update any required details
                        </p>
                    </div>
                    <div className="d-flex justify-center mt-3">
                        <Button
                            variant="outline-secondary"
                            className="btn btn-outline rounded-btn font-size-13"
                            onClick={() => handleClose()}
                        >
                            Okay
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CopyQuoteConfirmationPopup;
