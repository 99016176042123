import { Link, useNavigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Noresultfound from 'assets/images/Noresultfound2.png';
import { format } from 'date-fns';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button'
import CarMakeLogo from 'views/common/UI/CarMakeLogo';

const GloablSearch = (props) => {

  function closeSearchBox(){
    props.setActiveDropdown(0);
  }
  const navigate = useNavigate(); // Navigate URL
  if(props.globalSearchData.isFetching === true){
    
    const searchType = props.searchType;
    
    var customerData = [];
    var supplierData = [];
    var invoiceData = [];
   
    //fetch and set customer data
    if(searchType === 'customer'){
        for(var i=0; i<props.globalSearchData.searchData.length; i++){
            var getData = props.globalSearchData.searchData[i];
            
            if(getData.length > 0){

              //first array
              if(i===0){

                if(getData.length > 0){
                  
                  for(var j = 0; j < getData.length; j++) {
                    var data = [];
                    data['cust_id'] = getData[j].id;
                    data['user_id'] = getData[j].user_id;
                    if(getData[j].type === '1'){
                      data['name'] = getData[j].company_name;
                    } else {
                      data['name'] = `${getData[j].firstname} ${getData[j].lastname}`;
                    }
                    data['mobile'] = `${getData[j].mobile_code} ${getData[j].mobile_number}`;
                    data['email'] = getData[j].user.email;
                    data['url'] = `${'edit_customer/'+getData[j].id}`;

                    var allVehicle = [];
                    if(getData[j].user.vehicle_list.length > 0){
                      for(var p = 0; p < getData[j].user.vehicle_list.length; p++) {
                        var vehicleDetail = getData[j].user.vehicle_list[p];
                        var vehicleData = [];
                        vehicleData['veh_id'] = vehicleDetail.id;
                        vehicleData['rego'] = vehicleDetail.rego;
                        vehicleData['make'] = vehicleDetail.make;
                        vehicleData['model'] = vehicleDetail.model;
                        vehicleData['year'] = vehicleDetail.year;
                        vehicleData['odometer_reading'] = vehicleDetail.odometer_reading;
                        vehicleData['edit_url'] = `${'/edit_vehicle/'+vehicleDetail.id+'/'+vehicleDetail.vehicle_type}`;
                        vehicleData['url'] = `${'/edit_vehicle/'+vehicleDetail.id+'/'+vehicleDetail.vehicle_type}`;
                        
                        // create job or job detail START
                        vehicleData['job_number'] = "Book a Job";  
                        vehicleData['job_url'] = `${'/scheduler/'+vehicleDetail.id}`;
                        vehicleData['job_color'] = "0";
                        vehicleData['job_status'] = "Book a Job";
                        if(vehicleDetail.job_list.length > 0){
                          vehicleData['job_status'] = vehicleDetail?.job_list[0]?.job_status || "";
                          vehicleData['job_number'] = "Job #"+vehicleDetail.job_list[0].job_number;
                          vehicleData['job_url'] = `${'/job_detail/'+vehicleDetail.job_list[0].id}`;
                          vehicleData['job_color'] = "1";
                        }
                        // create job or job detail END 


                        // create quote or quote detail START

                        vehicleData['quote_number'] = "Create Quote";  
                        vehicleData['quote_url'] = `${'/quote_create/'+vehicleDetail.id}`;
                        vehicleData['quote_color'] = "0";
                        vehicleData['quote_status'] = "Create Quote";
                        if(vehicleDetail.quotes_list.length > 0){
                          vehicleData['quote_status'] = vehicleDetail?.quotes_list[0]?.quote_status;
                          if(vehicleDetail.quotes_list[0].quote_status === "Open") {
                            vehicleData['quote_url'] = `${'/edit_quote/'+vehicleDetail.quotes_list[0].id}`;
                          } else {
                            vehicleData['quote_url'] = `${'/view_quote/'+vehicleDetail.quotes_list[0].id}`;
                          }
                          vehicleData['quote_number'] = "Quote #"+vehicleDetail.quotes_list[0].quote_number;
                          vehicleData['quote_url'] = `${'/edit_quote/'+vehicleDetail.quotes_list[0].id}`;
                          vehicleData['quote_color'] = "1";
                        }
                        // create quote or quote detail START

                        allVehicle.push(vehicleData);
                      }
                    }

                    data['vehicle_list'] = allVehicle;

                    customerData.push(data);
                  }

                }

              }


              
              //second array
              if(i===1){

                if(getData.length > 0){

                  for(let j = 0; j < getData.length; j++) {

                    if(getData[j].user.customers_details.length > 0) {
                      
                      let data = [];
                      data['cust_id'] = getData[j].user.customers_details[0].id;
                      data['user_id'] = getData[j].user.customers_details[0].user_id
                      if(getData[j].user.customers_details[0].type === '1'){
                        data['name'] = getData[j].user.customers_details[0].company_name;
                      } else {
                        data['name'] = `${getData[j].user.customers_details[0].firstname} ${getData[j].user.customers_details[0].lastname}`;
                      }
                      data['mobile'] = `${getData[j].user.customers_details[0].mobile_code} ${getData[j].user.customers_details[0].mobile_number}`;
                      data['email'] = getData[j].user.email;
                      data['url'] = `${'edit_customer/'+getData[j].user.customers_details[0].id}`;

                      let allVehicle = [];
                      let vehicleData = [];
                      vehicleData['veh_id'] = getData[j].id;
                      vehicleData['rego'] = getData[j].rego;
                      vehicleData['make'] = getData[j].make;
                      vehicleData['model'] = getData[j].model;
                      vehicleData['year'] = getData[j].year;
                      vehicleData['odometer_reading'] = getData[j].odometer_reading;
                      vehicleData['url'] = `${'/edit_vehicle/'+getData[j].id+'/'+getData[j].vehicle_type}`;

                      // create job or job detail START
                      vehicleData['job_number'] = "Book a Job";  
                      vehicleData['job_url'] = `${'/scheduler/'+getData[j].id}`;
                      vehicleData['job_color'] = "0";
                      vehicleData['job_status'] = "Book a Job";
                      if(getData[j].job_list.length > 0){
                        vehicleData['job_status'] = getData[j].job_list[0].job_status;
                        vehicleData['job_number'] = "Job #"+getData[j].job_list[0].job_number;
                        vehicleData['job_url'] = `${'/job_detail/'+getData[j].job_list[0].id}`;
                        vehicleData['job_color'] = "1";
                      }
                      // create job or job detail END 


                      // create quote or quote detail START

                      vehicleData['quote_number'] = "Create Quote";  
                      vehicleData['quote_url'] = `${'/quote_create/'+getData[j].id}`;
                      vehicleData['quote_color'] = "0";
                      vehicleData['quote_status'] = "Create Quote";
                      if(getData[j].quotes_list.length > 0){
                        vehicleData['quote_status'] = getData[j].quotes_list[0].quote_status;
                        if(getData[j].quotes_list[0].quote_status === "Open") {
                          vehicleData['quote_url'] = `${'/edit_quote/'+getData[j].quotes_list[0].id}`;
                        } else {
                          vehicleData['quote_url'] = `${'/view_quote/'+getData[j].quotes_list[0].id}`;
                        }
                        vehicleData['quote_number'] = "Quote #"+getData[j].quotes_list[0].quote_number;
                        vehicleData['quote_url'] = `${'/edit_quote/'+getData[j].quotes_list[0].id}`;
                        vehicleData['quote_color'] = "1";
                      }
                      // create quote or quote detail START

                      allVehicle.push(vehicleData);
                      data['vehicle_list'] = allVehicle;

                      customerData.push(data);

                    }

                  }

                }
                
              }

              //third array
              if(i===2){

                if(getData.length > 0){
                
                  for(let j = 0; j < getData.length; j++) {
                  
                  let data = [];
                  data['cust_id'] = getData[j].vehicle.user.customers_details[0].id;
                  data['user_id'] = getData[j].vehicle.user.customers_details[0].user_id
                  
                  if(getData[j].vehicle.user.customers_details[0].type === '1'){
                    data['name'] = `${getData[j].vehicle.user.customers_details[0].company_name}`;
                  } else {
                    data['name'] = `${getData[j].vehicle.user.customers_details[0].firstname} ${getData[j].vehicle.user.customers_details[0].lastname}`;
                  }

                  data['mobile'] = `${getData[j].vehicle.user.customers_details[0].mobile_code} ${getData[j].vehicle.user.customers_details[0].mobile_number}`;
                  data['email'] = getData[j].vehicle.user.email;
                  data['url'] = `${'edit_customer/'+getData[j].vehicle.user.customers_details[0].id}`;

                  let allVehicle = [];
                  let vehicleData = [];
                  vehicleData['veh_id'] = getData[j].vehicle.id;
                  vehicleData['rego'] = getData[j].vehicle.rego;
                  vehicleData['make'] = getData[j].vehicle.make;
                  vehicleData['model'] = getData[j].vehicle.model;
                  vehicleData['year'] = getData[j].vehicle.year;
                  vehicleData['odometer_reading'] = getData[j].vehicle.odometer_reading;
                  vehicleData['url'] = `${'/edit_vehicle/'+getData[j].vehicle.id+'/'+getData[j].vehicle.vehicle_type}`;
                  vehicleData['job_number'] = "Job #"+getData[j].job_number;  
                  vehicleData['job_url'] = `${'/job_detail/'+getData[j].id}`;
                  vehicleData['job_status'] = getData[j].job_status;
                  vehicleData['job_color'] = "1";
                  vehicleData['quote_number'] = "Create Quote";  
                  vehicleData['quote_url'] = `${'/quote_create/'+getData[j].vehicle.id}`;
                  vehicleData['quote_color'] = "";
                  vehicleData['quote_status'] = "Create Quote";

                  allVehicle.push(vehicleData);
                  data['vehicle_list'] = allVehicle;

                  customerData.push(data);
                  
                  }

                }
                
              }

              //four array
              if(i===3){
                if(getData.length > 0){

                  for(let j = 0; j < getData.length; j++) {
                    let data = [];
                    data['cust_id'] = getData[j].vehicle.user.customers_details[0].id;
                    data['user_id'] = getData[j].vehicle.user.customers_details[0].user_id
                    // data['name'] = `${getData[j].vehicle.user.customers_details[0].firstname} ${getData[j].vehicle.user.customers_details[0].lastname}`;

                    if(getData[j].vehicle.user.customers_details[0].type === '1'){
                      data['name'] = `${getData[j].vehicle.user.customers_details[0].company_name}`;
                    } else {
                      data['name'] = `${getData[j].vehicle.user.customers_details[0].firstname} ${getData[j].vehicle.user.customers_details[0].lastname}`;
                    }
                    
                    data['mobile'] = `${getData[j].vehicle.user.customers_details[0].mobile_code} ${getData[j].vehicle.user.customers_details[0].mobile_number}`;
                    data['email'] = getData[j].vehicle.user.email;
                    data['url'] = `${'edit_customer/'+getData[j].vehicle.user.customers_details[0].id}`;

                    let allVehicle = [];
                    let vehicleData = [];
                    vehicleData['veh_id'] = getData[j].vehicle.id;
                    vehicleData['rego'] = getData[j].vehicle.rego;
                    vehicleData['make'] = getData[j].vehicle.make;
                    vehicleData['model'] = getData[j].vehicle.model;
                    vehicleData['year'] = getData[j].vehicle.year;
                    vehicleData['odometer_reading'] = getData[j].vehicle.odometer_reading;
                    vehicleData['url'] = `${'/edit_vehicle/'+getData[j].vehicle.id+'/'+getData[j].vehicle.vehicle_type}`;

                    
                    vehicleData['job_number'] = "Book a Job";  
                    vehicleData['job_url'] = `${'/scheduler/'+getData[j].vehicle.id}`;
                    vehicleData['job_status'] = "Book a Job";
                    vehicleData['job_color'] = "";
                    vehicleData['quote_number'] = "Quote #"+getData[j].quote_number;  
                    vehicleData['quote_status'] = getData[j].quote_status;
                    vehicleData['quote_url'] = `${'/edit_quote/'+getData[j].quote_number}`;
                    vehicleData['quote_color'] = "1";

                    allVehicle.push(vehicleData);
                    data['vehicle_list'] = allVehicle;

                    customerData.push(data);
                  }

                }
                            
              }


            }
        }
    }
    
    //fetch and set supplier data
    if(searchType === 'supplier'){
      for(let i=0; i<props.globalSearchData.searchData.length; i++){
        let getData = props.globalSearchData.searchData[i];
        if(getData.length > 0){
          //first array
          if(i===0){
            for(let j = 0; j < getData.length; j++) {
              let data = [];
              data['id'] = getData[j].id;
              data['name'] = getData[j].name;
              data['email'] = getData[j].email;
              data['phone'] = getData[j].phone;
              data['url'] = '/edit_supplier/'+getData[j].id;

              let allPO = [];
              if(getData[j].p_o_list.length > 0){
                for(let p = 0; p < getData[j].p_o_list.length; p++) {
                  let PODetail = getData[j].p_o_list[p];
                  
                  let poData = [];
                  poData['id'] = PODetail['id'];
                  poData['po_number'] = PODetail['po_number'] ? '#'+PODetail['po_number'] : "-" ;
                  poData['date'] = format(new Date(PODetail['created_on']), 'dd-MM-yyyy');
                  poData['status'] = PODetail['po_status'];
                  poData['url'] = '/purchases-edit/'+PODetail['id'];
                  allPO.push(poData);
                }
              }

              data['p_o_list'] = allPO;

              supplierData.push(data);
            }
          }
          
          // second array
          if(i===1){
            for(let j = 0; j < getData.length; j++) {
              let data = [];
              data['id'] = getData[j].supplier.id;
              data['name'] = getData[j].supplier.name;
              data['email'] = getData[j].supplier.email;
              data['phone'] = getData[j].supplier.phone;
              data['url'] = '/edit_supplier/'+getData[j].supplier.id;

              let allPO = [];
              let poData = [];
              poData['id'] = getData[j].id;
              poData['po_number'] = getData[j].po_number ? '#'+getData[j].po_number : "-" ;
              poData['date'] = format(new Date(getData[j].created_on), 'dd-MM-yyyy');
              poData['status'] = getData[j].po_status;
              poData['url'] = '/purchases-edit/'+getData[j].id;
              allPO.push(poData);

              data['p_o_list'] = allPO;

              supplierData.push(data);

            }

          }

        }
      }
    }

    //fetch and set invoice data
    if(searchType === 'invoice'){
      for(let i=0; i<props.globalSearchData.searchData.length; i++){
        let getData = props.globalSearchData.searchData[i];
        if(getData.length > 0){
          
          //first array
          if(i===0){
            for(let j = 0; j < getData.length; j++) {
              let data = [];
              data['cust_id'] = getData[j].job_schedule.customer.cust_detail.id;

              if(getData[j].job_schedule.customer.cust_detail.type === '1'){
                data['name'] = getData[j].job_schedule.customer.cust_detail.company_name;
              } else {
                data['name'] = getData[j].job_schedule.customer.cust_detail.firstname+' '+getData[j].job_schedule.customer.cust_detail.lastname;
              }

              
              data['phone'] = getData[j].job_schedule.customer.cust_detail.mobile_number;
              data['email'] = getData[j].job_schedule.customer.email;
              data['cust_url'] = '/edit_customer/'+getData[j].job_schedule.customer.cust_detail.id;

              let allInv = [];
              let invData = [];

              invData['invoice_no'] = getData[j].invoice_number ? '#'+getData[j].invoice_number : "-";
              invData['date_issued'] = format(new Date(getData[j].date_issued), 'dd-MM-yyyy');
              invData['url'] = '/edit-invoice/'+getData[j].id;
              invData['job_url'] = '/job_detail/'+getData[j].job_schedule.id;
              invData['job_no'] = getData[j].job_schedule.job_number ? '#'+getData[j].job_schedule.job_number : "-";
              invData['job_status'] = getData[j].job_schedule.job_status;
              invData['invoice_status'] = getData[j].invoice_status === '1' ? "Unpaid" : "Paid";

              if(getData[j].invoice_status === '1'){
                invData['url'] = '/edit-invoice/'+getData[j].id;
                invData['job_url'] = '/job_detail/'+getData[j].job_schedule.id;
              } else {
                invData['url'] = '/invoice-view/'+getData[j].id;
                invData['job_url'] = '/view_job/'+getData[j].job_schedule.id;
              }

              allInv.push(invData);
              data['invoice_list'] = allInv;

              invoiceData.push(data);

            }
          }

          //second array
          if(i===1){

            for(let j = 0; j < getData.length; j++) {
              let data = [];
              data['cust_id'] = getData[j].id;
              
              if(getData[j].type === '1'){
                data['name'] = getData[j].firstname+' '+getData[j].company_name;
              } else {
                data['name'] = getData[j].firstname+' '+getData[j].lastname;
              }
              data['phone'] = getData[j].mobile_number;
              data['email'] = getData[j].user.email;
              data['cust_url'] = '/edit_customer/'+getData[j].id;

              let allInv = [];

              // if(getData[j].user.vehicles.length > 0){
              //   for(let p = 0; p < getData[j].user.vehicles.length; p++) {
              //     let vehicleDetail = getData[j].user.vehicles[p];
              //     if(vehicleDetail.job_schedules.length > 0){
                    
              //       for(let k = 0; k < vehicleDetail.job_schedules.length; k++) {
              //         let jobScheduleDetail = vehicleDetail.job_schedules[k];
              //         if(jobScheduleDetail.invoices.length > 0){
              //           let invData = [];
              //           invData['job_no'] = jobScheduleDetail.job_number ? '#'+jobScheduleDetail.job_number : "-";
              //           invData['job_url'] = '/job_detail/'+jobScheduleDetail.id;
              //           invData['invoice_no'] = jobScheduleDetail.invoices[0].invoice_number ? '#'+jobScheduleDetail.invoices[0].invoice_number : "-";
              //           invData['date_issued'] = format(new Date(jobScheduleDetail.invoices[0].date_issued), 'dd-MM-yyyy');
              //           if(jobScheduleDetail.invoices[0].invoice_status === '1'){
              //             invData['url'] = '/edit-invoice/'+jobScheduleDetail.invoices[0].id;
              //             invData['job_url'] = '/job_detail/'+jobScheduleDetail.id;
              //           } else {
              //             invData['url'] = '/invoice-view/'+jobScheduleDetail.invoices[0].id;
              //             invData['job_url'] = '/view_job/'+jobScheduleDetail.id;
              //           }
              //           invData['invoice_status'] = (jobScheduleDetail.invoices[0].invoice_status === '1') ? "Unpaid" : "Paid"; 
              //           invData['job_status'] = jobScheduleDetail.job_status;
              //           allInv.push(invData);

              //         }
              //       }
                    
              //     }
              //   }
              // }
              if (getData[j].user.invoices?.length > 0) {
                for (let p = 0; p < getData[j].user.invoices?.length; p++) {
                  let invoiceDetail = getData[j].user.invoices[p];
                  
                  if (invoiceDetail.job_schedule) {
                    let jobScheduleDetail = invoiceDetail.job_schedule;
                    
                    let invData = {};
                    invData['job_no'] = jobScheduleDetail.job_number ? '#' + jobScheduleDetail.job_number : "-";
                    invData['job_url'] = '/job_detail/' + jobScheduleDetail.id;
                    invData['invoice_no'] = invoiceDetail.invoice_number ? '#' + invoiceDetail.invoice_number : "-";
                    invData['date_issued'] = format(new Date(invoiceDetail.date_issued), 'dd-MM-yyyy');
                    
                    if (invoiceDetail.invoice_status === '1') {
                      invData['url'] = '/edit-invoice/' + invoiceDetail.id;
                      invData['job_url'] = '/job_detail/' + jobScheduleDetail.id;
                    } else {
                      invData['url'] = '/invoice-view/' + invoiceDetail.id;
                      invData['job_url'] = '/view_job/' + jobScheduleDetail.id;
                    }
                    
                    invData['invoice_status'] = (invoiceDetail.invoice_status === '1') ? "Unpaid" : "Paid"; 
                    invData['job_status'] = jobScheduleDetail.job_status;
                    
                    allInv.push(invData);
                  }
                }
              }
              
              data['invoice_list'] = allInv;
              invoiceData.push(data);
            }
          }
        }
      }
    }


    //view more and view less button functionality
    function viewMore(classDetail,id){
      let class_detail = classDetail+'detail_'+id;
      let btn_detail = classDetail+'more_btn_'+id;
      let allElements = document.querySelectorAll("."+class_detail);

      let buttonName = document.getElementById(btn_detail).innerHTML;
      
      if(buttonName === "View Less"){
        for(i=0; i<allElements.length; i++){ 
          allElements[i].classList.add('hide');
        }
        document.getElementById(btn_detail).innerHTML = "View More";
      } else {
        for(i=0; i<allElements.length; i++){ 
          allElements[i].classList.remove('hide');
        }
        document.getElementById(btn_detail).innerHTML = "View Less";
      }
    }

    const goToCustomer = (customer_id) => {
      navigate("/edit_customer/" + customer_id);
      closeSearchBox();
    }

    const goToSupplier = (supplier_id) => {
      navigate("/edit_supplier/" + supplier_id);
      closeSearchBox();
    }

    return (
      <>
        <div className="search-filter-wrapper custom-header-dropdown">
          <Tabs defaultActiveKey={props.eventKey} className="mb-3" onSelect={(k) => props.handleSearchType(k)} >

              {/* customer tab */}
              <Tab eventKey="customer" title="Customer" >
                
                <div>
                    <Scrollbars
                      className="custom-scrollbar" style={{ width: '100%', height: 350 }}
                      renderThumbVertical={props => <div {...props} className="thumb-vertical" />}>

                      {searchType === 'customer' &&
                        <div>
                          {customerData.length > 0 &&
                            <ul className="order-list">

                              {/* customer loop start */}
                              
                              
                              {customerData.map((customer,index) =>

                                <li key={`${index}_${customer.cust_id}`} >
                                  <div className="order-info" onClick={() => {goToCustomer(customer.cust_id)}}>
                                      <div>
                                        <i className="fa font-size-20 m-r-5 fa-user"></i>
                                        <span>{customer.name}</span>
                                      </div>
                                      <div>
                                        <i className="fa fa-phone font-size-20 m-r-5"></i>
                                        <span>{customer.mobile}</span>
                                      </div>
                                      <div>
                                        <i className="fa fa-envelope font-size-20 m-r-5"></i>
                                        <span>{customer.email}</span>
                                      </div>
                                  </div>
                                  <div className="order-detail-outer">
                                    {customer.vehicle_list.map((vehicle,index) =>
                                      <div className={"order-detail "+ (index <= 4 ? 'show' : `customer_detail_${customer.cust_id} hide`)} key={`${index}_${vehicle.veh_id}`}>
                                        <div className="left-block">
                                              {vehicle?.make !== "" ? (
                                                <CarMakeLogo
                                                  make={(vehicle && vehicle?.make) || ""}
                                                />
                                              ) : (
                                                <div className="profile" style={{ verticalAlign: "middle" }}>
                                                  <i className="d-flex align-center fa fa-user font-size-20 text-primary text-center mx-2 my-2"></i>
                                                </div>
                                              )}
                                            <div>
                                              <p>REGO: <Link onClick={closeSearchBox} className="rego-link" to={vehicle.url}>{vehicle.rego}</Link></p>
                                              <p>{vehicle.make} {vehicle.model} with {vehicle.odometer_reading ? vehicle.odometer_reading : 0} km</p>
                                            </div>
                                        </div>
                                        <div className="btn-wrapper ">
                                        
                                            {vehicle.quote_color > 0 ? (
                                              <Link to={vehicle.quote_url} onClick={closeSearchBox} title={vehicle.quote_status} className="order-id btn-rounded"><span>{vehicle.quote_number}</span></Link>
                                            ) : (
                                              <Link to={vehicle.quote_url} title="Create Quote" className="border-btn btn-rounded">Create Quote</Link>
                                            )}
                                            
                                            {vehicle.job_color > 0 ? (
                                              <Link to={vehicle.job_url} onClick={closeSearchBox} title={vehicle.job_status} className="order-id btn-rounded"><span>{vehicle.job_number}</span></Link>
                                            ) : (
                                              <Link to={vehicle.job_url} onClick={closeSearchBox} title="Book a Job" className="border-btn btn-rounded">Book a Job</Link>
                                            )}

                                        </div>
                                      </div>
                                    )}

                                    {customer.vehicle_list.length === 0 &&
                                        <div className="order-detail-outer">
                                            <div className="order-detail">
                                                <span>No Vehicle Details Found.</span>
                                                <Link to={customer.url} title="Add Vehicle" onClick={closeSearchBox} className="border-btn btn-rounded">Add Vehicle</Link>
                                            </div>
                                        </div>
                                    }

                                    {customer.vehicle_list.length > 4 &&
                                      <div className="view-more-wrapper">
                                        <Button onClick={() => viewMore('customer_',customer.cust_id)} id={`${'customer_more_btn_'+customer.cust_id}`} className="view-more-btn">View more</Button>
                                      </div>
                                    }
                                    
                                  </div>
                                </li>

                              )}
                              {/* customer loop end */}

                            </ul>
                          }

                          {customerData.length === 0 &&
                            <img src={Noresultfound} alt="No Result Found"></img>
                          }
                        </div>
                      }

                     
                    </Scrollbars>
                </div>
              </Tab>

              {/* purchase order tab */}
              <Tab eventKey="order" title="Purchase Order" >
                <div>
                    <Scrollbars
                      className="custom-scrollbar" style={{ width: '100%', height: 350 }}
                      renderThumbVertical={props => <div {...props} className="thumb-vertical" />}>
                      {searchType === 'supplier' &&
                        <div>
                        {supplierData.length > 0 &&
                          <ul className="order-list">
                              {supplierData.map((supplier,index) =>
                                <li key={`${index}_${supplier.id}`} >
                                  <div className="order-info" onClick={() => {goToSupplier(supplier.id)}}>
                                      <div>
                                        <i className="fa font-size-20 m-r-5 fa-user"></i>
                                        <span>{supplier.name}</span>
                                      </div>
                                      <div>
                                        <i className="fa fa-phone font-size-20 m-r-5"></i>
                                        <span>{supplier.phone}</span>
                                      </div>
                                      <div>
                                        <i className="fa fa-envelope font-size-20 m-r-5"></i>
                                        <span>{supplier.email ? supplier.email : ""}</span>
                                      </div>
                                  </div>

                                  {supplier.p_o_list.map((po_data,index) =>

                                    <div className={"order-detail-outer "+ (index <= 4 ? '' : `supplier_detail_${supplier.id} hide`)} key={`${index}_${po_data.id}`}>
                                        <div className="order-detail">
                                          <div className="width-33">
                                              <span>Date:</span>
                                              <span>{po_data.date}</span>
                                          </div>
                                          <div className="width-33">
                                              <span>Status:</span>
                                              <span>{po_data.status}</span>
                                          </div>
                                          <div className="width-33">
                                            {po_data.status === "complete" ? (
                                              <Link to={`/purchase-view/${po_data.id}`} onClick={closeSearchBox} className="order-id btn-rounded">PO <span>{po_data.po_number}</span></Link>
                                            ):(
                                              <Link to={`/purchases-edit/${po_data.id}`} onClick={closeSearchBox} className="order-id btn-rounded">PO <span>{po_data.po_number}</span></Link>
                                            )}
                                              
                                          </div>
                                        </div>
                                    </div>

                                  )}

                                  {supplier.p_o_list.length === 0 &&
                                      <div className="order-detail-outer">
                                          <div className="order-detail">
                                              <span>No Result Found.</span>
                                          </div>
                                      </div>
                                  }

                                  {supplier.p_o_list.length > 4 &&
                                    <div className="view-more-wrapper">
                                      <Button onClick={() => viewMore('supplier_',supplier.id)} id={`${'supplier_more_btn_'+supplier.id}`} className="view-more-btn">View more</Button>
                                    </div>
                                  }


                                </li>
                              )}
                          </ul>
                        }
                        {supplierData.length === 0 &&
                          <img src={Noresultfound} alt="No Result Found"></img>
                        }
                        </div>
                      }
                      
                    </Scrollbars>
                </div>
              </Tab>

              {/* Invoice tab */}
              <Tab eventKey="invoice" title="Invoice" >
                <div>
                    <Scrollbars
                      className="custom-scrollbar" style={{ width: '100%', height: 350 }}
                      renderThumbVertical={props => <div {...props} className="thumb-vertical" />}>
                      {searchType === 'invoice' &&
                        <div>
                          {invoiceData.length > 0 &&
                            <ul className="order-list">
                              {invoiceData.map((invoice,index) =>
                                <li  key={`${index}_${invoice.cust_id}`} >
                                  <div className="order-info" onClick={() => {goToCustomer(invoice.cust_id)}}>
                                      <div>
                                        <i className="fa font-size-20 m-r-5 fa-user"></i>
                                        <span>{invoice.name}</span>
                                      </div>
                                      <div>
                                        <i className="fa fa-phone font-size-20 m-r-5"></i>
                                        <span>{invoice.phone}</span>
                                      </div>
                                      <div>
                                        <i className="fa fa-envelope font-size-20 m-r-5"></i>
                                        <span>{invoice.email}</span>
                                      </div>
                                  </div>

                                  {invoice.invoice_list.map((invoice_data,index) =>
                                    // <div className="order-detail-outer" key={'invoice_index_id'+index} >
                                    <div className={"order-detail-outer "+ (index <= 4 ? '' : `invoice_detail_${invoice.cust_id} hide`)} key={'invoice_index_id'+index}>
                                        <div className="order-detail">
                                          <div>
                                              <span>Date:</span>
                                              <span>{invoice_data.date_issued}</span>
                                          </div>
                                          <div>
                                              <span>Status:</span>
                                              <span>{invoice_data.invoice_status}</span>
                                          </div>
                                          <div className="btn-wrapper">
                                            <Link to={invoice_data.url} onClick={closeSearchBox} className="order-id btn-rounded">Invoice <span> {invoice_data.invoice_no}</span></Link>
                                            <Link to={invoice_data.job_url} title={invoice_data.job_status} onClick={closeSearchBox} className="order-id btn-rounded">Job <span> {invoice_data.job_no}</span></Link>
                                          </div>
                                        </div>
                                    </div>
                                  )}

                                  {invoice.invoice_list.length === 0 &&
                                      <div className="order-detail-outer">
                                          <div className="order-detail">
                                              <span>No Result Found.</span>
                                          </div>
                                      </div>
                                  }

                                  {invoice.invoice_list.length > 4 &&
                                    <div className="view-more-wrapper">
                                      <Button onClick={() => viewMore('invoice_',invoice.cust_id)} id={`${'invoice_more_btn_'+invoice.cust_id}`} className="view-more-btn">View more</Button>
                                    </div>
                                  }

                                </li>
                              )}
                            </ul>
                          }

                          {invoiceData.length === 0 &&
                            <img src={Noresultfound} alt="No Result Found"></img>
                          }
                        </div>
                      }
                    </Scrollbars>
                </div>
              </Tab>
              
          </Tabs>
        </div>
      </>
    )

  } else {
    return (<>
       <div className="search-filter-wrapper custom-header-dropdown">
          <Tabs defaultActiveKey={props.eventKey} className="mb-3" onSelect={(k) => props.handleSearchType(k)} >
              {/* customer tab */}
              <Tab eventKey="customer" title="Customer" >
                <div className="spinner-loader">
                  <Spinner animation="border" variant="info" />
                </div>
              </Tab>

              {/* purchase order tab */}
              <Tab eventKey="order" title="Purchase Order" >
                <div className="spinner-loader">
                  <Spinner animation="border" variant="info" />
                </div>
              </Tab>

              {/* Invoice tab */}
              <Tab eventKey="invoice" title="Invoice" >
                <div className="spinner-loader">
                  <Spinner animation="border" variant="info" />
                </div>
              </Tab>

          </Tabs>
        </div>
    </>)
  }
  
}
export default GloablSearch;