import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import PayHead from './PayHead';
import Wrapped from './Wrapped';
import cheque_img from 'assets/images/cheque.png';
import { usePaymentInfo } from './PaymentInfoProvider';

const ChequePaymentModal = ({ onChangeClick, show, onClose, isChequePayment }) => {

    const { request, setRequest } = usePaymentInfo()
    const [chequeNumberError,setChequeNumberError] = useState(false);

    return (
        <Wrapped show={show} onHide={onClose} isChequePayment={isChequePayment}>
            <div className="px-2">
                <PayHead onChangeClick={onChangeClick} image={cheque_img} label="Cheque Payment" />
                <div className="row my-2">
                    <div className="col-5">
                        <label htmlFor="date">Date</label>
                        {/* <DatePicker id='date' style={{ padding: ".375rem .75rem", fontSize: 16, lineHeight: 1.5, display: 'block' }} className='form-control' /> */}
                        <DatePicker
                            onChange={(value => {
                                setRequest(p => {
                                    return {
                                        ...p,
                                        payment_date: value.format('YYYY-MM-DD')
                                    }
                                })
                            })}
                            style={{ padding: ".375rem .75rem", fontSize: 16, lineHeight: 1.5, display: 'block' }} className='form-control'
                            defaultValue={moment(request.payment_date, 'YYYY-MM-DD')}
                        />
                    </div>
                    <div className="col-7">
                        <label htmlFor="cheque_number">Cheque Number</label>
                        {/* <input id='cheque_number' name='cheque_number' placeholder='Cheque Number' className='form-control' /> */}
                        <input placeholder='Cheque Number' type="text" className='form-control' onChange={e => {
                                if (e.target.value.length <= 0) {
                                    setChequeNumberError(true);
                                } else {
                                    setChequeNumberError(false);
                                    setRequest(pr => {
                                        return {
                                            ...pr,
                                            "cheque_number": e.target.value,
                                        }
                                    })
                                }
                                
                            }} />

                            {chequeNumberError && <span style={{color:"red"}}>This field is required.</span>}
                    </div>
                </div>
            </div>
        </Wrapped>
    )
}

export default ChequePaymentModal