import logo from "../../assets/images/5iQ_logo.png";
import logoBlack from "../../assets/images/5iQ_logo_black.png";
import bookJobBlue from "../../assets/images/book-job-blue.svg";
import bookJobWhite from "../../assets/images/book-job-white.svg";
import bookJob from "../../assets/images/book-job.svg";
import cashSaleBlue from "../../assets/images/cash-sale-blue.svg";
import cashSaleWhite from "../../assets/images/cash-sale-white.svg";
import cashSale from "../../assets/images/cash-sale.svg";
import customerBlue from "../../assets/images/customer-blue.svg";
import customerWhite from "../../assets/images/customer-white.svg";
import customer from "../../assets/images/customer.svg";
import inspectionsBlue from "../../assets/images/inspections-blue.svg";
import inspectionsWhite from "../../assets/images/inspections-white.svg";
import inspections from "../../assets/images/inspections.svg";
import materialsBlue from "../../assets/images/materials-blue.svg";
import materialsWhite from "../../assets/images/materials-white.svg";
import materials from "../../assets/images/materials.svg";
import scheduleBlue from "../../assets/images/schedule-blue.svg";
import scheduleWhite from "../../assets/images/schedule-white.svg";
import schedule from "../../assets/images/schedule.svg";

export const topLinks = [
  {
    label: "Booking Diary",
    // mod: ["dashboard",],
    mods: ["dashboard", "onlineleads"],
    main_link: "/booking-diary",
    icon_class: "fa fa-book",
    sub_items: [
      {
        label: "Booking Diary",
        mod: "global",
        link: "/booking-diary",
      },
      {
        label: "Online Leads",
        mod: "onlineleads",
        // mod: "global",
        link: "/leads_list",
      }
    ],
  },
  {
    label: "Scheduler",
    mod: "scheduler",
    main_link: "/scheduler",
    normal_img: schedule,
    hover_img: scheduleBlue,
    dark_theme_img: scheduleWhite,
    sub_items: [
      {
        label: "Day",
        mod: "global",
        link: "/scheduler",
      },
      {
        label: "Week",
        mod: "global",
        link: "/scheduler_week",
      },
      {
        label: "Month",
        mod: "global",
        link: "/scheduler_month",
      },
    ],
  },

  // {
  //   label: "Leads",
  //   mod: "workshop",
  //   main_link: "/leads_list",
  //   normal_img: bookJob,
  //   hover_img: bookJobBlue,
  //   dark_theme_img: bookJobWhite,
  //   sub_items: [
  //     {
  //       label: "Online Leads",
  //       mod: "global",
  //       link: "/leads_list",
  //     },
  //   ],
  // },
  {
    label: "Quotes",
    mod: "workshop",
    main_link: "/quote-list",
    normal_img: cashSale,
    hover_img: cashSaleBlue,
    dark_theme_img: cashSaleWhite,
    sub_items: [
      {
        label: "Open Quotes",
        mod: "global",
        link: "/quote-list",
      },
      {
        label: "Won Quotes",
        mod: "global",
        link: "/quote-list#won-quotes",
      },
      {
        label: "Lost Quotes",
        mod: "global",
        link: "/quote-list#lost-quotes",
      },
    ],
  },
  {
    label: "Jobs",
    mod: "workshop",
    main_link: "/job-list",
    normal_img: bookJob,
    hover_img: bookJobBlue,
    dark_theme_img: bookJobWhite,
    sub_items: [
      {
        label: "Unassigned Jobs",
        mod: "global",
        link: "/job-list#unassigned-job",
      },
      {
        label: "Assigned Jobs",
        mod: "global",
        link: "/job-list#assigned-job",
      },
      {
        label: "In Progress Jobs",
        mod: "global",
        link: "/job-list#inProgress-job",
      },
      {
        label: "Completed Jobs",
        mod: "global",
        link: "/job-list#complete-job",
      },
    ],
  },


  {
    label: "Invoices",
    mod: "invoices",
    main_link: "/invoice",
    icon_class: "fa fa-files-o",
    sub_items: [
      {
        label: "Cash Sale",
        mod: "global",
        link: "/cash_sale",
      },
      {
        label: "Raised Invoices",
        mod: "global",
        link: "/invoice#raised-invoices",
      },
      {
        label: "Completed Invoices",
        mod: "global",
        link: "/invoice#completed-invoices",
      },
      {
        label: "Invoice Payments",
        mod: "global",
        link: "/invoice#invoice-payments",
      },
      {
        label: "Invoice Credit",
        mod: "global",
        link: "/invoice#invoice-credit",
      },
      {
        label: "Invoice Statement",
        mod: "global",
        link: "/invoice#invoice-statement",
      },
    ],
  },

  {
    label: "Contacts",
    mod: "contacts",
    main_link: "/contacts_list",
    normal_img: customer,
    hover_img: customerBlue,
    dark_theme_img: customerWhite,
    sub_items: [
      {
        label: "Customer",
        mod: "global",
        link: "/contacts_list",
      },
      {
        label: "3rd Party Company",
        mod: "global",
        link: "/contacts_list#ThirdParty",
      },
      {
        label: "Supplier",
        mod: "global",
        link: "/contacts_list#Suppliers",
      },
      {
        label: "Employee",
        mod: "global",
        link: "/contacts_list#Employees",
      },
    ],
  },

  {
    label: "Vehicles",
    mod: "workshop",
    main_link: "/asset_list",
    icon_class: "fa fa-list",
    sub_items: [
      {
        label: "Vehicle List",
        mod: "global",
        link: "/asset_list",
      },
      {
        label: "Service Reminder",
        mod: "workshop",
        link: "/service_schedule",
      }
    ],
  },

];

export const bottomList = [


  {
    label: "Inspections",
    // mod: "subs",
    mod: "workshop",
    main_link: "/inspections",
    normal_img: inspections,
    hover_img: inspectionsBlue,
    dark_theme_img: inspectionsWhite,
    sub_items: [],
  },

  {
    label: "Materials",
    mods: ["inventory", "purchases", "stockmanagements&stocktakes"],
    main_link: "/catalogue",
    normal_img: materials,
    hover_img: materialsBlue,
    dark_theme_img: materialsWhite,
    sub_items: [
      {
        label: "Parts & Kits",
        mod: "inventory",
        link: "/catalogue",
      },
      {
        label: "Purchase Orders",
        mod: "purchases",
        link: "/purchase-list",
      },
      {
        label: "Stock",
        mod: "stockmanagements&stocktakes",
        link: "/stock",
      },
      {
        label: "Stock Take",
        mod: "stockmanagements&stocktakes",
        link: "/stock-take/list",
      },
    ],
  },

  {
    label: "Marketing",
    mod: "marketing",
    main_link: "/marketing/marketing-dashboard/",
    icon_class: "fa fa-bullhorn",
    sub_items: [
      {
        label: "Broadcast",
        mod: "global",
        link: "/marketing/broadcast",
      },
      {
        label: "Campaign",
        mod: "global",
        link: "/marketing/campaign",
      },
      {
        label: "Referral",
        mod: "global",
        link: "/marketing/referral",
      },
      {
        label: "Feedback",
        mod: "global",
        link: "/marketing/marketing-dashboard/",
      },
      // {
      //   label: "Slow Day",
      //   mod: "global",
      //   link: "/marketing/marketing-dashboard/",
      // },
      // {
      //   label: "Last Minute",
      //   mod: "global",
      //   link: "/marketing/marketing-dashboard/",
      // },
    ],
  },

  {
    label: "Account Sync",
    mod: "sync",
    main_link: "/sync",
    icon_class: "fa fa-list",
    sub_items: [],
  },

  {
    label: "Reports",
    mod: "reports",
    main_link: "/reports",
    icon_class: "fa fa-bar-chart",
    sub_items: [
      {
        label: "Statements",
        mod: "global",
        link: "/reports/statements",
      },
      {
        label: "Invoices",
        mod: "global",
        link: "/reports/report-invoice",
      },
      {
        label: "Jobs",
        mod: "global",
        link: "/reports/job-report",
      },
      {
        label: "Quotes",
        mod: "global",
        link: "/reports/quote-report",
      },
      {
        label: "Customer",
        mod: "global",
        link: "/reports/customer-report",
      },
      {
        label: "Labour",
        mod: "global",
        link: "/reports/labour-report",
      },
      {
        label: "Clocking",
        mod: "global",
        link: "/reports/clocking-report",
      },
      {
        label: "Materials",
        mod: "global",
        link: "/reports/po-report",
      },
      {
        label: "Marketing",
        mod: "global",
        link: "/reports",
      },
      {
        label: "Notifications",
        mod: "global",
        link: "/reports",
      },
      {
        label: "Schedule Reports",
        mod: "global",
        link: "/reports",
      },
      {
        label: "Financials",
        mod: "global",
        link: "/reports",
      },
    ],
  },

  {
    label: "Settings",
    mod: "settings",
    main_link: "/settings",
    icon_class: "fa fa-gears",
    bottom_align: true,
    sub_items: [
      {
        label: "Company Detail",
        mod: "global",
        link: "/settings_company_detail",
      },
      {
        label: "Defaults",
        mod: "global",
        link: "/settings_defaults",
      },
      {
        label: "Inspection Templates",
        mod: "subs",
        link: "/inspection/template-lists",
      },
      {
        label: "Security Group",
        mod: "global",
        link: "/settings_security_group",
      },
      {
        label: "Chart Of Accounts",
        mod: "global",
        link: "/chart-of-accounts",
      },
      {
        label: "Data Import",
        mod: "global",
        link: "/utilities_import",
      },
      {
        label: "Statuses",
        mod: "global",
        link: "/statuses",
      },
      {
        label: "Workshop Bays",
        mod: "global",
        link: "/workshop_bays",
      },
      {
        label: "Job Responses",
        mod: "global",
        link: "/script",
      },
      {
        label: "Message Templates",
        mod: "global",
        link: "/notification/create",
      },
      {
        label: "Storage Locations",
        mod: "global",
        link: "/storage_list",
      },
      {
        label: "Supplier Integration",
        mod: "global",
        link: "/supplier_integration",
      },
      {
        label: "Payment Integration",
        mod: "global",
        link: "/stripe",
      },
      // {
      //   label: "3rd Party Company",
      //   mod: "global",
      //   link: "/insurance_company_list",
      // },
      {
        label: "Subscription",
        mod: "global",
        link: "/edit-subscription",
      },
      {
        label: "Subscription Transaction",
        mod: "global",
        link: "/user-transaction",
      },
    ],
  },
];

//Setting bar links
export const settingLinks = [
  {
    label: "Company Detail",
    mod: "global",
    link: "/settings_company_detail",
  },
  {
    label: "Defaults",
    mod: "global",
    link: "/settings_defaults",
  },
  {
    label: "Inspection Templates",
    mod: "subs",
    link: "/inspection/template-lists",
  },
  {
    label: "Security Group",
    mod: "global",
    link: "/settings_security_group",
  },
  {
    label: "Chart Of Accounts",
    mod: "global",
    link: "/chart-of-accounts",
  },
  {
    label: "Data Import",
    mod: "global",
    link: "/utilities_import",
  },
  {
    label: "Statuses",
    mod: "global",
    link: "/statuses",
  },
  {
    label: "Workshop Bays",
    mod: "global",
    link: "/workshop_bays",
  },
  {
    label: "Job Responses",
    mod: "global",
    link: "/script",
  },
  {
    label: "Message Templates",
    mod: "global",
    link: "/notification/create",
  },
  {
    label: "Storage Locations",
    mod: "global",
    link: "/storage_list",
  },
  {
    label: "Supplier Integration",
    mod: "global",
    link: "/supplier_integration",
  },
  {
    label: "Payment Integration",
    mod: "global",
    link: "/stripe",
  },
  // {
  //   label: "3rd Party Company",
  //   mod: "global",
  //   link: "/insurance_company_list",
  // },
  {
    label: "Subscription",
    mod: "global",
    link: "/edit-subscription",
  },
  {
    label: "Subscription Transaction",
    mod: "global",
    link: "/user-transaction",
  },
];

//report bar links
export const reportLinks = [
  {
    label: "Statements",
    mod: "global",
    link: "/reports/statements",
    title: "Invoices",
  },
  {
    label: "Invoices",
    mod: "global",
    link: "/reports/report-invoice",
    title: "Invoices",
  },
  {
    label: "Jobs",
    mod: "global",
    link: "/reports/job-report",
    title: "Jobs",
  },
  {
    label: "Quotes",
    mod: "global",
    link: "/reports/quote-report",
    title: "Quotes",
  },
  {
    label: "Customer",
    mod: "global",
    link: "/reports/customer-report",
    title: "Customer",
  },
  {
    label: "Labour",
    mod: "global",
    link: "/reports/labour-report",
    title: "Labour",
  },
  {
    label: "Clocking",
    mod: "global",
    link: "/reports/clocking-report",
    title: "Clocking",
  },
  {
    label: "Materials",
    mod: "global",
    link: "/reports/po-report",
    title: "Materials",
  },
  {
    label: "Marketing",
    mod: "global",
    link: "#",
    title: "Coming Soon...",
  },
  {
    label: "Notifications",
    mod: "global",
    link: "#",
    title: "Coming Soon...",
  },
  {
    label: "Schedule Reports",
    mod: "global",
    link: "#",
    title: "Coming Soon...",
  },
  {
    label: "Financials",
    mod: "global",
    link: "#",
    title: "Coming Soon...",
  },
];