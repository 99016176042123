import { apiCall } from "helpers/apiHelper";
import { GET_CASH_SALE_PAYMENTS_CREDITS, GET_INVOICES_LIST, GET_INVOICES_PAYMENT, GET_INVOICE_CASH_SALE_LIST } from "helpers/urlHelper";
import { getWorkshopId } from 'utils/getWorkshopId';
export async function getInvoiceReciept({ id, invoiceType, customer_preview_invoice = 0, insurance_preview_invoice = 0, job_id = "", is_split_payment = 0 }) {
    let request;
    let url;
    if (invoiceType === 'cash_sale') {
        request = {
            get: "all",
            conditions: {
                'CashSalePaymentsCredits.cash_sale_invoice_id': id
            },
            contain: ['CashSaleInvoice'],
            order: {
                'CashSalePaymentsCredits.id': 'desc'
            }
        }
        url = GET_CASH_SALE_PAYMENTS_CREDITS
    } else {
        // request = {
        //     'get': 'all',
        //     'conditions': {
        //         'InvoicesPaymentsCredits.invoice_id': id
        //     }
        //     ,
        //     'contain': ['Invoices'],
        //     'order': {
        //         'InvoicesPaymentsCredits.id': 'desc'
        //     }
        // }

        request = {
            "get": "all",
            "conditions": {
                "InvoicesPaymentsCredits.invoice_id": id
            },
            "contain": {
                "Invoices":{
                    "fields":["id","invoice_number","user_id","insurance_company_id","job_id","recepient","date_issued","payment_due_date","claim_method","claim","claim_amount","remain_balance","comments","tax_invoice","sub_total","discount","discount_cost","total_with_discount","gst","total","invoice_status","is_part_no_visible","form_type","customer_preview_invoice","insurance_preview_invoice","customer_invoice_total","insurance_invoice_total","is_labour_total_display","customer_invoice_allocation_total","customer_invoice_allocation_preview","invoice_allocation_type","email_description","email_template_id","created_on"]	
                }
            },
            "order": {
                "InvoicesPaymentsCredits.id": "desc"
            },
            "fields":[
                "id","invoice_id","receipt_no","receipt_no","invoice_type","payment_method","amount_due","amount_paid","balance_remaining","description","payment_type","card_type","payment_date","notes","payment_processing_fee","receipt_total","card_last_digit","created_on"
            ]
        }
        url = GET_INVOICES_PAYMENT;
    }
    const data = await apiCall(url, { payload: request }, "POST", true);
    let paymentCredits = invoiceType === 'cash_sale' ? data.data.CashSalePaymentsCredits : data.data.InvoicesPaymentsCredits;
    const invoiceArr = {};
    let paid_amount = 0;
    const paymentDetails = [];

    if (paymentCredits.length !== 0) {

        paymentCredits.forEach((payCred, i) => {
            paid_amount = paid_amount + parseFloat(payCred.amount_paid);

            paymentDetails[i] = {
                date: payCred.payment_date,
            }
            if (payCred.payment_method == 1) {
                paymentDetails[i].type = "Cash";
            }
            else if (payCred.payment_method == 2 || payCred.payment_method == 3) {
                paymentDetails[i].type = "Card";
            }
            else if (payCred.payment_method == 4) {
                paymentDetails[i].type = "Other";
            }
            paymentDetails[i].amount_paid = payCred.amount_paid;
        });

        paymentCredits[0].amount_paid = paid_amount;
        invoiceArr['data'] = paymentCredits[0];
        invoiceArr['type'] = 'credits';
        invoiceArr['paymentRecords'] = paymentDetails;
        invoiceArr['invoiceType'] = invoiceType;
    }

    if (Object.keys(invoiceArr).length === 0) {
        if (invoiceType === 'cash_sale') {
            const req = {
                get: "all",
                conditions: {
                    workshop_id: getWorkshopId(),
                    invoice_status: 1,
                    id: id
                },
                fields: [
                    "id",
                    "workshop_id",
                    "cash_sale_no",
                    "parts_total_sell",
                    "labour_total_sell",
                    "created_on",
                    "total",
                    "description",
                    // "tax_invoice",
                    // "remain_balance",
                    "invoice_status",
                ]
            }
            let invoiceDetails = await apiCall(GET_INVOICE_CASH_SALE_LIST, { payload: req }, "POST", true);
            if (invoiceDetails.code == 200) {
                invoiceArr['data'] = invoiceDetails.data.CashSaleInvoice[0];
                invoiceArr['type'] = 'cash_sale';
                invoiceArr['invoiceType'] = invoiceType;
            }

        } else {
            let condition = "";
            if (is_split_payment === 1) {
                condition = {
                    'Invoices.workshop_id': getWorkshopId(),
                    'invoice_status': 1,
                    'Invoices.job_id': job_id,
                    'Invoices.customer_preview_invoice': customer_preview_invoice,
                    'Invoices.insurance_preview_invoice': insurance_preview_invoice
                }
            } else {
                condition = {
                    'Invoices.workshop_id': getWorkshopId(),
                    'invoice_status': 1,
                    'Invoices.id': id,
                }
            }
            const req = {
                'get': 'all',
                'conditions': condition,
                'contain': {
                    "JobSchedules":{
                        "fields":["id"]
                    },
                    "JobSchedules.Invoices" : {
                        "fields":[
                          "id",
                          "job_id",
                          "invoice_status"
                        ]
                      }
                    },
                'fields': [
                    "id",
                    "workshop_id",
                    "invoice_number",
                    "created_on",
                    "total",
                    "comments",
                    "tax_invoice",
                    "remain_balance",
                    "invoice_status",
                    "claim_amount",
                    "customer_preview_invoice",
                    "insurance_preview_invoice",
                    "customer_invoice_allocation_total",
                    "customer_invoice_allocation_preview",
                    "customer_invoice_total",
                    "insurance_invoice_total",
                    "job_id"
                ]
            }
            let invoiceDetails = await apiCall(GET_INVOICES_LIST, { payload: req }, "POST", true);
            if (invoiceDetails.code == 200) {
                invoiceArr['data'] = invoiceDetails.data.Invoices[0];
                invoiceArr['type'] = 'invoice';
                invoiceArr['invoiceType'] = invoiceType;

            }
        }
    }

    return invoiceArr;

} 