import { updateJobTimer } from "helpers/backendHelper";
import moment from "moment";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  CLOCK_IN_JOB,
  CLOCK_IN_JOB_FAIL,
  CLOCK_IN_JOB_SUCCESS,
  CLOCK_OUT_JOB,
  CLOCK_OUT_JOB_FAIL,
  CLOCK_OUT_JOB_SUCCESS,
  PAUSE_TIME_JOB,
  PAUSE_TIME_JOB_FAIL,
  PAUSE_TIME_JOB_SUCCESS,
  RESUME_TIME_JOB,
  RESUME_TIME_JOB_FAIL,
  RESUME_TIME_JOB_SUCCESS,
} from "./actionTypes";

function* onClockIn() {
  try {
    const [job] = yield select((state) => state.JobDetails.jobBookedData);
    const requestPayload = {
      id: job.id,
      timer_status: "start",
      timer_start_time: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      job_stage: "Inprogress",
      job_status: "InProgress",
      clocked_in_time: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    const newJob = {
      ...job,
      ...requestPayload,
    };

    yield call(updateJobTimer, { payload: requestPayload });
    yield put({
      type: CLOCK_IN_JOB_SUCCESS,
      payload: newJob,
    });
  } catch (err) {
    yield put({
      type: CLOCK_IN_JOB_FAIL,
    });
  }
}

function* onClockOut({ payload }) {
  try {
    const [job] = yield select((state) => state.JobDetails.jobBookedData);
    const requestPayload = {
      timer_status: "stop",
      job_stage: "Completed",
      job_status: "ReadyForInvoice",
      timer_total_time: payload.timer_total_time,
      labour_time: payload.timer_total_time,
      clocked_off_time: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      id: job.id,
    };

    const newJob = {
      ...job,
      ...requestPayload,
    };

    yield call(updateJobTimer, { payload: requestPayload });
    yield put({
      type: CLOCK_OUT_JOB_SUCCESS,
      payload: newJob,
    });
  } catch (error) {
    yield put({
      type: CLOCK_OUT_JOB_FAIL,
    });
  }
}

function* onClockPauseTime({ payload }) {
  try {
    const [job] = yield select((state) => state.JobDetails.jobBookedData);
    const requestPayload = {
      id: job.id,
      timer_status: "pause",
      job_stage: "Inprogress",
      job_status: "OnHold",
      timer_total_time: payload.timer_total_time,
    };
    const newJob = {
      ...job,
      ...requestPayload,
    };
    yield call(updateJobTimer, { payload: requestPayload });
    yield put({
      type: PAUSE_TIME_JOB_SUCCESS,
      payload: newJob,
    });
  } catch (err) {
    yield put({
      type: PAUSE_TIME_JOB_FAIL,
    });
  }
}

function* onClockResumeTime() {
  try {
    const [job] = yield select((state) => state.JobDetails.jobBookedData);
    const requestPayload = {
      id: job.id,
      timer_status: "start",
      timer_start_time: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      job_stage: "Inprogress",
      job_status: "InProgress",
    };
    const newJob = {
      ...job,
      ...requestPayload,
    };

    yield call(updateJobTimer, { payload: requestPayload });
    yield put({
      type: RESUME_TIME_JOB_SUCCESS,
      payload: newJob,
    });
  } catch (err) {
    yield put({
      type: RESUME_TIME_JOB_FAIL,
    });
  }
}

export default function* clockTimerSaga() {
  yield takeLatest(CLOCK_IN_JOB, onClockIn);
  yield takeLatest(CLOCK_OUT_JOB, onClockOut);
  yield takeLatest(PAUSE_TIME_JOB, onClockPauseTime);
  yield takeLatest(RESUME_TIME_JOB, onClockResumeTime);
}
