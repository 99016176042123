import Loadable from "react-loadable";

import { Loading } from "../../../common/navigation";

const HQSupplierList = Loadable({
  loader: () => import("./components/SupplierHeader"),
  loading: Loading,
});

const HQCreateSupplier = Loadable({
  loader: () => import("./components/CreateHQSupplier"),
  loading: Loading,
});

const HQEditSupplier = Loadable({
  loader: () => import("./components/EditHQSupplier"),
  loading: Loading,
});

export const routes = [
  {
    path: "/suppliers",
    exact: true,
    name: "suppliers",
    element: HQSupplierList,
  },
  {
    path: "/create-supplier",
    exact: true,
    name: "create-suppliers",
    element: HQCreateSupplier,
  },
  {
    path: "/edit-supplier/:id",
    exact: true,
    name: "edit-suppliers",
    element: HQEditSupplier,
  },
];

export * from "./components";
