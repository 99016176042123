import { useSelector } from "react-redux";
import { useSecurityProvider } from "routes/SecurityProvider";

export default function useRoleLink() {
  const currentSecurity = useSecurityProvider();
  const { is_inspection_business } = useSelector((state) => state.LoginReducer);

  const filterCondition = (item) => {
    let multiMods = false;
    if (item.mods) {
      const keys = Object.keys(currentSecurity);
      const mods = item.mods.map((c) => `'${c}'`);
      multiMods = mods.some((c) => keys.includes(c));
    }
    if (item.mod === "subs") {
      return +is_inspection_business === 1;
    }
    if (+currentSecurity?.role_id === 2 || +currentSecurity?.role_id === 12) {
      return true;
    }
    if (item.mod === "global") {
      return true;
    }
    return !!currentSecurity[`'${item.mod}'`] || multiMods;
  };

  return { filterCondition };
}
