import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIA2HR5ROK6ITNVW6PH",
  secretAccessKey: "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",
});

const S3_BUCKET = "5iq-website-uploads";
const REGION = "ap-southeast-2";

const myBucket = new AWS.S3({
  params: {
    Bucket: S3_BUCKET,
  },
  region: REGION,
});

export function UploadFileToS3(file, filename) {
  
  var params = {
    ACL: "public-read",
    ContentType: file.type,
    Body: file,
    Bucket: S3_BUCKET,
    Key: filename,
  };

  myBucket.putObject(params, function (err, data) {
    if (err) {
      return err;
    } else {
      return filename;
    }
  });
}

export function deleteFileToS3(filename) {
  var params = {
    Bucket: S3_BUCKET,
    Key: filename,
  };

  myBucket.deleteObject(params, function (err, data) {
    if (err) {
      return err;
    } else {
      return filename;
    }
  });
}

export function downloadFileFromS3(bucketKey, filename) {
  var s3 = new AWS.S3();
  const signedUrlExpireSeconds = 60 * 5;
  const obj = {
    Bucket: S3_BUCKET,
    Key: bucketKey,
    Expires: signedUrlExpireSeconds,
  };
  if (filename) {
    obj["ResponseContentDisposition"] = `attachment; filename="${filename}"`;
  }
  var Url = s3.getSignedUrl("getObject", obj);
  return Url;
}

export async function uploadS3(file) {
  return new Promise((resolve, reject) => {
    myBucket.upload(
      {
        Bucket: S3_BUCKET,
        Key: Date.now() + "_" + file.name,
        Body: file,
        ContentType: file.type,
        ACL: "public-read",
      },
      function (err, data) {
        if (err) {
          reject(err);
        }

        // pass file unique id back to filepond
        resolve(data);
      }
    );
  });
}

export async function S3ImageShow(path) {
  return new Promise(async (res, rej) => {
    const signedUrlExpireSeconds = 60 * 5;
    let url = await myBucket.getSignedUrl("getObject", {
      Bucket: S3_BUCKET,
      Key: path,
      Expires: signedUrlExpireSeconds,
    });
    res(url);
  });
  // return imageUrl;
}

const awsS3Functions = {
  UploadFileToS3,
  downloadFileFromS3,
  S3ImageShow,
};

export default awsS3Functions;
