/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import gopayicon from "assets/images/gopay-favicon.ico";
const DropdownMenu = ({
  onEdit,
  onPayment,
  onMail,
  onSend,
  onPrint,
  onGoPay,
  onDelete,
  onView,
  onCreditClick,
  onSms
}) => {
  return (
    <div className="btn-group toggle-hover">
      <button className="btn " type="button">
        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
      </button>
      <div className="dropdown-list">
        {onView && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onView();
              }}
              className=""
              title="View"
            >
              <i className="fa fa-eye text-info"></i>
              <span>View</span>
            </a>
          </li>
        )}

        {onEdit && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onEdit();
              }}
              className=""
              title="EDIT"
            >
              <i className="fa fa-pencil text-info"></i>
              <span>Edit</span>
            </a>
          </li>
        )}
        {onPayment && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onPayment();
              }}
              className=" "
              title="Payment"
            >
              <i
                className="fa fa-dollar-sign"
                style={{
                  color: "#1cc9e4",
                  fontWeight: 700,
                  marginRight: "10px",
                }}
              >
                $
              </i>
              <span>Payment</span>
            </a>
          </li>
        )}

        {onMail && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onMail();
              }}
              className=" "
              title="Email"
            >
              <i className="fa fa-envelope text-info"></i>
              <span>Email</span>
            </a>
          </li>
        )}


        {onSend && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSend();
              }}
              className=" "
              title="Send"
            >
              <i className="fa fa-envelope text-info"></i>
              <span>Send</span>
            </a>
          </li>
        )}

        {onSms && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSms();
              }}
              className=" "
              title="SMS"
            >
              <span class="icon-wrapper"><label class="smsText">SMS</label></span>
              {/* <i className="fa fa-envelope text-info"></i> */}
              <span>SMS</span>
            </a>
          </li>
        )}

        {onPrint && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onPrint();
              }}
              className=" "
              title="Print"
            >
              <i className="fa fa-print text-info"></i>
              <span>Print</span>
            </a>
          </li>
        )}
        {onCreditClick && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onCreditClick();
              }}
              className=""
              title="Credit"
            >
              <i className="fa fa-credit-card text-info"></i>
              <span>Credit</span>
            </a>
          </li>
        )}
        {onGoPay && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onGoPay();
              }}
              className=" "
              title="Go Pay"
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
                src={gopayicon}
                alt="Gopay icon"
              />
              <span>Go Pay</span>
            </a>
          </li>
        )}
        {onDelete && (
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete();
              }}
              className="delete-link"
              title="Delete"
            >
              <span className="red-bg">
                <i className="fa fa-minus"></i>
              </span>
              <span>Delete</span>
            </a>
          </li>
        )}
      </div>
    </div>
  );
};

export default DropdownMenu;
