import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ERROR_ACTION, WAIT_FOR_ACTION } from "redux-wait-for-action";
import Loader from "views/common/UI/Loader";
import LoaderImage from "../../../../src/assets/images/page-loader.svg";
import logo from "assets/images/5iQ_logo_black.png";
import Alert from "react-bootstrap/Alert";

import {
  GET_LOGIN,
  GET_LOGIN_FAIL,
  GET_LOGIN_SUCCESS,
  SET_EXPIRED,
} from "../../../store/login/actionTypes";
import { GET_SUBSCRIPTION } from "../../../store/subscription/actionTypes";
import { getWorkShopSmsSubscriptionDetailsList } from "../../../store/subscription/actions";
import moment from "moment";
import { isEmpty } from "underscore";
import AutoLogoutPopup from "./AutoLogoutPopup";

const UserLogin = () => {
  const [showAutoLogout, setShowAutoLogout] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [registerStatus, setRegisterStatus] = useState();
  const [show, setShow] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  var expiry_date = moment().format("YYYY-MM-DD");
  var current_date = moment().format("YYYY-MM-DD");

  const { userData } = useSelector((state) => state.LoginReducer);
  const { isFetching, subscribeData } = useSelector(
    (state) => state.SubscriptionReducer
  );

  useEffect(() => {
    document.title = "Login | 5iQ";
    const hash = location.hash;
    setRegisterStatus(hash.split("#")[1]);
    removeHash(hash.split("#")[1]);
  }, [location.hash]);

  function removeHash(k) {
    var url = window.location.href;
    url = url.split("#");
    const nextURL = url[0];
    const nextTitle = "Login | 5iQ";
    const nextState = {};
    window.history.replaceState(nextState, nextTitle, nextURL);
  }

  useEffect(() => {
    if (userData) {
      if (userData.role_id != 12) {
        dispatch({
          type: GET_SUBSCRIPTION,
          payload: {
            get: "all",
            conditions: {
              workshop_id: userData.workshop.id,
              "WorkshopSubscription.status": 1,
              "WorkshopSubscription.is_deleted": 0,
            },
            contain: ["WorkshopSubscriptionDetails"],
            limit: 1,
            order: {
              id: "desc",
            },
          },
        });
      } else {
        navigate("/booking-diary");
      }
    } else {
      setShouldShow(true);
    }
  }, [userData, navigate]);

  const dispatch = useDispatch();
  function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      setIsLoading(true);
      dispatch({
        type: GET_LOGIN,
        payload: {
          email: email,
          password: password,
        },
        [WAIT_FOR_ACTION]: GET_LOGIN_SUCCESS,
        [ERROR_ACTION]: GET_LOGIN_FAIL,
      })
        .then(() => {
          // setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    setValidated(true);
  }

  useEffect(() => {
    if (isFetching === true) {
      if (subscribeData != null && !isEmpty(subscribeData)) {
        expiry_date = moment(subscribeData.plan_expiry_date).format(
          "YYYY-MM-DD"
        );
        if (expiry_date >= current_date) {
          dispatch(getWorkShopSmsSubscriptionDetailsList());
          navigate("/booking-diary");
          localStorage.setItem("isShowTrial", true);
          localStorage.setItem("isPlanExpire", false);
        } else {
          dispatch(getWorkShopSmsSubscriptionDetailsList());
          navigate("/subscription-plan");
          localStorage.setItem("isPlanExpire", true);
          dispatch({
            type: SET_EXPIRED,
          });
        }
      } else {
        if (
          userData?.workshop?.trial_expiry_date !== undefined &&
          userData?.workshop?.trial_expiry_date
        ) {
          var trial_date = moment(userData.workshop.trial_expiry_date);
          var today_date = moment(current_date);

          var days_diff = trial_date.diff(today_date, "days");
          if (days_diff > 0) {
            dispatch(getWorkShopSmsSubscriptionDetailsList());
            navigate("/booking-diary");
            localStorage.setItem("isPlanExpire", false);
            localStorage.setItem("isShowTrial", true);
          } else {
            dispatch(getWorkShopSmsSubscriptionDetailsList());
            navigate("/subscription-plan");
            localStorage.setItem("isPlanExpire", true);
            dispatch({
              type: SET_EXPIRED,
            });
          }
        }
      }
    }
  }, [subscribeData, isFetching]);

  useEffect(() => {
    const wasAutoLoggedOut = localStorage.getItem("autoLoggedOut");
    if (wasAutoLoggedOut) {
      setShowAutoLogout(true);
    }
  }, []);

  const handleAutoLogoutConfirm = () => {
    setShowAutoLogout(false)
    localStorage.removeItem("autoLoggedOut")
  }

  if (!shouldShow) return <Loader />;

  return (
    <>
      <div className="pre-login-wrapper">
        {/* main div section START */}
        <div className="row">
          {/* left content START */}
          <div className="column left-column">
            <img className="logo" src={logo} alt=""></img>
            <p className="mt-4"> "Together Let's Shape Tomorrow" </p>
          </div>
          {/* left content END */}

          {/* right content START */}
          <div className="column right-column">
            <div>
              {registerStatus === "invalid" && show && (
                <Alert
                  variant="danger"
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <p>Token is invalid.</p>
                </Alert>
              )}
              {registerStatus === "success" && show && (
                <Alert
                  variant="success"
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <p>Your account is activated now.</p>
                </Alert>
              )}
              {registerStatus === "failed" && show && (
                <Alert
                  variant="danger"
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <p>Your account is not activated. Please, try again.</p>
                </Alert>
              )}
              {registerStatus === "register" && show && (
                <Alert
                  variant="success"
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <p>
                    We sent you an email. Please validate to Login to your
                    account.
                  </p>
                </Alert>
              )}
            </div>
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
              <h1 className="mb-2">Login to your account</h1>

              <Form.Group className="form-group mb-3 has-fa-icon">
                {/* <Form.Label>Email</Form.Label> */}
                <Form.Control
                  values={email}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  type="email"
                  placeholder="Email"
                  onBlur={(e) => setEmail(e.target.value)}
                />
                <span className="fa fa-envelope"></span>
                <Form.Control.Feedback type="invalid">
                  Please Enter Email
                </Form.Control.Feedback>
                {/* <div className="d-flex justify-flex-end">
                  <Link
                    to="/forgot-email"
                    className="text-right secondary-link mt-1"
                  >
                    Forgot Email
                  </Link>
                </div> */}
              </Form.Group>
              <Form.Group className="form-group mb-3 has-fa-icon">
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control
                  autoComplete="off"
                  required
                  values={email}
                  onChange={(e) => setPassword(e.target.value)}
                  type={passwordShow ? "text" : "password"}
                  placeholder="Password"
                  onBlur={(e) => setPassword(e.target.value)}
                />

                <span
                  style={{ cursor: "pointer" }}
                  onClick={(e) => setPasswordShow(!passwordShow)}
                  className={passwordShow ? "fa fa-eye-slash" : "fa fa-eye"}
                ></span>

                <Form.Control.Feedback type="invalid">
                  Please Enter Password
                </Form.Control.Feedback>
                <div className="d-flex justify-flex-end forgot-password">
                  <Link
                    to="/forgot-password"
                    className="text-right secondary-link mt-3"
                  >
                    Forgot Password ?
                  </Link>
                </div>
              </Form.Group>
              <div className="btn-wrapper">
                <Button type="submit" variant="primary" className="primary-btn">
                  LOGIN
                </Button>
              </div>
            </Form>

            <div className="pre-login-footer mt-3">
              <p className="mb-2">
                By
                <Link to="/register" className="primary-link">
                  signing up
                </Link>
                for 5iQ account, you accept 5iQ
              </p>
              <p>
                <a
                  href={"https://fiiviq.com/terms-conditions"}
                  className="primary-link"
                >
                  Terms of Use
                </a>
                <span>and</span>
                <a
                  href={"https://fiiviq.com/privacy-policy"}
                  className="primary-link"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
          {/* right content END */}
        </div>
        {/* main div section END */}
      </div>

      {showAutoLogout && <AutoLogoutPopup positiveAction={handleAutoLogoutConfirm} />}

      {isLoading === true && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
    </>
  );
};

//  UserLogin.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
export default UserLogin;
