import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import cashSaleBlue from "../../../assets/images/cash-sale-blue.svg";
import LostQuotes from "./LostQuotes";
import OpenQuotes from "./OpenQuotes";
import WonQuotes from "./WonQuotes";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { searchQuoteJobsSuccess } from "store/commonApiStore/actions";
import { useDispatch } from "react-redux";
import CopyQuoteConfirmationPopup from "views/modals/CopyQuoteConfirmationPopup";

let QuoteList = () => {
  const [activeTab, setActiveTab] = useState("assigned-job");
  const location = useLocation();
  const dispatch = useDispatch();
  const [copyQuoteConfirmation, setCopyQuoteConfirmation] = useState();

  useEffect(() => {
    document.title = "5iQ | Quotes";
    
    const hash = location.hash;
    if(hash.split("#")[1] == "copy"){
      setCopyQuoteConfirmation(true)
      setActiveTab("open-quotes");
    }else{
      setActiveTab(hash.split("#")[1]);
    }
    
    var url = window.location.href;
    url = url.split('#');
    const nextURL = url[0];
    const nextTitle = "Purchases | 5iQ";
    const nextState = {};
    window.history.replaceState(nextState, nextTitle, nextURL);
  }, [location.hash]);

  dispatch(searchQuoteJobsSuccess({}));

  
  
  return (
    <div className="right-section-wrapper">
      <div className="table-header">
        <div className="table-header-tabs">
          <Tab.Container
            defaultActiveKey="open-quotes"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
          >
            <div className="row top-block">
              <div className="col-md-2">
                <div className="table-title">
                  <img src={cashSaleBlue} alt=""></img>
                  <span>QUOTES</span>
                </div>
              </div>
              <div className="col-md-8">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="open-quotes">Open Quotes</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="won-quotes">Won Quotes</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="lost-quotes">Lost Quotes</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-2">
                <div className="video-link-outer">
                  <div className="video-tutorial">
                    <i className="fa fa-icon"></i>
                    {/* <span className="help-text">Help</span> */}
                    <Link to="/trainee-video#quotes" ><span className="tutorial-title">Quotes Videos</span></Link>
                  </div>
                </div>
              </div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="open-quotes">
                <OpenQuotes activeTab={activeTab} />
              </Tab.Pane>
              <Tab.Pane eventKey="won-quotes">
                <WonQuotes activeTab={activeTab} />
              </Tab.Pane>
              <Tab.Pane eventKey="lost-quotes">
                <LostQuotes activeTab={activeTab} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          {copyQuoteConfirmation && (
            <CopyQuoteConfirmationPopup
              copyQuoteConfirmation={copyQuoteConfirmation}
              setCopyQuoteConfirmation={setCopyQuoteConfirmation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuoteList;
