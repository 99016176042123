import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import BNPLPayment from "./BNPLPayment";
import CardPaymentModal from "./CardPaymentModal";
import CashPaymentModal from "./CashPaymentModal";
import ChequePaymentModal from "./ChequePaymentModal";
import PaymentConfirmationModal from "./PaymentConfirmationModal";
import PaymentModal from "./PaymentModal";

export const PaymentInfoContext = React.createContext(null);

const PaymentInfoProvider = ({
  summary,
  hideModal,
  payment,
  setOpenedModal,
  openedModal,
  onChangeClick,
  previousPaidInvoice,
  jobStage = ""
}) => {
  
  const [paymentAmount, setPaymentAmount] = useState();
  const [processingFees, setProcessingFees] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const confirmationCloseModal = () => setConfirmationModal(false);

  const [request, setRequest] = useState({
    workshop_id: getWorkshopId(),
    invoice_id: "",
    amount_paid: "0.00",
    amount_due: "0.00",
    balance_remaining: "0.00",
    description: "",
    invoice_type: "1",
    payment_method: 3,
    // "card_type": 1,
    confirmation: "",
    card_last_digit: "",
    payment_processing_fee: "0.00",
    notes: "",
    payment_date: moment().format("YYYY-MM-DD"),
    receipt_total: "0.00",
    created_by: getSessionData("user_id"),
    type: "",
    cheque_number: "",
    remain_payment: "",
    previou_bill_paid: false,
  });

  useEffect(() => {
    setPaymentAmount(+summary.payment_amount);
  }, [summary]);

  let paymentProcessingFees = request.payment_processing_fee;

  useEffect(() => {
    if (payment?.recieptDetail?.data) {
      const processingFee = parseFloat(paymentAmount) * processingFees;
      const recieptTotal =
        parseFloat(paymentAmount) + parseFloat(paymentAmount) * processingFees;
      let invoice_type = "1";
      let invoice_id = "0";
      if (payment?.recieptDetail?.data?.type === "credits") {
        invoice_type = payment?.recieptDetail?.data?.data?.invoice?.tax_invoice;
        invoice_id = payment?.recieptDetail?.data?.data?.invoice?.id;
      } else {
        invoice_type = payment?.recieptDetail?.data?.data?.tax_invoice;
        invoice_id = payment?.recieptDetail?.data?.data?.id;
      }

      // let amount_due = parseFloat(summary.total_due).toFixed(2);

      // invoice_type  
      let amount_due = parseFloat(summary.total_due).toFixed(2);
      let amount_paid = paymentAmount;
      let payment_amount = summary.payment_amount;
      let receipt_total = recieptTotal.toFixed(2);
      let remaining = summary?.remaining;
      let insurance_invoice_total = payment?.recieptDetail?.data?.data?.insurance_invoice_total;
      let customer_invoice_total = payment?.recieptDetail?.data?.data?.customer_invoice_total;
      let customer_invoice_allocation_total = payment?.recieptDetail?.data?.data?.customer_invoice_allocation_total;

      if (invoice_type === "4") {
        if (payment?.recieptDetail?.data?.data?.insurance_preview_invoice === 1) {
          amount_due = insurance_invoice_total;
          remaining = 0;
          amount_paid = insurance_invoice_total;
          payment_amount = insurance_invoice_total;
          receipt_total = insurance_invoice_total;
        }

        if (payment?.recieptDetail?.data?.data?.customer_preview_invoice === 1) {
          amount_due = customer_invoice_total;
          remaining = 0;
          amount_paid = customer_invoice_total;
          payment_amount = customer_invoice_total;
          receipt_total = customer_invoice_total;
        }

        if (payment?.recieptDetail?.data?.data?.customer_invoice_allocation_preview === 1) {
          amount_due = customer_invoice_allocation_total;
          remaining = 0;
          amount_paid = customer_invoice_allocation_total;
          payment_amount = customer_invoice_allocation_total;
          receipt_total = customer_invoice_allocation_total;
        }
      }

      setRequest((prev) => {
        return {
          ...prev,
          workshop_id: +getWorkshopId(),
          invoice_id: invoice_id?.toString(),
          amount_paid: amount_paid,
          amount_due: parseFloat(amount_due).toFixed(2),
          balance_remaining:
            parseFloat(payment_amount) - parseFloat(amount_paid),
          description: "",
          invoice_type: invoice_type,
          payment_processing_fee: !isNaN(processingFee) ? processingFee.toFixed(2) : 0,
          payment_date: moment().format("YYYY-MM-DD"),
          receipt_total: receipt_total,
          created_by: +getSessionData("user_id"),
          type: payment?.recieptDetail?.data?.invoiceType,
          remain_payment: payment?.recieptDetail?.data?.data?.remain_balance || 0,
          previou_bill_paid: previousPaidInvoice,
          job_stage:jobStage,
        };        
      });
    }
  }, [payment?.recieptDetail?.data, summary, paymentAmount, processingFees]);

  useEffect(() => {
    let processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(paymentProcessingFees))/100);
    setRequest((prev) => {
      return {
        ...prev,
        // payment_processing_fee:processingFeesValue,
        receipt_total:parseFloat(parseFloat(processingFeesValue) + parseFloat(paymentAmount)).toFixed(2)
      }
    })
  },[paymentProcessingFees])
  
  useEffect(() => {

    if(summary){
      if(openedModal == "statementPayment"){
        const processingFeeStatement = parseFloat(paymentAmount) * processingFees;
        setRequest((prev) => {
          return {
            ...prev,
            workshop_id: +getWorkshopId(),
            invoice_id: summary?.invoice_ids,
            amount_paid: summary?.total_due,
            amount_due: 0,
            balance_remaining:0,
            description: "",
            invoice_type: 2,
            payment_processing_fee: !isNaN(processingFeeStatement) ? processingFeeStatement.toFixed(2) : 0,
            payment_date: moment().format("YYYY-MM-DD"),
            receipt_total: summary?.total_due,
            created_by: +getSessionData("user_id"),
            // type: payment?.recieptDetail?.data?.invoiceType,
            remain_payment: 0,
            previou_bill_paid: previousPaidInvoice,
            statementIds:summary?.ids,
            paymentType:openedModal,
            invoiceStatementTotal:summary?.invoiceStatementTotal,
            statement_id:summary?.statementId
          };
        });
      }
    }
  },[summary,openedModal])

  useEffect(() => {
    if(previousPaidInvoice){
      setRequest((prev) => {
        return {
          ...prev,          
          previou_bill_paid: previousPaidInvoice          
        };
      });
    }
  },[previousPaidInvoice])

  useEffect(() => {
    let payment_method;
    switch (openedModal) {
      case "card-payment":
        payment_method = "3";
        break;
      case "cash-payment":
        payment_method = "1";
        break;
      case "bnpl-payment":
        payment_method = "4";
        break;
      case "cheque-payment":
        payment_method = "5";
        break;
      default:
        break;
    }

    setRequest((prev) => {
      if (openedModal === "bnpl-payment") {
        prev.payment_type = "4";
      }
      if (prev?.payment_type && openedModal !== "bnpl-payment") {
        delete prev.payment_type;
      }
      if (prev?.card_type && openedModal !== "card-payment") {
        delete prev.card_type;
      }

      return {
        ...prev,
        payment_method,
        description: "",
        confirmation: "",
        card_last_digit: "",
        notes: "",
      };
    });
  }, [openedModal]);

  return (
    <PaymentInfoContext.Provider
      value={{
        ...summary,
        paymentAmount,
        setPaymentAmount,
        request,
        setRequest,
        processingFees,
        setProcessingFees,
        disableInput: payment.recieptDetail?.data?.invoiceType === "cash_sale",
      }}
    >
      <PaymentModal
        onClose={hideModal}
        onPaymentMethodClick={(k) => {
          setOpenedModal(k);
        }}
        onConfirmationMethod={(k) => {
          setConfirmationModal(true);
        }}
        show={(openedModal === "payment" && !!payment?.recieptDetail?.data) || openedModal === "statementPayment"}
      />
      <ChequePaymentModal
        show={openedModal === "cheque-payment"}
        onChangeClick={onChangeClick}
        onClose={hideModal}
        isChequePayment={true}
      />
      { openedModal == "card-payment" && 
        <CardPaymentModal
          show={openedModal === "card-payment"}
          onChangeClick={onChangeClick}
          onClose={hideModal}
        />
      }
      <CashPaymentModal
        show={openedModal === "cash-payment"}
        onChangeClick={onChangeClick}
        onClose={hideModal}
      />
      { openedModal == "bnpl-payment" && 
      <BNPLPayment
        show={openedModal === "bnpl-payment"}
        onChangeClick={onChangeClick}
        onClose={hideModal}
      />}

      {confirmationModal &&
        <PaymentConfirmationModal
          showModal={confirmationModal}
          closeModal={confirmationCloseModal}
          setOpenedModal={setOpenedModal}
        />
      }

    </PaymentInfoContext.Provider>
  );
};

export default PaymentInfoProvider;

export function usePaymentInfo() {
  return useContext(PaymentInfoContext);
}
