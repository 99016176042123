import { actionChannel } from "redux-saga/effects";
import * as ACTIONS from "./actionType";
const initialState = {
  invoiceCreate: null,
  loading: true
};
export const invoiceDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCreate: action.payload,
        invoice_id: null,
      };
    case ACTIONS.GET_INVOICE_REST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCreate: {
          ...state.invoiceCreate,
          ...action.payload,
          restLoaded: true,
        },
        invoice_id: null,
      };
    case ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        invoice_id: null,
        invoiceCreate: {
          redirect: true,
          location: "/invoice",
        },
      };
    case ACTIONS.CREATE_INVOICE:
      return {
        ...state,
        creating: true,
      };
    case ACTIONS.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        creating: false,
        invoice_id: action.payload,
      };
    case ACTIONS.CREATE_INVOICE_FAIL:
      return {
        ...state,
        creating: false,
        invoice_id: null,
      };

    case ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceEdit: action.payload,
      };    
    case ACTIONS.UPDATE_INVOICE_EMAIL_DETAILS:
      const currentInvoiceEdit = state?.invoiceEdit;    
      const currentInvoiceData = currentInvoiceEdit?.invoice_data;
    
      const updatedInvoiceData = {
        ...currentInvoiceData,
        email_template_id: action?.payload?.email_template_id,          
        email_description: action?.payload?.email_description,
      };
    
      const updatedInvoiceEdit = {
        ...currentInvoiceEdit,
        invoice_data: updatedInvoiceData,
      };
    
      return {
        ...state,
        invoiceEdit: updatedInvoiceEdit,
      };      
    case ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS.SEND_EMAIL:
      return {
        ...state,
        emailSent: "processing",
      };
    case ACTIONS.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        emailSent: "completed",
      };
    case ACTIONS.SEND_EMAIL_FAIL:
      return {
        ...state,
        emailSent: "failed",
      };

    case ACTIONS.SEND_STATEMENT_EMAIL:
      return {
        ...state,
        emailSent: "processing",
      };
    case ACTIONS.SEND_STATEMENT_EMAIL_SUCCESS:
      return {
        ...state,
        emailSent: "completed",
      };
    case ACTIONS.SEND_STATEMENT_EMAIL_FAIL:
      return {
        ...state,
        emailSent: "failed",
      };

    case ACTIONS.GET_INVOICE_CREDIT_INFO:
      return {
        ...state,
        loading: true,
        invoiceCredit: null,
      };
    case ACTIONS.GET_INVOICE_CREDIT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCredit: action.payload,
      };
    case ACTIONS.GET_INVOICE_CREDIT_INFO_FAIL:
      return {
        ...state,
        loading: false,
        invoiceCredit: null,
        invoiceCreditError: action.payload,
      };
    case ACTIONS.ADD_INVOICE_CREDIT:
      return {
        ...state,
        invoiceCreditCreating: "processing",
      };
    case ACTIONS.ADD_INVOICE_CREDIT_SUCCESS:
      return {
        ...state,
        invoiceCreditCreating: "success",
      };
    case ACTIONS.ADD_INVOICE_CREDIT_FAIL:
      return {
        ...state,
        invoiceCreditCreating: "failed",
      };
    case ACTIONS.GET_INVOCE_CREDIT_VIEW:
      return {
        ...state,
        loading: true,
        invoiceCreditView: null,
      };
    case ACTIONS.GET_INVOCE_CREDIT_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCreditView: action.payload,
      };
    case ACTIONS.GET_INVOCE_CREDIT_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        invoiceCreditView: null,
        invoiceCreditViewError: action.payload,
      };
    case ACTIONS.GET_INSURANCE_DETAILS:
      return {
        ...state,
        invoiceCreate: {
          ...state.invoiceCreate,
          insurance_detail: null,
        },
      };
    case ACTIONS.GET_INSURANCE_DETAILS_SUCCESS:
      return {
        ...state,
        invoiceCreate: {
          ...state.invoiceCreate,
          insurance_detail: action.payload,
        },
      };
    case ACTIONS.GET_INSURANCE_DETAILS_FAIL:
      return {
        ...state,
        invoiceCreate: {
          ...state.invoiceCreate,
          insurance_detail: null,
        },
      };
    case ACTIONS.GET_INVOICE_RECEIPT:
      return {
        ...state,
        loading: true,
        invoiceReceipt: null,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceReceipt: action.payload,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_FAIL:
      return {
        ...state,
        loading: false,
        invoiceReceipt: null,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_EMAIL:
      return {
        ...state,
        loading: true,
        invoiceReceiptEmail: null,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceReceiptEmail: action.payload,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        invoiceReceiptEmail: null,
      };
    case ACTIONS.GET_INVOICE_CREDIT_EMAIL:
      return {
        ...state,
        loading: true,
        invoiceCreditEmail: null,
      };
    case ACTIONS.GET_INVOICE_CREDIT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCreditEmail: action.payload,
      };
    case ACTIONS.GET_INVOICE_CREDIT_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        invoiceCreditEmail: null,
      };

    case ACTIONS.GET_CASH_SALE_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.GET_CASH_SALE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        cashSale: action.payload,
      };
    case ACTIONS.GET_CASH_SALE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        cashSale: null,
      };
    case ACTIONS.SAVE_CASH_SALE_DETAILS: {
      return {
        ...state,
        cashSaleId: null,
        creating: true,
      };
    }
    case ACTIONS.SAVE_CASH_SALE_DETAILS_SUCCESS:
      return {
        ...state,
        cashSaleId: action.payload,
        creating: false,
      };
    case ACTIONS.SAVE_CASH_SALE_DETAILS_FAIL:
      return {
        ...state,
        cashSaleId: "fail",
        creating: false,
      };
    case ACTIONS.GET_EDIT_CASH_SALE_DETAIL:
      return {
        ...state,
        editCashSaleLoading: true,
        editCashSale: null,
      };
    case ACTIONS.GET_EDIT_CASH_SALE_DETAIL_SUCCESS:
      return {
        ...state,
        editCashSaleLoading: false,
        editCashSale: action.payload,
      };
    case ACTIONS.GET_EDIT_CASH_SALE_DETAIL_FAIL:
      return {
        ...state,
        editCashSaleLoading: false,
        editCashSale: null,
      };
    case ACTIONS.DELETE_CASH_SALE_PART:
      return {
        ...state,
        deleting: "processing",
      };
    case ACTIONS.DELETE_CASH_SALE_PART_SUCCESS:
      return {
        ...state,
        deleting: "completed",
      };
    case ACTIONS.DELETE_CASH_SALE_PART_FAIL:
      return {
        ...state,
        deleting: "failed",
      };

    case ACTIONS.GET_CASH_SALE_CREDIT_RECEIPT:
      return {
        ...state,
        cashSaleLoading: true,
        cashSaleCreditReceipt: null,
      };
    case ACTIONS.GET_CASH_SALE_CREDIT_RECEIPT_SUCCESS:
      return {
        ...state,
        cashSaleLoading: false,
        cashSaleCreditReceipt: action.payload,
      };
    case ACTIONS.GET_CASH_SALE_CREDIT_RECEIPT_FAIL:
      return {
        ...state,
        cashSaleLoading: false,
        cashSaleCreditReceipt: null,
      };

    case ACTIONS.VIEW_CASH_SALE_RECEIPT:
      return {
        ...state,
        cashSaleLoading: true,
        viewCashSaleReceipt: null,
      };
    case ACTIONS.VIEW_CASH_SALE_RECEIPT_SUCCESS:
      return {
        ...state,
        cashSaleLoading: false,
        viewCashSaleReceipt: action.payload,
      };
    case ACTIONS.VIEW_CASH_SALE_RECEIPT_FAIL:
      return {
        ...state,
        cashSaleLoading: false,
        viewCashSaleReceipt: null,
      };

    case ACTIONS.GET_CASH_SALE_EMAIL_RECEIPT:
      return {
        ...state,
        cashSaleLoading: true,
        cashSaleEmail: null,
      };
    case ACTIONS.GET_CASH_SALE_EMAIL_RECEIPT_SUCCESS:
      return {
        ...state,
        cashSaleLoading: false,
        cashSaleEmail: action.payload,
      };
    case ACTIONS.GET_CASH_SALE_EMAIL_RECEIPT_FAIL:
      return {
        ...state,
        cashSaleLoading: false,
        cashSaleEmail: null,
      };

    case ACTIONS.VIEW_CASH_SALE_CREDIT:
      return {
        ...state,
        cashSaleLoading: true,
        cashSaleCredit: null,
      };
    case ACTIONS.VIEW_CASH_SALE_CREDIT_SUCCESS:
      return {
        ...state,
        cashSaleLoading: false,
        cashSaleCredit: action.payload,
      };
    case ACTIONS.VIEW_CASH_SALE_CREDIT_FAIL:
      return {
        ...state,
        cashSaleLoading: false,
        cashSaleCredit: null,
      };

    case ACTIONS.CASH_SALE_CREDIT_EMAIL:
      return {
        ...state,
        cashSaleLoading: true,
        cashSaleCreditEmail: null,
      };
    case ACTIONS.CASH_SALE_CREDIT_EMAIL_SUCCESS:
      return {
        ...state,
        cashSaleLoading: false,
        cashSaleCreditEmail: action.payload,
      };
    case ACTIONS.CASH_SALE_CREDIT_EMAIL_FAIL:
      return {
        ...state,
        cashSaleLoading: false,
        cashSaleCreditEmail: null,
      };
    case ACTIONS.CREATE_INVOICE_UNMOUNT:
      return {
        loading: true,
        invoiceCreate: null,
      };
    case ACTIONS.CASH_SALE_CREDITS_SAVE:
      return {
        ...state,
        cashSaleCreditCreating: "processing",
      };
    case ACTIONS.CASH_SALE_CREDITS_SAVE_SUCCESS:
      return {
        ...state,
        cashSaleCreditCreating: "success",
      };
    case ACTIONS.CASH_SALE_CREDITS_SAVE_FAIL:
      return {
        ...state,
        cashSaleCreditCreating: "failed",
      };

    case ACTIONS.SEND_CASHSALE_EMAIL:
      return {
        ...state,
        emailSent: "processing",
      };
    case ACTIONS.SEND_CASHSALE_EMAIL_SUCCESS:
      return {
        ...state,
        emailSent: "completed",
      };
    case ACTIONS.SEND_CASHSALE_EMAIL_FAIL:
      return {
        ...state,
        emailSent: "failed",
      };

    case ACTIONS.GET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceLoading: true,
        invoiceDetails: null,
      };
    case ACTIONS.GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceLoading: false,
        invoiceDetails: action.payload,
      };
    case ACTIONS.GET_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        invoiceLoading: false,
        invoiceDetails: null,
      };

    case ACTIONS.GET_INVOICE_CASHSALE_DETAIL:
      return {
        ...state,
        invoiceLoading: true,
        invoiceCashsaleDetails: null,
      };
    case ACTIONS.GET_INVOICE_CASHSALE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceLoading: false,
        invoiceCashsaleDetails: action.payload,
      };
    case ACTIONS.GET_INVOICE_CASHSALE_DETAIL_FAIL:
      return {
        ...state,
        invoiceLoading: false,
        invoiceCashsaleDetails: null,
      };

    default:
      return state;
  }
};
