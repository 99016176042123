import moment from "moment";
import { unserializeArr } from "./unserialize";

export const HQ_SESSION_STORAGE_BACKUP_KEY = "__backup_hq_data"

export function setSessionData(userData) {
  const sessionData = sessionStorage.getItem("stored_data");

  if (sessionData) {
    return JSON.parse(sessionData);
  } else {
    if (userData?.userData) {
      if (userData.token !== "" && userData.token !== undefined) {
        let user_id = "";
        let user_name = "";
        let ws_id = "";
        let hq_id = "";
        let ws_other_id = "";
        let ws_name = "";
        let ws_url = "";
        let theme_mode = "";
        let currency = "";
        let country = "";
        let state = "";
        let workshop_state = "";
        let suburb = "";
        let postcode = "";
        let api_token = "";
        let timezone_lable = "";
        let technician_timeslot = "";
        let schedule_details = "";
        let assets_type = "";
        let trial_expiry_date = "";
        let stripe_customer_id = ""
        let user_profile = "";
        let workshopLogo = "";
        let workshopPhone = "";
        let date_format = "";
        let time_format = "HH:mm";

        if (userData.role_id == 12) {
          user_id = window.btoa(userData.userData.id);
          user_name = window.btoa(
            `${userData.userData.firstname} ${userData.userData.lastname}`
          );
          hq_id = window.btoa(userData.userData.head_quarters.id);
          ws_name = window.btoa(userData.userData.head_quarters.name);
          currency = userData.userData.head_quarters.country.symbol;
          country = userData.userData.head_quarters.country.id;
          state = userData.userData.head_quarters.state?.id || 0;
          suburb = userData.userData.head_quarters.suburb;
          postcode = userData.userData.head_quarters.postcode;
          api_token = window.btoa(userData.token);
          timezone_lable = window.btoa(userData?.userData?.head_quarters?.timezone_label || "Asia/Kolkata");
          workshopLogo = userData?.userData?.head_quarters?.logo || "";
          date_format = "DD/MM/YYYY";
          time_format = "HH:mm";
        } else {
          user_id = window.btoa(userData.userData.id);
          user_name = window.btoa(
            `${userData.userData.firstname} ${userData.userData.lastname}`
          );
          ws_id = window.btoa(userData.userData.workshop_id);
          ws_other_id = window.btoa(
            userData.userData.workshop.workshop_other_details[0].id
          );
          ws_name = window.btoa(userData.userData.workshop.name);
          ws_url = userData.userData.workshop.url;
          theme_mode =
            userData.userData.workshop.workshop_other_details[0].is_light_mode;
          currency =
            userData.userData.workshop.workshop_other_details[0].country.symbol;
          country =
            userData.userData.workshop.workshop_other_details[0].country.id;
          state = userData?.userData?.workshop?.state?.id || 0;


          workshop_state = userData?.userData?.workshop?.state?.name;
          suburb = userData.userData.workshop.suburb;
          postcode = userData.userData.workshop.postcode;
          api_token = window.btoa(userData.token);
          timezone_lable = window.btoa(
            userData?.userData?.workshop?.workshop_other_details[0]
              ?.timezone_label || "Asia/Kolkata"
          );
          technician_timeslot = window.btoa(
            userData?.userData?.workshop?.workshop_other_details[0]
              .technician_timeslot || null
          );
          schedule_details = window.btoa(
            userData?.userData?.workshop?.workshop_other_details[0]
              .schedule_details || null
          );
          assets_type = userData?.userData?.workshop?.vehicle_types || [];

          trial_expiry_date = window.btoa(
            userData.userData.workshop.trial_expiry_date
              ? userData.userData.workshop.trial_expiry_date
              : moment().toDate()
          );
          stripe_customer_id = window.btoa(
            userData.userData.stripe_customer_id
          );
          user_profile = userData.userData.user_profile;

          workshopLogo = userData?.userData?.workshop?.logo || "";
          workshopPhone = userData?.userData?.workshop?.phone || "";

          date_format = userData?.userData?.workshop?.workshop_other_details?.[0]?.mobile_date_format || "DD/MM/YYYY";
          time_format = "HH:mm";
          if (userData?.userData?.workshop?.workshop_other_details?.[0]?.time_format === "h:i a" || userData?.userData?.workshop?.workshop_other_details?.[0]?.time_format === "hh:mm a") {
            time_format = "hh:mm a";
          }
        }


        // create object for session items
        const items = {
          hq_id: hq_id,
          user_id: user_id,
          user_name: user_name,
          workshop_id: ws_id,
          workshop_other_id: ws_other_id,
          workshop_name: ws_name,
          workshop_url: ws_url,
          token: api_token,
          theme_mode: theme_mode,
          currency: currency ? currency : "$",
          country: country ? country : 2,
          state: state,
          workshop_state: workshop_state,
          suburb: suburb,
          postcode: postcode,
          date_format: date_format,
          time_format: time_format,
          trial_expiry_date: trial_expiry_date,
          timezone_lable: timezone_lable,
          technician_timeslot: technician_timeslot,
          schedule_details: schedule_details,
          assets_type: (assets_type) ? unserializeArr(assets_type) : "",
          stripe_customer_id: stripe_customer_id,
          userProfile: user_profile,
          workshop_logo: workshopLogo,
          workshop_phone: workshopPhone
        };

        // set session of all items
        sessionStorage.setItem("stored_data", JSON.stringify(items));

        return items;
      }
    }
  }
}

/**
 * This function gets all the keys from `sessionStorage` and return them in an `object`. This function is useful to take backup of `sessionStorage`.
 * @returns Object
 */
export function dumpSessionStorage() {
  const backup = {}
  for (const key of Object.keys(sessionStorage)) {
    backup[key] = sessionStorage.getItem(key)
  }
  return backup
}

/**
* This function set key-values provided in an object in sessionStorage.
* @param {*} dump An key-value pair, preferably an object dumped using `dumpSessionStorage` function.
*/
export function restoreSessionStorage(dump) {
  if (typeof dump === "object" && !Array.isArray(dump) && dump !== null) {
    for (const [key, value] of Object.entries(dump)) {
      sessionStorage.setItem(key, value)
    }
  } else {
    throw new Error("Dump must be an object for restore process.")
  }
}


/**
 * This function removes all the keys from sessionStorage unless `preserveBackupKeys` parameter is `true` which will still remove all keys but any key which starts with `__backup` will be preserved.
 * @param {*} preserveBackupKeys `true` if you want to preserve backup key.
 */
export function destroySessionStorage(preserveBackupKeys = false) {
  if (preserveBackupKeys) {
    const keys = Object.keys(sessionStorage)
    for (const key of keys) {
      if (!key.startsWith("__backup")) {
        sessionStorage.removeItem(key)
      }
    }
  } else {
    sessionStorage.clear()
  }
}