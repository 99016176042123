import Loadable from "react-loadable";

import { Loading } from "../../../common/navigation";

const HQCustomerList = Loadable({
  loader: () => import("./components/CustomersList"),
  loading: Loading,
});

export const routes = [
  {
    path: "/customers",
    exact: true,
    name: "customers",
    element: HQCustomerList,
  },
];

export * from "./components";
