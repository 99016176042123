import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GET_INSPECTION_LIST } from "store/InspectionDetails/actionTypes";
import { getWorkshopId } from "utils/getWorkshopId";
import Loader from "views/common/UI/Loader";
import inspectionsBlue from "../../../assets/images/inspections-blue.svg";
import "../inspection.scss";
import InspectionsList from "./InspectionsList";

const Inspections = () => {
  document.title = "Inspections | 5iQ";
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_INSPECTION_LIST,
      payload: {
        get: "all",
        conditions: {
          "InspInspections.workshop_id": getWorkshopId(),
          "InspInspections.status": 1,
          "InspInspections.is_deleted": 0,
        },
        order: { "InspInspections.id": "desc" },
        contain: [
          "JobSchedules.Invoices",
          "JobSchedules.Customers",
          "JobSchedules.Customers.CustomersDetails",
          "Customers.CustomersDetails",
          "Vehicles",
          "InspTemplates",
        ],
      },
    });
  }, []);

  const { inspectionListData, loading } = useSelector(
    (state) => state.InspectionDetailsReducer
  );

  const pendingData = useMemo(() => {
    return inspectionListData.filter((data) => data.Insp_status === "Pending");
  }, [inspectionListData]);

  const sentData = useMemo(() => {
    return inspectionListData.filter((data) => data.Insp_status === "Sent");
  }, [inspectionListData]);

  const approvedData = useMemo(() => {
    return inspectionListData.filter((data) => data.Insp_status === "Approved");
  }, [inspectionListData]);

  const declinedData = useMemo(() => {
    return inspectionListData.filter((data) => data.Insp_status === "Declined");
  }, [inspectionListData]);

  if (loading) return <Loader />;

  return (
    <>
      <div className="right-section-wrapper inspection-wrapper">
        <div className="table-header">
          <div className="table-header-tabs">
            <div className="row top-block mb-2 align-center">
              <div className="col-md-2">
                <div className="d-flex align-items-center table-title">
                  <img className="hover-img" src={inspectionsBlue} alt=""></img>
                  <span>Inspections</span>
                </div>
              </div>
              <div className="col-md-8 d-flex justify-center">
                <div className="d-flex justify-content-center card direction-row p-r-15 p-l-15">
                  <Link
                    to="/inspections/list-inspection-by-status/pending"
                    state={{
                      statusWiseData: pendingData ? pendingData : [],
                      title: "Pending",
                    }}
                    className="rounded-widget d-flex direction-column justify-content-center warning-widget"
                  >
                    <div className="icon-wrapper d-flex justify-content-center">
                      <div className="icon-block d-flex align-center justify-content-center">
                        <i className="fa fa-edit"></i>
                      </div>
                    </div>
                    <span>{pendingData ? pendingData.length : 0} Pending</span>
                  </Link>
                  <Link
                    to="/inspections/list-inspection-by-status/sent"
                    state={{
                      statusWiseData: sentData ? sentData : [],
                      title: "Sent",
                    }}
                    className="rounded-widget d-flex direction-column justify-content-center primary-widget"
                  >
                    <div className="icon-wrapper d-flex justify-content-center">
                      <div className="icon-block d-flex align-center justify-content-center">
                        <i class="fa fa-send"></i>
                      </div>
                    </div>
                    <span>{sentData ? sentData.length : 0} Sent</span>
                  </Link>
                  <Link
                    to="/inspections/list-inspection-by-status/approved"
                    state={{
                      statusWiseData: approvedData ? approvedData : [],
                      title: "Approved",
                    }}
                    className="rounded-widget d-flex direction-column justify-content-center success-widget"
                  >
                    <div className="icon-wrapper d-flex justify-content-center">
                      <div className="icon-block d-flex align-center justify-content-center">
                        <i class="fa fa-check-square-o"></i>
                      </div>
                    </div>
                    <span>
                      {approvedData ? approvedData.length : 0} Approved
                    </span>
                  </Link>
                  <Link
                    to="/inspections/list-inspection-by-status/declined"
                    state={{
                      statusWiseData: declinedData ? declinedData : [],
                      title: "Declined",
                    }}
                    className="rounded-widget d-flex direction-column justify-content-center danger-widget"
                  >
                    <div className="icon-wrapper d-flex justify-content-center">
                      <div className="icon-block d-flex align-center justify-content-center">
                        <i class="fa fa-exclamation-triangle"></i>
                      </div>
                    </div>
                    <span>
                      {declinedData ? declinedData.length : 0} Declined
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-md-2">
                <div className="video-link-outer">
                  <div className="video-tutorial">
                    <i className="fa fa-icon"></i>
                    {/* <span className="help-text">Help</span> */}
                    <Link to="/trainee-video#inspection" ><span className="tutorial-title">Inspections Videos</span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InspectionsList inspectionListData={inspectionListData} />
      </div>
    </>
  );
};

export default Inspections;
