import {
  getInsurance,
  getInvoicesList,
  getJobCannedList,
  getJobEmailTemplateList,
  getJobNotes,
  getLabourChargesList,
  getUnassignedJobs,
  getWorkshopOtherDetailsView,
} from "helpers/backendHelper";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import {
  getBookedJobPayload,
  getInsurancePayload,
  getJobCannedListPayload,
  getJobNotesPayload,
  getLabourChargeListPayload,
  getWorkshopOtherDetailViewPayload,
  getMessageTemplatePayload
} from "../api";

export async function getInvoiceEditDetail(id) {
  let address,
    customer_detail,
    jobs,
    job_parts,
    customerdetail,
    labourname,
    parts = "",
    part_gst,
    labour_gst,
    purchase_gst,
    vehicle,
    scripts,
    job_type,
    invoice_data,
    remain_balance = 0,
    claim_amount = 0,
    inv_type = "Full",
    inv_number,
    all_invoices,
    last_invoices,
    jobnotes,
    comp_logo,
    job_labours,
    otherdata,
    defaultLabour,
    insurance_company,
    message_template;

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const workshop_id = getWorkshopId();

  // const request1 = {
  //   get: "all",
  //   conditions: { "Invoices.id": id },
  //   contain: {
  //     0: "JobSchedules.Users.EmployeesDetails.LabourCharges",
  //     1: "JobSchedules.Vehicles.Users.Countries",
  //     2: "JobSchedules.Vehicles.Users.States",
  //     "JobSchedules.Vehicles.Users.CustomersDetails": {
  //       conditions: { workshop_id: getWorkshopId() },
  //     },
  //     "JobSchedules.JobsParts": {
  //       conditions: { "JobsParts.status": 1 },
  //       sort: { "JobsParts.job_part_priority": "asc" },
  //     },
  //     3: "JobSchedules.JobsParts.Products",
  //     4: "JobSchedules.InsuranceCompany.States",
  //     5: "JobSchedules.InsuranceCompany.Countries",
  //     6: "JobSchedules.JobBookingTypes",
  //     7: "JobSchedules.JobLabours.LabourCharges",
  //     "JobSchedules.JobAttachments": {
  //       conditions: { invoice_attachment: "1" },
  //     },
  //     8: "Workshops",
  //     InvoicesPaymentsCredits: {
  //       fields: ["id", "invoice_id", "amount_due", "amount_paid", "balance_remaining"],
  //     },
  //     9: "JobSchedules.JobAttachmentList",
  //     10: "JobSchedules.Invoices"
  //   },
  // };
  const request1 = {
    "get": "all",
    "conditions": {
      "Invoices.id": id
    },
    "contain": {
        "JobSchedules":{
          "fields":["id","workshop_id","job_name","job_number","user_id","customer_id","bay_id","job_type_parent_id","job_department_id","vehicle_id","rego","inspections_id","insp_reference_id","insp_technician_id","insurance_company_id","claim_number","description","parts_cost","labour_cost","parts_markup","labour_markup","parts_total_cost","parts_total_sell","labour_total_cost","labour_total_sell","labour_time","sub_total","gst","discount_rate","discount_cost","total","next_service_id","time","next_service_due","odometer_reading","job_status","job_stage","show_allocation_column","inv_c_cust_id","inv_a_part_total","inv_a_labour_total","inv_a_total","inv_b_part_total","inv_b_labour_total","inv_b_total","inv_c_labour_total","inv_c_part_total","inv_c_total","customer_excess","invoice_a_summary","invoice_b_summary","invoice_c_summary","job_type","job_parent_id","is_copy_job","is_copid_job","job_estimate_time","profit_margin","is_no_show","is_default_time","purchase_order_number"]
        },
        "JobSchedules.Invoices" : {
          "fields":[
            "id","invoice_number","user_id","insurance_company_id","job_id","recepient","date_issued","payment_due_date","claim_method","claim_amount","remain_balance","comments","tax_invoice","sub_total","discount","discount_cost","total_with_discount","gst","total","invoice_status","payment_assist_status","payment_assist_values","is_part_no_visible","form_type","customer_preview_invoice","insurance_preview_invoice","customer_invoice_total","insurance_invoice_total","is_labour_total_display","customer_invoice_allocation_total","customer_invoice_allocation_preview","invoice_allocation_type","claim"
          ]
        },
        "JobSchedules.Users.EmployeesDetails.LabourCharges":{
            "fields" : ["id","workshop_id","labour_code","description"]
        },
        "JobSchedules.Users.EmployeesDetails":{
            "fields" : ["id","workshop_id","user_id","labour_charges_id"]
        },
        "JobSchedules.Users":{
          "fields" : ["id","workshop_id","firstname","lastname","email","country","state","statement_payment_date"]
        },
        "JobSchedules.Customers":{
          fields:["id","email","state","country"]
        },
        "JobSchedules.Customers.CustomersDetails":{
          "conditions": {
            "CustomersDetails.workshop_id": getWorkshopId(),
            "CustomersDetails.is_deleted":"0"
          },
          fields:["id","user_id","firstname","workshop_id","lastname","company_name","type","abn","website","phone_number","email","mobile_number","mobile_code","postcode","street_address","state","country","payment_method","payment_terms"]          
          // fields:["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code"]
        },
        "JobSchedules.Customers.Countries":{
          "fields" : ["id","country_name"]
        },
        "JobSchedules.Customers.States":{
            "fields" : ["id","name"]
        },
        // "JobSchedules":{
        //     "fields" : ["id","workshop_id","user_id","vehicle_id","customer_id","job_type_parent_id","job_department_id","rego","job_stage","job_status","description","total","inv_a_total","inv_b_total","inv_c_total","customer_excess","invoice_a_summary","invoice_b_summary","invoice_c_summary"]
        // },
        // "JobSchedules.Vehicles.Users.Countries":{
        //     "fields" : ["id","country_name"]
        // },
        // "JobSchedules.Vehicles.Users.States":{
        //     "fields" : ["id","name"]
        // },
        // "JobSchedules.Vehicles.Users":{
        //     "fields" : ["id","workshop_id","firstname","lastname","email","country","state","statement_payment_date"]
        // },
        "JobSchedules.Vehicles":{
            "fields" : ["id","user_id","make","model","rego","series","year"]
        },
        "JobSchedules.JobsParts.Products":{
            "fields" : ["id","name","description","product_number","cost_price","retail_price","sell_price","gst_price","margin","gst_price"]
        },
        "JobSchedules.JobsParts":{
            "conditions": {
                "JobsParts.status": 1
            },
            "sort": {
                "JobsParts.job_part_priority": "asc"
            },
            "fields" : ["id","job_part_priority","status","is_inspection_part","job_schedule_id","product_id","job_part_name","job_part_description","job_part_priority","cost","price","gst_total","sell_total","margin","sell","gst_price","retail","profit","gst","time","qty","invoice_type","selected_invoice_allocation"]
        },
        "JobSchedules.InsuranceCompany.States":{
            "fields" : ["id","name"]
        },
        "JobSchedules.InsuranceCompany.Countries":{
            "fields" : ["id","country_name"]
        },
        "JobSchedules.InsuranceCompany":{
            "fields" : ["id","company_name","contact_name","phone_number","email","website","street_address","country","state","postcode","suburb","abn","acn"]
        },
        "JobSchedules.JobBookingTypes": {
            "fields" :["id","name","parent_id"]
        },
        "JobSchedules.JobLabours.LabourCharges":{
          "fields" : ["id","labour_code","description","cost_price","margin","sell_price","gst_price","profit_price","retail_price","labour_margin"]
      },
      "JobSchedules.JobLabours":{
        "fields":["id","labour_charges_id","job_schedules_id","cost_price","margin_markup","margin","sell_price","gst_price","retail_price","gst_total","sell_total","labour_description","labour_ph","labour_time","total_price","profit","priority","is_inspection","is_carried_over_labour","labour_charge_type","invoice_type","selected_invoice_allocation"]
      },
				"Workshops":{
					"fields":["id","email","name","contact_name","suburb","street_address","postcode","city","state","country","phone","logo","url","websiteUrl"]
				},
				"JobSchedules.JobAttachmentList":{
					"fields": ["id","customer_id","vehicle_id","rego","user_id","insp_template_id","note","Insp_status","insp_approved_by","verification_code","insp_work_type"]
				},
        "JobSchedules.Vehicles.Users.CustomersDetails": {
            "conditions": {
                "CustomersDetails.workshop_id": getWorkshopId(),
                "CustomersDetails.is_deleted":"0"
            },
						"fields":["id","user_id","workshop_id","firstname","lastname","company_name","type","abn","website","phone_number","email","mobile_number","mobile_code","postcode","street_address","state","country","payment_method","payment_terms"]
        },
        "JobSchedules.Vehicles.Users.CustomersDetails.Countries":{
          "fields" : ["id","country_name"]
        },
        "JobSchedules.Vehicles.Users.CustomersDetails.States":{
            "fields" : ["id","name"]
        },
        "JobSchedules.JobAttachments": {
            "conditions": {
                "invoice_attachment": "1"
            },
						"fields":["id","job_id","type","pdf_type","size","path","thumb_path","invoice_attachment","label"]
        },
        "InvoicesPaymentsCredits": {
            "fields": [
                "id",
                "invoice_id",
                "amount_due",
                "amount_paid",
                "balance_remaining"
            ]
        },
        "JobSchedules.InvoiceCCustomer": {
          fields: [
              "id",
              "firstname",
              "lastname",
              "mobile_number",
              "mobile_code",
              "type",
              "company_name",
              "user_id"
          ]
        },
        "JobSchedules.InvoiceCCustomer.Users": {
          fields: [
              "id",              
              "email"
          ]
        },   
    },
    "fields":[
      "id","invoice_number","user_id","insurance_company_id","job_id","recepient","date_issued","payment_due_date","claim_method","claim_amount","remain_balance","comments","tax_invoice","sub_total","discount","discount_cost","total_with_discount","gst","total","invoice_status","payment_assist_status","payment_assist_values","is_part_no_visible","form_type","customer_preview_invoice","insurance_preview_invoice","customer_invoice_total","insurance_invoice_total","is_labour_total_display","customer_invoice_allocation_total","customer_invoice_allocation_preview","invoice_allocation_type","claim"
    ]
  }
  // const invoice_data_payload = {
  //   get: "all",
  //   conditions: {
  //     "Invoices.id": id,
  //   },
  // };
  const invoice_data_response = await getInvoicesList({
    payload: request1,
  });

  
  // debugger;
  invoice_data = invoice_data_response?.data?.Invoices[0];
  
  inv_number = invoice_data?.invoice_number;
  // let get_customer_data = await getUnassignedJobs({
  //   payload: getBookedJobPayload(invoice_data.job_id, workshop_id),
  // });

  // if (get_customer_data?.code === 200) {

    // jobs = get_customer_data?.data?.JobSchedules[0];
    jobs = invoice_data?.job_schedule
    // console.log("invoice_data?.job_schedule");
    // console.log(invoice_data?.job_schedule);
    // if (jobs?.customer?.cust_detail) {
    //   customer_detail = jobs?.customer?.cust_detail;
    // }
    // console.log("invoice_data=============");
    // console.log(invoice_data);
    customer_detail = invoice_data?.job_schedule?.customer?.customers_details?.[0];
    
    // job_parts = jobs?.jobs_parts;
    // job_labours = jobs?.job_labours;
    // customerdetail = jobs?.customer;
    job_parts = invoice_data?.job_schedule?.jobs_parts;

    job_labours = invoice_data?.job_schedule?.job_labours;
    customerdetail = invoice_data?.job_schedule?.customer;

    if (jobs?.user?.employees_details[0]?.labour_charge?.type == 1) {
      labourname = "Technician";
    }
    if (jobs?.user?.employees_details[0]?.labour_charge?.type == 2) {
      labourname = "Senior Technician";
    }
    if (jobs?.user?.employees_details[0]?.labour_charge?.type == 3) {
      labourname = "Apprentice";
    }
    if (jobs?.user?.employees_details[0]?.labour_charge?.type == 1) {
      labourname = "Mobile Technician";
    }

    all_invoices = jobs?.invoices;
    last_invoices = jobs?.invoices[jobs?.invoices.length - 1];
    vehicle = jobs?.vehicle;

    job_type = jobs?.job_booking_type?.name;
    address = invoice_data?.workshop;
  // }

  part_gst = getSessionData('part_gst');
  labour_gst = getSessionData('labour_gst');
  purchase_gst = getSessionData('purchase_gst');

  const get_worknotes_scripts = await getJobCannedList({
    payload: getJobCannedListPayload(workshop_id),
  });

  if (get_worknotes_scripts?.code === 200) {
    scripts = get_worknotes_scripts?.data?.WorkshopScriptsDetails;
  }

  // get messsage template start
  const get_message_template = await getJobEmailTemplateList({
    payload: getMessageTemplatePayload(workshop_id),
  });


  if (get_message_template?.code === 200) {
    message_template = get_message_template?.data?.CustomNotifications;
  }
  // get messsage template end

  const get_jobnotes = await getJobNotes({
    payload: getJobNotesPayload(invoice_data?.job_id),
  });
  if (get_jobnotes?.code == 200) {
    jobnotes = get_jobnotes?.data?.JobNotes[0];
  }

  const getworkshopotherdata = await getWorkshopOtherDetailsView({
    payload: getWorkshopOtherDetailViewPayload(workshop_id),
  });

  if (getworkshopotherdata?.code == 200) {
    otherdata = getworkshopotherdata?.data?.WorkshopOtherDetails;
  }

  const labourCharges = await getLabourChargesList({
    payload: getLabourChargeListPayload(workshop_id),
  });
  if (labourCharges?.code == 200) {
    defaultLabour = labourCharges?.data?.LabourCharges[0];
  }

  const insurance = await getInsurance({
    payload: getInsurancePayload(workshop_id),
  });

  if (insurance?.code == 200) {
    insurance_company = insurance?.data?.InsuranceCompany;
  }

  customer_detail.email = customerdetail?.email ?? "";

  return {
    address, //done
    customer_detail, //done
    jobs, //done
    job_parts, //done
    customerdetail, //done
    labourname, //done
    parts, //done
    part_gst, //done
    labour_gst, //done
    purchase_gst, //done
    vehicle, //done
    scripts, //done
    job_type, //done
    invoice_data, //done
    remain_balance, //done
    claim_amount, //done
    inv_type, //done
    inv_number, //done
    all_invoices, //done
    last_invoices, //done
    jobnotes, //done
    // comp_logo,
    job_labours, //done
    otherdata, //done
    defaultLabour, //done
    insurance_company, //done
    message_template, //done
  };
}
