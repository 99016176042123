import { Dropdown, DropdownButton } from "react-bootstrap";

const DropDown = () => {
  return (
    <DropdownButton
        className="d-flex align-center justify-space-between dropdown-btn custom-dropdown-list "
        title={<i className="la la-bars"></i>}
    >
        <Dropdown.Item >Day</Dropdown.Item>
        <Dropdown.Item >Week</Dropdown.Item>
        <Dropdown.Item >Month</Dropdown.Item>
    </DropdownButton>
  );
};

export default DropDown;
