import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Register = Loadable({
  loader: () => import("./Test"),
  loading: Loading,
});

export const routes = [
  {
    path: "/test",
    exact: true,
    name: "register",
    element: Register,
  }
];
