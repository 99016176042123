
export const ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS = "ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS";
export const ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_SUCCESS =
  "ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_SUCCESS";
export const ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_FAIL = "ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_FAIL";

export const EDIT_MESSAGE_TEMPLATE_DETAILS = "EDIT_MESSAGE_TEMPLATE_DETAILS";
export const EDIT_MESSAGE_TEMPLATE_DETAILS_SUCCESS =
  "EDIT_MESSAGE_TEMPLATE_DETAILS_SUCCESS";
export const EDIT_MESSAGE_TEMPLATE_DETAILS_FAIL =
  "EDIT_MESSAGE_TEMPLATE_DETAILS_FAIL";

