import { sendAwsEmailVerification, updateWorkshopDetail } from "helpers/backendHelper";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { showError, showSuccess } from "utils/alertMessage";
import { getWorkshopId } from "utils/getWorkshopId";
import Loader from "views/common/UI/Loader";
import EmailIcon from "../../../../../assets/images/Email-Vector-SVG-Icon-20-SVG-Repo.png";

const CompanyCommunicationEmail = ({ workShopDetails }) => {
  const [email, setEmail] = useState("");
  const [sms, setSMS] = useState("");
  const [disable, setDisable] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [validated, setValidated] = useState(false);
  const [smsValidated, setSMSValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (workShopDetails) {
      if (
        (workShopDetails.from_email_temp === "" || !workShopDetails.from_email_temp) &&
        (workShopDetails.from_email === "" || !workShopDetails.from_email)
      ) {
        setEmail(workShopDetails.email);
        if (
          workShopDetails.from_email !== "" || workShopDetails.from_email_temp ||
          workShopDetails.from_email_temp !== "" || workShopDetails.from_email
        ) {
          setShowVerify(false);
          setDisable(true);
        }
      } else if (
        (workShopDetails.from_email_temp !== "" || workShopDetails.from_email_temp) &&
        (workShopDetails.from_email === "" || !workShopDetails.from_email)
      ) {
        setEmail(workShopDetails.from_email_temp);
        if (workShopDetails.from_email_temp !== "" || workShopDetails.from_email_temp) {
          setDisable(true);
        }
      } else {
        if (workShopDetails.from_email !== "" || workShopDetails.from_email) {
          setEmail(workShopDetails.from_email);
          setDisable(true);
        }
      }

      if (workShopDetails.sms_header_name != "" && workShopDetails.sms_header_name != null) {
        setSMS(workShopDetails?.sms_header_name)
      } else {
        if (workShopDetails.name !== "") {
          let companyName = workShopDetails?.name;
          setSMS(companyName.substr(0, 11))
        }
      }
    }
  }, [workShopDetails]);

  const handleEdit = () => {
    setDisable(false);
    setShowVerify(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      setIsLoading(true);
      const response = await sendAwsEmailVerification({
        payload: {
          email: email,
          from_email: email,
          workshop_id: getWorkshopId(),
        },
      });
      if (response.code === 200) {
        setDisable(true);
        setShowVerify(false);
        showSuccess("Email Sent Successfully.");
        setIsLoading(false);
      } else {
        showError("Email Not Verify Please Try Again After Sometime.");
        setIsLoading(false);
      }
    }
    setValidated(true);
  };

  const handleSmsSubmit = async (event) => {
    event.preventDefault();
    const smsform = event.currentTarget;
    if (smsform.checkValidity() !== false) {
      setIsLoading(true);
      const response = await updateWorkshopDetail({
        payload: {
          sms_header_name: sms,
          id: getWorkshopId(),
        },
      });
      if (response.code === 200) {
        showSuccess("Company SMS name update Successfully.");
        setIsLoading(false);
      }
    }
    setSMSValidated(true);
  }

  const onChangeSms = (sms) => {
    if (sms.length <= 11) {
      setSMS(sms)
    }
  }

  const handleCancel = () => {
    setEmail("");
    setValidated(false);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="card mb-3">
      <div className="card-header d-flex flex-wrap align-items-center">
        <div className="img flex-shrink-0 me-2">
          <img
            src={EmailIcon}
            width="40"
            height="40"
            className="img-circle"
            alt=""
          />
        </div>
        <div className="text-uppercase me-2">
          <h5 className="font-size-16">EMAIL</h5>
        </div>
        <div className="text-uppercase font-size-13">
          5IQ WILL SEND ALL EMAILS WITH YOUR BUSINESS SENDER ID | EMAIL ADDRESS
          AS DISPLAYED BELOW.
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="form-group pb-3">
                <label htmlFor="" className="form-label">
                  {workShopDetails &&
                    workShopDetails.from_email_temp !== "" &&
                    workShopDetails.from_email === "" ? (
                    <>Under Moderation</>
                  ) : (
                    <>Company Email Address</>
                  )}
                </label>
                <div className="d-flex flex-nowrap">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    disabled={disable}
                    required
                  />
                  <div className="cta flex-shrink-0 ms-3">
                    {!showVerify &&
                      workShopDetails &&
                      (workShopDetails.from_email !== "" ||
                        workShopDetails.from_email_temp !== "") && (
                        <button
                          type="button"
                          className="btn btn-success btn-rounded px-4 font-size-14"
                          onClick={handleEdit}
                        >
                          Edit
                        </button>
                      )}
                    {workShopDetails &&
                      workShopDetails.from_email === "" &&
                      workShopDetails.from_email_temp === "" && (
                        <>
                          <button
                            type="button"
                            className="btn btn-success btn-rounded px-4 font-size-14"
                            onClick={handleSubmit}
                          >
                            Verify
                          </button>
                          <button
                            type="button"
                            className="btn btn-success btn-rounded px-4 font-size-14"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </>
                      )}
                    {showVerify && (
                      <>
                        <button
                          type="button"
                          className="btn btn-success btn-rounded px-4 m-r-10 font-size-14"
                          onClick={handleSubmit}
                        >
                          Verify
                        </button>
                        <button
                          type="button"
                          className="btn btn-success btn-rounded px-4 font-size-14"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </div>
                {workShopDetails &&
                  workShopDetails.from_email !== "" &&
                  workShopDetails.from_email_temp !== "" && (
                    <span className="under-moderation pt-1 d-block font-size-13">
                      {/* Under Moderation :- {workShopDetails.from_email_temp} */}
                    </span>
                  )}
              </div>
            </Form>
            <p className="pb-3 font-size-12">
              Please ensure this is correct in order to make your email look
              professional and personalised when sending to your customers
              ,suppliers and employee's.
            </p>
          </div>
          <div className="col-md-6">
            <Form noValidate validated={smsValidated} onSubmit={handleSmsSubmit}>
              <div className="form-group pb-3">
                <label htmlFor="" className="form-label">
                  Company SMS Name (Limited to 11 Characters)
                </label>
                <div className="d-flex flex-nowrap company-name-position">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => onChangeSms(e.target.value)}
                    value={sms}
                    // disabled={disable}
                    required
                  />
                  <span className="border p-2 company-sms" >{sms}</span>
                  <div className="cta flex-shrink-0 ms-3">
                    <button
                      type="submit"
                      className="btn btn-success btn-rounded px-4 font-size-14"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
            <p className="pb-3 font-size-12">
              This company name will be displayed in the SMS Header
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default CompanyCommunicationEmail;
