import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { SAVE_JOBSMSTEMPLATE,SAVE_JOBSMSTEMPLATE_SUCCESS, GET_JOBSMSTEMPLATE_LIST } from "../../store/commonApiStore/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { saveJobSmsTemplateSuccess } from "../../store/commonApiStore/actions";
import phoneCode from "utils/phoneCode.json";
import { looselyMobileRegex } from "utils/validationRegex";
import { useFormik } from "formik";
import * as yup from "yup";
import { CALLBACK_ARGUMENT, WAIT_FOR_ACTION } from "redux-wait-for-action";

const validationSchema = yup.object().shape({
  template: yup.string().trim().required("Please select a template"),
  send_to: yup.string().trim().matches(looselyMobileRegex, { message: "Please provide a valid phone number" }).required("Please provide a phone number"),
  message_body: yup.string().trim().required("Please write a message")
})

const SendSmsModal = ({
  SMSModalShow,
  handleSMSModalClose,
  smsTemplateData,
  getPhoneForPhoneModal,
  getPhoneCodeForPhoneModal,
  shareAdditionalDetailsSms,
  send_page,
  setIsLoading,
  refreshData,
}) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);

  useEffect(() => {
    dispatch({
      type: GET_JOBSMSTEMPLATE_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: workshop_id,
          type: "script",
          template_type: "1",
          is_sms: 1,
          is_deleted: 0,
        },
      },
    });
  },[])
  const getResponseFromApi = useSelector(
    (state) => state.CommonApiStoreReducer
  );
  
  // const [smsTemplateValidated, setSmsTemplateValidated] = useState(false);
  let phoneCode = getPhoneCodeForPhoneModal?.phoneCode ? getPhoneCodeForPhoneModal?.phoneCode : getPhoneCodeForPhoneModal?.phone;
  let phone = getPhoneForPhoneModal ? getPhoneForPhoneModal.phone : "";
  if (phone) {
    phone = phone.replace(phoneCode, '');
  }
  let jobsmsData = getResponseFromApi.jobSmsData ? getResponseFromApi.jobSmsData : [];


  const formik = useFormik({
    initialValues: {
      template: "",
      send_to: phone ?? "",
      message_body: ""
    },
    onSubmit: handleSubmitSmsTemplateModalForm,
    validationSchema
  })

  // useEffect(() => {
  //   setSmsValue({ ...smsValue, send_to: phone });
  // }, [phone]);

  const getSmsTemplate = async (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let smsTemplateID = e.target.value;
    let message_body = optionElement.getAttribute("data-val")
      ? optionElement.getAttribute("data-val")
      : "";
    // setSmsValue({
    //   template: smsTemplateID ? smsTemplateID : "",
    //   send_to: smsValue.send_to ? smsValue.send_to : "",
    //   message_body: message_body ? message_body : "",
    // });
    await formik.setFieldValue("message_body", message_body ?? "");
    formik.setFieldValue("template", smsTemplateID ?? "");
  };
  // const handleSmsChange = (e) => {
  //   setSmsValue({ ...smsValue, [e.target.name]: e.target.value });
  // };

  function handleSubmitSmsTemplateModalForm(values) {
    // e.preventDefault();
    // setSmsTemplateValidated(true);

    // if (
    //   smsValue.template === "" ||
    //   smsValue.send_to === "" || !isPhoneValid ||
    //   smsValue.message_body === ""
    // ) {
    //   console.log("returning...")
    //   return false;
    // }

    // setIsLoading(true);
    let smsFormData = {};
    smsFormData.workshop_id = workshop_id;
    smsFormData.job_id = shareAdditionalDetailsSms.job_id;
    smsFormData.template_id = values.template;
    smsFormData.send_method = shareAdditionalDetailsSms.send_method;
    smsFormData.table = shareAdditionalDetailsSms.table;
    if (shareAdditionalDetailsSms.sendType === "") {
      if (shareAdditionalDetailsSms.job_id.length === 1) {
        smsFormData.send_to = [values.send_to];
        smsFormData.to = [values.send_to];
        smsFormData.message = values.message_body;
      }
    } else {
      smsFormData.send_type = shareAdditionalDetailsSms.sendType;
    }
    if (shareAdditionalDetailsSms.send_method == "sms") {
      smsFormData.phone_code = phoneCode;
    }

    dispatch({
      type: SAVE_JOBSMSTEMPLATE,
      payload: smsFormData,
      [WAIT_FOR_ACTION]: SAVE_JOBSMSTEMPLATE_SUCCESS,
      [CALLBACK_ARGUMENT]: (action) => action.payload,
    }).then((response) => {
        if(response){
          handleSubmitSMS(response)
        }
    });
  }

  const handleSubmitSMS = (response) => {
    if(response !== "" || response){
      setIsLoading(false);
      if (send_page === "job_details") {
        handleSMSModalClose();
        handleSMSModalClose();
        refreshData();
        dispatch(saveJobSmsTemplateSuccess(null));
        navigate(
          `/job_detail/${shareAdditionalDetailsSms.job_id
            ? shareAdditionalDetailsSms.job_id[0]
            : ""
          }`
        );
      } else if (send_page === "job_list") {
        dispatch(saveJobSmsTemplateSuccess(null));
        handleSMSModalClose();
      } else if (send_page === "quote") {
        handleSMSModalClose();
        refreshData();
        dispatch(saveJobSmsTemplateSuccess(null));
      } else if (send_page === "quote_list") {
        navigate("/");
      } else if (send_page === "lead") {
        navigate("/");
      } else if (send_page === "quote") {
        navigate("/");
      } else {
        navigate("/");
      }
    } else {
      setIsLoading(false);
    }
  }
  // useEffect(() => {

  //   let saveDataResponse = getResponseFromApi.jobSmsSaveData
  //     ? getResponseFromApi.jobSmsSaveData
  //     : "";

  //   if (saveDataResponse !== "" || saveDataResponse) {
  //     setIsLoading(false);
  //     if (send_page === "job_details") {
  //       handleSMSModalClose();
  //       handleSMSModalClose();
  //       refreshData();
  //       dispatch(saveJobSmsTemplateSuccess(null));
  //       navigate(
  //         `/job_detail/${shareAdditionalDetailsSms.job_id
  //           ? shareAdditionalDetailsSms.job_id[0]
  //           : ""
  //         }`
  //       );
  //     } else if (send_page === "job_list") {
  //       dispatch(saveJobSmsTemplateSuccess(null));
  //       handleSMSModalClose();
  //     } else if (send_page === "quote") {
  //       handleSMSModalClose();
  //       refreshData();
  //       dispatch(saveJobSmsTemplateSuccess(null));
  //     } else if (send_page === "quote_list") {
  //       navigate("/");
  //     } else if (send_page === "lead") {
  //       navigate("/");
  //     } else if (send_page === "quote") {
  //       navigate("/");
  //     } else {
  //       navigate("/");
  //     }
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [getResponseFromApi]);
  return (
    <Modal
      className="preview-dialog"
      size="lg"
      show={SMSModalShow}
      onHide={handleSMSModalClose}
    >
      <Modal.Header>
        <Modal.Title className="d-flex justify-space-between">
          <span>Send SMS</span>
          <Button
            type="button"
            className="close-btn"
            onClick={handleSMSModalClose}
          >
            <i className="la la-times"></i>{" "}
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Form noValidate validated={Object.keys(formik.touched).length} id="smsTemplateForm" onSubmit={formik.handleSubmit}>
        <Modal.Body>

          <div className="column-wrapper d-flex">
            <div className="left-column">
              <div className="custom-normal-select mb-3">
                <Form.Label>Template *</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  required
                  value={formik.values?.template ?? ""}
                  name="template"
                  id="template"
                  isInvalid={Boolean(formik.errors.template)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    getSmsTemplate(e)
                    // formik.handleChange(e)
                  }}
                >
                  <option disabled key={0} value="" data-val="">
                    Select Template
                  </option>
                  {jobsmsData.map((val) => {
                    return (
                      <option
                        key={val.id}
                        value={val.id}
                        data-val={val.msg_txt ? val.msg_txt : ""}
                      >
                        {val.name}
                      </option>
                    );
                  })}
                </Form.Select>
                {
                  formik.errors?.template && (<Form.Control.Feedback type="invalid">
                    {
                      formik.errors?.template
                    }
                  </Form.Control.Feedback>)
                }
              </div>
              <Form.Group className="form-group mb-3">
                <Form.Label>Mobile NO *</Form.Label>
                <Form.Control
                  type="text"
                  name="send_to"
                  id="phone_no"
                  onBlur={formik.handleBlur}
                  // value={smsValue.send_to}
                  value={formik.values?.send_to}
                  onChange={formik.handleChange}
                  isInvalid={Boolean(formik.errors.send_to)}
                  isValid={!Boolean(formik.errors.send_to)}
                />
                {
                  formik.errors?.send_to && (<Form.Control.Feedback type="invalid">
                    {formik.errors?.send_to}
                  </Form.Control.Feedback>)
                }
              </Form.Group>
              <Form.Group className="form-group mb-3">
                <Form.Label>Message *</Form.Label>
                <Form.Control
                  as="textarea"
                  // value={smsValue.message_body ? smsValue.message_body : ""}
                  value={formik.values?.message_body ?? ""}
                  name="message_body"
                  id="text_message_body"
                  onBlur={formik.handleBlur}
                  // onChange={handleSmsChange}
                  onChange={formik.handleChange}
                  isInvalid={Boolean(formik.errors.message_body)}
                  rows={8}
                  required
                />
                {
                  formik.errors?.message_body && (<Form.Control.Feedback type="invalid">
                    {formik.errors.message_body}
                  </Form.Control.Feedback>)
                }
              </Form.Group>
            </div>
            <div className="right-column">
              <span className="panel-heading">PREVIEW</span>
              <div className="preview-outer">
                <div className="preview-wrapper">
                  <span className="panel-heading">MESSAGES</span>
                  <div className="panel-body">
                    <div className="msg-content">
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: smsValue.message_body
                            ? smsValue.message_body
                            : "",
                        }}
                      /> */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formik.values?.message_body
                            ??
                            "",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            className="btn-outline rounded-btn"
            onClick={handleSMSModalClose}
          >
            Cancel
          </Button>
          <Button
            className="rounded-btn"
            variant="primary"
            // type="button"
            type="submit"
          // onClick={handleSubmitSmsTemplateModalForm}
          >
            Send
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default SendSmsModal;
