import { apiCall } from "helpers/apiHelper";
import {
  addManyJobLabours,
  addQuote,
  createJob,
  getJobDepartmentList,
  getLabourChargeList,
  getQuoteDetails,
  saveJobLabour,
} from "helpers/backendHelper";
import { EDIT_ONLINE_JOB } from "helpers/urlHelper";
import moment from "moment";
import { date_format } from "utils/getCurrency";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import { convertDurationToDecimal, timeZoneToUTC } from "utils/timeZoneConvert";

export function changeLeadStatus(id) {
  const request = {
    id,
    lead_status: "Converted",
  };
  return apiCall(EDIT_ONLINE_JOB, { payload: request }, "POST", true);
}

export async function convertLeadToJob({ formikData, requestData, labourChargesList }) {
  const {
    customer_id,
    vehicle_id,
    job_type,
    description,
    job_parent_type,
    id,
    timeZone,
    labour_cost,
    labour_total_sell,
    sub_total,
    gst,
    total
  } = requestData;
  const {
    selectedDate,
    technician_id,
    selectedSlot,
    jobEstimateTime,
    pickup_date,
    pickup_time,
    unassignedFlag
  } = formikData;
  let duration = 30;
  // return
  await changeLeadStatus(id);
  const getJobDepRequest = {
    get: "all",
    conditions: {
      job_type_parent_id: job_parent_type,
      workshop_id: getWorkshopId(),
    },
    fields: ["id", "job_type_parent_id", "name", "is_custom"],
  };
  const accDets = await getJobDepartmentList({ payload: getJobDepRequest });
  const job_department_id =
    accDets?.data?.WorkshopDepartmentsAccountsDetails[0].id;

  if (jobEstimateTime) {
    const [hr, mn] = jobEstimateTime.split(":");
    duration = +hr * 60 + +mn;
  }
  const startTime = selectedDate + " " + selectedSlot;
  const start_time = timeZoneToUTC(
    startTime,
    timeZone,
    `${date_format} HH:mm:ss`,
    "HH:mm:ss"
  );

  const endTime = moment(start_time, "HH:mm:ss")
    .add({ minutes: duration })
    .format("HH:mm:ss");

  const pickupDateTime = pickup_date + " " + pickup_time;
  const pickupDateTimeInUtc = timeZoneToUTC(
    pickupDateTime,
    timeZone,
    `${date_format}`,
    "YYYY-MM-DD"
  );
  const pickupTime = moment(pickupDateTimeInUtc, "YYYY-MM-DD HH:mm:ss").format(
    "HH:mm"
  );

  const request = {
    workshop_id: getWorkshopId(),
    created_by: getSessionData("user_id"),
    customer_id,
    user_id: technician_id,
    vehicle_id,
    job_type,
    job_department_id,
    from_date: unassignedFlag ? moment().format("YYYY-MM-DD") : moment(selectedDate, date_format).format("YYYY-MM-DD"),
    to_date: unassignedFlag ? moment().format("YYYY-MM-DD") : moment(selectedDate, date_format).format("YYYY-MM-DD"),
    from_time: unassignedFlag ? "00:00:00" : start_time,
    to_time: unassignedFlag ? "00:00:00" : endTime,
    duration: duration,
    job_estimate_time: jobEstimateTime,
    pickup_time_only: unassignedFlag ? '19:30:00' : pickup_time,
    pickup_time: unassignedFlag ? moment().format("YYYY-MM-DD") + " 00:00:00" : pickupDateTimeInUtc + " " + pickup_time + ":00",
    tags_id: "[]",
    description,
    job_status: "Pending",
    job_stage: "Bookin",
    insp_reference_id: 0,
    bookin_source: 2,
    source_of_business: "Existing",
    labour_time: 0,
    parts_cost: 0,
    labour_cost: labour_cost,
    labour_total_sell:labour_total_sell,
    parts_markup: 0,
    labour_markup: 0,
    parts_total_cost: 0,
    labour_total_cost: labour_cost,
    sub_total: sub_total,
    gst: gst,
    total: total,
    discount_rate: 0,
    is_default_time: "3"
  };

  if (unassignedFlag === true) {
    request['job_status'] = 'Unassigned';
    request['job_stage'] = 'Unassigned';
    request['carry_over_to_unassign'] = 1;
    request['user_id'] = 0;
  }


  const createjob = await createJob({ payload: request });
  if (createjob?.code === 200) {
  
    if (labourChargesList !== null && labourChargesList !== undefined && id !== null) {
      let jobLaboursArr = [];
      let laboursDataArr = {
        labour_charges_id: labourChargesList[0].id,
        job_schedules_id: createjob?.data?.JobSchedules?.id,
        cost_price: labourChargesList[0].cost_price,
        margin: labourChargesList[0].margin,
        sell_price: labourChargesList[0].sell_price,
        retail_price: labourChargesList[0].retail_price,
        profit: labourChargesList[0].profit_price,
        labour_description: labourChargesList[0].description,
        gst_price: labourChargesList[0].gst_price
      };

      ["1", "2", "3"].map(cv => {
        const copiedData = { ...laboursDataArr, labour_charge_type: cv }
        if (cv === "3") {
          copiedData["labour_time"] = jobEstimateTime
          copiedData["labour_ph"] = convertDurationToDecimal(jobEstimateTime)
          copiedData["sell_total"] = parseFloat(laboursDataArr.sell_price) * parseFloat(convertDurationToDecimal(jobEstimateTime))
          copiedData["total_price"] =  parseFloat(labourChargesList[0].retail_price) * convertDurationToDecimal(jobEstimateTime)
          copiedData["gst_total"] = parseFloat(labourChargesList[0].gst_price) * convertDurationToDecimal(jobEstimateTime)
        }else {
          copiedData["sell_total"] = "0.00"
          copiedData["total_price"] = "0.00"
          copiedData["gst_total"] = "0.00"
        }
        return jobLaboursArr.push(copiedData)
      })

      await addManyJobLabours({
        payload: {
          data: jobLaboursArr
        }
      });




    }


    // const request2 = {
    //   get: "all",
    //   conditions: {
    //     workshop_id: getWorkshopId(),
    //     "labour_code !=": "",
    //     is_default: "1",
    //     is_deleted: 0,
    //   },
    // };

    // const labourCharges = await getLabourChargeList({ payload: request2 });
    // const defaultLabour = labourCharges?.data?.LabourCharges[0];
    // const request3 = {
    //   labour_charges_id: defaultLabour?.id,
    //   job_schedules_id: createjob?.data?.JobSchedules?.id,
    //   cost_price: defaultLabour?.cost_price,
    //   margin: defaultLabour?.margin,
    //   sell_price: defaultLabour?.sell_price,
    //   retail_price: defaultLabour?.retail_price,
    //   profit: 0.0,
    //   labour_description: defaultLabour?.description,
    //   total_price: 0.0,
    // };
    // await saveJobLabour({ payload: request3 });
  } else {
    throw new Error("Failed to create job");
  }
}

export async function convertLeadToQuote({ requestData }) {
  const {
    customer_id,
    vehicle_id,
    job_type,
    description,
    job_parent_type,
    id,
  } = requestData;
  const $quote_data = {};
  await changeLeadStatus(id);
  const getJobDepRequest = {
    get: "all",
    conditions: {
      job_type_parent_id: job_parent_type,
      workshop_id: getWorkshopId(),
    },
    fields: ["id", "job_type_parent_id", "name", "is_custom"],
  };

  const getLastQuotesResquest = {
    get: "all",
    conditions: {
      "CustomerQuotes.workshop_id": getWorkshopId(),
    },
    fields: [
      "CustomerQuotes.id",
      "CustomerQuotes.workshop_id",
      "CustomerQuotes.quote_number",
    ],
    order: {
      "CustomerQuotes.id": "desc",
    },
    limit: 1,
  };

  const get_quotes = await getQuoteDetails({ payload: getLastQuotesResquest });
  let quote_number = 1;
  if (get_quotes?.code === 200) {
    let quoteNumber = get_quotes?.data?.CustomerQuotes[0]?.quote_number;
    quote_number = +quoteNumber + 1;
  }

  const accDets = await getJobDepartmentList({ payload: getJobDepRequest });
  const job_department_id =
    accDets?.data?.WorkshopDepartmentsAccountsDetails[0].id;

  $quote_data["workshop_id"] = getWorkshopId();
  $quote_data["user_id"] = customer_id;
  $quote_data["vehicle_id"] = vehicle_id;
  $quote_data["quote_status"] = "Open";
  $quote_data["job_department"] = job_department_id;
  $quote_data["job_type"] = job_type;
  $quote_data["description"] = description;
  $quote_data["quote_number"] = quote_number;
  $quote_data["parts_markup"] = 0;
  $quote_data["labour_markup"] = 0;

  const save_quotes = await addQuote({ payload: $quote_data });

  if (save_quotes?.code === 200) {
    let $saved_quote = save_quotes?.data?.CustomerQuotes;
    let $quote_id = $saved_quote?.id;
    let $msg = "Awesome!Your update has been saved.";
    return { id: $quote_id, message: $msg };
  } else {
    throw new Error("Unable to save quotes");
  }
}
