import {
  addInpections,
  deleteInspectionQuestion,
  getJobs,
  updateJob,
} from "helpers/backendHelper";

export async function saveInspection({
  inspectionPayload,
  job_id,
  technician_id,
  ids,
}) {
  if (ids.ids.length > 1) {
    await deleteInspectionQuestion({ payload: ids });
  }
  const addInspectionResponse = await addInpections({
    payload: inspectionPayload,
  });

  if (addInspectionResponse?.code === 200) {
    const inspection_id = addInspectionResponse?.data?.InspInspections?.id;
    const updateJobRequest = {
      id: job_id,
      inspections_id: inspection_id,
      insp_technician_id: technician_id,
    };
    const update_job = await updateJob({ payload: updateJobRequest });
    if (update_job?.code === 200) {
      const jobId = update_job?.data?.JobSchedules?.id;
      const getParentJobRequest = {
        get: "all",
        conditions: {
          "JobSchedules.job_parent_id": jobId,
          "JobSchedules.is_deleted": "0",
          "JobSchedules.status": "1",
          "JobSchedules.inspections_id": 0,
        },
        fields: [
          "id",
          "job_parent_id",
          "is_copy_job",
          "is_copid_job",
          "inspections_id",
          "is_deleted",
          "status",
        ],
      };
      const getParentJob = await getJobs({ payload: getParentJobRequest });

      if (getParentJob?.code === 200) {
        updateJobRequest.id = getParentJob?.data?.JobSchedules[0]?.id;
        await updateJob({ payload: updateJobRequest });
      }
    }
  }

  return addInspectionResponse;
}
