import {
  GET_JOBDEPARTMENT_LIST,
  GET_JOBDEPARTMENT_LIST_SUCCESS,
  GET_JOBDEPARTMENT_LIST_FAIL,
  GET_JOBTYPE_LIST,
  GET_JOBTYPE_LIST_SUCCESS,
  GET_JOBTYPE_LIST_FAIL,
  GET_JOBCANNED_LIST,
  GET_JOBCANNED_LIST_SUCCESS,
  GET_JOBCANNED_LIST_FAIL,
  GET_JOBSMSTEMPLATE_LIST,
  GET_JOBSMSTEMPLATE_LIST_SUCCESS,
  GET_JOBSMSTEMPLATE_LIST_FAIL,
  GET_JOBEMAILTEMPLATE_LIST,
  GET_JOBEMAILTEMPLATE_LIST_SUCCESS,
  GET_JOBEMAILTEMPLATE_LIST_FAIL,
  SAVE_JOBEMAILTEMPLATE,
  SAVE_JOBEMAILTEMPLATE_SUCCESS,
  SAVE_JOBEMAILTEMPLATE_FAIL,
  SAVE_JOBSMSTEMPLATE,
  SAVE_JOBSMSTEMPLATE_SUCCESS,
  SAVE_JOBSMSTEMPLATE_FAIL,
  UPDATE_WORKSHOP_OTHER_DETAIL,
  UPDATE_WORKSHOP_OTHER_DETAIL_SUCCESS,
  UPDATE_WORKSHOP_OTHER_DETAIL_FAIL,
  GET_LABOUR_CHARGES_LIST,
  GET_LABOUR_CHARGES_LIST_SUCCESS,
  GET_LABOUR_CHARGES_LIST_FAIL,
  GET_COUNTRY,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_FAIL,
  GET_VEHICLEMODEL,
  GET_VEHICLEMODEL_SUCCESS,
  GET_VEHICLEMODEL_FAIL,
  GET_VEHICLESERIES,
  GET_VEHICLESERIES_SUCCESS,
  GET_VEHICLESERIES_FAIL,
  GET_VEHICLES,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  GET_WORKSHOP_MARKUP_DETAILS,
  GET_WORKSHOP_MARKUP_DETAILS_SUCCESS,
  GET_WORKSHOP_MARKUP_DETAILS_FAIL,
  GET_WORKSHOP_TAX_DETAILS,
  GET_WORKSHOP_TAX_DETAILS_SUCCESS,
  GET_WORKSHOP_TAX_DETAILS_FAIL,
  GET_STATUS_COLOR,
  GET_STATUS_COLOR_SUCCESS,
  GET_STATUS_COLOR_FAIL,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE_SUCCESS,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE_FAIL,
  EDIT_WORKSHOP_MARKUP_DETAILS_SUCCESS,
  GET_ASSETS,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAIL,
  SEARCH_JOBS,
  SEARCH_JOBS_SUCCESS,
  SEARCH_JOBS_FAIL,
  SEARCH_QUOTE_JOBS,
  SEARCH_QUOTE_JOBS_SUCCESS,
  SEARCH_QUOTE_JOBS_FAIL,
  APPLY_CODE,
  APPLY_CODE_SUCCESS,
  APPLY_CODE_FAIL,
  GET_WORKSHOP_TECHNICIAN,
  GET_WORKSHOP_TECHNICIAN_SUCCESS,
  GET_WORKSHOP_TECHNICIAN_FAIL,
  GET_SUPPLIER_INTEGRATION,
  GET_SUPPLIER_INTEGRATION_SUCCESS,
  GET_SUPPLIER_INTEGRATION_FAIL,
  GET_EMPLOYEE_ROLES,
  GET_EMPLOYEE_ROLES_SUCCESS,
  GET_EMPLOYEE_ROLES_FAIL,
  GET_STORAGE_DEVICE,
  GET_STORAGE_DEVICE_SUCCESS,
  GET_STORAGE_DEVICE_FAIL,
  GET_NOTIFICATION_COUNT,
  GET_NOTIFICATION_COUNT_SUCCESS,
  GET_NOTIFICATION_COUNT_FAIL,
  GET_INSPECTION_NOTIFICATION,
  GET_INSPECTION_NOTIFICATION_SUCCESS,
  GET_INSPECTION_NOTIFICATION_FAIL,
  GET_ONLINE_SCHEDULE_NOTIFICATION,
  GET_ONLINE_SCHEDULE_NOTIFICATION_SUCCESS,
  GET_ONLINE_SCHEDULE_NOTIFICATION_FAIL,
} from "./actionTypes";

export const getJobDepartmentList = () => {
  return {
    type: GET_JOBDEPARTMENT_LIST,
  };
};

export const getJobDepartmentListSuccess = (data) => {
  return {
    type: GET_JOBDEPARTMENT_LIST_SUCCESS,
    payload: data,
  };
};

export const getJobDepartmentListFail = (error) => {
  return {
    type: GET_JOBDEPARTMENT_LIST_FAIL,
    payload: error,
  };
};

export const getTypeList = () => {
  return {
    type: GET_JOBTYPE_LIST,
  };
};

export const getTypeListSuccess = (data) => {
  return {
    type: GET_JOBTYPE_LIST_SUCCESS,
    payload: data,
  };
};

export const getJobTypeListFail = (error) => {
  return {
    type: GET_JOBTYPE_LIST_FAIL,
    payload: error,
  };
};

export const getJobCannedList = (payload) => {
  return {
    type: GET_JOBCANNED_LIST,
    payload,
  };
};

export const getJobCannedListSuccess = (data) => {
  return {
    type: GET_JOBCANNED_LIST_SUCCESS,
    payload: data,
  };
};

export const getJobCannedListFail = (error) => {
  return {
    type: GET_JOBCANNED_LIST_FAIL,
    payload: error,
  };
};

export const getJobSmsTemplateList = () => {
  return {
    type: GET_JOBSMSTEMPLATE_LIST,
  };
};

export const getJobSmsTemplateListSuccess = (data) => {
  return {
    type: GET_JOBSMSTEMPLATE_LIST_SUCCESS,
    payload: data,
  };
};

export const getJobSmsTemplateListFail = (error) => {
  return {
    type: GET_JOBSMSTEMPLATE_LIST_FAIL,
    payload: error,
  };
};

export const getJobEmailTemplateList = () => {
  return {
    type: GET_JOBEMAILTEMPLATE_LIST,
  };
};

export const getJobEmailTemplateListSuccess = (data) => {
  return {
    type: GET_JOBEMAILTEMPLATE_LIST_SUCCESS,
    payload: data,
  };
};

export const getJobEmailTemplateListFail = (error) => {
  return {
    type: GET_JOBEMAILTEMPLATE_LIST_FAIL,
    payload: error,
  };
};

export const saveJobEmailTemplate = () => {
  return {
    type: SAVE_JOBEMAILTEMPLATE,
  };
};

export const saveJobEmailTemplateSuccess = (data) => {
  return {
    type: SAVE_JOBEMAILTEMPLATE_SUCCESS,
    payload: data,
  };
};

export const saveJobEmailTemplateFail = (error) => {
  return {
    type: SAVE_JOBEMAILTEMPLATE_FAIL,
    payload: error,
  };
};

export const saveJobSmsTemplate = () => {
  return {
    type: SAVE_JOBSMSTEMPLATE,
  };
};

export const saveJobSmsTemplateSuccess = (data) => {
  return {
    type: SAVE_JOBSMSTEMPLATE_SUCCESS,
    payload: data,
  };
};

export const saveJobSmsTemplateFail = (error) => {
  return {
    type: SAVE_JOBSMSTEMPLATE_FAIL,
    payload: error,
  };
};

export const getCountry = () => {
  return {
    type: GET_COUNTRY,
  };
};

export const getCountrySuccess = (data) => {
  return {
    type: GET_COUNTRY_SUCCESS,
    payload: data,
  };
};

export const getCountryFail = (error) => {
  return {
    type: GET_COUNTRY_FAIL,
    payload: error,
  };
};

export const getState = () => {
  return {
    type: GET_STATE,
  };
};

export const getStateSuccess = (data) => {
  return {
    type: GET_STATE_SUCCESS,
    payload: data,
  };
};

export const getStateFail = (error) => {
  return {
    type: GET_STATE_FAIL,
    payload: error,
  };
};

export const getvehicles = () => {
  return {
    type: GET_VEHICLES,
  };
};

export const getvehiclesSuccess = (data) => {
  return {
    type: GET_VEHICLES_SUCCESS,
    payload: data,
  };
};

export const getvehiclesFail = (error) => {
  return {
    type: GET_VEHICLES_FAIL,
    payload: error,
  };
};

export const getvehicleModel = () => {
  return {
    type: GET_VEHICLEMODEL,
  };
};

export const getvehicleModelSuccess = (data) => {
  return {
    type: GET_VEHICLEMODEL_SUCCESS,
    payload: data,
  };
};

export const getvehicleModelFail = (error) => {
  return {
    type: GET_VEHICLEMODEL_FAIL,
    payload: error,
  };
};

export const getvehicleSeries = () => {
  return {
    type: GET_VEHICLESERIES,
  };
};

export const getvehicleSeriesSuccess = (data) => {
  return {
    type: GET_VEHICLESERIES_SUCCESS,
    payload: data,
  };
};

export const getvehicleSeriesFail = (error) => {
  return {
    type: GET_VEHICLESERIES_FAIL,
    payload: error,
  };
};

// update workshop other detail START
export const updateWorkshopOtherDetail = () => {
  return {
    type: UPDATE_WORKSHOP_OTHER_DETAIL,
  };
};

export const updateWorkshopOtherDetailSuccess = (data) => {
  return {
    type: UPDATE_WORKSHOP_OTHER_DETAIL_SUCCESS,
    payload: data,
  };
};

export const updateWorkshopOtherDetailFail = (error) => {
  return {
    type: UPDATE_WORKSHOP_OTHER_DETAIL_FAIL,
    payload: error,
  };
};
// update workshop other detail END

export const getLabourChargesList = (data) => {
  return {
    type: GET_LABOUR_CHARGES_LIST,
    payload: data,
  };
};

export const getLabourChargesListSuccess = (data) => {
  return {
    type: GET_LABOUR_CHARGES_LIST_SUCCESS,
    payload: data,
  };
};

export const getLabourChargesListFail = (error) => {
  return {
    type: GET_LABOUR_CHARGES_LIST_FAIL,
    payload: error,
  };
};

/** get workshop Markup detail START */
export const getWorkshopMarkupDetail = () => {
  return {
    type: GET_WORKSHOP_MARKUP_DETAILS,
  };
};

export const getWorkshopMarkupDetailSuccess = (data) => {
  return {
    type: GET_WORKSHOP_MARKUP_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getWorkshopMarkupDetailFail = (error) => {
  return {
    type: GET_WORKSHOP_MARKUP_DETAILS_FAIL,
    payload: error,
  };
};

export const editWorkshopMarkupDetailSuccess = (data) => {
  return {
    type: EDIT_WORKSHOP_MARKUP_DETAILS_SUCCESS,
  };
};

/** get workshop Markup detail END */

/** get workshop TAX / RATES detail START */
export const getWorkshopTaxDetail = () => {
  return {
    type: GET_WORKSHOP_TAX_DETAILS,
  };
};

export const getWorkshopTaxDetailSuccess = (data) => {
  return {
    type: GET_WORKSHOP_TAX_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getWorkshopTaxDetailFail = (error) => {
  return {
    type: GET_WORKSHOP_TAX_DETAILS_FAIL,
    payload: error,
  };
};
/** get workshop TAX / RATES detail END */

export const getStatusColor = () => {
  return {
    type: GET_STATUS_COLOR,
  };
};

export const getStatusColorSuccess = (data) => {
  return {
    type: GET_STATUS_COLOR_SUCCESS,
    payload: data,
  };
};

export const getStatusColorFail = (error) => {
  return {
    type: GET_STATUS_COLOR_FAIL,
    payload: error,
  };
};

export const submitJobEmailTemplate = () => {
  return {
    type: SUBMIT_JOB_SMSEMAIL_TEMPLATE,
  };
};

export const submitJobEmailTemplateSuccess = (data) => {
  return {
    type: SUBMIT_JOB_SMSEMAIL_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const submitJobEmailTemplateFail = (error) => {
  return {
    type: SUBMIT_JOB_SMSEMAIL_TEMPLATE_FAIL,
    payload: error,
  };
};

export const getAssets = () => {
  return {
    type: GET_ASSETS,
  };
};

export const getAssetsSuccess = (data) => {
  return {
    type: GET_ASSETS_SUCCESS,
    payload: data,
  };
};

export const getAssetsFail = (error) => {
  return {
    type: GET_ASSETS_FAIL,
    payload: error,
  };
};

export const searchJobs = () => {
  return {
    type: SEARCH_JOBS,
  };
};

export const searchJobsSuccess = (data) => {
  return {
    type: SEARCH_JOBS_SUCCESS,
    payload: data,
  };
};

export const searchJobsFail = (error) => {
  return {
    type: SEARCH_JOBS_FAIL,
  };
};

export const applyCode = () => {
  return {
    type: APPLY_CODE,
  };
};

export const applyCodeSuccess = (data) => {
  return {
    type: APPLY_CODE_SUCCESS,
    payload: data,
  };
};

export const applyCodeFail = (error) => {
  return {
    type: APPLY_CODE_FAIL,
    payload: error,
  };
};

export const searchQuoteJobs = () => {
  return {
    type: SEARCH_QUOTE_JOBS,
  };
};

export const searchQuoteJobsSuccess = (data) => {
  return {
    type: SEARCH_QUOTE_JOBS_SUCCESS,
    payload: data,
  };
};

export const searchQuoteJobsFail = (error) => {
  return {
    type: SEARCH_QUOTE_JOBS_FAIL,
    payload: error,
  };
};
export const getWorkshopTechnician = () => {
  return {
    type: GET_WORKSHOP_TECHNICIAN,
  };
};

export const getWorkshopTechnicianSuccess = (data) => {
  return {
    type: GET_WORKSHOP_TECHNICIAN_SUCCESS,
    payload: data,
  };
};

export const getWorkshopTechnicianFail = (error) => {
  return {
    type: GET_WORKSHOP_TECHNICIAN_FAIL,
    payload: error,
  };
};

export const getsupplierIntegration = () => {
  return {
    type: GET_SUPPLIER_INTEGRATION,
  };
};

export const getsupplierIntegrationSuccess = (data) => {
  return {
    type: GET_SUPPLIER_INTEGRATION_SUCCESS,
    payload: data,
  };
};

export const getsupplierIntegrationFail = (error) => {
  return {
    type: GET_SUPPLIER_INTEGRATION_FAIL,
    payload: error,
  };
};

export const getEmployeeRoles = () => {
  return {
    type: GET_EMPLOYEE_ROLES,
  };
};

export const getEmployeeRolesSucess = (data) => {
  return {
    type: GET_EMPLOYEE_ROLES_SUCCESS,
    payload: data,
  };
};

export const getEmployeeRolesFail = (error) => {
  return {
    type: GET_EMPLOYEE_ROLES_FAIL,
    payload: error,
  };
};

export const getStorageDevices = () => {
  return {
    type: GET_STORAGE_DEVICE,
  };
};

export const getStorageDevicesSuccess = (data) => {
  return {
    type: GET_STORAGE_DEVICE_SUCCESS,
    payload: data,
  };
};

export const getStorageDevicesFail = (error) => {
  return {
    type: GET_STORAGE_DEVICE_FAIL,
    payload: error,
  };
};

export const getNotificationCount = () => {
  return {
    type: GET_NOTIFICATION_COUNT,
  };
};

export const getNotificationCountSuccess = (data) => {
  return {
    type: GET_NOTIFICATION_COUNT_SUCCESS,
    payload: data,
  };
};

export const getNotificationCountFail = (error) => {
  return {
    type: GET_NOTIFICATION_COUNT_FAIL,
    payload: error,
  };
};

export const getInspectionNotification = () => {
  return {
    type: GET_INSPECTION_NOTIFICATION,
  };
};

export const getInspectionNotificationSuccess = (data) => {
  return {
    type: GET_INSPECTION_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const getInspectionNotificationFail = (error) => {
  return {
    type: GET_INSPECTION_NOTIFICATION_FAIL,
    payload: error,
  };
};

export const getOnlineScheduleNotification = () => {
  return {
    type: GET_ONLINE_SCHEDULE_NOTIFICATION,
  };
};

export const getOnlineScheduleNotificationSuccess = (data) => {
  return {
    type: GET_ONLINE_SCHEDULE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const getOnlineScheduleNotificationFail = (error) => {
  return {
    type: GET_ONLINE_SCHEDULE_NOTIFICATION_FAIL,
    payload: error,
  };
};