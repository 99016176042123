import Loadable from "react-loadable";
import { Loading } from "../common/navigation";

const Catalogue = Loadable({
  loader: () => import("./components/Catalogue"),
  loading: Loading,
});

const CreatePart = Loadable({
  loader: () => import("./components/CreatePart"),
  loading: Loading,
});

const EditPart = Loadable({
  loader: () => import("./components/EditPart"),
  loading: Loading,
});

const CreateKit = Loadable({
  loader: () => import("./components/CreateKit"),
  loading: Loading,
});

const EditKit = Loadable({
  loader: () => import("./components/EditKit"),
  loading: Loading,
});

export const routes = [
  {
    path: "/catalogue",
    exact: true,
    name: "Catalogue",
    element: Catalogue,
  },
  {
    path: "/create-part",
    exact: true,
    name: "CreatePart",
    element: CreatePart,
  },
  {
    path: "/edit-part/:id",
    exact: true,
    name: "EditPart",
    element: EditPart,
  },
  {
    path: "/create-kit",
    exact: true,
    name: "CreateKit",
    element: CreateKit,
  },
  {
    path: "/edit-kit/:id",
    exact: true,
    name: "EditKit",
    element: EditKit,
  },
];

export * from "./components";
