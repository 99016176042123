import React from "react";
import CustomerPricingLevelsForm from "./components/customerPricingLevelsForm";
import CustomerPricingLevelsList from "./components/customerPricingLevelsList";

const CustomerPricingLevels = () => {
  return (
    <>
      <div className="card-header">
        <h6 className="mb-0 text-uppercase py-2">
          CUSTOMER PRICING LEVELS (PARTS)
        </h6>
      </div>
      <div className="card-body">
        <CustomerPricingLevelsForm />
        <h6 className="mb-3 text-uppercase py-3 border-bottom">MARK UP</h6>
        <CustomerPricingLevelsList />
      </div>
    </>
  );
};

export default CustomerPricingLevels;
