import { useEffect, useState } from "react";
import Loader from "views/common/UI/Loader";
import quoteImg from "assets/images/cash-sale-blue.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  Modifier,
  convertToRaw,
  ContentState,
  convertFromHTML,
  stateToHTML,
} from "draft-js";
import { updateWorkshopOtherDetail } from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import { showError, showSuccess } from "utils/alertMessage";
import { useDispatch } from "react-redux";
import { UPDATE_COMPANY_OTHER_DETAIL } from "../../../../../store/companyDetails/actionTypes";

const QuoteMessage = ({ workShopOtherDetails }) => {
  
  const dispatch = useDispatch(); // Dispatch variable

  const sessionData = JSON.parse(sessionStorage.getItem('stored_data'));
  
  let workshop_name = window.atob(sessionData.workshop_name);

  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
  const commentsText = "Thank you for choosing "+ workshop_name +" we hope you are completely satisfied with the service provided.";
  const [quoteComments, setQuoteComments] = useState(commentsText);
  const [invoiceComments, setInvoiceComments] = useState(commentsText);
  const storedData = JSON.parse(localStorage.getItem('data'))
  
  useEffect(() =>{

    if(workShopOtherDetails?.quote_comment){
      setQuoteComments(workShopOtherDetails?.quote_comment);
      let { contentBlocks, entityMap } = htmlToDraft(workShopOtherDetails?.quote_comment);
      const edState = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocks, entityMap)
      );
      setEditorState(edState);
    }

    if(workShopOtherDetails?.invoice_comment){
      setInvoiceComments(workShopOtherDetails?.invoice_comment);
      let { contentBlocks, entityMap } = htmlToDraft(workShopOtherDetails?.invoice_comment);
      const edState = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocks, entityMap)
      );
      setEditorState1(edState);
    }

  },[workShopOtherDetails]);

  const onEditorStateChange = (editorState) => {
    let draftToHtmlData = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setEditorState(editorState);
  };

  
  const onEditorStateChange1 = (editorState1) => {
    let draftToHtmlData = draftToHtml(
      convertToRaw(editorState1.getCurrentContent())
    );
    setEditorState1(editorState1);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      

      let description = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      dispatch({
        type: UPDATE_COMPANY_OTHER_DETAIL,
        payload: {
          quote_comment: quoteComments,
          id: parseInt(workShopOtherDetails.id),
        },
      });
      
    }
    setValidated(true);
  };


  const handleSubmit1 = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      

      let description = draftToHtml(
        convertToRaw(editorState1.getCurrentContent())
      );


      dispatch({
        type: UPDATE_COMPANY_OTHER_DETAIL,
        payload: {
          invoice_comment: invoiceComments,
          id: parseInt(workShopOtherDetails.id),
        },
      });
      storedData.workshop.workshop_other_details[0].invoice_comment = invoiceComments;
      localStorage.setItem('data',JSON.stringify(storedData));
    }
    setValidated(true);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="card mb-3">
        <div className="card-header d-flex flex-wrap align-items-center">
          <div className="text-uppercase me-2">
            <h5 className="font-size-16">Quote &amp; Invoice Comments</h5>
          </div>
        </div>
        <div className="card-body quote-invoice-block">
          <div className="row">
            <div className="col-sm-6">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="border mb-3">
                <div className="d-flex align-center border-bottom p-2">
                  <img className="img-block m-r-10" src={quoteImg} alt="quote"></img>
                  <h6 className="mb-0">QUOTES</h6>
                </div>
                <div className="p-3">
                  <label>Add your company comments to the base of the Quote</label>
                  {/* <Form.Control as="textarea" rows={2} /> */}
                  <textarea
                        className="form-control"
                        cols={30}
                        value={quoteComments}
                        onChange={(e) =>
                          setQuoteComments(e.target.value)
                          // formik.setFieldValue("reminderMsg", e.target.value)
                        }
                      />
                  {/* <Editor
                      name="script_description"
                      id="script_description"
                      editorState={editorState}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateChange}
                    /> */}
                </div>
                <div className="btn-wrapper p-3 d-flex justify-flex-end pt-0">
                  <Button
                    className="rounded-btn btn-success"
                    variant="success"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
            </div>
            <div className="col-sm-6">
              <div className="border mb-3">
              <Form noValidate validated={validated} onSubmit={handleSubmit1}>
                <div className="d-flex align-center border-bottom p-2">
                  <i className="fa fa-files-o text-center text-primary font-size-24 m-r-10"></i>
                  <h6 className="mb-0">INVOICE</h6>
                </div>
                <div className="p-3">
                  <label>Add your company comments to the base of the Invoice,Cash sale,Credit Note</label>
                  <textarea
                        className="form-control"
                        cols={30}
                        value={invoiceComments}
                        onChange={(e) =>
                          setInvoiceComments(e.target.value)
                          // formik.setFieldValue("reminderMsg", e.target.value)
                        }
                      />
                  {/* <Form.Control as="textarea" rows={2} /> */}
                  {/* <Editor
                      name="script_description"
                      id="script_description"
                      editorState={editorState1}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      onEditorStateChange={onEditorStateChange1}
                    /> */}
                </div>
                <div className="btn-wrapper p-3 d-flex justify-flex-end pt-0">
                  <Button
                    className="rounded-btn btn-success"
                    variant="success"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
  );
};

export default QuoteMessage;
