import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import registerImage from '../../../assets/images/register-image.jpg';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import FirstStep from './firstStep';
import SecondStep from './secondStep';
import Loader from 'views/common/UI/Loader';
import logo from "assets/images/5iQ_logo_black.png";

let Register = () => {
  const [validated, setValidated] = useState('false');
  const [changeStep, setStep] = useState(true);
  const [formData, setFormData] = useState()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Register | 5iQ";
  },[]);

  function changeFormData(data) {
    setFormData(data);
  }

  function setLoader(loaderValue) {
    setLoading(loaderValue);
  }

  return (
    <>
      {loading && 
        <Loader />
      }

      <div>
        <div className="register-wrapper"> 


          {/* main div section START */}
          <div class="row">

            {/* left content START */}
            <div class="column left-column" >
              <img className="logo" src={logo} alt=""></img>
              <p className="mt-4"> "Together Let's Shape Tomorrow" </p>
            </div>
            {/* left content END */}

            {/* right content START */}
            <div class="column right-column" >
              <form className="form-outer" noValidate validated={validated} id="submit_form" >
                {(changeStep) ? 
                  <FirstStep 
                    setLoader={setLoader} 
                    setStep={setStep} 
                    changeFormData={changeFormData} 
                  /> :
                  <SecondStep
                    formData={formData}
                    setStepSecond={setStep}
                    setStep={setStep} 
                    setValidated={setValidated}
                    setLoader={setLoader} 
                  />}
                <div className="pre-register-footer mt-3">
                  <p className="mb-2">Already Registered ?<Link to="/login" className="primary-link">Login</Link></p>
                </div>
              </form>
            </div>
            {/* right content END */}

          </div>
          {/* main div section END */}

          
        </div>
        
      </div>

      {/* <div className="pre-login-header">
        <h1 className="mb-2 mt-4">Welcome to 5iQ</h1>
      </div>
      <div className="register-wrapper mt-3">
        <Container className="link-wrapper">
          <p className="mb-3">Already Registered? <Link to="/login" className="primary-link mx-2">LOGIN</Link></p>
        </Container>
        <Container className="form-container mt-1">
          <form className="form-outer" noValidate validated={validated} id="submit_form" >
            <Row>
              <Col className="col-block" md={12} lg={6}>
                <img src={registerImage} alt="register-image"></img>
              </Col>
              <Col className="col-block" md={12} lg={6}>
                {(changeStep) ? <FirstStep setLoader={setLoader} setStep={setStep} changeFormData={changeFormData} /> :
                  <SecondStep
                    formData={formData}
                    setStepSecond={setStep}
                    setValidated={setValidated}
                    setLoader={setLoader} 
                  />}
              </Col>
            </Row>
          </form>
        </Container>
      </div> */}
    </>
  );
}
export default Register;