import {
  ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS,
  ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_FAIL,
  ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_SUCCESS,
  EDIT_MESSAGE_TEMPLATE_DETAILS,
  EDIT_MESSAGE_TEMPLATE_DETAILS_FAIL,
  EDIT_MESSAGE_TEMPLATE_DETAILS_SUCCESS,
} from "./actionTypes";

export const addUpdateMessageTemplate = () => {
  return {
    type: ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS,
  };
};

export const addUpdateMessageTemplateSuccess = (data) => {
  return {
    type: ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const addUpdateMessageTemplateFail = (error) => {
  return {
    type: ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_FAIL,
    payload: error,
  };
};

export const editMessageTemplateDetail = () => {
  return {
    type: EDIT_MESSAGE_TEMPLATE_DETAILS,
  };
};

export const editMessageTemplateSuccess = (data) => {
  return {
    type: EDIT_MESSAGE_TEMPLATE_DETAILS_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const editMessageTemplateFail = (error) => {
  return {
    type: EDIT_MESSAGE_TEMPLATE_DETAILS_FAIL,
    payload: error,
  };
};



