export const START_PAYMENT_RECEIPT = 'START_PAYMENT_RECEIPT'
export const START_PAYMENT_RECEIPT_SUCCESS = 'START_PAYMENT_RECEIPT_SUCCESS'
export const START_PAYMENT_RECEIPT_FAIL = 'START_PAYMENT_RECEIPT_FAIL'

export const START_STATEMENT_PAYMENT = 'START_STATEMENT_PAYMENT'
export const START_STATEMENT_PAYMENT_SUCCESS = 'START_STATEMENT_PAYMENT_SUCCESS'
export const START_STATEMENT_PAYMENT_FAIL = 'START_STATEMENT_PAYMENT_FAIL'

export const CLEAR_PAYMENT_RECEIPT = "CLEAR_PAYMENT_RECEIPT";
export const CLEAR_PAYMENT_RECEIPT_SUCCESS = "CLEAR_PAYMENT_RECEIPT_SUCCESS";

export const GET_RECEIPT_DETAILS = "GET_RECEIPT_DETAILS"
export const GET_RECEIPT_DETAILS_SUCCESS = "GET_RECEIPT_DETAILS_SUCCESS"
export const GET_RECEIPT_DETAILS_FAIL = "GET_RECEIPT_DETAILS_FAIL"
export const CLEAR_RECEIPT_DETAILS = "CLEAR_RECEIPT_DETAILS"