import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Setting = Loadable({
  loader: () => import("./components/settings"),
  loading: Loading,
});

const CompanyDetail = Loadable({
  loader: () => import("./components/CompanyDetails/companyDetails"),
  loading: Loading,
});

const DefaultSettings = Loadable({
  loader: () => import("./components/DefaultSettings/defaultSettings"),
  loading: Loading,
});

const SecurityGroup = Loadable({
  loader: () => import("./components/SecurityGroup/securityGroup"),
  loading: Loading,
});

const Statuses = Loadable({
  loader: () => import("./components/Statuses/statuses"),
  loading: Loading,
});

const WorkshopBays = Loadable({
  loader: () => import("./components/WorkshopBays/workshopBays"),
  loading: Loading,
});

const ChartOfAccounts = Loadable({
  loader: () => import("./components/ChartOfAccounts/ChartOfAccounts"),
  loading: Loading,
});

const InspectionTemplateList = Loadable({
  loader: () => import("./components/Inspection/inspection"),
  loading: Loading,
});

const InspectionTemplateCreate = Loadable({
  loader: () => import("./components/Inspection/templateCreate"),
  loading: Loading,
});

const InspectionTemplateEdit = Loadable({
  loader: () => import("./components/Inspection/templateEdit"),
  loading: Loading,
});

const InspectionCategoryList = Loadable({
  loader: () => import("./components/Inspection/categoryList"),
  loading: Loading,
});

const InspectionSectionList = Loadable({
  loader: () => import("./components/Inspection/sectionList"),
  loading: Loading,
});

const InspectionGroupCreate = Loadable({
  loader: () => import("./components/Inspection/groupCreate"),
  loading: Loading,
});

const InspectionCategoryCreate = Loadable({
  loader: () => import("./components/Inspection/categoryCreate"),
  loading: Loading,
});

const Script = Loadable({
  loader: () => import("./components/CannedResponses/script"),
  loading: Loading,
});
const MessageTemplateHeader = Loadable({
  loader: () => import("./components/Notification/MessageTemplateHeader"),
  loading: Loading,
});

const PaymentIntegration = Loadable({
  loader: () => import("./components/PaymentIntegration/PaymentHeader"),
  loading: Loading,
});

const SupplierIntegration = Loadable({
  loader: () => import("./components/SupplierIntegration/SupplierHeader"),
  loading: Loading,
});

const ThirdPartyHeader = Loadable({
  loader: () => import("./components/ThirdPartyCompany/ThirdPartyHeader"),
  loading: Loading,
});

const ThirdPartyDetails = Loadable({
  loader: () => import("./components/ThirdPartyCompany/ThirdPartyDetails"),
  loading: Loading,
});

const StorageDeviceHeader = Loadable({
  loader: () => import("./components/StorageDevice/StorageDeviceHeader"),
  loading: Loading,
});

const StorageDeviceDetails = Loadable({
  loader: () => import("./components/StorageDevice/StorageDeviceDetails"),
  loading: Loading,
});

const UtilityImport = Loadable({
  loader: () => import("./components/UtilitiesImport/UtilityHeader"),
  loading: Loading
});

const SubscriptionTransaction = Loadable({
  loader: () => import("./components/SubscriptionTransaction/SubscriptionTransaction"),
  loading: Loading,
});

export const routes = [
  {
    path: "/settings",
    exact: true,
    name: "settings",
    element: Setting,
  },
  {
    path: "/settings_company_detail",
    exact: true,
    name: "settings_company_detail",
    element: CompanyDetail,
  },
  {
    path: "/settings_defaults",
    exact: true,
    name: "settings_default",
    element: DefaultSettings,
  },
  {
    path: "/settings_security_group",
    exact: true,
    name: "settings_security_group",
    element: SecurityGroup,
  },
  {
    path: "/statuses",
    exact: true,
    name: "settings_statuses",
    element: Statuses,
  },
  {
    path: "/workshop_bays",
    exact: true,
    name: "workshop_bays",
    element: WorkshopBays,
  },
  {
    path: "/chart-of-accounts",
    exact: true,
    name: "ChartOfAccounts",
    element: ChartOfAccounts,
  },
  {
    path: "/inspection/template-lists",
    exact: true,
    name: "inspection_template_list",
    element: InspectionTemplateList,
  },
  {
    path: "/inspection/template-create",
    exact: true,
    name: "inspection_template_create",
    element: InspectionTemplateCreate,
  },
  {
    path: "/inspection/template-edit/:id",
    exact: true,
    name: "inspection_template_edit",
    element: InspectionTemplateEdit,
  },
  {
    path: "/inspection/category-list",
    exact: true,
    name: "inspection_category_list",
    element: InspectionCategoryList,
  },
  {
    path: "/inspection/group-list",
    exact: true,
    name: "inspection_section_list",
    element: InspectionSectionList,
  },
  {
    path: "/inspection/group-edit/:id",
    exact: true,
    name: "inspection_section_edit",
    element: InspectionGroupCreate,
  },
  {
    path: "/inspection/group-create",
    exact: true,
    name: "inspection_section_create",
    element: InspectionGroupCreate,
  },
  {
    path: "/inspection/category-create",
    exact: true,
    name: "inspection_category_create",
    element: InspectionCategoryCreate,
  },
  {
    path: "/inspection/category-edit/:id",
    exact: true,
    name: "inspection_category_create",
    element: InspectionCategoryCreate,
  },
  {
    path: "/stripe",
    exact: true,
    name: "stripe",
    element: PaymentIntegration,
  },
  {
    path: "/supplier_integration",
    exact: true,
    name: "supplier_integration",
    element: SupplierIntegration,
  },
  {
    path: "/script",
    exact: true,
    name: "canned-responses",
    element: Script,
  },
  {
    path: "/notification/create",
    exact: true,
    name: "MessageTemplateHeader",
    element: MessageTemplateHeader,
  },
  {
    path: "/insurance_company_list",
    exact: true,
    name: "insurance_company_list",
    element: ThirdPartyHeader,
  },
  {
    path: "/insurance_company",
    exact: true,
    name: "insurance_company",
    element: ThirdPartyDetails,
  },
  {
    path: "/edit_insurance_company/:id",
    exact: true,
    name: "edit_insurance_company",
    element: ThirdPartyDetails,
  },
  {
    path: "/storage_list",
    exact: true,
    name: "storage_list",
    element: StorageDeviceHeader,
  },
  {
    path: "/storage_create",
    exact: true,
    name: "storage_list",
    element: StorageDeviceDetails,
  },
  {
    path: "/edit_storage/:id",
    exact: true,
    name: "storage_list",
    element: StorageDeviceDetails,
  },
  {
    path: "/utilities_import",
    exact: true,
    name: "utilities_import",
    element: UtilityImport,
  },
  {
    path: "/user-transaction",
    exact: true,
    name: "subscription_transaction",
    element: SubscriptionTransaction
  },
  
];




export * from "./components";