import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { getCurrency } from "utils/getCurrency";

export const PaymentInfoContext = React.createContext(null);

const PaymentConfirmationModal = ({
  showModal,
  closeModal,
  setOpenedModal
}) => {

  
  return (
    <Modal
      className="info-dialog info-dialog-popup"
      size="md"
      show={showModal}
      onHide={closeModal}
    >
      <div className="heading-block d-flex justify-center">
          {/* <i className="la la-exclamation-circle info-icon" aria-hidden="true"></i> */}
          <p className="d-flex font-size-30 font-weight-600 heading-block justify-center text-danger">Warning</p>
          <p className="border p-2 rounded-circle available-hours" >!</p>
      </div>
      <Modal.Body>
          <div>
              <p className="font-size-14 mt-3 mb-3" >You are about to take payment for a {getCurrency}0.00 invoice ?</p>
          </div>
          <div className="d-flex justify-center">
              <Button
                  variant="primary"
                  className="btn-primary rounded-btn"
                  onClick={() => { 
                    closeModal();
                    setOpenedModal("cash-payment");
                  }}
              >
                  Proceed
              </Button>
              <Button
                  variant="primary"
                  className="btn-danger btn-lg rounded-btn"
                  onClick={() => closeModal()}
              >
                  Cancel
              </Button>
          </div>
      </Modal.Body>
    </Modal>

    // <Modal
    //         size="md"
    //         centered
    //         className="rogo-lookup"
    //         show={showModal}
    //         onHide={closeModal}
    //       >
    //         <Modal.Header>
    //           <Modal.Title className="d-flex justify-space-between">
    //             Rego Lookup
    //           </Modal.Title>
    //           <Button
    //             type="button"
    //             className="close-btn"
    //             onClick={closeModal}
    //           >
    //             <i className="la la-times"></i>{" "}
    //           </Button>
    //         </Modal.Header>

    //         <Modal.Body>
              

    //           <Table striped className="chart-data text-center">
    //             <thead>
    //               <tr>
    //                 <th>Total</th>
    //                 <th>Used</th>
    //                 <th>Balanced</th>
    //               </tr>
    //             </thead>
    //             <tbody>
    //               <tr>
                    
    //               </tr>
    //             </tbody>
    //           </Table>

              
    //           <div className="btn-wrapper d-flex justify-center">
    //             <Button
    //               type="button"
    //               className="btn rounded-btn btn-primary"
    //               onClick={() => {
    //                 closeModal();
    //               }}
    //             >
    //               Top Up Now !
    //             </Button>
    //           </div>
    //         </Modal.Body>
    //       </Modal>
  );
};

export default PaymentConfirmationModal;