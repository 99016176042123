import {
  ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS,
  ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_FAIL,
  ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_SUCCESS,
  EDIT_MESSAGE_TEMPLATE_DETAILS,
  EDIT_MESSAGE_TEMPLATE_DETAILS_FAIL,
  EDIT_MESSAGE_TEMPLATE_DETAILS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: {
    message: "",
  },
  success: {
  },

  msgTemplateDetails: null,
  updatedData: {},
};

const MessageTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        updatedData: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case ADD_UPDATE_MESSAGE_TEMPLATE_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case EDIT_MESSAGE_TEMPLATE_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_MESSAGE_TEMPLATE_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        msgTemplateDetails: action.payload,
      };
      break;
    case EDIT_MESSAGE_TEMPLATE_DETAILS_FAIL:
      state = {
        ...state,
        loading: false,
        msgTemplateDetails: null,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MessageTemplateReducer;
