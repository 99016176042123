import Container from "react-bootstrap/Container";
import { getWorkshopId } from "utils/getWorkshopId";
import { InitialData } from "views/common/InitialDataContext/InitialDataContext";
import HQFooter from "./Footer";
import HQHeader from "./Header";
import HQSidebar from "./sidebar";

const HQWrapper = (props) => {
  return (
    <InitialData
      workshop_id={getWorkshopId()}
      sessionData={JSON.parse(sessionStorage.getItem("stored_data"))}
    >
      <div className="main-wrapper">
        <HQHeader />
        <Container fluid>
          <div className="page-wrapper">
            <HQSidebar />
            {props.children}
          </div>
        </Container>
        <HQFooter />
      </div>
    </InitialData>
  );
};

export default HQWrapper;
