export const GET_LOGIN = "GET_LOGIN";
export const GET_LOGIN_WITH_TOKEN = "GET_LOGIN_WITH_TOKEN";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAIL = "GET_LOGIN_FAIL";
export const GET_LOGOUT = "GET_LOGOUT";
export const SET_FIREBASE_TOKEN = "SET_FIREBASE_TOKEN";
export const SET_FIREBASE_TOKEN_SUCCESS = "SET_FIREBASE_TOKEN_SUCCESS";

export const ACTIVE_INPSECTION_BUSINESS = "ACTIVE_INPSECTION_BUSINESS";
export const DEACTIVE_INPSECTION_BUSINESS = "DEACTIVE_INPSECTION_BUSINESS";
export const SET_EXPIRED = "SET_EXPIRED";
