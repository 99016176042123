import Loadable from "react-loadable";

import { Loading } from "views/common/navigation";

const MarketingList = Loadable({
  loader: () => import("./components/MarketingList/MarketingList"),
  loading: Loading,
});
const BroadcastList = Loadable({
  loader: () => import("./components/BroadcastList/BroadcastList"),
  loading: Loading,
});


const EditBroadcastList = Loadable({
  loader: () => import("./components/BroadcastList/EditBroadcastList"),
  loading: Loading,
});



const ReferralList = Loadable({
  loader: () => import("./components/ReferralList/ReferralList"),
  loading: Loading,
});


export const routes = [
  {
    path: "/marketing/marketing-dashboard/",
    exact: true,
    name: "Invoice",
    element: MarketingList,
  },
  {
    path: "/marketing/marketing-dashboard/:data",
    exact: true,
    name: "Invoice",
    element: MarketingList,
  },

  {
    path: "/marketing/broadcast",
    exact: true,
    name: "Invoice",
    element: BroadcastList,
  },
  {
    path: "/marketing/broadcast/:id",
    exact: true,
    name: "Invoice",
    element: EditBroadcastList,
  },

  {
    path: "/marketing/referral",
    exact: true,
    name: "Invoice",
    element: ReferralList,
  },
  // {
  //   path: "/broadcast",
  //   exact: true,
  //   name: "Invoice",
  //   element: BroadcastList,
  // },
]