import React, { useState } from "react";
import logo from "assets/images/5iQ_logo_black.png";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { GET_FORGOT_PASSWORD } from "../../../store/forgotPassword/actionTypes";

let ForgotPassword = (props) => {

  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState();

  const { classes } = props;

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      dispatch({
        type: GET_FORGOT_PASSWORD,
        payload: {
          email: email
        },
      });
    }
    setValidated(true);
  };


    return (
      <div className={classes}>
        
        <div className="pre-login-wrapper" style={{marginTop:"200px"}}> 

          {/* main div section START */}
          <div class="row">

            {/* left content START */}
            <div class="column left-column" >
              <img className="logo" src={logo} alt=""></img>
              <p className="mt-4"> "Together Let's Shape Tomorrow" </p>
            </div>
            {/* left content END */}

            {/* right content START */}
            <div class="column right-column" >

              <Form noValidate validated={validated} onSubmit={handleSubmit} id="forgot-password-form">
              <h1 className="mb-2">Forgot password</h1>
                <Form.Group className="form-group mb-3 has-fa-icon">
                  {/* <Form.Label>Email</Form.Label> */}
                  <Form.Control required type="email" onBlur={(e) => setEmail(e.target.value)} placeholder="Email" />
                  <span className="fa fa-envelope"></span>
                  <Form.Control.Feedback type="invalid">Please Enter Email</Form.Control.Feedback>
                  <div className="d-flex justify-flex-end forgot-password">
                    <Link to='/login' className="text-right secondary-link mt-1">Back to login</Link>
                  </div>
                </Form.Group>
                <div className="btn-wrapper">
                  <Button type="submit" variant="primary" className="primary-btn">SEND RESET LINK</Button>
                </div>
              </Form>

              <div className="pre-login-footer mt-3">
                <p className="mb-2">By<Link to="/register" className="primary-link">signing up</Link>for 5iQ account, you accept 5iQ</p>
                <p>
                <a href={"https://fiiviq.com/terms-conditions"} className="primary-link">
                  Terms of Use
                </a>
                <span>and</span>
                <a href={"https://fiiviq.com/privacy-policy"} className="primary-link">
                  Privacy Policy
                </a>
                </p>
              </div>
            </div>
            {/* right content END */}

          </div>
          {/* main div section END */}
        
          
        </div>
        
      </div>
    );
 }

//  ForgotPassword.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
 export default ForgotPassword;