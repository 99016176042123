import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import useRoleLink from "../hooks/useRoleLink";

const SidebarLinkItem = ({
  main_link,
  normal_img,
  hover_img,
  dark_theme_img,
  icon_class,
  sub_items,
  label,
  bottom_align,
  active
}) => {
  const { filterCondition } = useRoleLink()
  return (
    <li>
      <Link to={main_link} className={active ? "active" : ''}>
        {normal_img && (
          <img className="normal-img" src={normal_img} alt=""></img>
        )}
        {hover_img && <img className="hover-img" src={hover_img} alt=""></img>}
        {dark_theme_img && (
          <img className="dark-theme-img" src={dark_theme_img} alt=""></img>
        )}
        {icon_class && <i className={icon_class} aria-hidden="true"></i>}
        <span>{label}</span>
      </Link>
      {sub_items && sub_items.length > 0 &&
        <ul className={`sub-menu ${bottom_align ? "bottom-align" : ""}`}>
          <Scrollbars
            autoHeight
            autoHeightMax={250}
            className="custom-scrollbar"
            style={{ width: "100%" }}
            renderThumbVertical={(props) => (
              <div {...props} className="thumb-vertical black-thumb" />
            )}
          >
            {sub_items
              .filter(filterCondition)
              .map((item) => (
                <li key={item.label}>
                  <Link to={item.link}>{item.label}</Link>
                </li>
              ))}
          </Scrollbars>
        </ul>
      }
    </li>
  );
};

export default SidebarLinkItem;
