import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "../../style.scss";
import "../style.scss";
import Highcharts from 'highcharts';
import CarMakeLogo from "views/common/UI/CarMakeLogo";
import DashboardHighchart from "./common/DashboardHighchart" ;
import DashboardPieChart from "./common/DashboardPieChart" ;
import RightSection from "./RightSection" ;
import moment from "moment";

export default function HQDashboard() {

    const [onHoverType,setOnHoverType] = useState();
    const [onHoverTypeColour,setOnHoverTypeColour] = useState();

    let bookinSourceData = [
        { name: 'Website', y: 3 },
        { name: 'Walk in', y: 2 },
        { name: 'Phone', y: 1 },
        { name: 'Social Media', y: 1 },
        // { name: 'IE', y: 25.65 },
        // { name: 'Other', y: 5.93 }
    ]

    let makeAndModelData = [
        { name: 'Mazda', y: 20 },
        { name: 'Nissan', y: 15 },
        { name: 'Audi', y: 35 },
        { name: 'Ford', y: 26 },
        { name: 'BMW', y: 10 },
        // { name: 'Other', y: 5.93 }
    ]

    let jobByTypesData = [
        { name: 'Service', y: 3 },
        { name: 'Repairs', y: 2 },
        { name: 'Tyre', y: 1 },
        { name: 'Safety Certificates', y: 1 },
        // { name: 'BMW', y: 10 },
        // { name: 'Other', y: 5.93 }
    ]

    let lineChartForCustomerSurvey = {
        chart: {
            type: 'line',
            height:"75%"
        },
        title: {
            text: ''
        },
        // subtitle: {
        //     text: 'Source: ' +
        //         '<a href="https://en.wikipedia.org/wiki/List_of_cities_by_average_temperature" ' +
        //         'target="_blank">Wikipedia.com</a>'
        // },
        xAxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May'],
            accessibility: {
                description: 'Months of the year'
            }
            // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                formatter: function () {
                    return this.value + '';
                }
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true
        },
        plotOptions: {
            line: {
                marker: {
                    radius: 5,
                    lineColor: '#666666',
                    lineWidth: 1
                },
                dataLabels: {
                    enabled: false
                },
            }
        },
        series: [
            {
                showInLegend: false,
                name: "NPS",
                marker: {
                    symbol: 'circle'
                },
                data: [52, 80, 75, 95,
                    //  18.2, 21.4, 25.0, 
                    // {
                    // y: 26.4,
                    // marker: {
                    //     symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)'
                    // },
                    // accessibility: {
                    //     description: 'Sunny symbol, this is the warmest point in the chart.'
                    // }},
                    //  22.8, 17.5, 12.1, 7.6
                ]
            },
        ]
    }

    const jobChartData = [{
        name: 'Northland',
        y: 63,
    },
    {
        name: 'Auckland',
        y: 50,
    },
    {
        name: 'Waikato',
        y: 40,
    },
    {
        name: 'Taranaki',
        y: 15,
    },
    {
        name: 'Hawe’s Bay',
        y: 30,
    },
    {
        name: 'Wellington',
        y: 10,
    },
    {
        name: 'Other',
        y: 20,
    }]
    const invoiceChartData = [{
        name: 'Northland',
        y: 10.06,
    },
    {
        name: 'Auckland',
        y: 30.84,
    },
    {
        name: 'Waikato',
        y: 40.18,
    },
    {
        name: 'Taranaki',
        y: 20.12,
    },
    {
        name: 'Hawe’s Bay',
        y: 30.33,
    },
    {
        name: 'Wellington',
        y: 10.45,
    },
    {
        name: 'Other',
        y: 20.582,
    }]
    const paymentChartData = [{
        name: 'Northland',
        y: 40.06,
    },
    {
        name: 'Auckland',
        y: 20.84,
    },
    {
        name: 'Waikato',
        y: 30.18,
    },
    {
        name: 'Taranaki',
        y: 10.12,
    },
    {
        name: 'Hawe’s Bay',
        y: 30.33,
    },
    {
        name: 'Wellington',
        y: 10.45,
    },
    {
        name: 'Other',
        y: 20.582,
    }]

    return (
        <>
            <div className="right-section-wrapper has-white-bg">
                <div class="mb-1 row text-center outing-border">
                    <span className="font-size-20">This data represents the month of 
                    <span class="font-weight-600"> {moment().format('MMMM')}</span> </span>
                </div>

                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4 p-1">
                                <DashboardHighchart 
                                    type="JOB" 
                                    chartTitle="Job"
                                    data={jobChartData}
                                />
                            </div>
                            <div className="col-md-4 p-1">
                                <DashboardHighchart 
                                    type="INVOICE" 
                                    chartTitle="Invoice Amount"
                                    data={invoiceChartData}
                                />
                            </div>
                            <div className="col-md-4 p-1">
                                <DashboardHighchart 
                                    type="PAYMENT"
                                    chartTitle="Payment Amount"
                                    data={paymentChartData}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 p-1">
                                <div className="outing-border">
                                    <div className="chart-wrapper">
                                        <div className="d-flex align-center justify-space-between chart-title">
                                            <span>Book in source </span>
                                        </div>
                                        <div className="d-flex align-items-center justify-space-between">
                                            <DashboardPieChart
                                                type="BookingSource"
                                                data={bookinSourceData}
                                                setOnHoverTypeColour={setOnHoverTypeColour}
                                                setOnHoverType={setOnHoverType}
                                            />
                                            
                                            <div class="d-flex flex-column align-content-around chart-content-wrap" >
                                                <div className="outing-border-dashboard" style={{padding:"10px"}}>
                                                    <p className="mb-1 outing-border-dashboard p-1" style={{
                                                        border:(onHoverType === "Website") ? "2px solid "+onHoverTypeColour : ""
                                                    }}>
                                                        <span>Website </span>
                                                        <span>3</span>
                                                    </p>
                                                    <p className="mb-1 outing-border-dashboard p-1" style={{
                                                        border:(onHoverType === "Walk in") ? "2px solid "+onHoverTypeColour : ""
                                                    }}>
                                                        <span>Walk in </span>
                                                        <span>2</span>
                                                    </p>
                                                    <p className="mb-1 outing-border-dashboard p-1" style={{
                                                        border:(onHoverType === "Phone") ? "2px solid "+onHoverTypeColour : ""
                                                    }}>
                                                        <span>Phone </span>
                                                        <span>1</span>
                                                    </p>
                                                    <p className="mb-1 outing-border-dashboard p-1" style={{
                                                        border:(onHoverType === "Social Media") ? "2px solid "+onHoverTypeColour : ""
                                                    }}>
                                                        <span>Social Media </span>
                                                        <span>1</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-center justify-center chart-footer">
                                            <Button className="btn-gradient statusDetail">
                                                View Report
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-1">
                                <div className="outing-border">
                                    <div className="chart-wrapper">
                                        <div className="d-flex align-center justify-space-between chart-title">
                                            <span>Customer Survey / NPS</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-space-between">
                                            <div className="text-center chart-inner-wrap">
                                                <HighchartsReact
                                                    // containerProps={{ style: { height: "400px", width: '400px' } }}
                                                    highcharts={Highcharts}
                                                    options={lineChartForCustomerSurvey}
                                                    allowChartUpdate = {true}
                                                />
                                            </div>
                                            <div class="d-flex flex-column align-content-around chart-content-wrap">
                                                <div className="outing-border-dashboard" style={{padding:"10px"}}>
                                                    <span className="border-bottom d-block mb-1 text-center">{moment().format('MMMM')}</span>
                                                    {/* <p><span>Sent </span><span>5</span></p>
                                                    <p><span>Received </span><span>4</span></p> */}
                                                    <p className="mb-1 outing-border-dashboard p-1"><span>Promotors </span><span>3</span></p>
                                                    <p className="mb-1 outing-border-dashboard p-1"><span>Passive </span><span>2</span></p>
                                                    <p className="mb-1 outing-border-dashboard p-1"><span>Detractors </span><span>1</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-center justify-center chart-footer">
                                            <Button className="btn-gradient statusDetail">
                                                View Report
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 p-1">
                                <div className="outing-border">
                                    <div className="chart-wrapper">
                                        <div className="d-flex align-center justify-space-between chart-title">
                                            <span>Top 5 Make and Model</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-space-between">
                                            <DashboardPieChart
                                                type="MakeAndModel"
                                                data={makeAndModelData}
                                                setOnHoverTypeColour={setOnHoverTypeColour}
                                                setOnHoverType={setOnHoverType}
                                            />
                                            <div class="d-flex flex-column align-content-around chart-content-wrap">
                                                <div className="outing-border-dashboard" style={{padding:"10px"}}>
                                                    <p className="align-items-center d-flex justify-content-center mb-1 outing-border-dashboard p-1 make-dashboard" style={{
                                                        border:(onHoverType === "Mazda") ? "2px solid "+onHoverTypeColour : ""
                                                    }}>
                                                        <span className="align-items-center d-flex">
                                                            <CarMakeLogo make={"MAZDA"}/> 
                                                            Mazda 
                                                        </span>
                                                        <span>20</span>
                                                    </p>
                                                    <p className="align-items-center d-flex justify-content-center mb-1 outing-border-dashboard p-1 make-dashboard" style={{
                                                        border:(onHoverType === "Nissan") ? "2px solid "+onHoverTypeColour : ""
                                                    }}><span className="align-items-center d-flex"><CarMakeLogo make={"Nissan"}/>Nissan </span><span>15</span></p>
                                                    <p className="align-items-center d-flex justify-content-center mb-1 outing-border-dashboard p-1 make-dashboard" style={{
                                                        border:(onHoverType === "Audi") ? "2px solid "+onHoverTypeColour : ""
                                                    }}><span className="align-items-center d-flex"><CarMakeLogo make={"AUDI"}/>Audi </span><span>35</span></p>
                                                    <p className="align-items-center d-flex justify-content-center mb-1 outing-border-dashboard p-1 make-dashboard" style={{
                                                        border:(onHoverType === "Ford") ? "2px solid "+onHoverTypeColour : ""
                                                    }}><span className="align-items-center d-flex"><CarMakeLogo make={"FORD"}/>Ford </span><span>26</span></p>
                                                    <p className="align-items-center d-flex justify-content-center mb-1 outing-border-dashboard p-1 make-dashboard" style={{
                                                        border:(onHoverType === "BMW") ? "2px solid "+onHoverTypeColour : ""
                                                    }}><span className="align-items-center d-flex"><CarMakeLogo make={"BMW"}/>BMW </span><span>10</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-center justify-center chart-footer">
                                            <Button className="btn-gradient statusDetail">
                                                View Report
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-1">
                                <div className="outing-border">
                                    <div className="chart-wrapper">
                                        <div className="d-flex align-center justify-space-between chart-title">
                                            <span>Job by Types</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-space-between">
                                            <DashboardPieChart
                                                type="JobByTypes"
                                                data={jobByTypesData}
                                                setOnHoverTypeColour={setOnHoverTypeColour}
                                                setOnHoverType={setOnHoverType}
                                            />
                                            <div class="d-flex flex-column align-content-around chart-content-wrap">
                                                <div className="outing-border-dashboard" style={{padding:"10px"}}>
                                                    <p className="mb-1 outing-border-dashboard p-1" style={{
                                                        border:(onHoverType === "Service") ? "2px solid "+onHoverTypeColour : ""
                                                    }}><span>Service </span><span>3</span></p>
                                                    <p className="mb-1 outing-border-dashboard p-1" style={{
                                                        border:(onHoverType === "Repairs") ? "2px solid "+onHoverTypeColour : ""
                                                    }}><span>Repairs </span><span>2</span></p>
                                                    <p className="mb-1 outing-border-dashboard p-1" style={{
                                                        border:(onHoverType === "Tyre") ? "2px solid "+onHoverTypeColour : ""
                                                    }}><span>Tyre </span><span>1</span></p>
                                                    <p className="mb-1 outing-border-dashboard p-1" style={{
                                                        border:(onHoverType === "Safety Certificates") ? "2px solid "+onHoverTypeColour : ""
                                                    }}><span>Safety <br/>Certificates </span><span>1</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-center justify-center chart-footer">
                                            <Button className="btn-gradient statusDetail">
                                                View Report
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 p-1">
                        <RightSection/>
                    </div>
                </div>
            </div>
        </>
    );
}
