import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { unserializeArr } from "../../../utils/unserialize";
import { GET_ASSIGNED_JOBS } from "../../../store/assignedJobs/actionTypes"
import { utcToTimezone, timeDiffMinute, convertToHours } from "../../../utils/timeZoneConvert";
import { checkMakeIcon } from "../../../utils/makeIcon";
import Timer from "./Timer";
import { PRINT_JOB_CARD_PDF, GET_MONTH_JOB_LIST } from "../../../store/scheduler/actionTypes";
import { downloadFileFromS3 } from "../../../utils/uploadFileToS3";
import { Scrollbars } from "react-custom-scrollbars";
import Reschedule from "./Reschedule";
import LoaderImage from "../../../assets/images/page-loader.svg";
import ApproveJobModal from "./ApproveJob";
import { showSuccess } from "../../../utils/alertMessage";
import CarMakeLogo from "views/common/UI/CarMakeLogo";
import ClockInWarningModal from "views/modals/ClockInWarningModal";
import RescheduleOnly from './RescheduleOnly';

const DetailsTable = ({ clickDate, setUpdateMonthView, updateMonthView }) => {

    const dispatch = useDispatch(); // Dispatch variable
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);
    let timezoneLable = window.atob(storedData.timezone_lable);
    let time_format = storedData.time_format;
    let date_format = storedData.date_format;
    const [loading, setLoading] = useState(false);
    const [clickDay, setClickDay] = useState();
    const jobList = useSelector((state) => state.SchedulerReducer.jobData);
    const { jobData } = useSelector((state) => state.assignedJobsReducer);
    const { statusColor, Workshops } = useSelector((state) => state.CommonApiStoreReducer);
    const [jobArrList, setJobArrList] = useState([]);
    const [bookedHours, setBookedHours] = useState("00:00");
    const [availableWorkshopHours, setAvailableWorkshopHours] = useState("00:00");
    const [availableHours, setAvailableHours] = useState("00:00");
    const [technicianTotalMin, setTechnicianTotalMin] = useState(0);
    const [bookedMinutes, setBookedMinutes] = useState(0);
    const [workshopDetails, setWorkshopDetails] = useState(false);
    const [jobIdData, setJobIdData] = useState();
    const [timerShow, setTimerShow] = useState(false);
    const [clockInShow, setClockInShow] = useState(false);
    const [clickForPdf, setClickForPdf] = useState(false);

    useEffect(() => {
        if (clockInShow) {
            setTimerShow(false);
        }
    }, [clockInShow])

    // Job Timer 
    const handleTimerClose = () => setTimerShow(false);
    const handleTimerShow = (id) => {
        setJobIdData(id);
        timerShowData();
    }
    const timerShowData = () => {
        setTimerShow(true);
    }
    // Generate PDF
    const getJobCardPdf = (id) => {
        dispatch({
            type: PRINT_JOB_CARD_PDF,
            payload: {
                id: id,
                workshop_id: workshop_id
            }
        });
        setClickForPdf(true)
    }
    const jobCardPdf = useSelector((state) => state.SchedulerReducer.jobCardPdfPath);
    useEffect(() => {
        if (jobCardPdf !== null && jobCardPdf !== undefined && clickForPdf === true) {
            const downloadUrl = downloadFileFromS3(jobCardPdf.path);
            const newWindow = window.open(downloadUrl, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
            setClickForPdf(false);
        }
    }, [jobCardPdf])

    useEffect(() => {
        var elem = document.getElementById("list-table");
        elem.scrollIntoView();
    }, [])

    useEffect(() => {
        if (clickDate !== null) {
            dispatch({
                type: GET_MONTH_JOB_LIST,
                payload: {
                    get: "all",
                    conditions: {
                        // "JobSchedules.from_date >=": clickDate,
                        // "JobSchedules.to_date <=": clickDate,
                        // "JobSchedules.is_no_show": 0,
                        "JobSchedules.is_copid_job": 0,
                        "JobSchedules.workshop_id": workshop_id,
                        "OR" :
                        [
                            {
                                "JobSchedules.from_date <=": clickDate,
                                "JobSchedules.to_date >=": clickDate
                            },
                            {
                                "JobSchedules.from_date": clickDate,
                                "JobSchedules.to_date <": clickDate
                            }
                        ]
                    },
                    contain: {
                        0: "Users",
                        1: "WorkshopDepartmentsAccountsDetails",
                        3: "VehiclesB.Users.CustomersDetails",
                        "Customers": {
                            "fields": [
                                "id",
                                "email"
                            ]
                        },
                        "Customers.CustomersDetails": {
                            conditions: {
                                "workshop_id": workshop_id
                            }
                        },
                        WorkshopBaysDetails: {
                            fields: [
                                "id",
                                "name"
                            ]
                        },
                        JobBookingTypes: {
                            fields: [
                                "id",
                                "name"
                            ]
                        },
                        ActivitySchedule: {
                            fields: ['id', 'name', 'workshop_id']
                        },
                        'ActivitySchedule.ActivityMaster': {
                            fields: ['id', 'name', 'type', 'workshop_id']
                        }
                    }
                }
            })

            dispatch({
                type: GET_ASSIGNED_JOBS,
                payload: {
                    get: "all",
                    conditions: {
                        workshop_id: workshop_id,
                        from_date: clickDate,
                        is_no_show: 0,
                        is_copid_job: 0
                    },
                    fields: ["duration"],
                }
            })

            setClickDay(new Date(clickDate).getDay());
            setLoading(true);
        }
    }, [clickDate])

    
    useEffect(() => {
        if (jobList !== null && jobList !== undefined) {
            setLoading(false);
            let jobListArr = [];
            jobList.map((jobs, index) => {
                var jobArr = {};
                jobArr['from_time'] = moment(new Date(jobs.from_time)).format(time_format);
                jobArr['to_time'] = moment(new Date(jobs.to_time)).format(time_format);
                // jobArr['from_date'] = moment(new Date(jobs.from_time)).format(date_format+" "+time_format);
                var fromDate = moment(new Date(jobs.from_date)).format('YYYY-MM-DD');
                var fromTime = moment(new Date(jobs.from_time)).format('HH:mm:ss');
                jobArr['from_date'] = moment(fromDate + " " + fromTime).format("YYYY-MM-DDTHH:mm:ss");
                var type = jobs?.customer?.customers_details?.[0]?.type || '';
                var customer_name = '';
                if (type === 1 || type === "1") {
                    customer_name = jobs?.customer?.customers_details?.[0]?.company_name || '';
                } else {
                    var firstname = jobs?.customer?.customers_details?.[0]?.firstname || '';
                    var lastname = jobs?.customer?.customers_details?.[0]?.lastname || '';
                    customer_name = firstname + " " + lastname;
                }
                jobArr['activity_schedule_id'] = jobs?.activity_schedule_id || '';
                jobArr['main_activity_name'] = jobs?.activity_schedule?.activity_master?.name || '';
                jobArr['activity_name'] = jobs?.activity_schedule?.name || '';
                jobArr['customer_name'] = customer_name;
                jobArr['mobile_number'] = jobs?.customer?.customers_details?.[0]?.mobile_number || '';
                jobArr['email'] = jobs?.customer?.email || '';
                jobArr['make_icon'] = '';
                jobArr['rego'] = jobs?.rego || '';
                jobArr['make'] = jobs?.vehicles_b?.make || '';
                jobArr['model'] = jobs?.vehicles_b?.model || '';
                jobArr['year'] = jobs?.vehicles_b?.year || '';
                jobArr['job_number'] = jobs?.job_number || '';
                jobArr['job_booking_type'] = jobs?.job_booking_type?.name || '';

                var user = jobs?.user?.id || null;
                var username = '';
                if (user !== null) {
                    var first_uname = jobs?.user?.firstname || '';
                    var last_uname = jobs?.user?.lastname || '';
                    username = first_uname + " " + last_uname;
                } else {
                    if (jobs.workshop_bays_detail !== null && jobs.workshop_bays_detail !== undefined) {
                        var bayName = jobs?.workshop_bays_detail?.name || '';
                        username = bayName;
                    }
                }
                jobArr['username'] = username;

                if (jobs?.job_status == "Payment") {
                    jobArr['job_status'] = "Paid";
                } else {
                    jobArr['job_status'] = jobs?.job_status || '';
                }
                jobArr['status_color'] = '';
                jobArr['id'] = jobs.id;
                jobArr['timer_disabled'] = '';
                jobArr['reschedule_disabled'] = '';
                jobArr['carry_over_disabled'] = '';
                if (jobs.bay_id !== 0) {
                    jobArr['timer_disabled'] = 'disable-option';
                }

                if ((jobs.job_status !== "InProgress" && jobs.job_status !== "OnHold") || jobs.is_copid_job === 1) {
                    jobArr['carry_over_disabled'] = 'disable-option';
                }
                if (jobs.job_status !== "Pending") {
                    jobArr['reschedule_disabled'] = 'disable-option';
                }
                if (jobs.job_status === "Rescheduled") {
                    jobArr['timer_disabled'] = 'disable-option';
                    jobArr['reschedule_disabled'] = '';
                    jobArr['carry_over_disabled'] = 'disable-option';
                }
                jobListArr.push(jobArr);
            })
            setJobArrList(jobListArr);
        }
    }, [jobList])

    useEffect(() => {
        // for Booked Hours
        if (jobData !== null && jobData !== undefined) {
            var sumDuration = 0;
            for (let i = 0; i < jobData.length; i++) {
                sumDuration += jobData[i]['duration'];
            }
            setBookedMinutes(sumDuration);
            setBookedHours(convertToHours(sumDuration));
        }
    }, [jobData])

    if (Workshops !== null && Workshops !== undefined) {
        if (workshopDetails !== true) {
            var workshopOtherDetails = Workshops.workshop_other_details[0];
            var users = Workshops.users;
            var techDetails = users.length;
            var scheduleDetailsVar = workshopOtherDetails.schedule_details;
            var scheduleDetails = '';
            var totalOffDay = [];
            var totalWorkingDay = [];
            if (scheduleDetailsVar !== "") {
                scheduleDetails = unserializeArr(scheduleDetailsVar);
            }

            //if technician is on leave then that technician hours is not count in total hours
            // eslint-disable-next-line array-callback-return
            users.map((user) => {
                var shiftData = user?.employees_details?.[0]?.shift_working_day || null;
                if (shiftData !== null) {
                    var employeeDetails = unserializeArr(shiftData);
                    if (!employeeDetails.includes('' + clickDay)) {
                        techDetails = techDetails - 1;
                    }
                }
            })

            //count total working days of workshop
            for (var i of times(7)) {
                if (scheduleDetails[i]['status'] === 0 || scheduleDetails[i]['status'] === "") {
                    totalOffDay.push(i)
                } else {
                    totalWorkingDay.push(i)
                }
            }

            if (totalWorkingDay.includes(clickDay)) {
                var startTime = moment("HH:mm:ss");
                var endTime = moment("HH:mm:ss");
                //get start time and end time of workshop
                if (scheduleDetails[clickDay]['status'] === "1" || scheduleDetails[clickDay]['status'] === 1) {
                    startTime = utcToTimezone(scheduleDetails[clickDay]['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss')
                    endTime = utcToTimezone(scheduleDetails[clickDay]['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                    if (endTime === "00:00:00") {
                        endTime = "24:00:00";
                    }
                }

                //count of total working hours of workshop
                var workshopTotalMinutes = timeDiffMinute(startTime, endTime);

                // For remove 1 hour default as a break time
                var techLunchBreak = techDetails * 60;

                //Total technician hours * Total Workshop hours = Available Workshop Hours
                var techTotalMin = workshopTotalMinutes * techDetails;
                techTotalMin = techTotalMin - techLunchBreak;

                setTechnicianTotalMin(techTotalMin);
                setAvailableWorkshopHours(convertToHours(techTotalMin));
                setWorkshopDetails(true);
            }
        }
    }

    useEffect(() => {
        var availableMinutes = technicianTotalMin - bookedMinutes;
        setAvailableHours(convertToHours(availableMinutes));
    }, [technicianTotalMin, bookedMinutes])

    function* times(x) {
        for (var i = 0; i < x; i++)
            yield i;
    }

    const emptyDataMessage = () => {
        return (
            <div style={{ textAlign: "center" }}>No data available in table</div>
        );
    };

    const checkStatusColor = (cell, row) => {
        if (statusColor !== null && row.activity_schedule_id == 0) {
            var colourCell = cell;
            if (colourCell === "Paid") {
                colourCell = "Payment";
            }
            return (
                <div
                    style={{
                        background: statusColor[colourCell],
                    }}
                    className="jobStatus"
                >
                    {cell}
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        background: "#000",
                    }}
                    className="jobStatus"
                >
                    Activity
                </div>
            );
        }
    };

    const columns = [{
        dataField: "from_time",
        text: "Time",
        headerAttrs: {
            hidden: true
        },
        style: { 'width': '6%' },
        formatter: (cell, row) => {
            return (
                <div>{(row.activity_schedule_id == 0) ? row.from_time : ''}</div>
            )
        },
    },
    {
        dataField: "customer_name",
        text: "First Name",
        headerAttrs: {
            hidden: true
        },
        style: { 'width': '22%' },
        formatter: (cell, row) => {
            return (
                <div className="align-center d-flex block-wrap no-wrap" style={{ wordBreak: "break-all" }}>
                    <div className="profile">
                        <i className="fa fa-user"></i>
                    </div>
                    <div>
                        {(row.activity_schedule_id == 0) ? <><p>{row.customer_name}</p>
                            <span>{row.mobile_number} ~ {row.email}</span></>
                            : <p>{row.username}</p>}
                    </div>
                </div>
            )
        },
    },
    {
        dataField: "rego",
        text: "Vehicle",
        headerAttrs: {
            hidden: true
        },
        style: { 'width': '21%' },
        formatter: (cell, row) => {
            return (
                <div className="align-center d-flex block-wrap">
                    {(row.activity_schedule_id == 0) ?
                        <><CarMakeLogo make={row && row?.make || ""} />
                            {/* <i className={checkMakeIcon(row.make) + " font-size-20 text-primary text-center"} ></i> */}
                            <div>
                                <p>Rego:{row.rego}</p>
                                <span> {row.make} {row.model} {row.year}</span>
                            </div>
                        </> : <p>{row.main_activity_name}</p>}
                </div>
            )
        },
    },
    {
        dataField: "job_number",
        text: "Job",
        headerAttrs: {
            hidden: true
        },
        style: { 'width': '11%' },
        formatter: (cell, row) => {
            return (
                <div>
                    {
                        (row.activity_schedule_id == 0) ?
                            <>{"Job #"} <Link to={"/job_detail/" + row.id}>{cell}</Link> </>
                            : <p>{row.activity_name}</p>
                    }
                </div>
            )
        },
    },
    {
        dataField: "job_booking_type",
        text: "Parts",
        headerAttrs: {
            hidden: true
        },
        style: { 'width': '12%' },
        formatter: (cell, row) => {
            return (
                <div>
                    {
                        (row.activity_schedule_id == 0) ?
                            <div className="text-nowrap">{row.job_booking_type}</div>
                            : <p>{row.from_time} - {row.to_time}</p>
                    }
                </div>
            )
        },
    },
    {
        dataField: "username",
        text: "mechanic",
        headerAttrs: {
            hidden: true
        },
        style: { 'width': '11%' },
        formatter: (cell, row) => {
            return (
                <>
                    {
                        (row.activity_schedule_id == 0) ?
                            <div className="text-nowrap">{row.username}</div>
                            : <p></p>
                    }
                </>
            )
        },
    },
    {
        dataField: "job_status",
        text: "Status",
        formatter: checkStatusColor,
        headerAttrs: {
            hidden: true
        },
        style: { 'width': '9%' },
        // formatter: (cell, row) => {
        //     return (
        //         <div>
        //             <i className="glyphicon glyphicon-option-horizontal"></i>
        //             <div className="rounded-pill" style={{ background: "#f9990b" }}><small>Pending</small></div>
        //         </div>
        //     )
        // },
    },
    {
        dataField: "df1",
        isDummyField: true,
        text: "Action 1",
        headerAttrs: {
            hidden: true
        },
        style: { 'width': '8%' },
        formatter: (cell, row) => {
            let newDiv = "";
            if (row.activity_schedule_id == 0) {
                newDiv = <div className="d-flex justify-content-end more-options">
                    <button className="action-btn border-0 bg-transparent"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></button>
                    <ul className="options-list">
                        {row.is_copid_job === 1 ? (
                            <Link to={"/view_job/" + row.id}>
                                <span>View Job</span>
                            </Link>
                        ) : (
                            <li>
                                <Link to={"/job_detail/" + row.id}>
                                    <span>View Job</span>
                                </Link>
                            </li>
                        )}
                        <li className={row.timer_disabled}>
                            <Link to="#" onClick={() => {
                                if (moment(clickDate).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
                                    setClockInShow(true);
                                    handleRescheduleShow(row.id, row.username, row.job_number, row.from_date, 'time_reschedule')
                                } else {
                                    handleTimerShow(row.id)
                                }
                            }}>
                                <span>Time Clock</span>
                            </Link>
                        </li>
                        <li>
                            <Link target="_blank" to={`/booking-diary/print-job-cards?id=${row.id}`}  >
                                <span>Job Card</span>
                            </Link>
                        </li>
                        <li className={row.reschedule_disabled}>
                            <Link to="#" onClick={() => { handleRescheduleShow(row.id, row.username, row.job_number, row.from_date, 'reschedule') }}>
                                <span>Reschedule</span>
                            </Link>
                        </li>
                        <li className={"carry-over-option  " + row.carry_over_disabled}>
                            <Link to="#" onClick={() => { handleRescheduleShow(row.id, row.username, row.job_number, row.from_date, 'carryover') }}>
                                <span>Carry Over Job</span>
                            </Link>
                        </li>
                        {
                            // (row.job_status === "Rescheduled") ?
                            //     <li className="approve-job-option">
                            //         <span onClick={() => approveJob(row.id)}>
                            //             Apporve Job
                            //         </span>
                            //     </li> : ''
                        }
                    </ul>
                </div>
            }
            return newDiv;
        },
    }
    ];

    const [rescheduleShow, setRescheduleShow] = useState(false);
    const handleRescheduleClose = () => {
        setRescheduleShow(false);
    }
    const [employeeName, setEmployeeName] = useState();
    const [jobNumber, setJobNumber] = useState();
    const [startEventTime, setStartEventTime] = useState();
    const [jobType, setJobType] = useState('reschedule');
    const handleRescheduleShow = (id, name, job_number, time, type) => {
        setStartEventTime(time);
        setJobIdData(id);
        setEmployeeName(name);
        setJobNumber(job_number);
        if (type == "time_reschedule") {
            setJobType("Rescheduled");
        } else {
            setJobType(type);
            setRescheduleShow(true);
        }
    };

    // Approve Job for reschedule
    const [approveJobId, setApproveJobId] = useState(null);
    const [approveJobFlag, setApproveJobFlag] = useState(false);
    const [approveJobModal, setApproveJobModal] = useState(false);
    const approveJob = (id) => {
        setApproveJobId(id)
        setApproveJobModal(true);
    }
    const approvedJobData = useSelector((state) => state.JobDetails.jobTimerData);
    useEffect(() => {
        if (approvedJobData !== null && approvedJobData !== undefined && approveJobFlag === true) {
            showSuccess('Job Approved Successfully');
            setApproveJobFlag(false);
            setUpdateMonthView(!updateMonthView);
        }
    }, [approvedJobData])

    return (
        <>
            {
                loading ? <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div> : ""
            }
            {
                approveJobModal && <ApproveJobModal setApproveJobModal={setApproveJobModal} approveJobId={approveJobId} setApproveJobFlag={setApproveJobFlag} />
            }
            <div id="list-table" className="detail-table-wrapper right-section-wrapper card bg-light px-4 mb-3">
                <div className="table-header rounded">
                    <div className="row d-flex align-center">
                        <div className="col-md-4 left-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <span>{moment(new Date(clickDate)).format(date_format)}</span>
                                </div>
                                <div className="col-md-6">
                                    <span>Hours Capacity</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-2 time-block">
                                    <b>{availableWorkshopHours} Hrs</b>
                                    <p>Billable</p>
                                </div>
                                <div className="col-md-2 time-block">
                                    <b>{bookedHours} Hrs</b>
                                    <p>Booked</p>
                                </div>
                                <div className="col-md-2 time-block">
                                    <b>{availableHours} Hrs</b>
                                    <p>Available</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Scrollbars
                    className="custom-scrollbar" style={{ width: '100%', height: 400 }}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical grey-thumb" />}>
                    <BootstrapTable
                        remote
                        keyField="id"
                        data={jobArrList}
                        columns={columns}
                        noDataIndication={emptyDataMessage}
                    />
                </Scrollbars>
            </div>
            {
                (timerShow === true) ? <Timer
                    timerShow={timerShow}
                    handleTimerClose={handleTimerClose}
                    jobIdData={jobIdData}
                    setUpdateCalendar={setUpdateMonthView}
                    updateCalendar={updateMonthView}
                    setClockInShow={setClockInShow}
                >
                </Timer> : ''
            }
            {clockInShow &&
                <ClockInWarningModal
                    setRescheduleShow={setRescheduleShow}
                    clockInShow={clockInShow}
                    setClockInShow={setClockInShow}
                />
            }
            {
                (rescheduleShow === true) ?
                    <RescheduleOnly
                        rescheduleShow={rescheduleShow}
                        handleRescheduleClose={handleRescheduleClose}
                        jobIdData={jobIdData}
                        startEventTime={startEventTime}
                        technicianName={employeeName}
                        jobNumber={jobNumber}
                        setUpdateCalendar={setUpdateMonthView}
                        updateCalendar={updateMonthView}
                        jobType={jobType}
                    ></RescheduleOnly> : ''
            }
        </>
    )
}

export default DetailsTable;