import {
  GET_UPDATE_VEHICLE,
  GET_UPDATE_VEHICLE_SUCCESS,
  GET_UPDATE_VEHICLE_FAIL,
  GET_VEHICLES_HISTORY,
  GET_VEHICLES_HISTORY_SUCCESS,
  GET_VEHICLES_HISTORY_FAIL,
  ADD_VEHICLES_HISTORY,
  ADD_VEHICLES_HISTORY_SUCCESS,
  ADD_VEHICLES_HISTORY_FAIL,
} from "./actionTypes";

const initialState = {
  vehicleData: null,
  vehiclesHistoryData: null,
  error: {
    message: "",
  },
  addVehicleHistoryData:null
};

const VehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPDATE_VEHICLE:
      state = {
        ...state,
      };
      break;
    case GET_UPDATE_VEHICLE_SUCCESS:
      state = {
        ...state,
        vehicleData: action.payload
      };
      break;
    case GET_UPDATE_VEHICLE_FAIL:
      state = {
        ...state,
        vehicleData: action.payload,
        error: {
          message: action.payload,
        }
      };
      break;
    
    case GET_VEHICLES_HISTORY:
      state = {
        ...state,
      };
      break;
    case GET_VEHICLES_HISTORY_SUCCESS:
      state = {
        ...state,
        vehiclesHistoryData: action.payload
      };
      break;
    case GET_VEHICLES_HISTORY_FAIL:
      state = {
        ...state,
        vehiclesHistoryData: [],
        error: {
          message: action.payload,
        }
      };
      break;
    
    case ADD_VEHICLES_HISTORY:
      state = {
        ...state,
      };
      break;
    case ADD_VEHICLES_HISTORY_SUCCESS:
      state = {
        ...state,
        addVehicleHistoryData: action.payload
      };
      break;
    case ADD_VEHICLES_HISTORY_FAIL:
      state = {
        ...state,
        addVehicleHistoryData: [],
        error: {
          message: action.payload,
        }
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VehicleReducer;
