import Loadable from "react-loadable";

import { Loading } from "views/common/navigation";

const HQStoreList = Loadable({
  loader: () => import("./components/StoreList"),
  loading: Loading,
});

export const routes = [
  {
    path: "/stores",
    exact: true,
    name: "stores",
    element: HQStoreList,
  },
];

export * from "./components";
