import React, { useEffect, useState, useMemo } from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { DatePicker } from "antd";
import moment from "moment";

const CustomFieldData = ({
        customFieldsData = "",
        vehiclesFormData,
        handleVehiclesInputChange,
        setVehiclesFormData
    }) => {

    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let date_format = storedData.date_format;
    let workshop_id = window.atob(storedData.workshop_id);
    let listArr = [];

    return (
        <>
        <Accordion className="custom-accordion p-0 w-100 mt-2 alwaysOpen">
            <Accordion.Item eventKey="0">
                <Accordion.Header >
                    <span className="p-1">Custom Fields</span>
                </Accordion.Header>
                <Accordion.Body className="p-2">
                    <div className="row">
                        {customFieldsData && customFieldsData.map((customField, index) => {
                            if (customField.field_type === "1") {
                                return (
                                    <Col key={customField?.id+"-"+index} xs={12} md={6}>
                                        <Form.Group className="form-group mb-3">
                                            <label>{customField.label}</label>
                                            <Form.Control
                                                type="text"
                                                name={customField.name}
                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                value={vehiclesFormData[customField.label]}
                                            />
                                        </Form.Group>
                                    </Col>
                                );
                            }

                            if (customField.field_type === "2") {
                                return (
                                    <Col key={customField?.id+"-"+index} xs={12} md={6}>
                                    <Form.Group className="form-group date-picker mb-3">
                                        <label>{customField.label}</label>
                                        <DatePicker
                                            allowClear={true}
                                            className="form-control customfield-datepicker"
                                            id={customField.name}
                                            name={customField.name}
                                            onChange={(value) => {
                                            if(value){
                                                setVehiclesFormData({
                                                ...vehiclesFormData,
                                                [customField.name]: value.format(date_format),
                                                });
                                            }else{
                                                setVehiclesFormData({
                                                ...vehiclesFormData,
                                                [customField.name]: "",
                                                });
                                            }
                                            }}
                                            value={
                                            vehiclesFormData[customField.name] &&
                                            vehiclesFormData[customField.name] !== undefined
                                                ? moment(vehiclesFormData[customField.name], date_format)
                                                : ""
                                            }
                                            format={date_format}
                                        />
                                    </Form.Group>
                                    </Col>
                                );
                            }

                            if (customField?.field_type === "3") {
                                listArr = customField?.list_value?.split(",");
                                return (
                                    <Col key={customField?.id+"-"+index} xs={12} md={6}>
                                    <Form.Group className="form-group mb-3">
                                        <label>{customField.label}</label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name={customField.name}
                                            onChange={(e) => handleVehiclesInputChange(e)}
                                            value={vehiclesFormData[customField.label]}
                                        >
                                            <option value="">Select</option>
                                            {listArr?.map((list, index) => (
                                                <option key={list} value={list}>
                                                    {list}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    </Col>
                                );
                            }
                        })}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </>
    )
}

export default CustomFieldData;
