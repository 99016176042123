import * as actions from "./actionTypes";

const initialState = {
  loading: false,
  subscriptionData: [],
  subscriptionDetailsData: [],
  subscriptionHistoryData: [],
  isFetching: false,
  subscriptionHistoryDetailsData: [],
  monthlySubscriptionData: [],
  error: {
    message: "",
  },
  planDataLoading: true,
  detailsPlanDataLoading: true
};

const SubscriptionTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        loading: true,
        isFetching: false,
      };

    case actions.GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionData: action.payload,
        isFetching: true,
      };

    case actions.GET_SUBSCRIPTION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        subscriptionData: [],
        error: {
          message: action.payload,
        },
        isFetching: true,
      };

    case actions.GET_SUBSCRIPTION_HISTORY_LIST:
      return {
        ...state,
        loading: true,
        isFetching: false,
      };

    case actions.GET_SUBSCRIPTION_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionHistoryData: action.payload,
        isFetching: true,
      };

    case actions.GET_SUBSCRIPTION_HISTORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        subscriptionHistoryData: [],
        error: {
          message: action.payload,
        },
        isFetching: true,
      };

    case actions.GET_SUBSCRIPTION_HISTORY_DETAIL_LIST:
      return {
        ...state,
        loading: true,
        isFetching: false,
      };

    case actions.GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionHistoryDetailsData: action.payload,
      };

    case actions.GET_SUBSCRIPTION_HISTORY_DETAIL_LIST_FAIL:
      return {
        ...state,
        loading: false,
        subscriptionHistoryDetailsData: [],
        error: {
          message: action.payload,
        },
      };
    case actions.GET_SUBSCRIPTION_PLAN_DATA:
      return {
        ...state,
        planDataLoading: true,
      };
    case actions.GET_SUBSCRIPTION_PLAN_DATA_SUCCESS:
      return {
        ...state,
        planDataLoading: false,
      };
    case actions.GET_SUBSCRIPTION_PLAN_DATA_FAIL:
      return {
        ...state,
        planDataLoading: false,
      };

    case actions.GET_SUBSCRIPTION_DETAILS_LIST:
      return {
        ...state,
        loading: true,
        isFetching: false,
      };

    case actions.GET_SUBSCRIPTION_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionDetailsData: action.payload,
        isFetching: true,
      };

    case actions.GET_SUBSCRIPTION_DETAILS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        subscriptionDetailsData: [],
        error: {
          message: action.payload,
        },
        isFetching: true,
      };


    case actions.GET_SUBSCRIPTION_MONTHLY_HISTORY:
      return {
        ...state,
        planDataLoading: true,
      };
    case actions.GET_SUBSCRIPTION_MONTHLY_HISTORY_SUCCESS:
      console.log('success');
      return {
        ...state,
        planDataLoading: false,
        monthlySubscriptionData: action.payload,
      };
    case actions.GET_SUBSCRIPTION_MONTHLY_HISTORY_FAIL:
      return {
        ...state,
        planDataLoading: false,
        monthlySubscriptionData: [],
      };

    default:
      return { ...state };
  }
};

export default SubscriptionTransactionReducer;