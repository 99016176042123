import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_COMPANY_OTHER_DETAIL } from "store/companyDetails/actionTypes";
import { getSessionData } from "utils/getWorkshopId";
import obj from "../../../../../utils/unserialize";

const defaultScheduledata = [
    {
        day: 1,
        status: "1",
        open_time: "07:00:00",
        close_time: "17:00:00",
    },
    {
        day: 2,
        status: "1",
        open_time: "07:00:00",
        close_time: "17:00:00",
    },
    {
        day: 3,
        status: "1",
        open_time: "07:00:00",
        close_time: "17:00:00",
    },
    {
        day: 4,
        status: "1",
        open_time: "07:00:00",
        close_time: "17:00:00",
    },
    {
        day: 5,
        status: "1",
        open_time: "07:00:00",
        close_time: "17:00:00",
    },
    {
        day: 6,
        status: "0"
    },
    {
        day: 0,
        status: "0"
    },
];

export function useDefaultForm() {
    const dispatch = useDispatch();
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    const { formLoading, workShopOtherDetails } = useSelector(
        (state) => state.CompanyDetailReducer
    );
    const sessionLabel = getSessionData("timezone_lable");
    const utcToZone = (time) =>
        moment.utc(time, "HH:mm:ss").tz(sessionLabel).format("HH:mm:ss");

    const zoneToUtc = (time) =>
        moment.tz(time, "HH:mm:ss", sessionLabel).utc().format("HH:mm:ss");

    const scheduleDataFromWorkshop = useMemo(() => {
        if (workShopOtherDetails?.schedule_details) {
            const schedules = obj.unserializeArr(
                workShopOtherDetails.schedule_details
            );
            const ss = schedules.map((schedule, i) => {
                let scheduleDetailsArr = {
                    day: schedule.day,
                    close_time: schedule?.close_time
                        ? utcToZone(schedule.close_time)
                        : "17:00:00",
                    status: schedule.status.toString(),
                    open_time: schedule?.open_time
                        ? utcToZone(schedule.open_time)
                        : "07:00:00",
                };
                if (schedule.status == "0") {
                    scheduleDetailsArr = {
                        day: schedule.day,
                        status: schedule.status.toString(),
                    }
                }
                return scheduleDetailsArr;
            });

            if (ss[0]?.day == 0) {
                ss.push(ss.shift());
            }
            return ss;
        } else {
            return defaultScheduledata;
        }
    }, [workShopOtherDetails]);

    const initialValues = useMemo(() => {
        return {
            otherId: workShopOtherDetails?.id ?? "",
            jsDateFormat: workShopOtherDetails?.js_date_format || "DD/MM/YYYY",
            mobileTimeFormat: workShopOtherDetails?.mobile_time_format || "hh:mm:a",
            dateFormat: workShopOtherDetails?.date_format || "DD/MM/YYYY",
            mobileDateFormat: workShopOtherDetails?.mobile_date_format || "DD/MM/YYYY",
            timeFormat: workShopOtherDetails?.time_format || "HH:mm",
            quoteNumber: workShopOtherDetails?.quote_number ?? "",
            poNumber: workShopOtherDetails?.po_number ?? "",
            jobNumber: workShopOtherDetails?.job_number ?? "",
            invoiceNumber: workShopOtherDetails?.invoice_number ?? "",
            technicianTimeSlot: workShopOtherDetails?.technician_timeslot ?? "",
            customerTimeSlot: workShopOtherDetails?.customer_timeslot ?? "",
            scheduleData: scheduleDataFromWorkshop,
        };
    }, [scheduleDataFromWorkshop, workShopOtherDetails]);

    const submitForm = (values) => {

        let editFormData = {
            id: values.otherId,
            js_date_format: values.jsDateFormat,
            mobile_time_format: values.mobileTimeFormat,
            date_format: values.dateFormat,
            time_format: values.timeFormat,
            quote_number: values.quoteNumber,
            po_number: values.poNumber,
            job_number: values.jobNumber,
            invoice_number: values.invoiceNumber,
            technician_timeslot: values.technicianTimeSlot,
            customer_timeslot: values.customerTimeSlot,
            schedule_details: values.scheduleData,
        };
           
        if(values.dateFormat === "DD/MM/YYYY" || values.mobileDateFormat === "DD/MM/YYYY"){
            editFormData.date_format = "d/m/Y";
            editFormData.mobile_date_format = "DD/MM/YYYY";
            editFormData.js_date_format = "d/m/yyyy";

        } else if (values.dateFormat === "MM/DD/YYYY" || values.mobileDateFormat === "MM/DD/YYYY") {

            editFormData.date_format = "m/d/Y";
            editFormData.mobile_date_format = "MM/DD/YYYY";
            editFormData.js_date_format = "m/d/yyyy";

        } else if (values.dateFormat === "YYYY/MM/DD" || values.mobileDateFormat === "YYYY/MM/DD") {

            editFormData.date_format = "Y/m/d";
            editFormData.mobile_date_format = "YYYY/MM/DD";
            editFormData.js_date_format = "yyyy/m/d";

        } else if (values.dateFormat === "DD/MM/YY" || values.mobileDateFormat === "DD/MM/YY") {

            editFormData.date_format = "d/m/y";
            editFormData.mobile_date_format = "DD/MM/YY";
            editFormData.js_date_format = "d/m/yy";

        } else if (values.dateFormat === "MM/DD/YY" || values.mobileDateFormat === "MM/DD/YY") {

            editFormData.date_format = "m/d/y";
            editFormData.mobile_date_format = "MM/DD/YY";
            editFormData.js_date_format = "m/d/yy";

        } else if (values.dateFormat === "YY/MM/DD" || values.mobileDateFormat === "YY/MM/DD") {

            editFormData.date_format = "y/m/d";
            editFormData.mobile_date_format = "YY/MM/DD";
            editFormData.js_date_format = "yy/m/d";

        } else if (values.dateFormat === "DD/MMM/YYYY" || values.mobileDateFormat === "DD/MMM/YYYY") {

            editFormData.date_format = "d/M/Y";
            editFormData.mobile_date_format = "DD/MMM/YYYY";
            editFormData.js_date_format = "d/M/yyyy";

        } else if (values.dateFormat === "MMM/DD/YYYY" || values.mobileDateFormat === "MMM/DD/YYYY") {

            editFormData.date_format = "M/d/Y";
            editFormData.mobile_date_format = "MMM/DD/YYYY";
            editFormData.js_date_format = "M/d/yyyy";

        } else if (values.dateFormat === "DD/MMM/YY" || values.mobileDateFormat === "DD/MMM/YY") {

            editFormData.date_format = "d/M/y";
            editFormData.mobile_date_format = "DD/MMM/YY";
            editFormData.js_date_format = "d/M/yy";

        } else if (values.dateFormat === "MMM/DD/YY" || values.mobileDateFormat === "MMM/DD/YY") {

            editFormData.date_format = "M/d/y";
            editFormData.mobile_date_format = "MMM/DD/YY";
            editFormData.js_date_format = "M/d/yy";
        }

        // update format of schedule data for serialize
        let data = values.scheduleData.map((detail, index) => {
            return {
                ...detail,
                status: detail.status,
                open_time: detail.status == 0 ? "" : zoneToUtc(detail.open_time),
                close_time: detail.status == 0 ? "" : zoneToUtc(detail.close_time),
            };
        });

        let newData = data.map((scheduleData, index) => {
            let scheduleDetailsArr = {
                day: scheduleData.day,
                close_time: scheduleData?.close_time,
                status: scheduleData.status,
                open_time: scheduleData?.open_time,
            };
            if (scheduleData.status == "0") {
                scheduleDetailsArr = {
                    day: scheduleData.day,
                    status: scheduleData.status.toString(),
                }
            }
            return scheduleDetailsArr;
        })
        let sortData = newData.sort((a, b) => a.day - b.day);

        //update session schedule details
        storedData.schedule_details = window.btoa(obj.serializeArr(sortData));
        storedData.technician_timeslot = window.btoa(values.technicianTimeSlot);
        storedData.date_format = values.mobileDateFormat;
        storedData.time_format = values.timeFormat;
        sessionStorage.setItem('stored_data', JSON.stringify(storedData));
        // serialize schedule details
        editFormData = {
            ...editFormData,
            schedule_details: obj.serializeArr(sortData),
            is_sunday: values.scheduleData[0].status,
            is_saturday: values.scheduleData[6].status,
        };

        dispatch({
            type: UPDATE_COMPANY_OTHER_DETAIL,
            payload: editFormData,
        });
    };

    const formik = useFormik({
        initialValues,
        onSubmit: submitForm,
    });

    useEffect(() => {
        formik.setValues((v) => {
            return {
                ...v,
                ...initialValues,
            };
        });
    }, [initialValues]);

    return { formik, formLoading };
}
