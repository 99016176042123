import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const RecommendationWarningModal = ({setShowWarning}) => {
  // TEST
  const handleClose = () => {setShowWarning(false)}

  return (
    <>
        <Modal
            className="info-dialog sms-warning-dialog-popup"
            size="md"
            show={true}
            onHide={handleClose}
        >
            <div className="heading-block d-flex justify-center bg-warning align-items-center" >
                <span className="font-size-30" style={{color:"red"}} > Warning </span>
                <i
                    className="la las la-exclamation-circle close-icon1"
                    aria-hidden="true"
                ></i>
            </div>
            <Modal.Body>
                <div className="text-center mb-2">
                    <p className="font-size-18 mt-3 mb-2">Untick task will remove from job</p>
                    <p className="font-size-18">You must also remove this task text from the job description</p>

                    
                </div>
                <div className="d-flex justify-center mt-4">
                    
                        <Button
                            variant="outline-secondary"
                            className="btn btn-primary rounded-btn font-size-13"
                            onClick={() => handleClose()}
                            style={{width:"170px"}}
                        >
                            OK
                        </Button>
                    
                </div>
            </Modal.Body>
        </Modal>
    </>
);
};

export default RecommendationWarningModal;
