export const GET_STATUSES_DETAILS = "GET_STATUSES_DETAILS";
export const GET_STATUSES_DETAILS_SUCCESS = "GET_STATUSES_DETAILS_SUCCESS";
export const GET_STATUSES_DETAILS_FAIL = "GET_STATUSES_DETAILS_FAIL";

export const ADD_STATUSES_DETAILS = "ADD_STATUSES_DETAILS";
export const ADD_STATUSES_DETAILS_SUCCESS = "ADD_STATUSES_DETAILS_SUCCESS";
export const ADD_STATUSES_DETAILS_FAIL = "ADD_STATUSES_DETAILS_FAIL";

export const CHECK_NAME_STATUSES_DETAILS = "CHECK_NAME_STATUSES_DETAILS";
export const CHECK_NAME_STATUSES_DETAILS_SUCCESS =
  "CHECK_NAME_STATUSES_DETAILS_SUCCESS";
export const CHECK_NAME_STATUSES_DETAILS_FAIL =
  "CHECK_NAME_STATUSES_DETAILS_FAIL";

export const EDIT_STATUSES_DETAILS = "EDIT_STATUSES_DETAILS";
export const EDIT_STATUSES_DETAILS_SUCCESS = "EDIT_STATUSES_DETAILS_SUCCESS";
export const EDIT_STATUSES_DETAILS_FAIL = "EDIT_STATUSES_DETAILS_FAIL";

export const UPDATE_STATUSES_DETAILS = "UPDATE_STATUSES_DETAILS";
export const UPDATE_STATUSES_DETAILS_SUCCESS =
  "UPDATE_STATUSES_DETAILS_SUCCESS";
export const UPDATE_STATUSES_DETAILS_FAIL = "UPDATE_STATUSES_DETAILS_FAIL";

export const DELETE_STATUSES_DETAILS = "DELETE_STATUSES_DETAILS";
export const DELETE_STATUSES_DETAILS_SUCCESS =
  "DELETE_STATUSES_DETAILS_SUCCESS";
export const DELETE_STATUSES_DETAILS_FAIL = "DELETE_STATUSES_DETAILS_FAIL";

export const DELETE_MANY_STATUSES_DETAILS = "DELETE_MANY_STATUSES_DETAILS";
export const DELETE_MANY_STATUSES_DETAILS_SUCCESS =
  "DELETE_MANY_STATUSES_DETAILS_SUCCESS";
export const DELETE_MANY_STATUSES_DETAILS_FAIL = "DELETE_MANY_STATUSES_DETAILS_FAIL";

export const GET_STATUSES_COUNT = "GET_STATUSES_COUNT";
export const GET_STATUSES_COUNT_SUCCESS =
  "GET_STATUSES_COUNT_SUCCESS";
export const GET_STATUSES_COUNT_FAIL = "GET_STATUSES_COUNT_FAIL";

export const GET_STATUS_TECHNICIAN_DETAILS = "GET_STATUS_TECHNICIAN_DETAILS";
export const GET_STATUS_TECHNICIAN_DETAILS_SUCCESS = "GET_STATUS_TECHNICIAN_DETAILS_SUCCESS";
export const GET_STATUS_TECHNICIAN_DETAILS_FAIL = "GET_STATUS_TECHNICIAN_DETAILS_FAIL";