export const GET_ASSIGNED_JOBS = "GET_ASSIGNED_JOBS";
export const GET_ASSIGNED_JOBS_SUCCESS = "GET_ASSIGNED_JOBS_SUCCESS";
export const GET_ASSIGNED_JOBS_FAIL = "GET_ASSIGNED_JOBS_FAIL";

export const GET_ASSIGNED_JOBS_COUNT = "GET_ASSIGNED_JOBS_COUNT";
export const GET_ASSIGNED_JOBS_COUNT_SUCCESS =
  "GET_ASSIGNED_JOBS_COUNT_SUCCESS";
export const GET_ASSIGNED_JOBS_COUNT_FAIL = "GET_ASSIGNED_JOBS_COUNT_FAIL";

export const GET_JOB_CARD = "GET_JOB_CARD";
export const GET_JOB_CARD_SUCCESS = "GET_JOB_CARD_SUCCESS";
export const GET_JOB_CARD_FAIL = "GET_JOB_CARD_FAIL";
