import { takeLatest, put, call } from "redux-saga/effects";
import { GET_EMPLOYEE_CLOCKING_HISTORY, 
          GET_EMPLOYEE_WISE_CLOCKING_HISTORY
  // ADD_EMPLOYEE_CLOCKING_HISTORY, UPDATE_EMPLOYEE_CLOCKING_HISTORY 
} from "./actionTypes";
import {
  getEmployeeClockingHistorySuccess,
  getEmployeeClockingHistoryFail,
  getEmployeeWiseClockingHistorySuccess,
  getEmployeeWiseClockingHistoryFail
  // addEmployeeClockingHistorySuccess,
  // addEmployeeClockingHistoryFail,
  // updateEmployeeClockingHistorySuccess,
  // updateEmployeeClockingHistoryFail
} from "./actions";

import { getEmployeeClockingHistory, getEmployeeWiseClockingHistory,
  //  addEmployeeClockingHistory, updateEmployeeClockingHistory
   } from "../../helpers/backendHelper";
import { showError } from "../../utils/alertMessage";

function* onGetEmployeeClockingHistory(data) {
  try {
    const response = yield call(getEmployeeClockingHistory, data);
    if(response){
      if(response.code === 200){
        yield put(getEmployeeClockingHistorySuccess(response?.data?.EmployeeClockingHistory));
      }else{
        yield put(getEmployeeClockingHistoryFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(getEmployeeClockingHistoryFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getEmployeeClockingHistoryFail(error.response));
  }
}

function* onGetEmployeeWiseClockingHistory(data) {
  try {
    const response = yield call(getEmployeeWiseClockingHistory, data);
    if(response){
      if(response.code === 200){
        yield put(getEmployeeWiseClockingHistorySuccess(response?.data?.EmployeeClockingHistory));
      }else{
        yield put(getEmployeeWiseClockingHistoryFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(getEmployeeWiseClockingHistoryFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getEmployeeWiseClockingHistoryFail(error.response));
  }
}

// function* onAddEmployeeClockingHistory(data) {
//   try {
//     const response = yield call(addEmployeeClockingHistory, data);
//     if(response){
//       if(response.code === 200){
//         yield put(addEmployeeClockingHistorySuccess(response?.data?.EmployeeClockingHistory));
//       }else{
//         yield put(addEmployeeClockingHistoryFail(response.message));
//         showError(response.message);
//       }
//     }else{
//       yield put(addEmployeeClockingHistoryFail("Didn't get api response"));  
//     }
//   } catch (error) {
//     yield put(addEmployeeClockingHistoryFail(error.response));
//   }
// }

// function* onUpdateEmployeeClockingHistory(data) {
//   try {
//     const response = yield call(updateEmployeeClockingHistory, data);
//     if(response){
//       if(response.code === 200){
//         yield put(updateEmployeeClockingHistorySuccess(response?.data?.EmployeeClockingHistory));
//       }else{
//         yield put(updateEmployeeClockingHistoryFail(response.message));
//         showError(response.message);
//       }
//     }else{
//       yield put(updateEmployeeClockingHistoryFail("Didn't get api response"));  
//     }
//   } catch (error) {
//     yield put(updateEmployeeClockingHistoryFail(error.response));
//   }
// }


function* EmployeeClockingHistorySaga() {
  yield takeLatest(GET_EMPLOYEE_CLOCKING_HISTORY, onGetEmployeeClockingHistory);
  yield takeLatest(GET_EMPLOYEE_WISE_CLOCKING_HISTORY, onGetEmployeeWiseClockingHistory);
  // yield takeLatest(ADD_EMPLOYEE_CLOCKING_HISTORY, onAddEmployeeClockingHistory);
  // yield takeLatest(UPDATE_EMPLOYEE_CLOCKING_HISTORY, onUpdateEmployeeClockingHistory);
}

export default EmployeeClockingHistorySaga;
