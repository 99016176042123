import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteArchiveCompletedInvoiceAPI,
  getCompletedInvoiceAPI,
} from "store/invoices/api";
import CommonSecond from "./CommonSecond";
import { useInvoiceColumns } from "./useInvoiceColumns";
import SendSMSToUser from "views/modals/SendSMSToUser";
import JobSmsBalance from "views/modals/JobSmsBalance";
import { getSubscriptionAddon } from "helpers/backendHelper";
import SendSmsToUserModal from "../../../../modals/SendSmsToUserModal";
import Loader from "views/common/UI/Loader";

const CompletedInvoices = () => {
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);

  const invoice = useSelector((state) => state.invoice);
  const [deletePayload, setDeletePayload] = useState(null);

  const handleSMSModalClose = () => setShowAddMobileModal(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvoiceB, setIsInvoiceB] = useState(false);
  const [currentPageLimit, setCurrentPageLimit] = useState(10);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentSize, setCurrentSize] = useState({ invoicePerPage: 0, saleInvoicePerPage: 0 });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { completedInvoiceColumns } = useInvoiceColumns({
    onView: ({ row }) => {
      if (row.type === "sale") {
        navigate(`/view_cash_sale_invoice/${row.id}`);
        return;
      }
      navigate(`/invoice-view/${row.id}`);
    },
    onMail: ({ row }) => {
      if (row.type === "sale") {
        navigate(`/email_cash_sale/${row.id}`);
        return;
      }
      navigate(`/invoice_email/${row.id}`);
    },
    onPrint: ({ row }) => {
      if (row.type === "sale") {
        return;
      }
      navigate(`/invoice_print/${row.id}`);
    },
    onSms: ({ row }) => {
      
      if (row.type === "sale") {
        setMobileNumber(row?.data?.user?.customers_details?.[0]?.mobile_number);
        setMobileCode(row?.data?.user?.customers_details?.[0]?.mobile_code);
        setCustomerId(row?.data?.user?.customers_details?.[0]?.user_id);
        setCustId(row?.data?.user?.customers_details?.[0]?.id)
        setVehicleRego(row?.data?.vehicle?.rego);
        setInvoiceType("payment_cashsale");
        setInvoiceId(row?.id)
        var name = "";
        if(row?.data?.user?.customers_details?.[0]?.type == 2){
          name = row?.data?.user?.customers_details?.[0]?.firstname;
        }else{
          name = row?.data?.user?.customers_details?.[0]?.company_name;
        }
        setCustomerName(name)
        setInvoiceNumber(row?.invoice_number)
      }else{        
        setMobileNumber(row?.data?.job_schedule?.customer?.customers_details?.[0]?.mobile_number);
        setMobileCode(row?.data?.job_schedule?.customer?.customers_details?.[0]?.mobile_code);
        setCustomerId(row?.data?.job_schedule?.customer?.customers_details?.[0]?.user_id);
        setCustId(row?.data?.job_schedule?.customer?.customers_details?.[0]?.id);
        setVehicleRego(row?.data?.job_schedule?.vehicle?.rego);
        setInvoiceType("payment_invoice");
        setInvoiceId(row?.id)
        setIsInvoiceB(false);

        let jobData = row?.data?.job_schedule;
        let customerData = row?.data?.job_schedule?.customer?.customers_details?.[0];
        let tmpCustomerName = "";
        tmpCustomerName = customerData?.type == 2
          ? customerData?.firstname + " " + customerData?.lastname
          : customerData?.company_name
        if((jobData?.show_allocation_column == "1" && row?.data?.invoice_allocation_type == "2") || (jobData?.show_allocation_column == "0" && row?.data?.insurance_preview_invoice == "1")){           
          setMobileNumber(row?.data?.insurance_company?.phone_number); 
          setCustomerId(row?.data?.insurance_company?.id);
          setIsInvoiceB(true); 
          tmpCustomerName = row?.data?.insurance_company?.company_name;
        }else if(jobData?.show_allocation_column == "1" && row?.data?.invoice_allocation_type == "3"){
          setMobileNumber(jobData?.invoice_c_customer?.mobile_number);
          setMobileCode(jobData?.invoice_c_customer?.mobile_code);
          setCustomerId(jobData?.invoice_c_customer?.user_id);
          setCustId(jobData?.invoice_c_customer?.id);
          tmpCustomerName = jobData?.invoice_c_customer?.type == 2
          ? jobData?.invoice_c_customer?.firstname + " " + jobData?.invoice_c_customer?.lastname
          : jobData?.invoice_c_customer?.company_name
        }
        setCustomerName(tmpCustomerName);
        setInvoiceNumber(row?.invoice_number)
      }
      
      getSMSBalanceData();
      // navigate(`/invoice_email/${row.id}`);
    },
    onDelete: ({ row }) => {
      setDeletePayload([row]);
    },
  });

  const deleteEvent = (cb) => {
    const saleIds = deletePayload
      .filter((c) => c.type === "sale")
      .map((c) => c.id);
    const invoiceIds = deletePayload
      .filter((c) => c.type !== "sale")
      .map((c) => c.id);

    const saleInvoice = {},
      invoice = {};
    saleInvoice.action_type = "delete";
    invoice.action_type = "delete";
    saleInvoice.ids = saleIds;
    invoice.ids = invoiceIds;
    deleteArchiveCompletedInvoiceAPI(dispatch)({ saleInvoice, invoice });
    cb();
  };

  const [showAddMobileModal, setShowAddMobileModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const [mobileCode, setMobileCode] = useState();
  const [customerId, setCustomerId] = useState();
  const [cust_id, setCustId] = useState();
  const [vehicleRego, setVehicleRego] = useState();
  const [invoiceType, setInvoiceType] = useState();
  const [invoiceId, setInvoiceId] = useState();    
  const [customerName, setCustomerName] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState();
  
  const [updateMobileNumber,setUpdateMobileNumber] = useState(false);
  // const [isSmsSubscribe, setIsSmsSubscribe] = useState(0);
  const [showSmsBalanceModal, setShowSmsBalanceModal] = useState(false);
  const handleShowSmsBalanceModal = () => setShowSmsBalanceModal(true);
  const handleCloseSmsBalanceModal = () => setShowSmsBalanceModal(false);

  async function getSMSBalanceData(){
    let data = {
      payload: {
        get: "all",
        conditions: {
          "WorkshopSubscriptionDetails.plan_type": "sms",
          "WorkshopSubscriptionDetails.current_sms_limit >": 0,
          "WorkshopSubscriptionDetails.workshop_id": workshop_id,
        },
        fields: [
          "id",
          "workshop_subscription_id",
          "subscription_plan_id",
          "sms_limit",
          "current_sms_limit",
        ],
        order: { "WorkshopSubscriptionDetails.id": "DESC" },
      },
    };
    let response = await getSubscriptionAddon(data);

    if (response.code === 200) {
      setShowAddMobileModal(true);
    } else {
      handleShowSmsBalanceModal();
    }
  }


  return (
    <>
      {isLoading === true && (<Loader />)}
      {/* helloawdsadasd */}
      <CommonSecond
        deletePayload={deletePayload}
        setDeletePayload={setDeletePayload}
        completed={true}
        api={getCompletedInvoiceAPI}
        columns={completedInvoiceColumns}
        // countApi={getCompletedInvoicesCountAPI}
        main={invoice?.completedInvoices}
        onCreateCashSale={() => {}}
        onRowClick={(row) => {
          if (row.type === "sale") {
            navigate(`/view_cash_sale_invoice/${row.id}`);
            return;
          }
          navigate(`/invoice-view/${row.id}`);
        }}
        onSearch={() => {}}
        deleteInvoices={deleteEvent}
        invoiceListType= {"2"}
        setCurrentPageLimit={setCurrentPageLimit}
        setCurrentPageNumber={setCurrentPageNumber}
        setCurrentSize={setCurrentSize}
      />
      {/* {showAddMobileModal && (
        <SendSMSToUser
            cust_id={cust_id}
            workshop_id = {workshop_id}
            type = {invoiceType}
            id = {invoiceId}
            customer_id = {customerId}
            mobileCode = {mobileCode}
            rego = {vehicleRego}
            setShowAddMobileModal = {setShowAddMobileModal}
            updateMobileNumber={updateMobileNumber}
            setUpdateMobileNumber={setUpdateMobileNumber}
            mobileNumberForCustomer={mobileNumber}
            setMobileNumberForCustomer={setMobileNumber}
            customerName={customerName}
            number={invoiceNumber}
        />)
      } */}

      {showAddMobileModal && (          
        <SendSmsToUserModal
          rego={vehicleRego}
          workshop_id={workshop_id}
          type = {invoiceType}
          id = {invoiceId}
          cust_id={cust_id}
          customer_id={customerId}
          mobileCode={mobileCode}
          SMSModalShow={showAddMobileModal}
          handleSMSModalClose={handleSMSModalClose}
          customerName={customerName}
          number={invoiceNumber}           
          setIsLoading={setIsLoading}
          mobileNumberForCustomer={mobileNumber}
          setMobileNumberForCustomer={setMobileNumber}
          isInvoiceB={isInvoiceB} 
          pageType={"completed_invoice"}
          currentPageLimit={currentPageLimit}
          currentPageNumber={currentPageNumber}
          currentSize={currentSize}
        />)          
      }

      {showSmsBalanceModal && (
          <JobSmsBalance
              showSmsBalanceModal={showSmsBalanceModal}
              handleShowSmsBalanceModal={handleShowSmsBalanceModal}
              handleCloseSmsBalanceModal={handleCloseSmsBalanceModal}
          />
      )}
    </>
  );
};

export default CompletedInvoices;
