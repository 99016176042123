import {
  INVENTORY_STOCK_LIST,
  INVENTORY_STOCK_LIST_FAIL,
  INVENTORY_STOCK_LIST_SUCCESS,
  INVENTORY_STOCK_TAKE_HISTORY_LIST_SUCCESS,
  INVENTORY_STOCK_TAKE_LIST,
  INVENTORY_STOCK_TAKE_LIST_FAIL,
  INVENTORY_STOCK_TAKE_LIST_SUCCESS,
  SAVE_STOCK,
  SAVE_STOCK_FAIL,
  SAVE_STOCK_SUCCESS,
  UPDATE_PART_DATA,
  UPDATE_PART_DATA_FAIL,
  UPDATE_PART_DATA_SUCCESS,
  EDIT_STOCK_TAKE_DETAILS,
  EDIT_STOCK_TAKE_DETAILS_FAIL,
  EDIT_STOCK_TAKE_DETAILS_SUCCESS,
  SAVE_STOCK_TAKE,
  SAVE_STOCK_TAKE_SUCCESS,
  SAVE_STOCK_TAKE_FAIL,
  UPDATE_PARTS_QTY,
  UPDATE_PARTS_QTY_SUCCESS,
  UPDATE_PARTS_QTY_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,

  inventoryStockList: [],
  saveStockData: [],
  isStockSave: false,
  updatePartData: [],

  inventoryStockTakeList: [],
  inventoryStockTakeHistoryList: [],
  editStockDetails: [],
  saveStockTakeData: [],
  updatedPartsQtyData:[],

  error: {
    message: "",
  },
};

const StockReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_STOCK_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case INVENTORY_STOCK_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        inventoryStockList: action.payload,
      };
      break;

    case INVENTORY_STOCK_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        inventoryStockList: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case SAVE_STOCK:
      state = {
        ...state,
        isStockSave: false,
        loading: true,
      };
      break;

    case SAVE_STOCK_SUCCESS:
      state = {
        ...state,
        loading: false,
        isStockSave: true,
        saveStockData: action.payload,
      };
      break;

    case SAVE_STOCK_FAIL:
      state = {
        ...state,
        loading: false,
        isStockSave: true,
        saveStockData: action.payload,
        error: {
          message: action.payload,
        },
      };
      break;

    case UPDATE_PART_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_PART_DATA_SUCCESS:
      state = {
        ...state,

        updatePartData: action.payload,
        loading: false,
      };
      break;

    case UPDATE_PART_DATA_FAIL:
      state = {
        ...state,

        updatePartData: action.payload,
        loading: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case INVENTORY_STOCK_TAKE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case INVENTORY_STOCK_TAKE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        inventoryStockTakeList: action.payload,
      };
      break;

    case INVENTORY_STOCK_TAKE_HISTORY_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        inventoryStockTakeHistoryList: action.payload,
      };
      break;

    case INVENTORY_STOCK_TAKE_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        inventoryStockTakeList: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case EDIT_STOCK_TAKE_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case EDIT_STOCK_TAKE_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        editStockDetails: action.payload,
      };
      break;

    case EDIT_STOCK_TAKE_DETAILS_FAIL:
      state = {
        ...state,
        loading: false,
        editStockDetails: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case SAVE_STOCK_TAKE:
      state = {
        ...state,
        isStockSave: false,
        loading: true,
      };
      break;

    case SAVE_STOCK_TAKE_SUCCESS:
      state = {
        ...state,
        loading: false,
        isStockSave: true,
        saveStockTakeData: action.payload,
      };
      break;

    case SAVE_STOCK_TAKE_FAIL:
      state = {
        ...state,
        loading: false,
        isStockSave: true,
        saveStockTakeData: action.payload,
        error: {
          message: action.payload,
        },
      };
      break;

      case UPDATE_PARTS_QTY:
      state = {
        ...state,
        isStockSave: false,
        loading: true,
      };
      break;

    case UPDATE_PARTS_QTY_SUCCESS:
      state = {
        ...state,
        loading: false,
        isStockSave: true,
        updatedPartsQtyData: action.payload,
      };
      break;

    case UPDATE_PARTS_QTY_FAIL:
      state = {
        ...state,
        loading: false,
        isStockSave: true,
        updatedPartsQtyData: action.payload,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default StockReducer;
