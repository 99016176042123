import { call, put, takeLatest } from "redux-saga/effects";
import * as Actions from "./actionType";
import {
  getEodInvoiceData,
  getInvoicePaymentData,
  getInvoiceValueData,
} from "./api/getInvoiceData";

import {
  getJobReportData,
  getJobReportForecastData,
  getJobReportStatsData
} from "./api/getJobData";


import {
  getQuoteReportData,
  getQuoteReportForecastData,
  getQuoteReportStatsData
} from "./api/getQuoteData";

import {
  getCustomerAverageRevenue, getCustomerBasedRevenue, getJobAverageRevenue
} from "./api/getCustomerData";

import { getLabourHours, getEffectiveLabourRate } from "./api/getLabourData";

import {
  getMaterialPurchaseOrder,
  getMaterialStockTake,
  getMaterialStockValue
} from "./api/getMaterialData";
import { getcompletedJobs, getInvoicesList, getSupplierStatement,getSupplierListStatement,sendStatementReport } from "helpers/backendHelper";

function* onGetEODInvoiceData({ payload }) {
  try {
    const data = yield call(getEodInvoiceData, payload);
    yield put({
      type: Actions.GET_INVOICE_EOD_CHART_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: Actions.GET_INVOICE_EOD_CHART_FAIL,
    });
  }
}

function* onGetInvoiceValueData({ payload }) {
  try {
    const data = yield call(getInvoiceValueData, payload);
    yield put({
      type: Actions.GET_INVOICE_VALUE_CHART_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.GET_INVOICE_VALUE_CHART_FAIL,
    });
  }
}

function* onGetInvoicePaymentChartData({ payload }) {
  try {
    const data = yield call(getInvoicePaymentData, payload);
    yield put({
      type: Actions.GET_INVOICE_PAYMENT_CHART_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.GET_INVOICE_PAYMENT_CHART_FAIL,
    });
  }
}

function* onGetJobReportTable({ payload }) {
  try {
    const data = yield call(getJobReportData, payload);
    yield put({
      type: Actions.JOB_REPORT_TABLE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.JOB_REPORT_TABLE_FAIL,
    });
  }
}

function* onGetJobReportForecastTable({ payload }) {
  try {
    const data = yield call(getJobReportForecastData, payload);
    yield put({
      type: Actions.JOB_REPORT_FORECAST_TABLE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.JOB_REPORT_FORECAST_TABLE_FAIL,
    });
  }
}


function* onGetJobStatsChart({ payload }) {
  try {
    const data = yield call(getJobReportStatsData, payload);
    yield put({
      type: Actions.JOB_REPORT_STATS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.JOB_REPORT_STATS_FAIL,
    });
  }
}

function* onGetReportStatement(payload) {
  try {
    const data = yield call(getInvoicesList, payload);
    yield put({
      type: Actions.GET_INVOICE_STATEMENTS_SUCCESS,
      payload: data.data.Invoices,
    });
  } catch (err) {
    yield put({
      type: Actions.GET_INVOICE_STATEMENTS_FAIL,
    });
  }
}

function* onGetSupplierStatement(payload) {
  try {
    const data = yield call(getSupplierStatement, payload);
    yield put({
      type: payload.type === Actions.GET_SUPPLIER_STATEMENT ? Actions.GET_SUPPLIER_STATEMENT_SUCCESS : Actions.GET_SUPPLIER_STATEMENT_COUNT_SUCCESS,
      payload: data?.data?.SupplierStatement || [],
    });
    
  } catch (err) {
    yield put({
      type: payload.type === Actions.GET_SUPPLIER_STATEMENT ? Actions.GET_SUPPLIER_STATEMENT_FAILURE : Actions.GET_SUPPLIER_STATEMENT_COUNT_FAILURE,
    });
  }
}

function* onGetSupplierListStatement(payload) {
  try {
    const data = yield call(getSupplierListStatement, payload);
    yield put({
      type: payload.type === Actions.GET_SUPPLIER_LIST_STATEMENT ? Actions.GET_SUPPLIER_LIST_STATEMENT_SUCCESS : Actions.GET_SUPPLIER_LIST_STATEMENT_COUNT_SUCCESS,
      payload: data?.data?.SupplierStatementDetail || [],
    });
    
  } catch (err) {
    yield put({
      type: payload.type === Actions.GET_SUPPLIER_LIST_STATEMENT ? Actions.GET_SUPPLIER_LIST_STATEMENT_FAILURE : Actions.GET_SUPPLIER_LIST_STATEMENT_COUNT_FAILURE,
    });
  }
}

function* onSendStatementReport(payload) {
  try {
    const data = yield call(sendStatementReport, payload);
    yield put({
      type: Actions.SEND_STATEMENT_REPORT_SUCCESS,
      payload: data.message,
    });
  } catch (err) {
    yield put({
      type: Actions.SEND_STATEMENT_REPORT_FAIL,
    });
  }
}

function* onGetReportStatementCount(payload) {
  try {
    const data = yield call(getInvoicesList, payload);
    yield put({
      type: Actions.GET_INVOICE_STATEMENTS_COUNT_SUCCESS,
      payload: Array.isArray(data.data.Invoices) && data.data.Invoices.length > 0 ? (data.data.Invoices[0].COUNT ?? 0) : 0,
    });
  } catch (err) {
    yield put({
      type: Actions.GET_INVOICE_STATEMENTS_COUNT_FAIL,
    });
  }
}


function* onGetQuoteReport({ payload }) {
  try {
    const data = yield call(getQuoteReportData, payload);
    yield put({
      type: Actions.GET_QUOTE_REPORT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.GET_QUOTE_REPORT_FAIL,
    });
  }
}

function* onGetQuoteReportForecastTable({ payload }) {
  try {
    const data = yield call(getQuoteReportForecastData, payload);
    yield put({
      type: Actions.QUOTE_REPORT_FORECAST_TABLE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.QUOTE_REPORT_FORECAST_TABLE_FAIL,
    });
  }
}

function* onGetQuoteReportStats({ payload }) {
  try {
    const data = yield call(getQuoteReportStatsData, payload);
    yield put({
      type: Actions.QUOTE_REPORT_STATS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.QUOTE_REPORT_STATS_FAIL,
    });
  }
}



function* onGetAverageRevenue({ payload }) {
  try {
    const data = yield call(getCustomerAverageRevenue, payload);
    yield put({
      type: Actions.AVERAGE_REVENUE_CUSTOMER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.AVERAGE_REVENUE_CUSTOMER_FAIL,
    });
  }
}

function* onGetAverageRevenueByJob({ payload }) {
  try {
    const data = yield call(getJobAverageRevenue, payload);
    yield put({
      type: Actions.AVERAGE_REVENUE_JOBTYPE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.AVERAGE_REVENUE_JOBTYPE_FAIL,
    });
  }
}


function* onGetCustomerBasedRevenue({ payload }) {
  try {
    const data = yield call(getCustomerBasedRevenue, payload);
    yield put({
      type: Actions.CUSTOMER_BASED_REVENUE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.CUSTOMER_BASED_REVENUE_FAIL,
    });
  }
}

function* onGetLabourHours({ payload }) {
  try {
    const data = yield call(getLabourHours, payload);
    yield put({
      type: Actions.LABOUR_HOURS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.LABOUR_HOURS_FAIL,
    });
  }
}

function* onGetEffectiveLabourRate({ payload }) {
  try {
    const data = yield call(getEffectiveLabourRate, payload);
    yield put({
      type: Actions.EFFECTIVE_LABOUR_RATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.EFFECTIVE_LABOUR_RATE_FAIL,
    });
  }
}

function* onGetMaterialPurchaseOrder({ payload }) {
  try {
    const data = yield call(getMaterialPurchaseOrder, payload);
    yield put({
      type: Actions.MATERIAL_PURCHASE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.MATERIAL_PURCHASE_ORDER_FAIL,
    });
  }
}

function* onGetMaterialStockValue({ payload }) {
  try {
    const data = yield call(getMaterialStockValue, payload);
    yield put({
      type: Actions.MATERIAL_STOCK_VALUE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.MATERIAL_STOCK_VALUE_FAIL,
    });
  }
}

function* onGetMaterialStockTake({ payload }) {
  try {
    const data = yield call(getMaterialStockTake, payload);
    yield put({
      type: Actions.MATERIAL_STOCK_TAKE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: Actions.MATERIAL_STOCK_TAKE_FAIL,
    });
  }
}






export default function* reportsSaga() {
  yield takeLatest(Actions.GET_INVOICE_EOD_CHART, onGetEODInvoiceData);
  yield takeLatest(Actions.GET_INVOICE_VALUE_CHART, onGetInvoiceValueData);
  yield takeLatest(Actions.GET_INVOICE_PAYMENT_CHART, onGetInvoicePaymentChartData);
  yield takeLatest(Actions.JOB_REPORT_TABLE, onGetJobReportTable);
  yield takeLatest(Actions.JOB_REPORT_FORECAST_TABLE, onGetJobReportForecastTable);
  yield takeLatest(Actions.JOB_REPORT_STATS, onGetJobStatsChart);
  // Statement
  yield takeLatest(Actions.GET_INVOICE_STATEMENTS, onGetReportStatement);
  yield takeLatest(Actions.GET_INVOICE_STATEMENTS_COUNT, onGetReportStatementCount);
  yield takeLatest(Actions.SEND_STATEMENT_REPORT, onSendStatementReport);
  yield takeLatest(Actions.GET_SUPPLIER_STATEMENT, onGetSupplierStatement);
  yield takeLatest(Actions.GET_SUPPLIER_STATEMENT_COUNT, onGetSupplierStatement);

  yield takeLatest(Actions.GET_SUPPLIER_LIST_STATEMENT, onGetSupplierListStatement);
  yield takeLatest(Actions.GET_SUPPLIER_LIST_STATEMENT_COUNT, onGetSupplierListStatement);

  yield takeLatest(Actions.GET_QUOTE_REPORT, onGetQuoteReport);
  yield takeLatest(Actions.QUOTE_REPORT_FORECAST_TABLE, onGetQuoteReportForecastTable);
  yield takeLatest(Actions.QUOTE_REPORT_STATS, onGetQuoteReportStats);

  yield takeLatest(Actions.AVERAGE_REVENUE_CUSTOMER, onGetAverageRevenue);
  yield takeLatest(Actions.AVERAGE_REVENUE_JOBTYPE, onGetAverageRevenueByJob);
  yield takeLatest(Actions.CUSTOMER_BASED_REVENUE, onGetCustomerBasedRevenue);


  yield takeLatest(Actions.LABOUR_HOURS, onGetLabourHours);
  yield takeLatest(Actions.EFFECTIVE_LABOUR_RATE, onGetEffectiveLabourRate);


  yield takeLatest(Actions.MATERIAL_PURCHASE_ORDER, onGetMaterialPurchaseOrder);
  yield takeLatest(Actions.MATERIAL_STOCK_VALUE, onGetMaterialStockValue);
  yield takeLatest(Actions.MATERIAL_STOCK_TAKE, onGetMaterialStockTake);

}
