import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const InvoiceCreate = Loadable({
  loader: () => import("./components/InvoiceCreate/InvoiceCreate"),
  loading: Loading,
});


const InvoicePreview = () => <InvoiceCreate preview />


const InvoiceList = Loadable({
  loader: () => import("./components/InvoiceList/InvoiceList"),
  loading: Loading,
});

const InvoiceEdit = Loadable({
  loader: () => import("./components/InvoiceEdit/InvoiceEdit"),
  loading: Loading,
});

const SplitInvoice = Loadable({
  loader: () => import("./components/SplitInvoice/SplitInvoice"),
  loading: Loading,
});

const StatementInvoiceEdit = Loadable({
  loader: () => import("./components/StatementInvoiceEdit/StatementInvoiceEdit"),
  loading: Loading,
});

const StatementEmail = Loadable({
  loader: () => import("./components/StatementInvoiceEdit/StatementEmail"),
  loading: Loading,
});

const StatementPrint = Loadable({
  loader: () => import("./components/StatementInvoiceEdit/StatementInvoicePrint"),
  loading: Loading,
});

const InvoiceEmail = Loadable({
  loader: () => import("./components/InvoiceEmail/InvoiceEmail"),
  loading: Loading,
});

const InvoicePrint = Loadable({
  loader: () => import("./components/InvoicePrint/InvoicePrint"),
  loading: Loading,
});

const InvoiceView = Loadable({
  loader: () => import("./components/InvoiceView/InvoiceView"),
  loading: Loading,
});
const PaymentCredits = Loadable({
  loader: () => import("./components/PaymentCredits/PaymentCredits"),
  loading: Loading,
});
const ViewPaymentCredit = Loadable({
  loader: () => import("./components/PaymentCredits/ViewPaymentCredit"),
  loading: Loading,
});
const InvoiceReceipt = Loadable({
  loader: () => import("./components/PaymentCredits/InvoiceReceipt"),
  loading: Loading,
});
const ReceiptEmail = Loadable({
  loader: () => import("./components/PaymentCredits/ReceiptEmail"),
  loading: Loading,
});

const CreditEmail = Loadable({
  loader: () => import("./components/PaymentCredits/CreditEmail"),
  loading: Loading,
});
const CashSale = Loadable({
  loader: () => import("./components/CashSale/index"),
  loading: Loading,
});

const EditCashSale = Loadable({
  loader: () => import("./components/CashSale/EditCashSale"),
  loading: Loading,
}); 
 
const CashSaleInvoiceEmail = Loadable({
  loader: () => import("./components/CashSale/MailCashSale"),
  loading: Loading,
}); 

const CashSaleInvoicePrint = Loadable({
  loader: () => import("./components/CashSale/PrintCashSale"),
  loading: Loading,
}); 

const ViewCashSale = Loadable({
  loader: () => import("./components/CashSale/ViewCashSale"),
  loading: Loading,
}); 

const ViewCashSaleReceipt = Loadable({
  loader: () => import("./components/CashSale/ViewCashSaleReceipt"),
  loading: Loading,
}); 

const CashSalePaymentCredit = Loadable({
  loader: () => import("./components/CashSale/CashSalePaymentCredit"),
  loading: Loading,
}); 

const CashSaleReceiptEmail = Loadable({
  loader: () => import("./components/CashSale/CashSaleReceiptEmail"),
  loading: Loading,
}); 

const ViewCashSaleCredit = Loadable({
  loader: () => import("./components/CashSale/ViewCashSaleCredit"),
  loading: Loading,
});

const CashSaleCreditEmail = Loadable({
  loader: () => import("./components/CashSale/CashSaleCreditEmail"),
  loading: Loading,
}); 


export const routes = [
  {
    path: "/invoice",
    exact: true,
    name: "Invoice",
    element: InvoiceList,
  },
  {
    path: "/invoice-create/:id",
    exact: true,
    name: "Invoice Create",
    element: InvoiceCreate,
  },
  {
    path: "/edit-invoice/:id",
    exact: true,
    name: "Edit Invoice",
    element: InvoiceEdit,
  },
  {
    path: "/split-invoice/:id",
    exact: true,
    name: "Split Invoice",
    element: SplitInvoice,
  },
  {
    path: "/edit-statement-invoice/:id",
    exact: true,
    name: "Edit Statement Invoice",
    element: StatementInvoiceEdit,
  },
  {
    path: "/statement_email/:id",
    exact: true,
    name: "Mail Statement",
    element: StatementEmail,
  },
  {
    path: "/statement_print/:id",
    exact: true,
    name: "Print Statement",
    element: StatementPrint,
  },
  {
    path: "/invoice_email/:id",
    exact: true,
    name: "Mail Invoice",
    element: InvoiceEmail,
  },
  {
    path: "/invoice_print/:id",
    exact: true,
    name: "Print Invoice",
    element: InvoicePrint,
  },
  {
    path: "/invoice-view/:id",
    exact: true,
    name: "View Invoice",
    element: InvoiceView,
  },
  {
    path: "/payment_credit/:id",
    exact: true,
    name: "Credit of Invoice",
    element: PaymentCredits,
  },
  {
    path: "/view-invoice-credit/:id",
    exact: true,
    name: "Credit of Invoice",
    element: ViewPaymentCredit,
  },
  {
    path: "/view-invoice-receipt/:id",
    exact: true,
    name: "Receipt of Invoice",
    element: InvoiceReceipt,
  },
  {
    path: "/invoice-receipt-email/:id",
    exact: true,
    name: "Receipt of Invoice",
    element: ReceiptEmail,
  },
  {
    path: "/invoice-credit-email/:id",
    exact: true,
    name: "Receipt of Invoice",
    element: CreditEmail,
  },
  {
    path: "/invoice-preview/:id",
    exact: true,
    name: "Preview of Invoice",
    element: InvoicePreview,
  },
  {
    path: "/cash_sale",
    exact: true,
    name: "Create Cash Sale Invoice",
    element: CashSale,
  },
  {
    path: "/cash_sale",
    exact: true,
    name: "Create Cash Sale Invoice",
    element: CashSale,
  },
  {
    path: "/edit_cash_sale_invoice/:id",
    exact: true,
    name: "Edit Cash Sale Invoice",
    element: EditCashSale,
  },
  {
    path: "/email_cash_sale/:id",
    exact: true,
    name: "Mail Cash Sale Invoice",
    element: CashSaleInvoiceEmail,
  },
  {
    path: "/print_cash_sale/:id",
    exact: true,
    name: "Print Invoice",
    element: CashSaleInvoicePrint,
  },
  {
    path: "/view_cash_sale_invoice/:id",
    name: "View Cash Sale Invoice",
    exact: true,
    element: ViewCashSale,
  },
  {
    path: "/view-cashsale-receipt/:id",
    exact: true,
    name: "View Cash Sale Invoice",
    element: ViewCashSaleReceipt,
  },
  {
    path: "/cash_sale_payment_credit/:id",
    exact: true,
    name: "Cash Sale Payment Invoice",
    element: CashSalePaymentCredit,
  },
  {
    path: "/cashsale-receipt-email/:id",
    exact: true,
    name: "Cash Sale Receipt Email",
    element: CashSaleReceiptEmail,
  },
  {
    path: "/view-cashsale-credit/:id",
    exact: true,
    name: "View Cash Sale Credit",
    element: ViewCashSaleCredit,
  },
  {
    path: "/cashsale-credit-email/:id",
    exact: true,
    name: "Cash Sale Email",
    element: CashSaleCreditEmail,
  },
];
