import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { utcToTimezone } from "utils/timeZoneConvert";
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export function useJobTimer(
  { total_time, start_time, running, activeTechnician } = {
    total_time: "0:0:0",
    running: false,
    start_time: null,
  }
) {
  const totalTimeToSeconds = moment.duration(total_time).asSeconds();

  const [elaspedSeconds, setElaspedSeconds] = useState(totalTimeToSeconds);
  const timerId = useRef(null);

  const changeTime = useCallback(() => {

    if (running && start_time) {
      const dateTime =
        moment.utc().unix() -
        moment.utc(start_time, "YYYY-MM-DD HH:mm:ss").unix();
      setElaspedSeconds(dateTime + totalTimeToSeconds);
    } else {
      setElaspedSeconds(totalTimeToSeconds);
    }
  }, [running, start_time, totalTimeToSeconds]);

  useEffect(() => {
    changeTime();
    timerId.current = setInterval(() => {
      changeTime();
    }, 1000);

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, [changeTime]);

  const time = elaspedSeconds.toString().toHHMMSS();

  let tag = "sec";
  if (elaspedSeconds > 3600) {
    tag = "Hrs";
  } else if (elaspedSeconds > 60) {
    tag = "min";
  }

  return { time, tag };
}
