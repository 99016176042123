import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Reports = () => {
  document.title = "Reports | 5iQ";

  return (
    <div className="right-section-wrapper">
      <div className="table-header mb-2">
        <div className="top-block">
          <div className="col-sm-12 col-md-2">
            <div className="table-title">
              <i className="fa fa-bar-chart text-primary me-2 font-size-24"></i>
              <span>REPORTS</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-2">
            <div className="video-link-outer">
              <div className="video-tutorial">
                <i className="fa fa-icon"></i>
                {/* <span className="help-text">Help</span> */}
                <Link to="/trainee-video#reports" ><span className="tutorial-title">Reports Videos</span></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="settings-navigation-list">
        <div className="row">
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">STATEMENTS</h5>
              <div className="card-body">
                <Link
                  to="/reports/statements#customer-statements"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Customer Statements
                </Link>
                <Link
                  to="/reports/statements#capricorn-statements"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Capricorn Statements
                </Link>
                <Link
                  to="/reports/statements#supplier-statements"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Supplier Statements
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">INVOICES</h5>
              <div className="card-body">
                <Link
                  to="/reports/report-invoice"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  End of Day
                </Link>
                <Link
                  to="/reports/report-invoice"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Invoice Value
                </Link>
                <Link
                  to="/reports/report-invoice"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Invoice Payment
                </Link>
                {/* <Link
                  to="/reports/report-invoice"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Statements
                </Link> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">JOBS</h5>
              <div className="card-body">
                <Link
                  to="/reports/job-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Jobs
                </Link>

              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">QUOTES</h5>
              <div className="card-body">
                <Link
                  to="/reports/quote-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Quotes
                </Link>

              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">CUSTOMER</h5>
              <div className="card-body">
                <Link
                  to="/reports/customer-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Average Revenue Per Customer
                </Link>
                <Link
                  to="/reports/customer-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Average Revenue by Job Type
                </Link>
                <Link
                  to="/reports/customer-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Top Customer based on Revenue
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">LABOUR</h5>
              <div className="card-body">
                <Link
                  to="/reports/labour-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Labour Hours
                </Link>
                <Link
                  to="/reports/labour-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Effective Labour Rate
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">CLOCKING</h5>
              <div className="card-body">
                <Link
                  to="/reports/clocking-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Daily Clocking
                </Link>
                <Link
                  to="/reports/clocking-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Job Clocking
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">MATERIALS</h5>
              <div className="card-body">
                <Link
                  to="/po-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Purchase Order
                </Link>
                <Link
                  to="/po-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Stock Value
                </Link>
                <Link
                  to="/po-report"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Stock Take
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">MARKETING</h5>
              <div className="card-body">
                <Link
                  to="/reports"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Coming Soon...
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">NOTIFICATIONS</h5>
              <div className="card-body">
                <Link
                  to="/reports"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Coming Soon...
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">SCHEDULE REPORTS</h5>
              <div className="card-body">
                <Link
                  to="/reports"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Coming Soon...
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">FINANCIALS</h5>
              <div className="card-body">
                <Link
                  to="/reports"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Coming Soon...
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;