import { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getHourMinSecondByTwoDates } from "utils/timeZoneConvert";
import { useJobTimer } from "./useTimer";
import { useNavigate } from "react-router-dom";
import moment from "moment";

let TimeClocking = ({
    onAddClockToList, onUpdateClockToList, fromDate, lastActivity,
    activeUserName, activeTechnician
    // timerStartTime,timerTotalTime
}) => {

    const navigate = useNavigate();

    const [isBreakTime, setIsBreakTime] = useState(false);
    const [isLunchTime, setIsLunchTime] = useState(false);
    const [timerStartTime, setTimerStartTime] = useState("0000-00-00 00:00:00");
    const [timerTotalTime, setTimerTotalTime] = useState("00:00:00");
    const [timerStatus, setTimerStatus] = useState("stop");
    const [totalLog, setTotalLog] = useState(0);

    const { technicianDetails } = useSelector((state) => state.AccountSyncReducer);

    useEffect(() => {

        if (technicianDetails !== null && technicianDetails !== undefined) {

            const userTimeLog = technicianDetails.filter((c) => c.id === activeTechnician);

            setTotalLog(userTimeLog[0]?.employee_clocking_history?.length || 0);

            if (userTimeLog[0]?.employee_clocking_history.length > 0) {

                let day_start_time = userTimeLog[0]?.employee_clocking_history[0]?.start_time;
                let day_end_time = userTimeLog[0]?.employee_clocking_history[userTimeLog[0]?.employee_clocking_history?.length - 1]?.start_time;
                setTimerStartTime(day_start_time);

                if (lastActivity === "Finish Time" && userTimeLog[0]?.employee_clocking_history?.length > 0) {
                    let diff_hours = getHourMinSecondByTwoDates(day_start_time, day_end_time);
                    setTimerTotalTime(diff_hours);
                }

            } else {
                setTimerTotalTime("00:00:00");
                setTimerStartTime("0000-00-00 00:00:00");
            }

        }

        return () => {
            setTimerTotalTime("00:00:00");
            setTimerStartTime("0000-00-00 00:00:00");
        };
    }, [technicianDetails, activeTechnician])



    function stopClockTimer() {
        Swal.fire({
            title: "Ready to Clock Out?",
            text: "",
            icon: "error",
            position: "top",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonColor: "#65cc88",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancel",
            confirmButtonText: "Yes",
            customClass: {
                container: 'clock-out-dialog',
            },
        }).then((result) => {
            if (result !== "" && result.isConfirmed === true) {
                onUpdateClockToList();
                onAddClockToList({
                    "activity": "Finish Time",
                    "message": "Clocked Out"
                });
            }
        });
    }

    const { time, tag } = useJobTimer({
        total_time: timerTotalTime,
        start_time: timerStartTime,
        running: lastActivity !== "Finish Time",
        activeTechnician
    });

    const viewFullClockingReport = () => {
        let searchDate = moment(fromDate).format("YYYY-MM-DD");
        navigate("/reports/clocking-report?searchDate=" + searchDate);
    }


    return (
        <>
            <div className="col-md-4 clocking-buttons">
                <div className="clocking-actions">
                    <p className="clocking-title mb-3" >Time Clocking</p>
                    <div className="row">

                        <div className="page-title mb-3 text-center">
                            <span>Employee: {activeUserName}</span>
                            <p className="clocking-history mt-4">
                                <span className={totalLog > 0 ? "green-label" : ""} >{time}</span>
                            </p>
                        </div>

                        <div className="btn-wrapper col-md-6 px-1 first-buttons">

                            <Button type="button" variant="success" className="btn-success rounded-btn start mb-4"
                                onClick={(e) => {
                                    onAddClockToList({
                                        "activity": "Start Time",
                                        "message": "Clocked In"
                                    });
                                }}
                                disabled={lastActivity === "Finish Time" && totalLog === 0 ? false : true}
                            >
                                Start Time
                            </Button>
                            <br />
                            <Button type="button" variant="danger" className="btn-danger rounded-btn remove"
                                onClick={(e) => {
                                    stopClockTimer();
                                }}
                                disabled={lastActivity === "" || lastActivity === "Break" || lastActivity === "Lunch" || lastActivity === "Finish Time" ? true : false}
                            >
                                Finish Time
                            </Button>

                        </div>

                        <div className="btn-wrapper col-md-6 px-1 second-buttons">

                            <ToggleButtonGroup
                                type="radio"
                                name="break"
                                value={lastActivity === "Break" ? 1 : 0}
                                className="mb-4"
                            >
                                <ToggleButton
                                    value={1}
                                    id={`options1`}
                                    variant="outline-primary"
                                    className="btn-rounded mb-0 font-size-15"
                                    onClick={(e) => {
                                        onUpdateClockToList();
                                        setTimeout(() => {
                                            onAddClockToList({
                                                "activity": "Break",
                                                "message": "Break Started"
                                            });
                                        }, 1000);
                                        setIsBreakTime(true);
                                    }}
                                    disabled={lastActivity === "" || lastActivity === "Lunch" || lastActivity === "Break" || lastActivity === "Finish Time" ? true : false}
                                >
                                    Break
                                </ToggleButton>
                                <ToggleButton
                                    value={0}
                                    id={`options2`}
                                    variant="outline-primary"
                                    className="btn-rounded mb-0 font-size-15 "
                                    onClick={(e) => {
                                        onUpdateClockToList();
                                        setTimeout(() => {
                                            onAddClockToList({
                                                "activity": "Clocked In",
                                                "message": "Clocked In"
                                            });
                                        }, 1000);
                                        setIsBreakTime(false);
                                    }}
                                    disabled={lastActivity === "" || lastActivity === "Start Time" || lastActivity === "Clocked In" || lastActivity === "Lunch" || lastActivity === "Finish Time" ? true : false}
                                >
                                    Continue
                                </ToggleButton>
                            </ToggleButtonGroup>

                            <br />

                            <ToggleButtonGroup
                                type="radio"
                                name="lunch"
                                value={isLunchTime && lastActivity === "Lunch" ? 1 : 0}
                            >
                                <ToggleButton
                                    value={1}
                                    id={`options3`}
                                    variant="outline-primary"
                                    className="btn-rounded mb-0 font-size-15"
                                    onClick={(e) => {
                                        onUpdateClockToList();
                                        setTimeout(() => {
                                            onAddClockToList({
                                                "activity": "Lunch",
                                                "message": "Lunch Started"
                                            });
                                        }, 1000);
                                        setIsLunchTime(true);
                                    }}
                                    disabled={lastActivity === "" || lastActivity === "Lunch" || lastActivity === "Break" || lastActivity === "Finish Time" ? true : false}
                                >
                                    Lunch
                                </ToggleButton>
                                <ToggleButton
                                    value={0}
                                    id={`options4`}
                                    variant="outline-primary"
                                    className="btn-rounded mb-0 font-size-15"
                                    onClick={(e) => {
                                        onUpdateClockToList();
                                        setTimeout(() => {
                                            onAddClockToList({
                                                "activity": "Clocked In",
                                                "message": "Clocked In"
                                            });
                                        }, 1000);
                                        setIsLunchTime(false);
                                    }}
                                    disabled={lastActivity === "" || lastActivity === "Start Time" || lastActivity === "Break" || lastActivity === "Clocked In" || lastActivity === "Finish Time" ? true : false}
                                >
                                    Continue
                                </ToggleButton>
                            </ToggleButtonGroup>

                        </div>
                    </div>


                </div>

                {/* <div className="clocking-actions mt-3">
                    <p className="clocking-title mb-3" >Status</p>
                    <div className="col-md-12 px-1 d-flex justify-content-center color-badges">

                        <div className="color-class">
                            <i style={{ color: "green", fontSize: "20px" }} className="fa fa-circle"></i>
                            <span>Clocked On</span>
                        </div>

                        <div className="ml-1 color-class">
                            <i style={{ color: "#0096FF", fontSize: "20px" }} className="fa fa-circle"></i>
                            <span>Break/Lunch</span>
                        </div>

                        <div className="ml-1 color-class">
                            <i style={{ color: "red", fontSize: "20px" }} className="fa fa-circle"></i>
                            <span>Clocked Out</span>
                        </div>

                        <div className="ml-1 color-class">
                            <i style={{ color: "black", fontSize: "20px" }} className="fa fa-circle"></i>
                            <span>Leave/Sick</span>
                        </div>

                    </div>
                </div> */}

                <div className="clocking-actions mt-3">
                    <div onClick={viewFullClockingReport} className="col-md-12 px-1 d-flex align-items-center justify-content-sm-evenly color-badges ">

                        <div className="">
                            <span>Full Clocking Report</span>
                        </div>

                        <div className="color-class report-button rounded-btn break-btn btn btn-primary">
                            <span>View Report</span>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};

export default TimeClocking;
