import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_FAIL,
  DO_SUBSCRIPTION_CHECKOUT,
  DO_SUBSCRIPTION_CHECKOUT_SUCCESS,
  DO_SUBSCRIPTION_CHECKOUT_FAIL,
  EDIT_SUBSCRIPTION_PLANS,
  EDIT_SUBSCRIPTION_PLANS_SUCCESS,
  EDIT_SUBSCRIPTION_PLANS_FAIL,
  CAPRICORN_SUBSCRIPTION_PLANS,
  CAPRICORN_SUBSCRIPTION_PLANS_SUCCESS,
  CAPRICORN_SUBSCRIPTION_PLANS_FAIL,

} from "./actionTypes";


const initialState = {
  loadingData: false,
  subscriptionPlans: [],
  subscribeData: null,
  editSubscriptionPlans: [],
  capricornSubscriptionPlans: [],
  isFetching: false,
  workshopSmsSubscribeData: null,
  error: {
    message: "",
  },
  success: {
    message: ""
  }
};

const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION:
      state = {
        ...state,
        loadingData: true,
        subscribeData: null,
        isFetching: false,
      };
      break;
    case GET_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        subscribeData: action.payload,
        isFetching: true,
      };
      break;
    case GET_SUBSCRIPTION_FAIL:
      state = {
        ...state,
        loadingData: false,
        subscribeData: null,
        error: {
          message: action.payload,
        },
        isFetching: true,
      };
      break;
    case GET_SUBSCRIPTION_PLANS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_SUBSCRIPTION_PLANS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        subscriptionPlans: action.payload,
      };
      break;
    case GET_SUBSCRIPTION_PLANS_FAIL:
      state = {
        ...state,
        loadingData: false,
        subscriptionPlans: [],
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST:
      state = {
        ...state,
        loadingData: true,
        isFetching: false
      };
      break;
    case GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        workshopSmsSubscribeData: action.payload
      };
      break;
    case GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        }
      };
      break;
    case DO_SUBSCRIPTION_CHECKOUT:
      state = {
        ...state,
        loadingData: true,
        success: {
          message: ""
        },
        error: {
          message: ""
        },
        isFetching: false,
      };
      break;
    case DO_SUBSCRIPTION_CHECKOUT_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        success: {
          message: action.payload
        }
      };
      break;
    case DO_SUBSCRIPTION_CHECKOUT_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case EDIT_SUBSCRIPTION_PLANS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case EDIT_SUBSCRIPTION_PLANS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        editSubscriptionPlans: action.payload,
      };
      break;
    case EDIT_SUBSCRIPTION_PLANS_FAIL:
      state = {
        ...state,
        loadingData: false,
        editSubscriptionPlans: [],
        error: {
          message: action.payload,
        },
      };
      break;


    case CAPRICORN_SUBSCRIPTION_PLANS:
      state = {
        ...state,
        loadingData: true,
        success: {
          message: ""
        },
        error: {
          message: ""
        },
        isFetching: false,
      };
      break;
    case CAPRICORN_SUBSCRIPTION_PLANS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        success: {
          message: action.payload
        }
      };
      break;
    case CAPRICORN_SUBSCRIPTION_PLANS_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
      };
      break;




    // case CAPRICORN_SUBSCRIPTION_PLANS:
    //   state = {
    //     ...state,
    //     loadingData: true,
    //     success: {
    //       message: ""
    //     },
    //     error: {
    //       message: ""
    //     },
    //   };
    //   break;
    // case CAPRICORN_SUBSCRIPTION_PLANS_SUCCESS:
    //   state = {
    //     ...state,
    //     loadingData: false,
    //     capricornSubscriptionPlans: action.payload,
    //     success: {
    //       message: "Capricorn subscription confirmed sucessfully",
    //     }
    //   };
    //   break;
    // case CAPRICORN_SUBSCRIPTION_PLANS_FAIL:
    //   state = {
    //     ...state,
    //     loadingData: false,
    //     capricornSubscriptionPlans: [],
    //     error: {
    //       message: action.payload,
    //     },
    //   };
    //   break;


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SubscriptionReducer;
