import * as actions from "../actionType";

const customerReportState = {
  customerRevenueReport: {
    revenueChart: null,
    visitorChart: null,
    vehicleChart: null,
    error: false,
    loading: false,
  },
  jobRevenueReport: {
    jobChart: null,
    jobTable: [],
    error: false,
    loading: false,
  },
  topCustomerRevenueReport: {
    customerChart: null,
    customerTable: [],
    error: false,
    loading: false,
  },
  
  
};

export const CustomerReportsReducer = (state = customerReportState, action) => {

  switch (action.type) {
    case actions.AVERAGE_REVENUE_CUSTOMER:
      return {
        ...state,
        customerRevenueReport: {
          ...state.customerRevenueReport,
          loading: true,
          error: false,
        },
      };
    case actions.AVERAGE_REVENUE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerRevenueReport: {
          ...state.customerRevenueReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.AVERAGE_REVENUE_CUSTOMER_FAIL:
      return {
        ...state,
        customerRevenueReport: {
          ...state.customerRevenueReport,
          loading: false,
          error: true,
        },
      };


    case actions.AVERAGE_REVENUE_JOBTYPE:
      return {
        ...state,
        jobRevenueReport: {
          ...state.jobRevenueReport,
          loading: true,
          error: false,
        },
      };
    case actions.AVERAGE_REVENUE_JOBTYPE_SUCCESS:
      return {
        ...state,
        jobRevenueReport: {
          ...state.jobRevenueReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.AVERAGE_REVENUE_JOBTYPE_FAIL:
      return {
        ...state,
        jobRevenueReport: {
          ...state.jobRevenueReport,
          loading: false,
          error: true,
        },
      };


    case actions.CUSTOMER_BASED_REVENUE:
      return {
        ...state,
        topCustomerRevenueReport: {
          ...state.topCustomerRevenueReport,
          loading: true,
          error: false,
        },
      };
    case actions.CUSTOMER_BASED_REVENUE_SUCCESS:
      return {
        ...state,
        topCustomerRevenueReport: {
          ...state.topCustomerRevenueReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.CUSTOMER_BASED_REVENUE_FAIL:
      return {
        ...state,
        topCustomerRevenueReport: {
          ...state.topCustomerRevenueReport,
          loading: false,
          error: true,
        },
      };

      

      
    default:
      return state;
  }
};
