import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import americanexpress from 'assets/images/american-express.svg';
import card_img from 'assets/images/credit-card.svg';
import bankTransfer from 'assets/images/bt-3-1.png';
import efticon from '../../../../assets/images/eft-icon.png';
import mastercard from 'assets/images/mastercard.svg';
import visa from 'assets/images/visa.svg';
import Cards from './Cards';
import Checkbox from './Checkbox';
import PayHead from './PayHead';
import { usePaymentInfo } from './PaymentInfoProvider';
import { bgdark, debit } from './styles.module.scss';
import Wrapped from './Wrapped';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkshopId } from 'utils/getWorkshopId';
import {
    GET_PAYMENT_PROCESSING_FEES
} from "store/chartOfAccount/actionTypes";

const CardPaymentModal = ({ onChangeClick, show, onClose }) => {
    
    const { request, setRequest } = usePaymentInfo()

    const [checked, setChecked] = useState(true);
    const [eftposChecked, setEftposChecked] = useState(true);

    const [selectedCard, setSelectedCard] = useState("Other");

    useEffect(() => {
        if (show) {
            setRequest(pr => {
                return {
                    ...pr,
                    payment_method: checked ? 3 : 2,
                    card_type: checked ? 1 : 2,
                }
            })
        }
    }, [checked, show])

    const dispatch = useDispatch();

    const { paymentProcessing } = useSelector(
      (state) => state.ChartsOfAccountReducer
    );

    const loadPaymentProcessingFees = () => {
        dispatch({
            type: GET_PAYMENT_PROCESSING_FEES,
            payload: {
                get: "all",
                conditions: { 
                    workshop_id: getWorkshopId(),
                    card_payment: 1
                },
            },
        });
    };

    useEffect(() => {
        loadPaymentProcessingFees()
    },[])

    const [cards,setCards] = useState([]);
    const [paymentProcessingFees,setPaymentProcessingFees] = useState();

    const [masterCardFees,setMasterCardFees] = useState(0.00);
    const [visaCardFees,setVisaCardFees] = useState(0.00);
    const [amexCardFees,setAmexCardFees] = useState(0.00)

    useEffect(() => {
        let cardData = [];
        if(paymentProcessing){
            paymentProcessing.map((card) => {
                let cardArr = {};
                if(card.payment_types == "Master Card"){
                    cardArr['img'] = mastercard;
                    setMasterCardFees(card.processing_fees)
                }else if(card.payment_types == "Visa Card"){
                    cardArr['img'] = visa;
                    setVisaCardFees(card.processing_fees)
                }else if(card.payment_types == "Amex Card"){
                    cardArr['img'] = americanexpress;
                    setAmexCardFees(card.processing_fees)
                }else if(card.payment_types == "Other"){
                    setPaymentProcessingFees(card.processing_fees)
                    cardArr['img'] = bankTransfer;
                    setRequest(p => {
                        return {
                            ...p,
                            // eftpos_checked:eftposChecked,
                            payment_processing_fee:card.processing_fees
                        }
                    })
                }
                cardArr['value'] = card.payment_types;
                cardArr['dbValue'] = card.id;
                cardArr['processing_fees'] = card.processing_fees;
                cardData.push(cardArr);
            })
            setCards(cardData)
        }
    },[paymentProcessing])
    
    useEffect(() => {
        if(eftposChecked){
            setRequest(p => {
                return {
                    ...p,
                    // eftpos_checked:eftposChecked,
                    payment_processing_fee:paymentProcessingFees
                }
            })
        }
        else{
            if(selectedCard == "Master Card"){
                setRequest(p => {
                    return {
                        ...p,
                        payment_processing_fee:masterCardFees
                    }
                })
            }
            if(selectedCard == "Visa Card"){
                setRequest(p => {
                    return {
                        ...p,
                        payment_processing_fee:visaCardFees
                    }
                })
            }
            if(selectedCard == "Amex Card"){
                setRequest(p => {
                    return {
                        ...p,
                        payment_processing_fee:amexCardFees
                    }
                })
            }
        }
    },[eftposChecked])

    return (
        <Wrapped 
            show={show} 
            onHide={onClose} 
            selectedCard={selectedCard || "master-card" } 
            checked={checked} >

            <div className='px-2'>
                <PayHead onChangeClick={onChangeClick} image={card_img} label="Card Payment" />

                <div className="row my-3" style={{ marginLeft: 0, marginRight: 0 }}>
                    <div className="col-md-6 px-0">
                        <div>
                            <label htmlFor="date">Date</label>
                            <DatePicker
                                onChange={(value => {
                                    setRequest(p => {
                                        return {
                                            ...p,
                                            payment_date: value.format('YYYY-MM-DD')
                                        }
                                    })
                                })}
                                style={{ padding: ".375rem .75rem", fontSize: 16, lineHeight: 1.5, display: 'block' }} className='form-control'
                                defaultValue={moment(request.payment_date, 'YYYY-MM-DD')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6" style={{ paddingLeft: "10px", paddingRight: "0" }}>
                        <div>
                            <label htmlFor="date">Last 4 Digit (Optional)</label>
                            <input placeholder='0000' value={request.card_last_digit} type="text" className='form-control' onChange={e => {
                                if (e.target.value.length > 4 || !(/^[\d]*$/.test(e.target.value))) return
                                setRequest(pr => {
                                    return {
                                        ...pr,
                                        "card_last_digit": e.target.value,
                                    }
                                })
                            }} />
                        </div>
                    </div>
                </div>

                <div className={`${debit} ${checked ? bgdark : ''}`} onClick={() => setChecked(!checked)}>
                    <div>
                        <img width={30} src={efticon} alt="Card" />
                        <span>EFTPOS</span>
                    </div>
                    <Checkbox checked={checked} onClick={(e) => {
                        setEftposChecked(!eftposChecked)
                        setChecked(!checked)
                    }} style={{ position: "absolute", right: '10px', }} />
                </div>

                <div>
                    <Cards onClick={(value, index, dbValue,processing_fees) => {
                        setSelectedCard(value)
                        setEftposChecked(false)
                        setChecked(false)
                        if(!eftposChecked){
                            setRequest(p => {
                                return {
                                    ...p,
                                    // payment_type: index === 0 ? undefined : index?.toString(),
                                    payment_type: index === 0 ? undefined : dbValue?.toString(),
                                    payment_processing_fee:(value == "Other") ? 0.00 : processing_fees
                                }
                            })
                        }
                    }} cards={cards} activeSelection={selectedCard} />
                </div>
                <div className='my-1'>
                    <label htmlFor="">Confirmation (Optional)</label>
                    <input
                        value={request.confirmation}
                        onChange={(e) => {
                            setRequest(p => {
                                return {
                                    ...p,
                                    confirmation: e.target.value
                                }
                            })
                        }}
                        type="text"
                        className='form-control'
                    />
                </div>
                <div className='my-1'>
                    <label htmlFor="">Note (Optional)</label>
                    <input
                        value={request.notes}
                        onChange={(e) => {
                            setRequest(p => {
                                return {
                                    ...p,
                                    notes: e.target.value,
                                    description: e.target.value,
                                }
                            })
                        }}
                        type="text" className='form-control' placeholder='e.g. Payment for entire order' />
                </div>
            </div>

        </Wrapped>
    )
}

export default CardPaymentModal