import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GET_SUPPILERS_COUNT,
  GET_SUPPILERS_LIST,
} from "../../../../../store/Contact/actionTypes";
import { supplierSearchSuccess } from "../../../../../store/Contact/actions";
import "../../style.scss";
import CommonListLayout from "./common/ListLayout";

const searchConditions = (search) => {
  return {
    "name LIKE": `%${search}%`,
    "email LIKE": `%${search}%`,
    "phone LIKE": `%${search}%`,
    "suburb LIKE": `%${search}%`,
    "primary_firstname LIKE": `%${search}%`,
    "primary_lastname LIKE": `%${search}%`,
  };
};

const SuppliersList = () => {
  document.title = "5iQ | Suppliers";
  const [selectedWorkshop, setSelectedWorkshop] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState([]);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [customerData, setCustomerData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const workshop_id = window.atob(storedData.workshop_id);
  const hq_id = window.atob(storedData.hq_id);

  const { loading, supplierList, supplierCount, supplierSearch } = useSelector(
    (state) => state.Contact
  );

  const loadCount = useCallback(
    (searchTerm = "") => {
      const conditions = {
        head_quarters_id: hq_id,
        is_deleted: 0,
        "name NOT IN ": ["Repco Supplier", "Burson Supplier", "BNT Supplier"],
      };

      if (searchTerm !== "") {
        conditions["OR"] = searchConditions(searchTerm.trim());
      }

      let loadDataPayload = {
        get: "all",
        conditions,
        order: {
          id: "desc",
        },
        fields: {
          COUNT: "COUNT(Suppliers.id)",
        },
      };

      if (selectedWorkshop && selectedWorkshop !== 0) {
        loadDataPayload["conditions"]["workshop_id"] = selectedWorkshop;
      }

      dispatch({
        type: GET_SUPPILERS_COUNT,
        payload: loadDataPayload,
      });
    },
    [dispatch, hq_id, selectedWorkshop]
  );

  const loadData = useCallback(
    (page = 1, pageLimit = 10, searchTerm = "") => {
      const conditions = {
        "Suppliers.head_quarters_id": hq_id,
        "Suppliers.is_deleted": 0,
        "Suppliers.name NOT IN ": ["Repco Supplier", "Burson Supplier", "BNT Supplier"],
      };

      if (searchTerm !== "") {
        conditions["OR"] = searchConditions(searchTerm.trim());
      }
      let loadDataPayload = {
        get: "all",
        conditions,
        limit: pageLimit,
        page: page,
        order: {
          "Suppliers.name": "asc",
        },
        contain: {
          Workshops: {
            fields: [
              "Workshops.id",
              "Workshops.name"
            ],
          },
        },
        fields: [
          "Suppliers.name",
          "Suppliers.id",
          "Suppliers.primary_firstname",
          "Suppliers.primary_lastname",
          "Suppliers.email",
          "Suppliers.phone",
          "Suppliers.suburb",
          "Suppliers.workshop_id",
          "Suppliers.head_quarters_id",
        ],
      };

      if (selectedWorkshop && selectedWorkshop !== 0) {
        loadDataPayload["conditions"]["Suppliers.workshop_id"] = selectedWorkshop;
      }

      dispatch({
        type: GET_SUPPILERS_LIST,
        payload: loadDataPayload,
      });
    },
    [dispatch, hq_id, selectedWorkshop]
  );

  useEffect(() => {
    loadData(currentPage, sizePerPage, search);
    loadCount(search);
  }, [currentPage, loadCount, loadData, search, selectedWorkshop, sizePerPage]);

  useEffect(() => {
    return () => {
      dispatch(supplierSearchSuccess({}));
    };
  }, [dispatch]);

  const columns = [
    {
      dataField: "name",
      text: "Supplier Name",
      // events: {
      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     navigate(`/edit_supplier/${row.id}`);
      //   },
      // },
    },

    {
      dataField: "primary_firstname",
      text: "Contact Name",
      // events: {
      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     navigate(`/edit_supplier/${row.id}`);
      //   },
      // },
      formatter: (cell, row) => {
        return `${row.primary_firstname || " "} ${row.primary_lastname || ""}`;
      },
    },
    {
      dataField: "email",
      text: "Email",
      // events: {
      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     navigate(`/edit_supplier/${row.id}`);
      //   },
      // },
    },

    {
      dataField: "phone",
      text: "Contact Number",
      // events: {
      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     navigate(`/edit_supplier/${row.id}`);
      //   },
      // },
    },
    {
      dataField: "suburb",
      text: "Suburb",
      // events: {
      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     navigate(`/edit_supplier/${row.id}`);
      //   },
      // },
    },
    {
      dataField: "workshop.name",
      text: "Workshop Name",
    }
    // {
    //   dataField: "df1",
    //   isDummyField: true,
    //   text: "Action",
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="btn-group toggle-hover">
    //         <button className="btn " type="button">
    //           <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
    //         </button>
    //         <div className="dropdown-list">
    //           <li>
    //             <Link to={`/get_branch/${row.id}`} className="" title="Branch">
    //               <i className=" fa fa-file-text text-info font-size-20"></i>
    //               <span>Branch</span>
    //             </Link>
    //           </li>

    //           <li>
    //             <Link to={`/edit_supplier/${row.id}`} className="" title="EDIT">
    //               <i className="fa fa-pencil text-info"></i>
    //               <span>Edit</span>
    //             </Link>
    //           </li>
    //           <li
    //             onClick={() => {
    //               setSelectedRow(row.id);
    //               setShowDeleteModal(true);
    //             }}
    //           >
    //             <Link to="#" className="delete-link" title="DELETE">
    //               <span className="red-bg">
    //                 <i className="fa fa-minus"></i>
    //               </span>
    //               <span>Delete</span>
    //             </Link>
    //           </li>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  // const checkBox = {
  //   mode: "checkbox",
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     if (isSelect) {
  //       setSelectedRow([...selectedRow, row.id]);
  //     } else {
  //       let rowSelected = [...selectedRow];

  //       const index = rowSelected.indexOf(row.id);
  //       if (index > -1) {
  //         rowSelected.splice(index, 1);
  //       }
  //       setSelectedRow(rowSelected);
  //     }
  //   },
  //   onSelectAll: (isSelected) => {
  //     if (isSelected) {
  //       let ids = [];
  //       for (var key = 0; key < sizePerPage; key++) {
  //         if (customerData[key] !== undefined) ids.push(customerData[key].id);
  //       }
  //       setSelectedRow(ids);
  //     } else {
  //       setSelectedRow([]);
  //     }
  //   },
  //   selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         ref={(input) => {
  //           if (input) input.indeterminate = indeterminate;
  //         }}
  //         checked={rest.checked}
  //         name={rest.name}
  //         id={rest.id}
  //         type="checkbox"
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  //   selectionRenderer: ({ mode, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         id={rest.id}
  //         checked={rest.checked}
  //         type={mode}
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  // };

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
    loadData(page, sizePerPage);
  };

  const handleSearch = (e) => {
    setCurrentPage(1);
    setSearch(e);

    // if (e !== "") {
    //   dispatch({
    //     type: SUPPLIER_SEARCH,
    //     payload: {
    //       [selectedWorkshop ? "workshop_id" : "head_quarters_id"]:
    //         selectedWorkshop ?? hq_id,
    //       keyword: e,
    //     },
    //   });
    // } else {
    //   dispatch(supplierSearchSuccess({}));
    //   setCustomerData(supplierList);
    //   setTotalRecords(supplierCount);
    // }
  };
  useEffect(() => {
    setTotalRecords(supplierCount);
  }, [supplierCount]);

  useEffect(() => {
    if (Object.keys(supplierSearch).length !== 0) {
      var text = [];

      let totalSearchCount = 0;
      for (var key = 0; key < 10; key++) {
        if (supplierSearch[key] !== undefined) {
          // totalSearchCount = supplierSearch.total_count;
          text.push(supplierSearch[key]);
        }
      }

      setTotalRecords(supplierSearch.length);
      setCustomerData(text);
    } else {
      if (supplierList.length !== 0) {
        setCustomerData(supplierList);
      } else if (supplierList.length === 0) {
        setCustomerData([]);
      }
    }
  }, [supplierList, supplierSearch]);

  // useEffect(() => {
  //   if (!loading && search && search !== "" && !Array.isArray(supplierSearch))
  //     toast.error("No record found...");
  // }, [search, supplierSearch, loading]);

  return (
    <CommonListLayout
      loading={loading}
      totalRecords={totalRecords}
      searchQuery={search}
      onSearchQueryChange={handleSearch}
      columns={columns}
      rows={customerData}
      // selectRowHandler={checkBox}
      tableChangeHandler={onTableChange}
      currentPage={currentPage}
      sizePerPage={sizePerPage}
      remotePagination={true}
      selectedWorkshop={selectedWorkshop}
      setSelectedWorkshop={setSelectedWorkshop}
      type={"suppliers"}
    />
  );
};

export default SuppliersList;
