import * as actions from "../actionType";

const quoteReportState = {
  quoteRevenueReport: {
    chartTable: [],
    chartData: null,
    error: false,
    loading: false,
  },
  quoteForecastReport: {
    forecastTable: [],
    forecastChart: null,
    error: false,
    loading: false,
  },
  quoteStatsReport: {
    conversionChart: null,
    categoryChart: null,
    statsChart: null,
    error: false,
    loading: false,
  },
  
};

export const QuoteReportsReducer = (state = quoteReportState, action) => {

  switch (action.type) {
    case actions.GET_QUOTE_REPORT:
      return {
        ...state,
        quoteRevenueReport: {
          ...state.quoteRevenueReport,
          loading: true,
          error: false,
        },
      };
    case actions.GET_QUOTE_REPORT_SUCCESS:
      return {
        ...state,
        quoteRevenueReport: {
          ...state.quoteRevenueReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.GET_QUOTE_REPORT_FAIL:
      return {
        ...state,
        quoteRevenueReport: {
          ...state.quoteRevenueReport,
          loading: false,
          error: true,
        },
      };


    case actions.QUOTE_REPORT_FORECAST_TABLE:
      return {
        ...state,
        quoteForecastReport: {
          ...state.quoteForecastReport,
          loading: true,
          error: false,
        },
      };
    case actions.QUOTE_REPORT_FORECAST_TABLE_SUCCESS:
      return {
        ...state,
        quoteForecastReport: {
          ...state.quoteForecastReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.QUOTE_REPORT_FORECAST_TABLE_FAIL:
      return {
        ...state,
        quoteForecastReport: {
          ...state.quoteForecastReport,
          loading: false,
          error: true,
        },
      };

    case actions.QUOTE_REPORT_STATS:
      return {
        ...state,
        quoteStatsReport: {
          ...state.quoteStatsReport,
          loading: true,
          error: false,
        },
      };
    case actions.QUOTE_REPORT_STATS_SUCCESS:
      return {
        ...state,
        quoteStatsReport: {
          ...state.quoteStatsReport,
          loading: false,
          error: false,
          ...action.payload,
        },
      };
    case actions.QUOTE_REPORT_STATS_FAIL:
      return {
        ...state,
        quoteStatsReport: {
          ...state.quoteStatsReport,
          loading: false,
          error: true,
        },
      };

      
    default:
      return state;
  }
};
