import React, { useEffect, useState } from 'react';
import { dashboardWrapper, blockWrapper, blueTiles, orangeTiles, greenTiles, purpleTiles, rightBlock, chartWrapper, chartTitle, chartBlock, DropdownBtn, ratingBlock, ratingOuter, ratingWrapper, progressBar, customSlider, carouselItem } from "./styles.module.scss";
import serviceImg from '../../../assets/images/book-job-red.svg';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from "react-bootstrap/Button";
import Carousel from 'react-bootstrap/Carousel';
import NoResult from "../../../assets/images/no-result-found.jpeg";
import StarRatings from 'react-star-ratings';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { curentWeekDateRange,curentMonthWeekRange,curentMonthRange } from "../../../utils/dateRange";
import {
    GET_VEHICLE_COUNT,
    GET_SERVICE_DUE_COUNT,
    GET_CUSTOMER_AND_APP_COUNT,
    GET_BOOK_IN_SOURCE,
    GET_CUSTOMER_SURVEY,
    GET_NEWS_FEED,
    GET_DASHBOARD_JOB_COUNT,
    GET_DASHBOARD_WEEK_JOB_COUNT,
    GET_DASHBOARD_INVOICE_COUNT,
    GET_DASHBOARD_WEEK_INVOICE_COUNT,
    GET_DASHBOARD_PAYMENT_COUNT,
    GET_DASHBOARD_WEEK_PAYMENT_COUNT
} from "../../../store/dashboard/actionTypes";
import { 
    getDashboardWeekJobCountSuccess,
    getDashboardWeekInvoiceCountSuccess,
    getDashboardWeekPaymentCountSuccess 
} from 'store/dashboard/actions';
import { precise_round,numberWithCommas } from "utils/numberFunction";

const Dashboard = () => {

    const navigate = useNavigate(); // Navigate URL
    const dispatch = useDispatch(); // Dispatch variable
    const storedData = JSON.parse(sessionStorage.getItem('stored_data')); // Get session storage data
    let workshop_id = window.atob(storedData.workshop_id); // workshop id

    // eslint-disable-next-line no-unused-vars
    const [title, setTitle] = useState("5iQ | Home");
    
    // Count data
    const [carCount, setCarCount] = useState(0);
    const [serviceDueCount, setServiceDueCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);
    const [appDownloadCount, setAppDownloadCount] = useState(0);

    // Book in Source
    const [totalBookInSource,setTotalBookInSource] = useState(0);
    const [inAppCount,setInAppCount] = useState(0);
    const [webCount,setWebCount] = useState(0);
    
    // Customer survey feedback
    const [starTotal,setStarTotal] = useState(0);
    const [starAvgTotal,setStarAvgTotal] = useState(0);
    const [starOne,setStarOne] = useState(0);
    const [starTwo,setStarTwo] = useState(0);
    const [starThree,setStarThree] = useState(0);
    const [starFour,setStarFour] = useState(0);
    const [starFive,setStarFive] = useState(0);
    const [starPercentOne,setStarPercentOne] = useState(0);
    const [starPercentTwo,setStarPercentTwo] = useState(0);
    const [starPercentThree,setStarPercentThree] = useState(0);
    const [starPercentFour,setStarPercentFour] = useState(0);
    const [starPercentFive,setStarPercentFive] = useState(0);

    // News feed
    const [newsFeedData,setNewsFeedData] = useState(null);
    let description = '';

    useEffect(() => {
        document.title = "5iQ | Home";
    }, [title]);

    // Book in source data options 
    const [bookInSourceData,setBookInSourceData] = useState([
        {
            name: 'In App',
            y: 0,
            color: '#4b78c4'
        }, {
            name: 'Website',
            y: 0,
            color: '#09c3d0'
        },{
            name: 'Social Media',
            y: 0,
            color: '#08c8b0'
        }
    ])

    // chart data (jobs,invoice and payment)
    // eslint-disable-next-line no-unused-vars
    const [weekDays,setWeekDays] = useState(curentWeekDateRange);
    // eslint-disable-next-line no-unused-vars
    const [monthWeekDays,setMonthWeekDays] = useState(curentMonthWeekRange);
    // eslint-disable-next-line no-unused-vars
    const [monthDays,setMonthDays] = useState(curentMonthRange);

    // jobs
    const [xAxisTitle,setxAxisTitle] = useState();
    const [totalJobCount,setTotalJobCount] = useState(0);
    const [categoriesArr,setCategoriesArr] = useState([]);
    const [barChartDataArr,setBarChartDataArr] = useState([]);
    
    const [jobDayFlag,setJobDayFlag] = useState(true);
    const [jobMonthFlag,setJobMonthFlag] = useState(false);    
    const [jobWeekFlag,setJobWeekFlag] = useState(false);    

    // invoice
    const [xAxisInvoiceTitle,setxAxisInvoiceTitle] = useState();
    const [totalInvoiceCount,setTotalInvoiceCount] = useState(0);
    const [categoriesInvoiceArr,setCategoriesInvoiceArr] = useState([]);
    const [barChartInvoiceDataArr,setBarChartInvoiceDataArr] = useState([]);

    const [invoiceDayFlag,setInvoiceDayFlag] = useState(true);
    const [invoiceMonthFlag,setInvoiceMonthFlag] = useState(false);    
    const [invoiceWeekFlag,setInvoiceWeekFlag] = useState(false);    

    // payment
    const [xAxisPaymentTitle,setxAxisPaymentTitle] = useState();
    const [totalPaymentCount,setTotalPaymentCount] = useState(0);
    const [categoriesPaymentArr,setCategoriesPaymentArr] = useState([]);
    const [barChartPaymentDataArr,setBarChartPaymentDataArr] = useState([]);

    const [paymentDayFlag,setPaymentDayFlag] = useState(true);
    const [paymentMonthFlag,setPaymentMonthFlag] = useState(false);    
    const [paymentWeekFlag,setPaymentWeekFlag] = useState(false);    

    useEffect(() => {
        // Dispatch action for Car(Vehicles) count 
        const fetchData = async () => {
            dispatch({
                type: GET_VEHICLE_COUNT,
                payload: {
                    "get": "all",
                    "conditions": {
                        "Vehicles.workshop_id": workshop_id,
                        "Vehicles.status": 1,
                        "Vehicles.vehicle_type": "Vehicle"
                    },
                    "fields": {
                        "count": "count(Vehicles.id)"
                    }
                }
            });
        }
        fetchData()
            .catch(console.error)

        // Dispatch action for service due vehicle count 
        const fetchServiceData = async () => {
            dispatch({
                type: GET_SERVICE_DUE_COUNT,
                payload: {
                    "get": "all",
                    "conditions": { "workshop_id": workshop_id },
                    "group": "vehicle_id",
                    "fields": ["id"]
                }
            });
        }
        fetchServiceData()
            .catch(console.error)

        // Dispatch action for customer count and app download count
        const fetchCustomerData = async () => {
            dispatch({
                type: GET_CUSTOMER_AND_APP_COUNT,
                payload: { "workshop_id": workshop_id }
            });
        }
        fetchCustomerData()
            .catch(console.error)

        // Dispatch action for book in source
        const fetchBookInSourceData = async () => {
            dispatch({
                type: GET_BOOK_IN_SOURCE,
                payload:{
                    "get": "all",
                    "conditions": {
                        "workshop_id": workshop_id,
                        "is_break_time": 0
                    },
                    "fields": {
                        "app": "sum(if(`bookin_source` = 1,1,0))",
                        "web": "sum(if(`bookin_source` = 2,1,0))",
                        "0": "workshop_id"
                    }
                }
            });
        }
        fetchBookInSourceData()
            .catch(console.error)

        // Dispatch action for customer survey
        const fetchCustomerSurvey = async () => {
            dispatch({
                type: GET_CUSTOMER_SURVEY,
                payload:{
                    "get": "all",
                    "conditions": {
                      "workshop_id": workshop_id
                    },
                    "fields": {
                      "five": "sum(if(`garage_ratting` > 4.5 AND `garage_ratting` < 5.1 ,1,0))",
                      "four": "sum(if(`garage_ratting` > 3.5 AND `garage_ratting` < 4.5,1,0))",
                      "three": "sum(if(`garage_ratting` > 2.5 AND `garage_ratting` < 3.5,1,0))",
                      "two": "sum(if(`garage_ratting` > 1.5 AND `garage_ratting` < 2.5,1,0))",
                      "one": "sum(if(`garage_ratting` > 0 AND `garage_ratting` < 1.5,1,0))",
                      "total": "count(Id)",
                      "total_star": "SUM(`garage_ratting`)"
                    }
                }
            });
        }
        fetchCustomerSurvey()
            .catch(console.error)

        // Dispatch action for news feed
        const fetchNewsFeed = async () => {
            dispatch({
                type: GET_NEWS_FEED,
                payload:{
                    "get": "all",
                    "limit": 5,
                    "order": {
                        "id": "DESC"
                    },
                    "conditions": {
                      "status": 1
                    }
                }
            });
        }
        fetchNewsFeed()
            .catch(console.error)
        
    }, [])

    useEffect(() => {
        if(jobDayFlag === true){
            // Dispatch action for jobs count
            const fetchJobChart = async () => {
                dispatch({
                    type: GET_DASHBOARD_JOB_COUNT,
                    payload:{
                        "get": "all",
                        "conditions": {
                        "workshop_id": workshop_id,
                        "from_date >=": weekDays[0],
                        "from_date <=": weekDays[6],
                        "is_break_time": 0,
                        "activity_schedule_id": 0,
                        "is_deleted": 0
                        }
                    }
                });
            }
            fetchJobChart()
                .catch(console.error)
            
            setJobDayFlag(false);
        }
    },[weekDays, jobDayFlag, dispatch, workshop_id])

    useEffect(() => {
        if(invoiceDayFlag === true){
            // Dispatch action for invoice count
            const fetchJobChart = async () => {
                dispatch({
                    type: GET_DASHBOARD_INVOICE_COUNT,
                    payload:{
                        "get": "all",
                        "conditions": {
                            "workshop_id": workshop_id,
                            "date_issued >=": weekDays[0],
                            "date_issued <=": weekDays[6]
                        }
                    }
                });
            }
            fetchJobChart()
                .catch(console.error)
            
            setInvoiceDayFlag(false);
        }
    },[weekDays, invoiceDayFlag, dispatch, workshop_id])

    useEffect(() => {
        if(paymentDayFlag === true){
            // Dispatch action for payment count
            const fetchJobChart = async () => {
                dispatch({
                    type: GET_DASHBOARD_PAYMENT_COUNT,
                    payload:{
                        "get": "all",
                        "conditions": {
                            "workshop_id": workshop_id,
                            "created_on >=": weekDays[0]+' 00:00:00',
                            "created_on <=": weekDays[6]+' 23:59:59'
                        }
                    }
                });
            }
            fetchJobChart()
                .catch(console.error)
            
            setPaymentDayFlag(false);
        }
    },[weekDays, paymentDayFlag, dispatch, workshop_id])

    // Reducer (data store)
    const { 
        vehicleCount,
        serviceCount, 
        customerAndAppCount, 
        bookInSource, 
        customerSurvey, 
        newsFeed,
        dashboardJobCount,
        dashboardWeekJobCount,
        dashboardInvoiceCount,
        dashboardWeekInvoiceCount,
        dashboardPaymentCount,
        dashboardWeekPaymentCount,
    } = useSelector((state) => state.DashboardReducer);

    // set vehicle count 
    useEffect(() => {
        if (vehicleCount !== null && vehicleCount !== undefined) {
            setCarCount(vehicleCount?.[0]?.count || 0);
        }
    }, [vehicleCount])

    // set service due count 
    useEffect(() => {
        if (serviceCount !== null && serviceCount !== undefined) {
            setServiceDueCount(serviceCount.length);
        }
    }, [serviceCount])

    // set customer and app download count 
    useEffect(() => {
        if (customerAndAppCount !== null && customerAndAppCount !== undefined) {
            setCustomerCount(customerAndAppCount?.total_customers || 0);
            setAppDownloadCount(customerAndAppCount?.app_download || 0);
        }
    }, [customerAndAppCount])

    // set Book in source chart data
    useEffect(() => {
        if (bookInSource !== null && bookInSource !== undefined) {
            let bookData = [
                {
                    name: 'In App',
                    y: parseInt(bookInSource?.[0]?.app || 0),
                    color: '#4b78c4'
                }, {
                    name: 'Website',
                    y: parseInt(bookInSource?.[0]?.web || 0),
                    color: '#09c3d0'
                },{
                    name: 'Social Media',
                    y: 0,
                    color: '#08c8b0'
                }
            ];
            var totalBooked = parseInt(bookInSource?.[0]?.app || 0) + parseInt(bookInSource?.[0]?.web || 0);
            setInAppCount(bookInSource?.[0]?.app || 0);
            setWebCount(bookInSource?.[0]?.web || 0);
            setBookInSourceData(bookData);
            setTotalBookInSource(totalBooked);
        }
    }, [bookInSource])

    // set customer survey feedback data
    useEffect(() => {
        if(customerSurvey !== null && customerSurvey !== undefined){
            var totalStar = customerSurvey?.[0]?.total_star || 0;
            var total = customerSurvey?.[0]?.total || 0;  

            var averageStar = parseFloat(totalStar/total).toFixed(2);
            
            var five = customerSurvey?.[0]?.five || 0;  
            var four = customerSurvey?.[0]?.four || 0;  
            var three = customerSurvey?.[0]?.three || 0;  
            var two = customerSurvey?.[0]?.two || 0;  
            var one = customerSurvey?.[0]?.one || 0;  
            
            var percentFive = parseFloat(five * 100) / parseFloat(total);
            var percentFour = parseFloat(four * 100) / parseFloat(total);
            var percentThree = parseFloat(three * 100) / parseFloat(total);
            var percentTwo = parseFloat(two * 100) / parseFloat(total);
            var percentOne = parseFloat(one * 100) / parseFloat(total);

            setStarTotal(total);
            setStarAvgTotal(parseFloat(averageStar));

            setStarOne(one);
            setStarTwo(two);
            setStarThree(three);
            setStarFour(four);
            setStarFive(five);

            setStarPercentFive(percentFive);
            setStarPercentFour(percentFour);
            setStarPercentThree(percentThree);
            setStarPercentTwo(percentTwo);
            setStarPercentOne(percentOne);
        }
    },[customerSurvey])

    useEffect(() => {
        if(newsFeed !== null && newsFeed !== undefined){
            setNewsFeedData(newsFeed);
        }
    },[newsFeed])

    // set dashboard job chart count
    useEffect(() => {
        if(dashboardJobCount !== null && dashboardJobCount !== undefined){
            var totalJob = 0;
            let jobValueArr = [];
            let jobKeyArr = [];
            weekDays.map((days,index) => {
                totalJob = totalJob + dashboardJobCount[days];
                jobValueArr.push(dashboardJobCount[days]);
                jobKeyArr.push(moment(days).format("ddd"));
            })
            var day = moment(weekDays[0]).format('DD')+" - "+moment(weekDays[6]).format('DD');
            var month = moment(weekDays[6]).format('MMM');
            var year = moment(weekDays[6]).format('YYYY');
            setxAxisTitle(day+" <b>"+month+" - "+year+"</b>");
            setTotalJobCount(totalJob);
            setCategoriesArr(jobKeyArr);
            setBarChartDataArr(jobValueArr);
        }
    },[dashboardJobCount,weekDays])

    // set dashboard invoice chart count
    useEffect(() => {
        if(dashboardInvoiceCount !== null && dashboardInvoiceCount !== undefined){
            var totalInvoice = 0;
            let invoiceValueArr = [];
            let invoiceKeyArr = [];
            weekDays.map((days,index) => {
                totalInvoice = parseFloat(totalInvoice) + parseFloat(dashboardInvoiceCount[days]);
                invoiceValueArr.push(parseFloat(dashboardInvoiceCount[days]));
                invoiceKeyArr.push(moment(days).format("ddd"));
            })
            var day = moment(weekDays[0]).format('DD')+" - "+moment(weekDays[6]).format('DD');
            var month = moment(weekDays[6]).format('MMM');
            var year = moment(weekDays[6]).format('YYYY');
            setxAxisInvoiceTitle(day+" <b>"+month+" - "+year+"</b>");
            setTotalInvoiceCount(numberWithCommas(parseFloat(precise_round(totalInvoice))));
            setCategoriesInvoiceArr(invoiceKeyArr);
            setBarChartInvoiceDataArr(invoiceValueArr);
        }
    },[dashboardInvoiceCount,weekDays])

    // set dashboard payment chart count
    useEffect(() => {
        if(dashboardPaymentCount !== null && dashboardPaymentCount !== undefined){
            var totalPayment = 0;
            let paymentValueArr = [];
            let paymentKeyArr = [];
            weekDays.map((days,index) => {
                totalPayment = totalPayment + parseFloat(dashboardPaymentCount[days]);
                paymentValueArr.push(parseFloat(dashboardPaymentCount[days]));
                paymentKeyArr.push(moment(days).format("ddd"));
            })
            var day = moment(weekDays[0]).format('DD')+" - "+moment(weekDays[6]).format('DD');
            var month = moment(weekDays[6]).format('MMM');
            var year = moment(weekDays[6]).format('YYYY');
            setxAxisPaymentTitle(day+" <b>"+month+" - "+year+"</b>");
            setTotalPaymentCount(numberWithCommas(parseFloat(precise_round(totalPayment))));
            setCategoriesPaymentArr(paymentKeyArr);
            setBarChartPaymentDataArr(paymentValueArr);
        }
    },[dashboardPaymentCount,weekDays])

    // Gradient colors 
    const gradientChartColors = [{
        linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1
        },
        stops: [
            [0, '#159957'],
            [1, '#155799']
        ]
    }, {
        linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1
        },
        stops: [
            [0, '#FFE000'],
            [1, '#799F0C']
        ]
    }, {
        linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1
        },
        stops: [
            [0, '#ff9068'],
            [1, '#ff4b1f']
        ]
    }, {
        linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1
        },
        stops: [
            [0, '#89216B'],
            [1, '#DA4453']
        ]
    }, {
        linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1
        },
        stops: [
            [0, '#2980b9'],
            [1, '#6DD5FA'],
            [2, '#ffffff']
        ]
    }, {
        linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1
        },
        stops: [
            [0, '#F37335'],
            [1, '#FDC830']
        ]
    }, {
        linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1
        },
        stops: [
            [0, '#78ffd6'],
            [1, '#a8ff78']
        ]
    }]

    // set gradient colours in charts data
    const generateChartDataWithGradient = (data) => {
        const genData = data.map(function (item, index) {
            return {
                y: item,
                color: { ...gradientChartColors[index] }
            }
        });
        return genData;
    }

    // bar chart default data
    const barChartOptions = {
        title: {text: ''},
        legend: {enabled: false},
        tooltip: {
            formatter: function() {
              return this.x +': <b>'+ this.y +'</b>';
            }
        },
        chartOptions: {
            bar: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true
                },
                showInLegend: true
            }
        },
        xAxis: {
            categories: categoriesArr,
            title:{
                text:xAxisTitle
            }
        },
        yAxis: {
            visible: false
        },
        series: [{
            type: 'column',
            data: generateChartDataWithGradient(barChartDataArr),
            linearGradient: {x1: 0,x2: 0,y1: 0,y2: 1},
            stops: [[0, '#2980b9'],[1, '#6DD5FA'],[2, '#ffffff']]
        }]
    }

    // Book in source high chart
    const pieChartOptions = {
        legend: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                size:150,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend:false
            }
        },
        title: {
            verticalAlign: 'middle',
            text: totalBookInSource
        },
        series: [{
            depth: 45,
            type: "pie",
            innerSize: '70%',
            name: '',
            colorByPoint: true,
            data: bookInSourceData
        }]
    }
    
    const barChartClick = (type,day) => {
        if(day === "week" && type === "job"){
            // Dispatch action for week jobs count
            const fetchWeekJobChart = async () => {
                dispatch({
                    type: GET_DASHBOARD_WEEK_JOB_COUNT,
                    payload:{
                        "workshop_id": workshop_id,
                        "days":monthWeekDays
                    }
                });
            }
            fetchWeekJobChart()
                .catch(console.error)
            setJobWeekFlag(true);
        }
        if(day === "day" && type === "job"){
            setJobDayFlag(true);
        }
        if(day === "month" && type === "job"){
            // Dispatch action for month jobs count
            const fetchMonthJobChart = async () => {
                dispatch({
                    type: GET_DASHBOARD_WEEK_JOB_COUNT,
                    payload:{
                        "workshop_id": workshop_id,
                        "days":monthDays
                    }
                });
            }
            fetchMonthJobChart()
                .catch(console.error)
            setJobMonthFlag(true);
        }

        if(day === "day" && type === "invoice"){
            setInvoiceDayFlag(true);
        }
        if(day === "week" && type === "invoice"){
            // Dispatch action for week invoice count
            const fetchWeekInvoiceChart = async () => {
                dispatch({
                    type: GET_DASHBOARD_WEEK_INVOICE_COUNT,
                    payload:{
                        "workshop_id": workshop_id,
                        "days":monthWeekDays
                    }
                });
            }
            fetchWeekInvoiceChart()
                .catch(console.error)
            setInvoiceWeekFlag(true);
        }
        if(day === "month" && type === "invoice"){
            // Dispatch action for month invoice count
            const fetchMonthInvoiceChart = async () => {
                dispatch({
                    type: GET_DASHBOARD_WEEK_INVOICE_COUNT,
                    payload:{
                        "workshop_id": workshop_id,
                        "days":monthDays
                    }
                });
            }
            fetchMonthInvoiceChart()
                .catch(console.error)
            setInvoiceMonthFlag(true);
        }

        if(day === "day" && type === "payment"){
            setPaymentDayFlag(true);
        }
        if(day === "week" && type === "payment"){
            // Dispatch action for week payment count
            const fetchWeekPaymentChart = async () => {
                dispatch({
                    type: GET_DASHBOARD_WEEK_PAYMENT_COUNT,
                    payload:{
                        "workshop_id": workshop_id,
                        "days":monthWeekDays
                    }
                });
            }
            fetchWeekPaymentChart()
                .catch(console.error)
            setPaymentWeekFlag(true);
        }
        if(day === "month" && type === "payment"){
            // Dispatch action for month payment count
            const fetchMonthPaymentChart = async () => {
                dispatch({
                    type: GET_DASHBOARD_WEEK_PAYMENT_COUNT,
                    payload:{
                        "workshop_id": workshop_id,
                        "days":monthDays
                    }
                });
            }
            fetchMonthPaymentChart()
                .catch(console.error)
            setPaymentMonthFlag(true);
        }
    }

    // Display job count in month and week 
    useEffect(()=>{
        if(dashboardWeekJobCount !== null && dashboardWeekJobCount !== undefined){
            if(jobMonthFlag === true){
                let monthCategories = [];
                var currentyear = '';
                if(monthDays.length !== 0){
                    monthDays.map((week,index) => {
                        monthCategories.push(moment(week['Monday'],'YYYY-MM-DD').format('MMMM'));
                    })
                    currentyear = moment(monthDays[2]).format('YYYY');
                }
                var jobTotal = dashboardWeekJobCount.reduce((a, b) => parseInt(a) + parseInt(b), 0)
                
                setCategoriesArr(monthCategories);
                setBarChartDataArr(dashboardWeekJobCount.map(Number));
                setxAxisTitle(" <b>"+currentyear+"</b>");
                setTotalJobCount(jobTotal);
                setJobMonthFlag(false);
                dispatch(getDashboardWeekJobCountSuccess(null))
            }
            
            if(jobWeekFlag === true){
                let weekCategories = [];
                var month = '';
                var year = ''
                if(monthWeekDays.length !== 0){
                    monthWeekDays.map((week,index) => {
                        var weekString = moment(week['Monday'],'YYYY-MM-DD').format('DD')+" - "+moment(week['Sunday'],'YYYY-MM-DD').format('DD');
                        weekCategories.push(weekString);
                    })
                    month = moment().format('MMM');
                    year = moment().format('YYYY');
                }
                // eslint-disable-next-line no-redeclare
                var jobTotal = dashboardWeekJobCount.reduce((a, b) => parseInt(a) + parseInt(b), 0)
                
                setCategoriesArr(weekCategories);
                setBarChartDataArr(dashboardWeekJobCount.map(Number));
                setxAxisTitle(" <b>"+month+" - "+year+"</b>");
                setTotalJobCount(jobTotal);
                setJobWeekFlag(false);
                dispatch(getDashboardWeekJobCountSuccess(null))
            }
        }
    },[dashboardWeekJobCount, jobMonthFlag,jobWeekFlag])

    // Display invoice count in week and month
    useEffect(()=>{
        if(dashboardWeekInvoiceCount !== null && dashboardWeekInvoiceCount !== undefined){
            if(invoiceMonthFlag === true){
                let monthCategories = [];
                var currentyear = '';
                if(monthDays.length !== 0){
                    monthDays.map((week,index) => {
                        var monthString = moment(week['Monday'],'YYYY-MM-DD').format('MMM');
                        monthCategories.push(monthString);
                    })
                    currentyear = moment(monthDays[2]).format('YYYY');
                }
                var invoiceTotal = dashboardWeekInvoiceCount.reduce((a, b) => parseInt(a) + parseInt(b), 0)
                
                setBarChartInvoiceDataArr(dashboardWeekInvoiceCount.map(Number));
                setCategoriesInvoiceArr(monthCategories);
                setxAxisInvoiceTitle(" <b>"+currentyear+"</b>");
                setTotalInvoiceCount(numberWithCommas(parseFloat(precise_round(invoiceTotal))));
                setInvoiceMonthFlag(false);
                dispatch(getDashboardWeekInvoiceCountSuccess(null));
            }
            if(invoiceWeekFlag === true){
                let weekCategories = [];
                var month = '';
                var year = ''
                if(monthWeekDays.length !== 0){
                    monthWeekDays.map((week,index) => {
                        var weekString = moment(week['Monday'],'YYYY-MM-DD').format('DD')+" - "+moment(week['Sunday'],'YYYY-MM-DD').format('DD');
                        weekCategories.push(weekString);
                    })
                    month = moment().format('MMM');
                    year = moment().format('YYYY');
                }
                // eslint-disable-next-line no-redeclare
                var invoiceTotal = dashboardWeekInvoiceCount.reduce((a, b) => parseInt(a) + parseInt(b), 0)
                
                setBarChartInvoiceDataArr(dashboardWeekInvoiceCount.map(Number));
                setCategoriesInvoiceArr(weekCategories);
                setxAxisInvoiceTitle(" <b>"+month+" - "+year+"</b>");
                setTotalInvoiceCount(numberWithCommas(parseFloat(precise_round(invoiceTotal))));
                setInvoiceWeekFlag(false);
                dispatch(getDashboardWeekInvoiceCountSuccess(null));
            }
        }
    },[dashboardWeekInvoiceCount,invoiceMonthFlag,invoiceWeekFlag])

    // Display payment count in week and month view    
    useEffect(()=>{
        if(dashboardWeekPaymentCount !== null && dashboardWeekPaymentCount !== undefined){
            if(paymentMonthFlag === true){
                let monthCategories = [];
                var currentyear = '';
                if(monthDays.length !== 0){
                    monthDays.map((week,index) => {
                        var monthString = moment(week['Monday'],'YYYY-MM-DD').format('MMM');
                        monthCategories.push(monthString);
                    })
                    currentyear = moment(monthDays[2]).format('YYYY');
                }
                var paymentTotal = dashboardWeekPaymentCount.reduce((a, b) => parseInt(a) + parseInt(b), 0)
                
                setBarChartPaymentDataArr(dashboardWeekPaymentCount.map(Number));
                setCategoriesPaymentArr(monthCategories);
                setxAxisPaymentTitle(" <b>"+currentyear+"</b>");
                setTotalPaymentCount(numberWithCommas(parseFloat(precise_round(paymentTotal))));
                setPaymentMonthFlag(false);
                dispatch(getDashboardWeekPaymentCountSuccess(null));
            }
            if(paymentWeekFlag === true){
                let weekCategories = [];
                var month = '';
                var year = ''
                if(monthWeekDays.length !== 0){
                    monthWeekDays.map((week,index) => {
                        var weekString = moment(week['Monday'],'YYYY-MM-DD').format('DD')+" - "+moment(week['Sunday'],'YYYY-MM-DD').format('DD');
                        weekCategories.push(weekString);
                    })
                    month = moment().format('MMM');
                    year = moment().format('YYYY');
                }
                // eslint-disable-next-line no-redeclare
                var paymentTotal = dashboardWeekPaymentCount.reduce((a, b) => parseInt(a) + parseInt(b), 0)
                
                setBarChartPaymentDataArr(dashboardWeekPaymentCount.map(Number));
                setCategoriesPaymentArr(weekCategories);
                setxAxisPaymentTitle(" <b>"+month+" - "+year+"</b>");
                setTotalPaymentCount(numberWithCommas(parseFloat(precise_round(paymentTotal))));
                setPaymentWeekFlag(false);
                dispatch(getDashboardWeekPaymentCountSuccess(null));
            }
        }
    },[dashboardWeekPaymentCount,paymentMonthFlag,paymentWeekFlag])

    // bar chart invoice default data
    const barChartInvoiceOptions = {
        title: {text: ''},
        legend: {enabled: false},
        chartOptions: {
            bar: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true
                },
                showInLegend: true
            }
        },
        tooltip: {
            formatter: function() {
              return this.x +': <b>'+ this.y +'</b>';
            }
        },
        xAxis: {
            categories: categoriesInvoiceArr,
            title:{
                text:xAxisInvoiceTitle
            }
        },
        yAxis: {
            visible: false
        },
        series: [{
            type: 'column',
            data: generateChartDataWithGradient(barChartInvoiceDataArr),
            linearGradient: {x1: 0,x2: 0,y1: 0,y2: 1},
            stops: [[0, '#2980b9'],[1, '#6DD5FA'],[2, '#ffffff']]
        }]
    }

    // bar chart payment default data
    const barChartPaymentOptions = {
        title: {text: ''},
        legend: {enabled: false},
        chartOptions: {
            bar: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true
                },
                showInLegend: true
            }
        },
        tooltip: {
            formatter: function() {
              return this.x +': <b>'+ this.y +'</b>';
            }
        },
        xAxis: {
            categories: categoriesPaymentArr,
            title:{
                text:xAxisPaymentTitle
            }
        },
        yAxis: {
            visible: false
        },
        series: [{
            type: 'column',
            data: generateChartDataWithGradient(barChartPaymentDataArr),
            linearGradient: {x1: 0,x2: 0,y1: 0,y2: 1},
            stops: [[0, '#2980b9'],[1, '#6DD5FA'],[2, '#ffffff']]
        }]
    }

    return (
        <>
            <div className={`right-section-wrapper has-white-bg ${dashboardWrapper}`}>
                <div className="row mb-3">
                    <div className="col-sm-3">
                        <div className={`d-flex align-center ${blockWrapper} ${blueTiles}`}>
                            <i className="fa fa-car"></i>
                            <div className={`${rightBlock}`}>
                                <h1>{carCount}</h1>
                                <p>Car Count</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className={`d-flex align-center ${blockWrapper} ${orangeTiles}`}>
                            <img src={serviceImg} alt=""></img>
                            <div className={`${rightBlock}`}>
                                <h1>{serviceDueCount}</h1>
                                <p>Service Due</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className={`d-flex align-center ${blockWrapper} ${purpleTiles}`}>
                            <i className="fa fa-users"></i>
                            <div className={`${rightBlock}`}>
                                <h1>{customerCount}</h1>
                                <p>Customer</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className={`d-flex align-center ${blockWrapper} ${greenTiles}`}>
                            <i className="fa fa-cloud-download" aria-hidden="true"></i>
                            <div className={`${rightBlock}`}>
                                <h1>{appDownloadCount}</h1>
                                <p>App Download</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 mb-3">
                        <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                            <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                                <span>Jobs | {totalJobCount} </span>
                                <DropdownButton className={`d-flex align-center justify-space-between dropdown-btn custom-dropdown-list ${DropdownBtn}`} title={<i className="la la-bars"></i>}>
                                    <Dropdown.Item onClick={() => barChartClick('job','day')}>Day</Dropdown.Item>
                                    <Dropdown.Item onClick={() => barChartClick('job','week')}>Week</Dropdown.Item>
                                    <Dropdown.Item onClick={() => barChartClick('job','month')}>Month</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className={`${chartBlock}`}>
                                <HighchartsReact
                                    containerProps={{ style: { height: "300px", width: '300px' } }}
                                    highcharts={Highcharts}
                                    options={barChartOptions}
                                    allowChartUpdate = {true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-3">
                        <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                            <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                                <span>INVOICE | ₹{totalInvoiceCount} </span>
                                <DropdownButton className={`d-flex align-center justify-space-between dropdown-btn custom-dropdown-list ${DropdownBtn}`} title={<i className="la la-bars"></i>}>
                                    <Dropdown.Item onClick={() => barChartClick('invoice','day')}>Day</Dropdown.Item>
                                    <Dropdown.Item onClick={() => barChartClick('invoice','week')}>Week</Dropdown.Item>
                                    <Dropdown.Item onClick={() => barChartClick('invoice','month')}>Month</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className={`${chartBlock}`}>
                                <HighchartsReact
                                    containerProps={{ style: { height: "300px", width: '300px' } }}
                                    highcharts={Highcharts}
                                    options={barChartInvoiceOptions}
                                    allowChartUpdate = {true}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-3">
                        <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                            <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                                <span>PAYMENTS | ₹{totalPaymentCount} </span>
                                <DropdownButton className={`d-flex align-center justify-space-between dropdown-btn custom-dropdown-list ${DropdownBtn}`} title={<i className="la la-bars"></i>}>
                                    <Dropdown.Item onClick={() => barChartClick('payment','day')}>Day</Dropdown.Item>
                                    <Dropdown.Item onClick={() => barChartClick('payment','week')}>Week</Dropdown.Item>
                                    <Dropdown.Item onClick={() => barChartClick('payment','month')}>Month</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className={`${chartBlock}`}>
                                <HighchartsReact
                                    containerProps={{ style: { height: "300px", width: '300px' } }}
                                    highcharts={Highcharts}
                                    options={barChartPaymentOptions}
                                    allowChartUpdate = {true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-3">
                        <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                            <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                                <span>BOOK IN SOURCE </span>
                            </div>
                            <div className={`${chartBlock}`}>
                                <HighchartsReact
                                    containerProps={{ style: { height: "200px" } }}
                                    highcharts={Highcharts}
                                    options={pieChartOptions}
                                    allowChartUpdate = {true}
                                />
                                <ul className='d-flex justify-center'>
                                    <li>
                                        <h3>{inAppCount}</h3>
                                        <label>In App</label>
                                    </li>
                                    <li>
                                        <h3>{webCount}</h3>
                                        <label>Website</label>
                                    </li>
                                    <li>
                                        <h3>0</h3>
                                        <label>Social Media</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-3">
                        <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                            <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                                <span>CUSTOMER SURVEY</span>
                            </div>
                            <div className={`${ratingOuter}`}>
                                <h1 className="mb-2">{starAvgTotal}</h1>
                                <div className="mb-2">
                                    <StarRatings
                                        rating={starAvgTotal}
                                        starRatedColor="#ffce00"
                                        starDimension="25px"
                                        starSpacing="0px"
                                    />
                                </div>
                                <h4 className="mb-2">Based on {starTotal} ratings</h4>
                                <div className={`${ratingWrapper}`}>
                                    <div className={`d-flex align-center ${ratingBlock}`}>
                                        <label className="m-r-10">5 Star</label>
                                        <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={starPercentFive} />
                                        <span>{starFive}</span>
                                    </div>
                                    <div className={`d-flex align-center ${ratingBlock}`}>
                                        <label className="m-r-10">4 Star</label>
                                        <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={starPercentFour} />
                                        <span>{starFour}</span>
                                    </div>
                                    <div className={`d-flex align-center ${ratingBlock}`}>
                                        <label className="m-r-10">3 Star</label>
                                        <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={starPercentThree} />
                                        <span>{starThree}</span>
                                    </div>
                                    <div className={`d-flex align-center ${ratingBlock}`}>
                                        <label className="m-r-10">2 Star</label>
                                        <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={starPercentTwo} />
                                        <span>{starTwo}</span>
                                    </div>
                                    <div className={`d-flex align-center ${ratingBlock}`}>
                                        <label className="m-r-10">1 Star</label>
                                        <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={starPercentOne} />
                                        <span>{starOne}</span>
                                    </div>
                                </div>
                                <Button className="btn-primary rounded-btn height-34" onClick={() => {navigate("/survey-marketing")}}>
                                    See Comments
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-3">
                        <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                            <Carousel className={`custom-slider ${customSlider}`}>
                                {
                                    newsFeedData !== null && (
                                        newsFeedData.map((news,index) => {
                                            description = news.description;
                                            return (<Carousel.Item className={`${carouselItem}`}>
                                                <img
                                                    className="d-block w-100"
                                                    src={NoResult}
                                                    alt="First slide"
                                                />
                                                <Carousel.Caption>
                                                    <h3 className="text-success">{news.title}</h3>
                                                    <p className="date">{moment(news.news_date).format("DD MMMM YYYY")} </p>
                                                    <p className="title">
                                                        {
                                                            (description).length > 50 ? (
                                                                (description.replace( /(<([^>]+)>)/ig, '')).substring(0, 50)
                                                            ): description.replace( /(<([^>]+)>)/ig, '')
                                                        }
                                                        </p>
                                                    <Button className="view-all" onClick={()=>navigate('/news_list')}>
                                                        View All
                                                    </Button>
                                                </Carousel.Caption>
                                            </Carousel.Item>)
                                        })
                                    )
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard;
