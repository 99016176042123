export const SAVE_PART = "SAVE_PART";
export const SAVE_PART_SUCCESS = "SAVE_PART_SUCCESS";
export const SAVE_PART_FAIL = "SAVE_PART_FAIL";

export const EDIT_PART = "EDIT_PART";
export const EDIT_PART_SUCCESS = "EDIT_PART_SUCCESS";
export const EDIT_PART_FAIL = "EDIT_PART_FAIL";

export const PART_DETAIL = "PART_DETAIL";
export const PART_DETAIL_SUCCESS = "PART_DETAIL_SUCCESS";
export const PART_DETAIL_FAIL = "PART_DETAIL_FAIL";

export const SAVE_PART_REACT = "SAVE_PART_REACT";
export const SAVE_PART_REACT_SUCCESS = "SAVE_PART_REACT_SUCCESS";
export const SAVE_PART_REACT_FAIL = "SAVE_PART_REACT_FAIL";

export const EDIT_PART_REACT = "EDIT_PART_REACT";
export const EDIT_PART_REACT_SUCCESS = "EDIT_PART_REACT_SUCCESS";
export const EDIT_PART_REACT_FAIL = "EDIT_PART_REACT_FAIL";

export const PART_VIEW = "PART_VIEW";
export const PART_VIEW_SUCCESS = "PART_VIEW_SUCCESS";
export const PART_VIEW_FAIL = "PART_VIEW_FAIL";

export const GET_PART_LIST_NEW = "GET_PART_LIST_NEW";
export const GET_PART_LIST = "GET_PART_LIST";
export const GET_PART_LIST_SUCCESS = "GET_PART_LIST_SUCCESS";
export const GET_PART_LIST_FAIL = "GET_PART_LIST_FAIL";
export const CLEAR_PART_LIST = "CLEAR_PART_LIST";

export const GET_PART_LIST_COUNT = "GET_PART_LIST_COUNT";
export const GET_PART_LIST_COUNT_SUCCESS = "GET_PART_LIST_COUNT_SUCCESS";
export const GET_PART_LIST_COUNT_FAIL = "GET_PART_LIST_COUNT_FAIL";

export const GET_PART_CATEGORY_LIST = "GET_PART_CATEGORY_LIST";
export const GET_PART_CATEGORY_LIST_SUCCESS = "GET_PART_CATEGORY_LIST_SUCCESS";
export const GET_PART_CATEGORY_LIST_FAIL = "GET_PART_CATEGORY_LIST_FAIL";

export const SEARCH_PART_LIST = "SEARCH_PART_LIST";
export const SEARCH_PART_LIST_SUCCESS = "SEARCH_PART_LIST_SUCCESS";
export const SEARCH_PART_LIST_FAIL = "SEARCH_PART_LIST_FAIL";

export const GET_KIT_PART_LIST = "GET_KIT_PART_LIST";
export const GET_KIT_PART_LIST_SUCCESS = "GET_KIT_PART_LIST_SUCCESS";
export const GET_KIT_PART_LIST_FAIL = "GET_KIT_PART_LIST_FAIL";

export const GET_KIT_PART_LIST_COUNT = "GET_KIT_PART_LIST_COUNT";
export const GET_KIT_PART_LIST_COUNT_SUCCESS =
  "GET_KIT_PART_LIST_COUNT_SUCCESS";
export const GET_KIT_PART_LIST_COUNT_FAIL = "GET_KIT_PART_LIST_COUNT_FAIL";

export const SEARCH_KIT_PART_LIST = "SEARCH_KIT_PART_LIST";
export const SEARCH_KIT_PART_LIST_SUCCESS = "SEARCH_KIT_PART_LIST_SUCCESS";
export const SEARCH_KIT_PART_LIST_FAIL = "SEARCH_KIT_PART_LIST_FAIL";

export const DELETE_PART = "DELETE_PART";
export const DELETE_PART_SUCCESS = "DELETE_PART_SUCCESS";
export const DELETE_PART_FAIL = "DELETE_PART_FAIL";

export const DELETE_KIT_PART = "DELETE_KIT_PART";
export const DELETE_KIT_PART_SUCCESS = "DELETE_KIT_PART_SUCCESS";
export const DELETE_KIT_PART_FAIL = "DELETE_KIT_PART_FAIL";

export const ADD_PART_TO_LIST = "ADD_PART_TO_LIST";
export const UPDATE_PART = "UPDATE_PART";
export const REMOVE_PART_FROM_LIST = "REMOVE_PART_FROM_LIST";
export const REMOVE_PART_FROM_KIT_LIST = "REMOVE_PART_FROM_KIT_LIST";
export const REMOVE_PO_PART_FROM_LIST = "REMOVE_PO_PART_FROM_LIST";

export const TEST_PART = "TEST_PART";
export const TEST_PART_SUCCESS = "TEST_PART_SUCCESS";

export const ADD_LABOUR = "ADD_LABOUR";
export const UPDATE_LABOUR = "UPDATE_LABOUR";
export const UPDATE_LABOUR_DATA = "UPDATE_LABOUR_DATA";
export const DELETE_LABOUR_FROM_LIST = "DELETE_LABOUR_FROM_LIST";

export const UPDATE_SUMMARY = "UPDATE_SUMMARY";

export const ADD_INITIAL_PARTS = "ADD_MULTIPLE_PARTS";
export const ADD_INITIAL_LABOURS = "ADD_MULTIPLE_LABOURS";

export const RESET_PARTS = "RESET_PARTS";
export const RESET_LABOURS = "RESET_LABOURS";
export const RESET_SUMMARY = "RESET_SUMMARY";
export const UPDATE_PARTS_ASSIGNED = "UPDATE_PARTS_ASSIGNED";
export const UPDATE_PARTS_PO_QTY = "UPDATE_PARTS_PO_QTY";
