import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Scheduler = Loadable({
  loader: () => import("./components/Scheduler"),
  loading: Loading,
});

export const routes = [
  {
    path: "/scheduler",
    exact: true,
    name: "scheduler",
    element: Scheduler,
  },
  {
    path: "/scheduler_week",
    exact: true,
    name: "scheduler",
    element: Scheduler,
  },
  {
    path: "/scheduler_month",
    exact: true,
    name: "scheduler",
    element: Scheduler,
  },
  {
    path: "/scheduler/:vehicle_id",
    exact: true,
    name: "scheduler",
    element: Scheduler,
  },
];

export * from "./components";
