import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import DeleteConfirm from "../../../../../utils/deleteConfirm";
import {
  DELETE_CUSTOM_VEHICLE_DETAILS,
  EDIT_CUSTOM_VEHICLE_DETAILS,
  GET_CUSTOM_VEHICLE_LIST,
} from "../../../../../store/defaultSettings/actionTypes";

const AssetTypeList = () => {
  const dispatch = useDispatch(); // Dispatch variable
  const { customVehicles } = useSelector(
    (state) => state.defaultSettingsReducer
  ); // GET Form loader from Company Detail Reducer data

  const { SearchBar } = Search;
  const [customVehiclesData, setCustomVehiclesData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [deleteLabourId, setDeleteLabourId] = useState();
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const [emptyMessage,setEmptyMessage] = useState("No data available in table");

  const handleDeleteClose = () => setShowDeleteModal(false);

  // GET labour charge details for edit
  const editCustomVehicleDetails = (charge) => {
    dispatch({
      type: EDIT_CUSTOM_VEHICLE_DETAILS,
      payload: {
        get: "all",
        conditions: {
          id: charge.id,
        },
      },
    });
  };

  //delete job labour confirmation
  async function deleteCustomVehicleConfirm(row_id) {
    setDeleteLabourId(row_id);
    setShowDeleteModal(true);
  }

  // delete labour charge
  const deleteRecord = async () => {
    dispatch({
      type: DELETE_CUSTOM_VEHICLE_DETAILS,
      payload: {
        id: deleteLabourId,
      },
    });

    setShowDeleteModal(false);
  };

  const sorting = (order, column) => {
    if (!order)
      return (
        <span className="ps-2">
          <i className="fa fa-sort" aria-hidden="true"></i>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ps-2">
          <i className="fa fa-sort-asc" aria-hidden="true"></i>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="ps-2">
          <i className="fa fa-sort-desc" aria-hidden="true"></i>
        </span>
      );
    return null;
  };

  const columns = [
    {
      dataField: "label",
      text: "Name",
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "vehicle_type",
      text: "Asset Type",
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "field_type",
      text: "Field Type",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {row.field_type === "1"
              ? "Text"
              : row.field_type === "2"
              ? "Date"
              : "List"}
          </div>
        );
      },
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },

    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <button
                  onClick={() => {
                    editCustomVehicleDetails(row);
                  }}
                  type="button"
                  className="w-100"
                >
                  <span>
                    <i className="fa fa-pencil text-info"></i>
                  </span>
                  <span>Edit</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => deleteCustomVehicleConfirm(row.id)}
                  type="button"
                  variant="danger"
                  className="delete-link w-100"
                >
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </button>
              </li>
            </div>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch({
      type: GET_CUSTOM_VEHICLE_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: window.atob(storedData.workshop_id),
        },
        // limit: sizePerPage,
      },
    });
  }, []);
  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
    loadData(page, sizePerPage);
  };
  const loadData = (page = 1, pageLimit = 10) => {
    dispatch({
      type: GET_CUSTOM_VEHICLE_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: window.atob(storedData.workshop_id),
        },
        // limit: sizePerPage,
      },
    });
  };
  useEffect(() => {
    if (customVehicles) {
      setCustomVehiclesData(customVehicles);
    }
  }, [customVehicles]);

  const emptyDataMessage = () => {
    return (
      <div className= {(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  const MySearch = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
      setEmptyMessage("No Results Found")
    };
    return (
      <div>
        <input
          className="form-control"
          ref={ n => input = n }
          type="text"
          id="search"
          placeholder="Search..."
          onChange={ handleClick }
        />
      </div>
    );
  };

  return (
    <>
      <div>
        <ToolkitProvider
          keyField="id"
          data={customVehiclesData}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="table-search-wrapper ">
                <MySearch {...props.searchProps} />
              </div>
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage,
                  totalSize: customVehiclesData.length, //levelsCount,
                  nextPageText: "Next",
                  prePageText: "Previous",
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      keyField="id"
                      data={customVehiclesData}
                      columns={columns}
                      onTableChange={onTableChange}
                      noDataIndication={emptyDataMessage}
                      {...paginationTableProps}
                      {...props.baseProps}
                    />
                    <div>
                      <div className="table-pagination">
                        <div>
                          <span>Show</span>
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          <span>entries</span>
                        </div>
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            </div>
          )}
        </ToolkitProvider>
      </div>
      {showDeleteModal && (
        <DeleteConfirm
          handleDeleteClose={handleDeleteClose}
          deleteRecord={deleteRecord}
          msg={"Delete this record?"}
        />
      )}
    </>
  );
};

export default AssetTypeList;
