import { getInvoicesCredits } from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";

export async function getInvoiceCreditedViewDetail(id) {
  let invoice,
    customer,
    job_detail,
    invoices_payments_credit,
    invoices_payments_credits_other,
    all_credits;

  const request1 = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCreditsOtherDetails.id": id,
    },
    contain: {
      0: "Workshops",
      1: "InvoicesPaymentsCredits.Invoices.JobSchedules.Vehicles.Users.States",
      2: "InvoicesPaymentsCredits.Invoices.JobSchedules.CreatedByUser",
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Vehicles.Users.CustomersDetails":
        {
          conditions: {
            workshop_id: getWorkshopId(),
          },
        },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Customers.CustDetails":
      {
        conditions: {
          "CustDetails.workshop_id": getWorkshopId(),
        },
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Customers.CustDetails.Countries":{
        "fields" : ["id","country_name"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Customers.CustDetails.States":{
          "fields" : ["id","name"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.InsuranceCompany.States":{
        "fields" : ["id","name"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.InsuranceCompany.Countries":{
          "fields" : ["id","country_name"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.InsuranceCompany":{
          "fields" : ["id","company_name","contact_name","phone_number","email","website","street_address","country","state","postcode","suburb","abn","acn"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.InvoiceCCustomer": {
        "fields" : ["id","firstname","lastname","mobile_number","mobile_code","type","company_name","user_id","country","postcode","state","street_address","suburb"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.InvoiceCCustomer.Users": {
        "fields" : ["id","email"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.InvoiceCCustomer.Countries":{
        "fields" : ["id","country_name"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.InvoiceCCustomer.States":{
          "fields" : ["id","name"]
      },
    },
  };

  const get_invoices = await getInvoicesCredits({ payload: request1 });

  if (get_invoices?.code === 200) {
    invoices_payments_credits_other =
      get_invoices?.data?.InvoicesPaymentsCreditsOtherDetails[0];
    invoices_payments_credit =
      invoices_payments_credits_other?.invoices_payments_credit;
    invoice = invoices_payments_credit?.invoice;
    job_detail = invoice?.job_schedule;
    // customer = job_detail?.vehicle?.user;
    customer = job_detail?.customer;
    
    const request2 = {
      get: "all",
      conditions: {
        "InvoicesPaymentsCreditsOtherDetails.invoice_payment_credit_id":
          invoices_payments_credits_other?.invoice_payment_credit_id,
      },
    };

    const get_invoices_credits = await getInvoicesCredits({
      payload: request2,
    });
    if (get_invoices_credits?.code === 200) {
      all_credits =
        get_invoices_credits?.data?.InvoicesPaymentsCreditsOtherDetails;
    }
  }

  return {
    invoice,
    customer,
    job_detail: job_detail,
    invoices_payments_credit,
    invoices_payments_credits_other,
    all_credits,
  };
}
