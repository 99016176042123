import React from "react";

export const PartListContext = React.createContext(null);

const PartListProvider = ({value,children}) => {

  return <PartListContext.Provider value={value}>{children}</PartListContext.Provider>

}

export default PartListProvider;