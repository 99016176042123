import React,{useState} from "react";
import { Button, Modal } from 'react-bootstrap';
import leadImg from "../../../src/assets/images/lead-image.png";
import { useNavigate } from "react-router-dom";

const LeadNotification = ({leadReceivedShow,handleLeadReceivedClose,handleLeadReceivedShow,leadNotificationData}) => {
    
    const navigate = useNavigate(); // Navigate URL
    const viewLead = () => {
        navigate("/leads_list");
        handleLeadReceivedClose();
    }
    return (
        <>
            {/*  Lead Received dialog */}
            <Modal
                className="Lead-received-dialog"
                size="sm"
                show={leadReceivedShow}
                onHide={handleLeadReceivedClose}
            >
                <Modal.Body>
                    <div className="d-flex justify-flex-end">
                        <i onClick={handleLeadReceivedClose} class="la la-times font-size-20 close-icon mb-2"></i>
                    </div>
                    <div className="content-block d-flex">
                        <img className="img-block" src={leadImg}></img>
                        <div className='right-block'>
                            <h4>{leadNotificationData.lead_count}</h4>
                            <p className="mb-3">New Lead Received.</p>
                            <Button
                                className="btn-outline rounded-btn height-34"
                                variant="outline"
                                onClick={() => viewLead()}
                            >
                                View
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}
export default LeadNotification;