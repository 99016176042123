import {
  getUserProfile,
  getCustomerLists,
  addCustomer,
  updateCustomer,
  addUserLogin,
  addAssets,
  getOnlineJobs,
  addOnlineJobs,
  sendLeadNotification,
  sendLeadNotificationWorkshop,
  getCustomerAndVehicleDetails,
  updateVehicle,
} from "helpers/backendHelper";
import crypto from "crypto";
import moment from "moment";
import { timeZoneToUTC } from "utils/timeZoneConvert";

export async function createOnlineJob(request) {
  let user_id,
    customer_id,
    email = request.email,
    mobile = request.mobile,
    vehicle_id,
    status = 0;
  let { workshop_url, workshop_id } = request;
  let userData;
  let customerDetails;
  let lead_number = 1;
  //Email not provided - create new email for user

  if (!email) {
    const request1 = {
      get: "all",
      conditions: {
        workshop_id: workshop_id,
        "email LIKE": "%newcustomer-%",
      },
      order: {
        id: "DESC",
      },
      fields: ["id", "email"],
    };

    const resp = await getUserProfile({ payload: request1 });

    if (resp?.code === 200) {
      let emailData = resp?.data?.Users[0];
      //newcustomer-324@wertyu.fiiviq.com
      const [one, two] = emailData.email.split("-"); // [newcustomer, 324@wertyu.fiiviq.com]
      const [number] = two.split("@"); //[324];
      email = `newcustomer-${+number + 1}@${workshop_url}`;
    }
  } else {
    let getUserRequest = {
      get: "all",
      conditions: {
        email: email,
        is_deleted: 0,
        workshop_id: workshop_id,
      },
      contain: {
        CustomersDetails: {
          conditions: {
            "CustomersDetails.workshop_id": workshop_id,
          },
          fields: [
            "CustomersDetails.id",
            "CustomersDetails.user_id",
            "CustomersDetails.workshop_id",
          ],
        },
      },
      fields: ["Users.id", "Users.workshop_id", "Users.email"],
    };
    //Check userExist by email
    const userDataResponse = await getUserProfile({ payload: getUserRequest });
    if (userDataResponse?.code === 200) {
      userData = userDataResponse?.data?.Users[0];
      // debugger;
      user_id = userData?.id;
      customer_id = userData?.customers_details[0]?.id;
    }
  }
  if (!userData) {
    //Check userExist by mobile number
    let requestUser = {
      get: "all",
      conditions: {
        "CustomersDetails.mobile_number": mobile,
        "CustomersDetails.is_deleted": 0,
        "CustomersDetails.workshop_id": workshop_id,
      },
      contain: {
        Users: {
          fields: ["Users.id", "Users.workshop_id", "Users.email"],
        },
      },
      fields: [
        "CustomersDetails.id",
        "CustomersDetails.workshop_id",
        "CustomersDetails.user_id",
        "CustomersDetails.mobile_number",
      ],
    };

    const userDataResponse = await getCustomerLists({ payload: requestUser });
    if (userDataResponse?.code == 200) {
      userData = userDataResponse?.data?.CustomersDetails[0];
      // debugger;
      user_id = userData?.user_id;
      customer_id = userData?.id;
      email = userData?.user?.email;
    }
  }

  if (mobile && mobile.indexOf("0") === 0) {
    mobile = mobile.substring(1);
  }

  if (!userData) {
    //Still not found user data. Add new user
    let password = crypto.randomBytes(5).toString("hex");
    const createUserRequest = {
      workshop_id: workshop_id,
      role_id: 4,
      email: email,
      country: request.country,
      state: request.state,
      password: password,
      customers_details: [
        {
          workshop_id: workshop_id,
          firstname: request.first_name,
          lastname: request.last_name,
          mobile_number: mobile,
          country: request.country,
          state: request.state,
          type: 2,
        },
      ],
    };

    const create_customer = await addCustomer({ payload: createUserRequest });
    if (create_customer?.code === 200) {
      customerDetails = create_customer?.data?.Users;
      if (customerDetails?.id) {
        // debugger;
        user_id = customerDetails?.id;
      } else {
        // debugger;
        user_id = customerDetails?.customers_details[0]?.user_id;
      }
      customer_id = customerDetails?.customers_details[0]?.id;
      email = customerDetails?.email;
    }
  } else {
    if (!customer_id) {
      const requestUpdate = {
        workshop_id,
        firstname: request.first_name,
        lastname: request.last_name,
        mobile_number: mobile,
        country: request.country,
        state: request.state,
        type: 2,
        user_id,
      };
      await updateCustomer({
        payload: requestUpdate,
      });
    } else {
      const requestUpdate = {
        id: customer_id,
        firstname: request["first_name"],
        lastname: request["last_name"],
        mobile_number: mobile,
        company_name: request["company"],
      };

      await updateCustomer({
        payload: requestUpdate,
      });
    }
  }
  if (user_id) {
    const re1 = {
      workshop_id: workshop_id,
      status: status,
      user_id: user_id,
    };
    await addUserLogin({ payload: re1 });

    const vehicleAddRequest = {
      rego: request["rego"],
      make: request["make"],
      model: request["model"],
      year: request["year"],
      vehicle_type: "Vehicle",
      workshop_id,
      user_id,
      odometer_reading: request["mileage"],
      is_verified: request["is_verified"],
    };


    let payload = {
      payload: {
        get: "all",
        conditions: {
          "Vehicles.workshop_id": workshop_id,
          "Vehicles.rego": request["rego"]
        },
        fields: [
          "Vehicles.id",
          "Vehicles.rego",
          "Vehicles.workshop_id"
        ],
      },
    };
    const vehicleResponse = await getCustomerAndVehicleDetails(payload);

    if (vehicleResponse?.code === 200) {
      vehicleAddRequest['id'] = vehicleResponse?.data?.Vehicles[0]?.id;
      vehicle_id = vehicleResponse?.data?.Vehicles[0]?.id;

      await updateVehicle({ payload: vehicleAddRequest });

    } else {
      const vehicle_add = await addAssets({ payload: vehicleAddRequest });

      if (vehicle_add?.code === 200) {
        vehicle_id = vehicle_add?.data?.Vehicles?.id;
      }
    }

    const re2 = {
      get: "all",
      conditions: {
        workshop_id: workshop_id,
      },
      fields: ["id", "lead_number"],
    };
    const get_leads = await getOnlineJobs({ payload: re2 });

    if (get_leads?.code === 200) {
      let leads = get_leads?.data?.OnlineSchedule.pop();
      lead_number = leads?.lead_number + 1;
    }
    // debugger;
    const start_time = timeZoneToUTC(
      request["selectedTime"],
      request["zone"],
      "hh:mm a"
    );
    const end_time = moment(start_time, "HH:mm:ss")
      .add({
        minutes: +request["duration"],
      })
      .format("HH:mm:ss");
    const jobDetailRequest = {
      user_id: user_id,
      vehicle_id: vehicle_id,
      workshop_id: workshop_id,
      lead_number: lead_number,
      job_type: request["job_type_id"],
      job_parent_type: request["job_parent_type"],
      firstname: request["first_name"],
      lastname: request["last_name"],
      company_name: request["company"],
      mobile_number: mobile,
      email: email,
      make: request["make"],
      model: request["model"],
      year: request["year"],
      rego: request["rego"],
      vehicle_description: request["description"],
      lead_script: request["description"],
      mileage: request["mileage"],
      // from_date: moment(request["selectedDate"], "DD-MM-YYY").format(
      //   "YYYY-MM-DD"
      // ),
      // to_date: moment(request["selectedDate"], "DD-MM-YYY").format(
      //   "YYYY-MM-DD"
      // ),
      from_date: request["from_date"],
      to_date: request["to_date"],
      from_time: start_time,
      to_time: end_time,
      duration: request["duration"],
    };

    const job_create = await addOnlineJobs({ payload: jobDetailRequest });

    if (job_create?.code === 200) {
      let data = job_create?.data?.OnlineSchedule;

      let re3 = {
        send_method: "sms",
        message: "Booking request submitted we'll be in touch to confirm!",
        to: mobile,
        name: request["first_name"] + " " + request["last_name"],
        workshop_id: workshop_id,
        title: "Online Booking",
        type: "online_job_booking",
        online_schedule_id: data?.id,
        mobile: mobile,
      };

      if (request.email) {
        re3.send_method = "email";
        re3.email_message = "Thanks for your booking request.You will receive confirmation when your booking is confirmed.";
        re3.to = request.email;
        re3.email = request.email;
      }

      sendLeadNotification({
        payload: re3,
      });
      sendLeadNotificationWorkshop({ payload: re3 });
      return true;
    }
  }
  return false;
}
