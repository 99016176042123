export const GET_SECURITY_GROUP_DETAILS = "GET_SECURITY_GROUP_DETAILS";
export const GET_SECURITY_GROUP_DETAILS_SUCCESS =
  "GET_SECURITY_GROUP_DETAILS_SUCCESS";
export const GET_SECURITY_GROUP_DETAILS_FAIL =
  "GET_SECURITY_GROUP_DETAILS_FAIL";

export const ADD_SECURITY_GROUP_DETAILS = "ADD_SECURITY_GROUP_DETAILS";
export const ADD_SECURITY_GROUP_DETAILS_SUCCESS =
  "ADD_SECURITY_GROUP_DETAILS_SUCCESS";
export const ADD_SECURITY_GROUP_DETAILS_FAIL =
  "ADD_SECURITY_GROUP_DETAILS_FAIL";

export const UPDATE_SECURITY_GROUP_DETAILS = "UPDATE_SECURITY_GROUP_DETAILS";
export const UPDATE_SECURITY_GROUP_DETAILS_SUCCESS =
  "UPDATE_SECURITY_GROUP_DETAILS_SUCCESS";
export const UPDATE_SECURITY_GROUP_DETAILS_FAIL =
  "UPDATE_SECURITY_GROUP_DETAILS_FAIL";


export const LOAD_SECURITY_GROUP = 'LOAD_SECURITY_GROUP';
export const LOAD_SECURITY_GROUP_SUCCESS = 'LOAD_SECURITY_GROUP_SUCCESS';
export const LOAD_SECURITY_GROUP_FAIL = 'LOAD_SECURITY_GROUP_FAIL';