import { addEmployeeClockingHistory, updateEmployeeClockingHistory } from "helpers/backendHelper";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_USERS, GET_USERS_TECHNICIAN } from "store/accountSync/actionTypes";
import { addEmployeeClockingHistorySuccess, getUsersTechnicianSuccess, updateEmployeeClockingHistorySuccess } from "store/accountSync/actions";
import { showError, showSuccess } from "utils/alertMessage";
import { getSessionData } from "utils/getWorkshopId";
import { unserializeArr } from "utils/unserialize";
import Loader from "views/common/UI/Loader";
import TechnicianList from "./technicianList";
import TimeClocking from "./timeClocking";
import TimeClockingDisabled from "./timeClockingDisabled";
import TimeLog from "./timeLog";
import { convertToHours, timeZoneToUTC } from "utils/timeZoneConvert";

let ClockingContent = ({ fromDate, currentDay }) => {

    const dispatch = useDispatch();
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);
    let timezoneLable = window.atob(storedData.timezone_lable);

    const todayDate = moment().format('YYYY-MM-DD');

    const [allTechnicianList, setAllTechnicianList] = useState([]);
    const [activeTechnician, setActiveTechnician] = useState("");
    const [activeUserName, setActiveUserName] = useState("");

    const [timeLogData, setTimeLogData] = useState([]);
    const [lastTimeLogId, setLastTimeLogId] = useState("");
    const [lastActivity, setLastActivity] = useState("Finish Time");


    const [activityMessage, setActivityMessage] = useState("");

    // userdetail data already dispatch in job clocking tab
    const { loadingLogins, technicianDetails } = useSelector((state) => state.AccountSyncReducer);


    useEffect(() => {
        loadClockingHistory();

        return () => {
            dispatch(getUsersTechnicianSuccess(null));
            setActiveTechnician("");
            setActiveUserName("");
        };

    }, [fromDate]);

    function loadClockingHistory() {

        let current_time = moment().utc().format('HH:mm');

        let local_start_time = fromDate + " 00:00:00";
        let local_end_time = fromDate + " 23:59:59";

        const utc_start_time = timeZoneToUTC(
            local_start_time,
            timezoneLable,
            "YYYY-MM-DD HH:mm:ss",
            "YYYY-MM-DD HH:mm:ss"
        );

        const utc_end_time = timeZoneToUTC(
            local_end_time,
            timezoneLable,
            "YYYY-MM-DD HH:mm:ss",
            "YYYY-MM-DD HH:mm:ss"
        );

        dispatch({
            type: GET_USERS_TECHNICIAN,
            payload: {
                get: "all",
                conditions: {
                    "workshop_id": workshop_id,
                    "role_id IN": [3, 7, 8, 9, 10],
                    "is_deleted": 0
                },
                contain: {
                    "EmployeesDetails": {
                        fields: [
                            "EmployeesDetails.id",
                            "EmployeesDetails.user_id",
                            "EmployeesDetails.shift_working_day",
                            "EmployeesDetails.workshop_id"
                        ]
                    },
                    "EmployeeClockingHistory": {
                        conditions: {
                            "EmployeeClockingHistory.created_on >= ": utc_start_time,
                            "EmployeeClockingHistory.created_on <= ": utc_end_time,
                            "EmployeeClockingHistory.is_deleted =": "0"
                        }
                    },
                    "JobSchedules": {
                        conditions: {
                            "JobSchedules.from_date >= ": fromDate + " 00:00:00",
                            "JobSchedules.from_date <= ": fromDate + " 23:59:59",
                            "JobSchedules.from_time < ": current_time,
                            "JobSchedules.to_time > ": current_time,
                            "JobSchedules.activity_schedule_id > ": 0,
                            "JobSchedules.status": 1,
                            "JobSchedules.is_deleted": 0,
                        },
                        fields: ["id", "user_id", "activity_schedule_id", "from_date", "from_time", "to_time"]
                    }
                },
                fields: ["id", "workshop_id", "firstname", "lastname", "role_id", "user_profile"]
            }
        })
    }

    useEffect(() => {
        if (technicianDetails !== null && technicianDetails !== undefined) {

            var users = technicianDetails;

            var employeeWorkingDay = {};

            users.map((user, index) => {
                user['lastActivity'] = "";
                if (user?.employee_clocking_history.length > 0) {
                    user['lastActivity'] = user?.employee_clocking_history[user?.employee_clocking_history?.length - 1]?.activity;
                }

                if (user.employees_details[0] !== "" && user.employees_details[0] !== undefined && user.employees_details[0] !== null) {
                    if (user.employees_details[0].shift_working_day) {
                        employeeWorkingDay = unserializeArr(user.employees_details[0].shift_working_day);
                    }
                }

                if (user?.job_schedules?.length > 0) {
                    user['isAway'] = 1;
                } else {
                    user['isAway'] = 0;
                }


                user['workingDays'] = employeeWorkingDay;
                return user;
            });

            setAllTechnicianList(users);
            if (activeTechnician === "") {
                setActiveTechnician(users?.[0]?.id);
                setActiveUserName(users?.[0]?.firstname + " " + users?.[0]?.lastname);
            }

            // const userTimeLog = technicianDetails.filter((c) => c.id === users?.[0]?.id);


        }


    }, [technicianDetails])


    useEffect(() => {
        if (activeTechnician) {

            const userTimeLog = allTechnicianList.filter((c) => c.id === activeTechnician);
            const currentTimeLog = userTimeLog[0]?.employee_clocking_history;



            if (currentTimeLog) {
                let lastArray = currentTimeLog[currentTimeLog.length - 1];
                setLastTimeLogId(lastArray?.id || "");
                setLastActivity(lastArray?.activity || "Finish Time");
            }
            setTimeLogData(userTimeLog[0]?.employee_clocking_history || "");
        }

    }, [activeTechnician]);


    useEffect(() => {
        if (activityMessage) {
            showSuccess(activityMessage);
            setActivityMessage("");
        }
    }, [activityMessage])

    // if (loadingLogins) {
    //     return <Loader />
    // }


    return (
        <>
            <div className="row d-flex mb-3">

                <TechnicianList
                    allTechnicianList={allTechnicianList}
                    setActiveTechnician={setActiveTechnician}
                    setActiveUserName={setActiveUserName}
                    activeTechnician={activeTechnician}
                    currentDay={currentDay}
                />

                {fromDate === todayDate &&
                    <TimeClocking
                        activeTechnician={activeTechnician}
                        activeUserName={activeUserName}
                        fromDate={fromDate}
                        lastActivity={lastActivity}
                        startTime={moment().utc().format('YYYY-MM-DD HH:mm:ss')}
                        onAddClockToList={async (e, cb) => {

                            let payloadData = "";
                            if (e.activity === "Finish Time") {
                                payloadData = {
                                    workshop_id: workshop_id,
                                    user_id: activeTechnician,
                                    created_by: getSessionData("user_id"),
                                    activity: e.activity,
                                    start_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                                    end_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                                    duration: "00.00",
                                }
                            } else {
                                payloadData = {
                                    workshop_id: workshop_id,
                                    user_id: activeTechnician,
                                    created_by: getSessionData("user_id"),
                                    activity: e.activity,
                                    start_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                                    end_time: "",
                                    duration: "",
                                }
                            }

                            try {

                                const data = await addEmployeeClockingHistory({ payload: payloadData });

                                if (data.code === 200) {

                                    const clockingData = data?.data?.EmployeeClockingHistory;

                                    // const indexPart = allTechnicianList.findIndex((c) => c.id === clockingData.user_id);
                                    // if (indexPart !== -1) {
                                    //     allTechnicianList[indexPart]['employee_clocking_history'].push(clockingData);
                                    //     allTechnicianList[indexPart]['lastActivity'] = clockingData?.activity;
                                    //     setLastTimeLogId(clockingData?.id || "");
                                    //     setLastActivity(clockingData?.activity || "");
                                    // }

                                    const addClocking = technicianDetails.map((user, index) => {

                                        if (user.id === clockingData.user_id) {

                                            const userhistories = user?.employee_clocking_history || [];

                                            userhistories.push(clockingData);
                                            user['lastActivity'] = clockingData?.activity;
                                            // const indexPart = userhistories.findIndex((c) => c.id === clockingData.id);
                                            // if (indexPart !== -1) {
                                            //     userhistories[indexPart] = {
                                            //     ...userhistories[indexPart],
                                            //     ...clockingData,
                                            //     };
                                            // }

                                            user['employee_clocking_history'] = userhistories;
                                            setLastTimeLogId(clockingData?.id || "");
                                            setLastActivity(clockingData?.activity || "Finish Time");
                                        }
                                        return user;
                                    });


                                    dispatch(addEmployeeClockingHistorySuccess(addClocking));
                                } else {
                                    showError("Something went wrong! Please try after sometime");
                                }

                                setActivityMessage(e?.message);

                            } catch (error) { }
                        }}
                        onUpdateClockToList={async (e, cb) => {
                            let payloadData = "";

                            // if(e?.activity){
                            //     payloadData = {
                            //         id: lastTimeLogId,
                            //         activity: e?.activity,
                            //         end_time: moment().utc().format('YYYY-MM-DD HH:mm:ss')
                            //     }
                            //     setLastActivity(e?.activity || "");
                            // } else {
                            payloadData = {
                                id: lastTimeLogId,
                                end_time: moment().utc().format('YYYY-MM-DD HH:mm:ss')
                            }
                            // }

                            try {

                                const data = await updateEmployeeClockingHistory({ payload: payloadData });

                                if (data.code === 200) {

                                    const clockingData = data?.data?.EmployeeClockingHistory;


                                    const updateClocking = technicianDetails.map((user, index) => {

                                        if (user.id === clockingData.user_id) {

                                            const userhistories = user?.employee_clocking_history || [];


                                            const indexPart = userhistories.findIndex((c) => c.id === clockingData.id);
                                            if (indexPart !== -1) {
                                                userhistories[indexPart] = {
                                                    ...userhistories[indexPart],
                                                    ...clockingData,
                                                };
                                            }

                                            user['employee_clocking_history'] = userhistories;

                                        }
                                        return user;
                                    });

                                    dispatch(updateEmployeeClockingHistorySuccess(updateClocking));
                                } else {
                                    showError("Something went wrong! Please try after sometime");
                                }


                                if (e.message) {
                                    setActivityMessage(e?.message);
                                }
                                //   cb({ main_id: data.id });
                            } catch (error) { }
                        }}
                    />
                }

                {fromDate !== todayDate &&
                    <TimeClockingDisabled
                        activeTechnician={activeTechnician}
                        activeUserName={activeUserName}
                        lastActivity={lastActivity}
                        fromDate={fromDate}
                    />
                }

                <TimeLog
                    activeTechnician={activeTechnician}
                    currentDay={currentDay}
                    fromDate={fromDate}
                    timeLogData={timeLogData}
                    todayDate={todayDate}
                />

            </div>
        </>
    );
};

export default ClockingContent;
