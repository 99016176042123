import { getWorkshopId } from "utils/getWorkshopId";
import {
  DELETE_COMPLETED_INVOICES,
  DELETE_RAISED_INVOICES,
  GET_COMPLETED_INVOICES,
  GET_INVOICE_CREDITS,
  GET_INVOICE_PAYMENTS,
  GET_RAISED_INVOICES,
  GET_INVOICES_STATEMENTS
} from "./actionTypes";

export const getRaisedInvoicePayload = (type, pageLimit, page) => {

  const workshop_id = getWorkshopId();
  let listRequest = "invoice";
  let saleInvoiceRequest = "cashsale";

  let listCount = "";
  let saleInvoiceCount = "";

  if (type == "invoice") {
    listRequest = {
      get: "all",
      conditions: {
        "Invoices.workshop_id": workshop_id,
        invoice_status: 1,
      },
      contain: {
        0: "JobSchedules.JobAttachmentList",
        // "JobSchedules.Customers.CustDetails": {
        //   conditions: {
        //     "CustDetails.workshop_id": workshop_id,
        //   },
        //   fields: [
        //     "user_id",
        //     "workshop_id",
        //     "firstname",
        //     "lastname",
        //     "type",
        //     "company_name",
        //     "mobile_number",
        //     "mobile_code",
        //     "id"
        //   ],
        // },
        "JobSchedules.Vehicles.Users.CustomersDetails": {
          conditions: {
            workshop_id: workshop_id,
          },
          fields: [
            "user_id",
            "workshop_id",
            "firstname",
            "lastname",
            "type",
            "company_name",
            "mobile_number",
            "mobile_code",
            "id"
          ],
        },
        "JobSchedules.Customers":{
          fields:["id","email"]
        },
        "JobSchedules.Customers.CustomersDetails":{
          fields:["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code","customer_app","street_address","suburb","postcode","state","country"]
        },
        "InsuranceCompany":{
          fields: ["id", "company_name", "email", "phone_number", "postcode", "suburb", "street_address"]
        },
        "JobSchedules.InvoiceCCustomer" : {
          fields: [ "user_id","workshop_id","firstname","lastname","type","company_name","mobile_number","mobile_code","id"]
        },
        "JobSchedules.InvoiceCCustomer.Users": {
          fields: [
              "id",              
              "email"
          ]
        }, 
        InvoicesPaymentsCredits: {
          fields: ["id", "invoice_id", "amount_due", "amount_paid", "balance_remaining"],
        },
        "Workshops.WorkshopOtherDetails": {
          fields: [
            "workshop_id",
            "payment_assist_API_url",
            "payment_assist_API_key",
          ],
        },
        // "JobSchedules.InvoiceCCustomer": {
        //   fields: [
        //       "id",
        //       "firstname",
        //       "lastname"
        //   ]
        // },
        // "InsuranceCompany": {
        //   fields: [
        //       "id",
        //       "company_name",
        //       "phone_number"
        //   ]
        // }
      },
      limit: pageLimit,
      page: page,
      order: {
        "Invoices.id": "desc",
      },
      fields: [
        "id",
        "workshop_id",
        "invoice_number",
        "created_on",
        "total",
        "comments",
        "tax_invoice",
        "remain_balance",
        "invoice_status",
        "claim_amount",
        "customer_preview_invoice",
        "insurance_preview_invoice",
        "customer_invoice_total",
        "insurance_invoice_total",
        "JobSchedules.id",
        "JobSchedules.job_number",
        "JobSchedules.total",
        "JobSchedules.inspections_id",
        "JobSchedules.job_stage",
        "JobSchedules.show_allocation_column",
        "JobAttachmentList.insp_work_type",
        "JobAttachmentList.Insp_status",
        "JobAttachmentList.approval_token",
        "JobAttachmentList.workshop_id",
        "Vehicles.id",
        "Vehicles.rego",
        "Vehicles.make",
        "Vehicles.model",
        "Vehicles.service_due_date",
        "Vehicles.created_on",
        // "Users.id",
        // "Users.email",
        // "Customers.id",
        // "Customers.email",
        "Workshops.id",
        "customer_invoice_allocation_total",
        "customer_invoice_allocation_preview",
        "JobSchedules.inv_c_cust_id",
        "invoice_allocation_type",
        "date_issued"
      ],
    };

    listCount = {
      get: "all",
      conditions: {
        "Invoices.workshop_id": workshop_id,
        invoice_status: 1,
      },
      contain: {
        0: "JobSchedules.JobAttachmentList",
        "JobSchedules.Vehicles.Users.CustomersDetails": {
          conditions: {
            workshop_id: workshop_id,
          },
          fields: [
            "user_id",
            "workshop_id",
            "firstname",
            "lastname",
            "type",
            "company_name",
            "mobile_number",
            "mobile_code",
            "id"
          ],
        },
        InvoicesPaymentsCredits: {
          fields: ["id", "invoice_id", "amount_due", "amount_paid", "balance_remaining"],
        },
        "Workshops.WorkshopOtherDetails": {
          fields: [
            "workshop_id",
            "payment_assist_API_url",
            "payment_assist_API_key",
          ],
        },
      },
      fields: {
        COUNT: "COUNT(Invoices.id)",
      },
    };
  }

  if (type == "cashsale") {
    saleInvoiceRequest = {
      get: "all",
      conditions: {
        "CashSaleInvoice.workshop_id": workshop_id,
        "CashSaleInvoice.status": 1,
        "CashSaleInvoice.invoice_status": 1,
      },
      contain: {
        WorkshopDepartmentsAccountsDetails: {
          fields: [
            "WorkshopDepartmentsAccountsDetails.id",
            "WorkshopDepartmentsAccountsDetails.name",
          ],
        },
        Vehicles: {
          fields: [
            "Vehicles.id",
            "Vehicles.user_id",
            "Vehicles.workshop_id",
            "Vehicles.rego",
            "Vehicles.make",
            "Vehicles.model",
            "Vehicles.created_on",
            "Vehicles.service_due_date",
          ],
        },
        "Users.customersDetails": {
          conditions: {
            workshop_id: workshop_id,
          },
          fields: [
            "id",
            "user_id",
            "firstname",
            "lastname",
            "mobile_number",
            "type",
            "company_name",
            "mobile_code"
          ],
        },
      },
      limit: pageLimit,
      page: page,
      order: {
        "CashSaleInvoice.id": "desc",
      },
      fields: [
        "CashSaleInvoice.id",
        "CashSaleInvoice.cash_sale_no",
        "CashSaleInvoice.parts_total_sell",
        "CashSaleInvoice.labour_total_sell",
        "CashSaleInvoice.workshop_id",
        "CashSaleInvoice.invoice_dept_id",
        "CashSaleInvoice.total",
        "CashSaleInvoice.description",
        "Users.id",
        "Users.workshop_id",
        "Users.firstname",
        "Users.phone_number",
        "Users.email",
        "CashSaleInvoice.created_on",
      ],
    };

    saleInvoiceCount = {
      get: "all",
      conditions: {
        "CashSaleInvoice.workshop_id": workshop_id,
        "CashSaleInvoice.status": 1,
        "CashSaleInvoice.invoice_status": 1,
      },
      contain: {
        WorkshopDepartmentsAccountsDetails: {
          fields: [
            "WorkshopDepartmentsAccountsDetails.id",
            "WorkshopDepartmentsAccountsDetails.name",
          ],
        },
        Vehicles: {
          fields: [
            "Vehicles.id",
            "Vehicles.user_id",
            "Vehicles.workshop_id",
            "Vehicles.rego",
            "Vehicles.make",
            "Vehicles.model",
            "Vehicles.created_on",
            "Vehicles.service_due_date",
          ],
        },
        "Users.customersDetails": {
          conditions: {
            workshop_id: workshop_id,
          },
          fields: [
            "id",
            "user_id",
            "firstname",
            "lastname",
            "mobile_number",
            "type",
            "company_name",
            "mobile_code"
          ],
        },
      },
      fields: {
        COUNT: "COUNT(CashSaleInvoice.id)",
      },
    };
  }

  return {
    type: GET_RAISED_INVOICES,
    payload: { invoiceListRequest: listRequest, saleInvoiceRequest, listCount, saleInvoiceCount },
  };
};

export const getCompletedInvoicePayload = (type, pageLimit, page) => {

  const workshop_id = getWorkshopId();
  let listRequest = "invoice";
  let saleInvoiceRequest = "cashsale";

  let listCount = "";
  let saleInvoiceCount = "";

  if (type == "invoice") {
    listRequest = {
      get: "all",
      // "limit": invoicePerPage,
      // "page": page,
      conditions: {
        "Invoices.workshop_id": workshop_id,
        invoice_status: 2,
      },
      contain: {
        "JobSchedules.Vehicles.Users.CustomersDetails": {
          conditions: {
            workshop_id: workshop_id,
          },
          fields: [
            "user_id",
            "workshop_id",
            "firstname",
            "lastname",
            "type",
            "company_name",
            "mobile_number",
            "mobile_code",
            "id"
          ],
        },
        "JobSchedules.Customers":{
          fields:["id","email"]
        },
        "JobSchedules.Customers.CustomersDetails":{
          fields:["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code","customer_app","street_address","suburb","postcode","state","country"]
        },
        // "JobSchedules.Customers.CustDetails": {
        //   conditions: {
        //     "CustDetails.workshop_id": workshop_id,
        //   },
        //   fields: [
        //     "user_id",
        //     "workshop_id",
        //     "firstname",
        //     "lastname",
        //     "type",
        //     "company_name",
        //     "mobile_number",
        //     "mobile_code",
        //     "id"
        //   ],
        // },
        InvoicesPaymentsCredits: {
          fields: ["id", "invoice_id", "amount_due", "amount_paid", "balance_remaining"],
        },
        "Workshops.WorkshopOtherDetails": {
          fields: [
            "workshop_id",
            "payment_assist_API_url",
            "payment_assist_API_key",
          ],
        },
        "JobSchedules.InvoiceCCustomer": {
          fields: [
              "id",
              "firstname",
              "lastname",
              "mobile_number",
              "mobile_code",
              "type",
              "company_name",
              "user_id"
          ]
        },
        "InsuranceCompany": {
          fields: [
              "id",
              "company_name",
              "phone_number"
          ]
        }
      },
      limit: pageLimit,
      page: page,
      order: {
        "Invoices.id": "desc",
      },
      fields: [
        "id",
        "workshop_id",
        "invoice_number",
        "created_on",
        "total",
        "comments",
        "tax_invoice",
        "remain_balance",
        "invoice_status",
        "claim_amount",
        "customer_preview_invoice",
        "insurance_preview_invoice",
        "customer_invoice_total",
        "insurance_invoice_total",
        "customer_invoice_allocation_total",
        "customer_invoice_allocation_preview",
        "JobSchedules.id",
        "JobSchedules.job_number",
        "JobSchedules.total",
        "JobSchedules.show_allocation_column",
        "Vehicles.id",
        "Vehicles.rego",
        "Vehicles.make",
        "Vehicles.model",
        "Vehicles.service_due_date",
        "Vehicles.created_on",
        "Users.id",
        "Users.email",
        // "Customers.id",
        // "Customers.email",
        "Workshops.id",
        "JobSchedules.inv_c_cust_id",
        "invoice_allocation_type",
        "date_issued"
      ],
    };

    listCount = {
      get: "all",
      // "limit": invoicePerPage,
      // "page": page,
      conditions: {
        "Invoices.workshop_id": workshop_id,
        invoice_status: 2,
      },
      contain: {
        "JobSchedules.Vehicles.Users.CustomersDetails": {
          conditions: {
            workshop_id: workshop_id,
          },
          fields: [
            "user_id",
            "workshop_id",
            "firstname",
            "lastname",
            "type",
            "company_name",
            "mobile_number",
            "mobile_code",
            "id"
          ],
        },
        InvoicesPaymentsCredits: {
          fields: ["id", "invoice_id", "amount_due", "amount_paid", "balance_remaining"],
        },
        "Workshops.WorkshopOtherDetails": {
          fields: [
            "workshop_id",
            "payment_assist_API_url",
            "payment_assist_API_key",
          ],
        },
      },
      fields: {
        COUNT: "COUNT(Invoices.id)",
      },
    };
  }

  if (type == "cashsale") {
    saleInvoiceRequest = {
      get: "all",
      // "limit": saleInvoicePerPage,
      // "page": page,
      conditions: {
        "CashSaleInvoice.workshop_id": workshop_id,
        "CashSaleInvoice.status": 1,
        "CashSaleInvoice.invoice_status": 2,
      },
      contain: {
        cashsalePaymentsCredits: {
          fields: ["id", "cash_sale_invoice_id"],
        },
        WorkshopDepartmentsAccountsDetails: {
          fields: [
            "WorkshopDepartmentsAccountsDetails.id",
            "WorkshopDepartmentsAccountsDetails.name",
          ],
        },
        Vehicles: {
          fields: [
            "Vehicles.id",
            "Vehicles.user_id",
            "Vehicles.workshop_id",
            "Vehicles.rego",
            "Vehicles.make",
            "Vehicles.model",
            "Vehicles.created_on",
            "Vehicles.service_due_date",
          ],
        },
        "Users.customersDetails": {
          conditions: {
            workshop_id: workshop_id,
          },
          fields: [
            "id",
            "user_id",
            "firstname",
            "lastname",
            "mobile_number",
            "type",
            "company_name",
            "mobile_code"
          ],
        },
      },
      limit: pageLimit,
      page: page,
      order: {
        "CashSaleInvoice.id": "desc",
      },
      fields: [
        "CashSaleInvoice.id",
        "CashSaleInvoice.cash_sale_no",
        "CashSaleInvoice.parts_total_sell",
        "CashSaleInvoice.labour_total_sell",
        "CashSaleInvoice.workshop_id",
        "CashSaleInvoice.invoice_dept_id",
        "CashSaleInvoice.total",
        "CashSaleInvoice.description",
        "Users.id",
        "Users.workshop_id",
        "Users.firstname",
        "Users.phone_number",
        "Users.email",
        "CashSaleInvoice.created_on",
      ],
    };

    saleInvoiceCount = {
      get: "all",
      // "limit": saleInvoicePerPage,
      // "page": page,
      conditions: {
        "CashSaleInvoice.workshop_id": workshop_id,
        "CashSaleInvoice.status": 1,
        "CashSaleInvoice.invoice_status": 2,
      },
      contain: {
        cashsalePaymentsCredits: {
          fields: ["id", "cash_sale_invoice_id"],
        },
        WorkshopDepartmentsAccountsDetails: {
          fields: [
            "WorkshopDepartmentsAccountsDetails.id",
            "WorkshopDepartmentsAccountsDetails.name",
          ],
        },
        Vehicles: {
          fields: [
            "Vehicles.id",
            "Vehicles.user_id",
            "Vehicles.workshop_id",
            "Vehicles.rego",
            "Vehicles.make",
            "Vehicles.model",
            "Vehicles.created_on",
            "Vehicles.service_due_date",
          ],
        },
        "Users.customersDetails": {
          conditions: {
            workshop_id: workshop_id,
          },
          fields: [
            "id",
            "user_id",
            "firstname",
            "lastname",
            "mobile_number",
            "type",
            "company_name",
            "mobile_code"
          ],
        },
      },
      fields: {
        COUNT: "COUNT(CashSaleInvoice.id)",
      },
    };
  }

  return {
    type: GET_COMPLETED_INVOICES,
    payload: { invoiceListRequest: listRequest, saleInvoiceRequest, listCount, saleInvoiceCount },
  };
};

//Raise Invoices
export const getRaisedInvoiceAPI =
  (state) => (dispatch, type, pageLimit, page) => () => {
    dispatch(getRaisedInvoicePayload(type, pageLimit, page));
  };

// Completed Invoices
export const getCompletedInvoiceAPI =
  (state) => (dispatch, type, pageLimit, page) => () => {
    dispatch(getCompletedInvoicePayload(type, pageLimit, page));
  };

export const getInvoicePaymentsAPI = (state) => (dispatch, type, pageLimit, page) => () => {

  let listRequest = "invoice";
  let saleInvoiceRequest = "cashsale";
  let listCount = "";
  let saleInvoiceCount = "";
  const workshop_id = getWorkshopId();

  if (type == "invoice") {
    listRequest = {
      get: "all",
      conditions: {
        "InvoicesPaymentsCredits.workshop_id": workshop_id,
      },
      contain: {
        Invoices: {
          fields: ["id", "user_id", "job_id", "invoice_number", "total", "claim_amount", "customer_preview_invoice", "insurance_preview_invoice", "customer_invoice_allocation_preview", "invoice_allocation_type","date_issued"],
        },
        "Invoices.JobSchedules": {
          fields: [
            "JobSchedules.id",
            "JobSchedules.user_id",
            "JobSchedules.customer_id",
            "JobSchedules.total",
            "JobSchedules.job_number",
            "JobSchedules.rego",
            "JobSchedules.inv_c_cust_id",
            "JobSchedules.show_allocation_column",
          ],
        },
        "Invoices.JobSchedules.Customers":{
          fields:["id","email"]
        },
        "Invoices.JobSchedules.Customers.CustomersDetails":{
          fields:["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code","customer_app","street_address","suburb","postcode","state","country"]
        },
        "Invoices.JobSchedules.Vehicles.Users.CustomersDetails": {
          conditions: {
            "CustomersDetails.workshop_id": workshop_id,
          },
          fields: [
            "CustomersDetails.user_id",
            "CustomersDetails.workshop_id",
            "CustomersDetails.firstname",
            "CustomersDetails.lastname",
            "CustomersDetails.type",
            "CustomersDetails.company_name",
            "CustomersDetails.id",
          ],
        },
        "Invoices.JobSchedules.Customers.CustDetails": {
          conditions: {
            "CustDetails.workshop_id": workshop_id,
          },
          fields: [
            "user_id",
            "workshop_id",
            "firstname",
            "lastname",
            "type",
            "company_name",
            "mobile_number",
            "mobile_code",
            "id"
          ],
        },
        "Invoices.JobSchedules.InvoiceCCustomer": {
          fields: [ "user_id","workshop_id","firstname","lastname","type","company_name","mobile_number","mobile_code","id"]
        },
        "Invoices.JobSchedules.InvoiceCCustomer.Users": {
          fields: [
              "id",              
              "email"
          ]
        },
        "Invoices.InsuranceCompany":{
          fields: ["id", "company_name", "email", "phone_number", "postcode", "suburb", "street_address"]
        },
      },
      order: {
        "InvoicesPaymentsCredits.id": "desc",
      },
      limit: pageLimit,
      page: page,
      fields: [
        "id",
        "invoice_id",
        "workshop_id",
        "receipt_no",
        "payment_method",
        "amount_paid",
        "created_on",
        "balance_remaining",
        // "Users.id",
        // "Users.email",
        "Customers.id",
        "Customers.email",
        "Vehicles.id",
        "Vehicles.rego",
      ],
    };

    listCount = {
      get: "all",
      conditions: {
        "InvoicesPaymentsCredits.workshop_id": workshop_id,
      },
      contain: {
        Invoices: {
          fields: ["id", "user_id", "job_id", "invoice_number", "total", "claim_amount"],
        },
        "Invoices.JobSchedules": {
          fields: [
            "JobSchedules.id",
            "JobSchedules.user_id",
            "JobSchedules.customer_id",
            "JobSchedules.total",
            "JobSchedules.job_number",
          ],
        },
        "Invoices.JobSchedules.Vehicles.Users.CustomersDetails": {
          conditions: {
            "CustomersDetails.workshop_id": workshop_id,
          },
          fields: [
            "CustomersDetails.user_id",
            "CustomersDetails.workshop_id",
            "CustomersDetails.firstname",
            "CustomersDetails.lastname",
            "CustomersDetails.type",
            "CustomersDetails.company_name",
            "CustomersDetails.id",
          ],
        },
        "Invoices.JobSchedules.Customers.CustDetails": {
          conditions: {
            "CustDetails.workshop_id": workshop_id,
          },
          fields: [
            "user_id",
            "workshop_id",
            "firstname",
            "lastname",
            "type",
            "company_name",
            "mobile_number",
            "mobile_code",
            "id"
          ],
        },
      },
      order: {
        "InvoicesPaymentsCredits.id": "desc",
      },
      fields: {
        COUNT: "COUNT(InvoicesPaymentsCredits.id)",
      },
    };
  }

  if (type == "cashsale") {
    saleInvoiceRequest = {
      get: "all",
      conditions: {
        "CashSalePaymentsCredits.workshop_id": workshop_id,
        "CashSalePaymentsCredits.status": 1,
      },
      contain: {
        CashSaleInvoice: {
          fields: ["cash_sale_no", "user_id", "total", "created_on"],
        },
        "CashSaleInvoice.Users.customersDetails": {
          fields: [
            "id",
            "user_id",
            "firstname",
            "lastname",
            "mobile_number",
            "type",
            "company_name",
            "mobile_code",
          ],
        },
        "CashSaleInvoice.Vehicles": {
          conditions: {
            "Vehicles.workshop_id": workshop_id,
          }
        },
      },
      limit: pageLimit,
      page: page,
      order: {
        "CashSalePaymentsCredits.id": "desc",
      },
      fields: [],
    };

    saleInvoiceCount = {
      get: "all",
      conditions: {
        "CashSalePaymentsCredits.workshop_id": workshop_id,
        "CashSalePaymentsCredits.status": 1,
      },
      contain: {
        CashSaleInvoice: {
          fields: ["cash_sale_no", "user_id", "total", "created_on"],
        },
        "CashSaleInvoice.Users.customersDetails": {
          fields: [
            "id",
            "user_id",
            "firstname",
            "lastname",
            "mobile_number",
            "type",
            "company_name",
          ],
        },
      },
      order: {
        "CashSalePaymentsCredits.id": "desc",
      },
      fields: {
        COUNT: "COUNT(CashSalePaymentsCredits.id)",
      },
    };
  }

  dispatch({
    type: GET_INVOICE_PAYMENTS,
    payload: { listRequest, saleInvoiceRequest, listCount, saleInvoiceCount },
  });
};

export const getInvoiceCreditsAPI = (state) => (dispatch) => () => {
  const workshop_id = getWorkshopId();
  const listRequest = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCreditsOtherDetails.workshop_id": workshop_id,
    },
    contain: {
      InvoicesPaymentsCredits: {
        fields: ["receipt_no", "id", "invoice_id"],
      },
      "InvoicesPaymentsCredits.Invoices": {
        fields: [
          "Invoices.invoice_number",
          "Invoices.id",
          "Invoices.total",
          "Invoices.created_on",
          "Invoices.customer_preview_invoice", 
          "Invoices.insurance_preview_invoice", 
          "Invoices.customer_invoice_allocation_preview",
          "Invoices.invoice_allocation_type"
        ],
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules": {
        fields: ["JobSchedules.id", "JobSchedules.job_number", "JobSchedules.inv_c_cust_id","JobSchedules.show_allocation_column"],
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Customers":{
        fields:["id"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Customers.CustomersDetails":{
        fields:["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code","customer_app","street_address","suburb","postcode","state","country"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Vehicles.Users.CustomersDetails":
      {
        conditions: {
          "CustomersDetails.workshop_id": workshop_id,
        },
        fields: [
          "CustomersDetails.user_id",
          "CustomersDetails.workshop_id",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.type",
          "CustomersDetails.company_name",
          "CustomersDetails.id",
        ],
      },

      "InvoicesPaymentsCredits.Invoices.JobSchedules.Customers.CustDetails": {
        conditions: {
          "CustDetails.workshop_id": workshop_id,
        },
        fields: [
          "user_id",
          "workshop_id",
          "firstname",
          "lastname",
          "type",
          "company_name",
          "mobile_number",
          "mobile_code",
          "id"
        ],
      },

      "InvoicesPaymentsCredits.Invoices.JobSchedules.InvoiceCCustomer": {
        fields: [ "user_id","workshop_id","firstname","lastname","type","company_name","mobile_number","mobile_code","id"]        
      },
      "InvoicesPaymentsCredits.Invoices.InsuranceCompany": {
        fields: [
            "id",
            "company_name"
        ]
      }
    },
    order: {
      "InvoicesPaymentsCreditsOtherDetails.id": "desc",
    },
    fields: [
      "InvoicesPaymentsCreditsOtherDetails.id",
      "InvoicesPaymentsCreditsOtherDetails.workshop_id",
      "InvoicesPaymentsCreditsOtherDetails.invoice_id",
      "InvoicesPaymentsCreditsOtherDetails.invoice_payment_credit_id",
      "InvoicesPaymentsCreditsOtherDetails.description",
      "InvoicesPaymentsCreditsOtherDetails.amount_paid",
      "InvoicesPaymentsCreditsOtherDetails.amount_credit",
      "InvoicesPaymentsCreditsOtherDetails.credit_no",
      "JobSchedules.id",
      "JobSchedules.job_number",
      "JobSchedules.total",
      "Users.id",
      "Users.email",
      "Customers.id",
      "Customers.email",
      "Vehicles.id",
      "Invoices.invoice_allocation_type",
      "JobSchedules.inv_c_cust_id"
    ],
  };
  const cashSaleListRequest = {
    get: "all",
    conditions: {
      "CashSalePaymentsCreditsOtherDetails.workshop_id": workshop_id,
      "CashSalePaymentsCreditsOtherDetails.status": 1,
    },
    contain: {
      "CashSalePaymentsCredits.CashSaleInvoice": {
        fields: ["cash_sale_no", "user_id", "total"],
      },
      "CashSalePaymentsCredits.CashSaleInvoice.Users.customersDetails": {
        fields: [
          "id",
          "user_id",
          "firstname",
          "lastname",
          "mobile_number",
          "type",
          "company_name",
        ],
      },
    },
    order: {
      "CashSalePaymentsCredits.id": "desc",
    },
    fields: [],
  };
  dispatch({ type: GET_INVOICE_CREDITS, payload: { listRequest, cashSaleListRequest } });
};

export const getInvoiceStatementAPI = (state) => (dispatch) => () => {
  const workshop_id = getWorkshopId();
  const listRequest = {
    get: "all",
    conditions: {
      "InvoiceStatement.workshop_id": workshop_id,
    },
    contain: {
      'Users.CustomersDetails': {}
    }
  };
  dispatch({ type: GET_INVOICES_STATEMENTS, payload: { listRequest } });
};

export const deleteArchiveRaisedInvoiceAPI = (dispatch) => (payload) => {
  dispatch({
    type: DELETE_RAISED_INVOICES,
    payload: payload,
  });
};

export const deleteArchiveCompletedInvoiceAPI = (dispatch) => (payload) => {
  dispatch({
    type: DELETE_COMPLETED_INVOICES,
    payload: payload,
  });
};

export const getInvoicePaymentAction = () => {
  const workshop_id = getWorkshopId();
  const listRequest = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCredits.workshop_id": workshop_id,
    },
    contain: {
      Invoices: {
        fields: ["id", "user_id", "job_id", "invoice_number", "total"],
      },
      "Invoices.JobSchedules": {
        fields: [
          "JobSchedules.id",
          "JobSchedules.user_id",
          "JobSchedules.customer_id",
          "JobSchedules.total",
          "JobSchedules.job_number",
        ],
      },
      "Invoices.JobSchedules.Customers":{
        fields:["id"]
      },
      "Invoices.JobSchedules.Customers.CustomersDetails":{
        fields:["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code","customer_app","street_address","suburb","postcode","state","country"]
      },
      "Invoices.JobSchedules.Vehicles.Users.CustomersDetails": {
        conditions: {
          "CustomersDetails.workshop_id": workshop_id,
        },
        fields: [
          "CustomersDetails.user_id",
          "CustomersDetails.workshop_id",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.type",
          "CustomersDetails.company_name",
          "CustomersDetails.id",
        ],
      },
    },
    order: {
      "InvoicesPaymentsCredits.id": "desc",
    },
    fields: [
      "id",
      "invoice_id",
      "workshop_id",
      "receipt_no",
      "payment_method",
      "amount_paid",
      "created_on",
      "balance_remaining",
      "Users.id",
      "Users.email",
      "Vehicles.id",
    ],
  };
  return { type: GET_INVOICE_PAYMENTS, payload: { listRequest } };
};

export const getInvoiceCreditAction = () => {
  const workshop_id = getWorkshopId();
  const listRequest = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCreditsOtherDetails.workshop_id": workshop_id,
    },
    contain: {
      InvoicesPaymentsCredits: {
        fields: ["receipt_no", "id", "invoice_id"],
      },
      "InvoicesPaymentsCredits.Invoices": {
        fields: [
          "Invoices.invoice_number",
          "Invoices.id",
          "Invoices.total",
          "Invoices.created_on",
        ],
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules": {
        fields: ["JobSchedules.id", "JobSchedules.job_number"],
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Vehicles.Users.CustomersDetails":
      {
        conditions: {
          "CustomersDetails.workshop_id": workshop_id,
        },
        fields: [
          "CustomersDetails.user_id",
          "CustomersDetails.workshop_id",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.type",
          "CustomersDetails.company_name",
          "CustomersDetails.id",
        ],
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Customers":{
        fields:["id"]
      },
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Customers.CustomersDetails":{
        fields:["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code","customer_app","street_address","suburb","postcode","state","country"]
      },
    },
    order: {
      "InvoicesPaymentsCreditsOtherDetails.id": "desc",
    },
    fields: [
      "InvoicesPaymentsCreditsOtherDetails.id",
      "InvoicesPaymentsCreditsOtherDetails.workshop_id",
      "InvoicesPaymentsCreditsOtherDetails.invoice_id",
      "InvoicesPaymentsCreditsOtherDetails.invoice_payment_credit_id",
      "InvoicesPaymentsCreditsOtherDetails.description",
      "InvoicesPaymentsCreditsOtherDetails.amount_paid",
      "InvoicesPaymentsCreditsOtherDetails.amount_credit",
      "InvoicesPaymentsCreditsOtherDetails.credit_no",
      "JobSchedules.id",
      "JobSchedules.job_number",
      "JobSchedules.total",
      "Users.id",
      "Users.email",
      "Vehicles.id",
    ],
  };
  return { type: GET_INVOICE_CREDITS, payload: { listRequest } };
};

export const getInvoiceStatementAction = () => {
  const workshop_id = getWorkshopId();
  const listRequest = {
    get: "all",
    conditions: {
      "InvoiceStatement.workshop_id": workshop_id,
    },
    contain: {
      'Users.CustomersDetails': {}
    }
  };
  return { type: GET_INVOICES_STATEMENTS, payload: { listRequest } };
};