import * as ACTIONS from "./actionTypes";
const initialState = {
  leadsList: {
    list: [],
    error: false,
    loading: false,
    count: 0,
  },
};
export default function leadsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leadsList: {
          list: [],
          error: false,
          loading: false,
          count: 0,
        },
      };
    case ACTIONS.GET_LEADS_SUCCESS:
      return {
        ...state,
        leadsList: {
          ...action.payload,
          error: false,
          loading: false,
        },
      };
    case ACTIONS.GET_LEADS_FAIL:
      return {
        ...state,
        leadsList: {
          list: [],
          error: true,
          loading: false,
          count: 0,
        },
      };
    case ACTIONS.GET_LEAD_DETAIL:
      return {
        ...state,
        leadDetail: {
          loading: true,
          error: false,
          data: null,
          updating: false,
        },
      };
    case ACTIONS.GET_LEAD_DETAIL_SUCCESS:
      return {
        ...state,
        leadDetail: {
          loading: false,
          error: false,
          data: action.payload,
          updating: false,
        },
      };
    case ACTIONS.GET_LEAD_DETAIL_FAIL:
      return {
        ...state,
        leadDetail: {
          loading: false,
          error: true,
          data: null,
          updating: false,
        },
      };
    case ACTIONS.CONVERT_LEAD_TO_JOB:
      return {
        ...state,
        covertJob: {
          status: "processing",
          data: null,
        },
      };
    case ACTIONS.CONVERT_LEAD_TO_JOB_SUCCESS:
      return {
        ...state,
        covertJob: {
          status: "success",
          data: action.payload,
        },
      };
    case ACTIONS.CONVERT_LEAD_TO_JOB_FAIL:
      return {
        ...state,
        covertJob: {
          status: "failed",
          data: null,
        },
      };
    case ACTIONS.CONVERT_LEAD_TO_QUOTE:
      return {
        ...state,
        covertQuote: {
          status: "processing",
          data: null,
        },
      };
    case ACTIONS.CONVERT_LEAD_TO_QUOTE_SUCCESS:
      return {
        ...state,
        covertQuote: {
          status: "success",
          data: action.payload,
        },
      };
    case ACTIONS.CONVERT_LEAD_TO_QUOTE_FAIL:
      return {
        ...state,
        covertQuote: {
          status: "failed",
          data: null,
        },
      };
    case ACTIONS.UPDATE_LEAD:
      return {
        ...state,
        leadDetail: {
          ...state.leadDetail,
          updating: true,
        },
      };
    case ACTIONS.UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        leadDetail: {
          ...state.leadDetail,
          data: action.payload,
          updating: false,
        },
      };
    case ACTIONS.UPDATE_LEAD_FAIL:
      return {
        ...state,
        leadDetail: {
          ...state.leadDetail,
          updating: false,
          error: true,
        },
      };
    case ACTIONS.GET_INTERNAL_NOTE_WITH_SCRIPTS:
      return {
        ...state,
        internalNotes: {
          scripts: [],
          notes: [],
          loading: true,
          error: false,
        },
      };
    case ACTIONS.GET_INTERNAL_NOTE_WITH_SCRIPTS_SUCCESS:
      return {
        ...state,
        internalNotes: {
          scripts: action.payload.scripts,
          notes: action.payload.notes,
          loading: false,
          error: false,
        },
      };
    case ACTIONS.GET_INTERNAL_NOTE_WITH_SCRIPTS_FAIL:
      return {
        ...state,
        internalNotes: {
          scripts: [],
          notes: [],
          loading: false,
          error: false,
        },
      };
    case ACTIONS.ADD_INTERNAL_NOTE_TO_LEAD_SUCCESS:
      return {
        ...state,
        internalNotes: {
          ...state.internalNotes,
          notes: action.payload,
        },
      };
    case ACTIONS.DELETE_INTERNAL_NOTE_TO_LEAD_SUCCESS:
      return {
        ...state,
        internalNotes: {
          ...state.internalNotes,
          notes: action.payload,
        },
      };

    default:
      return state;
  }
}
