import { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Form from "react-bootstrap/Form";

const RecommendationViewModal = ({setShowViewModal,closeModal,localData,api}) => {
  // TEST

  const [localRecommendation, setLocalRecommendation] = useState(localData?.recommendation);

  const handleClose = async () => {
    return closeModal();
  };

  const updateRecommendations = async () => {
    return api(localRecommendation);
  };

  return (
    <>

        <Modal
            size="md"
            centered
            className="rogo-lookup"
            show={true}
            onHide={handleClose}
        >

            <Modal.Header>
              <Modal.Title className="d-flex justify-space-between">
                Recommendation
              </Modal.Title>
              <Button
                type="button"
                className="close-btn"
                onClick={handleClose}
              >
                <i className="la la-times"></i>{" "}
              </Button>
            </Modal.Header>

            <Modal.Body>
                
                    <Form.Group className="form-group p-2">
                    <textarea
                        rows={2}
                        className="form-control"
                        disabled={localData?.is_show === "1" || localData?.is_completed === "1" ? true : false}
                        type="textarea"
                        name="comments"
                        id="comments"
                        value={localRecommendation}
                        onChange={(e) => {
                            setLocalRecommendation(e.target.value);
                        }}
                    />
                    </Form.Group>
                
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-center">
                {localData?.is_show === "1" || localData?.is_completed === "1" ? (
                    <Button
                        variant="outline-secondary"
                        className="btn-outline rounded-btn"
                        onClick={(e) => handleClose()}
                    >
                        Close
                    </Button>
                ) : (
                    <>
                        <Button
                            variant="outline-secondary"
                            className="mr-1 btn btn-primary rounded-btn"
                            onClick={(e) => updateRecommendations()}
                        >
                            Update
                        </Button>

                        <Button
                            variant="outline-secondary"
                            className="btn-outline rounded-btn"
                            id="closeButton"
                            onClick={handleClose}
                            >
                            Cancel
                        </Button>
                    </>
                )}
                
              </div>
            </Modal.Footer>
        </Modal>
    </>
);
};

export default RecommendationViewModal;
