import {
  GET_STORAGE_DEVICES,
  GET_STORAGE_DEVICES_SUCCESS,
  GET_STORAGE_DEVICES_FAIL,
  EDIT_STORAGE_DEVICE,
  EDIT_STORAGE_DEVICE_SUCCESS,
  EDIT_STORAGE_DEVICE_FAIL,
  UPDATE_STORAGE_DEVICE,
  UPDATE_STORAGE_DEVICE_SUCCESS,
  UPDATE_STORAGE_DEVICE_FAIL,
  ADD_STORAGE_DEVICE,
  ADD_STORAGE_DEVICE_SUCCESS,
  ADD_STORAGE_DEVICE_FAIL,
  VIEW_STORAGE_DEVICE_DETAILS,
  VIEW_STORAGE_DEVICE_DETAILS_SUCCESS,
  VIEW_STORAGE_DEVICE_DETAILS_FAIL,
} from "./actionTypes";

const initialState = {
  storageDevicesList: null,
  loading: true,
  editStorageDeviceData: {},
  updateStorageDeviceData: {},
  addStorageDeviceData: {},
  storageDeviceDetails: null,
  error: {
    message: "",
  },
};

const StorageDevicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORAGE_DEVICES:
      state = {
        loading: true,
        ...state,
      };
      break;
    case GET_STORAGE_DEVICES_SUCCESS:
      state = {
        ...state,
        loading: false,
        storageDevicesList: action.payload,
      };
      break;
    case GET_STORAGE_DEVICES_FAIL:
      state = {
        ...state,
        loading: false,
        storageDevicesList: action.payload,
        error: {
          message: action.payload,
        },
      };
      break;

    case EDIT_STORAGE_DEVICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_STORAGE_DEVICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        editStorageDeviceData: action.payload,
      };
      break;
    case EDIT_STORAGE_DEVICE_FAIL:
      state = {
        ...state,
        loading: false,
        editStorageDeviceData: {},
        error: {
          message: action.payload,
        },
      };
      break;

    case UPDATE_STORAGE_DEVICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_STORAGE_DEVICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        updateStorageDeviceData: action.payload,
      };
      break;
    case UPDATE_STORAGE_DEVICE_FAIL:
      state = {
        ...state,
        loading: false,
        updateStorageDeviceData: {},
        error: {
          message: action.payload,
        },
      };
      break;

    case ADD_STORAGE_DEVICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_STORAGE_DEVICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        addStorageDeviceData: action.payload,
      };
      break;
    case ADD_STORAGE_DEVICE_FAIL:
      state = {
        ...state,
        loading: false,
        addStorageDeviceData: {},
        error: {
          message: action.payload,
        },
      };
      break;
    
      case VIEW_STORAGE_DEVICE_DETAILS:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case VIEW_STORAGE_DEVICE_DETAILS_SUCCESS:
        state = {
          ...state,
          loading: false,
          storageDeviceDetails: action.payload,
        };
        break;
  
      case VIEW_STORAGE_DEVICE_DETAILS_FAIL:
        state = {
          ...state,
          loading: false,
          storageDeviceDetails: [],
          error: {
            message: action.payload,
          },
        };
        break;
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default StorageDevicesReducer;
