import { useMemo } from "react";

export function usePayData(data) {

  const payData = useMemo(() => {
    let remaining_due = 0;
    let total_due = 0;
    let paid_to_date = 0;
    let payment_amount = 0;
    let remaining = 0;
    let tax_invoice = "";
    let invoiceData = "";
    let remain_balance = 0;
    let invoice_type = data?.invoiceType ?? "";
    let customer_preview_invoice = data?.data?.customer_preview_invoice ?? "0";
    let insurance_preview_invoice = data?.data?.insurance_preview_invoice ?? "0";
    let customer_invoice_allocation_preview = data?.data?.customer_invoice_allocation_preview ?? "0";
    let customer_invoice_total = data?.data?.customer_invoice_total ?? "0";
    let insurance_invoice_total = data?.data?.insurance_invoice_total ?? "0";
    let customer_invoice_allocation_total = data?.data?.customer_invoice_allocation_total ?? "0";

    if (!data || Object.keys(data).length === 0) {
      return {
        remaining_due,
        total_due,
        paid_to_date,
        payment_amount,
        remaining,
        tax_invoice,
        invoiceData,
        remain_balance,
        invoice_type,
        customer_preview_invoice,
        insurance_preview_invoice,
        customer_invoice_total,
        insurance_invoice_total,
        customer_invoice_allocation_total,
        customer_invoice_allocation_preview
      };
    }

    if (data.type === "credits") {

      // $('.paymentDetails').html(paymentDiv);
      if (invoice_type == "cash_sale") {
        invoiceData = data.data.cash_sale_invoice;
        tax_invoice = 1;
      } else {
        invoiceData = data.data.invoice;
        tax_invoice = data.data.invoice.tax_invoice;
      }
      if (tax_invoice == 1) {
        // return "Full";
        total_due = parseFloat(invoiceData.total).toFixed(2);
        if (data.data.balance_remaining == "") {
          remaining = "0.00";
        } else {
          remaining = data.data.balance_remaining;
        }
        remaining = parseFloat(remaining).toFixed(2);
        remain_balance = remaining;
      } else {
        // return "Progress Claim or Deposit";
        total_due = parseFloat(invoiceData.claim_amount).toFixed(2);
        remaining =
          parseFloat(invoiceData.claim_amount) -
          parseFloat(data.data.amount_paid);
        remaining = parseFloat(remaining).toFixed(2);
      }
      // remaining = total_due - data.data.amount_paid;
      // total_due = reciept_total;
      payment_amount = remaining;
      paid_to_date = parseFloat(data.data.amount_paid).toFixed(2);
      remaining_due = remaining;
      remain_balance = remaining;
    }
    //If inovice is not of type credit
    else {
      if (invoice_type == "cash_sale") {
        tax_invoice = 1;
        remain_balance = "0";
      } else {
        tax_invoice = data?.data?.tax_invoice;
        remain_balance = data?.data?.remain_balance || 0;
      }
      if (tax_invoice == 1) {
        // return "Full Invoice Type";
        total_due = parseFloat(data.data.total).toFixed(2);
        if (remain_balance == "") {
          remaining = "0.00";
        } else {
          // remaining = data.data.balance_remaining;
          remaining = parseFloat(remain_balance).toFixed(2);
        }
        payment_amount = total_due;

      } else {
        // return "Progress Claim or Deposit";
        var partialTotal = parseFloat(data.data.claim_amount || 0);

        // }
        total_due = parseFloat(partialTotal).toFixed(2);

        // total_due = parseFloat(data.data.total).toFixed(2);
        remaining = parseFloat(remain_balance).toFixed(2);
        // total_due = remaining;
        payment_amount = total_due;
      }
    }

    return {
      remaining_due,
      total_due,
      paid_to_date,
      payment_amount,
      remaining,
      tax_invoice,
      invoiceData,
      remain_balance,
      invoice_type,
      customer_preview_invoice,
      insurance_preview_invoice,
      customer_invoice_total,
      insurance_invoice_total,
      customer_invoice_allocation_total,
      customer_invoice_allocation_preview

    };
  }, [data]);

  return payData;
}
