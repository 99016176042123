import React from "react";
import LoaderImage from "../../../assets/images/page-loader.svg";
const Loader = () => {
  return (
    <div className="page-loader d-flex align-center justify-center">
      <img src={LoaderImage} alt="Loader"></img>
    </div>
  );
};

export default Loader;
