import { deleteFirebaseToken } from "firbaseInit";
import { deleteNotificationToken } from "helpers/backendHelper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GET_LOGOUT } from "../../../store/login/actionTypes";

let Logout = () => {
  const [searchParams] = useSearchParams()
  const isAuto = Boolean(searchParams.get("auto") ?? false)
  const { firebase, userData } = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // localStorage.setItem('data',JSON.stringify(''));

  useEffect(() => {
    (async () => {
      await deleteFirebaseToken()

      if (firebase) {
        const payload = {
          id: firebase?.id,
        };

        deleteNotificationToken({ payload });
      }

      localStorage.clear();
      if (isAuto) {
        localStorage.setItem("lastLogoutTime", new Date())
        localStorage.setItem("autoLoggedOut", true)
      }

      // Remove all saved data from sessionStorage
      sessionStorage.clear();
      dispatch({
        type: GET_LOGOUT,
      });
      navigate("/login");
    })()
  }, []);
  return <></>;
};

export default Logout;
