import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/5iQ_logo.png";
import logoBlack from "../../../assets/images/5iQ_logo_black.png";
import SidebarLinkItem from "./SidebarLinkItem";
import { hqBottomLinks, hqTopLinks } from "./links";
import useRoleLink from "../hooks/useRoleLink";
import ScrollPoints from "./ScrollPoints";

export default function HQSidebar() {
  const [isShown, setIsShown] = useState(false);
  const { filterCondition } = useRoleLink();
  const inSidebars = useRef(false);
  const location = useLocation();
  //   const showSettingBar =
  //     settingLinks.map((c) => c.link).includes(location.pathname) && !isShown;

  //   const showReportBar =
  //     reportLinks.map((c) => c.link).includes(location.pathname) && !isShown;

  let showMainSidebar = true;

  //   if (showSettingBar || showReportBar) {
  //     showMainSidebar = false;
  //   }
  const activeLink = (item) => {
    // window.scrollTo(0, 0);
    return (
      item.main_link === location.pathname ||
      item.sub_items.map((c) => c.link).includes(location.pathname)
    );
  };

  return (
    <>
      <Button
        className="side-menu-btn"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={(e) => !inSidebars.current && setIsShown(false)}
      >
        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
      </Button>
      {showMainSidebar && (
        <div
          className="sidebar-wrapper"
          onMouseEnter={() => {
            inSidebars.current = true;
          }}
          onMouseLeave={() => {
            inSidebars.current = false;
            setIsShown(false);
          }}
        >
          <ul className="top-list">
            {hqTopLinks.filter(filterCondition).map((link) => (
              <SidebarLinkItem
                {...link}
                active={activeLink(link)}
                key={link.label}
              />
            ))}
          </ul>
          <ul className="bottom-list">
            {hqBottomLinks.filter(filterCondition).map((link) => (
              <SidebarLinkItem
                {...link}
                active={activeLink(link)}
                key={link.label}
              />
            ))}

            <li className="sidebar-logo">
              <Link to="/dashboard">
                <img className="light-theme" src={logoBlack} alt=""></img>
                <img className="dark-theme" src={logo} alt=""></img>
              </Link>
            </li>
          </ul>
        </div>
      )}
      <ScrollPoints />
    </>
  );
}
