import React from "react";
import { Link, useLocation } from "react-router-dom";
import { reportLinks } from "./links";
import { useRoleLink } from "./useLinkRole";

const ReportBar = () => {
  const { filterCondition } = useRoleLink();
  const location = useLocation();

  const activeLink = (item) => {
    // window.scrollTo(0, 0);
    return item.link === location.pathname;
   };
  return (
    <div className="sidebar-wrapper secondary-sidebar">
      <label>
        <Link to="/reports" className="d-flex align-center direction-column">
          <i className="fa fa-bar-chart"></i>
          <p>Reports</p>
        </Link>
      </label>
      <ul className="child-menu-list">
        {reportLinks.filter(filterCondition).map((c,index) => (
          <li className={activeLink(c) ? 'active' : ''} key={index}>
            <Link title={c.title} to={c.link}>{c.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReportBar;
