import { useState } from 'react';
export function useTablePaginate({ currPage = 1, total = 1, size = 10 }) {
    const [currentPage, setCurrentPage] = useState(currPage || 1);
    const [totalRecords, setTotalRecords] = useState(total || 0);
    const [sizePerPage, setSizePerPage] = useState(size || 10);
    return {
        currentPage,
        setCurrentPage,
        totalRecords,
        setTotalRecords,
        sizePerPage,
        setSizePerPage
    };
}