import { getTimeSlotsApi } from "helpers/backendHelper";
import moment from "moment";
import { utcToTimezone } from "utils/timeZoneConvert";

export async function getTimeSlots({ workshop_other_details, date }) {
  const { shift_start_time, timezone_label, workshop_id } =
    workshop_other_details;
  const utcTime = utcToTimezone(shift_start_time, timezone_label, "HH:mm:ss");
  const dateTime = date + " " + utcTime;

  const requestData = {
    get: "all",
    conditions: {
      workshop_id: workshop_id,
      from_date: dateTime,
      "job_stage NOT IN": ["Archive"],
    },
  };

  const resp = await getTimeSlotsApi({ payload: requestData });
  //   const timeSlots = [];
  const slots = {
    Morning: [],
    Afternoon: [],
    // Evening: [],
  };

  if (resp?.code === 200) {
    resp?.data?.JobSchedules.forEach((time) => {
      const pushTime = moment(time, "HH:mm:ss").format("hh:mm a");
      const intTime = parseFloat(time);
      if (intTime < 12) {
        slots.Morning.push(pushTime);
      } else if (intTime > 11) {
        slots.Afternoon.push(pushTime);
      }
      // else {
      //   slots.Evening.push(pushTime);
      // }
    });
  }

  return slots;
}
