import React from "react";
import { carMakeData } from "./carMakeData";
import defaultMakeImage from "../../../../assets/images/default_make.png";
// Taken from here : https://github.com/avto-dev/vehicle-logotypes/blob/master/src/vehicle-logotypes.json

const CarMakeLogo = ({ make, size = "", isBigSize = false }) => {
  const makeURL =
    make && make !== "" && make.toLowerCase() in carMakeData
      ? carMakeData[make.toLowerCase()]?.logotype?.uri || ""
      : "";
  return (
    size !== "" && size === "small" ?
      <>
        <div className="profile-small" style={{ verticalAlign: "middle" }}>
          <img
            alt="Car Make"
            src={`${makeURL || defaultMakeImage}`}
            className="mx-0"
            style={{
              width: "100%",
              maxWidth: "36px",
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${defaultMakeImage}`;
            }}
          />
        </div>
      </>
      :
      <>
        <div className={isBigSize ? "profile-big" : "profile"} style={{ verticalAlign: "middle" }}>
          <img
            alt="Car Make"
            src={`${makeURL || defaultMakeImage}`}
            className="mx-0"
            style={{
              width: "100%",
              maxWidth: isBigSize ? "80px" : "36px",
              height: isBigSize && "70px"
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${defaultMakeImage}`;
            }}
          />
        </div>
      </>

  );
};

export default CarMakeLogo;
