import { addJobClockingHistory, getJobClockingHistory, updateJobClockingHistory } from "helpers/backendHelper";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { showError, showSuccess } from "utils/alertMessage";
import { getSessionData } from "utils/getWorkshopId";
import { downloadFileFromS3 } from "utils/uploadFileToS3";
import CarMakeLogo from "views/common/UI/CarMakeLogo";
import ColorStatus from "./colorStatus";
import TimeClocking from "./timeClocking";
import TimeLog from "./timeLog";
import { timeZoneToUTC } from "utils/timeZoneConvert";

let TechnicianDetail = ({ techician, techCounter, jobData,
    statusColor, fromDate, setJobData
}) => {

    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);
    const todayDate = moment().format('YYYY-MM-DD');
    let timezoneLable = window.atob(storedData.timezone_lable);

    let defaultJobList = {
        value: 0,
        label: "Select Job",
        isDisabled: false,
        isSelected: false
    };

    const [displayLog, setDisplayLog] = useState(false);
    const [jobList, setJobList] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [continueJob, setContinueJob] = useState(0);
    const [activityMessage, setActivityMessage] = useState("");
    const [jobHistory, setJobHistory] = useState("");
    const [lastTimeLogId, setLastTimeLogId] = useState("");
    const [lastActivity, setLastActivity] = useState("");
    const [currentTextCell, setCurrentTextCell] = useState("");
    const [currentColourCell, setCurrentColourCell] = useState("");

    let jobDetail = {
        customer_name: "",
        rego: "",
        make: "",
        model: "",
        year: "",
        job_booking_type: "",
        job_status: "",
    };
    const [jobDetailData, setJobDetailData] = useState(jobDetail);

    useEffect(() => {
        return () => {
            setJobList([]);
            setContinueJob();
            setSelectedJob([]);
        };
    }, []);

    useEffect(() => {
        if (jobData?.length !== 0) {

            let jobs = [];
            jobs.push(defaultJobList);

            jobData.map(({ id, job_number, user_id, timer_status, clocked_in_time, checkIn_date, job_stage, user, job_status, workshop_bays_detail }) => {


                if (job_number !== "" && job_number) {


                    var colourCell = job_status;
                    var textCell = job_status;
                    if (colourCell === "Paid") {
                        colourCell = "Payment";
                    }
                    if (textCell === "Payment") {
                        colourCell = "Payment";
                        textCell = "Paid";
                    }
                    if (textCell === "InProgress") {
                        textCell = "In Progress";
                    }

                    if (textCell === "ReadyForInvoice") {
                        textCell = "Ready For Invoice";
                    }
                    if (colourCell === "Pending") {
                        textCell = "Booked";
                        colourCell = "Pending"
                        if (checkIn_date !== null) {
                            textCell = "Arrived";
                            colourCell = "Arrived"
                        }
                    }

                    if (continueJob === id) {
                        setCurrentTextCell(textCell);
                        setCurrentColourCell(colourCell);
                    }


                    // let jobNumber = "Job #" + job_number + " - " + user?.firstname;
                    let jobNumber = "Job #" + job_number;
                    if (user?.firstname && typeof user?.firstname !== undefined) {
                        jobNumber += " - " + user?.firstname;
                    } else {
                        jobNumber += " - " + workshop_bays_detail?.name;
                    }
                    let isDisabled = false;
                    let isSelected = false;

                    if (fromDate === todayDate) {

                        if (techician?.id === user_id) {
                            // jobNumber = "<span>" + jobNumber + " - A  </span>";
                            if (timer_status === 'start') {
                                isSelected = true;
                            }
                            if (job_stage === "Completed") {
                                isDisabled = true;
                            }
                        } else {
                            if (job_stage === "Completed") {
                                isDisabled = true;
                            }
                        }

                        if (job_stage === "Bookin") {
                            job_stage = "Booking";
                        }
                        if (job_stage === "Inprogress") {
                            job_stage = "In Progress";
                        }
                        // if (id !== continueJob) {
                        jobNumber = jobNumber + " <i>" + job_stage + "</i>"
                        // }

                        const eValue = {
                            value: id, label: <span className="clocking-job-list" dangerouslySetInnerHTML={{ __html: jobNumber }} />,
                            isDisabled: isDisabled, isSelected: isSelected,
                            textCell: textCell, colourCell: colourCell
                        }

                        jobs.push(eValue);

                        // if (isSelected === true) {

                        //     selectedJobNumber(eValue.value);
                        //     setContinueJob(eValue.value);
                        // }
                    } else {

                        let newa = [];
                        const res = techician?.job_clocking_history.reduce((a, c) => {
                            if (!a.find(v => v?.job_id === c?.job_id)) {
                                a.push(c);
                                newa.push(c?.job_id);
                            }
                            return a;
                        }, []);

                        if (newa.includes(id)) {
                            const eValue = {
                                value: id, label: jobNumber,
                                isDisabled: isDisabled, isSelected: isSelected
                            }
                            jobs.push(eValue);
                        }

                    }

                }
            });
            setJobList(jobs);
        }

        return () => {
            // setJobList([]);
            // setContinueJob();
            // setSelectedJob([]);
        };

    }, [jobData]);

    useEffect(() => {
        // let continueJobNumber = 0;

        if (techician?.job_clocking_history.length > 0 && jobData?.length !== 0) {
            let currentTimeLog = techician?.job_clocking_history;
            let lastArray = currentTimeLog[currentTimeLog.length - 1];
            // if (lastArray?.activity === "Clock In" || lastArray?.activity === "Clock Off") {
            if (lastArray?.activity === "Clock In") {
                // continueJobNumber = lastArray?.job_id;
                selectedJobNumber(lastArray?.job_id);
                setContinueJob(lastArray?.job_id);
            }
            // setJobHistory(techician?.job_clocking_history);
        }
    }, [techician?.job_clocking_history]);



    let avatarPath = window.location.origin + "/Avatar/";
    var techProfile = avatarPath + "1.png";

    if (techician?.user_profile !== "" && techician?.user_profile !== undefined && techician?.user_profile !== "bay" && techician?.id !== "z") {
        let user_profile_name = techician?.user_profile.split('/');
        if (user_profile_name[0] === 'user_profile') {
            techProfile = downloadFileFromS3(techician?.user_profile);
        }
        else {
            techProfile = avatarPath + techician?.user_profile;
        }
    }


    const selectedJobNumber = async (job_id) => {

        let filterJob = jobData?.filter((c) => c.id === job_id);

        if (filterJob[0]) {

            let customerType = filterJob[0]?.vehicle?.user?.customers_details[0]?.type;
            let customerName = filterJob[0]?.vehicle?.user?.customers_details[0]?.company_name;
            let firstname = filterJob[0]?.vehicle?.user?.customers_details[0]?.firstname || "";
            let lastname = filterJob[0]?.vehicle?.user?.customers_details[0]?.lastname || "";
            if (customerType === "2") {
                customerName = firstname + " " + lastname;
            }

            if (filterJob[0]?.job_status) {
                var colourCell = filterJob[0]?.job_status;
                var textCell = filterJob[0]?.job_status;
                if (colourCell === "Paid") {
                    colourCell = "Payment";
                }
                if (textCell === "Payment") {
                    colourCell = "Payment";
                    textCell = "Paid";
                }
                if (textCell === "InProgress") {
                    textCell = "In Progress";
                }

                if (textCell === "ReadyForInvoice") {
                    textCell = "Ready For Invoice";
                }
                if (colourCell === "Pending") {
                    textCell = "Booked";
                    colourCell = "Pending"
                    if (filterJob[0]?.checkIn_date !== null) {
                        textCell = "Arrived";
                        colourCell = "Arrived"
                    }
                }

                filterJob[0]['textCell'] = textCell;
                filterJob[0]['colourCell'] = colourCell;
            }

            if (filterJob[0]?.user_id === techician?.id) {
                filterJob[0]['is_same_technician'] = true;
            } else {
                filterJob[0]['is_same_technician'] = false;
            }

            let jobDetail = {
                customer_name: customerName,
                rego: filterJob[0]?.vehicle?.rego,
                make: filterJob[0]?.vehicle?.make,
                model: filterJob[0]?.vehicle?.model,
                year: filterJob[0]?.vehicle?.year,
                job_booking_type: filterJob[0]?.job_booking_type?.name || "",
                job_status: filterJob[0]['textCell'] || "",
                job_status_color: filterJob[0]['colourCell'] || "",
                is_same_technician: filterJob[0]?.user_id === techician?.id ? true : false,
            };


            setCurrentTextCell(filterJob[0]['textCell'] || "");
            setCurrentColourCell(filterJob[0]['colourCell'] || "");


            setJobDetailData(jobDetail);

            setSelectedJob(filterJob[0]);

            loadJobClockingHistory(filterJob[0]);
        }
    };


    async function loadJobClockingHistory(filterJob = '') {

        let local_start_time = fromDate + " 00:00:00";
        let local_end_time = fromDate + " 23:59:59";

        const utc_start_time = timeZoneToUTC(
            local_start_time,
            timezoneLable,
            "YYYY-MM-DD HH:mm:ss",
            "YYYY-MM-DD HH:mm:ss"
        );

        const utc_end_time = timeZoneToUTC(
            local_end_time,
            timezoneLable,
            "YYYY-MM-DD HH:mm:ss",
            "YYYY-MM-DD HH:mm:ss"
        );

        let job_id = filterJob ? filterJob?.id : selectedJob?.id;
        let customer_id = filterJob ? filterJob?.customer_id : selectedJob?.customer_id;

        let request = {
            get: "all",
            "conditions": {
                workshop_id: workshop_id,
                user_id: techician?.id,
                job_id: job_id,
                customer_id: customer_id,
                "created_on >= ": utc_start_time,
                "created_on <= ": utc_end_time,
                is_deleted: "0"
            }
        }

        const dataHistory = await getJobClockingHistory({ payload: request });

        if (dataHistory.code === 200) {

            if (dataHistory?.data?.JobClockingHistory.length > 0) {
                setJobHistory(dataHistory?.data?.JobClockingHistory);
                let currentTimeLog = dataHistory?.data?.JobClockingHistory;

                let lastArray = currentTimeLog[currentTimeLog.length - 1];
                setLastTimeLogId(lastArray?.id || "");
                setLastActivity(lastArray?.activity);
            } else {
                setJobHistory("");
                setLastTimeLogId("");
                setLastActivity("");
            }

        } else {
            setJobHistory("");
            setLastTimeLogId("");
            setLastActivity("");
        }

    }


    useEffect(() => {
        if (activityMessage) {
            // showSuccess(activityMessage);
            showSuccess(activityMessage + " & Job Status Changed");
            setActivityMessage("");
        }
    }, [activityMessage])

    return (
        <>
            <div className="col-4 " style={{ padding: "0px 15px 0px 15px" }} >
                <div className="job-outer-clocking">

                    {/* first row */}
                    <div className="technician-detail row text-center" style={{ alignItems: "center" }} >
                        {/* Technician Detail */}
                        <div className="col-md-7">
                            <div className={"job-resource-box "}>
                                <div className="job-resource-profile">
                                    <img className="technician-img" src={techProfile} alt={"Technician"} />
                                </div>
                                <div className="job-right-block">
                                    <p>{techician?.firstname} {techician?.lastname}</p>
                                </div>
                            </div>
                        </div>

                        {/* clocking detail */}
                        <div className="col-md-5 job-clock-detail" style={{ paddingLeft: "0px" }}>

                            <div style={{ textAlign: "end", marginRight: "15px" }} >

                                {lastActivity === "Clock In" &&
                                    <Button style={{ background: "#65CC88", marginRight: "15px" }}
                                        type="button"
                                        variant="outline-secondary"
                                        className="btn-outline rounded-btn statusDetail2"
                                    >
                                        Clocked On
                                    </Button>
                                }
                                {(lastActivity === "Clock Off" || lastActivity === "" || lastActivity === "Finish Job") &&
                                    <Button style={{ background: "red" }}
                                        type="button"
                                        variant="outline-secondary"
                                        className="btn-outline rounded-btn statusDetail2"
                                    >
                                        Clocked Off
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>

                    <hr />

                    {/* job detail START */}
                    <div className="job-detail px-3">
                        <div className="table-summary mt-3">
                            <p className="summary-title mb-3" >Job Detail</p>

                            <div className="user-rego-detail row mt-3 " style={{ alignItems: "center" }} >
                                <div className="col-md-7">
                                    <div className={"select-wrapper form-group align-center "} style={{ display: "block" }} >

                                        <Form.Group className="form-group mx-0">
                                            <Select
                                                className="form-control custom-select"
                                                required
                                                placeholder="Select Job"
                                                onChange={(e) => {
                                                    setContinueJob(e.value);
                                                    if (e.value === 0) {
                                                        setDisplayLog(false);
                                                        setJobHistory("");
                                                        setLastTimeLogId("");
                                                        setLastActivity("");
                                                        setSelectedJob([]);
                                                    }
                                                    if (e.value > 0) {
                                                        selectedJobNumber(e.value);
                                                    }
                                                }}
                                                options={jobList}
                                                isDisabled={lastActivity === "Clock In"}
                                                value={
                                                    jobList.filter(option =>
                                                        option?.value === continueJob)
                                                }
                                            // defaultValue={continueJob}
                                            // menuIsOpen={true}

                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                {statusColor && continueJob > 0 &&

                                    <ColorStatus
                                        continueJob={continueJob}
                                        statusColor={statusColor}
                                        currentTextCell={currentTextCell}
                                        currentColourCell={currentColourCell}
                                    />
                                }
                            </div>

                            {selectedJob && selectedJob?.id && continueJob > 0 &&
                                <div className="user-rego-detail row mt-3 " style={{ alignItems: "top" }} >
                                    <div className="col-md-6">
                                        <div className="user-detail" style={{ alignItems: "center" }}>
                                            <div className="profile">
                                                <i className="fa fa-user"></i>
                                            </div>
                                            <div className="job-right-block">
                                                <p> {jobDetailData?.customer_name} </p>
                                            </div>
                                        </div>
                                        <div className="user-detail mt-2" style={{ alignItems: "center" }}>
                                            <CarMakeLogo make={(jobDetailData && jobDetailData?.make) || ""} />
                                            <div className="job-right-block">
                                                <p> {jobDetailData?.rego} <br /> {jobDetailData?.make} {jobDetailData?.model} {jobDetailData?.year} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="user-detail service_type" style={{ alignItems: "top !important" }}>
                                            <p> {jobDetailData?.job_booking_type} </p>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    {/* job detail END */}


                    {/* Time Clocking START */}
                    <TimeClocking
                        techicianID={techician?.id}
                        workshop_id={workshop_id}
                        selectedJob={selectedJob}
                        setSelectedJob={setSelectedJob}
                        jobList={setJobList}
                        jobData={jobData}
                        setJobData={setJobData}
                        fromDate={fromDate}
                        lastActivity={lastActivity}
                        setLastActivity={setLastActivity}
                        jobHistory={jobHistory}
                        onAddClockToList={async (e, cb) => {

                            let payloadData = "";
                            if (e.activity === "Finish Job") {
                                payloadData = {
                                    workshop_id: workshop_id,
                                    // user_id: selectedJob?.user_id,
                                    user_id: techician?.id,
                                    job_id: selectedJob?.id,
                                    customer_id: selectedJob?.customer_id,
                                    created_by: getSessionData("user_id"),
                                    activity: e.activity,
                                    start_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                                    end_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                                    duration: "00.00",
                                    is_action_done: 1
                                }
                            } else {
                                payloadData = {
                                    workshop_id: workshop_id,
                                    // user_id: selectedJob?.user_id,
                                    user_id: techician?.id,
                                    job_id: selectedJob?.id,
                                    customer_id: selectedJob?.customer_id,
                                    created_by: getSessionData("user_id"),
                                    activity: e.activity,
                                    start_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                                    end_time: "",
                                    duration: "",
                                    is_action_done: 0
                                }
                            }


                            try {

                                const data = await addJobClockingHistory({ payload: payloadData });

                                if (data.code === 200) {

                                    // const clockingData = data?.data?.JobClockingHistory;
                                    // setLastTimeLogId(clockingData?.id || "");
                                    // setLastActivity(clockingData?.activity || "Finish Time");
                                    loadJobClockingHistory();
                                    // if (e.activity === "Finish Job") {
                                    //     setDisplayLog(false);
                                    //     setJobHistory("");
                                    //     setLastTimeLogId("");
                                    //     setLastActivity("");
                                    //     setSelectedJob([]);
                                    // }


                                } else {
                                    showError("Something went wrong! Please try after sometime");
                                }

                                setActivityMessage(e?.message);

                            } catch (error) {
                                showError("Something went wrong! Please try after sometime");
                            }
                        }}
                        onUpdateClockToList={async (e, cb) => {
                            let payloadData = "";

                            payloadData = {
                                id: lastTimeLogId,
                                end_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                                is_action_done: 1
                            }

                            try {

                                const data = await updateJobClockingHistory({ payload: payloadData });
                                if (data.code === 200) {
                                    const clockingData = data?.data?.JobClockingHistory;
                                    console.log(clockingData);
                                } else {
                                    showError("Something went wrong! Please try after sometime");
                                }


                                if (e.message) {
                                    setActivityMessage(e?.message);
                                }
                                //   cb({ main_id: data.id });
                            } catch (error) { }
                        }}
                    />

                    {/* Time Clocking END */}

                    {/* View more button START */}
                    <div className="view-more-time-log  mt-3  mb-3">


                        <Button
                            type="button"
                            className="btn btn-primary rounded-btn view-log-btn"
                            disabled={selectedJob?.length === 0 || continueJob === 0}
                            onClick={(e) => setDisplayLog(!displayLog)}
                        >
                            {displayLog ? (
                                <i className="fa fa-chevron-up mr-1" aria-hidden="true"></i>
                            ) : (
                                <i className="fa fa-chevron-down mr-1" aria-hidden="true"></i>
                            )}
                            View Log
                        </Button>
                    </div>
                    {/* View more button END */}

                    {/* Time Log START */}
                    {displayLog === true &&
                        <TimeLog
                            jobHistory={jobHistory}
                            fromDate={fromDate}
                            loadJobClockingHistory={loadJobClockingHistory}
                        />
                    }
                    {/* Time Log EMD */}


                </div>
            </div>

            {techCounter % 3 === 0 &&

                <div className="col-12 mb-3 mt-3">
                    <p style={{ border: "1px solid black" }} ></p>
                </div>

            }




        </>
    );
};

export default TechnicianDetail;
