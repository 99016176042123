import {
    GET_TECHNICIAN_SCHEDULE,
    GET_TECHNICIAN_SCHEDULE_SUCCESS,
    GET_TECHNICIAN_SCHEDULE_FAIL,
    GET_WORKSHOP_OTHER_DETAILS,
    GET_WORKSHOP_OTHER_DETAILS_SUCCESS,
    GET_WORKSHOP_OTHER_DETAILS_FAIL,
    GET_TECHNICIAN_HOUR,
    GET_TECHNICIAN_HOUR_SUCCESS,
    GET_TECHNICIAN_HOUR_FAIL,
    GET_WORKSHOP_BAYS,
    GET_WORKSHOP_BAYS_SUCCESS,
    GET_WORKSHOP_BAYS_FAIL,
    GET_INSURANCE,
    GET_INSURANCE_SUCCESS,
    GET_INSURANCE_FAIL,
    GET_JOB_TAGS,
    GET_JOB_TAGS_SUCCESS,
    GET_JOB_TAGS_FAIL,
    ADD_JOB_TAGS,
    ADD_JOB_TAGS_SUCCESS,
    ADD_JOB_TAGS_FAIL,
    PRINT_JOB_CARD_PDF,
    PRINT_JOB_CARD_PDF_SUCCESS,
    PRINT_JOB_CARD_PDF_FAIL,
    CHECK_AVAILABLE_HOURS,
    CHECK_AVAILABLE_HOURS_SUCCESS,
    CHECK_AVAILABLE_HOURS_FAIL,
    CREATE_QUOTE_TO_JOB,
    CREATE_QUOTE_TO_JOB_SUCCESS,
    CREATE_QUOTE_TO_JOB_FAIL,
    GET_AVAIALABLE_SLOTS,
    GET_AVAIALABLE_SLOTS_SUCCESS,
    GET_AVAIALABLE_SLOTS_FAIL,
    GET_CARRY_OVER_JOB,
    GET_CARRY_OVER_JOB_SUCCESS,
    GET_CARRY_OVER_JOB_FAIL,
    GET_JOBS_KIT_PARTS,
    GET_JOBS_KIT_PARTS_SUCCESS,
    GET_JOBS_KIT_PARTS_FAIL,
    GET_JOBS_KIT_LABOURS,
    GET_JOBS_KIT_LABOURS_SUCCESS,
    GET_JOBS_KIT_LABOURS_FAIL,
    ADD_MANY_JOB_PARTS,
    ADD_MANY_JOB_PARTS_SUCCESS,
    ADD_MANY_JOB_PARTS_FAIL,
    ADD_MANY_JOB_LABOURS,
    ADD_MANY_JOB_LABOURS_SUCCESS,
    ADD_MANY_JOB_LABOURS_FAIL,
    ADD_MANY_JOB_KIT_PARTS,
    ADD_MANY_JOB_KIT_PARTS_SUCCESS,
    ADD_MANY_JOB_KIT_PARTS_FAIL,
    ADD_MANY_JOB_KIT_LABOURS,
    ADD_MANY_JOB_KIT_LABOURS_SUCCESS,
    ADD_MANY_JOB_KIT_LABOURS_FAIL,
    GET_ACTIVITY,
    GET_ACTIVITY_SUCCESS,
    GET_ACTIVITY_FAIL,
    GET_SCHEDULE_ACTIVITY,
    GET_SCHEDULE_ACTIVITY_SUCCESS,
    GET_SCHEDULE_ACTIVITY_FAIL,
    ADD_ACTIVITY,
    ADD_ACTIVITY_SUCCESS,
    ADD_ACTIVITY_FAIL,
    ADD_SCHEDULE_ACTIVITY,
    ADD_SCHEDULE_ACTIVITY_SUCCESS,
    ADD_SCHEDULE_ACTIVITY_FAIL,
    ADD_MANY_CREATE_JOB,
    ADD_MANY_CREATE_JOB_SUCCESS,
    ADD_MANY_CREATE_JOB_FAIL,
    DELETE_JOB_ACTIVITY,
    DELETE_JOB_ACTIVITY_SUCCESS,
    DELETE_JOB_ACTIVITY_FAIL,
    DELETE_JOB_SCHEDULE_ACTIVITY,
    DELETE_JOB_SCHEDULE_ACTIVITY_SUCCESS,
    DELETE_JOB_SCHEDULE_ACTIVITY_FAIL,
    GET_MONTH_JOB_LIST,
    GET_MONTH_JOB_LIST_SUCCESS,
    GET_MONTH_JOB_LIST_FAIL,
    UPDATE_CARRY_OVER_JOB,
    UPDATE_CARRY_OVER_JOB_SUCCESS,
    UPDATE_CARRY_OVER_JOB_FAIL,
    GET_TIMER_DETAILS_SUCCESS,
    GET_TIMER_DETAILS_FAIL,
    GET_TIMER_DETAILS,
    GET_OPEN_REQUEST_JOB_SUCCESS,
    GET_OPEN_REQUEST_JOB_FAIL,
    GET_OPEN_REQUEST_JOB,
    GET_QUOTE_DETAILS,
    GET_QUOTE_DETAILS_SUCCESS,
    GET_QUOTE_DETAILS_FAIL,
    GET_QUOTE_KIT_PARTS,
    GET_QUOTE_KIT_PARTS_SUCCESS,
    GET_QUOTE_KIT_PARTS_FAIL,
    GET_QUOTE_KIT_LABOURS,
    GET_QUOTE_KIT_LABOURS_SUCCESS,
    GET_QUOTE_KIT_LABOURS_FAIL,
    GET_INSPECTION_QUE_ANS,
    GET_INSPECTION_QUE_ANS_SUCCESS,
    GET_INSPECTION_QUE_ANS_FAIL,
    GET_BAYS_JOB_COUNT,
    GET_BAYS_JOB_COUNT_SUCCESS,
    GET_BAYS_JOB_COUNT_FAIL,
    UPDATE_JOB_ACTIVITY,
    UPDATE_JOB_ACTIVITY_SUCCESS,
    UPDATE_JOB_ACTIVITY_FAIL
} from "./actionTypes";

export const getTechnicianSchedule = () => {
    return {
        type: GET_TECHNICIAN_SCHEDULE,
    };
};

export const getTechnicianScheduleSuccess = (data) => {
    return {
        type: GET_TECHNICIAN_SCHEDULE_SUCCESS,
        payload: data.data.Users,
        message: data.message
    };
};

export const getTechnicianScheduleFail = (error) => {
    return {
        type: GET_TECHNICIAN_SCHEDULE_FAIL,
        payload: error,
    };
};

export const getWorkshopOtherDetails = () => {
    return {
        type: GET_WORKSHOP_OTHER_DETAILS,
    };
};

export const getWorkshopOtherDetailsSuccess = (data) => {
    return {
        type: GET_WORKSHOP_OTHER_DETAILS_SUCCESS,
        payload: data.data.WorkshopOtherDetails,
        message: data.message
    };
};

export const getWorkshopOtherDetailsFail = (error) => {
    return {
        type: GET_WORKSHOP_OTHER_DETAILS_FAIL,
        payload: error,
    };
};

export const getTechnicianHour = () => {
    return {
        type: GET_TECHNICIAN_HOUR,
    };
};

export const getTechnicianHourSuccess = (data) => {
    return {
        type: GET_TECHNICIAN_HOUR_SUCCESS,
        payload: data.data.JobSchedules,
        message: data.message
    };
};

export const getTechnicianHourFail = (error) => {
    return {
        type: GET_TECHNICIAN_HOUR_FAIL,
        payload: error,
    };
};

export const getWorkshopBays = () => {
    return {
        type: GET_WORKSHOP_BAYS,
    };
};

export const getWorkshopBaysSuccess = (data) => {
    return {
        type: GET_WORKSHOP_BAYS_SUCCESS,
        payload: data.data.WorkshopBaysDetails,
        message: data.message
    };
};

export const getWorkshopBaysFail = (error) => {
    return {
        type: GET_WORKSHOP_BAYS_FAIL,
        payload: error,
    };
};

export const getInsurance = () => {
    return {
        type: GET_INSURANCE,
    };
};

export const getInsuranceSuccess = (data) => {
    return {
        type: GET_INSURANCE_SUCCESS,
        payload: data?.data?.InsuranceCompany || [],
        message: data.message
    };
};

export const getInsuranceFail = (error) => {
    return {
        type: GET_INSURANCE_FAIL,
        payload: error,
    };
};

export const getJobTags = () => {
    return {
        type: GET_JOB_TAGS,
    };
};

export const getJobTagsSuccess = (data) => {
    return {
        type: GET_JOB_TAGS_SUCCESS,
        payload: data.data.Tags,
        message: data.message
    };
};

export const getJobTagsFail = (error) => {
    return {
        type: GET_JOB_TAGS_FAIL,
        payload: error,
    };
};

export const addJobTags = () => {
    return {
        type: ADD_JOB_TAGS,
    };
};

export const addJobTagsSuccess = (data) => {
    return {
        type: ADD_JOB_TAGS_SUCCESS,
        payload: data.data.Tags,
        message: data.message
    };
};

export const addJobTagsFail = (error) => {
    return {
        type: ADD_JOB_TAGS_FAIL,
        payload: error,
    };
};

export const printJobCardPdf = () => {
    return {
        type: PRINT_JOB_CARD_PDF,
    };
};

export const printJobCardPdfSuccess = (data) => {
    return {
        type: PRINT_JOB_CARD_PDF_SUCCESS,
        payload: data.data.JobSchedules,
        message: data.message
    };
};

export const printJobCardPdfFail = (error) => {
    return {
        type: PRINT_JOB_CARD_PDF_FAIL,
        payload: error,
    };
};

export const checkAvailableHours = () => {
    return {
        type: CHECK_AVAILABLE_HOURS,
    };
};

export const checkAvailableHoursSuccess = (data) => {
    return {
        type: CHECK_AVAILABLE_HOURS_SUCCESS,
        payload: data,
    };
};

export const checkAvailableHoursFail = (error) => {
    return {
        type: CHECK_AVAILABLE_HOURS_FAIL,
        payload: error,
    };
};

export const createQuoteToJob = () => {
    return {
        type: CREATE_QUOTE_TO_JOB,
    };
};

export const createQuoteToJobSuccess = (data) => {
    return {
        type: CREATE_QUOTE_TO_JOB_SUCCESS,
        payload: data,
    };
};

export const createQuoteToJobFail = (error) => {
    return {
        type: CREATE_QUOTE_TO_JOB_FAIL,
        payload: error,
    };
};
export const getAvailableSlots = () => {
    return {
        type: GET_AVAIALABLE_SLOTS,
    };
};

export const getAvailableSlotsSuccess = (data) => {
    return {
        type: GET_AVAIALABLE_SLOTS_SUCCESS,
        payload: data,
    };
};

export const getAvailableSlotsFail = (error) => {
    return {
        type: GET_AVAIALABLE_SLOTS_FAIL,
        payload: error,
    };
};

export const getCarryOverJob = () => {
    return {
        type: GET_CARRY_OVER_JOB,
    };
};

export const getCarryOverJobSuccess = (data) => {
    return {
        type: GET_CARRY_OVER_JOB_SUCCESS,
        payload: data,
    };
};

export const getCarryOverJobFail = (error) => {
    return {
        type: GET_CARRY_OVER_JOB_FAIL,
        payload: error,
    };
};

export const getJobsKitParts = () => {
    return {
        type: GET_JOBS_KIT_PARTS,
    };
};

export const getJobsKitPartsSuccess = (data) => {
    return {
        type: GET_JOBS_KIT_PARTS_SUCCESS,
        payload: data,
    };
};

export const getJobsKitPartsFail = (error) => {
    return {
        type: GET_JOBS_KIT_PARTS_FAIL,
        payload: error,
    };
};

export const getJobsKitLabours = () => {
    return {
        type: GET_JOBS_KIT_LABOURS,
    };
};

export const getJobsKitLaboursSuccess = (data) => {
    return {
        type: GET_JOBS_KIT_LABOURS_SUCCESS,
        payload: data,
    };
};

export const getJobsKitLaboursFail = (error) => {
    return {
        type: GET_JOBS_KIT_LABOURS_FAIL,
        payload: error,
    };
};

export const addManyJobParts = () => {
    return {
        type: ADD_MANY_JOB_PARTS,
    };
};

export const addManyJobPartsSuccess = (data) => {
    return {
        type: ADD_MANY_JOB_PARTS_SUCCESS,
        payload: data,
    };
};

export const addManyJobPartsFail = (error) => {
    return {
        type: ADD_MANY_JOB_PARTS_FAIL,
        payload: error,
    };
};

export const addManyJobLabours = () => {
    return {
        type: ADD_MANY_JOB_LABOURS,
    };
};

export const addManyJobLaboursSuccess = (data) => {
    return {
        type: ADD_MANY_JOB_LABOURS_SUCCESS,
        payload: data,
    };
};

export const addManyJobLaboursFail = (error) => {
    return {
        type: ADD_MANY_JOB_LABOURS_FAIL,
        payload: error,
    };
};

export const addManyJobKitParts = () => {
    return {
        type: ADD_MANY_JOB_KIT_PARTS,
    };
};

export const addManyJobKitPartsSuccess = (data) => {
    return {
        type: ADD_MANY_JOB_KIT_PARTS_SUCCESS,
        payload: data,
    };
};

export const addManyJobKitPartsFail = (error) => {
    return {
        type: ADD_MANY_JOB_KIT_PARTS_FAIL,
        payload: error,
    };
};

export const addManyJobKitLabours = () => {
    return {
        type: ADD_MANY_JOB_KIT_LABOURS,
    };
};

export const addManyJobKitLaboursSuccess = (data) => {
    return {
        type: ADD_MANY_JOB_KIT_LABOURS_SUCCESS,
        payload: data,
    };
};

export const addManyJobKitLaboursFail = (error) => {
    return {
        type: ADD_MANY_JOB_KIT_LABOURS_FAIL,
        payload: error,
    };
};

export const getActivity = () => {
    return {
        type: GET_ACTIVITY,
    };
};

export const getActivitySuccess = (data) => {
    return {
        type: GET_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const getActivityFail = (error) => {
    return {
        type: GET_ACTIVITY_FAIL,
        payload: error,
    };
};

export const getScheduleActivity = () => {
    return {
        type: GET_SCHEDULE_ACTIVITY,
    };
};

export const getScheduleActivitySuccess = (data) => {
    return {
        type: GET_SCHEDULE_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const getScheduleActivityFail = (error) => {
    return {
        type: GET_SCHEDULE_ACTIVITY_FAIL,
        payload: error,
    };
};

export const addActivity = () => {
    return {
        type: ADD_ACTIVITY,
    };
};

export const addActivitySuccess = (data) => {
    return {
        type: ADD_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const addActivityFail = (error) => {
    return {
        type: ADD_ACTIVITY_FAIL,
        payload: error,
    };
};

export const addScheduleActivity = () => {
    return {
        type: ADD_SCHEDULE_ACTIVITY,
    };
};

export const addScheduleActivitySuccess = (data) => {
    return {
        type: ADD_SCHEDULE_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const addScheduleActivityFail = (error) => {
    return {
        type: ADD_SCHEDULE_ACTIVITY_FAIL,
        payload: error,
    };
};

export const addManyCreateJob = () => {
    return {
        type: ADD_MANY_CREATE_JOB,
    };
};

export const addManyCreateJobSuccess = (data) => {
    return {
        type: ADD_MANY_CREATE_JOB_SUCCESS,
        payload: data,
    };
};

export const addManyCreateJobFail = (error) => {
    return {
        type: ADD_MANY_CREATE_JOB_FAIL,
        payload: error,
    };
};

export const deleteJobActivity = () => {
    return {
        type: DELETE_JOB_ACTIVITY,
    };
};

export const deleteJobActivitySuccess = (data) => {
    return {
        type: DELETE_JOB_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const deleteJobActivityFail = (error) => {
    return {
        type: DELETE_JOB_ACTIVITY_FAIL,
        payload: error,
    };
};

export const deleteJobScheduleActivity = () => {
    return {
        type: DELETE_JOB_SCHEDULE_ACTIVITY,
    };
};

export const deleteJobScheduleActivitySuccess = (data) => {
    return {
        type: DELETE_JOB_SCHEDULE_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const deleteJobScheduleActivityFail = (error) => {
    return {
        type: DELETE_JOB_SCHEDULE_ACTIVITY_FAIL,
        payload: error,
    };
};

export const getMonthJobList = () => {
    return {
        type: GET_MONTH_JOB_LIST,
    };
};

export const getMonthJobListSuccess = (data) => {
    return {
        type: GET_MONTH_JOB_LIST_SUCCESS,
        payload: data,
    };
};

export const getMonthJobListFail = (error) => {
    return {
        type: GET_MONTH_JOB_LIST_FAIL,
        payload: error,
    };
};

export const updateCarryOverJob = () => {
    return {
        type: UPDATE_CARRY_OVER_JOB,
    };
};

export const updateCarryOverJobSuccess = (data) => {
    return {
        type: UPDATE_CARRY_OVER_JOB_SUCCESS,
        payload: data,
    };
};

export const updateCarryOverJobFail = (error) => {
    return {
        type: UPDATE_CARRY_OVER_JOB_FAIL,
        payload: error,
    };
};

export const getTimerDetails = () => {
    return {
        type: GET_TIMER_DETAILS,
    };
};

export const getTimerDetailsSuccess = (data) => {
    return {
        type: GET_TIMER_DETAILS_SUCCESS,
        payload: data,
    };
};

export const getTimerDetailsFail = (error) => {
    return {
        type: GET_TIMER_DETAILS_FAIL,
        payload: error,
    };
};

export const getOpenRequestJob = () => {
    return {
        type: GET_OPEN_REQUEST_JOB,
    };
};

export const getOpenRequestJobSuccess = (data) => {
    return {
        type: GET_OPEN_REQUEST_JOB_SUCCESS,
        payload: data,
    };
};

export const getOpenRequestJobFail = (error) => {
    return {
        type: GET_OPEN_REQUEST_JOB_FAIL,
        payload: error,
    };
};

export const getQuoteDetails = () => {
    return {
        type: GET_QUOTE_DETAILS,
    };
};

export const getQuoteDetailsSuccess = (data) => {
    return {
        type: GET_QUOTE_DETAILS_SUCCESS,
        payload: data,
    };
};

export const getQuoteDetailsFail = (error) => {
    return {
        type: GET_QUOTE_DETAILS_FAIL,
        payload: error,
    };
};

export const getQuoteKitParts = () => {
    return {
        type: GET_QUOTE_KIT_PARTS,
    };
};

export const getQuoteKitPartsSuccess = (data) => {
    return {
        type: GET_QUOTE_KIT_PARTS_SUCCESS,
        payload: data,
    };
};

export const getQuoteKitPartsFail = (error) => {
    return {
        type: GET_QUOTE_KIT_PARTS_FAIL,
        payload: error,
    };
};

export const getQuoteKitLabours = () => {
    return {
        type: GET_QUOTE_KIT_LABOURS,
    };
};

export const getQuoteKitLaboursSuccess = (data) => {
    return {
        type: GET_QUOTE_KIT_LABOURS_SUCCESS,
        payload: data,
    };
};

export const getQuoteKitLaboursFail = (error) => {
    return {
        type: GET_QUOTE_KIT_LABOURS_FAIL,
        payload: error,
    };
};

export const getInspectionQueAns = () => {
    return {
        type: GET_INSPECTION_QUE_ANS,
    };
};

export const getInspectionQueAnsSuccess = (data) => {
    return {
        type: GET_INSPECTION_QUE_ANS_SUCCESS,
        payload: data,
    };
};

export const getInspectionQueAnsFail = (error) => {
    return {
        type: GET_INSPECTION_QUE_ANS_FAIL,
        payload: error,
    };
};

export const getBaysJobCount = () => {
    return {
        type: GET_BAYS_JOB_COUNT,
    };
};

export const getBaysJobCountSuccess = (data) => {
    return {
        type: GET_BAYS_JOB_COUNT_SUCCESS,
        payload: data,
    };
};

export const getBaysJobCountFail = (error) => {
    return {
        type: GET_BAYS_JOB_COUNT_FAIL,
        payload: error,
    };
};

export const updateJobActivity = () => {
    return {
        type: UPDATE_JOB_ACTIVITY,
    };
};

export const updateJobActivitySuccess = (data) => {
    return {
        type: UPDATE_JOB_ACTIVITY_SUCCESS,
        payload: data,
    };
};

export const updateJobActivityFail = (error) => {
    return {
        type: UPDATE_JOB_ACTIVITY_FAIL,
        payload: error,
    };
};