import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_WON_QUOTES,
  GET_WON_QUOTES_COUNT,
} from "../../../../store/wonQuotes/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import LoaderImage from "../../../../assets/images/page-loader.svg";
import SendEmailModal from "../../../modals/sendEmailModal";
import SendSmsModal from "../../../modals/sendSmsModal";
import {
  SEARCH_QUOTE_JOBS,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE,
} from "../../../../store/commonApiStore/actionTypes";
import { searchQuoteJobsSuccess } from "../../../../store/commonApiStore/actions";
import QuoteFooter from "../Footer";
import { showError, showSuccess } from "../../../../utils/alertMessage";
import { addComma } from "utils/numberFunction";
import Form from "react-bootstrap/Form";
import { checkStatusChange } from "store/openQuotes/actions";
import { editQuotes } from "helpers/backendHelper";

const WonQuotesList = ({ activeTab }) => {
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(300);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [emptyMessage, setEmptyMessage] = useState("No data available in table");

  const [selectedRow, setSelectedRow] = useState([]);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [sendSmsModal, setSendSmsModal] = useState(false);
  const [selectedRowEmail, setSelectedRowEmail] = useState({});
  const [selectedRowPhone, setSelectedRowPhone] = useState({});
  const [selectedRowPhoneCode, setSelectedRowPhoneCode] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [sendNotificationTo, setSendNotificationTo] = useState("");
  const [notificationTemplate, setNotificationTemplate] = useState("");

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const localStorageData = JSON.parse(localStorage.getItem("data"));
  const dispatch = useDispatch();
  const send_page = "job_list";
  const navigate = useNavigate();
  const dateFormat = storedData.date_format;
  const currencySymbol = storedData.currency || "$";

  const { wonQuotesCount } = useSelector((state) => state.WonQuotesReducer);
  const {
    statusColor,
    jobSmsData,
    jobEmailData,
    searchQuoteJobs,
    loadingLogins,
  } = useSelector((state) => state.CommonApiStoreReducer);
  const { statusChange } = useSelector((state) => state.OpenQuotesReducer);
  let emailTemplateData = {
    jobEmailData: jobEmailData ? jobEmailData : null,
  };
  let smsTemplateData = {
    jobSmsData: jobSmsData ? jobSmsData : null,
  };

  let shareAdditionalDetailsEmail = {
    job_id: [selectedRow],
    send_method: "email",
    table: "chkQuotes",
    send_page: "quote",
    sendType: "",
  };
  let shareAdditionalDetailsSms = {
    job_id: [selectedRow],
    send_method: "sms",
    table: "chkQuotes",
    send_page: "quote",
    sendType: "",
  };

  useEffect(() => {
    if (setTotalRecords !== 0) {
      setTotalRecords(wonQuotesCount);
    }
  }, [wonQuotesCount]);

  useEffect(() => {
    if (statusChange) {
      loadData();
      dispatch(checkStatusChange(false));
    }
  }, [statusChange]);

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);

    return responseDate;
  };

  useEffect(() => {
    return () => {
      dispatch(searchQuoteJobsSuccess({}));
    };
  }, []);

  useEffect(() => {
    setSearch("");
  }, [activeTab]);

  const checkStatusColor = (cell, row) => {
    if (statusColor !== null) {
      return (
        <div
          style={{
            background: statusColor[cell],
          }}
          className="jobStatus"
        >
          {cell}
        </div>
      );
    }
  };

  const columns = [
    {
      dataField: "created_on",
      text: "Date",
      formatter: dateFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/view_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "quote_number",
      text: "Quote No.",
      formatter: (cell, row) => {
        return (
          <div>
            <span className="text-blue">{row.quote_number}</span>
            {" "}
            {row.quote_type == 1 && (


              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Estimate</span>
                  </Tooltip>

                }
              >
                <span className="co-tag" style={{ padding: "0 5px" }}>
                  E
                </span>
              </OverlayTrigger>
            )}
            {row.copy_quote == 1 && (

              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Copied Quote</span>
                  </Tooltip>

                }
              >
                <span className="co-tag" style={{ padding: "0 5px" }}>C</span>
              </OverlayTrigger>

            )}
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/view_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "JobSchedules.id",
      text: "Job No.",
      formatter: (cell, row) => {
        return (
          <div>
            <span className="text-blue">{row?.JobSchedules?.job_number || "-"}</span>
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row?.JobSchedules?.id) {
            navigate(`/job_detail/${row?.JobSchedules?.id}`);
          }
        }
      },
    },

    {
      dataField: "Contact Name",
      text: "Contact Name",
      formatter: (cell, row) => {
        let data = "";
        if (row?.user?.customers_details?.[0]) {
          data = row?.user?.customers_details?.[0];
        } else {
          data = row?.user?.customers_details;
        }

        if (typeof data !== "undefined") {
          if (data[0] && typeof data[0] != "undefined") {
            if (data[0]?.type === '1') {
              return `${data[0]?.company_name || ""}`;
            } else {
              return `${data[0]?.firstname || ""} ${data[0]?.lastname || ""}`;
            }
          } else {
            if (data?.type === '1') {
              return data
                ? `${data.company_name || ""}`
                : "";
            } else {
              return data
                ? `${data.firstname || ""} ${data.lastname || ""}`
                : "";
            }
          }
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/view_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "Contact Number",
      text: "Contact Number",
      formatter: (cell, row) => {
        if (row?.user?.customers_details?.[0]) {
          return `${row?.user?.customers_details?.[0]?.mobile_number || ""}`;
        } else {
          return `${row?.user?.customers_details?.mobile_number || ""}`;
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/view_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "rego",
      text: "Rego",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/view_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "job_sub_type.name",
      text: "Quote Type",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/view_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "expiry_date",
      text: "Exp Date",
      formatter: (data) => {
        if (!data) return "";
        return moment(data).format("DD/MM/YYYY");
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/view_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "total_cost",
      text: "Est Total",
      formatter: (data) => {
        return data
          ? `${currencySymbol} ${addComma(data)}`
          : `${currencySymbol} 0.00`;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/view_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "quote_status",
      text: "Status",
      formatter: checkStatusColor,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/view_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <Link
                  to={`/job_detail/${row?.JobSchedules?.id}`}
                  className={row?.JobSchedules?.id || "disable-option"}
                  title="View Job"
                >
                  <i className="fa fa-eye text-info font-size-20"></i>
                  <span>View Job</span>
                </Link>
              </li>
              <li>
                <Link
                  to={`/view_quote/${row.id}`}
                  className=""
                  title="View Quote"
                >
                  <i className="fa fa-eye text-info font-size-20"></i>
                  <span>View</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setSendSmsModal(!sendSmsModal);
                  setSelectedRowPhone({
                    phone: row?.user?.customers_details?.[0]?.mobile_number,
                  });
                  setSelectedRowPhoneCode({
                    phoneCode: row?.user?.customers_details?.[0]?.mobile_code,
                  })
                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className=" " title="SMS">
                  <label className="smsText">SMS</label>
                  <span>SMS</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setEmailModalShow(!emailModalShow);
                  setSelectedRowEmail({ email: row.user.email });

                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className=" " title="EMAIL">
                  <i className="fa fa-envelope font-size-20 text-info"></i>
                  <span>Email</span>
                </Link>
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);

    loadData();
    getTotalCount();
  }, []);

  async function loadData(page = 1, pageLimit = 10) {
    dispatch({
      type: GET_WON_QUOTES,
      payload: {
        get: "all",
        conditions: {
          quote_status: "Won",
          "CustomerQuotes.workshop_id": Number(workshop_id),
        },
        contain: {
          Vehicles: {
            fields: [
              "Vehicles.id",
              "Vehicles.rego",
              "Vehicles.service_due_date",
              "Vehicles.created_on",
              "Vehicles.make",
              "Vehicles.model",
              "Vehicles.user_id",
            ],
          },
          Users: {
            conditions: {
              "Users.workshop_id": Number(workshop_id),
            },
            fields: ["id", "firstname", "email", "phone_number"],
          },
          "Users.CustomersDetails": {
            conditions: {
              workshop_id: Number(workshop_id),
            },
            fields: [
              "CustomersDetails.firstname",
              "CustomersDetails.lastname",
              "CustomersDetails.id",
              "CustomersDetails.user_id",
              "CustomersDetails.mobile_number",
              "CustomersDetails.mobile_code",
              "CustomersDetails.type",
              "CustomersDetails.company_name",
            ],
          },
          CustomerQuotesLabours: {
            fields: [
              "CustomerQuotesLabours.type",
              "CustomerQuotesLabours.id",
              "CustomerQuotesLabours.customer_quotes_id",
              "CustomerQuotesLabours.user_id",
            ],
          },
          JobSubTypes: {
            fields: ["JobSubTypes.name", "JobSubTypes.parent_id"],
          },
          jobSchedules: {
            fields: ["id", "customer_quotes_id", "job_number"]
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
        },
        limit: pageLimit,
        page: page,
        order: {
          "CustomerQuotes.id": "desc",
        },
        fields: [
          "CustomerQuotes.id",
          "CustomerQuotes.workshop_id",
          "CustomerQuotes.user_id",
          "CustomerQuotes.vehicle_id",
          "CustomerQuotes.job_department",
          "CustomerQuotes.job_type",
          "CustomerQuotes.employee_id",
          "CustomerQuotes.quote_number",
          "CustomerQuotes.description",
          "CustomerQuotes.total_cost",
          "CustomerQuotes.quote_status",
          "CustomerQuotes.created_on",
          "CustomerQuotes.quote_number",
          "CustomerQuotes.quote_type",
          "CustomerQuotes.expiry_date",
          "CustomerQuotes.created_on",
          "CustomerQuotes.parts_cost",
          "CustomerQuotes.rego",
          "CustomerQuotes.copy_quote",
        ],
      },
    });
  }

  const getTotalCount = () => {
    dispatch({
      type: GET_WON_QUOTES_COUNT,
      payload: {
        get: "all",
        conditions: {
          quote_status: "Won",
          "CustomerQuotes.workshop_id": Number(workshop_id),
        },
        contain: {
          Vehicles: {
            fields: ["Vehicles.id", "Vehicles.user_id"],
          },
          Users: {
            conditions: {
              "Users.workshop_id": Number(workshop_id),
            },
            fields: ["id", "firstname", "email", "phone_number"],
          },
          "Users.CustomersDetails": {
            conditions: {
              workshop_id: Number(workshop_id),
            },
            fields: [
              "CustomersDetails.firstname",
              "CustomersDetails.lastname",
              "CustomersDetails.id",
              "CustomersDetails.user_id",
            ],
          },
          CustomerQuotesLabours: {
            fields: [
              "CustomerQuotesLabours.type",
              "CustomerQuotesLabours.id",
              "CustomerQuotesLabours.customer_quotes_id",
              "CustomerQuotesLabours.user_id",
            ],
          },
          JobSubTypes: {
            fields: ["JobSubTypes.name", "JobSubTypes.parent_id"],
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
        },

        order: {
          "CustomerQuotes.id": "desc",
        },
        fields: {
          COUNT: "COUNT(CustomerQuotes.id)",
        },
      },
    });
  };

  const wonQuotes = useSelector((state) => state.WonQuotesReducer);

  useEffect(() => {
    if (Object.keys(searchQuoteJobs).length !== 0) {
      var text = [];

      let totalSearchCount = 0;
      for (var key = 0; key < 10; key++) {
        if (searchQuoteJobs[key] !== undefined) {
          totalSearchCount = searchQuoteJobs.total_count;
          text.push(searchQuoteJobs[key]);
        }
      }
      setTotalRecords(totalSearchCount);

      setJobData(text);
    } else {
      if (wonQuotes.wonQuotesData) {
        setJobData(wonQuotes.wonQuotesData);
        setTotalRecords(wonQuotesCount);
      } else if (wonQuotes.wonQuotesData === null) {
        setJobData([]);
        setTotalRecords(0);
      }
    }
  }, [wonQuotes]);

  const expandRow = {
    showExpandColumn: true,

    renderer: (row) => (
      <div>
        <span dangerouslySetInnerHTML={{ __html: row.description }}></span>
      </div>
    ),
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);

    if (search === "") {
      loadData(page, sizePerPage);
    } else {
      let text = [];
      for (
        var key = (page - 1) * sizePerPage;
        key < sizePerPage * page;
        key++
      ) {
        if (searchQuoteJobs[key] !== undefined) text.push(searchQuoteJobs[key]);
      }
      setJobData(text);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setEmptyMessage("No Results Found")

    if (e.target.value !== "") {
      dispatch({
        type: SEARCH_QUOTE_JOBS,
        payload: {
          workshop_id: workshop_id,
          type: "Won",
          keyword: e.target.value,
        },
      });
    } else {
      dispatch(searchQuoteJobsSuccess({}));
      setJobData(wonQuotes.wonQuotesData);
      setTotalRecords(wonQuotesCount);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => { },
  };

  const emptyDataMessage = () => {
    return (
      <div className={(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  const checkBox = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row.id]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.indexOf(row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
    onSelectAll: (isSelected) => {
      if (isSelected) {
        let ids = [];
        for (var key = 0; key < sizePerPage; key++) {
          if (jobData[key] !== undefined) ids.push(jobData[key].id);
        }
        setSelectedRow(ids);
      } else {
        setSelectedRow([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          checked={rest.checked}
          name={rest.name}
          id={rest.id}
          type="checkbox"
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          id={rest.id}
          checked={rest.checked}
          type={mode}
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
  };

  const sendNotification = () => {
    if (selectedRow.length !== 0) {
      if (sendNotificationTo) {
        if (notificationTemplate) {
          dispatch({
            type: SUBMIT_JOB_SMSEMAIL_TEMPLATE,
            payload: {
              workshop_id: workshop_id,
              job_id: selectedRow,
              template_id: notificationTemplate,
              send_method: sendNotificationTo,
              table: "chkQuotes",
              send_type: "bulk",
            },
          });
        } else {
          showError("Please select the Template");
        }
      } else {
        showError("Please select send method SMS / Email");
      }
    } else {
      showError("Please select the Quote");
    }
  };

  const handleEmailModalClose = (is_sent = "") => {
    setEmailModalShow(false);
    if (is_sent === true) {
      if (selectedRow > 0) {
        editEmailStatus();
      }
    }
  }

  const handleSMSModalClose = () => setSendSmsModal(false);

  async function editEmailStatus() {

    let data = {
      payload: {
        id: selectedRow,
        is_email_sent: 1
      },
    };

    if (selectedRow > 0) {
      await editQuotes(data)
        .then((response) => {

        })
        .catch((err) => {

        });
    }

  }

  return (
    <>
      {/* {(wonQuotes.loading || loadingLogins) && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )} */}
      <>
        <div>
          <div className="table-search-wrapper">
            <input
              type="text"
              className="form-control"
              id="search"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
              value={search}
            />
            <label className="dropdown">
              <div className="dd-button btn btn-primary rounded-btn dropdown-toggle">
                <i className="fa fa-plus"> </i> Create Quote
              </div>
              <input type="checkbox" className="dd-input" id="test" />
              <ul className="dd-menu">
                <li onClick={() => navigate(`/quote_create`)}>Create Quote</li>
                <li onClick={() => navigate(`/quote_estimate`)}>
                  Create Estimate
                </li>
              </ul>
            </label>
          </div>

          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: currentPage,
              sizePerPage: sizePerPage,
              totalSize: Number(totalRecords),
              nextPageText: "Next",
              prePageText: "Previous",
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable
                  remote
                  keyField="id"
                  data={jobData}
                  columns={columns}
                  onTableChange={onTableChange}
                  selectRow={checkBox}
                  rowEvents={rowEvents}
                  expandRow={expandRow}
                  noDataIndication={emptyDataMessage}
                  {...paginationTableProps}
                />
                <div>
                  <div className="table-pagination">
                    <div>
                      <span>Show</span>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <span>entries</span>
                    </div>
                    <PaginationTotalStandalone {...paginationProps} />
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </div>
            )}
          </PaginationProvider>

          {emailModalShow && (
            <SendEmailModal
              emailModalShow={emailModalShow}
              handleEmailModalClose={handleEmailModalClose}
              emailTemplateData={emailTemplateData}
              getEmailForEmailModal={selectedRowEmail}
              shareAdditionalDetailsEmail={shareAdditionalDetailsEmail}
              send_page={send_page}
              setIsLoading={setIsLoading}
            />
          )}
          {sendSmsModal && (
            <SendSmsModal
              SMSModalShow={sendSmsModal}
              handleSMSModalClose={handleSMSModalClose}
              smsTemplateData={smsTemplateData}
              getPhoneForPhoneModal={selectedRowPhone}
              getPhoneCodeForPhoneModal={selectedRowPhoneCode}
              shareAdditionalDetailsSms={shareAdditionalDetailsSms}
              send_page={send_page}
              setIsLoading={setIsLoading}
            />
          )}

          <QuoteFooter
            selectedRow={selectedRow}
            refreshData={loadData}
            jobSmsData={jobSmsData}
            jobEmailData={jobEmailData}
            setSendNotificationTo={setSendNotificationTo}
            setNotificationTemplate={setNotificationTemplate}
            sendNotification={sendNotification}
            activeTab={"won"}
          />
        </div>
      </>
    </>
  );
};

export default WonQuotesList;
