import { updateEmployeeClockingHistory } from "helpers/backendHelper";
import moment from "moment";
import { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployeeClockingHistorySuccess } from "store/accountSync/actions";
import { showError, showSuccess } from "utils/alertMessage";
import { timeZoneToUTC } from "utils/timeZoneConvert";
import { convertToHours, utcToTimezone } from "utils/timeZoneConvert";


let TimeLog = ({ activeTechnician, todayDate, fromDate }) => {

    const dispatch = useDispatch();
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let time_format = storedData.time_format;
    let workshop_id = window.atob(storedData.workshop_id);

    const localStorageData = JSON.parse(localStorage.getItem("data"));
    let timezoneLable = localStorageData?.workshop?.workshop_other_details[0]?.timezone_label || "Asia/Kolkata";
    // let timezoneLable =  "Asia/Kolkata";

    const [timeLogData, setTimeLogData] = useState([]);
    const [prevTimeLogData, setPrevTimeLogData] = useState([]);

    const [breakHours, setBreakHours] = useState("00.00");
    const [lunchHours, setLunchHours] = useState("00.00");
    const [clockedHours, setClockedHours] = useState("00.00");
    const [selectedRow, setSelectedRow] = useState("");

    const { technicianDetails } = useSelector((state) => state.AccountSyncReducer);

    useEffect(() => {

        if (technicianDetails !== null && technicianDetails !== undefined) {

            const userTimeLog = technicianDetails.filter((c) => c.id === activeTechnician);

            setTimeLogData([]);
            setPrevTimeLogData(userTimeLog[0]?.employee_clocking_history);

            if (userTimeLog[0]?.employee_clocking_history.length > 0) {

                let timeLog = [];
                let prevTimeLog = [];
                userTimeLog[0]?.employee_clocking_history.map((clock, index) => {
                    let timeData = {};
                    timeData['id'] = clock?.id;
                    timeData['activity'] = clock?.activity;
                    timeData['start_time'] = utcToTimezone(clock?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss");
                    timeData['end_time'] = clock?.end_time ? utcToTimezone(clock?.end_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss") : "-";
                    timeData['display_start_time'] = utcToTimezone(clock?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", time_format || "HH:mm");
                    timeData['display_end_time'] = clock?.end_time ? utcToTimezone(clock?.end_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", time_format || "HH:mm") : "-";
                    timeData['duration'] = clock?.duration;
                    timeData['is_updated'] = clock?.is_updated;
                    timeLog.push(timeData);
                    prevTimeLog.push(timeData);
                });

                setTimeLogData(timeLog);


                const totalBreak = userTimeLog[0]?.employee_clocking_history.filter((c) => c.activity === "Break").reduce(function (sum, current) {
                    var num = current?.duration.split(".");
                    var minutes = num[0] * 60;
                    var rminutes = Math.round(num[1]);
                    var totalMin = parseInt(minutes) + parseInt(rminutes);

                    return parseFloat(sum) + parseFloat(totalMin || 0);
                }, 0);
                setBreakHours(totalBreak.toString());

                const totalLunch = userTimeLog[0]?.employee_clocking_history.filter((c) => c.activity === "Lunch").reduce(function (sum, current) {

                    var num = current?.duration.split(".");
                    var minutes = num[0] * 60;
                    var rminutes = Math.round(num[1]);
                    var totalMin = parseInt(minutes) + parseInt(rminutes);

                    return parseFloat(sum) + parseFloat(totalMin || 0);

                }, 0);
                setLunchHours(totalLunch.toString());

                const totalHours = userTimeLog[0]?.employee_clocking_history.filter((c) => c.activity !== "Lunch").reduce(function (sum, current) {

                    var num = current?.duration.split(".");
                    var minutes = num[0] * 60;
                    var rminutes = Math.round(num[1]);
                    var totalMin = parseInt(minutes) + parseInt(rminutes);

                    return parseFloat(sum) + parseFloat(totalMin || 0);
                }, 0);
                setClockedHours(totalHours.toString());
            } else {
                setBreakHours("00.00");
                setLunchHours("00.00");
                setClockedHours("00.00");
            }

        }

        return () => {
            setSelectedRow("");
        };
    }, [technicianDetails, activeTechnician])

    function cancelEditTime(i) {

        const newData = [...timeLogData];

        newData[i]["display_start_time"] = utcToTimezone(prevTimeLogData[i]?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", time_format || "HH:mm");
        newData[i]["start_time"] = utcToTimezone(prevTimeLogData[i]?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss");
        newData[i]["display_end_time"] = utcToTimezone(prevTimeLogData[i]?.end_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", time_format || "HH:mm");
        newData[i]["end_time"] = utcToTimezone(prevTimeLogData[i]?.end_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss");

        setTimeLogData(newData);
        setSelectedRow("");
    }


    async function clockingEditTime(i) {
        const newData = timeLogData[i];


        let start_time = timeZoneToUTC(newData?.start_time, timezoneLable, "", "YYYY-MM-DD HH:mm:ss");
        let end_time = timeZoneToUTC(newData?.end_time, timezoneLable, "", "YYYY-MM-DD HH:mm:ss");

        let current_time = moment().utc().format('YYYY-MM-DD HH:mm');
        let val_start_time = moment(start_time).format('YYYY-MM-DD HH:mm');
        let val_end_time = moment(end_time).format('YYYY-MM-DD HH:mm');

        if (val_start_time > current_time) {
            showError("Invalid start time, start time is less than to current time");
            return false;
        }

        if (val_start_time > val_end_time) {
            showError("Invalid end time, end time is greater than to start time");
            return false;
        }

        if (val_end_time > current_time) {
            showError("Invalid end time, end time is less than to current time");
            return false;
        }

        if (val_start_time <= current_time && val_end_time <= current_time) {
            console.log('time is ok');
        }

        let payloadData = "";
        if (newData?.activity === "Finish Time") {
            payloadData = {
                id: newData?.id,
                start_time: end_time,
                end_time: end_time,
                is_updated: "1",
                workshop_id: workshop_id,
                user_id: activeTechnician
            }
        } else {
            payloadData = {
                id: newData?.id,
                start_time: start_time,
                end_time: end_time,
                is_updated: "1",
                workshop_id: workshop_id,
                user_id: activeTechnician
            }
        }

        const data = await updateEmployeeClockingHistory({ payload: payloadData });

        if (data.code === 200) {

            const clockingData = data?.data?.EmployeeClockingHistory;


            const updateClocking = technicianDetails.map((user, index) => {

                if (user.id === clockingData.user_id) {

                    const userhistories = user?.employee_clocking_history || [];


                    const indexPart = userhistories.findIndex((c) => c.id === clockingData.id);
                    if (indexPart !== -1) {
                        userhistories[indexPart] = {
                            ...userhistories[indexPart],
                            ...clockingData,
                        };
                    }

                    user['employee_clocking_history'] = userhistories;

                }
                return user;
            });

            dispatch(updateEmployeeClockingHistorySuccess(updateClocking));
            showSuccess("Time log updated");
            setSelectedRow("");
        } else {
            showError("Please enter proper start and stop time, it's mismatch with other records");
            // showError("Something went wrong! Please try after sometime");
        }



    }


    return (
        <>
            <div className="col-md-4 px-0 clocking-history-data">
                <div className="react-bootstrap-table clocking-table">
                    <p className="clocking-title" >Time Log</p>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th />
                                <th>Action</th>
                                <th>Start</th>
                                <th>Stop</th>
                                <th>Duration</th>
                                {/* {fromDate !== todayDate && */}
                                <th>Action</th>
                                {/* } */}
                            </tr>
                        </thead>
                        {timeLogData && timeLogData.length > 0 &&
                            <tbody className="text-capitalize">
                                {timeLogData && timeLogData.map((clock, i) => (

                                    <tr key={"timeLog_" + clock?.id}>
                                        <td />
                                        <td>
                                            {(() => {
                                                if (clock?.activity === "Clocked In") {
                                                    return (
                                                        <div>Clocked On</div>
                                                    )
                                                } else if (clock?.activity === "Finish Time") {
                                                    return (
                                                        <div>Clocked Out</div>
                                                    )
                                                } else {
                                                    return (
                                                        <div>{clock?.activity}</div>
                                                    )
                                                }
                                            })()}
                                            {/* {clock?.activity === "Clocked In" ? "Clocked On" : clock?.activity} */}
                                        </td>
                                        {/* Start Time */}
                                        {clock?.activity === "Finish Time" ? (
                                            <td>-</td>
                                        ) : (
                                            <>
                                                {selectedRow && selectedRow?.id === clock?.id ? (
                                                    <td>

                                                        <Form.Group className="form-group disable">
                                                            <ReactInputMask
                                                                // onBlur={(e) => changeLabourRow(labour, "time")}
                                                                mask="99:99"
                                                                // value={utcToTimezone(clock?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", time_format || "HH:mm")}
                                                                value={clock?.display_start_time || ""}
                                                                onChange={(e) => {
                                                                    const newData = [...timeLogData];

                                                                    let [hr, mn] = e.target.value.split(":");
                                                                    let v = e.target.value
                                                                        .split(":")
                                                                        .join("")
                                                                        .replaceAll("_", "");

                                                                    var new_start_time = "";
                                                                    if (!isNaN(parseInt(mn)) && +mn > 59) {
                                                                        // newData[i]["start_time"] = `${hr}:59`;
                                                                        new_start_time = `${hr}:59`;
                                                                    } else {
                                                                        // newData[i]["start_time"] = e.target.value;
                                                                        new_start_time = e.target.value;
                                                                    }

                                                                    if (+v > 2359) {
                                                                        // newData[i]["start_time"] = "23:59";
                                                                        new_start_time = "23:59";
                                                                    }

                                                                    newData[i]["display_start_time"] = new_start_time;
                                                                    newData[i]["start_time"] = fromDate + " " + new_start_time + ":00";

                                                                    setTimeLogData(newData);
                                                                }}
                                                                className="small-input"
                                                                name={"start_time_" + clock.id}
                                                                id={"start_time_" + clock.id}
                                                            >
                                                                {(inputProps) => (
                                                                    <Form.Control
                                                                        {...inputProps}
                                                                        type="text"
                                                                    />
                                                                )}
                                                            </ReactInputMask>
                                                        </Form.Group>


                                                    </td>
                                                ) : (
                                                    <td>{clock?.display_start_time}</td>
                                                )}
                                            </>
                                        )}

                                        {/* Stop Time */}
                                        {selectedRow && selectedRow?.id === clock?.id ? (

                                            <td>
                                                <Form.Group className="form-group disable">
                                                    <ReactInputMask
                                                        // onBlur={(e) => changeLabourRow(labour, "time")}
                                                        mask="99:99"
                                                        // value={utcToTimezone(clock?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", time_format || "HH:mm")}
                                                        value={clock?.display_end_time || ""}
                                                        onChange={(e) => {
                                                            const newData = [...timeLogData];

                                                            let [hr, mn] = e.target.value.split(":");
                                                            let v = e.target.value
                                                                .split(":")
                                                                .join("")
                                                                .replaceAll("_", "");

                                                            var new_end_time = "";
                                                            if (!isNaN(parseInt(mn)) && +mn > 59) {
                                                                // newData[i]["start_time"] = `${hr}:59`;
                                                                new_end_time = `${hr}:59`;
                                                            } else {
                                                                // newData[i]["start_time"] = e.target.value;
                                                                new_end_time = e.target.value;
                                                            }

                                                            if (+v > 2359) {
                                                                // newData[i]["start_time"] = "23:59";
                                                                new_end_time = "23:59";
                                                            }

                                                            newData[i]["display_end_time"] = new_end_time;
                                                            newData[i]["end_time"] = fromDate + " " + new_end_time + ":00";

                                                            setTimeLogData(newData);
                                                        }}
                                                        className="small-input"
                                                        name={"end_time_" + clock.id}
                                                        id={"end_time_" + clock.id}
                                                    >
                                                        {(inputProps) => (
                                                            <Form.Control
                                                                {...inputProps}
                                                                type="text"
                                                            />
                                                        )}
                                                    </ReactInputMask>
                                                </Form.Group>
                                            </td>

                                        ) : (
                                            <td> {clock?.display_end_time || "-"} </td>
                                        )}

                                        {/* Duration */}
                                        {clock?.activity === "Finish Time" ? (
                                            <td>-</td>
                                        ) : (
                                            <td>{clock?.duration ? clock?.duration.replace(".", ":") : ""}</td>
                                        )}

                                        {/* Action */}
                                        {selectedRow && selectedRow?.id === clock?.id ? (
                                            <td>
                                                <i style={{ color: "green !important" }} onClick={(e) => {
                                                    clockingEditTime(i)
                                                }} className="fa fa-check text-info edit"></i>
                                                <i style={{ color: "red !important" }} onClick={(e) => {
                                                    cancelEditTime(i)
                                                }}
                                                    className="ml-1 fa fa-times text-info cancel"></i>
                                            </td>
                                        ) : (
                                            <td onClick={(e) => {
                                                setSelectedRow(clock);
                                            }}
                                            >
                                                <i className="fa fa-pencil text-info"></i>
                                                {clock?.is_updated === "1" &&
                                                    <span className="ml-1 edited">Edited</span>
                                                }
                                            </td>
                                        )}


                                    </tr>

                                ))}
                            </tbody>
                        }

                        {timeLogData.length === 0 &&
                            <tbody className="text-capitalize text-center">
                                <tr>
                                    <td colSpan={6}> No Time Log Available </td>
                                </tr>
                            </tbody>
                        }
                    </table>

                </div>

                <div className="table-summary mt-3">
                    <p className="summary-title mb-3" >Summary</p>
                    <div className="d-flex mb-2">
                        <div className="col-md-4 px-1">
                            <Button style={{ width: "100%" }} type="button" variant="danger" className="btn-danger rounded-btn start break-button"
                            >Break <br /> {breakHours ? convertToHours(breakHours) : "00:00"}</Button>
                        </div>
                        <div className="col-md-4 px-1">
                            <Button style={{ width: "100%" }} type="button" variant="danger" className="btn-danger rounded-btn remove break-button"
                            >Lunch <br /> {lunchHours ? convertToHours(lunchHours) : "00:00"}</Button>
                        </div>
                        <div className="col-md-4 px-1">
                            <Button style={{ width: "100%" }} type="button" variant="success" className="btn-success rounded-btn remove clocked-button"
                            >Clocked Hours <br /> {clockedHours ? convertToHours(clockedHours) : "00:00"}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TimeLog;
