import { useState } from "react";
import { DatePicker } from "antd";
import scheduleIcon from "assets/images/schedule-blue.svg";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  BookingDiaryWrapper,
  customCalenderButton, customCalenderHeading, iconBtn, currentTimer, topBlock
} from "../styles.module.scss";
import { useDates } from "../useDates";
import Timer from "../Timer";
import ClockingContent from "./clockingContent";

let JobClocking = () => {

  const [currentDay, setCurrentDay] = useState(new Date().getDay());
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));

  const { dates, onNext, onPrevious, updateCurrentDate, currentDate } = useDates();

  const handleDatePickerChange = (date) => {
    updateCurrentDate(moment(date));
  };

  const selectedDate = (date) => {
    setFromDate(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    setCurrentDay(moment(date, 'DD-MM-YYYY').day());
  }

  return (
    <>
      <div className={`job-clocking right-section-wrapper has-white-bg ${BookingDiaryWrapper} m-0`}>

        <div className="row d-flex align-end mb-5">
          <div className="col-md-3 px-0 mb-3 table-header">
            <div className="table-title ml-2">
              <span>Job Clocking</span>
            </div>
          </div>
          <div className="col-md-6 px-0">
            <div className={`${customCalenderHeading}`}>
              <div className="d-flex align-center justify-center">
                <h1 className="m-r-10">
                  {moment(currentDate, "DD-MM-YYYY").format('MMM')} {moment(currentDate, "DD-MM-YYYY").format('YYYY')}
                </h1>
                <Form.Group className="form-group date-picker width-33">
                  <div className="tooltip-datepicker">
                    <img src={scheduleIcon} alt="Schedule"></img>
                    <DatePicker
                      onChange={(date, dateString) => {
                        handleDatePickerChange(date, dateString, 1)
                      }}
                      className="form-control mb-3"
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className={`d-flex justify-center calender-btn no-wrap ${customCalenderButton}`}>
              <Button className={`${iconBtn}`} onClick={onPrevious}>
                <b>&#171;</b>
              </Button>
              {/* //Map dates */}
              {dates.map((date, i) => (
                <Button
                  className={
                    moment(fromDate, 'YYYY-MM-DD').format('DD-MM-YYYY') === date ? "active" : ""
                  }
                  key={date}
                  onClick={() => { selectedDate(date) }}
                >
                  <p className="mb-1">{moment(date, 'DD-MM-YYYY').format("ddd")}</p>
                  <h4>{moment(date, 'DD-MM-YYYY').format("DD")}</h4>
                </Button>
              ))}
              <Button className={`${iconBtn}`} onClick={onNext}>
                <b>&#187;</b>
              </Button>
            </div>
          </div>
          <div className="col-md-3 px-0">
            <div className={`d-flex align-center ${currentTimer}`}>
              <div className={`d-flex align-center ${topBlock}`}>
                <i className="las la-clock"></i>
                <h1>
                  <Timer />
                </h1>
              </div>
              <p>{moment().format("dddd Do MMMM YYYY")}</p>
            </div>
          </div>
        </div>

        <ClockingContent
          fromDate={fromDate}
          currentDay={currentDay}
        />

      </div>
    </>
  );
};

export default JobClocking;
