import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const PurchasesCreate = Loadable({
  loader: () => import("./components/PurchasesCreate"),
  loading: Loading,
});

const PurchasesEdit = Loadable({
  loader: () => import("./components/PurchasesEdit"),
  loading: Loading,
});

const PurchasesView = Loadable({
  loader: () => import("./components/PurchasesView"),
  loading: Loading,
});


const RaisedOrderReceipt = Loadable({
  loader: () => import("./components/RaisedPurchaseOrder/RaisedOrderReceipt"),
  loading: Loading,
});

const PurchaseRaisedOrderView = Loadable({
  loader: () => import("./components/RaisedPurchaseOrder/PurchasesRaisedOrderView"),
  loading: Loading,
});

const CompletedOrderReceipt = Loadable({
  loader: () => import("./components/CompletedPurchasedOrder/CompletedOrderReceipt"),
  loading: Loading,
});

const PurchaseReceipts = Loadable({
  loader: () => import("./components/PurchaseOrderReceipts/PurchaseReceipts"),
  loading: Loading,
});

const PurchasesReceiptsEmail = Loadable({
  loader: () => import("./components/PurchaseOrderReceipts/PurchasesReceiptsEmail"),
  loading: Loading,
});

const CreditedOrderReceipt = Loadable({
  loader: () => import("./components/PurchaseOrderCredits/CreditedOrderReceipt"),
  loading: Loading,
});

const PurchasesCredit = Loadable({
  loader: () => import("./components/PurchasesCredit"),
  loading: Loading,
});

const PurchasesOrderView = Loadable({
  loader: () => import("./components/PurchaseOrderReceipts/PurchasesOrderView"),
  loading: Loading,
});


const PurchasesCreditView = Loadable({
  loader: () => import("./components/PurchaseOrderCredits/PurchasesCreditView"),
  loading: Loading,
});

const CreditedOrderEmail = Loadable({
  loader: () => import("./components/PurchaseOrderCredits/CreditedOrderEmail"),
  loading: Loading,
});

const PurchasesEmail = Loadable({
  loader: () => import("./components/PurchasesEmail"),
  loading: Loading,
});

const PurchasesList = Loadable({
  loader: () => import("./components/PurchasesList"),
  loading: Loading,
});


export const routes = [
  {
    path: "/purchase-create",
    exact: true,
    name: "PurchasesCreate",
    element: PurchasesCreate,
  },
  {
    path: "/purchase-edit/:id",
    exact: true,
    name: "PurchasesEdit",
    element: PurchasesEdit,
  },
  {
    path: "/purchase-view/:id",
    exact: true,
    name: "PurchasesView",
    element: PurchasesView,
  },
  {
    path: "/raised-order-receipt/:id",
    exact: true,
    name: "RaisedOrderReceipt",
    element: RaisedOrderReceipt,
  },
  {
    path: "/purchase-raised-order-view/:id",
    exact: true,
    name: "PurchaseRaisedOrderView",
    element: PurchaseRaisedOrderView,
  },
  {
    path: "/complete-order-receipt/:id",
    exact: true,
    name: "CompletedOrderReceipt",
    element: CompletedOrderReceipt,
  },
  {
    path: "/credited-order-receipt/:id",
    exact: true,
    name: "CreditedOrderReceipt",
    element: CreditedOrderReceipt,
  },
  {
    path: "/credited-order-email/:id",
    exact: true,
    name: "CreditedOrderEmail",
    element: CreditedOrderEmail,
  },
  {
    path: "/purchase-receipt/:id",
    exact: true,
    name: "PurchaseReceipts",
    element: PurchaseReceipts,
  },
  {
    path: "/purchase-receipt-email/:id",
    exact: true,
    name: "PurchasesReceiptsEmail",
    element: PurchasesReceiptsEmail,
  },
  {
    path: "/purchase-credit-view/:id",
    exact: true,
    name: "PurchasesCreditView",
    element: PurchasesCreditView,
  },
  {
    path: "/purchase-order-receipt-view/:id",
    exact: true,
    name: "PurchasesOrderView",
    element: PurchasesOrderView,
  },
  {
    path: "/purchase-credit/:id",
    exact: true,
    name: "PurchasesCredit",
    element: PurchasesCredit,
  },
  {
    path: "/purchase-email/:id",
    exact: true,
    name: "PurchasesEmail",
    element: PurchasesEmail,
  },
  {
    path: "/purchase-list",
    exact: true,
    name: "PurchasesList",
    element: PurchasesList,
  }
];

export * from "./components";
