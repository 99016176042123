import { takeLatest, put, call } from "redux-saga/effects";
import * as url from "../../helpers/urlHelper";

import {
  GET_TEMPLATES,
  DELETE_TEMPLATE,
  GET_DEPARTMENT_LIST,
  ADD_SCRIPT,
  GET_SCRIPT_DETAIL,
  EDIT_SCRIPT,
  COPY_SCRIPT,
} from "./actionTypes";

import {
  getTemplates,
  getTemplatesSuccess,
  getTemplatesFail,
  deleteTemplateSuccess,
  deleteTemplateFail,
  getDepartmentListSuccess,
  getDepartmentListFail,
  addScriptSuccess,
  addScriptFail,
  getScriptDetailSuccess,
  getScriptDetailFail,
  editScriptSuccess,
  editScriptFail,
  copyScriptSuccess,
  copyScriptFail,
} from "./actions";

import {
  getCannedResponseList,
  deleteCannedResponse,
  getJobDepartmentList,
  addScript,
  editScript,
  copyScript,
} from "../../helpers/backendHelper";
import { showSuccess, showError } from "utils/alertMessage";

function* onGetTemplateList(data) {
  try {
    const response = yield call(getCannedResponseList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTemplatesSuccess(response.data));
      } else {
        yield put(getTemplatesFail(response.message));
      }
    } else {
      yield put(getTemplatesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTemplatesFail(error.response));
  }
}

function* onDeleteTemplate(data) {
  try {
    const response = yield call(deleteCannedResponse, data);
    if (response && response.code === 200) {
      const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
      let workshop_id = window.atob(storedData.workshop_id);
      yield put(
        getTemplates({
          get: "all",
          conditions: {
            "WorkshopScriptsDetails.workshop_id": workshop_id,
            "WorkshopScriptsDetails.status": 1,
          },
          contain: {
            WorkshopDepartmentsAccountsDetails: [],
          },
          order: {
            order_by: "asc",
          },
        })
      );
      showSuccess(response.message);
      yield put(deleteTemplateSuccess(response));
    } else {
      yield put(deleteTemplateFail(response.message));
    }
  } catch (error) {
    yield put(deleteTemplateFail(error.response));
  }
}

function* onGetDepartmentList(data) {
  try {
    const response = yield call(getJobDepartmentList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getDepartmentListSuccess(response.data));
      } else {
        yield put(getDepartmentListFail(response.message));
      }
    } else {
      yield put(getDepartmentListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getDepartmentListFail(error.response));
  }
}

function* onAddScript(data) {
  try {
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let workshop_id = window.atob(storedData.workshop_id);
    const response = yield call(addScript, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getTemplates({
            get: "all",
            conditions: {
              "WorkshopScriptsDetails.workshop_id": workshop_id,
              "WorkshopScriptsDetails.status": 1,
            },
            contain: {
              WorkshopDepartmentsAccountsDetails: [],
            },
            order: {
              order_by: "asc",
            },
          })
        );
        yield put(addScriptSuccess(response));
        showSuccess("Script added.");
      } else {
        yield put(addScriptFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(addScriptFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(addScriptFail(error.response));
  }
}

function* onGetScriptDetail(data) {
  try {
    const response = yield call(getCannedResponseList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getScriptDetailSuccess(response.data));
      } else {
        yield put(getScriptDetailFail(response.message));
      }
    } else {
      yield put(getScriptDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getScriptDetailFail(error.response));
  }
}

function* onEditScript(data) {
  try {
    const response = yield call(editScript, data);
    if (response && response.code === 200) {
      showSuccess(response.message);
      yield put(editScriptSuccess(response));
    } else {
      yield put(editScriptFail(response.message));
    }
  } catch (error) {
    yield put(editScriptFail(error.response));
  }
}

function* onCopyScript(data) {
  const uri = url.VIEW_SCRIPT + data.payload.id + ".json";
  try {
    const response = yield call(copyScript, uri);
    if (response && response.code === 200) {
      let strippedHtml =
        response?.data?.WorkshopScriptsDetails?.description.replace(/<[^>]+>|&nbsp;/g,"");
      navigator.clipboard.writeText(strippedHtml);
      yield put(copyScriptSuccess(response?.data?.WorkshopScriptsDetails));
    } else {
      yield put(copyScriptFail(response.message));
    }
  } catch (error) {
    yield put(copyScriptFail(error.response));
  }
}

function* CannedResponsesSaga() {
  yield takeLatest(GET_TEMPLATES, onGetTemplateList);
  yield takeLatest(DELETE_TEMPLATE, onDeleteTemplate);
  yield takeLatest(GET_DEPARTMENT_LIST, onGetDepartmentList);
  yield takeLatest(ADD_SCRIPT, onAddScript);
  yield takeLatest(GET_SCRIPT_DETAIL, onGetScriptDetail);
  yield takeLatest(EDIT_SCRIPT, onEditScript);
  yield takeLatest(COPY_SCRIPT, onCopyScript);
}

export default CannedResponsesSaga;
