import { ToggleButtonGroup, ToggleButton, Form } from "react-bootstrap";
import TimePicker from "react-time-picker-input";

const DayValues = ({ data, days, index, setFieldValue, handleChange }) => {
  const name = (key) => `scheduleData[${index}].${key}`;

  const disable = +data.status === 0;
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="form-group pb-3">
          <input
            type="text"
            value={days[data.day]}
            className="form-control"
            readOnly
          />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group pb-3">
          <ToggleButtonGroup
            type="radio"
            name={`scheduleData[${index}].status`}
            value={data.status.toString()}
          >
            <ToggleButton
              value={"1"}
              id={`options1-${index}`}
              variant="outline-primary"
              className="btn-rounded mb-0 font-size-12"
              name={`scheduleData[${index}].status`}
              onChange={(e) => {
                handleChange(e)
                setFieldValue(name('close_time'), "17:00:00")
                setFieldValue(name('open_time'), "07:30:00")
              }}
            >
              Open
            </ToggleButton>
            <ToggleButton
              value={"0"}
              id={`options2-${index}`}
              name={`scheduleData[${index}].status`}
              variant="outline-primary"
              className="btn-rounded mb-0 font-size-12"
              onChange={handleChange}

            >
              Close
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group pb-3">
          <Form.Group className="form-group width-33 time-input time-input-no-label">
            <TimePicker
              hour12Format={false}
              className="form-control"
              disabled={disable}
              value={!disable ? data.open_time : "--:--"}
              onChange={(e) => {
                setFieldValue(name('open_time'), e);
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group pb-3">
          <Form.Group className="form-group width-33 time-input time-input-no-label">
            <TimePicker
              hour12Format={false}
              className="form-control"
              disabled={disable}
              value={!disable ? data.close_time : "--:--"}
              onChange={(e) => {
                setFieldValue(name('close_time'), e);
              }}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default DayValues;
