import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const InventoryStockHeader = Loadable({
  loader: () => import("./stock/components/StockInventoryHeader"),
  loading: Loading,
});

const ManageStockList = Loadable({
  loader: () => import("./stock/components/ManageStockList"),
  loading: Loading,
});

const StockTakeInventoryHeader = Loadable({
  loader: () => import("./stockTake/components/StockTakeInventoryHeader"),
  loading: Loading,
});

const CreateStockTakeList = Loadable({
  loader: () => import("./stockTake/components/CreateStockTakeList"),
  loading: Loading,
});

const StockTakeHistoryDetailsList = Loadable({
  loader: () => import("./stockTake/components/StockTakeHistoryDetailsList"),
  loading: Loading,
});


export const routes = [
  {
    path: "/stock",
    exact: true,
    name: "InventoryStockHeader",
    element: InventoryStockHeader,
  },
  {
    path: "/manage_stock/:id",
    exact: true,
    name: "ManageStockList",
    element: ManageStockList,
  },
  {
    path: "/stock-take/list",
    exact: true,
    name: "StockTakeInventoryHeader",
    element: StockTakeInventoryHeader,
  },
  {
    path: "/stock-take/create",
    exact: true,
    name: "CreateStockTakeList",
    element: CreateStockTakeList,
  },
  {
    path: "/stock-take/history-detail/:id",
    exact: true,
    name: "StockTakeHistoryDetailsList",
    element: StockTakeHistoryDetailsList,
  },
];



export * from "./";
