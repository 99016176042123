export const GET_SYNC_ACCOUNT = "GET_SYNC_ACCOUNT";
export const GET_SYNC_ACCOUNT_SUCCESS = "GET_SYNC_ACCOUNT_SUCCESS";
export const GET_SYNC_ACCOUNT_FAIL = "GET_SYNC_ACCOUNT_FAIL";

export const GET_TAX_RATES = "GET_TAX_RATES";
export const GET_TAX_RATES_SUCCESS = "GET_TAX_RATES_SUCCESS";
export const GET_TAX_RATES_FAIL = "GET_TAX_RATES_FAIL";

export const GET_PAYMENT_PROCESSING_FEES = "GET_PAYMENT_PROCESSING_FEES";
export const GET_PAYMENT_PROCESSING_FEES_SUCCESS = "GET_PAYMENT_PROCESSING_FEES_SUCCESS";
export const GET_PAYMENT_PROCESSING_FEES_FAIL = "GET_PAYMENT_PROCESSING_FEES_FAIL";


export const GET_PAYMENT_TERMS = "GET_PAYMENT_TERMS";
export const GET_PAYMENT_TERMS_SUCCESS = "GET_PAYMENT_TERMS_SUCCESS";
export const GET_PAYMENT_TERMS_FAIL = "GET_PAYMENT_TERMS_FAIL";


export const EDIT_PAYMENT_TERM = "EDIT_PAYMENT_TERM";
export const EDIT_PAYMENT_TERM_SUCCESS = "EDIT_PAYMENT_TERM_SUCCESS";
export const EDIT_PAYMENT_TERM_FAIL = "EDIT_PAYMENT_TERM_FAIL";


export const ADD_PAYMENT_TERM = "ADD_PAYMENT_TERM";
export const ADD_PAYMENT_TERM_SUCCESS = "ADD_PAYMENT_TERM_SUCCESS";
export const ADD_PAYMENT_TERM_FAIL = "ADD_PAYMENT_TERM_FAIL";


export const UPDATE_PAYMENT_TERM = "UPDATE_PAYMENT_TERM";
export const UPDATE_PAYMENT_TERM_SUCCESS = "UPDATE_PAYMENT_TERM_SUCCESS";
export const UPDATE_PAYMENT_TERM_FAIL = "UPDATE_PAYMENT_TERM_FAIL";


export const UPDATE_PAYMENT_PROCESSING_FEES = "UPDATE_PAYMENT_PROCESSING_FEES";
export const UPDATE_PAYMENT_PROCESSING_FEES_SUCCESS = "UPDATE_PAYMENT_PROCESSING_FEES_SUCCESS";
export const UPDATE_PAYMENT_PROCESSING_FEES_FAIL = "UPDATE_PAYMENT_PROCESSING_FEES_FAIL";


export const WORKSHOP_TAX_UPDATE = "WORKSHOP_TAX_UPDATE";
export const WORKSHOP_TAX_UPDATE_SUCCESS = "WORKSHOP_TAX_UPDATE_SUCCESS";
export const WORKSHOP_TAX_UPDATE_FAIL = "WORKSHOP_TAX_UPDATE_FAIL";


export const ADD_WORKSHOP_OTHER_DETAILS = "ADD_WORKSHOP_OTHER_DETAILS";
export const ADD_WORKSHOP_OTHER_DETAILS_SUCCESS = "ADD_WORKSHOP_OTHER_DETAILS_SUCCESS";
export const ADD_WORKSHOP_OTHER_DETAILS_FAIL = "ADD_WORKSHOP_OTHER_DETAILS_FAIL";


export const ADD_MANY_DEPARTMENT_ACCOUNT = "ADD_MANY_DEPARTMENT_ACCOUNT";
export const ADD_MANY_DEPARTMENT_ACCOUNT_SUCCESS = "ADD_MANY_DEPARTMENT_ACCOUNT_SUCCESS";
export const ADD_MANY_DEPARTMENT_ACCOUNT_FAIL = "ADD_MANY_DEPARTMENT_ACCOUNT_FAIL";


