import LoaderImage from "assets/images/page-loader.svg";
import { getPartList } from "helpers/backendHelper";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { clearPartList } from "store/parts/actions";
import {
  CLEAR_PART_LIST,
  GET_PART_LIST,
  SAVE_PART,
} from "store/parts/actionTypes";
import { getSupplierListSuccess } from "store/supplier/actions";
import {
  ADD_SUPPLIER,
  CHECK_SUPPLIER_EXIST,
  GET_SUPPLIER_LIST,
} from "store/supplier/actionTypes";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import PriceCalculation from "./PriceCalculation";

const CreateNewPart = (props) => {

  const dispatch = useDispatch(); // Dispatch variable

  const supplierList = useSelector((state) => state.SupplierReducer);
  const partsData = useSelector((state) => state.PartReducer);

  let part_gst = getSessionData('part_gst');

  //fetch session storage data
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  //workshop id
  let workshop_id = getWorkshopId();
  let misc_part_id = process?.env?.REACT_APP_MISC_PART;

  let supplierOptions = [];

  // declare all variables
  const [validated, setValidated] = useState(false);
  const [supplierDiv, setSupplierDiv] = useState(false);
  const [partExist, setPartExist] = useState(false);
  const [partNumberValid, setPartNumberValid] = useState(false);
  const [partNameValid, setPartNameValid] = useState(false);
  const [priceList, setPriceList] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [PartNumber, setPartNumber] = useState();
  const [PartName, setPartName] = useState(props?.searchPartName || "");
  const [supplierID, setSupplierID] = useState(props.supplierID || "");
  const [supplierError, setSupplierError] = useState(false);
  const [supplierPlaceholder, setSupplierPlaceholder] =
    useState("Select Supplier");
  const [isFavorite, setIsFavorite] = useState(false);
  const [inventoryChecked, setInventoryChecked] = useState(true);
  const [miscChecked, setMiscChecked] = useState(false);
  const [isMiscPart, setIsMiscPart] = useState(0);
  const [supplierName, setSupplierName] = useState();
  const [supplierEmail, setSupplierEmail] = useState();
  const [supplierExists, setSupplierExists] = useState(false);
  const [isPartSave, setIsPartSave] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [isJobNewForm, setIsJobNewForm] = useState(false);
  const [appendPartData, setAppendPartData] = useState({});

  // hide / show supplier div
  const showSupplierDiv = (supplierDivStatus) => {
    if (supplierDivStatus === true) {
      setSupplierDiv(false);
      setSupplierExists(false);
    } else {
      setSupplierDiv(true);
    }
  };

  useEffect(() => {
    if (props.supplierID) {
      setSupplierID(props.supplierID);
    }
    if (props?.searchPartName) {
      setPartName(props?.searchPartName || "");
    }
    return () => {
      if (props.addType === "purchase_order") {
        dispatch({ type: CLEAR_PART_LIST });
      }
    };
  }, [dispatch, props.addType, props?.searchPartName, props.supplierID]);

  // fetch supplier list
  useEffect(() => {
    if (props.addType === "purchase_order") {
      dispatch(clearPartList());
    }
    if (!supplierList.supplierListData) {
      dispatch({
        type: GET_SUPPLIER_LIST,
        payload: {
          get: "all",
          conditions: {
            status: 1,
            workshop_id: getWorkshopId(),
            is_deleted: 0,
            "name NOT IN ": ["Repco Supplier", "Burson Supplier", "BNT Supplier"],
          },
          order: {
            name: "asc",
          },
        },
      });
    }
  }, [dispatch]);

  // supplier reducer

  if (
    supplierList.supplierListData &&
    Array.isArray(supplierList.supplierListData)
  ) {
    supplierOptions = supplierList.supplierListData.map((supp) => ({
      value: supp.id,
      label: supp.name,
    }));
  }

  function checkSupplierEmail(supplier_email) {
    if (supplierDiv && !!supplier_email?.trim()) {
      dispatch({
        type: CHECK_SUPPLIER_EXIST,
        payload: {
          get: "all",
          conditions: {
            email: supplier_email,
            "id !=": 0,
            is_deleted: 0,
            status: 1,
            workshop_id: workshop_id,
          },
          order: {
            name: "asc",
          },
        },
      });
    }
  }

  function setPartSupplierID(supplier_id) {
    setSupplierID(supplier_id);
    setSupplierDiv(false);
    setSupplierEmail();
    setSupplierName();
    setSupplierExists(false);
  }

  useEffect(() => {
    // if supplier list found set into supplier data
    if (supplierList.supplierData && supplierList.loadingData) {
      const supplier_id = supplierList.supplierData.id;
      setSupplierID(supplier_id);
      if (isPartSave && supplierDiv) {
        saveParts(supplier_id);
      }
    }

    if (supplierDiv) {
      if (supplierList.isSupplierExist) {
        setSupplierExists(true);
      } else {
        setSupplierExists(false);
      }
    }
  }, [supplierList]);

  // parts reducer

  // check part number is exist or not
  const checkPartNumber = async (part_number) => {
    if (part_number.length >= 50) {
      setPartNumberValid(true)
    } else {
      setPartNumberValid(false)
    }

    if (miscChecked === false) {
      if (part_number.trim() !== "") {

        // dispatch({
        //   type: GET_PART_LIST,
        //   payload: {
        //     get: "all",
        //     conditions: {
        //       product_number: part_number,
        //       add_from: 1,
        //       is_deleted: 0,
        //       workshop_id: workshop_id,
        //     },
        //   },
        // });

        let data = {
          payload: {
            get: 'all',
            conditions: {
              'product_number': part_number,
              'workshop_id': workshop_id,
              add_from: 1,
              'is_deleted': '0',
            },
          },
        };

        await getPartList(data).then((res) => {
          if (res.code === 200) {
            if (res.data) {
              setPartExist(true);
            }
          } else {
            setPartExist(false);
          }

          setIsLoading(false);

          setPartNumber(part_number);
        });
      }
    }
    // setPartNumber(part_number);
  };


  useEffect(() => {


    // if (partsData.partListData !== null && partsData.partListData.length > 0) {
    //   setPartExist(true);
    // } else {
    //   setPartExist(false);
    // }

    if (partsData.partsData) {

      setIsLoading(false);
      if (isNewForm === true) {
        setIsNewForm(false);
        saveJobPart(partsData.partsData);
        setAppendPartData(partsData.partsData);
        // resetPartForm();
      }
    }
  }, [partsData]);

  // favorite apply or not

  // handler and set favorite
  const handlerFavorite = (e) => {
    setIsFavorite(!isFavorite);
  };

  // handler part type

  const handlePartType = (e, type) => {
    // setGSTChecked(e.target.checked);
    setPartExist(false);
    setSupplierError(false);
    setValidated(false);
    if (type === "misc") {
      setMiscChecked(true);
      setInventoryChecked(false);
      setIsMiscPart(1);
      setPartNumber("Misc Part");
      setPartExist(false);
      setSupplierID();
      setSupplierEmail();
      setSupplierName();
      setSupplierExists(false);
      setSupplierPlaceholder("No supplier required");
    } else {
      setMiscChecked(false);
      setInventoryChecked(true);
      setIsMiscPart(0);
      setPartNumber("");
      setSupplierPlaceholder("Select supplier");
    }
  };

  async function savePartsSupplier(e) {

    setIsPartSave(true);
    setSupplierError(false);

    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      setValidated(true);
    }

    // setIsLoading(true);
    if (isMiscPart === 0) {
      if (supplierDiv === false) {
        if (supplierID) {
          setSupplierError(false);
        } else {
          setSupplierError(true);
          return false;
        }
      }

      if (supplierDiv === true) {
        setSupplierError(false);
      } else {
        setSupplierError(true);
      }
    } else {
      setSupplierError(false);
    }

    // if (supplierID) {
    if (form.checkValidity()) {
      if (partExist === false && partNumberValid == false && partNameValid == false) {
        setValidated(false);

        if (supplierDiv) {
          setIsLoading(true);
          dispatch({
            type: ADD_SUPPLIER,
            payload: {
              workshop_id: workshop_id,
              name: supplierName,
              email: supplierEmail,
            },
          });
        }

        if (!supplierDiv) {
          setIsLoading(true);
          saveParts();
        }
      }
    }
    // }

  }

  //save product
  function saveParts(supplier_id = "") {
    if (isMiscPart === 0) {
      setIsPartSave(false);
      setIsNewForm(true);

      dispatch({
        type: SAVE_PART,
        payload: {
          purchase_tax_code: priceList?.applyGst ? part_gst : "0",
          purchase_tax_code_is_default: priceList?.applyGst ? "1" : "0",
          workshop_id: workshop_id,
          product_type: 1,
          name: PartName,
          product_number: PartNumber,
          supplier_id: supplier_id !== "" ? supplier_id : supplierID,
          cost_price: priceList.costPrice,
          sell_price: priceList.sellPrice,
          cost_markup_price: priceList.productMarkup,
          gst_price: priceList.gstPrice,
          gst: priceList?.applyGst ? 1 : 0,
          profit_price: priceList.profitPrice,
          retail_price: priceList.retailPrice,
          margin: priceList.productMargin,
          is_favourite: isFavorite,
          add_from:1,
          status: 1,
        },
      });
    }

    if (isMiscPart === 1) {
      let miscObj = {
        workshop_id: workshop_id,
        job_schedule_id: props.id,
        id: misc_part_id,
        name: PartName,
        product_number: PartNumber,
        product_type: 1,
        supplier_id: 0,
        qty: 1,
        cost_price: priceList.costPrice,
        margin: priceList.productMargin,
        sell_price: priceList.sellPrice,
        retail_price: priceList.retailPrice,
        gst_price: priceList.gstPrice,
        gst: priceList?.applyGst ? 1 : 0,
        profit_price: priceList.profitPrice,
        cost_markup_price: priceList.productMarkup,
        is_favourite: "",
        time: 0,
        purchase_tax_code: priceList?.applyGst ? part_gst : "0",
        purchase_tax_code_is_default: priceList?.applyGst ? "1" : "0"
      };
      setAppendPartData(miscObj);
      saveJobPart(miscObj);
    }

    if (supplierDiv) {
      dispatch(getSupplierListSuccess(null));
    }

    setIsLoading(true);
  }

  function resetPartForm() {
    setPartName();
    setPartNumber();
    setSupplierDiv(false);
    setPartExist(false);
    setSupplierID();
    setIsFavorite(false);
    setInventoryChecked(true);
    setMiscChecked(false);
    setIsMiscPart(0);
    setSupplierExists(false);
    setIsNewForm(false);
    setIsJobNewForm(false);
    // setAppendPartData();

    props.handleClose();
  }

  function setPriceDetail(getPriceObj) {
    setPriceList(getPriceObj);
  }

  function saveJobPart(part) {
    if (part) {
      part.is_misc_part = isMiscPart;
      props.savePartDetail(part);
      setIsJobNewForm(true);
      return;
    }
  }

  const JobPartReducer = useSelector((state) => state.JobPartReducer);

  useEffect(() => {
    if (
      JobPartReducer.partsData !== "null" &&
      typeof JobPartReducer.partsData !== "undefined"
    ) {
      if (isJobNewForm) {
        setIsLoading(false);
        // if(isMiscPart === 1){
        //     props.apppendPart(appendPartData);
        // } else {
        //     props.apppendPart(appendPartData);
        // }

        resetPartForm();
        setIsJobNewForm(false);
        setAppendPartData();
      }
    }
  }, [JobPartReducer]);

  return (
    <>
      <Modal
        className="create-new-part"
        size="lg"
        show={props.show}
        onHide={props.handleClose}
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-space-between">
            <span>New Part</span>
            <Button
              type="button"
              className="close-btn"
              onClick={props.handleClose}
            >
              <i className="la la-times"></i>{" "}
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={savePartsSupplier} noValidate validated={validated}>
          <Modal.Body>
            <div className="primary-tab">
              {!props.disableMisc && (
                <div
                  className={
                    "radio-wrapper tab-radio-btn " +
                    (props.addType === "marketing" ||
                      props.addType === "purchase_order"
                      ? "d-none"
                      : "")
                  }
                >
                  <Form.Check
                    className="radio-btn mb-2"
                    type="radio"
                    id="inventory_part_tab"
                  >
                    <Form.Check.Input
                      name="rdoDisplayPart"
                      type="radio"
                      defaultChecked={inventoryChecked}
                      onChange={(e) => handlePartType(e, "inventory")}
                    />
                    <Form.Check.Label>
                      Inventory
                      <span className="check"></span>
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check
                    className="radio-btn mb-2"
                    type="radio"
                    id="misc_part_tab"
                  >
                    <Form.Check.Input
                      name="rdoDisplayPart"
                      type="radio"
                      defaultChecked={miscChecked}
                      onChange={(e) => handlePartType(e, "misc")}
                    />
                    <Form.Check.Label>
                      Misc Part / Once Off
                      <span className="check"></span>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              )}
              <div className="tab-wrapper">
                <div className="form-outer form-align-start">
                  <Form.Group className="form-group width-25">
                    <label>Part Number *</label>
                    <Form.Control
                      type="text"
                      readOnly={miscChecked}
                      required
                      name="part_product_number"
                      id="part_product_number"
                      onBlur={(e) => checkPartNumber(e.target.value)}
                      value={PartNumber}
                      onChange={(e) => {
                        setPartNumber(e.target.value)
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required.
                    </Form.Control.Feedback>
                    <div
                      className={
                        "invalid-feedback " +
                        (partExist === true ? "show" : "hide")
                      }
                      id="part_product_number_error"
                    >
                      This part number already exists.
                    </div>
                    <div
                      className={
                        "invalid-feedback " +
                        (partNumberValid === true ? "show" : "hide")
                      }
                      id="part_product_number_id_error"
                    >
                      Maximum character limit is 50.
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group width-25">
                    <label>Part Name *</label>
                    <Form.Control
                      type="text"
                      required
                      name="part_name"
                      id="part_name"
                      value={PartName}
                      onChange={(e) => setPartName(e.target.value)}
                      onBlur={(e) => {
                        if ((e.target.value).length >= 50) {
                          setPartNameValid(true)
                        } else {
                          setPartNameValid(false)
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required.
                    </Form.Control.Feedback>
                    <div
                      className={
                        "invalid-feedback " +
                        (partNameValid === true ? "show" : "hide")
                      }
                      id="part_product_name_id_error"
                    >
                      Maximum character limit is 50.
                    </div>
                  </Form.Group>
                  <div
                    className={
                      "select-block select-wrapper d-flex justify-space-between width-25 " +
                      (props.addType === "purchase_order" ? "d-none" : "")
                    }
                  >
                    <Form.Group className="form-group mb-3">
                      <label>Supplier *</label>
                      <Select
                        className="form-control custom-select"
                        isDisabled={miscChecked || supplierDiv === true}
                        placeholder={supplierPlaceholder}
                        required
                        name="part_supplier_id"
                        id="part_supplier_id"
                        options={supplierOptions}
                        onChange={(e) => setPartSupplierID(e.value)}
                      />
                      {supplierError && supplierDiv === false && (
                        <span
                          class="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          This field is required.
                        </span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="form-group width-25 ">
                    <div className={isMiscPart === 1 ? "hide" : "show"}>
                      <label>Favorite</label>
                      <Form.Check
                        className="custom-check mb-2"
                        id="new_part_is_favorite"
                      >
                        <Form.Check.Input
                          type="checkbox"
                          name="favorite"
                          className="custom-check secondary-check mb-2"
                          value="1"
                          onChange={(e) => handlerFavorite(e.value)}
                        />
                        <Form.Check.Label>
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  </div>

                  <div
                    className={
                      "form-group width-25 " +
                      (isMiscPart === 0 ? "show" : "hide")
                    }
                  >
                    <Button
                      className={
                        "rounded-btn btn-success " +
                        (props.addType === "purchase_order" ? "d-none" : "")
                      }
                      variant="primary"
                      onClick={() => showSupplierDiv(supplierDiv)}
                    >
                      New Supplier
                    </Button>
                  </div>
                  <Form.Group
                    className={
                      "form-group width-25 " +
                      (supplierDiv === true ? "show" : "hide")
                    }
                  >
                    <label>Supplier Name *</label>
                    <Form.Control
                      required={supplierDiv === true}
                      type="text"
                      id="supplier_name"
                      name="supplier_name"
                      onChange={(e) => setSupplierName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className={
                      "form-group width-25 " +
                      (supplierDiv === true ? "show" : "hide")
                    }
                  >
                    <label>Supplier Email *</label>
                    <Form.Control
                      required={supplierDiv === true}
                      type="email"
                      id="supplier_email"
                      name="supplier_email"
                      onChange={(e) => setSupplierEmail(e.target.value)}
                      onBlur={(e) => checkSupplierEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter supplier email.
                    </Form.Control.Feedback>
                    <div
                      className={
                        "invalid-feedback " +
                        (supplierExists === true ? "show" : "hide")
                      }
                    >
                      This supplier email is already exist.
                    </div>
                  </Form.Group>
                </div>
              </div>

              <PriceCalculation setPriceDetail={setPriceDetail} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="outline-secondary"
              className="btn-outline rounded-btn"
              onClick={resetPartForm}
            >
              Cancel
            </Button>
            <Button
              className="rounded-btn btn-success"
              variant="primary"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* {isLoading === true && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )} */}
    </>
  );
};
export default CreateNewPart;