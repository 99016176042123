import { Button, Modal } from 'react-bootstrap';
import ReactPlayer from "react-player";

const VimeoPlayer = ({show,onHide,url,title,header}) => {
  return (
    <>

      <Modal
        className="assign-part"
        size="md"
        show={show}
        onHide={onHide}
        centered
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-space-between align-center">
            <div>{header+" - "+title}</div>
          </Modal.Title>
          <Button
                type="button"
                className="close-btn"
                onClick={onHide}
          >
            <i className="la la-times"></i>{" "}
          </Button>
        </Modal.Header>
        <Modal.Body>
        <div className="content-block d-flex align-center justify-space-between">

          <ReactPlayer
              url={url}
              controls
          />

          </div>
        </Modal.Body>
        
      </Modal>
      
    </>
  )
}
export default VimeoPlayer;