// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
  isSupported
} from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCsLves83qq0nnQILbwFSIwOIYIlhSDhrY",
  authDomain: "fiiviq-c84b2.firebaseapp.com",
  databaseURL: "https://fiiviq-c84b2.firebaseio.com",
  projectId: "fiiviq-c84b2",
  storageBucket: "fiiviq-c84b2.appspot.com",
  messagingSenderId: "830431736849",
  appId: "1:830431736849:web:0ba3b1faab8b7c4b9b6db0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export let messaging;

(async ()=>{
  const supported = await isSupported()
  if(supported) {
    messaging = getMessaging(app) 
  }
})()


export const getFirebaseToken = () => {
  
  return new Promise(async (resolve, reject) => {
    if(messaging) {
      Notification.requestPermission()
        .then(async () => {
          getToken(messaging, {
            vapidKey:
              "BHDaBkiDEI88ZlOe-eXsMbDJA6xfTquMY_PUv7G2ioP4yu8gnzaNDgA3CgOcvMj0vPb4eAuDX4cE0BYHbizJyFc",
          })
            .then((value) => {
              resolve(value);
            })
            .catch((err) => {
              resolve("");
            });
        })
        .catch((er) => {
        });
    } else {
      resolve("");
    }
  
  });
};

export const onReceiveMessage = (cb) => {
  if(messaging) {
    onMessage(messaging, (payload) => {
      cb(payload);
    });
  }
};

export const deleteFirebaseToken = async () => {
  if(messaging) {
    try {
      const supported = await isSupported()
      if(supported) {
        return deleteToken(messaging);
      }
    } catch(err) {
    }
  }
};
