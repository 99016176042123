import {
    GET_TECHNICIAN_SCHEDULE,
    GET_TECHNICIAN_SCHEDULE_SUCCESS,
    GET_TECHNICIAN_SCHEDULE_FAIL,
    GET_WORKSHOP_OTHER_DETAILS,
    GET_WORKSHOP_OTHER_DETAILS_SUCCESS,
    GET_WORKSHOP_OTHER_DETAILS_FAIL,
    GET_TECHNICIAN_HOUR,
    GET_TECHNICIAN_HOUR_SUCCESS,
    GET_TECHNICIAN_HOUR_FAIL,
    GET_WORKSHOP_BAYS,
    GET_WORKSHOP_BAYS_SUCCESS,
    GET_WORKSHOP_BAYS_FAIL,
    GET_INSURANCE,
    GET_INSURANCE_SUCCESS,
    GET_INSURANCE_FAIL,
    GET_JOB_TAGS,
    GET_JOB_TAGS_SUCCESS,
    GET_JOB_TAGS_FAIL,
    ADD_JOB_TAGS,
    ADD_JOB_TAGS_SUCCESS,
    ADD_JOB_TAGS_FAIL,
    PRINT_JOB_CARD_PDF,
    PRINT_JOB_CARD_PDF_SUCCESS,
    PRINT_JOB_CARD_PDF_FAIL,
    CHECK_AVAILABLE_HOURS,
    CHECK_AVAILABLE_HOURS_SUCCESS,
    CHECK_AVAILABLE_HOURS_FAIL,
    CREATE_QUOTE_TO_JOB,
    CREATE_QUOTE_TO_JOB_SUCCESS,
    CREATE_QUOTE_TO_JOB_FAIL,
    GET_AVAIALABLE_SLOTS,
    GET_AVAIALABLE_SLOTS_SUCCESS,
    GET_AVAIALABLE_SLOTS_FAIL,
    GET_CARRY_OVER_JOB,
    GET_CARRY_OVER_JOB_SUCCESS,
    GET_CARRY_OVER_JOB_FAIL,
    GET_JOBS_KIT_PARTS,
    GET_JOBS_KIT_PARTS_SUCCESS,
    GET_JOBS_KIT_PARTS_FAIL,
    GET_JOBS_KIT_LABOURS,
    GET_JOBS_KIT_LABOURS_SUCCESS,
    GET_JOBS_KIT_LABOURS_FAIL,
    ADD_MANY_JOB_PARTS,
    ADD_MANY_JOB_PARTS_SUCCESS,
    ADD_MANY_JOB_PARTS_FAIL,
    ADD_MANY_JOB_LABOURS,
    ADD_MANY_JOB_LABOURS_SUCCESS,
    ADD_MANY_JOB_LABOURS_FAIL,
    ADD_MANY_JOB_KIT_PARTS,
    ADD_MANY_JOB_KIT_PARTS_SUCCESS,
    ADD_MANY_JOB_KIT_PARTS_FAIL,
    ADD_MANY_JOB_KIT_LABOURS,
    ADD_MANY_JOB_KIT_LABOURS_SUCCESS,
    ADD_MANY_JOB_KIT_LABOURS_FAIL,
    GET_ACTIVITY,
    GET_ACTIVITY_SUCCESS,
    GET_ACTIVITY_FAIL,
    GET_SCHEDULE_ACTIVITY,
    GET_SCHEDULE_ACTIVITY_SUCCESS,
    GET_SCHEDULE_ACTIVITY_FAIL,
    ADD_ACTIVITY,
    ADD_ACTIVITY_SUCCESS,
    ADD_ACTIVITY_FAIL,
    ADD_SCHEDULE_ACTIVITY,
    ADD_SCHEDULE_ACTIVITY_SUCCESS,
    ADD_SCHEDULE_ACTIVITY_FAIL,
    ADD_MANY_CREATE_JOB,
    ADD_MANY_CREATE_JOB_SUCCESS,
    ADD_MANY_CREATE_JOB_FAIL,
    DELETE_JOB_ACTIVITY,
    DELETE_JOB_ACTIVITY_SUCCESS,
    DELETE_JOB_ACTIVITY_FAIL,
    DELETE_JOB_SCHEDULE_ACTIVITY,
    DELETE_JOB_SCHEDULE_ACTIVITY_SUCCESS,
    DELETE_JOB_SCHEDULE_ACTIVITY_FAIL,
    GET_MONTH_JOB_LIST,
    GET_MONTH_JOB_LIST_SUCCESS,
    GET_MONTH_JOB_LIST_FAIL,
    UPDATE_CARRY_OVER_JOB,
    UPDATE_CARRY_OVER_JOB_SUCCESS,
    UPDATE_CARRY_OVER_JOB_FAIL,
    GET_TIMER_DETAILS,
    GET_TIMER_DETAILS_SUCCESS,
    GET_TIMER_DETAILS_FAIL,
    GET_OPEN_REQUEST_JOB,
    GET_OPEN_REQUEST_JOB_SUCCESS,
    GET_OPEN_REQUEST_JOB_FAIL,
    GET_QUOTE_DETAILS,
    GET_QUOTE_DETAILS_SUCCESS,
    GET_QUOTE_DETAILS_FAIL,
    GET_QUOTE_KIT_PARTS,
    GET_QUOTE_KIT_PARTS_SUCCESS,
    GET_QUOTE_KIT_PARTS_FAIL,
    GET_QUOTE_KIT_LABOURS,
    GET_QUOTE_KIT_LABOURS_SUCCESS,
    GET_QUOTE_KIT_LABOURS_FAIL,
    GET_INSPECTION_QUE_ANS,
    GET_INSPECTION_QUE_ANS_SUCCESS,
    GET_INSPECTION_QUE_ANS_FAIL,
    GET_BAYS_JOB_COUNT,
    GET_BAYS_JOB_COUNT_SUCCESS,
    GET_BAYS_JOB_COUNT_FAIL,
    UPDATE_JOB_ACTIVITY,
    UPDATE_JOB_ACTIVITY_SUCCESS,
    UPDATE_JOB_ACTIVITY_FAIL,
} from "./actionTypes";

const initialState = {
    data: [],
    technicianData: null,
    loadingLogins: false,
    workshopOtherDetails: null,
    workshopBaysDetails: null,
    error: {
        message: "",
    },
    success: {
        message: "",
    },
    technicianHourData: null,
    insuranceData: null,
    tagsData: null,
    addtagsData: null,
    jobCardPdfPath: null,
    checkAvailableHours: null,
    createQuoteDetails: null,
    getAvailableSlots: null,
    getCarryOverJob: null,
    getJobsKitParts: null,
    getJobsKitLabours: null,
    jobParts: null,
    jobLabours: null,
    jobKitParts: null,
    jobKitLabours: null,
    activityData: null,
    activityScheduleData: null,
    addActivityData: null,
    addActivityScheduleData: null,
    createJobActivity: null,
    deleteJobActivity: null,
    deleteJobScheduleActivity: null,
    jobData: null,
    updateCarryOverJob: null,
    getTimerDetails: null,
    getOpenRequest: null,
    quoteDetails: null,
    getQuoteKitParts: null,
    getQuoteKitLabours: null,
    getInspectionQueAns:null,
    getBaysJobCount:null,
    updateJobActivity:null
};

const SchedulerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TECHNICIAN_SCHEDULE:
            state = {
                ...state,
                loadingLogins: true,
                technicianData: action.userData,
            };
            break;
        case GET_TECHNICIAN_SCHEDULE_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                technicianData: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_TECHNICIAN_SCHEDULE_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case GET_WORKSHOP_OTHER_DETAILS:
            state = {
                ...state,
                loadingLogins: true,
                workshopOtherDetails: action.WorkshopOtherDetails,
            };
            break;
        case GET_WORKSHOP_OTHER_DETAILS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                workshopOtherDetails: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_WORKSHOP_OTHER_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case GET_TECHNICIAN_HOUR:
            state = {
                ...state,
                loadingLogins: true,
                technicianHourData: action.JobSchedules,
            };
            break;
        case GET_TECHNICIAN_HOUR_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                technicianHourData: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_TECHNICIAN_HOUR_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case GET_WORKSHOP_BAYS:
            state = {
                ...state,
                loadingLogins: true,
                workshopBaysDetails: action.WorkshopBaysDetails,
            };
            break;
        case GET_WORKSHOP_BAYS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                workshopBaysDetails: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_WORKSHOP_BAYS_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case GET_INSURANCE:
            state = {
                ...state,
                loadingLogins: true,
                insuranceData: action.InsuranceCompany,
            };
            break;
        case GET_INSURANCE_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                insuranceData: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_INSURANCE_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case GET_JOB_TAGS:
            state = {
                ...state,
                loadingLogins: true,
                tagsData: action.tagsData,
            };
            break;
        case GET_JOB_TAGS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                tagsData: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_JOB_TAGS_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case ADD_JOB_TAGS:
            state = {
                ...state,
                loadingLogins: true,
                addtagsData: action.addtagsData,
            };
            break;
        case ADD_JOB_TAGS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                addtagsData: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case ADD_JOB_TAGS_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case PRINT_JOB_CARD_PDF:
            state = {
                ...state,
                loadingLogins: true,
                jobCardPdfPath: action.jobCardPdfPath,
            };
            break;
        case PRINT_JOB_CARD_PDF_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                jobCardPdfPath: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case PRINT_JOB_CARD_PDF_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case CHECK_AVAILABLE_HOURS:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case CHECK_AVAILABLE_HOURS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                checkAvailableHours: action.payload
            };
            break;
        case CHECK_AVAILABLE_HOURS_FAIL:
            state = {
                ...state,
                checkAvailableHours: action.payload,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case CREATE_QUOTE_TO_JOB:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case CREATE_QUOTE_TO_JOB_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                createQuoteDetails: action.payload,
            };
            break;
        case CREATE_QUOTE_TO_JOB_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                createQuoteDetails: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_AVAIALABLE_SLOTS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_AVAIALABLE_SLOTS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getAvailableSlots: action.payload,
            };
            break;
        case GET_AVAIALABLE_SLOTS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getAvailableSlots: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_CARRY_OVER_JOB:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_CARRY_OVER_JOB_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getCarryOverJob: action.payload,
            };
            break;
        case GET_CARRY_OVER_JOB_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getCarryOverJob: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_JOBS_KIT_PARTS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_JOBS_KIT_PARTS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getJobsKitParts: action.payload,
            };
            break;
        case GET_JOBS_KIT_PARTS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getJobsKitParts: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_JOBS_KIT_LABOURS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_JOBS_KIT_LABOURS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getJobsKitLabours: action.payload,
            };
            break;
        case GET_JOBS_KIT_LABOURS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getJobsKitLabours: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_MANY_JOB_PARTS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_MANY_JOB_PARTS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                jobParts: action.payload,
            };
            break;
        case ADD_MANY_JOB_PARTS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                jobParts: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_MANY_JOB_LABOURS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_MANY_JOB_LABOURS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                jobLabours: action.payload,
            };
            break;
        case ADD_MANY_JOB_LABOURS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                jobLabours: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case ADD_MANY_JOB_KIT_PARTS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_MANY_JOB_KIT_PARTS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                jobKitParts: action.payload,
            };
            break;
        case ADD_MANY_JOB_KIT_PARTS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                jobKitParts: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_MANY_JOB_KIT_LABOURS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_MANY_JOB_KIT_LABOURS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                jobKitLabours: action.payload,
            };
            break;
        case ADD_MANY_JOB_KIT_LABOURS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                jobKitLabours: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_ACTIVITY:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_ACTIVITY_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                activityData: action.payload,
            };
            break;
        case GET_ACTIVITY_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                activityData: null,
                error: {
                    message: action.payload,
                },
            };
            break;

        case GET_SCHEDULE_ACTIVITY:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_SCHEDULE_ACTIVITY_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                activityScheduleData: action.payload,
            };
            break;
        case GET_SCHEDULE_ACTIVITY_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                activityScheduleData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_ACTIVITY:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_ACTIVITY_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                addActivityData: action.payload,
            };
            break;
        case ADD_ACTIVITY_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                addActivityData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_SCHEDULE_ACTIVITY:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_SCHEDULE_ACTIVITY_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                addActivityScheduleData: action.payload,
            };
            break;
        case ADD_SCHEDULE_ACTIVITY_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                addActivityScheduleData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_MANY_CREATE_JOB:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_MANY_CREATE_JOB_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                createJobActivity: action.payload,
            };
            break;
        case ADD_MANY_CREATE_JOB_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                createJobActivity: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case DELETE_JOB_ACTIVITY:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case DELETE_JOB_ACTIVITY_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                deleteJobActivity: action.payload,
            };
            break;
        case DELETE_JOB_ACTIVITY_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                deleteJobActivity: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case DELETE_JOB_SCHEDULE_ACTIVITY:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case DELETE_JOB_SCHEDULE_ACTIVITY_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                deleteJobScheduleActivity: action.payload,
            };
            break;
        case DELETE_JOB_SCHEDULE_ACTIVITY_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                deleteJobScheduleActivity: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_MONTH_JOB_LIST:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_MONTH_JOB_LIST_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                jobData: action.payload,
            };
            break;
        case GET_MONTH_JOB_LIST_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                jobData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case UPDATE_CARRY_OVER_JOB:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case UPDATE_CARRY_OVER_JOB_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                updateCarryOverJob: action.payload,
            };
            break;
        case UPDATE_CARRY_OVER_JOB_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                updateCarryOverJob: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_TIMER_DETAILS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_TIMER_DETAILS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getTimerDetails: action.payload,
            };
            break;
        case GET_TIMER_DETAILS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getTimerDetails: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_OPEN_REQUEST_JOB:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_OPEN_REQUEST_JOB_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getOpenRequest: action.payload,
            };
            break;
        case GET_OPEN_REQUEST_JOB_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getOpenRequest: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_QUOTE_DETAILS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_QUOTE_DETAILS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                quoteDetails: action.payload,
            };
            break;
        case GET_QUOTE_DETAILS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                quoteDetails: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_QUOTE_KIT_PARTS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_QUOTE_KIT_PARTS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getQuoteKitParts: action.payload,
            };
            break;
        case GET_QUOTE_KIT_PARTS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getQuoteKitParts: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_QUOTE_KIT_LABOURS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_QUOTE_KIT_LABOURS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getQuoteKitLabours: action.payload,
            };
            break;
        case GET_QUOTE_KIT_LABOURS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getQuoteKitLabours: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_INSPECTION_QUE_ANS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_INSPECTION_QUE_ANS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getInspectionQueAns: action.payload,
            };
            break;
        case GET_INSPECTION_QUE_ANS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getInspectionQueAns: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_BAYS_JOB_COUNT:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_BAYS_JOB_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                getBaysJobCount: action.payload,
            };
            break;
        case GET_BAYS_JOB_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                getBaysJobCount: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case UPDATE_JOB_ACTIVITY:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case UPDATE_JOB_ACTIVITY_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                updateJobActivity: action.payload,
            };
            break;
        case UPDATE_JOB_ACTIVITY_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                updateJobActivity: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default SchedulerReducer;