import { apiCall } from "helpers/apiHelper";
import {
  GET_CASH_SALE_CREDIT,
  GET_CASH_SALE_PAYMENTS_CREDITS,
  GET_INVOICE_CREDIT,
} from "helpers/urlHelper";
import { getWorkshopId } from "utils/getWorkshopId";


const api = (url, requestObject) =>
  apiCall(url, { payload: requestObject }, "POST", true);

export async function getCashSaleCreditReceipt(id) {
  const workshop_id = getWorkshopId();
  let invoice, customer, invoices_payments_credits, credits, credit_number;

  const request1 = {
    get: "all",
    conditions: {
      "CashSalePaymentsCredits.id": id,
    },
    contain: {
      CashSaleInvoice: {
        fields: [
          "id",
          "workshop_id",
          "is_part_no_visible",
          "sub_total",
          "total",
          "user_id",
          "vehicle_id",
          "description",
          "rego",
          "gst",
          "cash_sale_no"
        ],
      },
      "CashSaleInvoice.Users.CustomersDetails": {
        conditions: {
          workshop_id: workshop_id,
        },
        fields: [
          "CustomersDetails.id",
          "CustomersDetails.user_id",
          "CustomersDetails.type",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.company_name",
          "CustomersDetails.street_address",
          "CustomersDetails.suburb",
          "CustomersDetails.mobile_number",
          "CustomersDetails.customer_account",
          "CustomersDetails.mobile_code",
          "CustomersDetails.postcode"
        ],
      },
      "CashSaleInvoice.Users.CustomersDetails.Countries":{
        "fields" : ["id","country_name"]
      },
      "CashSaleInvoice.Users.CustomersDetails.States":{
          "fields" : ["id","name"]
      },
      "CashSaleInvoice.Users.States": {
        fields: ["States.id", "States.country_id", "States.name"],
      },
      "CashSaleInvoice.Vehicles": {
        fields: [
          "Vehicles.id",
          "Vehicles.rego",
          "Vehicles.make",
          "Vehicles.model",
          "Vehicles.odometer_reading",
          "Vehicles.user_id",
          "Vehicles.year",
        ],
      },
      "CashSaleInvoice.Workshops": {
        fields: [
          "Workshops.id",
          "Workshops.name",
          "Workshops.street_address",
          "Workshops.suburb",
          "Workshops.postcode",
          "Workshops.phone",
          "Workshops.state",
          "Workshops.email",
        ],
      },
      "CashSaleInvoice.Workshops.WorkshopOtherDetails": {
        fields: [
          "WorkshopOtherDetails.id",
          "WorkshopOtherDetails.workshop_id",
          "WorkshopOtherDetails.abn",
          "WorkshopOtherDetails.bsb",
          "WorkshopOtherDetails.account_number",
        ],
      },
      "CashSaleInvoice.Workshops.States": {
        fields: ["States.id", "States.country_id", "States.name"],
      },
      "CashSaleInvoice.CashSaleInvoiceParts": {
        fields: [
          "id",
          "cash_sale_invoice_id",
          "invoice_part_name",
          "invoice_part_description",
          "qty",
          "gst",
          "invoice_part_priority",
          "sell",
          "retail",
          "time",
          "profit",
          "cost",
          "margin",
          "price",
          "sell_total",
          "gst_total"
        ],
      },
      "CashSaleInvoice.CashSaleInvoiceParts.Products": {
        fields: [
          "id",
          "product_number",
          "name",
          "description",
          "stock_in_hand",
          "product_type",
        ],
      },
      "CashSaleInvoice.CashSaleInvoiceLabours": {
        conditions: {
          "CashSaleInvoiceLabours.workshop_id": workshop_id,
        },
        fields: [
          "id",
          "cash_sale_invoice_id",
          "time",
          "type",
          "labour_description",
          "cost",
          "markup",
          "sell",
          "retail",
          "gst",
          "total",
          "profit",
          "sell_total",
          "gst_total"
        ],
      },
      "CashSaleInvoice.CashSaleInvoiceLabours.LabourCharges": {
        fields: ["id", "labour_code", "description"],
      },
      "CreatedByUser": {
        fields: ["id", "firstname", "lastname", "username", "email"],
      },
    },
    fields: [
      "Users.id",
      "Users.email",
      "Users.state",
      "Users.country",
      "Users.customer_account",
      "CashSalePaymentsCredits.id",
      "CashSalePaymentsCredits.payment_method",
      "CashSalePaymentsCredits.created_on",
      "CashSalePaymentsCredits.description",
      "CashSalePaymentsCredits.amount_due",
      "CashSalePaymentsCredits.balance_remaining",
      "CashSalePaymentsCredits.amount_paid",
      "CashSalePaymentsCredits.cash_sale_invoice_id",
      "CashSalePaymentsCredits.cash_sale_receipt_no",
      "CashSalePaymentsCredits.invoice_type",
      "CashSalePaymentsCredits.receipt_total",
    ],
  };
  const get_invoices = await api(GET_CASH_SALE_PAYMENTS_CREDITS, request1);

  if (get_invoices?.code === 200) {
    invoices_payments_credits = get_invoices?.data?.CashSalePaymentsCredits[0];
    credits =
      invoices_payments_credits?.cash_sale_payments_credits_other_details;
    invoice = invoices_payments_credits?.cash_sale_invoice;
    customer = invoice?.user;

    //Get all invoice credits
    const request2 = {
      get: "all",
      conditions: {
        "InvoicesPaymentsCreditsOtherDetails.workshop_id": workshop_id,
      },
      fields: ["credit_no", "id"],
      order: {
        "InvoicesPaymentsCreditsOtherDetails.id": "desc",
      },
      limit: 1,
    };

    const request3 = {
      get: "all",
      conditions: {
        "CashSalePaymentsCreditsOtherDetails.workshop_id": workshop_id,
      },
      fields: ["cash_sale_credit_no", "id"],
      order: {
        "CashSalePaymentsCreditsOtherDetails.id": "desc",
      },
      limit: 1,
    };
    const get_all_invoice_credits = await api(GET_INVOICE_CREDIT, request2);

    const get_all_cash_sale_credits = await api(GET_CASH_SALE_CREDIT, request3);
    let invoice_credit_no =
      get_all_invoice_credits?.data?.InvoicesPaymentsCreditsOtherDetails[0]
        ?.credit_no || 0;
    let cash_sale_credit_no =
      get_all_cash_sale_credits?.data?.CashSalePaymentsCreditsOtherDetails[0]
        ?.credit_no || 0;

    if (cash_sale_credit_no > invoice_credit_no) {
      credit_number = cash_sale_credit_no + 1;
    } else {
      credit_number = invoice_credit_no + 1;
    }
  }

  return {
    invoice,
    customer,
    invoices_payments_credits,
    credits,
    credit_number,
  };
}

export async function getCashSaleEmailReceipt(id) {
  const workshop_id = getWorkshopId();
  let invoice_receipt, invoice, receipt_number, email, customer, type, workshop;
  const request1 = {
    get: "all",
    conditions: {
      "CashSalePaymentsCredits.id": id,
    },
    contain: {
      CashSaleInvoice: {
        fields: [
          "id",
          "workshop_id",
          "is_part_no_visible",
          "sub_total",
          "total",
          "user_id",
          "vehicle_id",
          "description",
          "rego"
        ],
      },
      "CashSaleInvoice.Users.CustomersDetails": {
        conditions: {
          workshop_id: workshop_id,
        },
        fields: [
          "CustomersDetails.id",
          "CustomersDetails.user_id",
          "CustomersDetails.type",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.company_name",
          "CustomersDetails.street_address",
          "CustomersDetails.suburb",
          "CustomersDetails.mobile_number",
        ],
      },
      "CashSaleInvoice.Users.States": {
        fields: ["States.id", "States.country_id", "States.name"],
      },
      "CashSaleInvoice.Vehicles": {
        fields: [
          "Vehicles.id",
          "Vehicles.rego",
          "Vehicles.make",
          "Vehicles.model",
          "Vehicles.odometer_reading",
          "Vehicles.user_id",
        ],
      },
      "CashSaleInvoice.Workshops": {
        fields: [
          "Workshops.id",
          "Workshops.name",
          "Workshops.street_address",
          "Workshops.suburb",
          "Workshops.postcode",
          "Workshops.phone",
          "Workshops.state",
          "Workshops.email",
        ],
      },
      "CashSaleInvoice.Workshops.WorkshopOtherDetails": {
        fields: [
          "WorkshopOtherDetails.id",
          "WorkshopOtherDetails.workshop_id",
          "WorkshopOtherDetails.abn",
          "WorkshopOtherDetails.bsb",
          "WorkshopOtherDetails.account_number",
        ],
      },
      "CashSaleInvoice.Workshops.States": {
        fields: ["States.id", "States.country_id", "States.name"],
      },
    },
    fields: [
      "Users.id",
      "Users.email",
      "Users.state",
      "Users.country",
      "CashSalePaymentsCredits.id",
      "CashSalePaymentsCredits.payment_method",
      "CashSalePaymentsCredits.created_on",
      "CashSalePaymentsCredits.description",
      "CashSalePaymentsCredits.amount_due",
      "CashSalePaymentsCredits.balance_remaining",
      "CashSalePaymentsCredits.amount_paid",
      "CashSalePaymentsCredits.cash_sale_invoice_id",
      "CashSalePaymentsCredits.cash_sale_receipt_no",
      "CashSalePaymentsCredits.invoice_type",
      "CashSalePaymentsCredits.receipt_total",
    ],
  };

  const get_invoices = await api(GET_CASH_SALE_PAYMENTS_CREDITS, request1);

  if (get_invoices?.code == 200) {
    invoice_receipt = get_invoices?.data?.CashSalePaymentsCredits[0];
    invoice = invoice_receipt?.cash_sale_invoice;
    receipt_number = invoice_receipt?.cash_sale_receipt_no;
    customer = invoice?.user;
    email = invoice?.user?.email;
    workshop = invoice?.workshop;
  }

  return {
    invoice_receipt,
    invoice,
    receipt_number,
    email,
    customer,
    type,
    workshop,
  };
}

export async function viewCashSaleCredit(id) {
  let invoice,
    customer,
    invoices_payments_credit,
    invoices_payments_credits_other;
  const workshop_id = getWorkshopId();
  const request1 = {
    get: "all",
    conditions: {
      "CashSalePaymentsCreditsOtherDetails.id": id,
    },
    contain: {
      0: "Workshops",
      "CashSalePaymentsCredits.CashSaleInvoice.Users.CustomersDetails": {
        conditions: {
          workshop_id: workshop_id,
        },
        fields: [
          "CustomersDetails.id",
          "CustomersDetails.workshop_id",
          "CustomersDetails.user_id",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.company_name",
          "CustomersDetails.postcode",
          "CustomersDetails.mobile_code",
          "CustomersDetails.street_address",
          "CustomersDetails.mobile_number",
          "CustomersDetails.email",
          "CustomersDetails.state",
          "CustomersDetails.type",
          "CustomersDetails.suburb"
        ],
      },
      "CashSalePaymentsCredits.CashSaleInvoice.Users.States": {
        fields: ["id", "name"],
      },
      "CashSalePaymentsCredits.CreatedByUser": {
        fields: ["id", "firstname", "lastname", "username", "email"],
      },
      "CashSalePaymentsCredits.CashSaleInvoice.Users.CustomersDetails.Countries":{
        "fields" : ["id","country_name"]
      },
      "CashSalePaymentsCredits.CashSaleInvoice.Users.CustomersDetails.States":{
          "fields" : ["id","name"]
      },
    },
    fields: [
      "CashSalePaymentsCredits.id",
      "CashSalePaymentsCredits.workshop_id",
      "CashSalePaymentsCredits.cash_sale_invoice_id",
      "CashSalePaymentsCredits.cash_sale_receipt_no",
      "CashSalePaymentsCredits.invoice_type",
      "CashSalePaymentsCredits.payment_method",
      "CashSalePaymentsCredits.amount_due",
      "CashSalePaymentsCredits.amount_paid",
      "CashSalePaymentsCredits.balance_remaining",
      "CashSalePaymentsCredits.description",
      "CashSalePaymentsCredits.created_by",
      "CashSalePaymentsCredits.payment_type",
      "CashSalePaymentsCredits.payment_date",
      "CashSalePaymentsCredits.receipt_total",
      "Users.id",
      "Users.email",
      "Users.state",
      "Users.country",
      "CashSaleInvoice.id",
      "CashSaleInvoice.cash_sale_no",
      "CashSaleInvoice.parts_total_sell",
      "CashSaleInvoice.labour_total_sell",
      "CashSaleInvoice.workshop_id",
      "CashSaleInvoice.user_id",
      "CashSaleInvoice.vehicle_id",
      "CashSaleInvoice.description",
      "CashSaleInvoice.total",
      "CashSaleInvoice.created_on",
      "CashSalePaymentsCreditsOtherDetails.id",
      "CashSalePaymentsCreditsOtherDetails.cash_sale_invoice_id",
      "CashSalePaymentsCreditsOtherDetails.cash_sale_payments_credits_id",
      "CashSalePaymentsCreditsOtherDetails.cash_sale_credit_no",
      "CashSalePaymentsCreditsOtherDetails.amount_credit",
      "CashSalePaymentsCreditsOtherDetails.description",
      "CashSalePaymentsCreditsOtherDetails.amount_paid",
      "CashSalePaymentsCreditsOtherDetails.payment_method",
    ],
  };

  const get_invoices = await api(GET_CASH_SALE_CREDIT, request1);
  if (get_invoices?.code === 200) {
    invoices_payments_credits_other =
      get_invoices?.data?.CashSalePaymentsCreditsOtherDetails[0];
    invoices_payments_credit =
      invoices_payments_credits_other?.cash_sale_payments_credit;
    invoice = invoices_payments_credit?.cash_sale_invoice;
    customer = invoice?.user;
  }

  return {
    invoice,
    customer,
    invoices_payments_credit,
    invoices_payments_credits_other,
  };
}

export async function cashSaleCreditEmail(id) {
  const workshop_id = getWorkshopId();
  let invoices_payments_credits_other,
    invoice,
    credit_number,
    email,
    customer,
    type = "credit",
    workshop;
  const request1 = {
    get: "all",
    conditions: { "CashSalePaymentsCreditsOtherDetails.id": id },
    contain: {
      CashSaleInvoice: {
        fields: [
          "id",
          "workshop_id",
          "is_part_no_visible",
          "sub_total",
          "total",
          "user_id",
          "vehicle_id",
          "description",
          "rego"
        ],
      },
      "CashSaleInvoice.Users.CustomersDetails": {
        conditions: { workshop_id: workshop_id },
        fields: [
          "CustomersDetails.id",
          "CustomersDetails.user_id",
          "CustomersDetails.type",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.company_name",
          "CustomersDetails.street_address",
          "CustomersDetails.suburb",
          "CustomersDetails.mobile_number",
          "CustomersDetails.mobile_code",
          "CustomersDetails.postcode",
        ],
      },
      "CashSaleInvoice.Users.States": {
        fields: ["States.id", "States.country_id", "States.name"],
      },
      "CashSaleInvoice.Users.Countries":{
        "fields" : ["id","country_name"]
      },
      "CashSaleInvoice.Vehicles": {
        fields: [
          "Vehicles.id",
          "Vehicles.rego",
          "Vehicles.make",
          "Vehicles.model",
          "Vehicles.odometer_reading",
          "Vehicles.user_id",
        ],
      },
      "CashSaleInvoice.Workshops": {
        fields: [
          "Workshops.id",
          "Workshops.name",
          "Workshops.street_address",
          "Workshops.suburb",
          "Workshops.postcode",
          "Workshops.phone",
          "Workshops.state",
          "Workshops.email",
        ],
      },
      "CashSaleInvoice.Workshops.WorkshopOtherDetails": {
        fields: [
          "WorkshopOtherDetails.id",
          "WorkshopOtherDetails.workshop_id",
          "WorkshopOtherDetails.abn",
          "WorkshopOtherDetails.bsb",
          "WorkshopOtherDetails.account_number",
        ],
      },
      "CashSaleInvoice.Workshops.States": {
        fields: ["States.id", "States.country_id", "States.name"],
      },
      CashSalePaymentsCredits: {
        fields: [
          "id",
          "payment_method",
          "created_on",
          "description",
          "amount_due",
          "balance_remaining",
          "amount_paid",
          "cash_sale_invoice_id",
          "cash_sale_receipt_no",
          "invoice_type",
          "receipt_total",
        ],
      },
    },
    fields: [
      "Users.id",
      "Users.email",
      "Users.state",
      "Users.country",
      "CashSalePaymentsCreditsOtherDetails.id",
      "CashSalePaymentsCreditsOtherDetails.id",
      "CashSalePaymentsCreditsOtherDetails.cash_sale_invoice_id",
      "CashSalePaymentsCreditsOtherDetails.cash_sale_payments_credits_id",
      "CashSalePaymentsCreditsOtherDetails.cash_sale_credit_no",
      "CashSalePaymentsCreditsOtherDetails.amount_credit",
      "CashSalePaymentsCreditsOtherDetails.description",
      "CashSalePaymentsCreditsOtherDetails.amount_paid",
      "CashSalePaymentsCreditsOtherDetails.payment_method",
    ],
  };
  // debugger;
  const get_invoices = await await api(GET_CASH_SALE_CREDIT, request1);
  if (get_invoices?.code === 200) {
    invoices_payments_credits_other =
      get_invoices?.data?.CashSalePaymentsCreditsOtherDetails[0];
    invoice = invoices_payments_credits_other?.cash_sale_invoice;
    credit_number = invoices_payments_credits_other?.cash_sale_credit_no;

    customer = invoice?.user;
    email = invoice?.user?.email;
    workshop = invoice?.workshop;
  } else {
    throw new Error('Failed to fetch data')
  }

  return {
    invoices_payments_credits_other,
    invoice,
    credit_number,
    email,
    customer,
    type,
    workshop,
  };
}
