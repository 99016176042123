import { call, put, takeLatest } from "redux-saga/effects";
import { showError, showSuccess } from "utils/alertMessage";
import {
  addThirdPartyCompanyDetails,
  editThirdPartyCompany,
  updateThirdPartyCompanyDetails,
} from "../../helpers/backendHelper";
import {
  addThirdPartyCompanyDetailsFail,
  addThirdPartyCompanyDetailsSuccess,
  editThirdPartyCompanyFail,
  editThirdPartyCompanySuccess,
  updateThirdPartyCompanyDetailsFail,
  updateThirdPartyCompanyDetailsSuccess,
} from "./actions";
import {
  ADD_THIRD_PARTY_COMPANY_DETAILS,
  EDIT_THIRD_PARTY_COMPANY,
  UPDATE_THIRD_PARTY_COMPANY_DETAILS,
} from "./actionTypes";

function* onEditThirdPartyCompany(data) {
  try {
    const response = yield call(editThirdPartyCompany, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          editThirdPartyCompanySuccess(response.data.InsuranceCompany[0])
        );
      } else {
        yield put(editThirdPartyCompanyFail(response.message));
      }
    } else {
      yield put(editThirdPartyCompanyFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editThirdPartyCompanyFail(error.response));
  }
}

function* onUpdateThirdPartyCompanyDetails(data) {
  try {
    const navigate = data.payload.navigate;
    const response = yield call(updateThirdPartyCompanyDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          updateThirdPartyCompanyDetailsSuccess(response.data.InsuranceCompany)
        );
        navigate('/contacts_list#ThirdParty');
        showSuccess(response.message);
      } else {
        yield put(updateThirdPartyCompanyDetailsFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateThirdPartyCompanyDetailsFail("Didn't get api response"));
      showError("Didn't get api response");
    }
  } catch (error) {
    yield put(updateThirdPartyCompanyDetailsFail(error.response));
  }
}

function* onAddThirdPartyCompanyDetails(data) {
  try {
    const navigate = data.payload.navigate;
    const response = yield call(addThirdPartyCompanyDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          addThirdPartyCompanyDetailsSuccess(response.data.InsuranceCompany)
        );
        navigate('/contacts_list#ThirdParty');
        showSuccess(response.message);
      } else {
        yield put(addThirdPartyCompanyDetailsFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(addThirdPartyCompanyDetailsFail("Didn't get api response"));
      showError("Didn't get api response");
    }
  } catch (error) {
    yield put(addThirdPartyCompanyDetailsFail(error.response));
  }
}

function* ThirdPartyCompanySaga() {
  yield takeLatest(EDIT_THIRD_PARTY_COMPANY, onEditThirdPartyCompany);
  yield takeLatest(
    UPDATE_THIRD_PARTY_COMPANY_DETAILS,
    onUpdateThirdPartyCompanyDetails
  );
  yield takeLatest(
    ADD_THIRD_PARTY_COMPANY_DETAILS,
    onAddThirdPartyCompanyDetails
  );
}

export default ThirdPartyCompanySaga;
