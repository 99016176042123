export const GET_DETAIL_FOR_CREATE_INVOICE = "GET_DETAIL_FOR_CREATE_INVOICE";
export const GET_DETAIL_FOR_CREATE_INVOICE_SUCCESS =
  "GET_DETAIL_FOR_CREATE_INVOICE_SUCCESS";
export const GET_DETAIL_FOR_CREATE_INVOICE_FAIL =
  "GET_DETAIL_FOR_CREATE_INVOICE_FAIL";

export const GET_INVOICE_REST_DATA = "GET_INVOICE_REST_DATA";
export const GET_INVOICE_REST_DATA_SUCCESS = "GET_INVOICE_REST_DATA_SUCCESS";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAIL = "CREATE_INVOICE_FAIL";

export const GET_DETAIL_FOR_EDIT_INVOICE = "GET_DETAIL_FOR_EDIT_INVOICE";
export const GET_DETAIL_FOR_EDIT_INVOICE_SUCCESS =
  "GET_DETAIL_FOR_EDIT_INVOICE_SUCCESS";
export const GET_DETAIL_FOR_EDIT_INVOICE_FAIL =
  "GET_DETAIL_FOR_EDIT_INVOICE_FAIL";

export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";

export const SEND_STATEMENT_EMAIL = "SEND_STATEMENT_EMAIL";
export const SEND_STATEMENT_EMAIL_SUCCESS = "SEND_STATEMENT_EMAIL_SUCCESS";
export const SEND_STATEMENT_EMAIL_FAIL = "SEND_STATEMENT_EMAIL_FAIL";

export const GET_INVOICE_CREDIT_INFO = "GET_INVOICE_CREDIT_INFO";
export const GET_INVOICE_CREDIT_INFO_SUCCESS =
  "GET_INVOICE_CREDIT_INFO_SUCCESS";
export const GET_INVOICE_CREDIT_INFO_FAIL = "GET_INVOICE_CREDIT_INFO_FAIL";

export const ADD_INVOICE_CREDIT = "ADD_INVOICE_CREDIT";
export const ADD_INVOICE_CREDIT_SUCCESS = "ADD_INVOICE_CREDIT_SUCCESS";
export const ADD_INVOICE_CREDIT_FAIL = "ADD_INVOICE_CREDIT_FAIL";

export const CASH_SALE_CREDITS_SAVE = "CASH_SALE_CREDITS_SAVE";
export const CASH_SALE_CREDITS_SAVE_SUCCESS = "CASH_SALE_CREDITS_SAVE_SUCCESS";
export const CASH_SALE_CREDITS_SAVE_FAIL = "CASH_SALE_CREDITS_SAVE_FAIL";

export const GET_INVOCE_CREDIT_VIEW = "GET_INVOCE_CREDIT_VIEW";
export const GET_INVOCE_CREDIT_VIEW_SUCCESS = "GET_INVOCE_CREDIT_VIEW_SUCCESS";
export const GET_INVOCE_CREDIT_VIEW_FAIL = "GET_INVOCE_CREDIT_VIEW_FAIL";

export const GET_INSURANCE_DETAILS = "GET_INSURANCE_DETAILS";
export const GET_INSURANCE_DETAILS_SUCCESS = "GET_INSURANCE_DETAILS_SUCCESS";
export const GET_INSURANCE_DETAILS_FAIL = "GET_INSURANCE_DETAILS_FAIL";

export const GET_INVOICE_RECEIPT = "GET_INVOICE_RECEIPT";
export const GET_INVOICE_RECEIPT_SUCCESS = "GET_INVOICE_RECEIPT_SUCCESS";
export const GET_INVOICE_RECEIPT_FAIL = "GET_INVOICE_RECEIPT_FAIL";

export const GET_INVOICE_RECEIPT_EMAIL = "GET_INVOICE_RECEIPT_EMAIL";
export const GET_INVOICE_RECEIPT_EMAIL_SUCCESS =
  "GET_INVOICE_RECEIPT_EMAIL_SUCCESS";
export const GET_INVOICE_RECEIPT_EMAIL_FAIL = "GET_INVOICE_RECEIPT_EMAIL_FAIL";

export const GET_INVOICE_CREDIT_EMAIL = "GET_INVOICE_CREDIT_EMAIL";
export const GET_INVOICE_CREDIT_EMAIL_SUCCESS =
  "GET_INVOICE_CREDIT_EMAIL_SUCCESS";
export const GET_INVOICE_CREDIT_EMAIL_FAIL = "GET_INVOICE_CREDIT_EMAIL_FAIL";

export const GET_CASH_SALE_DETAILS = "GET_CASH_SALE_DETAILS";
export const GET_CASH_SALE_DETAILS_SUCCESS = "GET_CASH_SALE_DETAILS_SUCCESS";
export const GET_CASH_SALE_DETAILS_FAIL = "GET_CASH_SALE_DETAILS_FAIL";

export const SAVE_CASH_SALE_DETAILS = "SAVE_CASH_SALE_DETAILS";
export const SAVE_CASH_SALE_DETAILS_SUCCESS = "SAVE_CASH_SALE_DETAILS_SUCCESS";
export const SAVE_CASH_SALE_DETAILS_FAIL = "SAVE_CASH_SALE_DETAILS_FAIL";

export const GET_EDIT_CASH_SALE_DETAIL = "GET_EDIT_CASH_SALE_DETAIL";
export const GET_EDIT_CASH_SALE_DETAIL_SUCCESS =
  "GET_EDIT_CASH_SALE_DETAIL_SUCCESS";
export const GET_EDIT_CASH_SALE_DETAIL_FAIL = "GET_EDIT_CASH_SALE_DETAIL_FAIL";

// export const ADD_CASH_SALE_PART = "ADD_CASH_SALE_PART";
// export const ADD_CASH_SALE_PART_SUCCESS = "ADD_CASH_SALE_PART_SUCCESS";
// export const ADD_CASH_SALE_PART_FAIL = "ADD_CASH_SALE_PART_FAIL";
export const DELETE_CASH_SALE_PART = "DELETE_CASH_SALE_PART";
export const DELETE_CASH_SALE_PART_SUCCESS = "DELETE_CASH_SALE_PART_SUCCESS";
export const DELETE_CASH_SALE_PART_FAIL = "DELETE_CASH_SALE_PART_FAIL";

export const GET_CASH_SALE_CREDIT_RECEIPT = "GET_CASH_SALE_CREDIT_RECEIPT";
export const GET_CASH_SALE_CREDIT_RECEIPT_SUCCESS =
  "GET_CASH_SALE_CREDIT_RECEIPT_SUCCESS";
export const GET_CASH_SALE_CREDIT_RECEIPT_FAIL =
  "GET_CASH_SALE_CREDIT_RECEIPT_FAIL";

export const VIEW_CASH_SALE_RECEIPT = "VIEW_CASH_SALE_RECEIPT";
export const VIEW_CASH_SALE_RECEIPT_SUCCESS = "VIEW_CASH_SALE_RECEIPT_SUCCESS";
export const VIEW_CASH_SALE_RECEIPT_FAIL = "VIEW_CASH_SALE_RECEIPT_FAIL";

export const GET_CASH_SALE_EMAIL_RECEIPT = "GET_CASH_SALE_EMAIL_RECEIPT";
export const GET_CASH_SALE_EMAIL_RECEIPT_SUCCESS =
  "GET_CASH_SALE_EMAIL_RECEIPT_SUCCESS";
export const GET_CASH_SALE_EMAIL_RECEIPT_FAIL =
  "GET_CASH_SALE_EMAIL_RECEIPT_FAIL";

export const VIEW_CASH_SALE_CREDIT = "VIEW_CASH_SALE_CREDIT";
export const VIEW_CASH_SALE_CREDIT_SUCCESS = "VIEW_CASH_SALE_CREDIT_SUCCESS";
export const VIEW_CASH_SALE_CREDIT_FAIL = "VIEW_CASH_SALE_CREDIT_FAIL";

export const CASH_SALE_CREDIT_EMAIL = "CASH_SALE_CREDIT_EMAIL";
export const CASH_SALE_CREDIT_EMAIL_SUCCESS = "CASH_SALE_CREDIT_EMAIL_SUCCESS";
export const CASH_SALE_CREDIT_EMAIL_FAIL = "CASH_SALE_CREDIT_EMAIL_FAIL";

export const CREATE_INVOICE_UNMOUNT = "CREATE_INVOICE_UNMOUNT";


export const SEND_CASHSALE_EMAIL = "SEND_CASHSALE_EMAIL";
export const SEND_CASHSALE_EMAIL_SUCCESS = "SEND_CASHSALE_EMAIL_SUCCESS";
export const SEND_CASHSALE_EMAIL_FAIL = "SEND_CASHSALE_EMAIL_FAIL";

export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";
export const GET_INVOICE_DETAIL_FAIL = "GET_INVOICE_DETAIL_FAIL";

export const GET_INVOICE_CASHSALE_DETAIL = "GET_INVOICE_CASHSALE_DETAIL";
export const GET_INVOICE_CASHSALE_DETAIL_SUCCESS = "GET_INVOICE_CASHSALE_DETAIL_SUCCESS";
export const GET_INVOICE_CASHSALE_DETAIL_FAIL = "GET_INVOICE_CASHSALE_DETAIL_FAIL";

export const UPDATE_INVOICE_EMAIL_DETAILS = "UPDATE_INVOICE_EMAIL_DETAILS";