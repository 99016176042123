export function useCalculate({ values, part_gst, setFieldValue }) {
  const calculate = (key, value) => {

    switch (key) {
      case "applyGst":
        {
          let gstPrice = 0;
          let retailPrice = values.retailPrice;
          if (value) {
            gstPrice = +values.sellPrice * (part_gst / 100);
            retailPrice = +values.sellPrice + gstPrice;
          } else {
            gstPrice = 0;
            retailPrice = +values.sellPrice;
          }
          setFieldValue("applyGst", value);
          setFieldValue("gstPrice", gstPrice.toFixed(2));
          setFieldValue("retailPrice", retailPrice.toFixed(2));
        }
        break;
      case "costPrice":
        {
          let gstPrice = 0;
          let profitPrice = 0,
            retailPrice = 0,
            sellPrice = 0;

          if (value > 0) {
            let productMarkup = +values.productMarkup;
            let productMargin = +values.productMargin;

            let applyGst = values.applyGst;

            if (productMargin) {
              let gross_margin = +productMargin / 100;
              if (1 - gross_margin === 0) {
                sellPrice = +value;
              } else {
                sellPrice = +value / (1 - gross_margin);
              }
              sellPrice = parseFloat(parseFloat(sellPrice.toPrecision(4)).toFixed(2));
              profitPrice = +sellPrice - +value;
              productMarkup = (profitPrice / +value) * 100;

            } else if (productMarkup) {
              let gross_markup = +productMarkup / 100;
              let costPercent = +value * gross_markup;
              sellPrice = parseFloat(costPercent) + parseFloat(value);
              profitPrice = sellPrice - +value;
              productMargin = ((sellPrice - +value) / sellPrice) * 100;
            }

            if (productMarkup === 0 && productMargin === 0) {

              let costPrice = +value;

              let gross_markup = 0;
              let costPercent = costPrice * gross_markup;
              sellPrice = parseFloat(costPercent) + parseFloat(costPrice);
              profitPrice = sellPrice - costPrice;
              productMargin = ((sellPrice - costPrice) / sellPrice) * 100;


            }

            if (applyGst) {
              gstPrice = sellPrice * (part_gst / 100);
            }
            retailPrice = sellPrice + gstPrice;

            setFieldValue("productMarkup", productMarkup.toFixed(2));
            setFieldValue("sellPrice", sellPrice.toFixed(2));
            setFieldValue("profitPrice", profitPrice.toFixed(2));
            setFieldValue("gstPrice", gstPrice.toFixed(2));
            setFieldValue("retailPrice", retailPrice.toFixed(2));
            setFieldValue("productMargin", productMargin.toFixed(2));
          } else {
            setFieldValue("productMarkup", 0);
            setFieldValue("sellPrice", 0);
            setFieldValue("profitPrice", 0);
            setFieldValue("gstPrice", 0);
            setFieldValue("retailPrice", 0);
            setFieldValue("productMargin", 0);
          }
        }
        break;
      case "productMargin":
        {
          let sellPrice = 0;
          let retailPrice = 0;
          let profitPrice = 0;
          let productMarkup = 0;
          let gstPrice = 0;
          let applyGst = values.applyGst;
          let costPrice = +values.costPrice;

          let gross_margin = +value / 100;
          if (1 - gross_margin === 0) {
            sellPrice = costPrice;
          } else {
            sellPrice = costPrice / (1 - gross_margin);
          }
          sellPrice = parseFloat(parseFloat(sellPrice.toPrecision(4)).toFixed(2));
          profitPrice = +sellPrice - costPrice;
          productMarkup = (profitPrice / costPrice) * 100;

          if (applyGst) {
            gstPrice = sellPrice * (part_gst / 100);
          }


          retailPrice = sellPrice + +gstPrice;
          setFieldValue("gstPrice", gstPrice.toFixed(2));
          setFieldValue("sellPrice", sellPrice.toFixed(2));
          setFieldValue("retailPrice", retailPrice.toFixed(2));
          setFieldValue("profitPrice", profitPrice.toFixed(2));
          setFieldValue("productMarkup", productMarkup.toFixed(2));
        }
        break;
      case "productMarkup":
        {
          let sellPrice = 0;
          let retailPrice = 0;
          let profitPrice = 0;
          let productMargin = 0;
          let gstPrice = 0;
          let applyGst = values.applyGst;
          let productMarkup = value;
          let costPrice = +values.costPrice;

          let gross_markup = +productMarkup / 100;
          let costPercent = costPrice * gross_markup;
          sellPrice = parseFloat(costPercent) + parseFloat(costPrice);
          profitPrice = sellPrice - costPrice;
          productMargin = ((sellPrice - costPrice) / sellPrice) * 100;

          if (applyGst) {
            gstPrice = sellPrice * (part_gst / 100);
          }

          retailPrice = sellPrice + +gstPrice;


          setFieldValue("gstPrice", gstPrice.toFixed(2));
          setFieldValue("sellPrice", sellPrice.toFixed(2));
          setFieldValue("retailPrice", retailPrice.toFixed(2));
          setFieldValue("profitPrice", profitPrice.toFixed(2));
          setFieldValue("productMargin", productMargin.toFixed(2));
        }

        break;
      case "sellPrice":
        {
          let retailPrice = 0;
          let profitPrice = 0;
          let productMargin = 0;
          let productMarkup = 0;
          let gstPrice = 0;
          let applyGst = values.applyGst;
          let costPrice = values.costPrice;
          let sellPrice = +value;

          if (value > 0 && costPrice > 0) {

            profitPrice = sellPrice - costPrice;
            productMarkup = (profitPrice * 100) / costPrice;
            productMargin = (profitPrice / sellPrice) * 100;

            if (applyGst) {
              gstPrice = sellPrice * (part_gst / 100);
            }

            retailPrice = sellPrice + +gstPrice;

            setFieldValue("gstPrice", gstPrice.toFixed(2));
            setFieldValue("retailPrice", retailPrice.toFixed(2));
            setFieldValue("profitPrice", profitPrice.toFixed(2));
            setFieldValue("productMarkup", productMarkup.toFixed(2));
            setFieldValue("productMargin", productMargin.toFixed(2));

          } else {

            setFieldValue("gstPrice", 0);
            setFieldValue("retailPrice", 0);
            setFieldValue("profitPrice", 0);
            setFieldValue("productMarkup", 0);
            setFieldValue("productMargin", 0);

          }



        }

        break;
      case "retailPrice":
        {
          let retailPrice = +value;
          let profitPrice = 0;
          let productMargin = 0;
          let productMarkup = 0;
          let gstPrice = 0;
          let applyGst = values.applyGst;
          let costPrice = +values.costPrice;
          let gstPercent = +part_gst / 100;

          let sellPrice = retailPrice;
          if (applyGst) {
            sellPrice = retailPrice / (1 + gstPercent);
          }

          if (value > 0 && sellPrice > 0 && costPrice > 0) {

            profitPrice = sellPrice - costPrice;

            productMarkup = (profitPrice * 100) / costPrice;
            productMargin = (profitPrice / sellPrice) * 100;

            if (applyGst) {
              gstPrice = sellPrice * (part_gst / 100);
            }

            setFieldValue("gstPrice", gstPrice.toFixed(2));
            setFieldValue("sellPrice", sellPrice.toFixed(2));
            setFieldValue("profitPrice", profitPrice.toFixed(2));
            setFieldValue("productMarkup", productMarkup.toFixed(2));
            setFieldValue("productMargin", productMargin.toFixed(2));

          } else {

            setFieldValue("gstPrice", "");
            setFieldValue("sellPrice", "");
            setFieldValue("profitPrice", "");
            setFieldValue("productMarkup", "");
            setFieldValue("productMargin", "");

          }
        }
        break;

      default:
        break;
    }
  };

  return calculate;
}
