import { getInvoicesSalesList } from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";

export async function getEditCashSaleDetail(id) {
  const workshop_id = getWorkshopId();
  // const request1 = {
  //   get: "all",
  //   conditions: {
  //     "CashSaleInvoice.id": id,
  //   },
  //   contain: {
  //     0: "Vehicles",
  //     "Users.customersDetails": {
  //       conditions: {
  //         workshop_id: workshop_id,
  //       },
  //       fields: [
  //         "id",
  //         "user_id",
  //         "firstname",
  //         "lastname",
  //         "street_address",
  //         "suburb",
  //         "postcode",
  //         "phone_number",
  //         "mobile_code",
  //         "mobile_number",
  //         "type",
  //         "company_name",
  //       ],
  //     },
  //     "Users.States": {
  //       fields: ["id", "name"],
  //     },
  //     "Users.Countries": {
  //       fields: ["id", "country_name"],
  //     },
  //     CashSaleInvoiceParts: {
  //       fields: [
  //         "id",
  //         "cash_sale_invoice_id",
  //         "invoice_part_name",
  //         "invoice_part_description",
  //         "qty",
  //         "gst",
  //         "invoice_part_priority",
  //         "sell",
  //         "retail",
  //         "time",
  //         "profit",
  //         "cost",
  //         "margin",
  //       ],
  //     },
  //     "CashSaleInvoiceParts.Products": {
  //       fields: [
  //         "id",
  //         "product_number",
  //         "name",
  //         "description",
  //         "stock_in_hand",
  //         "product_type",
  //       ],
  //     },
  //     CashSaleInvoiceLabours: {
  //       conditions: {
  //         "CashSaleInvoiceLabours.workshop_id": workshop_id,
  //       },
  //       fields: [
  //         "id",
  //         "cash_sale_invoice_id",
  //         "time",
  //         "type",
  //         "labour_description",
  //         "cost",
  //         "markup",
  //         "sell",
  //         "retail",
  //         "gst",
  //         "total",
  //         "profit",
  //       ],
  //     },
  //   },
  //   fields: [
  //     "id",
  //     "user_id",
  //     "vehicle_id",
  //     "workshop_id",
  //     "is_part_no_visible",
  //     "cash_sale_no",
  //     "invoice_dept_id",
  //     "description",
  //     "description_script_id",
  //     "labour_cost",
  //     "labour_markup",
  //     "labour_total_cost",
  //     "discount_rate",
  //     "discount_claim_type",
  //     "discount_cost",
  //     "Vehicles.id",
  //     "Vehicles.rego",
  //     "Vehicles.make",
  //     "Vehicles.model",
  //     "Vehicles.year",
  //     "Vehicles.vehicle_type",
  //     "Users.id",
  //     "Users.email",
  //   ],
  // };

  const request1 = {
    get: "all",
    conditions: {
      "CashSaleInvoice.id": id,
    },
    contain: {
      0: "Vehicles",
      "Users.customersDetails": {
        conditions: {
          workshop_id: workshop_id,
        },
        fields: [
          "id",
          "user_id",
          "firstname",
          "lastname",
          "company_name",
          "street_address",
          "suburb",
          "postcode",
          "phone_number",
          "mobile_code",
          "mobile_number",
          "type",
          "customer_account",
        ],
      },
      "Users.States": {
        fields: ["id", "name"],
      },
      "Users.Countries": {
        fields: ["id", "country_name"],
      },
      CashSaleInvoiceParts: {
        fields: [
          "id",
          "cash_sale_invoice_id",
          "invoice_part_name",
          "invoice_part_description",
          "qty",
          "gst",
          "invoice_part_priority",
          "sell",
          "retail",
          "time",
          "profit",
          "cost",
          "margin",
          "price",
          "sell_total",
          "gst_total"
        ],
      },
      "CashSaleInvoiceParts.Products": {
        fields: [
          "id",
          "product_number",
          "name",
          "description",
          "stock_in_hand",
          "product_type",
        ],
      },
      CashSaleInvoiceLabours: {
        conditions: {
          "CashSaleInvoiceLabours.workshop_id": workshop_id,
        },
        fields: [
          "id",
          "cash_sale_invoice_id",
          "time",
          "type",
          "labour_description",
          "cost",
          "markup",
          "sell",
          "retail",
          "gst",
          "total",
          "profit",
          "sell_total",
          "gst_total"
        ],
      },
      "CashSaleInvoiceLabours.LabourCharges": {
        fields: ["id", "labour_code", "description"],
      },
      "Workshops.Countries": {
        fields: ["id", "country_name"],
      },
      "Workshops.States": {
        fields: ["id", "name"],
      },
      "Workshops.WorkshopOtherDetails": {
        fields: ["id", "workshop_id", "abn", "bsb", "account_number"],
      },
    },
    fields: [
      "id",
      "user_id",
      "vehicle_id",
      "workshop_id",
      "is_part_no_visible",
      "cash_sale_no",
      "parts_total_sell",
      "labour_total_sell",
      "invoice_dept_id",
      "description",
      "description_script_id",
      "labour_cost",
      "labour_markup",
      "labour_total_cost",
      "discount_rate",
      "discount_claim_type",
      "discount_cost",
      "Vehicles.id",
      "Vehicles.rego",
      "Vehicles.make",
      "Vehicles.model",
      "Vehicles.year",
      "Vehicles.vehicle_type",
      "Users.id",
      "Users.email",
      "Users.customer_account",
      "Workshops.logo",
      "Workshops.name",
      "Workshops.street_address",
      "Workshops.suburb",
      "Workshops.postcode",
      "Workshops.email",
      "Workshops.phone",
      "sub_total",
      "gst",
      "total",
      "Workshops.id",
      "Workshops.state",
      "Workshops.country",
      "parts_total_cost",
      "invoice_status",
      "rego",
      "email_template_id",
      "email_description"
    ],
  };
  const data = await getInvoicesSalesList({ payload: request1 });
  if (data?.code === 200) {
    return data?.data?.CashSaleInvoice[0];
  } else {
    throw new Error("No Data found");
  }
}
