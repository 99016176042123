export const GET_WORKSHOP_DETAIL_ONLINE_JOB = "GET_WORKSHOP_DETAIL_ONLINE_JOB";
export const GET_WORKSHOP_DETAIL_ONLINE_JOB_SUCCESS =
  "GET_WORKSHOP_DETAIL_ONLINE_JOB_SUCCESS";
export const GET_WORKSHOP_DETAIL_ONLINE_JOB_FAIL =
  "GET_WORKSHOP_DETAIL_ONLINE_JOB_FAIL";

export const GET_INITIAL_DATA = "GET_INITIAL_DATA";
export const GET_INITIAL_DATA_SUCCESS = "GET_INITIAL_DATA_SUCCESS";
export const GET_INITIAL_DATA_FAIL = "GET_INITIAL_DATA_FAIL";

export const GET_TIME_SLOTS = "GET_TIME_SLOTS";
export const GET_TIME_SLOTS_SUCCESS = "GET_TIME_SLOTS_SUCCESS";
export const GET_TIME_SLOTS_FAIL = "GET_TIME_SLOTS_FAIL";

export const CREATE_ONLINE_JOB = "CREATE_ONLINE_JOB";
export const CREATE_ONLINE_JOB_SUCCESS = "CREATE_ONLINE_JOB_SUCCESS";
export const CREATE_ONLINE_JOB_FAIL = "CREATE_ONLINE_JOB_FAIL";
