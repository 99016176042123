export const GET_JOBDEPARTMENT_LIST = "GET_JOBDEPARTMENT_LIST";
export const GET_JOBDEPARTMENT_LIST_SUCCESS = "GET_JOBDEPARTMENT_LIST_SUCCESS";
export const GET_JOBDEPARTMENT_LIST_FAIL = "GET_JOBDEPARTMENT_LIST_FAIL";

export const GET_JOBTYPE_LIST = "GET_JOBTYPE_LIST";
export const GET_JOBTYPE_LIST_SUCCESS = "GET_JOBTYPE_LIST_SUCCESS";
export const GET_JOBTYPE_LIST_FAIL = "GET_JOBTYPE_LIST_FAIL";

export const GET_JOBCANNED_LIST = "GET_JOBCANNED_LIST";
export const GET_JOBCANNED_LIST_SUCCESS = "GET_JOBCANNED_LIST_SUCCESS";
export const GET_JOBCANNED_LIST_FAIL = "GET_JOBCANNED_LIST_FAIL";

export const GET_JOBSMSTEMPLATE_LIST = "GET_JOBSMSTEMPLATE_LIST";
export const GET_JOBSMSTEMPLATE_LIST_SUCCESS =
  "GET_JOBSMSTEMPLATE_LIST_SUCCESS";
export const GET_JOBSMSTEMPLATE_LIST_FAIL = "GET_JOBSMSTEMPLATE_LIST_FAIL";

export const GET_JOBEMAILTEMPLATE_LIST = "GET_JOBEMAILTEMPLATE_LIST";
export const GET_JOBEMAILTEMPLATE_LIST_SUCCESS =
  "GET_JOBEMAILTEMPLATE_LIST_SUCCESS";
export const GET_JOBEMAILTEMPLATE_LIST_FAIL = "GET_JOBEMAILTEMPLATE_LIST_FAIL";

export const SAVE_JOBEMAILTEMPLATE = "SAVE_JOBEMAILTEMPLATE";
export const SAVE_JOBEMAILTEMPLATE_SUCCESS = "SAVE_JOBEMAILTEMPLATE_SUCCESS";
export const SAVE_JOBEMAILTEMPLATE_FAIL = "SAVE_JOBEMAILTEMPLATE_FAIL";

export const SAVE_JOBSMSTEMPLATE = "SAVE_JOBSMSTEMPLATE";
export const SAVE_JOBSMSTEMPLATE_SUCCESS = "SAVE_JOBSMSTEMPLATE_SUCCESS";
export const SAVE_JOBSMSTEMPLATE_FAIL = "SAVE_JOBSMSTEMPLATE_FAIL";

export const GET_COUNTRY = "GET_COUNTRY";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAIL = "GET_COUNTRY_FAIL";

export const GET_STATE = "GET_STATE";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_FAIL = "GET_STATE_FAIL";

export const GET_VEHICLES = "GET_VEHICLES";
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS";
export const GET_VEHICLES_FAIL = "GET_VEHICLES_FAIL";

export const GET_VEHICLEMODEL = "GET_VEHICLEMODEL";
export const GET_VEHICLEMODEL_SUCCESS = "GET_VEHICLEMODEL_SUCCESS";
export const GET_VEHICLEMODEL_FAIL = "GET_VEHICLEMODEL_FAIL";

export const GET_VEHICLESERIES = "GET_VEHICLESERIES";
export const GET_VEHICLESERIES_SUCCESS = "GET_VEHICLESERIES_SUCCESS";
export const GET_VEHICLESERIES_FAIL = "GET_VEHICLESERIES_FAIL";
export const UPDATE_WORKSHOP_OTHER_DETAIL = "UPDATE_WORKSHOP_OTHER_DETAIL";
export const UPDATE_WORKSHOP_OTHER_DETAIL_SUCCESS =
  "UPDATE_WORKSHOP_OTHER_DETAIL_SUCCESS";
export const UPDATE_WORKSHOP_OTHER_DETAIL_FAIL =
  "UPDATE_WORKSHOP_OTHER_DETAIL_FAIL";

export const GET_LABOUR_CHARGES_LIST = "GET_LABOUR_CHARGES_LIST";
export const GET_LABOUR_CHARGES_LIST_SUCCESS =
  "GET_LABOUR_CHARGES_LIST_SUCCESS";
export const GET_LABOUR_CHARGES_LIST_FAIL = "GET_LABOUR_CHARGES_LIST_FAIL";

/** Workshop Markup Detail START*/
export const GET_WORKSHOP_MARKUP_DETAILS = "GET_WORKSHOP_MARKUP_DETAILS";
export const GET_WORKSHOP_MARKUP_DETAILS_SUCCESS =
  "GET_WORKSHOP_MARKUP_DETAILS_SUCCESS";
export const GET_WORKSHOP_MARKUP_DETAILS_FAIL =
  "GET_WORKSHOP_MARKUP_DETAILS_FAIL";
export const EDIT_WORKSHOP_MARKUP_DETAILS_SUCCESS =
  "EDIT_WORKSHOP_MARKUP_DETAILS_SUCCESS";
/** Workshop Markup Detail END*/

/** Workshop Tax / Rates Detail START*/
export const GET_WORKSHOP_TAX_DETAILS = "GET_WORKSHOP_TAX_DETAILS";
export const GET_WORKSHOP_TAX_DETAILS_SUCCESS =
  "GET_WORKSHOP_TAX_DETAILS_SUCCESS";
export const GET_WORKSHOP_TAX_DETAILS_FAIL = "GET_WORKSHOP_TAX_DETAILS_FAIL";
/** Workshop  Tax / Rates Detail END*/

export const GET_STATUS_COLOR = "GET_STATUS_COLOR";
export const GET_STATUS_COLOR_SUCCESS = "GET_STATUS_COLOR_SUCCESS";
export const GET_STATUS_COLOR_FAIL = "GET_STATUS_COLOR_FAIL";

export const SUBMIT_JOB_SMSEMAIL_TEMPLATE = "SUBMIT_JOB_SMSEMAIL_TEMPLATE";
export const SUBMIT_JOB_SMSEMAIL_TEMPLATE_SUCCESS =
  "SUBMIT_JOB_SMSEMAIL_TEMPLATE_SUCCESS";
export const SUBMIT_JOB_SMSEMAIL_TEMPLATE_FAIL =
  "SUBMIT_JOB_SMSEMAIL_TEMPLATE_FAIL";

export const GET_ASSETS = "GET_ASSETS";
export const GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS";
export const GET_ASSETS_FAIL = "GET_ASSETS_FAIL";

export const SEARCH_JOBS = "SEARCH_JOBS";
export const SEARCH_JOBS_SUCCESS = "SEARCH_JOBS_SUCCESS";
export const SEARCH_JOBS_FAIL = "SEARCH_JOBS_FAIL";

export const SEARCH_QUOTE_JOBS = "SEARCH_QUOTE_JOBS";
export const SEARCH_QUOTE_JOBS_SUCCESS = "SEARCH_QUOTE_JOBS_SUCCESS";
export const SEARCH_QUOTE_JOBS_FAIL = "SEARCH_QUOTE_JOBS_FAIL";

export const APPLY_CODE = "APPLY_CODE";
export const APPLY_CODE_SUCCESS = "APPLY_CODE_SUCCESS";
export const APPLY_CODE_FAIL = "APPLY_CODE_FAIL";

export const GET_WORKSHOP_TECHNICIAN = "GET_WORKSHOP_TECHNICIAN";
export const GET_WORKSHOP_TECHNICIAN_SUCCESS =
  "GET_WORKSHOP_TECHNICIAN_SUCCESS";
export const GET_WORKSHOP_TECHNICIAN_FAIL = "GET_WORKSHOP_TECHNICIAN_FAIL";

export const GET_SUPPLIER_INTEGRATION = "GET_SUPPLIER_INTEGRATION";
export const GET_SUPPLIER_INTEGRATION_SUCCESS =
  "GET_SUPPLIER_INTEGRATION_SUCCESS";
export const GET_SUPPLIER_INTEGRATION_FAIL = "GET_SUPPLIER_INTEGRATION_FAIL";

export const GET_EMPLOYEE_ROLES = "GET_EMPLOYEE_ROLES";
export const GET_EMPLOYEE_ROLES_SUCCESS = "GET_EMPLOYEE_ROLES_SUCCESS";
export const GET_EMPLOYEE_ROLES_FAIL = "GET_EMPLOYEE_ROLES_FAIL";

export const GET_STORAGE_DEVICE = "GET_STORAGE_DEVICE";
export const GET_STORAGE_DEVICE_SUCCESS = "GET_STORAGE_DEVICE_SUCCESS";
export const GET_STORAGE_DEVICE_FAIL = "GET_STORAGE_DEVICE_FAIL";

export const GET_NOTIFICATION_COUNT = "GET_NOTIFICATION_COUNT";
export const GET_NOTIFICATION_COUNT_SUCCESS = "GET_NOTIFICATION_COUNT_SUCCESS";
export const GET_NOTIFICATION_COUNT_FAIL = "GET_NOTIFICATION_COUNT_FAIL";

export const GET_INSPECTION_NOTIFICATION = "GET_INSPECTION_NOTIFICATION";
export const GET_INSPECTION_NOTIFICATION_SUCCESS = "GET_INSPECTION_NOTIFICATION_SUCCESS";
export const GET_INSPECTION_NOTIFICATION_FAIL = "GET_INSPECTION_NOTIFICATION_FAIL";

export const GET_ONLINE_SCHEDULE_NOTIFICATION = "GET_ONLINE_SCHEDULE_NOTIFICATION";
export const GET_ONLINE_SCHEDULE_NOTIFICATION_SUCCESS = "GET_ONLINE_SCHEDULE_NOTIFICATION_SUCCESS";
export const GET_ONLINE_SCHEDULE_NOTIFICATION_FAIL = "GET_ONLINE_SCHEDULE_NOTIFICATION_FAIL";
