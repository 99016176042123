import {
  UPDATE_SUPPLIER_INTEGRATION,
  UPDATE_SUPPLIER_INTEGRATION_SUCCESS,
  UPDATE_SUPPLIER_INTEGRATION_FAIL,
  SAVE_SUPPLIER_INTEGRATION,
  SAVE_SUPPLIER_INTEGRATION_SUCCESS,
  SAVE_SUPPLIER_INTEGRATION_FAIL,
} from "./actionTypes";

export const updateSupplierIntegration = () => {
  return {
    type: UPDATE_SUPPLIER_INTEGRATION,
  };
};

export const updateSupplierIntegrationSuccess = (data) => {
  return {
    type: UPDATE_SUPPLIER_INTEGRATION_SUCCESS,
    payload: data.data,
  };
};

export const updateSupplierIntegrationFail = (error) => {
  return {
    type: UPDATE_SUPPLIER_INTEGRATION_FAIL,
    payload: error,
  };
};


export const saveSupplierIntegration = () => {
  return {
    type: SAVE_SUPPLIER_INTEGRATION,
  };
};

export const saveSupplierIntegrationSuccess = (data) => {
  return {
    type: SAVE_SUPPLIER_INTEGRATION_SUCCESS,
    payload: data.data.SupplierIntegration,
  };
};

export const saveSupplierIntegrationFail = (error) => {
  return {
    type: SAVE_SUPPLIER_INTEGRATION_FAIL,
    payload: error,
  };
};