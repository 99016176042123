import { call, debounce, put, takeLatest } from "redux-saga/effects";
import {
  APPROVE_DIGITAT_INSPECTION,
  APPROVE_DIGITAT_INSPECTION_FAIL,
  APPROVE_DIGITAT_INSPECTION_SUCCESS,
  GET_INSPECTIONS_DIGITAL_INSPECTION,
  GET_INSPECTIONS_DIGITAL_INSPECTION_VIEW,
  GET_INSPECTION_GROUP_DIGITAL_INSPECTION,
  REJECT_INSPECTION,
  REJECT_INSPECTION_FAIL,
  REJECT_INSPECTION_SUCCESS,
  VERIFY_CODE,
  VERIFY_CODE_FAIL,
  VERIFY_CODE_SUCCESS,
} from "./actionTypes";

import {
  getInspectionGroupDigitalInspectionFail,
  getInspectionGroupDigitalInspectionSuccess,
  getInspectionsDigitalInspectionFail,
  getInspectionsDigitalInspectionSuccess,
} from "./actions";

import {
  getDigitalInspections,
  getInspectionGroups,
  getInspections,
} from "../../helpers/backendHelper";

import { approveInspection, rejectInspection } from "./approveInspection";

function* onGetInspectionsDigitalInspection(data) {
  try {
    const response = yield call(getDigitalInspections, data);
    if (response) {
      if (response.code === 200) {
        const InspInspections = response.data.InspInspections;
        if (InspInspections && InspInspections.length) {
          InspInspections[0].insp_question_ans.forEach((queAns) => {
            queAns.answers = JSON.parse(queAns.answers);
          });
        }
        yield put({
          type: GET_INSPECTION_GROUP_DIGITAL_INSPECTION,
          payload: {
            get: "all",
            conditions: {
              status: 1,
              categories_id: InspInspections[0]?.insp_template?.categories_id,
            },
          },
        });
        yield put(getInspectionsDigitalInspectionSuccess(InspInspections));
      } else {
        yield put(getInspectionsDigitalInspectionFail(response.message));
      }
    } else {
      yield put(getInspectionsDigitalInspectionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionsDigitalInspectionFail(error.response));
  }
}

function* onGetInspectionGroupsDigitalInspection(data) {
  try {
    const response = yield call(getInspectionGroups, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getInspectionGroupDigitalInspectionSuccess(
            response?.data?.InspGroups || []
          )
        );
      } else {
        yield put(getInspectionGroupDigitalInspectionFail(response.message));
      }
    } else {
      yield put(
        getInspectionGroupDigitalInspectionFail("Didn't get api response")
      );
    }
  } catch (error) {
    yield put(getInspectionGroupDigitalInspectionFail(error));
  }
}

function* onApproveDigitalInspection({ payload }) {
  try {
    yield call(approveInspection, payload);
    yield put({
      type: APPROVE_DIGITAT_INSPECTION_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: APPROVE_DIGITAT_INSPECTION_FAIL,
    });
  }
}

function* onVerifyCode({ payload }) {
  try {
    const request = {
      get: "all",
      conditions: payload,
    };
    const data = yield call(getDigitalInspections, { payload: request });
    if (data?.code !== 200) {
      throw new Error("Not verified");
    }
    yield put({
      type: VERIFY_CODE_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: VERIFY_CODE_FAIL,
    });
  }
}

function* onGetInspectionsDigitalInspectionView(data) {
  try {
    const response = yield call(getInspections, data);
    if (response) {
      if (response.code === 200) {
        const InspInspections = response.data.InspInspections;
        if (InspInspections && InspInspections.length) {
          InspInspections[0].insp_question_ans.forEach((queAns) => {
            queAns.answers = JSON.parse(queAns.answers);
          });
        }
        yield put({
          type: GET_INSPECTION_GROUP_DIGITAL_INSPECTION,
          payload: {
            get: "all",
            conditions: {
              status: 1,
              categories_id: InspInspections[0]?.insp_template?.categories_id,
            },
          },
        });
        yield put(getInspectionsDigitalInspectionSuccess(InspInspections));
      } else {
        yield put(getInspectionsDigitalInspectionFail(response.message));
      }
    } else {
      yield put(getInspectionsDigitalInspectionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionsDigitalInspectionFail(error.response));
  }
}

function* onRejectInspection({ payload }) {
  try {
    yield call(rejectInspection, payload);
    yield put({
      type: REJECT_INSPECTION_SUCCESS,
    });
  } catch (er) {
    yield put({
      type: REJECT_INSPECTION_FAIL,
    });
  }
}

function* DigitalInspection() {
  yield takeLatest(
    GET_INSPECTIONS_DIGITAL_INSPECTION,
    onGetInspectionsDigitalInspection
  );
  yield takeLatest(
    GET_INSPECTION_GROUP_DIGITAL_INSPECTION,
    onGetInspectionGroupsDigitalInspection
  );
  yield takeLatest(APPROVE_DIGITAT_INSPECTION, onApproveDigitalInspection);
  yield debounce(800, VERIFY_CODE, onVerifyCode);
  yield takeLatest(
    GET_INSPECTIONS_DIGITAL_INSPECTION_VIEW,
    onGetInspectionsDigitalInspectionView
  );
  yield takeLatest(REJECT_INSPECTION, onRejectInspection);
}

export default DigitalInspection;
