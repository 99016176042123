// SCHEDULED MARKETING
export const GET_SCHEDULED_MARKETING_LIST = "GET_SCHEDULED_MARKETING_LIST";

// export const GET_RAISED_INVOICES = "GET_RAISED_INVOICES";

export const GET_SCHEDULED_MARKETING_LIST_COUNT = "GET_SCHEDULED_MARKETING_LIST_COUNT";
export const GET_SCHEDULED_MARKETING_LIST_COUNT_SUCCESS =
  "GET_SCHEDULED_MARKETING_LIST_COUNT_SUCCESS";
export const GET_SCHEDULED_MARKETING_LIST_COUNT_FAIL = "GET_SCHEDULED_MARKETING_LIST_COUNT_FAIL";
export const GET_SCHEDULED_MARKETING_LIST_SUCCESS = "GET_SCHEDULED_MARKETING_LIST_SUCCESS";
export const GET_SCHEDULED_MARKETING_LIST_FAIL = "GET_SCHEDULED_MARKETING_LIST_FAIL";

// SCHEDULED MARKETING


//DELETE SCHEDULED MARKETING
export const DELETE_SCHEDULED_MARKETING_LIST = "DELETE_SCHEDULED_MARKETING_LIST";
export const DELETE_SCHEDULED_MARKETING_LIST_SUCCESS = "DELETE_SCHEDULED_MARKETING_LIST_SUCCESS";
export const DELETE_SCHEDULED_MARKETING_LIST_FAIL = "DELETE_SCHEDULED_MARKETING_LIST_FAIL";
//DELETE SCHEDULED MARKETING

// LIVE MARKETING

export const GET_LIVE_MARKETING_LIST = "GET_LIVE_MARKETING_LIST";

export const GET_LIVE_MARKETING_LIST_SUCCESS = "GET_LIVE_MARKETING_LIST_SUCCESS";
export const GET_LIVE_MARKETING_LIST_FAIL = "GET_LIVE_MARKETING_LIST_FAIL";

// LIVE MARKETING

//DELETE SCHEDULED MARKETING
export const DELETE_LIVE_MARKETING_LIST = "DELETE_LIVE_MARKETING_LIST";
export const DELETE_LIVE_MARKETING_LIST_SUCCESS = "DELETE_LIVE_MARKETING_LIST_SUCCESS";
export const DELETE_LIVE_MARKETING_LIST_FAIL = "DELETE_LIVE_MARKETING_LIST_FAIL";
//DELETE SCHEDULED MARKETING


//CREATE  MARKETING
export const CREATE_MARKETING = "CREATE_MARKETING";
export const CREATE_MARKETING_SUCCESS = "CREATE_MARKETING_SUCCESS";
export const CREATE_MARKETING_FAIL = "CREATE_MARKETING_FAIL";
//CREATE  MARKETING





// COMPLETED MARKETING

export const GET_COMPLETED_MARKETING_LIST = "GET_COMPLETED_MARKETING_LIST";

export const GET_COMPLETED_MARKETING_LIST_SUCCESS = "GET_COMPLETED_MARKETING_LIST_SUCCESS";
export const GET_COMPLETED_MARKETING_LIST_FAIL = "GET_COMPLETED_MARKETING_LIST_FAIL";

// COMPLETED MARKETING

//DELETE SCHEDULED MARKETING
export const DELETE_COMPLETED_MARKETING_LIST = "DELETE_COMPLETED_MARKETING_LIST";
export const DELETE_COMPLETED_MARKETING_LIST_SUCCESS = "DELETE_COMPLETED_MARKETING_LIST_SUCCESS";
export const DELETE_COMPLETED_MARKETING_LIST_FAIL = "DELETE_COMPLETED_MARKETING_LIST_FAIL";
//DELETE SCHEDULED MARKETING


//VIEW MARKETING
export const VIEW_MARKETING = "VIEW_MARKETING";
export const VIEW_MARKETING_SUCCESS = "VIEW_MARKETING_SUCCESS";
export const VIEW_MARKETING_FAIL = "VIEW_MARKETING_FAIL";
//VIEW MARKETING


//EDIT MARKETING
export const EDIT_MARKETING = "EDIT_MARKETING";
export const EDIT_MARKETING_SUCCESS = "EDIT_MARKETING_SUCCESS";
export const EDIT_MARKETING_FAIL = "EDIT_MARKETING_FAIL";
//EDIT MARKETING