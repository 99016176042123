import
  {
    GET_GOPAY_PAYMENT_INTEGRATION, GET_GOPAY_PAYMENT_INTEGRATION_FAIL, GET_GOPAY_PAYMENT_INTEGRATION_SUCCESS, GET_STRIPE_PAYMENT_INTEGRATION, GET_STRIPE_PAYMENT_INTEGRATION_FAIL, GET_STRIPE_PAYMENT_INTEGRATION_SUCCESS, SAVE_GOPAY_PAYMENT_INTEGRATION, SAVE_GOPAY_PAYMENT_INTEGRATION_FAIL, SAVE_GOPAY_PAYMENT_INTEGRATION_SUCCESS, SAVE_STRIPE_PAYMENT_INTEGRATION, SAVE_STRIPE_PAYMENT_INTEGRATION_FAIL, SAVE_STRIPE_PAYMENT_INTEGRATION_SUCCESS
  } from "./actionTypes";



export const getStripePaymentIntegration = () => {
  return {
    type: GET_STRIPE_PAYMENT_INTEGRATION,
  };
};

export const getStripePaymentIntegrationSuccess = (data) => {
  return {
    type: GET_STRIPE_PAYMENT_INTEGRATION_SUCCESS,
    payload: data.Workshops[0],
  };
};

export const getStripePaymentIntegrationFail = (error) => {
  return {
    type: GET_STRIPE_PAYMENT_INTEGRATION_FAIL,
    payload: error,
  };
};

export const saveStripePaymentIntegration = () => {
  return {
    type: SAVE_STRIPE_PAYMENT_INTEGRATION,
  };
};

export const saveStripePaymentIntegrationSuccess = (data) => {
  return {
    type: SAVE_STRIPE_PAYMENT_INTEGRATION_SUCCESS,
    payload: data.data.Workshops,
  };
};

export const saveStripePaymentIntegrationFail = (error) => {
  return {
    type: SAVE_STRIPE_PAYMENT_INTEGRATION_FAIL,
    payload: error,
  };
};


export const getGopayPaymentIntegration = () => {
  return {
    type: GET_GOPAY_PAYMENT_INTEGRATION,
  };
};

export const getGopayPaymentIntegrationSuccess = (data) => {
  return {
    type: GET_GOPAY_PAYMENT_INTEGRATION_SUCCESS,
    payload: data.WorkshopOtherDetails[0],
  };
};

export const getGopayPaymentIntegrationFail = (error) => {
  return {
    type: GET_GOPAY_PAYMENT_INTEGRATION_FAIL,
    payload: error,
  };
};

export const saveGopayPaymentIntegration = () => {
  return {
    type: SAVE_GOPAY_PAYMENT_INTEGRATION,
  };
};

export const saveGopayPaymentIntegrationSuccess = (data) => {
  return {
    type: SAVE_GOPAY_PAYMENT_INTEGRATION_SUCCESS,
    payload: data.data.WorkshopOtherDetails,
  };
};

export const saveGopayPaymentIntegrationFail = (error) => {
  return {
    type: SAVE_GOPAY_PAYMENT_INTEGRATION_FAIL,
    payload: error,
  };
};