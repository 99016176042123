import moment from "moment";
import { useEffect, useState } from "react";
import { showError, showSuccess } from "utils/alertMessage";
import { timeZoneToUTC } from "utils/timeZoneConvert";
import { convertToHours, utcToTimezone } from "utils/timeZoneConvert";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactInputMask from "react-input-mask";
import { updateJobClockingHistory } from "helpers/backendHelper";


let TimeLog = ({ jobHistory, fromDate, loadJobClockingHistory }) => {

    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    const timeFormat = storedData.time_format + ":ss";
    let workshop_id = window.atob(storedData.workshop_id);

    const localStorageData = JSON.parse(localStorage.getItem("data"));
    let timezoneLable = localStorageData?.workshop?.workshop_other_details[0]?.timezone_label || "Asia/Kolkata";

    const [timeLogData, setTimeLogData] = useState([]);
    const [prevTimeLogData, setPrevTimeLogData] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");

    useEffect(() => {

        if (jobHistory.length > 0) {
            let timeLog = [];

            setTimeLogData([]);
            setPrevTimeLogData(jobHistory);

            jobHistory.map((clock, index) => {
                let timeData = {};
                timeData['id'] = clock?.id;
                timeData['activity'] = clock?.activity;
                timeData['start_time'] = utcToTimezone(clock?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss");
                timeData['end_time'] = clock?.end_time ? utcToTimezone(clock?.end_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss") : "-";
                timeData['display_start_time'] = utcToTimezone(clock?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", timeFormat);
                timeData['display_end_time'] = clock?.end_time ? utcToTimezone(clock?.end_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", timeFormat) : "-";
                // timeData['duration'] = clock?.durat  ion;
                timeData['duration'] = clock?.time_duration;
                timeData['user_id'] = clock?.user_id;
                timeData['is_updated'] = clock?.is_updated;
                timeLog.push(timeData);
            });

            setTimeLogData(timeLog);

        } else {
            setTimeLogData([]);
        }

        return () => {
            setSelectedRow("");
        };

    }, [jobHistory])


    function cancelEditTime(i) {

        const newData = [...timeLogData];

        newData[i]["display_start_time"] = utcToTimezone(prevTimeLogData[i]?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", timeFormat);
        newData[i]["start_time"] = utcToTimezone(prevTimeLogData[i]?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss");
        newData[i]["display_end_time"] = utcToTimezone(prevTimeLogData[i]?.end_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", timeFormat);
        newData[i]["end_time"] = utcToTimezone(prevTimeLogData[i]?.end_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss");

        setTimeLogData(newData);
        setSelectedRow("");
    }

    async function clockingEditTime(i) {

        const newData = timeLogData[i];

        let start_time = timeZoneToUTC(newData?.start_time, timezoneLable, "", "YYYY-MM-DD HH:mm:ss");
        let end_time = timeZoneToUTC(newData?.end_time, timezoneLable, "", "YYYY-MM-DD HH:mm:ss");

        let current_time = moment().utc().format('YYYY-MM-DD HH:mm:ss');

        let val_start_time = moment(start_time).format('YYYY-MM-DD HH:mm:ss');
        let val_end_time = moment(end_time).format('YYYY-MM-DD HH:mm:ss');

        if (val_start_time > current_time) {
            showError("Invalid start time, start time is less than to current time");
            return false;
        }

        if (val_start_time > val_end_time) {
            showError("Invalid end time, end time is greater than to start time");
            return false;
        }

        if (val_end_time > current_time) {
            showError("Invalid end time, end time is less than to current time");
            return false;
        }

        if (val_start_time <= current_time && val_end_time <= current_time) {
            console.log('time is ok');
        }



        let payloadData = "";
        if (newData?.activity === "Finish Time") {
            payloadData = {
                id: newData?.id,
                start_time: end_time,
                end_time: end_time,
                is_updated: "1",
                workshop_id: workshop_id,
                user_id: newData?.user_id
            }
        } else {
            payloadData = {
                id: newData?.id,
                start_time: start_time,
                end_time: end_time,
                is_updated: "1",
                workshop_id: workshop_id,
                user_id: newData?.user_id
            }
        }

        const data = await updateJobClockingHistory({ payload: payloadData });

        if (data.code === 200) {
            showSuccess("Time log updated");
            setSelectedRow("");
            loadJobClockingHistory();
        } else {
            showError("Please enter proper start and stop time, it's mismatch with other records");
            // showError("Something went wrong! Please try after sometime");
        }



    }

    return (
        <>
            <div className="job-detail px-3 mb-4">
                <div className="react-bootstrap-table clocking-table">
                    <p className="clocking-title" >Time Log</p>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th />
                                <th>Action</th>
                                <th>Start</th>
                                <th>Stop</th>
                                <th>Duration</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        {timeLogData && timeLogData.length > 0 &&
                            <tbody className="text-capitalize">
                                {timeLogData && timeLogData.map((clock, i) => (

                                    <tr key={"timeLog_" + clock?.id}>
                                        <td />
                                        <td>{clock?.activity}</td>
                                        {/* Start Time */}

                                        {clock?.activity === "Finish Time" ? (
                                            <td>-</td>
                                        ) : (
                                            <>
                                                {selectedRow && selectedRow?.id === clock?.id ? (
                                                    <td>

                                                        <Form.Group className="form-group disable">
                                                            <ReactInputMask
                                                                // onBlur={(e) => changeLabourRow(labour, "time")}
                                                                mask="99:99:99"
                                                                // value={utcToTimezone(clock?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", time_format || "HH:mm")}
                                                                value={clock?.display_start_time || ""}
                                                                onChange={(e) => {
                                                                    const newData = [...timeLogData];

                                                                    let [hr, mn, ss] = e.target.value.split(":");
                                                                    let v = e.target.value
                                                                        .split(":")
                                                                        .join("")
                                                                        .replaceAll("_", "");

                                                                    var new_start_time = "";
                                                                    if (!isNaN(parseInt(mn)) && +mn > 59) {
                                                                        // newData[i]["start_time"] = `${hr}:59`;
                                                                        new_start_time = `${hr}:59:${ss}`;
                                                                    } else if (!isNaN(parseInt(ss)) && +ss > 59) {
                                                                        // newData[i]["start_time"] = `${hr}:59`;
                                                                        new_start_time = `${hr}:${mn}:59`;
                                                                    } else {
                                                                        // newData[i]["start_time"] = e.target.value;
                                                                        new_start_time = e.target.value;
                                                                    }

                                                                    newData[i]["display_start_time"] = new_start_time;
                                                                    newData[i]["start_time"] = fromDate + " " + new_start_time;

                                                                    setTimeLogData(newData);
                                                                }}
                                                                className="small-input"
                                                                name={"start_time_" + clock.id}
                                                                id={"start_time_" + clock.id}
                                                            >
                                                                {(inputProps) => (
                                                                    <Form.Control
                                                                        {...inputProps}
                                                                        type="text"
                                                                    />
                                                                )}
                                                            </ReactInputMask>
                                                        </Form.Group>


                                                    </td>
                                                ) : (
                                                    <td>{clock?.display_start_time}</td>
                                                )}
                                            </>
                                        )}
                                        {/*                                         
                                        {clock?.activity === "Finish Time" ? (
                                            <td>-</td>
                                        ) : (
                                            <td>{clock?.display_start_time}</td>
                                        )} */}

                                        {/* Stop Time */}

                                        {selectedRow && selectedRow?.id === clock?.id ? (

                                            <td>
                                                <Form.Group className="form-group disable">
                                                    <ReactInputMask
                                                        // onBlur={(e) => changeLabourRow(labour, "time")}
                                                        mask="99:99:99"
                                                        // value={utcToTimezone(clock?.start_time, timezoneLable, "YYYY-MM-DD HH:mm:ss", time_format || "HH:mm")}
                                                        value={clock?.display_end_time || ""}
                                                        onChange={(e) => {
                                                            const newData = [...timeLogData];

                                                            let [hr, mn, ss] = e.target.value.split(":");
                                                            let v = e.target.value
                                                                .split(":")
                                                                .join("")
                                                                .replaceAll("_", "");

                                                            var new_end_time = "";
                                                            if (!isNaN(parseInt(mn)) && +mn > 59) {
                                                                // newData[i]["start_time"] = `${hr}:59`;
                                                                new_end_time = `${hr}:59:${ss}`;
                                                            } else if (!isNaN(parseInt(ss)) && +ss > 59) {
                                                                // newData[i]["start_time"] = `${hr}:59`;
                                                                new_end_time = `${hr}:${mn}:59`;
                                                            } else {
                                                                // newData[i]["start_time"] = e.target.value;
                                                                new_end_time = e.target.value;
                                                            }

                                                            if (+v > 235959) {
                                                                // newData[i]["start_time"] = "23:59";
                                                                new_end_time = "23:59:59";
                                                            }

                                                            newData[i]["display_end_time"] = new_end_time;
                                                            newData[i]["end_time"] = fromDate + " " + new_end_time;

                                                            setTimeLogData(newData);
                                                        }}
                                                        className="small-input"
                                                        name={"end_time_" + clock.id}
                                                        id={"end_time_" + clock.id}
                                                    >
                                                        {(inputProps) => (
                                                            <Form.Control
                                                                {...inputProps}
                                                                type="text"
                                                            />
                                                        )}
                                                    </ReactInputMask>
                                                </Form.Group>
                                            </td>

                                        ) : (
                                            <td> {clock?.display_end_time || "-"} </td>
                                        )}

                                        {/* <td> {clock?.display_end_time || "-"} </td> */}


                                        {/* Duration */}
                                        {clock?.activity === "Finish Job" ? (
                                            <td>-</td>
                                        ) : (
                                            <td>{clock?.duration ? clock?.duration.replace(".", ":") : ""}</td>
                                        )}

                                        {/* Action */}
                                        {selectedRow && selectedRow?.id === clock?.id ? (
                                            <td>
                                                <i style={{ color: "green !important" }} onClick={(e) => {
                                                    clockingEditTime(i)
                                                }} className="fa fa-check text-info edit"></i>
                                                <i style={{ color: "red !important" }} onClick={(e) => {
                                                    cancelEditTime(i)
                                                }}
                                                    className="ml-1 fa fa-times text-info cancel"></i>
                                            </td>
                                        ) : (
                                            <td onClick={(e) => {
                                                setSelectedRow(clock);
                                            }}
                                            >
                                                <i className="fa fa-pencil text-info"></i>
                                                {clock?.is_updated === "1" &&
                                                    <span className="ml-1 edited">Edited</span>
                                                }
                                            </td>
                                        )}



                                    </tr>

                                ))}
                            </tbody>
                        }

                        {timeLogData.length === 0 &&
                            <tbody className="text-capitalize text-center">
                                <tr>
                                    <td colSpan={5}> No Time Log Available </td>
                                </tr>
                            </tbody>
                        }
                    </table>

                </div>
            </div>
        </>
    )
};

export default TimeLog;
