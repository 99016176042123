export const INVENTORY_STOCK_LIST = "INVENTORY_STOCK_LIST";
export const INVENTORY_STOCK_LIST_SUCCESS = "INVENTORY_STOCK_LIST_SUCCESS";
export const INVENTORY_STOCK_LIST_FAIL = "INVENTORY_STOCK_LIST_FAIL";

export const SAVE_STOCK = "SAVE_STOCK";
export const SAVE_STOCK_SUCCESS = "SAVE_STOCK_SUCCESS";
export const SAVE_STOCK_FAIL = "SAVE_STOCK_FAIL";

export const UPDATE_PART_DATA = "UPDATE_PART_DATA";
export const UPDATE_PART_DATA_SUCCESS = "UPDATE_PART_DATA_SUCCESS";
export const UPDATE_PART_DATA_FAIL = "UPDATE_PART_DATA_FAIL";

export const INVENTORY_STOCK_TAKE_LIST = "INVENTORY_STOCK_TAKE_LIST";
export const INVENTORY_STOCK_TAKE_LIST_SUCCESS = "INVENTORY_STOCK_TAKE_LIST_SUCCESS";
export const INVENTORY_STOCK_TAKE_HISTORY_LIST_SUCCESS = "INVENTORY_STOCK_TAKE_HISTORY_LIST_SUCCESS";
export const INVENTORY_STOCK_TAKE_LIST_FAIL = "INVENTORY_STOCK_TAKE_LIST_FAIL";

export const EDIT_STOCK_TAKE_DETAILS = "EDIT_STOCK_TAKE_DETAILS";
export const EDIT_STOCK_TAKE_DETAILS_SUCCESS = "EDIT_STOCK_TAKE_DETAILS_SUCCESS";
export const EDIT_STOCK_TAKE_DETAILS_FAIL = "EDIT_STOCK_TAKE_DETAILS_FAIL";

export const SAVE_STOCK_TAKE = "SAVE_STOCK_TAKE";
export const SAVE_STOCK_TAKE_SUCCESS = "SAVE_STOCK_TAKE_SUCCESS";
export const SAVE_STOCK_TAKE_FAIL = "SAVE_STOCK_TAKE_FAIL";

export const UPDATE_PARTS_QTY = "UPDATE_PARTS_QTY";
export const UPDATE_PARTS_QTY_SUCCESS = "UPDATE_PARTS_QTY_SUCCESS";
export const UPDATE_PARTS_QTY_FAIL = "UPDATE_PARTS_QTY_FAIL";