import React, { useState } from "react";
import CompanyCommunicationEmail from "./forms/companyCommunicationEmail";
import CompanyCommunicationSMS from "./forms/companyCommunicationSMS";
import QuoteMessage from "./forms/quoteMessage";
import FacebookReview from "./forms/facebookReview";
import GoogleReview from "./forms/googleReview";
import quoteImg from "../../../../assets/images/cash-sale-blue.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  Modifier,
  convertToRaw,
  ContentState,
  convertFromHTML,
  stateToHTML,
} from "draft-js";

const CompanyCommunication = ({ userData, companyDetailData }) => {

  return (
    <div className="communication-block-wrapper">
      <CompanyCommunicationEmail workShopDetails={companyDetailData.workShopDetails} />
      <CompanyCommunicationSMS workShopDetails={companyDetailData.workShopDetails} workShopSMS={companyDetailData.workShopSMS} />
      
      <QuoteMessage workShopOtherDetails={companyDetailData.workShopOtherDetails} />

      <div className="card mb-3">
        <div className="card-header d-flex flex-wrap align-items-center">
          <div className="text-uppercase me-2">
            <h5 className="font-size-16">SOCIAL MEDIA REVIEWS</h5>
          </div>
        </div>
        <div className="card-body row">
          <GoogleReview workShopOtherDetails={companyDetailData.workShopOtherDetails} />
          <FacebookReview workShopOtherDetails={companyDetailData.workShopOtherDetails} />
        </div>
      </div>
    </div>
  );
}

export default CompanyCommunication;