import { useEffect, useState } from "react";
import { Button, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { Presence } from "views/common/UI/Presence";
import videoIcon from "assets/images/video-icon.png";
import "../trainee-video.scss";
import AllSectionVideos from "./AllSectionVideos";
import VimeoPlayer from "./vimeoPlayer";
import customerBlue from "assets/images/customer-blue.svg";
import cashSaleBlue from "assets/images/cash-sale-blue.svg";
import bookJobBlue from "assets/images/book-job-blue.svg";
import inspectionsBlue from "assets/images/inspections-blue.svg";
import scheduleBlue from "assets/images/schedule-blue.svg";
import materialsBlue from "assets/images/materials-blue.svg";
import { useLocation } from "react-router-dom";
import Loader from "views/common/UI/Loader";

const TraineeVideo = () => {

  const [lightboxOpen, setLightBoxOpen] = useState(false);
  const [listView, setListVew] = useState(true);
  const [searchKey, setSearchKey] = useState();
  const [vimeoPlayerShow, setVimeoPlayerShow] = useState(false);
  const [currentVideoURL, setCurrentVideoURL] = useState("");
  const [currentVideoTitle, setCurrentVideoTitle] = useState("");
  const [currentVideoHeader, setCurrentVideoHeader] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const location = useLocation();

  const handleVimeoPlayerShow = (url,title,header) => {
    setVimeoPlayerShow(true);
    setCurrentVideoURL(url);
    setCurrentVideoTitle(title);
    setCurrentVideoHeader(header);
  };

  useEffect(() => {
    const hash = location.hash;
    let url = window.location.href;
    url = url.split("#");
    if (hash.split("#")[1]) {
        setIsLoading(true);
        const nextURL = url[0];
        const nextTitle = "Purchases | 5iQ";
        const nextState = {};
        window.history.replaceState(nextState, nextTitle, nextURL);
        setTimeout(() => {
          setIsLoading(false);
          redirectToDiv(hash.split("#")[1]);    
        }, 5000);
    }
  },[location.hash]);

  function redirectToDiv(divId){
    var element = document.getElementById(divId);
    if(element){
      var headerOffset = 70;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
    }
  }

  return (
    <>
      {isLoading &&
        <Loader />
      }
      
      <div className="video-page-wrapper right-section-wrapper has-white-bg">
        <div className="card mb-3">
          <div className="top-block d-flex justify-space-between pa-15">
            <div className="left-block d-flex align-center">
              <img src={videoIcon} alt="video icon"></img>
              <h3>How to Videos</h3>
            </div>
            <div className="right-block d-flex align-center list-grid-toggle">

            <ToggleButtonGroup
              type="radio"
              name={`videoView`}
              value={listView === true ? 1 : 0}
            >
            <ToggleButton
              value={1}
              id={`options1-view`}
              variant="outline-primary"
              className="btn-rounded mb-0 font-size-12"
              name={`videoListView`}
              onChange={() => setListVew(true)}
            >
              <i className="list-grid-button fa fa-list-ul"></i> <span>List</span>
            </ToggleButton>
            <ToggleButton
              value={0}
              id={`options2-view`}
              name={`videoGridView`}
              variant="outline-primary"
              className="btn-rounded mb-0 font-size-12"
              onChange={() => setListVew(false)}
            >
              <i class="list-grid-button fa fa-th" aria-hidden="true"></i> <span>Grid</span>
            </ToggleButton>
          </ToggleButtonGroup>

              {/* <Button
                className="list-grid-button"
                onClick={() => setListVew(!listView)}
              >
                <i className="fa fa-list-ul"></i>
              </Button>
              <span>{listView === false ? "Grid" : "List"}</span> */}
            </div>
          </div>
          <div className="bottom-block pa-15 d-flex align-center justify-space-between">
            <div className="search-wrapper full-width">
              <div className="input-wrapper">
                <i className="fa fa-search search-icon"></i>
                <Form.Group className="form-group">
                  <Form.Control type="text" placeholder="Search Titles" onKeyUp={(e) => setSearchKey(e.target.value)} />
                </Form.Group>
              </div>
            </div>
            <ul className="training-video-list">
              <li>
                <span onClick={(e) => redirectToDiv("servicesSchedule")}>Header Menu</span>
              </li>
              <li>
                <span onClick={(e) => redirectToDiv("contact")}>Contacts</span>
              </li>
              <li>
                <span onClick={(e) => redirectToDiv("quotes")}>Quotes</span>
              </li>
              <li>
                <span onClick={(e) => redirectToDiv("jobs")}>Jobs</span>
              </li>
              <li>
                <span onClick={(e) => redirectToDiv("invoices")} >Invoices</span>
              </li>
              <li>
                <DropdownButton
                  className="primary-dropdown custom-dropdown-list"
                  title="others"
                >
                    <span onClick={(e) => redirectToDiv("schedule")} >Scheduler</span>
                    {/* <span onClick={(e) => redirectToDiv("customerApp")} >Customer App</span> */}
                    <span onClick={(e) => redirectToDiv("techApp")} >Mobile App</span>
                    <span onClick={(e) => redirectToDiv("inspection")} >Inspection</span>
                    <span onClick={(e) => redirectToDiv("schedule")} >Schedule</span>
                    <span onClick={(e) => redirectToDiv("customerAssets")} >Vehicle List / Service Reminders</span>
                    <span onClick={(e) => redirectToDiv("marketing")} >Marketing</span>
                    <span onClick={(e) => redirectToDiv("materials")} >Materials</span>
                    {/* <span onClick={(e) => redirectToDiv("servicesSchedule")} >Header Menu</span> */}
                    <span onClick={(e) => redirectToDiv("accountSync")} >Account Sync</span>
                    <span onClick={(e) => redirectToDiv("reports")} >Reports</span>
                    <span onClick={(e) => redirectToDiv("settings")} >Setting</span>
                </DropdownButton>
              </li>
            </ul>
          </div>
        </div>
        
        {/* Services Schedule Videos Start */}
        <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767385"
            title="Header Menu"
            pageId="servicesSchedule"
            icon={customerBlue}
            defaultActiveKey="0"
            eventKey="0"
            icon_class="fa fa-clock-o"
          />
        {/* Services Schedule Videos End */}

        {/* Contacts Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767364"
            title="Contacts"
            pageId="contact"
            icon={customerBlue}
            defaultActiveKey="1"
            eventKey="1"
          />
        {/* Contacts Videos End */}


        {/* Quotes Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767369"
            title="Quotes"
            pageId="quotes"
            icon={cashSaleBlue}
            defaultActiveKey="2"
            eventKey="2"
          />
        {/* Quotes Videos End */}


        {/* Jobs Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767372"
            title="Jobs"
            pageId="jobs"
            icon={bookJobBlue}
            defaultActiveKey="3"
            eventKey="3"
          />
        {/* Jobs Videos End */}
        
        
        {/* Invoices Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767376"
            title="Invoices"
            pageId="invoices"
            icon={customerBlue}
            defaultActiveKey="4"
            eventKey="4"
            icon_class="fa fa-files-o"
          />
        {/* Invoices Videos End */}


        {/* Schedules Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767378"
            title="Schedules"
            pageId="schedule"
            icon={scheduleBlue}
            defaultActiveKey="5"
            eventKey="5"
          />
        {/* Schedules Videos End */}
        

        {/* CustomerApps Videos Start */}
          {/* <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767394"
            title="Customer Apps"
            pageId="customerApp"
            icon={bookJobBlue}
            defaultActiveKey="5"
            eventKey="5"
          /> */}
        {/* CustomerApps Videos End */}


        {/* Mobile App Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767395"
            title="Mobile App"
            pageId="techApp"
            icon={bookJobBlue}
            defaultActiveKey="6"
            eventKey="6"
          />
        {/* Mobile App Videos End */}
        

        {/* Inspection Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767375"
            title="Inspection"
            pageId="inspection"
            icon={inspectionsBlue}
            defaultActiveKey="7"
            eventKey="6"
          />
        {/* Inspection Videos End */}


        {/* Customer Assets Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767386"
            title="Vehicle List / Service Reminders"
            pageId="customerAssets"
            icon={customerBlue}
            defaultActiveKey="8"
            eventKey="6"
            icon_class="fa fa-list"
          />
        {/* Customer Assets Videos End */}


        {/* Marketing Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767382"
            title="Marketing"
            pageId="marketing"
            icon={customerBlue}
            defaultActiveKey="9"
            eventKey="6"
            icon_class="fa fa-bullhorn"
          />
        {/* Marketing Videos End */}



        {/* Materials Videos Start */}
        <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="13427764"
            title="Materials"
            pageId="materials"
            icon={materialsBlue}
            defaultActiveKey="10"
            eventKey="6"
        />
        {/* Materials Videos End */}


        


        {/* Account Sync Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767388"
            title="Account Sync"
            pageId="accountSync"
            icon={customerBlue}
            defaultActiveKey="11"
            eventKey="6"
            icon_class="fa fa-refresh"
          />
        {/* Account Sync Videos End */}


        {/* Reports Videos Start */}
          <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767390"
            title="Reports"
            pageId="reports"
            icon={customerBlue}
            defaultActiveKey="12"
            eventKey="6"
            icon_class=  "fa fa-bar-chart"
          />
        {/* Reports Videos End */}


        {/* Setting Videos Start */}
        <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767391"
            title="Setting"
            pageId="settings"
            icon={customerBlue}
            defaultActiveKey="13"
            eventKey="6"
            icon_class="fa fa-gears"
          />
        {/* Setting Videos End */}


        {/* Setting Videos Start */}
        {/* <AllSectionVideos 
            handleVimeoPlayerShow={handleVimeoPlayerShow}
            listView={listView}
            searchKey={searchKey}
            vimoeId="3767391"
            title="Time Log History"
            pageId="TimeLogHistory"
            icon={customerBlue}
            defaultActiveKey="14"
            eventKey="6"
            icon_class="fa fa-gears"
          /> */}
        {/* Setting Videos End */}

        
        
        
      </div>

      {vimeoPlayerShow && (
          <Presence>
            {(show) => (
              <VimeoPlayer
                show={show}
                onHide={() => {
                  setVimeoPlayerShow(false);
                }}
                url={currentVideoURL}
                title={currentVideoTitle}
                header={currentVideoHeader}
              />
            )}
          </Presence>
      )}

    </>
  );
};

export default TraineeVideo;
