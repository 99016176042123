import { addAssetsDetails, addAssetsMaster, getAssetsDetails, getAssetsMaster } from "helpers/backendHelper";

export async function getAsssetIdsData(vehicleData, vehicle_type) {


  
  
  let assetMasterId = "",
    assetsDetailId = "";

  let data = [];
  let assetsMaster = {
    payload: {
      get: "all",
      conditions: {
        assets_model: vehicleData.model,
        assets_make: vehicleData.make,
      },
    },
  };

  await getAssetsMaster(assetsMaster).then(async (assetsMasterRes) => {

    if (assetsMasterRes.code === 200) {
      assetMasterId = assetsMasterRes.data.AssetsMaster[0].id;
      let assetsDetails = {
        payload: {
          get: "all",
          conditions: {
            asset_master_id: assetsMasterRes.data.AssetsMaster[0].id,
            // fuel_type: vehicleData.fuelType,
            series: vehicleData?.series || vehicleData?.model_series || ""
          },
          limit: 1,
        },
      };

      await getAssetsDetails(assetsDetails).then(
        async (assetsDetailsRes) => {
          if (assetsDetailsRes.code === 200) {
            assetsDetailId = assetsDetailsRes.data.AssetsDetails[0].id;
          } else {
            let assetsDetailsArr = {};
            let bodyType = vehicleData?.bodyType || vehicleData?.body_type || "";
            assetsDetailsArr["asset_master_id"] =
              assetsMasterRes.data.AssetsMaster[0].id;
            assetsDetailsArr["fuel_type"] = vehicleData?.fuelType || vehicleData?.fuel_type || "";
            assetsDetailsArr["row_data"] = bodyType + "$-$" + vehicleData.year + "$-$";
            assetsDetailsArr["series"] = vehicleData?.series || vehicleData?.model_series || "";
            assetsDetailsArr["is_in_au"] = 1;
            assetsDetailsArr["is_in_nz"] = 1;


            let addAssetsDetail = {
              payload: assetsDetailsArr,
            };

            await addAssetsDetails(addAssetsDetail).then(
              (addAssetsDetailRes) => {
                if (addAssetsDetailRes.code === 200)
                  assetsDetailId = addAssetsDetailRes.data.AssetsDetails.id;
              }
            );
          }
        }
      );
    } else {
      let storeAssetMaster = {
        payload: {
          assets_model: vehicleData.model,
          assets_make: vehicleData.make,
          vehicle_type: vehicle_type
        },
      }
      
      await addAssetsMaster(storeAssetMaster).then(
        async (addassetsMasterRes) => {
          if (addassetsMasterRes.code === 200) {
            assetMasterId = addassetsMasterRes.data.AssetsMaster.id;

            let assetsDetailsArr = {};
            let bodyType = vehicleData?.bodyType || vehicleData?.body_type || "";
            assetsDetailsArr["asset_master_id"] = assetMasterId;
            assetsDetailsArr["fuel_type"] = vehicleData?.fuelType || vehicleData?.fuel_type || "";
            assetsDetailsArr["row_data"] = bodyType + "$-$" + vehicleData.year + "$-$";
            assetsDetailsArr["series"] = vehicleData?.series || vehicleData?.model_series|| "";
            assetsDetailsArr["is_in_au"] = 1;
            assetsDetailsArr["is_in_nz"] = 1;


            let addAssetsDetail = {
              payload: assetsDetailsArr,
            };

            await addAssetsDetails(addAssetsDetail).then(
              (addAssetsDetailRes) => {
                if (addAssetsDetailRes.code === 200)
                  assetsDetailId = addAssetsDetailRes.data.AssetsDetails.id;
              }
            );
          } 
        }
      );
    }
  });

  data['assetMasterId'] = +assetMasterId;
  data['assetsDetailId'] = +assetsDetailId;

  return data;
}

