import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  saveStockFail,
  saveStockSuccess,
  stockListFail,
  stockListSuccess,
  updatePartDataFail,
  updatePartDataSuccess,
  stockTakeListFail,
  stockTakeListSuccess,
  stockTakeHistoryListSuccess,
  editStockTakeDetailsSuccess,
  editStockTakeDetailsFail,
  saveStockTakeSuccess,
  saveStockTakeFail,
  updatePartsQtySuccess,
  updatePartsQtyFail,
} from "./actions";
import {
  INVENTORY_STOCK_LIST,
  SAVE_STOCK,
  UPDATE_PART_DATA,
  INVENTORY_STOCK_TAKE_LIST,
  EDIT_STOCK_TAKE_DETAILS,
  SAVE_STOCK_TAKE,
  UPDATE_PARTS_QTY,
} from "./actionTypes";

import { showError, showSuccess } from "utils/alertMessage";
import {
  getStockList,
  saveStock,
  updatePartData,
  getStockTakeList,
  editStockTakeDetails,
  saveStockTakeData,
  updatePartsQty,
} from "../../helpers/backendHelper";

function* onGetStockList(data) {
  try {
    const response = yield call(getStockList, data);
    if (response) {
      if (response.code === 200) {
        yield put(stockListSuccess(response.data.InventoryStock));
      } else {
        yield put(stockListFail(response));
      }
    } else {
      yield put(stockListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(stockListFail(error.response));
  }
}

function* onSaveStock(data) {
  try {
    const response = yield call(saveStock, data);
    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);
        yield put(saveStockSuccess(response.data.InventoryStock));
      } else {
        showError(response.message);
        yield put(saveStockFail(response));
      }
    } else {
      showError("Didn't get api response");
      yield put(saveStockFail("Didn't get api response"));
    }
  } catch (error) {
    showError(error.response);
    yield put(saveStockFail(error.response));
  }
}

function* onGetUpdatePartData(data) {
  try {
    const response = yield call(updatePartData, data);
    if (response) {
      if (response.code === 200) {
        yield put(updatePartDataSuccess(response));
      } else {
        yield put(updatePartDataFail(response));
      }
    } else {
      yield put(updatePartDataFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updatePartDataFail(error.response));
  }
}

function* onGetStockTakeList(data) {
  const { history } = data;
  try {
    const response = yield call(getStockTakeList, data);
    if (response) {
      if (response.code === 200) {
        if (history) {
          yield put(
            stockTakeHistoryListSuccess(response.data.InventoryStockTake)
          );
        } else {
          yield put(stockTakeListSuccess(response.data.InventoryStockTake));
        }
      } else {
        yield put(stockTakeListFail(response));
      }
    } else {
      yield put(stockTakeListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(stockTakeListFail(error));
  }
}

function* onEditStockTakeDetails(data) {
  try {
    const response = yield call(editStockTakeDetails, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(
          editStockTakeDetailsSuccess(response.data.InventoryStockTake)
        );
      } else {
        yield put(editStockTakeDetailsFail(response));
      }
    } else {
      yield put(editStockTakeDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editStockTakeDetailsFail(error.response));
  }
}

function* onSaveStockTake(data) {
  try {
    const response = yield call(saveStockTakeData, data);
    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);
        yield put(saveStockTakeSuccess(response.data.InventoryStock));
      } else {
        showError(response.message);
        yield put(saveStockTakeFail(response));
      }
    } else {
      showError("Didn't get api response");
      yield put(saveStockTakeFail("Didn't get api response"));
    }
  } catch (error) {
    showError(error.response);
    yield put(saveStockTakeFail(error.response));
  }
}

function* onUpdatePartData(data) {
  try {
    const response = yield call(updatePartsQty, data);
    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);
        yield put(updatePartsQtySuccess(response.data.InventoryStock));
      } else {
        showError(response.message);
        yield put(updatePartsQtyFail(response));
      }
    } else {
      showError("Didn't get api response");
      yield put(updatePartsQtyFail("Didn't get api response"));
    }
  } catch (error) {
    showError(error.response);
    yield put(updatePartsQtyFail(error.response));
  }
}

function* StockSaga() {
  yield takeLatest(INVENTORY_STOCK_LIST, onGetStockList);
  yield takeLatest(SAVE_STOCK, onSaveStock);
  yield takeLatest(UPDATE_PART_DATA, onGetUpdatePartData);
  yield takeEvery(INVENTORY_STOCK_TAKE_LIST, onGetStockTakeList);
  yield takeEvery(EDIT_STOCK_TAKE_DETAILS, onEditStockTakeDetails);
  yield takeLatest(SAVE_STOCK_TAKE, onSaveStockTake);
  yield takeLatest(UPDATE_PARTS_QTY, onUpdatePartData);
}

export default StockSaga;
