import { call, put, select } from "redux-saga/effects";
import { GET_LABOUR_CHARGES_LIST } from "store/commonApiStore/actionTypes";
import { GET_JOB_LABOUR_LIST } from "store/jobLabour/actionTypes";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import * as actions from "./actionTypes";
import {
  onGetInspections,
  onGetInspectionTemplates,
  onGetTechnician,
} from "./saga";
export function* onGetInspectionsData({ payload }) {
  const { jobBookedData, inspInspections } = yield select(
    (state) => state.JobDetails
  );
  const { is_inspection_business } = yield select(
    (state) => state.LoginReducer
  );
  const { inspection_id, job_id } = payload;
  const workshop_id = getWorkshopId();
  /*GET INSPECTION DETAILS*/

  let refetch =
    typeof inspection_id == "undefined" ||
    inspection_id !== inspInspections[0]?.id;
  try {
    if (payload.inspection_id && refetch) {
      yield call(onGetInspections, {
        type: actions.GET_INSPECTIONS,
        payload: {
          get: "all",
          conditions: {
            "InspInspections.id": inspection_id,
            "InspInspections.workshop_id": Number(workshop_id),
            "InspInspections.status": 1,
            "InspInspections.is_deleted": 0,
          },
          order: {
            "InspInspections.id": "desc",
          },
          contain: {
            0: "ApprovedBy",
            InspQuestionAns: {
              fields: [
                "InspQuestionAns.id",
                "InspQuestionAns.insp_inspection_id",
                "InspQuestionAns.insp_question_id",
                "InspQuestionAns.part_id",
                "InspQuestionAns.part_name",
                "InspQuestionAns.question_text",
                "InspQuestionAns.answers",
                "InspQuestionAns.qn_status",
              ],
            },
            "InspApprovedBy.CustomersDetails": {
              conditions: {
                "CustomersDetails.workshop_id =": Number(workshop_id),
              },
            },
            Users: {
              fields: ["firstname", "lastname", "id"],
            },
            InspTemplates: {
              fields: [
                "InspTemplates.id",
                "InspTemplates.workshop_id",
                "InspTemplates.categories_id",
                "InspTemplates.name",
                "InspTemplates.description",
                "InspTemplates.group_data",
              ],
            },
            "InspTemplates.InspQuestion": {
              fields: [
                "InspQuestion.id",
                "InspQuestion.name",
                "InspQuestion.type",
                "InspQuestion.insp_group_id",
                "InspQuestion.insp_template_id",
              ],
            },
            JobSchedules: {
              fields: ["insp_technician_id", "id"],
            },
          },
        },
      });
    }

    if (refetch) {
      /*GET INSPECTION TEMPLATES*/
      yield call(onGetInspectionTemplates, {
        type: actions.GET_INSPECTION_TEMPLATES,
        payload: {
          get: "all",
          conditions: {
            workshop_id: is_inspection_business == 1 ? Number(workshop_id) : 0,
            is_deleted: 0,
            status: 1,
          },
          contain: {
            InspQuestion: {
              sort: [
                "InspQuestion.insp_group_id ASC",
                "InspQuestion.position ASC",
              ],
            },
          },
          order: {
            priority: "asc",
          },
        },
        is_inspection_business,
      });
    }

    /*TECHNITIONS AND APPROVED BY SERVICE ADVISOR*/
    if (refetch) {
      yield call(onGetTechnician, {
        type: actions.GET_TECHNICIAN_LIST,
        payload: {
          get: "all",
          conditions: {
            "Users.workshop_id": Number(workshop_id),
            "role_id IN": [3, 5, 6, 7, 8, 9, 10],
            "Users.is_deleted": 0,
            // "Users.Id !=": 18088,
          },
          "contain": {
            "EmployeesDetails" : {
                "fields":["id","user_id","workshop_id","position","labour_charges_id"]
            }
          },
          "fields":["id","is_deleted","role_id","workshop_id","firstname","lastname","email","phone_number"]
        },
      });

      yield put({
        type: GET_LABOUR_CHARGES_LIST,
        payload: {
          get: "all",
          conditions: {
            workshop_id: getWorkshopId(),
            "labour_code !=": "",
          },
        },
      });
    }

    yield put({
      type: actions.GET_INSPECTION_DETAIL_SUCCESS,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: actions.GET_INSPECTION_DETAIL_FAIL,
    });
  }
}
