import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { CHECK_MOBILE_EXIST } from "../../store/contacts/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { SENT_BOOKINGJOB_SMS } from "../../store/jobDetails/actionTypes";
import { showSuccess } from "utils/alertMessage";
import axios from "axios";
import smsImage from "../../assets/images/sms-vector.png";
import { useNavigate } from "react-router-dom";
import { getSubscriptionAddon } from "helpers/backendHelper";
import JobSmsBalance from "views/modals/JobSmsBalance";
import { useInitialData } from "views/common/InitialDataContext/InitialDataContext";

const SendSMSToUser = ({
    rego,
    workshop_id,
    type,
    id,
    cust_id,
    customer_id,
    mobileCode,
    setShowAddMobileModal,
    setUpdateMobileNumber,
    setMobileNumberForCustomer,
    mobileNumberForCustomer,
    jobDetails,
    setJobDetails,
    customerName,
    inspWorkType = "",
    inspStatus = "",
    approvalToken = "",
    number = "",
    inspectionId = "",
    rescheduleDate = "",
    rescheduleTime = "",
}) => {

    const localStoredData = JSON.parse(localStorage.getItem('data'));
    let workshop_phone = localStoredData?.workshop?.phone;

    const navigate = useNavigate();
    const handleModalClose = () => {
        setShowAddMobileModal(false);
    }
    const [isUpdateMobile, setIsUpdateMobile] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(mobileNumberForCustomer);
    const [validateMobile, setValidateMobile] = useState(false);
    const [udpateJobBookingConfirmation, setUdpateJobBookingConfirmation] = useState(false);

    const [showSmsBalanceModal, setShowSmsBalanceModal] = useState(false);
    const handleShowSmsBalanceModal = () => setShowSmsBalanceModal(true);
    const handleCloseSmsBalanceModal = () => {
        setShowSmsBalanceModal(false)
        handleModalClose();
    };

    const dispatch = useDispatch();
    let MobileExist = useSelector(
        (state) => state.ContactsReducer.checkMobileData
    );

    const checkMobileExist = (e) => {
        if (e.length !== 0) {
            if (e.length >= 9 && e.length <= 11) {
                setValidateMobile(false);
                dispatch({
                    type: CHECK_MOBILE_EXIST,
                    payload: {
                        get: "all",
                        conditions: {
                            mobile_number: '0' + e,
                            is_deleted: 0,
                            workshop_id: workshop_id,
                            "id !=": cust_id
                        },
                    },
                });
            } else {
                setValidateMobile(true);
            }
        } else {
            MobileExist = false;
        }
    };

    const [tinyUrl, setTinyUrl] = useState();
    const [inspTinyUrl, setInspTinyUrl] = useState();

    const converToTinyUrl = async (values) => {
        await axios
            .get("https://tinyurl.com/api-create.php?url=" + values)
            .then((response) => {
                setTinyUrl(response.data)
            })
            .catch((error) => {

            });
    }

    const converToInspectionUrl = async (values) => {
        await axios
            .get("https://tinyurl.com/api-create.php?url=" + values)
            .then((response) => {
                setInspTinyUrl(response.data)
            })
            .catch((error) => {

            });
    }

    if (type == "quote") {
        // for Quote  /* digital-quote-pdf/id WS worskhopid  */
        converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-quote-pdf/" + window.btoa(id + "WS" + workshop_id));
    } else if (type == "invoice") {
        // for Invoice  /* digital-quote-pdf/id WS worskhopid WS IN */
        converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(id + "WS" + workshop_id + "WSIN"));
        if (inspWorkType === "Need to book a job" && inspStatus != "Sent") {
            converToInspectionUrl(process.env.REACT_APP_SITE_URL + "inspection-email-verification/" + approvalToken);
        }
    } else if (type == "cashsale") {
        // for Cashsale  /* digital-quote-pdf/id WS worskhopid WS CS */
        converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(id + "WS" + workshop_id + "WSCS"));
    } else if (type == "payment_invoice") {
        converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(id + "WS" + workshop_id + "WSINWSP"));
    } else if (type == "payment_cashsale") {
        converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(id + "WS" + workshop_id + "WSCSWSP"));
    }

    const {
        expDayCount,
        isTrialMode,
        subscriptionType,
    } = useInitialData();

    async function getSMSBalanceData() {
        if (subscriptionType === "Trial" && isTrialMode && expDayCount > 0) {
            handleSaveAndSend();
        } else {
            let data = {
                payload: {
                    get: "all",
                    conditions: {
                        "WorkshopSubscriptionDetails.plan_type": "sms",
                        "WorkshopSubscriptionDetails.current_sms_limit >": 0,
                        "WorkshopSubscriptionDetails.workshop_id": workshop_id,
                    },
                    fields: [
                        "id",
                        "workshop_subscription_id",
                        "subscription_plan_id",
                        "sms_limit",
                        "current_sms_limit",
                    ],
                    order: { "WorkshopSubscriptionDetails.id": "DESC" },
                },
            };
            let response = await getSubscriptionAddon(data);
            if (response.code === 200) {
                handleSaveAndSend();
            } else {
                handleShowSmsBalanceModal();
            }
        }
    }

    const handleSaveAndSend = () => {
        if (mobileNumber.length >= 9 && mobileNumber.length <= 11) {
            setValidateMobile(false);
            setMobileNumberForCustomer(mobileNumber);
            setUpdateMobileNumber(true);
            var messageVar = "Invoice";
            var table = "";
            if (type == "quote") {
                messageVar = "Quote";
                table = "chkQuotes";
            } else if (type == "invoice" || type == "payment_invoice") {
                table = "chkInvoice";
            } else if (type == "cashsale" || type == "payment_cashsale") {
                table = "chkCashsale";
            } else if (type == "job" || type == "quoteToJob") {
                messageVar = "Job";
                table = "chkJobs";
            }
            let smsFormData = {};

            smsFormData.type = type;
            smsFormData.workshop_id = workshop_id;
            smsFormData.job_id = id;
            smsFormData.customer_id = customer_id;
            smsFormData.send_method = 'sms';
            smsFormData.table = table;
            smsFormData.send_to = mobileNumber;
            smsFormData.to = mobileNumber;
            // smsFormData.message = "Please find your vehicle's ("+ rego +") "+messageVar+" "+ tinyUrl;
            smsFormData.message = "Hi " + customerName + " click the link to view your digital " + messageVar + " for Rego #" + rego + " " + tinyUrl;
            if (inspWorkType === "Need to book a job" && inspStatus != "Sent") {
                smsFormData.insp_message = "Hi " + customerName + " please click the link to view your Inspection sheet for Rego #" + rego + " and additional work approval. ";
                smsFormData.insp_id = inspectionId;
            }
            // if(inspTinyUrl) {
            // smsFormData.message = "Hi "+customerName+" please click the link to view your Inspection sheet for Rego #"+rego+" and additional work approval. "+tinyUrl;
            // smsFormData.message += " Digital Inspection "+inspTinyUrl;
            // }

            if (type == "payment_invoice" || type == "payment_cashsale") {
                smsFormData.message = "Hi " + customerName + " click the link to view your paid digital " + messageVar + " for Rego #" + rego + " " + tinyUrl;
            } else if (type == "job") {
                smsFormData.message = "Hi " + customerName + " confirming your vehicle Rego #" + rego + " is reschedule on " + rescheduleDate + " at " + rescheduleTime + " .Contact us if you need to change this appointment on " + workshop_phone;
            } else if (type == "quoteToJob") {
                smsFormData.message = "Hi " + customerName + " confirming your vehicle Rego #" + rego + " is booked on " + rescheduleDate + " at " + rescheduleTime + " .Contact us if you need to change this appointment on " + workshop_phone;
            }

            smsFormData.number = number;
            smsFormData.phone_code = mobileCode;
            smsFormData.subject = "SMS";

            // if(mobileNumber != mobileNumberForCustomer){
            if (isUpdateMobile) {

                if (type == "quote") {
                    let tempJobDetails = jobDetails;
                    tempJobDetails.user.customers_details[0].mobile_number = mobileNumber;
                    setJobDetails({
                        ...jobDetails,
                        tempJobDetails
                    });
                }

                if (type == "invoice") {
                    setMobileNumberForCustomer(mobileNumber)
                }

                smsFormData.update_mobile = "1";
            }

            dispatch({
                type: SENT_BOOKINGJOB_SMS,
                payload: smsFormData,
            });

            setUdpateJobBookingConfirmation(true);

            if (type == "payment_invoice" || type == "payment_cashsale") {
                navigate("/invoice#completed-invoices");
            }
        } else {
            setValidateMobile(true);
        }
    }

    useEffect(() => {
        if (isUpdateMobile) {
            checkMobileExist(mobileNumber)
        } else {
            MobileExist = false;
        }
    }, [isUpdateMobile])

    const jobBookingConfirmation = useSelector((state) => state?.JobDetails?.jobBookSmsSent || false);

    useEffect(() => {
        if (jobBookingConfirmation && udpateJobBookingConfirmation) {
            showSuccess('SMS sent successfully!');
            setUdpateJobBookingConfirmation(false);
            handleModalClose();
        }
    }, [jobBookingConfirmation, udpateJobBookingConfirmation])

    return (
        <>
            <Modal
                className={"sms-balance-active sms-balance-active-popup " + ((showSmsBalanceModal == true) ? " hide" : "")}
                size="sm"
                show={true}
                centered
            >
                <Modal.Header>
                    <Modal.Title className="d-flex justify-space-between">
                        <span>SMS {(type == "job" || type == "quoteToJob") ? " Booking Confirmation" : (type == "quote" ? "Quote to Customer" : "Invoice to Customer")}  </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <Form.Group className="align-items-center d-flex form-group justify-content-around mt-2">
                            <div className="">
                                <img src={smsImage} alt="SMS" style={{ maxWidth: "50px" }}></img>
                            </div>
                            <div>
                                <Form.Control
                                    className="font-size-16"
                                    type="text"
                                    minLength={9}
                                    maxLength={11}
                                    value={mobileNumber}
                                    required
                                    onChange={(e) => {
                                        if (isUpdateMobile) {
                                            checkMobileExist(e.target.value)
                                        }
                                        setMobileNumber(e.target.value)
                                    }}
                                    placeholder="Mobile Number"
                                />
                            </div>
                            {MobileExist && (
                                <div className="err-msg">
                                    This Mobile Number is already in use.
                                </div>
                            )}
                            {validateMobile && (
                                <div className="err-msg">
                                    Please enter a valid Mobile Number.
                                </div>
                            )}
                        </Form.Group>
                    </div>
                    <div className="col-md-12">
                        <div className="align-items-center d-flex form-group justify-center mb-2" style={{ marginLeft: "2rem" }}>
                            <Form.Check className="custom-check form-check table-checkbox">
                                <Form.Check.Input
                                    id="update_message"
                                    type="checkbox"
                                    className="custom-check booking-confirmation"
                                    onChange={() => {
                                        setIsUpdateMobile(!isUpdateMobile)
                                    }}
                                />
                                <Form.Check.Label>
                                    <span className="check"></span>
                                </Form.Check.Label>
                            </Form.Check>
                            <div style={{ marginLeft: "10px" }} className="font-size-13">Update Customer Mobile #</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-center">
                        <Button variant="outline-secondary" className="btn-outline rounded-btn mr-5" id="closeButton" onClick={handleModalClose}>Cancel</Button>
                        <Button
                            type="button"
                            variant="outline-secondary"
                            className={"btn-primary rounded-btn  " + ((MobileExist) ? 'disabled' : '')}
                            onClick={
                                () => {
                                    if (type == "job" || type == "quoteToJob") {
                                        handleSaveAndSend();
                                    } else {
                                        getSMSBalanceData();
                                    }
                                }
                            }
                        >Send SMS</Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {showSmsBalanceModal && (
                <JobSmsBalance
                    showSmsBalanceModal={showSmsBalanceModal}
                    handleShowSmsBalanceModal={handleShowSmsBalanceModal}
                    handleCloseSmsBalanceModal={handleCloseSmsBalanceModal}
                />
            )}
        </>
    );
};
export default SendSMSToUser;
