import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteArchiveCreditInvoice } from "store/invoices/actions";
import { getInvoiceCreditsAPI } from "store/invoices/api";
import Loader from "views/common/UI/Loader";
import CommonSecond from "./CommonSecond";
import { useInvoiceColumns } from "./useInvoiceColumns";

const InvoiceCredits = () => {
  const invoice = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  const [deletePayload, setDeletePayload] = useState(null);

  const navigate = useNavigate();

  const { invoiceCreditsColumns } = useInvoiceColumns({
    onView: ({ row }) => {
      if (row.job_number === "Cash Sale") {
        navigate(`/view-cashsale-credit/${row.id}`);
        return;
      }
      navigate(`/view-invoice-credit/${row.id}`);
    },
    onMail: ({ row }) => {
      if (row.job_number === "Cash Sale") {
        navigate(`/cashsale-credit-email/${row.id}`);
        return;
      }
      navigate(`/invoice-credit-email/${row.id}`);
    },
    onPrint: ({ row }) => {
      if (row.job_number === "Cash Sale") {
        navigate(`/cashsale-credit-email/${row.id}?print=true`);
        return;
      }
      navigate(`/invoice-credit-email/${row.id}?print=true`);
    },
    onDelete: ({ row }) => {
      //Delete
      setDeletePayload([row]);
    },
  });

  const deleteEvent = (cb) => {
    const payload = {
      action_type: "delete",
      ids: deletePayload.map((c) => c.id),
    };
    dispatch(deleteArchiveCreditInvoice(payload));
    cb();
  };

  return (
    <>
      {invoice?.invoiceCredits?.invoiceDeleteStatus === "started" && <Loader />}
      <CommonSecond
        deletePayload={deletePayload}
        setDeletePayload={setDeletePayload}
        api={getInvoiceCreditsAPI}
        columns={invoiceCreditsColumns}
        main={invoice?.invoiceCredits}
        // onCreateCashSale={() => { }}
        onRowClick={(row) => {
          if (row.job_number === "Cash Sale") {
            navigate(`/view-cashsale-credit/${row.id}`);
            return;
          }
          navigate(`/view-invoice-credit/${row.id}`);
        }}
        onSearch={(e) => {}}
        completed={true}
        deleteInvoices={deleteEvent}
      />
    </>
  );
};

export default InvoiceCredits;
