export const GET_STORAGE_DEVICES = "GET_STORAGE_DEVICES";
export const GET_STORAGE_DEVICES_SUCCESS = "GET_STORAGE_DEVICES_SUCCESS";
export const GET_STORAGE_DEVICES_FAIL = "GET_STORAGE_DEVICES_FAIL";

export const EDIT_STORAGE_DEVICE = "EDIT_STORAGE_DEVICE";
export const EDIT_STORAGE_DEVICE_SUCCESS = "EDIT_STORAGE_DEVICE_SUCCESS";
export const EDIT_STORAGE_DEVICE_FAIL = "EDIT_STORAGE_DEVICE_FAIL";

export const UPDATE_STORAGE_DEVICE = "UPDATE_STORAGE_DEVICE";
export const UPDATE_STORAGE_DEVICE_SUCCESS = "UPDATE_STORAGE_DEVICE_SUCCESS";
export const UPDATE_STORAGE_DEVICE_FAIL = "UPDATE_STORAGE_DEVICE_FAIL";

export const ADD_STORAGE_DEVICE = "ADD_STORAGE_DEVICE";
export const ADD_STORAGE_DEVICE_SUCCESS = "ADD_STORAGE_DEVICE_SUCCESS";
export const ADD_STORAGE_DEVICE_FAIL = "ADD_STORAGE_DEVICE_FAIL";

export const VIEW_STORAGE_DEVICE_DETAILS = "VIEW_STORAGE_DEVICE_DETAILS";
export const VIEW_STORAGE_DEVICE_DETAILS_SUCCESS = "VIEW_STORAGE_DEVICE_DETAILS_SUCCESS";
export const VIEW_STORAGE_DEVICE_DETAILS_FAIL = "VIEW_STORAGE_DEVICE_DETAILS_FAIL";
