import {
    CONNECT_QUICKBOOK,
    CONNECT_QUICKBOOK_SUCCESS,
    CONNECT_QUICKBOOK_FAIL,
    DISCONNECT_QUICKBOOK,
    DISCONNECT_QUICKBOOK_SUCCESS,
    DISCONNECT_QUICKBOOK_FAIL,
    DISCONNECT_MYOB,
    DISCONNECT_MYOB_SUCCESS,
    DISCONNECT_MYOB_FAIL,
    UPDATE_WORKSHOP_DETAIL,
    UPDATE_WORKSHOP_DETAIL_SUCCESS,
    UPDATE_WORKSHOP_DETAIL_FAIL,
    GET_SYNC_ACCOUNT_DATA,
    GET_SYNC_ACCOUNT_DATA_SUCCESS,
    GET_SYNC_ACCOUNT_DATA_FAIL,
    GET_SYNC_TAX_DATA,
    GET_SYNC_TAX_DATA_SUCCESS,
    GET_SYNC_TAX_DATA_FAIL,
    UPDATE_SYNC_ACCOUNT_DATA,
    UPDATE_SYNC_ACCOUNT_DATA_SUCCESS,
    UPDATE_SYNC_ACCOUNT_DATA_FAIL,
    UPDATE_SYNC_TAX_DATA,
    UPDATE_SYNC_TAX_DATA_SUCCESS,
    UPDATE_SYNC_TAX_DATA_FAIL,
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    GET_USERS_TECHNICIAN,
    GET_USERS_TECHNICIAN_SUCCESS,
    GET_USERS_TECHNICIAN_FAIL,
    GET_INVOICE,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_FAIL,
    GET_INVOICE_PAYMENTS_DATA,
    GET_INVOICE_PAYMENTS_DATA_SUCCESS,
    GET_INVOICE_PAYMENTS_DATA_FAIL,
    GET_INVOICE_PAYMENTS_CREDIT,
    GET_INVOICE_PAYMENTS_CREDIT_SUCCESS,
    GET_INVOICE_PAYMENTS_CREDIT_FAIL,
    SYNC_TRANSFER,
    SYNC_TRANSFER_SUCCESS,
    SYNC_TRANSFER_FAIL,
    GET_SYNC_DATA,
    GET_SYNC_DATA_SUCCESS,
    GET_SYNC_DATA_FAIL,
    UPDATE_MANY_WORKSHOP_TAX_DETAILS,
    UPDATE_MANY_WORKSHOP_TAX_DETAILS_SUCCESS,
    UPDATE_MANY_WORKSHOP_TAX_DETAILS_FAIL,
    UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS,
    UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_SUCCESS,
    UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_FAIL,
    DELETE_SYNC_ACCOUNTS,
    DELETE_SYNC_ACCOUNTS_SUCCESS,
    DELETE_SYNC_ACCOUNTS_FAIL,
    DELETE_SYNC_TAX,
    DELETE_SYNC_TAX_SUCCESS,
    DELETE_SYNC_TAX_FAIL,
    CONNECT_XERO,
    CONNECT_XERO_SUCCESS,
    CONNECT_XERO_FAIL,
    DISCONNECT_XERO,
    DISCONNECT_XERO_SUCCESS,
    DISCONNECT_XERO_FAIL,
    IMPORT_MYOB,
    IMPORT_MYOB_SUCCESS,
    IMPORT_MYOB_FAIL,
    GET_CASH_SALE_INVOICE,
    GET_CASH_SALE_INVOICE_SUCCESS,
    GET_CASH_SALE_INVOICE_FAIL,
    GET_CASH_SALE_INVOICE_PAYMENT,
    GET_CASH_SALE_INVOICE_PAYMENT_SUCCESS,
    GET_CASH_SALE_INVOICE_PAYMENT_FAIL,
    GET_CASH_SALE_INVOICE_PAYMENT_CREDIT,
    GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_SUCCESS,
    GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_FAIL,
    ADD_EMPLOYEE_CLOCKING_HISTORY,
    ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
    ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL,
    UPDATE_EMPLOYEE_CLOCKING_HISTORY,
    UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
    UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL,
    CRON_SYNC_TRANSFER,
    CRON_SYNC_TRANSFER_SUCCESS,
    CRON_SYNC_TRANSFER_FAIL,
} from "./actionTypes";

export const connectQuickbook = () => {
    return {
        type: CONNECT_QUICKBOOK,
    };
};

export const connectQuickbookSuccess = (data) => {
    return {
        type: CONNECT_QUICKBOOK_SUCCESS,
        payload: data,
    };
};

export const connectQuickbookFail = (error) => {
    return {
        type: CONNECT_QUICKBOOK_FAIL,
        payload: error,
    };
};

export const disconnectMyob = () => {
    return {
        type: DISCONNECT_MYOB,
    };
};

export const disconnectMyobSuccess = (data) => {
    return {
        type: DISCONNECT_MYOB_SUCCESS,
        payload: data,
    };
};

export const disconnectMyobFail = (error) => {
    return {
        type: DISCONNECT_MYOB_FAIL,
        payload: error,
    };
};

export const updateWorkshopDetail = () => {
    return {
        type: UPDATE_WORKSHOP_DETAIL,
    };
};

export const updateWorkshopDetailSuccess = (data) => {
    return {
        type: UPDATE_WORKSHOP_DETAIL_SUCCESS,
        payload: data,
    };
};

export const updateWorkshopDetailFail = (error) => {
    return {
        type: UPDATE_WORKSHOP_DETAIL_FAIL,
        payload: error,
    };
};

export const getSyncAccountData = () => {
    return {
        type: GET_SYNC_ACCOUNT_DATA,
    };
};

export const getSyncAccountDataSuccess = (data) => {
    return {
        type: GET_SYNC_ACCOUNT_DATA_SUCCESS,
        payload: data,
    };
};

export const getSyncAccountDataFail = (error) => {
    return {
        type: GET_SYNC_ACCOUNT_DATA_FAIL,
        payload: error,
    };
};

export const getSyncTaxData = () => {
    return {
        type: GET_SYNC_TAX_DATA,
    };
};

export const getSyncTaxDataSuccess = (data) => {
    return {
        type: GET_SYNC_TAX_DATA_SUCCESS,
        payload: data,
    };
};

export const getSyncTaxDataFail = (error) => {
    return {
        type: GET_SYNC_TAX_DATA_FAIL,
        payload: error,
    };
};


export const updateSyncAccountData = () => {
    return {
        type: UPDATE_SYNC_ACCOUNT_DATA,
    };
};

export const updateSyncAccountDataSuccess = (data) => {
    return {
        type: UPDATE_SYNC_ACCOUNT_DATA_SUCCESS,
        payload: data,
    };
};

export const updateSyncAccountDataFail = (error) => {
    return {
        type: UPDATE_SYNC_ACCOUNT_DATA_FAIL,
        payload: error,
    };
};

export const updateSyncTaxData = () => {
    return {
        type: UPDATE_SYNC_TAX_DATA,
    };
};

export const updateSyncTaxDataSuccess = (data) => {
    return {
        type: UPDATE_SYNC_TAX_DATA_SUCCESS,
        payload: data,
    };
};

export const updateSyncTaxDataFail = (error) => {
    return {
        type: UPDATE_SYNC_TAX_DATA_FAIL,
        payload: error,
    };
};

export const getUsers = () => {
    return {
        type: GET_USERS,
    };
};

export const getUsersSuccess = (data) => {
    return {
        type: GET_USERS_SUCCESS,
        payload: data,
    };
};

export const getUsersFail = (error) => {
    return {
        type: GET_USERS_FAIL,
        payload: error,
    };
};


export const getUsersTechnician = () => {
    return {
        type: GET_USERS_TECHNICIAN,
    };
};

export const getUsersTechnicianSuccess = (data) => {
    return {
        type: GET_USERS_TECHNICIAN_SUCCESS,
        payload: data,
    };
};

export const getUsersTechnicianFail = (error) => {
    return {
        type: GET_USERS_TECHNICIAN_FAIL,
        payload: error,
    };
};

export const getInvoice = () => {
    return {
        type: GET_INVOICE,
    };
};

export const getInvoiceSuccess = (data) => {
    return {
        type: GET_INVOICE_SUCCESS,
        payload: data,
    };
};

export const getInvoiceFail = (error) => {
    return {
        type: GET_INVOICE_FAIL,
        payload: error,
    };
};

export const getInvoicePaymentsData = () => {
    return {
        type: GET_INVOICE_PAYMENTS_DATA,
    };
};

export const getInvoicePaymentsDataSuccess = (data) => {
    return {

        type: GET_INVOICE_PAYMENTS_DATA_SUCCESS,
        payload: data,
    };
};

export const getInvoicePaymentsDataFail = (error) => {
    return {
        type: GET_INVOICE_PAYMENTS_DATA_FAIL,
        payload: error,
    };
};

export const getInvoicePaymentsCredit = () => {
    return {
        type: GET_INVOICE_PAYMENTS_CREDIT,
    };
};

export const getInvoicePaymentsCreditSuccess = (data) => {
    return {
        type: GET_INVOICE_PAYMENTS_CREDIT_SUCCESS,
        payload: data,
    };
};

export const getInvoicePaymentsCreditFail = (error) => {
    return {
        type: GET_INVOICE_PAYMENTS_CREDIT_FAIL,
        payload: error,
    };
};

export const syncTransfer = () => {
    return {
        type: SYNC_TRANSFER,
    };
};

export const syncTransferSuccess = (data) => {
    return {
        type: SYNC_TRANSFER_SUCCESS,
        payload: data,
    };
};

export const syncTransferFail = (error) => {
    return {
        type: SYNC_TRANSFER_FAIL,
        payload: error,
    };
};

export const getSyncData = () => {
    return {
        type: GET_SYNC_DATA,
    };
};

export const getSyncDataSuccess = (data) => {
    return {
        type: GET_SYNC_DATA_SUCCESS,
        payload: data,
    };
};

export const getSyncDataFail = (error) => {
    return {
        type: GET_SYNC_DATA_FAIL,
        payload: error,
    };
};

export const updateManyWorkshopTaxDetails = () => {
    return {
        type: UPDATE_MANY_WORKSHOP_TAX_DETAILS,
    };
};

export const updateManyWorkshopTaxDetailsSuccess = (data) => {
    return {
        type: UPDATE_MANY_WORKSHOP_TAX_DETAILS_SUCCESS,
        payload: data,
    };
};

export const updateManyWorkshopTaxDetailsFail = (error) => {
    return {
        type: UPDATE_MANY_WORKSHOP_TAX_DETAILS_FAIL,
        payload: error,
    };
};

export const updateManyWorkshopDepartmentDetails = () => {
    return {
        type: UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS,
    };
};

export const updateManyWorkshopDepartmentDetailsSuccess = (data) => {
    return {
        type: UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_SUCCESS,
        payload: data,
    };
};

export const updateManyWorkshopDepartmentDetailsFail = (error) => {
    return {
        type: UPDATE_MANY_WORKSHOP_DEPARTMENT_DETAILS_FAIL,
        payload: error,
    };
};


export const deleteSyncAccounts = () => {
    return {
        type: DELETE_SYNC_ACCOUNTS,
    };
};

export const deleteSyncAccountsSuccess = (data) => {
    return {
        type: DELETE_SYNC_ACCOUNTS_SUCCESS,
        payload: data,
    };
};

export const deleteSyncAccountsFail = (error) => {
    return {
        type: DELETE_SYNC_ACCOUNTS_FAIL,
        payload: error,
    };
};

export const deleteSyncTax = () => {
    return {
        type: DELETE_SYNC_TAX,
    };
};

export const deleteSyncTaxSuccess = (data) => {
    return {
        type: DELETE_SYNC_TAX_SUCCESS,
        payload: data,
    };
};

export const deleteSyncTaxFail = (error) => {
    return {
        type: DELETE_SYNC_TAX_FAIL,
        payload: error,
    };
};

export const connectXero = () => {
    return {
        type: CONNECT_XERO,
    };
};

export const connectXeroSuccess = (data) => {
    return {
        type: CONNECT_XERO_SUCCESS,
        payload: data,
    };
};

export const connectXeroFail = (error) => {
    return {
        type: CONNECT_XERO_FAIL,
        payload: error,
    };
};

export const disconnectXero = () => {
    return {
        type: DISCONNECT_XERO,
    };
};

export const disconnectXeroSuccess = (data) => {
    return {
        type: DISCONNECT_XERO_SUCCESS,
        payload: data,
    };
};

export const disconnectXeroFail = (error) => {
    return {
        type: DISCONNECT_XERO_FAIL,
        payload: error,
    };
};

export const disconnectQuickbook = () => {
    return {
        type: DISCONNECT_QUICKBOOK,
    };
};

export const disconnectQuickbookSuccess = (data) => {
    return {
        type: DISCONNECT_QUICKBOOK_SUCCESS,
        payload: data,
    };
};

export const disconnectQuickbookFail = (error) => {
    return {
        type: DISCONNECT_QUICKBOOK_FAIL,
        payload: error,
    };
};

export const importMyob = () => {
    return {
        type: IMPORT_MYOB,
    };
};

export const importMyobSuccess = (data) => {
    return {
        type: IMPORT_MYOB_SUCCESS,
        payload: data,
    };
};

export const importMyobFail = (error) => {
    return {
        type: IMPORT_MYOB_FAIL,
        payload: error,
    };
};


export const getCashSaleInvoice = (payload) => ({
    type: GET_CASH_SALE_INVOICE,
    payload,
});

export const getCashSaleInvoiceSuccess = (payload) => ({
    type: GET_CASH_SALE_INVOICE_SUCCESS,
    payload,
});

export const getCashSaleInvoiceFail = (payload) => ({
    type: GET_CASH_SALE_INVOICE_FAIL,
    payload,
});

export const getCashSaleInvoicePayment = (payload) => ({
    type: GET_CASH_SALE_INVOICE_PAYMENT,
    payload,
});

export const getCashSaleInvoicePaymentSuccess = (payload) => ({
    type: GET_CASH_SALE_INVOICE_PAYMENT_SUCCESS,
    payload,
});

export const getCashSaleInvoicePaymentFail = (payload) => ({
    type: GET_CASH_SALE_INVOICE_PAYMENT_FAIL,
    payload,
});

export const getCashSaleInvoicePaymentCredit = (payload) => ({
    type: GET_CASH_SALE_INVOICE_PAYMENT_CREDIT,
    payload,
});

export const getCashSaleInvoicePaymentCreditSuccess = (payload) => ({
    type: GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_SUCCESS,
    payload,
});

export const getCashSaleInvoicePaymentCreditFail = (payload) => ({
    type: GET_CASH_SALE_INVOICE_PAYMENT_CREDIT_FAIL,
    payload,
});



export const addEmployeeClockingHistory = () => {
    return {
        type: ADD_EMPLOYEE_CLOCKING_HISTORY,
    };
};

export const addEmployeeClockingHistorySuccess = (data) => {
    return {
        type: ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
        payload: data,
    };
};

export const addEmployeeClockingHistoryFail = (error) => {
    return {
        type: ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL,
        payload: error,
    };
};

export const updateEmployeeClockingHistory = () => {
    return {
        type: UPDATE_EMPLOYEE_CLOCKING_HISTORY,
    };
};

export const updateEmployeeClockingHistorySuccess = (data) => {
    return {
        type: UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
        payload: data,
    };
};

export const updateEmployeeClockingHistoryFail = (error) => {
    return {
        type: UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL,
        payload: error,
    };
};

export const cronSyncTransfer = () => {
    return {
        type: CRON_SYNC_TRANSFER,
    };
};

export const cronSyncTransferSuccess = (data) => {
    return {
        type: CRON_SYNC_TRANSFER_SUCCESS,
        payload: data,
    };
};

export const cronSyncTransferFail = (error) => {
    return {
        type: CRON_SYNC_TRANSFER_FAIL,
        payload: error,
    };
};