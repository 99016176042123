import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_WORKSHOP_MARKUP_DETAIL } from "../../../../../store/companyDetails/actionTypes";
import LoaderImage from "../../../../../../src/assets/images/page-loader.svg";
import { EDIT_WORKSHOP_MARKUP_DETAILS_SUCCESS } from "../../../../../store/commonApiStore/actionTypes";

const options = [
  { value: "0", label: "Markup" },
  { value: "1", label: "Margin" },
];

const CustomerPricingLevelsForm = () => {
  const dispatch = useDispatch(); // Dispatch variable
  const { formLoading, success } = useSelector(
    (state) => state.CompanyDetailReducer
  ); // GET Form loader from Company Detail Reducer data
  const { worskhopMarkupData } = useSelector(
    (state) => state.CommonApiStoreReducer
  ); // GET mark up detail for edit
  useEffect(() => {
    dispatch({
      type: EDIT_WORKSHOP_MARKUP_DETAILS_SUCCESS,
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: worskhopMarkupData ? worskhopMarkupData[0].charge_type : "",
      percentage: worskhopMarkupData ? worskhopMarkupData[0].percentage : "",
      type: worskhopMarkupData
        ? worskhopMarkupData[0].is_markup
        : options[0].value,
      is_default: worskhopMarkupData ? worskhopMarkupData[0].is_default : 0,
      markup_id: worskhopMarkupData ? worskhopMarkupData[0].id : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
      const workshop_id = window.atob(storedData.workshop_id);

      const editFormData = {
        workshop_id,
        charge_type: values.name,
        percentage: values.percentage,
        is_markup: values.type,
        is_default: values.is_default,
        id: values.markup_id,
      };
      dispatch({
        type: UPDATE_WORKSHOP_MARKUP_DETAIL,
        payload: editFormData,
      });
    },
    validate: (values) => {
      const error = {};
      if (!values.name) {
        error.name = "Please enter name";
      }
      if (!values.percentage) {
        error.percentage = "Please enter percentage";
      } else {
        if (!parseInt(values.percentage)) {
          error.percentage = "Please enter valid number";
        }
      }

      return error;
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [success]);

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <Form.Group className="form-group pb-3">
            <Form.Label>Name *</Form.Label>
            <Form.Control
              className={
                formik.touched.name && formik.errors.name
                  ? "form-control is-invalid"
                  : "form-control"
              }
              type="text"
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
            />
            {formik.errors.name && formik.touched.name && (
              <div className="invalidFeedback">{formik.errors.name}</div>
            )}
          </Form.Group>
        </div>
        <div className="col-md-3">
          <Form.Group className="form-group pb-3">
            <Form.Label>Percentage *</Form.Label>
            <Form.Control
              className={
                formik.touched.percentage && formik.errors.percentage
                  ? "form-control is-invalid"
                  : "form-control"
              }
              type="text"
              value={formik.values.percentage}
              onChange={(e) =>
                formik.setFieldValue("percentage", e.target.value)
              }
            />
            {formik.errors.percentage && formik.touched.percentage && (
              <div className="invalidFeedback">{formik.errors.percentage}</div>
            )}
          </Form.Group>
        </div>
        <div className="col-md-3">
          <Form.Group className="form-group pb-3">
            <Form.Label>Type</Form.Label>
            <Select
              className="custom-select"
              classNamePrefix="custom-select"
              options={options}
              value={options.filter(
                (data) => data.value === formik.values.type
              )}
              onChange={(e) => formik.setFieldValue("type", e.value)}
            />
          </Form.Group>
        </div>
        <div className="col-md-3">
          <div className="form-group pb-3">
            <label htmlFor="" className="form-label d-block">
              &nbsp;
            </label>
            <button
              className="btn btn-success px-3 btn-rounded"
              type="button"
              onClick={formik.handleSubmit}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      {/* {formLoading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )} */}
    </>
  );
};

export default CustomerPricingLevelsForm;
