import React, { useState } from "react";
import AssetTypeForm from "./components/assetTypeForm";
import AssetTypeList from "./components/assetTypeList";

const AssetType = () => {
  const [handleSubmit, setHandleSubmit] = useState(false);
  return (
    <>
      <div className="card-header d-flex justify-space-between">
        <h6 className="mb-0 text-uppercase py-2">VEHICLE TYPE</h6>
        <button
          type="button"
          className="btn btn-success px-4 btn-rounded"
          onClick={() => setHandleSubmit(true)}
        >
          Add
        </button>
      </div>
      <div className="card-body">
        <AssetTypeForm
          handleSubmit={handleSubmit}
          setHandleSubmit={setHandleSubmit}
        />
        <AssetTypeList />
      </div>
    </>
  );
};

export default AssetType;
