import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Reports = Loadable({
  loader: () => import("./components/Reports"),
  loading: Loading,
});

const Statements = Loadable({
  loader: () => import("./components/Statements/Statement"),
  loading: Loading,
});

const Invoice = Loadable({
  loader: () => import("./components/Invoices/Invoice"),
  loading: Loading,
});
const StatementPrint = Loadable({
  loader: () => import("./components/Invoices/StatementPrint"),
  loading: Loading,
});
const Job = Loadable({
  loader: () => import("./components/Jobs/Job"),
  loading: Loading,
});
const Quotes = Loadable({
  loader: () => import("./components/Quotes/Quotes"),
  loading: Loading,
});
const Customer = Loadable({
  loader: () => import("./components/Customer/Customer"),
  loading: Loading,
});
const Labour = Loadable({
  loader: () => import("./components/Labour/Labour"),
  loading: Loading,
});

const Materials = Loadable({
  loader: () => import("./components/Materials/Materials"),
  loading: Loading,
});

const Clocking = Loadable({
  loader: () => import("./components/Clocking/Clocking"),
  loading: Loading,
});

export const routes = [
  {
    path: "/reports/statements",
    exact: true,
    name: "statements",
    element: Statements,
  },
  {
    path: "/reports",
    exact: true,
    name: "reports",
    element: Reports,
  },
  {
    path: "/reports/report-invoice",
    exact: true,
    name: "invoice",
    element: Invoice,
  },
  {
    path: "/reports/report-invoice/print",
    exact: true,
    name: "invoice-print",
    element: StatementPrint,
  },
  {
    path: "/reports/job-report",
    exact: true,
    name: "Job",
    element: Job,
  },
  {
    path: "/reports/quote-report",
    exact: true,
    name: "Quotes",
    element: Quotes,
  },
  {
    path: "/reports/customer-report",
    exact: true,
    name: "Customer",
    element: Customer,
  },
  {
    path: "/reports/labour-report",
    exact: true,
    name: "Labour",
    element: Labour,
  },
  {
    path: "/reports/po-report",
    exact: true,
    name: "Materials",
    element: Materials,
  },
  {
    path: "/reports/clocking-report",
    exact: true,
    name: "Clocking",
    element: Clocking,
  },
  {
    path: "/reports/clocking-report/:searchDate",
    exact: true,
    name: "Clocking",
    element: Clocking,
  },
];

export * from "./components";
