import {

  RAISED_PURCHASE_ORDER,
  RAISED_PURCHASE_ORDER_SUCCESS,
  RAISED_PURCHASE_ORDER_FAIL,
  RAISED_PURCHASE_ORDER_COUNT,
  RAISED_PURCHASE_ORDER_COUNT_SUCCESS,
  RAISED_PURCHASE_ORDER_COUNT_FAIL,
  RAISED_SEARCH_PURCHASE_ORDER,
  RAISED_SEARCH_PURCHASE_ORDER_SUCCESS,
  RAISED_SEARCH_PURCHASE_ORDER_FAIL,

  COMPLETED_PURCHASE_ORDER,
  COMPLETED_PURCHASE_ORDER_SUCCESS,
  COMPLETED_PURCHASE_ORDER_FAIL,
  COMPLETED_PURCHASE_ORDER_COUNT,
  COMPLETED_PURCHASE_ORDER_COUNT_SUCCESS,
  COMPLETED_PURCHASE_ORDER_COUNT_FAIL,
  COMPLETED_SEARCH_PURCHASE_ORDER,
  COMPLETED_SEARCH_PURCHASE_ORDER_SUCCESS,
  COMPLETED_SEARCH_PURCHASE_ORDER_FAIL,

  RECEIPTS_PURCHASE_ORDER,
  RECEIPTS_PURCHASE_ORDER_SUCCESS,
  RECEIPTS_PURCHASE_ORDER_FAIL,
  RECEIPTS_PURCHASE_ORDER_COUNT,
  RECEIPTS_PURCHASE_ORDER_COUNT_SUCCESS,
  RECEIPTS_PURCHASE_ORDER_COUNT_FAIL,
  RECEIPTS_SEARCH_PURCHASE_ORDER,
  RECEIPTS_SEARCH_PURCHASE_ORDER_SUCCESS,
  RECEIPTS_SEARCH_PURCHASE_ORDER_FAIL,
  RECEIPTS_PURCHASE_ORDER_VIEW,
  RECEIPTS_PURCHASE_ORDER_VIEW_SUCCESS,
  RECEIPTS_PURCHASE_ORDER_VIEW_FAIL,
  RECEIPTS_PURCHASE_ORDER_CREATE,
  RECEIPTS_PURCHASE_ORDER_CREATE_SUCCESS,
  RECEIPTS_PURCHASE_ORDER_CREATE_FAIL,


  CREDIT_PURCHASE_ORDER,
  CREDIT_PURCHASE_ORDER_SUCCESS,
  CREDIT_PURCHASE_ORDER_FAIL,
  CREDIT_PURCHASE_ORDER_COUNT,
  CREDIT_PURCHASE_ORDER_COUNT_SUCCESS,
  CREDIT_PURCHASE_ORDER_COUNT_FAIL,
  CREDIT_SEARCH_PURCHASE_ORDER,
  CREDIT_SEARCH_PURCHASE_ORDER_SUCCESS,
  CREDIT_SEARCH_PURCHASE_ORDER_FAIL,
  CREDIT_PURCHASE_ORDER_VIEW,
  CREDIT_PURCHASE_ORDER_VIEW_SUCCESS,
  CREDIT_PURCHASE_ORDER_VIEW_FAIL,
  CREDIT_PURCHASE_ORDER_CREATE,
  CREDIT_PURCHASE_ORDER_CREATE_SUCCESS,
  CREDIT_PURCHASE_ORDER_CREATE_FAIL,
  CREATE_RECEIPT_CREDIT_NOTE,
  CREATE_RECEIPT_CREDIT_NOTE_SUCCESS,
  CREATE_RECEIPT_CREDIT_NOTE_FAIL,

  PURCHASE_ORDER_VIEW,
  PURCHASE_ORDER_VIEW_SUCCESS,
  PURCHASE_ORDER_VIEW_FAIL,

  PURCHASE_ORDER_DELETE_PART,
  PURCHASE_ORDER_DELETE_PART_SUCCESS,
  PURCHASE_ORDER_DELETE_PART_FAIL,

  FETCH_PO_JOB_WISE,
  FETCH_PO_JOB_WISE_SUCCESS,
  FETCH_PO_JOB_WISE_FAIL,

  PO_CREATE_JOB_WISE,
  PO_CREATE_JOB_WISE_SUCCESS,
  PO_CREATE_JOB_WISE_FAIL,

  EDIT_PURCHASE_ORDER,
  EDIT_PURCHASE_ORDER_SUCCESS,
  EDIT_PURCHASE_ORDER_FAIL,

  SEND_PURCHASE_ORDER_EMAIL,
  SEND_PURCHASE_ORDER_EMAIL_SUCCESS,
  SEND_PURCHASE_ORDER_EMAIL_FAIL,
  
} from "./actionTypes";

// Raised purchase START
export const raisedOrder = () => {
  return {
    type: RAISED_PURCHASE_ORDER,
  };
};

export const raisedOrderSuccess = (data) => {
  return {
    type: RAISED_PURCHASE_ORDER_SUCCESS,
    payload: data,
  };
};

export const raisedOrderFail = (error) => {
  return {
    type: RAISED_PURCHASE_ORDER_FAIL,
    payload: error,
  };
};

export const raisedOrderCount = () => {
  return {
    type: RAISED_PURCHASE_ORDER_COUNT,
  };
};

export const raisedOrderCountSuccess = (data) => {
  return {
    type: RAISED_PURCHASE_ORDER_COUNT_SUCCESS,
    payload: data,
  };
};

export const raisedOrderCountFail = (error) => {
  return {
    type: RAISED_PURCHASE_ORDER_COUNT_FAIL,
    payload: error,
  };
};


export const raisedSearchPurchaseOrder = () => {
  return {
    type: RAISED_SEARCH_PURCHASE_ORDER,
  };
};

export const raisedSearchPurchaseOrderSuccess = (data) => {
  return {
    type: RAISED_SEARCH_PURCHASE_ORDER_SUCCESS,
    payload: data,
  };
};

export const raisedSearchPurchaseOrderFail = (error) => {
  return {
    type: RAISED_SEARCH_PURCHASE_ORDER_FAIL,
    payload: error,
  };
};

// Raised purchase END

// Completed purchase START

export const completedOrder = () => {
  return {
    type: COMPLETED_PURCHASE_ORDER,
  };
};

export const completedOrderSuccess = (data) => {
  return {
    type: COMPLETED_PURCHASE_ORDER_SUCCESS,
    payload: data,
  };
};

export const completedOrderFail = (error) => {
  return {
    type: COMPLETED_PURCHASE_ORDER_FAIL,
    payload: error,
  };
};

export const completedOrderCount = () => {
  return {
    type: COMPLETED_PURCHASE_ORDER_COUNT,
  };
};

export const completedOrderCountSuccess = (data) => {
  return {
    type: COMPLETED_PURCHASE_ORDER_COUNT_SUCCESS,
    payload: data,
  };
};

export const completedOrderCountFail = (error) => {
  return {
    type: COMPLETED_PURCHASE_ORDER_COUNT_FAIL,
    payload: error,
  };
};


export const completedSearchPurchaseOrder = () => {
  return {
    type: COMPLETED_SEARCH_PURCHASE_ORDER,
  };
};

export const completedSearchPurchaseOrderSuccess = (data) => {
  return {
    type: COMPLETED_SEARCH_PURCHASE_ORDER_SUCCESS,
    payload: data,
  };
};

export const completedSearchPurchaseOrderFail = (error) => {
  return {
    type: COMPLETED_SEARCH_PURCHASE_ORDER_FAIL,
    payload: error,
  };
};

// Completed purchase END

// Receipt purchase START

export const receiptsOrder = () => {
  return {
    type: RECEIPTS_PURCHASE_ORDER,
  };
};

export const receiptsOrderSuccess = (data) => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_SUCCESS,
    payload: data,
  };
};

export const receiptsOrderFail = (error) => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_FAIL,
    payload: error,
  };
};



export const receiptsOrderCount = () => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_COUNT,
  };
};

export const receiptsOrderCountSuccess = (data) => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_COUNT_SUCCESS,
    payload: data,
  };
};

export const receiptsOrderCountFail = (error) => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_COUNT_FAIL,
    payload: error,
  };
};


export const receiptsSearchPurchaseOrder = () => {
  return {
    type: RECEIPTS_SEARCH_PURCHASE_ORDER,
  };
};

export const receiptsSearchPurchaseOrderSuccess = (data) => {
  return {
    type: RECEIPTS_SEARCH_PURCHASE_ORDER_SUCCESS,
    payload: data,
  };
};

export const receiptsSearchPurchaseOrderFail = (error) => {
  return {
    type: RECEIPTS_SEARCH_PURCHASE_ORDER_FAIL,
    payload: error,
  };
};



export const receiptsOrderView = () => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_VIEW,
  };
};

export const receiptsOrderViewSuccess = (data) => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_VIEW_SUCCESS,
    payload: data,
  };
};

export const receiptsOrderViewFail = (error) => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_VIEW_FAIL,
    payload: error,
  };
};

export const receiptsOrderCreate = () => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_CREATE,
  };
};

export const receiptsOrderCreateSuccess = (data) => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_CREATE_SUCCESS,
    payload: data,
  };
};

export const receiptsOrderCreateFail = (error) => {
  return {
    type: RECEIPTS_PURCHASE_ORDER_CREATE_FAIL,
    payload: error,
  };
};

// Receipt purchase END

// Credit purchase START

export const creditOrder = () => {
  return {
    type: CREDIT_PURCHASE_ORDER,
  };
};

export const creditOrderSuccess = (data) => {
  return {
    type: CREDIT_PURCHASE_ORDER_SUCCESS,
    payload: data,
  };
};

export const creditOrderFail = (error) => {
  return {
    type: CREDIT_PURCHASE_ORDER_FAIL,
    payload: error,
  };
};

export const creditOrderCount = () => {
  return {
    type: CREDIT_PURCHASE_ORDER_COUNT,
  };
};

export const creditOrderCountSuccess = (data) => {
  return {
    type: CREDIT_PURCHASE_ORDER_COUNT_SUCCESS,
    payload: data,
  };
};

export const creditOrderCountFail = (error) => {
  return {
    type: CREDIT_PURCHASE_ORDER_COUNT_FAIL,
    payload: error,
  };
};


export const creditSearchPurchaseOrder = () => {
  return {
    type: CREDIT_SEARCH_PURCHASE_ORDER,
  };
};

export const creditSearchPurchaseOrderSuccess = (data) => {
  return {
    type: CREDIT_SEARCH_PURCHASE_ORDER_SUCCESS,
    payload: data,
  };
};

export const creditSearchPurchaseOrderFail = (error) => {
  return {
    type: CREDIT_SEARCH_PURCHASE_ORDER_FAIL,
    payload: error,
  };
};


export const creditOrderView = () => {
  return {
    type: CREDIT_PURCHASE_ORDER_VIEW,
  };
};

export const creditOrderViewSuccess = (data) => {
  return {
    type: CREDIT_PURCHASE_ORDER_VIEW_SUCCESS,
    payload: data,
  };
};

export const creditOrderViewFail = (error) => {
  return {
    type: CREDIT_PURCHASE_ORDER_VIEW_FAIL,
    payload: error,
  };
};

export const creditOrderCreate = () => {
  return {
    type: CREDIT_PURCHASE_ORDER_CREATE,
  };
};

export const creditOrderCreateSuccess = (data) => {
  return {
    type: CREDIT_PURCHASE_ORDER_CREATE_SUCCESS,
    payload: data,
  };
};

export const creditOrderCreateFail = (error) => {
  return {
    type: CREDIT_PURCHASE_ORDER_CREATE_FAIL,
    payload: error,
  };
};


export const createReceiptCreditNote = () => {
  return {
    type: CREATE_RECEIPT_CREDIT_NOTE,
  };
};

export const createReceiptCreditNoteSuccess = (data) => {
  return {
    type: CREATE_RECEIPT_CREDIT_NOTE_SUCCESS,
    payload: data,
  };
};

export const createReceiptCreditNoteFail = (error) => {
  return {
    type: CREATE_RECEIPT_CREDIT_NOTE_FAIL,
    payload: error,
  };
};


// Credit purchase END


// View purchase order START

export const purchaseOrderView = () => {
  return {
    type: PURCHASE_ORDER_VIEW,
  };
};

export const purchaseOrderViewSuccess = (data) => {
  return {
    type: PURCHASE_ORDER_VIEW_SUCCESS,
    payload: data,
  };
};

export const purchaseOrderViewFail = (error) => {
  return {
    type: PURCHASE_ORDER_VIEW_FAIL,
    payload: error,
  };
};

// View purchase order END


// delete purchase order part START

export const deletePurchaseOrderPart = () => {
  return {
    type: PURCHASE_ORDER_DELETE_PART,
  };
};

export const deletePurchaseOrderPartSuccess = (data) => {
  return {
    type: PURCHASE_ORDER_DELETE_PART_SUCCESS,
    payload: data,
  };
};

export const deletePurchaseOrderPartFail = (error) => {
  return {
    type: PURCHASE_ORDER_DELETE_PART_FAIL,
    payload: error,
  };
};

// delete purchase order part START


// View purchase order START

export const fetchPOJobWise = () => {
  return {
    type: FETCH_PO_JOB_WISE,
  };
};

export const fetchPOJobWiseSuccess = (data) => {
  return {
    type: FETCH_PO_JOB_WISE_SUCCESS,
    payload: data,
  };
};

export const fetchPOJobWiseFail = (error) => {
  return {
    type: FETCH_PO_JOB_WISE_FAIL,
    payload: error,
  };
};

// View purchase order END

// PO create job wise order START

export const poCreateJobWise = () => {
  return {
    type: PO_CREATE_JOB_WISE,
  };
};

export const poCreateJobWiseSuccess = (data) => {
  return {
    type: PO_CREATE_JOB_WISE_SUCCESS,
    payload: data,
  };
};

export const poCreateJobWiseFail = (error) => {
  return {
    type: PO_CREATE_JOB_WISE_FAIL,
    payload: error,
  };
};

// PO create job wise order END


// Edit PO START

export const editPO = () => {
  return {
    type: EDIT_PURCHASE_ORDER,
  };
};

export const editPOSuccess = (data) => {
  return {
    type: EDIT_PURCHASE_ORDER_SUCCESS,
    payload: data,
  };
};

export const editPOFail = (error) => {
  return {
    type: EDIT_PURCHASE_ORDER_FAIL,
    payload: error,
  };
};

// Edit PO END

// Sent PO Email START

export const sendPOEmail = () => {
  return {
    type: SEND_PURCHASE_ORDER_EMAIL,
  };
};

export const sendPOEmailSuccess = (data) => {
  return {
    type: SEND_PURCHASE_ORDER_EMAIL_SUCCESS,
    payload: data,
  };
};

export const sendPOEmailFail = (error) => {
  return {
    type: SEND_PURCHASE_ORDER_EMAIL_FAIL,
    payload: error,
  };
};

// Sent PO Email END