import React from "react";

const TimeField = ({ slotDuration,onChange,fieldName }) => {
    const allLetter = (intValidNum) => {
      var letters = /^[A-Za-z]+$/;
      var iChars = /[^\w\:\-]/;

      if(intValidNum.match(letters) || intValidNum.match(iChars))
      {
        return false;
      }
      else
      {
        return true;
      }
    }

    const validateInputField = (e) => {
      var validate = /^([0-9]?[0-9]|2[0-9]):([0-5][0-9])(:[0-5][0-9])?$/;
      if(validate.test(e.target.value)){
        return true;
      }else{
        e.target.value = "";
        return false;
      }
    }

    const formatTime = (e) => {
        let timeInput = e.target;
        let intValidNum = timeInput.value;

        let checkLaters = allLetter(intValidNum);
        
        if(checkLaters == false){
            timeInput.value = "";
            return false;  
        }
      
        if (intValidNum < 99 && intValidNum.length == 2) {
            timeInput.value = timeInput.value + ":";
            return false;  
        }
      
        if (intValidNum.length == 5 && intValidNum.slice(-2) < 60) {
          timeInput.value = timeInput.value;
          return false;
        }
        if (intValidNum.length == 5 && intValidNum.slice(-2) > 60) {
          timeInput.value = timeInput.value.slice(0, 2) + ":";
          return false;
        }
      } //end function

      return (
        <>
            <input 
            name={fieldName} 
            id="job_estimate_time" 
            className="form-control" 
            type="text" 
            placeholder="HH:MM" 
            defaultValue={slotDuration}
            onChange={(e) => {
              formatTime(e);
              onChange(e)
            }}
            onBlur={(e) => validateInputField(e)}
            maxLength="5"/>
        </>
      );
      
};
export default TimeField;