/********************************************
 ░░░░ INSPECTION ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
 ********************************************/
export const GET_INSPECTIONS_DIGITAL_INSPECTION = "GET_INSPECTIONS_DIGITAL_INSPECTION";
export const GET_INSPECTIONS_DIGITAL_INSPECTION_SUCCESS = "GET_INSPECTIONS_DIGITAL_INSPECTION_SUCCESS";
export const GET_INSPECTIONS_DIGITAL_INSPECTION_FAIL = "GET_INSPECTIONS_DIGITAL_INSPECTION_FAIL";

/********************************************
 ░░░░ INSPECTION GROUPS ░░░░░░░░░░░░░░░░░░░░░
 ********************************************/
export const GET_INSPECTION_GROUP_DIGITAL_INSPECTION = "GET_INSPECTION_GROUP_DIGITAL_INSPECTION";
export const GET_INSPECTION_GROUP_DIGITAL_INSPECTION_FAIL = "GET_INSPECTION_GROUP_DIGITAL_INSPECTION_FAIL";
export const GET_INSPECTION_GROUP_DIGITAL_INSPECTION_SUCCESS = "GET_INSPECTION_GROUP_DIGITAL_INSPECTION_SUCCESS";


export const APPROVE_DIGITAT_INSPECTION = 'APPROVE_DIGITAT_INSPECTION';
export const APPROVE_DIGITAT_INSPECTION_SUCCESS = 'APPROVE_DIGITAT_INSPECTION_SUCCESS';
export const APPROVE_DIGITAT_INSPECTION_FAIL = 'APPROVE_DIGITAT_INSPECTION_FAIL';


export const VERIFY_CODE = "VERIFY_CODE"
export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS"
export const VERIFY_CODE_FAIL = "VERIFY_CODE_FAIL"

export const GET_INSPECTIONS_DIGITAL_INSPECTION_VIEW = "GET_INSPECTIONS_DIGITAL_INSPECTION_VIEW";


export const REJECT_INSPECTION = "REJECT_INSPECTION"
export const REJECT_INSPECTION_SUCCESS = "REJECT_INSPECTION_SUCCESS"
export const REJECT_INSPECTION_FAIL = "REJECT_INSPECTION_FAIL"