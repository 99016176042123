import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function AutoLogoutPopup({ positiveAction }) {
  const handleClose = () => {
    positiveAction?.();
  };

  return (
    <Modal
      show={true}
      className="border-0"
      style={{ marginTop: 120 }}
      onHide={handleClose}
    >
      <Modal.Body className="p-5">
        <div className="d-flex justify-content-center flex-column text-center">
          <p className="h4 mt-2 fw-bold">You've been logged out.</p>
          <div className="mt-3 text-center">
            <Button
              variant="primary"
              // className="fw-bold"
              style={{ minWidth: 100 }}
              onClick={handleClose}
            >
              Return to Login
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
