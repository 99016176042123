import { Button } from "react-bootstrap";
import { downloadFileFromS3 } from "utils/uploadFileToS3";

let TechnicianDetail = ({ techician, activeTechnician, setActiveTechnician, currentDay, setActiveUserName, listType }) => {

    let avatarPath = window.location.origin + "/Avatar/";
    var techProfile = avatarPath + "1.png";

    if (techician?.user_profile !== "" && techician?.user_profile !== undefined && techician?.user_profile !== "bay" && techician?.id !== "z") {
        let user_profile_name = techician?.user_profile.split('/');
        if (user_profile_name[0] === 'user_profile') {
            techProfile = downloadFileFromS3(techician?.user_profile);
        }
        else {
            techProfile = avatarPath + techician?.user_profile;
        }
    }

    return (
        <>

            <div className="col-md-6" key={listType + "_technician_" + techician?.id} >
                <div className="resource-outer-clocking">
                    <div className={"resource-box " + (activeTechnician === techician?.id ? "active" : "") + (!(techician['workingDays'].map(String)).includes(currentDay.toString()) || techician['isAway'] === 1 ? " disable-technician" : "")}
                        onClick={(e) => {
                            setActiveTechnician(techician?.id);
                            setActiveUserName(techician?.firstname + " " + techician?.lastname);
                        }}
                    >
                        <div className="resource-profile">
                            <img className="technician-img" src={techProfile} alt={"Technician"} />
                        </div>
                        {/* <div className="resource-profile">
                        <img className="technician-img" src={techProfile} alt={"Technician"} />
                    </div> */}

                        <div className="right-block">
                            <p>{techician?.firstname} {techician?.lastname}</p>
                            {(techician?.lastActivity === "" && techician?.isAway === 1) &&
                                <div style={{ textAlign: "start" }} >
                                    <Button style={{ background: "black" }}
                                        type="button"
                                        variant="outline-secondary"
                                        className="btn-outline rounded-btn statusDetail2"
                                    >
                                        Leave/Sick
                                    </Button>
                                </div>
                            }

                            {((techician?.lastActivity === "" || techician?.lastActivity === "Finish Time") && techician?.isAway === 0) &&
                                <div style={{ textAlign: "start" }} >
                                    <Button style={{ background: "red" }}
                                        type="button"
                                        variant="outline-secondary"
                                        className="btn-outline rounded-btn statusDetail2"
                                    >
                                        Clocked Off
                                    </Button>
                                </div>
                            }

                            {(techician?.lastActivity === "Break" || techician?.lastActivity === "Lunch") &&
                                <div style={{ textAlign: "start" }}>
                                    <Button style={{ background: "#0096FF" }}
                                        type="button"
                                        variant="outline-secondary"
                                        className="btn-outline rounded-btn statusDetail2"
                                    >
                                        Break /Lunch
                                    </Button>
                                </div>
                            }

                            {(techician?.lastActivity === "Start Time" || techician?.lastActivity === "Clocked In") &&
                                <div style={{ textAlign: "start" }}>
                                    <Button style={{ background: "green" }}
                                        type="button"
                                        variant="outline-secondary"
                                        className="btn-outline rounded-btn statusDetail2"
                                    >
                                        Clocked On
                                    </Button>
                                </div>
                            }

                        </div>
                        {/* <div className="dot-status">
                            <>
                                {(techician?.lastActivity === "" && techician?.isAway === 1) &&
                                    <i style={{ color: "black", fontSize: "20px" }} className="fa fa-circle"></i>
                                }

                                {((techician?.lastActivity === "" || techician?.lastActivity === "Finish Time") && techician?.isAway === 0) &&
                                    <i style={{ color: "red", fontSize: "20px" }} className="fa fa-circle"></i>
                                }

                                {(techician?.lastActivity === "Break" || techician?.lastActivity === "Lunch") &&
                                    <i style={{ color: "#0096FF", fontSize: "20px" }} className="fa fa-circle"></i>
                                }

                                {(techician?.lastActivity === "Start Time" || techician?.lastActivity === "Clocked In") &&
                                    <i style={{ color: "green", fontSize: "20px" }} className="fa fa-circle"></i>
                                }
                            </>

                        </div> */}
                    </div>
                </div>
            </div>


        </>
    );
};

export default TechnicianDetail;
