export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"; 

export const GET_CUSTOMER_NOTIFICATION = "GET_CUSTOMER_NOTIFICATION";
export const GET_CUSTOMER_NOTIFICATION_SUCCESS = "GET_CUSTOMER_NOTIFICATION_SUCCESS";
export const GET_CUSTOMER_NOTIFICATION_FAIL = "GET_CUSTOMER_NOTIFICATION_FAIL"; 

export const GET_MARKETING_NOTIFICATION = "GET_MARKETING_NOTIFICATION";
export const GET_MARKETING_NOTIFICATION_SUCCESS = "GET_MARKETING_NOTIFICATION_SUCCESS";
export const GET_MARKETING_NOTIFICATION_FAIL = "GET_MARKETING_NOTIFICATION_FAIL";