import { getJobLabourList } from "helpers/backendHelper";
import { useEffect, useState } from "react";

/** 
  * This function fetches labours of a job
 * @param {string|number} jobSchedulesId Scheduled job id
 * @param {string|number} labourChargeType Scheduled job labour charge type. Accepted values are `1`, `2`, or `3`
 * @param {*} options 
 * @returns 
 */
export default function useFetchLabour(jobSchedulesId, labourChargeType, {
    skip = false
}) {
    const [isLoading, setIsLoading] = useState(false)
    const [errorState, setErrorState] = useState({
        isError: false,
        error: undefined
    })
    const [data, setData] = useState([])

    useEffect(() => {
        // Invoke an instantly invoked function to make async call.
        (async () => {
            // Fetch only if skip is false. This is for scenarios where it might be needed to not fetch data on page load. In that case, skip can be true so data will not be fetched on page load. Data will be fetched only when skip becomes false.
            if (!skip) {
                try {
                    // Set is loading to true
                    setIsLoading(true)

                    // Prepare a payload to fetch data
                    const fetchPayload = {
                        payload: {
                            'get': 'all',
                            'conditions': {
                                'JobLabours.job_schedules_id ': `${jobSchedulesId}`
                            },
                            order: {
                                'JobLabours.priority': 'asc'
                            },
                            'contain': {
                                'LabourCharges': {
                                    'conditions': {
                                        'LabourCharges.is_deleted': 0
                                    }
                                },
                            },

                        }
                    }

                    // If labourChargeType is provided then append it to the payload
                    if (labourChargeType) {
                        fetchPayload.payload.conditions['JobLabours.labour_charge_type '] = labourChargeType
                    }

                    // Make API call to fetch data
                    const labourList = await getJobLabourList(fetchPayload)

                    // Get labour from response
                    const labours = labourList.data?.JobLabours

                    // Check if received data is an Array or not.
                    if (Array.isArray(labours)) {
                        // Set data if received data is an Array
                        setData(labours)
                    }
                } catch (error) {
                    // Log the error
                    console.error(error)

                    // Set the error to provided appropriate error message
                    setErrorState({
                        error,
                        isError: true
                    })
                } finally {
                    // After everything is done, set isLoading back to false.
                    setIsLoading(false)
                }
            }
        })()
    }, [jobSchedulesId, labourChargeType, skip])

    // Return states
    return {
        isLoading, ...errorState, data
    }

}