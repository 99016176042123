import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import { GET_KIT_PART_LIST, GET_KIT_PART_LIST_COUNT, SEARCH_KIT_PART_LIST } from "store/parts/actionTypes";
import Loader from "views/common/UI/Loader";
import { addComma, precise_round } from "utils/numberFunction";
import moment from "moment";
import { getKitSearchPartListSuccess } from "store/parts/actions";
import Select from "react-select";
import { showError, showSuccess } from "utils/alertMessage";
import { deletePart, editManyPart, editPart } from "helpers/backendHelper";
import DeleteConfirm from "utils/deleteConfirm";

const KitList = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const dateFormat = storedData.date_format;
  const currencySymbol = storedData.currency;

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [partList, setPartList] = useState([]);
  const [search, setSearch] = useState("");
  const [emptyMessage,setEmptyMessage] = useState("No data available in table");

  const [type, setType] = useState('all');
  const [categoryList, setCategoryList] = useState([]);
  const [categoryID, setCategoryID] = useState();

  const [selectedRow, setSelectedRow] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState("delete this record?");
  // delete popup variable START
  const [deletePartId, setDeletePartId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // const handleDeleteClose = () => setShowDeleteModal(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  // delete popup variable END

  function handleDeleteClose(){
    setShowDeleteModal(false);
    setSelectedRow([]);
  }

  function deletePartRecords(){

    if (selectedRow.length !== 0) {
      setShowDeleteModal(!showDeleteModal)
      setDeleteMessage("Delete this records?")
    } else {
      showError("Please select the Kit.");
    }
  }

  useEffect( () => {
    loadPartLists();
  },[]);

  const { kitPartListData, kitPartSearchList, kitPartListCount, isKitPartListLoading } = useSelector((state) => state.PartReducer);
  

  const loadPartLists = (page = 1, pageLimit = 10) => {

    var conObj = {
      'Products.workshop_id': workshop_id,
      'Products.status IN': [1],
      'Products.is_deleted': '0',
      'Products.product_type': 2,
    }

    if(type === "favourite"){
      conObj.is_favourite = 1;
    }

    dispatch({
      type: GET_KIT_PART_LIST,
      payload: {
        get : 'all',
        conditions: conObj,
        contain: {
            'Workshops.Countries':{
                "conditions": {
                  'Countries.status': '1'
                }
            }
        },
        'order': {
          'Products.id': 'desc'
        },
        limit: pageLimit,
        page: page,
      },
    });
   
    dispatch({
      type: GET_KIT_PART_LIST_COUNT,
      payload: {
        get : 'all',
        conditions: conObj,
        fields: {
          COUNT: "COUNT(Products.id)",
        }
      },
    });

  };


  useEffect(() => {
    
    if(search !== "") {
       if(kitPartSearchList[0] !== null && typeof kitPartSearchList[0] !== "undefined") {
        const onList = kitPartSearchList[0].slice((currentPage - 1) * sizePerPage, currentPage * sizePerPage);
        setPartList(onList);
        setTotalRecords(kitPartSearchList['final_count']);
       } 
    } else {

      if(kitPartListData.length === 0){
        setPartList([]);
        setTotalRecords(0);
      }

      if(kitPartListData !== null && typeof kitPartListData !== "undefined" && kitPartListData.length > 0) {
        setPartList(kitPartListData);
        setTotalRecords(kitPartListData.length);
      } 
    }
 
    setSelectedRow([]);

  },[kitPartListData, kitPartSearchList, currentPage, sizePerPage, totalRecords]);


  useEffect(() => {

    
    if (kitPartListCount !== 0) {
      setTotalRecords(kitPartListCount);
    }

  },[kitPartListCount]);

  
  const columns = [
    {
      dataField: "product_number",
      text: "Kit No.",
      formatter: (cell,row) => {
        return <span>{cell}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-kit/${row.id}`);
        },
      },
    },
    {
      dataField: "name",
      text: "Kit Name",
      formatter: (cell,row) => {
        return <span>{cell}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-kit/${row.id}`);
        },
      },
    },
    {
      dataField: "cost_price",
      text: "Cost",
      formatter: (cell,row) => {
        return <span>{currencySymbol}{addComma(precise_round(cell))}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-kit/${row.id}`);
        },
      },
    },
    {
      dataField: "cost_markup_price",
      text: "Margin",
      formatter: (cell,row) => {
        return <span>{addComma(precise_round(cell))}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-kit/${row.id}`);
        },
      },
    },
    {
      dataField: "retail_price",
      text: "Retail",
      formatter: (cell,row) => {
        return <span>{currencySymbol}{addComma(precise_round(cell))}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-kit/${row.id}`);
        },
      },
    },
    {
      dataField: "is_favourite",
      text: "Favourites",
      formatter: (cell,row,rowIndex) => {
       return <i className={"fa fa-star " + ( cell === '1' ? "checked" : "" )} onClick={ (e) => partFavorite(row.id,cell,rowIndex) } aria-hidden="true"></i>;
      }
    },
    {
      dataField: "more option",
      text: "",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <Link to={"/edit-kit/"+row.id} className="" title="EDIT">
                  <i className="fa fa-pencil text-info"></i>
                  <span>Edit</span>
                </Link>
              </li>
              <li onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                  setSelectedRow(row.id);
                  setDeleteMessage("Delete this record?")
                }} >
                <a href className="delete-link" title="DELETE">
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </a>
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  const selectRow = {
    mode: "checkbox",
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect){
          if(rows.length !== 0){
              let rowPoRecSelectedAll = [...selectedRow];
              rows.map((row) => {
                  rowPoRecSelectedAll.push(row.id);
              })
              setSelectedRow(rowPoRecSelectedAll);
          }
      }else{
        setSelectedRow([]);
      }
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row.id]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.indexOf(row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          checked={rest.checked}
          name={rest.name}
          id={rest.id}
          type="checkbox"
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          id={rest.id}
          checked={rest.checked}
          type={mode}
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
  };

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);
    return responseDate;
  };

  const emptyDataMessage = () => {
    return (
      <div className= {(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  const onTableChange = (type, { page, sizePerPage }) => {

    setCurrentPage(page);
    setSizePerPage(sizePerPage);

    if (search === "") {
      setIsLoading(true);
      loadPartLists(page, sizePerPage);
    } else {
      
      
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setEmptyMessage("No Results Found")

    if (e.target.value !== "") {

      // setIsLoading(true);
      setCurrentPage(1);

      dispatch({
        type: SEARCH_KIT_PART_LIST,
        payload: {
          workshop_id: workshop_id,
          type:type,
          part_type: 2,
          keyword: e.target.value,
        },
      });
    } else {

      dispatch(getKitSearchPartListSuccess({}));
      // setJobData(getJobData.jobData);
      loadPartLists();
      setTotalRecords(kitPartListCount);
    }
  };

  useEffect(() => {
    setSearch("");
    loadPartLists();
  },[type]);


  async function partFavorite(part_id,is_favourite,rowIndex){
   
    if(type === 'favourite') {
      setIsLoading(true);
    }

    const newData = [...partList];

    let new_is_favourite = '1';
    if(is_favourite === '1'){
      new_is_favourite = '0';
    }

    newData[rowIndex]["is_favourite"] = new_is_favourite;

    setPartList(newData);

    let data = {
      payload: {
        is_favourite:new_is_favourite,
        id: part_id,
      },
    };

    let response = await editPart(data);
    if (response.code === 200) {
      setIsLoading(false);
      if(new_is_favourite === '0'){
        showSuccess("Kit is removed from favorite.");
      } else {
        showSuccess("Kit is marked as a favorite.");
      }
      if(type === 'favourite') {
        loadPartLists();
      }
    } else {
      setIsLoading(false);
      showError(response.message);
    }

  }

  const deleteRecord = async () => {
        
    if (selectedRow.length !== 0) {
      let data = {
        payload: {
          action_type: "delete",
          ids: selectedRow,
        },
      };

      let response = await editManyPart(data);
      if (response.code === 200) {
        showSuccess("Kit Deleted Sucessfully");
        loadPartLists();
      } else {
        showError(response.message);
      }
      setShowDeleteModal(false);
    } else {
      showError("Please select the Parts");
    }
    
  }

  const archiveRecord = async () => {
    if (selectedRow.length !== 0) {
      let data = {
        payload: {
          action_type: "archive",
          ids: selectedRow,
        },
      };
      let response = await editManyPart(data);
      if (response.code === 200) {
        showSuccess("Job Archive Sucessfully");
        loadPartLists();
      } else {
        showError(response.message);
      }
    } else {
      showError("Please select the Parts");
    }
  };

  if(isKitPartListLoading){
    return  <Loader />
  }

  if(isLoading){
    return  <Loader />
  }

  const expandRow = {
    renderer: (row) => (
      <div>
        <p>{`This Expand row is belong to rowKey ${row.id}`}</p>
        <p>
          You can render anything here, also you can add additional data on
          every row object
        </p>
        <p>
          expandRow.renderer callback will pass the origin row object to you
        </p>
      </div>
    ),
    showExpandColumn: true,
  };

  return (
    <>
      <div className="d-flex align-center justify-space-between mb-2">
        <div className="radio-wrapper align-center">
          <Form.Check
            className="custom-check radio-btn secondary-radio mb-2"
            type="radio"
            id="kit1"
          >
            <Form.Check.Input name="kitRadio" type="radio" checked={type === 'all'} onChange={ (e) => setType("all") } isValid />
            <Form.Check.Label className="d-flex align-center">
              <span className="m-r-10">All</span>
              <span className="check"></span>
            </Form.Check.Label>
          </Form.Check>
          <Form.Check
            className="custom-check radio-btn secondary-radio mb-2"
            type="radio"
            id="kit2"
          >
            <Form.Check.Input name="kitRadio" type="radio" isValid  checked={type === 'favourite'} onChange={ (e) => setType("favourite") } />
            <Form.Check.Label>
              Favourite
              <span className="check"></span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        <div className="table-search-wrapper ">
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Search..."
            onChange={(e) => handleSearch(e)}
            value={search}
          />
          <Button
            className="btn-primary rounded-btn"
            onClick={() => navigate("/create-kit")}
          >
            <i className="fa fa-plus"></i> Create Kit
          </Button>
        </div>
      </div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page: currentPage,
          sizePerPage: sizePerPage,
          totalSize: parseInt(totalRecords),
          nextPageText: "Next",
          prePageText: "Previous",
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div className="table-wrapper">
            <BootstrapTable
              remote
              keyField="id"
              data={partList}
              columns={columns}
              selectRow={selectRow}
              onTableChange={onTableChange}
              noDataIndication={emptyDataMessage}
              expandRow={expandRow}
              {...paginationTableProps}
            />
            <div>
              <div className="table-pagination">
                <div>
                  <span>Show</span>
                  <SizePerPageDropdownStandalone {...paginationProps} />
                  <span>entries</span>
                </div>
                <PaginationTotalStandalone {...paginationProps} />
                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
          </div>
        )}
      </PaginationProvider>

      <div className="d-flex custom-table-footer align-center">
        <Button
          variant="warning"
          className="rounded-btn d-flex align-center mr-10"
          onClick={() => archiveRecord()}
        >
          <i className="la la-archive font-size-18"></i> Archive
        </Button>
        <Button
          onClick={() => {
            deletePartRecords();
          }}
          variant="danger"
          className="rounded-btn d-flex align-center mr-10"
        >
          <i className="la la-times-circle font-size-18"></i> Delete
        </Button>
        
      </div>

      {showDeleteModal && (
          <DeleteConfirm
              handleDeleteClose={handleDeleteClose}
              deleteRecord={deleteRecord}
              msg={deleteMessage}
          />
      )}
    </>
  );
};

export default KitList;
