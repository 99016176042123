import {
  GET_INSPECTION_LIST,
  GET_INSPECTION_LIST_FAIL,
  GET_INSPECTION_LIST_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: true,
  message: "",
  error: "",
  inspectionListData: [],
};

const InspectionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSPECTION_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_INSPECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        inspectionListData: action.payload,
      };

    case GET_INSPECTION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        inspectionListData: [],
        error: {
          message: action.payload,
        },
      };

    default:
      return { ...state };
  }
};

export default InspectionDetailsReducer;
