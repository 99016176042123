import { getFirebaseToken } from "firbaseInit";
import { call, put, takeLatest } from "redux-saga/effects";
import { onLoadSecurityGroup } from "store/securityGroup/saga";
import { setSessionData } from "utils/setSessiondata";
import {
  addNotificationToken,
  getLogin,
  getUserData,
} from "../../helpers/backendHelper";
import { showError } from "../../utils/alertMessage";
import { getLoginFail, getLoginSuccess } from "./actions";
import {
  GET_LOGIN,
  GET_LOGIN_WITH_TOKEN,
  SET_FIREBASE_TOKEN_SUCCESS,
} from "./actionTypes";

function* onGetLogin(data) {
  try {
    const response = yield call(getLogin, data);
    let firebase = null;
    if (response) {
      if (response.code === 200) {
        const token = yield call(getFirebaseToken);
        //if firebase token received
        if(token) {
          const payload = {
            workshop_id: response.data.Users?.workshop_id,
            firebase_token: token,
          };
          const addToken = yield call(addNotificationToken, { payload });
          firebase = {
            id: addToken?.data?.PushNotification?.id,
            token: token,
          };
        }

        yield call(onLoadSecurityGroup, {
          payload: {
            workshop_id: response.data.Users?.workshop_id,
          },
        });
        const userData = response.data.Users;
        
        const state = {
          loadingLogins: false,
          token: userData.email + ":" + userData.api_plainkey,
          userData: userData,
          role_id: userData.role_id,
        };
        setSessionData(state);
        // const firebase = {
        //   id: addToken?.data?.PushNotification?.id,
        //   token: token,
        // };
        yield put(getLoginSuccess(response));
        yield put({
          type: SET_FIREBASE_TOKEN_SUCCESS,
          payload: firebase,
        });
        localStorage.setItem("data", JSON.stringify(response.data.Users));
        localStorage.setItem("firebase", JSON.stringify(firebase));
      } else {
        yield put(getLoginFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getLoginFail("Didn't get api response"));
    }
  } catch (error) {
    console.log('error',error)
    yield put(getLoginFail(error.response));
  }
}

function* onGetLoginWithToken({ id }) {
  try {
    const response = yield call(getUserData, id);

    if (response) {
      if (response.code === 200) {
        yield call(onLoadSecurityGroup, {
          payload: {
            workshop_id: response.data.Users?.workshop_id,
          },
        });
        const userData = response.data.Users;
        const state = {
          loadingLogins: false,
          token: userData.email + ":" + userData.api_plainkey,
          userData: userData,
          role_id: userData.role_id,
        };
        setSessionData(state);

        yield put(getLoginSuccess(response));
        localStorage.setItem("data", JSON.stringify(response.data.Users));
      } else {
        yield put(getLoginFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getLoginFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getLoginFail(error.response));
  }
}

function* LoginSaga() {
  yield takeLatest(GET_LOGIN, onGetLogin);
  yield takeLatest(GET_LOGIN_WITH_TOKEN, onGetLoginWithToken);
}

export default LoginSaga;
