import materials from "../../../assets/images/materials.svg";
import materialsBlue from "../../../assets/images/materials-blue.svg";
import materialsWhite from "../../../assets/images/materials-white.svg";

export const hqTopLinks = [
  {
    label: "Dashboard",
    mod: "dashboard",
    main_link: "/dashboard",
    icon_class: "fa fa-tachometer",
    sub_items: [],
  },
  // {
  //   label: "Booking Information",
  //   mod: "booking-information",
  //   main_link: "/booking-information",
  //   icon_class: "fa fa-book",
  //   sub_items: [],
  // },
  {
    label: "Stores",
    mod: "stores",
    main_link: "/stores",
    icon_class: "fa fa-building",
    sub_items: [],
  },
  {
    label: "Customers",
    mod: "customers",
    main_link: "/customers",
    icon_class: "fa fa-users",
    sub_items: [],
  },
  {
    label: "Vehicles",
    mod: "workshop",
    main_link: "/asset_list",
    icon_class: "fa fa-list",
    sub_items: [],
  },
  {
    label: "Employees",
    mod: "employees",
    main_link: "/employees",
    icon_class: "fa fa-user-circle",
    sub_items: [],
  },
  {
    label: "Suppliers",
    mod: "suppliers",
    main_link: "/suppliers",
    icon_class: "fa fa-industry",
    sub_items: [],
  },
];

export const hqBottomLinks = [
  {
    label: "Invoices",
    mod: "invoices",
    main_link: "/invoice",
    icon_class: "fa fa-files-o",
    sub_items: [
      // {
      //   label: "Cash Sale",
      //   mod: "global",
      //   link: "/cash_sale",
      // },
      {
        label: "Raised Invoices",
        mod: "global",
        link: "/invoice#raised-invoices",
      },
      {
        label: "Completed Invoices",
        mod: "global",
        link: "/invoice#completed-invoices",
      },
      {
        label: "Invoice Payments",
        mod: "global",
        link: "/invoice#invoice-payments",
      },
      {
        label: "Invoice Credit",
        mod: "global",
        link: "/invoice#invoice-credit",
      },
    ],
  },
  {
    label: "Materials",
    mods: ["inventory", "purchases", "stockmanagements&stocktakes"],
    main_link: "/catalogue",
    normal_img: materials,
    hover_img: materialsBlue,
    dark_theme_img: materialsWhite,
    sub_items: [
      {
        label: "Parts & Kits",
        mod: "inventory",
        link: "/catalogue",
      },
      {
        label: "Purchase Orders",
        mod: "purchases",
        link: "/purchase-list",
      },
      {
        label: "Stock",
        mod: "stockmanagements&stocktakes",
        link: "/stock",
      },
      {
        label: "Stock Take",
        mod: "stockmanagements&stocktakes",
        link: "/stock-take/list",
      },
    ],
  },

  {
    label: "Marketing",
    mod: "marketing",
    main_link: "/marketing/marketing-dashboard/",
    icon_class: "fa fa-bullhorn",
    sub_items: []
    // sub_items: [
    //   {
    //     label: "Broadcast",
    //     mod: "global",
    //     link: "/marketing/broadcast",
    //   },
    //   {
    //     label: "Campaign",
    //     mod: "global",
    //     link: "/marketing/campaign",
    //   },
    //   {
    //     label: "Referral",
    //     mod: "global",
    //     link: "/marketing/referral",
    //   },
    //   {
    //     label: "Feedback",
    //     mod: "global",
    //     link: "/marketing/marketing-dashboard/",
    //   },
    // ],
  },

  {
    label: "Account Sync",
    mod: "workshop",
    main_link: "/sync",
    icon_class: "fa fa-list",
    sub_items: [],
  },

  {
    label: "Reports",
    mod: "reports",
    main_link: "/reports",
    icon_class: "fa fa-bar-chart",
    sub_items: [
      {
        label: "Invoices",
        mod: "global",
        link: "/reports/report-invoice",
      },
      {
        label: "Jobs",
        mod: "global",
        link: "/reports/job-report",
      },
      {
        label: "Quotes",
        mod: "global",
        link: "/reports/quote-report",
      },
      {
        label: "Customer",
        mod: "global",
        link: "/reports/customer-report",
      },
      {
        label: "Labour",
        mod: "global",
        link: "/reports/labour-report",
      },
      {
        label: "Materials",
        mod: "global",
        link: "/reports/po-report",
      },
      {
        label: "Marketing",
        mod: "global",
        link: "/reports",
      },
      {
        label: "Notifications",
        mod: "global",
        link: "/reports",
      },
      {
        label: "Schedule Reports",
        mod: "global",
        link: "/reports",
      },
      {
        label: "Financials",
        mod: "global",
        link: "/reports",
      },
    ],
  },

  {
    label: "Settings",
    mod: "settings",
    main_link: "/settings",
    icon_class: "fa fa-gears",
    bottom_align: true,
    sub_items: [
      {
        label: "Company Detail",
        mod: "global",
        link: "/settings_company_detail",
      },
      {
        label: "Defaults",
        mod: "global",
        link: "/settings_defaults",
      },
      {
        label: "Inspection Templates",
        mod: "subs",
        link: "/inspection/template-lists",
      },
      {
        label: "Security Group",
        mod: "global",
        link: "/settings_security_group",
      },
      {
        label: "Chart Of Accounts",
        mod: "global",
        link: "/chart-of-accounts",
      },
      {
        label: "Data Import",
        mod: "global",
        link: "/utilities_import",
      },
      {
        label: "Statuses",
        mod: "global",
        link: "/statuses",
      },
      {
        label: "Workshop Bays",
        mod: "global",
        link: "/workshop_bays",
      },
      {
        label: "Job Responses",
        mod: "global",
        link: "/script",
      },
      {
        label: "Message Templates",
        mod: "global",
        link: "/notification/create",
      },
      {
        label: "Storage Locations",
        mod: "global",
        link: "/storage_list",
      },
      {
        label: "Supplier Integration",
        mod: "global",
        link: "/supplier_integration",
      },
      {
        label: "Payment Integration",
        mod: "global",
        link: "/stripe",
      },
      // {
      //   label: "3rd Party Company",
      //   mod: "global",
      //   link: "/insurance_company_list",
      // },
      {
        label: "Subscription",
        mod: "global",
        link: "/edit-subscription",
      },
      {
        label: "Subscription Transaction",
        mod: "global",
        link: "/user-transaction",
      },
    ],
  },
];
