import { getMarketingsList, getLiveMarketingsList, getCompletedMarketingsList, createMarketing, viewMarketing, editMarketing, viewHQMarketing } from "helpers/backendHelper";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getScheduledMarketingList,
  getScheduledMarketingFail,
  getScheduledMarketingListSuccess,
  getLivetMarketingListSuccess,
  getLivetMarketingListfail,
  getCompletedMarketingListSuccess,
  getCompletedMarketingListFail, createMarketingFail, createMarketingSuccess, viewMarketingSuccess, viewMarketingFail, editMarketingSuccess, editMarketingFail
} from "./actions";

import {
  GET_SCHEDULED_MARKETING_LIST, GET_LIVE_MARKETING_LIST, GET_COMPLETED_MARKETING_LIST, CREATE_MARKETING,
  DELETE_SCHEDULED_MARKETING_LIST,
  DELETE_LIVE_MARKETING_LIST,
  DELETE_COMPLETED_MARKETING_LIST,
  VIEW_MARKETING,
  EDIT_MARKETING
} from "./actionTypes";
import { formatInvoiceList } from "store/invoices/formatter";

// import formatter
import { getScheduledMarketingListPayload } from "./api";

// import { formatInvoiceList, formatSaleInvoiceList } from "store/invoiceDetail/formatter";

// SCHEDULED MARKETING LIST

function* onGetScheduledMarketingList(action) {
  const { marketingListRequest } = action.payload;
  const payload = {};



  try {
    const listResponse = yield call(getMarketingsList, {
      payload: marketingListRequest,
    });
    if (listResponse?.code === 200) {
      payload.list = listResponse?.data?.WorkshopMarketing;
      payload.count = listResponse?.data?.WorkshopMarketing.length;
      yield put(getScheduledMarketingListSuccess(payload));
    } else {
      yield put(getScheduledMarketingFail("Failed to fetch marketing list "));
    }
  } catch (err) {
    yield put(getScheduledMarketingFail("Failed to fetch marketing list "));
  }
}
// SCHEDULED MARKETING LIST

//CREATE MARKETING LIST

function* onCreateMarketing(data) {
  try {
    const response = yield call(createMarketing, data);
    if (response) {
      if (response.code === 200) {
        yield put(createMarketingSuccess(response.data));
      } else {
        yield put(createMarketingFail(response.message));
      }
    } else {
      yield put(createMarketingFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(createMarketingFail(error.response));
  }
}

//CREATE MARKETING LIST

//DELETE SCHEDULED MARKETING LIST
{
  // function* onDeleteArchiveScheduledMarketingList(action) {
  //   try {
  //     const {saleMarketing , marketing } = action.payload;
  //     let saleMarketingDelete;
  //     let marketingDelete;

  //     if (marketing.ids.length !== 0) {
  //       marketingDelete = yield call(deleteArchiveInvoices, { payload: invoice });
  //     }
  //     if (saleInvoice && saleInvoice.ids.length !== 0) {
  //       saleInvoiceDelete = yield call(deleteArchiveInvoicesSales, {
  //         payload: saleInvoice,
  //       });
  //     }

  //     if (invoiceDelete?.code === 200 || saleInvoiceDelete?.code === 200) {
  //       yield put(deleteArchiveRaisedInvoiceSuccess(invoice.action_type));
  //       const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
  //       let workshop_id = window.atob(storedData.workshop_id);
  //       yield put(getRaisedInvoicePayload(workshop_id));
  //     } else {
  //       yield put(deleteArchiveRaisedInvoiceFail());
  //     }
  //   } catch (error) {
  //     yield put(deleteArchiveRaisedInvoiceFail());
  //   }
  // }
}
//DELETE SCHEDULED MARKETING LIST

// LIVE MARKETING LIST

function* onGetLiveMarketingList(action) {
  const { liveMarketingListRequest } = action.payload;
  const payload = {};
  try {
    const listResponse = yield call(getLiveMarketingsList, {
      payload: liveMarketingListRequest,
    });
    if (listResponse?.code === 200) {
      payload.list = listResponse?.data?.WorkshopMarketing;
      payload.count = listResponse?.data?.WorkshopMarketing.length;
      yield put(getLivetMarketingListSuccess(payload));
    } else {
      yield put(getLivetMarketingListfail("Failed to fetch marketing list "));
    }
  } catch (err) {
    yield put(getLivetMarketingListfail("Failed to fetch marketing list "));
  }
}

// LIVE MARKETING LIST




//DELETE LIVE MARKETING LIST
{

  // function* onDeleteArchiveScheduledMarketingList(action) {
  //   try {
  //     const {saleMarketing , marketing } = action.payload;
  //     let saleMarketingDelete;
  //     let marketingDelete;

  //     if (marketing.ids.length !== 0) {
  //       marketingDelete = yield call(deleteArchiveInvoices, { payload: invoice });
  //     }
  //     if (saleInvoice && saleInvoice.ids.length !== 0) {
  //       saleInvoiceDelete = yield call(deleteArchiveInvoicesSales, {
  //         payload: saleInvoice,
  //       });
  //     }

  //     if (invoiceDelete?.code === 200 || saleInvoiceDelete?.code === 200) {
  //       yield put(deleteArchiveRaisedInvoiceSuccess(invoice.action_type));
  //       const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
  //       let workshop_id = window.atob(storedData.workshop_id);
  //       yield put(getRaisedInvoicePayload(workshop_id));
  //     } else {
  //       yield put(deleteArchiveRaisedInvoiceFail());
  //     }
  //   } catch (error) {
  //     yield put(deleteArchiveRaisedInvoiceFail());
  //   }
  // }
}

//DELETE LIVE MARKETING LIST


// COMPLETED MARKETING LIST

function* onGetCompletedMarketingList(action) {
  const { completedMarketingListRequest } = action.payload;
  const payload = {};
  try {
    const listResponse = yield call(getCompletedMarketingsList, {
      payload: completedMarketingListRequest,
    });
    if (listResponse?.code === 200) {
      payload.list = listResponse?.data?.WorkshopMarketing;
      payload.count = listResponse?.data?.WorkshopMarketing.length;
      yield put(getCompletedMarketingListSuccess(payload));
    } else {
      yield put(getCompletedMarketingListFail("Failed to fetch marketing list "));
    }
  } catch (err) {
    yield put(getCompletedMarketingListFail("Failed to fetch marketing list "));
  }
}

// COMPLETED MARKETING LIST


// VIEW MARKETING

function* onViewMarketing(data) {
  try {
    const { mode, ...payload } = data.payload
    const response = yield call(mode === "HQ" ? viewHQMarketing : viewMarketing, payload.id);

    if (response) {
      if (response.code === 200) {
        yield put(viewMarketingSuccess(response.data.WorkshopMarketing));
      } else {
        yield put(viewMarketingFail(response.message));
      }
    } else {
      yield put(viewMarketingFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(viewMarketingFail(error.response));
  }
}

// VIEW MARKETING


// EDIT MARKETING

function* onEditMarketing(data) {
  try {

    const response = yield call(editMarketing, data);


    if (response) {
      if (response.code === 200) {
        yield put(editMarketingSuccess(response.data));
      } else {
        yield put(editMarketingFail(response.message));
      }
    } else {
      yield put(editMarketingFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editMarketingFail(error.response));
  }
}

// EDIT MARKETING

//DELETE COMPLETED MARKETING LIST
{

  // function* onDeleteArchiveCompletedMarketingList(action) {
  //   try {
  //     const {saleMarketing , marketing } = action.payload;
  //     let saleMarketingDelete;
  //     let marketingDelete;

  //     if (marketing.ids.length !== 0) {
  //       marketingDelete = yield call(deleteArchiveInvoices, { payload: invoice });
  //     }
  //     if (saleInvoice && saleInvoice.ids.length !== 0) {
  //       saleInvoiceDelete = yield call(deleteArchiveInvoicesSales, {
  //         payload: saleInvoice,
  //       });
  //     }

  //     if (invoiceDelete?.code === 200 || saleInvoiceDelete?.code === 200) {
  //       yield put(deleteArchiveRaisedInvoiceSuccess(invoice.action_type));
  //       const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
  //       let workshop_id = window.atob(storedData.workshop_id);
  //       yield put(getRaisedInvoicePayload(workshop_id));
  //     } else {
  //       yield put(deleteArchiveRaisedInvoiceFail());
  //     }
  //   } catch (error) {
  //     yield put(deleteArchiveRaisedInvoiceFail());
  //   }
  // }
}

//DELETE COMPLETED MARKETING LIST


function* marketingSagas() {
  yield takeLatest(GET_SCHEDULED_MARKETING_LIST, onGetScheduledMarketingList);
  yield takeLatest(GET_LIVE_MARKETING_LIST, onGetLiveMarketingList);
  yield takeLatest(GET_COMPLETED_MARKETING_LIST, onGetCompletedMarketingList);
  yield takeLatest(CREATE_MARKETING, onCreateMarketing);
  yield takeLatest(VIEW_MARKETING, onViewMarketing);
  yield takeLatest(EDIT_MARKETING, onEditMarketing);
  // yield takeLatest(DELETE_SCHEDULED_MARKETING_LIST, onDeleteArchiveScheduledMarketingList); 
  // yield takeLatest(DELETE_LIVE_MARKETING_LIST, onDeleteArchiveLiveMarketingList); 
  // yield takeLatest(DELETE_COMPLETED_MARKETING_LIST, onDeleteArchiveCompletedMarketingList); 
  // onDeleteArchiveCompletedMarketingList
}
export default marketingSagas;
