import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const AccountSyncHeader = Loadable({
  loader: () => import("./components/accountSync/AccountSyncHeader"),
  loading: Loading,
});

const ServiceScheduleHeader = Loadable({
  loader: () => import("./components/serviceSchedule/ServiceScheduleHeader"),
  loading: Loading,
});

const VehicleHeader = Loadable({
  loader: () => import("./components/vehicleList/VehicleHeader"),
  loading: Loading,
});

const CreateVehicleHeader = Loadable({
  loader: () =>
    import("./components/vehicleList/createVehicle/CreateVehicleHeader"),
  loading: Loading,
});

const EditVehicle = Loadable({
  loader: () => import("./components/vehicleList/createVehicle/EditVehicle"),
  loading: Loading,
});

const CreateTrailer = Loadable({
  loader: () => import("./components/vehicleList/Trailer/CreateTrailer"),
  loading: Loading,
});

const EditTrailer = Loadable({
  loader: () => import("./components/vehicleList/Trailer/EditTrailer"),
  loading: Loading,
});

const CreateCaravan = Loadable({
  loader: () => import("./components/vehicleList/Caravan/CreateCaravan"),
  loading: Loading,
});

const EditCaravan = Loadable({
  loader: () => import("./components/vehicleList/Caravan/EditCaravan"),
  loading: Loading,
});

const CreateMarine = Loadable({
  loader: () => import("./components/vehicleList/Marine/CreateMarine"),
  loading: Loading,
});

const EditMarine = Loadable({
  loader: () => import("./components/vehicleList/Marine/EditMarine"),
  loading: Loading,
});

const CreateTruck = Loadable({
  loader: () => import("./components/vehicleList/Truck/CreateTruck"),
  loading: Loading,
});

const EditTruck = Loadable({
  loader: () => import("./components/vehicleList/Truck/EditTruck"),
  loading: Loading,
});
const CreateMotorBike = Loadable({
  loader: () => import("./components/vehicleList/MotorBike/CreateMotorBike"),
  loading: Loading,
});

const EditMotorBike = Loadable({
  loader: () => import("./components/vehicleList/MotorBike/EditMotorBike"),
  loading: Loading,
});

const SyncHistory = Loadable({
  loader: () =>
    import("./components/accountSync/SyncHistory"),
  loading: Loading,
});

const CreateMachine = Loadable({
  loader: () => import("./components/vehicleList/Machine/CreateMachine"),
  loading: Loading,
});

const EditMachine = Loadable({
  loader: () => import("./components/vehicleList/Machine/EditMachine"),
  loading: Loading,
});

export const routes = [
  {
    path: "/sync",
    exact: true,
    name: "AccountSync",
    element: AccountSyncHeader,
  },
  {
    path: "/service_schedule",
    exact: true,
    name: "ServiceScheduleHeader",
    element: ServiceScheduleHeader,
  },
  {
    path: "/asset_list",
    exact: true,
    name: "VehicleHeader",
    element: VehicleHeader,
  },
  {
    path: "/vehicle_create",
    exact: true,
    name: "CreateVehicleHeader",
    element: CreateVehicleHeader,
  },
  {
    path: "/edit_vehicle/:id/Vehicle",
    exact: true,
    name: "EditVehicle",
    element: EditVehicle,
  },

  {
    path: "/trailer_create",
    exact: true,
    name: "CreateTrailer",
    element: CreateTrailer,
  },

  {
    path: "/edit_vehicle/:id/Trailer",
    exact: true,
    name: "EditTrailer",
    element: EditTrailer,
  },
  {
    path: "/caravan_create",
    exact: true,
    name: "CreateCaravan",
    element: CreateCaravan,
  },
  {
    path: "/edit_vehicle/:id/Caravan",
    exact: true,
    name: "EditCaravan",
    element: EditCaravan,
  },
  {
    path: "/marine_create",
    exact: true,
    name: "CreateMarine",
    element: CreateMarine,
  },

  {
    path: "/edit_vehicle/:id/Marine",
    exact: true,
    name: "EditMarine",
    element: EditMarine,
  },
  {
    path: "/truck_create",
    exact: true,
    name: "CreateTruck",
    element: CreateTruck,
  },
  {
    path: "/edit_vehicle/:id/Truck",
    exact: true,
    name: "EditTruck",
    element: EditTruck,
  },
  {
    path: "/motorcycle_create",
    exact: true,
    name: "CreateMotorBike",
    element: CreateMotorBike,
  },
  {
    path: "/edit_vehicle/:id/Motorbike",
    exact: true,
    name: "EditMotorBike",
    element: EditMotorBike,
  },
  {
    path: "/sync_history/:id",
    exact: true,
    name: "SyncHistory",
    element: SyncHistory,
  },
  {
    path: "/machine_create",
    exact: true,
    name: "CreateMachine",
    element: CreateMachine,
  },
  {
    path: "/edit_vehicle/:id/Machine",
    exact: true,
    name: "EditMachine",
    element: EditMachine,
  }
];

export * from "./";
