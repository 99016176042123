import { takeLatest, put, call } from "redux-saga/effects";
import {
  ADD_WORKSHOP_BAYS_DETAILS,
  CHECK_NAME_WORKSHOP_BAYS_DETAILS,
  CHECK_ORDER_WORKSHOP_BAYS_DETAILS,
  DELETE_WORKSHOP_BAYS_DETAILS,
  EDIT_WORKSHOP_BAYS_DETAILS,
  GET_WORKSHOP_BAYS_DETAILS,
  UPDATE_WORKSHOP_BAYS_DETAILS,
} from "./actionTypes";
import {
  getWorkShopBaysSuccess,
  getWorkShopBaysFail,
  addWorkShopBaysSuccess,
  addWorkShopBaysFail,
  editWorkShopBaysSuccess,
  editWorkShopBaysFail,
  updateWorkShopBaysSuccess,
  updateWorkShopBaysFail,
  deleteWorkShopBaysSuccess,
  deleteWorkShopBaysFail,
  checkNameWorkShopBaysSuccess,
  checkNameWorkShopBaysFail,
  checkOrderWorkShopBaysSuccess,
  checkOrderWorkShopBaysFail,
  getWorkShopBaysDetail,
  updateWorkShopBaysDetail,
} from "./actions";

import {
  addWorkshopBay,
  getWorkshopBays,
  updateWorkshopBay,
  deleteWorkshopBay,
} from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

// List workshop bays
function* onGetWorkshopBaysDetails(data) {
  try {
    const response = yield call(getWorkshopBays, data);
    if (response) {
      if (response.code === 200) {
        yield put(getWorkShopBaysSuccess(response));
      } else {
        yield put(getWorkShopBaysFail(response.message));
      }
    } else {
      yield put(getWorkShopBaysFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWorkShopBaysFail(error.response));
  }
}

// Check name avaiability in workshop bays
function* onCheckNameWorkshopBaysDetails(data) {
  try {
    const response = yield call(getWorkshopBays, data);
    if (response) {
      // if (response.code === 400) {
      yield put(
        checkNameWorkShopBaysSuccess(response.data.WorkshopBaysDetails)
      );
      // } else {
      //   yield put(checkNameWorkShopBaysFail(response.message));
      // }
    } else {
      yield put(checkNameWorkShopBaysFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkNameWorkShopBaysFail(error.response));
  }
}

// Check order availability in  workshop bays
function* onCheckOrderWorkshopBaysDetails(data) {
  try {
    const response = yield call(getWorkshopBays, data);
    if (response) {
      // if (response.code === 200) {

      if (data.payload.isTable) {
        if (response.data.WorkshopBaysDetails.length === 0) {
          yield put(
            updateWorkShopBaysDetail({
              order_by: data.payload.conditions.order_by,
              id: data.payload.conditions["id !="],
              workshop_id: data.payload.conditions.workshop_id,
            })
          );
        } else {
          showError("Order already exist");
          yield put(
            checkOrderWorkShopBaysFail(response.data.WorkshopBaysDetails)
          );
        }
      } else {
        yield put(
          checkOrderWorkShopBaysSuccess(response.data.WorkshopBaysDetails)
        );
      }
      // } else {
      //   yield put(checkOrderWorkShopBaysFail(response.message));
      // }
    } else {
      yield put(checkOrderWorkShopBaysFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkOrderWorkShopBaysFail(error.response));
  }
}

// ADD workshop bays
function* onAddWorkshopBaysDetails(data) {
  try {
    const rec = yield call(getWorkshopBays, {
      payload: {
        get: "all",
        conditions: {
          order_by: data.payload.order_by,
          workshop_id: data.payload.workshop_id,
          is_deleted: 0,
        },
      },
    });
    if (rec.code !== 200) {
      const response = yield call(addWorkshopBay, data);
      if (response) {
        if (response.code === 200) {
          showSuccess(response.message);
          const storeData = JSON.parse(sessionStorage.getItem("stored_data"));
          const workshop_id = window.atob(storeData.workshop_id);
          yield put(addWorkShopBaysSuccess(response));
          yield put(
            getWorkShopBaysDetail({
              get: "all",
              conditions: {
                "WorkshopBaysDetails.workshop_id": workshop_id,
                "WorkshopBaysDetails.status !=": 2,
              },
              contain: ["JobSchedules"],
            })
          );
        } else {
          yield put(addWorkShopBaysFail(response.message));
        }
      } else {
        yield put(addWorkShopBaysFail("Didn't get api response"));
      }
    } else {
      showError("Order already exist");
      yield put(addWorkShopBaysFail("Order already exist"));
    }
  } catch (error) {
    yield put(addWorkShopBaysFail(error.response));
  }
}

// EDIT workshop bays
function* onEditWorkshopBaysDetails(data) {
  try {
    const response = yield call(getWorkshopBays, data);
    if (response) {
      if (response.code === 200) {
        yield put(editWorkShopBaysSuccess(response));
      } else {
        yield put(editWorkShopBaysFail(response.message));
      }
    } else {
      yield put(editWorkShopBaysFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editWorkShopBaysFail(error.response));
  }
}

// UPDATE workshop bays
function* onUpdateWorkshopBaysDetails(data) {
  try {
    const response = yield call(updateWorkshopBay, data);
    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);
        const storeData = JSON.parse(sessionStorage.getItem("stored_data"));
        const workshop_id = window.atob(storeData.workshop_id);
        yield put(
          getWorkShopBaysDetail({
            get: "all",
            conditions: {
              "WorkshopBaysDetails.workshop_id": workshop_id,
              "WorkshopBaysDetails.status !=": 2,
            },
            contain: ["JobSchedules"],
          })
        );
        yield put(updateWorkShopBaysSuccess(response));
      } else {
        yield put(updateWorkShopBaysFail(response.message));
      }
    } else {
      yield put(updateWorkShopBaysFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateWorkShopBaysFail(error.response));
  }
}

// DELETE workshop bays
function* onDeleteWorkshopBaysDetails(data) {
  try {
    const response = yield call(deleteWorkshopBay, data);
    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);
        const storeData = JSON.parse(sessionStorage.getItem("stored_data"));
        const workshop_id = window.atob(storeData.workshop_id);
        yield put(
          getWorkShopBaysDetail({
            get: "all",
            conditions: {
              "WorkshopBaysDetails.workshop_id": workshop_id,
              "WorkshopBaysDetails.status !=": 2,
            },
            contain: ["JobSchedules"],
          })
        );
        yield put(deleteWorkShopBaysSuccess(response));
      } else {
        yield put(deleteWorkShopBaysFail(response.message));
      }
    } else {
      yield put(deleteWorkShopBaysFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(deleteWorkShopBaysFail(error.response));
  }
}

// end workshop mark up detail
function* WorkshopBaysSaga() {
  yield takeLatest(GET_WORKSHOP_BAYS_DETAILS, onGetWorkshopBaysDetails);
  yield takeLatest(
    CHECK_NAME_WORKSHOP_BAYS_DETAILS,
    onCheckNameWorkshopBaysDetails
  );
  yield takeLatest(
    CHECK_ORDER_WORKSHOP_BAYS_DETAILS,
    onCheckOrderWorkshopBaysDetails
  );

  yield takeLatest(ADD_WORKSHOP_BAYS_DETAILS, onAddWorkshopBaysDetails);
  yield takeLatest(EDIT_WORKSHOP_BAYS_DETAILS, onEditWorkshopBaysDetails);
  yield takeLatest(UPDATE_WORKSHOP_BAYS_DETAILS, onUpdateWorkshopBaysDetails);
  yield takeLatest(DELETE_WORKSHOP_BAYS_DETAILS, onDeleteWorkshopBaysDetails);
}

export default WorkshopBaysSaga;
