import { GET_INSPECTION_LIST, GET_INSPECTION_LIST_FAIL, GET_INSPECTION_LIST_SUCCESS } from "./actionTypes";


export const getInspectionList = () => {
  return {
    type: GET_INSPECTION_LIST,
  };
};

export const getInspectionListSuccess = (data) => {
  return {
    type: GET_INSPECTION_LIST_SUCCESS,
    payload: data,
  };
};

export const getInspectionListFail = (error) => {
  return {
    type: GET_INSPECTION_LIST_FAIL,
    payload: error,
  };
};

