import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import { GET_USER_PROFILE, UPDATE_USER_PROFILE } from 'store/userProfile/actionTypes';
import { useDispatch, useSelector } from "react-redux";
import LoaderImage from "assets/images/page-loader.svg";
import { UploadFileToS3 } from "utils/uploadFileToS3";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./style.scss";

let Profile = () => {

    const title = useState("5iQ | User Profile");

    useEffect(() => {
        document.title = "5iQ | User Profile";
    }, [title]);
    
    const dispatch = useDispatch();

    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let user_id = window.atob(storedData.user_id);
    
    const [validated, setValidated] = useState(false);
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [profilePicture, setProfilePicture] = useState("");
    const [userProfile, setUserProfile] = useState();
    
    const [showUserProfile, setShowUserProfile] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSave, setIsFormSave] = useState(false);
    const [files, setFiles] = useState([]); 

    // get user profile data
    const UserProfileData = useSelector((state) => state.UserProfileReducer);
    
    useEffect(() => {
        dispatch({
            type: GET_USER_PROFILE,
            payload: {
                  "get": "all",
                  "conditions": {
                     "id": user_id,
                  }
            },
         });

    },[]);
    
    useEffect(() => {
        if(UserProfileData.isFetching === true && UserProfileData.userData !== null){
            var userDetail = UserProfileData.userData;
            setFirstname(userDetail.firstname);
            setLastname(userDetail.lastname);
            setUserProfile(userDetail.user_profile);
            
            if(UserProfileData.isUpdate === true && isFormSave === true){
                setIsLoading(false);
                setShowUserProfile(true);
                setProfilePicture("");
                setIsFormSave(false);
                let user_name = window.btoa(`${UserProfileData.userData.firstname} ${UserProfileData.userData.lastname}`);
                storedData.user_name = user_name;
                storedData.userProfile = userDetail.user_profile;
                sessionStorage.setItem('stored_data',JSON.stringify(storedData));
                document.getElementById('header_username').innerHTML = `${UserProfileData.userData.firstname} ${UserProfileData.userData.lastname}`;
            }
        }
    },[UserProfileData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        // setIsLoading(true);
        setIsFormSave(true);

        if (form.checkValidity() !== false) {
          
            if(profilePicture[0]){

                const d = new Date();
                var time = d.getTime();
                var filename = profilePicture[0].file.name.replace(/ /g,"_");
                var extenstion = profilePicture[0].file.type.split("/");
                filename = "user_profile/"+time+"_"+filename;

                setShowUserProfile(false);
                UploadFileToS3(profilePicture[0].file,filename);
            }
          
            dispatch({
                type: UPDATE_USER_PROFILE,
                payload: {
                    id: user_id,
                    firstname: firstname,
                    lastname: lastname,
                    user_profile: filename,
                    type:"head_quarters"
                },
            });

          
        } else {
            setIsLoading(false);
        }
        setValidated(true);
      };

    return(
    <>
        <div className="right-section-wrapper has-white-bg has-heading-block">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="page-heading-block d-flex align-center justify-space-between">
                    <h5>User Profile</h5>
                    <Button
                        type="submit"
                        className="rounded-btn btn-success height-34"
                        variant="success">
                        Save
                    </Button>
                </div>
                <div className="content-wrapper user-profile">
                    <div className="form-outer d-flex">
                        <div className="form-group width-25">
                            <label>First Name</label>
                            <Form.Group className="form-group">
                                <Form.Control required type="text" placeholder="Firstname" defaultValue={firstname}  onBlur={(e) => setFirstname(e.target.value)} />
                                <Form.Control.Feedback type="invalid">Please Enter Firstname</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="form-group width-25">
                            <label>Last Name</label>
                            <Form.Group className="form-group">
                                <Form.Control required type="text" placeholder="Lastname" defaultValue={lastname} onBlur={(e) => setLastname(e.target.value)}  />
                                <Form.Control.Feedback type="invalid">Please Enter Lastname</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="form-group width-50">
                            <label>Profile Picture</label>
                            <div className="file-upload-outer secondary-filepond browse-option d-flex">
                                <FilePond
                                    files={profilePicture}
                                    onupdatefiles={setProfilePicture}
                                    allowMultiple={false}
                                    name="files"
                                    labelIdle='<i class="fa fa-folder-open" aria-hidden="true"></i> Browse …'
                                />
                                {(profilePicture === '' || profilePicture.length == 0) && 
                                    <>
                                        <div className="user-profile-image">
                                            <img className="profile-img" 
                                                src={"https://5iq-website-uploads.s3.ap-southeast-2.amazonaws.com/"+(userProfile == "" ? "user_profile/1682330195836_5iQ_logo_black.png" : userProfile)}
                                                alt="Profile">
                                            </img>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
        {isLoading === true &&
            <div className="page-loader d-flex align-center justify-center" >
            <img src={LoaderImage} alt="Loading"></img>
            </div>
        }
    </>
    )
}

export default Profile;
