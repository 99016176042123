export function formatInvoiceDetail(data) {
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const {
    getBookedJobResponse,
    getInvoiceListResponse,
    getCashsaleListResponse,
    // getWorkShopOtherDetailResponse,
    // getJobNotesResponse,
    // getInsuranceResponse,
    // getJobCannedResponse,
    // getLabourChargeResponse,
  } = data;
  let //   otherdata,
    customer_detail,
    jobs,
    job_parts,
    customerdetail,
    labourname,
    part_gst,
    labour_gst,
    purchase_gst,
    vehicle,
    // scripts,
    job_type,
    claim_amount = 0,
    remain_balance = 0,
    inv_type,
    inv_number = 1,
    invoice_data,
    all_invoices,
    jobnotes,
    address,
    comp_logo,
    invoiceVal,
    job_labours,
    // defaultLabour,
    invoiceType = "";
  // insurance_company;
  // if (
  //     getWorkShopOtherDetailResponse?.code === 200
  // ) {
  //     otherdata = getWorkShopOtherDetailResponse.data.WorkshopOtherDetails;
  // }
  if (getBookedJobResponse?.code === 200) {
    jobs = getBookedJobResponse.data.JobSchedules[0];
    // customer_detail = jobs?.vehicle?.user?.customers_details[0];
    customer_detail = jobs?.customer.cust_detail;
    job_parts = jobs?.jobs_parts;
    customerdetail = jobs?.vehicle.Users[0];
    if (jobs?.user?.employees_details) {
      if (jobs?.user?.employees_details[0]?.labour_charge?.type == 1) {
        labourname = "Technician";
      }
      if (jobs?.user?.employees_details[0]?.labour_charge?.type == 2) {
        labourname = "Senior Technician";
      }
      if (jobs?.user?.employees_details[0]?.labour_charge?.type == 3) {
        labourname = "Apprentice";
      }
      if (jobs?.user?.employees_details[0]?.labour_charge?.type == 4) {
        labourname = "Mobile Technician";
      }
    }
  }

  part_gst = window.atob(storedData.part_gst);
  labour_gst = window.atob(storedData.labour_gst);
  purchase_gst = window.atob(storedData.purchase_gst);

  vehicle = jobs.vehicle;
  // if (getJobCannedResponse.code === 200) {
  //     scripts = getJobCannedResponse.data.WorkshopScriptsDetails;
  // }

  job_type = jobs?.job_booking_type?.name || "";
  invoice_data = jobs?.invoices[0];
  inv_type = {};

  const pathname = window.location.pathname;
  const pagedata = pathname.split("/");
  const pageName = pagedata[1];

  if (invoice_data) {
    //////////////check for full invoice///////////////
    let chkinoice_type = invoice_data.tax_invoice;
    if (chkinoice_type == 1) {
      //Redirect
      if (pageName !== "invoice-preview") {
        return {
          redirect: true,
          location: "/invoice",
        };
      }
    } else {
      if (chkinoice_type == 2) {
        claim_amount = invoice_data.claim_amount;
        remain_balance = invoice_data.remain_balance;
        inv_type = "Progress Claim";
      } else if (chkinoice_type == 3) {
        ///////////check for payment claim///////////
        // chkinoice_type = $this->searchForId('2',$invoices);
        if (chkinoice_type == 3 && invoice_data.remain_balance != 0) {
          claim_amount = invoice_data.claim_amount;
          remain_balance = invoice_data.remain_balance;
          inv_type = "Progress Claim";
        } else {
          return {
            redirect: true,
            location: "/invoice",
          };
        }
      }
    }
  } else {
    inv_type = "Full";
  }

  if (getInvoiceListResponse?.code == 200) {
    // let invoices =
    //   getInvoiceListResponse?.data?.Invoices[
    //   getInvoiceListResponse?.data?.Invoices.length - 1
    //   ];
    // inv_number = invoices?.invoice_number + 1;
    let last_invoice_number = getInvoiceListResponse?.data?.Invoices[0]?.invoice_number + 1;
    let last_casesale_number = getCashsaleListResponse?.data?.CashSaleInvoice[0]?.cash_sale_no + 1;
    inv_number = Math.max(last_invoice_number, last_casesale_number);
  }


  all_invoices = jobs?.invoices;

  // if (getJobNotesResponse?.code == 200) {
  //     jobnotes = getJobNotesResponse?.data?.JobNotes[0] || "";
  // }

  if (jobs?.user) {
    address = jobs?.user?.workshop || '';
  } else {
    address = jobs?.workshop || '';
  }

  comp_logo = jobs?.workshop?.logo;
  invoiceVal = all_invoices[all_invoices.length - 1];
  job_labours = jobs?.job_labours;

  // if (getLabourChargeResponse?.code == 200) {
  //     defaultLabour = getLabourChargeResponse?.data?.LabourCharges[0];
  // }
  // if (getInsuranceResponse?.code == 200) {
  //     insurance_company = getInsuranceResponse?.data?.InsuranceCompany;
  // }
  //   customer_detail = {
  //       ...customer_detail,
  //       email: customer_detail?.email || customerdetail.email
  //   }
  customer_detail.email = customer_detail?.email || jobs?.customer?.email;

  return {
    redirect: false,
    // otherdata,
    customer_detail,
    jobs,
    job_parts,
    customerdetail,
    labourname,
    part_gst,
    labour_gst,
    purchase_gst,
    vehicle,
    // scripts,
    job_type,
    invoice_data,
    claim_amount,
    inv_type,
    remain_balance,
    inv_number,
    all_invoices,
    jobnotes,
    address,
    comp_logo,
    invoiceVal,
    job_labours,
    // defaultLabour,
    invoiceType,
    // insurance_company,
    total: 0,
    remaing: 0,
  };
}

export function restInvoiceData(data) {
  let otherdata,
    scripts,
    inv_number = 1,
    jobnotes,
    defaultLabour,
    insurance_company;
  const {
    getWorkShopOtherDetailResponse,
    getJobNotesResponse,
    getInsuranceResponse,
    getJobCannedResponse,
    getLabourChargeResponse,
  } = data;

  if (getWorkShopOtherDetailResponse?.code === 200) {
    otherdata = getWorkShopOtherDetailResponse.data.WorkshopOtherDetails;
  }

  if (getJobCannedResponse.code === 200) {
    scripts = getJobCannedResponse.data.WorkshopScriptsDetails;
  }

  if (getWorkShopOtherDetailResponse?.code == 200) {
    inv_number = otherdata?.invoice_number;
  }

  if (getJobNotesResponse?.code == 200) {
    jobnotes = getJobNotesResponse?.data?.JobNotes[0] || "";
  }

  if (getJobNotesResponse?.code == 200) {
    jobnotes = getJobNotesResponse?.data?.JobNotes[0] || "";
  }

  if (getLabourChargeResponse?.code == 200) {
    defaultLabour = getLabourChargeResponse?.data?.LabourCharges[0];
  }
  if (getInsuranceResponse?.code == 200) {
    insurance_company = getInsuranceResponse?.data?.InsuranceCompany;
  }

  return {
    otherdata,
    scripts,
    inv_number,
    jobnotes,
    defaultLabour,
    insurance_company,
  };
}
