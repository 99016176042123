import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { Scrollbars } from "react-custom-scrollbars";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../utils/alertMessage";
import phoneCode from "../../utils/phoneCode.json";
import {
    GET_STATE,
    GET_VEHICLEMODEL,
    GET_VEHICLESERIES,
    GET_COUNTRY,
    GET_VEHICLES,
    GET_ASSETS
} from "../../store/commonApiStore/actionTypes";
import { REGO_EXIST, CLEAN_DATA } from "../../store/unassignedJobs/actionTypes";
import {
    GET_CUSTOM_FIELDS,
    CHECK_VIN_EXIST,
    CHECK_CHASIS_EXIST,
    CHECK_ENGINE_EXIST,
    CHECK_FLEET_EXIST,
    GET_ASSETS_MASTER,
    GET_ASSETS_DETAILS,
    ADD_ASSETS_DETAILS,
    ADD_ASSETS_MASTER,
    ADD_ASSETS
} from "../../store/assets/actionTypes";
import { addAssetsSuccess } from "../../store/assets/actions"
import {
    CHECK_EMAILEXIST,
    GET_SELECTED_CUSTOMERLIST
} from "../../store/userProfile/actionTypes";
import {
    ADD_CUSTOMER,
    CHECK_PHONE_EXIST,
    CHECK_MOBILE_EXIST
} from "../../store/contacts/actionTypes";
import LoaderImage from "../../assets/images/page-loader.svg";
import { getSelectedCustomerListSuccess } from "../../store/userProfile/actions";
import { getUserProfile } from "../../helpers/backendHelper";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
import { serializeArr } from "../../utils/unserialize";
import { GET_SUBSCRIPTION_ADDON } from "../../store/subscriptionAddon/actionTypes";
import { getStateAbbreviations } from "utils/stateAbbreviations";
import { GET_REGO_LOOKUP_DATA, UPDATE_VEHICLE } from "store/jobDetails/actionTypes";
import RegoLookup from "./regoLookup";
import { getRegoLookUpDataSuccess } from "store/jobDetails/actions";
import LookupBalance from "./lookupBalance";
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { decimalRegex, mobileRegex, numberRegex } from "utils/validationRegex";
import regoStates from "utils/regoStates.json";
import CustomFields from "../modals/CustomFieldData";
import Select from "react-select";
import VehicleFields from "../modals/VehicleFields";
import MotorbikeFields from "../modals/MotorbikeFields";
import TrailerFields from "../modals/TrailerFields";
import TruckFields from "../modals/TruckFields";
import CaravanFields from "../modals/CaravanFields";
import MarineFields from "../modals/MarineFields";
import MachineFields from "../modals/MachineFields";
import { GET_ACTIVE_ASSETS } from "../../store/list/actionTypes";
import { unserializeArr } from "utils/unserialize";
import { getAsssetIdsData } from "utils/getAssetIds";
import { useInitialData } from "views/common/InitialDataContext/InitialDataContext";
import EmptyRegoLookup from "views/modals/emptyRegoLookup";

const CustomerAndVehicle = ({ showModal, setShowModal, selectedUser, setManageStateModal, customerFormType, setCustomerDetails, setVehicleDetails, page }) => {

    const {
        expDayCount,
        isTrialMode,
        subscriptionType
    } = useInitialData();

    const md5 = require("md5");
    const [odometerType, setOdometerType] = useState(1);
    const [lookUpShow, setLookUpShow] = useState(false);
    const [activeTab, setActiveTab] = useState("customers");
    const [fieldShow, setFieldShow] = useState(false);
    const [selectConsumer, setSelectConsumer] = useState("Individual");
    const [validateCustomersFrom, setValidateCustomersFrom] = useState(false);
    const [validateVehiclesFrom, setValidateVehiclesFrom] = useState(false);
    const [validateMobile, setValidateMobile] = useState(false);
    const [VehicleModelData, setVehicleModeldata] = useState([]);
    const [VehicleSeriesData, setVehicleSeriesdata] = useState([]);
    const [transmissionData, setTransmissionData] = useState([]);
    const [engineTypeData, setEngineTypeData] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [updateViewFlag, setUpdateViewFlag] = useState(false);
    const [displayRegoStates, setDisplayRegoStates] = useState(false);
    const [assetsType, setAssetsType] = useState([]);

    const dispatch = useDispatch();
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    const workshop_state_1 = storedData?.workshop_state;
    let defaultCountryId = storedData.country;
    const AusCountryId = process.env.REACT_APP_REGO_AUS_ID;
    const NzCountryId = process.env.REACT_APP_REGO_NZ_ID;
    const [userState, setUserState] = useState(getStateAbbreviations(workshop_state_1));
    let trial_expiry_date = moment(
        window.atob(storedData.trial_expiry_date)
    ).format("YYYY-MM-DD");
    let trial_date = moment(trial_expiry_date);
    let today_date = moment(new Date());

    const workshopId = window.atob(storedData.workshop_id);
    const workshop_state = storedData.state.toString();
    let assets_type = storedData.assets_type;
    let date_format = storedData.date_format;
    let listArr = [];

    const currentYear = parseInt(new Date().getFullYear());
    const { country, vehicles, vehicleModel, state, loadingLogins, vehicleSeries, assetsDetails } = useSelector((state) => state.CommonApiStoreReducer);
    const { regoExist } = useSelector((state) => state.UnassignedJobsReducer);
    // const customFields = useSelector((state) => state.AssetsReducer.customFieldsData);
    const phoneExist = useSelector((state) => state.ContactsReducer.checkPhoneData);
    const MobileExist = useSelector((state) => state.ContactsReducer.checkMobileData);
    const { emailExist, customerList } = useSelector((state) => state.UserProfileReducer);
    const customerResp = useSelector((state) => state.ContactsReducer)
    const [validateEmail, setValidateEmail] = useState(false);

    const { customFieldsData,
        checkVinData,
        checkChasisData,
        checkEngineData,
        checkFleetData,
        assetsMasterData,
        assetsDetailsData,
        addAssetsDetails,
        addAssetsMaster,
        addAssetsData
    } = useSelector((state) => state.AssetsReducer);

    const { activeAssets } = useSelector((state) => state.List);

    //rego
    const { subscribeAddonData } = useSelector((state) => state.SubscriptionAddonReducer);
    const { regoLookUpData, vehicleData } = useSelector((state) => state.JobDetails);
    // const addAssetsDataErrorMsg = useSelector((state) => state.AssetsReducer.addAssetsData);

    const [assetsMaster, setAssetsMaster] = useState(false);
    const [assetsDetailsFlag, setAssetsDetailsFlag] = useState(false);
    const [addAssetsDetailsFlag, setAddAssetsDetailsFlag] = useState(false);
    const [addAssetsMasterFlag, setAddAssetsMasterFlag] = useState(false);
    const [assetsMasterId, setAssetsMasterId] = useState();
    const [assetsDetailsId, setAssetsDetailsId] = useState();
    const [makeValue, setMakeValue] = useState();
    const [modelValue, setModelValue] = useState();
    const [vehicleAddFlag, setVehicleAddFlag] = useState(false);
    const [displayVehicleMsg, setDisplayVehicleMsg] = useState(false);
    const [saveAndNext, setSaveAndNext] = useState(false);
    const [save, setSave] = useState(false);
    const [customerCreatedFlag, setCustomerCreatedFlag] = useState(false);

    // rego
    const [showLookupBalanceModal, setShowLookupBalanceModal] = useState(false);
    const [lookupAction, setLookupAction] = useState("set_vehicle");
    const [isLoading, setIsLoading] = useState(false);
    const [regoLookUpModal, setRegoLookUpModal] = useState(false);

    const [vehiclesFormData, setVehiclesFormData] = useState({
        asset_type_val: "Vehicle",
        rego: "",
        rego_state: "",
        id: "",
        customer_name1: "",
        customer_name: "",
        is_verified: 0,
        make: "",
        model: "",
        model_id: "",
        model_series: "",
        model_series_id: "",
        fuel_type: "",
        body_type: "",
        month: "",
        year: "",
        transmission: "",
        category: "",
        colour: "",
        seat_capacity: "",
        tyre_size_front: "",
        tyre_size_rear: "",
        vin_number: "",
        chasis_number: "",
        engine_number: "",
        fleet_number: "",
        odometer_reading: "",
        serial_no: "",
        engine_type: "",
        marine_type: "",
        engine_hours: "",
        engine_size: "",
    });

    const [emptyRegoLookUpModal, setEmptyRegoLookUpModal] = useState(false);
    const handleEmptyShowRegoLookupModal = () => {
        setEmptyRegoLookUpModal(true);
    }
    const handleCloseEmptyRegoLookupModal = () => {
        setEmptyRegoLookUpModal(false);
    }

    useEffect(() => {

        if (+defaultCountryId === +AusCountryId) {
            setDisplayRegoStates(true);
        }

        if (country.length === 0) {
            dispatch({
                type: GET_COUNTRY,
                payload: { get: "all" },
            });
        }
        if (vehicles.length === 0) {
            dispatch({
                type: GET_VEHICLES,
                payload: {
                    get: "all",
                    conditions: {
                        "vehicle_type": "1",
                    },
                    group: "assets_make",
                    order: {
                        "assets_make": "ASC"
                    }
                },
            });
        }
        if (assetsDetails === null || assetsDetails === undefined) {
            dispatch({
                type: GET_ASSETS,
                payload: {
                    get: "all"
                },
            });
        }
        getCustomFields('Vehicle')


        dispatch({
            type: GET_SUBSCRIPTION_ADDON,
            balancetype: "lookup",
            payload: {
                get: "all",
                conditions: {
                    "WorkshopSubscriptionDetails.plan_type": "vin_lookup",
                    "WorkshopSubscriptionDetails.current_vin_request >": 0,
                    "WorkshopSubscriptionDetails.workshop_id": workshopId,
                },
                // contain: {
                //     WorkshopSubscription: {
                //         conditions: {
                //             "WorkshopSubscription.workshop_id": workshopId,
                //         },
                //     },
                // },
                fields: [
                    "id",
                    "workshop_subscription_id",
                    "subscription_plan_id",
                    "current_vin_request",
                    "vin_request",
                ],
            },
        });

        dispatch({
            type: GET_ACTIVE_ASSETS,
            payload: {
                id: workshopId,
            },
        });

        return (() => {
            dispatch({
                type: CLEAN_DATA,
            })
        })
    }, [])

    useEffect(() => {
        if (activeAssets) {
            setAssetsType(unserializeArr(activeAssets));
        }
    }, [activeAssets])

    const isRegoSubscription = useMemo(() => {
        if (subscribeAddonData) {
            if (subscribeAddonData?.current_vin_request > 0) {
                return true;
            }
        } else {
            if (subscriptionType === "Trial" && isTrialMode && expDayCount > 0) {
                return true;
            } else {
                return false;
            }
        }
        // if (subscribeAddonData && Object.keys(subscribeAddonData).length != 0) return true
        // else if (trial_date.diff(today_date, "days") > 0) return true;
        // else return false
    }, [subscribeAddonData])

    useEffect(() => {
        if (customerResp.customerDetails !== null && customerResp.customerDetails !== undefined && customerCreatedFlag === true) {
            var custDetails = customerResp.customerDetails.customers_details[0];

            let stateName = state.find((stateList) => {
                if (stateList.id == custDetails.state) return stateList
            })
            if (stateName?.name === undefined) stateName = vehiclesFormData?.users_details?.States

            let userStateName = getStateAbbreviations(workshop_state_1);
            setUserState(userStateName);
            setVehiclesFormData({
                ...vehiclesFormData,
                customer_name1: custDetails.type == "2" ? custDetails.firstname + " " + custDetails.lastname : custDetails.company_name,
                user_id: custDetails.user_id,
                rego_state: userStateName,
            });

            setVehicleDetails(
                {
                    ...vehiclesFormData,
                    customer_name1: custDetails.type == "2" ? custDetails.firstname + " " + custDetails.lastname : custDetails.company_name,
                    user_id: custDetails.user_id
                }
            );
            if (custDetails.user_id !== undefined) {
                var tempData = customersFormData;
                tempData.user_id = customerResp.customerDetails.id;
                tempData.id = customerResp.customerDetails.customers_details[0].id;
                selectedUser.email = customerResp.customerDetails.email;

                setCustomersFormData(tempData);
                setCustomerDetails(customerResp.customerDetails);

                if (customerFormType == "add") {
                    showSuccess('Customer Created Successfully!');
                } else {
                    showSuccess('Customer Updated Successfully!');
                }

                setCustomerCreatedFlag(false);
                if (save === true && saveAndNext === false) {
                    handleCustomerClose();
                }
            }

        } else {
            if (customerResp.error.custMessage !== null) {
                showError(customerResp.error.custMessage);
            }
            setCustomerCreatedFlag(false)
        }
    }, [customerResp.customerDetails, updateViewFlag])

    useEffect(() => {
        if (customerResp.customerDetails !== null && customerResp.customerDetails !== undefined && customerFormType === "view") {
            var first_name = customerResp?.customerDetails?.customers_details?.[0]?.firstname || '';
            var last_name = customerResp?.customerDetails?.customers_details?.[0]?.lastname || '';
            var customer_type = customerResp?.customerDetails?.customers_details?.[0]?.type || null;
            var company_name = customerResp?.customerDetails?.customers_details?.[0]?.company_name || '';
            var abn = customerResp?.customerDetails?.customers_details?.[0]?.abn;
            var country = customerResp?.customerDetails?.country?.name;
            var country_id = customerResp?.customerDetails?.country?.id;
            var state = customerResp?.customerDetails?.country?.name;
            var state_id = customerResp?.customerDetails?.country?.id;
            var mobile_number = customerResp?.customerDetails?.customers_details?.[0]?.mobile_number;
            var phone_number = customerResp?.customerDetails?.customers_details?.[0]?.phone_number;
            var position = customerResp?.customerDetails?.customers_details?.[0]?.position;
            var postcode = customerResp?.customerDetails?.customers_details?.[0]?.postcode;
            var suburb = customerResp?.customerDetails?.customers_details?.[0]?.suburb;
            var address = customerResp?.customerDetails?.customers_details?.[0]?.street_address;
            if (Object.keys(selectedUser).length <= 1) {
                selectedUser.company_name = company_name;
                selectedUser.country = country;
                selectedUser.country_id = country_id;
                selectedUser.firstname = first_name;
                selectedUser.lastname = last_name;
                selectedUser.mobile = mobile_number;
                selectedUser.phone = phone_number;
                selectedUser.position = position;
                selectedUser.postcode = postcode;
                selectedUser.state = state;
                selectedUser.state_id = state_id;
                selectedUser.street_address = address;
                selectedUser.suburb = suburb;
                selectedUser.type = customer_type;
                selectedUser.abn = abn;
            }
        }
    }, [])


    useEffect(() => {
        let transmission = [];
        let engineType = [];
        let typeMarine = [];
        if (assetsDetails !== null) {
            assetsDetails.map((asset_type, index) => {
                if (asset_type.type === "4") {
                    transmission.push(asset_type)
                }
                if (asset_type.type === "6") {
                    engineType.push(asset_type)
                }
                if (asset_type.type === "7") {
                    typeMarine.push(asset_type)
                }
            })
        }
        setTransmissionData(transmission);
        setEngineTypeData(engineType);
        setTypeData(typeMarine);
    }, [assetsDetails])

    const [phone_code, setPhone_code] = useState("");
    const [customersFormData, setCustomersFormData] = useState({
        first_name: "",
        last_name: "",
        company_name: "",
        abn: "",
        phone_number: "",
        email: "",
        website: "",
        position: "",
        country: "",
        phone_code: "",
        mobile_number: "",
        street_address: "",
        suburb: "",
        postcode: "",
        state: workshop_state || 0,
        is_serialize: 1,
        customer_status: 1,
        payment_method: 1,
        customer_type: 1,
        payment_term: 1,
        id: null,
        user_id: null
    });


    useEffect(() => {

        if (Object.keys(selectedUser).length !== 0) {
            setVehiclesFormData({
                ...vehiclesFormData,
                // customer_name1: selectedUser.name,
                user_id: selectedUser.user_id,
                customer_name1: selectedUser.type === "2" ? selectedUser.firstname + " " + selectedUser.lastname : selectedUser.company_name,
                customer_id: selectedUser.cust_id,
                mobile_number: selectedUser.mobile,
                email: selectedUser.email
            });

            if (selectedUser.country_id !== "") {
                const newLocalCountry = selectedUser.country_id;
                defaultCountryId = newLocalCountry;
            }

            var tempCustomer = customersFormData;
            tempCustomer.first_name = selectedUser.firstname;
            tempCustomer.last_name = selectedUser.lastname;
            tempCustomer.phone_number = selectedUser.phone;
            tempCustomer.email = selectedUser.email;
            tempCustomer.mobile_number = selectedUser.mobile;
            tempCustomer.street_address = selectedUser.street_address;
            tempCustomer.suburb = selectedUser.suburb;
            tempCustomer.postcode = selectedUser.postcode;
            tempCustomer.state = selectedUser.state_id;
            tempCustomer.id = selectedUser.cust_id;
            tempCustomer.user_id = selectedUser.user_id;
            if (selectedUser.type === "1") {
                tempCustomer.company_name = selectedUser.company_name;
                tempCustomer.abn = selectedUser.abn;
                tempCustomer.website = selectedUser.website;
                tempCustomer.position = selectedUser.position;
                setSelectConsumer('Company')
            } else {
                setSelectConsumer('Individual')
            }
            setCustomersFormData(tempCustomer);
        }

        if (Object.keys(selectedUser).length !== 0 && customerFormType === "edit") {
            setActiveTab("vehicles");
        } else if (customerFormType === "view" && Object.keys(selectedUser).length !== 0) {

            var tempVehicleData = vehiclesFormData;
            tempVehicleData.rego = selectedUser.rego;
            tempVehicleData.make = selectedUser.make;
            tempVehicleData.model = selectedUser.model;
            tempVehicleData.model_series = selectedUser.series;
            tempVehicleData.id = selectedUser.vehicle_id;
            tempVehicleData.asset_type_val = selectedUser.vehicle_type;
            if (selectedUser.type == "2") {
                var customerName = selectedUser.firstname + " " + selectedUser.lastname;
            } else {
                var customerName = selectedUser.company_name;
            }
            tempVehicleData.customer_name1 = customerName;
            tempVehicleData.fuel_type = selectedUser.fuel_type;
            tempVehicleData.body_type = selectedUser.body_type;
            tempVehicleData.bike_type = selectedUser.bike_type;
            tempVehicleData.month = selectedUser.month;
            tempVehicleData.year = selectedUser.year;
            tempVehicleData.transmission = selectedUser.transmission;
            tempVehicleData.category = selectedUser.category;
            tempVehicleData.colour = selectedUser.color;
            tempVehicleData.seat_capacity = selectedUser.seat_capacity;
            tempVehicleData.tyre_size_front = selectedUser.tyre_size_front;
            tempVehicleData.tyre_size_rear = selectedUser.tyre_size_rear;
            tempVehicleData.vin_number = selectedUser.vin_number;
            tempVehicleData.chasis_number = selectedUser.chasis_number;
            tempVehicleData.engine_number = selectedUser.engine_number;
            tempVehicleData.fleet_number = selectedUser.fleet_number;

            tempVehicleData.engine_size = selectedUser.engine_size;
            tempVehicleData.engine_type = selectedUser.engine_type;
            tempVehicleData.cyliners = selectedUser.cyliners;
            tempVehicleData.engine_hours = selectedUser.engine_hours;
            tempVehicleData.no_of_axles = selectedUser.no_of_axles;
            tempVehicleData.odometer_reading = selectedUser.odometer_reading;

            tempVehicleData.brakes = selectedUser.brakes;
            tempVehicleData.trailer_type = selectedUser.trailer_type;
            tempVehicleData.number_of_wheels = selectedUser.number_of_wheels;
            tempVehicleData.gross_weight = selectedUser.gross_weight;
            tempVehicleData.tare_weight = selectedUser.tare_weight;
            tempVehicleData.hitch_type = selectedUser.hitch_type;
            tempVehicleData.hitch_size = selectedUser.hitch_size;
            tempVehicleData.trailer_size = selectedUser.trailer_size;
            tempVehicleData.travel_height = selectedUser.travel_height;
            tempVehicleData.travel_length = selectedUser.travel_length;
            tempVehicleData.aggregate_trailer_mass = selectedUser.aggregate_trailer_mass;
            tempVehicleData.tow_ball_weight = selectedUser.tow_ball_weight;
            tempVehicleData.sleeps_up_to = selectedUser.sleeps_up_to;
            tempVehicleData.configuration = selectedUser.configuration;
            tempVehicleData.date_of_manufacture = selectedUser.date_of_manufacture;
            tempVehicleData.boat_construction = selectedUser.boat_construction;
            tempVehicleData.engine_location = selectedUser.engine_location;
            tempVehicleData.number_of_engines = selectedUser.number_of_engines;
            tempVehicleData.boat_type = selectedUser.boat_type;
            tempVehicleData.boat_length = selectedUser.boat_length;
            tempVehicleData.engine_output = selectedUser.engine_output;
            tempVehicleData.number_of_gears = selectedUser.number_of_gears;
            tempVehicleData.driving_position = selectedUser.driving_position;
            setOdometerType(selectedUser.is_odometer);
            setVehiclesFormData(tempVehicleData);
            setActiveTab("customers");
        } else {
            setActiveTab("customers");
        }
    }, [selectedUser]);

    useEffect(() => {
        if (vehiclesFormData.asset_type_val !== "" || vehiclesFormData.asset_type_val !== "Vehicle") {
            setFieldShow(true);
        }
    }, [])

    useEffect(() => {
        if (country.length !== 0) {
            let countryId =
                customersFormData.country === ""
                    ? defaultCountryId
                    : customersFormData.country;
            let selectedCountry = country.find(({ id, country_name }) => {
                if (id == countryId) {
                    return country_name;
                }
            });
            getPhoneCode(selectedCountry?.country_name || '');
            getState(selectedCountry?.id || '');
        }
    }, [country, customersFormData.country]);

    const getPhoneCode = (countryName) => {
        if (phoneCode.length !== 0) {
            phoneCode.find(({ name, dial_code }) => {
                if (name == countryName) {
                    setPhone_code(dial_code);
                }
            });
        }
    };

    const getState = (countryId) => {
        dispatch({
            type: GET_STATE,
            payload: {
                get: "all",
                conditions: {
                    country_id: countryId,
                    is_deleted: 0,
                },
            },
        });

        setCustomersFormData({ ...customersFormData, country: countryId });
    };

    useEffect(() => {
        if (
            (vehiclesFormData.asset_type_val === "Vehicle" ||
                vehiclesFormData.asset_type_val === "") &&
            vehiclesFormData.make !== ""
        ) {
            dispatch({
                type: GET_VEHICLEMODEL,
                payload: {
                    get: "all",
                    conditions: {
                        assets_make: vehiclesFormData.make,
                    },
                },
            });
        }
    }, [vehiclesFormData.make]);

    useEffect(() => {
        if (vehiclesFormData.asset_type_val === "Vehicle" || vehiclesFormData.asset_type_val === "") {
            dispatch({
                type: GET_VEHICLESERIES,
                payload: {
                    get: "all",
                    conditions: {
                        asset_master_id: vehiclesFormData.model_id
                    },
                    group: ['series'],
                    fields: ['id', 'series', 'asset_master_id']
                },
            });
            if (vehicleModel.length != 0) {
                let filteredData = [];
                vehicleModel.filter((el) => {
                    if (el.assets_model.toLowerCase().includes(vehiclesFormData.model.toLowerCase()))
                        filteredData.push(el);
                });
                setVehicleModeldata(filteredData);
            }
        }
    }, [vehiclesFormData.model]);

    useEffect(() => {
        if (vehiclesFormData.asset_type_val === "Vehicle" || vehiclesFormData.asset_type_val === "") {
            if (vehicleSeries.length !== 0) {
                let filteredSeriesData = [];
                vehicleSeries.filter((el) => {
                    if (el.series.toLowerCase().includes(vehiclesFormData.model_series.toLowerCase()))
                        filteredSeriesData.push(el);
                });
                setVehicleSeriesdata(filteredSeriesData);
            }
        }
    }, [vehiclesFormData.model_series]);

    const checkRegoExist = (e) => {
        let value = e.target.value.trim().split(" ").join("");
        if (value !== "") {
            dispatch({
                type: REGO_EXIST,
                payload: {
                    get: "all",
                    conditions: {
                        "Vehicles.workshop_id": workshopId,
                        "Vehicles.rego": value,
                    },
                },
            });
        }
    }

    const handleLookupShow = () => {
        setLookUpShow(!lookUpShow);
    };
    const expandFields = () => {
        setFieldShow(!fieldShow);
    };
    const handleCustomerClose = () => {
        setShowModal(false)
        setManageStateModal('parent')
    };

    const handleCustomersInputChange = (e) => {
        const { name, value } = e.target;
        setCustomersFormData({
            ...customersFormData,
            [name]: value.trimStart(),
        });
    };

    const handleVehiclesInputChange = (e, minCheck = false) => {
        const { name, value } = e.target;

        let infoValue = value;

        if (name === "rego") {
            if (value.length == 0) {
                let vehicleData = vehiclesFormData;
                vehicleData.make = "";
                vehicleData.model = "";
                vehicleData.model_series = "";
                vehicleData.fuel_type = "";
                vehicleData.body_type = "";
                vehicleData.month = "";
                vehicleData.year = "";
                vehicleData.transmission = "";
                vehicleData.category = "";
                vehicleData.vin_number = "";
                vehicleData.chasis_number = "";
                vehicleData.engine_number = "";
                vehicleData.cyliners = "";
                vehicleData.fleet_number = "";
                vehicleData.seat_capacity = "";
                vehicleData.tyre_size_front = "";
                vehicleData.tyre_size_rear = "";
                setVehiclesFormData(vehicleData);
            } else {
                infoValue = value.trim().split(" ").join("");
            }
        }

        if (name === "month" && value !== "") {
            if (isNaN(Number(infoValue))) {
                return
            }

            if (infoValue.length > 2 || infoValue > 12) {
                infoValue = "12"
            }

            if (minCheck && infoValue < 1) {
                infoValue = "01"
            }
        }

        setVehiclesFormData({
            ...vehiclesFormData,
            [name]: infoValue.trimStart(),
        });
        if (name === "asset_type_val") {
            if (name === "asset_type_val" && (value !== "" || value !== "Vehicle")) {
                setFieldShow(true);
            }
            getCustomFields(value);
        }

        if (name === "customer_name1" && value.length > 2) {
            dispatch({
                type: GET_SELECTED_CUSTOMERLIST,
                payload: {
                    get: "all",
                    fields: [
                        "CustomersDetails.id",
                        "CustomersDetails.type",
                        "CustomersDetails.firstname",
                        "CustomersDetails.lastname",
                        "CustomersDetails.company_name",
                        "CustomersDetails.mobile_number",
                        "CustomersDetails.user_id",
                        "CustomersDetails.street_address",
                        "CustomersDetails.suburb",
                        "CustomersDetails.postcode",
                        "Users.email",
                        "Countries.id",
                        "Countries.country_name",
                        "States.id",
                        "States.name",
                    ],
                    order: {
                        "CustomersDetails.firstname": "asc",
                    },
                    conditions: {
                        "CustomersDetails.workshop_id": workshopId,
                        role_id: 4,
                        "CustomersDetails.is_deleted": 0,
                        "CustomersDetails.status": 1,
                        OR: [
                            `CustomersDetails.firstname LIKE '%${value}%'`,
                            `CustomersDetails.lastname LIKE '%${value}%'`,
                            `CONCAT(CustomersDetails.firstname, ' ', CustomersDetails.lastname) like'%${value}%'`,
                            `CustomersDetails.company_name LIKE '%${value}%'`,
                        ],
                    },
                    contain: ["Users", "Countries", "States"],
                },
            });
        }
    };

    const getCustomFields = (value) => {
        dispatch({
            type: GET_CUSTOM_FIELDS,
            payload: {
                get: "all",
                conditions: {
                    workshop_id: workshopId,
                    vehicle_type: value,
                },
            },
        });
    }

    const validatecustomerFrom = () => {

        if (selectConsumer === "Individual") {
            if (
                customersFormData.first_name === "" ||
                customersFormData.last_name === "" ||
                customersFormData.country === "" ||
                (customersFormData.email === "" && (customerFormType === "view" || customerFormType === "edit")) ||
                customersFormData.mobile_number === "" ||
                (customersFormData.mobile_number.length <= 9 && customersFormData.mobile_number.length >= 11)
            ) {
                setValidateCustomersFrom(true);
                return true;
            } else {
                setValidateCustomersFrom(false);
                return false;
            }
        } else {
            if (
                customersFormData.company_name === "" ||
                customersFormData.country === "" ||
                (customersFormData.email === "" && (customerFormType === "view" || customerFormType === "edit")) ||
                customersFormData.mobile_number === ""
                // customersFormData.mobile_number.length < 5
                // (customersFormData.mobile_number.length >= 9 && customersFormData.mobile_number.length <= 11)
            ) {
                setValidateCustomersFrom(true);
                return true;
            } else {
                setValidateCustomersFrom(false);
                return false;
            }
        }
    };

    const validateVehicleFrom = () => {
        if (
            vehiclesFormData.asset_type_val === "" ||
            vehiclesFormData.rego === "" ||
            vehiclesFormData.customer_name1 === "" ||
            regoExist
        ) {
            setValidateVehiclesFrom(true);
            return true;
        } else {
            setValidateVehiclesFrom(false);
            return false;
        }
    };

    const handleSave = async () => {

        if (!validatecustomerFrom()) {
            let customerType = 2;
            if (selectConsumer === "Company") {
                customerType = 1
            }
            let email = "";
            if (customersFormData.email === "") {
                let getEmail = {
                    payload: {
                        get: "all",
                        conditions: {
                            workshop_id: workshopId,
                            "email LIKE": "%newcustomer-%",
                        },
                        limit: 1,
                        order: {
                            id: "DESC",
                        },
                    },
                };

                let emailNo = 1;
                await getUserProfile(getEmail).then((res) => {
                    if (res.code == 200) {
                        emailNo = res.data.Users[0].email.substring(12, res.data.Users[0].email.indexOf("@"));
                        email = res.data.Users[0].email.replace(
                            emailNo,
                            parseInt(emailNo) + 1
                        );
                    } else {
                        email = 'newcustomer-' + emailNo + '@' + storedData.workshop_url;
                    }
                });
            } else {
                email = customersFormData.email;
            }

            setCustomerCreatedFlag(true);
            let customerRequest = {
                workshop_id: workshopId,
                role_id: 4,
                country: customersFormData.country,
                state: customersFormData.state,
                password: md5(Math.random().toString(36).slice(-8)),
                id: customersFormData.user_id,
                customers_details: [{
                    id: customersFormData.id,
                    workshop_id: workshopId,
                    firstname: customersFormData.first_name,
                    lastname: customersFormData.last_name,
                    type: customerType,
                    is_onsite: 0,
                    phone_number: customersFormData.phone_number,
                    country: customersFormData.country,
                    state: customersFormData.state,
                    source_of_business: "",
                    customer_status: 1,
                    customer_type: customersFormData.customer_type,
                    payment_method: 1,
                    payment_terms: "",
                    is_days_from_invoice: 0,
                    is_days_from_eom: 0,
                    customer_app: 0,
                    mobile_code: phone_code,
                    mobile_number: customersFormData.mobile_number,
                    street_address: customersFormData.street_address,
                    suburb: customersFormData.suburb,
                    postcode: customersFormData.postcode,
                    onsite_street_address: "",
                    onsite_suburb: "",
                    onsite_state: "",
                    onsite_postcode: "",
                    onsite_country: "",
                    position: customersFormData.position,
                    company_name: customersFormData.company_name,
                    abn: customersFormData.abn,
                    website: customersFormData.website
                }]
            }

            if (selectedUser.email !== email) {
                customerRequest.email = email;
            }
            dispatch({
                type: ADD_CUSTOMER,
                payload: customerRequest
            })
            if (saveAndNext === true) {
                setSave(false);
            } else {
                setSave(true);
            }
        }
    };

    const handleSaveNext = () => {
        if (activeTab === "customers") {
            if (!validatecustomerFrom()) {
                setActiveTab("vehicles");
                setSaveAndNext(true);
                handleSave();
            }
        } else {
            if (!validateVehicleFrom()) {
                saveVehicleDetails();
                // handleCustomerClose();
            }
        }
    };

    useEffect(() => {
        if (assetsMaster === true) {
            if (assetsMasterData !== null && assetsMasterData !== undefined) {
                var model = assetsMasterData?.[0]?.assets_model || '';
                var make = assetsMasterData?.[0]?.assets_make || '';
                setMakeValue(make);
                setModelValue(model);
                var assets_master_id = assetsMasterData?.[0]?.id || '';
                setAssetsMasterId(assets_master_id);
                dispatch({
                    type: GET_ASSETS_DETAILS,
                    payload: {
                        get: 'all',
                        conditions: {
                            asset_master_id: assets_master_id,
                            fuel_type: vehiclesFormData.fuel_type,
                            series: vehiclesFormData.model_series
                        },
                        limit: 1
                    }
                })
                setAssetsDetailsFlag(true)
            } else {
                dispatch({
                    type: ADD_ASSETS_MASTER,
                    payload: {
                        assets_model: vehiclesFormData.model,
                        assets_make: vehiclesFormData.make
                    }
                })
                setAddAssetsMasterFlag(true);
            }
            setAssetsMaster(false)
        }
    }, [assetsMasterData, assetsMaster])

    useEffect(() => {
        if (assetsDetailsFlag === true) {
            if (assetsDetailsData !== null && assetsDetailsData !== undefined) {
                var assets_details_id = assetsDetailsData?.[0]?.id || '';
                setAssetsDetailsId(assets_details_id);
                setVehicleAddFlag(true);
            } else {
                let assetsDetailsArr = {};
                assetsDetailsArr['assets_master_id'] = assetsMasterId;
                assetsDetailsArr['fuel_type'] = vehiclesFormData.fuel_type;
                assetsDetailsArr['row_data'] = vehiclesFormData.body_type + '$-$' + vehiclesFormData.year + '$-$';
                assetsDetailsArr['series'] = vehiclesFormData.model_series;
                assetsDetailsArr['is_in_au'] = 1;
                assetsDetailsArr['is_in_nz'] = 1;
                dispatch({
                    type: ADD_ASSETS_DETAILS,
                    payload: assetsDetailsArr
                })
                setAddAssetsDetailsFlag(true);
            }
            setAssetsDetailsFlag(false)
        }
    }, [assetsDetailsFlag])

    useEffect(() => {
        if (addAssetsDetailsFlag === true) {
            if (addAssetsDetails !== null && addAssetsDetails !== undefined) {
                var assets_details_id = addAssetsDetails?.id || '';
                setAssetsDetailsId(assets_details_id);
                setVehicleAddFlag(true);
            } else {
                setVehicleAddFlag(true);
            }
            setAddAssetsDetailsFlag(false)
        }
    }, [addAssetsDetails])

    useEffect(() => {
        if (addAssetsMasterFlag === true) {
            if (addAssetsMaster !== null && addAssetsMaster !== undefined) {
                var assets_master_id = addAssetsMaster?.id || '';
                setAssetsMasterId(assets_master_id);
                let assetsDetailsArr = {};
                assetsDetailsArr['assets_master_id'] = assets_master_id;
                assetsDetailsArr['fuel_type'] = vehiclesFormData.fuel_type;
                assetsDetailsArr['row_data'] = vehiclesFormData.body_type + '$-$' + vehiclesFormData.year + '$-$';
                assetsDetailsArr['series'] = vehiclesFormData.model_series;
                assetsDetailsArr['is_in_au'] = 1;
                assetsDetailsArr['is_in_nz'] = 1;
                dispatch({
                    type: ADD_ASSETS_DETAILS,
                    payload: assetsDetailsArr
                })
                setAddAssetsDetailsFlag(true)
            } else {
                setVehicleAddFlag(true);
            }
            setAddAssetsMasterFlag(false);
        }
    }, [addAssetsMaster])

    useEffect(() => {
        if (vehicleAddFlag === true) {
            var vehicleArr = {}
            if (customerFormType == "view") {
                vehicleArr['id'] = vehiclesFormData.id;
                vehicleArr['make'] = vehiclesFormData.make;
                vehicleArr['model'] = vehiclesFormData.model;
                // vehicleArr['rego'] = vehiclesFormData.rego;
            } else if (customerFormType == "add" || customerFormType == "edit") {
                vehicleArr['make'] = vehiclesFormData.make;
                vehicleArr['model'] = vehiclesFormData.model;
            } else {
                vehicleArr['make'] = makeValue;
                vehicleArr['model'] = modelValue;
            }

            if (vehiclesFormData.id !== null) {
                vehicleArr['id'] = vehiclesFormData.id;
            }

            if (selectedUser.rego !== vehiclesFormData.rego) {
                vehicleArr['rego'] = vehiclesFormData.rego;
            }

            if (vehiclesFormData?.rego_state) {
                vehicleArr['rego_state'] = vehiclesFormData.rego_state;
            }

            vehicleArr['workshop_id'] = workshopId;
            vehicleArr['user_id'] = vehiclesFormData.user_id;
            vehicleArr['make_icon'] = "fa fa-question-circle";
            vehicleArr['month'] = vehiclesFormData.month;
            vehicleArr['year'] = vehiclesFormData.year;
            vehicleArr['fuel_type'] = vehiclesFormData.fuel_type;
            vehicleArr['assets_master_id'] = assetsMasterId;
            vehicleArr['assets_detail_id'] = assetsDetailsId;
            vehicleArr['transmission'] = vehiclesFormData.transmission;
            vehicleArr['body_type'] = vehiclesFormData.body_type;
            vehicleArr['odometer_reading'] = vehiclesFormData.odometer_reading !== '' ? vehiclesFormData.odometer_reading : 0;
            vehicleArr['color'] = vehiclesFormData.colour;
            vehicleArr['vin_number'] = vehiclesFormData.vin_number;
            vehicleArr['chasis_number'] = vehiclesFormData.chasis_number;
            vehicleArr['engine_number'] = vehiclesFormData.engine_number;
            vehicleArr['fleet_number'] = vehiclesFormData.fleet_number;
            vehicleArr['vehicle_type'] = vehiclesFormData.asset_type_val;
            vehicleArr['engine_type'] = vehiclesFormData.engine_type;
            vehicleArr['engine_hours'] = vehiclesFormData.engine_hours;
            vehicleArr['engine_size'] = vehiclesFormData.engine_size;
            vehicleArr['marine_type'] = vehiclesFormData.marine_type;
            vehicleArr['serial_no'] = vehiclesFormData.serial_no;
            vehicleArr['category'] = vehiclesFormData.category;
            vehicleArr['is_mot_wof'] = 0;
            vehicleArr['seat_capacity'] = vehiclesFormData.seat_capacity;
            vehicleArr['tyre_size_front'] = vehiclesFormData.tyre_size_front;
            vehicleArr['tyre_size_rear'] = vehiclesFormData.tyre_size_rear;
            vehicleArr['series'] = vehiclesFormData.model_series;
            vehicleArr['is_verified'] = vehiclesFormData.is_verified;
            vehicleArr['cyliners'] = vehiclesFormData.cyliners;
            vehicleArr['no_of_axles'] = vehiclesFormData.no_of_axles;

            vehicleArr['is_odometer'] = odometerType;
            vehicleArr['bike_type'] = vehiclesFormData.bike_type;
            vehicleArr['brakes'] = vehiclesFormData.brakes;
            vehicleArr['trailer_type'] = vehiclesFormData.trailer_type;
            vehicleArr['number_of_wheels'] = vehiclesFormData.number_of_wheels;
            vehicleArr['gross_weight'] = vehiclesFormData.gross_weight;
            vehicleArr['tare_weight'] = vehiclesFormData.tare_weight;
            vehicleArr['hitch_type'] = vehiclesFormData.hitch_type;
            vehicleArr['hitch_size'] = vehiclesFormData.hitch_size;
            vehicleArr['trailer_size'] = vehiclesFormData.trailer_size;

            vehicleArr['travel_height'] = vehiclesFormData.travel_height;
            vehicleArr['travel_length'] = vehiclesFormData.travel_length;
            vehicleArr['aggregate_trailer_mass'] = vehiclesFormData.aggregate_trailer_mass;
            vehicleArr['tow_ball_weight'] = vehiclesFormData.tow_ball_weight;
            vehicleArr['sleeps_up_to'] = vehiclesFormData.sleeps_up_to;
            vehicleArr['configuration'] = vehiclesFormData.configuration;
            vehicleArr['date_of_manufacture'] = vehiclesFormData.date_of_manufacture;

            vehicleArr['boat_construction'] = vehiclesFormData.boat_construction;
            vehicleArr['engine_location'] = vehiclesFormData.engine_location;
            vehicleArr['number_of_engines'] = vehiclesFormData.number_of_engines;
            vehicleArr['boat_type'] = vehiclesFormData.boat_type;
            vehicleArr['boat_length'] = vehiclesFormData.boat_length;
            vehicleArr['engine_output'] = vehiclesFormData.engine_output;

            vehicleArr['number_of_gears'] = vehiclesFormData.number_of_gears;
            vehicleArr['driving_position'] = vehiclesFormData.driving_position;
            vehicleArr['hin_number'] = vehiclesFormData.hin_number;

            if (customFieldsData !== null) {
                var allFieldName = {};
                customFieldsData.map((customField, index) => {
                    var fieldName = customField.name;
                    if (vehiclesFormData[fieldName] != undefined && vehiclesFormData[fieldName] != "") {
                        allFieldName[fieldName] = vehiclesFormData[fieldName];
                    }
                })
                var fieldStr = serializeArr(allFieldName);
                vehicleArr['vehicle_custom_fields'] = fieldStr.replace(/\\/g, '');
            }

            setDisplayVehicleMsg(true)

            if (vehicleArr['id']) {
                dispatch({
                    type: UPDATE_VEHICLE,
                    payload: vehicleArr
                })
            } else {
                dispatch({
                    type: ADD_ASSETS,
                    payload: vehicleArr
                })
            }

            setVehicleAddFlag(false)
        }
    }, [vehicleAddFlag])

    useEffect(() => {
        if (vehicleData !== null && vehicleData !== undefined) {
            if (displayVehicleMsg === true) {
                showSuccess('Vehicle Updated Successfully!');
                let tempVehicle = vehiclesFormData;
                tempVehicle.id = vehicleData?.Vehicles?.id
                setVehicleDetails(tempVehicle);
                dispatch(addAssetsSuccess(null));
                handleCustomerClose();
                setDisplayVehicleMsg(false)
            }
        }
    }, [vehicleData, displayVehicleMsg])

    useEffect(() => {
        if (addAssetsData !== null && addAssetsData !== undefined) {
            if (displayVehicleMsg === true) {
                showSuccess('Vehicle Created Successfully!');
                let tempVehicle = vehiclesFormData;
                tempVehicle.id = addAssetsData.id;
                setVehicleDetails(tempVehicle);
                dispatch(addAssetsSuccess(null));
                handleCustomerClose();
                setDisplayVehicleMsg(false)
            }
        }
    }, [addAssetsData, displayVehicleMsg])

    // const saveVehicleDetails = async() => {
    async function saveVehicleDetails() {
        let vehicle_type = '';
        if (vehiclesFormData.asset_type_val === 'Vehicle') {
            vehicle_type = '1';
        }
        if (vehiclesFormData.asset_type_val === 'Motorbike') {
            vehicle_type = '2';
        }
        if (vehiclesFormData.asset_type_val === 'Trailer') {
            vehicle_type = '3';
        }
        if (vehiclesFormData.asset_type_val === 'Caravan') {
            vehicle_type = '4';
        }
        if (vehiclesFormData.asset_type_val === 'Truck') {
            vehicle_type = '6';
        }
        if (vehiclesFormData.asset_type_val === 'Marine') {
            vehicle_type = '5';
        }
        if (vehiclesFormData.asset_type_val === 'Machine') {
            vehicle_type = '7';
        }


        const assetData = await getAsssetIdsData(vehiclesFormData, vehicle_type);

        let assetMasterId1 = assetData['assetMasterId'] || "";
        let assetsDetailId1 = assetData['assetsDetailId'] || "";


        if (assetMasterId1 > 0 && assetsDetailId1 > 0) {
            setAssetsMasterId(assetMasterId1);
            setAssetsDetailsId(assetsDetailId1);
            setVehicleAddFlag(true);
        }

        // setModelValue(vehiclesFormData.model)
        // setMakeValue(vehiclesFormData.make);
        // dispatch({
        //     type: GET_ASSETS_MASTER,  
        //     payload: {
        //         get: "all",
        //         conditions: {
        //             assets_model: vehiclesFormData.model,
        //             assets_make: vehiclesFormData.make
        //         },
        //         limit: 1
        //     },
        // });
        // setAssetsMaster(true);
    }

    const checkEmailExist = (e) => {
        if (e.target.value.length !== 0) {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
                setValidateEmail(false);
                dispatch({
                    type: CHECK_EMAILEXIST,
                    payload: {
                        get: "all",
                        conditions: {
                            email: e.target.value,
                            workshop_id: workshopId,
                            is_deleted: '0',
                            role_id: 4,
                            "id !=" : customersFormData?.user_id
                        },
                        check_email: 1,
                        id:customersFormData?.user_id
                    },
                });
            } else {
                setValidateEmail(true);
            }
        }
    };

    const checkPhoneExist = (e) => {
        if (e.target.value.length !== 0) {
            dispatch({
                type: CHECK_PHONE_EXIST,
                payload: {
                    get: "all",
                    conditions: {
                        "phone_number": e.target.value,
                        "is_deleted": 0,
                        "workshop_id": workshopId
                    }
                },
            });
        }
    }


    const checkMobileExist = (e) => {
        if (e.target.value.length >= 9 && e.target.value.length <= 11) {
            setValidateMobile(false);
            dispatch({
                type: CHECK_MOBILE_EXIST,
                payload: {
                    get: "all",
                    conditions: {
                        "mobile_number": e.target.value,
                        "is_deleted": 0,
                        "workshop_id": workshopId,
                        "id !=":customersFormData?.id
                    }
                },
            });
        } else {
            setValidateMobile(true);
        }
    };

    const checkVinExist = (e) => {
        if (e.target.value.length !== 0) {

            dispatch({
                type: CHECK_VIN_EXIST,
                payload: {
                    get: "all",
                    conditions: {
                        "vin_number": e.target.value,
                        "is_deleted": 0,
                        "workshop_id": workshopId
                    }
                },
            });
        }
    };

    const checkEngineExist = (e) => {
        if (e.target.value.length !== 0) {

            dispatch({
                type: CHECK_ENGINE_EXIST,
                payload: {
                    get: "all",
                    conditions: {
                        "engine_number": e.target.value,
                        "is_deleted": 0,
                        "workshop_id": workshopId
                    }
                },
            });
        }
    };

    const checkChasisExist = (e) => {
        if (e.target.value.length !== 0) {

            dispatch({
                type: CHECK_CHASIS_EXIST,
                payload: {
                    get: "all",
                    conditions: {
                        "chasis_number": e.target.value,
                        "is_deleted": 0,
                        "workshop_id": workshopId
                    }
                },
            });
        }
    };

    const checkFleetExist = (e) => {
        if (e.target.value.length !== 0) {

            dispatch({
                type: CHECK_FLEET_EXIST,
                payload: {
                    get: "all",
                    conditions: {
                        "fleet_number": e.target.value,
                        "is_deleted": 0,
                        "workshop_id": workshopId
                    }
                },
            });
        }
    };

    const regoLookUp = () => {

        if (vehiclesFormData.rego === "") {
            showError("Please enter rego number");
            return false;
        }

        if (!isRegoSubscription) {
            handleShowLookupBalanceModal();
            return false;
        }
        setIsLoading(true);

        let stateName = state.find((stateList) => {
            if (stateList.id == customersFormData.state) return stateList
        })
        if (stateName?.name === undefined) stateName = vehiclesFormData?.users_details?.States

        let State = userState;
        if (+defaultCountryId === +NzCountryId) {
            State = "NZ";
        }
        let country = customersFormData.country || "";
        if (+defaultCountryId === +AusCountryId) {
            country = "Australia";
        }
        let rego = vehiclesFormData.rego || "";

        setLookupAction("update_vehicle");
        /*Get rego lookup data*/
        dispatch({
            type: GET_REGO_LOOKUP_DATA,
            payload: {
                country: country,
                state: State,
                licenceplate: rego,
                workshop_id: workshopId,
            },
        });
    };

    const handleShowLookupBalanceModal = () => {
        setShowLookupBalanceModal(true);
    };

    useEffect(() => {
        if (regoLookUpData) {
            if (!regoLookUpData?.Vehicles?.make) {
                setEmptyRegoLookUpModal(true)
                setIsLoading(false);
                dispatch(getRegoLookUpDataSuccess(null));
            } else {
                setEmptyRegoLookUpModal(false)
                if (regoLookUpData.Vehicles.length !== 0) {
                    setIsLoading(false);
                    setRegoLookUpModal(true)
                } else {
                    setIsLoading(false);
                    setRegoLookUpModal(false);
                    dispatch(getRegoLookUpDataSuccess(null));
                    showError("Rego/Plate not found!");
                }
            }
        }
    }, [regoLookUpData]);

    const handleShowRegoLookupModal = () => { setRegoLookUpModal(true) };

    const verifyVehicleDetail = () => {
        let vehicleDetail = regoLookUpData.Vehicles;
        setVehiclesFormData({
            ...vehiclesFormData,
            make: vehicleDetail.make,
            model: vehicleDetail.model,
            model_series: vehicleDetail.series,
            fuel_type: vehicleDetail.fuel_type,
            body_type: vehicleDetail.body_type,
            month: vehicleDetail.monthyear?.split("/")?.shift(),
            year: vehicleDetail.year,
            transmission: vehicleDetail.transmission,
            category: vehicleDetail.category,
            vin_number: vehicleDetail.vin_number,
            chasis_number: vehicleDetail.chasis_number,
            engine_number: vehicleDetail.engine_number,
            cyliners: vehicleDetail.cyliners,
            engine_type: vehicleDetail.engine_type,
            engine_size: vehicleDetail.engine_size,
            is_verified: 1,
        });
        handleCloseRegoLookupModal();
    };
    const handleCloseRegoLookupModal = () => {
        setRegoLookUpModal(false);
        dispatch(getRegoLookUpDataSuccess(null));
    };

    const handleCloseLookupBalanceModal = () => setShowLookupBalanceModal(false);


    return (
        <>
            {(loadingLogins === true || isLoading) && (
                <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div>
            )}

            <Modal
                className="add-customer-vehicle"
                size="md"
                show={showModal}
                onHide={handleCustomerClose}
                centered
            >
                <Modal.Body>
                    <div className="custom-tabs">
                        <Tabs
                            defaultActiveKey="customers"
                            activeKey={activeTab}
                            onSelect={(k) => setActiveTab(k)}
                        >
                            <Tab eventKey="customers" title="Customers">
                                <div className="content-wrapper">
                                    <div className="radio-wrapper">
                                        <Form.Check
                                            className="custom-check radio-btn secondary-radio mb-2"
                                            type="radio"
                                            id="1"
                                        >
                                            <Form.Check.Input
                                                name="csvpackage"
                                                type="radio"
                                                isValid
                                                onChange={() => setSelectConsumer("Individual")}
                                                checked={
                                                    selectConsumer === "Individual" ? true : false
                                                }
                                                value="1"
                                            />
                                            <Form.Check.Label>
                                                Individual
                                                <span className="check"></span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <Form.Check
                                            className="custom-check radio-btn secondary-radio mb-2"
                                            type="radio"
                                            id="2"
                                        >
                                            <Form.Check.Input
                                                name="csvpackage"
                                                type="radio"
                                                isValid
                                                onChange={() => setSelectConsumer("Company")}
                                                checked={selectConsumer === "Company" ? true : false}
                                                value="2"
                                            />
                                            <Form.Check.Label>
                                                Company
                                                <span className="check"></span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                    {selectConsumer === "Individual" ? (
                                        <Form validated={validateCustomersFrom}>
                                            <div className="form-outer form-align-start">
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>First Name *</label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        name="first_name"
                                                        value={customersFormData.first_name}
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Last Name *</label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        name="last_name"
                                                        value={customersFormData.last_name}
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Main Phone</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="phone_number"
                                                        className={
                                                            phoneExist && "form-control is-invalid"
                                                        }
                                                        minLength={6}
                                                        maxLength={10}
                                                        // onChange={(e) => handleCustomersInputChange(e)}
                                                        onChange={(e) => {
                                                            if (!decimalRegex.test(e.target.value)) return;
                                                            handleCustomersInputChange(e);
                                                        }}
                                                        onBlur={(e) => checkPhoneExist(e)}
                                                        value={customersFormData.phone_number}
                                                    />
                                                    {phoneExist &&
                                                        <div className="err-msg">
                                                            This Phone number is already registered.
                                                        </div>
                                                    }
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Email Address</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        className={
                                                            (emailExist || validateEmail) && "form-control is-invalid"
                                                        }
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        onBlur={(e) => checkEmailExist(e)}
                                                        value={customersFormData.email}
                                                        required={(customerFormType !== "add") && true}
                                                    />
                                                    {emailExist && (
                                                        <div className="invalid-feedback">
                                                            This email is already registered.
                                                        </div>
                                                    )}
                                                    {validateEmail && (
                                                        <div className="err-msg">
                                                            Please enter a valid email address.
                                                        </div>
                                                    )}
                                                    {(!validateEmail && !emailExist) && (
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                                <div className="form-group custom-normal-select width-50 mb-3">
                                                    <label>Country *</label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        required
                                                        disabled={true}
                                                        name="country"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.country}
                                                    >
                                                        <option value="">Select Country</option>
                                                        {country.length !== 0 &&
                                                            country.map(({ id, country_name }, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={id}
                                                                // selected={
                                                                //   id === defaultCountryId ? true : false
                                                                // }
                                                                >
                                                                    {country_name}
                                                                </option>
                                                            ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required.
                                                    </Form.Control.Feedback>
                                                </div>
                                                <div className="mobile-wrapper form-group width-50 mb-3">
                                                    <Form.Group className="mobile-code-input form-group">
                                                        <label>Code</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="phone_code"
                                                            value={phone_code}
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <InputGroup className="form-group mb-3 mobile-input-group">
                                                            <label>Mobile No. *</label>
                                                            <InputGroup.Text></InputGroup.Text>
                                                            <Form.Control
                                                                type="text"
                                                                required
                                                                minLength={9}
                                                                maxLength={11}
                                                                className={
                                                                    (MobileExist || validateMobile) && "form-control is-invalid"
                                                                }
                                                                name="mobile_number"
                                                                onChange={(e) => {
                                                                    if (!decimalRegex.test(e.target.value)) return;
                                                                    handleCustomersInputChange(e)
                                                                }
                                                                }
                                                                onBlur={(e) => {
                                                                    setValidateMobile(false);
                                                                    if (!decimalRegex.test(e.target.value)) return; checkMobileExist(e)
                                                                }}
                                                                value={customersFormData.mobile_number}
                                                            />

                                                        </InputGroup>
                                                        {MobileExist &&
                                                            <div className="err-msg">
                                                                This Mobile Number is already registered.
                                                            </div>
                                                        }
                                                        {
                                                            (MobileExist === false) ?
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter a valid Mobile Number.
                                                                </Form.Control.Feedback> : ''
                                                        }
                                                        {validateMobile && (
                                                            <div className="err-msg">
                                                                Please enter a valid Mobile Number.
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                                <Form.Group className="form-group mb-3">
                                                    <label>Street Address</label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={2}
                                                        name="street_address"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.street_address}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Suburb</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="suburb"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.suburb}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Post code</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="postcode"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.postcode}
                                                    />
                                                </Form.Group>
                                                <div className="form-group custom-normal-select">
                                                    <label>{customersFormData?.country == NzCountryId ? "Region" : "State"}</label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        name="state"
                                                        value={customersFormData.state}
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                    >
                                                        <option>Select State</option>
                                                        {state.length !== 0 &&
                                                            state.map(({ id, name }, index) => (
                                                                <option key={id} value={id}>{name}</option>
                                                            ))}
                                                    </Form.Select>
                                                </div>
                                            </div>
                                        </Form>
                                    ) : (
                                        <Form validated={validateCustomersFrom}>
                                            <div className="form-outer form-align-start">
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Company Name *</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="company_name"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.company_name}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>{customersFormData.country == NzCountryId ? "NZBN" : "ABN"}</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="abn"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.abn}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Main Phone</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="phone_number"
                                                        onChange={(e) => { if (!decimalRegex.test(e.target.value)) return; handleCustomersInputChange(e) }}
                                                        value={customersFormData.phone_number}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Email Address</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        className={
                                                            (emailExist || validateEmail) && "form-control is-invalid"
                                                        }
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        onBlur={(e) => checkEmailExist(e)}
                                                        value={customersFormData.email}
                                                        required={(customerFormType !== "add") && true}
                                                    />
                                                    {emailExist && (
                                                        <div className="invalid-feedback">
                                                            This email is already registered.
                                                        </div>
                                                    )}
                                                    {validateEmail && (
                                                        <div className="err-msg">
                                                            Please enter a valid email address.
                                                        </div>
                                                    )}
                                                    {(!validateEmail && !emailExist) && (
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Website</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="website"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.website}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Position</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="position"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.position}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>First Name</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="first_name"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.first_name}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Last Name</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="last_name"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.last_name}
                                                    />
                                                </Form.Group>
                                                <div className="form-group custom-normal-select width-50 mb-3">
                                                    <label>Country *</label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        required
                                                        disabled={true}
                                                        name="country"
                                                        value={customersFormData.country}
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                    >
                                                        <option value="">Select Country</option>
                                                        {country.length !== 0 &&
                                                            country.map(({ id, country_name }, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={id}
                                                                // selected={
                                                                //   id == defaultCountryId ? true : false
                                                                // }
                                                                >
                                                                    {country_name}
                                                                </option>
                                                            ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required.
                                                    </Form.Control.Feedback>
                                                </div>
                                                <div className="mobile-wrapper form-group width-50 mb-3">
                                                    <Form.Group className="mobile-code-input form-group">
                                                        <label>Code</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="phone_code"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={phone_code}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <InputGroup className="form-group mb-3 mobile-input-group">
                                                            <label>Mobile No. *</label>
                                                            <InputGroup.Text></InputGroup.Text>
                                                            <Form.Control
                                                                type="text"
                                                                name="mobile_number"
                                                                onChange={(e) => handleCustomersInputChange(e)}
                                                                value={customersFormData.mobile_number}
                                                                required
                                                                minLength={9}
                                                                maxLength={11}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter at least 10 characters.
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </div>
                                                <Form.Group className="form-group mb-3">
                                                    <label>Street Address</label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={4}
                                                        name="street_address"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.street_address}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Suburb</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="suburb"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.suburb}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Post code</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="postcode"
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                        value={customersFormData.postcode}
                                                    />
                                                </Form.Group>
                                                <div className="form-group custom-normal-select">
                                                    <label>{customersFormData.country == NzCountryId ? "Region" : "State"}</label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        name="state"
                                                        value={customersFormData.state}
                                                        onChange={(e) => handleCustomersInputChange(e)}
                                                    >
                                                        <option value="">Select State</option>
                                                        {state.length !== 0 &&
                                                            state.map(({ id, name }, index) => (
                                                                <option key={id} value={id}>{name}</option>
                                                            ))}
                                                    </Form.Select>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </div>
                            </Tab>

                            <Tab eventKey="vehicles" title="Vehicles">
                                <Form validated={validateVehiclesFrom}>
                                    <div className="content-wrapper">
                                        <div className="form-outer form-align-start">
                                            <Form.Group className={`form-group custom-normal-select ${displayRegoStates && vehiclesFormData.asset_type_val !== "Machine" ? (`width-33 mb-3`) : (`width-50 mb-3`)} `} >
                                                <label>Asset Type *</label>

                                                <Form.Select
                                                    aria-label="Default select example"
                                                    name="asset_type_val"
                                                    required
                                                    onChange={(e) => handleVehiclesInputChange(e)}
                                                    value={vehiclesFormData.asset_type_val}
                                                >
                                                    <option value="">Select Vehicle</option>
                                                    {
                                                        assetsType.length !== 0 &&
                                                        assetsType.map((assets, index) => (
                                                            <option key={index} value={assets}>{assets}</option>
                                                        ))
                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required.
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                            {["Machine"].includes(
                                                vehiclesFormData.asset_type_val
                                            ) ? (

                                                <Form.Group className={`form-group width-50 mb-3`}>
                                                    <label>Machine ID *</label>
                                                    <div
                                                        className={`  d-flex flex-column`}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="d-flex">
                                                            <div className="d-flex flex-1 justify-space-between" style={{ "border": '1px solid #ced4da', borderRadius: "0.25rem", marginRight: "0.25rem" }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="rego"
                                                                    className={`text-uppercase ${regoExist && `form-control is-invalid`} `}
                                                                    required
                                                                    onBlur={(e) => checkRegoExist(e)}
                                                                    onChange={(e) => handleVehiclesInputChange(e)}
                                                                    value={vehiclesFormData.rego}
                                                                    style={{ width: displayRegoStates ? 'calc(100% - 85px )' : '', 'border': 'none' }}
                                                                />

                                                            </div>
                                                        </div>
                                                        {regoExist && (
                                                            <div style={{ color: "red" }}>
                                                                This machine id is already registered.
                                                            </div>
                                                        )}

                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>

                                            ) : (
                                                <Form.Group className={`form-group ${displayRegoStates ? (`width-65-30 mb-3`) : (`width-50 mb-3`)} `}>
                                                    {["Motorbike"].includes(
                                                        vehiclesFormData.asset_type_val
                                                    ) ? (
                                                        <>
                                                            <label>Rego or Name *</label>
                                                            <OverlayTrigger
                                                                key="right"
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-right" className="vehicle-tooltip">
                                                                        <p>Enter a Bike Rego or Name
                                                                            must be entered to distinguish</p>
                                                                        <p className="pt-2">Bike Rego - On Road</p>
                                                                        <p>Bike Name - Off Road</p>
                                                                    </Tooltip>
                                                                }>
                                                                <span className="m-l-5">
                                                                    <i className="fa fa-info-circle font-size-14 text-primary"></i>
                                                                </span>
                                                            </OverlayTrigger>
                                                        </>
                                                    ) : (
                                                        // ["Marine"].includes(
                                                        //     vehiclesFormData.asset_type_val
                                                        // ) ? (
                                                        //     <label>HIN * (Hull Identification Number)</label>
                                                        // ) : (
                                                        <label>Rego *</label>
                                                        // )
                                                    )}
                                                    <div
                                                        className={`  d-flex flex-column ${vehiclesFormData?.isverified === 1 ? `m-r-10` : ""}`}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="d-flex">
                                                            <div className="d-flex flex-1 justify-space-between" style={{ "border": '1px solid #ced4da', borderRadius: "0.25rem", marginRight: "0.25rem" }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="rego"
                                                                    className={`text-uppercase ${regoExist && `form-control is-invalid`} `}
                                                                    required
                                                                    onBlur={(e) => checkRegoExist(e)}
                                                                    onChange={(e) => handleVehiclesInputChange(e)}
                                                                    value={vehiclesFormData.rego}
                                                                    style={{ width: displayRegoStates ? 'calc(100% - 85px )' : '', 'border': 'none' }}
                                                                />
                                                                {displayRegoStates &&
                                                                    <div className="select-block select-wrapper d-flex justify-space-between btn-gradient" style={{ right: "30px", background: "none", borderLeft: '1px solid #ced4da', paddingTop: '3px', width: '85px' }}>
                                                                        <Form.Group className="form-group" style={{ maxWidth: "85px", margin: '0' }}>
                                                                            <Select
                                                                                maxMenuHeight={360}
                                                                                className="custom-select gradient-select"
                                                                                placeholder="State"
                                                                                name="rego_state"
                                                                                id="rego_state"
                                                                                options={regoStates}
                                                                                onChange={(e) => {
                                                                                    setVehiclesFormData({
                                                                                        ...vehiclesFormData,
                                                                                        rego_state: e.value
                                                                                    });
                                                                                    setUserState(e.value);
                                                                                }}
                                                                                value={regoStates.filter(
                                                                                    (option) => option.value === userState
                                                                                )}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <OverlayTrigger
                                                                key="top"
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip-bottom">
                                                                        Rego Lookup
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Button className="btn-gradient" onClick={regoLookUp} >
                                                                    <i className="fa fa-search "></i>
                                                                    <span className="hide-rego-text"> Rego Lookup</span>
                                                                </Button>
                                                            </OverlayTrigger>

                                                            {vehiclesFormData?.isverified == 1 ? (
                                                                <i
                                                                    className="fa fa-check-circle is_rego_verified validate-icon"
                                                                    id="is_rego_verified"
                                                                    title="Rego Verified"
                                                                ></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        {regoExist && (
                                                            <div style={{ color: "red" }}>
                                                                This rego already exits in this workshop.
                                                            </div>
                                                        )}

                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            )}


                                            <Form.Group className="form-group auto-complete-wrapper mb-3 width-50">
                                                <label>Customer Name *</label>
                                                <Form.Control
                                                    type="text"
                                                    name="customer_name1"
                                                    required
                                                    onChange={(e) => handleVehiclesInputChange(e)}
                                                    value={vehiclesFormData.customer_name1}
                                                    autoComplete="off"
                                                    placeholder="Search Customer"
                                                />

                                                {customerList !== null &&
                                                    customerList.length !== 0 &&
                                                    vehiclesFormData.customer_name1 !== "" && (
                                                        <ul className="auto-complete-list">
                                                            <Scrollbars
                                                                autoHeight
                                                                autoHeightMax={200}
                                                                className="custom-scrollbar"
                                                                style={{ width: "100%" }}
                                                                renderThumbVertical={(props) => (
                                                                    <div
                                                                        {...props}
                                                                        className="thumb-vertical grey-thumb"
                                                                    />
                                                                )}
                                                            >
                                                                {customerList.map((customer_values, index) => (
                                                                    <li
                                                                        key={index}
                                                                        name="customer_name1"
                                                                        onClick={() => {
                                                                            setVehiclesFormData({
                                                                                ...vehiclesFormData,
                                                                                customer_name1: customer_values.type === "2" ? customer_values.firstname + " " + customer_values.lastname : customer_values.company_name,
                                                                                user_id: customer_values.user_id,
                                                                                customer_id: customer_values.id,
                                                                                mobile_number: customer_values.mobile_number,
                                                                                users_details: customer_values
                                                                            });
                                                                            dispatch(
                                                                                getSelectedCustomerListSuccess([])
                                                                            );
                                                                        }}
                                                                    >
                                                                        {(customer_values.type === "2") ? customer_values.firstname + " " + customer_values.lastname : customer_values.company_name}
                                                                    </li>
                                                                ))}
                                                            </Scrollbars>
                                                        </ul>
                                                    )}

                                                <Form.Control.Feedback type="invalid">
                                                    This field is required.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            {["Marine"].includes(
                                                vehiclesFormData.asset_type_val
                                            ) ? (

                                                <Form.Group className="form-group mb-3 width-50">
                                                    <Form.Label>Run Time (in Hours)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="odometer_reading"
                                                        onChange={(e) => {
                                                            handleVehiclesInputChange(e)
                                                            setOdometerType(0)
                                                        }
                                                        }
                                                        value={vehiclesFormData.odometer_reading}
                                                    />
                                                </Form.Group>
                                            ) : (
                                                <>
                                                    {["Motorbike", "Machine"].includes(
                                                        vehiclesFormData.asset_type_val
                                                    ) ? (
                                                        <>
                                                            <Form.Group className="form-group mb-3 width-50">
                                                                <Form.Label>{((odometerType == 1) ? "Odometer (in Km)" : "Run Time (in Hours)")}</Form.Label>
                                                                {/* <Form.Control
                                                        type="text"
                                                        name="odometer_reading"
                                                        onChange={(e) => handleVehiclesInputChange(e)}
                                                        value={vehiclesFormData.odometer_reading}
                                                    /> */}
                                                                <div className="algn-items-center d-flex flex-nowrap">
                                                                    <Form.Group className="form-group m-0 p-r-15" style={{ maxWidth: "50%" }}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="odometer_reading"
                                                                            value={vehiclesFormData.odometer_reading}
                                                                            onChange={(e) => handleVehiclesInputChange(e)}
                                                                        />
                                                                    </Form.Group>
                                                                    <div className="radio-wrapper tab-radio-btn odo-hrs-radio yes-no-radio">
                                                                        <Form.Check className="radio-btn" type="radio" id="odo">
                                                                            <Form.Check.Input
                                                                                checked={
                                                                                    odometerType == 1 ? true : false
                                                                                }
                                                                                name="selectPart" type="radio" onChange={() => setOdometerType(1)} />
                                                                            <Form.Check.Label>ODO<span className="check"></span></Form.Check.Label>
                                                                        </Form.Check>
                                                                        <Form.Check className="radio-btn" type="radio" id="hrs">
                                                                            <Form.Check.Input checked={
                                                                                odometerType == 0 ? true : false
                                                                            } name="selectPart" type="radio" onChange={() => setOdometerType(0)} />
                                                                            <Form.Check.Label>HRS<span className="check"></span></Form.Check.Label>
                                                                        </Form.Check>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        </>
                                                    ) : (
                                                        <Form.Group className="form-group mb-3 width-50">
                                                            <Form.Label>Odometer (in Km)</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="odometer_reading"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.odometer_reading}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </>
                                            )
                                            }



                                            {["Vehicle", ""].includes(
                                                vehiclesFormData.asset_type_val
                                            ) && (
                                                    <VehicleFields
                                                        vehiclesFormData={vehiclesFormData}
                                                        handleVehiclesInputChange={handleVehiclesInputChange}
                                                        setVehiclesFormData={setVehiclesFormData}
                                                        VehicleModelData={VehicleModelData}
                                                        vehicles={vehicles}
                                                        setVehicleModeldata={setVehicleModeldata}
                                                        VehicleSeriesData={VehicleSeriesData}
                                                        setVehicleSeriesdata={setVehicleSeriesdata}
                                                        transmissionData={transmissionData}
                                                        lookUpShow={lookUpShow}
                                                        handleLookupShow={handleLookupShow}
                                                        checkVinData={checkVinData}
                                                        checkVinExist={checkVinExist}
                                                        checkChasisData={checkChasisData}
                                                        checkChasisExist={checkChasisExist}
                                                        checkEngineData={checkEngineData}
                                                        checkEngineExist={checkEngineExist}
                                                        checkFleetExist={checkFleetExist}
                                                        checkFleetData={checkFleetData}
                                                        fieldShow={fieldShow}
                                                    >
                                                    </VehicleFields>
                                                )}

                                            {["Motorbike"].includes(
                                                vehiclesFormData.asset_type_val
                                            ) && (
                                                    <MotorbikeFields
                                                        vehiclesFormData={vehiclesFormData}
                                                        handleVehiclesInputChange={handleVehiclesInputChange}
                                                        transmissionData={transmissionData}
                                                        lookUpShow={lookUpShow}
                                                        handleLookupShow={handleLookupShow}
                                                        checkVinData={checkVinData}
                                                        checkVinExist={checkVinExist}
                                                        checkChasisData={checkChasisData}
                                                        checkChasisExist={checkChasisExist}
                                                        checkEngineData={checkEngineData}
                                                        checkEngineExist={checkEngineExist}
                                                        checkFleetExist={checkFleetExist}
                                                        checkFleetData={checkFleetData}
                                                        fieldShow={fieldShow}
                                                    >
                                                    </MotorbikeFields>
                                                )}

                                            {["Trailer"].includes(
                                                vehiclesFormData.asset_type_val
                                            ) && (
                                                    <TrailerFields
                                                        vehiclesFormData={vehiclesFormData}
                                                        handleVehiclesInputChange={handleVehiclesInputChange}
                                                        setVehiclesFormData={setVehiclesFormData}
                                                        VehicleModelData={VehicleModelData}
                                                        vehicles={vehicles}
                                                        setVehicleModeldata={setVehicleModeldata}
                                                        VehicleSeriesData={VehicleSeriesData}
                                                        setVehicleSeriesdata={setVehicleSeriesdata}
                                                        transmissionData={transmissionData}
                                                        lookUpShow={lookUpShow}
                                                        handleLookupShow={handleLookupShow}
                                                        checkVinData={checkVinData}
                                                        checkVinExist={checkVinExist}
                                                        checkChasisData={checkChasisData}
                                                        checkChasisExist={checkChasisExist}
                                                        checkEngineData={checkEngineData}
                                                        checkEngineExist={checkEngineExist}
                                                        checkFleetExist={checkFleetExist}
                                                        checkFleetData={checkFleetData}
                                                        fieldShow={fieldShow}
                                                    >
                                                    </TrailerFields>
                                                )}

                                            {["Truck"].includes(
                                                vehiclesFormData.asset_type_val
                                            ) && (
                                                    <TruckFields
                                                        vehiclesFormData={vehiclesFormData}
                                                        handleVehiclesInputChange={handleVehiclesInputChange}
                                                        setVehiclesFormData={setVehiclesFormData}
                                                        VehicleModelData={VehicleModelData}
                                                        vehicles={vehicles}
                                                        setVehicleModeldata={setVehicleModeldata}
                                                        VehicleSeriesData={VehicleSeriesData}
                                                        setVehicleSeriesdata={setVehicleSeriesdata}
                                                        transmissionData={transmissionData}
                                                        lookUpShow={lookUpShow}
                                                        handleLookupShow={handleLookupShow}
                                                        checkVinData={checkVinData}
                                                        checkVinExist={checkVinExist}
                                                        checkChasisData={checkChasisData}
                                                        checkChasisExist={checkChasisExist}
                                                        checkEngineData={checkEngineData}
                                                        checkEngineExist={checkEngineExist}
                                                        checkFleetExist={checkFleetExist}
                                                        checkFleetData={checkFleetData}
                                                        fieldShow={fieldShow}
                                                    >
                                                    </TruckFields>
                                                )}

                                            {["Caravan"].includes(
                                                vehiclesFormData.asset_type_val
                                            ) && (
                                                    <CaravanFields
                                                        vehiclesFormData={vehiclesFormData}
                                                        handleVehiclesInputChange={handleVehiclesInputChange}
                                                        setVehiclesFormData={setVehiclesFormData}
                                                        VehicleModelData={VehicleModelData}
                                                        vehicles={vehicles}
                                                        setVehicleModeldata={setVehicleModeldata}
                                                        VehicleSeriesData={VehicleSeriesData}
                                                        setVehicleSeriesdata={setVehicleSeriesdata}
                                                        transmissionData={transmissionData}
                                                        lookUpShow={lookUpShow}
                                                        handleLookupShow={handleLookupShow}
                                                        checkVinData={checkVinData}
                                                        checkVinExist={checkVinExist}
                                                        checkChasisData={checkChasisData}
                                                        checkChasisExist={checkChasisExist}
                                                        checkEngineData={checkEngineData}
                                                        checkEngineExist={checkEngineExist}
                                                        checkFleetExist={checkFleetExist}
                                                        checkFleetData={checkFleetData}
                                                        fieldShow={fieldShow}
                                                    >
                                                    </CaravanFields>
                                                )}

                                            {["Marine"].includes(
                                                vehiclesFormData.asset_type_val
                                            ) && (
                                                    <MarineFields
                                                        vehiclesFormData={vehiclesFormData}
                                                        handleVehiclesInputChange={handleVehiclesInputChange}
                                                        setVehiclesFormData={setVehiclesFormData}
                                                        VehicleModelData={VehicleModelData}
                                                        vehicles={vehicles}
                                                        setVehicleModeldata={setVehicleModeldata}
                                                        VehicleSeriesData={VehicleSeriesData}
                                                        setVehicleSeriesdata={setVehicleSeriesdata}
                                                        transmissionData={transmissionData}
                                                        lookUpShow={lookUpShow}
                                                        handleLookupShow={handleLookupShow}
                                                        checkVinData={checkVinData}
                                                        checkVinExist={checkVinExist}
                                                        checkChasisData={checkChasisData}
                                                        checkChasisExist={checkChasisExist}
                                                        checkEngineData={checkEngineData}
                                                        checkEngineExist={checkEngineExist}
                                                        checkFleetExist={checkFleetExist}
                                                        checkFleetData={checkFleetData}
                                                        fieldShow={fieldShow}
                                                    >
                                                    </MarineFields>
                                                )}

                                            {["Machine"].includes(
                                                vehiclesFormData.asset_type_val
                                            ) && (
                                                    <MachineFields
                                                        vehiclesFormData={vehiclesFormData}
                                                        handleVehiclesInputChange={handleVehiclesInputChange}
                                                        setVehiclesFormData={setVehiclesFormData}
                                                        VehicleModelData={VehicleModelData}
                                                        vehicles={vehicles}
                                                        setVehicleModeldata={setVehicleModeldata}
                                                        VehicleSeriesData={VehicleSeriesData}
                                                        setVehicleSeriesdata={setVehicleSeriesdata}
                                                        transmissionData={transmissionData}
                                                        lookUpShow={lookUpShow}
                                                        handleLookupShow={handleLookupShow}
                                                        checkVinData={checkVinData}
                                                        checkVinExist={checkVinExist}
                                                        checkChasisData={checkChasisData}
                                                        checkChasisExist={checkChasisExist}
                                                        checkEngineData={checkEngineData}
                                                        checkEngineExist={checkEngineExist}
                                                        checkFleetExist={checkFleetExist}
                                                        checkFleetData={checkFleetData}
                                                        fieldShow={fieldShow}
                                                    >
                                                    </MachineFields>
                                                )}

                                            {/* Custom Field Data START */}
                                            <div className={`w-100 hidden-form ${fieldShow ? "active" : ""}`}>
                                                {customFieldsData !== null &&
                                                    <CustomFields
                                                        customFieldsData={customFieldsData}
                                                        vehiclesFormData={vehiclesFormData}
                                                        handleVehiclesInputChange={handleVehiclesInputChange}
                                                        setVehiclesFormData={setVehiclesFormData}
                                                    ></CustomFields>
                                                }
                                            </div>
                                            {/* Custom Field Data END */}
                                        </div>

                                        <div className="see-more-custom-fields mt-1">
                                            <Button
                                                type="button"
                                                // variant="outline-primary"
                                                className="btn btn-primary rounded-btn"
                                                onClick={expandFields}
                                            >
                                                {fieldShow ? "View Less" : "View More"}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-secondary"
                        className="btn-outline rounded-btn"
                        onClick={handleCustomerClose}
                    >
                        Close
                    </Button>

                    {activeTab === "customers" && (
                        <Button
                            className="rounded-btn btn-success"
                            variant="success"
                            onClick={() => handleSave()}
                            disabled={(validateMobile || MobileExist || emailExist) && "true"}
                        >
                            Save
                        </Button>
                    )}
                    <Button
                        className="rounded-btn btn-success"
                        variant="success"
                        onClick={() => handleSaveNext()}
                        disabled={(validateMobile || MobileExist || emailExist) && "true"}
                    >
                        {activeTab === "customers" ? `Save & Next` : page === "quote" ? `Save & Create a Quote` : page === "invoice" ? "Save & Create a Cash Sale" : `Save & Create a Job`}
                    </Button>
                </Modal.Footer>
            </Modal>

            {regoLookUpModal === true && (
                <RegoLookup
                    showRegoLookupModal={regoLookUpModal}
                    handleShowRegoLookupModal={handleShowRegoLookupModal}
                    handleCloseRegoLookupModal={handleCloseRegoLookupModal}
                    vehicleRegoData={regoLookUpData && regoLookUpData.Vehicles}
                    verifyVehicleDetail={verifyVehicleDetail}

                />
            )}

            {emptyRegoLookUpModal === true && (
                <EmptyRegoLookup
                    showEmptyRegoLookUpModal={emptyRegoLookUpModal}
                    handleShowEmptyRegoLookupModal={handleEmptyShowRegoLookupModal}
                    handleCloseEmptyRegoLookupModal={handleCloseEmptyRegoLookupModal}
                />
            )}

            <LookupBalance
                isSubscription={0}
                showLookupBalanceModal={showLookupBalanceModal}
                handleCloseLookupBalanceModal={handleCloseLookupBalanceModal}
            />
        </>
    );
};

export default CustomerAndVehicle;
