import { getInsurance, getInvoicesPayments, getJobCannedList, getJobEmailTemplateList, getJobNotes, getLabourChargesList, getUnassignedJobs, getWorkshopOtherDetailsView } from "helpers/backendHelper";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import { getBookedJobPayload, getInsurancePayload, getJobCannedListPayload, getJobNotesPayload, getLabourChargeListPayload, getMessageTemplatePayload, getWorkshopOtherDetailViewPayload } from "../api";

export async function getInvoiceReceiptDetail(id) {
  // let invoice, customer, job_detail, invoices_payments_credit, jobs, address;
  let invoice, customer, job_detail, invoices_payments_credit,
    address,
    customer_detail,
    jobs,
    job_parts,
    customerdetail,
    labourname,
    parts = "",
    part_gst,
    labour_gst,
    purchase_gst,
    vehicle,
    scripts,
    job_type,
    invoice_data,
    remain_balance = 0,
    claim_amount = 0,
    inv_type = "Full",
    inv_number,
    all_invoices,
    last_invoices,
    jobnotes,
    comp_logo,
    job_labours,
    otherdata,
    defaultLabour,
    insurance_company,
    message_template;

  let workshop_id = getWorkshopId();

  const request = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCredits.id": id,
    },
    contain: {
      0: "Invoices.JobSchedules.Vehicles.Users.States",
      1: "Invoices.JobSchedules.CreatedByUser",
      "Invoices.JobSchedules.Vehicles.Users.CustomersDetails": {
        conditions: {
          workshop_id: getWorkshopId(),
        },
      },
      "Invoices.JobSchedules.Customers.CustDetails": {
        conditions: {
          "CustDetails.workshop_id": getWorkshopId(),
        },
      },
      "Invoices.JobSchedules.Customers.CustDetails.States": {}
    },
  };


  const get_invoices = await getInvoicesPayments({ payload: request });

  if (get_invoices?.code === 200) {
    invoices_payments_credit = get_invoices?.data?.InvoicesPaymentsCredits[0];
    invoice = invoices_payments_credit?.invoice;

    invoice_data = invoices_payments_credit?.invoice;

    inv_number = invoice_data?.invoice_number;
    let get_customer_data = await getUnassignedJobs({
      payload: getBookedJobPayload(invoice_data.job_id, getWorkshopId()),
    });

    if (get_customer_data?.code === 200) {

      jobs = get_customer_data?.data?.JobSchedules[0];

      if (jobs?.customer?.cust_detail) {
        customer_detail = jobs?.customer?.cust_detail;
      }
      job_parts = jobs?.jobs_parts;
      job_labours = jobs?.job_labours;
      customerdetail = jobs?.customer;

      if (jobs?.user?.employees_details[0]?.labour_charge?.type == 1) {
        labourname = "Technician";
      }
      if (jobs?.user?.employees_details[0]?.labour_charge?.type == 2) {
        labourname = "Senior Technician";
      }
      if (jobs?.user?.employees_details[0]?.labour_charge?.type == 3) {
        labourname = "Apprentice";
      }
      if (jobs?.user?.employees_details[0]?.labour_charge?.type == 1) {
        labourname = "Mobile Technician";
      }

      all_invoices = jobs?.invoices;
      last_invoices = jobs?.invoices[jobs?.invoices.length - 1];
      vehicle = jobs?.vehicle;

      job_type = jobs?.job_booking_type?.name;
      if (jobs?.user) {
        address = jobs?.user?.workshop;
      } else {
        address = jobs?.workshop;
      }
    }

    part_gst = getSessionData('part_gst');
    labour_gst = getSessionData('labour_gst');
    purchase_gst = getSessionData('purchase_gst');

    const get_worknotes_scripts = await getJobCannedList({
      payload: getJobCannedListPayload(workshop_id),
    });

    if (get_worknotes_scripts?.code === 200) {
      scripts = get_worknotes_scripts?.data?.WorkshopScriptsDetails;
    }

    // get messsage template start
    const get_message_template = await getJobEmailTemplateList({
      payload: getMessageTemplatePayload(workshop_id),
    });


    if (get_message_template?.code === 200) {
      message_template = get_message_template?.data?.CustomNotifications;
    }
    // get messsage template end

    const get_jobnotes = await getJobNotes({
      payload: getJobNotesPayload(invoice_data?.job_id),
    });
    if (get_jobnotes?.code == 200) {
      jobnotes = get_jobnotes?.data?.JobNotes[0];
    }

    const getworkshopotherdata = await getWorkshopOtherDetailsView({
      payload: getWorkshopOtherDetailViewPayload(workshop_id),
    });
    if (getworkshopotherdata?.code == 200) {
      otherdata = getworkshopotherdata?.data?.WorkshopOtherDetails;
    }

    const labourCharges = await getLabourChargesList({
      payload: getLabourChargeListPayload(workshop_id),
    });
    if (labourCharges?.code == 200) {
      defaultLabour = labourCharges?.data?.LabourCharges[0];
    }

    const insurance = await getInsurance({
      payload: getInsurancePayload(workshop_id),
    });

    if (insurance?.code == 200) {
      insurance_company = insurance?.data?.InsuranceCompany;
    }

    customer_detail.email = customer_detail?.email || customerdetail.email;

    // job_detail = invoice?.job_schedule;
    // customer = job_detail?.vehicle?.user;
    // customer = job_detail?.customer;

    //old code
    job_detail = invoice?.job_schedule;
    // customer = job_detail?.vehicle?.user;
    customer = job_detail?.customer;
  } else {
    throw new Error("Failed to fetch data");
  }

  return {
    invoice,
    customer,
    job_detail: job_detail,
    invoices_payments_credit,
    address, //done
    customer_detail, //done
    jobs, //done
    job_parts, //done
    customerdetail, //done
    labourname, //done
    parts, //done
    part_gst, //done
    labour_gst, //done
    purchase_gst, //done
    vehicle, //done
    scripts, //done
    job_type, //done
    invoice_data, //done
    remain_balance, //done
    claim_amount, //done
    inv_type, //done
    inv_number, //done
    all_invoices, //done
    last_invoices, //done
    jobnotes, //done
    // comp_logo,
    job_labours, //done
    otherdata, //done
    defaultLabour, //done
    insurance_company, //done
    message_template, //done

  };
}
