import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import tickMark from "assets/images/tick-mark.png";
import { getCurrency } from "utils/getCurrency";
import moment from "moment";
import { payment, buttons, width_400, printIcon } from "./styles.module.scss";
import {
    clearPaymentReceipt,
    clearPaymentReceiptWithNavigate,
} from "store/invoicePayment/actions";
import { useDispatch, useSelector } from "react-redux";
import { WAIT_FOR_ACTION } from "redux-wait-for-action";
import { CLEAR_PAYMENT_RECEIPT_SUCCESS } from "store/invoicePayment/actionType";
// import { GET_RAISED_INVOICES_SUCCESS } from "store/invoices/actionType";
import { SENT_BOOKINGJOB_SMS } from "store/jobDetails/actionTypes";
import { useNavigate } from "react-router-dom";
import { useActiveTab } from "../InvoiceList/InvoiceList";
import emailIcon from "../../../../assets/images/email-icon.png";
import smsImage from "../../../../assets/images/sms-vector.png";
import SendSMSToUser from "views/modals/SendSMSToUser";
import { sendCashsaleEmailInvoice, sendEmailInvoice, checkMobileExist } from "helpers/backendHelper";
import Form from "react-bootstrap/Form";
import { CHECK_MOBILE_EXIST } from "store/contacts/actionTypes";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import { showError } from "utils/alertMessage";
import { emailRegex, looselyMobileRegex } from "utils/validationRegex";

const PaymentComplete = ({
    amountPaid,
    paymentType,
    show,
    onHide,
    cust_id,
    mobile_number,
    mobileCode,
    customerId,
    vehicleRego,
    customerName,
    number = "",
    customerEmail,
    statementType = "",
    statementId = "",
    paymentReceipt = false,
    receiptData = "",
    isInvoiceB=false
}) => {

    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setActiveTab } = useActiveTab() || { setActiveTab: () => null };
    const { createdReceipt } = useSelector(
        (state) => state.invoicePaymentReducer
    );

    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let workshop_id = window.atob(storedData.workshop_id);
    const [showAddMobileModal, setShowAddMobileModal] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(mobile_number);
    const [invoiceType, setInvoiceType] = useState("payment_invoice");
    const [invoiceId, setInvoiceId] = useState();
    const [updateMobileNumber, setUpdateMobileNumber] = useState(false);
    const [isUpdateMobile, setIsUpdateMobile] = useState(false);
    const [validateMobile, setValidateMobile] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [isSms, setIsSms] = useState(true);
    const [email, setEmail] = useState(customerEmail);
    const [allEmailList, setAllEmailList] = useState();
    const [tinyUrl, setTinyUrl] = useState();
    const [receiptTotal, setReceiptTotal] = useState();

    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isEmailListRequired, setIsEmailListRequired] = useState(false);
    const [isEmailListValid, setIsEmailListValid] = useState(false);
    const [isMobileNumberRequired, setIsMobileNumberRequired] = useState(false);
    const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);
    const [isFormValidate, setIsFormValidate] = useState(true);
    const [isMobileExist, setIsMobileExist] = useState(false);

    const converToTinyUrl = async (values) => {
        await axios
            .get("https://tinyurl.com/api-create.php?url=" + values)
            .then((response) => {
                setTinyUrl(response.data)
            })
            .catch((error) => {
            });
    }



    useEffect(() => {

        if (createdReceipt) {
            setReceiptTotal(createdReceipt?.receipt_total)
            if (createdReceipt?.type == "cash_sale") {
                setInvoiceType("payment_cashsale")
                setInvoiceId(createdReceipt?.cash_sale_invoice_id)
                converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(receiptData?.cash_sale_invoice_id + "WS" + workshop_id + "WSCSWSP"));
            } else {
                converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(createdReceipt?.invoice_id + "WS" + workshop_id + "WSINWSP"));
                setInvoiceType("payment_invoice")
                setInvoiceId(createdReceipt?.invoice_id)
            }
        }
    }, [createdReceipt])

    useEffect(() => {
        if (paymentReceipt === true) {
            if (receiptData?.job_number === "Cash Sale") {
                converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(receiptData?.cash_sale_invoice_id + "WS" + workshop_id + "WSCSWSPWS" + receiptData?.id));
                setInvoiceType("payment_cashsale")
                setInvoiceId(receiptData?.cash_sale_invoice_id)
            } else {
                converToTinyUrl(process.env.REACT_APP_SITE_URL + "digital-invoice-pdf/" + window.btoa(receiptData?.invoice?.id + "WS" + workshop_id + "WSINWSPWS" + receiptData?.id));
                // setInvoiceType("payment_invoice")
                setInvoiceId(receiptData?.invoice?.id)
            }
        }
    }, [paymentReceipt])

    useEffect(() => {
        setEmail(customerEmail);
        let emailList = [
            { value: customerEmail, label: customerEmail}
        ];
        setAllEmailList(emailList)
    }, [customerEmail]);

    useEffect(() => {
        let emailList = [];
        if (email) {
            setEmail(email);
            emailList = [
                { value: email, label: email}
            ];
        }
        setAllEmailList(emailList);
    }, [email]);

    useEffect(() => {
        if (mobile_number) {
            setMobileNumber(mobile_number);
        }
    }, [mobile_number]);

    let MobileExist = useSelector(
        (state) => state.ContactsReducer.checkMobileData
    );

    const checkMobileExistFun = (e) => {
        if(isUpdateMobile){
            if (!isMobileNumberRequired && !isMobileNumberValid) {
                setIsMobileExist(MobileExist);
                dispatch({
                type: CHECK_MOBILE_EXIST,
                payload: {
                    get: "all",
                    conditions: {
                        mobile_number: e,
                        is_deleted: 0,
                        workshop_id: workshop_id,
                        "id !=": cust_id
                    },
                },
                });
            }else{
                setIsMobileExist(false);
            }
        }else{
          setIsMobileExist(false);
        }
    };

    useEffect(() => {
        if (isUpdateMobile) {
            if(!isInvoiceB){
                checkMobileExistFun(mobileNumber);
            }           
        } else {
          setIsMobileExist(false);    
        }    
        setUpdateMobileNumber(isUpdateMobile);
    }, [isUpdateMobile])
    
    useEffect(() => {
        if (isUpdateMobile) {
            setIsMobileExist(MobileExist);
        }else{
            setIsMobileExist(false);
        }
    }, [MobileExist])

    useEffect(() => {
        if (!isEmail) {
            setIsEmailRequired(false);
            setIsEmailValid(false);
            setIsEmailListRequired(false);
            setIsEmailListValid(false);            
        }else{
            if (paymentReceipt === true) {
                validateEmailList(allEmailList);
            }else{
                validateEmail(email);
            }
        }       
    }, [isEmail]);

    useEffect(() => {
        if (!isSms) {
            setIsMobileNumberRequired(false);
            setIsMobileNumberValid(false);
            setIsMobileExist(false);                    
        }else{
            if(mobileNumber){
                validateMobileNumber(mobileNumber);
            }
        }  
    }, [isSms]);

    useEffect(() => {
        if(!isEmail && !isSms){
            setIsFormValidate(false);
        }else{
            if(isEmailRequired || isEmailValid || isMobileNumberRequired || isMobileNumberValid || isEmailListRequired || isEmailListValid){
                setIsFormValidate(false);
            }else{
                setIsFormValidate(true);
            }
        }
    },[isEmailRequired,isEmailValid,isMobileNumberValid,isMobileNumberRequired,isEmailListRequired,isEmailListValid,isEmail,isSms])

    const validateEmail = (email) => {
        if(email.trim() == ""){
            setIsEmailRequired(true);
            setIsEmailValid(false);
            setIsFormValidate(false);
            return false;
        }
        if (!email.trim().match(emailRegex)) {
            setIsEmailValid(true);
            setIsEmailRequired(false);
            setIsFormValidate(false);
            return false;
        }
        setIsEmailValid(false);
        setIsEmailRequired(false);        
        return true;
    }

    const validateEmailList = (emailList) => {
        let emails = [];
        let emailError = false;
        emailList.map(({ value }) => {
            let emailValue = value.trim();
            if (!emailValue.match(emailRegex)) {
                emailError = true;
            } else {
                emails.push(emailValue);
            }
        });
        if (emailError) {
            setIsEmailListValid(true);
            setIsEmailListRequired(false);
            setIsFormValidate(false);
            return false;
        }
        if(emails.length == 0){
            setIsEmailListRequired(true);
            setIsEmailListValid(false);
            setIsFormValidate(false);
            return false;
        }
        setIsEmailListRequired(false);
        setIsEmailListValid(false);
        return true;
    }

    const validateMobileNumber = (mobileNumber) => {
        if(mobileNumber?.trim() == ""){
            setIsMobileNumberRequired(true);
            setIsMobileNumberValid(false);
            setIsFormValidate(false);
            return false;
        }
        if (!mobileNumber?.trim()?.match(looselyMobileRegex)) {
            setIsMobileNumberValid(true);
            setIsMobileNumberRequired(false);
            setIsFormValidate(false);
            return false;
        }
        setIsMobileNumberValid(false);
        setIsMobileNumberRequired(false);
        return true;
    }

    const handleSaveAndSend = async () => {
        let successSend = false;
        if (isSms) {
            if (isUpdateMobile && !isInvoiceB) {
                const requestMobile = {
                    get: "all",
                    conditions: {
                        mobile_number: mobileNumber,
                        is_deleted: 0,
                        workshop_id: workshop_id,
                        "id !=": cust_id                         
                    },
                };
                const mobileDataResponse = await checkMobileExist({ payload: requestMobile });      
                if (mobileDataResponse?.code === 200) {
                    setIsMobileExist(true);
                    return;
                }
            }
        }
        if (isEmail) {
            let emails = [];
            if (paymentReceipt === true) {
                if(!validateEmailList(allEmailList)){
                    return;
                }
                allEmailList.map(({ value }) => {
                    emails.push(value.trim());
                });
            }else{
                if(!validateEmail(email)){
                    return;
                }
                emails.push(email);
            }         
            
            let emailRequest = {
                descriptionWithItem: 1,
                id: invoiceId,
                type: "",
                email_content: "<p></p>",
                to: emails,
                paid: 1,
                invoice_payment_credit_id: receiptData?.id || ""
            }
            if (invoiceType == "payment_cashsale") {
                emailRequest.type = "receipt";
                sendCashsaleEmailInvoice({ payload: emailRequest });
            } else {
                sendEmailInvoice({ payload: emailRequest });
            }
            successSend = true;
        }
        
        if (isSms) {
            if(!validateMobileNumber(mobileNumber)){
                return;
            }

            var messageVar = "Invoice";
            if (paymentReceipt === true) {
                messageVar = "Receipt";
            }
            var table = "";
            var type = "payment_invoice";
            if (createdReceipt?.type == "cash_sale" || receiptData?.job_number === "Cash Sale") {
                table = "chkCashsale";
                type = "payment_cashsale";
            } else {
                table = "chkInvoice";
                type = "payment_invoice";
            }

            let smsFormData = {};
            smsFormData.type = type;
            smsFormData.workshop_id = workshop_id;
            smsFormData.job_id = invoiceId;
            smsFormData.customer_id = customerId;
            smsFormData.send_method = 'sms';
            smsFormData.table = table;
            smsFormData.send_to = mobileNumber;
            smsFormData.to = mobileNumber;
            if (type == "payment_invoice" || type == "payment_cashsale") {
                smsFormData.message = "Hi " + customerName + " click the link to view your paid digital " + messageVar + " for Rego #" + vehicleRego + " " + tinyUrl;
            }
            smsFormData.number = number;
            smsFormData.phone_code = mobileCode;
            smsFormData.subject = "SMS";

            if (isUpdateMobile) {
                smsFormData.update_mobile = "1";
                if(isInvoiceB){
                    smsFormData.update_mobile_type = "insurance_company";
                  }
            }

            dispatch({
                type: SENT_BOOKINGJOB_SMS,
                payload: smsFormData,
            });
            successSend = true;
        }

        if (successSend == true) {
            dispatch(clearPaymentReceipt());
            onHide?.();
            if (paymentReceipt === true) {
                navigate("/invoice#invoice-payments");
            } else {
                navigate("/invoice#completed-invoices");
            }
        }
    }

    const handleTagsChange = (emails) => {
        setAllEmailList(emails || []);
        if(isEmail){
            validateEmailList(emails || []);
        }
    };

    return (
        <>
            <Modal
                show={show}
                onHide={() => {
                    dispatch(clearPaymentReceipt());
                    onHide?.();
                }}
                dialogClassName={`center-dialog ${width_400}`}
                backdrop="static"
                size={paymentReceipt === true ? "md" : ""}
            >
                {paymentReceipt === true &&
                    <Modal.Header>
                        <Modal.Title className="d-flex justify-space-between">
                            <span>Send Invoice</span>
                            <Button
                                type="button"
                                className="close-btn"
                                onClick={onHide}
                            >
                                <i className="la la-times"></i>{" "}
                            </Button>
                        </Modal.Title>
                    </Modal.Header>
                }

                <Modal.Body>
                    <div className={payment}>
                        <div>
                            {paymentReceipt === false &&
                                <>
                                    <img src={tickMark} alt="Payment Complete" />
                                    <h3>Payment Received</h3>
                                    <div className="align-items-center d-flex justify-content-evenly">
                                        <p>{getCurrency} {receiptTotal || amountPaid}</p>
                                        <p>Paid by {paymentType}</p>
                                        <p data-time>{moment().format("hh:mm a")}</p>
                                    </div>
                                </>
                            }
                            <div className={"row " + (statementType == "statementInvoice" ? "hide" : " ")}>
                                <div className="col-sm-12 align-items-center d-flex ">
                                    <div className="align-items-center col-sm-3 d-flex justify-space-between ">
                                        <img
                                            src={emailIcon}
                                            alt="Email"
                                            className="emailIcon-image"
                                            style={{ maxWidth: "40px" }}
                                        />
                                        EMAIL
                                        <Form.Check className="custom-check form-check table-checkbox">
                                            <Form.Check.Input
                                                id="email_confirmation"
                                                type="checkbox"
                                                className="custom-check booking-confirmation"
                                                onChange={() => {
                                                    setIsEmail(!isEmail);
                                                }}
                                            />
                                            <Form.Check.Label>
                                                <span className="check"></span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                    <div className="col-sm-9 ">
                                        {paymentReceipt === true ?
                                            <>
                                                <Form.Group className="form-group m-2 mb-0">
                                                    <div className="multi-select-wrapper">
                                                        <CreatableSelect
                                                            value={allEmailList}
                                                            isMulti
                                                            name="emailList"
                                                            options={allEmailList}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={handleTagsChange}
                                                        />

                                                    </div>
                                                </Form.Group>
                                                {isEmailListRequired && (
                                                    <div className="err-msg mt-0" style={{"text-align": "left", "margin-left": "9px"}}>
                                                        Please select at least one email address.
                                                    </div>
                                                )}
                                                {isEmailListValid && (
                                                    <div className="err-msg mt-0" style={{"text-align": "left", "margin-left": "9px"}}>
                                                        Please enter valid email address.
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                                <Form.Group className="form-group m-2 mb-0">
                                                    <Form.Control
                                                        type="text"
                                                        value={email}
                                                        required
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                            if(isEmail){
                                                                validateEmail(e.target.value);
                                                            }
                                                            // setCustomerEmail(e.target.value)
                                                        }}
                                                        placeholder="Email"
                                                    />
                                                </Form.Group>
                                                {isEmailRequired && (
                                                    <div className="err-msg mt-0" style={{"text-align": "left", "margin-left": "9px"}}>
                                                        Please enter a email address.
                                                    </div>
                                                )}
                                                {isEmailValid && (
                                                    <div className="err-msg mt-0" style={{"text-align": "left", "margin-left": "9px"}}>
                                                        Please enter a valid email address.
                                                    </div>
                                                )}                                                                                                                                                  
                                            </>
                                        }
                                    </div>
                                    {paymentReceipt === true &&
                                        <>
                                            <div className="align-items-center col-sm-1 "></div>
                                            <div className="col-sm-11 ">
                                                <p>
                                                    {`User must enter 1st email and select, then enter 2nd email and select.`}
                                                </p>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="col-sm-12 align-items-center d-flex ">
                                    <div className="align-items-center col-sm-3 d-flex justify-space-between">
                                        <div className="">
                                            <img
                                                src={smsImage}
                                                alt="SMS"
                                                style={{ maxWidth: "40px" }}
                                            ></img>
                                        </div>
                                        SMS
                                        <Form.Check className="custom-check form-check table-checkbox">
                                            <Form.Check.Input
                                                id="sms_confirmation"
                                                checked={isSms}
                                                type="checkbox"
                                                className="custom-check booking-confirmation"
                                                onChange={() => {
                                                    setIsSms(!isSms);
                                                }}
                                            />
                                            <Form.Check.Label>
                                                <span className="check"></span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                    <div className="col-sm-9 ">
                                        <Form.Group className="form-group m-2">
                                            <Form.Control
                                                type="text"
                                                minLength={9}
                                                maxLength={11}
                                                value={mobileNumber}
                                                required
                                                onChange={(e) => {
                                                    setMobileNumber(e.target.value);
                                                    if(isSms){
                                                        validateMobileNumber(e.target.value);
                                                    }
                                                    if(!isInvoiceB){
                                                        checkMobileExistFun(e.target.value);
                                                    }                                                  
                                                }}
                                                placeholder="Mobile Number"
                                            />
                                            {isMobileExist && (
                                                <div className="err-msg mt-0" style={{"text-align": "left"}}>
                                                    This mobile number is already in use.
                                                </div>
                                            )}
                                            {/* {validateMobile && (
                                                <div className="err-msg">
                                                    Please enter a valid Mobile Number.
                                                </div>
                                            )} */}
                                            {isMobileNumberRequired && (
                                                <div className="err-msg mt-0" style={{"text-align": "left"}}>
                                                    Please enter a mobile number.
                                                </div>
                                            )}
                                            {isMobileNumberValid && (
                                                <div className="err-msg mt-0" style={{"text-align": "left"}}>
                                                    Please enter a valid mobile number.
                                                </div>
                                            )}  
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={"col-md-12 align-items-center d-flex " + (paymentReceipt === false ? "border-bottom" : "")}>
                                    <div className="align-items-center col-sm-3 d-flex justify-content-end">

                                        <Form.Check className="custom-check form-check table-checkbox">
                                            <Form.Check.Input
                                                id="update_message"
                                                type="checkbox"
                                                className="custom-check booking-confirmation"
                                                onChange={() => {
                                                    setIsUpdateMobile(!isUpdateMobile);
                                                }}
                                            />
                                            <Form.Check.Label>
                                                <span className="check"></span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                    <div className="col-sm-9">
                                        <div class="d-flex justify-space-between">
                                            <div className="m-2">Update Customer Mobile #</div>
                                            <Button
                                                type="button"
                                                className={"btn btn-primary btn-sm rounded-btn mb-2  " + (isMobileExist ? ' disabled ' : '') + (!isFormValidate ? ' disabled ' : '') }
                                                onClick={handleSaveAndSend}
                                            >
                                                Send Invoice
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {paymentReceipt === false &&
                                <>
                                    <div className="align-items-center d-flex justify-space-between pt-2">
                                        <button type="button" class="btn btn-danger rounded-btn btn-sm"
                                            onClick={() => {
                                                // navigate('')
                                                dispatch(clearPaymentReceipt());
                                                if (statementType == "statementInvoice") {
                                                    navigate(
                                                        `/statement_print/${statementId}`
                                                    );
                                                } else {
                                                    if (createdReceipt?.type == "cash_sale") {
                                                        navigate(
                                                            `/cashsale-receipt-email/${createdReceipt?.id}?print=true`
                                                        );
                                                    } else {
                                                        navigate(
                                                            `/invoice-receipt-email/${createdReceipt?.id}?print=true`
                                                        );
                                                    }
                                                }
                                            }}>
                                            <i class="fa fa-print mr-half"></i>Print
                                        </button>
                                        {/* <div className="align-items-center d-flex"
                                    >
                                    <i className={"border fa fa-print font-size-6r text-primary mb-2 " +printIcon}></i>
                                    <span className="p-lg-1">Print</span>
                                </div> */}
                                        <Button
                                            onClick={() => {
                                                if (statementType == "statementInvoice") {
                                                    onHide?.();
                                                    setActiveTab?.("invoice-statement");
                                                    navigate("/invoice#invoice-statement");
                                                } else {
                                                    dispatch({
                                                        ...clearPaymentReceiptWithNavigate(),
                                                        [WAIT_FOR_ACTION]: CLEAR_PAYMENT_RECEIPT_SUCCESS,
                                                    }).then(() => {
                                                        navigate("/invoice#completed-invoices");
                                                        setActiveTab?.("completed-invoices");
                                                        onHide?.();
                                                    });
                                                }
                                            }}
                                            className="btn btn-sm btn-success rounded-btn"
                                        >
                                            Back to Dashboard
                                        </Button>

                                    </div>

                                    <div></div>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>


            </Modal>

            {showAddMobileModal && (
                <SendSMSToUser
                    cust_id={cust_id}
                    workshop_id={workshop_id}
                    type={invoiceType}
                    id={invoiceId}
                    customer_id={customerId}
                    mobileCode={mobileCode}
                    rego={vehicleRego}
                    setShowAddMobileModal={setShowAddMobileModal}
                    updateMobileNumber={updateMobileNumber}
                    setUpdateMobileNumber={setUpdateMobileNumber}
                    mobileNumberForCustomer={mobileNumber}
                    setMobileNumberForCustomer={setMobileNumber}
                    customerName={customerName}
                    number={number}
                />
            )}
        </>
    );
};

export default PaymentComplete;
