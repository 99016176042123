import HighchartsReact from "highcharts-react-official";
import React, { useMemo, useState } from "react";
import "../../../style.scss";
import "../../style.scss";
import Highcharts from 'highcharts';
import DropDown from "./DropDown";
import BarchartModal from "./BarchartModal";

const DashboardHighChart = ({type,chartTitle,data,pointFormat}) => {

    const typeText = useMemo(() => {
        if(type == "INVOICE"){
            return "Invoice";
        }else if(type == "PAYMENT"){
            return "Payment";
        }else{
            return "Job";
        }
    }, [type]);

    const [openModal, setOpenModal] = useState();
    const [barchartDetails, setBarchartDetails] = useState();

    const barChartOptions = {
        colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
            return {
              radialGradient: {
                cx: 0.5,
                cy: 0.3,
                r: 0.7
              },
              stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
              ]
            };
        }),
        chart: {
            type: 'column',
            height:"100%"
        },
        title: {
            align: 'left',
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Total '+chartTitle
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: ((chartTitle==="Invoice Amount" || chartTitle==="Payment Amount") ? "$" : "") +'{point.y}'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f} </b> of total<br/>'
            // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>'
        },
        series: [
            {
                name: 'New Zealand',
                colorByPoint: true,
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            setBarchartDetails(this.name)
                            setOpenModal(true)
                            // alert('Category: ' + this.category + ', value: ' + this.y);
                        }
                    }
                },
                data: data
            }
        ],
    }

    return (
        <>
            <div className="outing-border">
                <div className="chart-wrapper">
                    <div className="d-flex align-center justify-space-between chart-title">
                        <span>{type} </span>
                        <DropDown />
                    </div>
                    <div>
                        <HighchartsReact
                            // containerProps={{ style: { height: "300px", width: '100px' } }}
                            highcharts={Highcharts}
                            options={barChartOptions}
                            allowChartUpdate={true}
                        />
                    </div>
                </div>
            </div>
        
            <BarchartModal
                typeText={typeText}
                details={barchartDetails}
                open={openModal}
                setBarChartDetails={setOpenModal}
            />
        </>
    );
};

export default DashboardHighChart;
