import LoaderImage from "assets/images/page-loader.svg";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import {
  getHQWorkshopList,
  getHQWorkshopListCount,
} from "views/hq/store/workshop/actions";
import ConfirmModal from "../../common/components/ConfirmModal";
import WorkshopDetailModal from "./WorkshopDetailModal";

const emptyDataMessage = () => {
  return <div style={{ textAlign: "center" }}>No data available in table</div>;
};

const getWorkshopFilters = (search) => ({
  "Workshops.name LIKE": `%${search}%`,
  "Workshops.phone LIKE": `%${search}%`,
  "Workshops.url LIKE": `%${search}%`,
  "Workshops.city LIKE": `%${search}%`,
  "Workshops.email LIKE": `%${search}%`,
  "States.name LIKE": `%${search}%`,
  "Countries.country_name LIKE": `%${search}%`,
});

export default function StoreList() {
  document.title = "5iQ | Stores";
  const { loading, workshops, totalCount, loadingTotalCount } = useSelector(
    (state) => state.HQReducer.HQWorkshopListReducer
  );
  // const [selectedRow, setSelectedRow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  // const [storeData, setStoreData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedWorkshop, setSelectedWorkshop] = useState();
  const [selectedWorkshopForSignin, setSelectedWorkshopForSignin] = useState();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("data"));
  // console.log({ workshops });

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
    loadData(page, sizePerPage);
  };

  const handleWorkshopUrlClick = async (clickedWorkshop) => {
    setSelectedWorkshopForSignin(clickedWorkshop);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleWorkshopDetailModalClose = () => {
    setSelectedWorkshop(undefined);
  };

  // const checkBox = {
  //   mode: "checkbox",
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     if (isSelect) {
  //       setSelectedRow([...selectedRow, row.id]);
  //     } else {
  //       let rowSelected = [...selectedRow];

  //       const index = rowSelected.indexOf(row.id);
  //       if (index > -1) {
  //         rowSelected.splice(index, 1);
  //       }
  //       setSelectedRow(rowSelected);
  //     }
  //   },
  //   onSelectAll: (isSelected) => {
  //     if (isSelected) {
  //       let ids = [];
  //       for (var key = 0; key < sizePerPage; key++) {
  //         if (storeData[key] !== undefined) ids.push(storeData[key].id);
  //       }
  //       setSelectedRow(ids);
  //     } else {
  //       setSelectedRow([]);
  //     }
  //   },
  //   selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         ref={(input) => {
  //           if (input) input.indeterminate = indeterminate;
  //         }}
  //         checked={rest.checked}
  //         name={rest.name}
  //         id={rest.id}
  //         type="checkbox"
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  //   selectionRenderer: ({ mode, ...rest }) => (
  //     <Form.Check className="custom-check secondary-check table-checkbox">
  //       <Form.Check.Input
  //         id={rest.id}
  //         checked={rest.checked}
  //         type={mode}
  //         className="custom-check"
  //         readOnly
  //       />
  //       <Form.Check.Label>
  //         <span className="check"></span>
  //       </Form.Check.Label>
  //     </Form.Check>
  //   ),
  // };

  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    // {
    //   dataField: "phone",
    //   text: "Contact Phone #",
    // },
    {
      dataField: "workshop_subscription",
      text: "Subscription",
      formatter: (cell) => {
        return cell && cell.plan_name ? (
          <>
            <p className="fw-bold">{cell.plan_name}</p>
            {cell.plan_expiry_date && (
              <p className="text-muted" style={{ fontSize: 12 }}>
                Expiring on {moment(cell.plan_expiry_date).format("DD/MM/YYYY")}
              </p>
            )}
          </>
        ) : (
          "N/A"
        );
      },
    },
    {
      dataField: "state",
      text: "State",
      formatter: (cell, row) => {
        return cell && cell.name ? cell.name : "N/A";
      },
    },
    {
      dataField: "url",
      text: "URL",
      formatter: (cell, row) => {
        return (
          <p onClick={() => handleWorkshopUrlClick(row)} className="text-blue">
            {cell}
          </p>
        );
      },
    },
    {
      dataField: "created_on",
      text: "Signed Up",
      formatter: (cell) => {
        return moment(cell).format("DD/MM/YYYY");
      },
    },
    // {
    //   dataField: "trial_expiry_date",
    //   text: "Trial",
    //   formatter: (cell) => {
    //     return cell ? (
    //       <>
    //         <p className="fw-bold">
    //           {moment().isAfter(cell) ? "Expired" : "Active"}
    //         </p>
    //         <p className="text-muted" style={{ fontSize: 12 }}>
    //           {moment(cell).format("DD/MM/YYYY")}
    //         </p>
    //       </>
    //     ) : (
    //       "N/A"
    //     );
    //   },
    // },
    // {
    //   dataField: "accounting_package",
    //   text: "Accounting Package",
    //   formatter: (cell) => {
    //     return cell && cell.name ? (cell.id === 1 ? "None" : cell.name) : "N/A";
    //   },
    // },
    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <button
                  onClick={() => {
                    setSelectedWorkshop(row);
                  }}
                  className="px-1 w-100 d-flex justify-content-center"
                >
                  Workshop Details
                </button>
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  const loadCount = useCallback(() => {
    const conditions = {
      head_quarters_id: userData.head_quarters_id,
    };

    if (search && search !== "") {
      conditions["OR"] = getWorkshopFilters(search);
    }

    dispatch(getHQWorkshopListCount(conditions));
  }, [dispatch, search, userData.head_quarters_id]);

  const loadData = useCallback(
    (page = 1, pageLimit = 10, search) => {
      const conditions = {
        "Workshops.head_quarters_id": userData.head_quarters_id,
        "Workshops.status": 1,
      };

      if (search && search !== "") {
        conditions["OR"] = getWorkshopFilters(search);
      }

      dispatch(
        getHQWorkshopList({
          get: "all",
          conditions,
          contain: {
            // WorkshopSubscription: {
            //   fields: ["id", "plan_name", "plan_expiry_date"],
            //   order: [
            //     "DESC"
            //   ],
            //   limit: 1
            // },
            Countries: {
              fields: ["id", "country_name"],
            },
            States: {
              fields: ["id", "name"],
            },
            AccountingPackages: {
              fields: ["id", "name"],
            },
          },
          // fields: [
          //   "Workshops.id",
          //   "Workshops.created_on",
          //   "Workshops.name",
          //   "Workshops.phone",
          //   "Workshops.trial_expiry_date",
          //   "Workshops.url",
          // ],
          page: page,
          limit: pageLimit,
        })
      );
    },
    [dispatch, userData.head_quarters_id]
  );

  useEffect(() => {
    loadData(currentPage, sizePerPage, search);
  }, [loadData, currentPage, sizePerPage, search]);

  useEffect(() => {
    loadCount();
  }, [loadCount]);

  return (
    <div className="right-section-wrapper">
      {(loading || loadingTotalCount) && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
      <div className="table-header">
        <div className="row top-block">
          <div className="col-md-2">
            <div className="table-title">
              <i className="fa fa-building text-center text-primary font-size-30 m-r-10"></i>
              <span>STORES</span>
            </div>
          </div>
          <div className="col-md-2">
            <div className="video-link-outer">
              <div className="video-tutorial">
                <i className="fa fa-icon"></i>
                {/* <span className="help-text">Help</span> */}
                <Link to="/trainee-video#stores">
                  <span className="tutorial-title">Stores Videos</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div className="table-search-wrapper">
            <input
              type="text"
              className="form-control"
              id="search"
              placeholder="Search..."
              onChange={handleSearch}
              value={search}
            />
          </div>

          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: currentPage,
              sizePerPage: sizePerPage,
              totalSize: parseInt(totalCount),
              nextPageText: "Next",
              prePageText: "Previous",
              // sizePerPageList: [
              //   {
              //     text: "5",
              //     value: 5,
              //   },
              //   {
              //     text: "10",
              //     value: 10,
              //   },
              // ],
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable
                  remote
                  keyField="id"
                  data={workshops}
                  columns={columns}
                  onTableChange={onTableChange}
                  // selectRow={checkBox}
                  noDataIndication={emptyDataMessage}
                  {...paginationTableProps}
                />
                <div>
                  <div className="table-pagination">
                    <div>
                      <span>Show</span>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <span>entries</span>
                    </div>
                    <PaginationTotalStandalone {...paginationProps} />
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </div>
            )}
          </PaginationProvider>
        </div>
      </div>
      <WorkshopDetailModal
        onClose={handleWorkshopDetailModalClose}
        workshop={selectedWorkshop}
        open={typeof selectedWorkshop !== "undefined"}
      />
      <ConfirmModal
        clickedWorkshop={selectedWorkshopForSignin}
        handleClose={() => setSelectedWorkshopForSignin(undefined)}
      />
    </div>
  );
}
