import React from 'react'
import { cardsStyle, activeCard, bankCard } from './styles.module.scss';
const Cards = ({ cards, activeSelection, onClick }) => {
    return (
        <div className={cardsStyle}>
            {cards.map((card, index) => (
                <div onClick={() => {
                    if (onClick) {
                        onClick(card.value,index,card.dbValue,card.processing_fees)
                    }
                }} className={activeSelection === card.value ? activeCard : ''} key={card.value}>
                    {card.dbValue === 11 ? (
                        <img className={bankCard} src={card.img} alt={card.value} />
                    ) : (
                        <img src={card.img} alt={card.value} />
                    )}
                </div>
            ))}
        </div>
    )
}

export default Cards