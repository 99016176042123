import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { GET_PART_CATEGORY_LIST , GET_PART_LIST, GET_PART_LIST_COUNT, SEARCH_PART_LIST } from "store/parts/actionTypes";

import Loader from "views/common/UI/Loader";
import { addComma, precise_round } from "utils/numberFunction";
import moment from "moment";
import { clearPartList, getSearchPartListSuccess } from "store/parts/actions";
import Select from "react-select";
import DeleteConfirm from 'utils/deleteConfirm';
import { DELETE_PART } from "helpers/urlHelper";
import { deletePart, editManyPart, editPart } from "helpers/backendHelper";
import { showError, showSuccess } from "utils/alertMessage";

const PartList = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const dateFormat = storedData.date_format;
  const currencySymbol = storedData.currency;

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [partList, setPartList] = useState([]);
  const [search, setSearch] = useState("");
  const [emptyMessage,setEmptyMessage] = useState("No data available in table");

  const [type, setType] = useState('all');
  const [categoryList, setCategoryList] = useState([]);
  const [categoryID, setCategoryID] = useState(0);
  
  const [selectedRow, setSelectedRow] = useState([]);

  // delete popup variable START
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("delete this record?");
  const [deletePartId, setDeletePartId] = useState();
  // const handleDeleteClose = () => setShowDeleteModal(false);
  const handleDeleteShow = () => setShowDeleteModal(true);

  function handleDeleteClose(){
    setShowDeleteModal(false);
    setSelectedRow([]);
  }

  function deletePartRecords(){

    if (selectedRow.length !== 0) {
      setShowDeleteModal(!showDeleteModal)
      setDeleteMessage("Delete this records?")
    } else {
      showError("Please select the Parts");
    }
  }
  
  // delete popup variable END

  useEffect( () => {
    loadPartLists();

    dispatch({
      type: GET_PART_CATEGORY_LIST,
      payload: {
        get : 'all',
      },
    });

  },[]);


  const { partCategoryList, partListData, partSearchList, partListCount, isPartListLoading } = useSelector((state) => state.PartReducer);
  
  const loadPartLists = (page = 1, pageLimit = 10) => {


    var conObj = {
      'Products.workshop_id': workshop_id,
      'Products.is_deleted': '0',
      'Products.status IN': [1,0],
      'Products.product_type': 1,
      'Products.add_from': 1
    }

   if(type === "all"){
      if(categoryID !== 0){
        conObj.product_category_id = categoryID;
      }
    }

    if(type === "favourite"){
      conObj.is_favourite = 1;
    }

    dispatch({
      type: GET_PART_LIST,
      payload: {
        get : 'all',
        conditions: conObj,
        contain: {
            'Suppliers': {
              "fields": [
                  'Suppliers.id','Suppliers.workshop_id','Suppliers.name'
              ]
            }, 
            'ProductCategory': {
              "fields": [
                'ProductCategory.id','ProductCategory.name'
              ]
            },
            'Workshops.Countries':{
                "conditions": {
                  'Countries.status': '1'
                }
            }
        },
        'order': {
          'Products.id': 'desc'
        },
        "fields": [
          'Products.id',
          'Products.workshop_id',
          'Products.product_type',
          'Products.name',
          'Products.description',
          'Products.product_number',
          'Products.cost_price',
          'Products.sell_price',
          'Products.retail_price',
          'Products.cost_markup_price',
          'Products.is_favourite',
          'Products.product_category_id'
        ],
        limit: pageLimit,
        page: page,
      },
    });
   
    dispatch({
      type: GET_PART_LIST_COUNT,
      payload: {
        get : 'all',
        conditions: conObj,
        fields: {
          COUNT: "COUNT(Products.id)",
        }
      },
    });

    

  };


  useEffect( () => {


    let categories = [];

   
    categories.push({
      value: 0,
      label: "All"
    })

    if(partCategoryList.code === 200){
     
      partCategoryList.data.ProductCategory.map((category) => (
          categories.push({
              value: category?.id,
              label: category?.name
          })
      ));

      setCategoryList(categories);

    }

     

  },[partCategoryList])


  useEffect(() => {

    if(search !== "") {
      if(partSearchList[0] !== null && typeof partSearchList[0] !== "undefined") {
        const onList = partSearchList[0].slice((currentPage - 1) * sizePerPage, currentPage * sizePerPage);
        setPartList(onList);
        setTotalRecords(partSearchList['final_count']);
       } 
    } else {

      
      if(partListData !== null && typeof partListData !== "undefined" && partListData.length === 0){
        setPartList([]);
        setTotalRecords(0);
      }

      if(partListData !== null && typeof partListData !== "undefined" && partListData.length > 0) {
        setPartList(partListData);
      } 
    }

    setSelectedRow([]);
 
  },[partListData, partSearchList, currentPage, sizePerPage, totalRecords]);


  useEffect(() => {

    
    if (partListCount !== 0) {
      setTotalRecords(partListCount);
    }

  },[partListCount]);
  
  const columns = [
    {
      dataField: "product_number",
      text: "Part No.",
      formatter: (cell,row) => {
        return <span>{cell}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-part/${row.id}`);
        },
      },
    },
    {
      dataField: "name",
      text: "Part Name",
      formatter: (cell,row) => {
        return <span>{cell}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-part/${row.id}`);
        },
      },
    },
    {
      dataField: "Category",
      text: "Category",
      formatter: (cell,row) => {
        return <span>{row?.product_category?.name || "-"}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-part/${row.id}`);
        },
      },
    },
    {
      dataField: "Supplier",
      text: "Supplier",
      formatter: (cell,row) => {
        return <span>{row?.supplier?.name || "-"}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-part/${row.id}`);
        },
      },
    },
    {
      dataField: "cost_price",
      text: "Cost",
      formatter: (cell,row) => {
        return <span>{currencySymbol}{addComma(precise_round(cell))}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-part/${row.id}`);
        },
      },
    },
    {
      dataField: "cost_markup_price",
      text: "Margin",
      formatter: (cell,row) => {
        return <span>{addComma(precise_round(cell))}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-part/${row.id}`);
        },
      },
    },
    {
      dataField: "retail_price",
      text: "Retail",
      formatter: (cell,row) => {
        return <span>{currencySymbol}{addComma(precise_round(cell))}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-part/${row.id}`);
        },
      },
    },
    {
      dataField: "is_favourite",
      text: "Favourites",
      formatter: (cell,row,rowIndex) => {
        return <i className={"fa fa-star " + ( cell === '1' ? "checked" : "" )} onClick={ (e) => partFavorite(row.id,cell,rowIndex) } aria-hidden="true"></i>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit-part/${row.id}`);
        },
      },
    },
    {
      dataField: "more option",
      text: "",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <Link to={"/edit-part/"+row.id} className="" title="EDIT">
                  <i className="fa fa-pencil text-info"></i>
                  <span>Edit</span>
                </Link>
              </li>
              <li 
                onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                  setSelectedRow(row.id);
                  setDeleteMessage("Delete this record?")
                }}
              >
                <a href="#" className="delete-link" title="DELETE">
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </a>
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  const selectRow = {
    mode: "checkbox",
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect){
          if(rows.length !== 0){
              let rowPoRecSelectedAll = [...selectedRow];
              rows.map((row) => {
                  rowPoRecSelectedAll.push(row.id);
              })
              setSelectedRow(rowPoRecSelectedAll);
          }
      }else{
        setSelectedRow([]);
      }
    }, 
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row.id]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.indexOf(row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          checked={rest.checked}
          name={rest.name}
          id={rest.id}
          type="checkbox"
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          id={rest.id}
          checked={rest.checked}
          type={mode}
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
  };

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true,
  //   clickToExpand: true,
  // };

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);
    return responseDate;
  };

  const emptyDataMessage = () => {
    return (
      <div className= {(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  const onTableChange = (type, { page, sizePerPage }) => {

    setCurrentPage(page);
    setSizePerPage(sizePerPage);

    if (search === "") {
      // setIsLoading(true);
      loadPartLists(page, sizePerPage);
    } else {
      
      
    }
  };


  const handleSearch = (e) => {
    setSearch(e.target.value);
    setEmptyMessage("No Results Found")

    if (e.target.value !== "") {

      // setIsLoading(true);
      setCurrentPage(1);

      dispatch({
        type: SEARCH_PART_LIST,
        payload: {
          workshop_id: workshop_id,
          part_type: 1,
          type:type,
          keyword: e.target.value,
          categoryID: categoryID
        },
      });
    } else {

      dispatch(getSearchPartListSuccess({}));
      // setJobData(getJobData.jobData);
      loadPartLists();
      setTotalRecords(partListCount);
    }
  };

  
  useEffect(() => {
    setSearch("");
    // dispatch(clearPartList([]));
    loadPartLists();
  },[type, categoryID]);

  // part set as favorite
  async function partFavorite(part_id,is_favourite,rowIndex){

    if(type === 'favourite') {
      setIsLoading(true);
    }

    const newData = [...partList];

    let new_is_favourite = '1';
    if(is_favourite === '1'){
      new_is_favourite = '0';
    }

    newData[rowIndex]["is_favourite"] = new_is_favourite;


    setPartList(newData);

    let data = {
      payload: {
        is_favourite:new_is_favourite,
        id: part_id,
      },
    };

    let response = await editPart(data);
    if (response.code === 200) {
      setIsLoading(false);
      if(new_is_favourite === '0'){
        showSuccess("Part is removed from favorite.");
      } else {
        showSuccess("Part is marked as a favorite.");
      }
      if(type === 'favourite') {
        loadPartLists();
      }
    } else {
      setIsLoading(false);
      showError(response.message);
    }

  }

  const deleteRecord = async () => {
        
    if (selectedRow.length !== 0) {
      let data = {
        payload: {
          action_type: "delete",
          ids: selectedRow,
        },
      };

      let response = await editManyPart(data);
      if (response.code === 200) {
        showSuccess("Part Deleted Sucessfully");
        loadPartLists();
      } else {
        showError(response.message);
      }
      setShowDeleteModal(false);
    } else {
      showError("Please select the Parts.");
    }
    
  }

  const archiveRecord = async () => {
    if (selectedRow.length !== 0) {
      let data = {
        payload: {
          action_type: "archive",
          ids: selectedRow,
        },
      };
      let response = await editManyPart(data);
      if (response.code === 200) {
        showSuccess("Job Archive Sucessfully");
        loadPartLists();
      } else {
        showError(response.message);
      }
    } else {
      showError("Please select the Parts");
    }
  };
  
  // if(isPartListLoading){
  //   return  <Loader />
  // }

  // if(isLoading){
  //   return  <Loader />
  // }

  return (
    <>
      <div className="d-flex align-center justify-space-between mb-2 part-category">
        <div className="radio-wrapper align-center">

          
          <Form.Check
            className="custom-check radio-btn secondary-radio mb-2"
            type="radio"
            id="1"
          >
            
            <Form.Check.Input name="type" type="radio" checked={type === 'all'} onChange={ (e) => setType("all") } isValid value="0-5" />
            <Form.Check.Label className="d-flex align-center">
              <span className="m-r-10">Category</span>
              <span className="check"></span>

             
             
            </Form.Check.Label>
          </Form.Check>

          <div className={"select-wrapper form-group m-r-10 " + (type === 'favourite' ? 'select2-disable' : '' )}>
            <Form.Group className="form-group mx-0">
                <Select
                className="form-control"
                defaultValue = {
                  categoryList.filter(option => 
                      option.value === categoryID)
                }
                onChange={(e) => setCategoryID(e.value) }
                options={categoryList}
              />
            </Form.Group>
          </div>
                

          <Form.Check
            className="custom-check radio-btn secondary-radio mb-2"
            type="radio"
            id="2"
          >
            <Form.Check.Input name="type" type="radio" isValid checked={type === 'favourite'} onChange={ (e) => setType("favourite") } />
            <Form.Check.Label>
              Favourite
              <span className="check"></span>
            </Form.Check.Label>
          </Form.Check>
        </div>
        <div className="table-search-wrapper ">
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Search..."
            onChange={(e) => handleSearch(e)}
            value={search}
          />
          <Button
            className="btn-primary rounded-btn"
            onClick={() => navigate("/create-part")}
          >
            <i className="fa fa-plus"></i> Create Part
          </Button>
        </div>
      </div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page: currentPage,
          sizePerPage: sizePerPage,
          totalSize: parseInt(totalRecords),
          nextPageText: "Next",
          prePageText: "Previous",
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div className="table-wrapper">
            <BootstrapTable
              remote
              keyField="id"
              data={partList}
              columns={columns}
              selectRow={selectRow}
              onTableChange={onTableChange}
              noDataIndication={emptyDataMessage}
              {...paginationTableProps}
            />
            <div>
              <div className="table-pagination">
                <div>
                  <span>Show</span>
                  <SizePerPageDropdownStandalone {...paginationProps} />
                  <span>entries</span>
                </div>
                <PaginationTotalStandalone {...paginationProps} />
                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
          </div>
        )}
      </PaginationProvider>

      <div className="d-flex custom-table-footer align-center">
          <Button
            variant="warning"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => archiveRecord()}
          >
            <i className="la la-archive font-size-18"></i> Archive
          </Button>
          <Button
            onClick={() => {
              deletePartRecords();
              
            }}
            variant="danger"
            className="rounded-btn d-flex align-center mr-10"
          >
            <i className="la la-times-circle font-size-18"></i> Delete
          </Button>
          
        </div>


      {showDeleteModal && (
          <DeleteConfirm
              handleDeleteClose={handleDeleteClose}
              deleteRecord={deleteRecord}
              msg={deleteMessage}
          />
      )}
    </>
  );
};

export default PartList;
