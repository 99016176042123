import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_FAIL,
  EDIT_SUBSCRIPTION_PLANS,
  EDIT_SUBSCRIPTION_PLANS_SUCCESS,
  EDIT_SUBSCRIPTION_PLANS_FAIL,
  DO_SUBSCRIPTION_CHECKOUT,
  DO_SUBSCRIPTION_CHECKOUT_SUCCESS,
  DO_SUBSCRIPTION_CHECKOUT_FAIL,
  CAPRICORN_SUBSCRIPTION_PLANS,
  CAPRICORN_SUBSCRIPTION_PLANS_SUCCESS,
  CAPRICORN_SUBSCRIPTION_PLANS_FAIL,
} from "./actionTypes";

export const getSubscription = () => {
  return {
    type: GET_SUBSCRIPTION,
  };
};

export const getSubscriptionSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_FAIL,
    payload: error,
  };
};

export const getWorkShopSmsSubscriptionDetailsList = () => {
  return {
    type: GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST,
  };
};

export const getWorkShopSmsSubscriptionDetailsListSuccess = (data) => {
  return {
    type: GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS,
    payload: data,
  };
};

export const getWorkShopSmsSubscriptionDetailsListFail = (error) => {
  return {
    type: GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL,
    payload: error,
  };
};

export const getSubscriptionPlans = (data) => {
  return {
    type: GET_SUBSCRIPTION_PLANS,
    payload: data
  };
};

export const getSubscriptionPlansSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_PLANS_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionPlansFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_PLANS_FAIL,
    payload: error,
  };
};

export const editSubscriptionPlans = (data) => {
  return {
    type: EDIT_SUBSCRIPTION_PLANS,
    payload: data
  };
};

export const editSubscriptionPlansSuccess = (data) => {
  return {
    type: EDIT_SUBSCRIPTION_PLANS_SUCCESS,
    payload: data,
  };
};

export const editSubscriptionPlansFail = (error) => {
  return {
    type: EDIT_SUBSCRIPTION_PLANS_FAIL,
    payload: error,
  };
};

export const doSubscriptionCheckout = () => {
  return {
    type: DO_SUBSCRIPTION_CHECKOUT,
  };
};

export const doSubscriptionCheckoutSuccess = (data) => {
  return {
    type: DO_SUBSCRIPTION_CHECKOUT_SUCCESS,
    payload: data,
  };
};

export const doSubscriptionCheckoutFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_PLANS_FAIL,
    payload: error,
  };
};

export const capricornSubscriptionPlans = (data) => {
  return {
    type: CAPRICORN_SUBSCRIPTION_PLANS,
    payload: data
  };
};

export const capricornSubscriptionPlansSuccess = (data) => {
  return {
    type: CAPRICORN_SUBSCRIPTION_PLANS_SUCCESS,
    payload: data,
  };
};

export const capricornSubscriptionPlansFail = (error) => {
  return {
    type: CAPRICORN_SUBSCRIPTION_PLANS_FAIL,
    payload: error,
  };
};