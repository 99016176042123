import {
  GET_EMPLOYEE_CLOCKING_HISTORY,
  GET_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
  GET_EMPLOYEE_CLOCKING_HISTORY_FAIL,
  GET_EMPLOYEE_WISE_CLOCKING_HISTORY,
  GET_EMPLOYEE_WISE_CLOCKING_HISTORY_SUCCESS,
  GET_EMPLOYEE_WISE_CLOCKING_HISTORY_FAIL
  // ADD_EMPLOYEE_CLOCKING_HISTORY,
  // ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
  // ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL,
  // UPDATE_EMPLOYEE_CLOCKING_HISTORY,
  // UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
  // UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL,
} from "./actionTypes";

export const getEmployeeClockingHistory = () => {
  return {
    type: GET_EMPLOYEE_CLOCKING_HISTORY,
  };
};

export const getEmployeeClockingHistorySuccess = (data) => {
  return {
    type: GET_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getEmployeeClockingHistoryFail = (error) => {
  return {
    type: GET_EMPLOYEE_CLOCKING_HISTORY_FAIL,
    payload: error,
  };
};

export const getEmployeeWiseClockingHistory = () => {
  return {
    type: GET_EMPLOYEE_WISE_CLOCKING_HISTORY,
  };
};

export const getEmployeeWiseClockingHistorySuccess = (data) => {
  return {
    type: GET_EMPLOYEE_WISE_CLOCKING_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getEmployeeWiseClockingHistoryFail = (error) => {
  return {
    type: GET_EMPLOYEE_WISE_CLOCKING_HISTORY_FAIL,
    payload: error,
  };
};

// export const addEmployeeClockingHistory = () => {
//   return {
//     type: ADD_EMPLOYEE_CLOCKING_HISTORY,
//   };
// };

// export const addEmployeeClockingHistorySuccess = (data) => {
//   return {
//     type: ADD_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
//     payload: data,
//   };
// };

// export const addEmployeeClockingHistoryFail = (error) => {
//   return {
//     type: ADD_EMPLOYEE_CLOCKING_HISTORY_FAIL,
//     payload: error,
//   };
// };

// export const updateEmployeeClockingHistory = () => {
//   return {
//     type: UPDATE_EMPLOYEE_CLOCKING_HISTORY,
//   };
// };

// export const updateEmployeeClockingHistorySuccess = (data) => {
//   return {
//     type: UPDATE_EMPLOYEE_CLOCKING_HISTORY_SUCCESS,
//     payload: data,
//   };
// };

// export const updateEmployeeClockingHistoryFail = (error) => {
//   return {
//     type: UPDATE_EMPLOYEE_CLOCKING_HISTORY_FAIL,
//     payload: error,
//   };
// };