import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrency } from 'utils/getCurrency';
import { utcToTimezone } from 'utils/timeZoneConvert';
import { usePaymentInfo } from './PaymentInfoProvider';
import { Row } from './PaymentModal';
import { p_15, summary } from './styles.module.scss';
const Summary = ({ selectedCard, checked , selectedPaymentFees }) => {
    const payinfo = usePaymentInfo();
    let payment_amount = payinfo?.payment_amount;
    let total_due = payinfo?.total_due;
    let remain_balance = payinfo?.remain_balance;
    let remaining_due = payinfo?.remaining_due;
    let paid_to_date = payinfo?.paid_to_date;
    let receipt_total = payinfo?.request?.receipt_total;
    let paymentAmount = payinfo?.paymentAmount;
    let processing_fees = payinfo?.request?.payment_processing_fee;
    let processingFeesValue = 0;

    if (payinfo?.tax_invoice === "4") {
        if (payinfo?.insurance_preview_invoice === 1) {
            payment_amount = payinfo?.insurance_invoice_total;
            remain_balance = payinfo?.insurance_invoice_total;
            total_due = payinfo?.insurance_invoice_total;
            receipt_total = payinfo?.insurance_invoice_total;
            paymentAmount = payinfo?.insurance_invoice_total;
        }
        if (payinfo?.customer_preview_invoice === 1) {
            payment_amount = payinfo?.customer_invoice_total;
            remain_balance = payinfo?.customer_invoice_total;
            total_due = payinfo?.customer_invoice_total;
            receipt_total = payinfo?.customer_invoice_total;
            paymentAmount = payinfo?.customer_invoice_total;
        }
        if (payinfo?.customer_invoice_allocation_preview === 1) {
            payment_amount = payinfo?.customer_invoice_allocation_total;
            remain_balance = payinfo?.customer_invoice_allocation_total;
            total_due = payinfo?.customer_invoice_allocation_total;
            receipt_total = payinfo?.customer_invoice_allocation_total;
            paymentAmount = payinfo?.customer_invoice_allocation_total;
        }
    }

    const invoice = useSelector(state => state.invoice);

    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let timezoneLable = window.atob(storedData.timezone_lable);

    let invoice_number = payinfo?.invoiceData?.invoice_number;
    const payment_history = invoice?.invoicePayments?.list.filter((item) => item.invoice_number === invoice_number);

    let processingFeeText = "(EFTPOS 0.00%)";

    if (checked === true) {
        // if (selectedCard === "Other") {
            processingFeeText = "(EFTPOS "+processing_fees+"%)";
            processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(processing_fees))/100).toFixed(2);
        // }
    } else {
        if (selectedCard === "Master Card") {
            processingFeeText = "(MC "+processing_fees+"%)";
            processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(processing_fees))/100).toFixed(2);
        } else if (selectedCard === "Visa Card") {
            processingFeeText = "(Visa "+processing_fees+"%)";
            processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(processing_fees))/100).toFixed(2);
        } else if (selectedCard === "Amex Card") {
            processingFeeText = "(Amex "+processing_fees+"%)";
            processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(processing_fees))/100).toFixed(2);
        } else if (selectedCard === "Other") {
            processingFeeText = "(Bank Transfer 0.00%)";
            processingFeesValue = 0;
        }
    }

    if(selectedCard == "Afterpay"){
        if(processing_fees == "0.00" || processing_fees == "0"){
            processingFeeText = "(Afterpay "+selectedPaymentFees+"%)";
            processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(selectedPaymentFees))/100).toFixed(2);
        }else{
            processingFeeText = "(Afterpay "+processing_fees+"%)";
            processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(processing_fees))/100).toFixed(2);
        }
        // processingFeeText = "(Afterpay "+processing_fees+"%)";
        // processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(processing_fees))/100);
    }else if(selectedCard == "Humm"){
        processingFeeText = "(Humm "+processing_fees+"%)";
        processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(processing_fees))/100).toFixed(2);
    }else if(selectedCard == "Zip"){
        processingFeeText = "(Zip "+processing_fees+"%)";
        processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(processing_fees))/100).toFixed(2);
    }else if(selectedCard == "Others") {
        processingFeeText = "(Others "+processing_fees+"%)";
        processingFeesValue = parseFloat((parseFloat(paymentAmount) * parseFloat(processing_fees))/100).toFixed(2);
    }

    return (
        <div className={`col-6 ${summary}`} style={{ borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }}>
            <div className={`${p_15}`} style={{
                borderBottom: '1px solid #dee2e6'
            }}>
                <div className={`${p_15} pb-0`}>
                    <Row label="Total Due" value={total_due} />
                </div>
                <div className={p_15}>
                    <Row label="Paid To Date" value={payinfo.paid_to_date} />
                    <Row label="Payment Amount" value={parseFloat(paymentAmount || 0).toFixed(2)} />
                </div>
                <div className={p_15} style={{
                    borderBottom: '1px solid #dee2e6'
                }}>
                    <Row red label="Remaining Balance Payment" value={parseFloat(payinfo?.request?.balance_remaining || 0).toFixed(2)} />
                    <Row label={"Payment Processing Fee <br/>" + processingFeeText} value={processingFeesValue} />
                </div>
                <div className={`${p_15} pb-0`}>
                    <Row label="Total" value={isNaN(receipt_total) ? "0.00" : parseFloat(receipt_total).toFixed(2)} />
                </div>
            </div>
            <div style={{ padding: 30 }}>
                <p style={{ margin: '15px 0' }}>Payments</p>
                {payment_history && payment_history?.length !== 0 ? <>
                    {payment_history?.map((c, i) => (
                        <div key={i}>
                            <div className="d-flex justify-content-between">
                                {/* <span>{moment(c.created_on).format("dddd DD-MM-YYYY HH:MM:ss")}</span> */}
                                <span>{utcToTimezone(c.created_on, timezoneLable, "", "dddd DD-MM-YYYY")}</span>
                                <span>{c.payment_method === "1" ? "Cash" :
                                    c.payment_method === "4" ? "BNPL" :
                                        c.payment_method === "5" ? "Cheque" :
                                            "Card"}
                                </span>
                                <span>{getCurrency + c.amount_paid}</span>
                            </div>
                            <p>{c.type}</p>
                        </div>
                    ))}
                </> : <p>No Transaction Yet</p>}
            </div>
        </div>
    )
}

export default Summary