export const HQ_LOCAL_STORAGE_BACKUP_KEY = "__backup_hq_data"

/**
 * This function gets all the keys from `localStorage` and return them in an `object`. This function is useful to take backup of `localStorage`.
 * @returns Object
 */
export function dumpLocalStorage() {
    const backup = {}
    for (const key of Object.keys(localStorage)) {
        backup[key] = localStorage.getItem(key)
    }
    return backup
}

/**
 * This function set key-values provided in an object in localStorage.
 * @param {*} dump An key-value pair, preferably an object dumped using `dumpLocalStorage` function.
 */
export function restoreLocalStorage(dump) {
    if (typeof dump === "object" && !Array.isArray(dump) && dump !== null) {
        for (const [key, value] of Object.entries(dump)) {
            localStorage.setItem(key, value)
        }
    } else {
        throw new Error("Dump must be an object for restore process.")
    }
}

/**
 * This function removes all the keys from localStorage unless `preserveBackupKeys` parameter is `true` which will still remove all keys but any key which starts with `__backup` will be preserved.
 * @param {*} preserveBackupKeys `true` if you want to preserve backup key.
 */
export function destroyLocalStorage(preserveBackupKeys = false) {
    if (preserveBackupKeys) {
        for (const key of Object.keys(localStorage)) {
            if (!key.startsWith("__backup")) {
                localStorage.removeItem(key)
            }
        }
    } else {
        localStorage.clear()
    }
}