import {
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_FAIL,
  GET_SECONDARY_CUSTOMER_LIST,
  GET_SECONDARY_CUSTOMER_LIST_SUCCESS,
  GET_SECONDARY_CUSTOMER_LIST_FAIL,
  GET_CUSTOMER_COUNT,
  GET_CUSTOMER_COUNT_SUCCESS,
  GET_CUSTOMER_COUNT_FAIL,
  GET_SUPPILERS_LIST,
  GET_SUPPILERS_LIST_SUCCESS,
  GET_SUPPILERS_LIST_FAIL,
  GET_SUPPILERS_COUNT,
  GET_SUPPILERS_COUNT_SUCCESS,
  GET_SUPPILERS_COUNT_FAIL,
  GET_EMPLOYEE_LIST,
  GET_EMPLOYEE_LIST_SUCCESS,
  GET_EMPLOYEE_LIST_FAIL,
  GET_SUPPILERS_BRANCH,
  GET_SUPPILERS_BRANCH_SUCCESS,
  GET_SUPPILERS_BRANCH_FAIL,
  GET_ONSITE_STATE,
  GET_ONSITE_STATE_SUCCESS,
  GET_ONSITE_STATE_FAIL,
  GET_CUSTOMER_DETAIL,
  GET_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMER_DETAIL_FAIL,
  GET_CUSTOMER_VEHICLE,
  GET_CUSTOMER_VEHICLE_SUCCESS,
  GET_CUSTOMER_VEHICLE_FAIL,
  GET_CUSTOMER_NOTES,
  GET_CUSTOMER_NOTES_SUCCESS,
  GET_CUSTOMER_NOTES_FAIL,
  GET_CUSTOMER_ATTACHMENT,
  GET_CUSTOMER_ATTACHMENT_SUCCESS,
  GET_CUSTOMER_ATTACHMENT_FAIL,
  CHECK_SUPPILER_EMAIL,
  CHECK_SUPPILER_EMAIL_SUCCESS,
  CHECK_SUPPILER_EMAIL_FAIL,
  CHECK_SUPPILER_MOBILE,
  CHECK_SUPPILER_MOBILE_SUCCESS,
  CHECK_SUPPILER_MOBILE_FAIL,
  GET_SUPPILERS,
  GET_SUPPILERS_SUCCESS,
  GET_SUPPILERS_FAIL,
  CHECK_EMPLOYEE_EMAIL,
  CHECK_EMPLOYEE_EMAIL_SUCCESS,
  CHECK_EMPLOYEE_EMAIL_FAIL,
  CHECK_EMPLOYEE_MOBILE,
  CHECK_EMPLOYEE_MOBILE_SUCCESS,
  CHECK_EMPLOYEE_MOBILE_FAIL,
  GET_EMPLOYEE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  GET_EMPLOYEE_NOTES,
  GET_EMPLOYEE_NOTES_SUCCESS,
  GET_EMPLOYEE_NOTES_FAIL,
  GET_CUSTOMER_HISTORY,
  GET_CUSTOMER_HISTORY_SUCCESS,
  GET_CUSTOMER_HISTORY_FAIL,
  GET_POS_HISTORY,
  GET_POS_HISTORY_SUCCESS,
  GET_POS_HISTORY_FAIL,
  CHECK_BRANCH_EMAIL,
  CHECK_BRANCH_EMAIL_SUCCESS,
  CHECK_BRANCH_EMAIL_FAIL,
  CHECK_BRANCH_MOBILE,
  CHECK_BRANCH_MOBILE_SUCCESS,
  CHECK_BRANCH_MOBILE_FAIL,
  VIEW_SUPPILERS_BRANCH,
  VIEW_SUPPILERS_BRANCH_SUCCESS,
  VIEW_SUPPILERS_BRANCH_FAIL,
  SUPPLIER_SEARCH,
  SUPPLIER_SEARCH_SUCCESS,
  SUPPLIER_SEARCH_FAIL,
  CUSTOMER_SEARCH,
  CUSTOMER_SEARCH_SUCCESS,
  CUSTOMER_SEARCH_FAIL,
  HQ_GET_SUPPILERS_LIST,
  HQ_GET_SUPPILERS_LIST_SUCCESS,
  HQ_GET_SUPPILERS_LIST_FAIL,
  HQ_GET_SUPPILERS_COUNT,
  HQ_GET_SUPPILERS_COUNT_SUCCESS,
  HQ_GET_SUPPILERS_COUNT_FAIL,
  HQ_GET_SUPPLIER,
  HQ_GET_SUPPLIER_SUCCESS,
  HQ_GET_SUPPLIER_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  notesLoading: false,
  message: "",
  error: "",
  customerList: [],
  secondCustomerList: [],
  customerCount: 0,
  supplierList: [],
  hqSupplierList: [],
  supplierCount: 0,
  hqSupplierCount: 0,
  employeeList: [],
  supplierBranch: [],
  onSiteState: [],
  customerDetail: [],
  customerVehicle: [],
  customrtHistory: [],
  posHistory: [],
  notes: [],
  attachment: [],
  suppilerEmailExist: false,
  suppilerMobilelExist: false,
  suppilers: [],
  hqSingleSupplier: undefined,
  employeeEmailExist: false,
  employeeMobilelExist: false,
  employee: {},
  employeeNotes: [],
  branchEmailExist: false,
  branchMobilelExist: false,
  viewSupplierBranch: [],
  customerSearch: {},
  supplierSearch: {},
};

const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMER_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        customerList: action.payload,
      };
      break;
    case GET_CUSTOMER_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        customerList: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_SECONDARY_CUSTOMER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SECONDARY_CUSTOMER_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        secondCustomerList: action.payload,
      };
      break;
    case GET_SECONDARY_CUSTOMER_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        secondCustomerList: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_CUSTOMER_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMER_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        customerCount: action.payload,
      };
      break;
    case GET_CUSTOMER_COUNT_FAIL:
      state = {
        ...state,
        loading: false,
        customerCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_SUPPILERS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SUPPILERS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        supplierList: action.payload,
      };
      break;
    case GET_SUPPILERS_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        supplierList: [],
        error: {
          message: action.payload,
        },
      };
      break;
    case HQ_GET_SUPPILERS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case HQ_GET_SUPPILERS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        hqSupplierList: action.payload,
      };
      break;
    case HQ_GET_SUPPILERS_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        hqSupplierList: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_SUPPILERS_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SUPPILERS_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        supplierCount: action.payload,
      };
      break;
    case GET_SUPPILERS_COUNT_FAIL:
      state = {
        ...state,
        loading: false,
        supplierCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;
    case HQ_GET_SUPPILERS_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case HQ_GET_SUPPILERS_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        hqSupplierCount: action.payload,
      };
      break;
    case HQ_GET_SUPPILERS_COUNT_FAIL:
      state = {
        ...state,
        loading: false,
        hqSupplierCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_EMPLOYEE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_EMPLOYEE_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        employeeList: action.payload,
      };
      break;
    case GET_EMPLOYEE_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        employeeList: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_SUPPILERS_BRANCH:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SUPPILERS_BRANCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        supplierBranch: action.payload,
      };
      break;
    case GET_SUPPILERS_BRANCH_FAIL:
      state = {
        ...state,
        loading: false,
        supplierBranch: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_ONSITE_STATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ONSITE_STATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        onSiteState: action.payload,
      };
      break;
    case GET_ONSITE_STATE_FAIL:
      state = {
        ...state,
        loading: false,
        onSiteState: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_CUSTOMER_DETAIL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMER_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        customerDetail: action.payload,
      };
      break;
    case GET_CUSTOMER_DETAIL_FAIL:
      state = {
        ...state,
        loading: false,
        customerDetail: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_CUSTOMER_VEHICLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMER_VEHICLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        customerVehicle: action.payload,
      };
      break;
    case GET_CUSTOMER_VEHICLE_FAIL:
      state = {
        ...state,
        loading: false,
        customerVehicle: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_CUSTOMER_NOTES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMER_NOTES_SUCCESS:
      state = {
        ...state,
        loading: false,
        notes: action.payload,
      };
      break;
    case GET_CUSTOMER_NOTES_FAIL:
      state = {
        ...state,
        loading: false,
        notes: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_CUSTOMER_ATTACHMENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMER_ATTACHMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        attachment: action.payload,
      };
      break;
    case GET_CUSTOMER_ATTACHMENT_FAIL:
      state = {
        ...state,
        loading: false,
        attachment: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case CHECK_SUPPILER_EMAIL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_SUPPILER_EMAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        suppilerEmailExist: action.payload,
      };
      break;
    case CHECK_SUPPILER_EMAIL_FAIL:
      state = {
        ...state,
        loading: false,
        suppilerEmailExist: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case CHECK_SUPPILER_MOBILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_SUPPILER_MOBILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        suppilerMobilelExist: action.payload,
      };
      break;
    case CHECK_SUPPILER_MOBILE_FAIL:
      state = {
        ...state,
        loading: false,
        suppilerMobilelExist: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_SUPPILERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SUPPILERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        suppilers: action.payload,
      };
      break;
    case GET_SUPPILERS_FAIL:
      state = {
        ...state,
        loading: false,
        suppilers: [],
        error: {
          message: action.payload,
        },
      };
      break;
    case HQ_GET_SUPPLIER:
      state = {
        ...state,
        loading: true,
        hqSingleSupplier: undefined
      };
      break;
    case HQ_GET_SUPPLIER_SUCCESS:
      state = {
        ...state,
        loading: false,
        hqSingleSupplier: action.payload,
      };
      break;
    case HQ_GET_SUPPLIER_FAIL:
      state = {
        ...state,
        loading: false,
        hqSingleSupplier: undefined,
        error: {
          message: action.payload,
        },
      };
      break;

    case CHECK_EMPLOYEE_EMAIL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_EMPLOYEE_EMAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        employeeEmailExist: action.payload,
      };
      break;
    case CHECK_EMPLOYEE_EMAIL_FAIL:
      state = {
        ...state,
        loading: false,
        employeeEmailExist: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case CHECK_EMPLOYEE_MOBILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_EMPLOYEE_MOBILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        employeeMobilelExist: action.payload,
      };
      break;
    case CHECK_EMPLOYEE_MOBILE_FAIL:
      state = {
        ...state,
        loading: false,
        employeeMobilelExist: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_EMPLOYEE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_EMPLOYEE_SUCCESS:
      state = {
        ...state,
        loading: false,
        employee: action.payload,
      };
      break;
    case GET_EMPLOYEE_FAIL:
      state = {
        ...state,
        loading: false,
        employee: {},
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_EMPLOYEE_NOTES:
      state = {
        ...state,
        notesLoading: true,
      };
      break;
    case GET_EMPLOYEE_NOTES_SUCCESS:
      state = {
        ...state,
        notesLoading: false,
        employeeNotes: action.payload,
      };
      break;
    case GET_EMPLOYEE_NOTES_FAIL:
      state = {
        ...state,
        notesLoading: false,
        employeeNotes: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_CUSTOMER_HISTORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMER_HISTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        customrtHistory: action.payload,
      };
      break;
    case GET_CUSTOMER_HISTORY_FAIL:
      state = {
        ...state,
        loading: false,
        customrtHistory: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_POS_HISTORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_POS_HISTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        posHistory: action.payload,
      };
      break;
    case GET_POS_HISTORY_FAIL:
      state = {
        ...state,
        loading: false,
        posHistory: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case CHECK_BRANCH_EMAIL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_BRANCH_EMAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        branchEmailExist: action.payload,
      };
      break;
    case CHECK_BRANCH_EMAIL_FAIL:
      state = {
        ...state,
        loading: false,
        branchEmailExist: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case CHECK_BRANCH_MOBILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_BRANCH_MOBILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        branchMobilelExist: action.payload,
      };
      break;
    case CHECK_BRANCH_MOBILE_FAIL:
      state = {
        ...state,
        loading: false,
        branchMobilelExist: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case VIEW_SUPPILERS_BRANCH:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VIEW_SUPPILERS_BRANCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        viewSupplierBranch: action.payload,
      };
      break;
    case VIEW_SUPPILERS_BRANCH_FAIL:
      state = {
        ...state,
        loading: false,
        viewSupplierBranch: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case CUSTOMER_SEARCH:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CUSTOMER_SEARCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        customerSearch: action.payload,
      };
      break;
    case CUSTOMER_SEARCH_FAIL:
      state = {
        ...state,
        loading: false,
        customerSearch: action.payload,
        error: {
          message: action.payload,
        },
      };
      break;

    case SUPPLIER_SEARCH:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUPPLIER_SEARCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        supplierSearch: action.payload,
      };
      break;
    case SUPPLIER_SEARCH_FAIL:
      state = {
        ...state,
        loading: false,
        supplierSearch: action.payload,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ContactReducer;
