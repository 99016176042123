import {
  SAVE_PART,
  SAVE_PART_SUCCESS,
  SAVE_PART_FAIL,
  EDIT_PART,
  EDIT_PART_SUCCESS,
  EDIT_PART_FAIL,
  SAVE_PART_REACT,
  SAVE_PART_REACT_SUCCESS,
  SAVE_PART_REACT_FAIL,
  PART_DETAIL,
  PART_DETAIL_SUCCESS,
  PART_DETAIL_FAIL,
  EDIT_PART_REACT,
  EDIT_PART_REACT_SUCCESS,
  EDIT_PART_REACT_FAIL,
  PART_VIEW,
  PART_VIEW_SUCCESS,
  PART_VIEW_FAIL,
  GET_PART_LIST,
  GET_PART_LIST_SUCCESS,
  GET_PART_LIST_FAIL,
  GET_PART_LIST_COUNT,
  GET_PART_LIST_COUNT_SUCCESS,
  GET_PART_LIST_COUNT_FAIL,
  SEARCH_PART_LIST,
  SEARCH_PART_LIST_SUCCESS,
  SEARCH_PART_LIST_FAIL,
  GET_PART_CATEGORY_LIST,
  GET_PART_CATEGORY_LIST_SUCCESS,
  GET_PART_CATEGORY_LIST_FAIL,
  GET_KIT_PART_LIST,
  GET_KIT_PART_LIST_SUCCESS,
  GET_KIT_PART_LIST_FAIL,
  GET_KIT_PART_LIST_COUNT,
  GET_KIT_PART_LIST_COUNT_SUCCESS,
  GET_KIT_PART_LIST_COUNT_FAIL,
  SEARCH_KIT_PART_LIST,
  SEARCH_KIT_PART_LIST_SUCCESS,
  SEARCH_KIT_PART_LIST_FAIL,
  DELETE_PART,
  DELETE_PART_SUCCESS,
  DELETE_PART_FAIL,
  DELETE_KIT_PART,
  DELETE_KIT_PART_SUCCESS,
  DELETE_KIT_PART_FAIL,
  ADD_PART_TO_LIST,
  TEST_PART_SUCCESS,
  REMOVE_PART_FROM_LIST,
  CLEAR_PART_LIST,
  UPDATE_PART,
  ADD_LABOUR,
  UPDATE_LABOUR,
  DELETE_LABOUR_FROM_LIST,
  UPDATE_SUMMARY,
  ADD_INITIAL_LABOURS,
  ADD_INITIAL_PARTS,
  RESET_PARTS,
  RESET_LABOURS,
  RESET_SUMMARY,
  UPDATE_PARTS_ASSIGNED,
  UPDATE_PARTS_PO_QTY,
  REMOVE_PART_FROM_KIT_LIST,
  REMOVE_PO_PART_FROM_LIST,
  UPDATE_LABOUR_DATA
} from "./actionTypes";

const initialState = {
  partsData: null,
  error: {
    message: "",
  },
  partListData: [],
  partListCount: 0,
  partSearchList: [],
  partCategoryList: [],
  partsDetails: [],

  partsDetailsNew: null,

  editPartData: [],

  kitPartListData: [],
  kitPartListCount: 0,
  kitPartSearchList: [],

  isLoadData: true,
  deletedPart: null,
  deletedKitPart: null,
  parts: [],
  testPart: "",
  labours: [],
  summary: {
    part_cost: "0.00",
    part_sub_total: "0.00",
    part_margin: "0.00",
    labour_cost: "0.00",
    labour_sub_total: "0.00",
    labour_margin: "0.00",
    labour_estimate_time: "0.00",
    labour_actual_time: "0.00",
    labour_variance_time: "0.00",
    profit: "0.00",
    profit_margin: "0.00",
    sub_total: "0.00",
    discount: "0.00",
    gst: "0.00",
    total: "0.00",
    labour_time: "1",
  },
};

const PartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PART:
      state = {
        ...state,
        partsData: action.partsData,
      };
      break;
    case SAVE_PART_SUCCESS:
      state = {
        ...state,
        partsData: action.payload,
      };
      break;
    case SAVE_PART_FAIL:
      state = {
        ...state,
        partsData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case EDIT_PART:
      state = {
        ...state,
        editPartData: action.payload,
      };
      break;
    case EDIT_PART_SUCCESS:
      state = {
        ...state,
        editPartData: action.payload,
      };
      break;
    case EDIT_PART_FAIL:
      state = {
        ...state,
        editPartData: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case SAVE_PART_REACT:
      state = {
        ...state,
      };
      break;
    case SAVE_PART_REACT_SUCCESS:
      state = {
        ...state,
        partsReactData: action.payload,
      };
      break;
    case SAVE_PART_REACT_FAIL:
      state = {
        ...state,
        partsReactData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case EDIT_PART_REACT:
      state = {
        ...state,
        editPartLoading: true,
      };
      break;
    case EDIT_PART_REACT_SUCCESS:
      state = {
        ...state,
        editPartData: action.payload,
        editPartLoading: false,
      };
      break;
    case EDIT_PART_REACT_FAIL:
      state = {
        ...state,
        editPartData: null,
        editPartLoading: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case PART_DETAIL:
      state = {
        ...state,
        partsDetailsNewLoading: true,
      };
      break;
    case PART_DETAIL_SUCCESS:
      state = {
        ...state,
        partsDetailsNew: action.payload,
        partsDetailsNewLoading: false,
      };
      break;
    case PART_DETAIL_FAIL:
      state = {
        ...state,
        partsDetailsNew: null,
        partsDetailsNewLoading: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case PART_VIEW:
      state = {
        ...state,
      };
      break;
    case PART_VIEW_SUCCESS:
      state = {
        ...state,
        partsDetails: action.payload,
      };
      break;
    case PART_VIEW_FAIL:
      state = {
        ...state,
        partsDetails: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_PART_LIST:
      state = {
        ...state,
        isLoadData: false,
        isPartListLoading: true,
      };
      break;
    case GET_PART_LIST_SUCCESS:
      state = {
        ...state,
        partListData: action.payload,
        isLoadData: true,
        isPartListLoading: false,
      };
      break;
    case GET_PART_LIST_FAIL:
      state = {
        ...state,
        partListData: [],
        isLoadData: true,
        isPartListLoading: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case SEARCH_PART_LIST:
      state = {
        ...state,
        partSearchLoading: true,
      };
      break;
    case SEARCH_PART_LIST_SUCCESS:
      state = {
        ...state,
        partSearchList: action.payload,
        partSearchLoading: false,
      };
      break;
    case SEARCH_PART_LIST_FAIL:
      state = {
        ...state,
        partSearchList: [],
        partSearchLoading: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_PART_LIST_COUNT:
      state = {
        ...state,
      };
      break;
    case GET_PART_LIST_COUNT_SUCCESS:
      state = {
        ...state,
        partListCount: action.payload,
      };
      break;
    case GET_PART_LIST_COUNT_FAIL:
      state = {
        ...state,
        partListCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_KIT_PART_LIST:
      state = {
        ...state,
        isKitPartListLoading: true,
      };
      break;
    case GET_KIT_PART_LIST_SUCCESS:
      state = {
        ...state,
        kitPartListData: action.payload,
        isKitPartListLoading: false,
      };
      break;
    case GET_KIT_PART_LIST_FAIL:
      state = {
        ...state,
        kitPartListData: [],
        isKitPartListLoading: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_KIT_PART_LIST_COUNT:
      state = {
        ...state,
      };
      break;
    case GET_KIT_PART_LIST_COUNT_SUCCESS:
      state = {
        ...state,
        kitPartListCount: action.payload,
      };
      break;
    case GET_KIT_PART_LIST_COUNT_FAIL:
      state = {
        ...state,
        kitPartListCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    case SEARCH_KIT_PART_LIST:
      state = {
        ...state,
        partSearchLoading: true,
      };
      break;
    case SEARCH_KIT_PART_LIST_SUCCESS:
      state = {
        ...state,
        kitPartSearchList: action.payload,
        kitPartSearchLoading: false,
      };
      break;
    case SEARCH_KIT_PART_LIST_FAIL:
      state = {
        ...state,
        kitPartSearchList: [],
        kitPartSearchLoading: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_PART_CATEGORY_LIST:
      state = {
        ...state,
        categoryListLoading: true,
      };
      break;
    case GET_PART_CATEGORY_LIST_SUCCESS:
      state = {
        ...state,
        partCategoryList: action.payload,
        categoryListLoading: false,
      };
      break;
    case GET_PART_CATEGORY_LIST_FAIL:
      state = {
        ...state,
        partCategoryList: [],
        categoryListLoading: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case CLEAR_PART_LIST:
      state = {
        ...state,
        partListData: null,
        isLoadData: true,
      };
      break;
    case DELETE_PART:
      state = {
        ...state,
        deletedPart: action.partsData,
      };
      break;
    case DELETE_PART_SUCCESS:
      state = {
        ...state,
        deletedPart: action.payload,
      };
      break;
    case DELETE_PART_FAIL:
      state = {
        ...state,
        deletedPart: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case DELETE_KIT_PART:
      state = {
        ...state,
        deletedKitPart: action.partsData,
      };
      break;
    case DELETE_KIT_PART_SUCCESS:
      state = {
        ...state,
        deletedKitPart: action.payload,
      };
      break;
    case DELETE_KIT_PART_FAIL:
      state = {
        ...state,
        deletedKitPart: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case ADD_PART_TO_LIST:
      state = {
        ...state,
        parts: [...state.parts, action.payload],
      };
      break;
    case UPDATE_PART: {

      let parts = "";

      if (parseInt(action.payload?.is_misc_part) === 1 || action.payload?.is_misc_part === "1") {


        parts = state.parts.map((c) => {
          if (c.main_id === action.payload.main_id) {
            return action.payload;
          } else {
            return c;
          }
        });

      } else {

        parts = state.parts.map((c) => {

          if (c.main_id) {
            if (c.main_id === action.payload.main_id) {
              return action.payload;
            } else {
              return c;
            }
          } else {
            if (c.id === action.payload.id) {
              return action.payload;
            } else {
              return c;
            }
          }

        });

      }

      state = {
        ...state,
        parts: parts,
      };
      break;
    }
    case REMOVE_PART_FROM_LIST:
      state = {
        ...state,
        parts: state.parts.filter((c) => c.main_id !== action.id),
      };
      break;


    case REMOVE_PART_FROM_KIT_LIST:
      state = {
        ...state,
        parts: state.parts.filter((c) => c.id !== action.id),
      };
      break;

    case REMOVE_PO_PART_FROM_LIST:
      state = {
        ...state,
        parts: state.parts.filter((c) => c.id !== action.id),
      };
      break;


    case TEST_PART_SUCCESS:
      state = {
        ...state,
        testPart: "Hello World",
      };
      break;
    case ADD_LABOUR:
      state = {
        ...state,
        labours: [...state.labours, action.payload],
      };
      break;
    case UPDATE_LABOUR: {
      const labours = state.labours.map((c) => {
        if (c.id === action.payload.id) {
          return action.payload;
        } else {
          return c;
        }
      });

      state = {
        ...state,
        labours,
      };
      break;
    }
    case UPDATE_LABOUR_DATA: {
      const labours = state.labours.map((c) => {
        if (c.id === action.payload.id) {
          const { id, ...data } = action.payload
          return { ...c, ...data };
        } else {
          return c;
        }
      });

      state = {
        ...state,
        labours,
      };
      break;
    }
    case DELETE_LABOUR_FROM_LIST:
      state = {
        ...state,
        labours: state.labours.filter((c) => c.id !== action.id),
      };
      break;
    case UPDATE_SUMMARY:
      state = {
        ...state,
        summary: action.payload,
      };
      break;
    case ADD_INITIAL_LABOURS:
      state = {
        ...state,
        labours: action.payload,
      };
      break;
    case ADD_INITIAL_PARTS:
      state = {
        ...state,
        parts: action.payload,
      };
      break;
    case RESET_PARTS:
      state = {
        ...state,
        parts: [],
      };
      break;
    case RESET_LABOURS:
      state = {
        ...state,
        labours: [],
      };
      break;
    case RESET_SUMMARY:
      state = {
        ...state,
        summary: {
          part_cost: "0.00",
          part_sub_total: "0.00",
          part_margin: "0.00",
          labour_cost: "0.00",
          labour_sub_total: "0.00",
          labour_margin: "0.00",
          labour_estimate_time: "0.00",
          labour_actual_time: "0.00",
          labour_variance_time: "0.00",
          profit: "0.00",
          profit_margin: "0.00",
          sub_total: "0.00",
          discount: "0.00",
          gst: "0.00",
          total: "0.00",
          labour_time: "1",
        },
      };
      break;
    case UPDATE_PARTS_ASSIGNED:
      {
        const parts = [
          ...state.parts.map((part) => {
            if (action.ids.includes(part.id)) {
              return {
                ...part,
                is_assigned: 1,
                assigned_qty: part.qty,
              };
            }
            return part;
          }),
        ];

        state = {
          ...state,
          parts: parts,
        };
      }
      break;


    case UPDATE_PARTS_PO_QTY:
      {
        const updatedParts = [...state.parts].map(c => {
          if (c.main_id === action.payload.main_id) {
            const pop = c.product.purchase_orders_parts || [];
            pop[0] = pop?.[0] || {};
            pop[0].purchase_order = {
              ...(pop[0]?.purchase_order || {}),
              ...action.payload,
            }
            c.product.purchase_orders_parts = pop;
          }

          return c;

        });

        state = {
          ...state,
          parts: updatedParts
        };
      }
      break;

    default:
      state = { ...state, };
      break;
  }
  return state;
};

export default PartReducer;
