import React from "react";
import { Button } from "react-bootstrap";

const ScrollPoints = () => {
  const GoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const GoToPrevTop = () => {
    var getTop = window.pageYOffset || document.documentElement.scrollTop;
    window.scrollTo({ top: getTop - 150, behavior: "smooth" });
  };
  const GoToNextBottom = () => {
    var getTop = window.pageYOffset || document.documentElement.scrollTop;
    window.scrollTo({ top: getTop + 150, behavior: "smooth" });
  };
  const GoToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };
  return (
    <div className="page-scroll-btn d-flex flex-direction direction-column">
      <Button className="d-flex align-center justify-center" title="Top of Page" onClick={GoToTop}>
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </Button>
      <Button
        className="d-flex align-center justify-center"
        onClick={GoToPrevTop}
        title="Scroll Up"
      >
        <i className="fa fa-angle-double-up" aria-hidden="true"></i>
      </Button>
      <Button
        className="d-flex align-center justify-center"
        onClick={GoToNextBottom}
        title="Scroll Down"
      >
        <i className="fa fa-angle-double-down" aria-hidden="true"></i>
      </Button>
      <Button
        className="d-flex align-center justify-center"
        onClick={GoToBottom}
        title="Bottom of Page"
      >
        <i className="fa fa-angle-down" aria-hidden="true"></i>
      </Button>
    </div>
  );
};

export default ScrollPoints;
