import React from 'react'
import tick from '../../../../assets/images/tick.svg';
import { checkbox } from './styles.module.scss';
const Checkbox = ({ checked, onClick, className, style }) => {
    return (
        <div className={`${checkbox} ${className}`} onClick={onClick} style={style}>
            {checked && <img src={tick} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} alt="Checked" />}
        </div>
    )
}

export default Checkbox