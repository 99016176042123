import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CompletedInvoices from "./Invoices/CompletedInvoices";
import InvoiceCredits from "./Invoices/InvoiceCredits";
import InvoicePayments from "./Invoices/InvoicePayments";
import RaisedInvoice from "./Invoices/RaisedInvoices";
import InvoiceStatement from "./Invoices/InvoiceStatement";
import Nav from "react-bootstrap/Nav";

export const ActiveTabContext = React.createContext();

const InvoiceList = () => {
  const [activeTab, setActiveTab] = useState("raised-invoices");
  const location = useLocation();
  document.title = "Invoice List | 5iQ"
  useEffect(() => {
    const hash = location.hash;
    selectActiveTab(hash.split("#")[1]);
  }, [location.hash]);

  function selectActiveTab(k){
    if(k){
      setActiveTab(k);
    }
    var url = window.location.href;
    url = url.split('#');
    const nextURL = url[0];
    const nextTitle = "Purchases | 5iQ";
    const nextState = {};
    window.history.replaceState(nextState, nextTitle, nextURL);
  }

  return (
    <ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
      <div className="right-section-wrapper">
        <div className="table-header">
          <div className="table-header-tabs">
            <Tab.Container
              defaultActiveKey={"raised-invoices"}
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <div className="row top-block">
                <div className="col-md-2">
                  <div className="table-title">
                    <i className="fa fa-files-o text-center text-primary font-size-30 m-r-10"></i>
                    <span>INVOICE</span>
                  </div>
                </div>
                <div className="col-md-8">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="raised-invoices">
                        Raised Invoices
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="completed-invoices">
                        Completed Invoices
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="invoice-payments">
                        Invoice Payments
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="invoice-credit">
                        Invoice Credit
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="invoice-statement">
                        Invoice Statement
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-2">
                  <div className="video-link-outer">
                    <div className="video-tutorial">
                      <i className="fa fa-icon"></i>
                      {/* <span className="help-text">Help</span> */}
                      <Link to="/trainee-video#invoices" ><span className="tutorial-title">Invoices Videos</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="raised-invoices">
                {activeTab === "raised-invoices" &&
                  <RaisedInvoice />
                }
                </Tab.Pane>
                <Tab.Pane eventKey="completed-invoices">
                {activeTab === "completed-invoices" &&
                    <CompletedInvoices />
                }
                </Tab.Pane>
                <Tab.Pane eventKey="invoice-payments">
                {activeTab === "invoice-payments" &&
                  <InvoicePayments />
                }
                </Tab.Pane>
                <Tab.Pane eventKey="invoice-credit">
                {activeTab === "invoice-credit" &&
                  <InvoiceCredits />
                }
                </Tab.Pane>
                <Tab.Pane eventKey="invoice-statement">
                {activeTab === "invoice-statement" &&
                  <InvoiceStatement />
                }
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </ActiveTabContext.Provider>
  );
};

export function useActiveTab() {
  return useContext(ActiveTabContext);
}

export default InvoiceList;
