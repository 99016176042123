import moment from "moment";
import { useCallback } from "react";
import { useContext } from "react";
import { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_WORKSHOP_MARKUP_DETAILS,
  GET_WORKSHOP_TAX_DETAILS,
} from "store/commonApiStore/actionTypes";
import { ACTIVE_INPSECTION_BUSINESS } from "store/login/actionTypes";
import { GET_PART_LIST } from "store/parts/actionTypes";
import { GET_STORAGE_DEVICES } from "store/storageDevices/actionTypes";
import { GET_SUBSCRIPTION } from "store/subscription/actionTypes";
import {
  GET_SUBSCRIPTION_ADDON,
  GET_SUBSCRIPTION_LOOKUP_ADDON,
} from "store/subscriptionAddon/actionTypes";
import { isEmpty } from "underscore";
import { unserializeArr } from "utils/unserialize";

export const InitialDataContext = createContext();

export const InitialData = ({ workshop_id, children, sessionData }) => {
  const sessionDataRef = useRef(sessionData);
  const storedData = sessionDataRef.current;
  const sessionUpdate = useRef(false);
  const [isChecked, setChecked] = useState(true);
  const [userName, setUserName] = useState();
  const [isPlanExpire, setIsPlanExpire] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("Trial");
  const [subscriptionType1, setSubscriptionType1] = useState(""); // For Trial Popup
  const [isTrialMode, setIsTrialMode] = useState(false);
  const [expDayCount, setExpDayCount] = useState("-1");

  const dispatch = useDispatch();

  const { is_inspection_business } = useSelector((state) => state.LoginReducer);

  // set theme mode and set username
  useEffect(() => {
    if (storedData.theme_mode === "1") {
      document.body.classList.remove("dark-theme");
      setChecked(true);
    } else {
      document.body.classList.add("dark-theme");
      setChecked(false);
    }
    setUserName(window.atob(storedData.user_name));
  }, []);

  // fetch current subscription detail
  useEffect(() => {
    dispatch({
      type: GET_SUBSCRIPTION,
      payload: {
        get: "all",
        conditions: {
          workshop_id: workshop_id,
          "WorkshopSubscription.status": 1,
          "WorkshopSubscription.is_deleted": 0,
        },
        "contain": {
          "SubscriptionPlan" : {
            "fields":["id","plan_type","name","topup_charge","yearly_charge","monthly_charge","stripe_monthly_plan_id","stripe_yearly_plan_id","sms_limit","select_default_plan","is_active","min_sms_limit","vin_request"]
          },
          "WorkshopSubscriptionDetails" :{
            "fields":["id","workshop_subscription_id","subscription_plan_id","start_date","end_date","subscription_type","plan_type","plan_name","sms_limit","current_sms_limit","min_sms_limit","is_sms_rollover","vin_request","current_vin_request","min_vin_request","is_vin_rollover","desktop_user_price","extra_desktop_user","mobile_user_price","extra_mobile_user","form_desktop_user_price","form_mobile_user_price","form_desktop_user","form_mobile_user","paid_amount","payment_processing_fees","transaction_details"]
          },
          "WorkshopSubscriptionDetails.SubscriptionPlan":{
            "fields":["id","plan_type","name","topup_charge","yearly_charge","monthly_charge","stripe_monthly_plan_id","stripe_yearly_plan_id","sms_limit","select_default_plan","is_active","min_sms_limit","vin_request"]
          }
        },
        "limit": 1,
        "order": {
          "WorkshopSubscription.id": "desc"
        },
        "fields":[
          "id","workshop_id","status","is_deleted","subscription_plan_id","plan_name","user_id","plan_expiry_date","plan_type","plan_amount","paid_amount","payment_processing_fees","default_user_count","transaction_details","is_vin_included","is_sms_included","payment_type"
        ]
      },
    });

    // get sms balance
    if (typeof storedData.is_sms_subscribe === "undefined") {
      dispatch({
        type: GET_SUBSCRIPTION_ADDON,
        payload: {
          get: "all",
          conditions: {
            "WorkshopSubscriptionDetails.plan_type": "sms",
            "WorkshopSubscriptionDetails.current_sms_limit >": 0,
            "WorkshopSubscriptionDetails.workshop_id": workshop_id,
          },
          // contain: {
          //   WorkshopSubscription: {
          //     conditions: {
          //       "WorkshopSubscription.workshop_id": workshop_id,
          //     },
          //   },
          // },
          fields: [
            "id",
            "workshop_subscription_id",
            "subscription_plan_id",
            "sms_limit",
            "current_sms_limit",
          ],
        },
      });
    }

    if (typeof storedData.is_lookup_subscribe === "undefined") {
      // get rego lookup request balance
      dispatch({
        type: GET_SUBSCRIPTION_LOOKUP_ADDON,
        balancetype: "lookup",
        payload: {
          get: "all",
          conditions: {
            "WorkshopSubscriptionDetails.plan_type": "vin_lookup",
            "WorkshopSubscriptionDetails.current_vin_request >": 0,
            "WorkshopSubscriptionDetails.workshop_id": workshop_id,
          },
          // contain: {
          //   WorkshopSubscription: {
          //     conditions: {
          //       "WorkshopSubscription.workshop_id": workshop_id,
          //     },
          //   },
          // },
          fields: [
            "id",
            "workshop_subscription_id",
            "subscription_plan_id",
            "current_vin_request",
            "vin_request",
          ],
        },
      });
    }

    if (typeof storedData.part_gst === "undefined") {
      dispatch({
        type: GET_WORKSHOP_TAX_DETAILS,
        payload: {
          get: "all",
          conditions: {
            status: "1",
            workshop_id: workshop_id,
          },
        },
      });
    }

    if (
      typeof storedData.default_markup === "undefined" ||
      typeof storedData.default_margin === "undefined"
    ) {
      // fetch workshop markup detail
      dispatch({
        type: GET_WORKSHOP_MARKUP_DETAILS,
        payload: {
          get: "all",
          conditions: {
            is_default: "1",
            workshop_id: workshop_id,
          },
        },
      });
    }

    if (typeof storedData.misc_part_id === "undefined") {
      dispatch({
        type: GET_PART_LIST,
        payload: {
          get: "all",
          conditions: {
            workshop_id: 0,
            product_number: "Misc Part",
            supplier_id: 0,
            status: 1,
            is_deleted: 0,
          },
          limit: 1,
        },
      });
    }

    if (typeof storedData.default_storage_device_id === "undefined") {
      dispatch({
        type: GET_STORAGE_DEVICES,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            "status IN": [1, 0],
            is_default: 1,
          },
          limit: 1,
        },
      });
    }
  }, [dispatch, storedData, workshop_id]);

  //Expirty DATA START

  //check current plan expire data

  // get subscription detail by reducer
  const { isFetching, subscribeData } = useSelector(
    (state) => state.SubscriptionReducer
  );

  // lookup and sms modal show
  const subscriptionDataSession = useCallback(() => {
    if (isFetching === true) {
      storedData.is_inspection_business = 0;
      let trial_expiry_date = moment(
        window.atob(storedData.trial_expiry_date)
      ).format("YYYY-MM-DD");
      let expiry_date = moment().format("YYYY-MM-DD");
      let current_date = moment().format("YYYY-MM-DD");

      if (subscribeData != null && !isEmpty(subscribeData)) {
        setSubscriptionType("Subscription");
        setSubscriptionType1("Subscription");

        expiry_date = moment(subscribeData.plan_expiry_date).format(
          "YYYY-MM-DD"
        );
        if (expiry_date >= current_date) {
          setIsPlanExpire(false);

          if (subscribeData?.subscription_plan?.select_default_plan) {
            const deserialized = unserializeArr(
              subscribeData.subscription_plan.select_default_plan
            );
            const inspectionVal = Number(deserialized.inspections ?? 0);
            if (!isNaN(inspectionVal) && inspectionVal > 0) {
              storedData.is_inspection_business = 1;
              sessionUpdate.current = true;

              if (is_inspection_business == 0) {
                dispatch({
                  type: ACTIVE_INPSECTION_BUSINESS,
                });
              }
            }
          }
        } else {
          setIsPlanExpire(true);
        }
      } else {
        if (trial_expiry_date) {
          var trial_date = moment(trial_expiry_date);
          var today_date = moment(current_date);
          var days_diff = trial_date.diff(today_date, "days"); // 1
          setExpDayCount(days_diff);
          if (current_date <= trial_expiry_date) {
            setSubscriptionType("Trial");
            setSubscriptionType1("Trial");
            setIsTrialMode(true);
            storedData.is_inspection_business = 1;
            sessionUpdate.current = true;
            if (is_inspection_business == 0) {
              dispatch({
                type: ACTIVE_INPSECTION_BUSINESS,
              });
            }
          } else {
            setIsPlanExpire(true);
          }
        }
      }
    }
    if (
      storedData.is_inspection_business === 0 &&
      is_inspection_business == 1
    ) {
      dispatch({
        type: ACTIVE_INPSECTION_BUSINESS,
      });
    }
  }, [isFetching, subscribeData, dispatch, is_inspection_business, storedData]);

  // get subscription Addon data SMS and lookup data
  const {
    isSubscription,
    subscribeAddonData,
    isLookupSubscription,
    subscribeLookupAddonData,
  } = useSelector((state) => state.SubscriptionAddonReducer);

  const { storageDevicesList } = useSelector(
    (state) => state.StorageDevicesReducer
  );

  // lookup and sms modal show
  const smsLookupSet = useCallback(() => {
    // sms data store session
    if (typeof storedData.is_sms_subscribe === "undefined") {
      if (isSubscription === 1 && subscribeAddonData) {
        let is_sms_subscribe = window.btoa(isSubscription);
        storedData.is_sms_subscribe = is_sms_subscribe;
        let sms_limit = window.btoa(subscribeAddonData.sms_limit);
        storedData.sms_limit = sms_limit;
        let current_sms_limit = window.btoa(
          subscribeAddonData.current_sms_limit
        );
        storedData.current_sms_limit = current_sms_limit;
        sessionUpdate.current = true;
      }

      // sessionStorage.setItem("stored_data", JSON.stringify(storedData));
    }

    // rego lookup data store session

    if (typeof storedData.is_lookup_subscribe === "undefined") {
      if (isLookupSubscription === 1 && subscribeLookupAddonData) {
        let is_lookup_subscribe = window.btoa(isLookupSubscription);
        storedData.is_lookup_subscribe = is_lookup_subscribe;
        let vin_request = window.btoa(subscribeLookupAddonData.vin_request);
        storedData.vin_request = vin_request;
        let current_vin_request = window.btoa(
          subscribeLookupAddonData.current_vin_request
        );
        storedData.current_vin_request = current_vin_request;
        sessionUpdate.current = true;
      }

      // sessionStorage.setItem("stored_data", JSON.stringify(storedData));
    }
  }, [
    isLookupSubscription,
    isSubscription,
    storedData,
    subscribeAddonData,
    subscribeLookupAddonData,
  ]);

  // markup and tax reducer
  const { worskhopMarkupData, worskhopTaxData } = useSelector(
    (state) => state.CommonApiStoreReducer
  );
  const updateMarkupDetail = useCallback(() => {
    // set default margin and markup into session

    if (typeof storedData.default_margin === "undefined") {
      if (worskhopMarkupData !== null) {
        const markupData = worskhopMarkupData[0];
        var default_margin = 0;
        var default_markup = 0;
        if (markupData && markupData.is_markup === "1") {
          // data append to margin
          default_margin = window.btoa(markupData.percentage);
        }
        if (markupData && markupData.is_markup === "0") {
          // data append to markup
          default_markup = window.btoa(markupData.percentage);
        }
        storedData.default_markup = default_markup;
        storedData.default_margin = default_margin;
        sessionUpdate.current = true;

        // sessionStorage.setItem("stored_data", JSON.stringify(storedData));
      }
    }

    // set parts, labour and products taxes into session
    if (typeof storedData.part_gst === "undefined") {
      if (worskhopTaxData !== null) {
        const worskhopTaxDatas = worskhopTaxData;

        worskhopTaxDatas.forEach((taxs) => {
          // part taxes
          if (taxs.application === "Part") {
            let part_gst = window.btoa(taxs.tax_rate || 0);
            storedData.part_gst = part_gst;

            let part_expense_gst = window.btoa(taxs.expense_tax_rate || 0);
            storedData.part_expense_gst = part_expense_gst;
          }

          // Labour taxes
          if (taxs.application === "Labour") {
            let labour_gst = window.btoa(taxs.tax_rate || 0);
            storedData.labour_gst = labour_gst;
            let labour_expense_gst = window.btoa(taxs.expense_tax_rate || 0);
            storedData.labour_expense_gst = labour_expense_gst;
          }

          // Purchase taxes
          if (taxs.application === "Purchase") {
            let purchase_gst = window.btoa(taxs.tax_rate || 0);
            storedData.purchase_gst = purchase_gst;
            let purchase_expense_gst = window.btoa(taxs.expense_tax_rate || 0);
            storedData.purchase_expense_gst = purchase_expense_gst;
          }
        });
        sessionUpdate.current = true;

        // sessionStorage.setItem("stored_data", JSON.stringify(storedData));
      }
    }
  }, [worskhopMarkupData, worskhopTaxData, storedData]);

  // part list reducer
  const { partListData } = useSelector((state) => state.PartReducer);

  // default misc part is store into session
  const updateMiscPart = useCallback(() => {
    if (typeof storedData.misc_part_id === "undefined") {
      // alert("hello")
      if (partListData !== null) {
        const partList = partListData;

        let misc_part_id = window.btoa(partList?.[0]?.id || null);
        storedData.misc_part_id = misc_part_id;
        sessionUpdate.current = true;

        // sessionStorage.setItem("stored_data", JSON.stringify(storedData));
      }
    }
  }, [partListData, storedData]);

  //stroge device reducer START
  const updateStorageId = useCallback(() => {
    if (typeof storedData.default_storage_device_id === "undefined") {
      if (storageDevicesList) {
        if (storageDevicesList.code === 200) {
          let default_storage_device = window.btoa(
            storageDevicesList.data.StorageDevices[0]?.id || null
          );
          storedData.default_storage_device_id = default_storage_device;
          sessionUpdate.current = true;
        }
      }
    }
  }, [storageDevicesList, storedData]);
  //stroge device reducer END

  useEffect(() => {
    subscriptionDataSession();
    smsLookupSet();
    updateMarkupDetail();
    updateMiscPart();
    updateStorageId();
    if (sessionUpdate.current) {
      storedData.invoice_id = "";
      storedData.isInvoiced = false;
      sessionStorage.setItem("stored_data", JSON.stringify(storedData));
    }
  }, [
    smsLookupSet,
    subscriptionDataSession,
    updateMarkupDetail,
    updateMiscPart,
    updateStorageId,
    storedData,
  ]);

  //Expirty DATA END
  return (
    <InitialDataContext.Provider
      value={{
        subscriptionType,
        subscriptionType1,
        isTrialMode,
        expDayCount,
        setChecked,
        isChecked,
        userName,
        isPlanExpire,
        setIsPlanExpire,
        setIsTrialMode,
      }}
    >
      {children}
    </InitialDataContext.Provider>
  );
  // return (
  //   <InitialDataContext.Provider
  //     value={{
  //       subscriptionType,
  //       isTrialMode,
  //       expDayCount,
  //       setChecked,
  //       isChecked,
  //       userName,
  //       isPlanExpire
  //     }}
  //   >
  //     {children}
  //   </InitialDataContext.Provider>
  // );
};

export function useInitialData() {
  const data = useContext(InitialDataContext);
  return data;
}
