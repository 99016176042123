import React, { useEffect, useState } from 'react';
import "../contactHelp.scss";
import contactImg from "assets/images/contact-image.png";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_PROFILE } from '../../../store/userProfile/actionTypes';

const ContactHelp = () => {
  const optionList = [
    { value: "1", label: "Sales" },
    { value: "2", label: "Billing" },
    { value: "3", label: "Technical Support" },
  ];

  const dispatch = useDispatch();

  const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
  let user_id = window.atob(storedData.user_id);
  let workshopSessionName = window.atob(storedData.workshop_name);

  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();

  // get user profile data
  const UserProfileData = useSelector((state) => state.UserProfileReducer);

  useEffect(() => {
    dispatch({
      type: GET_USER_PROFILE,
      payload: {
        "get": "all",
        "conditions": {
          "id": user_id,
        }
      },
    });
  }, []);

  useEffect(() => {
    if (UserProfileData.isFetching === true && UserProfileData.userData !== null) {
      var userDetail = UserProfileData.userData;
      setFirstname(userDetail.firstname);
      setLastname(userDetail.lastname);
      setEmail(userDetail.email);
      setPhone(userDetail.phone_number);
    }
  }, [UserProfileData]);

  return (
    <div className="right-section-wrapper has-white-bg contact-page-wrapper">
      <div className="row">
        <div className="col-sm-6 d-flex direction-column no-wrap">
          <div className="panel-heading">Self Help</div>
          <div className="panel-body image-block">
            <img alt='contact' src={contactImg}></img>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="panel-heading">5iQ Help</div>
          <div className="panel-body">
            <h3 className="m-b-10">Send us a message</h3>
            <strong className="description-text m-b-10">{workshopSessionName}</strong>
            <Form>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group className="form-group mb-3">
                    <label>
                      First name <span class="text-danger">*</span>
                    </label>
                    <Form.Control required type="text" defaultValue={firstname} />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group className="form-group mb-3">
                    <label>Surname</label>
                    <Form.Control required type="text" defaultValue={lastname} />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group className="form-group mb-3">
                    <label>
                      Email Address <span class="text-danger">*</span>
                    </label>
                    <Form.Control
                      required
                      type="text"
                      defaultValue={email}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group className="form-group mb-3">
                    <label>Phone</label>
                    <Form.Control
                      required
                      type="text"
                      defaultValue={phone}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <div className="select-block select-wrapper d-flex justify-space-between">
                    <Form.Group className="form-group mb-3">
                      <label>How can we assist you? </label>
                      <Select
                        className="form-control custom-select"
                        required
                        name="country"
                        id="country"
                        options={optionList}
                        searchable={false}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="col-sm-12">
                  <Form.Group className="form-group mb-3">
                    <label>Message <span class="text-danger">*</span></label>
                    <Form.Control className="text-area" as="textarea" rows={3} />
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex justify-flex-end">
                <Button className="send-btn"><i class="fa fa-paper-plane-o"></i></Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHelp;
