import {
  ACTIVE_INPSECTION_BUSINESS, DEACTIVE_INPSECTION_BUSINESS, GET_LOGIN, GET_LOGIN_FAIL, GET_LOGIN_SUCCESS, GET_LOGOUT, SET_EXPIRED, SET_FIREBASE_TOKEN_SUCCESS
} from "./actionTypes";

const initialState = {
  token: "",
  userData: null,
  loadingLogins: false,
  role_id: 0,
  expired: false,
  error: {
    message: "",
  },
  is_inspection_business: 0,
  firebase: null,
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN:
      state = {
        ...state,
        loadingLogins: true,
        token: "",
        userData: null,
        role_id: 0,
      };
      break;
    case GET_LOGIN_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        token: action.payload.email + ":" + action.payload.api_plainkey,
        userData: action.payload,
        role_id: action.payload.role_id,
      };
      break;
    case GET_LOGIN_FAIL:
      state = {
        ...state,
        token: "",
        userData: null,
        role_id: 0,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;
    case GET_LOGOUT:
      state = {
        ...state,
        token: "",
        userData: null,
        role_id: 0,
        loadingLogins: false,
        is_inspection_business: 0,
        expired: false,
        firebase: null,
      };
      break;
    case ACTIVE_INPSECTION_BUSINESS:
      return {
        ...state,
        is_inspection_business: 1,
      };
      case DEACTIVE_INPSECTION_BUSINESS:
      return {
        ...state,
        is_inspection_business: 0,
      };
    case SET_EXPIRED:

      return {
        ...state,
        expired: action.payload ?? true,
      };
    case SET_FIREBASE_TOKEN_SUCCESS:
      return {
        ...state,
        firebase: action.payload,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LoginReducer;
