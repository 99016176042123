import { call, put, takeLatest } from "redux-saga/effects";
import
  {
    addStorageDeviceFail, 
    addStorageDeviceSuccess, 
    editStorageDeviceFail, 
    editStorageDeviceSuccess, 
    getStorageDevicesFail, 
    getStorageDevicesSuccess, 
    updateStorageDeviceFail, 
    updateStorageDeviceSuccess,
    viewStorageDeviceDetailsSuccess,
    viewStorageDeviceDetailsFail
  } from "./actions";
import
  {
    ADD_STORAGE_DEVICE,
    EDIT_STORAGE_DEVICE,
    GET_STORAGE_DEVICES,
    UPDATE_STORAGE_DEVICE,
    VIEW_STORAGE_DEVICE_DETAILS
  } from "./actionTypes";


import { showError, showSuccess } from "utils/alertMessage";
import
  {
    addStorageDevice,
    editStorageDevice,
    getStorageDevices, 
    updateStorageDevice,
    viewStorageDeviceDetails
  } from "../../helpers/backendHelper";

function* onGetStorageDevices(data) {
  try {
    const response = yield call(getStorageDevices, data);
    if (response) {
      if (response.code === 200) {
        yield put(getStorageDevicesSuccess(response));
      } else {
        yield put(getStorageDevicesFail(response.message));
        
      }
    } else {
      yield put(getStorageDevicesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getStorageDevicesFail(error.response));
  }
}

function* onEditStorageDevice(data) {
  try {
    const response = yield call(editStorageDevice, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(editStorageDeviceSuccess(response.data.StorageDevices));
      } else {
        yield put(editStorageDeviceFail(response.message));
      }
    } else {
      yield put(editStorageDeviceFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editStorageDeviceFail(error.response));
  }
}

function* onUpdateStorageDevice(data) {
  try {
    const navigate = data.payload.navigate;
    const response = yield call(updateStorageDevice, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateStorageDeviceSuccess(response.data.StorageDevices));
        navigate("/storage_list");
        // showSuccess(response.message);
        showSuccess("Storage Location updated");
      } else {
        yield put(updateStorageDeviceFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateStorageDeviceFail("Didn't get api response"));
      showError("Didn't get api response");
    }
  } catch (error) {
    yield put(updateStorageDeviceFail(error.response));
  }
}

function* onAddStorageDevice(data) {
  try {
    const navigate = data.payload.navigate;
    const response = yield call(addStorageDevice, data);
    if (response) {
      if (response.code === 200) {
        yield put(addStorageDeviceSuccess(response.data.StorageDevices));
        navigate("/storage_list");
        // showSuccess(response.message);
        showSuccess("Storage Location added");
      } else {
        yield put(addStorageDeviceFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(addStorageDeviceFail("Didn't get api response"));
      showError("Didn't get api response");
    }
  } catch (error) {
    yield put(addStorageDeviceFail(error.response));
  }
}
function* onViewStorageDeviceDetails(data) {
  try {
    const response = yield call(viewStorageDeviceDetails, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(
          viewStorageDeviceDetailsSuccess(response.data.StorageDevices)
        );
      } else {
        yield put(viewStorageDeviceDetailsFail(response));
      }
    } else {
      yield put(viewStorageDeviceDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(viewStorageDeviceDetailsFail(error.response));
  }
}

function* StorageDevicesSaga() {
  yield takeLatest(GET_STORAGE_DEVICES, onGetStorageDevices);
  yield takeLatest(EDIT_STORAGE_DEVICE, onEditStorageDevice);
  yield takeLatest(ADD_STORAGE_DEVICE, onAddStorageDevice);
  yield takeLatest(UPDATE_STORAGE_DEVICE, onUpdateStorageDevice);
  yield takeLatest( VIEW_STORAGE_DEVICE_DETAILS, onViewStorageDeviceDetails);
}

export default StorageDevicesSaga;
