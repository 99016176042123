import {
  UPDATE_SUPPLIER_INTEGRATION,
  UPDATE_SUPPLIER_INTEGRATION_SUCCESS,
  UPDATE_SUPPLIER_INTEGRATION_FAIL,
  SAVE_SUPPLIER_INTEGRATION,
  SAVE_SUPPLIER_INTEGRATION_SUCCESS,
  SAVE_SUPPLIER_INTEGRATION_FAIL,
} from "./actionTypes";

const initialState = {
  supplierData: {},
};

const SupplierIntegrationReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case UPDATE_SUPPLIER_INTEGRATION:
      state = {
        ...state,
      };
      break;
    case UPDATE_SUPPLIER_INTEGRATION_SUCCESS:
      state = {
        ...state,
        supplierData: action.payload,
      };
      break;
    case UPDATE_SUPPLIER_INTEGRATION_FAIL:
      state = {
        ...state,
        supplierData: {},
        error: {
          message: action.payload,
        },
      };
      break;
      case SAVE_SUPPLIER_INTEGRATION:
        state = {
          ...state,
        };
        break;
      case SAVE_SUPPLIER_INTEGRATION_SUCCESS:
        state = {
          ...state,
          supplierData: action.payload,
        };
        break;
      case SAVE_SUPPLIER_INTEGRATION_FAIL:
        state = {
          ...state,
          supplierData: {},
          error: {
            message: action.payload,
          },
        };
        break;
  
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SupplierIntegrationReducer;
