import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_USERS } from "store/accountSync/actionTypes";
import { GET_ASSIGNED_JOBS } from "store/assignedJobs/actionTypes";
import { GET_STATUS_COLOR } from "store/commonApiStore/actionTypes";
import Loader from "views/common/UI/Loader";
import TechnicianDetail from "./technicianDetail";
import DefaultTechnicianList from "./defaultTechnicianList";
import { getassignedJobsSuccess } from "store/assignedJobs/actions";
import { timeZoneToUTC } from "utils/timeZoneConvert";
import { useNavigate } from "react-router-dom";
import { getassignedJobs } from "helpers/backendHelper";

let ClockingContent = ({ fromDate, currentDay }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);
    let timezoneLable = window.atob(storedData.timezone_lable);

    const [allTechnicianList, setAllTechnicianList] = useState([]);
    const [jobData, setJobData] = useState([]);
    // const [activeTechnician, setActiveTechnician] = useState("");
    // const [activeUserName, setActiveUserName] = useState("");

    // let avatarPath = window.location.origin + "/Avatar/";
    // var techProfile = avatarPath + "1.png";

    const { loadingLogins, usersDetails } = useSelector((state) => state.AccountSyncReducer);

    const {
        statusColor,
    } = useSelector((state) => state.CommonApiStoreReducer);

    useEffect(() => {
        loadClockingHistory();
        loadJobList();

        return () => {
            dispatch(getassignedJobsSuccess(null));
            setAllTechnicianList([]);
            setJobData([]);
        };

    }, [fromDate]);

    // this function is used for both tab job clocking and daily clocking
    function loadClockingHistory() {

        let current_time = moment().utc().format('HH:mm');

        let local_start_time = fromDate + " 00:00:00";
        let local_end_time = fromDate + " 23:59:59";

        const utc_start_time = timeZoneToUTC(
            local_start_time,
            timezoneLable,
            "YYYY-MM-DD HH:mm:ss",
            "YYYY-MM-DD HH:mm:ss"
        );

        const utc_end_time = timeZoneToUTC(
            local_end_time,
            timezoneLable,
            "YYYY-MM-DD HH:mm:ss",
            "YYYY-MM-DD HH:mm:ss"
        );

        dispatch({
            type: GET_USERS,
            payload: {
                get: "all",
                conditions: {
                    "workshop_id": workshop_id,
                    "role_id IN": [3, 7, 8, 9],
                    "is_deleted": 0
                },
                contain: {
                    "EmployeesDetails": {
                        fields: [
                            "EmployeesDetails.id",
                            "EmployeesDetails.user_id",
                            "EmployeesDetails.shift_working_day",
                            "EmployeesDetails.workshop_id"
                        ]
                    },
                    "JobClockingHistory": {
                        conditions: {
                            "JobClockingHistory.created_on >= ": utc_start_time,
                            "JobClockingHistory.created_on <= ": utc_end_time,
                            "JobClockingHistory.is_deleted =": "0"
                        }
                    },
                },
                fields: ["id", "workshop_id", "firstname", "lastname", "role_id", "user_profile"]
            }
        })
    }

    async function loadJobList() {

        const request1 = {
            get: "all",
            conditions: {
                "JobSchedules.workshop_id": workshop_id,
                // "JobSchedules.job_stage IN": ["Bookin", "Unassigned"],
                "JobSchedules.activity_schedule_id": 0,
                "JobSchedules.is_break_time": 0,
                "JobSchedules.status": 1,
                "JobSchedules.is_copid_job": 0,
                "OR" :[
                    {
                        "JobSchedules.from_date <=": fromDate,
                        "JobSchedules.to_date >=": fromDate
                    },
                    {
                        "JobSchedules.from_date": fromDate,
                        "JobSchedules.to_date <": moment(fromDate).add(1, 'days')
                    }
                ],
            },
            contain: {
                Users: {
                    fields: ["id", "workshop_id"],
                },
                "Vehicles.Users": {
                    fields: ["Users.id"],
                },
                "Vehicles.Users.CustomersDetails": {
                    conditions: {
                        workshop_id: workshop_id,
                    },
                    fields: [
                        "CustomersDetails.id",
                        "CustomersDetails.user_id",
                        "CustomersDetails.firstname",
                        "CustomersDetails.lastname",
                        "CustomersDetails.mobile_number",
                        "CustomersDetails.customer_app",
                        "CustomersDetails.type",
                        "CustomersDetails.street_address",
                        "CustomersDetails.suburb",
                        "CustomersDetails.postcode",
                        "CustomersDetails.company_name",
                        "CustomersDetails.mobile_code"
                    ],
                },
                "Customers.CustDetails": {
                    conditions: {
                        "CustDetails.workshop_id": workshop_id,
                    },
                    fields: [
                        "user_id",
                        "id",
                        "firstname",
                        "lastname",
                        "mobile_number",
                        "mobile_code",
                        "phone_number",
                        "company_name",
                        "type",
                    ],
                },
                JobBookingTypes: {
                    fields: ["name", "parent_id"],
                },
                WorkshopBaysDetails: {
                    fields: ["id", "name"],
                },
            },
            order: {
                "JobSchedules.id": "asc",
            },
            fields: [
                "JobSchedules.id",
                "JobSchedules.user_id",
                "JobSchedules.workshop_id",
                "JobSchedules.from_date",
                "JobSchedules.description",
                "JobSchedules.created_on",
                "JobSchedules.total",
                "JobSchedules.claim_number",
                "JobSchedules.from_time",
                "JobSchedules.to_time",
                "JobSchedules.timer_status",
                "JobSchedules.timer_start_time",
                "JobSchedules.timer_total_time",
                "JobSchedules.clocked_in_time",
                "JobSchedules.clocked_off_time",
                "JobSchedules.job_number",
                "JobSchedules.job_status",
                "JobSchedules.job_stage",
                "JobSchedules.customer_id",
                "JobSchedules.vehicle_id",
                "Vehicles.rego",
                "Vehicles.user_id",
                "Vehicles.service_due_date",
                "Vehicles.rego_due_date",
                "Vehicles.make",
                "Vehicles.model",
                "Users.id",
                "Users.firstname",
                "Users.lastname",
                "Customers.id",
                "Customers.email",
                "JobSchedules.is_copid_job",
                "JobSchedules.is_copy_job",
                "JobSchedules.from_date",
                "JobSchedules.checkIn_date",
            ],
        };

        const get_jobs = await getassignedJobs({ payload: request1 });
        if (get_jobs?.code === 200) {
            setJobData(get_jobs?.data?.JobSchedules);
        }

        if (statusColor === null) {
            dispatch({
                type: GET_STATUS_COLOR,
                payload: {
                    get: "all",
                    conditions: {
                        workshop_id: workshop_id,
                        status: 1,
                    },
                    fields: ["name", "color"],
                },
            });
        }
    }

    useEffect(() => {
        if (usersDetails !== null && usersDetails !== undefined) {
            var users = usersDetails;
            setAllTechnicianList(users);
        }
    }, [usersDetails]);

    const getJobData = useSelector((state) => state.assignedJobsReducer);

    const viewFullClockingReport = () => {
        let searchDate = moment(fromDate).format("YYYY-MM-DD");
        navigate("/reports/clocking-report?searchDate=" + searchDate + "&key=job");
    }

    // if (loadingLogins) {
    //     return (<Loader />)
    // }


    return (
        <>
            <div className="row mb-3 mt-5">
                {jobData?.length > 0 ? (
                    <>
                        {allTechnicianList && allTechnicianList.map((techician, i) => (
                            <TechnicianDetail
                                techician={techician}
                                techCounter={parseInt(i) + 1}
                                jobData={jobData}
                                setJobData={setJobData}
                                key={i}
                                statusColor={statusColor}
                                fromDate={fromDate}
                            />
                        ))}
                    </>
                ) : (
                    <>
                        {allTechnicianList && allTechnicianList.map((techician, i) => (
                            <DefaultTechnicianList
                                techician={techician}
                                techCounter={parseInt(i) + 1}
                                jobData={jobData}
                                setJobData={setJobData}
                                key={i}
                                statusColor={statusColor}
                                fromDate={fromDate}
                            />
                        ))}
                    </>
                )}

                <div className="col-4 " style={{ padding: "0px 15px 0px 15px" }} >
                    <div className="clocking-buttons">
                        <div className="clocking-actions mt-3">
                            <div onClick={viewFullClockingReport} className="col-md-12 px-1 d-flex align-items-center justify-content-sm-evenly color-badges ">

                                <div className="">
                                    <span>Full Clocking Report</span>
                                </div>

                                <div className="color-class report-button rounded-btn break-btn btn btn-primary">
                                    <span>View Report</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ClockingContent;
