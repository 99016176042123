import {
    GET_VEHICLE_COUNT,
    GET_VEHICLE_COUNT_SUCCESS,
    GET_VEHICLE_COUNT_FAIL,
    GET_SERVICE_DUE_COUNT,
    GET_SERVICE_DUE_COUNT_SUCCESS,
    GET_SERVICE_DUE_COUNT_FAIL,
    GET_CUSTOMER_AND_APP_COUNT,
    GET_CUSTOMER_AND_APP_COUNT_SUCCESS,
    GET_CUSTOMER_AND_APP_COUNT_FAIL,
    GET_BOOK_IN_SOURCE,
    GET_BOOK_IN_SOURCE_SUCCESS,
    GET_BOOK_IN_SOURCE_FAIL,
    GET_CUSTOMER_SURVEY,
    GET_CUSTOMER_SURVEY_SUCCESS,
    GET_CUSTOMER_SURVEY_FAIL,
    GET_NEWS_FEED,
    GET_NEWS_FEED_SUCCESS,
    GET_NEWS_FEED_FAIL,
    GET_DASHBOARD_JOB_COUNT,
    GET_DASHBOARD_JOB_COUNT_SUCCESS,
    GET_DASHBOARD_JOB_COUNT_FAIL,
    GET_DASHBOARD_WEEK_JOB_COUNT,
    GET_DASHBOARD_WEEK_JOB_COUNT_SUCCESS,
    GET_DASHBOARD_WEEK_JOB_COUNT_FAIL,
    GET_DASHBOARD_INVOICE_COUNT,
    GET_DASHBOARD_INVOICE_COUNT_SUCCESS,
    GET_DASHBOARD_INVOICE_COUNT_FAIL,
    GET_DASHBOARD_WEEK_INVOICE_COUNT,
    GET_DASHBOARD_WEEK_INVOICE_COUNT_SUCCESS,
    GET_DASHBOARD_WEEK_INVOICE_COUNT_FAIL,
    GET_DASHBOARD_PAYMENT_COUNT,
    GET_DASHBOARD_PAYMENT_COUNT_SUCCESS,
    GET_DASHBOARD_PAYMENT_COUNT_FAIL,
    GET_DASHBOARD_WEEK_PAYMENT_COUNT,
    GET_DASHBOARD_WEEK_PAYMENT_COUNT_SUCCESS,
    GET_DASHBOARD_WEEK_PAYMENT_COUNT_FAIL,
    UPDATE_CHECK_IN_TIME,
    UPDATE_CHECK_IN_TIME_SUCCESS,
    UPDATE_CHECK_IN_TIME_FAIL,
} from "./actionTypes";

const initialState = {
    data: [],
    error: {
        message: ""
    },
    vehicleCount:null,
    serviceCount:null,
    customerAndAppCount:null,
    bookInSource:null,
    customerSurvey:null,
    newsFeed:null,
    dashboardJobCount:null,
    dashboardWeekJobCount:null,
    dashboardInvoiceCount:null,
    dashboardWeekInvoiceCount:null,
    dashboardPaymentCount:null,
    dashboardWeekPaymentCount:null,
    updateCheckInTime:null
};

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VEHICLE_COUNT:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_VEHICLE_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                vehicleCount: action.payload
            };
            break;
        case GET_VEHICLE_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                vehicleCount:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_SERVICE_DUE_COUNT:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_SERVICE_DUE_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                serviceCount: action.payload
            };
            break;
        case GET_SERVICE_DUE_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                serviceCount:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_CUSTOMER_AND_APP_COUNT:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_CUSTOMER_AND_APP_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                customerAndAppCount: action.payload
            };
            break;
        case GET_CUSTOMER_AND_APP_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                customerAndAppCount:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_BOOK_IN_SOURCE:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_BOOK_IN_SOURCE_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                bookInSource: action.payload
            };
            break;
        case GET_BOOK_IN_SOURCE_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                bookInSource:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_CUSTOMER_SURVEY:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_CUSTOMER_SURVEY_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                customerSurvey: action.payload
            };
            break;
        case GET_CUSTOMER_SURVEY_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                customerSurvey:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_NEWS_FEED:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_NEWS_FEED_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                newsFeed: action.payload
            };
            break;
        case GET_NEWS_FEED_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                newsFeed:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_DASHBOARD_JOB_COUNT:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_DASHBOARD_JOB_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                dashboardJobCount: action.payload
            };
            break;
        case GET_DASHBOARD_JOB_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                dashboardJobCount:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_DASHBOARD_WEEK_JOB_COUNT:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_DASHBOARD_WEEK_JOB_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                dashboardWeekJobCount: action.payload
            };
            break;
        case GET_DASHBOARD_WEEK_JOB_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                dashboardWeekJobCount:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_DASHBOARD_INVOICE_COUNT:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_DASHBOARD_INVOICE_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                dashboardInvoiceCount: action.payload
            };
            break;
        case GET_DASHBOARD_INVOICE_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                dashboardInvoiceCount:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_DASHBOARD_WEEK_INVOICE_COUNT:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_DASHBOARD_WEEK_INVOICE_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                dashboardWeekInvoiceCount: action.payload
            };
            break;
        case GET_DASHBOARD_WEEK_INVOICE_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                dashboardWeekInvoiceCount:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_DASHBOARD_PAYMENT_COUNT:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_DASHBOARD_PAYMENT_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                dashboardPaymentCount: action.payload
            };
            break;
        case GET_DASHBOARD_PAYMENT_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                dashboardPaymentCount:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case GET_DASHBOARD_WEEK_PAYMENT_COUNT:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case GET_DASHBOARD_WEEK_PAYMENT_COUNT_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                dashboardWeekPaymentCount: action.payload
            };
            break;
        case GET_DASHBOARD_WEEK_PAYMENT_COUNT_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                dashboardWeekPaymentCount:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        case UPDATE_CHECK_IN_TIME:
            state = {
                ...state,
                loadingLogins: true
            };
            break;
        case UPDATE_CHECK_IN_TIME_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                updateCheckInTime: action.payload
            };
            break;
        case UPDATE_CHECK_IN_TIME_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                updateCheckInTime:null,
                error: {
                    message: action.payload,
                }
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default DashboardReducer;