import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { showError, showSuccess } from "../../../utils/alertMessage";
import { DO_SUBSCRIPTION_CHECKOUT } from "../../../store/subscription/actionTypes";
import StripeLogo from "../../../assets/images/logo-stripe.svg";
import LoaderImage from "../../../assets/images/page-loader.svg";
import { addSupplierSuccess } from "store/supplier/actions";
import { useInitialData } from "views/common/InitialDataContext/InitialDataContext";

const ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      fontSize: '16px',
      color: '#000000',
      padding: '5px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const CheckoutForm = ({
  user_plan_id,
  sms_plan_id,
  vin_plan_id,
  total_amount,
  payment_processing_fees,
  desktop_user,
  desktop_user_price,
  subscriptionCycle,
  mobile_user,
  mobile_user_price,
  app_config_plan_id,
  success,
  paymentType
}) => {

  const {
    setIsPlanExpire,
    setIsTrialMode
  } = useInitialData();

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [stripeLoading, setStripeLoading] = useState(false);
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const userId = window.atob(storedData['user_id'])
  const stripe_customer_id = window.atob(storedData.stripe_customer_id)
  useEffect(() => {
    if (success && success.message) {
      setIsPlanExpire(false);
      setIsTrialMode(false);
      showSuccess(success.message);
      navigate("/home");
    }
  }, [success]);
  const formInitialValues = {
    name: "",
    card_number: false,
    card_expiry: false,
    cvc: false,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    onSubmit: async () => {
      if (!user_plan_id) {
        showError("You didn't select any main plan, Please select Growth, Pro or Ultimate plan");
      }
      else {
        setStripeLoading(true);
        const card = elements.getElement(CardNumberElement);
        const result = await stripe.createToken(card);
        if (result.error) {
          await setStripeLoading(false);
        } else {
          let editFormValues = {
            stripe_token_id: result.token.id,
            user_id: userId,
            workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
            email: JSON.parse(localStorage.getItem("data")).email,
            user_plan_id,
            plan_type: subscriptionCycle,
            total_amount,
            payment_processing_fees,
            desktop_user,
            desktop_user_price,
            mobile_user,
            mobile_user_price,
            sms_plan_id,
            vin_plan_id,
            app_config_plan_id
          };
          if (stripe_customer_id != "null") editFormValues.stripe_customer_id = stripe_customer_id;

          dispatch({
            type: DO_SUBSCRIPTION_CHECKOUT,
            payload: editFormValues
          });
          setStripeLoading(false);
        }
        // pass the token to your backend API
        // await updateSubscriptionConfirmationDetails(editFormValues);
        // await setIsLoading(false);
      }
    },
    validate: (values) => {
      const error = {};
      if (!values.name) {
        error.name = "This field is required.";
      }

      if (!values.card_number) {
        error.card_number = "This field is required.";
      }
      else if (values.card_number.empty === true) {
        error.card_number = "This field is required.";
      }
      else if (!values.card_number.complete && values.card_number.error) {
        error.card_number = values.card_number.error.message;
      }

      if (!values.cvc) {
        error.cvc = "This field is required.";
      }
      else if (values.cvc.empty === true) {
        error.cvc = "This field is required.";
      }
      else if (!values.cvc.complete && values.cvc.error) {
        error.cvc = values.cvc.error.message;
      }

      if (!values.card_expiry) {
        error.card_expiry = "This field is required.";
      }
      else if (values.card_expiry.empty === true) {
        error.card_expiry = "This field is required.";
      }
      else if (!values.card_expiry.complete && values.card_expiry.error) {
        error.card_expiry = values.card_expiry.error.message;
      }

      return error;
    },
  });
  return (
    <>
      <div className="pb-0 pt-0 stripe-form">
        <div className="form-group mb-3">
          <label htmlFor="name">Name</label>
          <div className="iconic-input">
            <i className="fa fa-user"></i>
            <input
              name="name"
              id="name"
              className="form-control"
              onChange={e => formik.setFieldValue("name", e.target.value)}
              readOnly={paymentType === '1'}
            />
            {formik.errors.name && formik.touched.name && (
              <div className="form-error text-danger">
                {formik.errors.name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="cardNumber">Card Number</label>
          <CardNumberElement
            id="cardNumber"
            className="form-control"
            options={ELEMENT_OPTIONS}
            showIcon={true}
            onChange={(event) => {
              formik.setFieldValue("card_number", event);
            }}
          />
          {formik.errors.card_number && formik.touched.card_number && (
            <div className="form-error text-danger">
              {formik.errors.card_number}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label htmlFor="expiry">Card Expiration</label>
              <CardExpiryElement
                id="expiry"
                className="form-control"
                options={ELEMENT_OPTIONS}
                onChange={(event) => {
                  formik.setFieldValue("card_expiry", event);
                }}
              />
              {formik.errors.card_expiry && formik.touched.card_expiry && (
                <div className="form-error text-danger">
                  {formik.errors.card_expiry}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label htmlFor="cvc">CVC</label>
              <CardCvcElement
                id="cvc"
                className="form-control"
                options={ELEMENT_OPTIONS}
                onChange={(event) => {
                  formik.setFieldValue("cvc", event);
                }}
              />
              {formik.errors.cvc && formik.touched.cvc && (
                <div className="form-error text-danger">
                  {formik.errors.cvc}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="form-group">
              <div className="powered-by">
                <label htmlFor="">powered by</label>
                <img src={StripeLogo} height="30" alt="" />
              </div>
            </div>
          </div>
          <div className="col-4 text-end">
            <button
              className="btn btn-primary btn-rounded"
              type="submit"
              disabled={!stripe || paymentType === '1'}
              readOnly={paymentType === '1'}
              onClick={formik.handleSubmit}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
      {stripeLoading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
    </>
  );
}

export default CheckoutForm;