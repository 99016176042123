import { apiCall } from "helpers/apiHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import { groupBy } from "lodash";
import { date_format, getCurrency } from "utils/getCurrency";
import moment from "moment";
import { quoteReportCategory, quoteReportChartData, quoteReportConversion, quoteReportStatsData, quoteReportTableData } from "helpers/backendHelper";
import { addComma } from "utils/numberFunction";

/** Single call utilities */

// revunue functions START 
async function getRevenueTableList(request) {
  
  const tableResponse = await quoteReportTableData({ payload: request });

  const revenueTable =
    tableResponse?.data?.CustomerQuotes?.map((quotes) => {

      let customer_name = quotes?.type === '1' ? quotes?.company_name : quotes?.customer;
      
      return {
        id: quotes?.quote_number,
        date: moment(quotes?.quote_date).format(date_format),
        customer: customer_name,
        quote_category: quotes?.quote_category,
        quote_number: quotes?.quote_number,
        quote_status: quotes?.quote_status,
        total: +quotes?.total_cost || 0.00
      };
    }) || [];

  return revenueTable;

  
}

async function getRevenueChartData(request) {

  const chartResponse = await quoteReportChartData({ payload: request });

  const obj = {
    quoteDates: [],
    totalQuotes: [],
    wonQuotes: [],
    lostQuotes: [],
    quoteAvg: [],
    revenue: [],
  };

  chartResponse?.data?.CustomerQuotes?.forEach((quotes) => {

    let quoteAvgValue = 0;
      if(quotes?.revenue){
          quoteAvgValue = +quotes?.revenue / +quotes?.open_won_quotes;
      }

    obj.quoteDates.push(moment(quotes.quote_date).format(date_format));
    obj.totalQuotes.push(+quotes?.total_quotes || 0);
    obj.wonQuotes.push(+quotes?.won_quotes || 0);
    obj.lostQuotes.push(+quotes?.lost_quotes || 0);
    obj.quoteAvg.push(+quoteAvgValue || 0);
    obj.revenue.push(+quotes?.revenue || 0);

  });

  return obj;

}
// revunue functions END 



// forecast functions START 
async function getForecastTableList(request) {
  
  const tableResponse = await quoteReportTableData({ payload: request });

  const forecastTable =
    tableResponse?.data?.CustomerQuotes?.map((quotes) => {
      
      let customer_name = quotes?.type === '1' ? quotes?.company_name : quotes?.customer;
      
      return {
        id: quotes?.quote_number,
        date: moment(quotes?.quote_date).format(date_format),
        customer: customer_name,
        quote_category: quotes?.quote_category,
        quote_number: quotes?.quote_number,
        quote_status: quotes?.quote_status,
        total: +quotes?.total_cost || 0.00
      };
    }) || [];

  return forecastTable;

  
}

async function getForecastChartData(request) {

  const chartResponse = await quoteReportChartData({ payload: request });

  const obj = {
    quoteDates: [],
    totalQuotes: [],
    wonQuotes: [],
    lostQuotes: [],
    quoteAvg: [],
    revenue: [],
  };

  chartResponse?.data?.CustomerQuotes?.forEach((quotes) => {

    let quoteAvgValue = 0;
      if(quotes?.revenue){
          quoteAvgValue = +quotes?.revenue / +quotes?.open_quotes;
      }

    obj.quoteDates.push(moment(quotes.quote_date).format(date_format));
    obj.totalQuotes.push(+quotes?.total_quotes || 0);
    obj.wonQuotes.push(+quotes?.won_quotes || 0);
    obj.lostQuotes.push(+quotes?.lost_quotes || 0);
    obj.quoteAvg.push(+quoteAvgValue || 0);
    obj.revenue.push(+quotes?.revenue || 0);

  });

  return obj;

}
// forecast functions END 


// stats report functions START

async function getConversionChartData({ start_date, end_date }) {

  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
    type: "conversion"
  };

  const chartResponse = await quoteReportConversion({ payload: request });

  const conversionChart = chartResponse?.data?.CustomerQuotes[0];
  
  const data = [];
  
  data['totalQuotes'] = conversionChart?.total_quotes;

  // Coversion chart START
  let createdColor = "#"+Math.floor(Math.random()*16777215).toString(16);
  let wonColor = "#"+Math.floor(Math.random()*16777215).toString(16);
  let lostColor = "#"+Math.floor(Math.random()*16777215).toString(16);

  const conversionSeriesData = [
    {
      "name": "Created", 'y' : parseInt(conversionChart?.total_quotes), 'color' : createdColor
    },
    {
      "name": "Won", 'y' : parseInt(conversionChart?.won_quotes), 'color' : wonColor
    },
    {
      "name": "Lost", 'y' : parseInt(conversionChart?.lost_quotes), 'color' : lostColor
    },  
  ];

  data['conversionSeries'] = conversionSeriesData;

  let conversion = 0;
  if(conversionChart?.won_quotes > 0){
        conversion = (+conversionChart?.won_quotes * 100) / (+conversionChart?.won_quotes + +conversionChart?.lost_quotes);
  }

  data['quoteConversion'] = addComma(conversion.toFixed(2));

  // Coversion chart END


  // STATS chart START

  let OpenColor = "#"+Math.floor(Math.random()*16777215).toString(16);
  let wonColor1 = "#"+Math.floor(Math.random()*16777215).toString(16);
  let lostColor1 = "#"+Math.floor(Math.random()*16777215).toString(16);


  const statsSeriesData = [
    {
      "name": "Open", 'y' : parseInt(conversionChart?.open_quotes), 'color' : OpenColor
    },
    {
      "name": "Won", 'y' : parseInt(conversionChart?.won_quotes), 'color' : wonColor1
    },
    {
      "name": "Lost", 'y' : parseInt(conversionChart?.lost_quotes), 'color' : lostColor1
    },  
  ];

  data['statsSeriesData'] = statsSeriesData;



  // STATS chart END


  return data;


}


async function getCategoryChartData({ start_date, end_date }) {

  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
    type: "category"
  };

  const chartResponse = await quoteReportCategory({ payload: request });

  const data = [];
  const seriesData = [];
  
  chartResponse?.data?.CustomerQuotes?.forEach((quotes) => {

    let categoryColor = "#"+Math.floor(Math.random()*16777215).toString(16);
    const a = {};
    a['name'] = quotes?.name;
    a['y'] = parseInt(+quotes?.total_quote || 0);
    a['color'] = categoryColor
    
    seriesData.push(a);

    

  });

  data['series'] = seriesData;
  
  
  return data;
 
}


/** Single call utilities End */


// quote report by revenue
export async function getQuoteReportData({ start_date, end_date }) {
  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
    type: "revenue"
  };
  
  const chartData = await getRevenueChartData(request);
  const chartTable = await getRevenueTableList(request);

  return { chartData, chartTable };
}


// quote report by forecast
export async function getQuoteReportForecastData({ start_date, end_date }) {
  const request = {
    start_date: start_date,
    end_date: end_date,
    workshop_id: getWorkshopId(),
    type: "forecast"
  };
  
  const forecastChart = await getForecastChartData(request);
  const forecastTable = await getForecastTableList(request);

  return { forecastChart, forecastTable };
}

// quote report by stats
export async function getQuoteReportStatsData({ start_date, end_date }) {
 
  const conversionChart = await getConversionChartData({ start_date, end_date });
  const categoryChart = await getCategoryChartData({ start_date, end_date });
  const statsChart = [];
  
  return { conversionChart, categoryChart, statsChart };
}