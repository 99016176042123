import { call, put, takeLatest } from "redux-saga/effects";
import {
  getCompletedInvoicePayload,
  getRaisedInvoicePayload,
} from "store/invoices/api";
import { getWorkshopId } from "utils/getWorkshopId";
import {
  getReceiptDetailFail,
  getReceiptDetailSuccess,
  startPaymentReceiptFail,
  startPaymentReceiptSuccess,
  startStatementPaymentFail,
  startStatementPaymentSuccess,
} from "./actions";
import {
  CLEAR_PAYMENT_RECEIPT,
  CLEAR_PAYMENT_RECEIPT_SUCCESS,
  GET_RECEIPT_DETAILS,
  START_PAYMENT_RECEIPT,
  START_STATEMENT_PAYMENT,
} from "./actionType";
import { getInvoiceReciept } from "./getInvoiceReciept";
import { createInvoicePayment,createStatementPayment } from "./payInvoice";
// import { createStatementPayment } from "../../helpers/backendHelper";

function* onStartReceiptPayment(action) {
  try {
    const { payload } = action;
    const data = yield call(createInvoicePayment, payload);
    yield put(startPaymentReceiptSuccess(data));
  } catch (error) {
    yield put(startPaymentReceiptFail(error));
  }
}

function* onStartStatementPayment(action) {
  try {
    const { payload } = action;
    const data = yield call(createStatementPayment, payload);
    yield put(startStatementPaymentSuccess(data));
  } catch (error) {
    yield put(startStatementPaymentFail(error));
  }
}

function* onGetReceiptDetails(action) {
  try {
    const { payload } = action;
    const data = yield call(getInvoiceReciept, payload);
    yield put(getReceiptDetailSuccess(data));
  } catch (error) {
    yield put(getReceiptDetailFail());
  }
}

function* onClearPaymentReceipt(action) {
  try {
    yield put(getRaisedInvoicePayload());
    yield put(getCompletedInvoicePayload(getWorkshopId()));
    yield put({ type: CLEAR_PAYMENT_RECEIPT_SUCCESS });
  } catch (err) {}
}

function* invoicePaymentSaga() {
  yield takeLatest(START_PAYMENT_RECEIPT, onStartReceiptPayment);
  yield takeLatest(START_STATEMENT_PAYMENT, onStartStatementPayment);
  yield takeLatest(GET_RECEIPT_DETAILS, onGetReceiptDetails);
  yield takeLatest(CLEAR_PAYMENT_RECEIPT, onClearPaymentReceipt);
}

export default invoicePaymentSaga;