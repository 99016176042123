import { DatePicker } from 'antd'
import React from 'react'
import PayHead from './PayHead'
import Wrapped from './Wrapped'
import cash_img from '../../../../assets/images/money.svg';
import Cards from './Cards';

const CashPaymentModal = ({ onChangeClick, show, onClose }) => {
    const payOptions = [
        {

        }
    ]
    return (
        <Wrapped show={show} onHide={onClose}>
            <div className="px-2">
                <PayHead onChangeClick={onChangeClick} image={cash_img} label="Cash Payment" />
                <div className="row my-2">
                    <div className="col-5">
                        <label htmlFor="date">Date</label>
                        <DatePicker id='date' style={{ padding: ".375rem .75rem", fontSize: 16, lineHeight: 1.5, display: 'block' }} className='form-control' />
                    </div>
                    <div className="col-7">
                        <label htmlFor="note">Note (Optional)</label>
                        <input id='note' placeholder='e.g. Payment for entire order' className='form-control' />
                    </div>
                </div>
            </div>
        </Wrapped>
    )
}

export default CashPaymentModal