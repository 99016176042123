import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { GET_SUBSCRIPTION_PLANS } from "../../../store/subscription/actionTypes";
import { GET_WORKSHOP_DETAIL } from "../../../store/companyDetails/actionTypes";
import { unserializeArr } from "../../../utils/unserialize";
import LoaderImage from "../../../assets/images/page-loader.svg";
import CheckoutForm from "./checkoutForm";
import "../subscription-plan.scss";
import CapricornForm from "./capricornForm";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const stripePromise = loadStripe(process.env.REACT_APP_SUBSCRIPTION_PROMISE);

const SubscriptionPlan = () => {
  const dispatch = useDispatch();
  const localStorageData = JSON.parse(localStorage.getItem('data'));
  const gsPercentage = localStorageData?.workshop?.workshop_other_details[0]?.country?.part_gst || '12';

  const [subscriptionCycle, setSubscriptionCycle] = useState("monthly");
  const [userPackageArr, setUserPackageArr] = useState([]);
  const [smsPackagesArr, setSmsPackagesArr] = useState([]);
  const [inspection, setInspection] = useState([]);
  const [form, setForm] = useState([]);
  const [applicationConfiguration, setApplicationConfiguration] = useState("");
  const [vinLookUpArr, setVinLookUpArr] = useState([]);
  const [addUsrDesktop, setAddUsrDesktop] = useState(0);
  const [addUsrMobile, setAddUsrMobile] = useState(0);

  const [userPlan, setUserPlan] = useState("");
  const [appConfigPlan, setAppConfigPlan] = useState("");
  const [smsPlan, setSmsPlan] = useState("");
  const [vinPlan, setVinPlan] = useState("");
  const [capricornNumber, setCapricornNumber] = useState("");
  const [paymentType, setPaymentType] = useState("0");

  const { subscriptionPlans, loadingData, success } = useSelector(
    (state) => state.SubscriptionReducer
  );
  const { workShopDetails, loading } = useSelector(
    (state) => state.CompanyDetailReducer
  );

  useEffect(() => {
    dispatch({
      type: GET_WORKSHOP_DETAIL,
      payload: {
        id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });
  }, []);

  useEffect(() => {
    if (workShopDetails) {
      dispatch({
        type: GET_SUBSCRIPTION_PLANS,
        payload: {
          get: "all",
          conditions: {
            is_active: 1,
            country: workShopDetails.country.id
          },
        },
      });

      setCapricornNumber(workShopDetails?.capricorn_number || "");
      setPaymentType(workShopDetails?.payment_type);
    }

  }, [workShopDetails]);

  useEffect(() => {
    setUserPackageArr([]);
    setSmsPackagesArr([]);
    setVinLookUpArr([]);
    if (subscriptionPlans.length > 0) {
      subscriptionPlans.forEach((element) => {
        if (element.plan_type === "user") {
          if (
            element.select_default_plan !== "" &&
            typeof element.select_default_plan === "string"
          ) {
            element.select_default_plan = unserializeArr(
              element.select_default_plan
            );
          }
          setUserPackageArr((prevArray) => [...prevArray, element]);
        } else if (element.plan_type === "sms") {
          setSmsPackagesArr((prevArray) => [...prevArray, element]);
        } else if (element.plan_type === "form") {
          setForm(element);
        } else if (element.plan_type === "inspection") {
          setInspection(element);
        } else if (element.plan_type === "application_configuration") {
          setApplicationConfiguration(element);
        } else if (element.plan_type === "vin_lookup") {
          setVinLookUpArr((prevArray) => [...prevArray, element]);
        }
      });
    }
  }, [subscriptionPlans]);

  const handleDecrement = (type) => {
    if (type === "desktop") {
      if (addUsrDesktop > 0) {
        setAddUsrDesktop(addUsrDesktop - 1);
      }
    } else {
      if (addUsrMobile > 0) {
        setAddUsrMobile(addUsrMobile - 1);
      }
    }
  };
  const handleIncrement = (type) => {
    if (type === "desktop") {
      setAddUsrDesktop(addUsrDesktop + 1);
    } else {
      setAddUsrMobile(addUsrMobile + 1);
    }
  };

  const monthlySubscriptionSubTotal =
    (userPlan
      ? subscriptionCycle === "monthly"
        ? userPlan.monthly_charge
        : userPlan.yearly_charge
      : 0) +
    (addUsrDesktop *
      (workShopDetails ? workShopDetails.country.extra_desktop_user_price : 0) +
      addUsrMobile *
      (workShopDetails
        ? workShopDetails.country.extra_mobile_user_price
        : 0));


  const appConfigPlanPrice = (appConfigPlan
    ? subscriptionCycle === "monthly"
      ? appConfigPlan.monthly_charge
      : appConfigPlan.yearly_charge
    : 0);
  const gstTotal = parseFloat(((monthlySubscriptionSubTotal + appConfigPlanPrice) * gsPercentage) / 100).toFixed(2);

  const addonSubscriptionSubTotal =
    (appConfigPlan
      ? subscriptionCycle === "monthly"
        ? appConfigPlan.monthly_charge
        : appConfigPlan.yearly_charge
      : 0) +
    (smsPlan ? smsPlan.monthly_charge : 0) +
    (vinPlan ? vinPlan.monthly_charge : 0);

  //const addonSubscriptionSubTotal = appConfigPlan ? (subscriptionCycle === "monthly" ? appConfigPlan.monthly_charge : appConfigPlan.yearly_charge) : 0;

  const totalCharge = monthlySubscriptionSubTotal + addonSubscriptionSubTotal + parseFloat(gstTotal);

  const processingFee = parseFloat((totalCharge * 1.95) / 100).toFixed(2);
  if (loadingData || loading) {
    return (
      <div className="page-loader d-flex align-center justify-center">
        <img src={LoaderImage} alt="Loading"></img>
      </div>
    );
  }

  return (
    <>
      <div className="right-section-wrapper">
        <div className="card">
          <div className="card-body">
            <div className="text-center">
              <div className="d-inline-flex align-items-center">
                <p className="mb-0 me-2">
                  Select the BEST plan for your business
                </p>
                <ToggleButtonGroup
                  type="radio"
                  name="subscription-cycle"
                  value={subscriptionCycle}
                >
                  <ToggleButton
                    value="monthly"
                    id="subscription-cycle-1"
                    variant="outline-primary"
                    className="btn-rounded mb-0 font-size-12"
                    checked={subscriptionCycle === "monthly"}
                    onChange={(e) => setSubscriptionCycle(e.target.value)}
                  >
                    Monthly
                  </ToggleButton>
                  <ToggleButton
                    value="yearly"
                    id="subscription-cycle-2"
                    variant="outline-primary"
                    className="btn-rounded mb-0 font-size-12"
                    checked={subscriptionCycle === "yearly"}
                    onChange={(e) => setSubscriptionCycle(e.target.value)}
                  >
                    Annually
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="card-header px-0">
                  <div className="d-flex">
                    <h6 className="background-gradient rounded mb-0">
                      Subscription
                    </h6>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    {userPackageArr.map((plan) => (

                      <OverlayTrigger
                        key={plan.name === "Ultimate" ? "left" : "right"}
                        placement={plan.name === "Ultimate" ? "left" : "right"}
                        overlay={
                          <Tooltip id="sub-tooltip-top">
                            {plan.name === "Growth" &&
                              <>
                                <b>What’s included:</b>
                                <ul class="b">
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Bookings, Quotes, Jobs, Invoices</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Customer / Vehicle CRM</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Parts / Kits, Stock, PO</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Tech App</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>SMS, Rego Lookup capability</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Accounts and Supplier integration</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>2 Users</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>24/7 Online videos, human technical support</li>
                                </ul>
                                <hr />
                                <b>Additional User licence:</b>
                                <ul class="b">
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$15 per month</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$10c per SMS</li>
                                </ul>
                              </>
                            }

                            {plan.name === "Pro" &&
                              <>
                                <b>What’s included:</b>
                                <ul class="b">
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Growth items plus</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Digital Inspection checklist</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Live Customer approvals</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Multiple Vehicle types</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Parts Stock Take</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Daily / Job Clocking</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Reports</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>5 Users</li>
                                </ul>
                                <hr />
                                <b>Additional User licence:</b>
                                <ul class="b">
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$15 per month</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$10c per SMS</li>
                                </ul>
                              </>
                            }


                            {plan.name === "Ultimate" &&
                              <>
                                <b>What’s included:</b>
                                <ul class="b">
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Growth / Pro items plus</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>10 Users</li>
                                </ul>
                                <hr />
                                <b>Additional User licence:</b>
                                <ul class="b">
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$10 per month</li>
                                  <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$10c per SMS</li>
                                </ul>
                              </>
                            }
                          </Tooltip>

                        }
                      >
                        <div className="col-md-4" key={plan.id}>
                          <div
                            className={
                              userPlan.id !== plan.id
                                ? "pricingTable mx-auto"
                                : "pricingTable active mx-auto"
                            }
                          >
                            <h3>{plan.name}</h3>
                            <div className="price-value">
                              {subscriptionCycle === "monthly" ? (
                                <>
                                  <span>{`${workShopDetails?.country?.symbol}${plan.monthly_charge}`}</span>
                                  <span className="month"> Monthly</span>
                                </>
                              ) : (
                                <>
                                  <span>{`${workShopDetails?.country?.symbol}${plan.yearly_charge}`}</span>
                                  <span className="month"> Annually</span>
                                </>
                              )}
                            </div>
                            <div className="pricing-content">
                              <ul>
                                <li>
                                  Incl.{" "}
                                  {Object.keys(plan.select_default_plan).length >
                                    0
                                    ? plan.select_default_plan.user_limit
                                    : 0}
                                  * user |
                                  <span>
                                    {subscriptionCycle === "monthly"
                                      ? `${workShopDetails?.country?.symbol}${plan.yearly_charge} Annually`
                                      : `${workShopDetails?.country?.symbol}${plan.monthly_charge} Monthly`}
                                  </span>
                                </li>
                              </ul>
                              {userPlan.id !== plan.id ? (
                                <div className="pricingTable-signup">
                                  <button
                                    type="button"
                                    onClick={() => setUserPlan(plan)}
                                  >
                                    Select plan
                                  </button>
                                </div>
                              ) : (
                                <div className="pricingTable-signup">
                                  <button
                                    type="button"
                                    onClick={() => setUserPlan("")}
                                  >
                                    Unselect plan
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                      </OverlayTrigger>
                    ))}
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="card">
                        <div className="card-header py-2 font-size-13">
                          Add additional users for plan:
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="d-flex px-0 px-xl-4 flex-nowrap justify-content-center align-items-start">
                                <h4 className="mb-0 me-2 font-size-15 text-nowrap pt-2">
                                  Desktop
                                </h4>
                                <div className="d-flex flex-nowrap align-items-start">
                                  <button
                                    className="btn btn-icon btn-danger rounded-circle me-2 flex-shrink-0"
                                    onClick={() => handleDecrement("desktop")}
                                  >
                                    <i className="fa fa-minus"></i>
                                  </button>
                                  <div>
                                    <input
                                      type="text"
                                      value={addUsrDesktop}
                                      className="form-control text-center"
                                      readOnly
                                    />
                                    <div className="font-size-14">
                                      {workShopDetails?.country?.symbol}{" "}
                                      {workShopDetails
                                        ? workShopDetails.country
                                          .extra_desktop_user_price
                                        : 0}{" "}
                                      per user
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-icon btn-success rounded-circle ms-2 flex-shrink-0"
                                    onClick={() => handleIncrement("desktop")}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex px-0 px-xl-4 flex-nowrap justify-content-center align-items-start">
                                <h4 className="mb-0 me-2 font-size-15 text-nowrap pt-2">
                                  Mobile App
                                </h4>
                                <div className="d-flex flex-nowrap align-items-start">
                                  <button
                                    className="btn btn-icon btn-danger rounded-circle me-2 flex-shrink-0"
                                    onClick={() => handleDecrement("mobile")}
                                  >
                                    <i className="fa fa-minus"></i>
                                  </button>
                                  <div>
                                    <input
                                      type="text"
                                      value={addUsrMobile}
                                      className="form-control text-center"
                                      readOnly
                                    />
                                    <div className="font-size-14">
                                      {workShopDetails?.country?.symbol}{" "}
                                      {workShopDetails
                                        ? workShopDetails.country
                                          .extra_mobile_user_price
                                        : 0}{" "}
                                      per user
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-icon btn-success rounded-circle ms-2 flex-shrink-0"
                                    onClick={() => handleIncrement("mobile")}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="font-size-14 font-weight-bold text-danger">
                      * Subscription Pricing Excludes GST
                    </div>
                  </div>

                </div>
              </div>
              {/* {
                applicationConfiguration && applicationConfiguration !== "" && (<div className="col-lg-3 mb-3">
                  <div className="card-header px-0">
                    <div className="d-flex">
                      <h6 className="background-gradient rounded mb-0">
                        BRANDED APP
                      </h6>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className={
                        appConfigPlan.id !== applicationConfiguration.id
                          ? "pricingTable mx-auto"
                          : "pricingTable active mx-auto"
                      }
                    >
                      <h3>{applicationConfiguration.name}</h3>
                      <div className="price-value">
                        {subscriptionCycle === "monthly" ? (
                          <>
                            <span>{`${workShopDetails?.country?.symbol}${applicationConfiguration.monthly_charge}`}</span>
                            <span className="month"> Monthly</span>
                          </>
                        ) : (
                          <>
                            <span>{`${workShopDetails?.country?.symbol}${applicationConfiguration.yearly_charge}`}</span>
                            <span className="month"> Annually</span>
                          </>
                        )}
                      </div>
                      <div className="pricing-content">
                        <ul>
                          {subscriptionCycle === "monthly" ? (
                            <li>
                              {`${workShopDetails?.country?.symbol}${applicationConfiguration.yearly_charge}`}{" "}
                              Annually
                            </li>
                          ) : (
                            <li>
                              {`${workShopDetails?.country?.symbol}${applicationConfiguration.monthly_charge}`}{" "}
                              Monthly
                            </li>
                          )}
                        </ul>
                        {appConfigPlan.id !== applicationConfiguration.id ? (
                          <div className="pricingTable-signup">
                            <button
                              type="button"
                              onClick={() =>
                                setAppConfigPlan(applicationConfiguration)
                              }
                            >
                              Select plan
                            </button>
                          </div>
                        ) : (
                          <div className="pricingTable-signup">
                            <button
                              type="button"
                              onClick={() => setAppConfigPlan("")}
                            >
                              Unselect plan
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="pt-4">
                      <div className="col-md-6 mx-auto text-center">
                        <i>
                          This module will be added/integrated into your workshop
                          application and the change will be added to your
                          subscription charge.
                        </i>
                      </div>
                    </div>
                  </div>
                </div>)
              } */}
              <div className="col-lg-8 mb-3">
                <div className="card-header px-0">
                  <div className="d-flex">
                    <h6 className="background-gradient rounded mb-0">SMS</h6>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    {smsPackagesArr && smsPackagesArr.length > 0 && (
                      <>
                        {smsPackagesArr.map((elmnt) => (
                          <div className="col-md-6 mb-3" key={elmnt.id}>
                            <div
                              className={
                                smsPlan.id !== elmnt.id
                                  ? "pricingTable mx-auto"
                                  : "pricingTable active mx-auto"
                              }
                            >
                              <h3>{elmnt.name}</h3>
                              <div className="price-value">
                                <span>{`${workShopDetails?.country?.symbol}${elmnt.monthly_charge}`}</span>{" "}
                                <span className="month font-size-14">
                                  Unused sms will rollover to next month
                                </span>
                              </div>
                              <div className="pricing-content">
                                <ul>
                                  <li>Incl. Approx {elmnt.sms_limit} sms</li>
                                </ul>
                                {smsPlan.id !== elmnt.id ? (
                                  <div className="pricingTable-signup">
                                    <button
                                      type="button"
                                      onClick={() => setSmsPlan(elmnt)}
                                    >
                                      Select plan
                                    </button>
                                  </div>
                                ) : (
                                  <div className="pricingTable-signup">
                                    <button
                                      type="button"
                                      onClick={() => setSmsPlan("")}
                                    >
                                      Unselect plan
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <div className="pt-4">
                    <div className="text-center">
                      <div className="mb-3">
                        The SMS packages does NOT expire at the end of month, the un-used credit will rollover to the next month.
                      </div>
                      <p className="mb-3">
                        Your balance will auto renew when you reach 20% remaining.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="card-header px-0">
                  <div className="d-flex">
                    <h6 className="background-gradient rounded mb-0">
                      REGO LOOKUP
                    </h6>
                  </div>
                </div>
                <div className="card-body">
                  {vinLookUpArr && vinLookUpArr.length > 0 && (
                    <>
                      {vinLookUpArr.map((elmnt) => (
                        <div
                          className={
                            vinPlan.id !== elmnt.id
                              ? "pricingTable mx-auto mb-3"
                              : "pricingTable active mx-auto mb-3"
                          }
                          key={elmnt.id}
                        >
                          <h3>{elmnt.name}</h3>
                          <div className="price-value">
                            <span>{`${workShopDetails?.country?.symbol}${elmnt.monthly_charge}`}</span>{" "}
                            <span className="month font-size-14">
                              Unused request will rollover to next month
                            </span>
                          </div>
                          <div className="pricing-content">
                            <ul>
                              <li>
                                Incl. Approx {elmnt.vin_request} request
                              </li>
                            </ul>
                            {vinPlan.id !== elmnt.id ? (
                              <div className="pricingTable-signup">
                                <button
                                  type="button"
                                  onClick={() => setVinPlan(elmnt)}
                                >
                                  Select plan
                                </button>
                              </div>
                            ) : (
                              <div className="pricingTable-signup">
                                <button
                                  type="button"
                                  onClick={() => setVinPlan("")}
                                >
                                  Unselect plan
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <div className="pt-4">
                    <div className="text-center">
                      <p className="mb-3">The VIN lookup package does NOT expire at the end of month, the un-used credit will rollover to the next month.</p>
                      <p className="mb-3">Your balance will auto renew when you reach 20% remaining.</p>
                    </div>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="font-size-14 font-weight-bold text-danger">
                  * SMS & VIN Lookup Packages Include GST
                </div>
              </div>


              <div className="col-lg-12">
                <div className="card-header px-0">
                  <div className="d-flex">
                    <h6 className="background-gradient rounded mb-0">
                      SUMMARY
                    </h6>
                  </div>
                </div>
                <div className="card-body pb-0 pt-0">
                  <div className="row">
                    <div className="col-md-4 price-summary">
                      <table>
                        <tbody>
                          <tr>
                            <td className="price-summary-title">
                              Monthly Subscription
                            </td>
                          </tr>
                          <tr>
                            <td> Subscription Plan</td>
                            <td>
                              {workShopDetails?.country?.symbol}
                              {userPlan
                                ? subscriptionCycle === "monthly"
                                  ? userPlan.monthly_charge
                                  : userPlan.yearly_charge
                                : 0}
                            </td>
                          </tr>
                          <tr>
                            <td>Sub Additional Users</td>
                            <td>
                              {workShopDetails?.country?.symbol}
                              {addUsrDesktop *
                                workShopDetails?.country
                                  ?.extra_desktop_user_price +
                                addUsrMobile *
                                workShopDetails?.country
                                  ?.extra_mobile_user_price}
                            </td>
                          </tr>
                          <tr>
                            <td>Sub Total</td>
                            <td>{workShopDetails?.country?.symbol}{monthlySubscriptionSubTotal}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-4 price-summary">
                      <table>
                        <tbody>
                          <tr>
                            <td className="price-summary-title">
                              Addon Subscription
                            </td>
                          </tr>
                          {/* <tr>
                            <td>Customised Cust App</td>
                            <td>
                              {workShopDetails?.country?.symbol}
                              {appConfigPlan
                                ? subscriptionCycle === "monthly"
                                  ? appConfigPlan.monthly_charge
                                  : appConfigPlan.yearly_charge
                                : 0}
                            </td>
                          </tr> */}
                          <tr>
                            <td>SMS Plan</td>
                            <td>{workShopDetails?.country?.symbol}{smsPlan ? smsPlan.monthly_charge : 0}</td>
                          </tr>
                          <tr>
                            <td>Rego Lookup Plan</td>
                            <td>{workShopDetails?.country?.symbol}{vinPlan ? vinPlan.monthly_charge : 0}</td>
                          </tr>
                          <tr>
                            <td>Sub Total</td>
                            <td>{workShopDetails?.country?.symbol}{addonSubscriptionSubTotal}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-4 price-summary">
                      <table>
                        <tbody>
                          <tr>
                            <td className="price-summary-title">Summary</td>
                          </tr>
                          <tr>
                            <td>Monthly Subscription</td>
                            <td>{workShopDetails?.country?.symbol}{parseFloat(monthlySubscriptionSubTotal).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td>Addon Subscription</td>
                            <td>{workShopDetails?.country?.symbol}{parseFloat(addonSubscriptionSubTotal).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td>GST</td>
                            <td>{workShopDetails?.country?.symbol}{gstTotal}</td>
                          </tr>
                          <tr>
                            <td>Sub Total</td>
                            <td>
                              <h3>
                                {workShopDetails?.country?.symbol}
                                {monthlySubscriptionSubTotal +
                                  addonSubscriptionSubTotal + parseFloat(gstTotal)}
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>Payment Process fee 1.95%</td>
                            <td>
                              <h3>{workShopDetails?.country?.symbol}{processingFee}</h3>
                            </td>
                          </tr>
                          <tr className="bg-inverse">
                            <td>Total Charge</td>
                            <td>
                              <h3>
                                {workShopDetails?.country?.symbol}
                                {parseFloat((monthlySubscriptionSubTotal + addonSubscriptionSubTotal) + parseFloat(processingFee) + parseFloat(gstTotal)).toFixed(2)}
                              </h3>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {subscriptionCycle === "monthly" ? (

              <div className="row border-top pt-4">
                <div className="col-md-2">
                  <h4 className="mb-3">Payment Details</h4>
                  <div className="d-inline-flex align-items-center">

                    <ToggleButtonGroup
                      type="radio"
                      name="payment-type"
                      value={paymentType}
                    >
                      <ToggleButton
                        value="0"
                        id="payment-type-1"
                        variant="outline-primary"
                        className="btn-rounded mb-0 font-size-12"
                        checked={paymentType === "0"}
                        onChange={(e) => setPaymentType("0")}
                      >
                        Stripe
                      </ToggleButton>
                      <ToggleButton
                        value="1"
                        id="payment-type-2"
                        variant="outline-primary"
                        className="btn-rounded mb-0 font-size-12"
                        checked={paymentType === "1"}
                        onChange={(e) => setPaymentType("1")}
                        disabled={workShopDetails?.country?.country_name !== "Australia"}
                      >
                        Capricorn
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>

                </div>


                <div className="row col-md-10">
                  {/* Stripe Payment */}
                  <div className="col-md-6 d-flex justify-content-center" style={{ borderRight: '1px solid black' }} >
                    <p className='text-center' >
                      Subscription activated, billed today and on this anniversary
                      date each month
                    </p>

                    <div className={"col-md-10 mt-3 " + (paymentType === '1' ? 'disableDiv' : '')} >
                      <div className="card shadow">
                        <div className="card-body">
                          <Elements stripe={stripePromise}>
                            <CheckoutForm
                              user_plan_id={userPlan ? userPlan.id : ""}
                              subscriptionCycle={subscriptionCycle}
                              total_amount={
                                monthlySubscriptionSubTotal +
                                addonSubscriptionSubTotal + parseFloat(gstTotal)
                              }
                              payment_processing_fees={processingFee}
                              desktop_user={addUsrDesktop}
                              desktop_user_price={
                                workShopDetails?.country?.extra_desktop_user_price
                              }
                              mobile_user={addUsrMobile}
                              mobile_user_price={
                                workShopDetails?.country?.extra_mobile_user_price
                              }
                              sms_plan_id={smsPlan ? smsPlan.id : ""}
                              vin_plan_id={vinPlan ? vinPlan.id : ""}
                              app_config_plan_id={appConfigPlan !== "" ? appConfigPlan.id : ""}
                              success={success}
                              paymentType={paymentType}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/* Capricorn  */}
                  <CapricornForm
                    user_plan_id={userPlan ? userPlan.id : ""}
                    subscriptionCycle={subscriptionCycle}
                    total_amount={
                      monthlySubscriptionSubTotal +
                      addonSubscriptionSubTotal + parseFloat(gstTotal)
                    }
                    payment_processing_fees={processingFee}
                    desktop_user={addUsrDesktop}
                    desktop_user_price={
                      workShopDetails?.country?.extra_desktop_user_price
                    }
                    mobile_user={addUsrMobile}
                    mobile_user_price={
                      workShopDetails?.country?.extra_mobile_user_price
                    }
                    sms_plan_id={smsPlan ? smsPlan.id : ""}
                    vin_plan_id={vinPlan ? vinPlan.id : ""}
                    app_config_plan_id={appConfigPlan !== "" ? appConfigPlan.id : ""}
                    success={success}
                    capricornNumber={capricornNumber}
                    setCapricornNumber={setCapricornNumber}
                    paymentType={paymentType}
                    actionType="add"
                  />
                </div>



              </div>
            ) : (
              <div className="row border-top pt-4">
                <div className="col-md-3">
                  <h4 className="mb-3">Payment Details</h4>
                  <p>
                    (Subscription activated, billed today and on this anniversary
                    date each month)
                  </p>
                </div>
                <div className="col-md-5">
                  <div className="card shadow">
                    <div className="card-body">
                      <Elements stripe={stripePromise}>
                        <CheckoutForm
                          user_plan_id={userPlan ? userPlan.id : ""}
                          subscriptionCycle={subscriptionCycle}
                          total_amount={
                            monthlySubscriptionSubTotal +
                            addonSubscriptionSubTotal + parseFloat(gstTotal)
                          }
                          payment_processing_fees={processingFee}
                          desktop_user={addUsrDesktop}
                          desktop_user_price={
                            workShopDetails?.country?.extra_desktop_user_price
                          }
                          mobile_user={addUsrMobile}
                          mobile_user_price={
                            workShopDetails?.country?.extra_mobile_user_price
                          }
                          sms_plan_id={smsPlan ? smsPlan.id : ""}
                          vin_plan_id={vinPlan ? vinPlan.id : ""}
                          app_config_plan_id={appConfigPlan !== "" ? appConfigPlan.id : ""}
                          success={success}
                        />
                      </Elements>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div >
        </div >
      </div >
    </>
  );
};

export default SubscriptionPlan;
