import { takeLatest, put, call } from "redux-saga/effects";

import {
  ADD_SECURITY_GROUP_DETAILS,
  GET_SECURITY_GROUP_DETAILS,
  LOAD_SECURITY_GROUP,
  LOAD_SECURITY_GROUP_FAIL,
  LOAD_SECURITY_GROUP_SUCCESS,
  UPDATE_SECURITY_GROUP_DETAILS,
} from "./actionTypes";

import {
  getSecurityGroupDetailSuccess,
  getSecurityGroupDetailFail,
  updateSecurityGroupDetailSuccess,
  updateSecurityGroupDetailFail,
} from "./actions";

import {
  addSecruityGroup,
  getSecruityGroup,
  updateSecruityGroup,
} from "../../helpers/backendHelper";

import { showError, showSuccess } from "../../utils/alertMessage";
import { unserializeArr } from "utils/unserialize";
import { getWorkshopId } from "utils/getWorkshopId";

// Get security group details
function* onGetSecurityGroupDetails(data) {
  try {
    const response = yield call(getSecruityGroup, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSecurityGroupDetailSuccess(response));
      } else {
        yield put(getSecurityGroupDetailFail(response.message));
      }
    } else {
      yield put(getSecurityGroupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSecurityGroupDetailFail(error.response));
  }
}

// ADD security group details

function* onAddSecurityGroupDetails(data) {
  try {
    const response = yield call(addSecruityGroup, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateSecurityGroupDetailSuccess(response));
        showSuccess("Company details updated successfully.");
        yield put({
          type: LOAD_SECURITY_GROUP,
          payload: {
            workshop_id: getWorkshopId(),
          },
        });
      } else {
        yield put(updateSecurityGroupDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateSecurityGroupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateSecurityGroupDetailFail(error.response));
  }
}

// UPDATE security group details

function* onUpdateSecurityGroupDetails(data) {
  try {
    const response = yield call(updateSecruityGroup, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateSecurityGroupDetailSuccess(response));
        yield put({
          type: LOAD_SECURITY_GROUP,
          payload: {
            workshop_id: getWorkshopId(),
          },
        });
        showSuccess("Company details updated successfully.");
      } else {
        yield put(updateSecurityGroupDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateSecurityGroupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateSecurityGroupDetailFail(error.response));
  }
}

export function* onLoadSecurityGroup({ payload }) {
  try {
    if (!payload.workshop_id) {
      yield put({
        type: LOAD_SECURITY_GROUP_SUCCESS,
        payload: {},
      });
      return;
    }
    const response = yield call(getSecruityGroup, { payload });
    if (response?.code === 200) {
      const [security] = response.data.SecurityGroupsDetails;
      const data = {};
      data[10] =
        security && security.administration
          ? unserializeArr(security.administration)
          : {};
      data[5] =
        security && security.accounts ? unserializeArr(security.accounts) : {};
      data[6] =
        security && security.office ? unserializeArr(security.office) : {};
      data[7] =
        security && security.senior_mechanic
          ? unserializeArr(security.senior_mechanic)
          : {};
      data[3] =
        security && security.mechanic ? unserializeArr(security.mechanic) : [];
      data[8] =
        security &&
        security.workshop_technician &&
        unserializeArr(security.workshop_technician);
      data[9] = unserializeArr(security.mobile_technician);
      yield put({
        type: LOAD_SECURITY_GROUP_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: LOAD_SECURITY_GROUP_FAIL,
    });
  }
}

function* SecurityGroupSaga() {
  yield takeLatest(GET_SECURITY_GROUP_DETAILS, onGetSecurityGroupDetails);
  yield takeLatest(ADD_SECURITY_GROUP_DETAILS, onAddSecurityGroupDetails);

  yield takeLatest(UPDATE_SECURITY_GROUP_DETAILS, onUpdateSecurityGroupDetails);
  yield takeLatest(LOAD_SECURITY_GROUP, onLoadSecurityGroup);
}

export default SecurityGroupSaga;
