export const GET_TEMPLATE_LIST = "GET_TEMPLATE_LIST";
export const GET_TEMPLATE_LIST_SUCCESS = "GET_TEMPLATE_LIST_SUCCESS";
export const GET_TEMPLATE_LIST_FAIL = "GET_TEMPLATE_LIST_FAIL";

export const GET_TEMPLATE_DETAIL = "GET_TEMPLATE_DETAIL";
export const GET_TEMPLATE_DETAIL_SUCCESS = "GET_TEMPLATE_DETAIL_SUCCESS";
export const GET_TEMPLATE_DETAIL_FAIL = "GET_TEMPLATE_DETAIL_FAIL";

export const TEMPLET_DELETE = "TEMPLET_DELETE";
export const TEMPLET_DELETE_SUCCESS = "TEMPLET_DELETE_SUCCESS";
export const TEMPLET_DELETE_FAIL = "TEMPLET_DELETE_FAIL";

export const CATEGORY_DELETE = "CATEGORY_DELETE";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL";

export const SECTION_DELETE = "SECTION_DELETE";
export const SECTION_DELETE_SUCCESS = "SECTION_DELETE_SUCCESS";
export const SECTION_DELETE_FAIL = "SECTION_DELETE_FAIL";

export const GET_CATEGORIES_LIST = "GET_CATEGORIES_LIST";
export const GET_CATEGORIES_LIST_SUCCESS = "GET_CATEGORIES_LIST_SUCCESS";
export const GET_CATEGORIES_LIST_FAIL = "GET_CATEGORIES_LIST_FAIL";

export const GET_SECTION_LIST = "GET_SECTION_LIST";
export const GET_SECTION_LIST_SUCCESS = "GET_SECTION_LIST_SUCCESS";
export const GET_SECTION_LIST_FAIL = "GET_SECTION_LIST_FAIL";

export const GET_TEMPLATE_SECTIONS = "GET_TEMPLATE_SECTIONS";
export const GET_TEMPLATE_SECTIONS_SUCCESS = "GET_TEMPLATE_SECTIONS_SUCCESS";
export const GET_TEMPLATE_SECTIONS_FAIL = "GET_TEMPLATE_SECTIONS_FAIL";

export const ADD_TEMPLATE_CATEGORY = "ADD_TEMPLATE_CATEGORY";
export const ADD_TEMPLATE_CATEGORY_SUCCESS = "ADD_TEMPLATE_CATEGORY_SUCCESS";
export const ADD_TEMPLATE_CATEGORY_FAIL = "ADD_TEMPLATE_CATEGORY_FAIL";

export const ADD_TEMPLATE_SECTION = "ADD_TEMPLATE_SECTION";
export const ADD_TEMPLATE_SECTION_SUCCESS = "ADD_TEMPLATE_SECTION_SUCCESS";
export const ADD_TEMPLATE_SECTION_FAIL = "ADD_TEMPLATE_SECTION_FAIL";

export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAIL = "ADD_TEMPLATE_FAIL";

export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAIL = "UPDATE_TEMPLATE_FAIL";

export const COPY_TEMPLATE = "COPY_TEMPLATE";
export const COPY_TEMPLATE_SUCCESS = "COPY_TEMPLATE_SUCCESS";
export const COPY_TEMPLATE_FAIL = "COPY_TEMPLATE_FAIL";

export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_FAIL = "EDIT_CATEGORY_FAIL";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const EDIT_SECTION = "EDIT_SECTION";
export const EDIT_SECTION_SUCCESS = "EDIT_SECTION_SUCCESS";
export const EDIT_SECTION_FAIL = "EDIT_SECTION_FAIL";

export const UPDATE_SECTION = "UPDATE_SECTION";
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS";
export const UPDATE_SECTION_FAIL = "UPDATE_SECTION_FAIL";

export const DELETE_INSPECTION_TEMPLATE_QUE = "DELETE_INSPECTION_TEMPLATE_QUE";
export const DELETE_INSPECTION_TEMPLATE_QUE_SUCCESS = "DELETE_INSPECTION_TEMPLATE_QUE_SUCCESS";
export const DELETE_INSPECTION_TEMPLATE_QUE_FAIL = "DELETE_INSPECTION_TEMPLATE_QUE_FAIL";

