import { takeLatest, put, call } from "redux-saga/effects";
import { ADD_MANY_DEPARTMENT_ACCOUNT, GET_SYNC_ACCOUNT } from "./actionTypes";
import { GET_TAX_RATES } from "./actionTypes";
import { GET_PAYMENT_PROCESSING_FEES } from "./actionTypes";
import { GET_PAYMENT_TERMS } from "./actionTypes";
import { EDIT_PAYMENT_TERM } from "./actionTypes";
import { ADD_PAYMENT_TERM } from "./actionTypes";
import { UPDATE_PAYMENT_TERM } from "./actionTypes";
import { UPDATE_PAYMENT_PROCESSING_FEES } from "./actionTypes";
import { WORKSHOP_TAX_UPDATE } from "./actionTypes";
import { ADD_WORKSHOP_OTHER_DETAILS } from "./actionTypes";

import {
  getSyncAccountSuccess,
  getSyncAccountFail,
  getTaxRatesSuccess,
  getTaxRatesFail,
  getPaymentProcessingSuccess,
  getPaymentProcessingFail,
  getPaymentTermsSuccess,
  getPaymentTermsFail,
  editPaymentTermsSuccess,
  editPaymentTermsFail,
  addPaymentTermSuccess,
  addPaymentTermFail,
  updatePaymentTermSuccess,
  updatePaymentTermFail,
  updatePaymentProcessingFeesSuccess,
  updatePaymentProcessingFeesFail,
  updateWorkShopTaxRateSuccess,
  updateWorkShopTaxRateFail,
  workshopOtherDetailsSuccess,
  workshopOtherDetailsFail,
  addManyDepartmentAccountSuccess,
  addManyDepartmentAccountFail,
} from "./actions";

import {
  getSyncAccount,
  getTaxRates,
  getPaymentProcessingFees,
  getPaymentTerms,
  editPaymentTerm,
  addPaymentTerm,
  updatePaymentTerm,
  updatePaymentProcessingFees,
  updateWorkShopTaxRate,
  workshopOtherDetails,
  addManyDepartmentAccount,
} from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

function* onGetSyncAccount(data) {
  try {
    const response = yield call(getSyncAccount, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSyncAccountSuccess(response.data.SyncedAccounts));
      } else {
        yield put(getSyncAccountFail(response.message));
      }
    } else {
      yield put(getSyncAccountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSyncAccountFail(error.response));
  }
}

function* onGetTaxRates(data) {
  try {
    const response = yield call(getTaxRates, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTaxRatesSuccess(response.data.SyncedTaxes));
      } else {
        yield put(getTaxRatesFail(response.message));
      }
    } else {
      yield put(getTaxRatesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTaxRatesFail(error.response));
  }
}

function* onGetPaymentProcessingFees(data) {
  try {
    const response = yield call(getPaymentProcessingFees, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getPaymentProcessingSuccess(response.data.PaymentProcessingFees)
        );
      } else {
        yield put(getPaymentProcessingFail(response.message));
      }
    } else {
      yield put(getPaymentProcessingFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getPaymentProcessingFail(error.response));
  }
}
function* onGetPaymentTerms(data) {
  try {
    const response = yield call(getPaymentTerms, data);
    if (response) {
      if (response.code === 200) {
        yield put(getPaymentTermsSuccess(response.data.PaymentTerms));
      } else {
        yield put(getPaymentTermsFail(response.message));
      }
    } else {
      yield put(getPaymentTermsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getPaymentTermsFail(error.response));
  }
}
function* onEditPaymentTerms(data) {
  try {
    const response = yield call(editPaymentTerm, data.payload.id);
    if (response) {
      if (response.code === 200) {
        yield put(editPaymentTermsSuccess(response.data.PaymentTerms));
        // showSuccess(response.message);
      } else {
        yield put(editPaymentTermsFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(editPaymentTermsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getPaymentTermsFail(error.response));
  }
}
function* onAddPaymentTerms(data) {
  try {
    const response = yield call(addPaymentTerm, data);
    if (response) {
      if (response.code === 200) {
        yield put(addPaymentTermSuccess(response.data.PaymentTerms));
        showSuccess(response.message);
      } else {
        yield put(addPaymentTermFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(addPaymentTermFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(error.response);
  }
}
function* onUpdatePaymentTerms(data) {
  try {
    const response = yield call(updatePaymentTerm, data);
    if (response) {
      if (response.code === 200) {
        yield put(updatePaymentTermSuccess(response.data.PaymentTerms));
        showSuccess(response.message);
      } else {
        yield put(updatePaymentTermFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updatePaymentTermFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updatePaymentTermFail(error.response));
  }
}
function* onUpdatePaymentProcessingFees(data) {
  try {
    const response = yield call(updatePaymentProcessingFees, data);
    if (response) {
      if (response.code === 200) {
        yield put(updatePaymentProcessingFeesSuccess(response));
        showSuccess(response.message);
      } else {
        yield put(updatePaymentProcessingFeesFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updatePaymentProcessingFeesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updatePaymentProcessingFeesFail(error.response));
  }
}
function* onUpdateTaxRates(data) {
  try {
    const response = yield call(updateWorkShopTaxRate, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateWorkShopTaxRateSuccess(response));
        showSuccess(response.message);
      } else {
        yield put(updateWorkShopTaxRateFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateWorkShopTaxRateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateWorkShopTaxRateFail(error.response));
  }
}

function* getWorkshopOtherDetails(data) {
  try {
    const response = yield call(workshopOtherDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          workshopOtherDetailsSuccess(response.data.WorkshopOtherDetails)
        );
        showSuccess(response.message);
      } else {
        yield put(workshopOtherDetailsFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(workshopOtherDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(workshopOtherDetailsFail(error.response));
  }
}

function* OnAddManyDepartmentAccount(data) {
  try {
    const response = yield call(addManyDepartmentAccount, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          addManyDepartmentAccountSuccess(response.data.WorkshopDepartmentsAccountsDetails)
        );
        showSuccess(response.message);
      } else {
        yield put(addManyDepartmentAccountFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(addManyDepartmentAccountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(addManyDepartmentAccountFail(error.response));
  }
}

function* SyncAccountSaga() {
  yield takeLatest(GET_SYNC_ACCOUNT, onGetSyncAccount);
  yield takeLatest(GET_TAX_RATES, onGetTaxRates);
  yield takeLatest(GET_PAYMENT_PROCESSING_FEES, onGetPaymentProcessingFees);
  yield takeLatest(GET_PAYMENT_TERMS, onGetPaymentTerms);
  yield takeLatest(EDIT_PAYMENT_TERM, onEditPaymentTerms);
  yield takeLatest(ADD_PAYMENT_TERM, onAddPaymentTerms);
  yield takeLatest(UPDATE_PAYMENT_TERM, onUpdatePaymentTerms);
  yield takeLatest(
    UPDATE_PAYMENT_PROCESSING_FEES,
    onUpdatePaymentProcessingFees
  );
  yield takeLatest(WORKSHOP_TAX_UPDATE, onUpdateTaxRates);
  yield takeLatest(ADD_WORKSHOP_OTHER_DETAILS, getWorkshopOtherDetails);
  yield takeLatest(ADD_MANY_DEPARTMENT_ACCOUNT, OnAddManyDepartmentAccount);
}

export default SyncAccountSaga;
