export const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


export const decimalRegex = /^\d*\.?\d*$/;


export const numberRegex = /^\d*$/;

export const mobileRegex = /^\d{10}$/;

export const looselyMobileRegex = /^\d{9,11}$/;