import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_INPROGRESS_JOBS,
  GET_INPROGRESS_JOBS_COUNT,
} from "../../../store/inProcressJobs/actionTypes";
import {
  SUBMIT_JOB_SMSEMAIL_TEMPLATE,
  SEARCH_JOBS,
  GET_STATUS_COLOR,
} from "../../../store/commonApiStore/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import moment from "moment";
import Form from "react-bootstrap/Form";
import DeleteRecords from "../../../utils/deleteRecords";
import { manageStatusChange } from "../../../store/Jobs/actions";
import { deleteJobs, updateJob } from "../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../utils/alertMessage";
import { searchJobsSuccess } from "../../../store/commonApiStore/actions";
import LoaderImage from "../../../assets/images/page-loader.svg";
import { useNavigate } from "react-router-dom";
import SendEmailModal from "../../modals/sendEmailModal";
import SendSmsModal from "../../modals/sendSmsModal";
import { addComma } from "utils/numberFunction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

let InProgressJobList = ({ activeTab }) => {
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [emptyMessage, setEmptyMessage] = useState("No data available in table");
  const [stage, setStage] = useState("");
  const [status, setStatus] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [sendNotificationTo, setSendNotificationTo] = useState("");
  const [notificationTemplate, setNotificationTemplate] = useState("");
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [sendSmsModal, setSendSmsModal] = useState(false);
  const [selectedRowEmail, setSelectedRowEmail] = useState({});
  const [selectedRowPhone, setSelectedRowPhone] = useState({});
  const [selectedRowPhoneCode, setSelectedRowPhoneCode] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateFormat = storedData.date_format;
  const currencySymbol = storedData.currency;
  const deleteMsg = "Job Deleted Sucessfully";
  const send_page = "job_list";
  const deletePayloadData = {
    payload: {
      action_type: "delete",
      ids: selectedRow,
    },
  };
  const { statusColor, jobSmsData, jobEmailData, searchJobs, loadingLogins } =
    useSelector((state) => state.CommonApiStoreReducer);
  const { jobCount } = useSelector((state) => state.inProgressJobsReducer);
  let emailTemplateData = {
    jobEmailData: jobEmailData ? jobEmailData : null,
  };
  let smsTemplateData = {
    jobSmsData: jobSmsData ? jobSmsData : null,
  };

  let shareAdditionalDetailsEmail = {
    job_id: [selectedRow],
    send_method: "email",
    table: "chkBookin",
    send_page: "quote",
    sendType: "",
  };
  let shareAdditionalDetailsSms = {
    job_id: [selectedRow],
    send_method: "sms",
    table: "chkBookin",
    send_page: "quote",
    sendType: "",
  };

  const { statusChangeTab } = useSelector((state) => state.jobs);

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);

    return responseDate;
  };

  const checkStatusColor = (cell, row) => {
    let textCell = cell;
    if (cell === "InProgress") {
      textCell = "In Progress"
    }
    if (statusColor !== null) {
      return (
        <div
          style={{
            background: statusColor[cell],
          }}
          className="jobStatus"
        >
          {textCell}
        </div>
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(searchJobsSuccess({}));
    };
  }, []);

  useEffect(() => {
    setSearch("");
  }, [activeTab]);

  useEffect(() => {
    if (jobCount !== 0) {
      setTotalRecords(jobCount);
    }
  }, [jobCount]);

  useEffect(() => {
    if (statusChangeTab === "Inprogress") {
      loadData();
      dispatch(manageStatusChange(null));
    }
  }, [statusChangeTab]);

  const columns = [
    {
      dataField: "created_on",
      text: "Date",
      formatter: dateFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            if (row.is_copid_job === 1) {
              navigate(`/view_job/${row.id}`);
            } else {
              navigate(`/job_detail/${row.id}`);
            }
          }
        },
      },
    },
    {
      dataField: "job_number",
      text: "Job No.",
      formatter: (cell, row) => {
        return (
          <div>
            <span className="text-blue mr-half">{row.job_number}</span>
            {row.show_allocation_column && row.show_allocation_column == 1 &&
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Split Job</span>
                  </Tooltip>

                }
              >
                <span className="pj-tag">SP</span>
              </OverlayTrigger>
            }
            {row.customer_quotes_id > 0 &&
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Converted Quote</span>
                  </Tooltip>

                }
              >
                <span className="co-tag px-2">Q</span>
              </OverlayTrigger>
            }
            {(row.is_copy_job === "" || row.is_copy_job === 0) && row.is_copid_job === 1 &&
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Carryover</span>
                  </Tooltip>

                }
              >
                <span className="pj-tag">CO</span>
              </OverlayTrigger>
            }
            {row.is_copy_job > 0 &&
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={

                  <Tooltip id="tooltip-top">
                    <span>Carryover</span>
                  </Tooltip>

                }
              >
                <span className="co-tag">CO{row.is_copy_job}</span>
              </OverlayTrigger>
            }
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "customer.customers_details[0]",
      text: "Contact Name",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
      formatter: (data) => {
        if (data) {
          if (data?.type === '1') {
            return data
              ? `${data.company_name || ""}`
              : "";
          } else {
            return data
              ? `${data.firstname || ""} ${data.lastname || ""}`
              : "";
          }
        } else {
          return "";
        }
      },
    },
    {
      dataField: "customer.customers_details[0].mobile_number",
      text: "Contact Number",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "rego",
      text: "Rego",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "job_booking_type.name",
      text: "RO Type",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "from_date",
      text: "Scheduled",
      formatter: dateFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "user",
      text: "Tech",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
      formatter: (cell, row) => {
        if (row.user_id === 0) return row?.workshop_bays_detail?.name;
        else
          return row.user ? `${row.user.firstname} ${row.user.lastname}` : "";
      },
    },
    {
      dataField: "total",
      text: "Est Total",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
      formatter: (data) => {
        if (data == "NaN") return `${currencySymbol} 0.00`;
        else {
          return data
            ? `${currencySymbol}  ${addComma(data)}`
            : `${currencySymbol} 0.00`;
        }
      },
    },

    {
      dataField: "job_status",
      text: "Status",
      formatter: checkStatusColor,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.is_copid_job === 1) {
            navigate(`/view_job/${row.id}`);
          } else {
            navigate(`/job_detail/${row.id}`);
          }
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        const disableInvoice = !(parseInt(row?.odometer_reading)>0 && row?.next_service_id != null)

        let redirect_url = `/job_detail/${row.id}`;
        if (row.is_copid_job === 1) {
          redirect_url = `/view_job/${row.id}`;
        }

        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <Link to={redirect_url} className="" title="EDIT">
                  <i className="fa fa-pencil text-info"></i>
                  <span>Edit</span>
                </Link>
              </li>
              <li>
                <Link
                  className={disableInvoice == true ? "disable-invoice":""}
                  title={disableInvoice == true ? "Odometer & Next service date are not available.":"INVOICE"}
                  to={disableInvoice ? '#' : `/invoice-create/${row.id}`}
                >
                  <i className="la la-clipboard text-info"></i>
                  <span>Invoice</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setSendSmsModal(!sendSmsModal);
                  setSelectedRowPhone({
                    phone:
                      row.customer?.customers_details?.[0]?.mobile_code +
                      row.customer?.customers_details?.[0]?.mobile_number,
                  });
                  setSelectedRowPhoneCode({
                    phoneCode:
                      row.customer?.customers_details?.[0]?.mobile_code
                  });
                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className=" " title="SMS">
                  <label className="smsText">SMS</label>

                  <span>SMS</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setEmailModalShow(!emailModalShow);
                  setSelectedRowEmail({ email: row.customer.email });
                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className=" " title="EMAIL">
                  <i className="fa fa-envelope font-size-20 text-info"></i>

                  <span>Email</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className="delete-link" title="DELETE">
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </Link>
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(async () => {
    setLoading(true);

    if (statusColor === null) {
      dispatch({
        type: GET_STATUS_COLOR,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            status: 1,
          },
          fields: ["name", "color"],
        },
      });
    }

    loadData();
    dispatch({
      type: GET_INPROGRESS_JOBS_COUNT,

      payload: {
        get: "all",
        conditions: {
          "JobSchedules.workshop_id": workshop_id,
          "JobSchedules.job_stage": "Inprogress",
          "JobSchedules.activity_schedule_id": 0,
          "JobSchedules.is_break_time": 0,
          "JobSchedules.status": 1,
          "JobSchedules.is_copid_job": 0,
        },
        contain: {
          Users: {
            fields: ["id", "workshop_id"],
          },
          "Vehicles.Users": {
            fields: ["Users.id"],
          },
          "Customers.CustomersDetails": {
            conditions: {
              workshop_id: workshop_id,
            },
            fields: ["user_id", "id"],
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
          JobBookingTypes: {
            fields: ["name", "parent_id"],
          },
          Invoices: {
            fields: ["id"],
          },
          WorkshopBaysDetails: {
            fields: ["id"],
          },
        },

        fields: {
          COUNT: "COUNT(JobSchedules.id)",
        },
      },
    });
  }, []);

  const loadData = (page = 1, pageLimit = 10) => {
    dispatch({
      type: GET_INPROGRESS_JOBS,
      payload: {
        get: "all",
        conditions: {
          "JobSchedules.workshop_id": workshop_id,
          "JobSchedules.job_stage": "Inprogress",
          "JobSchedules.activity_schedule_id": 0,
          "JobSchedules.is_break_time": 0,
          "JobSchedules.status": 1,
          "JobSchedules.is_copid_job": 0,
        },
        contain: {
          Users: {
            fields: ["id", "workshop_id"],
          },
          "Vehicles.Users": {
            fields: ["Users.id"],
          },
          "Vehicles.Users.CustomersDetails": {
            conditions: {
              workshop_id: workshop_id,
            },
            fields: [
              "CustomersDetails.id",
              "CustomersDetails.user_id",
              "CustomersDetails.firstname",
              "CustomersDetails.lastname",
              "CustomersDetails.mobile_number",
              "CustomersDetails.customer_app",
              "CustomersDetails.type",
              "CustomersDetails.street_address",
              "CustomersDetails.suburb",
              "CustomersDetails.postcode",
              "CustomersDetails.company_name",
              "CustomersDetails.mobile_code"
            ],
          },
          "Customers":{
            fields:["id","email"]
          },
          "Customers.CustomersDetails":{
            fields:["id","user_id","workshop_id","firstname","lastname","company_name","type","mobile_number","mobile_code","customer_app","street_address","suburb","postcode","state","country"]
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
          JobBookingTypes: {
            fields: ["name", "parent_id"],
          },
          Invoices: {
            fields: [
              "id",
              "job_id",
              "created_on",
              "invoice_number",
              "total",
              "remain_balance",
            ],
          },
          WorkshopBaysDetails: {
            fields: ["id", "name"],
          },
        },
        limit: pageLimit,
        page: page,
        order: {
          "JobSchedules.id": "desc",
        },
        fields: [
          "JobSchedules.id",
          "JobSchedules.user_id",
          "JobSchedules.workshop_id",
          "JobSchedules.from_date",
          "JobSchedules.customer_quotes_id",
          "JobSchedules.description",
          "JobSchedules.created_on",
          "JobSchedules.total",
          "JobSchedules.claim_number",
          "JobSchedules.from_time",
          "JobSchedules.job_number",
          "JobSchedules.job_status",
          "JobSchedules.job_stage",
          "JobSchedules.customer_id",
          "JobSchedules.vehicle_id",
          "Vehicles.rego",
          "Vehicles.user_id",
          "Vehicles.service_due_date",
          "Vehicles.rego_due_date",
          "Vehicles.make",
          "Vehicles.model",
          "Users.id",
          "Users.firstname",
          "Users.lastname",
          "Customers.id",
          "Customers.email",
          "JobSchedules.is_copid_job",
          "JobSchedules.is_copy_job",
          "JobSchedules.rego",
          "JobSchedules.show_allocation_column",
          "JobSchedules.odometer_reading",
          "JobSchedules.next_service_id",
        ],
      },
    });

    dispatch({
      type: GET_INPROGRESS_JOBS_COUNT,
      payload: {
        get: "all",
        conditions: {
          "JobSchedules.workshop_id": workshop_id,
          "JobSchedules.job_stage": "Inprogress",
          "JobSchedules.activity_schedule_id": 0,
          "JobSchedules.is_break_time": 0,
          "JobSchedules.status": 1,
          "JobSchedules.is_copid_job": 0,
        },
        contain: {
          Users: {
            fields: ["id", "workshop_id"],
          },
          "Vehicles.Users": {
            fields: ["Users.id"],
          },
          "Customers.CustomersDetails": {
            conditions: {
              workshop_id: workshop_id,
            },
            fields: ["user_id", "id"],
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
          JobBookingTypes: {
            fields: ["name", "parent_id"],
          },
          Invoices: {
            fields: ["id"],
          },
          WorkshopBaysDetails: {
            fields: ["id"],
          },
        },

        fields: {
          COUNT: "COUNT(JobSchedules.id)",
        },
      },
    });
  };

  const getJobData = useSelector((state) => state.inProgressJobsReducer);
  useEffect(() => {
    if (Object.keys(searchJobs).length !== 0) {
      var text = [];

      let totalSearchCount = 0;
      for (var key = 0; key < 10; key++) {
        if (searchJobs[key] !== undefined) {
          totalSearchCount = searchJobs.total_count;
          text.push(searchJobs[key]);
        }
      }
      setTotalRecords(totalSearchCount);

      setJobData(text);
    } else {
      if (getJobData.jobData) {
        setJobData(getJobData.jobData);
        setTotalRecords(jobCount);
      } else if (getJobData.jobData === null) {
        setJobData([]);
        setTotalRecords(0);
      }
    }
  }, [getJobData]);

  const checkBox = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row.id]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.indexOf(row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
    onSelectAll: (isSelected) => {
      if (isSelected) {
        let ids = [];
        for (var key = 0; key < sizePerPage; key++) {
          if (jobData[key] !== undefined) ids.push(jobData[key].id);
        }
        setSelectedRow(ids);
      } else {
        setSelectedRow([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          checked={rest.checked}
          name={rest.name}
          id={rest.id}
          type="checkbox"
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <Form.Check className="custom-check secondary-check table-checkbox">
        <Form.Check.Input
          id={rest.id}
          checked={rest.checked}
          type={mode}
          className="custom-check"
          readOnly
        />
        <Form.Check.Label>
          <span className="check"></span>
        </Form.Check.Label>
      </Form.Check>
    ),
  };

  const expandRow = {
    showExpandColumn: true,

    renderer: (row) => (
      <div>
        <span dangerouslySetInnerHTML={{ __html: row.description }}></span>
      </div>
    ),
  };
  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);

    if (search === "") {
      loadData(page, sizePerPage);
    } else {
      let text = [];
      for (
        var key = (page - 1) * sizePerPage;
        key < sizePerPage * page;
        key++
      ) {
        if (searchJobs[key] !== undefined) text.push(searchJobs[key]);
      }
      setJobData(text);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setEmptyMessage("No Results Found")

    if (e.target.value !== "") {
      dispatch({
        type: SEARCH_JOBS,
        payload: {
          workshop_id: workshop_id,
          type: "Inprogress",
          keyword: e.target.value,
        },
      });
    } else {
      dispatch(searchJobsSuccess({}));
      setJobData(getJobData.jobData || []);
      setTotalRecords(jobCount);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => { },
  };

  const emptyDataMessage = () => {
    return (
      <div className={(emptyMessage == "No Results Found") ? 'noResultFound' : ''} style={{ textAlign: "center" }} >{emptyMessage} </div>
    );
  };

  const statusOption = (value) => {
    switch (value) {
      case "Inprogress":
        return (
          <>
            <option value="InProgress">In Progress</option>
            <option value="OnHold">On Hold</option>
            <option value="WaitingForParts">Waiting for Parts</option>
          </>
        );
      case "Completed":
        return (
          <>
            <option value="ReadyForInvoice" selected="selected">
              Ready for Invoice
            </option>
            <option value="Invoiced">Invoiced</option>
            <option value="Payment">Payment</option>
            <option value="Archive">Archive</option>
          </>
        );

      default:
        return <option value="">Select</option>;
    }
  };

  const handleStageChange = (e) => {
    setStage(e.target.value);
    switch (e.target.value) {
      case "Bookin":
        setStatus("Pending");
        break;
      case "Inprogress":
        setStatus("InProgress");
        break;

      case "Completed":
        setStatus("ReadyForInvoice");
        break;
    }
  };
  const changeStatus = async () => {
    if (selectedRow.length !== 0) {
      if (selectedRow.length < 2) {
        let data = {
          payload: {
            id: selectedRow[0],
            job_status: status,
            job_stage: stage,
          },
        };

        const response = await updateJob(data);
        if (response.code === 200) {
          showSuccess("Status changed successfully");
          loadData();
          if (stage != "Inprogress") {
            dispatch(manageStatusChange(stage));
          }
        } else {
          showError(response.message);
        }
      } else {
        selectedRow.forEach(async (id) => {
          let data = {
            payload: {
              id: id,
              job_status: status,
              job_stage: stage,
            },
          };

          const response = await updateJob(data);
          if (response.code === 200) {
            showSuccess("Status changed successfully");
            if (stage != "Inprogress") {
              dispatch(manageStatusChange(stage));
            }
          } else {
            showError(response.message);
          }
        });
        loadData();
      }
    } else {
      showError("Please select the record");
    }
  };

  const archiveRecord = async () => {
    if (selectedRow.length !== 0) {
      let data = {
        payload: {
          action_type: "archive",
          ids: selectedRow,
        },
      };
      let response = await deleteJobs(data);
      if (response.code === 200) {
        showSuccess("Job Archive Sucessfully");
        loadData();
      } else {
        showError(response.message);
      }
    } else {
      showError("Select Atlest One Record");
    }
  };

  const sendNotification = () => {
    if (selectedRow.length !== 0) {
      if (sendNotificationTo) {
        if (notificationTemplate) {
          dispatch({
            type: SUBMIT_JOB_SMSEMAIL_TEMPLATE,
            payload: {
              workshop_id: workshop_id,
              job_id: selectedRow,
              template_id: notificationTemplate,
              send_method: sendNotificationTo,
              table: "chkBookin",
              send_type: "bulk",
            },
          });
        } else {
          showError("Please select the Template");
        }
      } else {
        showError("Please select send method SMS / Email");
      }
    } else {
      showError("Please select the Jobs");
    }
  };
  const handleEmailModalClose = () => setEmailModalShow(false);
  const handleSMSModalClose = () => setSendSmsModal(false);

  return (
    <>
      {(loadingLogins || getJobData.loadingLogins) && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
      <div>
        <div className="table-search-wrapper">
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Search..."
            onChange={(e) => handleSearch(e)}
            value={search}
          />
          <Button
            className="btn-primary rounded-btn"
            onClick={() => navigate("/scheduler")}
          >
            <i className="fa fa-plus"></i> Schedule Job
          </Button>
        </div>

        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: currentPage,
            sizePerPage: sizePerPage,
            totalSize: parseInt(totalRecords),
            nextPageText: "Next",
            prePageText: "Previous",
            sizePerPageList: [10, 25, 50, 100],
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                remote
                keyField="id"
                data={jobData}
                columns={columns}
                onTableChange={onTableChange}
                selectRow={checkBox}
                rowEvents={rowEvents}
                expandRow={expandRow}
                noDataIndication={emptyDataMessage}
                {...paginationTableProps}
              />
              <div>
                <div className="table-pagination">
                  <div>
                    <span>Show</span>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <span>entries</span>
                  </div>
                  <PaginationTotalStandalone {...paginationProps} />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            </div>
          )}
        </PaginationProvider>

        {emailModalShow && (
          <SendEmailModal
            emailModalShow={emailModalShow}
            handleEmailModalClose={handleEmailModalClose}
            emailTemplateData={emailTemplateData}
            getEmailForEmailModal={selectedRowEmail}
            shareAdditionalDetailsEmail={shareAdditionalDetailsEmail}
            send_page={send_page}
            setIsLoading={setIsLoading}
          />
        )}
        {sendSmsModal && (
          <SendSmsModal
            SMSModalShow={sendSmsModal}
            handleSMSModalClose={handleSMSModalClose}
            smsTemplateData={smsTemplateData}
            getPhoneForPhoneModal={selectedRowPhone}
            getPhoneCodeForPhoneModal={selectedRowPhoneCode}
            shareAdditionalDetailsSms={shareAdditionalDetailsSms}
            send_page={send_page}
            setIsLoading={setIsLoading}
          />
        )}

        <div className="d-flex custom-table-footer align-end">
          <Button
            variant="warning"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => archiveRecord()}
          >
            <i className="la la-archive font-size-18"></i> Archive
          </Button>
          <Button
            variant="danger"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => setShowDeleteModal(!showDeleteModal)}
          >
            <i className="la la-times-circle font-size-18"></i> Delete
          </Button>
          {showDeleteModal && (
            <DeleteRecords
              showDeleteModal={setShowDeleteModal}
              refreshData={loadData}
              api={deleteJobs}
              selectedRow={selectedRow}
              message={deleteMsg}
              payloadData={deletePayloadData}
            />
          )}
          <div className="custom-normal-select ">
            <label>Change Stage</label>
            <select
              id="change_stage_unassigned"
              name="change_stage_unassigned"
              onChange={(e) => handleStageChange(e)}
            >
              <option value="">Select</option>

              <option value="Inprogress">In Progress</option>

              <option value="Completed">Completed</option>
            </select>
          </div>
          <div className="custom-normal-select ">
            <label>Change Status</label>
            <select
              id="change_status_unassigned"
              name="change_status_unassigned"
              onChange={(e) => setStatus(e.target.value)}
            >
              {statusOption(stage)}
            </select>
          </div>
          <Button
            className="btn-primary rounded-btn mr-10"
            onClick={() => changeStatus()}
          >
            Change
          </Button>
          <div className="custom-normal-select mr-10">
            <label>Send Bulk Notification</label>
            <select
              id="template_bulk_chkUnassigned"
              name="template"
              onChange={(e) => setNotificationTemplate(e.target.value)}
            >
              <option value="">Select Template</option>

              {jobSmsData &&
                jobEmailData &&
                [...jobSmsData, ...jobEmailData].map(({ id, name }, index) => (
                  <option value={id} key={index}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div className="radio-wrapper mr-10">
            <Form.Check
              className="custom-check radio-btn secondary-radio mb-2"
              type="radio"
              id="inProgress1"
            >
              <Form.Check.Input
                name="size"
                type="radio"
                isValid
                value="sms"
                onChange={(e) => setSendNotificationTo(e.target.value)}
              />
              <Form.Check.Label>
                SMS
                <span className="check"></span>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check
              className="custom-check radio-btn secondary-radio mb-2"
              type="radio"
              id="inProgress2"
            >
              <Form.Check.Input
                name="size"
                type="radio"
                isValid
                value="email"
                onChange={(e) => setSendNotificationTo(e.target.value)}
              />
              <Form.Check.Label>
                Email
                <span className="check"></span>
              </Form.Check.Label>
            </Form.Check>
          </div>
          <Button
            className="btn-primary rounded-btn"
            onClick={() => sendNotification()}
          >
            Send
          </Button>
        </div>
      </div>
    </>
  );
};

export default InProgressJobList;
