import React, { useEffect, useState } from "react";
import { apiCall } from "helpers/apiHelper";
import { getUnassignedJobs, updateInspection, updateJob, sendEmailInvoice, sentJobBookSms, getInspections } from "helpers/backendHelper";
import { ADD_INVOICE, UPDATE_INVOICE } from "helpers/urlHelper";
import axios from "axios";
import { showSuccess } from "utils/alertMessage";

const { getWorkshopId } = require("utils/getWorkshopId");
// const [tinyUrl,setTinyUrl] = useState();
let tinyUrl = '';

export async function createInvoice(request) {

    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    const invoice_data = {};
    const job_detail = {};
    let inspection;
    let url = ADD_INVOICE;
    invoice_data['workshop_id'] = +getWorkshopId();
    invoice_data['created_by'] = +window.atob(storedData['user_id']);
    invoice_data['user_id'] = request.data['user_id'];
    invoice_data['ap_id'] = 0;

    invoice_data['job_id'] = request.data['job_id'];
    invoice_data['comments'] = request.data['txtInvoiceComment'];
    invoice_data['tax_invoice'] = request.data['drpInvoiceType'];
    invoice_data['claim_method'] = request.data['rdoClaims'];
    invoice_data['claim_amount'] = request.data['claim_amount'];
    invoice_data['claim'] = request.data['claim'];

    if (request.data['drpInvoiceType'] === "4") {

        invoice_data['customer_preview_invoice'] = request.data['customer_preview_invoice'];
        invoice_data['customer_invoice_total'] = request.data['customer_invoice_total'];
        invoice_data['insurance_preview_invoice'] = (request.data['insurance_preview_invoice']);
        invoice_data['insurance_invoice_total'] = (request.data['insurance_invoice_total']);
        invoice_data['customer_invoice_allocation_preview'] = (request.data['customer_invoice_allocation_preview']);
        invoice_data['customer_invoice_allocation_total'] = (request.data['customer_invoice_allocation_total']);
        invoice_data['invoice_allocation_type'] = (request.data['invoice_allocation_type']);
        job_detail['job_status'] = 'Invoiced';
        job_detail['job_stage'] = 'Completed';
    }


    invoice_data['remain_balance'] = request.data['remain_balance'];
    invoice_data['date_issued'] = (request.data['start_date']);
    invoice_data['payment_due_date'] = (request.data['payment_due']);
    invoice_data['insurance_company_id'] = request.data['insurance_company_id'];
    invoice_data['recepient'] = request.data['select_payer'];

    invoice_data['footnotes'] = request.data['footnote'] ?? null;
    invoice_data['sub_total'] = request.data['sub_total'];
    if (request.data['discount']) {
        invoice_data['discount'] = request.data['discount'];
    }


    invoice_data['total_with_discount'] = request.data['total_amount'];
    invoice_data['gst'] = request.data['gst'];
    invoice_data['total'] = request.data['total_amount'];

    invoice_data['is_part_no_visible'] = request.data['is_part_no_visible'];
    invoice_data['is_labour_total_display'] = request.data['is_labour_total_display'];

    invoice_data['form_type'] = request.data['form_type'];

    const getAssignedVehiclePayload = {
        "get": "all",
        "conditions": {
            "id": request.job_id
        }
    }

    const get_assigned_vehicles = await getUnassignedJobs({ payload: getAssignedVehiclePayload });

    job_detail['id'] = request.job_id;
    if (request.payment_popup) {
        job_detail['job_status'] = 'Invoiced';
    }

    if (request.data['select_payer'] == 2) {
        if (request.data['claim_number_text']) {
            job_detail['claim_number'] = request.data['claim_number_text'];
        }
        if (request.data['insurance_company_id']) {
            job_detail['insurance_company_id'] = request.data['insurance_company_id'];
        }
    } else {
        job_detail['claim_number'] = "";
        job_detail['insurance_company_id'] = 0;
    }

    if (request.drpInvoiceType == 1) {
        job_detail['job_status'] = 'Invoiced';
    }

    if(request.data['remain_balance'] && request.data['remain_balance'] == 0 && (request.drpInvoiceType == 2 || request.drpInvoiceType == 3)){
        job_detail['job_status'] = 'Invoiced';
        job_detail['job_stage'] = 'Completed';
    }

    if (get_assigned_vehicles?.code === 200) {
        inspection = get_assigned_vehicles?.data?.JobSchedules[0]?.insp_reference_id || "";
    }

    invoice_data['send_type'] = request.data['create_type'];
    if (request.data['create_type'] == "smsAndEmail" || request.data['create_type'] == "sms") {
        invoice_data['communication_mode'] = request.data['communication_mode'] ?? 'customer'
        invoice_data['sms_type'] = request.data['type']
        invoice_data['sms_job_id'] = "";
        invoice_data['sms_customer_id'] = request.data['sms_customer_id'];
        invoice_data['send_method'] = "sms";
        invoice_data['table'] = request.data['sms_table'];
        invoice_data['send_to'] = request.data['send_to'];
        invoice_data['to'] = request.data['send_to'];
        invoice_data['message'] = request.data['message'];
        invoice_data['insp_message'] = request.data['insp_message'];
        invoice_data['phone_code'] = request.data['phone_code'];
        invoice_data['subject'] = request.data['subject'];
        invoice_data['update_mobile'] = request.data['update_mobile'];
        invoice_data['inspection_id'] = get_assigned_vehicles?.data?.JobSchedules?.[0]?.inspections_id;
    }

    if (request.data['invoice_id']) {
        //If invoice Id exists
        invoice_data['id'] = request.data['invoice_id'];
        url = UPDATE_INVOICE;
    }

    // return invoice_data;
    const save_data = await apiCall(url, { payload: invoice_data }, 'POST', true);
    await updateJob({ payload: job_detail });
    if (inspection) {
        const inspectionUpdatePayload = {
            id: inspection,
            'Insp_status': "Invoiced"
        };

        await updateInspection({ payload: inspectionUpdatePayload });
    }
    if (save_data?.code == 200) {
        if (request.data['create_type'] == "smsAndEmail" || request.data['create_type'] == "email") {
            let emailRequest = {
                communication_mode: request.data['communication_mode'] ?? 'customer',
                descriptionWithItem: 1,
                id: save_data?.data?.Invoices?.id,
                type: "",
                email_content: "<p></p>",
                to: [request.data['email']]
            }
            if (request.data['drpInvoiceType'] === "4") {
                if (request.data['email_sent'] === 1) {
                    await sendEmailInvoice({ payload: emailRequest });
                }
            } else {
                await sendEmailInvoice({ payload: emailRequest });
            }
        }
        showSuccess("Invoice Created Successfully.")
        return save_data.data.Invoices.id;
    } else {
        throw new Error("Failed to create Invoice");
    }
}