import { Col, Modal, Row } from "react-bootstrap";

export default function WorkshopDetailModal({
  open = false,
  workshop,
  ...props
}) {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      console.log("No close function provided");
    }
  };

  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className="text-white">{workshop?.name}</h5>
        <i
          className="la la-times text-white"
          style={{ cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Row className="gy-2">
          <Col sm={6}>
            <p className="text-muted fw-semibold">Contact name</p>
            <p>{workshop?.contact_name}</p>
          </Col>
          <Col sm={6}>
            <p className="text-muted fw-semibold">Main Phone</p>
            <p>{workshop?.phone}</p>
          </Col>
          <Col sm={6}>
            <p className="text-muted fw-semibold">Email Address</p>
            <p>{workshop?.email}</p>
          </Col>
          <Col sm={6}>
            <p className="text-muted fw-semibold">Accounting Package</p>
            <p>{workshop?.accounting_package.name}</p>
          </Col>
        </Row>
        <hr className="mt-3" />
        <Row className="gy-2">
          <Col sm={12}>
            <p className="text-muted fw-semibold">Street Address</p>
            <p>{workshop?.street_address}</p>
          </Col>
          <Col sm={6}>
            <p className="text-muted fw-semibold">Suburb</p>
            <p>{workshop?.suburb}</p>
          </Col>
          <Col sm={6}>
            <p className="text-muted fw-semibold">State</p>
            <p>{workshop?.state?.name}</p>
          </Col>
          <Col sm={6}>
            <p className="text-muted fw-semibold">Country</p>
            <p>{workshop?.country?.country_name}</p>
          </Col>
          <Col sm={6}>
            <p className="text-muted fw-semibold">Zip code</p>
            <p>{workshop?.postcode}</p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
