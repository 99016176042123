import {
  getInternalNoteForLead,
  getJobCannedList,
} from "helpers/backendHelper";
import moment from "moment";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import { utcToTimezone } from "utils/timeZoneConvert";
import { S3ImageShow } from "utils/uploadFileToS3";

export async function getInternalScriptWithNoteForLead(id) {
  const request1 = {
    get: "all",
    conditions: {
      online_schedule_id: id,
    },
    contain: ["OnlineScheduleInternalNotesAttachment", "Users"],
    order: {
      "OnlineScheduleInternalNotes.id": "desc",
    },
  };

  const notesResponse = await getInternalNoteForLead({ payload: request1 });

  const request2 = {
    get: "all",
    conditions: {
      workshop_id: getWorkshopId(),
      status: 1,
      "type IN": [8],
    },
    fields: ["id", "department_id", "name", "description"],
  };
  const notes =
    notesResponse?.data?.OnlineScheduleInternalNotes?.map(async (note) => {
      const images =
        note?.online_schedule_internal_notes_attachment?.map(async (image) => {
          let url = "";
          if (
            image.attachment_path.includes(
              "https://5iq-website-uploads.s3.ap-southeast-2.amazonaws.com"
            )
          ) {
            url = image.attachment_path;
          } else {
            url = await S3ImageShow(image.attachment_path);
          }
          return {
            name: image.name,
            url,
          };
        }) || [];
      const imgs = await Promise.all(images);
      return {
        id: note.id,
        images: imgs,
        date: moment(note.created_on).format("DD-MM-YYYY"),
        time: utcToTimezone(note.created_on, getSessionData('timezone_lable'),'','hh:mm A'),
        name: note?.user?.firstname + " " + note?.user?.lastname,
        content:note?.notes || ''
      };
    }) || [];
  const scriptResponse = await getJobCannedList({ payload: request2 });
  const scripts = scriptResponse?.data?.WorkshopScriptsDetails || [];
  const ntes = await Promise.all(notes);

  return {
    scripts: scripts,
    notes: ntes,
  };
}
