import {
getSubscription
} from "helpers/backendHelper";
import moment from "moment";
import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";
import LoaderImage from "../../../../../src/assets/images/page-loader.svg";
import {
GET_WORKSHOP_DETAIL,
GET_WORKSHOP_OTHER_DETAIL
} from "../../../../store/companyDetails/actionTypes";
import BrandedApp from "./brandedApp";
import CompanyCommunication from "./companyCommunication";
import CompanyDetailForm from "./companyDetailForm";

const CompanyDetailSetting = () => {
  const dispatch = useDispatch(); // Dispatch variable

  const [title] = useState("5iQ | Company Detail Settings");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [showBrandedApp, setShowBrandedApp] = useState(false);

  const userData = useSelector((state) => state.RegisterReducer); // Get Regiser Reducer data
  const companyDetailData = useSelector((state) => state.CompanyDetailReducer); // GET Company Detail Reducer data

  const [updateCompanyDetails, setUpdateCompanyDetails] = useState(false);
  const getWorkshopDetails = async () => {
    const workshop = {
      payload: {
        get: "all",
        conditions: {
          "WorkshopSubscription.workshop_id": getWorkshopId(),
        },
        contain: {
          WorkshopSubscriptionDetails: {
            fields: [
              "id",
              "workshop_subscription_id",
              "subscription_plan_id",
              "subscription_type",
              "plan_type",
              "plan_name",
              "subscription_type",
              "paid_amount",
              "end_date",
              "sms_limit",
              "min_sms_limit",
              "current_sms_limit",
              "status",
              "extra_desktop_user",
              "mobile_user_price",
              "desktop_user_price",
              "extra_mobile_user",
              "is_sms_rollover",
              "vin_request",
              "min_vin_request",
              "is_vin_rollover",
              "created_on",
            ],
          },
        },
        order: {
          id: "DESC",
        },
        limit: 1,
      },
    };
    const workshopDetails = await getSubscription(workshop);

    let plan_type = [];
    if (workshopDetails.code === 200) {
      workshopDetails?.data?.WorkshopSubscription.forEach((element) => {
        plan_type = element?.workshop_subscription_details.filter(
          (data) => data.plan_type === "application_configuration"
        );
      });


      if (plan_type.length > 0) {
        setShowBrandedApp(true);
      }
    } else {
      const date = getSessionData("trial_expiry_date");

      var current_date = moment().format("YYYY-MM-DD");
      const expiry_date = moment(date).format("YYYY-MM-DD");

      if (expiry_date >= current_date) {
        setShowBrandedApp(true);
      }
    }
  };

  useEffect(() => {
    dispatch({
      type: GET_WORKSHOP_DETAIL,
      payload: {
        id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });

    dispatch({
      type: GET_WORKSHOP_OTHER_DETAIL,
      payload: {
        workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });
    getWorkshopDetails();
  }, [updateCompanyDetails]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setIsLoading(userData.loadingLogins);
  }, [userData]);

  useEffect(() => {
    setIsLoading(companyDetailData.loading);
    setIsFormLoading(companyDetailData.formLoading);
  }, [companyDetailData]);

  return (
    <>
      {(isLoading === true || isFormLoading === true) && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
      <div className="right-section-wrapper">
        <div className="table-header">
          <div className="table-header-tabs profile-tabs">
            <Tab.Container defaultActiveKey="company-details">
              <div className="row top-block">
                <div className="col-md-2">
                  <div className="table-title">
                    <i className="fa fa-gears text-primary me-2 font-size-30"></i>
                    <span>COMPANY DETAILS</span>
                  </div>
                </div>
                <div className="col-md-8">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="company-details">
                        Company Details
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="company-communication">
                        Company Communication
                      </Nav.Link>
                    </Nav.Item>
                    {/* {showBrandedApp && (
                      <Nav.Item>
                        <Nav.Link eventKey="branded-app">Branded App</Nav.Link>
                      </Nav.Item>
                    )} */}
                  </Nav>
                </div>
                <div className="col-md-2">
                  <div className="video-link-outer">
                    <div className="video-tutorial">
                      <i className="fa fa-icon"></i>
                      {/* <span className="help-text">Help</span> */}
                      <Link to="/trainee-video#settings" ><span className="tutorial-title">Settings Videos</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="company-details">
                  <CompanyDetailForm
                    userData={userData}
                    companyDetailData={companyDetailData}
                    updateCompanyDetails={updateCompanyDetails}
                    setUpdateCompanyDetails={setUpdateCompanyDetails}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="company-communication">
                  <CompanyCommunication
                    userData={userData}
                    companyDetailData={companyDetailData}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="branded-app">
                  <BrandedApp
                    workShopOtherDetails={
                      companyDetailData.workShopOtherDetails
                    }
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailSetting;
