import { getOnlineJobs, updateOnlineJob } from "helpers/backendHelper";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getJobCannedListPayload } from "store/invoiceDetail/api";
import { getWorkshopId } from "utils/getWorkshopId";
import { getJobCannedList } from "../commonApiStore/actions";
import * as ACTIONS from "./actionTypes";
import { addInternalNotes } from "./api/addInternalNote";
import { convertLeadToJob, convertLeadToQuote } from "./api/convertLeads";
import { deleteInternalNote } from "./api/deleteInternalNote";
import { getInternalScriptWithNoteForLead } from "./api/getInternalScriptWithNotes";
import { getLeadDetail } from "./api/getLeadDetail";

function formatData(arr) {
  return arr.map((c) => ({
    data: c,
    lead_number: c.lead_number,
    date: c.created_on,
    contact_name:
      c.user?.customers_details[0]?.type === '1' ?
        c.user?.customers_details[0]?.company_name
        :
        c.user?.customers_details[0]?.firstname +
        " " +
        c.user?.customers_details[0]?.lastname,
    contact_number: c.user?.customers_details[0]?.mobile_number,
    rego: c?.rego,
    lead_type: c.job_booking_type?.name,
    source: "Website",
    date_booked: c.from_date,
    status: c.lead_status,
    id: c.id,
  }));
}

function* onGetLeads() {
  try {
    const workshop_id = getWorkshopId();
    const request = {
      get: "all",
      conditions: {
        "OnlineSchedule.workshop_id": workshop_id,
        "OnlineSchedule.status": 1,
      },
      contain: {
        JobBookingTypes: {
          fields: ["JobBookingTypes.name", "JobBookingTypes.parent_id"],
        },
        Vehicles: {
          fields: [
            "Vehicles.id",
            "Vehicles.user_id",
            "Vehicles.workshop_id",
            "Vehicles.rego",
          ],
        },
        "Users.customersDetails": {
          conditions: {
            workshop_id: workshop_id,
          },
          fields: ["id", "user_id", "firstname", "lastname", "mobile_number", "type", "company_name"],
        },
      },
      order: {
        "OnlineSchedule.id": "desc",
      },
      fields: [
        "OnlineSchedule.id",
        "OnlineSchedule.lead_number",
        "OnlineSchedule.lead_script",
        "OnlineSchedule.workshop_id",
        "OnlineSchedule.job_type",
        "OnlineSchedule.job_parent_type",
        "OnlineSchedule.created_on",
        "OnlineSchedule.lead_status",
        "OnlineSchedule.rego",
        "OnlineSchedule.from_date",
        "Users.id",
        "Users.workshop_id",
        "Users.firstname",
        "firstname",
        "lastname",
        "Users.phone_number",
        "Users.email",
      ],
    };
    const data = yield call(getOnlineJobs, { payload: request });
    if (data?.code === 200) {
      const payload = {
        list: formatData(data?.data?.OnlineSchedule),
        count: data?.data?.OnlineSchedule.length,
      };
      yield put({
        type: ACTIONS.GET_LEADS_SUCCESS,
        payload,
      });
    } else {
      yield put({
        type: ACTIONS.GET_LEADS_FAIL,
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.GET_LEADS_FAIL,
    });
  }
}

function* onGetLeadDetail({ id }) {
  try {
    const data = yield call(getLeadDetail, id);
    yield put(getJobCannedList(getJobCannedListPayload(getWorkshopId())));
    yield put({
      type: ACTIONS.GET_INTERNAL_NOTE_WITH_SCRIPTS,
      id,
    });
    yield put({
      type: ACTIONS.GET_LEAD_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: ACTIONS.GET_LEAD_DETAIL_FAIL,
    });
  }
}

function* onConvertToJob({ payload }) {
  try {
    yield call(convertLeadToJob, payload);
    yield put({ type: ACTIONS.CONVERT_LEAD_TO_JOB_SUCCESS });
  } catch (error) {
    yield put({ type: ACTIONS.CONVERT_LEAD_TO_JOB_FAIL });
  }
}

function* onConvertQuote({ payload }) {
  try {
    const data = yield call(convertLeadToQuote, payload);
    yield put({ type: ACTIONS.CONVERT_LEAD_TO_QUOTE_SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: ACTIONS.CONVERT_LEAD_TO_QUOTE_FAIL });
  }
}

function* onUpdateLead({ payload }) {
  try {
    const { lead_department_id, lead_type, description } = payload;
    const { leadDetail } = yield select((state) => state.leads);
    const { lead } = leadDetail.data;
    const $lead_data = {};
    $lead_data["id"] = lead?.id;
    $lead_data["user_id"] = lead?.user_id;
    $lead_data["vehicle_id"] = lead?.vehicle_id;
    $lead_data["job_type"] = lead_type;
    $lead_data["job_parent_type"] = lead_department_id;
    $lead_data["lead_script"] = description;
    $lead_data["vehicle_description"] = description;
    $lead_data["workshop_id"] = getWorkshopId();
    yield call(updateOnlineJob, { payload: $lead_data });
    yield put({
      type: ACTIONS.UPDATE_LEAD_SUCCESS,
      payload: {
        ...leadDetail.data,
        lead: {
          ...leadDetail.data.lead,
          job_type: lead_type,
          job_parent_type: lead_department_id,
          lead_script: description || leadDetail.data.lead.lead_script,
          vehicle_description: description || leadDetail.data.lead.lead_script,
        },
      },
    });
  } catch (err) {
    yield put({
      type: ACTIONS.UPDATE_LEAD_FAIL,
    });
  }
}

function* onGetInternalScriptWithNotes({ id }) {
  try {
    const data = yield call(getInternalScriptWithNoteForLead, id);
    yield put({
      type: ACTIONS.GET_INTERNAL_NOTE_WITH_SCRIPTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ACTIONS.GET_INTERNAL_NOTE_WITH_SCRIPTS_FAIL,
    });
  }
}

function* onAddInternalNote({ payload }) {
  try {
    const { internalNotes } = yield select((state) => state.leads);
    const data = yield call(addInternalNotes, payload);
    const notes = [data, ...internalNotes.notes];
    yield put({
      type: ACTIONS.ADD_INTERNAL_NOTE_TO_LEAD_SUCCESS,
      payload: notes,
    });
  } catch (err) {
    yield put({
      type: ACTIONS.ADD_INTERNAL_NOTE_TO_LEAD_FAIL,
    });
  }
}

function* onDeleteInternalNote({ payload }) {
  try {
    const { internalNotes } = yield select((state) => state.leads);
    yield call(deleteInternalNote, payload);
    const notes = internalNotes.notes.filter((c) => c.id !== payload.id);
    yield put({
      type: ACTIONS.DELETE_INTERNAL_NOTE_TO_LEAD_SUCCESS,
      payload: notes,
    });
  } catch (err) {
    yield put({
      type: ACTIONS.DELETE_INTERNAL_NOTE_TO_LEAD_FAIL,
    });
  }
}

export default function* watchLeadsSaga() {
  yield takeLatest(ACTIONS.GET_LEADS, onGetLeads);
  yield takeLatest(ACTIONS.GET_LEAD_DETAIL, onGetLeadDetail);
  yield takeLatest(ACTIONS.CONVERT_LEAD_TO_JOB, onConvertToJob);
  yield takeLatest(ACTIONS.CONVERT_LEAD_TO_QUOTE, onConvertQuote);
  yield takeLatest(ACTIONS.UPDATE_LEAD, onUpdateLead);
  yield takeLatest(ACTIONS.ADD_INTERNAL_NOTE_TO_LEAD, onAddInternalNote);
  yield takeLatest(
    ACTIONS.GET_INTERNAL_NOTE_WITH_SCRIPTS,
    onGetInternalScriptWithNotes
  );
  yield takeLatest(ACTIONS.DELETE_INTERNAL_NOTE_TO_LEAD, onDeleteInternalNote);
}
