import { takeLatest, put, call } from "redux-saga/effects";
import { GET_WON_QUOTES, GET_WON_QUOTES_COUNT } from "./actionTypes";
import {
  getWonQuotesSuccess,
  getWonQuotesFail,
  getWonQuotesCountSuccess,
  getWonQuotesCountFail,
} from "./actions";

import { getWonQuotes } from "../../helpers/backendHelper";

function* onGetWonQuotes(data) {
  try {
    const response = yield call(getWonQuotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(getWonQuotesSuccess(response.data.CustomerQuotes));
      } else {
        yield put(getWonQuotesFail(response.message));
      }
    } else {
      yield put(getWonQuotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWonQuotesFail(error.response));
  }
}

function* onGetWonQuotesCount(data) {
  try {
    const response = yield call(getWonQuotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getWonQuotesCountSuccess(response.data.CustomerQuotes[0].COUNT)
        );
      } else {
        yield put(getWonQuotesCountFail(response.message));
      }
    } else {
      yield put(getWonQuotesCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWonQuotesCountFail(error.response));
  }
}

function* WonQuotesSaga() {
  yield takeLatest(GET_WON_QUOTES, onGetWonQuotes);
  yield takeLatest(GET_WON_QUOTES_COUNT, onGetWonQuotesCount);
}

export default WonQuotesSaga;
