import React from "react";
import Loader from "views/common/UI/Loader";

export class LoaderContainer extends React.Component {
  state = {
    show: false,
  };
  show = () => {
    this.setState({
      show: true,
    });
  };

  hide = () => {
    this.setState({
      show: false,
    });
  };
  render() {
    if (this.state.show) {
      return <Loader />;
    } else {
      return null;
    }
  }
}
