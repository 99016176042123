import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
} from "@stripe/react-stripe-js";
import {
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION,
  EDIT_SUBSCRIPTION_PLANS,
  EDIT_SUBSCRIPTION_PLANS_SUCCESS,
  EDIT_SUBSCRIPTION_PLANS_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
} from "../../../store/subscription/actionTypes";
import { editSubscriptionPlansSuccess } from "../../../store/subscription/actions";
import { GET_WORKSHOP_DETAIL } from "../../../store/companyDetails/actionTypes";
import { unserializeArr } from "../../../utils/unserialize";
import LoaderImage from "../../../assets/images/page-loader.svg";
import StripeLogo from "../../../assets/images/logo-stripe.svg";
import "../subscription-plan.scss";
import { showError, showSuccess } from "../../../utils/alertMessage";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ACTIVE_INPSECTION_BUSINESS, DEACTIVE_INPSECTION_BUSINESS } from 'store/login/actionTypes';
import capricorn from "assets/images/capricorn.jpg";
import CapricornForm from './capricornForm';
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
const stripePromise = loadStripe(process.env.REACT_APP_SUBSCRIPTION_PROMISE);

const ELEMENT_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      fontSize: "16px",
      color: "#000000",
      padding: "5px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const EditSubscription = () => {
  const { is_inspection_business } = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch();
  const localStorageData = JSON.parse(localStorage.getItem('data'));
  const gsPercentage = localStorageData?.workshop?.workshop_other_details[0]?.country?.part_gst || '12';

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);

  const [userPackageArr, setUserPackageArr] = useState([]);
  const [smsPackagesArr, setSmsPackagesArr] = useState([]);
  const [inspection, setInspection] = useState([]);
  const [form, setForm] = useState([]);
  const [applicationConfiguration, setApplicationConfiguration] = useState("");
  const [vinLookUpArr, setVinLookUpArr] = useState([]);
  const [addUsrDesktop, setAddUsrDesktop] = useState(0);
  const [addUsrMobile, setAddUsrMobile] = useState(0);
  const [selectedPlanType, setSelectedPlanType] = useState("monthly");

  const [myExtraUserPackageArr, setMyExtraUserPackageArr] = useState("");
  const [myExtraFormUserPackageArr, setMyExtraFormUserPackageArr] =
    useState("");
  const [mySmsPackagesArr, setMySmsPackageArr] = useState("");
  const [myVinlookupArr, setMyVinlookupArr] = useState("");
  const [myInspection, setMyInspection] = useState("");
  const [myApplicationConfiguration, setMyApplicationConfiguration] =
    useState("");
  const [myForm, setMyForm] = "";

  const [userPlan, setUserPlan] = useState("");
  const [appConfigPlan, setAppConfigPlan] = useState("");
  const [smsPlan, setSmsPlan] = useState("");
  const [vinPlan, setVinPlan] = useState("");
  const [capricornNumber, setCapricornNumber] = useState("");
  const [paymentType, setPaymentType] = useState("");

  const {
    subscriptionPlans,
    subscribeData,
    loadingData,
    editSubscriptionPlans,
    success
  } = useSelector((state) => state.SubscriptionReducer);
  const { workShopDetails, loading } = useSelector(
    (state) => state.CompanyDetailReducer
  );

  useEffect(() => {

    dispatch({
      type: GET_WORKSHOP_DETAIL,
      payload: {
        id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });
  }, []);

  useEffect(() => {

    if (workShopDetails) {
      dispatch({
        type: GET_SUBSCRIPTION_PLANS,
        payload: {
          get: "all",
          conditions: {
            country: workShopDetails.country.id,
            is_active: 1,
          },
        },
      });
      setCapricornNumber(workShopDetails?.capricorn_number);
      setPaymentType(workShopDetails?.payment_type);
      loadActivePlan();
    }
  }, [workShopDetails])


  const loadActivePlan = () => {
    dispatch({
      type: GET_SUBSCRIPTION,
      payload: {
        get: "all",
        conditions: {
          workshop_id: workshop_id,
        },
        contain: ["WorkshopSubscriptionDetails"],
        limit: 1,
        order: {
          id: "desc",
        },
      },
    });
  };

  useEffect(() => {
    setUserPackageArr([]);
    setSmsPackagesArr([]);
    setVinLookUpArr([]);
    if (subscriptionPlans.length > 0) {
      subscriptionPlans.forEach((element) => {
        if (element.plan_type === "user") {
          if (
            element.select_default_plan !== "" &&
            typeof element.select_default_plan === "string"
          ) {
            element.select_default_plan = unserializeArr(
              element.select_default_plan
            );
          }
          setUserPackageArr((prevArray) => [...prevArray, element]);
        } else if (element.plan_type === "sms") {
          setSmsPackagesArr((prevArray) => [...prevArray, element]);
        } else if (element.plan_type === "form") {
          setForm(element);
        } else if (element.plan_type === "inspection") {
          setInspection(element);
        } else if (element.plan_type === "application_configuration") {
          setApplicationConfiguration(element);
        } else if (element.plan_type === "vin_lookup") {
          setVinLookUpArr((prevArray) => [...prevArray, element]);
        }
      });
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    if (subscribeData && Object.keys(subscribeData).length > 0) {
      if (
        subscribeData.workshop_subscription_details &&
        subscribeData.workshop_subscription_details.length > 0
      ) {
        subscribeData.workshop_subscription_details.forEach((plan) => {
          if (plan.plan_type === "user") {
            setMyExtraUserPackageArr(plan);
            // setAddUsrDesktop(plan.extra_desktop_user);
            // setAddUsrMobile(plan.extra_mobile_user);
          } else if (plan.plan_type === "sms") {
            setMySmsPackageArr(plan);
          } else if (plan.plan_type === "form") {
            if (plan.plan_name === "extra form user") {
              setMyExtraFormUserPackageArr(plan);
            } else {
              setMyForm(plan);
            }
          } else if (plan.plan_type === "inspection") {
            setMyInspection(plan);
          } else if (plan.plan_type === "application_configuration") {
            setMyApplicationConfiguration(plan);
          } else if (plan.plan_type === "vin_lookup") {
            setMyVinlookupArr(plan);
          }
        });
        setSelectedPlanType(subscribeData.plan_type);
        // if (workShopDetails) {
        //   setPaymentType(workShopDetails?.payment_type || "");
        // }
      }
    }
  }, [subscribeData]);

  useEffect(() => {
    if (editSubscriptionPlans.length !== 0) {
      if (editSubscriptionPlans.code === 200) {
        showSuccess("Subscription Update Successfully");
        loadActivePlan();
        setUserPlan("");
        setAppConfigPlan("");
        setSmsPlan("");
        setVinPlan("");
        setAddUsrMobile(0);
        setAddUsrDesktop(0);
        dispatch(editSubscriptionPlansSuccess([]));
      } else showError(editSubscriptionPlans.message);
    }
  }, [editSubscriptionPlans]);

  const handleDecrement = (type) => {
    if (type === "desktop") {
      if (addUsrDesktop > 0) {
        setAddUsrDesktop(addUsrDesktop - 1);
      }
    } else {
      if (addUsrMobile > 0) {
        setAddUsrMobile(addUsrMobile - 1);
      }
    }
  };
  const handleIncrement = (type) => {
    if (type === "desktop") {
      setAddUsrDesktop(addUsrDesktop + 1);
    } else {
      setAddUsrMobile(addUsrMobile + 1);
    }
  };
  const monthlySubscriptionSubTotal =
    (userPlan
      ? selectedPlanType === "monthly"
        ? userPlan.monthly_charge
        : userPlan.yearly_charge
      : 0) +
    (addUsrDesktop *
      (workShopDetails ? workShopDetails.country.extra_desktop_user_price : 0) +
      addUsrMobile *
      (workShopDetails
        ? workShopDetails.country.extra_mobile_user_price
        : 0));

  const appConfigPlanPrice = (appConfigPlan
    ? selectedPlanType === "monthly"
      ? appConfigPlan.monthly_charge
      : appConfigPlan.yearly_charge
    : 0);
  const gstTotal = parseFloat(((monthlySubscriptionSubTotal + appConfigPlanPrice) * gsPercentage) / 100).toFixed(2);

  const addonSubscriptionSubTotal =
    (appConfigPlan
      ? selectedPlanType === "monthly"
        ? appConfigPlan.monthly_charge
        : appConfigPlan.yearly_charge
      : 0) +
    (smsPlan ? smsPlan.monthly_charge : 0) +
    (vinPlan ? vinPlan.monthly_charge : 0);

  const totalCharge = monthlySubscriptionSubTotal + addonSubscriptionSubTotal + parseFloat(gstTotal);

  const processingFee = parseFloat((totalCharge * 1.95) / 100).toFixed(2);
  if (loadingData || loading) {
    return (
      <div className="page-loader d-flex align-center justify-center">
        <img src={LoaderImage} alt="Loading"></img>
      </div>
    );
  }

  if (subscribeData && Object.keys(subscribeData).length === 0) {
    return <Navigate to="/subscription-plan" />;
  }

  const handleSubmit = () => {
    if (monthlySubscriptionSubTotal + addonSubscriptionSubTotal !== 0) {
      let data = {
        user_id: workShopDetails.user_id,
        email: workShopDetails.email,
        workshop_id: workShopDetails.id,
        plan_type: selectedPlanType,
        total_amount: monthlySubscriptionSubTotal + addonSubscriptionSubTotal + parseFloat(gstTotal),
        payment_processing_fees: Number(processingFee),
        stripe_token_id: workShopDetails.users[0].stripe_token,
        stripe_customer_id: workShopDetails.users[0].stripe_customer_id,
        desktop_user: addUsrDesktop,
        desktop_user_price:
          workShopDetails && workShopDetails.country.extra_desktop_user_price,
        mobile_user: addUsrMobile,
        mobile_user_price:
          workShopDetails && workShopDetails.country.extra_mobile_user_price,
        app_config_plan_id: appConfigPlan !== "" ? appConfigPlan.id : "",
      };
      if (userPlan !== "") {
        data["user_plan_id"] = userPlan.id;
      }
      if (smsPlan !== "") {
        data["sms_plan_id"] = smsPlan.id;
      }
      if (vinPlan !== "") {
        data["vin_plan_id"] = vinPlan.id;
      }
      if (form.length !== 0) {
        data["from_edit"] = form.id;
      }

      dispatch({
        type: DEACTIVE_INPSECTION_BUSINESS,
      });

      dispatch({
        type: EDIT_SUBSCRIPTION_PLANS,
        payload: data,
        [WAIT_FOR_ACTION]: EDIT_SUBSCRIPTION_PLANS_SUCCESS,
        [ERROR_ACTION]: EDIT_SUBSCRIPTION_PLANS_FAIL
      }).then(() => {
        dispatch({
          type: GET_SUBSCRIPTION,
          payload: {
            get: "all",
            conditions: {
              workshop_id: workshop_id,
              "WorkshopSubscription.status": 1,
              "WorkshopSubscription.is_deleted": 0,
            },
            contain: [
              "SubscriptionPlan",
              "WorkshopSubscriptionDetails",
              "WorkshopSubscriptionDetails.SubscriptionPlan",
            ],
            limit: 1,
            order: {
              "WorkshopSubscription.id": "desc",
            },
          },
          [WAIT_FOR_ACTION]: GET_SUBSCRIPTION_SUCCESS,
          [ERROR_ACTION]: GET_SUBSCRIPTION_FAIL
        }).then((result) => {
          const deserialized = unserializeArr(
            result.subscription_plan.select_default_plan
          );
          const inspectionVal = Number(deserialized.inspections ?? 0);
          if (!isNaN(inspectionVal) && inspectionVal > 0) {
            if (is_inspection_business == 0) {
              console.log("Dispatching is_inspection_business")
              dispatch({
                type: ACTIVE_INPSECTION_BUSINESS,
              });
            }
          }
        })
      });
    }
  };

  if (subscribeData && Object.keys(subscribeData).length > 0) {
    return (
      <>
        <div className="right-section-wrapper">
          <div className="card">
            <div className="card-header bg-primary text-white">
              Subscription Edit
            </div>
            <div className="d-flex justify-center align-center">
              <h5 style={{ marginRight: "10px", marginTop: "10px" }}>
                Select the BEST plan for your business
              </h5>
              <div className="radio-wrapper justify-center tab-radio-btn yes-no-radio mt-3">
                <Form.Check className="radio-btn mb-2" type="radio" id="no1">
                  <Form.Check.Input
                    name="selectPart"
                    type="radio"
                    checked={selectedPlanType === "monthly" ? true : false}
                    onClick={() => setSelectedPlanType("monthly")}
                    onChange={() => { }}
                  />
                  <Form.Check.Label>
                    Monthly
                    <span className="check"></span>
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="radio-btn mb-2" type="radio" id="yes1">
                  <Form.Check.Input
                    name="selectPart"
                    type="radio"
                    onClick={() => setSelectedPlanType("yearly")}
                    checked={selectedPlanType !== "monthly" ? true : false}
                    onChange={() => { }}
                  />
                  <Form.Check.Label>
                    Annually
                    <span className="check"></span>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="card-header px-0">
                    <div className="d-flex">
                      <h6 className="background-gradient rounded mb-0">
                        User Plan
                      </h6>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      {userPackageArr.map((plan) => (

                        <OverlayTrigger
                          key={plan.name === "Ultimate" ? "left" : "right"}
                          placement={plan.name === "Ultimate" ? "left" : "right"}
                          overlay={
                            <Tooltip id="sub-tooltip-top" style={{ marginLeft: "20px !important" }} >
                              {plan.name === "Growth" &&
                                <>
                                  <b>What’s included:</b>
                                  <ul class="b">
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Bookings, Quotes, Jobs, Invoices</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Customer / Vehicle CRM</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Parts / Kits, Stock, PO</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Tech App</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>SMS, Rego Lookup capability</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Accounts and Supplier integration</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>2 Users</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>24/7 Online videos, human technical support</li>
                                  </ul>
                                  <hr />
                                  <b>Additional User licence:</b>
                                  <ul class="b">
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$15 per month</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$10c per SMS</li>
                                  </ul>
                                </>
                              }

                              {plan.name === "Pro" &&
                                <>
                                  <b>What’s included:</b>
                                  <ul class="b">
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Growth items plus</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Digital Inspection checklist</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Live Customer approvals</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Multiple Vehicle types</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Parts Stock Take</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Daily / Job Clocking</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Reports</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>5 Users</li>
                                  </ul>
                                  <hr />
                                  <b>Additional User licence:</b>
                                  <ul class="b">
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$15 per month</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$10c per SMS</li>
                                  </ul>
                                </>
                              }


                              {plan.name === "Ultimate" &&
                                <>
                                  <b>What’s included:</b>
                                  <ul class="b">
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>Growth / Pro items plus</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>10 Users</li>
                                  </ul>
                                  <hr />
                                  <b>Additional User licence:</b>
                                  <ul class="b">
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$10 per month</li>
                                    <li><i class="fa fa-check-square mr-1" aria-hidden="true"></i>$10c per SMS</li>
                                  </ul>
                                </>
                              }
                            </Tooltip>

                          }
                        >
                          <div className="col-md-4" key={plan.id}>
                            <div
                              className={
                                subscribeData.subscription_plan_id === plan.id &&
                                  !userPlan?.id &&
                                  (new Date(subscribeData.plan_expiry_date) > new Date())
                                  ? "pricingTable active mx-auto"
                                  : userPlan.id !== plan.id
                                    ? "pricingTable mx-auto"
                                    : "pricingTable active mx-auto"
                              }
                            >
                              <h3>{plan.name}</h3>
                              <div className="price-value">
                                {selectedPlanType === "monthly" ? (
                                  <>
                                    <span>{`${workShopDetails.country.symbol}${plan.monthly_charge}`}</span>
                                    <span className="month"> Monthly</span>
                                  </>
                                ) : (
                                  <>
                                    <span>{`${workShopDetails.country.symbol}${plan.yearly_charge}`}</span>
                                    <span className="month"> Annually</span>
                                  </>
                                )}
                              </div>
                              <div className="pricing-content">
                                <ul>
                                  <li>
                                    Incl.{" "}
                                    {Object.keys(plan.select_default_plan)
                                      .length > 0
                                      ? plan.select_default_plan.user_limit
                                      : 0}
                                    * user
                                    <span>
                                      {selectedPlanType === "monthly"
                                        ? `${workShopDetails.country.symbol}${plan.yearly_charge} Annually`
                                        : `${workShopDetails.country.symbol}${plan.monthly_charge} Monthly`}
                                    </span>
                                  </li>
                                </ul>
                                {((subscribeData.subscription_plan_id !== plan.id)
                                  || (new Date(subscribeData.plan_expiry_date) < new Date()))
                                  && (
                                    <>
                                      {userPlan.id !== plan.id ? (
                                        <div className="pricingTable-signup">
                                          <button
                                            type="button"
                                            onClick={() => setUserPlan(plan)}
                                          >
                                            Select plan
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="pricingTable-signup">
                                          <button
                                            type="button"
                                            onClick={() => setUserPlan("")}
                                          >
                                            Unselect plan
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>


                        </OverlayTrigger>
                      ))}
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="card">
                          <div className="card-header py-2 font-size-13">
                            Add additional users for plan:
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="d-flex px-0 px-xl-4 flex-nowrap justify-content-center align-items-start">
                                  <h4 className="mb-0 me-2 font-size-15 text-nowrap pt-2">
                                    Desktop
                                  </h4>
                                  <div className="d-flex">
                                    <div className="d-flex flex-nowrap align-items-start">
                                      <button
                                        className="btn btn-icon btn-danger rounded-circle me-2 flex-shrink-0"
                                        onClick={() =>
                                          handleDecrement("desktop")
                                        }
                                      >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                      <div>
                                        <input
                                          type="text"
                                          value={addUsrDesktop}
                                          className="form-control text-center"
                                          readOnly
                                        />
                                        {/* <div className="font-size-14">$ {workShopDetails ? workShopDetails.country.extra_desktop_user_price : 0} per user</div> */}
                                      </div>
                                      <button
                                        className="btn btn-icon btn-success rounded-circle ms-2 flex-shrink-0"
                                        onClick={() =>
                                          handleIncrement("desktop")
                                        }
                                      >
                                        <i className="fa fa-plus"></i>
                                      </button>
                                    </div>
                                    <div className="counter d-flex align-items-center justify-spacebetween">
                                      <p className="w-50 m-b-0 text-left">
                                        New User (
                                        <span id="new-desktop-per-user">
                                          {addUsrDesktop}
                                        </span>
                                        )
                                      </p>
                                      <p className="w-50 m-b-0 text-right">
                                        {workShopDetails?.country?.symbol}{" "}
                                        <span id="user-desktop-per-user">
                                          {" "}
                                          {workShopDetails
                                            ? workShopDetails.country
                                              .extra_desktop_user_price
                                            : 0}{" "}
                                        </span>{" "}
                                        per user
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex px-0 px-xl-4 flex-nowrap justify-content-center align-items-start">
                                  <h4 className="mb-0 me-2 font-size-15 text-nowrap pt-2">
                                    Mobile App
                                  </h4>
                                  <div className="d-flex">
                                    <div className="d-flex flex-nowrap align-items-start">
                                      <button
                                        className="btn btn-icon btn-danger rounded-circle me-2 flex-shrink-0"
                                        onClick={() =>
                                          handleDecrement("mobile")
                                        }
                                      >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                      <div>
                                        <input
                                          type="text"
                                          value={addUsrMobile}
                                          className="form-control text-center"
                                          readOnly
                                        />
                                        {/* <div className="font-size-14">$ {workShopDetails ? workShopDetails.country.extra_mobile_user_price : 0} per user</div> */}
                                      </div>
                                      <button
                                        className="btn btn-icon btn-success rounded-circle ms-2 flex-shrink-0"
                                        onClick={() =>
                                          handleIncrement("mobile")
                                        }
                                      >
                                        <i className="fa fa-plus"></i>
                                      </button>
                                    </div>
                                    <div className="counter d-flex align-items-center justify-spacebetween">
                                      <p className="w-50 m-b-0 text-left">
                                        New User (
                                        <span id="new-desktop-per-user">
                                          {addUsrMobile}
                                        </span>
                                        )
                                      </p>
                                      <p className="w-50 m-b-0 text-right">
                                        {workShopDetails?.country?.symbol}{" "}
                                        <span id="user-desktop-per-user">
                                          {" "}
                                          {workShopDetails
                                            ? workShopDetails.country
                                              .extra_mobile_user_price
                                            : 0}{" "}
                                        </span>{" "}
                                        per user
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="font-size-14 font-weight-bold text-danger">
                        * Subscription Pricing Excludes GST
                      </div>
                    </div>

                  </div>
                </div>
                {/* {
                  applicationConfiguration && applicationConfiguration !== "" && (<div className="col-lg-3 mb-3">
                    <div className="card-header px-0">
                      <div className="d-flex">
                        <h6 className="background-gradient rounded mb-0">
                          BRANDED APP
                        </h6>
                      </div>
                    </div>
                    <div className="card-body">
                      <div
                        className={
                          ((
                            (myApplicationConfiguration === "") ||
                            (myApplicationConfiguration.subscription_plan_id !== applicationConfiguration.id) ||
                            (new Date(myApplicationConfiguration.end_date) < new Date())
                          ))
                            ? (appConfigPlan.id !== applicationConfiguration.id
                              ? "pricingTable mx-auto"
                              : "pricingTable active mx-auto")
                            : "pricingTable active mx-auto"

                        }
                      >
                        <h3>{applicationConfiguration.name}</h3>
                        <div className="price-value">
                          {selectedPlanType === "monthly" ? (
                            <>
                              <span>{`${workShopDetails.country.symbol}${applicationConfiguration.monthly_charge}`}</span>
                              <span className="month"> Monthly</span>
                            </>
                          ) : (
                            <>
                              <span>{`${workShopDetails.country.symbol}${applicationConfiguration.yearly_charge}`}</span>
                              <span className="month"> Annually</span>
                            </>
                          )}
                        </div>
                        <div className="pricing-content">
                          <ul>
                            {selectedPlanType === "monthly" ? (
                              <li>
                                {`${workShopDetails.country.symbol}${applicationConfiguration.yearly_charge}`}{" "}
                                Annually
                              </li>
                            ) : (
                              <li>
                                {`${workShopDetails.country.symbol}${applicationConfiguration.monthly_charge}`}{" "}
                                Monthly
                              </li>
                            )}
                          </ul>
                          {(myApplicationConfiguration === "" ||
                            myApplicationConfiguration.subscription_plan_id !== applicationConfiguration.id ||
                            new Date(myApplicationConfiguration.end_date) < new Date()) &&
                            (
                              <>
                                {appConfigPlan.id !==
                                  applicationConfiguration.id ? (
                                  <div className="pricingTable-signup">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setAppConfigPlan(applicationConfiguration)
                                      }
                                    >
                                      Select plan
                                    </button>
                                  </div>
                                ) : (
                                  <div className="pricingTable-signup">
                                    <button
                                      type="button"
                                      onClick={() => setAppConfigPlan("")}
                                    >
                                      Unselect plan
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                      <div className="pt-4">
                        <div className="col-md-6 mx-auto text-center">
                          <i>
                            This module will be added/integrated into your
                            workshop application and the change will be added to
                            your subscription charge.
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>)
                } */}
                <div className="col-lg-8 mb-3">
                  <div className="card-header px-0">
                    <div className="d-flex">
                      <h6 className="background-gradient rounded mb-0">SMS</h6>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {smsPackagesArr && smsPackagesArr.length > 0 && (
                        <>
                          {smsPackagesArr.map((elmnt) => (
                            <div className="col-md-6 mb-3" key={elmnt.id}>
                              <div
                                className={
                                  mySmsPackagesArr === "" ||
                                    mySmsPackagesArr.subscription_plan_id !== elmnt.id ||
                                    mySmsPackagesArr.current_sms_limit == 0
                                    ? smsPlan.id !== elmnt.id
                                      ? "pricingTable mx-auto"
                                      : "pricingTable active mx-auto"
                                    : "pricingTable active mx-auto"
                                }
                              >
                                <h3>{elmnt.name}</h3>
                                <div className="price-value">
                                  <span>{`${workShopDetails.country.symbol}${elmnt.monthly_charge}`}</span>{" "}
                                  <span className="month font-size-14">
                                    Unused sms will rollover to next month
                                  </span>
                                </div>
                                <div className="pricing-content">
                                  <ul>
                                    <li>
                                      Incl. Approx {elmnt.sms_limit} sms
                                    </li>
                                  </ul>
                                  {(mySmsPackagesArr === "" ||
                                    mySmsPackagesArr.subscription_plan_id !== elmnt.id ||
                                    mySmsPackagesArr.current_sms_limit == 0
                                  ) && (
                                      <>
                                        {smsPlan.id !== elmnt.id ? (
                                          <div className="pricingTable-signup">
                                            <button
                                              type="button"
                                              onClick={() => setSmsPlan(elmnt)}
                                            >
                                              Select plan
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="pricingTable-signup">
                                            <button
                                              type="button"
                                              onClick={() => setSmsPlan("")}
                                            >
                                              Unselect plan
                                            </button>
                                          </div>
                                        )}
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    <div className="pt-4">
                      <div className="text-center">
                        <div className="mb-3">
                          The SMS packages does NOT expire at the end of month, the un-used credit will rollover to the next month.
                        </div>
                        <p className="mb-3">
                          Your balance will auto renew when you reach 20% remaining.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="card-header px-0">
                    <div className="d-flex">
                      <h6 className="background-gradient rounded mb-0">
                        REGO LOOKUP
                      </h6>
                    </div>
                  </div>
                  <div className="card-body">
                    {vinLookUpArr && vinLookUpArr.length > 0 && (
                      <>
                        {vinLookUpArr.map((elmnt) => (
                          <div
                            className={
                              myVinlookupArr === "" ||
                                myVinlookupArr.subscription_plan_id !== elmnt.id ||
                                myVinlookupArr.current_vin_request == 0
                                ? vinPlan.id !== elmnt.id
                                  ? "pricingTable mx-auto mb-3"
                                  : "pricingTable active mx-auto mb-3"
                                : "pricingTable active mx-auto mb-3"
                            }
                            key={elmnt.id}
                          >
                            <h3>{elmnt.name}</h3>
                            <div className="price-value">
                              <span>{`${workShopDetails.country.symbol}${elmnt.monthly_charge}`}</span>{" "}
                              <span className="month font-size-14">
                                Unused request will rollover to next month
                              </span>
                            </div>
                            <div className="pricing-content">
                              <ul>
                                <li>
                                  Incl. Approx {elmnt.vin_request} request
                                </li>
                              </ul>
                              {(myVinlookupArr === "" ||
                                myVinlookupArr.subscription_plan_id !== elmnt.id ||
                                myVinlookupArr.current_vin_request == 0
                              ) && (
                                  <>
                                    {vinPlan.id !== elmnt.id ? (
                                      <div className="pricingTable-signup">
                                        <button
                                          type="button"
                                          onClick={() => setVinPlan(elmnt)}
                                        >
                                          Select plan
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="pricingTable-signup">
                                        <button
                                          type="button"
                                          onClick={() => setVinPlan("")}
                                        >
                                          Unselect plan
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    <div className="pt-4">
                      <div className="text-center">
                        <p className="mb-3">The VIN lookup package does NOT expire at the end of month, the un-used credit will rollover to the next month.</p>
                        <p className="mb-3">Your balance will auto renew when you reach 20% remaining.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="font-size-14 font-weight-bold text-danger">
                    * SMS & VIN Lookup Packages Include GST
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card-header px-0">
                    <div className="d-flex">
                      <h6 className="background-gradient rounded mb-0">
                        SUMMARY
                      </h6>
                    </div>
                  </div>
                  <div className="card-body pb-0 pt-0">
                    <div className="row">
                      <div className="col-md-4 price-summary">
                        <table>
                          <tbody>
                            <tr>
                              <td className="price-summary-title">
                                {selectedPlanType.charAt(0).toUpperCase() +
                                  selectedPlanType.slice(1)}{" "}
                                Subscription
                              </td>
                            </tr>
                            <tr>
                              <td> Subscription Plan</td>
                              <td>
                                {workShopDetails?.country?.symbol}
                                {userPlan
                                  ? selectedPlanType === "monthly"
                                    ? userPlan.monthly_charge
                                    : userPlan.yearly_charge
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Sub Additional Users</td>
                              <td>
                                {workShopDetails?.country?.symbol}
                                {addUsrDesktop *
                                  workShopDetails?.country
                                    ?.extra_desktop_user_price +
                                  addUsrMobile *
                                  workShopDetails?.country
                                    ?.extra_mobile_user_price}
                              </td>
                            </tr>
                            <tr>
                              <td>Sub Total</td>
                              <td>{workShopDetails?.country?.symbol}{monthlySubscriptionSubTotal}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4 price-summary">
                        <table>
                          <tbody>
                            <tr>
                              <td className="price-summary-title">
                                Addon Subscription
                              </td>
                            </tr>
                            {/* <tr>
                              <td>Customised Cust App</td>
                              <td>
                                {workShopDetails?.country?.symbol}
                                {appConfigPlan
                                  ? selectedPlanType === "monthly"
                                    ? appConfigPlan.monthly_charge
                                    : appConfigPlan.yearly_charge
                                  : 0}
                              </td>
                            </tr> */}
                            <tr>
                              <td>SMS Plan</td>
                              <td>{workShopDetails?.country?.symbol}{smsPlan ? smsPlan.monthly_charge : 0}</td>
                            </tr>
                            <tr>
                              <td>Rego Lookup Plan</td>
                              <td>{workShopDetails?.country?.symbol}{vinPlan ? vinPlan.monthly_charge : 0}</td>
                            </tr>
                            <tr>
                              <td>Sub Total</td>
                              <td>{workShopDetails?.country?.symbol}{addonSubscriptionSubTotal}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4 price-summary">
                        <table>
                          <tbody>
                            <tr>
                              <td className="price-summary-title">Summary</td>
                            </tr>
                            <tr>
                              <td>Monthly Subscription</td>
                              <td>{workShopDetails?.country?.symbol}{parseFloat(monthlySubscriptionSubTotal).toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td>Addon Subscription</td>
                              <td>{workShopDetails?.country?.symbol}{parseFloat(addonSubscriptionSubTotal).toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td>GST</td>
                              <td>{workShopDetails?.country?.symbol}{gstTotal}</td>
                            </tr>
                            <tr >
                              <td>Sub Total</td>
                              <td>
                                <h3>
                                  {workShopDetails?.country?.symbol}
                                  {monthlySubscriptionSubTotal +
                                    addonSubscriptionSubTotal + parseFloat(gstTotal)}
                                </h3>
                              </td>
                            </tr>
                            <tr>
                              <td>Payment Process fee 1.95%</td>
                              <td>
                                <h3>{workShopDetails?.country?.symbol}{processingFee}</h3>
                              </td>
                            </tr>
                            <tr className="bg-inverse">
                              <td>Total Charge</td>
                              <td>
                                <h3>
                                  $
                                  {parseFloat((monthlySubscriptionSubTotal + addonSubscriptionSubTotal) + parseFloat(processingFee) + parseFloat(gstTotal)).toFixed(2)}
                                </h3>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {selectedPlanType === "monthly" ? (
                <div className="row border-top pt-4">
                  <div className="col-md-2">
                    <h4 className="mb-3">Payment Details</h4>
                    <div className="d-inline-flex align-items-center">

                      <ToggleButtonGroup
                        type="radio"
                        name="payment-type"
                        value={paymentType}
                      >
                        <ToggleButton
                          value="0"
                          id="payment-type-1"
                          variant="outline-primary"
                          className="btn-rounded mb-0 font-size-12"
                          checked={paymentType === "0"}
                          onChange={(e) => setPaymentType("0")}
                        >
                          Stripe
                        </ToggleButton>
                        <ToggleButton
                          value="1"
                          id="payment-type-2"
                          variant="outline-primary"
                          className="btn-rounded mb-0 font-size-12"
                          checked={paymentType === "1"}
                          onChange={(e) => setPaymentType("1")}
                          disabled={workShopDetails?.country?.country_name !== "Australia"}
                        >
                          Capricorn
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>


                  <div className="row col-md-10">
                    {/* Stripe Payment */}
                    <div className="col-md-6 d-flex justify-content-center" style={{ borderRight: '1px solid black' }} >
                      <p className='text-center' >
                        Subscription activated, billed today and on this anniversary
                        date each month
                      </p>

                      <div className={"col-md-10 mt-3 " + (paymentType === '1' ? 'disableDiv' : '')} >
                        <div className="card shadow">
                          <div className="card-body">
                            <Elements stripe={stripePromise}>
                              <div className="pb-0 pt-0 stripe-form">
                                <div className="form-group mb-3">
                                  <label htmlFor="name">Name</label>
                                  <div className="iconic-input">
                                    <i className="fa fa-user"></i>
                                    <input
                                      name="name"
                                      id="name"
                                      className="form-control"
                                      readOnly={paymentType === '1'}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-3">
                                  <label htmlFor="cardNumber">Card Number</label>
                                  <CardNumberElement
                                    id="cardNumber"
                                    className="form-control"
                                    options={ELEMENT_OPTIONS}
                                    showIcon={true}
                                    onChange={() => { }}
                                  />
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="expiry">Card Expiration</label>
                                      <CardExpiryElement
                                        id="expiry"
                                        className="form-control"
                                        options={ELEMENT_OPTIONS}
                                        onChange={() => { }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group mb-3">
                                      <label htmlFor="cvc">CVC</label>
                                      <CardCvcElement
                                        id="cvc"
                                        className="form-control"
                                        options={ELEMENT_OPTIONS}
                                        onChange={() => { }}

                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-8">
                                    <div className="form-group">
                                      <div className="powered-by">
                                        <label htmlFor="">powered by</label>
                                        <img src={StripeLogo} height="30" alt="" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4 text-end">
                                    <button
                                      className="btn btn-primary btn-rounded"
                                      readOnly={paymentType === '1'}
                                      disabled={paymentType === '1'}
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Elements>
                          </div>
                        </div>
                      </div>

                    </div>

                    {/* Capricorn  */}
                    <CapricornForm
                      user_plan_id={userPlan ? userPlan.id : ""}
                      subscriptionCycle={selectedPlanType}
                      total_amount={
                        monthlySubscriptionSubTotal +
                        addonSubscriptionSubTotal + parseFloat(gstTotal)
                      }
                      payment_processing_fees={processingFee}
                      desktop_user={addUsrDesktop}
                      desktop_user_price={
                        workShopDetails?.country?.extra_desktop_user_price
                      }
                      mobile_user={addUsrMobile}
                      mobile_user_price={
                        workShopDetails?.country?.extra_mobile_user_price
                      }
                      sms_plan_id={smsPlan ? smsPlan.id : ""}
                      vin_plan_id={vinPlan ? vinPlan.id : ""}
                      app_config_plan_id={appConfigPlan !== "" ? appConfigPlan.id : ""}
                      success={success}
                      capricornNumber={capricornNumber}
                      setCapricornNumber={setCapricornNumber}
                      paymentType={paymentType}
                      actionType="edit"
                    />
                  </div>



                </div>
              ) : (
                <div className="row border-top pt-4">
                  <div className="col-md-3">
                    <h4 className="mb-3">Payment Details</h4>
                    <p>
                      (Subscription activated, billed today and on this anniversary
                      date each month)
                    </p>
                  </div>
                  <div className="col-md-5">
                    <div className="card shadow">
                      <div className="card-body">
                        <Elements stripe={stripePromise}>
                          <div className="pb-0 pt-0 stripe-form">
                            <div className="form-group mb-3">
                              <label htmlFor="name">Name</label>
                              <div className="iconic-input">
                                <i className="fa fa-user"></i>
                                <input
                                  name="name"
                                  id="name"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              <label htmlFor="cardNumber">Card Number</label>
                              <CardNumberElement
                                id="cardNumber"
                                className="form-control"
                                options={ELEMENT_OPTIONS}
                                showIcon={true}
                                onChange={() => { }}
                              />
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label htmlFor="expiry">Card Expiration</label>
                                  <CardExpiryElement
                                    id="expiry"
                                    className="form-control"
                                    options={ELEMENT_OPTIONS}
                                    onChange={() => { }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label htmlFor="cvc">CVC</label>
                                  <CardCvcElement
                                    id="cvc"
                                    className="form-control"
                                    options={ELEMENT_OPTIONS}
                                    onChange={() => { }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-8">
                                <div className="form-group">
                                  <div className="powered-by">
                                    <label htmlFor="">powered by</label>
                                    <img src={StripeLogo} height="30" alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 text-end">
                                <button
                                  className="btn btn-primary btn-rounded"
                                  onClick={() => handleSubmit()}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </div>
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
              )}


            </div >
          </div >
        </div >
      </>
    );
  }
  return null;
};

export default EditSubscription;
