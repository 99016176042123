export function getWorkshopId() {
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  return storedData?.workshop_id ? window.atob(storedData?.workshop_id) : "";
}

export function getSessionData(key, decrypt = true) {
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  try {
    if (decrypt) {
      return window.atob(storedData[key]);
    } else {
      return storedData[key];
    }
  } catch (err) {
    return storedData[key] ?? "";
  }
}
