import React from 'react';
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { UPDATE_JOB_TIMER } from '../../../store/jobDetails/actionTypes';
import { useDispatch } from "react-redux";

const ApproveJobModal = ({ setApproveJobModal, approveJobId,setApproveJobFlag }) => {
    const handleClose = () => setApproveJobModal(false);
    const dispatch = useDispatch(); // Dispatch variable
    // Approve Job for reschedule job
    const confirmApprove = () => {
        dispatch({
            type: UPDATE_JOB_TIMER,
            payload: {
                id:approveJobId,
                job_status:'Pending'
            }
        });
        setApproveJobFlag(true);
    }

    return (
        <>
            <Modal
                className="info-dialog"
                size="sm"
                show={true}
                onHide={handleClose}
            >
                <div className="heading-block d-flex justify-center bg-warning">
                    <i
                        className="la las la-exclamation-circle close-icon "
                        aria-hidden="true"
                    ></i>
                </div>
                <Modal.Body>
                    <div className="text-center mb-2">
                        <h2 className="mb-2">Approve this reschedule on behalf of the customer?</h2>
                    </div>
                    <div className="d-flex justify-center">
                        <Button
                            variant="outline-secondary"
                            className="btn-outline rounded-btn"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outline-secondary"
                            className="btn btn-primary rounded-btn font-size-13"
                            onClick={() => {confirmApprove();handleClose(); }}
                        >
                            Yes
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ApproveJobModal;
