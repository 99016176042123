import React from 'react';
import { cardPay, changeBtn } from './styles.module.scss';

const PayHead = ({ label, image, onChangeClick }) => {
    return (
        <>
            <div className={cardPay}>
                <span>
                    <img src={image} alt={label} />
                </span>
                <p>
                    {label}
                </p>
            </div>

            <div className={changeBtn}>
                <span onClick={onChangeClick}>Change</span>
            </div></>
    )
}

export default PayHead