import {
  GET_TEMPLATE_LIST,
  GET_TEMPLATE_LIST_FAIL,
  GET_TEMPLATE_LIST_SUCCESS,
  GET_TEMPLATE_DETAIL,
  GET_TEMPLATE_DETAIL_FAIL,
  GET_TEMPLATE_DETAIL_SUCCESS,
  TEMPLET_DELETE,
  TEMPLET_DELETE_SUCCESS,
  TEMPLET_DELETE_FAIL,
  GET_CATEGORIES_LIST,
  GET_CATEGORIES_LIST_SUCCESS,
  GET_CATEGORIES_LIST_FAIL,
  GET_SECTION_LIST,
  GET_SECTION_LIST_SUCCESS,
  GET_SECTION_LIST_FAIL,
  ADD_TEMPLATE_CATEGORY,
  ADD_TEMPLATE_CATEGORY_SUCCESS,
  ADD_TEMPLATE_CATEGORY_FAIL,
  ADD_TEMPLATE_SECTION,
  ADD_TEMPLATE_SECTION_SUCCESS,
  ADD_TEMPLATE_SECTION_FAIL,
  ADD_TEMPLATE,
  ADD_TEMPLATE_SUCCESS,
  ADD_TEMPLATE_FAIL,
  GET_TEMPLATE_SECTIONS,
  GET_TEMPLATE_SECTIONS_SUCCESS,
  GET_TEMPLATE_SECTIONS_FAIL,
  CATEGORY_DELETE,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  SECTION_DELETE,
  SECTION_DELETE_SUCCESS,
  SECTION_DELETE_FAIL,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAIL,
  COPY_TEMPLATE,
  COPY_TEMPLATE_SUCCESS,
  COPY_TEMPLATE_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  UPDATE_SECTION,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAIL,
  EDIT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_FAIL,
  EDIT_SECTION,
  EDIT_SECTION_SUCCESS,
  EDIT_SECTION_FAIL,
  DELETE_INSPECTION_TEMPLATE_QUE,
  DELETE_INSPECTION_TEMPLATE_QUE_SUCCESS,
  DELETE_INSPECTION_TEMPLATE_QUE_FAIL
} from "./actionType";

export const getTemplateList = (data) => {
  return {
    type: GET_TEMPLATE_LIST,
    payload: data
  }
}

export const getTemplateListSuccess = (data) => {
  return {
    type: GET_TEMPLATE_LIST_SUCCESS,
    payload: data,
    message: data.message
  }
}

export const getTemplateListFail = (error) => {
  return {
    type: GET_TEMPLATE_LIST_FAIL,
    payload: error
  }
}

export const getTemplateDetail = (data) => {
  return {
    type: GET_TEMPLATE_DETAIL,
    payload: data
  }
}

export const getTemplateDetailSuccess = (data) => {
  return {
    type: GET_TEMPLATE_DETAIL_SUCCESS,
    payload: data,
    message: data.message
  }
}

export const getTemplateDetailFail = (error) => {
  return {
    type: GET_TEMPLATE_DETAIL_FAIL,
    payload: error
  }
}

export const deleteTemplate = (data) => {
  return {
    type: TEMPLET_DELETE,
    payload: data
  }
}

export const deleteTemplateSuccess = (data) => {
  return {
    type: TEMPLET_DELETE_SUCCESS,
    payload: data,
    message: data.message
  }
}

export const deleteTemplateFail = (error) => {
  return {
    type: TEMPLET_DELETE_FAIL,
    payload: error
  }
}

export const deleteCategory = (data) => {
  return {
    type: CATEGORY_DELETE,
    payload: data
  }
}

export const deleteCategorySuccess = (data) => {
  return {
    type: CATEGORY_DELETE_SUCCESS,
    payload: data,
    message: data.message
  }
}

export const deleteCategoryFail = (error) => {
  return {
    type: CATEGORY_DELETE_FAIL,
    payload: error
  }
}

export const deleteSection = (data) => {
  return {
    type: SECTION_DELETE,
    payload: data
  }
}

export const deleteSectionSuccess = (data) => {
  return {
    type: SECTION_DELETE_SUCCESS,
    payload: data,
    message: data.message
  }
}

export const deleteSectionFail = (error) => {
  return {
    type: SECTION_DELETE_FAIL,
    payload: error
  }
}

export const getCategoriesList = (data) => {
  return {
    type: GET_CATEGORIES_LIST,
    payload: data
  }
}

export const getCategoriesListSuccess = (data) => {
  return {
    type: GET_CATEGORIES_LIST_SUCCESS,
    payload: data,
    message: data.message
  }
}

export const getCategoriesListFail = (error) => {
  return {
    type: GET_CATEGORIES_LIST_FAIL,
    payload: error
  }
}

export const getSectionList = (data) => {
  return {
    type: GET_SECTION_LIST,
    payload: data
  }
}

export const getSectionListSuccess = (data) => {
  return {
    type: GET_SECTION_LIST_SUCCESS,
    payload: data,
    message: data.message
  }
}

export const getSectionListFail = (error) => {
  return {
    type: GET_SECTION_LIST_FAIL,
    payload: error
  }
}

export const getTemplateSections = (data) => {
  return {
    type: GET_TEMPLATE_SECTIONS,
    payload: data
  }
}

export const getTemplateSectionsSuccess = (data) => {
  return {
    type: GET_TEMPLATE_SECTIONS_SUCCESS,
    payload: data,
    message: data.message
  }
}

export const getTemplateSectionsFail = (error) => {
  return {
    type: GET_TEMPLATE_SECTIONS_FAIL,
    payload: error
  }
}

export const addTemplateCategory = () => {
  return {
    type: ADD_TEMPLATE_CATEGORY,
  };
};

export const addTemplateCategorySuccess = (data) => {
  return {
    type: ADD_TEMPLATE_CATEGORY_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const addTemplateCategoryFail = (error) => {
  return {
    type: ADD_TEMPLATE_CATEGORY_FAIL,
    payload: error,
  };
};

export const addTemplate = () => {
  return {
    type: ADD_TEMPLATE,
  };
};

export const addTemplateSuccess = (data) => {
  return {
    type: ADD_TEMPLATE_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const addTemplateFail = (error) => {
  return {
    type: ADD_TEMPLATE_FAIL,
    payload: error,
  };
};

export const copyTemplate = () => {
  return {
    type: COPY_TEMPLATE,
  };
};

export const copyTemplateSuccess = (data) => {
  return {
    type: COPY_TEMPLATE_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const copyTemplateFail = (error) => {
  return {
    type: COPY_TEMPLATE_FAIL,
    payload: error,
  };
};

export const updateTemplate = () => {
  return {
    type: UPDATE_TEMPLATE,
  };
};

export const updateTemplateSuccess = (data) => {
  return {
    type: UPDATE_TEMPLATE_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const updateTemplateFail = (error) => {
  return {
    type: UPDATE_TEMPLATE_FAIL,
    payload: error,
  };
};

export const addTemplateSection = () => {
  return {
    type: ADD_TEMPLATE_SECTION,
  };
};

export const addTemplateSectionSuccess = (data) => {
  return {
    type: ADD_TEMPLATE_SECTION_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const addTemplateSectionFail = (error) => {
  return {
    type: ADD_TEMPLATE_SECTION_FAIL,
    payload: error,
  };
};

export const editCategory = () => {
  return {
    type: EDIT_CATEGORY,
  };
};

export const editCategorySuccess = (data) => {
  return {
    type: EDIT_CATEGORY_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const editCategoryFail = (error) => {
  return {
    type: EDIT_CATEGORY_FAIL,
    payload: error,
  };
};

export const updateCategory = () => {
  return {
    type: UPDATE_CATEGORY,
  };
};

export const updateCategorySuccess = (data) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const updateCategoryFail = (error) => {
  return {
    type: UPDATE_CATEGORY_FAIL,
    payload: error,
  };
};

export const editSection = () => {
  return {
    type: EDIT_SECTION,
  };
};

export const editSectionSuccess = (data) => {
  return {
    type: EDIT_SECTION_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const editSectionFail = (error) => {
  return {
    type: EDIT_SECTION_FAIL,
    payload: error,
  };
};

export const updateSection = () => {
  return {
    type: UPDATE_SECTION,
  };
};

export const updateSectionSuccess = (data) => {
  return {
    type: UPDATE_SECTION_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const updateSectionFail = (error) => {
  return {
    type: UPDATE_SECTION_FAIL,
    payload: error,
  };
};

export const deleteInspectionTemplateQue = () => {
  return {
    type: DELETE_INSPECTION_TEMPLATE_QUE,
  };
};

export const deleteInspectionTemplateQueSuccess = (data) => {
  return {
    type: DELETE_INSPECTION_TEMPLATE_QUE_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const deleteInspectionTemplateQueFail = (error) => {
  return {
    type: DELETE_INSPECTION_TEMPLATE_QUE_FAIL,
    payload: error,
  };
};