import Check from "assets/images/check-white.png";
import { editRecommendations } from "helpers/backendHelper";
import { useCallback, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { showSuccess } from "utils/alertMessage";
import DeleteModal from "utils/DeleteModal";
import useClickOutside from "utils/useClickOutside";
import CompleteWarningModal from "./CompleteWarningModal";
import RecommendationWarningModal from "./RecommendationWarningModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RecommendationViewModal from "./RecommendationViewModal";

const CheckBox = ({ color, active, imgSrc, onClick, className, shadow, label = "" }) => (
  <div
    className={className + ' d-flex align-items-center'}
    onClick={onClick}
    style={{border:"1px solid rgba(0,0,0,.2)", margin: "5px", borderRadius:"10px"}}
  >
    <span
      className="check"
      style={{
        width: "20px",
        height: "20px",
        margin: "8px",
        display: "flex",
        justifyContent: "center",
        borderRadius: "3px",
        boxShadow: active ? shadow : "none",
        alignItems: "center",
        backgroundColor: color,
        position: "relative",
        backgroundImage: active ? `url(${imgSrc})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "-100%",
      }}
    >
      
    </span>

    <p className="p-2">{label}</p>
  </div>
);


const RecommendationRow = ({ handleDeleteClose, input, setLocalData, addNewRecommendation, isEditable, updateJobDescription, isJobView, totalRecommendation }) => {

  const popover = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  
  const close = useCallback(() => setIsOpen(false), []);
  useClickOutside(popover, close);

  const localData = {
    job_id: "",
    workshop_id: "",
    recommendation: "",
    recommendation_color: "",
    recommendation_status: "",
    is_completed: "0",
    is_show: "0",
    status: 1,
    ...input,
  };

  const onChange = (key, value) => {

    if(key === "recommendation") {
      if(value === "" && totalRecommendation === 1) {
        handleDeleteClose();
      } else {
        setLocalData({
          ...localData,
          [key]: value,
        });
      }
    } else {
      setLocalData({
        ...localData,
        [key]: value,
      });
    }
    
  };
  
  const status = [
    // {
    //   id: "Yellow",
    //   color: "#f0c938",
    //   imgSrc: Check,
    //   shadow: "0 0 0 1px #fff, 0 0 0 3px #f0c938",
    //   label: "Std Recommendation",
    // },
    {
      id: "Orange",
      color: "#ED9301",
      imgSrc: Check,
      shadow: "0 0 0 1px #fff, 0 0 0 3px #ED9301",
      label: "Attention",
    },
    {
      id: "Red",
      color: "#f90027",
      imgSrc: Check,
      shadow: "0 0 0 1px #fff, 0 0 0 3px #f90027",
      label: "Urgent",
    },
  ];

  async function completeRecommendation(id){
    
    let data = {
      payload: {
        id: id,
        is_completed: '1'
      },
    };
     
    await editRecommendations(data).then((res) => {
      if (res.code === 200) {
        showSuccess("Recommendation completed successfully");
      }
    });

  }

  async function updateRecommendation(id,recomm){

    let data = {
      payload: {
        id: id,
        recommendation: recomm
      },
    };
     
    await editRecommendations(data).then((res) => {
      if (res.code === 200) {
        showSuccess("Recommendation updated successfully");
      }
    });

  }


  return (

        <div key={input?.id} className="row mb-2 align-center justify-center">
          


          {/* Create job popup section START */}
          {isEditable === false &&
            <>
              <div className="col-md-12">
                <div className="row align-items-center">
                {totalRecommendation > 0 ? (
                  <>
                  <div className="col-md-1 d-flex justify-content-center"> 
                  
                      <Form.Check
                          id={localData?.id}
                          style={{
                            justifyContent: 'center',
                            position: 'relative',
                            display: 'flex'
                          }}
                        >
                          <OverlayTrigger
                              key="bottom"
                              placement="bottom"
                              overlay={
                                  <Tooltip id="tooltip-bottom">
                                      Add to Job
                                  </Tooltip>
                              }
                            >
                            <Form.Check.Input
                              type="checkbox"
                              name="favorite"
                              disabled={localData?.is_completed === "1" || isJobView}
                              className="recommendation-checkbox secondary-check"
                              value={localData?.recommendation}
                              onChange={(e) => {
                                if(e.target.checked) {
                                  updateJobDescription(e.target.value,localData?.id,true);
                                  showSuccess("Recommendation added to job successfully");
                                  // handleDeleteClose();
                                } else {
                                  updateJobDescription(e.target.value,localData?.id,false);
                                  setShowWarning(true);
                                }
                              }}
                            />
                          </OverlayTrigger>
                          <Form.Check.Label>
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                        
                  </div>

                  <div className="col-md-11 align-items-center recommendation-status-list d-flex" >

                    <div  className="" style={{padding:'3px 0px', width: "80px"}}>
                      <div className="jobStatus text-left" style={{background: localData?.recommendation_color}}>
                          {localData?.recommendation_status === "Red" &&
                            <span>Urgent</span>
                          }
                          {localData?.recommendation_status === "Orange" &&
                            <span>Attention</span>
                          }
                      </div>
                    </div>
                    {isJobView ? ( 
                      <span className="ml-1" style={{width: "75%", textAlign:"justify"}} >{localData?.recommendation}</span>
                    ) : (
                      <span className="ml-1" style={{width: "85%", textAlign:"justify"}} >{localData?.recommendation}</span>
                    )}
                    
                  </div>
                  </>

                  ) : (
                    <div style={{padding:"0px 20px 0px 40px"}}>
                      <div style={{width:'100%'}} className="col-md-12 align-items-center recommendation-status-list" >
                        <span className="col-md-6" >No recommendation recorded</span>
                      </div>
                    </div>

                  )}
                  


                </div>
              </div>
              </>
            }
            {/* Create job popup section END */}


        {/* Add / Edit Recommendation on Job Detail Page START */}

          {isEditable &&

            <>

              <div className="col-md-12">
                <div className="row align-items-center">

                  {/* Checkbox START */}
                  <div className="col-md-1"> 
                      <Form.Check
                          id={localData?.id}
                          style={{
                            justifyContent: 'center',
                            position: 'relative',
                            display: 'flex'
                          }}
                        >
                          <OverlayTrigger
                              key="bottom"
                              placement="bottom"
                              overlay={
                                  <Tooltip id="tooltip-bottom">
                                      Add to Job
                                  </Tooltip>
                              }
                            >
                          <Form.Check.Input
                            type="checkbox"
                            disabled={isEditable && localData?.is_completed === "1"}
                            name="favorite"
                            className="recommendation-checkbox secondary-check"
                            value={localData?.recommendation}
                            checked={localData?.is_show === "1" || localData?.is_completed === "1" ? true : false}
                            onChange={(e) => {
                              if(e.target.checked) {
                                if(e.target.value){
                                  onChange("is_show", "1");
                                  updateJobDescription(e.target.value);
                                  showSuccess("Recommendation added to job successfully");
                                }
                              } else {
                                onChange("is_show", "0");
                                setShowWarning(true);
                              }
                            }}
                          />
                          </OverlayTrigger>
                          <Form.Check.Label>
                            <span className="check"></span>
                          </Form.Check.Label>
                        </Form.Check>
                    {/* <input type="checkbox" className="form-control recommendation-checkbox" /> */}
                  </div>
                  {/* Checkbox END */}


                  {/* Textbox START */}
                  <div className="col-md-10">
                      <div className="d-flex input-group">
                        <span disabled={localData?.is_show === "1" ? true : false} className="color-thumb input-group-text" onClick={localData?.is_show === "0" ? (() => setIsOpen(true)) : (() => setIsOpen(false))} style={{padding: "8px", width: "40px"}} >
                          <span style={ localData?.recommendation_color ? { background: localData?.recommendation_color } : { background: "#f90027" } } />
                        </span>
                        <input
                          type="text"
                          readOnly={localData?.is_show === "1" ? true : false}
                          disabled={localData?.is_show === "1" ? true : false}
                          className="form-control recommendations-form-control"
                          value={localData?.recommendation}
                          placeholder="Type to add recommendations"
                          onChange={(e) => {
                            onChange("recommendation", e.target.value);
                          }}
                          style={localData?.is_completed === "1" ?  { borderRight:"0"} : {} }
                        />
                        {localData?.is_completed === "1" &&
                          <span className="input-group-text" style={{background:"#EEEEEE", color:"green", marginLeft: "0px"}} >
                            <span>Completed</span>
                          </span>
                        }

                        {isOpen && (
                          <div className="popover top-100" ref={popover} style={{borderRadius:"5px"}}>

                                  {status.map((c) => (

                                    // <span key={c.id} className="color-thumb input-group-text" onClick={(e) => {
                                    //   setLocalData({
                                    //     ...localData,
                                    //     "recommendation_color": c.color,
                                    //     "recommendation_status": c.id,
                                    //   });
                                    // }}>
                                    //   <span style={{ background: c.color }} />
                                    // </span>

                                    <CheckBox
                                      label={c.label}
                                      key={c.id}
                                      color={c.color}
                                      imgSrc={c.imgSrc}
                                      shadow={c.shadow}
                                      active={input?.recommendation_status === c.id}
                                      onClick={(e) => {
                                        setLocalData({
                                          ...localData,
                                          "recommendation_color": c.color,
                                          "recommendation_status": c.id,
                                        });
                                      }}
                                    />
                                  ))}

                            
                            {/* <HexColorPicker
                              color={localData?.recommendation_color}
                            /> */}
                          </div>
                        )}
                      </div>
                  </div>
                  {/* Textbox END */}
            
                  {/* OPtions Menu START */}
                  <div className="col-md-1 react-bootstrap-table">
                      <div className="btn-group toggle-hover">
                        <button className="btn " type="button" style={{background:"#EFEFEF", borderRadius:"50%"}}>
                          <i style={{color:"#1cc9e4"}} className="fa fa-ellipsis-h" aria-hidden="true"></i>
                        </button>

                        <div className="dropdown-list recommendation-dropdown-list">

                          <li>
                            <Link to={``} onClick={addNewRecommendation}  className="" title="ADD LINE">
                              <i className="fa fa-plus text-info"></i>
                              <span>Add Line</span>
                            </Link>
                          </li>

                          <li>
                            <Link to={``} className="" title="View/Edit"
                              onClick={(e) => {
                                setShowViewModal(true);
                              }} 
                            >
                              <i className="fa fa-eye text-info"></i>
                              <span>View / Edit</span>
                            </Link>
                          </li>

                          <li>
                            {localData?.is_completed === "1" && 

                              <Link to={``} disabled={true} className="" title="Mark as Complete">
                                <i className="fa fa-check-square text-info"></i>
                                <span>Mark as Complete</span>
                              </Link>

                            }
                            {localData?.is_completed === "0" && localData?.is_show === "1" &&  

                              <Link to={``} className="" title="Mark as Complete"
                              onClick={(e) => {
                                setShowCompleteModal(true);
                              }}  
                              >
                              <i className="fa fa-check-square text-info"></i>
                              <span>Mark as Complete</span>
                              </Link>
                            }

                            {localData?.is_completed === "0" && localData?.is_show === "0" &&  

                              <Link disabled={true} to={``} className="" title="Mark as Complete">
                                <i className="fa fa-check-square text-info"></i>
                                <span>Mark as Complete</span>
                              </Link>

                            }
                            
                          </li>

                          {/* <li>
                            <Link to={``} className="" title="COPY" onClick={(e) => {
                                  // navigator.clipboard.writeText(marketingMessage);
                                  var doc = document.implementation.createDocument('http://www.w3.org/1999/xhtml', 'html', null);
                                  doc.documentElement.innerHTML = localData?.recommendation;
                                  let copyText =  doc.documentElement.textContent||doc.documentElement.innerText;
                                  navigator.clipboard.writeText(copyText);
                                  showSuccess('Copied!');
                                }}
                            >
                              <i className="fa fa-copy text-info"></i>
                              <span>Copy Text</span>
                            </Link>
                          </li> */}

                          <li
                            onClick={() => {
                              setShowDeleteModal(!showDeleteModal);
                            }}
                          >
                            <Link onClick={() => setShowDeleteModal(true)} to="" className="delete-link" title="DELETE">
                              <span className="red-bg">
                                <i className="fa fa-minus"></i>
                              </span>
                              <span>Delete</span>
                            </Link>
                          </li>

                        </div>
                        
                      </div>
                      
                  </div>
                  {/* OPtions Menu END */}

                </div>
              </div>
            
                  {showDeleteModal && (
                    <DeleteModal
                      handleOutSide
                      showDeleteModal={showDeleteModal}
                      setShowDeleteModal={() => {}}
                      api={() => {
                        handleDeleteClose();
                      }}
                      msg={"Delete this record?"}
                    />
                  )}
            </>
          
          }

          {/* Add / Edit Recommendation on Job Detail Page START */}



          {/* Recommendation Warning Message START */}

            {showWarning && 
              <RecommendationWarningModal 
                setShowWarning={setShowWarning}
              />
            }

          {/* Recommendation Warning Message END */}



          {/* Complete Warning Message START */}

          {showCompleteModal && 
              <CompleteWarningModal 
                showCompleteModal={showCompleteModal}
                setShowCompleteModal={() => {}}
                closeModal={() => {
                  setShowCompleteModal(false);
                }}
                api={() => {
                  setLocalData({
                    ...localData,
                    "is_completed": "1",
                  });
                  completeRecommendation(localData?.id);
                }}
                msg={"Complete this recommendation?"}
              />
            }

          {/* Complete Warning Message END */}



          {/* View/Edit Popup START */}

          {showViewModal && 
              <RecommendationViewModal 
                showViewModal={showViewModal}
                setShowViewModal={() => {}}
                closeModal={() => {
                  setShowViewModal(false);
                }}
                localData={localData}
                api={(recomm) => {
                  setLocalData({
                    ...localData,
                    "recommendation": recomm,
                  });
                  setShowViewModal(false);
                  updateRecommendation(localData?.id,recomm);
                }}
              />
            }

          {/* View/Edit Popup END */}

        </div>

   
  );
};

export default RecommendationRow;
